import React from 'react'
import { FaArrowRightLong } from 'react-icons/fa6';
import { Link } from "react-router-dom";

const InvalidRoutePage = () => {
    return (
        <div className=' h-[100%]'>
            <div className='w-full flex justify-center'>
                <img src="/404page.png" width={"50%"} alt="" />
            </div>
            <div className='w-full flex justify-center'>
                <Link to='/' className='bg-primary-main text-white p-2 px-4 rounded cursor-pointer text-[20px] flex items-center gap-2'> Go to dashboard <FaArrowRightLong /></Link>
            </div>
        </div>
    )
}

export default InvalidRoutePage