import React from "react";
import { IconType } from "react-icons";
import { MdHistory } from "react-icons/md";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import AccessDenied from "src/utils/AccessDenied";
import { isAuthorized } from "src/utils/authorization";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import { IoDocumentTextOutline } from "react-icons/io5";
import { ImProfile } from "react-icons/im";

type Props = {};

export const inquiriesEditTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
  {
    label: "Profile",
    icon: ImProfile,
    path: "profile",
    moduleName: UserModuleNameTypes.ACTION_TEAMS_VIEW_PROFILE_TAB,
  },
  {
    label: "TRANSACTION HISTORY",
    icon: MdHistory,
    path: "transaction-history",
    moduleName: UserModuleNameTypes.ACTION_TEAMS_VIEW_TRANSACTION_HISTORY_TAB,
  },
  {
    label: "SALARY SUMMARY",
    icon: FaMoneyBill1Wave,
    path: "salary-summary",
    moduleName: UserModuleNameTypes.ACTION_TEAMS_VIEW_SALARY_SUMMARY_TAB,
  },
  {
    label: "DOCUMENTS",
    icon: IoDocumentTextOutline,
    path: "documents",
    moduleName: UserModuleNameTypes.ACTION_TEAMS_VIEW_DOCUMENTS_TAB,
  },
];

const TeamViewTabsLayout = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname.split("/")[3];
  const { userData } = useGetLocalStorage();

  React.useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("open");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of inquiriesEditTabs) {
      const isValue = isAuthorized(
        nav?.moduleName as keyof typeof UserModuleNameTypes
      );
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav?.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tabsRender = inquiriesEditTabs?.some((nav) => {
    return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
  });

  return (
    <SideNavLayout>
      {tabsRender ? (
        <div className="flex flex-col gap-2 h-full mt-2">
          {/* inquiriesEditTabs */}
          {inquiriesEditTabs?.length && (
            <div className="flex gap-3 items-center bg-white">
              {inquiriesEditTabs
                ?.filter((nav) => {
                  return isAuthorized(
                    nav?.moduleName as keyof typeof UserModuleNameTypes
                  );
                })
                ?.map((tab: any, index: any) => {
                  return (
                    <div key={index}>
                      <button
                        type="button"
                        onClick={() => navigate(tab.path)}
                        className={`h-full px-4 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${
          currentPath === tab.path?.split("/")[0]
            ? "text-primary-main   border-primary-main"
            : "text-gray-700 border-white"
        }
         `}
                      >
                        <div className="text-lg">
                          <tab.icon />
                        </div>
                        {tab.label}
                      </button>
                    </div>
                  );
                })}
            </div>
          )}
          <div className="grow overflow-auto ">
            <Outlet />
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </SideNavLayout>
  );
};

export default TeamViewTabsLayout;
