import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { PlanFormValues } from "src/models/Plan.model";
import PlanForm from "../Layouts/PlanForm";
import {
  useGetPlanByIdQuery,
  useUpdatePlanByIdMutation,
} from "src/services/PlanService";

type Props = {
  getPlanId: string;
  onClose: () => void;
  productId: string;
};

const EditPlanWrapper = ({ getPlanId, onClose, productId }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetPlanByIdQuery(getPlanId);
  const [updatePlan] = useUpdatePlanByIdMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: PlanFormValues = {
    planName: items.planName,
    amount: items?.amount,
    recurranceDays: items?.recurranceDays,
  };

  // Validation Schema
  const validationSchema = object().shape({
    planName: string().required("Please enter plan name"),
    amount: string().test("cost-test", "Please enter amount", (value) => {
      return Number(value || 0) > 0;
    }),
    recurranceDays: string().required("Please enter recurrance  days"),
  });
  // Handle Submit
  const handleSubmit = (
    values: PlanFormValues,
    { setSubmitting, resetForm }: FormikHelpers<PlanFormValues>
  ) => {
    updatePlan({
      id: getPlanId,
      body: { ...values, productId: productId },
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <PlanForm
            formikProps={formikProps}
            formType="EDIT"
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditPlanWrapper;
