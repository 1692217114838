import apiSlice from "./ApiSlice";

export const GoalCategoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllGoalCategory: builder.query({
      providesTags: ["goal-category"],
      query: () => {
        return {
          url: "/goal-category",
          method: "GET",
        };
      },
    }),
    // Get goal-category with pagination
    getGoalCategory: builder.query({
      providesTags: ["goal-category"],
      query: (body) => {
        return {
          url: "/goal-category",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addGoalCategory: builder.mutation({
      invalidatesTags: ["goal-category"],
      query: (body) => {
        return {
          url: "/goal-category/add",
          method: "POST",
          body,
        };
      },
    }),

    deleteGoalCategory: builder.mutation({
      invalidatesTags: ["goal-category"],
      query: (id: any) => ({
        url: `/goal-category/${id}`,
        method: "DELETE",
      }),
    }),


    //Edit
    updateGoalCategory: builder.mutation({
      invalidatesTags: ["goal-category"],
      query: ({ id, body }) => {
        return {
          url: `/goal-category/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get goal-category by ID
    getGoalCategoryById: builder.query({
      providesTags: ["goal-category"],
      query: (id) => {
        return {
          url: `/goal-category/${id}`,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useGetAllGoalCategoryQuery,
  useGetGoalCategoryQuery,
  useAddGoalCategoryMutation,
  useUpdateGoalCategoryMutation,
  useDeleteGoalCategoryMutation,
  useGetGoalCategoryByIdQuery
} = GoalCategoryApi;
