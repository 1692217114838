import React, { useEffect, useState } from "react";
import EditTaskForm from "./EditTaskForm";
import { Form, Formik, FormikHelpers } from "formik";
import { EditTaskFormValues } from "src/models/Task.model";
import { object, string } from "yup";
import {
  useGetTaskByIdQuery,
  useUpdateTaskByIdMutation,
} from "src/services/TaskService";
import { showToast } from "src/utils/showToaster";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  selectedTask: string;
  onClose: () => void;
};

const EditTaskFormWrapper = ({ selectedTask, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetTaskByIdQuery(selectedTask);
  const [updateTask] = useUpdateTaskByIdMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: EditTaskFormValues = {
    title: items?.title || "",
    description: items?.description || "",
    estimation: items?.estimation || "",
    featureId: items?.featureId || "",
    moduleId: items?.moduleId || "",
    projectId: items?.projectId || "",
    dueDate: items?.dueDate ? items?.dueDate : "",
    label: items?.label?.map((label: any) => {
      return {
        labelName: label?.labelName,
      };
    }),
    layer: items?.layer,
    assignedToCheck: items?.assignedTo,
  };

  // Validation Schema
  const validationSchema = object().shape({
    // // repoProjects: object().required("Please select project."),
    title: string().required("Please enter Task Title"),
    // repoUrl: string().required("Please enter repo url."),
  });

  const handleSubmit = (
    values: EditTaskFormValues,
    { setSubmitting, resetForm }: FormikHelpers<EditTaskFormValues>
  ) => {
    const formattedValues = {
      title: values?.title,
      description: values?.description,
      estimation: values?.estimation,
      featureId: values?.featureId,
      projectId: values?.projectId,
      label: !Array.isArray(values?.label)
        ? [{ labelName: values?.label?.labelName, _id: values?.label?._id }]
        : values?.label,
      layer: values?.layer,
      dueDate: values?.dueDate
        ? moment(values?.dueDate).format("yyyy-MM-DD")
        : "",
    };
    const filteredValues: any = Object.fromEntries(
      Object.entries(formattedValues).filter(
        ([_, value]) =>
          value !== undefined && value !== "" && value.length !== 0
      )
    );
    updateTask({ id: selectedTask, body: filteredValues }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <EditTaskForm
            id={items?.projectId}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditTaskFormWrapper;
