import React, { useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import {
  MeetingFormValues,
  MeetingListResponse,
} from "src/models/Meeting.model";
import {
  useGetMeetingByIdQuery,
  useUpdateMeetingMutation,
} from "src/services/MeetingServices";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import { useState } from "react";
import MeetingForm from "../Layout/MeetingForm";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { convertIntoCapitalize } from "src/utils/common/capitalize";
import { CircularProgress } from "@mui/material";

type Props = {};

const getMeetingWithLabelAndValue = (value: string) => {
  switch (value) {
    case "INTERNAL_TEAM":
      return {
        label: "Internal Team",
        value: "INTERNAL_TEAM",
      };
    case "CLIENT":
      return {
        label: "Client",
        value: "CLIENT",
      };
    case "THIRD_PARTY_WITH_INTERNAL_TEAM":
      return {
        label: "Internal Team (Third Party)",
        value: "THIRD_PARTY_WITH_INTERNAL_TEAM",
      };
    case "THIRD_PARTY_WITH_CLIENT":
      return {
        label: "Client (Third Party)",
        value: "THIRD_PARTY_WITH_CLIENT",
      };
    default:
      break;
  }
};

const EditMeetingFormWrapper = (props: Props) => {
  const [meetingData, setMeetingData] = useState<MeetingListResponse>();

  const navigate = useNavigate();
  const { id: MEETING_ID } = useParams();

  const { isLoading, isFetching, data } = useGetMeetingByIdQuery(
    MEETING_ID || "",
    {
      skip: !MEETING_ID,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setMeetingData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const [updateMeeting] = useUpdateMeetingMutation();

  // Form Initial Values
  const initialValues: MeetingFormValues = {
    title: meetingData?.title || "",
    projectId: {
      label: meetingData?.projectName || "",
      value: meetingData?.projectId || "",
    },
    mode: {
      label: convertIntoCapitalize(meetingData?.mode || "") || "",
      value: meetingData?.mode || "",
    },
    scheduledDate: meetingData?.scheduledDate,
    meetingTypeId: {
      label: meetingData?.meetingTypeName,
      value: meetingData?.meetingTypeId,
    },
    meetingWith: getMeetingWithLabelAndValue(meetingData?.meetingWith),
    clientUsers: meetingData?.clientUsers || [],
    codioticUsers:
      meetingData?.codioticUsers?.map((ele) => {
        return {
          label: ele?.name,
          value: ele?._id,
        };
      }) || [],
    address: meetingData?.address || "",
    meetingLink: meetingData?.meetingLink || "",
    requiredThings: meetingData?.requiredThings || "",
    agenda: meetingData?.agenda || [],

    scheduledStartTime: meetingData?.scheduledStartTime,
    scheduledEndTime: meetingData?.scheduledEndTime,
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Title is required"),
    projectId: object().required("Project is required"),
    mode: object().required("Mode is required"),
    // scheduledDate: object().required("Scheduled Date is required"),
    // scheduledStartTime: object().required("Scheduled start time is required"),
    // scheduledEndTime: object().required("Scheduled end time is required"),
    // clientUsers: object().required("Client user is required"),
    // codioticUsers: object().required("Codiotic user is required"),
    address: string(),
    meetingLink: string(),
    meetingTypeId: object().required("Meeting type is required"),
    // address: string().required("address is required"),
    // meetingLink: string().required("link is required"),
    // requiredThings: string(),
    // agenda: string().required("agenda is required"),
  });

  // Handle Submit
  const handleSubmit = (
    values: MeetingFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingFormValues>
  ) => {
    const {
      projectId,
      mode,
      codioticUsers,
      scheduledStartTime,
      meetingTypeId,
      scheduledEndTime,
      scheduledDate,
      meetingWith,
      ...rest
    } = values;

    const formatedValues = {
      projectId: projectId?.value,
      mode: mode?.value,
      scheduledDate: moment(scheduledDate).format("YYYY-MM-DD"),
      codioticUsers: codioticUsers?.map((ele) => ele?.value),
      scheduledStartTime: moment(values?.scheduledStartTime, "YYYY-MM-DD HH:mm:ss", true).isValid()
        ? moment(scheduledStartTime).format("HH:mm:ss")
        : values.scheduledStartTime,
      scheduledEndTime: moment(values?.scheduledEndTime, "YYYY-MM-DD HH:mm:ss", true).isValid()
        ? moment(scheduledEndTime).format("HH:mm:ss")
        : values.scheduledEndTime,
      meetingTypeId: meetingTypeId?.value,
      meetingWith: meetingWith?.value,
      meetingCreateType: "SCHEDULED",
      ...rest,
    };
    updateMeeting({ id: MEETING_ID, body: formatedValues }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          navigate("/meeting");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <MeetingForm formType="Edit" formikProps={formikProps} />
        </Form>
      )}
    </Formik>
  );
};

export default EditMeetingFormWrapper;
