import React, { useEffect, useState } from "react";
import { BsFillReplyFill } from "react-icons/bs";
import { IoSend } from "react-icons/io5";
import ActionPopup from "src/components/UI/molecules/MOLActionPopUp/MOLActionPopUp";
import { MdDeleteForever } from "react-icons/md";
import { AiFillPushpin } from "react-icons/ai";
import { BiSolidChevronDown } from "react-icons/bi";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import ColorComponent from "src/components/UI/colorComponent";

type Props = {
  chatList: any;
  chatInputValue: string;
  isMessageSentLoading: boolean;
  isVisibleToClient: boolean;
  isMessageEditState: boolean;
  handleCancelEditMessage: () => void;
  setIsVisibleToClient: (value: boolean) => void;
  handleChatInputChange: (e: React.ChangeEvent | any) => void;
  onSubmitChat: () => void;
  handleDeleteMessage: (messageId: string) => void;
  handlePinMessage: (messageId: string, message: string) => void;
  handleEditMessage: (messageId: string, message: string) => void;
  handleUnPinMessage: (messagePinId: string) => void;
  setReplyMessageId: (messageId: string | null) => void;
  setReplyMessageText: (messageText: string) => void;
  setReplyMessageLabel: (messageText: string) => void;
};

type MessageData = {
  _id: string;
  ticketId: string;
  userId: string;
  time: string;
  message: string;
  isSeenByUsers: string[];
  isDeleted: boolean;
  isActive: boolean;
  createdAt: string;
  isEdited: boolean;
  updatedAt: string;
  messageParentId: string;
  messagePinId: string;
  __v: number;
  userLabel: string;
  messageParentText: string;
  parentMessageUserLabel: string;
};

const userData = JSON?.parse(localStorage?.getItem("userData") || "{}");

const TicketChat = ({
  chatList,
  chatInputValue,
  isMessageSentLoading,
  isVisibleToClient,
  isMessageEditState,
  handleCancelEditMessage,
  setIsVisibleToClient,
  handleChatInputChange,
  onSubmitChat,
  handleDeleteMessage,
  handlePinMessage,
  handleEditMessage,
  handleUnPinMessage,
  setReplyMessageId,
  setReplyMessageText,
  setReplyMessageLabel,
}: Props) => {
  const [replyMessage, setReplyMessage] = useState("");
  const [replyMessageUserName, setReplyMessageUserName] = useState("");
  useEffect(() => {
    document
      .getElementById("scroll-bottom")
      ?.scrollTo(
        0,
        document.getElementById("scroll-bottom")?.scrollHeight || 0
      );
  }, []);

  const scrollToParent = (pId: string) => {
    // document?.getElementById(pId).style.backgroundColor = "red";
    const element = document.getElementById(pId);

    if (element) {
      // Set the background color of the element
      element.classList.add("show-parent-id");
      setTimeout(() => {
        element.classList.remove("show-parent-id");
      }, 2000);

      // Scroll to the element
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const moment = require("moment");
  const Message = ({ timestamp }:any) => {
    // Convert the timestamp to a Moment object
    const messageTime = moment(timestamp);
    
    // Get the current time
    const currentTime = moment();
    
    // Calculate the difference between the current time and the message time
    const difference = currentTime.diff(messageTime, 'milliseconds');
    
    // Thresholds for displaying different formats
    const minuteThreshold = 60 * 1000; // 1 minute in milliseconds
    const hourThreshold = 60 * minuteThreshold; // 1 hour in milliseconds
    const dayThreshold = 24 * hourThreshold; // 1 day in milliseconds
    
    // Choose the format based on the difference
    let formattedTime;
    if (difference < minuteThreshold) {
      formattedTime = 'just now';
    } else if (difference < hourThreshold) {   
      formattedTime = `${moment.duration( -difference , 'milliseconds').humanize(true)}`
      // formattedTime = moment.duration(difference).humanize(true);
    } else if (difference < dayThreshold) {
      formattedTime = messageTime.calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: 'dddd',
        sameElse: `MMM D at ${moment(timestamp)?.format("hh:mm a")}`
      });
    } else {
      formattedTime = messageTime.format("DD MMM YYYY hh:mm A");
    }
  
    return (
      <div>
        <p className="text-[10px] text-neutral">{formattedTime}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <div
        className="flex-1 bg-[#fcfcfc] flex flex-col-reverse gap-2 h-full py-4 overflow-auto"
        id="scroll-bottom"
      >
        {chatList?.length ? (
          chatList?.map((chat: MessageData, index: number) => {
            const isCurrentUser = chat?.userId === userData?.userId;

            const cardClasses = `flex gap-2 px-4 py-2 ${
              chat?.messagePinId && "bg-[#E9F7F9] "
            }   ${chat?.messageParentId && "cursor-pointer"} 
            ${
              isCurrentUser
                ? "justify-end items-end flex row-reverse" //us
                : "flex-row " //other
            }
            `;
            return (
              <div
                key={index}
                className={`${chat?.isDeleted ? "" : "replay-icon-parent"}`}
              >
                <div id={chat?._id} className={cardClasses}>
                  {/* Other users side profile and chat time */}
                  {!isCurrentUser ? (
                    <>
                      <div className="flex flex-col items-center w-fit">
                        <div className="h-[28px] aspect-square bg-primary-main text-white rounded-full flex justify-center items-center font-medium">
                          {chat?.userLabel?.[0]?.toUpperCase()}
                        </div>
                        {/* <div className="text-xs text-neutral mt-1 font-medium">
                          {moment(chat?.createdAt)?.format("hh:mm a")}{" "}
                        </div> */}
                      </div>
                    </>
                  ) : null}

                  <div
                    className={` flex flex-col ${
                      isCurrentUser ? "" : ""
                    }  min-w-[12%] chat-menu`}
                  >
                    <div className="flex gap-3">
                      {/* My side replay icon */}
                      {isCurrentUser ? (
                        <div className="flex items-center reply-icon">
                          <div className="bg-slate-200 p-2 cursor-pointer flex items-center rounded-full">
                            <BsFillReplyFill
                              onClick={() => {
                                setReplyMessageId(chat?._id);
                                setReplyMessageText(chat?.message);
                                setReplyMessageLabel(chat?.userLabel);
                                setReplyMessage(chat?.message);
                                setReplyMessageUserName(chat?.userLabel);
                              }}
                              size={18}
                              className="flex items-center  "
                            />
                          </div>
                        </div>
                      ) : null}

                      <div
                        className={`flex flex-col shadow-sm rounded-md relative flex-1 gap-1 ${
                          isCurrentUser
                            ? "bg-[#196674] text-white min-w-[70%] pb-2 pl-2 pt-4 pr-6"
                            : "bg-white gap-1 pb-2 pl-2 pt-2 pr-2"
                        } border-slate-200 border chat-menu`}
                      >
                        <div
                          className={`flex justify-between items-center ${
                            isCurrentUser ? "absolute right-0 top-0" : ""
                          } `}
                        >
                          <div
                            className={`flex items-center ${
                              isCurrentUser ? "" : "justify-between"
                            } text-xs font-medium text-primary-main w-full`}
                          >
                            {!isCurrentUser ? (
                              <>
                                <ColorComponent
                                  inputString={
                                    chat?.userLabel?.charAt(0)?.toUpperCase() +
                                    chat?.userLabel?.slice(1)
                                  }
                                />
                              </>
                            ) : null}

                            {/* option icon for pin/upin */}
                            <div className="text-sm flex flex-row gap-2 z-[5000] pl-2">
                              <div className="cursor-pointer chat-menu-button">
                                <ActionPopup
                                  popUpIcon={
                                    !chat?.isDeleted && (
                                      <BiSolidChevronDown
                                        color={`${
                                          isCurrentUser ? "#f5f5f5" : ""
                                        }`}
                                        size={22}
                                      />
                                    )
                                  }
                                  isIconVertical={true}
                                  isEdit={!chat?.isDeleted && isCurrentUser}
                                  isDelete={!chat?.isDeleted && isCurrentUser}
                                  // isDelete={true}
                                  isPin={
                                    !chat?.isDeleted &&
                                    chat?.messagePinId === null
                                  }
                                  unPin={
                                    !chat?.isDeleted &&
                                    chat?.messagePinId !== null
                                  }
                                  handleEditActionButton={() => {
                                    handleEditMessage(chat?._id, chat?.message);
                                  }}
                                  handleDeleteActionButton={() =>
                                    handleDeleteMessage(chat?._id)
                                  }
                                  handleMessagePinActionButton={() => {
                                    handlePinMessage(chat?._id, chat?.message);
                                  }}
                                  handleMessageUnPinActionButton={() => [
                                    handleUnPinMessage(chat?.messagePinId),
                                  ]}
                                  handleOnAction={() => {}}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          {chat?.isDeleted ? (
                            <div
                              className={`${
                                isCurrentUser
                                  ? "text-slate-100"
                                  : "text-neutral"
                              }  italic font-medium text-sm`}
                            >
                              {isCurrentUser
                                ? "You deleted this message."
                                : "This message was deleted."}
                            </div>
                          ) : (
                            // chat Content
                            <div className="flex flex-col gap-1">
                              {chat?.messageParentId && (
                                <div
                                  onClick={() =>
                                    scrollToParent(chat?.messageParentId)
                                  }
                                >
                                  <div
                                    className={`shadow ${
                                      isCurrentUser
                                        ? "bg-[#134E58]"
                                        : "bg-[#F3F4F6]"
                                    }  border-l-[4px] rounded border-[#3FC9DD] p-2 w-full chat-menu`}
                                  >
                                    <div
                                      className={`text-[13px] ${
                                        isCurrentUser
                                          ? "text-white"
                                          : "text-[#000000]"
                                      }  font-bold text-primary-main`}
                                    >
                                      {chat?.parentMessageUserLabel}
                                    </div>
                                    <div className="line-clamp-2 text-sm">
                                      <ATMMarkdownContainer
                                        markdown={chat?.messageParentText}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div
                                className={`flex justify-between ${isCurrentUser && "main-message"}`}
                              >
                                <ATMMarkdownContainer
                                  markdown={chat?.message}
                                />
                              </div>
                              {!isCurrentUser ? (
                                <div className="text-[10px] text-neutral font-medium flex justify-end">
                                  {/* {moment(chat?.createdAt)?.format("hh:mm a")}{" "} */}
                                  <Message timestamp={chat?.createdAt} />
                                </div>
                              ) : null}

                              {chat?.isEdited && (
                                <div
                                  className={`text-[10px] font-medium flex justify-end ${isCurrentUser ? "text-white" : "text-black"}`}
                                >
                                  edited
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Other users side reply icon */}
                      {!isCurrentUser ? (
                        <div className="reply-icon flex items-center ">
                          <div className="bg-slate-200 p-2 cursor-pointer flex items-center rounded-full">
                            <BsFillReplyFill
                              onClick={() => {
                                setReplyMessageId(chat?._id);
                                setReplyMessageText(chat?.message);
                                setReplyMessageLabel(chat?.userLabel);

                                setReplyMessage(chat?.message);
                                setReplyMessageUserName(chat?.userLabel);
                              }}
                              size={18}
                              className="flex items-center"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {/* My Side chat time */}
                    {isCurrentUser ? (
                      <p>
                        <>
                          <div className="flex justify-end gap-3 items-center text-sm text-neutral">
                            {chat?.messagePinId ? (
                              <div className=" flex gap-2 text-[14px] items-center text-[#041618]">
                                <AiFillPushpin /> Pinned
                              </div>
                            ) : null}
                            <div className="text-[10px] text-neutral font-medium flex justify-end">
                            <Message timestamp={chat?.createdAt} />
                              {/* {moment(chat?.createdAt)?.format("hh:mm a")} */}
                            </div>
                          </div>
                        </>
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h1 className="px-2">Start Your chat here.........</h1>
        )}
      </div>

      {/* Message input */}
      <div>
        {replyMessage.length ? (
          <div className="flex flex-col gap-2 bg-gray-100 p-4 rounded-t-3xl border-t ">
            <div className="flex gap-2 items-center">
              <BsFillReplyFill size={18} color="#00A3A1" />
              <span className="font-medium text-sm">Replying to</span>
            </div>

            <div className="shadow-sm rounded-md border bg-white p-3 max-w-[50%] w-min-[10%]  chat-menu">
              <div className="flex justify-between">
                <div className="text-sm mb-1 font-medium text-primary-main">
                  {replyMessageUserName.charAt(0).toUpperCase() +
                    replyMessageUserName.slice(1)}
                </div>
                <div className="flex justify-end">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setReplyMessage("");
                      setReplyMessageUserName("");
                      setReplyMessageId(null);
                      setReplyMessageText("");
                      setReplyMessageLabel("");
                    }}
                  >
                    <MdDeleteForever size={20} color="#00A3A1" />
                  </span>
                </div>
              </div>
              <div className="line-clamp-2 text-neutral text-xs">
                <ATMMarkdownContainer markdown={replyMessage} />
              </div>
            </div>
          </div>
        ) : null}

        <div className="relative">
          {/* MDX Editor for chat */}
          <div className="z-0 fit-height">
            <MOLMarkdownEditor
              label=""
              height={100}
              value={chatInputValue}
              placeholder="Write chat or drag your files here...."
              onChange={handleChatInputChange}
            />
          </div>

          <div className="px-2 py-[0.1rem] flex justify-between items-center">
            <ATMCheckbox
              extraClasses="z-[5000000]"
              label="Show message to client"
              labelClasses="text-xs select-none"
              inputClasses="h-[10px]"
              checked={isVisibleToClient}
              onChange={(checked) => {
                setIsVisibleToClient(checked);
              }}
            />

            <div className="flex gap-x-4">
              {isMessageEditState && (
                <button
                  // disabled={isMessageSentLoading}
                  className="px-2 py-[0.1rem] rounded font-semibold text-black border-[1px] border-black transition-all hover:bg-[#f8f8f8]"
                  onClick={handleCancelEditMessage}
                >
                  Cancel
                </button>
              )}

              {chatInputValue?.length ? (
                <button
                  disabled={isMessageSentLoading}
                  className={`${
                    isMessageSentLoading ? "opacity-60" : "opacity-100"
                  } bg-primary-main px-3 py-[0.4rem] rounded font-semibold text-white`}
                  onClick={() => {
                    onSubmitChat();
                    setReplyMessage("");
                    setReplyMessageUserName("");
                  }}
                >
                  {isMessageEditState ? "Save" : <IoSend size={18} />}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketChat;
