/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useAddRequirementMutation } from "src/services/RequirementService";
import { object, string, array, boolean } from "yup";
import RequirementForm from "../Layouts/RequirementForm";
import { showToast } from "src/utils/showToaster";
import { RequirementFormValues } from "src/models/Requirement.model";
import { useNavigate } from "react-router-dom";

type Props = {
  moduleItems?: any;
};
const featureType = [
  {
    label: "Other",
    value: "OTHER",
  },
  {
    label: "Table",
    value: "TABLE",
  },
  {
    label: "Form",
    value: "FORM",
  },
  {
    label: "View",
    value: "VIEW",
  },
];

const fieldType = [
  {
    label: "Select",
    value: "SELECT",
  },
  {
    label: "Multi-Select",
    value: "MULTISELECT",
  },
  {
    label: "Text-Field",
    value: "TEXTFIELD",
  },
  {
    label: "Check-box",
    value: "CHECKBOX",
  },
  {
    label: "Radio",
    value: "RADIO",
  },
  {
    label: "Switch",
    value: "SWITCH",
  },
  {
    label: "HTML-Editor",
    value: "HTMLEDITOR",
  },
  {
    label: "TextArea",
    value: "TEXTAREA",
  },
  {
    label: "File Uploader",
    value: "FILEUPLOADER",
  },
];

const AddRequirementFormWrapper = ({ moduleItems }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const navigate = useNavigate();
  const [AddRequirementFormWrapper] = useAddRequirementMutation();

  // Form Initial Values
  const initialValues: RequirementFormValues = {
    projectId: "",
    moduleId: "",
    featureName: "",
    tableName: "",
    requirementFlow: "",
    requirementLogic: "",
    featureType: {
      label: "Other",
      value: "OTHER",
    },
    formFields: [
      {
        name: "",
        type: "",
        validation: "",
        logic: "",
        dataSource: "",
        isRequired: false,
      },
    ],
    tableFields: [
      {
        columnName: "",
      },
    ],
    columns: [
      {
        columnActions: "",
      },
    ],
    table: [
      {
        tableAction: "",
      },
    ],
    viewFields: [
      {
        tabs: "",
        detailsVisible: "",
        logic: "",
      },
    ],
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select project name"),
    moduleId: object().required("Please select module name"),
    featureName: string().required("Please enter feature name"),
    featureType: object().required("Please select feature type"),
    // formFields: array().of(
    //   object().shape({
    //     name: string().test({
    //       name: "name",
    //       test: function (value, context) {
    //         const { featureType } = context.parent;
    //         if (featureType === "FORM" && value === "") {
    //           return this.createError({
    //             message: "Name is required",
    //             path: "name",
    //           });
    //         }
    //         return true;
    //       },
    //     }),
    //   })
    // ),

    // fee_paid: string()
    //   .required("Amount is required")
    //   .test("fee_paid", "Amount must not be greater than amount due", function (
    //     value,
    //     context
    //   ) {
    //     const { amount_due } = context.parent;
    //     if (Number(value) > Number(amount_due)) {
    //       return false;
    //     }
    //     return true;
    //   }),
  });

  // Handle Submit
  const handleSubmit = (
    values: RequirementFormValues,
    { setSubmitting, resetForm }: FormikHelpers<RequirementFormValues>
  ) => {
    const { projectId, moduleId, featureType, formFields, ...rest } = values;
    const formattedValues = {
      ...rest,
      projectId: projectId?._id,
      moduleId: moduleId?.value,
      featureType: featureType?.value,
      formFields: formFields?.map((formField: any) => ({
        name: formField?.name,
        type: formField?.type?.value,
        validation: formField?.validation,
        logic: formField?.logic,
        dataSource: formField?.dataSource,
        isRequired: formField?.isRequired,
      })),
    };

    AddRequirementFormWrapper(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (saveNextChecked) {
            // Reset specific fields
            resetForm({
              values: {
                ...initialValues,
                projectId: projectId,
                moduleId: moduleId,
                // Add other fields you want to reset here
              },
            });
          } else {
            navigate("/requirement");
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <RequirementForm
            formikProps={formikProps}
            featureType={featureType}
            fieldType={fieldType}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddRequirementFormWrapper;
