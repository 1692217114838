import React from 'react'
import TaskNotificationCard from './TaskNotificationCard';

type TaskNotificationCardPropType = {

    taskData: any
}
const TaskNotificationCardWrapper = ({  taskData }: TaskNotificationCardPropType) => {

    return (
        <div className='child-menu'>
            <TaskNotificationCard taskNotificationList={taskData} />
        </div>
    )
}

export default TaskNotificationCardWrapper
