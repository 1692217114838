import { convertDateInDateMonNameFormat } from "src/utils/common/dateFormat";

type Props = {
  leaveStartDate: string;
  reportingDate: string;
  noOfDays: number;
};

const ViewOneAndMultipleDayLeaveRequest = ({
  leaveStartDate,
  reportingDate,
  noOfDays,
}: Props) => {
  return (
    <div className="grid grid-cols-12 gap-6 ">
      <div className="col-span-3">
        <p className="font-medium text-sm">Leave Start Date</p>
        <div className="border rounded-md text-sm bg-white p-2">
          {convertDateInDateMonNameFormat(leaveStartDate)}
        </div>
      </div>
      <div className="col-span-3">
        <p className="font-medium text-sm">Reporting Date</p>
        <div className="border rounded-md text-sm bg-white p-2">
          {convertDateInDateMonNameFormat(reportingDate)}
        </div>
      </div>
      <div className="col-span-3">
        <p className="font-medium text-sm">Absent Duration</p>
        <div className="border rounded-md text-sm bg-white p-2">
          {noOfDays + (noOfDays > 1 ? " days" : " day")}
        </div>
      </div>
    </div>
  );
};

export default ViewOneAndMultipleDayLeaveRequest;
