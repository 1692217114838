import React, { useEffect, useState } from "react";
import ProjectResourceRequestList from "./ProjectResourceRequestList";
import { useGetResourceByClientIdQuery, useGetResourceByProjectIdQuery } from "src/services/ResourceRequestService";
import { useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const PlatformProjectResourceRequestListWrapper = () => {
  //State
  const [requestData, setRequestData] = useState<any[]>([]);

  const location = useLocation();
  const currentPath = location.pathname;

  const {id} = useParams()
  // Check if the current path starts with "/client"
  const isClientRoute = currentPath.startsWith('/client');
  const { clientId } = useParams()
  const { data, isLoading, isFetching } = useGetResourceByProjectIdQuery({
    limit: 10,
    searchValue: "",
    params: [
      "projectId",
    ],
    page: 1,
    filterBy: [
      {
        fieldName : "projectId",
        value : id
      }
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  },{
      skip: isClientRoute,
    }
  );

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setRequestData(data?.data);
    }
  }, [isFetching, isLoading, data]);


  const { isLoading: clientIsLoading, isFetching: clientIsFetching, data: clientData } = useGetResourceByClientIdQuery(
    clientId || "",
    {
      skip: !clientId && !isClientRoute,
    }
  );

  useEffect(() => {
    if (!clientIsLoading && !clientIsFetching) {
      setRequestData(clientData?.data || []);
    }
  }, [clientIsLoading, clientIsFetching, clientData])
  return (
    <>
      {(isLoading || isFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <ProjectResourceRequestList requestData={requestData} projectId={id} />
    </>

  )
};

export default PlatformProjectResourceRequestListWrapper;
