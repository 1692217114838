import React, { useEffect, useState } from "react";
import IncidentListing from "./IncidentListing";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/IncidentSlice";
import AddIncidentFormWrapper from "../Add/AddIncidentFormWrapper";
import { useAllPointerQuery } from "src/services/IncidentService";
import moment from "moment";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdEdit } from "react-icons/md";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import EditIncidentFormWrapper from "../Edit/EditIncidentFormWrapper";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import PointerLogsFlowDialogWrapper from "./PointerLogsFlowDialog/PointerLogsFlowDialogWrapper";

type Props = {};
const IncidentListingWrapper = (props: Props) => {
  const dispatch = useDispatch();

  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);
  const [isOpenLogsFlowDialog, setIsOpenLogsFlowDialog] = useState(false);
  const [pointerId, setPointerId] = useState<any>("");
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    dateFilter,
    categoryId,
    memberId,
  } = useSelector((state: RootState) => state.incident);

  const { data, isLoading, isFetching } = useAllPointerQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["typeName", "categoryName"],
    page: page,
    filterBy: [
      {
        fieldName: "categoryId",
        value: categoryId?.value,
      },
      {
        fieldName: "teamMembers.memberId",
        value: memberId?.value,
      },
    ],
    dateFilter: {
      // dateFilterKey: "addedOnDate",
      startDate: dateFilter?.startDate ? dateFilter?.startDate : "",
      endDate: dateFilter?.endDate ? dateFilter?.endDate : "",
    },
    orderBy: "addedOnDate",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "CREATE AT DATE",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize max-w-[150px]",
      name: UserModuleNameTypes.ALL_INCIDENT_LIST_CREATE_ON_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {row.createdAt
              ? formatedDateTimeIntoIst(row.createdAt, "DD MMM yyyy")
              : "-"}
          </div>
          <div className="text-[13px] font-medium text-slate-400">
            {formatedDateTimeIntoIst(row.createdAt, "hh:mm A")}
          </div>
        </div>
      ),
    },
    {
      field: "addedOnDate",
      name: UserModuleNameTypes.ALL_INCIDENT_LIST_ADDED_ON_DATE,
      headerName: "Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[150px]",
      renderCell: (row: any) => (
        <div className="text-[13px] text-slate-700 font-medium">
          {row?.addedOnDate
            ? moment(row?.addedOnDate).format("DD MMM YYYY")
            : ""}
        </div>
      ),
    },
    {
      field: "categoryName",
      name: UserModuleNameTypes.ALL_INCIDENT_LIST_CATEGORY_NAME,
      headerName: "category Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "typeName",
      name: UserModuleNameTypes.ALL_INCIDENT_LIST_TYPE_NAME,
      headerName: "type Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "teamMembers",
      name: UserModuleNameTypes.ALL_INCIDENT_LIST_ASSIGN_TO,
      headerName: "Assign To",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[300px] max-h-[80px] overflow-scroll",
      renderCell: (row: any) => (
        <div className="grid grid-cols-1 gap-1">
          {row?.teamMembers?.length
            ? row?.teamMembers?.map((el: any, ind: any) => {
                return (
                  <div className="font-medium text-[12px]">{`${el?.memberName} ${
                    ind !== row?.teamMembers?.length - 1 ? "," : ""
                  }`}</div>
                );
              })
            : "-"}
        </div>
      ),
    },
    {
      field: "remark",
      name: UserModuleNameTypes.ALL_INCIDENT_LIST_REMARK,
      headerName: "remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-h-[100px] overflow-scroll",
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditForm(true);
                  setPointerId(row?._id);
                },
                acitonName:
                  UserModuleNameTypes.ACTION_INCIDENT_ALL_INCIDENT_EDIT,
              },
            ]}
          />
        </div>
      ),
    },
    {
      field: "Flow",
      name: UserModuleNameTypes.ALL_INCIDENT_LIST_FLOW,
      headerName: "flow",
      align: "center",
      flex: "flex-[1_1_0%]",
      renderCell: (row: any) => (
        <div>
          <ATMLoadingButton
            onClick={() => {
              setIsOpenLogsFlowDialog(true);
              setPointerId(row?._id);
            }}
            className="w-[65px] h-8"
          >
            Flow
          </ATMLoadingButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <IncidentListing
        columns={columns}
        onAddNew={() => {
          setIsOpenAddForm(true);
        }}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setRowsPerPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenAddForm && (
        <AddIncidentFormWrapper
          onClose={() => {
            setIsOpenAddForm(false);
          }}
        />
      )}
      {isOpenEditForm && (
        <EditIncidentFormWrapper
          onClose={() => {
            setIsOpenEditForm(false);
          }}
          pointerId={pointerId}
        />
      )}
      {isOpenLogsFlowDialog && (
        <PointerLogsFlowDialogWrapper
          pointerId={pointerId}
          onClose={() => setIsOpenLogsFlowDialog(false)}
        />
      )}
    </div>
  );
};

export default IncidentListingWrapper;
