import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useGetBaseCurrencyQuery } from "src/services/CurrencyServices";
import { showToast } from "src/utils/showToaster";
import { boolean, object, string } from "yup";
import IncomeFormLayout from "../Layout/IncomeFormLayout";
import { useAddIncomeMutation } from "src/services/Finance-New-Services/IncomeService";
import { IncomeFormProps } from "src/models/Income.model";

const AddIncomeFormWrapper = () => {
  const navigate = useNavigate();
  const [addIncome] = useAddIncomeMutation();
  const { data } = useGetBaseCurrencyQuery("");
  // Form Initial Values
  const initialValues: IncomeFormProps = {
    incomeDate: null,
    amount: "",
    gstApplicable: false,
    gstPercentage: "",
    tdsAmount: "",
    currency: data?.data,
    status: "UNPAID",
    category: "",
    dueDate: null,
    paymentDate: null,
    conversionLoss: "",
    account: "",
    mode: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    incomeDate: string().required("Please select income date"),
    category: object().required("Please select category "),
    amount: string().required("Please enter amount"),
    gstApplicable: boolean().required("Please enter GST applicability"),

    gstPercentage: string().test(
      "test-gstPercentage",
      "Please enter gst percentage ",
      (value, context) => {
        if (context?.parent?.gstApplicable) {
          if (!value) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }
    ),
    tdsAmount: string(),
    status: string()
      .oneOf(["PAID", "UNPAID"])
      .required("Please select status "),
    dueDate: string()
      .test("test-dueDate", "Please select due date", (value, context) => {
        if (context?.parent?.status === "UNPAID") {
          if (!value) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      })
      .nullable(),
    paymentDate: string()
      .test(
        "test-paymentDate",
        "Please select payment date",
        (value, context) => {
          if (context?.parent?.status === "PAID") {
            if (!value) {
              return false;
            } else {
              return true;
            }
          }
          return true;
        }
      )
      .nullable(),
    conversionLoss: string(),
    account: object().test(
      "test-account",
      "Please select account",
      (value, context) => {
        if (context?.parent?.status === "PAID") {
          if (!value) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }
    ),
    mode: object().test("test-mode", "Please select mode", (value, context) => {
      if (context?.parent?.status === "PAID") {
        if (!value) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    }),
    currency: object().required("Please select currency "),
  });

  // Handle Submit
  const handleSubmit = (
    values: IncomeFormProps,
    { setSubmitting, resetForm }: FormikHelpers<IncomeFormProps>
  ) => {
    const formattedValues = {
      incomeDate: moment(values?.incomeDate).format("YYYY-MM-DD"),
      amount: values?.amount,
      gstApplicable: values?.gstApplicable,
      gstPercentage: values?.gstPercentage || 0,
      tdsAmount: values?.tdsAmount,
      currencyId: values?.currency?._id || data?.data?._id,
      status: values?.status,
      financeCategoryId: values?.category?.value,
      dueDate: moment(values?.dueDate).format("YYYY-MM-DD"),
      paymentDate: moment(values?.paymentDate).format("YYYY-MM-DD"),
      conversionLoss: values?.conversionLoss || 0,
      accountId: values?.account?.value,
      paymentModeId: values?.mode?.value,
    };
    addIncome(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate("/finance/income-new");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <IncomeFormLayout
            formType="ADD"
            formikProps={formikProps}
            onClose={() => navigate("/finance/income-new")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddIncomeFormWrapper;
