import { useEffect, useState } from "react";
import ATMSelect from "../../atoms/formFields/ATMSelect/ATMSelect";
import { useGetAllUserQuery } from "src/services/UserService";

type Props = {
  name?: string;
  value: any;
  onChange: (newValue: any) => void;
  onBlur?: (e: any) => void;
  label?: string;
  placeholder?: string;
  required?:boolean ;
};

const MOLUserDropdown = ({
  name = "",
  value,
  onChange,
  required = false,
  onBlur,
  label = "User",
  placeholder = "Select User",
}: Props) => {
  const [userOptions, setUserOptions] = useState([]);

  const {
    data: users,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useGetAllUserQuery("");

  // Setting Team Members Data
  useEffect(() => {
    if (!isUserLoading && !isUserFetching) {
      setUserOptions(users?.data || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, isUserLoading, isUserFetching]);

  return (
    <ATMSelect
    required={required}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      options={userOptions}
      isLoading={isUserLoading || isUserFetching}
      renderOption={(option, { context }) => {
        if (context === "menu") {
          return <div className="capitalize"> {option.name} </div>;
        } else {
          return <div className="capitalize"> {option.name} </div>;
        }
      }}
      isOptionEqualToValue={(option, selected) => {
        return (
          selected?.findIndex(
            (selectedValue: any) => selectedValue?._id === option._id
          ) > -1
        );
      }}
      isSearchedOption={(option, searchValue) => {
        return searchValue
          ? option?.data?.name?.toLowerCase()?.includes(searchValue)
          : true;
      }}
    />
  );
};

export default MOLUserDropdown;
