import React, { useEffect, useState } from "react";
import IncomeListing from "./IncomeListing";
import { useIncomeListQuery } from "src/services/Finance-New-Services/IncomeService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/Finance-New-Slices/IncomeSlice";
import moment from "moment";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import PaymentInFormWrapper from "../Component/PaymentInFormWrapper";
type Props = {};

const IncomeListingWrapper = (props: Props) => {
  const [isOpenPaymentInDialog, setIsOpenPaymentInDialog] = useState(false);
  const [paymentInId, setPaymentInId] = useState("");
  const dispatch = useDispatch();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    statusFilter,
    categoryFilter,
    // dateFilter,
    // gstApplicableFilter,
  } = useSelector((state: RootState) => state.income);
  const {
    data: incomeList,
    isFetching,
    isLoading,
  } = useIncomeListQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["status", "financeCategoryName", "accountName", "paymentModeName"],
    page: page,
    filterBy: [
      {
        fieldName: "status",
        value: statusFilter?.map((ele: any) => ele?.value) || [],
      },
      {
        fieldName: "financeCategoryName",
        value: categoryFilter?.map((ele: any) => ele?.label) || [],
      },
      // {
      //   fieldName: "gstApplicable",
      //   value: gstApplicableFilter?.map((ele: any) => ele?.value) || [],
      // },
    ],
    // dateFilter: dateFilter,
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });
  const StatusColumn = ({ row }: { row: any }) => {
    switch (row?.status) {
      case "PAID":
        return (
          <div>
            <div className="font-medium text-green-600"> Paid </div>
            <div className="text-xs italic text-slate-600">
              on {moment(row?.paymentDate).format("DD MMM YYYY")}
            </div>
          </div>
        );
      case "UNPAID":
        return (
          <div>
            <div className="font-medium text-red-600"> Unpaid </div>
            <div className="text-xs italic text-slate-600">
              due on {moment(row?.dueDate).format("DD MMM YYYY")}
            </div>
          </div>
        );

      default:
        return <></>;
    }
  };

  const columns = [
    {
      field: "incomeDate",
      headerName: "Income Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>{moment(row?.incomeDate).format("DD MMM YYYY")}</div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount (Incl. TDS)",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          <div>
            {" "}
            {row?.currencySymbol}
            {row?.amount}
          </div>
          <div className="text-xs italic text-slate-600">
            TDS : {row?.currencySymbol}
            {row?.tdsAmount}
          </div>
        </div>
      ),
    },
    {
      field: "gstPercentage",
      headerName: "GST",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.gstApplicable ? (
            <>
              {" "}
              {row?.currencySymbol}{" "}
              {((row?.amount - row?.tdsAmount) * row?.gstPercentage) / 100} (
              {row?.gstPercentage}%)
            </>
          ) : (
            "-  "
          )}
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <StatusColumn row={row} />,
    },
    {
      field: "account",
      headerName: "Account",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.accountName || "-"}</div>,
    },
    {
      field: "paymentMode",
      headerName: "Mode",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.paymentModeName || "-"}</div>,
    },
    {
      field: "conversionLoss",
      headerName: "Conversion Loss",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) =>
        row?.conversionLoss ? <div>&#8377; {row?.conversionLoss}</div> : "-",
    },
    {
      field: "category",
      headerName: "Category",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.financeCategoryName || "-"}</div>,
    },
    {
      field: "action",
      headerName: "Action",
      flex: "flex-[1_1_0%]",
      renderCell: (row: any) => (
        <div>
          {row?.status === "UNPAID" && (
            <ATMLoadingButton
              onClick={() => {
                setIsOpenPaymentInDialog(true);
                setPaymentInId(row?._id);
              }}
              className="text-xs"
            >
              Payment-In
            </ATMLoadingButton>
          )}
        </div>
      ),
    },
  ];
  // Setting Team Members Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(incomeList?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(incomeList?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeList, isLoading, isFetching]);

  return (
    <>
      <IncomeListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenPaymentInDialog && (
        <PaymentInFormWrapper
          onClose={() => setIsOpenPaymentInDialog(false)}
          paymentId={paymentInId}
        />
      )}
    </>
  );
};

export default IncomeListingWrapper;
