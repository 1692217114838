import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useGetBaseCurrencyQuery } from "src/services/CurrencyServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import FinanceManagementForm from "../Layout/AccountForm";
import { AccountFormValues } from "src/models/Account.model";
import { useAddFinanceAccountsMutation } from "src/services/Finance-New-Services/AccountServices";

type Props = {
  onClose: () => void;
};

const AddFinanceManagementWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addFinanceAccount] = useAddFinanceAccountsMutation();
  const { data } = useGetBaseCurrencyQuery("");

  // Form Initial Values
  const initialValues: AccountFormValues = {
    accountName: "",
    accountType: "",
    description: "",
    openinBalance: "",
    currency: data?.data,
  };

  // Validation Schema
  const validationSchema = object().shape({
    openinBalance: string().required("Please enter opening balance"),
    accountName: string().required("Please enter account name"),
    accountType: object().required("Please select account type"),
  });

  // Handle Submit
  const handleSubmit = (
    values: AccountFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AccountFormValues>
  ) => {
    const formattedValues = {
      accountName: values?.accountName,
      accountType: values?.accountType?.value,
      description: values?.description,
      openingBalance: values?.openinBalance,
    };

    addFinanceAccount(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <FinanceManagementForm
              formType="ADD"
              setSaveNextChecked={setSaveNextChecked}
              saveNextChecked={saveNextChecked}
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddFinanceManagementWrapper;
