import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import { MarkAsUnhandledDialogWrapperValues } from "./MarkAsUnhandledDialogWrapper";

type Props = {
  formikProps: FormikProps<MarkAsUnhandledDialogWrapperValues>;
  onClose: () => void;
};

const MarkAsUnhandledDialog = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">Mark As Unhandled</div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="">
          <ATMTextArea
            required
            name="unhandledReason"
            value={values?.unhandledReason}
            onChange={(e) => setFieldValue("unhandledReason", e)}
            label="Remark"
            placeholder="Enter Remark"
            className="rounded"
          />
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default MarkAsUnhandledDialog;
