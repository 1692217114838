import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import useDepartment from "src/hooks/useDepartment";
import useSkills from "src/hooks/useSkills";
import { JobCreationFormProps } from "src/models/JobCreation.model";
import { handleValidNumber } from "src/utils/validations/numberInput";
interface typeOption {
  value: string;
  label: string;
}

const employmentTypeOptions: typeOption[] = [
  { value: "Full-Time", label: "Full-Time" },
  { value: "Part-Time", label: "Part-Time" },
  { value: "Internship", label: "Internship" },
  { value: "Contract", label: "Contract" },
  { value: "Freelance", label: "Freelance" },
];

const joiningAvailabilityOptions: typeOption[] = [
  { value: "Immediate Joiner", label: "Immediate Joiner" },
  { value: "Serving Notice Period", label: "Serving Notice Period" },
];

const workModeOptions: typeOption[] = [
  { value: "REMOTE", label: "REMOTE" },
  { value: "ONSITE", label: "ONSITE" },
  { value: "HYBRID", label: "HYBRID" },
];

const experienceLevelOption: typeOption[] = [
  { value: "0-1 years: Entry Level", label: "0-1 years: Entry Level " },
  { value: "1-3 years: Junior Level", label: "1-3 years: Junior Level" },
  { value: "3-5 years: Mid Level", label: "3-5 years: Mid Level" },
  { value: "5-7 years: Senior Level", label: "5-7 years: Senior Level" },
  { value: "7-10 years: Lead Level", label: "7-10 years: Lead Level" },
  { value: "10-15 years: Expert Level", label: "10-15 years: Expert Level" },
  { value: "15+ years: Veteran Level", label: "15+ years: Veteran Level" },
];


type Props = {
  formikProps: FormikProps<JobCreationFormProps>;
  onClose: any;
  formType: "ADD" | "EDIT";
};
const JobCreationFormLayout = ({
  formType = "ADD",
  formikProps,
  onClose,
}: Props) => {

  const formHeading =
    formType === "ADD" ? "Add Job Creation" : "Update Job Creation";
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const { department, isDataLoading } = useDepartment();
  const { skills, isDataLoading: isSkillsLoading } = useSkills();

  const departmentOptions = department?.map((depart) => {
    return {
      label: depart?.departmentName,
      value: depart?._id,
    };
  });

  const skillOptions = skills?.map((el) => {
    return {
      label: el?.skillName,
      value: el?._id,
    };
  });

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center bg-white sticky top-0 z-10 py-1">
        <div className="text-xl font-medium"> {formHeading} </div>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-8">
            <ATMTextField
              required
              name="jobTitle"
              label="Job Title"
              placeholder="Enter Job Title"
              value={values.jobTitle}
              onChange={(e) => setFieldValue('jobTitle', e.target.value)}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-span-4">
            <ATMTextField
              name="jobLocation"
              label="Job Location"
              placeholder="Enter Job Location"
              value={values.jobLocation}
              onChange={(e) => setFieldValue('jobLocation', e.target.value)}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <ATMTextField
            name="numberOfVacancies"
            label="Number of Vacancies"
            placeholder="Enter Number of Vacancies"
            value={values.numberOfVacancies}
            onChange={(e) => handleValidNumber(e) && setFieldValue('numberOfVacancies', (e.target.value))}
          />
          <ATMSelect
            name="employmentType"
            label="Employment Type"
            placeholder="Select Employment Type"
            value={values.employmentType}
            options={employmentTypeOptions}
            onChange={(newValue) => {
              setFieldValue("employmentType", newValue);
            }}
          />

          <ATMSelect
            name="experienceLevel"
            label="Experience Level"
            placeholder="Select Experience Level"
            value={values.experienceLevel}
            options={experienceLevelOption}
            onChange={(newValue) => {
              setFieldValue("experienceLevel", newValue);
            }}
          />

          <div>
            <ATMMultiSelect
              name="primarySkills"
              value={values.primarySkills}
              onChange={(newValue) => setFieldValue("primarySkills", newValue)}
              onBlur={handleBlur}
              label="Primary Skills"
              placeholder="Select Primary Skills"
              options={skillOptions}
              isLoading={isSkillsLoading}
            />
          </div>

          <div>
            <ATMMultiSelect
              name="secondarySkills"
              value={values.secondarySkills}
              onChange={(newValue) => setFieldValue("secondarySkills", newValue)}
              onBlur={handleBlur}
              label="Secondary Skills"
              placeholder="Select Secondary Skills"
              options={skillOptions}
              isLoading={isSkillsLoading}
            />
          </div>

          <ATMSelect
            name="immediateJoiner"
            label="Immediate Joiner"
            placeholder="Select Immediate Joiner"
            value={values.immediateJoiner}
            options={joiningAvailabilityOptions}
            onChange={(newValue) => {
              setFieldValue("immediateJoiner", newValue);
            }}
          />

          <ATMDatePicker
            name="applicationDeadline"
            label="Application Deadline"
            value={values.applicationDeadline}
            onChange={(date) => setFieldValue('applicationDeadline', date)}
          />
          <ATMDatePicker
            name="postingDate"
            label="Posting Date"
            value={values.postingDate}
            onChange={(date) => setFieldValue('postingDate', date)}
          />
          <ATMDatePicker
            name="onBoardingBeforeDate"
            label="Onboarding Before Date"
            value={values.onBoardingBeforeDate}
            onChange={(date) => setFieldValue('onBoardingBeforeDate', date)}
          />

          <ATMTextField
            name="minExperience"
            label="Minimum Experience"
            placeholder="Enter Minimum Experience"
            value={values.minExperience}
            onChange={(e) => setFieldValue('minExperience', (e.target.value))}
            onBlur={handleBlur}
          />
          <ATMTextField
            name="maxExperience"
            label="Maximum Experience"
            placeholder="Enter Maximum Experience"
            value={values.maxExperience}
            onChange={(e) => setFieldValue('maxExperience', (e.target.value))}
            onBlur={handleBlur}
          />
          <ATMTextField
            name="maximumPackageLimit"
            label="Maximum Package Limit"
            placeholder="Enter Maximum Package Limit"
            value={values.maximumPackageLimit}
            onChange={(e) => handleValidNumber(e) && setFieldValue('maximumPackageLimit', e.target.value)}
            onBlur={handleBlur}
          />
        </div>
        <div className="grid grid-cols-3 gap-4">

          <ATMSelect
            name="workMode"
            label="Work Mode"
            placeholder="Select Work Mode"
            value={values.workMode}
            options={workModeOptions}
            onChange={(newValue) => {
              setFieldValue("workMode", newValue);
            }}
          />
          <ATMTextField
            name="salaryRange"
            label="Salary Range"
            placeholder="Enter Salary Range"
            value={values.salaryRange}
            onChange={(e) => setFieldValue('salaryRange', (e.target.value))}
            onBlur={handleBlur}
          />
          <ATMSelect
            name="department"
            label="Department"
            placeholder="Select Department"
            isLoading={isDataLoading}
            value={values.department}
            options={departmentOptions}
            onChange={(newValue) => {
              setFieldValue("department", newValue);
            }}
          />
        </div>

        {/* Description */}
        <div className="z-0">
          <MOLMarkdownEditor
            label=" Description"
            placeholder="Write description or drag your files here...."
            value={values?.jobDescription}
            onChange={(e: any) => {
              setFieldValue("jobDescription", e);
            }}
          />
        </div>
        {/* Description */}
        <div className="z-0">
          <MOLMarkdownEditor
            label=" Job Requirements"
            placeholder="Write Job Requirements or drag your files here...."
            value={values?.jobRequirements}
            onChange={(e: any) => {
              setFieldValue("jobRequirements", e);
            }}
          />
        </div>

        <MOLMarkdownEditor
          label="Educational Requirements"
          placeholder="Write Educational Requirements or drag your files here...."
          value={values?.educationalRequirements}
          onChange={(e: any) => {
            setFieldValue("educationalRequirements", e);
          }}
        />

        <MOLMarkdownEditor
          label="Benefits"
          placeholder="Write Benefits or drag your files here...."
          value={values?.benefits}
          onChange={(e: any) => {
            setFieldValue("benefits", e);
          }}
        />
      </div>
      <div className="flex justify-end py-1 bg-white sticky bottom-0 z-50">
        <ATMLoadingButton
          isLoading={isSubmitting}
          type="submit">
          {formType === "ADD" ? "Save" : "Update"}
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default JobCreationFormLayout;
