import { FormikProps } from "formik";
import ATMAmountField from "src/components/UI/atoms/ATMAmountField/ATMAmountField";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { AccountFormValues } from "src/models/Account.model";
import { handleValidWithDotNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<AccountFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};
const accountTyoeOption = [
  {
    label: "Bank",
    value: "BANK",
  },
  {
    label: "Cash",
    value: "CASH",
  },
  {
    label: "Crypto",
    value: "CRYPTO",
  },
];

const FinanceManagementForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const formHeading = formType === "ADD" ? "Add Account" : "Update Account";
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="flex flex-col gap-6 p-3">
      <div className="flex items-center justify-between">
        <div className="text-xl font-medium"> {formHeading} </div>
        <button
          type="button"
          className="p-2 rounded hover:bg-hover"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Account Name */}
        <div>
          <ATMTextField
            required
            name="accountName"
            value={values.accountName}
            onChange={(e) => setFieldValue("accountName", e.target.value)}
            label="Account Name"
            placeholder="Enter Account Name"
            onBlur={handleBlur}
          />
        </div>

        <div>
          <ATMSelect
            required
            name="accountType"
            label="Account Type"
            placeholder="Select Account Type"
            value={values?.accountType}
            options={accountTyoeOption}
            onChange={(newValue) => {
              setFieldValue("accountType", newValue);
            }}
          />
        </div>
        {/* Mobile Number */}
        {formType === "ADD" && (
          <div>
            <ATMAmountField
              required
              name="openinBalance"
              value={values.openinBalance}
              onChange={(e) =>
                handleValidWithDotNumber(e) &&
                setFieldValue("openinBalance", e.target.value)
              }
              onCurrencyChange={(newValue) => {
                setFieldValue("currency", newValue);
              }}
              currencyValue={values.currency}
              label="Opening Balance"
              placeholder="Enter Opening Balance"
              onBlur={handleBlur}
            />
          </div>
        )}
        <div>
          <ATMTextArea
            name="description"
            value={values.description}
            onChange={(e) => setFieldValue("description", e)}
            label="Description"
            placeholder="Enter Description"
          />
        </div>
        {formType === "ADD" && (
          <div className="">
            <ATMCheckbox
              label="Save and Next"
              checked={saveNextChecked}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        )}

        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default FinanceManagementForm;
