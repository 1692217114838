import { useState, useEffect } from "react";
import { useGetAllUserQuery } from "src/services/UserService";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";

export const useGetAllCodioticUsers = () => {
  const [codioticUsers, setCodioticUsers] = useState<
    CodioticUserListResponse[]
  >([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllUserQuery("");

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setCodioticUsers(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { codioticUsers, isDataLoading };
};
