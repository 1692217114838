import { FormikProps } from "formik";
import moment from "moment";
import ATMAmountField from "src/components/UI/atoms/ATMAmountField/ATMAmountField";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMSwitchButton from "src/components/UI/atoms/formFields/ATMSwitchButton/ATMSwitchButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { IncomeFormProps } from "src/models/Income.model";
import { useGetFinanceAccountQuery } from "src/services/Finance-New-Services/AccountServices";
import { useGetFinanceCategoryQuery } from "src/services/Finance-New-Services/FinanceCategroyService";
import { useGetAllPaymentModesQuery } from "src/services/Finance-New-Services/PaymentModeServices";
import {
  handleValidPercent,
  handleValidWithDotNumber,
} from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<IncomeFormProps>;
  onClose: any;
  formType: "ADD" | "EDIT";
};
const IncomeFormLayout = ({
  formType = "ADD",
  formikProps,
  onClose,
}: Props) => {
  const { data: getAccount, isLoading: isAccountLoading } =
    useGetFinanceAccountQuery("");
  const { data: getpaymentMode, isLoading: isPaymentModeLoading } =
    useGetAllPaymentModesQuery("");
  const { data: getCategory, isLoading: isCategoryLoading } =
    useGetFinanceCategoryQuery("");
  const formHeading = formType === "ADD" ? "Add Income" : "Update Income";
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  return (
    <div className="flex flex-col">
      <div className="py-2 border-b">
        <div className="flex items-center justify-between w-[70%] m-auto">
          <div className="text-xl font-medium"> {formHeading} </div>
          <div className="flex gap-2">
            <div>
              <ATMLoadingButton
                onClick={() => onClose()}
                type="button"
                className="text-black bg-white"
              >
                Cancel
              </ATMLoadingButton>
            </div>
            <div>
              <ATMLoadingButton isLoading={isSubmitting} type="submit">
                Save
              </ATMLoadingButton>
            </div>
          </div>
        </div>
      </div>
      <div className="grid items-center grid-cols-2 xl:grid-cols-3 gap-4 w-[70%] m-auto py-4">
        {/* Income Date */}
        <div>
          <ATMDatePicker
            required
            label="Income Date"
            name="incomeDate"
            format="DD/MM/YYYY"
            value={values.incomeDate}
            onChange={(newValue) => setFieldValue("incomeDate", newValue)}
          />
        </div>
        {/* Category */}
        <div>
          <ATMSelect
            name="category"
            label="Category"
            required
            placeholder="Select Category"
            value={values.category}
            options={getCategory?.data?.map((ele: any) => {
              return {
                label: ele?.categoryName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("category", newValue);
            }}
            isLoading={isCategoryLoading}
          />
        </div>
        {/* Amount (Incl. TDS) */}
        <div>
          <ATMAmountField
            required
            name="amount"
            value={values.amount}
            onChange={(e) =>
              handleValidWithDotNumber(e) &&
              setFieldValue("amount", e.target.value)
            }
            onCurrencyChange={(newValue) => {
              setFieldValue("currency", newValue);
            }}
            currencyValue={values.currency}
            label=" Amount (Incl. TDS) "
            placeholder="Enter  Amount (Incl. TDS) "
            onBlur={handleBlur}
          />
        </div>
        {/* TDS Amount */}
        <div>
          <ATMTextField
            name="tdsAmount"
            value={values.tdsAmount}
            onChange={(e) =>
              handleValidWithDotNumber(e, { acceptPercent: false }) &&
              setFieldValue("tdsAmount", e.target.value)
            }
            label="TDS Amount"
            placeholder="Enter TDS Amount"
            onBlur={handleBlur}
          />
        </div>
        {/* GST Applicable */}
        <div className="flex items-end justify-between">
          <ATMSwitchButton
            name="gstApplicable"
            value={values.gstApplicable}
            onChange={(newValue) => {
              if (!newValue) {
                setFieldValue("gstPercentage", "");
              }
              return setFieldValue("gstApplicable", newValue);
            }}
            label="GST Applicable"
          />
          {values?.amount && values?.tdsAmount && values?.gstPercentage ? (
            <div>
              {values?.currency?.currencySymbol}{" "}
              {((parseInt(values?.amount) - parseInt(values?.tdsAmount)) *
                (parseInt(values?.gstPercentage) + 100)) /
                100 +
                parseInt(values?.tdsAmount)}
            </div>
          ) : (
            <div>
              {" "}
              {values?.currency?.currencySymbol}{" "}
              {parseInt(values?.amount) - parseInt(values?.tdsAmount)}
            </div>
          )}
        </div>
        {/* GST Percentage */}
        {values?.gstApplicable ? (
          <div>
            <ATMTextField
              required
              name="gstPercentage"
              value={values.gstPercentage}
              onChange={(e) =>
                handleValidPercent(e) &&
                setFieldValue("gstPercentage", e.target.value)
              }
              label="GST Percentage"
              placeholder="Enter GST Percentage"
              onBlur={handleBlur}
            />
          </div>
        ) : null}
        {/* Status */}
        <div>
          <div>Status</div>
          <div className="flex items-center gap-2 text-center">
            <div
              className={`py-1 px-4 w-[100px] border rounded-lg cursor-pointer ${values?.status === "PAID" ? "bg-green-600 text-white" : ""}`}
              onClick={() => {
                setFieldValue("status", "PAID");
                setFieldValue("dueDate", null);
              }}
            >
              Paid
            </div>
            <div
              className={`py-1 px-4  w-[100px] border rounded-lg cursor-pointer ${values?.status === "UNPAID" ? "bg-red-600 text-white" : ""}`}
              onClick={() => {
                setFieldValue("status", "UNPAID");
                setFieldValue("paymentDate", null);
                setFieldValue("conversionLoss", "");
              }}
            >
              UnPaid
            </div>
          </div>
        </div>
        {/* Due Date */}
        {values?.status === "UNPAID" ? (
          <div>
            <ATMDatePicker
              required
              label="Due Date"
              name="dueDate"
              format="DD/MM/YYYY"
              minDate={moment().format("yyyy-MM-DD")}
              value={values.dueDate}
              onChange={(newValue) => setFieldValue("dueDate", newValue)}
            />
          </div>
        ) : null}
        {/* Payment Date */}
        {values?.status === "PAID" ? (
          <div>
            <ATMDatePicker
              required
              label="Payment Date"
              name="paymentDate"
              format="DD/MM/YYYY"
              maxDate={moment().format("yyyy-MM-DD")}
              value={values.paymentDate}
              onChange={(newValue) => setFieldValue("paymentDate", newValue)}
            />
          </div>
        ) : null}
        {/* Conversion Loss */}
        {values?.status === "PAID" ? (
          <div>
            <ATMTextField
              name="conversionLoss"
              value={values.conversionLoss}
              onChange={(e) =>
                handleValidWithDotNumber(e, { acceptNegative: true }) &&
                setFieldValue("conversionLoss", e.target.value)
              }
              label="Conversion Loss"
              placeholder="Enter Conversion Loss"
              onBlur={handleBlur}
            />
          </div>
        ) : null}
        {/* Account */}
        {values?.status === "PAID" ? (
          <div>
            <ATMSelect
              name="account"
              label="Account"
              required
              placeholder="Select Account"
              value={values.account}
              options={getAccount?.data?.map((ele: any) => {
                return {
                  label: ele?.accountName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("account", newValue);
              }}
              isLoading={isAccountLoading}
            />
          </div>
        ) : null}
        {/* Mode */}
        {values?.status === "PAID" ? (
          <div>
            <ATMSelect
              name="mode"
              label="Mode"
              required
              placeholder="Select Mode"
              value={values.mode}
              options={getpaymentMode?.data?.map((ele: any) => {
                return {
                  label: ele?.modeName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("mode", newValue);
              }}
              isLoading={isPaymentModeLoading}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default IncomeFormLayout;
