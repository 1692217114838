import apiSlice from "./ApiSlice";

export const GoalsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllGoals: builder.query({
      providesTags: ["goals"],
      query: () => {
        return {
          url: "/goal",
          method: "GET",
        };
      },
    }),
    // Get goal-s with pagination
    getGoals: builder.query({
      providesTags: ["goals"],
      query: (body) => {
        return {
          url: "/goal",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addGoals: builder.mutation({
      invalidatesTags: ["goals"],
      query: (body) => {
        return {
          url: "/goal/add",
          method: "POST",
          body,
        };
      },
    }),

    deleteGoals: builder.mutation({
      invalidatesTags: ["goals"],
      query: (id: any) => ({
        url: `/goal/${id}`,
        method: "DELETE",
      }),
    }),


    //Edit
    updateGoals: builder.mutation({
      invalidatesTags: ["goals"],
      query: ({ id, body }) => {
        return {
          url: `/goal/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get goal-s by ID
    getGoalsById: builder.query({
      providesTags: ["goals"],
      query: (id) => {
        return {
          url: `/goal/${id}`,
          method: "GET",
        };
      },
    }),
    // goal achive
    getGoalsAchivement: builder.query({
      providesTags: ["goals"],
      query: (body) => {
        return {
          url: "/goal-achieve/",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addAchivement: builder.mutation({
      invalidatesTags: ["goals"],
      query: (body) => {
        return {
          url: "/goal-achieve/add",
          method: "POST",
          body,
        };
      },
    }),
  }),
});
export const {
  useGetAllGoalsQuery,
  useGetGoalsQuery,
  useAddGoalsMutation,
  useUpdateGoalsMutation,
  useDeleteGoalsMutation,
  useGetGoalsByIdQuery,
  useAddAchivementMutation,
  useGetGoalsAchivementQuery
} = GoalsApi;
