import apiSlice from "../ApiSlice";

export const incomeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // income pagination
    incomeList: builder.query({
      providesTags: ["income-list"],
      query: (body) => {
        return {
          url: "/income",
          method: "POST",
          body,
        };
      },
    }),
    // income add
    addIncome: builder.mutation({
      invalidatesTags: ["income-list"],
      query: (body) => {
        return {
          url: "income/add",
          method: "POST",
          body,
        };
      },
    }),
    // income update
    updateIncome: builder.mutation({
      invalidatesTags: ["income-list", "cash-flow", "finance-management"],
      query: ({ body, id }) => {
        return {
          url: `income/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // payment-in update
    updatepaymentIn: builder.mutation({
      invalidatesTags: ["income-list", "cash-flow", "finance-management"],
      query: ({ body, id }) => {
        return {
          url: `income/payment-in/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // income get one
    getOneIncome: builder.query({
      query: (id) => {
        return {
          url: `income/${id}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useIncomeListQuery,
  useAddIncomeMutation,
  useUpdateIncomeMutation,
  useGetOneIncomeQuery,
  useUpdatepaymentInMutation,
} = incomeApi;
