import { Form, Formik, FormikHelpers } from "formik";
import { ChannelGroupFormValues } from "src/models/ChannelGroup.model";
import {
  useEditChannelGroupMutation,
  useGetChannelGroupByIdQuery,
} from "src/services/ChannelGroupService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import ChannelGroupForm from "../Layouts/ChannelGroupForm";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  groupId: string;
};

const EditChannelGroupFormWrapper = ({ onClose, groupId }: Props) => {
  const [editChannelGroup] = useEditChannelGroupMutation();
  const { data, isLoading, isFetching } = useGetChannelGroupByIdQuery(groupId);
  const [channelGroupInfo, setChannelGroupInfo] = useState<any>();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setChannelGroupInfo(data?.data);
    }
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: ChannelGroupFormValues = {
    channelGroupName: channelGroupInfo?.channelGroupName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    channelGroupName: string().required("Please enter channel group name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ChannelGroupFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ChannelGroupFormValues>
  ) => {
    editChannelGroup({
      id: groupId,
      body: values,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <ChannelGroupForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditChannelGroupFormWrapper;
