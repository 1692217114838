import React, { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import AttendanceDateFilterWrapper from "../AttendanceDateFilter/AttendanceDateFilterWrapper";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    dateFilter: any;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const AttendanceListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    dateFilter,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenFilterDialog, setIsOpenFilterDialog] = useState<boolean>(false);

  return (
    <>
      <div className="flex flex-col h-full ">
        {/* Page Header */}
        <div>
          <ATMPageHeader
            isFilter
            onFilterClick={() => setIsOpenFilterDialog(true)}
            hideSearchBox
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            hideAddButton
          />
        </div>

        {/* Table */}
        <div className="flex flex-col flex-1 overflow-auto">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "h-[50px]"}
            isLoading={isTableLoading}
          />
        </div>

        <div className="flex justify-end p-2 py-2 md:p-4">
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
      {isOpenFilterDialog && (
        <AttendanceDateFilterWrapper
          onClose={() => setIsOpenFilterDialog(false)}
        />
      )}
    </>
  );
};

export default AttendanceListing;
