import apiSlice from "./ApiSlice";

export const ContactServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //get All Contacts
    getAllContacts: builder.query({
      providesTags: ["contact"],
      query: (body) => ({
        url: "/contact",
        method: "POST",
        body,
      }),
    }),
    //ADD Contact
    addContact: builder.mutation({
      invalidatesTags: ["contact"],
      query: (body) => {
        return {
          url: "/contact/add",
          method: "POST",
          body,
        };
      },
    }),

    //GET Contact  BY ID
    getContactById: builder.query({
      providesTags: ["contact"],
      query: (id) => {
        return {
          url: `/contact/${id}`,
          method: "GET",
        };
      },
    }),

    //Contact UPDATE
    ContactUpdate: builder.mutation({
      invalidatesTags: ["contact"],
      query: ({ body, id }) => {
        return {
          url: `/contact/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //add Contact Leads
    addContactLeads: builder.mutation({
      invalidatesTags: ["contact"],
      query: (body) => {
        return {
          url: "/contact-lead/add",
          method: "POST",
          body,
        };
      },
    }),
    //get All Contacts Leads
    getAllContactsLeads: builder.query({
      providesTags: ["contact"],
      query: (body) => ({
        url: "/contact-lead",
        method: "POST",
        body,
      }),
    }),
    //GET Contact  Lead  BY ID
    getLeadById: builder.query({
      providesTags: ["contact"],
      query: (id) => {
        return {
          url: `/contact-lead/${id}`,
          method: "GET",
        };
      },
    }),
    //Contact UPDATE
    ContactLeadUpdate: builder.mutation({
      invalidatesTags: ["contact"],
      query: ({ body, id }) => {
        return {
          url: `/contact-lead/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //add Contact Lead Task
    addContactLeadTask: builder.mutation({
      invalidatesTags: ["contact"],
      query: (body) => {
        return {
          url: "lead-task/add",
          method: "POST",
          body,
        };
      },
    }),
    //Get  Contact Lead All  Task
    getContactLeadAllTask: builder.query({
      providesTags: ["contact"],
      query: (body) => {
        return {
          url: "lead-task",
          method: "POST",
          body,
        };
      },
    }),
     //add Contact Lead Note
     addContactLeadNote: builder.mutation({
      invalidatesTags: ["contact"],
      query: (body) => {
        return {
          url: "lead-note/add",
          method: "POST",
          body,
        };
      },
    }),
     //Get  Contact Lead All  Note
     getContactLeadAllNote: builder.query({
      providesTags: ["contact"],
      query: (body) => {
        return {
          url: "lead-note",
          method: "POST",
          body,
        };
      },
    }),
      // UPDATE Contact Lead Note
      updateContactLeadNote: builder.mutation({
        invalidatesTags: ["contact"],
        query: ({ body, id }) => {
          return {
            url: `/lead-note/${id}`,
            method: "PUT",
            body,
          };
        },
      }),
       //add Contact Lead Log
     addContactLeadLog: builder.mutation({
      invalidatesTags: ["contact"],
      query: (body) => {
        return {
          url: "contact-lead-log/add",
          method: "POST",
          body,
        };
      },
    }),
     //Get  Contact Lead All  Log
     getContactLeadAllLogs: builder.query({
      providesTags: ["contact"],
      query: (body) => {
        return {
          url: "contact-lead-log",
          method: "POST",
          body,
        };
      },
    }),
     // UPDATE Contact Lead Note
     updateContactLeadLogs: builder.mutation({
      invalidatesTags: ["contact"],
      query: ({ body, id }) => {
        return {
          url: `/contact-lead-log/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
     //GET Contact  Lead Actions  BY ID
     getLeadActions: builder.query({
      providesTags: ["contact"],
      query: (id) => {
        return {
          url: `/contact-lead/${id}/actions`,
          method: "GET",
        };
      },
    }),
      // Add Contact Lead Contact
      addLeadsContact: builder.mutation({
        invalidatesTags: ["contact"],
        query: ({ body, id }) => {
          return {
            url: `/contact-lead/${id}/add-contact`,
            method: "PUT",
            body,
          };
        },
      }),
  }),
});

export const {
  useGetAllContactsQuery,
  useAddContactMutation,
  useContactUpdateMutation,
  useGetContactByIdQuery,
  useAddContactLeadsMutation,
  useGetAllContactsLeadsQuery,
  useGetLeadByIdQuery,
  useContactLeadUpdateMutation,
  useAddContactLeadTaskMutation,
  useGetContactLeadAllTaskQuery,
  useAddContactLeadNoteMutation,
  useGetContactLeadAllNoteQuery,
  useUpdateContactLeadNoteMutation,
  useAddContactLeadLogMutation,
  useGetContactLeadAllLogsQuery,
  useUpdateContactLeadLogsMutation,
  useGetLeadActionsQuery,
  useAddLeadsContactMutation
} = ContactServiceApi;
