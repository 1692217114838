import React from "react";
import { CircularProgress } from "@mui/material";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetProjectWiseMembers } from "src/hooks/useGetProjectWiseMembers";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import { showToast } from "src/utils/showToaster";
import moment from "moment";
import AddCommentFormWrapper from "./AddCommentForm/AddCommentFormWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { LiaComments } from "react-icons/lia";
import { BsClock } from "react-icons/bs";
import TimerComponent from "src/screens/Doubts/List/Timer/Timer";

function formatCreatedAt(date: any) {
  const now = moment();
  const createdDate = moment(date);
  const diffInMinutes = now.diff(createdDate, "minutes");

  if (diffInMinutes < 1) {
    return "Just now";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? "minute" : "minutes"} ago`;
  } else if (diffInMinutes < 1440) {
    // 60 minutes * 24 hours = 1440 minutes
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
  } else if (diffInMinutes < 43200) {
    // 60 minutes * 24 hours * 30 days = 43200 minutes
    const diffInDays = Math.floor(diffInMinutes / 1440);
    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  } else {
    // If more than 30 days, you might want to show the actual date or a different format
    return createdDate.format("MMMM Do YYYY, h:mm:ss a");
  }
}

type Props = {
  singleDoubtData: any;
  isLoading: boolean;
  currentAssignee: any;
  onChangeAssignee: (value: any) => void;
  onMarkAsResolved: () => void;
  doubtsLogListing: any[];
};

const SingleDoubtView = ({
  singleDoubtData,
  isLoading,
  currentAssignee,
  onChangeAssignee,
  onMarkAsResolved,
  doubtsLogListing,
}: Props) => {
  const [userData, setuserData] = React.useState<any>();

  const { allProjectWiseMembers, isDataLoading } = useGetProjectWiseMembers(
    singleDoubtData?.projectId
  );
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleRedirect = () => {
    return state
      ? navigate(`${state?.pathname + state?.search}`)
      : navigate("/doubt/open");
  };

  React.useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setuserData(userData);
    } else {
      console.error("userData is not defined");
    }
  }, []);

  return (
    <div className="flex flex-col h-full">
      {isLoading && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}

      <div className="md:flex block md:px-4 px-2 py-2 items-center justify-between border-b">
        {/* back arrow, open, added by, assigned to */}
        <div className="md:flex block items-center md:gap-4 gap-2">
          <div className="flex justify-between items-center md:gap-4 gap-2">
            <p
              className="text-end flex items-center cursor-pointer"
              onClick={() => handleRedirect()}
            >
              <FaArrowLeft />
            </p>
            <div className="md:text-xs text-[10px] bg-green-500 h-fit w-fit px-2 py-1 text-white font-medium rounded-md">
              {singleDoubtData?.status}
            </div>
            <div className="flex items-center gap-2 text-sm font-semibold capitalize">
              {singleDoubtData?.createdByName}
            </div>
            <div className="md:hidden block">
              {singleDoubtData?.status === "OPEN" && (
                <div className="flex items-center gap-4">
                  <span className="flex rounded-full items-center gap-2 bg-teal-100 text-sm h-full px-4 p-2 font-medium">
                    <BsClock />
                    <TimerComponent createdAt={singleDoubtData?.createdAt} />
                  </span>
                  <ATMLoadingButton
                    onClick={() => {
                      if (userData?.userId === singleDoubtData?.createdById) {
                        onMarkAsResolved();
                      } else {
                        showToast("error", "only doubt creator can solve this doubt");
                      }
                    }}
                  >
                     Resolved
                  </ATMLoadingButton>
                </div>
              )}
            </div>
          </div>
          <div className="flex md:py-0 py-2 items-center justify-end gap-4 md:gap-0">
            <span className="text-xs text-neutral">asked to</span>
            {singleDoubtData?.status === "OPEN" ? (
              <ATMSelect
                name=""
                placeholder="Select User"
                value={currentAssignee}
                isLoading={isDataLoading}
                options={allProjectWiseMembers?.members?.map(
                  (ele: CodioticUserListResponse) => {
                    return {
                      label: ele?.name,
                      value: ele?._id,
                    };
                  }
                )}
                onChange={(newValue) => {
                  if (userData?.userId === newValue?.value) {
                    showToast(
                      "error",
                      "you can not select yourself for assignee"
                    );
                  } else {
                    onChangeAssignee(newValue);
                  }
                }}
              />
            ) : (
              <div className="flex items-center gap-2 text-sm font-semibold capitalize">
                {singleDoubtData?.assignedToUserName}
              </div>
            )}
          </div>
        </div>
        <div className="md:block hidden">
        {/* mark as resolved, asked since time*/}
        {singleDoubtData?.status === "OPEN" && (
          <div className="flex items-center gap-4">
            <span className="flex rounded-full items-center gap-2 bg-teal-100 text-sm h-full px-4 p-2 font-medium">
              <BsClock />
              <TimerComponent createdAt={singleDoubtData?.createdAt} />
            </span>
            <ATMLoadingButton
              onClick={() => {
                if (userData?.userId === singleDoubtData?.createdById) {
                  onMarkAsResolved();
                } else {
                  showToast("error", "only doubt creator can solve this doubt");
                }
              }}
            >
              Mark As Resolved
            </ATMLoadingButton>
          </div>
        )}
        </div>
      </div>

      <div className="flex flex-col border-b shadow-md overflow-hidden h-full z-0">
        <div className="flex flex-col h-full overflow-hidden">
          {/* content */}
          <div className="p-4 flex flex-col gap-2">
            <div className="text-xs text-gray-600 flex items-center gap-1 w-fit bg-gray-50 px-1 py-1 font-semibold capitalize">
              {singleDoubtData?.projectName}

              {singleDoubtData?.moduleName ? (
                <>
                  <BiChevronRight /> {singleDoubtData?.moduleName}{" "}
                  {singleDoubtData?.featureName ? (
                    <>
                      <BiChevronRight /> {singleDoubtData?.featureName}
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
            <div>
              <ATMMarkdownContainer markdown={singleDoubtData?.question || "-"} />
            </div>
          </div>

          {/* Comment */}
          <div className="flex items-center gap-2 px-4 py-2 border-y">
            <LiaComments className="text-base" />
            <div className="text-primary-main flex text-sm items-center gap-2 font-semibold">
              <span>Comments</span>
              <span>
                ({doubtsLogListing?.length ? doubtsLogListing?.length : "0"})
              </span>
            </div>
          </div>
          <div className="bg-gray-50 grow overflow-scroll">
            <div className="flex w-full h-full">
              {/* description div */}
              <div className="w-full gap-2 rounded-md p-2 px-4 relative">
                {doubtsLogListing?.length ? (
                  <div className="flex flex-col gap-y-2 max-h-[550px] rounded-md">
                    doubts
                    {/* <!-- Comments Section --> */}
                    {doubtsLogListing?.map((ele) => {
                      return (
                        <div
                          className=" last:border-none border-b py-4"
                          key={ele?._id}
                        >
                          <div className="flex space-x-4">
                            <div>
                              <h2 className="text-gray-400 text-sm ">
                                {ele?.commentedByName} add comment on{" "}
                                {formatCreatedAt(ele?.createdAt)}
                              </h2>
                              <p className="text-gray-600 font-semibold text-md">
                                <ATMMarkdownContainer
                                  markdown={ele?.message || ""}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex justify-center items-center text-slate-400 mt-4">
                    No comment found in this doubt
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {singleDoubtData?.status === "OPEN" && (
          <div className="fit-height p-2">
            <AddCommentFormWrapper currentAssignee={currentAssignee} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleDoubtView;
