import React, { useEffect, useState } from "react";
import { FieldArray, FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { SalaryConfigureDialogFormValues } from "./SalaryConfigureDialogFormWrapper";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiPlus } from "react-icons/fi";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<SalaryConfigureDialogFormValues>;
  onClose: () => void;
};

const SalaryConfigureDialogForm = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const [totalSalary, setTotalSalary] = useState<any>(0);

  useEffect(() => {
    setTotalSalary(
      values?.earning?.reduce((accumulator: any, currentValue: any) => {
        return accumulator + Number(currentValue?.salary);
      }, 0) -
        values?.deducation?.reduce((accumulator: any, currentValue: any) => {
          return accumulator + Number(currentValue?.salary);
        }, 0)
    );
  }, [values]);

  return (
    <MOLFormDialog
      title={"Salary Configure"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={"ADD"}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-3 gap-8 items-center">
          <ATMDatePicker
            required
            label="Effective Date"
            name="effectiveDate"
            format="DD/MM/YYYY"
            value={values?.effectiveDate || null}
            onChange={(newValue) => {
              setFieldValue("effectiveDate", newValue);
            }}
          />
          <div className="mt-4 bg-slate-100 p-2">
            <p className="font-medium text-xs">Total Salary</p>
            <p className="font-medium text-[16px] ">₹ {totalSalary}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <div>
            <div className="text-lg font-semibold border-b pb-[2px]">
              Earnings
            </div>
            <FieldArray name="earning">
              {({ push, remove }) => (
                <div className="grid grid-cols-1  gap-4">
                  {values?.earning?.map((label, index) => (
                    <div key={index} className="md:flex block items-end  gap-2">
                      {/* Label Name*/}
                      <div className="">
                        <ATMTextField
                          name={`earning[${index}].fieldName`}
                          value={values?.earning[index]?.fieldName}
                          onChange={(e) =>
                            setFieldValue(
                              `earning[${index}].fieldName`,
                              e.target.value
                            )
                          }
                          label="Name"
                          required
                          placeholder="Enter Name"
                          onBlur={handleBlur}
                        />
                      </div>

                      {/* Status */}
                      <div className="my-3 md:my-0">
                        <ATMTextField
                          name={`earning[${index}].salary`}
                          value={values?.earning[index]?.salary}
                          onChange={(e) =>
                            setFieldValue(
                              `earning[${index}].salary`,
                              e.target.value
                            )
                          }
                          label="Salary"
                          required
                          placeholder="Enter Salary"
                          onBlur={handleBlur}
                        />
                      </div>

                      {/* Delete Button */}
                      <button
                        type="button"
                        className={`${getInputHeight(
                          "small"
                        )}  rounded  text-red-600 flex justify-center items-center mt-4 md:mt-0`}
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin5Line size={20} />
                      </button>
                    </div>
                  ))}

                  {/* Add More Button */}
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() =>
                        push({
                          fieldName: "",
                          salary: "",
                        })
                      }
                      className="rounded border-dashed border p-1 border-slate-500 font-medium text-md text-gray-400 flex justify-center  gap-1 items-center"
                    >
                      <FiPlus size="1.3em" /> Add More
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </div>
          <div className="border-l-2 pl-4">
            <div className="text-lg font-semibold border-b pb-[2px]">
              Deductions
            </div>
            <FieldArray name="deducation">
              {({ push, remove }) => (
                <div className="grid grid-cols-1  gap-4">
                  {values?.deducation?.map((label, index) => (
                    <div key={index} className="md:flex block items-end  gap-2">
                      {/* Label Name*/}
                      <div className="">
                        <ATMTextField
                          name={`deducation[${index}].fieldName`}
                          value={values?.deducation[index]?.fieldName}
                          onChange={(e) =>
                            setFieldValue(
                              `deducation[${index}].fieldName`,
                              e.target.value
                            )
                          }
                          label="Name"
                          required
                          placeholder="Enter Name"
                          onBlur={handleBlur}
                        />
                      </div>

                      {/* Status */}
                      <div className="my-3 md:my-0">
                        <ATMTextField
                          name={`deducation[${index}].salary`}
                          value={values?.deducation[index]?.salary}
                          onChange={(e) =>
                            setFieldValue(
                              `deducation[${index}].salary`,
                              e.target.value
                            )
                          }
                          label="Salary"
                          required
                          placeholder="Enter Salary"
                          onBlur={handleBlur}
                        />
                      </div>

                      {/* Delete Button */}
                      <button
                        type="button"
                        className={`${getInputHeight(
                          "small"
                        )}  rounded  text-red-600 flex justify-center items-center mt-4 md:mt-0`}
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin5Line size={20} />
                      </button>
                    </div>
                  ))}

                  {/* Add More Button */}
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() =>
                        push({
                          fieldName: "",
                          salary: "",
                        })
                      }
                      className="rounded border-dashed border p-1 border-slate-500 font-medium text-md text-gray-400 flex justify-center  gap-1 items-center"
                    >
                      <FiPlus size="1.3em" /> Add More
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </div>
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default SalaryConfigureDialogForm;
