import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import useCurrency from "src/hooks/useCurrency";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { twMerge } from "tailwind-merge";

export type ATMAmountFieldPropTypes = {
  name: string;
  value: string | string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  size?: "small" | "medium" | "large";
  type?: any;
  onCurrencyChange: (newValue: any) => void;
  currencyValue: any;
  disableCurrencyChange?: boolean;
} & Omit<React.ComponentProps<"input">, "size">;

const ATMAmountField = ({
  name,
  value,
  className = "bg-white rounded shadow",
  onChange,
  label,
  required,
  size = "small",
  type = "text",
  currencyValue,
  onCurrencyChange,
  disableCurrencyChange = false,
  ...rest
}: ATMAmountFieldPropTypes) => {
  //   const currencies = [
  //     {
  //       currencyName: "USD",
  //       symbol: "$",
  //       _id: "1",
  //     },
  //     {
  //       currencyName: "INR",
  //       symbol: "₹",
  //       _id: "2",
  //     },
  //     {
  //       currencyName: "Yen",
  //       symbol: "¥",
  //       _id: "3",
  //     },
  //   ];

  const [targetData, setTargetData] = useState({
    top: 0,
    left: 0,
  });
  const [showCurrencyPortal, setShowCurrencyPortal] = useState(false);

  const { currency: currencies, isDataLoading } = useCurrency();

  return (
    <div className="relative mt-1">
      {label && (
        <label className="font-medium text-slate-700">
          {label} {required && <span className="text-red-500"> * </span>}
        </label>
      )}
      <div
        className={`flex border border-slate-400 ${getInputHeight(size)} ${label && "mt-1"}  ${className}`}
      >
        <input
          type={type}
          name={name}
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          className={twMerge(
            `outline-none h-full w-full px-2 text-slate-700 rounded flex-1`
          )}
          {...rest}
        />

        <div
          onClick={(e) => {
            if (!disableCurrencyChange) {
              if (showCurrencyPortal) {
                setShowCurrencyPortal(false);
              } else {
                const clientRect = e.currentTarget.getBoundingClientRect();
                setTargetData({
                  top: clientRect.bottom + 8,
                  left: clientRect.left,
                });
                setShowCurrencyPortal(true);
              }
            }
          }}
          className={`w-[50px] h-full bg-stone-200 border-l border-slate-400 rounded-r flex justify-center items-center font-medium text-slate-700 ${!disableCurrencyChange && "cursor-pointer"}`}
        >
          {currencyValue?.currencySymbol}
        </div>

        {showCurrencyPortal &&
          createPortal(
            <ul
              className="absolute shadow w-[50px] z-[10000] bg-white rounded border py-2"
              style={{
                top: targetData?.top,
                left: targetData?.left,
              }}
            >
              {isDataLoading ? (
                <div> Loading... </div>
              ) : (
                currencies?.map((currency: any) => {
                  const isSelected = currency?._id === currencyValue?._id;
                  return (
                    <li
                      key={currency?._id}
                      onClick={() => {
                        onCurrencyChange(currency);
                        setShowCurrencyPortal(false);
                      }}
                      className={`flex justify-center text-slate-700 cursor-pointer ${isSelected && "bg-sky-300"}`}
                    >
                      {currency?.currencySymbol}
                    </li>
                  );
                })
              )}
            </ul>,
            document.body
          )}
      </div>
      {name && (
        <ErrorMessage name={name}>
          {(errMsg) => (
            <p className="font-poppins absolute text-[14px] text-start mt-0 text-red-500">
              {errMsg}
            </p>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ATMAmountField;
