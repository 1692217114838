import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
// import { object } from "yup";
import { showToast } from "src/utils/showToaster";
import { useAddMeetingNotesMutation } from "src/services/MeetingServices";
import { useParams } from "react-router-dom";
import AddMeetingNotesForm from "../Layout/AddMeetingNotesForm";
import { array, object, string } from "yup";
import { useGetAllNeetingNoteType } from "src/hooks/useMeetingNoteType";
import { useAddReminderMutation } from "src/services/ReminderService";
import moment from "moment";

type Props = {
  actualClientUsers: any[];
};

export type MeetingNotesTabFormValues = {
  meetingId: any;
  meetingNoteTypeId: any;
  clientUserId: any;
  notes: any;
  title: any;
  userData?: any;
};

const AddMeetingNotesFormWrapper = ({ actualClientUsers }: Props) => {
  const { meetingId: MEETING_ID } = useParams();
  // get all meetng note type hook
  const { allMeetingNoteType, isDataLoading: isAllMeetingNotetypeDataLoading } =
    useGetAllNeetingNoteType();
  const [showSelect, setShowSelect] = useState(false);
  const [addReminder] = useAddReminderMutation();
  const [addMeetingNotes] = useAddMeetingNotesMutation();
  let currentTime = moment();
  currentTime.add(2, "hours");
  const localUserData = JSON.parse(localStorage.getItem("userData") || "{}");

  // Form Initial Values
  const initialValues: MeetingNotesTabFormValues = {
    meetingId: MEETING_ID || "",
    clientUserId: "",
    notes: "",
    meetingNoteTypeId: allMeetingNoteType
      ?.filter((noteType) => noteType?.isDefault)
      ?.map((noteType: any) => {
        return {
          label: noteType?.meetingNoteType,
          value: noteType?._id,
        };
      }),
    title: "",
    userData: [],
  };

  // Validation Schema
  const validationSchema = object().shape({
    // notes: string().required("notes is required"),
    meetingNoteTypeId: array()
      .test("nonEmpty", "Please select note type.", function (value) {
        return value && value.length > 0;
      })
      .of(
        object().shape({
          label: string().required("required"),
          value: string().required("Please enter field type"),
        })
      ),

    notes: string().test(
      "is-notes-required",
      "Please enter notes",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: MeetingNotesTabFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingNotesTabFormValues>
  ) => {
    const {
      clientUserId,
      meetingNoteTypeId,
      userData,
      meetingId,
      title,
      ...rest
    } = values;
    const NoteformatedValues = {
      clientUserId: clientUserId?.value,
      meetingId: MEETING_ID,
      title: title,
      meetingNoteTypeId: meetingNoteTypeId
        ? meetingNoteTypeId?.map((clientAction: any) => clientAction?.value)
        : [],
      ...rest,
    };

    addMeetingNotes(NoteformatedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          const ReminderValues = {
            reminder: values?.title ? values.title : values?.notes,
            dateTime: currentTime.format("YYYY-MM-DD HH:mm:ss"),
            source: "NOTE",
            sourceId: res?.data?.data?._id,
            forWhom: values?.userData?.length
              ? values?.userData?.map((forWhom: any) => forWhom?.value)
              : [localUserData?.userId],
          };
          showToast("success", res?.data?.message);
          showSelect &&
            addReminder(ReminderValues).then((res: any) => {
              if (res?.error) {
                showToast("error", res?.error?.data?.message);
              } else {
                if (res?.data?.status) {
                  showToast("success", res?.data?.message);
                  setShowSelect(false);
                  resetForm();
                } else {
                  showToast("error", res?.data?.message);
                }
              }
              setSubmitting(false);
            });
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form className="h-full">
          <AddMeetingNotesForm
            formikProps={formikProps}
            actualClientUsers={actualClientUsers}
            isAllMeetingNotetypeDataLoading={isAllMeetingNotetypeDataLoading}
            allMeetingNoteType={allMeetingNoteType}
            showSelect={showSelect}
            setShowSelect={setShowSelect}
            formType="Add"
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddMeetingNotesFormWrapper;
