import apiSlice from "./ApiSlice";

export const meetingTypeApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Get All
        getAllMeetingTypePaginationData: builder.query({
            providesTags: ["meeting-type"],
            query: (body: PaginationPayloadType) => {
                return {
                    url: "/meeting-type",
                    method: "POST",
                    body,
                };
            },
        }),

        // Get Module by Id
        getMeetingTypeById: builder.query({
            providesTags: ["meeting-type"],
            query: (id) => {
                return {
                    url: `/meeting-type/${id}`,
                    method: "GET",
                };
            },
        }),

        // Add
        addMeetingType: builder.mutation({
            invalidatesTags: ["meeting-type"],
            query: (body) => {
                return {
                    url: "/meeting-type/add",
                    method: "POST",
                    body,
                };
            },
        }),


        // Edit
        editMeetingTypeById: builder.mutation({
            invalidatesTags: ["meeting-type"],
            query: ({ id, body }) => {
                return {
                    url: `/meeting-type/${id}`,
                    method: "PUT",
                    body,
                };
            },
        }),

        //delete leave Request
        deleteMeetingType: builder.mutation({
            invalidatesTags: ["meeting-type"],
            query: (id: string) => {
                return {
                    url: `meeting-type/${id}`,
                    method: "DELETE",
                };
            },
        }),


        // Change Status currency
        changeStatusMeetingType: builder.mutation({
            invalidatesTags: ["meeting-type"],
            query: (id) => {
                return {
                    url: `/meeting-type/status-change/${id}`,
                    method: "PUT",
                };
            },
        }),
    }),
});

export const {
    useGetAllMeetingTypePaginationDataQuery,
    useEditMeetingTypeByIdMutation,
    useDeleteMeetingTypeMutation,
    useChangeStatusMeetingTypeMutation,
    useAddMeetingTypeMutation,
    useGetMeetingTypeByIdQuery
} = meetingTypeApi;
