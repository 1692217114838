import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/CampaignSlice";
import { RootState } from "src/redux/store";
import {
  useDeleteCampaignMutation,
  useGetAllCampaignsQuery,
} from "src/services/CampaignService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import AddCampaignFormWrapper from "../Add/AddCampaignFormWrapper";
import EditCampaignFormWrapper from "../Edit/EditCampaignFormWrapper";
import CampaignListing from "./CampaignListing";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

const CampaignListingWrapper = () => {
  const dispatch = useDispatch();
  const { isTableLoading, totalItems, page, rowsPerPage, searchValue, items } =
    useSelector((state: RootState) => state.campaign);
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [deleteCampaign] = useDeleteCampaignMutation();
  const { data, isLoading, isFetching } = useGetAllCampaignsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["campaignName", "campaignGroupId"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    } else {
      dispatch(setIsTableLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const getActionOptions = (campaignId: string) => {
    return [
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdEdit color="#000000" /> Edit
          </div>
        ),
        onClick: () => {
          setIsOpenEditForm(true);
          setSelectedCampaignId(campaignId);
        },
        acitonName: UserModuleNameTypes.ACTION_CAMPAIGN_EDIT,
      },
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdDelete color="#000000" /> Delete
          </div>
        ),
        onClick: () => {
          showConfirmationDialog({
            title: "Hands Up",
            text: "Are you sure want to delete this Campaign?",
            icon: "question",
            showCancelButton: true,
            next: (result) => {
              if (result?.isConfirmed) {
                deleteCampaign(campaignId).then((res: any) => {
                  if (res.error) {
                    showToast("error", res?.error?.data?.message);
                  } else {
                    if (res?.data?.status) {
                      showToast("success", res?.data?.message);
                    } else {
                      showToast("success", res?.data?.message);
                    }
                  }
                });
              }
            },
          });
        },
        acitonName: UserModuleNameTypes.ACTION_CAMPAIGN_DELETE,
      },
    ];
  };
  const columns: columnTypes[] = [
    {
      field: "campaignName",
      headerName: "Campaign Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.CAMPAIGN_LIST_CAMPAIGN_NAME,
    },
    {
      field: "campaignGroupName",
      headerName: "Campaign Group Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.CAMPAIGN_LIST_CAMPAIGN_GROUP_NAME,
    },
    {
      field: "channels",
      headerName: "Channels Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.CAMPAIGN_LIST_CHANNELS_NAME,

      renderCell: (row: any) => {
        return row?.channels?.map((channel: any) => {
          return <Chip className="mx-1" label={channel?.channelName || ""} />;
        });
      },
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => {
        const options = getActionOptions(row?._id);
        return <ATMActionMenu options={options} orientation="vertical" />;
      },
    },
  ];
  return (
    <div>
      <CampaignListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
        onAddNew={() => setIsOpenAddForm(true)}
      />
      {isOpenAddForm ? (
        <AddCampaignFormWrapper onClose={() => setIsOpenAddForm(false)} />
      ) : null}
      {isOpenEditForm ? (
        <EditCampaignFormWrapper
          onClose={() => setIsOpenEditForm(false)}
          campaignId={selectedCampaignId}
        />
      ) : null}
    </div>
  );
};

export default CampaignListingWrapper;
