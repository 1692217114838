import apiSlice from "./ApiSlice";

export const stateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    getAllStates: builder.query({
      query: (countryId: string) => {
        return {
          url: countryId ? `/state?countryId=${countryId}` : `/state`,
          method: "GET",
        };
      },
    }),

    // GET
    getState: builder.query({
      providesTags: ["state"],
      query: (body) => ({
        url: "/state",
        method: "POST",
        body,
      }),
    }),

    // ADD STATE
    AddState: builder.mutation({
      invalidatesTags: ["state"],
      query: (body) => ({
        url: "/state/add",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetAllStatesQuery, useGetStateQuery, useAddStateMutation } =
  stateApi;
