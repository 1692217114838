import { FormikProps } from "formik";
import moment from "moment";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import {
  LeaveRequestFormValues,
  FullDayLeaveType,
} from "src/models/LeaveReques.model";

type Props = {
  handleClose: any;
  formikProps: FormikProps<LeaveRequestFormValues>;
  codioticUsers: any;
  isDataLoading: any;
};

export const typeOption = [
  {
    label: "HALF DAY",
    value: "HALF_DAY",
  },
  {
    label: "FULL DAY",
    value: "ONE_DAY",
  },
  {
    label: "MORE THAN 1 DAY",
    value: "MULTIPLE_DAYS",
  },
];

export const WhichPart = [
  {
    label: "FIRST HALF",
    value: "FIRST_HALF",
  },
  {
    label: "SECOND HALF",
    value: "SECOND_HALF",
  },
  {
    label: "For Hours",
    value: "FOR_HOURS",
  },
];

const UpdateLeaveRequestForm = ({
  handleClose,
  formikProps,
  codioticUsers,
  isDataLoading,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={"Update request"}
      onClose={handleClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMSelect
            required
            name="type"
            label="Type"
            placeholder="Type"
            value={values.type}
            options={typeOption?.map((ele: any) => {
              return {
                label: ele?.label,
                value: ele?.value,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("type", newValue);
              setFieldValue("leaveType", "");
              setFieldValue("arrivalTime", "");
              setFieldValue("dayPart", "");
              setFieldValue("leaveStartDate", "");
              setFieldValue("reportingDate", "");
              setFieldValue("leavingTime", "");

              // arrivalTime  dayPart leaveStartDate reportingDate leavingTime
            }}
          />
        </div>
        {values?.type?.value === "HALF_DAY" && (
          <div>
            <ATMSelect
              required
              name="dayPart"
              label="Which Part?"
              placeholder="Which Part?"
              value={values.dayPart}
              options={WhichPart?.map((ele: any) => {
                return {
                  label: ele?.label,
                  value: ele?.value,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("dayPart", newValue);
              }}
            />
          </div>
        )}
        {values?.type?.value === "ONE_DAY" && (
          <div>
            <ATMSelect
              required
              name="leaveType"
              label="Leave Type"
              placeholder="Select Leave Type"
              value={values.leaveType}
              options={FullDayLeaveType?.map((ele: any) => {
                return {
                  label: ele?.label,
                  value: ele?.value,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("leaveType", newValue);
              }}
            />
          </div>
        )}

        {/* date */}
        {values?.type?.value === "MULTIPLE_DAYS" && (
          <>
            <div className="w-full">
              <ATMDatePicker
                required
                label="Start Date"
                name="leaveStartDate"
                format="DD/MM/YYYY"
                value={values.leaveStartDate || null}
                minDate={moment().format("yyyy-MM-DD")}
                onChange={(newValue) => {
                  setFieldValue("leaveStartDate", newValue);
                }}
              />
            </div>
            <div className="w-full mt-1">
              <ATMDatePicker
                required
                label="Reporting Date"
                name="reportingDate"
                format="DD/MM/YYYY"
                minDate={
                  values?.leaveStartDate || moment().format("yyyy-MM-DD")
                }
                value={values.reportingDate || null}
                onChange={(newValue) =>
                  setFieldValue("reportingDate", newValue)
                }
              />
            </div>
          </>
        )}
        {/* date */}
        {values?.type?.value !== "MULTIPLE_DAYS" && (
          <div className="w-full mt-1">
            <ATMDatePicker
              required
              label="Date"
              name="leaveStartDate"
              format="DD/MM/YYYY"
              value={values.leaveStartDate || null}
              minDate={moment().format("yyyy-MM-DD")}
              onChange={(newValue) => {
                setFieldValue("leaveStartDate", newValue);
                setFieldValue("reportingDate", newValue);
              }}
            />
          </div>
        )}
        {values?.type?.value === "HALF_DAY" && (
          <>
            {values?.dayPart?.value === "SECOND_HALF" ||
            values?.dayPart?.value === "FOR_HOURS" ? (
              <div className="mb-2 mt-1">
                <ATMTextField
                  type="time"
                  name="leavingTime"
                  value={moment(values.leavingTime, "HH:mm").format("HH:mm")}
                  required
                  label="Leaving time"
                  className="mt-0 rounded"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    let time = moment(e.target.value, "HH:mm");
                    setFieldValue("leavingTime", time);
                  }}
                />
              </div>
            ) : null}
          </>
        )}
        {values?.type?.value === "HALF_DAY" && (
          <>
            {values?.dayPart?.value === "FIRST_HALF" ||
            values?.dayPart?.value === "FOR_HOURS" ? (
              <div className="mb-2 mt-1">
                <ATMTextField
                  type="time"
                  name="arrivalTime"
                  value={moment(values.arrivalTime, "HH:mm").format("HH:mm")}
                  required
                  label="Arrival time"
                  className="mt-0 rounded"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    let time = moment(e.target.value, "HH:mm");
                    setFieldValue("arrivalTime", time);
                  }}
                />
              </div>
            ) : null}
          </>
        )}

        <div>
          <ATMSelect
            required
            name="approvals"
            label="Approver"
            placeholder="Approver"
            value={values.approvals}
            options={codioticUsers?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            isLoading={isDataLoading}
            onChange={(newValue) => {
              setFieldValue("approvals", newValue);
            }}
          />
        </div>
        <div className="mt-1">
          <ATMSelect
            required
            name="buddy"
            label="Buddy"
            placeholder="Buddy"
            isLoading={isDataLoading}
            value={values.buddy}
            options={codioticUsers?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("buddy", newValue);
            }}
          />
        </div>
        {/* Remark */}
        <div className="mt-1">
          <ATMTextArea
            required
            name="remark"
            value={values.remark}
            onChange={(e) => setFieldValue("remark", e)}
            label="Remark"
            placeholder="Enter Remark"
          />
        </div>

        {/* Reason */}
        <div className="mt-1">
          <ATMTextArea
            required
            name="reason"
            value={values.reason}
            onChange={(e) => setFieldValue("reason", e)}
            label="Reason"
            placeholder="Enter Reason"
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default UpdateLeaveRequestForm;
