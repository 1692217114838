import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { FaUser } from "react-icons/fa";
import { GrStatusUnknown, GrTransaction } from "react-icons/gr";
import { RxLapTimer } from "react-icons/rx";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  useGetProjectByIdQuery,
} from "src/services/ProjectServices";
import AccessDenied from "src/utils/AccessDenied";
import { isAuthorized } from "src/utils/authorization";

type Props = {};

export const inquiriesEditTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
    {
      label: "DETAILS",
      icon: FaUser,
      path: "details",
      moduleName: UserModuleNameTypes.ACTION_CLIENTS_VIEW_DETAILS_TAB
    },
    {
      label: "PROJECTS",
      icon: RxLapTimer,
      path: "projects",
      moduleName: UserModuleNameTypes.ACTION_CLIENTS_VIEW_PROJECTS_TAB
    },
    {
      label: "Meetings",
      icon: GrStatusUnknown,
      path: "meetings",
      moduleName: UserModuleNameTypes.ACTION_CLIENTS_VIEW_MEETINGS_TAB
    },
    {
      label: "Change Request",
      icon: VscGitPullRequestCreate,
      path: "change-request",
      moduleName: UserModuleNameTypes.ACTION_CLIENTS_VIEW_CHANGE_REQUEST_TAB
    },
    {
      label: "RESOURCE",
      icon: VscGitPullRequestCreate,
      path: "resources",
      moduleName: UserModuleNameTypes.ACTION_CLIENTS_VIEW_RESOURCE_TAB
    },
    {
      label: "CLIENT LEDGER",
      icon: GrTransaction,
      path: "client-ledger",
      moduleName: UserModuleNameTypes.ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB
    },
  ];

const ClientViewTabsLayout = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [showProjectList, setShowProjectList] = useState<any>(false);

  const handleProjectNameClick = () => {
    setShowProjectList(!showProjectList);
  };

  const currentPath = location.pathname.split("/")[3];
  const [projectItems, setProjectItems] = useState<any>({});

  const {
    isLoading: projectIsLoading,
    isFetching: projectIsFetching,
    data: projectData,
  } = useGetProjectByIdQuery(id || "", {
    skip: !id,
  });

  useEffect(() => {
    if (!projectIsLoading && !projectIsFetching) {
      setProjectItems(projectData?.data || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectIsLoading, projectIsFetching, projectData]);

  const { userData } = useGetLocalStorage();

  React.useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("open");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of inquiriesEditTabs) {
      const isValue = isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav?.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tabsRender = inquiriesEditTabs?.some((nav) => {
    return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
  })


  return (
    <>
      <SideNavLayout>
        {tabsRender ?
          <div className="flex flex-col gap-2 h-full">
            <div className="flex justify-between">
              <div
                className="text-blue-500 font-semibold underline m-2 text-xl capitalize"
                onClick={handleProjectNameClick}
              >
                {projectItems?.projectName}
              </div>
            </div>

            {/* inquiriesEditTabs */}
            {inquiriesEditTabs?.length && (
              <div className="flex gap-3 items-center bg-white">
                {inquiriesEditTabs?.filter((nav) => {
                  return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
                })?.map((tab: any, index: any) => {
                  return (
                    <div key={index}>
                      <button
                        type="button"
                        onClick={() => navigate(tab.path)}
                        className={`h-full px-4 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${currentPath === tab.path?.split("/")[0]
                            ? "text-primary-main   border-primary-main"
                            : "text-gray-700 border-white"
                          }
         `}
                      >
                        <div className="text-lg">
                          <tab.icon />
                        </div>
                        {tab.label}
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="grow overflow-auto ">
              <Outlet />
            </div>
          </div> : <AccessDenied />}
      </SideNavLayout>
    </>
  );
};

export default ClientViewTabsLayout;
