import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { OperationsFormValues } from "src/models/Operations.model";
import {
  useGetSingleOperationQuery,
  useUpdateOperationMutation,
} from "src/services/OperationsService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import OperationForm from "../Layouts/OperationForm";
import { useNavigate, useParams } from "react-router-dom";

const EditOperationFormWrapper = () => {
  const { id: selectedOperationId } = useParams();
  const navigate = useNavigate();
  const [OperationData, setOperationData] = useState<any>({});
  const { isLoading, isFetching, data } = useGetSingleOperationQuery(
    selectedOperationId || ""
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setOperationData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const [updateDomain] = useUpdateOperationMutation();

  // Form Initial Values
  const initialValues: OperationsFormValues = {
    title: OperationData?.title || "",
    description: OperationData?.description || "",
    modelName: OperationData?.businessModels?.map((industry: any) => {
      return {
        label: industry?.modelName,
        value: industry?.businessModelId,
      };
    }),
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter title"),
    modelName: array().required("Please Select Industry"),
    description: string().required("Please enter description"),
  });

  // Handle Submit
  const handleSubmit = (
    values: OperationsFormValues,
    { setSubmitting, resetForm }: FormikHelpers<OperationsFormValues>
  ) => {
    const formattedValues = {
      title: values?.title || "",
      description: values?.description || "",
      businessModels: values?.modelName?.map((el: any) => el?.value) || "",
    };
    updateDomain({
      id: selectedOperationId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          navigate("/enterprisesuite/operation");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-20 bg-slate-100 opacity-50 select-none">
              <CircularProgress />
            </div>
          )}
          <OperationForm
            formikProps={formikProps}
            onClose={() => navigate("/enterprisesuite/operation")}
            formType="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditOperationFormWrapper;
