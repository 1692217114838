import { Form, Formik, FormikHelpers } from "formik";
import { ResourceRequestFormValues } from "src/models/ResourceRequest.model";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import { useState } from "react";
import ResourceRequestForm from "../Layout/ResourceRequestForm";
import { useAddResourceRequestMutation } from "src/services/ResourceRequestService";

type Props = {
  onClose: () => void;
  projectId?: any;
  projectLabel?: any;
  resourceRequiredSourceName?: any;
  resourceRequiredSourceId?: any;
  resourceRequiredResourceName?: any;
};

const AddResourceRequestFormWrapper = ({
  onClose,
  projectId,
  projectLabel,
  resourceRequiredSourceName,
  resourceRequiredSourceId,
  resourceRequiredResourceName,
}: Props) => {
  const [addResourceRequest] = useAddResourceRequestMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  // Form Initial Values

  const initialValues: ResourceRequestFormValues = {
    projectId:
      projectId && projectLabel
        ? { label: projectLabel, value: projectId }
        : "",
    resourceName: resourceRequiredResourceName
      ? resourceRequiredResourceName
      : "",
    description: "",
    requestedFromId: "",
    requestedFromType: "",
    sourceId: resourceRequiredSourceId ? resourceRequiredSourceId : null,
    sourceName: resourceRequiredSourceName
      ? resourceRequiredSourceName
      : "RESOURCE REQUEST",
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select project"),
    resourceName: string().required("Please enter resource"),
    requestedFromType: object().required("Please select requested from type"),
    requestedFromId: object().test(
      "requestedFromId",
      "Please select requested from",
      (value, context) => {
        const { requestedFromType } = context.parent;
        if (requestedFromType?.value === "CODIOTIC_USER" && !value) {
          return false;
        } else {
          return true;
        }
      }
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: ResourceRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ResourceRequestFormValues>
  ) => {
    const formattedValues = {
      projectId: values?.projectId?.value,
      resourceName: values?.resourceName,
      description: values?.description,
      requestedFromId: values?.requestedFromId?.value,
      requestedFromType: values?.requestedFromType?.value,
      sourceId: values?.sourceId || null,
      sourceName: values?.sourceName,
    };
    addResourceRequest(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ResourceRequestForm
            formType="ADD"
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
            resourceRequiredResourceName={resourceRequiredResourceName}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddResourceRequestFormWrapper;
