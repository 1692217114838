import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-based, so we add 1
const currentYear = currentDate.getFullYear();

export type TeamAttendanceSliceStateType = {
    items: any[] | [];
    unSeenMessageCount: number;
    totalItems: number;
    isTableLoading: boolean;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    month: any;
    year: any
    sortValue: { field: string; value: "DESC" | "ASC" };
    filterBy: {
        fieldName: string;
        value: string[];
    }[];
    dateFilter: {
        start_date: string | null;
        end_date: string | null;
    };
};

const initialState: TeamAttendanceSliceStateType = {
    items: [],
    unSeenMessageCount: 0,
    totalItems: 0,
    isTableLoading: true,
    page: 1,
    rowsPerPage: 20,
    year: { label: currentYear.toString(), value: currentYear },
    month: {
        label: getMonthName(currentMonth),
        value: currentMonth,
    },
    searchValue: "",
    sortValue: { field: "createdAt", value: "DESC" },
    filterBy: [
        {
            fieldName: "companyName",
            value: [],
        },
        {
            fieldName: "country",
            value: [],
        },
    ],
    dateFilter: {
        start_date: null,
        end_date: null,
    },
};

const teamAttendanceSlice: Slice<TeamAttendanceSliceStateType> = createSlice({
    name: "teamAttendanceSlice",
    initialState,
    reducers: {
        setItems: (state, action: PayloadAction<any[] | []>) => {
            state.items = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
            document.getElementById("scroll-top")?.scrollTo(0, 0);
        },
        setRowsPerPage: (state, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
            state.page = 1;
            document.getElementById("scroll-top")?.scrollTo(0, 0);
        },
        setSearchValue: (state, action: PayloadAction<string>) => {
            state.searchValue = action.payload;
            state.page = 1;
        },
        setSortValue: (
            state,
            action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
        ) => {
            state.sortValue = action.payload;
            state.page = 1;
        },
        setTotalUnSeenMessageCount: (state, action: PayloadAction<number>) => {
            state.unSeenMessageCount = action.payload;
        },
        setTotalItems: (state, action: PayloadAction<number>) => {
            state.totalItems = action.payload;
        },
        setIsTableLoading: (state, action: PayloadAction<boolean>) => {
            state.isTableLoading = action.payload;
        },
        setFilterBy: (
            state,
            action: PayloadAction<{ fieldName: string; value: string[] }[]>
        ) => {
            state.filterBy = action.payload;
            state.page = 1;
        },
        setDateFilter: (
            state,
            action: PayloadAction<{ start_date: string; end_date: string }>
        ) => {
            state.dateFilter = action.payload;
        },
        setYear: (state, action: PayloadAction<number>) => {
            state.year = action.payload;
        },
        setMonth: (state, action: PayloadAction<number>) => {
            state.month = action.payload;
        },
    },
});

export const {
    setItems,
    setPage,
    setRowsPerPage,
    setSearchValue,
    setSortValue,
    setTotalItems,
    setTotalUnSeenMessageCount,
    setIsTableLoading,
    setDateFilter,
    setMonth, 
    setYear,
    setFilterBy,
} = teamAttendanceSlice.actions;
export default teamAttendanceSlice.reducer;


function getMonthName(monthNumber :any) {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return months[monthNumber - 1];
}
