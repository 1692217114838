import React from "react";
import FinanceDashboard from "./FinanceDashboard";

type Props = {};

const FinanceDashboardWrapper = (props: Props) => {
  return <FinanceDashboard />;
};

export default FinanceDashboardWrapper;
