import React from "react";
import { useNavigate } from "react-router-dom";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    sortValue: {
      field: string;
      value: "DESC" | "ASC";
    } | null;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
    setSortValue: (
      newValue: {
        field: string;
        value: "DESC" | "ASC";
      } | null
    ) => void;
  };
};

const ProjectFlowTab = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex items-center justify-between">
          <ATMPageHeader
            pageTitle="Project Flow"
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_PROJECTS_VIEW_FLOW_ADD)
            }
            buttonProps={{
              btnName: "Add New",
              onClick: ()=>{ navigate("add")},
            }}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
          />
        </div>

        {/* Table */}
        <div className="flex flex-col flex-1 overflow-auto ">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "h-[40px]"}
            isLoading={isTableLoading}
          />
        </div>

        <div>
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectFlowTab;
