import React, { useState, useEffect, Suspense } from "react";
import GitRepoList from "./GitRepoList";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/GitRepoSlice";
import {
  useGetAllGitRepoWithPaginationQuery,
  useDeleteGitRepoMutation,
} from "src/services/GitRepoService";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdDelete, MdEdit } from "react-icons/md";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { GitRepoListResponse } from "src/models/GitRepo.model";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { CircularProgress } from "@mui/material";

type Props = {};

const GitRepoListWrapper = (props: Props) => {
  const [isOpenAddDialog, setIsOpenAddDialog] = useState<boolean>(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [selectedGitRepoId, setSelectedGitRepoId] = useState<string>("");

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.gitRepo);

  // Dynamic import
  const AddGitRepoFormWrapper = React.lazy(
    () => import("../Add/AddGitRepoFormWrapper")
  );
  const EditGitRepoFormWrapper = React.lazy(
    () => import("../Edit/EditGitRepoFormWrapper")
  );

  // Initiate Methods
  const dispatch = useDispatch();
  const [deleteGitRepo] = useDeleteGitRepoMutation();

  const handleDeleteGitRepo = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Git Repo ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteGitRepo(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const { data, isLoading, isFetching } = useGetAllGitRepoWithPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["repoName", "repoUrl", "repoProjects"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "repoName",
      headerName: " Git Repo",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120x]",
      name: UserModuleNameTypes.GIT_REPO_LIST_GIT_REPO,
      renderCell: (row: GitRepoListResponse) => (
        <div className="capitalize"> {row?.repoName} </div>
      ),
    },
    {
      field: "repoProjects",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150x]",
      name: UserModuleNameTypes.GIT_REPO_LIST_PROJECT,
      renderCell: (row: GitRepoListResponse) =>
        row?.repoProjects?.map((project: any, index: any) => (
          <span
            key={index}
            className="p-1 m-1 text-sm rounded-md  bg-slate-300"
          >
            {project}
          </span>
        )),
    },
    {
      field: "repoUrl",
      headerName: "URL",
      flex: "flex-[2_2_0%]",
      extraClasses: "min-w-[300px]",
      name: UserModuleNameTypes.GIT_REPO_LIST_URL,
      renderCell: (row: GitRepoListResponse) => (
        <div className="w-full overflow-hidden truncate">
          <a
            className="text-blue-500 cursor-pointer"
            target="_blank"
            rel="noreferrer"
            href={`${row?.repoUrl}`}
          >
            {row?.repoUrl}
          </a>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: GitRepoListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectedGitRepoId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_GIT_REPO_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDeleteGitRepo(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_GIT_REPO_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <GitRepoList
        columns={columns}
        rows={items}
        addNew={() => setIsOpenAddDialog(true)}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {/* Add */}
      <Suspense fallback={<CircularProgress />}>
        {isOpenAddDialog ? (
          <AddGitRepoFormWrapper onClose={() => setIsOpenAddDialog(false)} />
        ) : null}
      </Suspense>

      {/* Edit */}
      <Suspense fallback={<CircularProgress />}>
        {isOpenEditDialog ? (
          <EditGitRepoFormWrapper
            onClose={() => setIsOpenEditDialog(false)}
            gitRepoId={selectedGitRepoId}
          />
        ) : null}
      </Suspense>
    </React.Fragment>
  );
};

export default GitRepoListWrapper;
