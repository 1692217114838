import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { DepartmentFormValues } from "src/models/department.model";
import {
  useGetDepartmentByIdQuery,
  useUpdateDepartmentByIdMutation,
} from "src/services/DepartmentService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import DepartmentForm from "../Layouts/DepartmentForm";

type Props = {
  selectedDepartment: string;
  onClose: () => void;
};

const EditDepartmentFormWrapper = ({ selectedDepartment, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetDepartmentByIdQuery(selectedDepartment);
  const [updateGitRepo] = useUpdateDepartmentByIdMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: DepartmentFormValues = {
    repoName: items?.departmentName,
  };

  // Validation Schema
  const validationSchema = object().shape({
    repoName: string().required("Please enter department name."),
  });

  // Handle Submit
  const handleSubmit = (
    values: DepartmentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DepartmentFormValues>
  ) => {
    const formattedValues = {
      departmentName: values.repoName,
    };

    updateGitRepo({ id: selectedDepartment, body: formattedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <DepartmentForm
            formikProps={formikProps}
            formType="EDIT"
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditDepartmentFormWrapper;
