import apiSlice from "./ApiSlice";

export const ticketIssuesService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Add
    addTicketIssue: builder.mutation({
      invalidatesTags: ["issue"],
      query: (body) => {
        return {
          url: "/issue/add",
          method: "POST",
          body,
        };
      },
    }),
     // Get All Ticket
     getAllTicketIssue: builder.query({
        providesTags: ["issue"],
        query: (body: PaginationPayloadType) => {
          return {
            url: "/issue",
            method: "POST",
            body,
          };
        },
      }),
       // Get  user by id
    getTicketIssueById: builder.query({
        providesTags: ["issue"],
        query: (id: string) => {
          return {
            url: `/issue/${id}`,
            method: "GET",
          };
        },
      }),

      //Update 
      updateTicketIssueById: builder.mutation({
        invalidatesTags: ["issue"],
        query: ({ id, body }) => {
          return {
            url: `/issue/${id}`,
            method: "PUT",
            body,
          };
        },
      }),

       // Single Issue Delete
    deleteTicketIssueById: builder.mutation({
        invalidatesTags: ["issue"],
        query: (id: any) => ({
          url: `/issue/${id}`,
          method: "DELETE",
        }),
      }),

  }),
});

export const {
 useAddTicketIssueMutation ,
 useGetAllTicketIssueQuery ,
 useGetTicketIssueByIdQuery ,
 useUpdateTicketIssueByIdMutation ,
 useDeleteTicketIssueByIdMutation
} = ticketIssuesService;
