import apiSlice from "./ApiSlice";

export const currencyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all currency with pagination
    getCurrencyPagination: builder.query({
      providesTags: ["currency"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/currency",
          method: "POST",
          body,
        };
      },
    }),

    // Get all currency
    getAllCurrency: builder.query({
      providesTags: ["currency"],
      query: () => {
        return {
          url: "/currency",
          method: "GET",
        };
      },
    }),

    // Add currency
    addCurrency: builder.mutation({
      invalidatesTags: ["currency"],
      query: (body) => {
        return {
          url: "/currency/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get single currency
    getSingleCurrency: builder.query({
      providesTags: ["currency"],
      query: (id: string) => {
        return {
          url: `/currency/${id}`,
          method: "GET",
        };
      },
    }),

    // Update currency
    updateCurrency: builder.mutation({
      invalidatesTags: ["currency"],
      query: ({ id, body }) => {
        return {
          url: `/currency/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Change Status currency
    changeStatusCurrency: builder.mutation({
      invalidatesTags: ["currency"],
      query: (id) => {
        return {
          url: `/currency/status-change/${id}`,
          method: "PUT",
        };
      },
    }),

    // Change Status currency
    deleteCurrency: builder.mutation({
      invalidatesTags: ["currency"],
      query: (id) => {
        return {
          url: `/currency/${id}`,
          method: "DELETE",
        };
      },
    }),

    // Get Base Currency
    getBaseCurrency: builder.query({
      query: () => {
        return {
          url: `/currency/base-currency`,
          method: "GET",
        };
      },
    }),

    //
  }),
});

export const {
  useGetCurrencyPaginationQuery,
  useGetAllCurrencyQuery,
  useGetSingleCurrencyQuery,
  useAddCurrencyMutation,
  useUpdateCurrencyMutation,
  useChangeStatusCurrencyMutation,
  useDeleteCurrencyMutation,
  useGetBaseCurrencyQuery
} = currencyApi;
