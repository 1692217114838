import { useState, useEffect } from "react";
import { useGetProjectsQuery } from "src/services/ProjectServices";

export const useProjects = () => {
  const [projects, setProjects] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetProjectsQuery("");

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setProjects(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { projects, isDataLoading };
};
