import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDateFilter, setItems } from "src/redux/slices/SalarySlipSlice";
import { AppDispatch, RootState } from "src/redux/store";
import SalarySlipListing from "./SalarySlipListing";
import { useGetSalarySlipDataQuery } from "src/services/TeamServices";
import { useEffect } from "react";
import moment from "moment";
import { CircularProgress } from "@mui/material";

const SalarySlipListingWrapper = () => {
  const { dateFilter, items } = useSelector(
    (state: RootState) => state.salarySlip
  );
  const { userData } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetSalarySlipDataQuery({
    year: dateFilter ? moment(dateFilter)?.year() : moment()?.year(),
    month: dateFilter ? moment(dateFilter)?.month() + 1 : moment()?.month() + 1,
    limit: 10,
    searchValue: "",
    params: ["teamMemberName"],
    page: 1,
    filterBy: [
      {
        fieldName: "teamMemberId",
        value: [userData?.userId],
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);
  return (
    <>
      {isLoading || isFetching ? (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      ) : (
        <SalarySlipListing
          onDateChange={(value: any) => dispatch(setDateFilter(value))}
          salarySlipInfo={items}
          dateFilter={dateFilter}
          onSalarySlipClick={(salarySlipId) =>
            navigate(`/profile/salarySlip/${salarySlipId}`)
          }
        />
      )}
    </>
  );
};

export default SalarySlipListingWrapper;
