import { Form, Formik, FormikHelpers } from "formik";
import { ChannelFormValues } from "src/models/Channel.model";
import { object, string } from "yup";
import ChannelForm from "../Layouts/ChannelForm";
import {
  useEditChannelMutation,
  useGetChannelByIdQuery,
} from "src/services/ChannelService";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
  channelId: string;
};

const EditChannelFormWrapper = ({ onClose, channelId }: Props) => {
  const [editChannel] = useEditChannelMutation();
  const { data, isLoading, isFetching } = useGetChannelByIdQuery(channelId);
  const [channelInfo, setChannelInfo] = useState<any>();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setChannelInfo(data?.data);
    }
  }, [data, isLoading, isFetching]);
  // Form Initial Values
  const initialValues: ChannelFormValues = {
    channelGroupName:
      {
        label: channelInfo?.channelGroupName,
        value: channelInfo?.channelGroupId,
      } || "",
    channelName: channelInfo?.channelName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    channelGroupName: object().required("Please select channel group"),
    channelName: string().required("Please enter channel name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ChannelFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ChannelFormValues>
  ) => {
    const formattedValues = {
      channelName: values?.channelName,
      channelGroupId: values?.channelGroupName?.value,
    };
    editChannel({
      id: channelId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <ChannelForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditChannelFormWrapper;
