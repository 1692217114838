import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setTotalItems,
} from "src/redux/slices/ChangeRequestTimeLog";
import { RootState } from "src/redux/store";
import { useGetAllChangeRequestLogsQuery } from "src/services/ChangeRequestServices";
import TimeLogView from "./TimeLogView";

const TimeLogViewWrapper = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname.split("/");
  const id = currentPath[2];
  const { items, page, rowsPerPage, searchValue, totalItems } = useSelector(
    (state: RootState) => state.changeRequestTimeLog
  );
  const { data, isLoading, isFetching } = useGetAllChangeRequestLogsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["remark"],
    page: page,
    filterBy: [
      {
        fieldName: "changeRequestId",
        value: id,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Project Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      const logData = data?.data?.length ? [...items, ...data?.data] : items;
      dispatch(setItems(logData));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setPage(data?.currentPage || 1));
    }
    return () => {
      dispatch(setItems([]));
      dispatch(setTotalItems(0));
      dispatch(setPage(1));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);
  return (
    <TimeLogView
      items={items}
      paginationProps={{
        totalItems,
        page,
        setPage: (newPage) => {
          dispatch(setPage(newPage));
        },
      }}
    />
  );
};

export default TimeLogViewWrapper;
