import apiSlice from "./ApiSlice";

export const doubtsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get All with pagination
    getDoubts: builder.query({
      providesTags: ["doubts"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/doubt",
          method: "POST",
          body,
        };
      },
    }),

    // Get All
    getAllDoubts: builder.query({
      providesTags: ["doubts"],
      query: () => {
        return {
          url: "/doubt",
          method: "GET",
        };
      },
    }),

    // Get Doubt by Id
    getDoubtById: builder.query({
      providesTags: ["doubts"],
      query: (id) => {
        return {
          url: `/doubt/${id}`,
          method: "GET",
        };
      },
    }),

    // Add
    addDoubt: builder.mutation({
      invalidatesTags: ["doubts"],
      query: (body) => {
        return {
          url: "/doubt/add",
          method: "POST",
          body,
        };
      },
    }),

    // Edit
    editDoubt: builder.mutation({
      invalidatesTags: ["doubts"],
      query: ({ id, body }) => {
        return {
          url: `/doubt/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Add Comment on doubt
    addCommentOnDoubt: builder.mutation({
      invalidatesTags: ["doubts"],
      query: ({ doubtId, body }) => {
        return {
          url: `/doubt/comment/${doubtId}`,
          method: "POST",
          body,
        };
      },
    }),

    // Get All
    getDoubtsLogs: builder.query({
      providesTags: ["doubts"],
      query: (body) => {
        return {
          url: "/doubt/logs",
          method: "POST",
          body,
        };
      },
    }),

    // Mark as Done Doubt
    markAsDoneDoubt: builder.mutation({
      invalidatesTags: ["doubts"],
      query: (id: string) => {
        return {
          url: `/doubt/mark-as-solved/${id}`,
          method: "PUT",
        };
      },
    }),

    // Change Assignee Doubt
    changeAssigneeOnDoubt: builder.mutation({
      invalidatesTags: ["doubts"],
      query: ({ id, body }) => {
        return {
          url: `/doubt/change-assignee/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Get Comment
    getAllCommentOfDoubt: builder.query({
      providesTags: ["doubts"],
      query: (id: string) => {
        return {
          url: `doubt/comments/${id}`,
          method: "GET",
        };
      },
    }),

    // get doubt count
    getDoubtCount: builder.query({
      providesTags: ["doubts"],
      query: () => {
        return {
          url: "doubt/counts",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetDoubtsQuery,
  useGetAllDoubtsQuery,
  useGetDoubtByIdQuery,
  useAddDoubtMutation,
  useEditDoubtMutation,
  useAddCommentOnDoubtMutation,
  useGetDoubtsLogsQuery,
  useMarkAsDoneDoubtMutation,
  useChangeAssigneeOnDoubtMutation,
  useGetAllCommentOfDoubtQuery,
  useGetDoubtCountQuery,
} = doubtsApi;
