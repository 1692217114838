import {
  InterviewStatus,
  JobApplicationStatus,
} from "src/models/JobApplication.model";
import EditOnBoardStageFormWrapper from "./OnboardStage/Edit/EditOnBoardStageFormWrapper";

type Props = {
  answerList: any;
  stageData: any;
  handleInputChange: (questionInfo: any, value: any) => void;
  handleSubmit: (interviewId: string, status: InterviewStatus) => void;
  applicationId: string;
  stageId: string;
  isSubmitLoading: boolean;
  applicationStatus: JobApplicationStatus;
  handleProceedNextStage: (interviewId: string) => void;
  isProceedNextStageLoading: boolean;
  handleRejectApplication: () => void;
};
const JobApplicationStage = ({
  answerList,
  stageData,
  handleInputChange,
  handleSubmit,
  applicationId,
  stageId,
  isSubmitLoading,
  applicationStatus,
  handleProceedNextStage,
  isProceedNextStageLoading,
  handleRejectApplication,
}: Props) => {

  return (
    <div className="">
      <EditOnBoardStageFormWrapper stageData={stageData} />
    </div>
  );
};

export default JobApplicationStage;
