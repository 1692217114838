import React, { useEffect, useState } from 'react'
import ProjectMembersTabForm from './ProjectMembersTabForm'
import { useGetProjectByIdQuery } from 'src/services/ProjectServices'
import { useParams } from 'react-router-dom';
import { UserModuleNameTypes } from 'src/models/UserAccess/UserAccess.model';
import { columnTypes } from 'src/components/UI/atoms/ATMTable/ATMTable';

const PlatformProjectMembersTabFormWrapper = () => { 

    const { id } = useParams();

    const [rowData , setRowData] = useState<any>([])

    const {data , isLoading , isFetching}=useGetProjectByIdQuery(id ||'')

    useEffect(()=>{
        setRowData(data?.data?.members)
    },[data , isLoading , isFetching])

    const columns: columnTypes[] = [
        {
          field: "name",
          headerName: "Project Members",
          flex: "flex-[1_1_0%]",
          extraClasses: "min-w-[180px] py-2",
          name: UserModuleNameTypes.PROJECTS_LIST_PROJECT_NAME,
          renderCell: (row:any) => (
            <div className="capitalize"> {row?.name} </div>
          ),
        },
      
      ];

  return (
    <ProjectMembersTabForm row={rowData} columns={columns}   />
  )
}

export default PlatformProjectMembersTabFormWrapper