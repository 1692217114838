import { useState, useEffect } from "react";
import {
  // useGetProjectsQuery ,
  useGetAllProjectsPaginationQuery,
} from "src/services/ProjectServices";

export const useGetAllProjects = () => {
  const [allProjects, setAllProjects] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllProjectsPaginationQuery({
    limit: 0,
    searchValue: "",
    params: ["projectName"],
    page: 0,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setAllProjects(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { allProjects, isDataLoading };
};
