import apiSlice from "./ApiSlice";

export const gitRepoApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllGitRepoWithPagination: builder.query({
      providesTags: ["git-repo"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/git-repos",
          method: "POST",
          body,
        };
      },
    }),

    // Single Product Delete 
    deleteGitRepo: builder.mutation({
      invalidatesTags: ["git-repo"],
      query: (id: any) => ({
        url: `/git-repos/${id}`,
        method: "DELETE",
      }),
    }),
    // Get
    getGitRepo: builder.query({
      providesTags: ["git-repo"],
      query: () => {
        return {
          url: "/git-repos/",
          method: "GET",
        };
      },
    }),

    // Add
    addGitRepo: builder.mutation({
      invalidatesTags: ["git-repo"],
      query: (body) => {
        return {
          url: "/git-repos/add",
          method: "POST",
          body,
        };
      },
    }),


    // Get the Git Repo  by id
    getGitRepoById: builder.query({
      providesTags: ["git-repo"],
      query: (id: string) => {
        return {
          url: `/git-repos/${id}`,
          method: "GET",
        };
      },
    }),

    // Update Git Repo
    updateGitRepoById: builder.mutation({
      invalidatesTags: ["git-repo"],
      query: ({ id, body }) => {
        return {
          url: `/git-repos/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //
  }),
});

export const {
  useGetAllGitRepoWithPaginationQuery,
  useAddGitRepoMutation,
  useGetGitRepoQuery,
  useGetGitRepoByIdQuery,
  useUpdateGitRepoByIdMutation,
  useDeleteGitRepoMutation,
} = gitRepoApi;
