import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { GoalsFormValues } from "src/models/Goals.model";
import { useAddGoalsMutation } from "src/services/GoalsService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import ExpenseCategoryForm from "../Layout/GoalForm";
import moment from "moment";

type Props = {
  onClose: () => void;
};

const AddGoalWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addExpenses] = useAddGoalsMutation();

  // Form Initial Values
  const initialValues: GoalsFormValues = {
    goalCategoryId: "",
    deadline: null,
    type: "",
    name: "",
    target: 0,
    members: [],
    measuredBy: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    deadline: string().required("Please enter deadline date"),
    name: string().required("Please enter name"),
    type: string().required("Please select type"),
    goalCategoryId: object().required("Please select goal category"),
    measuredBy: object().required("Please select measured"),
    members: array()
      .min(1, "Please select member")
      .required("Please select member"),
  });

  // Handle Submit
  const handleSubmit = (
    values: GoalsFormValues,
    { setSubmitting, resetForm }: FormikHelpers<GoalsFormValues>
  ) => {
    const formattedValues = {
      goalCategoryId: values?.goalCategoryId?.value,
      deadline: values?.deadline
        ? moment(values?.deadline).format("YYYY-MM-DD")
        : "",
      type: values?.type,
      name: values?.name,
      target: values?.target,
      measuredById: values?.measuredBy?.value,
      membersInvolved: values?.members?.map((ele: any) => ele?.value),
    };

    addExpenses(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ExpenseCategoryForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddGoalWrapper;
