import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/ClientLeadgerSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { useGetClientLeadgerQuery } from "src/services/ClientServices";
import ClientLedgerListing from "./ClientLedgerListing";
import { useParams } from "react-router-dom";


type Props = {};

const ClientLedgerListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { clientId } = useParams()
  const [ledgerData, setLedgerData] = useState({})
  const { items, totalItems, page, rowsPerPage, searchValue, type, isTableLoading, dateFilter } =
    useSelector((state: RootState) => state.clientLeadger);
  const { data, isLoading, isFetching } = useGetClientLeadgerQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["clientName"],
    page: page,
    filterBy: [
      {
        fieldName: 'clientId',
        value: [clientId]
      },
      {
        fieldName: 'type',
        value: type
      },
    ],
    dateFilter: dateFilter,
    orderBy: "sortingDate",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      setLedgerData(data?.totalAmount || {})
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "type",
      headerName: "Type",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.CLIENTS_VIEW_CLIENT_LEDGER_LIST_TYPE,
      renderCell: (row: any) => (
        <div>
          {row?.type || '-'}
        </div>
      ),
    },
    {
      field: "sortingDate",
      headerName: "Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.CLIENTS_VIEW_CLIENT_LEDGER_LIST_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="text-slate-700 font-medium">
            {row.sortingDate ? formatedDateTimeIntoIst(row.sortingDate, "DD MMM yyyy") : '-'}
          </div>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div className="flex gap-1">
          {row?.currencySymbol}  {row?.amount || '-'}
        </div>
      ),
      name: UserModuleNameTypes.CLIENTS_VIEW_CLIENT_LEDGER_LIST_AMOUNT,
    },

    {
      field: "taxAmount",
      headerName: "Tax Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          {row?.taxAmount ? (row?.taxAmount).toFixed(2) : '-'}
        </div>
      ),
      name: UserModuleNameTypes.CLIENTS_VIEW_CLIENT_LEDGER_LIST_TAX_AMOUNT,
    },
    {
      field: "tdsAmount",
      headerName: "TDS Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          {row?.tdsAmount ? (row?.tdsAmount).toFixed(2) : '-'}
        </div>
      ),
      name: UserModuleNameTypes.CLIENTS_VIEW_CLIENT_LEDGER_LIST_TDS_AMOUNT,
    },
    {
      field: "conversionLoss",
      headerName: "Conversion Loss",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          {row?.conversionLoss ? (row?.conversionLoss).toFixed(2) : '-'}
        </div>
      ),
      name: UserModuleNameTypes.CLIENTS_VIEW_CLIENT_LEDGER_LIST_CONVERSION_LOSS,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          {(row?.amount + row?.taxAmount + row?.tdsAmount + row?.conversionLoss).toFixed(2) || '-'}
        </div>
      ),
      name: UserModuleNameTypes.CLIENTS_VIEW_CLIENT_LEDGER_LIST_TOTAL_AMOUNT,
    },
    {
      field: "accountLabel",
      headerName: "Local Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          {'-'}
        </div>
      ),
      name: UserModuleNameTypes.CLIENTS_VIEW_CLIENT_LEDGER_LIST_LOCAL_AMOUNT,
    },

    {
      field: "remark",
      headerName: "Remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[250px] max-w-[200px]",
      name: UserModuleNameTypes.CLIENTS_VIEW_CLIENT_LEDGER_LIST_REMARK,
      renderCell: (row: any) => (
        <Tooltip title={row?.remark}>
          <div className="w-full overflow-hidden truncate text-md min-w-[100%]">
            {row?.remark || '-'}
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <ClientLedgerListing
        columns={columns}
        rows={items}
        ledgerData={ledgerData}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default ClientLedgerListingWrapper;
