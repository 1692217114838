import apiSlice from "./ApiSlice";

export const SkillServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
     // List skill
     AllskillListPagination: builder.query({
        providesTags: ["skill"],
        query: (body) => ({
          url: "/skill",
          method: "POST",
          body,
        }),
      }),

    // ADD Skills
    addSkill: builder.mutation({
      invalidatesTags: ["skill"],
      query: (body) => ({
        url: "/skill/add",
        method: "POST",
        body,
      }),
    }),

    // Get single skill
    getSkill: builder.query({
      providesTags: ["skill"],
      query: (id: string) => {
        return {
          url: `/skill/${id}`,
          method: "GET",
        };
      },
    }),

    // Update skill
    updateSkill: builder.mutation({
      invalidatesTags: ["skill"],
      query: ({ id, body }) => {
        return {
          url: `/skill/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //DELETE skill
    deleteskill: builder.mutation({
      invalidatesTags: ["skill"],
      query: (id) => {
        return {
          url: `/skill/${id}`,
          method: "DELETE",
        };
      },
    }),

    // Get All skill
    getAllSkill: builder.query({
      providesTags: ["incident"],
      query: (id: string) => {
        return {
          url: `/skill`,
          method: "GET",
        };
      },
    }),

  }),
});

export const {
  useAddSkillMutation,
  useGetAllSkillQuery,
  useDeleteskillMutation,
  useGetSkillQuery,
  useUpdateSkillMutation,
   useAllskillListPaginationQuery
} = SkillServiceApi;
