import React, { useState } from "react";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { FaPen } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { FiPhoneCall } from "react-icons/fi";
import { useParams } from "react-router-dom";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import moment from "moment";
import AddNoteLogDialogFormWrapper from "../../../../AddNoteLogDialog/AddNoteLogDialogFormWrapper";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { ATMShowField } from "../../../OverView/OverView";
import { useUpdateContactLeadLogsMutation } from "src/services/ContactService";
import { showToast } from "src/utils/showToaster";

type Props = {
  callsData: any;
  editorValue?: string;
  isLoading?: boolean;
};

const CallActivitiesTab = ({ callsData, editorValue = "", isLoading }: Props) => {
 
  const [isOpenEditNote, setIsOpenEditNote] = useState(false);
  const [wantToEditNote, setWantToEditNote] = useState(false);
  const [description, setDescription] = useState("");
  const [actionId, setActionId] = useState("");
  const { leadId } = useParams();

  const [updateLeadLog, updateLeadLogInfo] = useUpdateContactLeadLogsMutation();
  return (
    <div>
      <div className="flex gap-4 items-center justify-end">
        <ATMLoadingButton
          className="bg-slate-700  !h-[32px] text-white w-32  text-[14px] font-medium"
          onClick={() => setIsOpenEditNote(true)}
        >
          Make a call
        </ATMLoadingButton>

        {isOpenEditNote && (
          <AddNoteLogDialogFormWrapper
            contactBy="CALL"
            onClose={() => setIsOpenEditNote(false)}
          />
        )}
      </div>

      <div className="flex flex-col gap-4 h-full overflow-y-scroll mt-3">
        {isLoading ? (
          Array(6)
            .fill(0)
            .map((_, index) => {
              return (
                <div key={index} className="animate-pulse  h-[95px]">
                  <div className="bg-slate-200 h-full rounded"> </div>
                </div>
              );
            })
        ) : callsData?.length ? (
          callsData?.map((el: any, ind: any) => {
            return (
              <div key={ind} className="border rounded p-2">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex gap-2 text-[13px] items-center">
                    <div className="rounded-full bg-slate-100 p-2 flex justify-center">
                      <FiPhoneCall size="1.2em" color="#425b76" />
                    </div>
                    <span className="text-slate-500">
                      {el?.createdByName} make a{" "}
                      <span className="font-semibold">Call</span>
                    </span>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="flex items-center text-cyan-600 text-[13px] font-semibold">
                      Action <IoMdArrowDropdown />{" "}
                    </div>
                    <div className="flex gap-1">
                      <div className="text-slate-400 font-medium text-[11px]">
                        {formatedDateTimeIntoIst(el?.createdAt, "DD MMM yyyy")},
                      </div>
                      <div className="text-[11px] font-medium text-slate-400">
                        {formatedDateTimeIntoIst(el?.createdAt, "hh:mm A")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid xl:grid-cols-6 lg:grid-cols-3 grid-cols-2 gap-4 my-3">
                  <ATMShowField
                    label="Activity Date "
                    value={formatedDateTimeIntoIst(el?.date, "DD MMM yyyy")}
                  />
                  <ATMShowField
                    label="Activity Time"
                    value={moment(el?.time, "HH:mm").format("h:mm A")}
                  />
                    {el?.assignedToName && (
                    <ATMShowField
                      value={el?.assignedToName}
                      label="Assigned To"
                    />
                  )}

                  <ATMShowField
                    label="Call Direction"
                    value={el?.direction?.toLowerCase()}
                  />
                  <ATMShowField
                    label="Call Out Come"
                    value={el?.callOutcome?.toLowerCase()}
                  />
                  {el?.reminderForDate && (
                    <ATMShowField
                      label="Reminder Date"
                      value={el?.reminderForDate}
                    />
                  )}
                  {el?.reminderOn && (
                    <ATMShowField label="Reminder On" value={el?.reminderOn} />
                  )}
                </div>
                <div className="grid grid-cols-12 gap-4 group/item hover:bg-hover items-center px-2 py-1">
                  <div className="col-span-11">
                    {wantToEditNote && actionId === el?._id ? (
                      <div>
                        <MOLMarkdownEditor
                          extraClassName="min-h-[200px] max-h-96 overflow-y-auto"
                          key={editorValue}
                          required
                          value={
                            el?.description ? el?.description : description
                          }
                          placeholder="Add Summary here...."
                          onChange={(newValue: any) => {
                            setDescription(newValue);
                          }}
                        />
                        <div className="mt-2 border-t py-2">
                          <ATMLoadingButton
                            isLoading={updateLeadLogInfo?.isLoading}
                            onClick={() => {
                                updateLeadLog({
                                    id: actionId,
                                body: {
                                    leadId: leadId,
                                  logType: "CALL",
                                  description: description
                                    ? description
                                    : el?.description,
                                  callOutcome: el?.callOutcome,
                                  assignedToId :el?.assignedToId ,
                                  direction: el?.direction,
                                  date: el?.date,
                                  time: el?.time,
                                },
                              }).then((res: any) => {
                                if (res?.error) {
                                  showToast("error", res?.error?.data?.message);
                                } else {
                                  if (res?.data?.status) {
                                    showToast("success", res?.data?.message);
                                    setWantToEditNote(false);
                                  } else {
                                    showToast("error", res?.data?.message);
                                  }
                                }
                              });
                            }}
                            type="submit"
                            className="bg-white text-slate-500 p-2 w-24 cursor-pointer  mx-4"
                          >
                            Save Call
                          </ATMLoadingButton>
                        </div>
                      </div>
                    ) : (
                      <div className="text-[13px] text-primary-dark font-medium  hover:bg-blue-50  rounded-lg p-2">
                        <ATMMarkdownContainer markdown={el?.description} />
                      </div>
                    )}
                  </div>
                  <div className="invisible col-span-1  group-hover/item:visible cursor-pointer">
                    <span
                      onClick={() => {
                        setWantToEditNote(!wantToEditNote);
                        setActionId(el?._id);
                      }}
                    >
                      <FaPen size="1.2em" color="#425b76" />{" "}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex justify-center items-end h-[120px] text-red-700 font-medium text-lg">
            No Data Found
          </div>
        )}
      </div>
    </div>
  );
};

export default CallActivitiesTab;
