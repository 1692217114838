import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { useParams } from "react-router-dom";
import AddNoteLogDialogForm from "./AddNoteLogDialogForm";
import { useGetAllCodioticMembersQuery } from "src/services/TeamServices";
import { useAddContactLeadNoteMutation , useAddContactLeadLogMutation } from "src/services/ContactService";
import { showToast } from "src/utils/showToaster";

export type Summary = {
  note: any;
  actionType: string;
  nextActionRequired: boolean;
  nextActionOn: string;
  nextActionDate: any;
  contactedBy: string;
  contactedOnDate: any;
  contactedOnTime: any;
  callOutCome: string;
  callDirection: string;
  meetingOutCome: string;
  meetingDuration: string;
};

type Props = {
  onClose: () => void;
  contactBy: "NOTE" | "EMAIL" | "CALL" | "MEETING" | "TASK" | "SMS";
};

const AddNoteLogDialogFormWrapper = ({ onClose, contactBy }: Props) => {
  const [showInFullWidth, setShowInFullWidth] = useState(false);
  const { leadId } = useParams();
  const { data } = useGetAllCodioticMembersQuery({
    limit: 10,
    searchValue: "",
    params: ["name", "mobile", "email"],
    page: 1,
    filterBy: [{ fieldName: "isActive", value: true }],
    dateFilter: {},
    orderBy: "name",
    orderByValue: 1,
    isPaginationRequired: false,
  });
     const [addLeadLog] = useAddContactLeadLogMutation();
  const [addLeadNote] = useAddContactLeadNoteMutation();

  const userOptions = data?.data?.map((el: any) => {
    return {
      label: el?.name,
      value: el?._id,
    };
  });

  // Form Initial Values
  const initialValues: Summary = {
    note: "",
    actionType: "",
    nextActionRequired: false,
    nextActionOn: "",
    nextActionDate: "",
    contactedBy: "",
    contactedOnDate: "",
    contactedOnTime: "",
    callOutCome: "",
    callDirection: "",
    meetingOutCome: "",
    meetingDuration: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    note: string().required("Please enter note"),
  });

  // Handle Submit
  const handleSubmit = (
    values: Summary,
    { setSubmitting, resetForm }: FormikHelpers<Summary>
  ) => {
    setSubmitting(true);
    const formatedValues = {
      leadId: leadId,
      wantToAddReminder: values?.nextActionRequired,
      description: values?.note?.trim(),
      reminderForDate: values?.nextActionDate,
      reminderRecordSource: values?.actionType,
      reminderOn: values?.nextActionOn || "",
    };

    const formatedLogValues = {
      leadId: leadId,
      logType: contactBy,
      description: values?.note?.trim(),
      callOutcome: values?.callOutCome,
      direction: values?.callDirection,
      date: values?.contactedOnDate,
      time: values?.contactedOnTime,
      meetingOutcome: values?.meetingOutCome,
      meetingDuration: values?.meetingDuration,
      reminderForDate: values?.nextActionDate,
      reminderOn: values?.nextActionOn || "",
      reminderRecordSource: values?.actionType,
      wantToAddReminder: values?.nextActionRequired,
      assignedToId: values?.contactedBy,
    };

    contactBy === "NOTE"
  ?  addLeadNote(formatedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    })
    : addLeadLog(formatedLogValues).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
  };

  return (
    <Dialog
      className="notelogDialog"
      open
      maxWidth={showInFullWidth ? "xl" : "sm"}
      fullWidth
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <AddNoteLogDialogForm
              userOptions={userOptions}
              showInFullWidth={showInFullWidth}
              setShowInFullWidth={setShowInFullWidth}
              contactBy={contactBy}
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddNoteLogDialogFormWrapper;
