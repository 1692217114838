import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { showToast } from "src/utils/showToaster";
import { object } from "yup";
import IncomeFormLayout from "../Layout/IncomeFormLayout";
import { IncomeFormProps } from "src/models/Income.model";
import { useUpdateIncomeMutation } from "src/services/Finance-New-Services/IncomeService";

const EditIncomeFormWrapper = () => {
  const navigate = useNavigate();
  // const { id } = useParams();
  const [updateIncome] = useUpdateIncomeMutation();
  // const { data } = useGetOneIncomeQuery();
  // Form Initial Values
  const initialValues: IncomeFormProps = {
    incomeDate: null,
    amount: "",
    gstApplicable: false,
    gstPercentage: "",
    tdsAmount: "",
    currency: "",
    status: "UNPAID",
    category: "",
    dueDate: null,
    paymentDate: null,
    conversionLoss: "",
    account: "",
    mode: "",
  };

  // Validation Schema
  const validationSchema = object().shape({});

  // Handle Submit
  const handleSubmit = (
    values: IncomeFormProps,
    { setSubmitting, resetForm }: FormikHelpers<IncomeFormProps>
  ) => {
    const formattedValues = {
      incomeDate: moment(values?.incomeDate).format("YYYY-MM-DD"),
      amount: values?.amount,
      gstApplicable: values?.gstApplicable,
      gstPercentage: values?.gstPercentage || 0,
      tdsAmount: values?.tdsAmount,
      currencyId: values?.currency?._id,
      status: values?.status,
      financeCategoryId: values?.category?.value,
      dueDate: moment(values?.dueDate).format("YYYY-MM-DD"),
      paymentDate: moment(values?.paymentDate).format("YYYY-MM-DD"),
      conversionLoss: values?.conversionLoss || 0,
      accountId: values?.account?.value,
      paymentModeId: values?.mode?.value,
    };
    updateIncome(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate("/finance/income-new");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <IncomeFormLayout
            formType="EDIT"
            formikProps={formikProps}
            onClose={() => navigate("/finance/income-new")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditIncomeFormWrapper;
