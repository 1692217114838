import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import moment from "moment";

type ReminderListInfoType = {
    createdAt: string;
    reminder: string;
    updatedAt: string;
    __v: 0;
    _id: string;
};

type Props = {
    reminderData: any;
    onReminderClick: (reminderId: string) => void;
};

const Reminder = ({ reminderData, onReminderClick }: Props) => {
    return (
        <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg custom-shadow">
            <div className="flex items-center gap-2 p-2 border-b bg-red-50">
                <div className="flex items-center justify-center p-2 font-semibold bg-white border rounded-md border-primary-dark size-8">
                    {reminderData?.count}
                </div>
                Reminders
            </div>
            {reminderData?.reminders?.length ? (
                <div className="h-full py-2 overflow-auto">
                    {reminderData?.reminders?.map(
                        (reminderInfo: ReminderListInfoType, index: any) => (
                            <div
                                key={index}
                                className="flex items-start justify-between px-4 py-1 gap-3 cursor-pointer hover:text-black hover:bg-hover text-neutral"
                                onClick={() => onReminderClick(reminderInfo?._id)}
                            >
                                <div className="dashboard-doubt-list flex-1  line-clamp-2 text-sm">
                                    <ATMMarkdownContainer markdown={reminderInfo?.reminder} />
                                </div>
                                <div className="w-1/6 text-end  text-primary-main  text-sm font-medium">
                                    {moment(reminderInfo?.createdAt).format("DD MMM YYYY")}
                                </div>
                            </div>
                        )
                    )}
                </div>
            ) : (
                <div className="flex items-center justify-center w-full h-full p-4">
                    No Reminder Found
                </div>
            )}
        </div>
    );
};

export default Reminder;
