import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { LeaveRequestFormValues } from "src/models/LeaveReques.model";
import { useAddLeaveRequestServiceMutation } from "src/services/LeaveRequestService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import OneDayLeaveRequestForm from "../Layouts/OneDayLeaveRequestForm";

type Props = {
  handleClose: any;
};

const AddOneDayLeaveRequestFormWrapper = ({ handleClose }: Props) => {
  // get  members
  const { codioticUsers, isDataLoading } = useGetAllCodioticUsers();

  const [addLeaveRequest] = useAddLeaveRequestServiceMutation();
  const initialValues: LeaveRequestFormValues = {
    reason: "",
    leaveStartDate: "",
    reportingDate: "",
    leaveType: "",
    daysOfLeave: "ONE_DAY",
    dayPart: "",
    leavingTime: "",
    arrivalTime: "",
    approvals: null,
    buddy: "",
  };

  const validationSchema = object({
    leaveStartDate: string().required("Please select date"),
    buddy: object().required("Please select buddy"),
    reason: string().required("Please enter reason"),
    approvals: object().required("Please select approval"),
    leaveType: object().required("Please select type of leave"),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeaveRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<LeaveRequestFormValues>
  ) => {
    const {
      leaveType,
      approvals,
      buddy,
      leaveStartDate,
      reportingDate,
      ...rest
    } = values;

    const formattedValues = {
      ...rest,
      leaveType: leaveType?.value,
      approvals: [approvals?.value],
      leaveStartDate: moment(leaveStartDate).format("YYYY-MM-DD"),
      reportingDate: moment(leaveStartDate).add(1, "days").format("YYYY-MM-DD"),
      buddy: buddy?.value,
    };

    addLeaveRequest(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <OneDayLeaveRequestForm
              handleClose={handleClose}
              formikProps={formikProps}
              codioticUsers={codioticUsers}
              isDataLoading={isDataLoading}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddOneDayLeaveRequestFormWrapper;
