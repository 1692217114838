import apiSlice from "./ApiSlice";

export const projectApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllProjectsPagination: builder.query({
      providesTags: ["projects"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/project",
          method: "POST",
          body,
        };
      },
    }),

    getAnalyticsProjectWise: builder.query({
      providesTags: ["projects"],
      query: (body: any) => {
        return {
          url: "/analytics/user-wise-time-spent-on-project",
          method: "POST",
          body,
        };
      },
    }),

    // Get
    getProjects: builder.query({
      providesTags: ["projects"],
      query: () => {
        return {
          url: "/project",
          method: "GET",
        };
      },
    }),

    // Get all project label
    getAllLabelsAllProject: builder.query({
      providesTags: ["projects"],
      query: () => {
        return {
          url: "/project/all-label",
          method: "GET",
        };
      },
    }),

    // Get all project layer
    getAllLayerOfProject: builder.query({
      providesTags: ["projects"],
      query: () => {
        return {
          url: "/project/all-layer",
          method: "GET",
        };
      },
    }),

    // Get all labels
    getAllLabels: builder.query({
      providesTags: ["projects"],
      query: (id: string) => {
        return {
          url: `/project/get-label/${id}`,
          method: "GET",
        };
      },
    }),

    // Get all layers
    getAllLayers: builder.query({
      providesTags: ["projects"],
      query: (id: string) => {
        return {
          url: `/project/get-layer/${id}`,
          method: "GET",
        };
      },
    }),

    // Add
    addProject: builder.mutation({
      invalidatesTags: ["projects"],
      query: (body) => {
        return {
          url: "/project/add",
          method: "POST",
          body,
        };
      },
    }),

    deleteProjectById: builder.mutation({
      invalidatesTags: ["projects"],
      query: (id: any) => ({
        url: `/project/${id}`,
        method: "DELETE",
      }),
    }),

    // Get the Git Repo  by id
    getProjectById: builder.query({
      providesTags: ["projects"],
      query: (id: string) => {
        return {
          url: `/project/${id}`,
          method: "GET",
        };
      },
    }),

    // Update Git Repo
    updateProjectById: builder.mutation({
      invalidatesTags: ["projects"],
      query: ({ id, body }) => {
        return {
          url: `/project/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Get the Git Repo  by id
    getProjectByClient: builder.query({
      providesTags: ["projects", "clients"],
      query: (id: string) => {
        return {
          url: `/client/projects/${id}`,
          method: "GET",
        };
      },
    }),

    //Time Taken Bu User On Project 
    getAllProjectssTimeTakenPagination: builder.query({
      providesTags: ["projects"],
      query: (body: any) => {
        return {
          url: "/analytics/projects-in-progress-report",
          method: "POST",
          body,
        };
      },
    }),

     //Time Taken Bu User On this Project Meeting
     getAllProjectsMeetingTimeTakenPagination: builder.query({
      providesTags: ["projects"],
      query: (body: any) => {
        return {
          url: "/analytics//projects-meeting-report",
          method: "POST",
          body,
        };
      },
    }),


    // Get the Git Repo  by id
    getProjectTaskStatusCount: builder.query({
      providesTags: ["projects"],
      query: (pId: string) => {
        return {
          url: `/project/${pId}/task-count`,
          method: "GET",
        };
      },
    }),

    // Get the Git Repo  by id
    getChangeRequestByProject: builder.query({
      providesTags: ["projects", "change-request"],
      query: (pId: string) => {
        return {
          url: `/project/${pId}/change-requests`,
          method: "GET",
        };
      },
    }),

    // project flowwwwww


    getAllProjectFlow: builder.query({
      providesTags: ["projects"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/project-flow",
          method: "POST",
          body,
        };
      },
    }),


    // Add
    addProjectFlow: builder.mutation({
      invalidatesTags: ["projects"],
      query: (body) => {
        return {
          url: "/project-flow/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get single projectFlow via id
    getSingleProjectFlowViaId: builder.query({
      providesTags: ["projects"],
      query: (projectFlowId: string) => {
        return {
          url: `/project-flow/${projectFlowId}`,
          method: "GET",
        };
      },
    }),


     // Update Git Repo
     updateProjectFlowById: builder.mutation({
      invalidatesTags: ["projects"],
      query: ({ projectFlowId, body }) => {
        return {
          url: `/project-flow/${projectFlowId}`,
          method: "PUT",
          body,
        };
      },
    }),


  }),
});

export const {
  useGetAllProjectsPaginationQuery,
  useGetProjectsQuery,
  useAddProjectMutation,
  useAddProjectFlowMutation,
  useUpdateProjectFlowByIdMutation,
  useGetProjectByIdQuery,
  useGetSingleProjectFlowViaIdQuery,
  useGetChangeRequestByProjectQuery,
  useGetAnalyticsProjectWiseQuery,
  useGetAllLabelsQuery,
  useGetAllProjectFlowQuery,
  useGetAllLabelsAllProjectQuery,
  useGetAllLayerOfProjectQuery,
  useGetAllLayersQuery,
  useDeleteProjectByIdMutation,
  useUpdateProjectByIdMutation,
  useGetProjectByClientQuery,
  useGetAllProjectssTimeTakenPaginationQuery,
  useGetAllProjectsMeetingTimeTakenPaginationQuery,
  useGetProjectTaskStatusCountQuery,
} = projectApi;
