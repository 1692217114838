import moment from "moment";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "src/redux/store";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
// import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import { FormikProps } from "formik";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetProjectsQuery } from "src/services/ProjectServices";
import { FormInitialValues } from "./MeetingListFilterFormDialogWrapper";
import ATMRadioButtonGroup from "src/components/UI/atoms/formFields/ATMRadioButtonGroup/ATMRadioButtonGroup";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";
// import MOLFilter from "src/components/UI/molecules/MOLFilter/MOLFilter";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};


const MeetingListFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  const [projectOptions, setProjectOptions] = useState([]);

  const {
    data: projects,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
  } = useGetProjectsQuery("");

  // Setting Team Members Data
  useEffect(() => {
    if (!isProjectLoading && !isProjectFetching) {
      const filterProjectOptions = projects?.data?.map((project: any) => ({
        label: project.projectName,
        value: project._id,
      }));
      setProjectOptions(filterProjectOptions || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, isProjectLoading, isProjectFetching]);

  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  return (
   <MOLFilterDialog 
   title={"Filters"}
   onClose={onClose}
   isSubmitting={isSubmitting}
   clearFilter={onReset}
   handleSubmit={handleSubmit}
   open={open}
   >
    <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">
        {/*  1st  Project */}
        <div>
          <ATMSelect
            label="Project"
            name="project"
            value={values.project}
            placeholder="Select Project"
            options={projectOptions}
            onChange={(newValue) => setFieldValue("project", newValue)}
          />
        </div>

        {/* Created At */}
        <div className="mb-2">
          <div className="text-slate-500 font-medium text-sm"> Actual  </div>
          <div className="flex gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                maxDate={moment().format("yyyy-MM-DD")}
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>

        <div>
          <ATMSelect
            name="mode"
            label="Mode Type"
            placeholder="Select Mode Type"
            value={values.mode}
            options={[
              {
                label: "ONLINE ",
                value: "ONLINE",
              },
              {
                label: "OFFLINE",
                value: "OFFLINE",
              },
              {
                label: "CALL",
                value: "CALL",
              },
            ]}
            onChange={(newValue) => {
              setFieldValue("mode", newValue);
            }}
          />
        </div>

        <div>

          <ATMRadioButtonGroup
            label="Meeting Type"
            required={false}
            value={values.meetingTypeFilter}
            options={[
              {
                label: "Conversation ",
                value: "CONVERSATION",
              },
              {
                label: "Scheduled",
                value: "SCHEDULED",
              },
              {
                label: "Internal",
                value: "INTERNAL",
              },
              {
                label: "Quick",
                value: "QUICK",
              },
            ]}
            allowMultipleSelection
            onChange={(newValue) => {
              setFieldValue("meetingTypeFilter", newValue);
            }}
          />
        </div>
        <div>
          <ATMSelect
            required
            name="meetingWith"
            label="Meeting With"
            placeholder="Select Meeting With"
            value={values.meetingWith}
            options={[
              {
                label: "Internal Team",
                value: "INTERNAL_TEAM",
              },
              {
                label: "Client",
                value: "CLIENT",
              },
              {
                label: "Internal Team (Third Party)",
                value: "THIRD_PARTY_WITH_INTERNAL_TEAM",
              },
              {
                label: "Client (Third Party)",
                value: "THIRD_PARTY_WITH_CLIENT",
              },
            ]}
            onChange={(newValue) => {
              setFieldValue("meetingWith", newValue);
            }}
          />
        </div>

        <div className="">
          <ATMSelect
            name="codioticUser"
            value={values.codioticUser}
            onChange={(newValue) => {
              setFieldValue("codioticUser", newValue);
            }}
            label="Codiotic User"
            placeholder="Select Codiotic User"
            isLoading={isCodioticUserDataLoading}
            options={codioticUsers?.map((ele: CodioticUserListResponse) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
          />
        </div>


        <div>

          <ATMRadioButtonGroup
            label="Not Completed"
            required={false}
            value={values.notCompletedMeeting}
            options={[
              {
                label: "Ongoing Meeting",
                value: "ONGOING",
              },
            ]}
            onChange={(newValue) => {
              setFieldValue("notCompletedMeeting", newValue);
            }}
          />
        </div>
      </div>
   </MOLFilterDialog>
  );
};

export default MeetingListFilterFormDialog;
