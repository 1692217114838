import apiSlice from "./ApiSlice";

export const expenseCategoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllExpenseCategory: builder.query({
      providesTags: ["expence-category"],
      query: () => {
        return {
          url: "/expence-category",
          method: "GET",
        };
      },
    }),
    // Get expenseCategory with pagination
    getExpenseCategorys: builder.query({
      providesTags: ["expence-category"],
      query: (body) => {
        return {
          url: "/expence-category",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addExpenseCategory: builder.mutation({
      invalidatesTags: ["expence-category"],
      query: (body) => {
        return {
          url: "/expence-category/add",
          method: "POST",
          body,
        };
      },
    }),

    deleteExpenseCategory: builder.mutation({
      invalidatesTags: ["expence-category"],
      query: (id: any) => ({
        url: `/expence-category/${id}`,
        method: "DELETE",
      }),
    }),


    //Edit
    updateExpenseCategory: builder.mutation({
      invalidatesTags: ["expence-category"],
      query: ({ id, body }) => {
        return {
          url: `/expence-category/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get expenseCategory by ID
    getExpenseCategoryById: builder.query({
      providesTags: ["expence-category"],
      query: (id) => {
        return {
          url: `/expence-category/${id}`,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useGetAllExpenseCategoryQuery,
  useGetExpenseCategorysQuery,
  useAddExpenseCategoryMutation,
  useUpdateExpenseCategoryMutation,
  useDeleteExpenseCategoryMutation,
  useGetExpenseCategoryByIdQuery
} = expenseCategoryApi;
