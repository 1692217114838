import { createSlice, Slice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  authTokenKeyName,
  refreshTokenKeyName,
} from "src/utils/configs/authConfig";

export type userData = {
  email: string;
  fullName: string;
  mobile: string;
  userId: string;
  userName: string;
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  userType: string;
};

export interface AuthSLiceStateType {
  accessToken: string | null;
  refreshToken: string | null;
  userAccess: string | null;
  userData: userData | null;
  taskNotificationData: any;
  isTaskIsInPrgoress: boolean;
  permissions: null | string[]
}

const initialState: AuthSLiceStateType = {
  accessToken: localStorage.getItem(authTokenKeyName) || null,
  refreshToken: localStorage.getItem(refreshTokenKeyName) || null,
  userAccess: null,
  userData: null,
  taskNotificationData: [],
  isTaskIsInPrgoress: false,
  permissions: null 
};

const authSlice: Slice<AuthSLiceStateType> = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string | null>) => {
      state.refreshToken = action.payload;
    },

    resetState: () => {
      return initialState;
    },
    setUserAccess: (state, action: PayloadAction<string | null>) => {
      state.userAccess = action.payload;
    },
    setUserData: (state, action: PayloadAction<userData | null>) => {
      state.userData = action.payload;
    },
    setTaskNotificationData: (state, action: PayloadAction<any>) => {
      state.taskNotificationData = action.payload;
    },
    setIsTaskInProgress: (state, action: PayloadAction<boolean>) => {
      state.isTaskIsInPrgoress = action.payload;
    },
    setPermissions: (state, action: PayloadAction<null | []>) => {
      state.permissions = action.payload;
    },
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  resetState,
  setUserAccess,
  setUserData,
  setTaskNotificationData,
  setIsTaskInProgress,
  setPermissions
} = authSlice.actions;
export default authSlice.reducer;
