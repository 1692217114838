import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import {
  useAddPrepareChangeRequestMutation,
  useGetChangeRequestByIdQuery,
  useGetEstTimeForPrepareMeetingQuery,
} from "src/services/ChangeRequestServices";
import { showToast } from "src/utils/showToaster";
import PrepareChangeRequestForm from "./PrepareChangeRequestForm";

export type PrepareChangeRequestFormValue = {
  meetings: {
    meetingId: string;
    meetingTitle: string;
    actualCodioticUser: number;
    meetingTimeInMins: number;
    totalMainHrs: number;
    actualStartTime: string;
    actualEndTime: string;
  }[];
  analysisMeetings: {
    meetingId: string;
    meetingTitle: string;
    actualCodioticUser: number;
    meetingTimeInMins: number;
    totalMainHrs: number;
    actualStartTime: string;
    actualEndTime: string;
  }[];
  devAndQaTime: number;
  numberOfDeveloper: number;
  deploymentTime: {
    requiredMembers: number;
    timeSpent: number;
  };
  demoTime: {
    requiredMembers: number;
    timeSpent: number;
  };
  costEst: number;
};

type Props = {
  onClose: () => void;
  selectedChangeRequestId: string;
};

const PrepareChangeRequestFormWrapper = ({
  onClose,
  selectedChangeRequestId,
}: Props) => {
  const [addPrepareChangeRequest] = useAddPrepareChangeRequestMutation();
  const [chagneRequestData, setChagneRequestData] = useState<any>();
  const { isLoading, isFetching, data } = useGetChangeRequestByIdQuery(
    selectedChangeRequestId,
    {
      skip: !selectedChangeRequestId,
    }
  );
  //get estimation time for dev and QA
  const { data: estimationData } = useGetEstTimeForPrepareMeetingQuery(
    selectedChangeRequestId,
    {
      skip: !selectedChangeRequestId,
    }
  );
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setChagneRequestData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: PrepareChangeRequestFormValue = {
    meetings: chagneRequestData?.meetings || [],
    analysisMeetings: chagneRequestData?.analysisMeetings || [],
    devAndQaTime: chagneRequestData?.devAndQaTime || estimationData?.data,
    numberOfDeveloper: chagneRequestData?.numberOfDeveloper || 0,

    deploymentTime: {
      requiredMembers: chagneRequestData?.deploymentTime?.requiredMembers || 0,
      timeSpent: chagneRequestData?.deploymentTime?.timeSpent || 0,
    },
    demoTime: {
      requiredMembers: chagneRequestData?.demoTime?.requiredMembers || 0,
      timeSpent: chagneRequestData?.demoTime?.timeSpent || 0,
    },
    costEst: chagneRequestData?.costEst || 0,
  };

  // Validation Schema
  // const validationSchema = object().shape({
  //   clientId: object().required("Please select client"),
  //   requestName: string().required("Please enter request name"),
  //   projectId: object().required("Please select project"),
  // });

  // Handle Submit
  const handleSubmit = (
    values: PrepareChangeRequestFormValue,
    { setSubmitting, resetForm }: FormikHelpers<PrepareChangeRequestFormValue>
  ) => {
    const { meetings, analysisMeetings } = values;

    const formatedMeetingValues = meetings?.map((ele) => {
      const { actualStartTime, actualEndTime, ...rest } = ele;
      return {
        ...rest,
      };
    });
    const formatedAnalysisMeetings = analysisMeetings?.map((ele) => {
      const { actualStartTime, actualEndTime, ...rest } = ele;
      return {
        ...rest,
      };
    });

    addPrepareChangeRequest({
      id: selectedChangeRequestId,
      body: {
        meetings: formatedMeetingValues,
        analysisMeetings: formatedAnalysisMeetings,
        devAndQaTime: values.devAndQaTime,
        numberOfDeveloper: values.numberOfDeveloper,
        deploymentTime: values.deploymentTime,
        demoTime: values.demoTime,
        costEst: values.costEst,
      },
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xl" fullWidth fullScreen>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <PrepareChangeRequestForm
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default PrepareChangeRequestFormWrapper;
