import { useState } from "react";
import AddFeatureNameWrapper from "./AddFeatureName/AddFeatureNameWrapper";
import LocationListView from "./LocationListView";

type Props = {
  features: any;
  featureId: any;
  featureIsLoading: any;
  singleModuleData: any;
};

const ProjectFeature = ({
  features,
  featureId,
  featureIsLoading,
  singleModuleData,
}: Props) => {
  const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  return (
    <>
      <LocationListView
        featureIsLoading={featureIsLoading}
        listHeading={"Features"}
        featureId={featureId}
        onAddClick={() => {
          setIsOpenAddFormDialog(true);
        }}
        OnSearchChange={setSearchValue}
        searchValue={searchValue}
        listData={features}
        disabled={false}
      />
      {isOpenAddFormDialog ? (
        <AddFeatureNameWrapper
          singleModuleData={singleModuleData}
          onClose={() => setIsOpenAddFormDialog(false)}
        />
      ) : null}
    </>
  );
};

export default ProjectFeature;
