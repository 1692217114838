import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import { useAddPlatformsMutation } from "src/services/RequirementGatheringService";
import PlatformForm from "../Layouts/PlatformForm";

type Props = {
  onClose: () => void;
  workspaceId: string;
};

export type PlatFormFormValues = {
  platformName: string;
};

const AddPlatformWrapper = ({ onClose, workspaceId }: Props) => {
  // const { id } = useParams();

  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addPlatForm] = useAddPlatformsMutation();

  // Form Initial Values
  const initialValues: PlatFormFormValues = {
    platformName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    platformName: string().required("Please enter platform name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: PlatFormFormValues,
    { setSubmitting, resetForm }: FormikHelpers<PlatFormFormValues>
  ) => {
    const formattedValues = {
      workspaceId: workspaceId || "",
      platformName: values?.platformName,
    };

    addPlatForm(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <PlatformForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddPlatformWrapper;
