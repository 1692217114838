import React from "react";
import { useNavigate } from "react-router-dom";
import ATMLoadingMenuItemButton from "src/components/UI/atoms/ATMLoadingMenuItemButton/ATMLoadingMenuItemButton";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  onAddNew: () => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const LeaveRequestList = ({
  columns,
  rows,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <SideNavLayout>
        <div className="flex flex-col h-full">
          {/* Page Header */}
          <div className="flex items-center gap-2 ">
            <ATMPageHeader
              pageTitle="Leave Request"
              searchValue={searchValue}
              onSearchChange={setSearchValue}
              hideAddButton
            />
            {isAuthorized(UserModuleNameTypes.ACTION_LEAVE_REQUEST_ADD) && (
              <div className="flex items-end justify-end mt-5 md:mt-0">
                <ATMLoadingMenuItemButton />
              </div>
            )}
          </div>

          {/* Table */}
          <div className="flex flex-col overflow-hidden">
            <div className="flex flex-col overflow-auto">
              <ATMTable
                extraClasses="bg-white"
                columns={columns}
                rows={rows}
                onRowClick={(row) => navigate(`view/${row?._id}`)}
                disableRowClick={
                  !isAuthorized(
                    UserModuleNameTypes.ACTION_LEAVE_REQUEST_SINGLE_VIEW
                  )
                }
                rowExtraClasses={() => "h-[40px]"}
                isLoading={isTableLoading}
              />
            </div>

            <div>
              <ATMPagination
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={setPage}
                onRowsPerPageChange={setRowsPerPage}
                rowCount={totalItems}
                rows={rows}
              />
            </div>
          </div>
        </div>
      </SideNavLayout>
    </>
  );
};

export default LeaveRequestList;
