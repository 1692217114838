/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { CircularProgress, Divider } from "@mui/material";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdChevronRight, MdDelete, MdEdit } from "react-icons/md";
import EditTaskFormWrapper from "../Edit/EditTaskFormWrapper";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { useDeleteTaskByIdMutation } from "src/services/TaskService";
import AssignToDialogWrapper from "./AssignToDialog/AssignToDialogWrapper";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import { BiSearch } from "react-icons/bi";
import { FaFileArchive } from "react-icons/fa";
import { IoIosGitBranch } from "react-icons/io";
import { IoChevronDown, IoDuplicate, IoFilter } from "react-icons/io5";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { RxDividerVertical } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import AddTaskFormWrapper from "../Add/AddTaskFormWrapper";
import DuplicateTaskFormWrapper from "../Duplicate/DuplicateTaskFormWrapper";
import TaskFilterFormCardDialogWrapper from "./AllTaskFilterFormCard/TaskFilterFormCardDialogWrapper";
import StatusChangeDialogWrapper from "./StatusChangeDialog/StatusChangeDialogWrapper";
import TimelineTaskWrapper from "./TimelineTask/TimelineTaskWrapper";
import useMoveTaskToArchive from "src/hooks/useMoveTaskToArchive";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {
  rows: any[];
  singleItem: any;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const TaskListing = ({
  rows,
  singleItem,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenTaskAllFilterDialog, setIsOpenTaskAllFilterDialog] =
    useState<boolean>(false);
  const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);
  const [copiedIndexes, setCopiedIndexes] = useState<number[]>([]);
  const [isOpenAssignTo, setIsOpenAssignTo] = useState(false);
  const [isOpenStatusChange, setIsOpenStatusChange] = useState(false);
  const [assigneeId, setAssigneeId] = useState<any>({});
  const [labelId, setLabelId] = useState<any>({});
  const [formType, setFormType] = useState("");

  const [projectId, setProjectId] = useState("");

  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [isOpenDuplicateDialog, setIsOpenDuplicateDialog] =
    useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>("");
  const [isTaskCollapse, setTaskCollapse] = useState(false);
  const navigate = useNavigate();
  const [deleteTask] = useDeleteTaskByIdMutation();
  const { handleMoveTaskToArchive } = useMoveTaskToArchive();
  const handleCopyClick = (index: number, ticketTitle: string) => {
    navigator.clipboard.writeText(ticketTitle);
    setCopiedIndexes([...copiedIndexes, index]);

    // Reset the copy state after a brief delay
    setTimeout(() => {
      setCopiedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
    }, 1500);
  };

  const handleDeleteTask = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Task?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteTask(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };
  // const handleTicketToTask = () => {
  //   const formattedValuesForTask = {
  //     title: singleItem?.[0]?.title,
  //     description: singleItem?.[0]?.description,
  //     relatedTo: "TICKET",
  //     ticketId: singleItem?.[0]?._id,
  //   };

  //   addTask(formattedValuesForTask).then((res: any) => {
  //     if (res?.error) {
  //       showToast("error", res?.error?.data?.message);
  //     } else {
  //       if (res?.data?.status) {
  //         showToast("success", res?.data?.message);
  //       } else {
  //         showToast("error", res?.data?.message);
  //       }
  //     }
  //   });
  // };
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div
          className={`flex gap-2 p-4 border-b shadow-sm ${isTaskCollapse ? "justify-end items-center" : "flex-col"
            }`}
        >
          <div
            className={`flex items-center  ${isTaskCollapse ? "" : "justify-between"
              }`}
          >
            <div className="flex gap-2 items-center">
              {/* Search Box */}
              {isTaskCollapse ? null : (
                <div className="w-[300px]">
                  <ATMInputAdormant
                    name=""
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    adormant={<BiSearch />}
                    adormantProps={{
                      position: "end",
                      extraClasses: "bg-white border-0",
                    }}
                    inputProps={{ className: "bg-white" }}
                    placeholder="Search..."
                  />
                </div>
              )}
              {/* Filter Button */}
              <ATMLoadingButton
                    variant="tonal"
                    onClick={() => setIsOpenTaskAllFilterDialog(true)}
                  >
                    <div className="flex items-center gap-2">
                      <IoFilter />
                      Filters
                    </div>
                  </ATMLoadingButton>

           
              <TaskFilterFormCardDialogWrapper
                open={isOpenTaskAllFilterDialog}
                onClose={() => setIsOpenTaskAllFilterDialog(false)}
              />
            </div>
            <div className="flex justify-end gap-2">
              <div className={` ${isTaskCollapse ? "ml-3" : null}`}>
                <ATMLoadingButton
                  // disabled={singleItem[0]?.status === "UNMAPPED"}
                  title="Please assign the ticket first"
                  onClick={() => {
                    setIsOpenAddFormDialog(true);
                    setProjectId(singleItem[0]?.projectId);
                    setFormType("ADD_TASK");
                  }}
                >
                  Add New
                </ATMLoadingButton>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[100vh] overflow-y-scroll overflow-x-hidden bg-gray-50">
          {isTableLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <CircularProgress />{" "}
            </div>
          ) : rows?.length ? (
            <>
              {isTaskCollapse ? (
                <TimelineTaskWrapper
                  setTaskCollapse={setTaskCollapse}
                  TASK_ID={taskId}
                />
              ) : (
                rows?.map((row: any, index: any) => {
                  return isTableLoading ? (
                    Array(10)
                      .fill(0)
                      ?.map((_, index) => {
                        return (
                          <div
                            key={index}
                            className="animate-pulse  h-[50px] p-2"
                          >
                            <div className="h-full rounded"></div>
                          </div>
                        );
                      })
                  ) : (
                    <div className="md:px-4 px-2 pt-4 last:pb-4">
                      <div
                        key={index}
                        className={`rounded-md bg-white border border-slate-200 hover:border-primary-dark max-w-[100vw] overflow-x-scroll transition-all duration-500 hover:shadow-md`}
                      >
                        <div className="rounded-md bg-white">
                          <div className="">
                            {/* ticket header */}
                            <div className="flex items-center gap-2 pl-4 pr-2">
                              <p className="font-medium text-sm leading-none flex items-center">
                                {row?.taskNumber}
                              </p>
                              <RxDividerVertical className="text-xl text-neutral" />
                              <p className="flex items-center">
                                <span className="font-medium text-xs flex items-center gap-1">
                                  {`${row?.layer[0]?.layerName || "-"} `}
                                  <MdChevronRight className="text-gray-600" />
                                  {` ${row?.projectName || "-"} `}
                                  <MdChevronRight className="text-gray-600" />
                                  {` ${row?.moduleName || "-"} `}
                                  <MdChevronRight className="text-gray-600" />
                                  {` ${row?.featureName || "-"}`}
                                </span>
                              </p>

                              {/* First div */}

                              <div className="flex-grow" />
                              {/* Second div */}

                              <div className="items-end">
                                <ATMActionMenu
                                  options={[
                                    {
                                      label: (
                                        <div className="flex gap-x-2 items-center">
                                          <MdEdit color="#000000" /> Edit
                                        </div>
                                      ),
                                      onClick: () => {
                                        setIsOpenEditFormDialog(true);
                                        setTaskId(row?._id);
                                        setProjectId(singleItem[0]?.projectId);
                                      },
                                      acitonName: UserModuleNameTypes.ACTION_TASK_EDIT
                                    },
                                    {
                                      label: (
                                        <div className="flex gap-x-2 items-center">
                                          <IoDuplicate color="#000000" />
                                          Duplicate
                                        </div>
                                      ),
                                      onClick: () => {
                                        setIsOpenDuplicateDialog(true);
                                        setTaskId(row?._id);
                                        setProjectId(singleItem[0]?.projectId);
                                      },
                                      acitonName: UserModuleNameTypes.ACTION_TASK_DUPLICATE
                                    },
                                    {
                                      label: (
                                        <div className="flex gap-x-2 items-center">
                                          <FaFileArchive color="#000000" /> Move
                                          to Archive
                                        </div>
                                      ),
                                      onClick: () => {
                                        handleMoveTaskToArchive(row?._id);
                                      },
                                      acitonName: UserModuleNameTypes.ACTION_TASK_MOVE_TO_ARCHIVE

                                    },
                                    {
                                      label: (
                                        <div className="flex gap-x-2 items-center">
                                          <MdDelete color="#000000" /> Delete
                                        </div>
                                      ),
                                      onClick: () => {
                                        handleDeleteTask(row?._id);
                                      },
                                      acitonName: UserModuleNameTypes.ACTION_TASK_DELETE

                                    },

                                  ]}
                                />
                              </div>

                              {/* Third div */}
                            </div>
                            {/* ticket header - end */}
                            <Divider />

                            <div className="p-4 flex justify-between gap-4 w-full">
                              <div className="flex flex-col gap-2">
                                <div
                                  className="flex flex-col gap-1"
                                  onClick={() => {
                                    // handleDivClick(row?._id)
                                    navigate(`${row?._id}`);
                                  }}
                                >
                                  <p className="cursor-pointer text-lg">
                                    {row?.title}
                                  </p>
                                </div>
                                {/* branch name copy */}
                                {/* <div
                                  onClick={() =>
                                    handleCopyClick(index, row?.branchName)
                                  }
                                  className="hidden md:flex items-center w-fit bg-gray-100  hover:bg-gray-300 text-xs px-2 py-1 gap-4 rounded-xl hover:rounded-full transition-all ease-linear cursor-pointer border border-white hover:border"
                                >
                                  <span className="flex items-center gap-1">
                                    <IoIosGitBranch /> {row?.branchName}
                                  </span>

                                  <button
                                    className={`focus:outline-none ${
                                      copiedIndexes.includes(index) ? "" : ""
                                    }`}
                                    disabled={copiedIndexes.includes(index)}
                                  >
                                    {copiedIndexes.includes(index) ? (
                                      <LuCopyCheck />
                                    ) : (
                                      <LuCopy />
                                    )}
                                  </button>
                                </div> */}
                              </div>

                              <div className="flex gap-4">
                                {/* Assignee */}
                                <div
                                  className="font-medium text-primary text-sm flex items-center gap-4 bg-gray-100 px-4 py-2 rounded-lg capitalize cursor-pointer h-fit"
                                  onClick={() => {
                                    setIsOpenAssignTo(true);
                                    setTaskId(row?._id);
                                    setProjectId(row?.projectId);
                                    setAssigneeId([
                                      row?.assignedTo,
                                      row?.assigneeName,
                                    ]);
                                  }}
                                >
                                  {row?.assignedTo === null
                                    ? "Unassigned"
                                    : row?.assigneeName}
                                  <p>
                                    <MdEdit />
                                  </p>
                                </div>
                                {/* Status */}
                                <div
                                  className={`font-medium text-primary text-sm flex items-center gap-4 bg-primary-main text-white px-4 py-2 rounded-lg capitalize cursor-pointer h-fit ${row?.assignedTo === null
                                      ? "disabled text-slate-300"
                                      : ""
                                    }`}
                                  onClick={() => {
                                    if (row?.assignedTo !== null) {
                                      setIsOpenStatusChange(true);
                                      setTaskId(row?._id);
                                      setProjectId(row?.projectId);
                                      setAssigneeId([
                                        row?.assignedTo,
                                        row?.assigneeName,
                                      ]);
                                      setLabelId(row?.label);
                                    }
                                  }}
                                  title={
                                    row?.assignedTo === null
                                      ? "Please assign the task"
                                      : ""
                                  }
                                >
                                  {row?.label[0]?.labelName}
                                  <IoChevronDown />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </>
          ) : (
            <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-[22px]">
              Data not Found
            </div>
          )}
        </div>

        <div className="p-4 py-2 border-t">
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
      {/* {isTicketToTaskOpen ? (
        <AddTaskFormWrapper
          onClose={() => setIsOpenAddFormDialog(false)}
          id={projectId}
        />
      ) : null} */}
      {isOpenAddFormDialog ? (
        <AddTaskFormWrapper
          onClose={() => setIsOpenAddFormDialog(false)}
          id={projectId}
          formType={formType}
        />
      ) : null}
      {isOpenEditFormDialog ? (
        <EditTaskFormWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedTask={taskId}
        />
      ) : null}
      {isOpenDuplicateDialog ? (
        <DuplicateTaskFormWrapper
          onClose={() => setIsOpenDuplicateDialog(false)}
          selectedTask={taskId}
          projectId={projectId}
        />
      ) : null}

      {isOpenAssignTo ? (
        <AssignToDialogWrapper
          onClose={() => setIsOpenAssignTo(false)}
          projectId={projectId}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}
      {isOpenStatusChange ? (
        <StatusChangeDialogWrapper
          onClose={() => setIsOpenStatusChange(false)}
          projectId={projectId}
          labelId={labelId}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}
    </>
  );
};

export default TaskListing;
