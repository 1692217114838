import React, { useEffect } from "react";
import ProjectList from "./ProjectList";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/ProjectSlice";
import {
  useDeleteProjectByIdMutation,
  useGetAllProjectsPaginationQuery,
} from "src/services/ProjectServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdDelete, MdEdit } from "react-icons/md";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

type ProjectListResponse = {
  _id: string;
  projectName: string;
  clientOrgLabel: string;
  isDeleted: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

const ProjectListWrapper = (props: Props) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Check if the current path starts with "/client"
  const isClientRoute = currentPath.startsWith("/client");
  const { clientId } = useParams();

  const dispatch = useDispatch();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.project);
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetAllProjectsPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["projectName"],
    page: page,
    filterBy: [
      ...(isClientRoute
        ? [
            {
              fieldName: "clientId",
              value: clientId,
            },
          ]
        : []),
    ],
    dateFilter: {},
    orderBy: "projectName",
    orderByValue: 1,
    isPaginationRequired: true,
  });

  // Setting Project Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const [deleteProject] = useDeleteProjectByIdMutation();

  const handleDeleteProject = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Project?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteProject(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "projectName",
      headerName: "Project Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[180px]",
      name: UserModuleNameTypes.PROJECTS_LIST_PROJECT_NAME,
      renderCell: (row: ProjectListResponse) => (
        <div className="capitalize"> {row?.projectName} </div>
      ),
    },
    {
      field: "clientOrgLabel",
      headerName: "Client",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[180px]",
      name: UserModuleNameTypes.PROJECTS_LIST_CLIENT,
      renderCell: (row: ProjectListResponse) => (
        <div className="capitalize"> {row?.clientOrgLabel} </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      // flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[100px]",
      renderCell: (row: ProjectListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  navigate(`/project/${row?._id}`);
                },
                acitonName: UserModuleNameTypes.ACTION_PROJECT_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDeleteProject(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_PROJECT_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <ProjectList
        columns={columns}
        isClientRoute={isClientRoute}
        onAddNew={() => navigate("/project/add")}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default ProjectListWrapper;
