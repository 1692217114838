import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import { AddTicketFormValues } from "src/models/Ticket.model";
import { useAddTicketMutation } from "src/services/TicketServices";
import { useAddTaskMutation } from "src/services/TaskService";
import TicketForm from "../Layout/TicketForm";
import { FILE_FORMAT } from "src/models/ResourceRequest.model";
import { isMobile } from "react-device-detect";
import { setSaveNextChecked } from "src/redux/slices/TicketSlice";
import { RootState } from "src/redux/store";
import { useSelector } from "react-redux";

type Props = {
  onClose: () => void;
  meetingId?: string;
  title?: string;
  description?: string;
  projectId?: string;
  sourceName?: string;
  projectName?: string;
  
};

const AddTicketFormWrapper = ({ onClose, meetingId,
  title,
  description,
  projectId,
  sourceName,
  projectName }: Props) => {
  const [isCheckedAddTask, setIsCheckedAddTask] = useState<boolean>(false);
  const [addTicket] = useAddTicketMutation(); // add ticket
  const [addTask] = useAddTaskMutation(); // add task

  const {
    saveNextChecked,
  } = useSelector((state: RootState) => state.ticket);

  // Form Initial Values
  const initialValues: AddTicketFormValues = {
    projectId:  projectId && projectName ?  {
      _id: projectId,
      projectName: projectName,
    } : " ",
        title: title ? title : "",
    priority: "",
    changeRequestId: "",
    description: description ? description : "",
    sourceName: sourceName ? sourceName : "",
    attachments: [],
    tags : [],
    sourceId : meetingId ? meetingId : null,
    isVisibleToClient : false
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select project"),
    title: string().required("Please enter title"),
    priority: object().required("Please select priority"),
    description: string(),
  });

  // Handle Submit
  const handleSubmit = (
    values: AddTicketFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AddTicketFormValues>
  ) => {
    const { projectId, priority, changeRequestId, sourceName, attachments, ...rest } =
      values;
    const formatedValue = {
      ...rest,
      projectId: projectId?._id,
      priority: priority?.value,
      changeRequestId: changeRequestId.value,
      sourceName: sourceName,
      attachments: values?.attachments?.map(
        (attachment: {
          url: string;
          type: "images" | "pdfs" | "videos" | "xlsx";
        }) => {
          return {
            url: attachment?.url,
            type: FILE_FORMAT[attachment.type],
          };
        }
      ),
    };

    // Add Ticket
    addTicket(formatedValue).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });

    const formattedValuesForTask = {
      title: values.title,
      description: values.description,
      relatedTo: "TICKET",
    };

    isCheckedAddTask &&
      addTask(formattedValuesForTask).then((res: any) => {
        setSubmitting(false);
      });
  };

  return (
    <Dialog open maxWidth="md" fullWidth fullScreen={isMobile}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <TicketForm
              formikProps={formikProps}
              formType="ADD"
              setSaveNextChecked={setSaveNextChecked}
              saveNextChecked={saveNextChecked}
              isCheckedAddTask={isCheckedAddTask}
              setIsCheckedAddTask={setIsCheckedAddTask}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddTicketFormWrapper;
