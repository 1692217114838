import { FormikProps } from "formik";
import React from "react";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFlowChart from "src/components/UI/molecules/MOLFlowChart/MOLFlowChart";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";



type Props = {
  formikProps: FormikProps<any>;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const ProjectFlowLayout = ({
  formikProps,
  formType,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">
          {formType === "ADD" ? "ADD New" : "Update"} Project Flow
        </div>
      </div>

      <div className="flex flex-col gap-4">
        {/* Flow Name */}
        <div>
          <ATMTextField
            required
            name="flowName"
            value={values.flowName}
            onChange={(e) => setFieldValue("flowName", e.target.value)}
            label="Flow Name"
            placeholder="Enter Flow Name"
            onBlur={handleBlur}
          />
        </div>

        {/* Flow */}
        <div className="z-0">
          <MOLMarkdownEditor
            label="flow"
            value={values.flow}
            placeholder="Write Flow...."
            onChange={(newValue: any) => {
              setFieldValue("flow", newValue);
            }}
          />
        </div>

        {/* Flow Chart */}
        <div className="h-[600px] w-full border p-2 mt-4">
          <label className="text-[18px] font-medium">Flow Chart</label>
          <MOLFlowChart
            initialNodes={values?.flowChartData?.nodes} 
            initialEdges={values?.flowChartData?.edges} 
            onSubmit={(nodes , edges)=>{
              setFieldValue('flowChartData.nodes' , nodes) ;
               setFieldValue('flowChartData.edges' , edges)
            }}
          />
        </div>
      </div>
      {formType === "ADD" && (
        <div className="flex justify-between">
          <div>
            <ATMCheckbox
              label="Save and Next"
              checked={saveNextChecked}
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        </div>
      )}
      <div className="flex justify-end">
        <ATMLoadingButton
          className="w-[120px]"
          isLoading={isSubmitting}
          type="submit"
        >
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default ProjectFlowLayout;
