import moment from "moment";
type Props = {
  deploymentRequest: {
    count: number;
    pendingDeploymentRequest: any;
  };
  onDeploymentRequestClick: () => void;
};

export const DeploymentRequest = ({
  deploymentRequest,
  onDeploymentRequestClick,
}: Props) => {
  return (
    <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg custom-shadow">
      <div className="flex items-center gap-2 p-2 border-b bg-cyan-50">
        <div className="flex items-center justify-center p-2 font-semibold bg-white border rounded-md border-primary-dark size-8">
          {deploymentRequest?.count}
        </div>
        <p className="font-medium">Deployment Requests</p>
      </div>

      {deploymentRequest?.pendingDeploymentRequest?.length ? (
        <div className="h-full py-2 overflow-auto">
          {deploymentRequest?.pendingDeploymentRequest?.map(
            (deploymentResourceData: any, index: any) => (
              <div
                key={index}
                className="flex items-start justify-between px-4 py-1 cursor-pointer hover:bg-hover text-sm"
                onClick={onDeploymentRequestClick}
              >
                <div>{deploymentResourceData?.projectName}</div>
                <div className="w-[150px] text-end  text-primary-main  text-sm font-medium">
                  {moment(deploymentResourceData?.createdAt).format(
                    "DD MMM YYYY"
                  )}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-4">
          No Deployments Requests found
        </div>
      )}
    </div>
  );
};
