import moment from "moment";
import React from "react";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";

type Props = {
  columns: columnTypes[];
  categoryData: any;
  isLoading: boolean;
  setIncidentCategory: any;
  incidentCategory: any;
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const PerformanceListing = ({
  columns,
  isLoading,
  categoryData,
  incidentCategory,
  setIncidentCategory,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  return (
    <>
      {isLoading ? (
        <div className="grid grid-cols-4">
          {Array(4)
            .fill(0)
            .map((_, index) => {
              return (
                <div
                  key={index}
                  className="animate-pulse  h-[250px] p-1 rounded-lg m-4"
                >
                  <div className="bg-slate-200 h-full rounded"> </div>
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <div className="grid  xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 p-4">
            <ATMDatePicker
              views={["month", "year"]}
              format="MMM yyyy"
              value={
                searchValue
                  ? searchValue
                  : moment().startOf("month").format("YYYY-MM-DD")
              }
              onChange={(newValue) => setSearchValue(newValue)}
            />
          </div>
          <div className="px-9 flex justify-between items-center">
            <div className="text-lg md:text-xl font-semibold">
              {categoryData?.totalMonthlyWeightage} Pointers{" "}
              <span className="text-red-600 text-[14px] font-medium">
                {`(+${
                  categoryData?.totalPreviousMonthWeightage <
                  categoryData?.totalMonthlyWeightage
                    ? categoryData?.totalMonthlyWeightage -
                      categoryData?.totalPreviousMonthWeightage
                    : 0
                } than previous month)`}
              </span>
            </div>
          </div>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 gap-6 p-4">
            {categoryData?.data?.map((el: any, ind: any) => {
              return (
                <div
                  key={ind}
                  className={`shadow-lg p-4 rounded-lg ${
                    incidentCategory === el?.categoryId ? "bg-amber-100" : ""
                  }`}
                >
                  <div className="flex justify-between">
                    <p className="capitalize font-medium text-[15px]">
                      {el?.categoryName}{" "}
                    </p>
                    <p className="text-red-600 text-[12px] font-medium">
                      Today +{el?.todayWeightage}
                    </p>
                  </div>
                  <p className="text-xl my-3">{el?.monthlyWeightage} </p>
                  <p className="text-red-600">
                    {" "}
                    +
                    {el?.previousMonthWeightage < el?.monthlyWeightage
                      ? el?.monthlyWeightage - el?.previousMonthWeightage
                      : 0}{" "}
                    from last month
                  </p>
                  <div className="border-t-2 px-2 pt-3 mt-1">
                    <span
                      className="border-b font-medium text-xs cursor-pointer"
                      onClick={() => setIncidentCategory(el?.categoryId)}
                    >
                      View Details
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {incidentCategory !== "" && (
            <div className="flex flex-col  p-4">
              {/* Page Header */}

              {/* Table */}
              <div className="flex-1 overflow-auto flex flex-col">
                <ATMTable
                  columns={columns}
                  rows={rows}
                  rowExtraClasses={() => "min-h-[40px]"}
                  isLoading={isTableLoading}
                />
              </div>

              <div className="p-4 py-2 flex justify-end">
                <ATMPagination
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={setPage}
                  onRowsPerPageChange={setRowsPerPage}
                  rowCount={totalItems}
                  rows={rows}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PerformanceListing;
