import React, { useState, useEffect, Suspense } from "react";
import ATMRadioButtonGroup from "src/components/UI/atoms/formFields/ATMRadioButtonGroup/ATMRadioButtonGroup";
import MOLProjecttDropdown from "src/components/UI/molecules/MOLProjectDropdown/MOLProjectDropdown";
import { ticketTypes } from "src/utils/constants/ticketUtils";
import { FormikProps } from "formik";
import { FormInitialValues } from "./AssignTicketDetailsModalWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetModulesByProjectIdQuery } from "src/services/ModuleServices";
import { useGetAllRequirementByModuleIdQuery } from "src/services/RequirementService";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { setIsOpenAddDialog } from "src/redux/slices/ModuleSlice";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import AddModuleFormWrapper from "src/screens/Module/Add/AddModuleFormWrapper";
import { RootState } from "src/redux/store";
import AddassignTicketFeatureFormWrapper from "./AddassignTicketFeature/AddassignTicketFeatureFormWrapper";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  handleClose: () => void;
};

const AssignTicketDetailsModalForm = ({ formikProps, handleClose }: Props) => {
  // Formik
  const { values, setFieldValue, isSubmitting, handleBlur, handleSubmit } =
    formikProps;

  const { isOpenAddDialog } = useSelector((state: RootState) => state.module);
  const [projectValueModule, setProjectValueModule] = useState<any>({});

  const dispatch = useDispatch();
  const [moduleOptions, setModuleOptions] = useState([]);
  const [featureOptions, setFeatureOptions] = useState([]);
  const [isOpenFeatureDialog, setIsOpenFeatureDialog] = useState(false);

  // get module
  const {
    isLoading: isModuleLoading,
    isFetching: isModuleFetching,
    data: moduleData,
  } = useGetModulesByProjectIdQuery(values.projectId?._id || "", {
    skip: !values.projectId?._id,
  });

  useEffect(() => {
    if (!isModuleLoading && !isModuleFetching) {
      const filteredOption = moduleData?.data?.map((ele: any) => {
        return {
          label: ele?.moduleName,
          value: ele?._id,
        };
      });
      setModuleOptions(filteredOption);
    }
  }, [isModuleLoading, isModuleFetching, moduleData]);

  // get features
  const {
    isLoading: isFeatureLoading,
    isFetching: isFeatureFetching,
    data: featureData,
  } = useGetAllRequirementByModuleIdQuery(values.moduleId?.value || "", {
    skip: !values.moduleId?.value,
  });

  useEffect(() => {
    if (!isFeatureLoading && !isFeatureLoading) {
      const filteredOption = featureData?.data?.map((ele: any) => {
        return {
          label: ele?.featureName,
          value: ele?._id,
        };
      });
      setFeatureOptions(filteredOption);
    }
  }, [isFeatureLoading, isFeatureFetching, featureData]);

  useEffect(() => {
    setProjectValueModule(values?.projectId);
  }, [values]);
  
  return (
    <>
      {" "}
      <div className="flex flex-col rounded border border-gray-200 relative">
        {/* Heading & Clear Button */}
        <div className="flex justify-between items-center px-4 py-2 sticky top-0 z-50 bg-white">
          <div className="text-[24px] font-medium"> Ticket details </div>
          <button
            type="button"
            className="text-[#041618] font-medium"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>

        {/* Filter Form */}
        <div className="p-3 overflow-auto flex-1 z-0">
          <div className="flex flex-col gap-4">
            {/* Project */}
            <div className="z-30">
              <MOLProjecttDropdown
                name="projectId"
                label="Project"
                placeholder="Select project"
                value={values?.projectId}
                onBlur={handleBlur}
                onChange={(newValue) => {
                  setFieldValue("projectId", newValue);
                }}
              />
            </div>

            {/* Type */}
            <div className="my-2">
              <ATMRadioButtonGroup
                name="type"
                required={false}
                label="Type"
                value={values.type}
                options={ticketTypes}
                onChange={(newValue) => {
                  setFieldValue("type", newValue);
                }}
              />
            </div>

            {/* Module */}
            <div className="z-20">
              <ATMSelect
                name="moduleId"
                showTopAddButton
                onClickShareAddButton={(value) =>
                  dispatch(setIsOpenAddDialog(value))
                }
                value={values.moduleId}
                onChange={(newValue: any) => {
                  setFieldValue("moduleId", newValue);
                }}
                required
                onBlur={handleBlur}
                label="Module"
                placeholder="Select module"
                options={moduleOptions}
                isLoading={isModuleLoading || isModuleFetching}
                // renderOption={(option, { context }) => {
                //   if (context === "menu") {
                //     return <div className="capitalize"> {option.label} </div>;
                //   } else {
                //     return <div className="capitalize"> {option.label} </div>;
                //   }
                // }}
                // isOptionEqualToValue={(option, selected) => {
                //   return option?.value === selected?.[0]?.value;
                // }}
                // isSearchedOption={(option, searchValue) => {
                //   return searchValue
                //     ? option?.data?.label?.toLowerCase()?.includes(searchValue)
                //     : true;
                // }}
              />
            </div>

            {/* Feature */}
            <div className="z-10">
              <ATMSelect
                name="featureId"
                required
                showTopAddButton
                onClickShareAddButton={() => setIsOpenFeatureDialog(true)}
                value={values.featureId}
                onBlur={handleBlur}
                label="Feature"
                placeholder="Select feature"
                options={featureOptions}
                isLoading={isFeatureLoading || isFeatureFetching}
                onChange={(newValue: any) => {
                  setFieldValue("featureId", newValue);
                }}
                // renderOption={(option, { context }) => {
                //   if (context === "menu") {
                //     return (
                //       <div className="capitalize z-[10000000]">{option.label}</div>
                //     );
                //   } else {
                //     return <div className="capitalize z-[10000000]">{option.label}</div>;
                //   }
                // }}
                // isOptionEqualToValue={(option, selected) => {
                //   return option?.value === selected?.[0]?.value;
                // }}
                // isSearchedOption={(option, searchValue) => {
                //   return searchValue
                //     ? option?.data?.label?.toLowerCase()?.includes(searchValue)
                //     : true;
                // }}
              />
            </div>

            {/* Problem Statement */}
            <div className="z-0">
              <MOLMarkdownEditor
                label="Description"
                value={values.description}
                placeholder="Write ticket description or drag your files here...."
                onChange={(newValue: any) => {
                  setFieldValue("description", newValue);
                }}
              />
            </div>
          </div>
        </div>

        {/* Apply & Cancel Buttons */}
        <div className="flex flex-col gap-2 p-3 bg-white">
          <div>
            <ATMLoadingButton
              type="button"
              className="h-[40px] bg-[#041618] font-medium"
              isLoading={isSubmitting}
              onClick={() => handleSubmit()}
            >
              Save
            </ATMLoadingButton>
          </div>
        </div>
      </div>
      <Suspense fallback={<CircularProgress />}>
        {isOpenAddDialog ? (
          <AddModuleFormWrapper
            onClose={() => dispatch(setIsOpenAddDialog(false))}
            projectValueModule={projectValueModule}
          />
        ) : null}
      </Suspense>
      <Suspense fallback={<CircularProgress />}>
        {isOpenFeatureDialog ? (
          <AddassignTicketFeatureFormWrapper
            onClose={() => setIsOpenFeatureDialog(false)}
            singleModuleData={projectValueModule}
            moduleOptions={moduleOptions}
          />
        ) : null}
      </Suspense>
    </>
  );
};

export default AssignTicketDetailsModalForm;
