/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RequirementFormValues } from "src/models/Requirement.model";
import {
  useAddRequirementMutation,
  useGetRequirementByIdQuery,
} from "src/services/RequirementService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import DuplicateRequirementForm from "./DuplicateRequirementForm";

type Props = {
  moduleItems?: any;
};
const featureType = [
  {
    label: "Other",
    value: "OTHER",
  },
  {
    label: "Table",
    value: "TABLE",
  },
  {
    label: "Form",
    value: "FORM",
  },
  {
    label: "View",
    value: "VIEW",
  },
];

const fieldType = [
  {
    label: "Select",
    value: "SELECT",
  },
  {
    label: "Multi-Select",
    value: "MULTISELECT",
  },
  {
    label: "Text-Field",
    value: "TEXTFIELD",
  },
  {
    label: "Check-box",
    value: "CHECKBOX",
  },
  {
    label: "Radio",
    value: "RADIO",
  },
  {
    label: "Switch",
    value: "SWITCH",
  },
  {
    label: "HTML-Editor",
    value: "HTMLEDITOR",
  },
  {
    label: "TextArea",
    value: "TEXTAREA",
  },
  {
    label: "File Uploader",
    value: "FILEUPLOADER",
  },
];

const DuplicateRequirementFormWrapper = ({ moduleItems }: Props) => {
  // ID
  const { singleFeatureId } = useParams();
  const { id } = useParams();

  //State
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetRequirementByIdQuery(
    singleFeatureId || "",
    {
      skip: !singleFeatureId, // Skipping the query if idOrFeatureId is falsy (null or undefined)
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const navigate = useNavigate();
  const [AddRequirementFormWrapper] = useAddRequirementMutation();

  // Form Initial Values
  const initialValues: RequirementFormValues = {
     projectId: "",
     moduleId: "",
    featureName: items?.featureName,
    tableName: items?.tableName,
    requirementFlow: items?.requirementFlow,
    requirementLogic: items?.requirementLogic,
    featureType: { label: items?.featureType, value: items?.featureType },
    formFields: (items?.formFields || [])?.map((item: any) => ({
      name: item?.name || "",
      type:
        {
          label: item?.type,
          value: item?.type,
        } || "",
      validation: item?.validation || "",
      logic: item?.logic || "",
      isRequired: item?.isRequired,
    })),
    tableFields: (items?.tableFields || [])?.map((item: any) => ({
      columnName: item?.columnName || "",
    })),

    table: (items?.table || [])?.map((item: any) => ({
      tableAction: item?.tableAction || "",
    })),
    columns: (items?.columns || [])?.map((item: any) => ({
      columnActions: item?.columnActions || "",
    })),
    viewFields: (items?.viewFields || [])?.map((item: any) => ({
      tabs: item?.tabs || "",
      detailsVisible: item?.detailsVisible || "",
      logic: item?.logic || "",
    })),
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select project name"),
    moduleId: object().required("Please select module name"),
    featureName: string().required("Please enter feature name"),
    featureType: object().required("Please select feature type"),
  });

  // Handle Submit
  const handleSubmit = (
    values: RequirementFormValues,
    { setSubmitting, resetForm }: FormikHelpers<RequirementFormValues>
  ) => {
    const { projectId, moduleId, featureType, formFields, ...rest } = values;

    const formattedValues = {
      ...rest,
      projectId: projectId?._id,
      moduleId: values?.moduleId?.value,
      featureType: featureType?.value,
      formFields: formFields?.map((formField: any) => ({
        name: formField?.name,
        type: formField?.type?.value,
        validation: formField?.validation,
        logic: formField?.logic,
        dataSource: formField?.dataSource,
        isRequired: formField?.isRequired,
      })),
    };
    AddRequirementFormWrapper(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message); 
          navigate("/requirement");
          resetForm()
        
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <DuplicateRequirementForm
            formikProps={formikProps}
            featureType={featureType}
            fieldType={fieldType}
          />
        </Form>
      )}
    </Formik>
  );
};

export default DuplicateRequirementFormWrapper;
