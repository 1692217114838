import { FormikProps } from 'formik'
import React, { useState } from 'react'
import ATMLoadingButton from 'src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton'
import ATMCheckbox from 'src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox'
import { useJoinMeetingbyIdMutation } from 'src/services/MeetingServices'
// import { showConfirmationDialog } from 'src/utils/showConfirmationDialog'
import { showToast } from 'src/utils/showToaster'

type Props = {
  onClose: any
  formikProps: FormikProps<any>;
  taskData: any
  setTaskIds: any
  submitting: any
  taskIds: any
  meetingStartId: any
}
const HoldStartMeetingTask = ({ onClose, meetingStartId, formikProps, taskData, taskIds, submitting, setTaskIds }: Props) => {
  const { setFieldValue } = formikProps;
  const [joinYourMeeting] = useJoinMeetingbyIdMutation()
  const [meetingJoined, setMeetingJoined] = useState(false); // State to track if meeting has been joined

  const handleCheckboxChange = (taskId: string) => {
    const currentIndex = taskIds.indexOf(taskId);
    const newTaskIds = [...taskIds];

    if (currentIndex === -1) {
      newTaskIds.push(taskId);
    } else {
      newTaskIds.splice(currentIndex, 1);
    }

    setTaskIds(newTaskIds);
    setFieldValue('taskIds', newTaskIds); // Assuming taskIds is the name of the array field in your form
  };

  const handleJoinMeeting = (row: string) => {
    // showConfirmationDialog({
    //   title: "Heads Up",
    //   text: "Are you sure want this user to join the meeting?",
    //   icon: "question",
    //   showCancelButton: true,
    //   next: (result) => {
    //     if (result?.isConfirmed) {
    joinYourMeeting({ id: meetingStartId, body: { type: "JOIN", userId: row } }).then((res: any) => {
      if (res.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        showToast("success", res?.data?.message);
        // navigate((`/meeting/${row}/notes`))
        setMeetingJoined(true); // Set meetingJoined to true upon successful join
      }
    });
    //     }
    //   },
    // });
  }

  return (
    <>
      <div className="text-center">
        <div className='flex justify-between items-center'>
          <span className="font-semibold text-md p-2">Task Listing</span>
          <button
            type="button"
            className="hover:bg-hover p-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>

        {taskData && taskData.length ? (
          taskData.map((task: any, index: any) => (
            <div key={index} className='flex justify-between items-center p-2 mx-1 border-b border-gray-300'>
              <div className="flex-shrink-0 border-r border-gray-300 pr-2">
                {index + 1}.
              </div>
              <div className='ml-2 border-r border-gray-300 pr-2'>
                <span className="tooltip inline-block relative">
                  <span className="truncate-5">{task?.title}</span>
                  <span className="tooltip-text absolute bg-gray-800 text-white px-2 py-1 rounded-md whitespace-nowrap bottom-full left-1/2 transform -translate-x-1/2 opacity-0 pointer-events-none">
                    {task?.title}
                  </span>
                </span>
              </div>
              <div className="flex-grow text-center border-r border-gray-300 pr-2">
                {task?.assigneeName}
              </div>
              {!meetingJoined && (
                <div className="capitalize mx-2" onClick={() => { handleJoinMeeting(task?.assignedTo) }}>
                  <button
                    type='button'
                    className="bg-blue-500 text-white p-1 font-semibold text-xs rounded-md hover:bg-blue-600 transition duration-300"
                  >
                    Join Meeting
                  </button>
                </div>
              )}
              <div className="flex-shrink-0 ml-auto">
                {/* Assuming ATMCheckbox is a custom component */}
                <ATMCheckbox
                  label=""
                  name="taskCheckbox"
                  checked={taskIds.includes(task._id)}
                  onChange={() => handleCheckboxChange(task._id)}
                />
              </div>
            </div>
          ))
        ) : (
          <div className='items-center text-md font-semibold p-3 m-4'>
            No Task is In Progress
          </div>
        )}


        <div className="flex justify-end m-1">
          <ATMLoadingButton isLoading={submitting?.isLoading} type="submit" className="w-20">
            Save
          </ATMLoadingButton>
        </div>
      </div>

    </>
  )
}

export default HoldStartMeetingTask
