import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";

import CircularProgress from "@mui/material/CircularProgress";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useEditPlatformsMutation, useGetPlatformsByIdQuery } from "src/services/RequirementGatheringService";
import { PlatFormFormValues } from "../Add/AddPlatformWrapper";
import SubscriptionForm from "../Layout/AccountsForm";

type Props = {
  selectedSubscription: string;
  onClose: () => void;
};

const EditAccountsWrapper = ({
  selectedSubscription,
  onClose,
}: Props) => {

  const { id } = useParams();
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetPlatformsByIdQuery(
    selectedSubscription
  );

  const [updatePlatform] = useEditPlatformsMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: PlatFormFormValues = {
    platformName: items?.platformName || "",

  };

  // Validation Schema
  const validationSchema = object().shape({
    platformName: string().required("Please enter account name"),
    
  });
  const handleSubmit = (
    values: PlatFormFormValues,
    { setSubmitting, resetForm }: FormikHelpers<PlatFormFormValues>
  ) => {
    const formattedValues = {
      workspaceId : id || '',
      platformName: values?.platformName,
    };

      updatePlatform({
        id: selectedSubscription,
        body: formattedValues,
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
  };

  return (
    <Dialog open maxWidth="sm" fullWidth fullScreen={isMobile}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <SubscriptionForm
              formType="EDIT"
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditAccountsWrapper;
