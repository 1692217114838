import * as React from "react";
import Pagination from "@mui/material/Pagination";

interface ATMPaginationPropTypes {
  page: number;
  onPageChange: (newPage: number) => void;
  rowsPerPage: number;
  onRowsPerPageChange?: (newRowsPerPage: number) => void;
  rowCount: number;
  rowsPerPageOptions?: number[];
  rows: any[];
  hideRowsPerPage?: boolean;
}

const ATMPagination = ({
  rows,
  rowCount,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  rowsPerPageOptions = [10, 20, 50, 100],
}: ATMPaginationPropTypes) => {
  return (
    <>
      {rows.length ? (
        <div className="md:flex justify-between gap-3 px-6 md:h-[50px] items-center">
          {/* Out of */}

          <div className="flex items-center gap-3">
            <div className="text-xs font-medium text-black">
              Rows per page :
            </div>
            <select
              value={rowsPerPage}
              onChange={(e) => onRowsPerPageChange?.(parseInt(e.target.value))}
              className={`rounded-lg p-1 outline-0 bg-slate-100 text-xs font-medium text-black `}
            >
              {rowsPerPageOptions.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>

            <div className="px-2 py-1 text-xs font-medium text-black rounded-lg bg-slate-100">
              Showing &nbsp; {rowsPerPage * (page - 1) + 1} -{" "}
              {rowsPerPage * (page - 1) + rows.length} of {rowCount}
            </div>
          </div>

          <div className="flex justify-end xs:my-2 md:my-0">
            <Pagination
              color="primary"
              count={Math.ceil(rowCount / rowsPerPage)}
              page={page}
              onChange={(e, page) => onPageChange(page)}
              showFirstButton={true}
              showLastButton={true}
              size="medium"
              shape="rounded"
              variant="outlined"
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ATMPagination;
