import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";

type Props = {
  pendingTicketData: any[];
  isLoading: boolean;
};

const ViewInProgressTickets = ({ pendingTicketData, isLoading }: Props) => {
  const navigate = useNavigate();

  const columns: columnTypes[] = [
    {
      field: "name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
    },
    {
      field: "status",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => (
        <>
          <h1 className="mb-4 text-2xl font-semibold capitalize ">
            {row?.isOnLeave && (
              <span className="inline-block px-2 py-1 text-xs font-semibold text-white uppercase bg-red-500 rounded-full">
                on Leave
              </span>
            )}
            {row?.isOnBreak && (
              <span className="inline-block px-2 py-1 text-xs font-semibold text-white uppercase rounded-full bg-cyan-400">
                on Break
              </span>
            )}
            {row?.isOnBench && (
              <span className="inline-block px-2 py-1 text-xs font-semibold text-white uppercase bg-pink-500 rounded-full">
                on Bench
              </span>
            )}
            {row?.inMeeting && (
              <span className="inline-block px-2 py-1 text-xs font-semibold text-white uppercase rounded-full bg-fuchsia-500">
                in Meeting
              </span>
            )}
          </h1>
          {!row?.isOnLeave &&
          !row?.isOnBreak &&
          !row?.isOnBench &&
          !row?.inMeeting ? (
            row?.InProgressTask?.length ? (
              <span className="inline-block px-2 py-1 text-xs font-semibold text-white uppercase bg-yellow-500 rounded-full">
                In Progress
              </span>
            ) : (
              <span className="inline-block px-2 py-1 text-xs font-semibold text-white uppercase rounded-full bg-slate-500">
                Idle
              </span>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "InProgressTask",
      headerName: "Task Title",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => (
        <>
          {!row?.isOnLeave &&
          !row?.isOnBreak &&
          !row?.isOnBench &&
          !row?.inMeeting ? (
            row?.InProgressTask?.length ? (
              <span
                className="font-semibold text-justify text-gray-800 cursor-pointer hover:text-blue-400 hover:underline"
                onClick={() =>
                  navigate(`/task/${row?.InProgressTask?.[0]?._id}`)
                }
              >
                {row?.InProgressTask?.[0]?.title}
              </span>
            ) : (
              <div className="text-gray-600">No task in progress.</div>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "projectName",
      headerName: "ProjectName",
      flex: "flex-[1_1_0%]",
      align: "center",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => (
        <>
          {!row?.isOnLeave &&
          !row?.isOnBreak &&
          !row?.isOnBench &&
          !row?.inMeeting ? (
            row?.InProgressTask?.length ? (
              <div>
                <p className="font-bold text-gray-800 capitalize">
                  {row?.InProgressTask?.[0]?.projectName || "-"}
                </p>
              </div>
            ) : null
          ) : null}
        </>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Started Time",
      flex: "flex-[1_1_0%]",
      align: "center",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => (
        <>
          {!row?.isOnLeave &&
          !row?.isOnBreak &&
          !row?.isOnBench &&
          !row?.inMeeting
            ? row?.InProgressTask?.length
              ? moment(row?.InProgressTask?.[0]?.updatedAt).format("h:mm A")
              : null
            : null}
        </>
      ),
    },
  ];
  return (
    <div className="flex flex-col flex-1 overflow-auto">
      <ATMTable
        columns={columns}
        rowExtraClasses={() => "h-[50px]"}
        rows={pendingTicketData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ViewInProgressTickets;
