export const handleValidNumber: (event: any) => boolean = (event) => {
  const numRegExp = /^[0-9]*$/; // Updated RegExp to allow numbers and %
  if (numRegExp.test(event?.target?.value)) {
    return true;
  } else {
    return false;
  }
};

export const handleValidWithDotNumber: (
  event: any,
  options?: { acceptPercent?: boolean; acceptNegative?: boolean }
) => boolean = (event, options) => {
  const numRegExp = /^[0-9.%]*$/; // Updated RegExp to allow numbers, dot (.), and %
  const numRegExpwithoutPercent = /^[0-9.]*$/; // Updated RegExp to allow numbers, dot (.)
  const numRegExpwithNegative = /^[0-9.-]*$/; // Updated RegExp to allow numbers, negative
  if (options?.acceptPercent) {
    return numRegExp.test(event?.target?.value);
  } else if (options?.acceptNegative) {
    return numRegExpwithNegative.test(event?.target?.value);
  } else {
    return numRegExpwithoutPercent.test(event?.target?.value);
  }
};

export const handleValidForHour = (event: any) => {
  const numRegExp = /^(1[0-9]|2[0-4]|[1-9])$/;
  const value = event?.target?.value;
  if (value === "") {
    return true;
  }
  if (numRegExp.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const handleValidPercent: (event: any) => boolean = (event) => {
  const value = event?.target?.value;
  const numValue = Number(value);
  if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
    return true;
  } else {
    return false;
  }
};
