import { useState, useEffect } from "react";
import { useGetAllMeetingsQuery } from "src/services/MeetingServices";

export const useGetAllMeetings = () => {
  const [allMeetings, setAllMeetings] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { data, isLoading, isFetching } = useGetAllMeetingsQuery("");

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setAllMeetings(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { allMeetings, isDataLoading };
};
