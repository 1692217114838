import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { sideNavLayoutSlice } from "./slices";
// import { authMiddleware } from "src/utils";
import apiSlice from "src/services/ApiSlice";
import { filePickerSlice } from "src/services/FilePickerServices";
import AccountsSlice from "./slices/AccountsSlice";
import AllTaskSlice from "./slices/AllTaskSlice";
import AttendanceSlice from "./slices/AttendanceSlice";
import AuthSlice from "./slices/AuthSlice";
import BusinessSlice from "./slices/BusinessSlice";
import CampaignGroupSlice from "./slices/CampaignGroupSlice";
import CampaignSlice from "./slices/CampaignSlice";
import ChangeRequestActivityLogSlice from "./slices/ChangeRequestActivityLogSlice";
import ChangeRequestSlice from "./slices/ChangeRequestSlice";
import ChangeRequestTimeLog from "./slices/ChangeRequestTimeLog";
import ChannelGroupSlice from "./slices/ChannelGroupSlice";
import ChannelSlice from "./slices/ChannelSlice";
import ChatSlice from "./slices/ChatSlice";
import CitySlice from "./slices/CitySlice";
import ClarificationIncidentSlice from "./slices/ClarificationIncidentSlice";
import ClientLeadgerSlice from "./slices/ClientLeadgerSlice";
import ClientSlice from "./slices/ClientSlice";
import ClientUserSlice from "./slices/ClientUserSlice";
import CloseDoubtSlice from "./slices/CloseDoubtSlice";
import ConfigurationSlice from "./slices/ConfigurationSlice";
import CountrySlice from "./slices/CountrySlice";
import CurrencySlice from "./slices/CurrencySlice";
import DeliveryDateSlice from "./slices/DeliveryDateSlice";
import DepartmentSlice from "./slices/DepartmentSlice";
import DeploymentSlice from "./slices/DeploymentSlice";
import DesputedIncidentSlice from "./slices/DesputedIncidentSlice";
import DomainSlice from "./slices/DomainSlice";
import DoubtSlice from "./slices/DoubtSlice";
import DumpSlice from "./slices/DumpSlice";
import EstimationCalculatorSlice from "./slices/EstimationCalculatorSlice";
import ExpenseCategorySlice from "./slices/ExpenseCategorySlice";
import ExpensesSlice from "./slices/ExpensesSlice";
import FeatureSlice from "./slices/FeatureSlice";
import AccountSlice from "./slices/Finance-New-Slices/AccountSlice";
import CashFlowSlice from "./slices/Finance-New-Slices/CashFlowSlice";
import ExpenseSlice from "./slices/Finance-New-Slices/ExpenseSlice";
import IncomeSlice from "./slices/Finance-New-Slices/IncomeSlice";
import PaymentModeSlice from "./slices/Finance-New-Slices/PaymentModeSlice";
import RecurringIncomeSlice from "./slices/Finance-New-Slices/RecurringIncomeSlice";
import FinanceDashboardSlice from "./slices/FinanceDashboardSlice";
import GitRepoSlice from "./slices/GitRepoSlice";
import GoalCategorySlice from "./slices/GoalCategorySlice";
import GoalViewSlice from "./slices/GoalViewSlice";
import GoalsSlice from "./slices/GoalsSlice";
import HolidayCalendarSlice from "./slices/HolidayCalendarSlice";
import IncidentCategorySlice from "./slices/IncidentCategorySlice";
import IncidentSlice from "./slices/IncidentSlice";
import IncidentTypeSlice from "./slices/IncidentTypeSlice";
import IndustrySlice from "./slices/IndustrySlice";
import JobApplicationSlice from "./slices/JobApplicationSlice";
import JobCreationSlice from "./slices/JobCreationSlice";
import JobCreationStageSlice from "./slices/JobCreationStageSlice";
import LeadLabelSlice from "./slices/LeadLabelSlice";
import LeadsFollowUpListingSlice from "./slices/LeadsFollowUpListingSlice";
import LeadsListingSlice from "./slices/LeadsListingSlice";
import LeadsPendingFollowupSlice from "./slices/LeadsPendingFollowupSlice";
import LeaveRequestSlice from "./slices/LeaveRequestSlice";
import MarketingLayoutSlice from "./slices/MarketingLayoutSlice";
import MeetingNotesSlice from "./slices/MeetingNotesSlice";
import MeetingNotesTypeSlice from "./slices/MeetingNotesTypeSlice";
import MeetingSlice from "./slices/MeetingSlice";
import MeetingTypeSlice from "./slices/MeetingTypeSlice";
import ModuleSlice from "./slices/ModuleSlice";
import OperationsSlice from "./slices/OperationsSlice";
import PayoutSlice from "./slices/PayoutSlice";
import PerformanceSlice from "./slices/PerformanceSlice";
import PlanSlice from "./slices/PlanSlice";
import PlatformsSlice from "./slices/PlatformsSlice";
import ProductSlice from "./slices/ProductSlice";
import PRojectFlowSlice from "./slices/ProjectFlowSlice";
import ProjectSlice from "./slices/ProjectSlice";
import RecurringPaymentSlice from "./slices/RecurringPaymentSlice";
import ReminderSlice from "./slices/ReminderSlice";
import RequirementGatheringNewSlice from "./slices/RequirementGatheringNewSlice";
import RequirementGatheringPlatformsSlice from "./slices/RequirementGatheringPlatformsSlice";
import RequirementGatheringSlice from "./slices/RequirementGatheringSlice";
import RequirementGatheringSliceNew from "./slices/RequirementGatheringSliceNew";
import RequirementGatheringUsersSlice from "./slices/RequirementGatheringUsersSlice";
import RequirementSlice from "./slices/RequirementSlice";
import ResourceRequestSlice from "./slices/ResourceRequestSlice";
import SalarySlipSlice from "./slices/SalarySlipSlice";
import ServerSlice from "./slices/ServerSlice";
import ServiceTicketSlice from "./slices/ServiceTicketSlice";
import SkillSlice from "./slices/SkillSlice";
import SocialMediaSlice from "./slices/SocialMediaSlice";
import StatesSlice from "./slices/StatesSlice";
import SubscriptionSlice from "./slices/SubscriptionSlice";
import TakSlice from "./slices/TaskSlice";
import TeamAttendanceSlice from "./slices/TeamAttendanceSlice";
import TeamSalaryTabSlice from "./slices/TeamSalaryTabSlice";
import TeamSlice from "./slices/TeamSlice";
import TeamTransationHistorySlice from "./slices/TeamTransationHistorySlice";
import TeamViewSlice from "./slices/TeamViewSlice";
import TeamViewTodayTaskSlice from "./slices/TeamViewTodayTaskSlice";
import TicketGroupSlice from "./slices/TicketGroupSlice";
import TicketSlice from "./slices/TicketSlice";
import TimeAnalyticsProject from "./slices/TimeAnalyticsProject";
import TimeAnalyticsSlice from "./slices/TimeAnalyticsSlice";
import UnhandledIncidentSlice from "./slices/UnhandledIncidentSlice";
import UpcomingPaymentsSlice from "./slices/UpcomingPaymentsSlice";
import UserSlice from "./slices/UserSlice";
import WorkspaceSlice from "./slices/WorkspaceSlice";
import UserAcessSlice from "./slices/access/userAcessSlice";

import RoundTrackingReportSlice from "./slices/RoundTrackingReportSlice";
import ContactSlice from "./slices/ContactSlice";
import ContactLeadSlice from "./slices/ContactLeadSlice";
import JobOnboardingSlice from "./slices/JobOnboardingSlice";
import OnBoardStageSlice from "./slices/OnBoardStageSlice";

const store = configureStore({
  reducer: {
    sideNavLayout: sideNavLayoutSlice,
    client: ClientSlice,
    clientUser: ClientUserSlice,
    project: ProjectSlice,
    module: ModuleSlice,
    feature: FeatureSlice,
    ticket: TicketSlice,
    team: TeamSlice,
    chat: ChatSlice,
    auth: AuthSlice,
    gitRepo: GitRepoSlice,
    deployment: DeploymentSlice,
    server: ServerSlice,
    salarySlip: SalarySlipSlice,
    marketingLayoutSlice: MarketingLayoutSlice,
    configurationLayoutSlice: ConfigurationSlice,
    task: TakSlice,
    leads: LeadsListingSlice,
    leadsFollowUp: LeadsFollowUpListingSlice,
    requirement: RequirementSlice,
    allTask: AllTaskSlice,
    teamView: TeamViewSlice,
    changeRequest: ChangeRequestSlice,
    leaveRequest: LeaveRequestSlice,
    reminder: ReminderSlice,
    doubts: DoubtSlice,
    closeDoubt: CloseDoubtSlice,
    meetingType: MeetingTypeSlice,
    deliveryDate: DeliveryDateSlice,
    changeRequestTimeLog: ChangeRequestTimeLog,
    projectTimeAnalytics: TimeAnalyticsProject,
    meeting: MeetingSlice,
    resourceRequest: ResourceRequestSlice,
    channel: ChannelSlice,
    channelGroup: ChannelGroupSlice,
    campaignGroup: CampaignGroupSlice,
    campaign: CampaignSlice,
    country: CountrySlice,
    states: StatesSlice,
    city: CitySlice,
    socialMedia: SocialMediaSlice,
    domain: DomainSlice,
    teamViewTodayTaskSlice: TeamViewTodayTaskSlice,
    projectFlow: PRojectFlowSlice,
    leadLabel: LeadLabelSlice,
    currency: CurrencySlice,
    holidayCalendar: HolidayCalendarSlice,
    leadsPendingFollowUp: LeadsPendingFollowupSlice,
    dump: DumpSlice,
    changeRequestActivityLog: ChangeRequestActivityLogSlice,
    estimationCalculcator: EstimationCalculatorSlice,
    userAccess: UserSlice,
    userAccessModules: UserAcessSlice,
    department: DepartmentSlice,
    attendance: AttendanceSlice,
    product: ProductSlice,
    subscription: SubscriptionSlice,
    plan: PlanSlice,
    meetingNotesType: MeetingNotesTypeSlice,
    teamAttendance: TeamAttendanceSlice,
    expenseCategory: ExpenseCategorySlice,
    accounts: AccountsSlice,
    expenses: ExpensesSlice,
    teamTransationHistory: TeamTransationHistorySlice,
    teamSalaryTab: TeamSalaryTabSlice,
    clientLeadger: ClientLeadgerSlice,
    recurringPayment: RecurringPaymentSlice,
    upcomingPayments: UpcomingPaymentsSlice,
    financeDashboard: FinanceDashboardSlice,
    goalCategory: GoalCategorySlice,
    serviceTicket: ServiceTicketSlice,
    goals: GoalsSlice,
    goalView: GoalViewSlice,
    meetingNotes: MeetingNotesSlice,
    requirementGathering: RequirementGatheringSlice,
    requirementGatheringNew: RequirementGatheringNewSlice,
    workspaceSlice: WorkspaceSlice,
    platforms: PlatformsSlice,

    incidentCategory: IncidentCategorySlice,
    incidentType: IncidentTypeSlice,
    incident: IncidentSlice,
    performance: PerformanceSlice,
    desputedIncident: DesputedIncidentSlice,
    clarificationIncident: ClarificationIncidentSlice,
    unhandledIncident: UnhandledIncidentSlice,

    skill: SkillSlice,

    timeAnalytics: TimeAnalyticsSlice,
    ticketGroup: TicketGroupSlice,

    // Finance Slices
    financeAccountSlice: AccountSlice,
    paymentMode: PaymentModeSlice,
    income: IncomeSlice,
    recurringIncome: RecurringIncomeSlice,
    expense: ExpenseSlice,
    cashFlow: CashFlowSlice,
    requirementGatheringNewSlice: RequirementGatheringSliceNew,
    requirementGatheringUsers: RequirementGatheringUsersSlice,
    requirementGatheringPlatforms: RequirementGatheringPlatformsSlice,
    jobCreation: JobCreationSlice,
    jobApplication: JobApplicationSlice,
    payout: PayoutSlice,
    jobCreationStage: JobCreationStageSlice,
    roundTrackingReport: RoundTrackingReportSlice,
    industry: IndustrySlice,
    business: BusinessSlice,
    operation: OperationsSlice,

    contact: ContactSlice,
    contactLead: ContactLeadSlice,
    jobOnboarding : JobOnboardingSlice,

    onBoardStage: OnBoardStageSlice,

    [apiSlice.reducerPath]: apiSlice.reducer,
    [filePickerSlice.reducerPath]: filePickerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      // authMiddleware,
      apiSlice.middleware,
      filePickerSlice.middleware,
    ]),
});

setupListeners(store.dispatch);
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
