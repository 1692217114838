import React from "react";
import { FormikProps } from "formik";
import { ChangeClientUserPasswordFormValues } from "src/models/ChangePassword.model";
import ATMPassword from "src/components/UI/atoms/formFields/ATMPassword/ATMPassword";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<ChangeClientUserPasswordFormValues>;
  onClose: () => void;
  formType?: "ADD" | "EDIT";
};

const ChangeClientUserPassword = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={"Set New Password"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        {/* Old Password */}
        <div>
          <ATMPassword
            name="newPassword"
            value={values.newPassword}
            required
            onChange={(e) => setFieldValue("newPassword", e.target.value)}
            label="New password"
            placeholder="Enter new password"
            onBlur={handleBlur}
          />
        </div>

        {/* New Password */}
        <div>
          <ATMPassword
            name="confirmPassword"
            value={values.confirmPassword}
            required
            onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
            label="Confirm password"
            placeholder="Enter confirm password"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default ChangeClientUserPassword;
