import React, { useEffect } from "react";
import LeadsListing from "./LeadsListing";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/LeadsListingSlice";
import moment from "moment";
import { useGetAllLeadsListPaginationQuery } from "src/services/LeadsService";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { useGiveStarInLeadMutation } from "src/services/LeadsService";
import { showToast } from "src/utils/showToaster";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const LeadsListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const [giveStar] = useGiveStarInLeadMutation();

  const {
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    items,
    leadLabel,
    followUpdate,
    starLeads,
  } = useSelector((state: RootState) => state.leads);

  const { data, isLoading, isFetching } = useGetAllLeadsListPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "name",
      "email",
      "mobile",
      "countryId",
      "stateId",
      "cityId",
      "channelId",
      "campaignId",
      "domainId",
      "socialMediaId",
      "assignedTo",
      "summaryNote",
      "leadLabelName",
      "organizationName",
      "nextFollowupDate",
      "nextFollowupTime",
      "createdById",
      "createdByName",
    ],
    page: page,
    filterBy: [
      {
        fieldName: "leadLabelName",
        value: leadLabel?.label ? [leadLabel?.label] : [],
      },
      {
        fieldName: "nextFollowupDate",
        value: followUpdate || "",
      },
      {
        fieldName: "isStarred",
        value: starLeads,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "isStarred",
      headerName: "Stared ",
      extraClasses: "max-w-[100px]",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.LEADS_LIST_STARED,
      renderCell: (row: any) => (
        <div className="py-1" onClick={(e: any) => e.stopPropagation()}>
          {row?.isStarred ? (
            <div>
              <FaStar size="1.5em" color="#4285F4" />
            </div>
          ) : (
            <div
              onClick={() => {
                giveStar(row?._id).then((res: any) => {
                  if (res.error) {
                    showToast("error", res?.error?.data?.message);
                  } else {
                    showToast("success", res?.data?.message);
                  }
                });
              }}
            >
              <FaRegStar size="1.5em" />
            </div>
          )}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date-Time",
      flex: "flex-[2_2_0%]",
      name: UserModuleNameTypes.LEADS_LIST_DATE_TIME,
      extraClasses: "min-w-[150px] my-1",
      renderCell: (row: any) => (
        <div className="py-1">
          <div className="text-[13px] text-slate-700 font-medium">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="text-[12px] text-slate-500  text-xs font-medium">
            {moment(row?.createdAt).format("hh:mm A")}
          </div>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.LEADS_LIST_NAME,
      extraClasses: "min-w-[150px]",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.LEADS_LIST_MOBILE,
      extraClasses: "min-w-[150px]",
    },
    {
      field: "organizationName",
      headerName: "Organization",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.LEADS_LIST_ORGANIZATION,
      extraClasses: "min-w-[150px]",
    },
    {
      field: "domainName",
      headerName: "Domains",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.LEADS_LIST_DOMAINS,
      extraClasses: "min-w-[150px]",
    },
    {
      field: "countryName",
      headerName: "Country",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.LEADS_LIST_COUNTRY,
      extraClasses: "min-w-[150px]",
    },
    {
      field: "leadLabelName",
      headerName: "Leads Label",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.LEADS_LIST_LEADS_LABEL,
      extraClasses: "min-w-[150px]",
    },
    {
      field: "nextFollowupDate",
      headerName: "Follow-up Date Time",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.LEADS_LIST_LEADS_FOLLOW_UP_DATE_TIME,
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) =>
        row?.nextFollowupDate && (
          <div className="py-1">
            <div className="text-[15px] text-slate-700 font-medium">
              {moment(row?.nextFollowupDate).format("DD-MM-YYYY")}
            </div>
            <div className="text-[15px] text-slate-500  text-xs font-medium">
              {row?.nextFollowupTime &&
                moment(row?.nextFollowupTime, "hh:mm A").format("hh:mm a")}
            </div>
          </div>
        ),
    },
  ];

  return (
    <LeadsListing
      columns={columns}
      rows={items}
      paginationProps={{
        isTableLoading,
        totalItems,
        page,
        rowsPerPage,
        searchValue,
        setPage: (newPage) => dispatch(setPage(newPage)),
        setRowsPerPage: (newRowsPerPage) =>
          dispatch(setRowsPerPage(newRowsPerPage)),
        setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
      }}
    />
  );
};

export default LeadsListingWrapper;
