import React from "react";
import { FieldArray, FormikProps } from "formik";
import { RiDeleteBin5Line } from "react-icons/ri";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import MOLProjecttDropdown from "src/components/UI/molecules/MOLProjectDropdown/MOLProjectDropdown";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { TicketFormValues } from "src/models/Ticket.model";
import MOLAffectedAreaFeatureDropdown from "src/components/UI/molecules/MOLAffectedAreaFeatureDropdown/MOLAffectedAreaFeatureDropdown";
import { GrFormAdd } from "react-icons/gr";
import MOLAffectedAreaModuleDropdown from "src/components/UI/molecules/MOLAffectedAreaModuleDropdown/MOLAffectedAreaModuleDropdown";

type Props = {
  formikProps: FormikProps<TicketFormValues>;
  onClose: () => void;
};

const EditTicketAffectedAreas = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex items-center justify-between">
        <div className="text-xl font-medium"> Update Affected Areas </div>
        <button
          type="button"
          className="p-2 rounded hover:bg-hover"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <FieldArray name="affectedAreas">
        {({ push, remove }) => (
          <div className="flex flex-col gap-6 fullScreenEditor">
            {values?.affectedAreas?.map((affectedArea, index) => (
              <div key={index} className="flex items-end gap-4">
                {/* Project */}
                <div className="flex-1 fullScreenEditor">
                  <MOLProjecttDropdown
                    name={`affectedAreas[${index}].projectId`}
                    label="Project"
                    placeholder="Select project"
                    value={values?.affectedAreas[index]?.projectId}
                    onBlur={handleBlur}
                    onChange={(newValue) => {
                      setFieldValue(
                        `affectedAreas[${index}].projectId`,
                        newValue
                      );
                    }}
                  />
                </div>

                {/* Module */}
                <div className="flex-1">
                  <MOLAffectedAreaModuleDropdown
                    projectId={values?.affectedAreas[index]?.projectId?._id}
                    keyOfProject={index}
                    name={`affectedAreas[${index}].moduleId`}
                    value={values?.affectedAreas[index]?.moduleId}
                    onBlur={handleBlur}
                    placeholder="Select module"
                    onChange={(newValue) => {
                      setFieldValue(
                        `affectedAreas[${index}].moduleId`,
                        newValue
                      );
                    }}
                  />
                </div>

                {/* Feature */}
                <div className="flex-1">
                  <MOLAffectedAreaFeatureDropdown
                    moduleId={values?.affectedAreas[index]?.moduleId?.value}
                    keyOfProject={index}
                    name={`affectedAreas[${index}].featureId`}
                    value={values?.affectedAreas[index]?.featureId}
                    onBlur={handleBlur}
                    placeholder="Select feature"
                    onChange={(newValue) => {
                      setFieldValue(
                        `affectedAreas[${index}].featureId`,
                        newValue
                      );
                    }}
                  />
                </div>

                {/* Delete Button */}
                <button
                  type="button"
                  className={`${getInputHeight(
                    "small"
                  )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                  onClick={() => remove(index)}
                >
                  <RiDeleteBin5Line size={20} />
                </button>
              </div>
            ))}

            {/* Add More Button */}
            <button
              type="button"
              onClick={() =>
                push({
                  projectId: "",
                  featureId: "",
                })
              }
              className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
            >
              Add {values?.affectedAreas?.length ? "More" : "Affeted Area"}
              <span>
                <GrFormAdd
                  color="#ADB8C8"
                  size="1.5em"
                  className="font-semibold"
                />
              </span>
            </button>
          </div>
        )}
      </FieldArray>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default EditTicketAffectedAreas;
