import { FieldArray, FormikProps } from "formik";
import { GrFormAdd } from "react-icons/gr";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLClientDropdown from "src/components/UI/molecules/MOLClientDropdown/MOLClientDropdown";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { ProjectFormValues } from "src/models/Project.model";
import { RootState } from "src/redux/store";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";

type Props = {
  formikProps: FormikProps<ProjectFormValues>;
  setSaveNextChecked: any;
  saveNextChecked: any;
};

// Default Template
const templateOfProject = [
  {
    labelName: "Open",
    status: { label: "Open", value: "OPEN" },
  },
  {
    labelName: "In_Progress",
    status: { label: "In_Progress", value: "IN_PROGRESS" },
  },
  {
    labelName: "Close",
    status: { label: "Close", value: "CLOSE" },
  },
  {
    labelName: "Hold",
    status: { label: "Hold", value: "HOLD" },
  },
  {
    labelName: "Blocking",
    status: { label: "Blocking", value: "BLOCKING" },
  },
  {
    labelName: "Ready for QA",
    status: { label: "Open", value: "OPEN" },
  },
  {
    labelName: "QA  In Progress",
    status: { label: "In_Progress", value: "IN_PROGRESS" },
  },
  {
    labelName: "ISSUE",
    status: { label: "ISSUE", value: "ISSUE" },
  },
  {
    labelName: "Todo",
    status: { label: "Todo", value: "TODO" },
  },
  {
    labelName: "Complete",
    status: { label: "COMPLETE", value: "COMPLETE" },
  },
];

const ProjectForm = ({
  formikProps,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const { codioticUsers, isDataLoading } = useGetAllCodioticUsers();

  const taskStatusOption = [
    {
      label: "OPEN",
      value: "OPEN",
    },
    {
      label: "TODO",
      value: "TODO",
    },
    {
      label: "IN_PROGRESS",
      value: "IN_PROGRESS",
    },
    {
      label: "BUGS",
      value: "BUGS",
    },
    {
      label: "BLOCKING",
      value: "BLOCKING",
    },
    {
      label: "UAT_REVIEW",
      value: "UAT_REVIEW",
    },
    {
      label: "DONE",
      value: "DONE",
    },
    {
      label: "HOLD",
      value: "HOLD",
    },
    {
      label: "ISSUE",
      value: "ISSUE",
    },
    {
      label: "COMPLETE",
      value: "COMPLETE",
    },
    {
      label: "CLOSE",
      value: "CLOSE",
    },
  ];

  // Redux state for login user data
  const { userData } = useSelector((state: RootState) => state?.auth);

  return (
    <SideNavLayout>
      <div className="p-4 flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <div className="text-xl font-medium"> Add New Project </div>
        </div>
        <div className="flex flex-col gap-4">
          {/* Client */}
          <div className="">
            <MOLClientDropdown
              required
              name="client"
              value={values.client}
              onChange={(newValue) => setFieldValue("client", newValue)}
              onBlur={handleBlur}
              label="Client"
              placeholder="Select Client"
            />
          </div>

          {/* Project Name */}
          <div className="">
            <ATMTextField
              required
              name="projectName"
              value={values.projectName}
              onChange={(e) => setFieldValue("projectName", e.target.value)}
              label="Project Name"
              placeholder="Enter Project Name"
              onBlur={handleBlur}
            />
          </div>

          <div>
            <ATMMultiSelect
              name="members"
              label="Members"
              placeholder="Select Members"
              value={values.members}
              isLoading={isDataLoading}
              options={codioticUsers?.map((ele) => {
                return {
                  label: ele?.name,
                  value: ele?._id,
                };
              })}
              renderOption={(option, { context }) => {
                if (context === "menu") {
                  return (
                    <div className="capitalize flex gap-x-3">
                      {option.label}
                      {option?.value === userData?.userId && (
                        <span className="bg-green-500 py-[2px] flex items-center px-[8px] text-center rounded-full text-white text-[10px]">
                          It's you
                        </span>
                      )}
                    </div>
                  );
                } else {
                  return <div className="capitalize"> {option.label} </div>;
                }
              }}
              onChange={(newValue) => {
                setFieldValue("members", newValue);
              }}
            />
          </div>

          <div className="">
            <ATMTextField
              name="timeQuotation"
              type="number"
              value={values.timeQuotation}
              onChange={(e) => setFieldValue("timeQuotation", e.target.value)}
              label="Time Quotation"
              placeholder="Enter Time Quotation"
              onBlur={handleBlur}
            />
          </div>

          <div className="">
            <ATMTextField
              name="perDayWorkingHour"
              type="number"
              value={values.perDayWorkingHour}
              onChange={(e) => setFieldValue("perDayWorkingHour", e.target.value)}
              label="Per Day Working Hour"
              placeholder="Enter Per Day Working Hour"
              onBlur={handleBlur}
            />
          </div>


          <div className="flex justify-end">
            <span
              className="cursor-pointer text-blue-500 underline"
              onClick={() => {
                setFieldValue("label", templateOfProject);
              }}
            >
              Click here for prefilled template
            </span>
          </div>

          <p className=" text-md text-red-500 underline">Label</p>
          {/* Label Name */}
          <FieldArray name="label">
            {({ push, remove }) => (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {values?.label?.map((label, index) => (
                  <div key={index} className="md:flex block items-end  gap-2">
                    {/* Label Name*/}
                    <div className="">
                      <ATMTextField
                        name={`label[${index}].labelName`}
                        value={values.label[index].labelName}
                        onChange={(e) =>
                          setFieldValue(
                            `label[${index}].labelName`,
                            e.target.value
                          )
                        }
                        label="Label Name"
                        required
                        placeholder="Label Name"
                        onBlur={handleBlur}
                      />
                    </div>

                    {/* Status */}
                    <div className="my-3 md:my-0">
                      <ATMSelect
                        name={`label[${index}].status`}
                        required
                        label="Status"
                        placeholder="Select Status"
                        value={values.label[index].status}
                        options={taskStatusOption}
                        onChange={(newValue) => {
                          setFieldValue(`label[${index}].status`, newValue);
                        }}
                      />
                    </div>

                    {/* Delete Button */}
                    <button
                      type="button"
                      className={`${getInputHeight(
                        "small"
                      )} aspect-square rounded bg-red-400 text-white flex justify-center items-center mt-4 md:mt-0`}
                      onClick={() => remove(index)}
                    >
                      <RiDeleteBin5Line size={20} />
                    </button>
                  </div>
                ))}

                {/* Add More Button */}
                <button
                  type="button"
                  onClick={() =>
                    push({
                      labelName: "",
                      status: "",
                    })
                  }
                  className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400 "
                >
                  Add {values?.label?.length ? "More" : "Label Name"}
                </button>
              </div>
            )}
          </FieldArray>

          <p className=" text-md text-red-500 underline">Layer</p>
          {/* Label Name */}
          <FieldArray name="layer">
            {({ push, remove }) => (
              <div className="grid grid-cols-1 md:grid-cols-6 gap-6">
                {values?.layer?.map((layer, index) => (
                  <div key={index} className="flex gap-4 items-end">
                    {/* layer Name*/}
                    <div className="">
                      <ATMTextField
                        required
                        name={`layer[${index}].layerName`}
                        value={values.layer[index].layerName}
                        onChange={(e) =>
                          setFieldValue(
                            `layer[${index}].layerName`,
                            e.target.value
                          )
                        }
                        label="Layer Name"
                        placeholder="Enter Layer Name"
                        onBlur={handleBlur}
                      />
                    </div>

                    {/* Delete Button */}
                    <button
                      type="button"
                      className={`${getInputHeight(
                        "small"
                      )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                      onClick={() => remove(index)}
                    >
                      <RiDeleteBin5Line size={20} />
                    </button>
                  </div>
                ))}

                {/* Add More Button */}
                <button
                  type="button"
                  onClick={() =>
                    push({
                      layerName: "",
                    })
                  }
                  className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
                >
                  Add {values?.layer?.length ? "More" : "Layer Name"}
                  <span>
                    <GrFormAdd
                      color="#ADB8C8"
                      size="1.5em"
                      className="font-semibold"
                    />
                  </span>
                </button>
              </div>
            )}
          </FieldArray>
        </div>
        <div>
          <ATMCheckbox
            label="Save and Next"
            checked={saveNextChecked}
            inputClasses="h-[15px]"
            labelClasses="text-sm select-none"
            onChange={(checked) => {
              setSaveNextChecked(checked);
            }}
          />
        </div>
        <div>
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </SideNavLayout>
  );
};

export default ProjectForm;
