import { useState, useEffect } from "react";
import { useGetAllCampaignListQuery } from "src/services/CampaignService";

const useCampaign = () => {
    const [Campaign, setCampaign] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const { data, isLoading, isFetching } = useGetAllCampaignListQuery("");

    useEffect(() => {
        if (isLoading || isFetching) {
            setIsDataLoading(true);
        } else {
            const result: any[] = data?.data;
            setCampaign(result || []);
            setIsDataLoading(false);
        }
    }, [data, isLoading, isFetching]);

    return { Campaign, isDataLoading };
};

export default useCampaign;
