import { FieldArray, FormikProps } from "formik";
import { FiPlus } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import ATMAccordian from "src/components/UI/atoms/ATMAccordian/ATMAccordian";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import { PayoutFormProps } from "src/models/Payout.model";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { handleValidNumber } from "src/utils/validations/numberInput";
import {
  setSelectedUser,
  setYear,
  setMonth
} from "src/redux/slices/PayoutSlice";
import { useEffect, useState } from "react";

type Props = {
  formikProps: FormikProps<PayoutFormProps>;
  onClose: any;
  formType: "ADD" | "EDIT";
  userData?: any
  userWorkingDayData?: any

};
const PayoutLayout = ({
  formType = "ADD",
  formikProps,
  onClose,
  userData,
  userWorkingDayData
}: Props) => {

  const formHeading =
    formType === "ADD" ? "Add Payout" : "Update Payout";
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const dispatch = useDispatch();

  // get all codiotic user hook
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  const [totalSalary, setTotalSalary] = useState(0)

  useEffect(() => {
    const totalNetAmount = (values?.earning?.reduce((accumulator: any, currentValue: any) => {
      return accumulator + Number(currentValue?.value || 0)
    }, 0) - values?.deducation?.reduce((accumulator: any, currentValue: any) => {
      return accumulator + Number(currentValue?.value || 0)
    }, 0))

    setTotalSalary(totalNetAmount)
    setFieldValue('netAmount', totalNetAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.earning, values?.deducation, values?.netAmount])

  useEffect(() => {
    // Map deduction data
    const deductionData = userData?.deductions?.length
      ? userData.deductions?.map((el: any) => ({
        labelName: el?.labelName,
        value: el?.value,
      }))
      : [
        {
          labelName: '',
          value: '',
        }
      ];

    // Map earning data
    const earningData = userData?.earnings?.length
      ? userData.earnings?.map((el: any) => ({
        labelName: el?.labelName,
        value: el?.value,
      }))
      : [
        {
          labelName: '',
          value: '',
        }
      ];

    // Update form values
    setFieldValue("deducation", deductionData);  // corrected key name if needed
    setFieldValue("earning", earningData);      // add this line to update earning data
    setFieldValue("totalWorkingDays", userWorkingDayData?.totalWorkingDays ? userWorkingDayData?.totalWorkingDays : '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userWorkingDayData]);


  return (
    <MOLFormDialog
      title={formHeading}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      hideSavenNext={true}
    >
      <div className="flex flex-col gap-4 ">
        <div className="grid grid-cols-12 gap-4">

          <div className="col-span-6">
            <ATMSelect
              required
              name="teamMemberId"
              label="Codiotic User"
              placeholder="Select Codiotic User"
              value={values.teamMemberId}
              isLoading={isCodioticUserDataLoading}
              options={codioticUsers?.map((ele: CodioticUserListResponse) => {
                return {
                  label: ele?.name,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("teamMemberId", newValue);
                dispatch(setSelectedUser(newValue?.value));
              }}
            />
          </div>
          <div className="col-span-3">

            <ATMDatePicker
              required
              views={["year"]}
              name="year"
              label="Year"
              format="YYYY"
              value={values.year}
              onChange={(value) => {
                setFieldValue("year", value)
                dispatch(setYear(value));
              }}
            // minDate={new Date()}
            />
          </div>
          <div className="col-span-3">
            <ATMDatePicker
              required
              views={["month"]}
              name="month"
              format="MMM"
              label="month"
              value={values.month}
              onChange={(value) => {
                setFieldValue("month", value)
                dispatch(setMonth(value));
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4" >
          <div className="col-span-6">
            <ATMTextField
              required
              disabled
              name="totalWorkingDays"
              label="Total Working Days"
              placeholder="Enter Total Working Days"
              value={values.totalWorkingDays}
              onChange={(e) => setFieldValue('totalWorkingDays', e.target.value)}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-span-6">

            <ATMTextField
              required
              name="daysOfAbsent"
              label="Days of Absent"
              placeholder="Enter Days of Absent"
              value={values.daysOfAbsent}
              onChange={(e) => setFieldValue('daysOfAbsent', e.target.value)}
              onBlur={handleBlur}
            />
          </div>

        </div>

        <div className="grid grid-cols-2 gap-4">
          <ATMAccordian
            heading={"Earning"}
            extraClass="border bg-white h-fit rounded-md p-2"
            isOpen={true}
          >
            <FieldArray name="earning">
              {({ push, remove }) => (
                <div className="grid grid-cols-1 gap-4">
                  {values?.earning?.map((_, index) => (
                    <div key={index} className="md:flex block h-fit gap-2">
                      <div>
                        <ATMTextField
                          name={`earning[${index}].labelName`}
                          value={values.earning[index].labelName}
                          onChange={(e) =>
                            setFieldValue(`earning[${index}].labelName`, e.target.value)
                          }
                          label="Name"
                          required
                          placeholder="Enter Name"
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="my-3 md:my-0">
                        <ATMTextField
                          name={`earning[${index}].value`}
                          value={values.earning[index].value}
                          onChange={(e) =>
                            handleValidNumber(e) && setFieldValue(`earning[${index}].value`, e.target.value)
                          }
                          label="Salary"
                          required
                          placeholder="Enter Salary"
                          onBlur={handleBlur}
                        />
                      </div>
                      <button
                        type="button"
                        className={`${getInputHeight("small")} rounded text-red-600 flex justify-center items-center mt-4 md:mt-0`}
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin5Line size={20} />
                      </button>
                    </div>
                  ))}
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => push({ labelName: "", value: "" })}
                      className="px-4 rounded border-dashed border border-slate-500 font-medium text-md text-gray-400 flex justify-center gap-1 items-center"
                    >
                      <FiPlus size="1.3em" /> Add More
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </ATMAccordian>


          <ATMAccordian
            heading={"Deducation"}
            extraClass="border bg-white h-fit rounded-md p-2"
            isOpen={true}
          >

            <FieldArray name="deducation">
              {({ push, remove }) => (
                <div className="flex flex-col gap-4">
                  {values.deducation.map((_, index) => (
                    <div key={index} className="md:flex block h-fit gap-2">
                      <div>
                        <ATMTextField
                          name={`deducation[${index}].labelName`}
                          value={values.deducation[index].labelName}
                          onChange={(e) =>
                            setFieldValue(`deducation[${index}].labelName`, e.target.value)
                          }
                          label="Name"
                          required
                          placeholder="Enter Name"
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="my-3 md:my-0">
                        <ATMTextField
                          name={`deducation[${index}].value`}
                          value={values.deducation[index].value}
                          onChange={(e) =>
                            handleValidNumber(e) && setFieldValue(`deducation[${index}].value`, e.target.value)
                          }
                          label="Salary"
                          required
                          placeholder="Enter Salary"
                          onBlur={handleBlur}
                        />
                      </div>
                      <button
                        type="button"
                        className={`${getInputHeight("small")} rounded text-red-600 flex justify-center items-center mt-4 md:mt-0`}
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin5Line size={20} />
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => push({ labelName: "", value: "" })}
                    className="rounded border-dashed border border-slate-500 font-medium text-md text-gray-400 flex justify-center gap-1 items-center"
                  >
                    <FiPlus size="1.3em" /> Add More
                  </button>
                </div>
              )}
            </FieldArray>
          </ATMAccordian>

        </div>
        <div className="col-span-6">
          <ATMTextField
            required
            name="netAmount"
            label="Net Amount"
            placeholder="Enter Net Amount"
            value={totalSalary ? totalSalary : values.netAmount}
            onChange={(e) => handleValidNumber(e) && setFieldValue('netAmount', e.target.value)}
            onBlur={handleBlur}
          />
        </div>

      </div>
    </MOLFormDialog>

  );
};

export default PayoutLayout;
