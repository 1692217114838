import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetInterviewNotesQuery } from "src/services/JobsCreationStageService";
import JobApplicationNotesList from "./JobApplicationNotesList";

const JobApplicationNotesListWrapper = () => {
  const [notesData, setNotesData] = useState<any>();
  const { applicationId } = useParams();

  const { data, isLoading, isFetching } = useGetInterviewNotesQuery({
    limit: 1,
    searchValue: "",
    params: ["notes"],
    page: 1,
    filterBy: [
      {
        fieldName: "jobApplicationId",
        value: [applicationId || ""],
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setNotesData(data?.data);
    }
  }, [data, isLoading, isFetching]);
  return (
    <div>
      {(isLoading || isFetching) &&
        Array(5)
          .fill(0)
          .map((_, index) => {
            return (
              <div key={index} className="animate-pulse h-[70px] p-1">
                <div className="h-full rounded bg-slate-200"> </div>
              </div>
            );
          })}
      <JobApplicationNotesList notesList={notesData} />
    </div>
  );
};

export default JobApplicationNotesListWrapper;
