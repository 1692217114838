import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import ProjectFlowLayout from "../Layouts/ProjectFlowLayout";
import {
  useGetSingleProjectFlowViaIdQuery,
  useUpdateProjectFlowByIdMutation,
} from "src/services/ProjectServices";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PlatformProjectFlowEditWrapper = () => {
  const { flowId } = useParams(); 
 const {id} = useParams()
  const [projectFlowData, setProjectFlowData] = useState<any>();
  const [updateProjectFlow] = useUpdateProjectFlowByIdMutation(); 
  const navigate = useNavigate()

  // Get Single Ticket Details
  const { isLoading, isFetching, data } = useGetSingleProjectFlowViaIdQuery(
    flowId || "",
    {
      skip: !flowId,
    }
  );

  // Set Single Ticket Details
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setProjectFlowData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: any = {
    projectId: projectFlowData?.projectId,
    flowName: projectFlowData?.flowName,
    flow: projectFlowData?.flow,
    flowChartData:{
      nodes :projectFlowData?.flowChartData?.nodes || [] ,
      edges: projectFlowData?.flowChartData?.edges || [] 
    }
  };

  // Validation Schema
  const validationSchema = object().shape({
    flowName: string().required("Flow Name is required."),
    flow: string().required("Flow is required."),
  });

  // Handle Submit
  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    updateProjectFlow({ projectFlowId: flowId, body: values }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            navigate(`/project/${id}/flow`)
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) ? (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          ) : <ProjectFlowLayout formikProps={formikProps} formType="EDIT" /> }
          
        </Form>
      )}
    </Formik>
  );
};

export default PlatformProjectFlowEditWrapper;
