import React, { useEffect, useState } from "react";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/LeadsPendingFollowupSlice";
import { Tooltip } from "@mui/material";
import FollowupListing from "./FollowupListing";
import { useGetPendingLeadFollowupQuery } from "src/services/LeadsService";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import ATMCopyToClipboard from "src/components/UI/atoms/formFields/ATMCopyToClipboard/ATMCopyToClipboard";
import { IoCopyOutline } from "react-icons/io5";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const FollowupListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const { totalItems, page, rowsPerPage, searchValue, isTableLoading, items } =
    useSelector((state: RootState) => state.leadsPendingFollowUp);

  const { data, isLoading, isFetching } = useGetPendingLeadFollowupQuery("");

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Created At",
      extraClasses: "py-1 min-w-[120px]",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.FOLLOW_UP_LIST_CREATED_AT,
      renderCell: (row: any) => (
        <div className="flex flex-col">
          <div className="text-slate-700 font-medium">
            {formatedDateTimeIntoIst(row.createdAt, "DD MMM yyyy")}
          </div>
        </div>
      ),
    },

    {
      field: "name",
      extraClasses: "py-2 justify-center min-w-[150px]",
      headerName: "Name",
      flex: "flex-[1.5_1.5_0%]",
      name: UserModuleNameTypes.FOLLOW_UP_LIST_NAME,
      renderCell: (row: any) => (
        <span className="capitalize"> {row?.leadLabelName} </span>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      flex: "flex-[1.5_1.5_0%]",
      name: UserModuleNameTypes.FOLLOW_UP_LIST_MOBILE_NUMBER,
      extraClasses: "min-w-[150px] py-2",
      renderCell: (row: any) => (
        <div
          onClick={(e: any) => {
            e.stopPropagation();
          }}
          className="bg-stone-100 p-[6px] flex justify-between items-center rounded-xl  text-sm gap-4 cursor-default"
        >
          <ATMCopyToClipboard
            tooltipTitle={isCopied ? "Copied" : "Copy"}
            copyText={row?.mobile}
            onCopy={() => {
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 1000);
            }}
          >
            <IoCopyOutline className="cursor-pointer" />
          </ATMCopyToClipboard>
          {row?.mobile}
        </div>
      ),
    },
    {
      field: "nextFollowupDate",
      headerName: "Date - Time",
      extraClasses: "py-1 min-w-[150px]",
      flex: "flex-[1_1_0%] ",
      name: UserModuleNameTypes.FOLLOW_UP_LIST_DATE_TIME,
      renderCell: (row: any) => (
        <div className="flex flex-col">
          <div className="text-slate-700 font-medium">
            {formatedDateTimeIntoIst(row.nextFollowupDate, "DD MMM yyyy")}
          </div>
          <div className="text-[13px] font-medium text-slate-400">
            {row.nextFollowupTime}
          </div>
        </div>
      ),
    },
    {
      field: "communication_summary",
      headerName: "Summary",
      flex: "flex-[1.5_1.5_0%]",
      name: UserModuleNameTypes.FOLLOW_UP_LIST_SUMMARY,
      extraClasses: "py-1 min-w-[150px]",
      renderCell: (row: any) => {
        return (
          <Tooltip title={row?.summaryNote}>
            <span>{row?.summaryNote}</span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      <FollowupListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default FollowupListingWrapper;
