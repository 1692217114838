import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { ChannelGroupFormValues } from "src/models/ChannelGroup.model";

type Props = {
  formikProps: FormikProps<ChannelGroupFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const ChannelGroupForm = ({
  formikProps,
  formType,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Channel Group" : "Update Channel Group"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      {/* Channel Group Name */}
      <ATMTextField
        name="channelGroupName"
        value={values.channelGroupName}
        required
        onChange={(e) => setFieldValue("channelGroupName", e.target.value)}
        label="Channel Group Name"
        placeholder="Enter Channel Group Name"
        onBlur={handleBlur}
      />
    </MOLFormDialog>
  );
};

export default ChannelGroupForm;
