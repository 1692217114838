import React from "react";
import { useNavigate } from "react-router-dom";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import AddCsvFileWrapper from "./UploadCsvFileForm/AddCsvFileWrapper";
import LeadsFilterFormCardDialogWrapper from "./LeadsFilterFormCard/LeadsFilterFormCardDialogWrapper";
import { IoFilter } from "react-icons/io5";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { BiSearch } from "react-icons/bi";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {
  columns: columnTypes[];
  rows: any[];
  onAddNew?: any;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const LeadsListing = ({
  columns,
  rows,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenUploadCsvFileDialog, setIsOpenUploadCsvFileDialog] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const [isOpenFilterDialog, setIsOpenFilterDialog] =
    React.useState<boolean>(false);

  // upload csv file function
  const downloadCSV = () => {
    // Your CSV data (replace with your actual CSV content)
    const csvContent =
      "name,email,mobile,altNumber1,altNumber2,summaryNote,leadLabelName,organizationName";
    // Create a Blob with the CSV content
    const blob = new Blob([csvContent as any], { type: "text/csv" });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = "leads.csv";

    // Append the link to the body
    document.body.appendChild(downloadLink);

    // Trigger a click on the link to start the download
    downloadLink.click();

    // Remove the link from the body
    document.body.removeChild(downloadLink);
  };

  // short cut key for open filter form
  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if the pressed keys match the specified shortcut (Ctrl + Shift + F)
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "f") {
        // Open the filter dialog when the shortcut is detected
        setIsOpenFilterDialog(true);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <div className="flex flex-col h-full">
      <div className="h-fit">
        <div className={`flex gap-2 flex-col`}>
          <div className="flex justify-between">
            <div className="flex items-center">
              <ATMPageHeader pageTitle="Leads" hideAddButton hideSearchBox />
              {isAuthorized(
                UserModuleNameTypes.ACTION_LEADS_ADD_LEADS_CSV_FILES
              ) && (
                <ATMLoadingButton
                  variant="text"
                  onClick={() => setIsOpenUploadCsvFileDialog(true)}
                >
                  Add Leads CSV Files
                </ATMLoadingButton>
              )}
              {isAuthorized(
                UserModuleNameTypes.ACTION_LEADS_DOWNLOAD_TEMPLATE
              ) && (
                <ATMLoadingButton variant="text" onClick={downloadCSV}>
                  Download Template
                </ATMLoadingButton>
              )}
              {isOpenUploadCsvFileDialog && (
                <AddCsvFileWrapper
                  onClose={() => setIsOpenUploadCsvFileDialog(false)}
                />
              )}
            </div>
            {isAuthorized(UserModuleNameTypes.ACTION_LEADS_ADD) && (
              <div className="block md:hidden">
                <ATMLoadingButton
                  onClick={() => {
                    navigate("/sales&marketing/leads/add");
                  }}
                >
                  Add New
                </ATMLoadingButton>
              </div>
            )}
            <div className="flex items-center gap-2 p-4">
              {/* Search Box */}
              <div className="w-[300px]">
                <ATMInputAdormant
                  name=""
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  adormant={<BiSearch />}
                  adormantProps={{
                    position: "start",
                    extraClasses: "bg-white border-0",
                  }}
                  inputProps={{ className: "bg-white" }}
                  placeholder="Search..."
                />
              </div>

              {/* Filter Button */}
              <ATMLoadingButton
                variant="tonal"
                onClick={() => setIsOpenFilterDialog(true)}
              >
                <div className="flex items-center gap-2">
                  <IoFilter />
                  Filters
                </div>
              </ATMLoadingButton>
              {/* Filter Form */}
              <LeadsFilterFormCardDialogWrapper
                open={isOpenFilterDialog}
                onClose={() => setIsOpenFilterDialog(false)}
              />
              {isAuthorized(UserModuleNameTypes.ACTION_LEADS_ADD) && (
                <div className="hidden md:block">
                  <ATMLoadingButton
                    onClick={() => {
                      navigate("/sales&marketing/leads/add");
                    }}
                  >
                    Add New
                  </ATMLoadingButton>
                </div>
              )}
            </div>
          </div>
          {/* <div className={`flex  items-center justify-between`}></div> */}
        </div>
        <div className="block my-2 md:hidden">
          <div className="flex justify-between">
            {isAuthorized(
              UserModuleNameTypes.ACTION_LEADS_ADD_LEADS_CSV_FILES
            ) && (
              <button
                onClick={() => setIsOpenUploadCsvFileDialog(true)}
                className={`px-2 py-1 bg-primary-main text-white rounded ${getInputHeight(
                  "small"
                )}`}
              >
                Add Leads CSV Files
              </button>
            )}
            {isAuthorized(
              UserModuleNameTypes.ACTION_LEADS_DOWNLOAD_TEMPLATE
            ) && (
              <button
                onClick={downloadCSV}
                className="px-2 mb-1 text-blue-500 hover:underline"
              >
                Download Template
              </button>
            )}
            {isOpenUploadCsvFileDialog && (
              <AddCsvFileWrapper
                onClose={() => setIsOpenUploadCsvFileDialog(false)}
              />
            )}
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="flex flex-col flex-1 h-full overflow-hidden">
        <div className="flex flex-col h-full overflow-auto">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "h-[50px]"}
            onRowClick={(row: any) => navigate(`${row?._id}/followup`)}
            disableRowClick={
              !isAuthorized(UserModuleNameTypes.ACTION_LEADS_SINGLE_VIEW)
            }
            isLoading={isTableLoading}
          />
        </div>

        <div>
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </div>
  );
};

export default LeadsListing;
