import { useEffect, useState } from "react";
import { FcPrint } from "react-icons/fc";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import {
    setIsTableLoading,
    setItems,
    setPage,
    setRowsPerPage,
    setSearchValue,
    setTotalItems,
} from "src/redux/slices/PayoutSlice";
import { RootState } from "src/redux/store";
import { useDeletePayoutMutation, useGetAllPayoutListingQuery } from "src/services/PayoutService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import EditPayoutFormWrapper from "../Edit/EditPayoutFormWrapper";
import PayoutListing from "./PayoutListing";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";


const PayoutListingWrapper = () => {
    const dispatch = useDispatch();
    const [deleteJobCreation] = useDeletePayoutMutation()
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
    const [selectedPayoutId, setSelectedPayoutId] = useState('')
    const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } = useSelector((state: RootState) => state.payout);
    const {
        data: incomeList,
        isFetching,
        isLoading,
    } = useGetAllPayoutListingQuery({
        limit: rowsPerPage,
        searchValue: searchValue,
        params: ["month", "teamMemberName"],
        page: page,
        filterBy: [],
        dateFilter: {},
        orderBy: "createdAt",
        orderByValue: -1,
        isPaginationRequired: true,
    });


    const handleDelete = (row: string) => {
        showConfirmationDialog({
            title: "Hands Up",
            text: "Are you sure want to delete this payout?",
            icon: "question",
            showCancelButton: true,
            next: (result) => {
                if (result?.isConfirmed) {
                    deleteJobCreation(row).then((res: any) => {
                        if (res.error) {
                            showToast("error", res?.error?.data?.message);
                        } else {
                            showToast("success", res?.data?.message);
                        }
                    });
                }
            },
        });
    };

    const columns = [
        {
            field: 'fullName',
            headerName: 'Member name',
            flex: 'flex-[1_1_0%]',
            extraClasses: '',
            renderCell: (row: any) => (
                <div className="capitalize">{row?.fullName}</div>
            ),
        },
        {
            field: 'year',
            headerName: 'Year',
            flex: 'flex-[1_1_0%]',
            extraClasses: '',
            renderCell: (row: any) => <div>{row?.year || '-'}</div>,
        },
        {
            field: 'month',
            headerName: 'Month',
            flex: 'flex-[1_1_0%]',
            extraClasses: '',
            renderCell: (row: any) => <div>{row?.month || '-'}</div>,
        },

        {
            field: 'onBoardingBeforeDate',
            headerName: 'Total Earning',
            flex: 'flex-[1_1_0%]',
            extraClasses: '',
            renderCell: (row: any) => {
                const totalEarnings = row?.earnings?.reduce((sum: any, el: any) => sum + el.value, 0) || 0;
                return (
                    <div>{(totalEarnings) || '-'}</div>
                )
            },
        },
        {
            field: 'onBoardingBeforeDate',
            headerName: 'Total Deducation',
            flex: 'flex-[1_1_0%]',
            extraClasses: '',
            renderCell: (row: any) => {
                const totalDeductions = row?.deductions?.reduce((sum: any, el: any) => sum + el.value, 0) || 0;
                return (
                    <div>{(totalDeductions) || '-'}</div>
                )
            },
        },

        {
            field: 'daysOfAbsent',
            headerName: 'Total Absent',
            flex: 'flex-[1_1_0%]',
            extraClasses: '',
            renderCell: (row: any) => (
                <div>{(row?.daysOfAbsent) || '-'}</div>
            ),
        },
        {
            field: 'netAmount',
            headerName: 'Net Payment',
            flex: 'flex-[1_1_0%]',
            extraClasses: '',
            renderCell: (row: any) => (
                <div>{(row?.netAmount) || '-'}</div>
            ),
        },
        {
            field: 'onBoardingBeforeDate',
            headerName: 'Download payout',
            flex: 'flex-[1_1_0%]',
            extraClasses: '',
            renderCell: (row: any) => (
                <div onClick={() => {
                    window.open(`/profile/salarySlip/${row?._id}`, '_blank');
                }} className="cursor-pointer"> <FcPrint size={16} />
                </div>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            extraClasses: "min-w-[100px]",
            renderCell: (row: any) => (
                <div>
                    <ATMActionMenu
                        options={[
                            {
                                label: (
                                    <div className="flex items-center gap-x-2">
                                        <MdEdit color="#000000" /> Edit
                                    </div>
                                ),
                                onClick: () => {
                                    setIsOpenEditDialog(true);
                                    setSelectedPayoutId(row?._id);
                                },
                                acitonName: UserModuleNameTypes.ACTION_PAYOUT_EDIT,
                            },

                            {
                                label: (
                                    <div className="flex items-center gap-x-2">
                                        <MdDelete color="#000000" /> Delete
                                    </div>
                                ),
                                onClick: () => {
                                    handleDelete(row?._id);
                                },
                                acitonName: UserModuleNameTypes.ACTION_PAYOUT_DELETE,
                            },
                        ]}
                    />
                </div>
            ),
        },

    ];
    // Setting Team Members Data
    useEffect(() => {
        if (isLoading || isFetching) {
            dispatch(setIsTableLoading(true));
        } else {
            dispatch(setItems(incomeList?.data || []));
            dispatch(setIsTableLoading(false));
            dispatch(setTotalItems(incomeList?.totalItem || 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incomeList, isLoading, isFetching]);
    return (
        <>
            <PayoutListing
                columns={columns}
                rows={items}
                paginationProps={{
                    isTableLoading: isTableLoading,
                    totalItems,
                    page,
                    rowsPerPage,
                    searchValue,
                    setPage: (newPage) => dispatch(setPage(newPage)),
                    setRowsPerPage: (newRowsPerPage) =>
                        dispatch(setRowsPerPage(newRowsPerPage)),
                    setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
                }}
            />

            {isOpenEditDialog &&
                <EditPayoutFormWrapper
                    onClose={() => setIsOpenEditDialog(false)}
                    payoutId={selectedPayoutId}
                />
            }
        </>
    );
};

export default PayoutListingWrapper;
