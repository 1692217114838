import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { showToast } from "src/utils/showToaster";

type Props = {
  ticketInfo: any;
  onClose: () => void;
  isLoading: any
  isFetching: any
};

const ETAStatusList = ({ onClose, ticketInfo, isLoading,
  isFetching }: Props) => {

  const handleTicketClick = (ticketNumber: string) => {
    window.open(`/ticket/${ticketNumber}/details`, "_blank");
  };
  const [activeTab, setActiveTab] = useState('withEta');
  // const [copiedIndex, setCopiedIndex] = useState(null); // State to store the index which is copied

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  const handleTicketNumberCopy = (e: any, ticketNumber: any, index: any) => {
    e.stopPropagation(); // Stop propagation to prevent triggering handleTicketClick
    navigator.clipboard.writeText(ticketNumber); // Copy ticketNumber to clipboard
    showToast("success", "Copied!!");
    // setCopiedIndex(index); // Set the copied index
    // setTimeout(() => setCopiedIndex(null), 2000); // Reset copied index after 2 seconds
  };


  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> ETA Status </div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <>
        {
          (isLoading || isFetching) ? (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          ) : Object.keys(ticketInfo)?.length ?
            <div>
              {(isLoading || isFetching) ? (
                <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                  <CircularProgress />
                </div>
              ) : (
                <div>
                  <div className="flex gap-4 items-center mb-2 bg-white">
                    <span
                      className={`cursor-pointer h-full pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium transition-all ${activeTab === 'withEta' ? "text-primary-main  border-primary-main"
                        : "text-gray-700 border-white"}`}
                      onClick={() => handleTabClick('withEta')}
                    >
                      Tickets With ETA
                    </span>
                    <span
                      className={`cursor-pointer h-full pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium transition-all ${activeTab === 'withoutEta' ? "text-primary-main   border-primary-main"
                        : "text-gray-700 border-white"}`}
                      onClick={() => handleTabClick('withoutEta')}
                    >
                      Tickets Without ETA
                    </span>
                  </div>

                  {activeTab === 'withEta' && (
                    <div className="h-[350px] overflow-scroll mb-2">
                      {ticketInfo?.ticketsWithEstimation?.length ? ticketInfo?.ticketsWithEstimation?.map((ticket: any, index: any) => (
                        <div
                          key={ticket.ticketId}
                          className="p-2 border cursor-pointer bg-slate-50  rounded-md shadow my-2 flex justify-between"
                          onClick={() => handleTicketClick(ticket.ticketId)}
                        >
                          <span className="flex  relative mt-2 px-2 mx-2">
                            <MdContentCopy className="text-gray-500 cursor-pointer" onClick={(e) => handleTicketNumberCopy(e, ticket.ticketNumber, index)} />
                            <span onClick={(e) => handleTicketNumberCopy(e, ticket.ticketNumber, index)}>{ticket?.ticketNumber}</span>
                          </span>
                          <span className="px-2 mt-2">{ticket?.ticketTitle} </span>
                        </div>
                      )) :
                        <div className="text-center bg-slate-100 rounded px-2 py-4">
                          No tickets Present at that time
                        </div>
                      }
                    </div>
                  )}

                  {activeTab === 'withoutEta' && (
                    <div className="h-[350px] overflow-scroll mb-2">
                      {ticketInfo?.ticketsWithoutEstimation?.length ? ticketInfo?.ticketsWithoutEstimation?.map((ticket: any, index: any) => (
                        <div
                          key={ticket.ticketId}
                          className="p-2 border cursor-pointer bg-slate-50  rounded-md shadow my-2 flex justify-between"
                          onClick={() => handleTicketClick(ticket.ticketId)}
                        >
                          <span className="flex  relative mt-2 px-2 mx-2">
                            <MdContentCopy className="text-gray-500 cursor-pointer" onClick={(e) => handleTicketNumberCopy(e, ticket.ticketNumber, index)} />
                            <span onClick={(e) => handleTicketNumberCopy(e, ticket.ticketNumber, index)}>{ticket?.ticketNumber}</span>
                          </span>
                          <span className="px-2 mt-2">{ticket?.ticketTitle} </span>
                        </div>
                      )) :
                        <div className="text-center bg-slate-100 rounded px-2 py-4">
                          No tickets Present at that time
                        </div>
                      }
                    </div>
                  )}
                </div>
              )}
            </div>
            :
            <div className="text-center bg-slate-100 rounded px-2 py-4">
              No tickets is mapped with this change request
            </div>
        }
      </>
    </div>
  );
};

export default ETAStatusList;
