import React from "react";

// |-- External Dependencies --|
import { IconType } from "react-icons";
import { BiSearchAlt2 } from "react-icons/bi";

// |-- Internal Dependencies --|
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
// |-- Redux --|
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import { JobApplicationListResponse } from "src/models/JobApplication.model";
import MOLJobApplicationStatusChip from "src/components/UI/molecules/MOLJobApplicationStatusChip/MOLJobApplicationStatusChip";

// |-- Types --|
type ViewLayoutPropTypes = {
  leftbar?: boolean;
  infoCard?: React.ReactNode;
  actionIcons?: {
    icon: IconType;
    onClick: () => void;
  }[];
  listData?: any[];
  tabs?: {
    label: string;
    icon: IconType;
    path: string;
  }[];
  searchValue?: string;
  onSearch?: (value: string) => void;
  onClickViewMore?: () => void;
  onClickApplication?: (applicationId: string) => void;
  hideViewMore?: boolean;
  children?: any;
  isLoading?: boolean;
};

const JobApplicationSideBarLayout = ({
  leftbar = true,
  infoCard,
  listData,
  tabs,
  searchValue = "",
  onSearch = () => { },
  onClickApplication = () => { },
  onClickViewMore,
  hideViewMore = false,
  children,
  isLoading,
}: ViewLayoutPropTypes) => {
  const { applicationId } = useParams();

  return (
    <>
      <div className="h-[calc(100vh-50px)] bg-white">
        <div className="w-full flex h-[calc(100%)]">
          {/* Left Section Side Bar */}
          <div className="md:block hidden">
            {leftbar === true && (
              <div className="w-[250px] h-full flex flex-col">
                {/* Search Bar */}

                <div className="flex items-center justify-start p-2 border-b w-full">
                  <ATMInputAdormant
                    name=""
                    value={searchValue}
                    placeholder="Search here..."
                    className="h-[40px] shadow-sm border-[1px] focus:border-black w-full"
                    adormant={
                      <BiSearchAlt2 className="text-slate-400 text-xl " />
                    }
                    adormantProps={{
                      position: "start",
                      extraClasses: "bg-white border-0 ",
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />
                </div>

                {/* List */}
                <div className="grow overflow-auto">
                  <div className="p-2 flex flex-col min-w-[100%] overflow-y-auto gap-2">
                    {listData?.map(
                      (applicationInfo: JobApplicationListResponse) => {
                        return (
                          <div
                            key={applicationInfo?._id}
                            className={` border-b p-2 cursor-pointer hover:bg-teal-100 flex flex-col gap-2 ${applicationInfo?._id === applicationId
                              ? "bg-teal-100 border border-teal-100 shadow-sm"
                              : "bg-white"
                              }`}
                            onClick={() =>
                              onClickApplication(applicationInfo?._id)
                            }
                          >
                            <div className="flex flex-col gap-1">
                              <Tooltip title={applicationInfo?.applicantName}>
                                <h3 className="w-full overflow-hidden line-clamp-2 text-sm min-w-[100%] font-medium">
                                  {applicationInfo?.applicantName}
                                </h3>
                              </Tooltip>
                              <div className="inline-flex justify-end">
                                <MOLJobApplicationStatusChip
                                  status={applicationInfo?.applicationStatus}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                    {/* View more button */}
                    {hideViewMore && !isLoading && (
                      <span
                        onClick={onClickViewMore}
                        className="text-black text-center text-sm cursor-pointer hover:underline"
                      >
                        View More
                      </span>
                    )}
                    {isLoading && (
                      <div className="flex justify-center w-full">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Right Section */}
          <div className={`w-full border-r border-l h-full`}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default JobApplicationSideBarLayout;
