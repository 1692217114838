import { FormikProps } from "formik";
import ATMAmountField from "src/components/UI/atoms/ATMAmountField/ATMAmountField";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import { AdjustBalanceFormValues } from "src/models/Account.model";
import { useGetFinanceAccountQuery } from "src/services/Finance-New-Services/AccountServices";
import { handleValidWithDotNumber } from "src/utils/validations/numberInput";
type Props = {
  formikProps: FormikProps<AdjustBalanceFormValues>;
  onClose: () => void;
  formType: "ADD";
};

const AdjustBalanceForm = ({
  formType = "ADD",
  formikProps,
  onClose,
}: Props) => {
  const formHeading = formType === "ADD" ? "Adjust balance" : "";
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const { data: getAccount, isLoading: isAccountLoading } =
    useGetFinanceAccountQuery("");

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex items-center justify-between">
        <div className="text-xl font-medium"> {formHeading} </div>
        <button
          type="button"
          className="p-2 rounded hover:bg-hover"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/*  */}
        <div className="flex gap-3">
          <button
            type="button"
            className={`px-2 py-1 rounded border text-xs font-medium ${values?.adjustType === "CREDIT" ? "bg-green-600 text-white" : "text-slate-700"} `}
            onClick={() => setFieldValue("adjustType", "CREDIT")}
          >
            {" "}
            Deposit{" "}
          </button>
          <button
            type="button"
            className={`px-2 py-1 rounded border text-xs ${values?.adjustType === "DEBIT" ? "bg-red-600 text-white" : "text-slate-700"} `}
            onClick={() => setFieldValue("adjustType", "DEBIT")}
          >
            {" "}
            Withdraw{" "}
          </button>
          <button
            type="button"
            className={`px-2 py-1 rounded border text-xs ${values?.adjustType === "TRANSFER" ? "bg-blue-600 text-white" : "text-slate-700"} `}
            onClick={() => setFieldValue("adjustType", "TRANSFER")}
          >
            {" "}
            Transfer{" "}
          </button>
        </div>
        <div>
          <ATMAmountField
            disableCurrencyChange
            required
            name="amount"
            value={values.amount}
            onChange={(e) =>
              handleValidWithDotNumber(e) &&
              setFieldValue("amount", e.target.value)
            }
            onCurrencyChange={(newValue) => {
              setFieldValue("currency", newValue);
            }}
            currencyValue={values.currency}
            label="Amount"
            placeholder="Enter Amount"
            onBlur={handleBlur}
          />
        </div>
        {values?.adjustType === "TRANSFER" ? (
          <div>
            <ATMSelect
              name="account"
              label="Account"
              required
              placeholder="Select Account"
              value={values.account}
              options={getAccount?.data?.map((ele: any) => {
                return {
                  label: ele?.accountName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("account", newValue);
              }}
              isLoading={isAccountLoading}
            />
          </div>
        ) : null}
        <div>
          <ATMTextArea
            required
            name="description"
            value={values.description}
            onChange={(e) => setFieldValue("description", e)}
            label="Description"
            placeholder="Enter Description"
          />
        </div>
        <div className="mt-4">
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default AdjustBalanceForm;
