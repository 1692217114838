import React from 'react'
import ATMPageHeading from 'src/components/UI/atoms/ATMPageHeading/ATMPageHeading'
import ATMTable from 'src/components/UI/atoms/ATMTable/ATMTable'

type Props = {
    isLoading: any,
    items: any
    columns: any
}

const ClientDetails = ({ isLoading, items, columns }: Props) => {
    const firstLetter = items?.organisationName?.charAt(0).toUpperCase();
    return (
        <>
            <div>
                <div className="">
                    <div className="">
                        <div className='px-4 mt-2'>
                            <ATMPageHeading>Client Details :-  </ATMPageHeading>
                            <div className='justify-between flex p-2 mt-3 text-md  border rounded-md '>
                                <div className='flex flex-col items-start relative'>
                                    <div className='flex items-center'>
                                        <span className='absolute left-0 top-0 bottom-0 flex items-center bg-sky-50 p-5 text-lg font-bold rounded'>{firstLetter}</span>
                                        <div className='ml-10 px-6'>
                                            <div className='capitalize font-semibold'>{items?.organisationName}</div>
                                            <div className='flex text-grey-500'>
                                                <span className=''>{items?.mobile}</span>
                                                <span className='px-2'>|</span>
                                                <span className=''>{items?.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=' my-3'>
                            <div className='px-4'> <ATMPageHeading >Client Users :-  </ATMPageHeading></div>
                            <div className='mt-3' >
                                {/* <table className="min-w-full border-collapse border border-gray-300">
                                    <thead>
                                        <tr>
                                            <th className="px-2 py-1">Index</th>
                                            <th className="px-2 py-1">Name</th>
                                            <th className="px-2 py-1 text-center">Email</th>
                                            <th className="text-end px-2 py-1">Mobile</th>
                                        </tr>
                                    </thead>
                                    {items?.clientUsers?.map((cu: any, index: any) => (
                                        <tbody>
                                            <>
                                                <tr key={index} className="flex border-b text-md">
                                                    <td className="px-2 py-1">{index + 1}</td>

                                                </tr>
                                                <tr>
                                                    <td className="px-2 py-1">{cu?.name}</td>
                                                    <td className="text-center px-2 py-1">{cu?.email}</td>
                                                    <td className="text-end px-2 py-1">{cu?.mobile}</td>

                                                </tr>

                                            </>
                                        </tbody>
                                    ))}

                                </table> */}
                                {items && items.clientUsers && items.clientUsers.length ? (
                                    <div className="flex-1 overflow-auto flex flex-col px-2 md:px-4">
                                        <ATMTable
                                            columns={columns}
                                            rows={items.clientUsers}
                                            rowExtraClasses={() => "min-h-[50px]"}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                ) : (
                                    <div className='p-4 m-4 text-center font-semibold  text-md'>
                                        NO Data Found
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ClientDetails
