import apiSlice from "../ApiSlice";

export const accountsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get Accounts with pagination
    getFinanceAccount: builder.query({
      providesTags: ["finance-management"],
      query: (body) => {
        return {
          url: "/account/",
          method: "POST",
          body,
        };
      },
    }),

    //Add
    addFinanceAccounts: builder.mutation({
      invalidatesTags: ["finance-management"],
      query: (body) => {
        return {
          url: "/account/add",
          method: "POST",
          body,
        };
      },
    }),
    //Get Accounts by ID
    getFinanceAccountsById: builder.query({
      providesTags: ["finance-management"],
      query: (id) => {
        return {
          url: `/account/${id}`,
          method: "GET",
        };
      },
    }),
    //Edit
    updateFinanceAccounts: builder.mutation({
      invalidatesTags: ["finance-management"],
      query: ({ id, body }) => {
        return {
          url: `/account/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // Delete
    deleteFinanceAccounts: builder.mutation({
      invalidatesTags: ["finance-management"],
      query: (id: any) => ({
        url: `/account/${id}`,
        method: "DELETE",
      }),
    }),
    // update adjust-amount
    updateAdjustAmount: builder.mutation({
      invalidatesTags: ["finance-management"],
      query: ({ id, body }) => {
        return {
          url: `/account/adjust-amount/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});
export const {
  useGetFinanceAccountQuery,
  useAddFinanceAccountsMutation,
  useUpdateFinanceAccountsMutation,
  useGetFinanceAccountsByIdQuery,
  useDeleteFinanceAccountsMutation,
  useUpdateAdjustAmountMutation,
} = accountsApi;
