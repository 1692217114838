import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { CurrencyFormValues } from "src/models/Currency.model";
import CurrencyForm from "../Layouts/CurrencyForm";
import { useAddCurrencyMutation } from "src/services/CurrencyServices";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
};

const AddCurrencyFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addCurrency] = useAddCurrencyMutation();

  // Form Initial Values
  const initialValues: CurrencyFormValues = {
    currencyName: "",
    currencySymbol: "",
    currencyValueInINR: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    currencyName: string().required("Please enter currency name"),
    currencySymbol: string(),
    currencyValueInINR: string().required("Please enter currency value in INR"),
  });

  // Handle Submit
  const handleSubmit = (
    values: CurrencyFormValues,
    { setSubmitting, resetForm }: FormikHelpers<CurrencyFormValues>
  ) => {
    addCurrency(values).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <CurrencyForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddCurrencyFormWrapper;
