import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { ExpenseCategoryFormValues } from "src/models/ExpenseCategory.model";

type Props = {
  formikProps: FormikProps<ExpenseCategoryFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const AccountsForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Account" : "Update Account"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      {/* Organisation Name */}
      <ATMTextField
        required
        name="title"
        value={values.title}
        onChange={(e) => setFieldValue("title", e.target.value)}
        label="Account"
        placeholder="Enter Account"
        onBlur={handleBlur}
      />
    </MOLFormDialog>
  );
};

export default AccountsForm;
