import React, { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useNavigate } from "react-router-dom";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import InternalMeetingAddWrapper from "../AddInternalMeeting/InternalMeetingAddWrapper";
import MeetingListFilterFormDialogWrapper from "./MeetingFilterFormCard/MeetingListFilterFormDialogWrapper";
import moment from "moment";
import MOLMeetingStatusChip from "src/components/UI/molecules/MOLMeetingStatusChip/MOLMeetingStatusChip";
import {
  TbBuildingEstate,
  TbEdit,
  TbPhoneCall,
  TbTrash,
  TbVideo,
} from "react-icons/tb";
import { Tooltip } from "@mui/material";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import HoldStartMeetingTaskWrapper from "./HoldStartMeetingTask/HoldStartMeetingTaskWrapper";
import MeetingMarkAsDoneFormWrapper from "../View/tabs/NotesTab/MeetingMarkAsDoneForm/MeetingMarkAsDoneFormWrapper";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import {
  useDeleteMeetingMutation,
  useJoinMeetingbyIdMutation,
} from "src/services/MeetingServices";
import { showToast } from "src/utils/showToaster";
import HoldJoinMeetingTaskWrapper from "./HoldJoinMeetingTask/HoldJoinMeetingTaskWrapper";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import AddQuicklMeetingWrapper from "../AddQuicklMeeting/AddQuicklMeetingWrapper";
import QuickMeetingMarkAsDoneFormWrapper from "../View/tabs/NotesTab/QuickMeetingMarkAsDoneForm/QuickMeetingMarkAsDoneFormWrapper";

type Props = {
  columns: columnTypes[];
  rows: any[];
  isMeetingLoading: boolean;
  isClientRoute?: boolean;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const MeetingList = ({
  columns,
  isClientRoute,
  isMeetingLoading,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const [internalMeetingAdd, setInternalMeetingAdd] = useState<boolean>(false);
  const [quickMeetingAdd, setQuickMeetingAdd] = useState<boolean>(false);

  const [isOpenMeetingFilterDialog, setIsOpenMeetingFilterDialog] =
    useState<boolean>(false);

  const handleButtonClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleInternalMeetingAdd = () => {
    setInternalMeetingAdd(true);
  };

  const handleQuicklMeetingAdd = () => {
    setQuickMeetingAdd(true);
  };

  const handleAddNewClick = () => {
    // Code to handle Add New button click
    navigate("/meeting/add");
  };

  const handleOtherButtonClick = () => {
    // Code to handle the other button click
    navigate("/meeting/conversation/add");
  };
  const [deleteMeeting] = useDeleteMeetingMutation();
  const { userData } = useGetLocalStorage();

  //start meeting
  const [isOpenMarkAsDoneModel, setIsOpenMarkAsDoneModel] =
    useState<boolean>(false);
  const [isOpenQuickMarkAsDoneModel, setIsOpenQuickMarkAsDoneModel] =
    useState<boolean>(false);
  const [openHoldTask, setIsOpenHoldTask] = useState<boolean>(false);
  const [meetingStartId, setMeetingStartId] = useState<any>("");
  const [actualStartTime, setActualStartTime] = useState<string>("");
  const [meetingId, setMeetingId] = useState<any>("");
  const [meetingType, setMeetingType] = useState<string>("");

  const handleStartMeeting = (row: string) => {
    setMeetingStartId(row);
    setIsOpenHoldTask(true);
  };

  //leave and join meeting
  const [isOpenLoggedInUserTask, setIsOpenLoggedInUserTask] =
    useState<boolean>(false);
  const [isOpenJoinMeeting, setIsOpenJoinMeeting] = useState<any>("");
  const [joinYourMeeting] = useJoinMeetingbyIdMutation();

  const handleLeaveMeeting = (row: string) => {
    showConfirmationDialog({
      title: "Heads Up",
      text: "Are you sure want to leave the meeting?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          joinYourMeeting({
            id: row,
            body: { type: "LEAVE", userId: userData?.userId },
          }).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };
  const handleJoinMeeting = (meetingApi: string) => {
    setIsOpenJoinMeeting(meetingApi);
    setIsOpenLoggedInUserTask(true);
  };

  //delete meeting
  const handleDeleteGitRepo = (row: string) => {
    showConfirmationDialog({
      title: "Heads Up",
      text: "Are you sure want to delete this Meeting ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteMeeting(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  function handleMeetingType(type: string) {
    switch (type) {
      case "THIRD_PARTY_WITH_INTERNAL_TEAM":
        return "Third Party With Internal Team";
      case "INTERNAL_TEAM":
        return "Internal Team";
      case "CLIENT":
        return "CLIENT";
      case "THIRD_PARTY_WITH_CLIENT":
        return "Third Party With Client";
      default:
        return "Unknown Type"; // or handle default case as per your requirement
    }
  }

  return (
    <>
      {isClientRoute ? (
        <div className="relative flex flex-col h-full">
          {/* Page Header */}
          <div className="relative flex items-center p-2 md:p-4">
            <ATMPageHeader
              pageTitle="Meetings"
              searchValue={searchValue}
              hideAddButton={
                !isAuthorized(UserModuleNameTypes.ACTION_MEETING_ADD)
              }
              buttonProps={{
                btnName: "Create",
                onClick: handleButtonClick,
              }}
              onSearchChange={setSearchValue}
            />
            {isAuthorized(UserModuleNameTypes.ACTION_MEETING_ADD) && (
              <div className="relative z-20 mt-5 md:mt-0">
                {isDropdownOpen && (
                  <div
                    className="absolute right-0 z-20 p-2 bg-white border rounded shadow top-12"
                    style={{
                      minWidth: "200px",
                      position: "absolute",
                      overflow: "visible",
                    }}
                  >
                    <ATMLoadingButton
                      style={{ width: "100%", marginBottom: "8px" }}
                      onClick={handleAddNewClick}
                    >
                      Schedule a meeting
                    </ATMLoadingButton>
                    <ATMLoadingButton
                      style={{ width: "100%" }}
                      onClick={handleOtherButtonClick}
                    >
                      Add a conversation
                    </ATMLoadingButton>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Table */}
          <div className="relative flex flex-col flex-1 px-2 overflow-auto md:px-4 z-1">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "min-h-[40px]"}
              isLoading={isTableLoading}
              disableRowClick={
                !isAuthorized(UserModuleNameTypes.ACTION_MEETING_SINGLE_VIEW)
              }
              onRowClick={(row) => navigate(`/meeting/${row?._id}/agenda`)}
            />
          </div>

          <div className="p-4 py-2">
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      ) : (
        <SideNavLayout>
          <div className="relative flex flex-col h-full">
            {/* Page Header */}
            <div className="relative flex items-center p-2 border-b md:p-4">
            <ATMPageHeader
              pageTitle="Meetings"
              searchValue={searchValue}
              isFilter
              onFilterClick={()=>setIsOpenMeetingFilterDialog(true)}
              hideAddButton={
                !isAuthorized(UserModuleNameTypes.ACTION_MEETING_ADD)
              }
              buttonProps={{
                btnName: "Create",
                onClick: handleButtonClick,
              }}
              onSearchChange={setSearchValue}
            />
               <div className="relative z-20 mt-5 md:mt-0">
                   
                   {isDropdownOpen && (
                     <div
                       className="absolute right-5 z-20 p-2 bg-white border rounded shadow top-12"
                       style={{
                         minWidth: "200px",
                         position: "absolute",
                         overflow: "visible",
                       }}
                     >
                       <ATMLoadingButton
                         style={{ width: "100%", marginBottom: "8px" }}
                         onClick={handleAddNewClick}
                       >
                         Schedule a meeting
                       </ATMLoadingButton>
                       <ATMLoadingButton
                         style={{ width: "100%", marginBottom: "8px" }}
                         onClick={handleOtherButtonClick}
                       >
                         Add a conversation
                       </ATMLoadingButton>
                       <ATMLoadingButton
                         style={{ width: "100%", marginBottom: "8px" }}
                         onClick={handleInternalMeetingAdd}
                       >
                         Internal meeting
                       </ATMLoadingButton>
                       <ATMLoadingButton
                         style={{ width: "100%" }}
                         onClick={handleQuicklMeetingAdd}
                       >
                         Quick Meeting
                       </ATMLoadingButton>
                     </div>
                   )}
                 </div>
            
            </div>
            <div className="relative flex flex-col flex-1 p-4 overflow-auto z-1 bg-gray-50">
              <div className="flex flex-col gap-4">
                {isMeetingLoading ?  (
                  <div className="flex flex-col gap-4">
                    {Array(10)
                      .fill(0)
                      .map((_, index) => {
                        return (
                          <div
                            key={index}
                            className="animate-pulse h-[70px] max-w-[100vw]  rounded-md "
                          >
                            <div className="h-full rounded bg-slate-200 shadow-md"> </div>
                          </div>
                        );
                      })}{" "}
                  </div>
                ) :  rows?.map((row:any) => {
                  return (
                    <div
                      onClick={() => navigate(`/meeting/${row?._id}/agenda`)}
                      className="flex items-center p-2 rounded-md cursor-pointer bg-white border border-slate-200 hover:border-primary-dark gap-4 max-w-[100vw] overflow-x-scroll transition-all duration-500 hover:shadow-md"
                    >
                      {/* <div className="flex items-center justify-center h-full aspect-square w-fit bg-gray-50">
                        A
                      </div> */}

                      {/* project name, meeting title  */}
                      <div className="flex flex-col flex-1">
                        {isAuthorized(
                          UserModuleNameTypes.MEETING_LIST_PROJECT
                        ) ? (
                          <p className="text-xs font-semibold text-sky-600">
                            {row.projectName}
                          </p>
                        ) : null}
                        {isAuthorized(
                          UserModuleNameTypes.MEETING_LIST_TITLE
                        ) ? (
                          <p className="text-base">{row.title}</p>
                        ) : null}
                      </div>
                      <div className="flex flex-col items-start flex-1">
                        <p className="text-xs font-medium text-neutral">Host</p>
                        {row.meetingHostName}
                      </div>
                      <div className="flex flex-col items-start flex-1">
                        <p className="text-xs font-medium text-neutral">Type</p>
                        <p className="flex text-sm">
                          {" "}
                          {handleMeetingType(row?.meetingWith)}
                        </p>
                      </div>
                      {/* schedule and actual date */}
                      <div className="flex items-center justify-end flex-1 gap-4">
                        {/* scheduled date */}

                        {isAuthorized(
                          UserModuleNameTypes.MEETING_LIST_SCHEDULED_DATE_TIME
                        ) ? (
                          <span className="w-32">
                            <p className="text-xs text-neutral">
                              Sch. date-time
                            </p>
                            <div>
                              <div className="text-sm font-medium text-slate-700">
                                {moment(row?.createdAt).format("DD MMM YYYY")}
                              </div>
                              <div className="flex items-center gap-1">
                                <span className="text-xs font-medium text-neutral">
                                  {moment(
                                    row?.scheduledStartTime,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")}
                                </span>
                                {row?.scheduledEndTime && (
                                  <span className="flex gap-1">
                                    <span className="text-xs font-bold text-neutral">
                                      :
                                    </span>
                                    <span className="text-xs font-medium text-neutral">
                                      {moment(
                                        row?.scheduledEndTime,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </span>
                        ) : null}

                        {/* actual date */}
                        {isAuthorized(
                          UserModuleNameTypes.MEETING_LIST_ACTUAL_END_DATE_TIME
                        ) ? (
                          <div className="w-32">
                            <p className="text-xs text-neutral">
                              Act. date-time
                            </p>
                            {UserModuleNameTypes.MEETING_LIST_ACTUAL_END_DATE_TIME ? (
                              <>
                                {row?.actualEndDate ? (
                                  <div>
                                    <div className="text-sm font-medium text-slate-700">
                                      {moment(row?.actualEndDate).format(
                                        "DD MMM YYYY"
                                      )}
                                    </div>
                                    <div className="flex gap-1">
                                      <span className="text-xs font-medium text-neutral">
                                        {moment(row?.actualStartTime).format(
                                          "hh:mm A"
                                        )}
                                      </span>
                                      <span className="text-xs font-bold text-neutral">
                                        :
                                      </span>
                                      <span className="text-xs font-medium text-neutral">
                                        {moment(row?.actualEndTime).format(
                                          "hh:mm A"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </div>
                        ) : null}
                      </div>

                      {/* meeting status and Mode  */}
                      <div className="flex items-center justify-end flex-1 gap-4">
                        {/* meeting status  */}
                        {isAuthorized(
                          UserModuleNameTypes.MEETING_LIST_STATUS
                        ) ? (
                          <div className="text-xs capitalize w-fit">
                            <MOLMeetingStatusChip status={row?.status} />
                          </div>
                        ) : null}
                        {/* meeting mode */}
                        {row?.meetingCreateType !== "QUICK" && (
                          <>
                            {isAuthorized(
                              UserModuleNameTypes.MEETING_LIST_MODE
                            ) ? (
                              <div className="w-32">
                                {row?.mode === "CALL" ? (
                                  <div className="flex items-center w-full gap-2 px-2 py-1 text-sm rounded-md cursor-context-menu bg-gray-50">
                                    <span className="">
                                      <TbPhoneCall />
                                    </span>
                                    <p>On call</p>
                                  </div>
                                ) : row?.mode === "ONLINE" ? (
                                  <a
                                    href={`${row?.meetingLink}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center w-full gap-2 px-2 py-1 text-sm text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
                                  >
                                    <span>
                                      <TbVideo />
                                    </span>
                                    <p>Meeting Link</p>
                                  </a>
                                ) : (
                                  <Tooltip title={row?.address}>
                                    <div className="flex items-center w-full gap-2 px-2 py-1 text-sm rounded-md cursor-context-menu bg-gray-50">
                                      <span className="">
                                        <TbBuildingEstate />
                                      </span>
                                      <p className="truncate">{row?.address}</p>
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>

                      {/* Buttons */}
                      <div className="flex items-center justify-end flex-1 gap-4">
                        {/* start end */}
                        <div>
                          {row?.mode !== "CALL" &&
                          row?.actualStartTime === "" &&
                          (userData?.userType === "SUPER_ADMIN" ||
                            userData?.userId === row?.createdById) ? (
                            <div
                              className="capitalize"
                              onClick={(e: any) => e.stopPropagation()}
                            >
                              <button
                                className="px-3 py-1 text-xs font-semibold text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
                                onClick={() => {
                                  handleStartMeeting(row?._id);
                                }}
                              >
                                Start Meeting
                              </button>
                            </div>
                          ) : row?.mode !== "CALL" &&
                            row?.actualStartTime !== "" &&
                            row?.actualEndTime === "" &&
                            (userData?.userType === "SUPER_ADMIN" ||
                              userData?.userId === row?.createdById) ? (
                            <>
                              <div
                                className="capitalize"
                                onClick={(e: any) => e.stopPropagation()}
                              >
                                {row?.meetingCreateType !== "QUICK" ? (
                                  <button
                                    className="px-3 py-1 text-xs font-semibold text-white transition duration-300 bg-red-500 rounded-md hover:bg-red-600"
                                    onClick={() => {
                                      setIsOpenMarkAsDoneModel(true);
                                      setActualStartTime(row?.actualStartTime);
                                      setMeetingId(row?._id);
                                      setMeetingType(row?.meetingWith);
                                    }}
                                  >
                                    End Meeting
                                  </button>
                                ) : (
                                  <button
                                    className="px-3 py-1 text-xs font-semibold text-white transition duration-300 bg-red-500 rounded-md hover:bg-red-600"
                                    onClick={() => {
                                      setIsOpenQuickMarkAsDoneModel(true);
                                      setActualStartTime(row?.actualStartTime);
                                      setMeetingId(row?._id);
                                      setMeetingType(row?.meetingWith);
                                    }}
                                  >
                                    End Quick Meeting
                                  </button>
                                )}
                              </div>
                            </>
                          ) : null}
                        </div>
                        {/* join leave */}
                        <div>
                          {row?.mode !== "CALL" &&
                          row?.status === "ONGOING" &&
                          row?.actualCodioticUsers ? (
                            row?.isJoined ? (
                              <div
                                className="capitalize"
                                onClick={(e: any) => e.stopPropagation()}
                              >
                                <button
                                  className="px-3 py-1 text-xs font-semibold text-white transition duration-300 bg-red-500 rounded-md hover:bg-red-600"
                                  onClick={() => {
                                    handleLeaveMeeting(row?._id);
                                  }}
                                >
                                  Leave Meeting
                                </button>
                              </div>
                            ) : (
                              <div
                                className="capitalize"
                                onClick={(e: any) => e.stopPropagation()}
                              >
                                <button
                                  className="px-3 py-1 text-xs font-semibold text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
                                  onClick={() => {
                                    handleJoinMeeting(row?._id);
                                  }}
                                >
                                  Join Meeting
                                </button>
                              </div>
                            )
                          ) : null}
                        </div>
                      </div>

                      {/* Actions */}
                      {row?.meetingCreateType !== "QUICK" ? (
                        <ATMActionMenu
                          options={
                            row?.status === "PENDING"
                              ? [
                                  {
                                    label: (
                                      <div className="flex items-center gap-x-2">
                                        <TbTrash color="#000000" /> Delete
                                      </div>
                                    ),
                                    onClick: () => {
                                      handleDeleteGitRepo(row?._id);
                                    },
                                    acitonName:
                                      UserModuleNameTypes.ACTION_MEETING_DELETE,
                                  },
                                  {
                                    label: (
                                      <div className="flex items-center gap-x-2">
                                        <TbEdit color="#000000" /> Edit
                                      </div>
                                    ),
                                    onClick: () => {
                                      if (row?.mode === "CALL") {
                                        navigate(
                                          `/meeting/conversation/${row?._id}/edit`
                                        );
                                      } else {
                                        navigate(`/meeting/${row?._id}/edit`);
                                      }
                                    },
                                    acitonName:
                                      UserModuleNameTypes.ACTION_MEETING_EDIT,
                                  },
                                ]
                              : [
                                  {
                                    label: (
                                      <div className="flex items-center gap-x-2">
                                        <TbEdit color="#000000" /> Edit
                                      </div>
                                    ),
                                    onClick: () => {
                                      if (row?.mode === "CALL") {
                                        navigate(
                                          `/meeting/conversation/${row?._id}/edit`
                                        );
                                      } else {
                                        navigate(`/meeting/${row?._id}/edit`);
                                      }
                                    },
                                    acitonName:
                                      UserModuleNameTypes.ACTION_MEETING_EDIT,
                                  },
                                ]
                          }
                        />
                      ) : (
                        <ATMActionMenu
                          options={
                            row?.status === "PENDING"
                              ? [
                                  {
                                    label: (
                                      <div className="flex items-center gap-x-2">
                                        <TbTrash color="#000000" /> Delete
                                      </div>
                                    ),
                                    onClick: () => {
                                      handleDeleteGitRepo(row?._id);
                                    },
                                    acitonName:
                                      UserModuleNameTypes.ACTION_MEETING_DELETE,
                                  },
                                ]
                              : []
                          }
                        />
                      )}
                    </div>
                  );
                })}
               
              </div>
            </div>

            <div className="p-4 py-2 border-t">
              <ATMPagination
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={setPage}
                onRowsPerPageChange={setRowsPerPage}
                rowCount={totalItems}
                rows={rows}
              />
            </div>
          </div>
        </SideNavLayout>
      )}

      {internalMeetingAdd ? (
        <InternalMeetingAddWrapper
          onClose={() => setInternalMeetingAdd(false)}
        />
      ) : null}

      {quickMeetingAdd ? (
        <AddQuicklMeetingWrapper onClose={() => setQuickMeetingAdd(false)} />
      ) : null}

      {isOpenMarkAsDoneModel ? (
        <MeetingMarkAsDoneFormWrapper
          meetingId={meetingId}
          meetingType={meetingType}
          actualStartTime={actualStartTime}
          onClose={() => {
            setIsOpenMarkAsDoneModel(false);
            setMeetingId("");
          }}
        />
      ) : null}

      {isOpenQuickMarkAsDoneModel ? (
        <QuickMeetingMarkAsDoneFormWrapper
          meetingId={meetingId}
          meetingType={meetingType}
          actualStartTime={actualStartTime}
          onClose={() => {
            setIsOpenQuickMarkAsDoneModel(false);
            setMeetingId("");
          }}
        />
      ) : null}

      {isOpenLoggedInUserTask && (
        <HoldJoinMeetingTaskWrapper
          onClose={() => {
            setIsOpenLoggedInUserTask(false);
            setIsOpenJoinMeeting("");
          }}
          JoinMeeting={isOpenJoinMeeting}
        />
      )}

      {openHoldTask && (
        <HoldStartMeetingTaskWrapper
          onClose={() => {
            setIsOpenHoldTask(false);
            setMeetingStartId("");
          }}
          meetingStartId={meetingStartId}
        />
      )}
      <MeetingListFilterFormDialogWrapper
       open={isOpenMeetingFilterDialog} 
       onClose={()=>setIsOpenMeetingFilterDialog(false)}
      />
    </>
  );
};

export default MeetingList;
