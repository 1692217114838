import { FormikProps } from "formik";
import React from "react";
import { addTicketIssuesFormWrapperPropType } from "../Add/AddTicketIssuesFormWrapper";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<addTicketIssuesFormWrapperPropType>;
  onClose: any;
  formType: "ADD" | "UPDATE";
  setSaveNextChecked: any;
  saveNextChecked: any;
  meetingOptions: any;
};

const TicketIssuesForm = ({
  onClose,
  formikProps,
  formType,
  saveNextChecked,
  setSaveNextChecked,
  meetingOptions,
}: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Issue" : "Update Issue"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
      fullScreen
    >
      <div className="flex flex-col gap-4">
        {/* Project */}
        <div>
          <ATMSelect
            name="meetings"
            label="Meetings"
            placeholder="Select Meeting"
            value={values?.meetings}
            // isLoading={isAllProjectsDataLoading}
            options={meetingOptions}
            onChange={(newValue) => {
              setFieldValue("meetings", newValue);
            }}
          />
        </div>
        {/* asked question */}
        <div className="z-0 ">
          <MOLMarkdownEditor
            required
            label="Issue"
            value={values?.Issue}
            placeholder="Enter Issue"
            onChange={(newValue: any) => {
              setFieldValue("Issue", newValue);
            }}
            extraClassName="min-h-[60vh]"
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default TicketIssuesForm;
