import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { boolean, object, string } from "yup";
import ExpenseForm from "../Layout/ExpenseForm";
import { showToast } from "src/utils/showToaster";
import moment from "moment";
import { useGetBaseCurrencyQuery } from "src/services/CurrencyServices";
import { useNavigate } from "react-router-dom";
import { useAddExpenseMutation } from "src/services/Finance-New-Services/ExpenseService";
import { ExpenseFormValues } from "src/models/Expense.model";


const AddExpenseWrapper = () => {
    const navigate = useNavigate();
    const [addExpense] = useAddExpenseMutation();
    const { data } = useGetBaseCurrencyQuery("");
    // Form Initial Values
    const initialValues: ExpenseFormValues = {
        expenseDate: null,
        financeCategoryId: '',
        expenseName: '',
        amount: '',
        currency: data?.data,
        tdsAmount: '',
        category: '',
        account: '',
        gstPercentage: '0',
        status: 'UNPAID',
        dueDate: null,
        paymentDate: null,
        modeName: '',
        gstApplicable: false,
        description: '',
        warrantyExpiryDate: null,
        attachments: []
    };

    // Validation Schema
    const validationSchema = object().shape({
        modeName: object().required("Please select mode"),
        expenseName: string().required("Please enter expense name"),
        // expenseDate: string().required("Please select expense date"),
        category: object().required("Please select category "),
        amount: string().required("Please enter amount"),
        gstApplicable: boolean().required("Please enter GST applicability"),
        gstPercentage: string().test(
            "test-gstPercentage",
            "GST percentage is required ",
            (value, context) => {
                if (context?.parent?.gstApplicable) {
                    if (!value) {
                        return false;
                    } else {
                        return true;
                    }
                }
                return true;
            }
        ),
        status: string().oneOf(["PAID", "UNPAID"]).required("Please enter status "),
        dueDate: string()
            .test("test-dueDate", "Please select due date", (value, context) => {
                if (context?.parent?.status === "UNPAID") {
                    if (!value) {
                        return false;
                    } else {
                        return true;
                    }
                }
                return true;
            })
            .nullable(),
        paymentDate: string()
            .test("test-paymentDate", "Please select payment date", (value, context) => {
                if (context?.parent?.status === "PAID") {
                    if (!value) {
                        return false;
                    } else {
                        return true;
                    }
                }
                return true;
            })
            .nullable(),
        account: object().required("Please select account"),
        currency: object().required("Please enter currency "),
    });

    // Handle Submit
    const handleSubmit = (
        values: ExpenseFormValues,
        { setSubmitting, resetForm }: FormikHelpers<ExpenseFormValues>
    ) => {
        const formattedValues = {
            expenseDate: moment(values?.expenseDate).format("YYYY-MM-DD"),
            paymentDate: moment(values?.paymentDate).format("YYYY-MM-DD"),
            financeCategoryId: values?.category?.value,
            amount: values?.amount,
            expenseName: values?.expenseName,
            gstApplicable: values?.gstApplicable,
            gstPercentage: values?.gstPercentage || 0,
            tdsAmount: values?.tdsAmount,
            currencyId: values?.currency?.value || data?.data?._id,
            status: values?.status,
            accountId: values?.account?.value,
            paymentModeId: values?.modeName?.value,
            dueDate: moment(values?.dueDate).format("YYYY-MM-DD"),
            description: values?.description,
            warrantyExpiryDate: values?.warrantyExpiryDate || ""
        };
        addExpense(formattedValues).then((res: any) => {
            if (res?.error) {
                showToast("error", res?.error?.data?.message);
            } else {
                if (res?.data?.status) {
                    showToast("success", res?.data?.message);
                    navigate("/finance/expense-new");
                } else {
                    showToast("error", res?.data?.message);
                }
            }
            setSubmitting(false);
        });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formikProps) => (
                <Form>
                    <ExpenseForm
                        formikProps={formikProps}
                        onClose={() => navigate("/finance/expense-new")}
                        formType="ADD"
                    />
                </Form>
            )}
        </Formik>
    );
};

export default AddExpenseWrapper;
