import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import AssignTicketTeamMemberModal from "./AssignTicketTeamMemberModal";
import { object, array, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { useUpdateAssignTicketIntoMembersMutation } from "src/services/TicketServices";
import { useGetAllUserQuery } from "src/services/UserService";

type Props = {
  ticketId: string;
  open: boolean;
  handleClose: () => void;
};

export type FormInitialValues = {
  assignedTo: any;
};

const AssignTicketTeamMemberModalWrapper = ({
  ticketId,
  open = false,
  handleClose,
}: Props) => {
  // user options
  const [userOptions, setUserOptions] = useState<any>([]);

  const { data, isLoading, isFetching } = useGetAllUserQuery("");

  const [updateTicketIntoTeamMembers] =
    useUpdateAssignTicketIntoMembersMutation();

  // Form Initial Values
  const initialValues: FormInitialValues = {
    assignedTo: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    assignedTo: array()
      .of(
        object().shape({
          label: string(),
          value: string(),
        })
      )
      .required("Please select team members"),
  });

  // Setting Clients Data
  useEffect(() => {
    if (!isLoading && !isFetching) {
      const filterUserOptions = data?.data?.map((ele: any) => {
        return {
          label: ele?.name,
          value: ele?._id,
        };
      });
      setUserOptions(filterUserOptions);
    }
  }, [data, isLoading, isFetching]);

  const handleSubmit = (
    values: FormInitialValues,
    { setSubmitting, resetForm }: FormikHelpers<FormInitialValues>
  ) => {
    const formatedValue = {
      assignedTo: values?.assignedTo?.map((ele: any) => {
        return ele?.value;
      }),
    };

    updateTicketIntoTeamMembers({
      ticketId: ticketId,
      body: formatedValue,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <AssignTicketTeamMemberModal
              formikProps={formikProps}
              userOptions={userOptions}
              handleClose={handleClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AssignTicketTeamMemberModalWrapper;
