import { MdLabel } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";

type Props = {
  items: any;
  paginationProps: {
    totalItems: number;
    page: number;
    setPage: (newPage: number) => void;
  };
};

const TimeLogView = ({
  items,
  paginationProps: { totalItems, page, setPage },
}: Props) => {
  return (
    <div className="flex flex-col p-4 bg-gray-50 h-full">
      <div id="scrollableDiv" className="w-full h-[100%] overflow-auto">
        <InfiniteScroll
          dataLength={items?.length || 0}
          scrollableTarget={"scrollableDiv"}
          next={() => {
            setPage(page + 1);
          }}
          scrollThreshold={0.5}
          hasMore={items?.length < totalItems}
          loader={
            <div className="grid grid-cols-1 gap-3 overflow-auto w-full mt-3">
              {Array(2)
                .fill(null)
                .map((_, index) => (
                  <div key={index} className="animate-pulse  h-[60px] p-2">
                    <div className="bg-slate-200 h-full rounded" />
                  </div>
                ))}
            </div>
          }
        >
          {items?.map((timeLine: any, index: number) => (
            <div key={index} className="flex gap-3 pb-6 h-full">
              <div className="p-2 h-fit aspect-square border rounded-full">
                <MdLabel className="text-sm text-gray-600" />
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  <p className="text-xs">
                    <div className="text-slate-700 font-medium">
                      {formatedDateTimeIntoIst(
                        timeLine?.createdAt,
                        "DD MMM yyyy"
                      )}
                    </div>
                    <div className="text-[13px] font-medium text-slate-400">
                      {formatedDateTimeIntoIst(timeLine?.createdAt, "hh:mm A")}
                    </div>
                  </p>
                  {/* <span className="flex gap-2">
                            <p className="text-sm text-teal-600 font-medium">
                              {/* {timeLine.user}  */}
                  {/* {timeLine.actionPerformedByUserName
                                .toLowerCase()
                                .replace(/\s+/g, "")} */}
                  {/* hey
                            </p>
                          </span> */}
                </div>
                <span className="bg-gray-50 text-blue-400 rounded-md">
                  {timeLine?.remark}
                </span>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default TimeLogView;
