import { useNavigate } from "react-router-dom";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const PlatformListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div>
          <ATMPageHeader
            pageTitle="Platforms"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_PLATFORM_ADD)
            }
          />
        </div>

        {/* Table */}
        <div className="flex flex-col flex-1 overflow-auto">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "h-[50px]"}
            isLoading={isTableLoading}
            onRowClick={(row) =>
              navigate(`platform-layout/${row?.platformId}/details`)
            }
            disableRowClick={
              !isAuthorized(UserModuleNameTypes.ACTION_PLATFORM_VIEW)
            }
          />
        </div>

        <div>
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

export default PlatformListing;
