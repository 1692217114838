import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { IconType } from "react-icons";
import { GrTasks } from "react-icons/gr";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoArrowBackOutline } from "react-icons/io5";
import { MdCall, MdOutlineEmail, MdOutlineTextsms } from "react-icons/md";
import { PiNotePencilBold } from "react-icons/pi";
import { RxCalendar } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import OverViewWrapper from "./Tabs/OverView/OverViewWrapper";
import AddNoteLogDialogFormWrapper from "./AddNoteLogDialog/AddNoteLogDialogFormWrapper";
import AddTaskDialogFormWrapper from "./AddTaskDialog/AddTaskDialogFormWrapper";
import ActivitiesLayout from "./Tabs/Activities/ActivitiesLayout";

type Props = {
  contactDetails: any;
  leadDetails: any;
  contactId: any;
};
type tabIcon = {
  Icon: IconType;
  label: string;
  onClick: any;
};

const TabIcon = ({ Icon, label, onClick }: tabIcon) => {
  return (
    <Tooltip title={label} placement="top-start">
      <div className="w-[40px] text-center ">
        <div
          onClick={onClick}
          className="border cursor-pointer rounded-full bg-slate-100	  py-[7px] flex justify-center"
        >
          <Icon size="1.2em" color="#425b76" />
        </div>
        <span className="text-xs text-slate-500">{label} </span>
      </div>
    </Tooltip>
  );
};

const ViewContactLead = ({
  contactDetails,
  leadDetails,
  contactId,
}: Props) => {
  const [isShowAboutContact, setIsShowAboutContact] = useState(true);
  const [contactBy, setContactBy] = useState<
    "SMS" | "NOTE" | "EMAIL" | "CALL" | "MEETING" | "TASK"
  >("SMS");
  const [isOpenEditNote, setIsOpenEditNote] = useState(false);
  const [isOpenAddTaskDialog, setIsOpenAddTaskDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = new URLSearchParams(location.search);
  const selectedTab = activeTab.get("contact");
  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-3  flex flex-col gap-5 p-4 bg-white border-r h-full">
          <div
            className="w-20 h-8 cursor-pointer  text-primary-dark flex gap-1 items-center border rounded-md justify-center border-primary-dark"
            onClick={() => {
              navigate(`/contact/${contactId}/leads`);
            }}
          >
            <IoArrowBackOutline /> Back
          </div>
          <div className="flex gap-4 items-center relative group/item hover:bg-hover px-2">
            <div className="w-[50px] h-[50px] rounded-full  bg-slate-500 text-white flex justify-center items-center text-[24px]  capitalize ">
              {contactDetails?.name?.[0]}
            </div>
            <div>
              <p className="text-lg text-slate-500">
                {contactDetails?.name || ""}{" "}
              </p>
              {/* <p className="text-sm text-slate-500">Developer</p> */}
              <p className="text-sm text-slate-500">
                {contactDetails?.email || ""}{" "}
              </p>
              <p className="text-sm text-slate-500">
                {contactDetails?.mobile || ""}{" "}
              </p>
            </div>
          </div>
          <div className="flex gap-4 mx-6">
            <TabIcon
              label="Note"
              onClick={() => {
                setIsOpenEditNote(true);
                setContactBy("NOTE");
              }}
              Icon={PiNotePencilBold}
            />
            <TabIcon
              label="Email"
              onClick={() => {
                setIsOpenEditNote(true);
                setContactBy("EMAIL");
              }}
              Icon={MdOutlineEmail}
            />
            <TabIcon
              label="Call"
              onClick={() => {
                setIsOpenEditNote(true);
                setContactBy("CALL");
              }}
              Icon={MdCall}
            />
            <TabIcon
              label="Meetings"
              onClick={() => {
                setIsOpenEditNote(true);
                setContactBy("MEETING");
              }}
              Icon={RxCalendar}
            />
            <TabIcon
              label="Task"
              onClick={() => {
                setIsOpenAddTaskDialog(true);
              }}
              Icon={GrTasks}
            />
            <TabIcon
              label="SMS"
              onClick={() => {
                setIsOpenEditNote(true);
                setContactBy("SMS");
              }}
              Icon={MdOutlineTextsms}
            />
          </div>
          <div className="border-t">
            <div className="flex gap-3 items-center my-2">
              <button
                className="cursor-pointer"
                onClick={() => setIsShowAboutContact(!isShowAboutContact)}
              >
                {isShowAboutContact ? (
                  <IoIosArrowDown color="#00a4bd" />
                ) : (
                  <IoIosArrowUp color="#00a4bd" />
                )}
              </button>
              <p className="text-[14px] font-semibold">About this Lead</p>
            </div>
            {isShowAboutContact ? (
              <div className="flex flex-col gap-3  mt-2">
                <div className="hover:bg-hover px-6">
                  <p className="text-gray-400 text-[12px]">Created Date</p>{" "}
                  <div className="text-slate-800 text-[14px]">
                    {leadDetails?.createdAt
                      ? formatedDateTimeIntoIst(
                          leadDetails?.createdAt,
                          "DD MMM yyyy"
                        )
                      : "--"}
                  </div>
                </div>
                <div className="hover:bg-hover px-6">
                  <p className="text-gray-400  text-[12px]">
                    Organization Name
                  </p>
                  <p className="text-[14px]">
                    {leadDetails?.organizationName || ""}
                  </p>
                </div>
                <div className="hover:bg-hover px-6">
                  <p className="text-gray-400 text-[12px]">Domain</p>
                  <p className="text-[14px]">{leadDetails?.domainName || ""}</p>
                </div>
                <div className="hover:bg-hover px-6">
                  <p className="text-gray-400 text-sm">Country</p>
                  <p className="text-[14px]">{leadDetails?.countryName} </p>
                </div>
                <div className="hover:bg-hover px-6">
                  <p className="text-gray-400 text-sm">Channel</p>
                  <p className="text-[14px]">{leadDetails?.channelName} </p>
                </div>
                <div className="hover:bg-hover px-6">
                  <p className="text-gray-400 text-[12px]">Created By</p>
                  <p className="text-[14px]">
                    {leadDetails?.createdByName || ""}{" "}
                  </p>
                </div>

                {/* LifeCycle Stage */}
                <div className="hover:bg-hover px-6">
                  <p className="text-gray-400 text-[12px]">Record Source</p>
                  {/* <p className="text-[14px]">{queryData?.recordSource?.toLowerCase() || ""} </p> */}
                </div>
                <div className="hover:bg-hover px-6">
                  <p className="text-gray-400 text-[12px]">
                    Last FollowUp Date
                  </p>{" "}
                  <div className="text-slate-800 text-[14px]">
                    {/* {queryData?.lastActionDate
                      ? formatedDateTimeIntoIst(
                          queryData?.lastActionDate,
                          "DD MMM yyyy"
                        )
                      : "--"} */}
                  </div>
                </div>

                <div className="hover:bg-hover px-6">
                  <p className="text-gray-400 text-[12px]">Last Call Summary</p>
                  <p className="text-[14px]">
                    {leadDetails?.summaryNote || ""}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-span-6 p-3">
          <div className="flex border rounded w-fit">
            <button
              onClick={() => navigate(`?contact=overview`)}
              className={`py-2 px-4 ${
                selectedTab === "overview" ? "bg-slate-50 " : ""
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => navigate(`?contact=activities&tab=task`)}
              className={`py-2 px-4 border-l  ${
                selectedTab === "activities" ? "bg-slate-50 " : ""
              }`}
            >
              Activities
            </button>
          </div>

          <div className="p-3 w-full">
            {selectedTab === "activities" && <ActivitiesLayout />}
            {selectedTab === "overview" && (
              <div>
                <OverViewWrapper leadDetails={leadDetails} />
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpenEditNote && (
        <AddNoteLogDialogFormWrapper
          onClose={() => setIsOpenEditNote(false)}
          contactBy={contactBy}
        />
      )}
      {isOpenAddTaskDialog && (
        <AddTaskDialogFormWrapper
          onClose={() => setIsOpenAddTaskDialog(false)}
        />
      )}
    </div>
  );
};

export default ViewContactLead;
