import { useState, useEffect } from "react";
import { useGetAllAccountsQuery } from "src/services/AccountsService";

export const useAccounts = () => {
  const [accounts, setAccounts] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllAccountsQuery("");

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setAccounts(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { accounts, isDataLoading };
};
