import { Form, Formik, FormikHelpers } from "formik";
import {
  CampaignGroupFormValues,
  CampaingGroupInfoPropType,
} from "src/models/CampaignGroup.model";
import { object, string } from "yup";
import CampaignGroupForm from "../Layouts/CampaignGroupForm";
import {
  useEditCampaignGroupMutation,
  useGetCampaignGroupByIdQuery,
} from "src/services/CampaignGroupsService";
import { useEffect, useState } from "react";
import { showToast } from "src/utils/showToaster";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  groupId: string;
};

const EditCampaignGroupFormWrapper = ({ onClose, groupId }: Props) => {
  const [editCampaignGroup] = useEditCampaignGroupMutation();
  const { data, isLoading, isFetching } = useGetCampaignGroupByIdQuery(groupId);
  const [campaignGroupInfo, setCampaignGroupInfo] =
    useState<CampaingGroupInfoPropType>();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCampaignGroupInfo(data?.data);
    }
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: CampaignGroupFormValues = {
    campaignGroupName: campaignGroupInfo?.campaignGroupName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    campaignGroupName: string().required("Please enter campaign group name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: CampaignGroupFormValues,
    { setSubmitting, resetForm }: FormikHelpers<CampaignGroupFormValues>
  ) => {
    editCampaignGroup({
      id: groupId,
      body: values,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <CampaignGroupForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditCampaignGroupFormWrapper;
