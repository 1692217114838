import { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import AddExpensesWrapper from "../Add/AddExpensesWrapper";
import ExpensesListFilterFormDialogWrapper from "./ExpensesFilterFormCard/ExpensesListFilterFormDialogWrapper";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const ExpensesListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenMeetingFilterDialog, setIsOpenMeetingFilterDialog] =
    useState<boolean>(false);
  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);

  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex justify-between px-2 " >
        <ATMPageHeader
            pageTitle="Expenses"
            searchValue={searchValue}
            isFilter
            onSearchChange={setSearchValue}
            onFilterClick={()=>{setIsOpenMeetingFilterDialog(true)}}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_TIKCETS_ADD)
            }
            buttonProps={{
              btnName: "Add New",
              onClick: ()=>{setIsOpenAddDialog(true);},
            }}
          />
        </div>
        {/* Table */}
        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-col overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "h-[50px]"}
              isLoading={isTableLoading}
            />
          </div>

          <div>
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      </div>
      <ExpensesListFilterFormDialogWrapper
              open={isOpenMeetingFilterDialog}
              onClose={() => setIsOpenMeetingFilterDialog(false)}
            />
      {isOpenAddDialog ? (
        <AddExpensesWrapper onClose={() => setIsOpenAddDialog(false)} />
      ) : null}
    </>
  );
};

export default ExpensesListing;
