import React, { useEffect, useState } from "react";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/ClarificationIncidentSlice";
import { useClarificationsListingQuery } from "src/services/IncidentService";
import moment from "moment";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import ClarificationIncidentListing from "./ClarificationIncidentListing";
import { TbProgressAlert } from "react-icons/tb";
import ClarificationAddByAdminWrapper from "../ClarificationAddByAdmin/ClarificationAddByAdminWrapper";
import MarkAsUnhandledDialogWrapper from "../../DesputedIncident/MarkAsUnhandledDialog/MarkAsUnhandledDialogWrapper";
import { RiShareForwardLine } from "react-icons/ri";
import ForwardIncidentWrapper from "../../DesputedIncident/ForwardIncident/ForwardIncidentWrapper";

type Props = {};

const ClarificationIncidentListingWrapper = (props: Props) => {
  const dispatch = useDispatch();

  const [pointerId, setPointerId] = useState<any>("");
  const [clarificationRemark, setClarificationRemark] = useState<any>();
  const [isOpenForwardDialog, setIsOpenForwardDialog] = useState(false);
  const [teamMemberId, setTeamMemberId] = useState<any>();
  const [isOpenClarificationDialog, setIsOpenClarificationDialog] =
    useState(false);
  const [isOpenUnhandledDialog, setIsOpenUnhandledDialog] = useState(false);
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    dateFilter,
  } = useSelector((state: RootState) => state.clarificationIncident);

  const { data, isLoading, isFetching } = useClarificationsListingQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["typeName", "categoryName"],
    page: page,
    filterBy: [],
    dateFilter: {
      startDate: dateFilter?.startDate ? dateFilter?.startDate : "",
      endDate: dateFilter?.endDate ? dateFilter?.endDate : "",
    },
    orderBy: "addedOnDate",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "addedOnDate",
      name: UserModuleNameTypes.CLARIFICATION_INCIDENT_LIST_ADDED_ON_DATE,
      headerName: "Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[150px]",
      renderCell: (row: any) => (
        <div className="text-[13px] text-slate-700 font-medium">
          {row?.addedOnDate
            ? moment(row?.addedOnDate).format("DD MMM YYYY")
            : ""}
        </div>
      ),
    },
    {
      field: "categoryName",
      name: UserModuleNameTypes.CLARIFICATION_INCIDENT_LIST_CATEGORY_NAME,
      headerName: "category Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "typeName",
      name: UserModuleNameTypes.CLARIFICATION_INCIDENT_LIST_TYPE_NAME,
      headerName: "type Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "teamMembers",
      name: UserModuleNameTypes.CLARIFICATION_INCIDENT_LIST_ASSIGN_TO,
      headerName: "Assign To",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[300px]",
      renderCell: (row: any) => (
        <div className="font-medium text-[12px] py-1">{row?.memberName}</div>
      ),
    },
    {
      field: "remark",
      name: UserModuleNameTypes.CLARIFICATION_INCIDENT_LIST_REMARK,
      headerName: "remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-h-[100px] overflow-scroll",
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center font-medium text-orange-400 gap-x-2">
                    <TbProgressAlert size="1.3em" color="#f99e3c" />{" "}
                    Clarification
                  </div>
                ),
                onClick: () => {
                  setIsOpenClarificationDialog(true);
                  setPointerId(row?._id);
                  setClarificationRemark(row?.clarifications);
                },
                acitonName:
                  UserModuleNameTypes.ACTION_INCIDENT_CLARIFICATION_REQUEST,
              },
              {
                label: (
                  <div className="flex items-center font-medium text-teal-600 gap-x-2">
                    <RiShareForwardLine size="1.3em" color="#12968A" /> Forward
                  </div>
                ),
                onClick: () => {
                  setPointerId(row?._id);
                  setIsOpenForwardDialog(true);
                  setTeamMemberId(row?.memberId);
                },
                acitonName: UserModuleNameTypes.ACTION_INCIDENT_FORWARD,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <IoIosCheckmarkCircleOutline size="1.4em" /> Mark As
                    Unhandled
                  </div>
                ),
                onClick: () => {
                  setIsOpenUnhandledDialog(true);
                  setPointerId(row?._id);
                },
                acitonName:
                  UserModuleNameTypes.ACTION_INCIDENT_MARK_AS_UNHANDLED,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <ClarificationIncidentListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setRowsPerPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenClarificationDialog && (
        <ClarificationAddByAdminWrapper
          onClose={() => setIsOpenClarificationDialog(false)}
          selectId={pointerId}
          clarificationRemark={clarificationRemark}
        />
      )}
      {isOpenUnhandledDialog && (
        <MarkAsUnhandledDialogWrapper
          onClose={() => setIsOpenUnhandledDialog(false)}
          selectId={pointerId}
        />
      )}
      {isOpenForwardDialog && (
        <ForwardIncidentWrapper
          teamMemberId={teamMemberId}
          onClose={() => setIsOpenForwardDialog(false)}
          pointerId={pointerId}
        />
      )}
    </div>
  );
};

export default ClarificationIncidentListingWrapper;
