// |-- Built-in Dependencies --|
import React from "react";

// |-- External Dependencies --|
import { useDispatch, useSelector } from "react-redux";

// |-- Internal Dependencies --|
import LocationListView from "../../Components/LocationListView";
import { showToast } from "src/utils/showToaster";

// |-- Redux --|
import { RootState, AppDispatch } from "src/redux/store";
import {
  setSearchValue,
  setSelectedLocationCity,
} from "src/redux/slices/CitySlice";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

// |-- Types --|
type Props = {
  city: any[];
  onAddNew: () => void;
};

const CityListing = ({ city, onAddNew }: Props) => {
  // const [isOpenAddForm, setisOpenAddForm] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { searchValue }: any = useSelector((state: RootState) => state.city);
  const { selectedLocationState }: any = useSelector(
    (state: RootState) => state.states
  );
  const { selectedLocationDistrict }: any = useSelector(
    (state: RootState) => state.city
  );

  function handleCountryClick(newValue: any) {
    if (selectedLocationDistrict?.value === newValue.value) {
      dispatch(setSelectedLocationCity(null));
    } else {
      dispatch(setSelectedLocationCity(newValue));
    }
  }

  return (
    <LocationListView
      listHeading="City"
      searchValue={searchValue}
      OnSearchChange={(newValue) => dispatch(setSearchValue(newValue))}
      listData={city}
      hideAddButton={
        !isAuthorized(UserModuleNameTypes.ACTION_CITY_ADD)
      }
      onAddClick={() => {
        if (selectedLocationState === null) {
          showToast("error", "Please select state");
        } else {
          onAddNew();
        }
      }}
      onListItemClick={(newValue) => {
        if (selectedLocationState !== null) {
          handleCountryClick(newValue);
        }
      }}
      disabled={false}
    />
  );
};

export default CityListing;
