import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import RequirementGathering from "../Layouts/RequirementGathering";
import { RequirementGatheringValues } from "src/models/RequirementGathering.model";
import { useAddRequirementGatheringMutation } from "src/services/RequirementGatheringService";
import { showToast } from "src/utils/showToaster";
import { useNavigate } from "react-router-dom";

type Props = {
  onClose: () => void;
};

const AddRequirementGatheringWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const navigate = useNavigate();
  const [addRequiremtGathering] = useAddRequirementGatheringMutation();

  // Form Initial Values
  const initialValues: RequirementGatheringValues = {
    user: "",
    title: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    user: string().required("Please enter user name"),
    title: string().required("Please enter title"),
  });

  // Handle Submit
  const handleSubmit = (
    values: RequirementGatheringValues,
    { setSubmitting, resetForm }: FormikHelpers<RequirementGatheringValues>
  ) => {
    const formattedValues = {
      requirementByName: values?.user,
      title: values?.title,
    };
    addRequiremtGathering(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate(`view/${res?.data?.data?._id}`);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <RequirementGathering
            formikProps={formikProps}
            onClose={onClose}
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddRequirementGatheringWrapper;
