export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
export const CLIENT_PANEL_URL = process.env.REACT_APP_CLIENT_PANEL_URL;
export const CLIENT_GRW = Boolean(
  process.env.REACT_APP_GRW === "true" ? true : false
);

// FIREBASE NOTIFI CRED...
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STRONG_BUCKET =
  process.env.REACT_APP_FIREBASE_STRONG_BUCKET;
export const FIREBASE_MSG_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MSG_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const FIREBASE_VAP_ID_KEY = process.env.REACT_APP_VAP_ID_KEY;
