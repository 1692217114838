import React, { useEffect, useState } from "react";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/DepartmentSlice";
import {
  useGetAllDepartmentWithPaginationQuery,
  useDeleteDepartmentMutation,
} from "src/services/DepartmentService";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdDelete, MdEdit } from "react-icons/md";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import DepartmentList from "./DepartmentList";
import { DepartmentListResponse } from "src/models/department.model";
import EditDepartmentFormWrapper from "../Edit/EditDepartmentFormWrapper";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const DepartmentListWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.department);
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [departmentId, setdepartmentId] = useState<string>("");

  const [deletedDepartment] = useDeleteDepartmentMutation();

  const handleDeleteDepartment = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this department ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deletedDepartment(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const { data, isLoading, isFetching } =
    useGetAllDepartmentWithPaginationQuery({
      limit: rowsPerPage,
      searchValue: searchValue,
      params: ["departmentName"],
      page: page,
      filterBy: [],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "departmentName",
      headerName: " Department Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120x]",
      name: UserModuleNameTypes.DEPARTMENT_LIST_DEPARTMENT_NAME,
      renderCell: (row: DepartmentListResponse) => (
        <div className="capitalize"> {row?.departmentName} </div>
      ),
    },

    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: DepartmentListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditFormDialog(true);
                  setdepartmentId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_DEPARTMENT_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDeleteDepartment(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_DEPARTMENT_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <DepartmentList
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenEditFormDialog ? (
        <EditDepartmentFormWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedDepartment={departmentId}
        />
      ) : null}
    </div>
  );
};

export default DepartmentListWrapper;
