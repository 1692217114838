import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import InternalMeetingAdd from "./InternalMeetingAdd";
import { showToast } from "src/utils/showToaster";
import { useAddMeetingMutation } from "src/services/MeetingServices";
import moment from "moment";
import { useNavigate } from "react-router-dom";

type Props = {
  onClose: () => void;
};

const InternalMeetingAddWrapper = ({ onClose }: Props) => {
  const navigate = useNavigate();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  const [addMeeting] = useAddMeetingMutation();
  // Form Initial Values
  const initialValues: any = {
    title: "",
    codioticUsers: [],
    projectId: "",
    scheduledStartTime: "",
    meetingWith: "INTERNAL_TEAM",
    meetingTypeId: "",
    address: "Office",
    scheduledDate: "",
    mode: "OFFLINE",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Title is required"),
    projectId: object().required("Project is required"),
    meetingTypeId: object().required("Meeting type is required"),
  });

  // Handle Submit
  const handleSubmit = (values: any, { setSubmitting }: FormikHelpers<any>) => {
    const {
      projectId,
      codioticUsers,
      scheduledStartTime,
      meetingTypeId,
      scheduledEndTime,
      scheduledDate,
      ...rest
    } = values;

    const formatedValues = {
      projectId: projectId?.value,
      codioticUsers: codioticUsers?.map((ele: any) => ele?.value),
      scheduledStartTime: moment(scheduledEndTime).format("HH:mm:ss"),
      meetingTypeId: meetingTypeId?.value,
      scheduledEndTime: moment(scheduledEndTime).format("HH:mm:ss"),
      scheduledDate: moment().format("YYYY-MM-DD"),
      meetingCreateType: "INTERNAL",
      ...rest,
    };
    addMeeting(formatedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (saveNextChecked) {
            navigate(`/meeting/${res?.data?.data?._id}/agenda`);
          }
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <InternalMeetingAdd
            formType="ADD"
            formikProps={formikProps}
            onClose={onClose}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
          />
        </Form>
      )}
    </Formik>
  );
};

export default InternalMeetingAddWrapper;
