import React from "react";
import { useNavigate } from "react-router-dom";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  isClientRoute?: boolean;
  onAddNew: () => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const ProjectList = ({
  columns,
  rows,
  isClientRoute,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();

  // const handleRowClick = (row: any) => {
  //   // If isClientRoute is true, open in a new tab
  //   if (isClientRoute) {
  //     window.open(`/project/${row?._id}/details`, '_blank');
  //   } else {
  //     // Otherwise, navigate as usual
  //     navigate(`${row?._id}/details`);
  //   }
  // };
  return (
    <>
      {isClientRoute ? (
        <div className="flex flex-col h-full">
          {/* Page Header */}
          <div>
            <ATMPageHeader
              pageTitle="Projects"
              searchValue={searchValue}
              onSearchChange={setSearchValue}
              hideAddButton={
                !isAuthorized(UserModuleNameTypes.ACTION_PROJECT_ADD)
              }
              buttonProps={{
                btnName: "Add New",
                onClick: () => onAddNew(),
              }}
            />
          </div>

          {/* Table */}
          <div className="flex flex-col flex-1 overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              disableRowClick={
                !isAuthorized(UserModuleNameTypes.ACTION_PROJECTS_VIEW)
              }
              onRowClick={(row) => navigate(`/project/${row?._id}/details`)}
              rowExtraClasses={() => "min-h-[40px]"}
              isLoading={isTableLoading}
            />
          </div>

          <div className="p-4 py-2 border-t">
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      ) : (
        <SideNavLayout>
          <div className="flex flex-col h-full">
            {/* Page Header */}
            <div>
              <ATMPageHeader
                pageTitle="Projects"
                searchValue={searchValue}
                onSearchChange={setSearchValue}
                hideAddButton={
                  !isAuthorized(UserModuleNameTypes.ACTION_PROJECT_ADD)
                }
                buttonProps={{
                  btnName: "Add New",
                  onClick: () => onAddNew(),
                }}
              />
            </div>

            {/* Table */}
            <div className="flex flex-col flex-1 overflow-auto">
              <ATMTable
                columns={columns}
                rows={rows}
                disableRowClick={
                  !isAuthorized(UserModuleNameTypes.ACTION_PROJECTS_VIEW)
                }
                onRowClick={(row) => navigate(`${row?._id}/details`)}
                // rowExtraClasses={() => "min-h-[40px]"}
                isLoading={isTableLoading}
              />
            </div>

            <div>
              <ATMPagination
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={setPage}
                onRowsPerPageChange={setRowsPerPage}
                rowCount={totalItems}
                rows={rows}
              />
            </div>
          </div>
        </SideNavLayout>
      )}
    </>
  );
};

export default ProjectList;
