import React, { useEffect, useState } from "react";
import IncidentTypeListing from "./IncidentTypeListing";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/IncidentTypeSlice";
import AddIncidentTypeFormWrapper from "../Add/AddIncidentTypeFormWrapper";
import { MdDelete, MdEdit } from "react-icons/md";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import EditIncidentTypeFormWrapper from "../Edit/EditIncidentTypeFormWrapper";
import { showToast } from "src/utils/showToaster";
import {
  useAllIncidentEventTypeQuery,
  useDeleteTypeMutation,
} from "src/services/IncidentTypeService";

type Props = {};

const IncidentTypeListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const [deleteEventType] = useDeleteTypeMutation();
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [typeId, setTypeId] = useState<any>("");
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.incidentType);

  const { data, isLoading, isFetching } = useAllIncidentEventTypeQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["categoryName", "typeName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "typeName",
      headerName: "Event",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.INCIDENT_TYPE_LIST_INCIDENT_TYPE_NAME,
    },
    {
      field: "categoryName",
      headerName: "category",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.INCIDENT_TYPE_LIST_INCIDENT_TYPE_CATEGORY_NAME,
    },
    {
      field: "weightage",
      headerName: "weightage",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.INCIDENT_TYPE_LIST_INCIDENT_TYPE_WEIGHT,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditForm(true);
                  setTypeId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_INCIDENT_TYPE_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  showConfirmationDialog({
                    title: "Hands Up",
                    text: "Are you sure want to delete this Incident Type ?",
                    icon: "question",
                    showCancelButton: true,
                    next: (result) => {
                      if (result?.isConfirmed) {
                        deleteEventType(row?._id).then((res: any) => {
                          if (res.error) {
                            showToast("error", res?.error?.data?.message);
                          } else {
                            showToast("success", res?.data?.message);
                          }
                        });
                      }
                    },
                  });
                },
                acitonName: UserModuleNameTypes.ACTION_INCIDENT_TYPE_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <IncidentTypeListing
        columns={columns}
        onAddNew={() => {
          setIsOpenAddForm(true);
        }}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenAddForm && (
        <AddIncidentTypeFormWrapper
          onClose={() => {
            setIsOpenAddForm(false);
          }}
        />
      )}
      {isOpenEditForm && (
        <EditIncidentTypeFormWrapper
          onClose={() => {
            setIsOpenEditForm(false);
          }}
          typeId={typeId}
        />
      )}
    </div>
  );
};

export default IncidentTypeListingWrapper;
