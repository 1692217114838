import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useGetUserAccess from "./hooks/useGetUserAccess";
import { UserModuleNameTypes } from "./models/UserAccess/UserAccess.model";
import {
  setAccessToken,
  setRefreshToken,
  setUserData,
} from "./redux/slices/AuthSlice";
import GoalCategoryListingWrapper from "./screens/Accounts/List/GoalCategoryListingWrapper";
import AddAllTaskWrapper from "./screens/AllTask/Add/AddAllTaskWrapper";
import DuplicateTaskWrapper from "./screens/AllTask/Duplicate/DuplicateTaskWrapper";
import EditAllTaskWrapper from "./screens/AllTask/Edit/EditAllTaskWrapper";
import AllTaskListingWrapper from "./screens/AllTask/List/AllTaskListingWrapper";
import SingleTaskViewWrapper from "./screens/AllTask/View/SingleTaskViewWrapper";
import ChangeRequestListWrapper from "./screens/ChangeRequest/List/ChangeRequestListWrapper";
import ChangeRequestViewTabsLayout from "./screens/ChangeRequest/View/ChangeRequestViewTabsLayout";
import ChangeRequestLogWrapper from "./screens/ChangeRequest/View/tabs/ChangeRequestLogTab/ChangeRequestLogWrapper";
import QuotationViewWrapper from "./screens/ChangeRequest/View/tabs/QuotationTab/QuotationViewWrapper";
import TicketDetailsViewWrapper from "./screens/ChangeRequest/View/tabs/TicketDetailsTab/TicketDetailsViewWrapper";
import TicketSummaryWrapper from "./screens/ChangeRequest/View/tabs/TicketSummary/TicketSummaryWrapper";
import TimeLogViewWrapper from "./screens/ChangeRequest/View/tabs/TimeLogTab/TimeLogViewWrapper";
import ClientListWrapper from "./screens/Client/List/ClientListWrapper";
import ClientDetailsWrapper from "./screens/Client/View/ClientDetailsTab/ClientDetailsWrapper";
import ClientLedgerListingWrapper from "./screens/Client/View/ClientLedger/List/ClientLedgerListingWrapper";
import ClientViewTabsLayout from "./screens/Client/View/ClientViewTabsLayout";
import ClientUserListWrapper from "./screens/ClientUser/List/ClientUserListWrapper";
import ConfigurationLayout from "./screens/Configuration/ConfigurationLayout";
import CurrencyListingWrapper from "./screens/Currency/List/CurrencyListingWrapper";
import DeliveryDateListingWrapper from "./screens/DeliveryDate/List/DeliveryDateListingWrapper";
import DeploymentRequestListWrapper from "./screens/DeloymentRequest/List/DeploymentRequestListWrapper";
import DepartmentListWrapper from "./screens/Department/List/DepartmentListWrapper";
import DomainListingWrapper from "./screens/Domains/List/DomainListingWrapper";
import DoubtsListWrapper from "./screens/Doubts/List/DoubtsListWrapper";
import CloseDoubtsListingWrapper from "./screens/Doubts/View/Tabs/CloseDoubts/List/CloseDoubtsLIstingWrapper";
import SingleDoubtViewWrapper from "./screens/Doubts/View/Tabs/OpenDoubts/SingleDoubtViewWrapper";
import ViewDoubtsLayout from "./screens/Doubts/View/Tabs/ViewDoubtsLayout";
import DumpListingWrapper from "./screens/Dumbs/List/DumpListingWrapper";
import AddEstimationCalculatorFormWrapper from "./screens/EstimationCalculator/Add/AddEstimationCalculatorFormWrapper";
import EditEstimationCalculatorFormWrapper from "./screens/EstimationCalculator/Edit/EditEstimationCalculatorFormWrapper";
import GoalListingWrapper from "./screens/Expenses/List/GoalListingWrapper";
import ViewGoalWrapper from "./screens/Expenses/View/ViewGoalWrapper";
import AccountsListingWrapper from "./screens/Finance/Accounts/List/AccountsListingWrapper";
import ExpenseCategoryListingWrapper from "./screens/Finance/ExpenseCategory/List/ExpenseCategoryListingWrapper";
import ExpensesListingWrapper from "./screens/Finance/Expenses/List/ExpensesListingWrapper";
import FinanceDashboardListingWrapper from "./screens/Finance/FinanceDashboard/FinanceDashboardListingWrapper";
import FinanceLayout from "./screens/Finance/FinanceLayout";
import RecurringListingWrapper from "./screens/Finance/Subscription copy/List/RecurringListingWrapper";
import SubscriptionListingWrapper from "./screens/Finance/Subscription/List/SubscriptionListingWrapper";
import UpcomingPaymentsListingWrapper from "./screens/Finance/UpcomingPayments/List/UpcomingPaymentsListingWrapper";
import GitRepoListWrapper from "./screens/GitRepo/List/GitRepoListWrapper";
import HolidayCalendarListingWrapper from "./screens/HolidayCalendar/List/HolidayCalendarListingWrapper";
import InvalidRoutePage from "./screens/InvalidRoutePage/InvalidRoutePage";
import LandingPage from "./screens/LandingPage";
import LeadLabelListingWrapper from "./screens/LeadLabel/List/LeadLabelListingWrapper";
import LeaveRequestListWrapper from "./screens/LeaveRequest/List/LeaveRequestListWrapper";
import ViewLeaveRequestWrapper from "./screens/LeaveRequest/View/ViewLeaveRequestWrapper";
import Locations from "./screens/Location/Location";
import LoginFormWrapper from "./screens/Login/LoginFormWrapper";
import AddMeetingFormWrapper from "./screens/Meeting/Add/AddMeetingFormWrapper";
import AddConversationWrapper from "./screens/Meeting/AddConversation/AddConversationWrapper";
import EditMeetingFormWrapper from "./screens/Meeting/Edit/EditMeetingFormWrapper";
import EditConversationWrapper from "./screens/Meeting/EditConversation/EditConversationWrapper";
import MeetingListWrapper from "./screens/Meeting/List/MeetingListWrapper";
import MeetingViewTabsLayout from "./screens/Meeting/View/MeetingViewTabsLayout";
import AgendaTabWrapper from "./screens/Meeting/View/tabs/AgendaTab/AgendaTabWrapper";
import AttendeesTabWrapper from "./screens/Meeting/View/tabs/AttendeesTab/AttendeesTabWrapper";
import MeetingMomWrapper from "./screens/Meeting/View/tabs/MeetingMOM/MeetingMomWrapper";
import NotesTabWrapper from "./screens/Meeting/View/tabs/NotesTab/NotesTabWrapper";
import MeetingNotesListWrapper from "./screens/MeetingNotes/MeetingNotesListWrapper";
import MeetingNoteTypeListingWrapper from "./screens/MeetingNotesType/List/MeetingNoteTypeListingWrapper";
import MeetingTypeListingWrapper from "./screens/MeetingType/List/MeetingTypeListingWrapper";
import ModuleListWrapper from "./screens/Module/List/ModuleListWrapper";
import ProductListingWrapper from "./screens/Products/List/ProductListingWrapper";
import ProductViewTabsLayout from "./screens/Products/View/ProductViewTabsLayout";
import CustomerListingWrapper from "./screens/Products/View/Tabs/Customer/List/CustomerListingWrapper";
import PlansListingWrapper from "./screens/Products/View/Tabs/Plans/List/PlansListingWrapper";
import AddProjectFormWrapper from "./screens/Project/Add/AddProjectFormWrapper";
import EditProjectFormWrapper from "./screens/Project/Edit/EditProjectFormWrapper";
import ProjectListWrapper from "./screens/Project/List/ProjectListWrapper";
import ProjectViewTabsLayout from "./screens/Project/View/ProjectViewTabsLayout";
import ProjectDetailsWrapper from "./screens/Project/View/tabs/ProjectDetailsTab/ProjectDetailsWrapper";
import ProjectStatusViewWrapper from "./screens/Project/View/tabs/ProjectDetailsTab/ProjectStatusView/ProjectStatusViewWrapper";
import ProjectFlowAddWrapper from "./screens/Project/View/tabs/ProjectFlowTab/Add/ProjectFlowAddWrapper";
import ProjectFlowEditWrapper from "./screens/Project/View/tabs/ProjectFlowTab/Edit/ProjectFlowEditWrapper";
import ProjectFlowTabWrapper from "./screens/Project/View/tabs/ProjectFlowTab/List/ProjectFlowTabWrapper";
import ProjectMembersTabFormWrapper from "./screens/Project/View/tabs/ProjectMembersTab/ProjectMembersTabFormWrapper";
import ProjectResourceRequestListWrapper from "./screens/Project/View/tabs/ProjectResourceRequest/ProjectResourceRequestListWrapper";
import MeetingTimeLogAnalyticsWrapper from "./screens/Project/View/tabs/ProjectTimeReportTab/MeetingsTime/List/MeetingTimeLogAnalyticsWrapper";
import ProjectTimeAnalyticsLayout from "./screens/Project/View/tabs/ProjectTimeReportTab/ProjectTimeAnalyticsLayout";
import TaskTimeLogAnalyticsWrapper from "./screens/Project/View/tabs/ProjectTimeReportTab/TasksTime/List/TaskTimeLogAnalyticsWrapper";
import ReminderListingWrapper from "./screens/Reminder/List/ReminderListingWrapper";
import AddRequirementFormWrapper from "./screens/Requirement/Add/AddRequirementFormWrapper";
import DuplicateRequirementFormWrapper from "./screens/Requirement/Duplicate/DuplicateRequirementFormWrapper";
import EditRequirementWrapper from "./screens/Requirement/Edit/EditRequirementWrapper";
import RequirementListWrapper from "./screens/Requirement/List/RequirementListWrapper";
import ResourceRequestListWrapper from "./screens/ResourceRequest/List/ResourceRequestListWrapper";
import ResourceRequestViewWrapper from "./screens/ResourceRequest/View/ResourceRequestViewWrapper";
import AddUserAccessWrapper from "./screens/Roles/Add/AddUserAccessWrapper";
import UserRoleListingWrapper from "./screens/Roles/List/UserRoleListingWrapper";
import CampaignListingWrapper from "./screens/SalesAndMarketing/Configuration/Campaign/List/CampaignListingWrapper";
import CampaignGroupListingWrapper from "./screens/SalesAndMarketing/Configuration/CampaignGroup/List/CampaignGroupListingWrapper";
import ChannelListingWrapper from "./screens/SalesAndMarketing/Configuration/Channel/List/ChannelListingWrapper";
import ChannelGroupListingWrapper from "./screens/SalesAndMarketing/Configuration/ChannelGroup/List/ChannelGroupListingWrapper";
import FollowupListingWrapper from "./screens/SalesAndMarketing/Followup/FollowupListingWrapper";
import AddLeadsFormWrapper from "./screens/SalesAndMarketing/Leads/Add/AddLeadsFromWrapper";
import LeadsListingWrapper from "./screens/SalesAndMarketing/Leads/List/LeadsListingWrapper";
import ViewLeadsFromWrapper from "./screens/SalesAndMarketing/Leads/View/Tabs/Details/ViewLeadsFromWrapper";
import LeadsFollowUpListingWrapper from "./screens/SalesAndMarketing/Leads/View/Tabs/FollowUp/List/LeadsFollowUpListingWrapper";
import ViewLayouts from "./screens/SalesAndMarketing/Leads/View/ViewLayouts";
import SalesAndMarketingLayout from "./screens/SalesAndMarketing/SalesAndMarketingLayout";
import ServerListWrapper from "./screens/Server/List/ServerListWrapper";
import ServiceTicketsListingWrapper from "./screens/ServiceTickets/List/ServiceTicketsListingWrapper";
import SingleServiceTicketViewWrapper from "./screens/ServiceTickets/View/SingleServiceTicketViewWrapper";
import SocialMediaListingWrapper from "./screens/SocialMedia/List/SocialMediaListingWrapper";
import TeamListWrapper from "./screens/Team/List/TeamListWrapper";
import TeamDetailsWrapper from "./screens/Team/View/TeamDetailsTab/TeamDetailsWrapper";
import DocumentListingWrapper from "./screens/Team/View/TeamDocuments/List/DocumentListingWrapper";
import TeamSalaryTabListWrapper from "./screens/Team/View/TeamSalaryTab/List/TeamSalaryTabListWrapper";
import TeamViewTabsLayout from "./screens/Team/View/TeamViewTabsLayout";
import TeamAttendanceWrapper from "./screens/TeamAttendance/List/TeamAttendanceWrapper";
import TeamViewListingWrapper from "./screens/TeamView/TeamViewListingWrapper";
import TicketListWrapper from "./screens/Ticket/List/TicketListWrapper";
import TicketChatWrapper from "./screens/Ticket/View/TicketTabs/Chat/TicketChatWrapper";
import TicketSummary from "./screens/Ticket/View/TicketTabs/Summary/TicketSummary";
import TaskListingWrapper from "./screens/Ticket/View/TicketTabs/Task/List/TaskListingWrapper";
import SingleTicketTaskViewWrapper from "./screens/Ticket/View/TicketTabs/Task/View/SingleTicketTaskViewWrapper";
import TicketCloseSummeryWrapper from "./screens/Ticket/View/TicketTabs/TicketCloseSummery/TicketCloseSummeryWrapper";
import TicketDetailsWrapper from "./screens/Ticket/View/TicketTabs/TicketDetails/TicketDetailsWrapper";
import TicketIssuesListingWrapper from "./screens/Ticket/View/TicketTabs/TicketIssues/List/TicketIssuesListingWrapper";
import TicketTimeAnalysisWrapper from "./screens/Ticket/View/TicketTabs/TicketTimeAnalysis/TicketTimeAnalysisWrapper";
import TicketViewLayout from "./screens/Ticket/View/TicketViewLayout";
import Welcome from "./utils/Welcome";
import Authorization from "./utils/userAccess/Authorization";

import { useEffect } from "react";
import EstimationCalculatorListingWrapper from "./screens/EstimationCalculator/List/EstimationCalculatorListingWrapper";
import FinanceManagementListingWrapper from "./screens/FinanceNew/Account/List/AccountListingWrapper";
import CashFlowListingListingWrapper from "./screens/FinanceNew/CashFlow/List/CashFlowListingListingWrapper";
import FinanceDashboardWrapper from "./screens/FinanceNew/Dashboard/FinanceDashboardWrapper";
import AddExpenseWrapper from "./screens/FinanceNew/Expense/Add/AddExpenseWrapper";
import ExpenseListingWrapper from "./screens/FinanceNew/Expense/List/ExpenseListingWrapper";
import AddIncomeFormWrapper from "./screens/FinanceNew/Income/Add/AddIncomeFormWrapper";
import EditIncomeFormWrapper from "./screens/FinanceNew/Income/Edit/EditIncomeFormWrapper";
import IncomeListingWrapper from "./screens/FinanceNew/Income/List/IncomeListingWrapper";
import PaymentModeListingWrapper from "./screens/FinanceNew/PaymentMode/List/PaymentModeListingWrapper";
import AddRecurringIncomeFormWrapper from "./screens/FinanceNew/RecurringIncome/Add/AddRecurringIncomeFormWrapper";
import EditRecurringIncomeFormWrapper from "./screens/FinanceNew/RecurringIncome/Edit/EditRecurringIncomeFormWrapper";
import RecurringIncomeListingWrapper from "./screens/FinanceNew/RecurringIncome/List/RecurringIncomeListingWrapper";
import IncidentCategoryListingWrapper from "./screens/Incident/IncidentCategory/List/IncidentCategoryListingWrapper";
import IncidentLayout from "./screens/Incident/IncidentLayout";
import IncidentTypeListingWrapper from "./screens/Incident/IncidentType/List/IncidentTypeListingWrapper";
import PerformanceListingWrapper from "./screens/Incident/Performance/List/PerformanceListingWrapper";
import AddJobApplicationFormWrapper from "./screens/JobApplication/Add/AddJobApplicationFormWrapper";
import EditJobApplicationFormWrapper from "./screens/JobApplication/Edit/EditJobApplicationFormWrapper";
import JobApplicationListingWrapper from "./screens/JobApplication/List/JobApplicationListingWrapper";
import AddJobCreationFormWrapper from "./screens/JobOnboard/JobCreation/Add/AddJobCreationFormWrapper";
import EditJobCreationFormWrapper from "./screens/JobOnboard/JobCreation/Edit/EditJobCreationFormWrapper";
import JobCreationListingWrapper from "./screens/JobOnboard/JobCreation/List/JobCreationListingWrapper";
import ViewJobCreationLayout from "./screens/JobOnboard/JobCreation/View/ViewJobCreationLayout";
import JobOnboardLayout from "./screens/JobOnboard/JobOnboardLayout";
import PayoutListingWrapper from "./screens/JobOnboard/Payout/List/PayoutListingWrapper";
import ProfileDetailsFormWrapper from "./screens/Profile/EditDetails/ProfileDetailsFormWrapper";
import ProfileLayout from "./screens/Profile/ProfileLayout";
import RequirementGatheringListingWrapper from "./screens/RequirementGathering/List/RequirementGatheringListingWrapper";
import RequirementGatheringLayout from "./screens/RequirementGathering/View/RequirementGatheringLayout";
import FeaturesListingWrapper from "./screens/RequirementGathering/View/Tabs/Features/FeaturesListingWrapper";
import RequirementGatheringPlatformsListingWrapper from "./screens/RequirementGathering/View/Tabs/Platforms/List/RequirementGatheringPlatformsListingWrapper";
import RequirementGatheringUsersListingWrapper from "./screens/RequirementGathering/View/Tabs/Users/List/RequirementGatheringUsersListingWrapper";
import ScreenAndFeatureWrapper from "./screens/ScreenAndFeatures/ScreenAndFeatureWrapper/ScreenAndFeatureWrapper";
import SkillListingWrapper from "./screens/Skill/List/SkillListingWrapper";
import TeamProfileTabWrapper from "./screens/Team/View/TeamProfileTab/TeamProfileTabWrapper";
import AddTicketGroupFormWrapper from "./screens/TicketGroup/Add/AddTicketGroupFormWrapper";
import TicketGroupListWrapper from "./screens/TicketGroup/List/TicketGroupListWrapper";
import TicketGroupViewTabsLayout from "./screens/TicketGroup/View/TicketGroupViewTabsLayout";
import TicketGroupDetailsViewWrapper from "./screens/TicketGroup/View/tabs/TicketDetailsTab/TicketGroupDetailsViewWrapper";
import TimeAnalyticsViewWrapper from "./screens/TicketGroup/View/tabs/TimeAnalyticsTab/TimeAnalyticsViewWrapper";
import TicketGroupTimeLogViewWrapper from "./screens/TicketGroup/View/tabs/TimeLogTab/TicketGroupTimeLogViewWrapper";
import TimeAnalyticsWrapper from "./screens/TimeAnayl/List/TimeAnalyticsWrapper";
import WorkspaceLisWrapper from "./screens/Workspace/List/WorkspaceLisWrapper";
import PlatformViewTabsLayout from "./screens/Workspace/View/Platform/View/View/PlatformViewTabsLayout";
import PlatFormProjectDetailsWrapper from "./screens/Workspace/View/Platform/View/View/tabs/ProjectDetailsTab/ProjectDetailsWrapper";
import PlatformProjectStatusViewWrapper from "./screens/Workspace/View/Platform/View/View/tabs/ProjectDetailsTab/ProjectStatusView/ProjectStatusViewWrapper";
import PlatformProjectFlowAddWrapper from "./screens/Workspace/View/Platform/View/View/tabs/ProjectFlowTab/Add/ProjectFlowAddWrapper";
import PlatformProjectFlowEditWrapper from "./screens/Workspace/View/Platform/View/View/tabs/ProjectFlowTab/Edit/ProjectFlowEditWrapper";
import PlatformProjectFlowTabWrapper from "./screens/Workspace/View/Platform/View/View/tabs/ProjectFlowTab/List/ProjectFlowTabWrapper";
import PlatformProjectMembersTabFormWrapper from "./screens/Workspace/View/Platform/View/View/tabs/ProjectMembersTab/ProjectMembersTabFormWrapper";
import PlatformProjectResourceRequestListWrapper from "./screens/Workspace/View/Platform/View/View/tabs/ProjectResourceRequest/ProjectResourceRequestListWrapper";
import PlatformMeetingTimeLogAnalyticsWrapper from "./screens/Workspace/View/Platform/View/View/tabs/ProjectTimeReportTab/MeetingsTime/List/MeetingTimeLogAnalyticsWrapper";
import PlatformProjectTimeAnalyticsLayout from "./screens/Workspace/View/Platform/View/View/tabs/ProjectTimeReportTab/ProjectTimeAnalyticsLayout";
import PlatformTaskTimeLogAnalyticsWrapper from "./screens/Workspace/View/Platform/View/View/tabs/ProjectTimeReportTab/TasksTime/List/TaskTimeLogAnalyticsWrapper";
import WorkspaceViewLayout from "./screens/Workspace/View/WorkspaceViewLayout";

import JobApplicationViewLayout from "./screens/JobApplication/View/JobApplicationViewLayout";
import UpdateJobApplicationWrapper from "./screens/JobApplication/View/Tabs/Details/UpdateJobApplicationWrapper";
import JobApplicationStageWrapper from "./screens/JobApplication/View/Tabs/JobApplicationStagesTab/JobApplicationStageWrapper";
import UpdateJobCreationFormWrapper from "./screens/JobOnboard/JobCreation/View/Tabs/Details/UpdateJobDetailsWrapper";
import JobCreationStageListingWrapper from "./screens/JobOnboard/JobCreation/View/Tabs/JobCreationStage/List/JobCreationStageListingWrapper";
import SalarySlipViewWrapper from "./screens/SalarySlip/View/SalarySlipViewWrapper";

import BusinessListingWrapper from "./screens/EnterpriseSuite/BusinessModel/List/BusinessListingWrapper";
import AddJobCreationStageFormWrapper from "./screens/JobOnboard/JobCreation/View/Tabs/JobCreationStage/Add/AddJobCreationStageFormWrapper";
import EditJobCreationStageFormWrapper from "./screens/JobOnboard/JobCreation/View/Tabs/JobCreationStage/Edit/EditJobCreationStageFormWrapper";
import ViewJobCreationStage from "./screens/JobOnboard/JobCreation/View/Tabs/JobCreationStage/View/ViewJobCreationStage";
import ContactListingWrapper from "./screens/Contact/List/ContactListingWrapper";
import ContactLeadListingWrapper from "./screens/Contact/View/ContactLead/List/ContactLeadListingWrapper";
import ViewContactLeadWrapper from "./screens/Contact/View/ContactLead/View/ViewContactLeadWrapper";
import OperationView from "./screens/EnterpriseSuite/Operation/View/OperationView";
import EditOperationFormWrapper from "./screens/EnterpriseSuite/Operation/Edit/EditOperationFormWrapper";
import AddOperationFormWrapper from "./screens/EnterpriseSuite/Operation/Add/AddOperationFormWrapper";
import OperationsListingWrapper from "./screens/EnterpriseSuite/Operation/List/OperationsListingWrapper";
import RoundTrackingReportListingWrapper from "./screens/JobOnboard/RoundTrackingReport/List/RoundTrackingReportListingWrapper";
import EnterpriseSuiteLayout from "./screens/EnterpriseSuite/EnterpriseSuiteLayout";
import IndustryListingWrapper from "./screens/EnterpriseSuite/Industry/List/IndustryListingWrapper";
import JobOnboardingListingWrapper from "./screens/JobOnboard/JobOnboarding/List/JobOnboardingListingWrapper";
import JobOnboardingViewLayout from "./screens/JobOnboard/JobOnboarding/View/JobOnboardingViewLayout";
import JobOnboardingStageWrapper from "./screens/JobOnboard/JobOnboarding/View/Tabs/JobApplicationStagesTab/JobOnboardingStageWrapper";
import AddOnBoardStageFormWrapper from "./screens/JobOnboard/OnBoardStage/Add/AddOnBoardStageFormWrapper";
import OnBoardStageListingWrapper from "./screens/JobOnboard/OnBoardStage/List/OnBoardStageListingWrapper";
import EditOnBoardStageFormWrapper from "./screens/JobOnboard/OnBoardStage/Edit/EditOnBoardStageFormWrapper";
import UpdateJobOnboardingWrapper from "./screens/JobOnboard/JobOnboarding/View/Tabs/Details/UpdateJobOnboardingWrapper";
const PageRoutes = () => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("authToken");
  const refreshToken = localStorage.getItem("refresh_token");
  const userDataLJson = localStorage.getItem("userData");
  const userData = JSON?.parse(userDataLJson as string);

  useEffect(() => {
    dispatch(setAccessToken(accessToken));
    dispatch(setRefreshToken(refreshToken));
    dispatch(setUserData(userData ? userData : null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isDataLoading } = useGetUserAccess();
  if (isDataLoading) {
    return (
      <div className="flex items-center justify-center h-[100vh] w-full">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/welcome"
            element={
              <Authorization
                children={<Welcome />}
                permission={UserModuleNameTypes.WELCOME}
              />
            }
          />

          <Route
            path="contact"
            element={
              <Authorization
                children={<ContactListingWrapper />}
                permission={UserModuleNameTypes.NAV_CONTACT}
              />
            }
          />
          <Route
            path="contact/:contactId/leads"
            element={
              <Authorization
                children={<ContactLeadListingWrapper />}
                permission={UserModuleNameTypes.NAV_CONTACT}
              />
            }
          />
          <Route
            path="contact/:contactId/leads/:leadId"
            element={
              <Authorization
                children={<ViewContactLeadWrapper />}
                permission={UserModuleNameTypes.NAV_CONTACT}
              />
            }
          />

          <Route
            path="/requirement-gathering"
            element={
              <Authorization
                children={<RequirementGatheringListingWrapper />}
                permission={UserModuleNameTypes.NAV_REQUIREMENT_GATHERING}
              />
            }
          />
          <Route
            path="/requirement-gathering/view/:requirementId"
            element={
              <Authorization
                children={<RequirementGatheringLayout />}
                permission={
                  UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW
                }
              />
            }
          >
            <Route
              path="user"
              element={
                <Authorization
                  children={<RequirementGatheringUsersListingWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB
                  }
                />
              }
            />
            <Route
              path="platforms"
              element={
                <Authorization
                  children={<RequirementGatheringPlatformsListingWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORMS_TAB
                  }
                />
              }
            />
            <Route
              path="features"
              element={
                <Authorization
                  children={<FeaturesListingWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_FEATURES_TAB
                  }
                />
              }
            />
          </Route>
          <Route
            path="/screen-and-features"
            element={<ScreenAndFeatureWrapper />}
          />
          <Route path="*" element={<InvalidRoutePage />} />
          <Route
            path="/"
            element={
              <Authorization
                children={<LandingPage />}
                permission={UserModuleNameTypes.NAV_DASHBOARD}
              />
            }
          />
          <Route path="/login" element={<LoginFormWrapper />} />
          <Route
            path="/reminder"
            element={
              <Authorization
                children={<ReminderListingWrapper />}
                permission={UserModuleNameTypes.NAV_REMINDER}
              />
            }
          />

          {/* <Route path="/client" element={<ClientListWrapper />} /> */}
          <Route
            path="/client"
            element={
              <Authorization
                children={<ClientListWrapper />}
                permission={UserModuleNameTypes.NAV_CLIENTS}
              />
            }
          />

          <Route
            path="/team-attendance"
            element={
              <Authorization
                children={<TeamAttendanceWrapper />}
                permission={UserModuleNameTypes.NAV_TEAM_ATTENDANCE}
              />
            }
          />
          <Route path="client/:clientId" element={<ClientViewTabsLayout />}>
            <Route path="details" element={<ClientDetailsWrapper />} />
            <Route path="projects" element={<ProjectListWrapper />} />
            <Route path="meetings" element={<MeetingListWrapper />} />
            <Route
              path="change-request"
              element={<ChangeRequestListWrapper />}
            />
            <Route
              path="resources"
              element={<ProjectResourceRequestListWrapper />}
            />
            <Route
              path="client-ledger"
              element={<ClientLedgerListingWrapper />}
            />
          </Route>

          <Route
            path="/product"
            element={
              <Authorization
                children={<ProductListingWrapper />}
                permission={UserModuleNameTypes.NAV_PRODUCTS}
              />
            }
          />

          <Route path="/product/:productId" element={<ProductViewTabsLayout />}>
            <Route path="plans" element={<PlansListingWrapper />} />
            <Route path="customer" element={<CustomerListingWrapper />} />
          </Route>

          <Route
            path="/client-user"
            element={
              <Authorization
                children={<ClientUserListWrapper />}
                permission={UserModuleNameTypes.NAV_CLIENT_USERS}
              />
            }
          />
          <Route
            path="/team"
            element={
              <Authorization
                children={<TeamListWrapper />}
                permission={UserModuleNameTypes.NAV_TEAMS}
              />
            }
          />
          <Route path="team/:teamMemberId" element={<TeamViewTabsLayout />}>
            <Route path="profile" element={<TeamProfileTabWrapper />} />
            <Route
              path="transaction-history"
              element={<TeamDetailsWrapper />}
            />
            <Route
              path="salary-summary"
              element={<TeamSalaryTabListWrapper />}
            />
            <Route path="documents" element={<DocumentListingWrapper />} />
          </Route>
          <Route
            path="/project"
            element={
              <Authorization
                children={<ProjectListWrapper />}
                permission={UserModuleNameTypes.NAV_PROJECTS}
              />
            }
          />
          <Route
            path="/project/add"
            element={
              <Authorization
                children={<AddProjectFormWrapper />}
                permission={UserModuleNameTypes.ACTION_PROJECT_ADD}
              />
            }
          />
          <Route
            path="/project/:id"
            element={
              <Authorization
                children={<EditProjectFormWrapper />}
                permission={UserModuleNameTypes.ACTION_PROJECT_EDIT}
              />
            }
          />
          <Route
            path="/delivery-date"
            element={
              <Authorization
                children={<DeliveryDateListingWrapper />}
                permission={UserModuleNameTypes.NAV_DELIVERY_DATE}
              />
            }
          />
          <Route path="/project/:id" element={<ProjectViewTabsLayout />}>
            <Route
              path="details"
              element={
                <Authorization
                  children={<ProjectDetailsWrapper />}
                  permission={UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS}
                />
              }
            />
            <Route path="details/edit" element={<EditRequirementWrapper />} />
            <Route
              path="details/ticket/:ticketId"
              element={<TicketViewLayout />}
            />
            <Route
              path="project-analytics"
              element={
                <Authorization
                  children={<ProjectTimeAnalyticsLayout />}
                  permission={
                    UserModuleNameTypes.ACTION_PROJECTS_VIEW_TIME_ANALYTICS
                  }
                />
              }
            >
              <Route
                path="tasks"
                element={
                  <Authorization
                    children={<TaskTimeLogAnalyticsWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_PROJECTS_VIEW_TIME_ANALYTICS_TASKS_TAB
                    }
                  />
                }
              />
              <Route
                path="meetings"
                element={
                  <Authorization
                    children={<MeetingTimeLogAnalyticsWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_PROJECTS_VIEW_TIME_ANALYTICS_MEETINGS_TAB
                    }
                  />
                }
              />
            </Route>

            <Route
              path="flow"
              element={
                <Authorization
                  children={<ProjectFlowTabWrapper />}
                  permission={UserModuleNameTypes.ACTION_PROJECTS_VIEW_FLOW}
                />
              }
            />
            <Route path="flow/add" element={<ProjectFlowAddWrapper />} />
            <Route
              path="flow/edit/:flowId"
              element={<ProjectFlowEditWrapper />}
            />

            <Route
              path="status"
              element={
                <Authorization
                  children={<ProjectStatusViewWrapper />}
                  permission={UserModuleNameTypes.ACTION_PROJECTS_VIEW_STATUS}
                />
              }
            />
            <Route
              path="members"
              element={
                <Authorization
                  children={<ProjectMembersTabFormWrapper />}
                  permission={UserModuleNameTypes.ACTION_PROJECTS_VIEW_MEMBERS}
                />
              }
            />
            <Route
              path="resource-request"
              element={
                <Authorization
                  children={<ProjectResourceRequestListWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_PROJECTS_VIEW_RESOURCE_REQUEST
                  }
                />
              }
            />
          </Route>
          <Route
            path="sales&marketing"
            element={
              <Authorization
                children={<SalesAndMarketingLayout />}
                permission={UserModuleNameTypes.NAV_SALES_MARKETING}
              />
            }
          />
          <Route
            path="/module"
            element={
              <Authorization
                children={<ModuleListWrapper />}
                permission={UserModuleNameTypes.NAV_MODULES}
              />
            }
          />
          <Route
            path="/ticket"
            element={
              <Authorization
                children={<TicketListWrapper />}
                permission={UserModuleNameTypes.NAV_TIKCETS}
              />
            }
          />
          <Route
            path="/git-repo"
            element={
              <Authorization
                children={<GitRepoListWrapper />}
                permission={UserModuleNameTypes.NAV_GIT_REPO}
              />
            }
          />
          <Route
            path="/server"
            element={
              <Authorization
                children={<ServerListWrapper />}
                permission={UserModuleNameTypes.NAV_SERVER}
              />
            }
          />
          <Route
            path="/deployment-request"
            element={
              <Authorization
                children={<DeploymentRequestListWrapper />}
                permission={UserModuleNameTypes.NAV_DEPLOYMENT_REQUEST}
              />
            }
          />
          {/* ACTION_LEAVE_REQUEST_SINGLE_VIEW */}
          <Route
            path="/leave-request/view/:id/"
            element={<ViewLeaveRequestWrapper />}
          />
          <Route
            path="/task/add"
            element={
              <Authorization
                children={<AddAllTaskWrapper />}
                permission={UserModuleNameTypes.ACTION_TASK_ADD}
              />
            }
          />
          <Route
            path="/task/edit/:id"
            element={
              <Authorization
                children={<EditAllTaskWrapper />}
                permission={UserModuleNameTypes.ACTION_TASK_EDIT}
              />
            }
          />
          <Route
            path="/task/duplicate/:id"
            element={
              <Authorization
                children={<DuplicateTaskWrapper />}
                permission={UserModuleNameTypes.ACTION_TASK_DUPLICATE}
              />
            }
          />
          <Route
            path="/requirement"
            element={
              <Authorization
                children={<RequirementListWrapper />}
                permission={UserModuleNameTypes.NAV_FEATURES}
              />
            }
          />
          <Route
            path="/requirement/add"
            element={
              <Authorization
                children={<AddRequirementFormWrapper />}
                permission={UserModuleNameTypes.ACTION_FEATURES_ADD}
              />
            }
          />
          <Route
            path="/requirement/:singleFeatureId"
            element={
              <Authorization
                children={<EditRequirementWrapper />}
                permission={UserModuleNameTypes.ACTION_FEATURES_EDIT}
              />
            }
          />
          <Route
            path="/requirement/duplicate/:singleFeatureId"
            element={
              <Authorization
                children={<DuplicateRequirementFormWrapper />}
                permission={UserModuleNameTypes.ACTION_FEATURES_DUPLICATE}
              />
            }
          />
          <Route
            path="/leave-request"
            element={
              <Authorization
                children={<LeaveRequestListWrapper />}
                permission={UserModuleNameTypes.NAV_LEAVE_REQUEST}
              />
            }
          />
          <Route
            path="/resource-request"
            element={
              <Authorization
                children={<ResourceRequestListWrapper />}
                permission={UserModuleNameTypes.NAV_RESOURCE_REQUEST}
              />
            }
          />
          <Route
            path="/resource-request/:id/view"
            element={
              <Authorization
                children={<ResourceRequestViewWrapper />}
                permission={
                  UserModuleNameTypes.ACTION_RESOURCE_REQUEST_SINGLE_VIEW
                }
              />
            }
          />
          <Route path="/ticket/:ticketId" element={<TicketViewLayout />}>
            <Route
              path="details"
              element={
                <Authorization
                  children={<TicketDetailsWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_TICKET_DETAILS
                  }
                />
              }
            />
            <Route
              path="summary"
              element={
                <Authorization
                  children={<TicketSummary />}
                  permission={
                    UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_SUMMARY
                  }
                />
              }
            />
            <Route
              path="chat"
              element={
                <Authorization
                  children={<TicketChatWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_CHAT
                  }
                />
              }
            />
            <Route
              path="task"
              element={
                <Authorization
                  children={<TaskListingWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_TASK
                  }
                />
              }
            />
            <Route path="task/:id" element={<SingleTicketTaskViewWrapper />} />
            <Route
              path="ticket-time-analysis"
              element={
                <Authorization
                  children={<TicketTimeAnalysisWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_TIME_ANALYIS
                  }
                />
              }
            />
            <Route
              path="ticket-close-summery"
              element={
                <Authorization
                  children={<TicketCloseSummeryWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_CLOSE_SUMMARY
                  }
                />
              }
            />
            <Route
              path="issues"
              element={
                <Authorization
                  children={<TicketIssuesListingWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_ISSUES
                  }
                />
              }
            />
          </Route>
          {/* TEAM VIEW */}
          <Route
            path="/team-view"
            element={
              <Authorization
                children={<TeamViewListingWrapper />}
                permission={UserModuleNameTypes.NAV_TEAM_VIEW}
              />
            }
          />
          {/* Change Request */}
          <Route
            path="/change-request"
            element={
              <Authorization
                children={<ChangeRequestListWrapper />}
                permission={UserModuleNameTypes.NAV_CHANGE_REQUEST}
              />
            }
          />

          <Route
            path="/change-request/:id"
            element={<ChangeRequestViewTabsLayout />}
          >
            <Route
              path="ticket-details"
              element={
                <Authorization
                  children={<TicketDetailsViewWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS
                  }
                />
              }
            />
            <Route
              path="ticket-details/:ticketId"
              element={<TicketViewLayout />}
            >
              <Route path="details" element={<TicketDetailsWrapper />} />
              <Route path="summary" element={<TicketSummary />} />
              <Route path="chat" element={<TicketChatWrapper />} />
              <Route path="task" element={<TaskListingWrapper />} />
              <Route
                path="ticket-time-analysis"
                element={<TicketTimeAnalysisWrapper />}
              />
              <Route
                path="ticket-close-summery"
                element={<TicketCloseSummeryWrapper />}
              />
              <Route
                path="task/:id"
                element={<SingleTicketTaskViewWrapper />}
              />
            </Route>

            <Route
              path="time-log"
              element={
                <Authorization
                  children={<TimeLogViewWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TIME_LOGS
                  }
                />
              }
            />
            <Route
              path="time-summary"
              element={
                <Authorization
                  children={<TicketSummaryWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_SUMMARY
                  }
                />
              }
            />
            <Route path="logs" element={<ChangeRequestLogWrapper />} />
            <Route path="time-quotation" element={<QuotationViewWrapper />} />
          </Route>

          {/* ALL TASK */}
          <Route
            path="/task"
            element={
              <Authorization
                children={<AllTaskListingWrapper />}
                permission={UserModuleNameTypes.NAV_TASK}
              />
            }
          />
          <Route path="/task/:id" element={<SingleTaskViewWrapper />} />
          <Route
            path="/estimations/estimation-calculator"
            element={
              <Authorization
                children={<AddEstimationCalculatorFormWrapper />}
                permission={
                  UserModuleNameTypes.ACTION_ESTIMATION_CALCULATOR_ADD
                }
              />
            }
          />
          <Route
            path="/estimations/:id"
            element={
              <Authorization
                children={<EditEstimationCalculatorFormWrapper />}
                permission={
                  UserModuleNameTypes.ACTION_ESTIMATION_CALCULATOR_VIEW
                }
              />
            }
          />
          <Route
            path="/estimations"
            element={
              <Authorization
                children={<EstimationCalculatorListingWrapper />}
                permission={UserModuleNameTypes.NAV_ESTIMATION_CALCULATOR}
              />
            }
          />
          {/* Doubts */}
          <Route
            path="/doubt"
            element={
              <Authorization
                children={<ViewDoubtsLayout />}
                permission={UserModuleNameTypes.NAV_DOUBTS}
              />
            }
          >
            <Route
              path="/doubt/open"
              element={
                <Authorization
                  children={<DoubtsListWrapper />}
                  permission={UserModuleNameTypes.ACTION_DOUBTS_OPEN_LIST}
                />
              }
            />
            <Route
              path="/doubt/close"
              element={
                <Authorization
                  children={<CloseDoubtsListingWrapper />}
                  permission={UserModuleNameTypes.ACTION_DOUBTS_CLOSE_LIST}
                />
              }
            />
          </Route>
          <Route path="/doubt/:id" element={<SingleDoubtViewWrapper />} />
          {/* Meeting */}
          <Route
            path="/meeting"
            element={
              <Authorization
                children={<MeetingListWrapper />}
                permission={UserModuleNameTypes.NAV_MEETING}
              />
            }
          />
          <Route
            path="/meeting/add"
            element={
              <Authorization
                children={<AddMeetingFormWrapper />}
                permission={UserModuleNameTypes.ACTION_MEETING_ADD}
              />
            }
          />
          <Route
            path="/meeting/conversation/add"
            element={<AddConversationWrapper />}
          />

          <Route
            path="/meeting/:id/edit"
            element={
              <Authorization
                children={<EditMeetingFormWrapper />}
                permission={UserModuleNameTypes.ACTION_MEETING_EDIT}
              />
            }
          />
          <Route
            path="/meeting/conversation/:id/edit"
            element={<EditConversationWrapper />}
          />
          {/* checking */}
          <Route path="/meeting/:meetingId" element={<MeetingViewTabsLayout />}>
            <Route
              path="agenda"
              element={
                <Authorization
                  children={<AgendaTabWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_MEETING_SINGLE_VIEW_AGENDA
                  }
                />
              }
            />
            <Route
              path="attendees"
              element={
                <Authorization
                  children={<AttendeesTabWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_MEETING_SINGLE_VIEW_ATTENDEES
                  }
                />
              }
            />
            <Route
              path="notes"
              element={
                <Authorization
                  children={<NotesTabWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_MEETING_SINGLE_VIEW_NOTES
                  }
                />
              }
            />
            <Route
              path="mom"
              element={
                <Authorization
                  children={<MeetingMomWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_MEETING_SINGLE_VIEW_MOM
                  }
                />
              }
            />
          </Route>
          <Route
            path="/sales&marketing"
            element={
              <Authorization
                children={<SalesAndMarketingLayout />}
                permission={UserModuleNameTypes.NAV_SALES_MARKETING}
              />
            }
          >
            <Route
              path="channel-group"
              element={
                <Authorization
                  children={<ChannelGroupListingWrapper />}
                  permission={UserModuleNameTypes.NAV_CHANNEL_GROUP}
                />
              }
            />
            <Route
              path="channel"
              element={
                <Authorization
                  children={<ChannelListingWrapper />}
                  permission={UserModuleNameTypes.NAV_CHANNEL}
                />
              }
            />
            <Route
              path="campaign-group"
              element={
                <Authorization
                  children={<CampaignGroupListingWrapper />}
                  permission={UserModuleNameTypes.NAV_CAMPAIGN_GROUP}
                />
              }
            />
            <Route
              path="campaign"
              element={
                <Authorization
                  children={<CampaignListingWrapper />}
                  permission={UserModuleNameTypes.NAV_CAMPAIGN}
                />
              }
            />
            <Route
              path="social-media"
              element={
                <Authorization
                  children={<SocialMediaListingWrapper />}
                  permission={UserModuleNameTypes.NAV_SOCIAL_MEDIA}
                />
              }
            />
            <Route
              path="domain"
              element={
                <Authorization
                  children={<DomainListingWrapper />}
                  permission={UserModuleNameTypes.NAV_DOMAIN}
                />
              }
            />
            <Route
              path="lead-label"
              element={
                <Authorization
                  children={<LeadLabelListingWrapper />}
                  permission={UserModuleNameTypes.NAV_LEAD_LABEL}
                />
              }
            />
            <Route
              path="leads"
              element={
                <Authorization
                  children={<LeadsListingWrapper />}
                  permission={UserModuleNameTypes.NAV_LEADS}
                />
              }
            />
            <Route
              path="leads/add"
              element={
                <Authorization
                  children={<AddLeadsFormWrapper />}
                  permission={UserModuleNameTypes.ACTION_LEADS_ADD}
                />
              }
            />
            <Route
              path="follow-up"
              element={
                <Authorization
                  children={<FollowupListingWrapper />}
                  permission={UserModuleNameTypes.NAV_FOLLOW_UP}
                />
              }
            />
            <Route
              path="leads/:leadsId"
              element={<ViewLayouts />}
            // element={
            //   <Authorization
            //     children={<ViewLayouts />}
            //     permission={UserModuleNameTypes.ACTION_LEADS_SINGLE_VIEW_DETAILS || UserModuleNameTypes.ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP}
            //   />
            // }
            >
              <Route
                path="details"
                element={
                  <Authorization
                    children={<ViewLeadsFromWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_LEADS_SINGLE_VIEW_DETAILS
                    }
                  />
                }
              />
              <Route
                path="followup"
                element={
                  <Authorization
                    children={<LeadsFollowUpListingWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP
                    }
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path="/configuration"
            element={
              <Authorization
                children={<ConfigurationLayout />}
                permission={UserModuleNameTypes.NAV_CONFIGURATION}
              />
            }
          >
            {/* incidentCategory */}
            <Route
              path="incidentCategory"
              element={
                <Authorization
                  children={<IncidentCategoryListingWrapper />}
                  permission={UserModuleNameTypes.NAV_INCIDENT_CATEGORY}
                />
              }
            />

            {/* incidentType */}
            <Route
              path="incidentType"
              element={
                <Authorization
                  children={<IncidentTypeListingWrapper />}
                  permission={UserModuleNameTypes.NAV_INCIDENT_TYPE}
                />
              }
            />
            {/* Skills */}
            <Route
              path="skill"
              element={
                <Authorization
                  children={<SkillListingWrapper />}
                  permission={UserModuleNameTypes.NAV_INCIDENT_TYPE}
                />
              }
            />
            {/* Location  */}
            <Route
              path="location"
              element={
                <Authorization
                  children={<Locations />}
                  permission={UserModuleNameTypes.NAV_LOCATION}
                />
              }
            />
            <Route
              path="currency"
              element={
                <Authorization
                  children={<CurrencyListingWrapper />}
                  permission={UserModuleNameTypes.NAV_CURRENCY}
                />
              }
            />

            <Route
              path="meeting-notes-type"
              element={
                <Authorization
                  children={<MeetingNoteTypeListingWrapper />}
                  permission={UserModuleNameTypes.NAV_MEETING_NOTES_TYPE}
                />
              }
            />

            <Route
              path="meeting-type"
              element={
                <Authorization
                  children={<MeetingTypeListingWrapper />}
                  permission={UserModuleNameTypes.NAV_MEETING_TYPE}
                />
              }
            />
          </Route>
          <Route
            path="holiday-calendar"
            element={
              <Authorization
                children={<HolidayCalendarListingWrapper />}
                permission={UserModuleNameTypes.NAV_HOLIDAY_CALENDAR}
              />
            }
          />
          <Route
            path="dump"
            element={
              <Authorization
                children={<DumpListingWrapper />}
                permission={UserModuleNameTypes.NAV_DUMPS}
              />
            }
          />
          <Route
            path="user-role"
            element={
              <Authorization
                children={<UserRoleListingWrapper />}
                permission={UserModuleNameTypes.NAV_ROLES}
              />
            }
          />
          <Route path="user-access" element={<AddUserAccessWrapper />} />
          <Route
            path="department"
            element={
              <Authorization
                children={<DepartmentListWrapper />}
                permission={UserModuleNameTypes.NAV_DEPARTMENT}
              />
            }
          />
          <Route
            path="/finance"
            element={
              <Authorization
                children={<FinanceLayout />}
                permission={UserModuleNameTypes.NAV_FINANCE}
              />
            }
          >
            {/* Subscription  */}
            <Route
              path="subscription"
              element={
                <Authorization
                  children={<SubscriptionListingWrapper />}
                  permission={UserModuleNameTypes.NAV_SUBSCRIPTION}
                />
              }
            />

            {/* Recurring Payments  */}
            <Route
              path="recurring-payments"
              element={
                <Authorization
                  children={<RecurringListingWrapper />}
                  permission={UserModuleNameTypes.NAV_RECURRING_PAYMENTS}
                />
              }
            />
            {/* Accounts  */}
            <Route
              path="accounts"
              element={
                <Authorization
                  children={<AccountsListingWrapper />}
                  permission={UserModuleNameTypes.NAV_ACCOUNTS}
                />
              }
            />
            {/* Expense Category  */}
            <Route
              path="expense-category"
              element={
                <Authorization
                  children={<ExpenseCategoryListingWrapper />}
                  permission={UserModuleNameTypes.NAV_EXPENSE_CATEGORY}
                />
              }
            />
            {/* Expenses  */}
            <Route
              path="expenses"
              element={
                <Authorization
                  children={<ExpensesListingWrapper />}
                  permission={UserModuleNameTypes.NAV_EXPENSES}
                />
              }
            />
            {/* Expense Category  */}
            <Route
              path="upcoming-payments"
              element={
                <Authorization
                  children={<UpcomingPaymentsListingWrapper />}
                  permission={UserModuleNameTypes.NAV_UPCOMING_PAYMENTS}
                />
              }
            />
            {/* dashboard  */}
            <Route
              path="dashboard"
              element={
                <Authorization
                  children={<FinanceDashboardListingWrapper />}
                  permission={UserModuleNameTypes.NAV_FINANCE_DASHBOARD}
                />
              }
            />

            {/* Finance New Routes */}
            {/* Dashboard New */}
            <Route
              path="dashboard-new"
              element={
                <Authorization
                  children={<FinanceDashboardWrapper />}
                  permission={UserModuleNameTypes.NAV_FINANCE_DASHBOARD}
                />
              }
            />
            <Route
              path="account-finance"
              element={<FinanceManagementListingWrapper />}
            />
            <Route
              path="payment-mode"
              element={<PaymentModeListingWrapper />}
            />

            {/* Income New */}
            <Route
              path="income-new"
              element={
                // <Authorization
                // children={
                <IncomeListingWrapper />
                // }
                // permission={UserModuleNameTypes.NAV_FINANCE_DASHBOARD}
                // />
              }
            />
            <Route
              path="expense-new"
              element={
                // <Authorization
                // children={
                <ExpenseListingWrapper />
                // }
                // permission={UserModuleNameTypes.NAV_FINANCE_DASHBOARD}
                // />
              }
            />
            {/* <Route path="expenseform" element={<ExpenseListingWrapper />} /> */}
            <Route path="incomeform" element={<AddIncomeFormWrapper />} />
            <Route path="incomeform/:id" element={<EditIncomeFormWrapper />} />

            {/* Recurring Income */}
            <Route
              path="recurringincome"
              element={<RecurringIncomeListingWrapper />}
            />
            <Route
              path="recurringincomeform"
              element={<AddRecurringIncomeFormWrapper />}
            />
            <Route
              path="recurringincomeform/:id"
              element={<EditRecurringIncomeFormWrapper />}
            />
            <Route path="expenseform" element={<AddExpenseWrapper />} />

            <Route
              path="cashflow"
              element={<CashFlowListingListingWrapper />}
            />
          </Route>

          {/* GOAL CATEGORY  */}
          <Route
            path="goal-category"
            element={
              <Authorization
                children={<GoalCategoryListingWrapper />}
                permission={UserModuleNameTypes.NAV_GOAL_CATEGORY}
              />
            }
          />
          {/* GOAL   */}
          <Route
            path="goals"
            element={
              <Authorization
                children={<GoalListingWrapper />}
                permission={UserModuleNameTypes.NAV_GOAL}
              />
            }
          />
          <Route
            path="goals/:id"
            element={
              <Authorization
                children={<ViewGoalWrapper />}
                permission={UserModuleNameTypes.NAV_GOAL}
              />
            }
          />
          {/* Service Ticket */}
          <Route
            path="service-ticket"
            element={
              <Authorization
                children={<ServiceTicketsListingWrapper />}
                permission={UserModuleNameTypes.NAV_SERVICE_TICKET}
              />
            }
          />
          <Route
            path="service-ticket/chat/:id"
            element={
              <Authorization
                children={<SingleServiceTicketViewWrapper />}
                permission={UserModuleNameTypes.NAV_SERVICE_TICKET}
              />
            }
          />

          {/* Meeting Notes */}
          <Route
            path="meeting-notes"
            element={
              <Authorization
                children={<MeetingNotesListWrapper />}
                permission={UserModuleNameTypes.NAV_MEETING_NOTES}
              />
            }
          />
          {/* incident */}
          <Route
            path="incident"
            element={
              <Authorization
                children={<IncidentLayout />}
                permission={UserModuleNameTypes.NAV_INCIDENT}
              />
            }
          />
          {/* Performance */}
          <Route path="performance" element={<PerformanceListingWrapper />} />

          {/* Performance */}
          <Route path="profile" element={<ProfileLayout />} />
          <Route
            path="profile/:profileId"
            element={<ProfileDetailsFormWrapper />}
          />
          <Route
            path="profile/salarySlip/:teamMemberId"
            element={<SalarySlipViewWrapper />}
          />

          {/* Time Analytics */}
          <Route
            path="time-analytics"
            element={
              <Authorization
                children={<TimeAnalyticsWrapper />}
                permission={UserModuleNameTypes.NAV_TIME_ANALYTICS}
              />
            }
          />
          {/* Change Request */}
          <Route
            path="/ticket-group"
            element={
              <Authorization
                children={<TicketGroupListWrapper />}
                permission={UserModuleNameTypes.NAV_TICKET_GROUP}
              />
            }
          />
          <Route
            path="/ticket-group/add"
            element={
              <Authorization
                children={<AddTicketGroupFormWrapper />}
                permission={UserModuleNameTypes.NAV_TICKET_GROUP}
              />
            }
          />
          <Route
            path="/ticket-group/:id"
            element={<TicketGroupViewTabsLayout />}
          >
            <Route
              path="ticket-details"
              element={
                <Authorization
                  children={<TicketGroupDetailsViewWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS
                  }
                />
              }
            />

            <Route
              path="time-log"
              element={
                <Authorization
                  children={<TicketGroupTimeLogViewWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TIME_LOGS
                  }
                />
              }
            />
            <Route
              path="time-analytics"
              element={<TimeAnalyticsViewWrapper />}
            />
          </Route>

          {/* Workspace */}

          <Route
            path="/workspace"
            element={
              <Authorization
                children={<WorkspaceLisWrapper />}
                permission={UserModuleNameTypes.NAV_WORKSPACE}
              />
            }
          ></Route>
          {/* VIew Workspace */}
          <Route
            path="/workspace/:id"
            element={
              <Authorization
                children={<WorkspaceViewLayout />}
                permission={UserModuleNameTypes.NAV_WORKSPACE}
              />
            }
          ></Route>

          <Route
            path="/workspace/:id/platform/:platformId"
            element={<PlatformViewTabsLayout />}
          >
            <Route
              path="details"
              element={
                <Authorization
                  children={<PlatFormProjectDetailsWrapper />}
                  permission={UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS}
                />
              }
            />
            <Route path="details/edit" element={<EditRequirementWrapper />} />
            <Route
              path="details/ticket/:ticketId"
              element={<TicketViewLayout />}
            />
            <Route
              path="project-analytics"
              element={
                <Authorization
                  children={<PlatformProjectTimeAnalyticsLayout />}
                  permission={
                    UserModuleNameTypes.ACTION_PROJECTS_VIEW_TIME_ANALYTICS
                  }
                />
              }
            >
              <Route
                path="tasks"
                element={
                  <Authorization
                    children={<PlatformTaskTimeLogAnalyticsWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_PROJECTS_VIEW_TIME_ANALYTICS_TASKS_TAB
                    }
                  />
                }
              />
              <Route
                path="meetings"
                element={
                  <Authorization
                    children={<PlatformMeetingTimeLogAnalyticsWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_PROJECTS_VIEW_TIME_ANALYTICS_MEETINGS_TAB
                    }
                  />
                }
              />
            </Route>

            <Route
              path="flow"
              element={
                <Authorization
                  children={<PlatformProjectFlowTabWrapper />}
                  permission={UserModuleNameTypes.ACTION_PROJECTS_VIEW_FLOW}
                />
              }
            />
            <Route
              path="flow/add"
              element={<PlatformProjectFlowAddWrapper />}
            />
            <Route
              path="flow/edit/:flowId"
              element={<PlatformProjectFlowEditWrapper />}
            />

            <Route
              path="status"
              element={
                <Authorization
                  children={<PlatformProjectStatusViewWrapper />}
                  permission={UserModuleNameTypes.ACTION_PROJECTS_VIEW_STATUS}
                />
              }
            />
            <Route
              path="members"
              element={
                <Authorization
                  children={<PlatformProjectMembersTabFormWrapper />}
                  permission={UserModuleNameTypes.ACTION_PROJECTS_VIEW_MEMBERS}
                />
              }
            />
            <Route
              path="resource-request"
              element={
                <Authorization
                  children={<PlatformProjectResourceRequestListWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_PROJECTS_VIEW_RESOURCE_REQUEST
                  }
                />
              }
            />
            <Route
              // path="screen-and-feature"
              path="screen-feature"
              element={
                <ScreenAndFeatureWrapper />
                // <ScreenAndFeatureWrapper />
                // <Authorization
                //   children={<ScreenAndFeatureWrapper />}
                //   permission={UserModuleNameTypes.NAV_DASHBOARD}
                // />
              }
            ></Route>
          </Route>

          {/* JOB ONBOARD */}

          <Route
            path="/job-onboard"
            element={
              <Authorization
                children={<JobOnboardLayout />}
                permission={UserModuleNameTypes.NAV_HR_MODULE}
              />
            }
          >
            <Route
              path="job-creation"
              element={
                <Authorization
                  children={<JobCreationListingWrapper />}
                  permission={UserModuleNameTypes.NAV_JOB_CREATION}
                />
              }
            ></Route>
            <Route
              path="job-creation/add"
              element={
                <Authorization
                  children={<AddJobCreationFormWrapper />}
                  permission={UserModuleNameTypes.ACTION_JOB_CREATION_ADD}
                />
              }
            />
            <Route
              path="job-creation/edit/:id"
              element={
                <Authorization
                  children={<EditJobCreationFormWrapper />}
                  permission={UserModuleNameTypes.ACTION_JOB_CREATION_EDIT}
                />
              }
            />
            <Route
              path="job-creation/view/:id"
              element={
                <Authorization
                  children={<ViewJobCreationLayout />}
                  permission={UserModuleNameTypes.ACTION_JOB_CREATION_VIEW}
                />
              }
            >
              {/* <Route
                path="application"
                element={
                  <Authorization
                    children={<RequirementGatheringUsersListingWrapper />}
                    permission={UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB}
                  />
                }
              /> */}
              <Route
                path="job-details"
                element={
                  <Authorization
                    children={<UpdateJobCreationFormWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_DETAILS_TAB
                    }
                  />
                }
              />
              <Route
                path="job-application"
                element={
                  <Authorization
                    children={<JobApplicationListingWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPICATION_TAB
                    }
                  />
                }
              />
              <Route
                path="stages"
                element={
                  <Authorization
                    children={<JobCreationStageListingWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB
                    }
                  />
                }
              />
              <Route
                path="stages/add"
                element={
                  <Authorization
                    children={<AddJobCreationStageFormWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB
                    }
                  />
                }
              />
              <Route
                path="stages/:stageId"
                element={
                  <Authorization
                    children={<ViewJobCreationStage />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB
                    }
                  />
                }
              />
              <Route
                path="stages/update/:stageId"
                element={
                  <Authorization
                    children={<EditJobCreationStageFormWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB
                    }
                  />
                }
              />
              <Route
                path="round-tracking-report"
                element={
                  <Authorization
                    children={<RoundTrackingReportListingWrapper />}
                    permission={UserModuleNameTypes.NAV_PAYOUT}
                  />
                }
              />

              <Route
                path="job-application/add"
                element={
                  <Authorization
                    children={<AddJobApplicationFormWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPICATION_TAB
                    }
                  />
                }
              />
              <Route
                path="job-application/edit/:applicationId"
                element={
                  <Authorization
                    children={<EditJobApplicationFormWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPICATION_TAB
                    }
                  />
                }
              />
              <Route
                path="job-application/view/:applicationId"
                element={
                  <Authorization
                    children={<JobApplicationViewLayout />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_VIEW
                    }
                  />
                }
              >
                <Route
                  path="details"
                  element={
                    <Authorization
                      children={<UpdateJobApplicationWrapper />}
                      permission={
                        UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_DETAILS_TAB
                      }
                    />
                  }
                />
                <Route
                  path="stages"
                  element={
                    <Authorization
                      children={<JobApplicationStageWrapper />}
                      permission={
                        UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB
                      }
                    />
                  }
                />
              </Route>
            </Route>

            <Route
              path="on-board-stage"
              element={
                <Authorization
                  children={<OnBoardStageListingWrapper />}
                  permission={UserModuleNameTypes.NAV_JOB_CREATION}
                />
              }
            />
            <Route
              path="on-board-stage/add"
              element={
                <Authorization
                  children={<AddOnBoardStageFormWrapper />}
                  permission={UserModuleNameTypes.NAV_JOB_CREATION}
                />
              }
            />
            <Route
              path="on-board-stage/edit/:id"
              element={
                <Authorization
                  children={<EditOnBoardStageFormWrapper />}
                  permission={UserModuleNameTypes.NAV_JOB_CREATION}
                />
              }
            />

            <Route
              path="hr-questions"
              element={
                <Authorization
                  children={<ChannelGroupListingWrapper />}
                  permission={UserModuleNameTypes.NAV_CHANNEL_GROUP}
                />
              }
            />
            <Route
              path="payout"
              element={
                <Authorization
                  children={<PayoutListingWrapper />}
                  permission={UserModuleNameTypes.NAV_PAYOUT}
                />
              }
            />
            <Route
              path="job-onboarding"
              element={
                <Authorization
                  children={<JobOnboardingListingWrapper />}
                  permission={UserModuleNameTypes.NAV_PAYOUT}
                />
              }
            />

            <Route
              path="job-onboarding/view/:applicationId"
              element={
                <Authorization
                  children={<JobOnboardingViewLayout />}
                  permission={
                    UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_VIEW
                  }
                />
              }
            >
              <Route
                path="details"
                element={
                  <Authorization
                    children={<UpdateJobOnboardingWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_DETAILS_TAB
                    }
                  />
                }
              />
              <Route
                path="stages"
                element={
                  <Authorization
                    children={<JobOnboardingStageWrapper />}
                    permission={
                      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB
                    }
                  />
                }
              />
            </Route>
          </Route>

          <Route
            path="/enterprisesuite"
            element={
              <Authorization
                children={<EnterpriseSuiteLayout />}
                permission={UserModuleNameTypes.NAV_ENTERPRISESUITE}
              />
            }
          >
            <Route
              path="industry"
              element={
                <Authorization
                  children={<IndustryListingWrapper />}
                  permission={UserModuleNameTypes.NAV_INDUSTRY}
                />
              }
            />
            <Route
              path="business"
              element={
                <Authorization
                  children={<BusinessListingWrapper />}
                  permission={UserModuleNameTypes.NAV_BUSINESS}
                />
              }
            />
            <Route
              path="operation"
              element={
                <Authorization
                  children={<OperationsListingWrapper />}
                  permission={UserModuleNameTypes.NAV_OPERATIONS}
                />
              }
            ></Route>
            <Route
              path="operation/add"
              element={
                <Authorization
                  children={<AddOperationFormWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_FEATURE_AS_OPERATIONS_ADD
                  }
                />
              }
            />
            <Route
              path="operation/edit/:id"
              element={
                <Authorization
                  children={<EditOperationFormWrapper />}
                  permission={
                    UserModuleNameTypes.ACTION_FEATURE_AS_OPERATIONS_EDIT
                  }
                />
              }
            />
            <Route
              path="operation/View/:id"
              element={
                <Authorization
                  children={<OperationView />}
                  permission={
                    UserModuleNameTypes.ACTION_FEATURE_AS_OPERATIONS_VIEW
                  }
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};
export default PageRoutes;
