import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { array, object, string } from "yup";
import { ProjectFormValues } from "src/models/Project.model";
import ProjectForm from "../Layouts/ProjectForm";
import { useAddProjectMutation } from "src/services/ProjectServices";
import { showToast } from "src/utils/showToaster";
import { useNavigate } from "react-router-dom";

const AddProjectFormWrapper = () => {
  const [addProject] = useAddProjectMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  const navigate = useNavigate();
  // Form Initial Values
  const initialValues: ProjectFormValues = {
    client: "",
    projectName: "",
    timeQuotation :"",
    perDayWorkingHour : "6.5",
    members: [],
    label: [
      {
        labelName: "",
        status: "",
      },
    ],
    layer: [
      {
        layerName: "",
      },
    ],
  };

  // Validation Schema
  const validationSchema = object().shape({
    client: object().required("Please select client"),
    projectName: string().required("Please enter project name"),
    // members: array().of(
    //   object().shape({
    //     label: string().required("Please enter label name"),
    //     value: string().required("Please enter label name"),
    //   })
    // ),
    label: array().of(
      object().shape({
        labelName: string().required("Please enter label name"),
        status: object().required("Please select status of label"),
      })
    ),
    layer: array().of(
      object().shape({
        layerName: string().required("Please enter Layer name"),
      })
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: ProjectFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ProjectFormValues>
  ) => {
    const { client, members, label, layer,perDayWorkingHour, timeQuotation, ...rest } = values;
    const formattedValues = {
      ...rest,
      clientId: client?._id || "",
      perDayWorkingHour : perDayWorkingHour ? perDayWorkingHour : "6.5",
      timeQuotation: timeQuotation ? parseInt(timeQuotation) : 1.5,
      members: members?.map((ele: any) => ele?.value),
      label: label?.map((ele: any) => {
        return {
          labelName: ele?.labelName,
          status: ele?.status?.value,
        };
      }),
      layer: layer?.map((ele: any) => {
        return {
          layerName: ele?.layerName?.toUpperCase(),
        };
      }),
    };

    addProject(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            navigate("/project");
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ProjectForm
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddProjectFormWrapper;
