import React from 'react'
import SideNavLayout from 'src/components/layouts/SideNavLayout/SideNavLayout'
import PlatformListingWrapper from './Platform/List/PlatformListingWrapper'

const WorkspaceViewLayout = () => {
    return (
        <SideNavLayout>
            <div className='flex flex-col'>
                <div className='flex justify-center my-20'>Workspace details</div>
                <PlatformListingWrapper />
            </div>

        </SideNavLayout>
    )
}

export default WorkspaceViewLayout