import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { FeatureAddFormValues } from "src/models/Feature.model";
import { useAddRequirementMutation } from "src/services/RequirementService";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import { useState } from "react";
import AddassignTicketFeatureForm from "./AddassignTicketFeatureForm";

type Props = {
    onClose: () => void;
    singleModuleData?: any;
    moduleOptions?:any
};

const AddassignTicketFeatureFormWrapper = ({ onClose, singleModuleData , moduleOptions }: Props) => {
    const [addFeature] = useAddRequirementMutation();
    const [saveNextChecked, setSaveNextChecked] = useState(true);

    // Form Initial Values
    const initialValues: FeatureAddFormValues = {
        project: {
            label: singleModuleData ? singleModuleData?.projectName : "",
            value: singleModuleData ? singleModuleData?._id : ""
        },
        moduleId: '',
        featureName: "",
    };

    // Validation Schema
    const validationSchema = object().shape({
        featureName: string().required("Please enter feature name"),
    });

    // Handle Submit
    const handleSubmit = (
        values: FeatureAddFormValues,
        { setSubmitting, resetForm }: FormikHelpers<FeatureAddFormValues>
    ) => {
        const { project, moduleId, ...rest } = values;
        const formattedValues = { ...rest, projectId: project?.value || "", moduleId : moduleId?.value || "" };
        addFeature(formattedValues).then((res: any) => {
            if (res?.error) {
                showToast("error", res?.error?.data?.message);
            } else {
                if (res?.data?.status) {
                    showToast("success", res?.data?.message);
                    if (!saveNextChecked) {
                        resetForm();
                        onClose();
                    }
                } else {
                    showToast("error", res?.data?.message);
                }
            }
            setSubmitting(false);
        });
    };

    return (
        <Dialog open maxWidth="xs" fullWidth>
            <Formik 
            enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formikProps) => (
                    <Form>
                        <AddassignTicketFeatureForm
                            singleModuleData={singleModuleData}
                            formType="Add"
                            moduleOptions={moduleOptions}
                            formikProps={formikProps}
                            setSaveNextChecked={setSaveNextChecked}
                            saveNextChecked={saveNextChecked}
                            onClose={onClose}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default AddassignTicketFeatureFormWrapper
