import apiSlice from "./ApiSlice";

export const industryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all industry pagination
    getIndustryPagination: builder.query({
      providesTags: ["industry"],
      query: (body) => {
        return {
          url: "/industry",
          method: "POST",
          body,
        };
      },
    }),

    // Get all industry
    getAllIndustry: builder.query({
      providesTags: ["industry"],
      query: () => {
        return {
          url: "/industry",
          method: "GET",
        };
      },
    }),

    // Get single industry
    getSingleIndustry: builder.query({
      providesTags: ["industry"],
      query: (id: string) => {
        return {
          url: `/industry/${id}`,
          method: "GET",
        };
      },
    }),

    // Add industry
    addIndustry: builder.mutation({
      invalidatesTags: ["industry"],
      query: (body) => {
        return {
          url: "/industry/add",
          method: "POST",
          body,
        };
      },
    }),

    // Update social media
    updateIndustry: builder.mutation({
      invalidatesTags: ["industry"],
      query: ({ id, body }) => {
        return {
          url: `/industry/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // delete industry
    deleteIndustry: builder.mutation({
      invalidatesTags: ["industry"],
      query: (id: any) => ({
        url: `/industry/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetIndustryPaginationQuery,
  useGetAllIndustryQuery,
  useGetSingleIndustryQuery,
  useAddIndustryMutation,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,
} = industryApi;
