import { useEffect } from "react";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import MOLJobApplicationStatusChip from "src/components/UI/molecules/MOLJobApplicationStatusChip/MOLJobApplicationStatusChip";
import { JobApplicationListResponse } from "src/models/JobApplication.model";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/JobOnboardingSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { useAllOnboardApplicationsQuery } from "src/services/JobOnboardingService";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import JobApplicationList from "./JobOnboardingList";

type Props = {};

const JobOnboardingListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    // selectedClient,
  } = useSelector((state: RootState) => state.jobOnboarding);

  const { data, isLoading, isFetching } = useAllOnboardApplicationsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["applicantName",
      "applicantEmail",
      "applicantPhoneNumber"
    ],
    page: page,
    filterBy: [

    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const getOptions = (row: any) => {
    const defaultOption = [
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdEdit color="#000000" /> Edit
          </div>
        ),
        onClick: () => {
          navigate(`edit/${row?._id}`);
        },
        acitonName:
          UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_EDIT,
      },
    ];
  

    return row?.applicationStatus === "SHORTLISTED" ||
      row?.applicationStatus === "REJECTED"
      ? defaultOption
      : [...defaultOption,];
  };

  const columns: columnTypes[] = [
    {
      field: "applicantName",
      headerName: "Applicant Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      // name: UserModuleNameTypes.LEAD_LABEL_LIST_LEAD_LABEL_NAME,
    },
    {
      field: "applicantEmail",
      headerName: "Applicant Email",
      flex: "flex-[1_1_0%]",
      // name: UserModuleNameTypes.LEAD_LABEL_LIST_LEAD_LABEL_NAME,
    },
    {
      field: "applicantPhoneNumber",
      headerName: "Mobile Number",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      // name: UserModuleNameTypes.LEAD_LABEL_LIST_LEAD_LABEL_NAME,
    },
    {
      field: "applicationDate",
      headerName: "Applied Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      // name: UserModuleNameTypes.LEAD_LABEL_LIST_LEAD_LABEL_NAME,
      renderCell: (row: JobApplicationListResponse) => (
        <span>
          {formatedDateTimeIntoIst(row?.applicationDate, "DD MMM yyyy")}
        </span>
      ),
    },
    {
      field: "platform",
      headerName: "Platform",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      // name: UserModuleNameTypes.LEAD_LABEL_LIST_LEAD_LABEL_NAME,
    },

    {
      field: "resumeLink",
      headerName: "Resume",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      // name: UserModuleNameTypes.LEAD_LABEL_LIST_LEAD_LABEL_NAME,
      renderCell: (row: JobApplicationListResponse) => (
        <a
          href={row?.resumeLink}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 underline"
        >
          Resume
        </a>
      ),
    },
    {
      field: "applicationStatus",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      renderCell: (row: JobApplicationListResponse) => (
        <MOLJobApplicationStatusChip status={row?.applicationStatus} />
      ),
      // name: UserModuleNameTypes.LEAD_LABEL_LIST_LEAD_LABEL_NAME,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => {
        const menuOptions = getOptions(row);
        return (
          <div>
            <ATMActionMenu options={menuOptions} />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <JobApplicationList
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
        onAddNew={() => navigate("add")}
        onRowClick={(applicationId: string) =>
          navigate(`view/${applicationId}/details`)
        }
      />
    
    </div>
  );
};

export default JobOnboardingListingWrapper;
