import { ErrorMessage, FormikProps } from "formik";
import moment from "moment";
import ATMAmountField from "src/components/UI/atoms/ATMAmountField/ATMAmountField";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMNumberPicker from "src/components/UI/atoms/ATMNumberPicker/ATMNumberPicker";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMSwitchButton from "src/components/UI/atoms/formFields/ATMSwitchButton/ATMSwitchButton";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { RecurringIncomeFormProps } from "src/models/RecurringIncome.model";
import { useGetFinanceCategoryQuery } from "src/services/Finance-New-Services/FinanceCategroyService";
import {
  handleValidPercent,
  handleValidWithDotNumber,
} from "src/utils/validations/numberInput";
interface DayOption {
  value: string;
  label: string;
}

const daysOptions: DayOption[] = [
  { value: "SUNDAY", label: "S" },
  { value: "MONDAY", label: "M" },
  { value: "TUESDAY", label: "T" },
  { value: "WEDNESDAY", label: "W" },
  { value: "THRUSDAY", label: "T" },
  { value: "FRIDAY", label: "F" },
  { value: "SATURDAY", label: "S" },
];
const untilData = [
  { value: "FOREVER", label: "Forever" },
  { value: "DATE", label: "Date" },
  { value: "NO OF EVENTS", label: "No of Events" },
];
const repeatData = [
  {
    label: "Daily",
    value: "DAILY",
  },
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
  {
    label: "Yearly",
    value: "YEARLY",
  },
];

type Props = {
  formikProps: FormikProps<RecurringIncomeFormProps>;
  onClose: any;
  formType: "ADD" | "EDIT";
};
const RecurringIncomeFormLayout = ({
  formType = "ADD",
  formikProps,
  onClose,
}: Props) => {
  const { data: getCategory, isLoading: isCategoryLoading } =
    useGetFinanceCategoryQuery("");
  const formHeading =
    formType === "ADD" ? "Add Recurring Income" : "Update Recurring Income";
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  return (
    <div className="flex flex-col">
      <div className="py-2 border-b">
        <div className="flex items-center justify-between w-[70%] m-auto">
          <div className="text-xl font-medium"> {formHeading} </div>
          <div className="flex gap-2">
            <div>
              <ATMLoadingButton
                onClick={() => onClose()}
                type="button"
                className="text-black bg-white"
              >
                Cancel
              </ATMLoadingButton>
            </div>
            <div>
              <ATMLoadingButton isLoading={isSubmitting} type="submit">
                Save
              </ATMLoadingButton>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col gap-4 w-[70%] m-auto py-4">
        <div className="grid grid-cols-2 gap-4 xl:grid-cols-3 ">
          {/* Amount (Incl. TDS) */}
          <div>
            <ATMAmountField
              required
              name="amount"
              value={values.amount}
              onChange={(e:any) =>
                handleValidWithDotNumber(e) &&
                setFieldValue("amount", e.target.value)
              }
              onCurrencyChange={(newValue:any) => {
                setFieldValue("currency", newValue);
              }}
              currencyValue={values.currency}
              label=" Amount (Incl. TDS) "
              placeholder="Enter  Amount (Incl. TDS) "
              onBlur={handleBlur}
            />
          </div>
          {/* GST Applicable */}
          <div className="flex items-end justify-between">
            <ATMSwitchButton
              name="gstApplicable"
              value={values.gstApplicable}
              onChange={(newValue) => {
                if (!newValue) {
                  setFieldValue("gstPercentage", "");
                }
                return setFieldValue("gstApplicable", newValue);
              }}
              label="GST Applicable"
            />
            {values?.amount && values?.tdsAmount && (
              <div>
                {values?.currency?.currencySymbol}{" "}
                {((parseInt(values?.amount) - parseInt(values?.tdsAmount)) *
                  ((parseInt(values?.gstPercentage) || 0) + 100)) /
                  100}
              </div>
            )}
          </div>
          {/* GST Percentage */}
          {values?.gstApplicable ? (
            <div>
              <ATMTextField
                required
                name="gstPercentage"
                value={values.gstPercentage}
                onChange={(e) =>
                  handleValidPercent(e) &&
                  setFieldValue("gstPercentage", e.target.value)
                }
                label="GST Percentage"
                placeholder="Enter GST Percentage"
                onBlur={handleBlur}
              />
            </div>
          ) : null}

          {/* TDS Amount */}
          <div>
            <ATMTextField
              name="tdsAmount"
              value={values.tdsAmount}
              onChange={(e) =>
                handleValidWithDotNumber(e, { acceptPercent: false }) &&
                setFieldValue("tdsAmount", e.target.value)
              }
              label="TDS Amount"
              placeholder="Enter TDS Amount"
              onBlur={handleBlur}
            />
          </div>
          {/* Category */}
          <div>
            <ATMSelect
              name="category"
              label="Category"
              required
              placeholder="Select Category"
              value={values.category}
              options={getCategory?.data?.map((ele: any) => {
                return {
                  label: ele?.categoryName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("category", newValue);
              }}
              isLoading={isCategoryLoading}
            />
          </div>
          {/* Repeat */}
          <div>
            <ATMSelect
              name="repeat"
              label="Repeat"
              required
              placeholder="Select repeat"
              value={values.repeat}
              options={repeatData}
              onChange={(newValue) => {
                setFieldValue("repeat", newValue);
                setFieldValue("recurranceDate", "");
                setFieldValue("days", []);
                setFieldValue("recurranceDateAndMonthForYear", null);
              }}
            />
          </div>
          {/* Days */}
          {values?.repeat?.value === "WEEKLY" ? (
            <div className="">
              <div>Days</div>
              <div>
                <ul className="flex justify-between uppercase">
                  {daysOptions.map((day) => (
                    <li
                      key={day.value}
                      className={` w-[30px] h-[30px] border rounded-full cursor-pointer flex justify-center items-center ${
                        values.days.includes(day.value)
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200"
                      }`}
                      onClick={() => {
                        const newSelectedDays = values.days.includes(day.value)
                          ? values.days.filter((value) => value !== day.value)
                          : [...values.days, day.value];
                        setFieldValue("days", newSelectedDays);
                      }}
                    >
                      {day.label}
                    </li>
                  ))}
                </ul>
              </div>
              <ErrorMessage name="days">
                {(errMsg) => (
                  <p className="font-poppins absolute text-[14px] text-start mt-0 text-red-500">
                    {errMsg}
                  </p>
                )}
              </ErrorMessage>
            </div>
          ) : null}
          {/* Income Date */}
          {values?.repeat?.value === "MONTHLY" ? (
            <div>
              <ATMNumberPicker
                name="recurranceDate"
                label="Select Date"
                value={values?.recurranceDate}
                required
                onSelect={(number: number) =>
                  setFieldValue("recurranceDate", number)
                }
                placeholder="select date"
              />
            </div>
          ) : values?.repeat?.value === "YEARLY" ? (
            <div>
              <ATMDatePicker
                required
                label="Income Date"
                name="recurranceDateAndMonthForYear"
                format={"DD/MM"}
                value={values.recurranceDateAndMonthForYear}
                onChange={(newValue) =>
                  setFieldValue("recurranceDateAndMonthForYear", newValue)
                }
              />
            </div>
          ) : null}
          {/* until */}
          <div>
            <ATMSelect
              name="until"
              label="Until"
              required
              placeholder="Select Until"
              value={values.until}
              options={untilData}
              onChange={(newValue) => {
                setFieldValue("untilDate", null);
                setFieldValue("event", "");
                setFieldValue("until", newValue);
              }}
            />
          </div>
          {/* Date */}
          {values?.until?.value === "DATE" ? (
            <div>
              <ATMDatePicker
                required
                label="Until Date"
                name="untilDate"
                format="DD/MM/YYYY"
                minDate={moment().format("yyyy-MM-DD")}
                value={values.untilDate}
                onChange={(newValue) => setFieldValue("untilDate", newValue)}
              />
            </div>
          ) : null}
          {values?.until?.value === "NO OF EVENTS" ? (
            <div>
              <ATMTextField
                required
                name="event"
                value={values.event}
                onChange={(e) =>
                  handleValidWithDotNumber(e) &&
                  setFieldValue("event", e.target.value)
                }
                label="Number of Event"
                placeholder="Enter event"
                onBlur={handleBlur}
              />
            </div>
          ) : null}
        </div>
        {/* Description */}
        <div className="">
          <ATMTextArea
            required
            name="description"
            value={values.description}
            onChange={(e) => setFieldValue("description", e)}
            label="Description"
            placeholder="Enter Description"
            className="rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default RecurringIncomeFormLayout;
