import apiSlice from "./ApiSlice";

export const expensessApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllExpenses: builder.query({
      providesTags: ["expence"],
      query: () => {
        return {
          url: "/expence",
          method: "GET",
        };
      },
    }),
    // Get expenses with pagination
    getExpenses: builder.query({
      providesTags: ["expence"],
      query: (body) => {
        return {
          url: "/expence",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addExpenses: builder.mutation({
      invalidatesTags: ["expence"],
      query: (body) => {
        return {
          url: "/expence/add",
          method: "POST",
          body,
        };
      },
    }),

    deleteExpenses: builder.mutation({
      invalidatesTags: ["expence"],
      query: (id: any) => ({
        url: `/expence/${id}`,
        method: "DELETE",
      }),
    }),


    //Edit
    updateExpenses: builder.mutation({
      invalidatesTags: ["expence"],
      query: ({ id, body }) => {
        return {
          url: `/expence/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get expenses by ID
    getExpensesById: builder.query({
      providesTags: ["expence"],
      query: (id) => {
        return {
          url: `/expence/${id}`,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useGetAllExpensesQuery,
  useGetExpensesQuery,
  useAddExpensesMutation,
  useUpdateExpensesMutation,
  useDeleteExpensesMutation,
  useGetExpensesByIdQuery
} = expensessApi;
