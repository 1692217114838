import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { CampaignGroupFormValues } from "src/models/CampaignGroup.model";
import { useAddCampaignGroupMutation } from "src/services/CampaignGroupsService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import CampaignGroupForm from "../Layouts/CampaignGroupForm";

type Props = {
  onClose: () => void;
};

const AddCampaignGroupFormWrapper = ({ onClose }: Props) => {
  const [addCampaignGroup] = useAddCampaignGroupMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  // Form Initial Values
  const initialValues: CampaignGroupFormValues = {
    campaignGroupName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    campaignGroupName: string().required("Please enter campaign group name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: CampaignGroupFormValues,
    { setSubmitting, resetForm }: FormikHelpers<CampaignGroupFormValues>
  ) => {
    addCampaignGroup(values).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <CampaignGroupForm
            formType="ADD"
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddCampaignGroupFormWrapper;
