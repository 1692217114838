import { useEffect, useState } from "react";
import { BiSolidTime } from "react-icons/bi";
import { RiTimerFlashFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { getTimeInHours } from "src/utils/constants/ticketUtils";

type Props = {
  ticketList: any;
};
const QuotationView = ({ ticketList }: Props) => {
  const { singleItemInfo: changeRequestDetails } = useSelector(
    (state: RootState) => state.changeRequest
  );
  const [layerWiseTime, setLayerWiseTime] = useState<any>([]);

  const quotationInfo = [
    {
      title: "Actual Time",
      icon: BiSolidTime,
      timeQuotation: 1,
    },
    {
      title: "Quotation Time",
      icon: RiTimerFlashFill,
      timeQuotation: changeRequestDetails?.projectTimeQuotation || 1,
    },
  ];

  useEffect(() => {
    if (ticketList?.length) {
      const layerEstData = ticketList?.reduce((acc: any, ticket: any) => {
        if (ticket?.layerWiseEst) {
          return [...acc, ...ticket?.layerWiseEst];
        }
        return acc;
      }, []);

      const groupedData = layerEstData?.reduce(
        (acc: any, currentValue: any) => {
          const { layerName, estTime } = currentValue;
          if (!acc[layerName]) {
            acc[layerName] = { layerName, totalEstTime: estTime };
          } else {
            acc[layerName].totalEstTime += estTime;
          }
          return acc;
        },
        {}
      );
      const layerWiseEst = Object.values(groupedData);
      setLayerWiseTime(layerWiseEst || []);
    }
  }, [ticketList]);

  //get total time of Prepared change request
  const getPreparedChangeRequestTime = () => {
    return (
      (changeRequestDetails?.devAndQaTime || 0) +
      (changeRequestDetails?.deploymentTime?.timeSpent || 0) +
      (changeRequestDetails?.demoTime?.timeSpent || 0)
    );
  };
  return (
    <div className="grid grid-cols-4 gap-10 p-4">
      <div className="flex col-span-4 gap-3 font-semibold">
        <div>Project Time Quotation :</div>
        <span className="">{changeRequestDetails?.projectTimeQuotation}</span>
      </div>
      {quotationInfo?.map((quotation, index) => {
        let totalTime = layerWiseTime?.reduce((acc: any, layerInfo: any) => {
          acc +=
            Number(layerInfo?.totalEstTime || 0) * quotation?.timeQuotation;
          return acc;
        }, 0);

        return (
          <div
            className="flex flex-col items-center justify-center gap-3 p-4 border rounded-md"
            key={index}
          >
            <div className="p-1 border rounded-full">
              <quotation.icon />
            </div>
            <div className="font-medium text-primary-main">
              {quotation?.title}
            </div>
            <div className="">
              <span className="font-semibold">
                {getTimeInHours(Number(totalTime) || 0)}
              </span>
              {totalTime ? <span>({totalTime + " min"})</span> : null}
            </div>
            {layerWiseTime?.map((layerInfo: any) => {
              return (
                <div className="flex justify-between w-full text-sm font-medium">
                  <div className="uppercase">{layerInfo?.layerName}</div>
                  <div>
                    {getTimeInHours(
                      quotation?.timeQuotation * layerInfo?.totalEstTime || 0
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
      <div className="flex flex-col items-center justify-center gap-3 p-4 border rounded-md">
        <div className="p-1 border rounded-full">
          <BiSolidTime />
        </div>
        <div className="font-medium text-primary-main">
          Prepared Change Request
        </div>
        <div className="">
          <span className="font-semibold">
            {getTimeInHours(getPreparedChangeRequestTime() || 0)}
          </span>
          {getPreparedChangeRequestTime() ? (
            <span>({getPreparedChangeRequestTime() + " min"})</span>
          ) : null}
        </div>

        <div className="flex justify-between w-full text-sm font-medium">
          <div>Development and QA Time</div>
          <div>{getTimeInHours(changeRequestDetails?.devAndQaTime || 0)}</div>
        </div>
        <div className="flex justify-between w-full text-sm font-medium">
          <div>Deployment Time</div>
          <div>
            {getTimeInHours(
              changeRequestDetails?.deploymentTime?.timeSpent || 0
            )}
          </div>
        </div>
        <div className="flex justify-between w-full text-sm font-medium">
          <div>Demo Time</div>
          <div>
            {getTimeInHours(changeRequestDetails?.demoTime?.timeSpent || 0)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationView;
