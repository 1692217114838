// |-- Built-in Dependencies --|
import React from "react";
import { FormikProps } from "formik";

// |-- Internal Dependencies --|
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { FormInitialValues } from "../Add/AddCityFormWrapper";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

// |-- Types --|
type Props = {
  onClose: () => void;
  formikProps: FormikProps<FormInitialValues>;
};

const tierOptionsDropdown = [
  {
    label: "Tier-1",
    value: "TIER_1",
  },
  {
    label: "Tier-2",
    value: "TIER_2",
  },
  {
    label: "Tier-3",
    value: "TIER_3",
  },
];

const CityForm = ({ onClose, formikProps }: Props) => {
  const { values, setFieldValue, handleBlur, isSubmitting, handleSubmit } =
    formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> Add City </div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <div>
            <ATMTextField 
             required
              name="cityName"
              value={values.cityName}
              onChange={(e) => {
                setFieldValue("cityName", e.target.value);
              }}
              placeholder="Enter city name"
              label="City Name"
              onBlur={handleBlur}
            />
          </div>
          <div className="mt-4">
            <ATMSelect
              name="tier"
              value={values.tier}
              label="Tier"
              placeholder="Select tier type"
              options={tierOptionsDropdown}
              onChange={(newValue) => setFieldValue("tier", newValue)}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div>
        <ATMLoadingButton
          isLoading={isSubmitting}
          type="submit"
          onClick={() => handleSubmit()}
        >
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default CityForm;
