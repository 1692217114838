import { FormikProps } from "formik";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<any>;
  onClose: () => void;
};

const approvalStatusOptions = [
  {
    label: "Approve",
    value: "APPROVED",
  },
  {
    label: "Decline",
    value: "DECLINED",
  },
];

const LeaveRequestApprovalForm = ({ onClose, formikProps }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={"Reject Request"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div className="hidden">
          <ATMSelect
            label="Approval Status"
            name="approvalStatus"
            placeholder="Select Approval Status"
            value={values?.approvalStatus}
            required
            onChange={(value) => {
              value?.value === "APPROVED" &&
                setFieldValue("declinedReason", "");
              setFieldValue("approvalStatus", value);
            }}
            onBlur={handleBlur}
            options={approvalStatusOptions}
          />
        </div>

        {/* Reason */}
        <div className="">
          <ATMTextArea
            name="declinedReason"
            value={values.declinedReason}
            required
            onChange={(value) => setFieldValue("declinedReason", value)}
            label="Reason"
            placeholder="Enter Reason"
            minRows={3}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default LeaveRequestApprovalForm;
