import React, { ReactNode, useEffect } from "react";
import { IconType } from "react-icons";
import ATMHorizontalScroll from "../ATMHorizontalScroll/ATMHorizontalScroll";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

export type Tabs = {
  icon: IconType;
  label: string;
  count?: string;
  path?: string;
  onClick: (tab: any) => void;
  children?: React.ReactNode;
};

type Props = {
  tabs: Tabs[];
  selected: (tab: Tabs) => boolean;
  children?: ReactNode;
  childrenLabel: any;
};

const ATMTab = ({ tabs, selected, childrenLabel = "", children }: Props) => {
  useEffect(() => {
    document.getElementById("scroll-to-view")?.scrollIntoView();
  }, []);

  return (
    <ATMHorizontalScroll>
      {(params) => (
        <div
          {...params}
          className="flex items-center w-full px-4 overflow-auto border-b text-neutral border-divider scrollbar-hide scroll-smooth "
        >
          {children}
          {tabs
            ?.filter((nav: any) => {
              return isAuthorized(
                nav?.name as keyof typeof UserModuleNameTypes
              );
            })
            ?.map((tab, tabIndex) => {
              return (
                <button
                  id={selected(tab) ? "scroll-to-view" : ""}
                  key={tabIndex}
                  type="button"
                  className={`relative flex gap-2 py-2 px-4 border-y-2 border-white text-xs min-w-fit font-medium hover:text-primary-dark  items-center h-full transition-all duration-500  ${
                    selected(tab)
                      ? " text-primary-dark  border-b-primary-dark "
                      : " "
                  }`}
                  onClick={() => {
                    !selected(tab) && tab.onClick(tab);
                  }}
                >
                  {/* <div className="text-sm">{tab?.icon({})}</div> */}
                  <div className="text-xs">{tab.label}</div>
                  {tab?.count ? (
                    <div
                      className={`${
                        selected(tab)
                          ? "bg-primary-dark text-white font-medium"
                          : "bg-slate-300 font-semibold"
                      } rounded-full  px-[6px] py-[2px] text-center`}
                    >
                      {tab?.count}{" "}
                    </div>
                  ) : null}
                  {tab?.children}
                </button>
              );
            })}
        </div>
      )}
    </ATMHorizontalScroll>
  );
};

export default ATMTab;
