import { Slider } from "@mui/material";
import moment from "moment";
import { Suspense, useState } from "react";
import { IoCheckmarkDoneCircle, IoCheckmarkSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import MOLJobApplicationStatusChip from "src/components/UI/molecules/MOLJobApplicationStatusChip/MOLJobApplicationStatusChip";
import {
  InterviewStatus,
  JobApplicationStatus,
} from "src/models/JobApplication.model";
import { handleValidNumber } from "src/utils/validations/numberInput";
import ScheduledRoundFormWrapper from "./ScheduledRound/ScheduledRoundFormWrapper";
import UpdateJobApplicationStatusWrapper from "src/screens/JobApplication/UpdateJobApplicationStatus/UpdateJobApplicationStatusWrapper";

type Props = {
  answerList: any;
  stageData: any;
  handleInputChange: (questionInfo: any, value: any) => void;
  handleSubmit: (interviewId: string, status: InterviewStatus) => void;
  applicationId: string;
  stageId: string;
  isSubmitLoading: boolean;
  applicationStatus: JobApplicationStatus;
  handleProceedNextStage: (interviewId: string) => void;
  isProceedNextStageLoading: boolean;
  handleRejectApplication: () => void;
};
const JobApplicationStage = ({
  answerList,
  stageData,
  handleInputChange,
  handleSubmit,
  applicationId,
  stageId,
  isSubmitLoading,
  applicationStatus,
  handleProceedNextStage,
  isProceedNextStageLoading,
  handleRejectApplication,
}: Props) => {

  const [isShowShortListDialog, setIsShowShortListDialog] = useState(false);
  const [status, setStatus] = useState('')

  const getQuestion = (questionInfo: any) => {
    switch (questionInfo?.questionType) {
      case "RANGE":
        return (
          <div className="grid grid-cols-2 items-center gap-4">
            <label className="text-sm font-medium">
              {questionInfo?.questionTitle}{" "}
            </label>
            <Slider
              value={
                answerList?.find(
                  (answerInfo: any) => answerInfo?._id === questionInfo?._id
                )?.value || [0, 0]
              }
              onChange={(e: any, newValue: number | number[]) => {
                handleInputChange(questionInfo, newValue);
              }}
              valueLabelDisplay="auto"
              min={questionInfo?.minimumValForRange}
              max={questionInfo?.maximumValForRange}
            />
          </div>
        );
      case "NUMBER":
        return (
          <div className="grid grid-cols-2 items-center gap-4">
            <label className="text-sm font-medium">
              {" "}
              {questionInfo?.questionTitle}
            </label>
            <input
              type={"text"}
              value={
                answerList?.find(
                  (answerInfo: any) => answerInfo?._id === questionInfo?._id
                )?.value || ""
              }
              onChange={(e) => {
                handleValidNumber(e) &&
                  handleInputChange(questionInfo, e.target.value);
              }}
              className={`outline-blue-8 rounded px-2 h-[45px] border border-gray-8`}
            />
          </div>
        );
      case "TEXT":
        return (
          <div className="grid grid-cols-2 items-center gap-4">
            <label className="text-sm font-medium">
              {" "}
              {questionInfo?.questionTitle}
            </label>
            <input
              type={"text"}
              value={
                answerList?.find(
                  (answerInfo: any) => answerInfo?._id === questionInfo?._id
                )?.value || ""
              }
              onChange={(e) => {
                handleInputChange(questionInfo, e.target.value);
              }}
              className={`outline-blue-8 rounded px-2 h-[45px] border border-gray-8`}
            />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="p-5">
      {!stageData?.interview && (
        <Suspense>
          <ScheduledRoundFormWrapper
            applicationId={applicationId}
            stageId={stageId}
          />
        </Suspense>
      )}
      {stageData?.interview ? (
        <>
          <div className=" flex flex-col gap-4">
            {stageData?.interview?.roundStatus === "COMPLETED" ||
              stageData?.interview?.roundStatus === "ONGOING" ? (
              <div className="flex flex-col gap-3 w-full font-medium ">
                <div className="flex justify-between">
                  <div className="flex flex-col gap-1 text-slate-500 text-xs ">
                    <div className="inline-flex">
                      <MOLJobApplicationStatusChip status={applicationStatus} />
                    </div>
                    <span>
                      Interviewer : {stageData?.interview?.feedbackByUserName}
                    </span>
                    <span>
                      Scheduled Date :{" "}
                      {moment(stageData?.interview?.interviewDateTime).format(
                        "DD-MM-YYYY hh:mm a"
                      )}
                    </span>
                    <span>
                      Actual Date :{" "}
                      {moment(stageData?.interview?.feedbackOnDate).format(
                        "DD-MM-YYYY hh:mm a"
                      )}
                    </span>
                  </div>
                  {stageData?.interview?.shortlistingStatus === "PENDING" &&
                    applicationStatus !== "REJECTED" && (
                      <div className="mt-4 flex flex-col gap-2">
                        <p className="text-slate-500 text-xs">Change Status</p>
                        <div className="flex ">
                          <ATMLoadingButton
                            variant="tonal"
                            color="success"
                            isLoading={isProceedNextStageLoading}
                            className="rounded-none p-2"
                            onClick={() =>
                              handleProceedNextStage(stageData?.interview?._id)
                            }
                          >
                            <IoCheckmarkSharp
                              size={15}
                              className=" text-green-500 text-bold"
                            />
                          </ATMLoadingButton>
                          <ATMLoadingButton
                            variant="tonal"
                            color="error"
                            className="rounded-none p-2"
                            onClick={() => handleRejectApplication()}
                          >
                            <RxCross2
                              size={15}
                              className=" text-red-500 text-bold"
                            />
                          </ATMLoadingButton>
                        </div>
                      </div>
                    )}
                </div>
                <p className="font-semibold text-slate-700 text-lg">Preview</p>
                {stageData?.interview?.questions?.map((questionInfo: any) => {
                  return (
                    <div className="grid grid-cols-3 gap-4 text-slate-700 text-sm">
                      <div className="flex gap-2 col-span-2 items-center">
                        <IoCheckmarkDoneCircle color="green" />
                        {questionInfo?.questionTitle}
                      </div>
                      <div className="">
                        {questionInfo?.value
                          ? questionInfo?.questionType === "RANGE"
                            ? JSON.parse(questionInfo?.value)?.join("-")
                            : questionInfo?.value
                          : "-"}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <p className="font-semibold text-lg">Questions</p>
                {stageData?.questions?.map((questionInfo: any) => {
                  return getQuestion(questionInfo);
                })}
                <div className="flex justify-end gap-3 pt-[30px] w-full">
                  <ATMLoadingButton
                    type="submit"
                    color="error"
                    isLoading={isSubmitLoading}
                    onClick={() =>
                      handleSubmit(stageData?.interview?._id, "REJECTED")
                    }
                  >
                    Reject
                  </ATMLoadingButton>
                  <ATMLoadingButton
                    type="submit"
                    color="neutral"
                    isLoading={isSubmitLoading}
                    onClick={() =>
                      handleSubmit(stageData?.interview?._id, "PENDING")
                    }
                  >
                    Maybe
                  </ATMLoadingButton>
                  <ATMLoadingButton
                    type="submit"
                    isLoading={isSubmitLoading}
                    onClick={() =>
                      handleSubmit(stageData?.interview?._id, "SHORTLISTED")
                    }
                  >
                    Save and next
                  </ATMLoadingButton>
                </div>
              </>
            )}
          </div>
        </>
      ) : null}
      {(stageData?.order)?.toString() === '4' && stageData?.interview?.roundStatus === 'COMPLETED' ?
        <div className="flex gap-2 mt-4">
          <ATMLoadingButton
            type="submit"
            isLoading={isSubmitLoading}
            onClick={() => {
              setIsShowShortListDialog(true)
              setStatus("onboard")
            }
            }
          >
            Job onboarding
          </ATMLoadingButton>
          <ATMLoadingButton
            type="submit"
            color="error"
            isLoading={isSubmitLoading}
            onClick={() => {
              setIsShowShortListDialog(true)
              setStatus("reject")
            }
            }
          >
            Reject
          </ATMLoadingButton>
        </div> : null
      }

      {isShowShortListDialog && (
        <UpdateJobApplicationStatusWrapper
          onClose={() => setIsShowShortListDialog(false)}
          applicationId={stageData?.interview?.jobApplicationId || ""}
          applicationStatus={status === 'onboard' ? 'ONBOARDED' : 'REJECTED'}
        />
      )}
    </div>
  );
};

export default JobApplicationStage;
