// |-- Built-in Dependencies --|
import React, { useState, useEffect } from "react";

// |-- External Dependencies --|
import { useDispatch, useSelector } from "react-redux";

// |-- Internal Dependencies --|
import CityListing from "./CityListing";
import { setItems } from "src/redux/slices/CitySlice";
import AddCityFormWrapper from "../Add/AddCityFormWrapper";

// |-- Redux --|
import { useGetCityQuery } from "src/services/CityServices";
import { AppDispatch, RootState } from "src/redux/store";

const CityListingWrapper = () => {
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { items }: any = useSelector((state: RootState) => state.city);
  const { searchValue, filterValue }: any = useSelector(
    (state: RootState) => state.city
  );

  const city = items?.map((ele: any) => {
    return { label: ele.cityName, value: ele._id };
  });

  const { data, isLoading, isFetching } = useGetCityQuery(
    {
      limit: 1000,
      searchValue: searchValue,
      params: ["cityName"],
      page: 0,
      filterBy: [
        {
          fieldName: "stateId",
          value: filterValue ? filterValue : [],
        },
      ],
      dateFilter: {},
      orderBy: "createdAt",
    },
    {
      skip: !filterValue || filterValue?.length === 0,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <>
      <CityListing city={city} onAddNew={() => setIsOpenAddForm(true)} />
      {isOpenAddForm ? (
        <AddCityFormWrapper onClose={() => setIsOpenAddForm(false)} />
      ) : null}
    </>
  );
};

export default CityListingWrapper;
