import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useGetAllTicketWithDifferentIdsQuery } from 'src/services/TicketServices';
import ChangeRequestView from './ChangeRequestView';
import { columnTypes } from 'src/components/UI/atoms/ATMTable/ATMTable';
import { TicketListResponse } from 'src/models/Ticket.model';
import moment from 'moment';
import MOLPriorityChip from 'src/components/UI/molecules/MOLPriorityChip/MOLPriorityChip';
import MOLTicketTypeChip from 'src/components/UI/molecules/MOLTicketTypeChip/MOLTicketTypeChip';
import MOLTicketStatusChip from 'src/components/UI/molecules/MOLTicketStatusChip/MOLTicketStatusChip';

const ChangeRequestViewWrapper = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const featureId = queryParams.get('featureId');
    const [items, setItems] = useState();
    const { data, isLoading, isFetching } = useGetAllTicketWithDifferentIdsQuery({
        featureId: featureId
    });

  // Use a separate useEffect to update the items state when data is available
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [data, isLoading, isFetching]);

  enum TicketPriorityEnum {
    low = "LOW",
    medium = "MEDIUM",
    high = "HIGH",
    urgent = "URGENT",
  }

  const columns: columnTypes[] = [
    {
      field: "ticketNumber",
      headerName: "Ticket Id",
      flex: "flex-[2_2_0%]",
      renderCell: (row: TicketListResponse) => (
        <div className="text-blue-500"> {row?.ticketNumber} </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date-Time",
      extraClasses: "min-w-[120px] max-w-[120px]",
      renderCell: (row: TicketListResponse) => (
        <div className="py-1">
          <div className="text-[15px] text-slate-500 font-medium">
            {moment(row?.createdAt).format("hh:mm A")}
          </div>
          <div className="text-[15px] text-slate-700 font-medium">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Ticket Title",
      flex: "flex-[2_2_0%]",
      extraClasses: "min-w-[200px]",
      renderCell: (row: TicketListResponse) => (
        <div className="w-full overflow-hidden truncate">{row?.title}</div>
      ),
    },
    {
      field: "projectLabel",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[100px]",
      renderCell: (row: TicketListResponse) => (
        <div className="capitalize">{row?.projectLabel} </div>
      ),
    },
    {
      field: "priority",
      headerName: "Priority",
      extraClasses: "min-w-[100px] max-w-[110px]",
      renderCell: (row: TicketListResponse) => (
        <div>
          <MOLPriorityChip priority={row?.priority as TicketPriorityEnum} />
        </div>
      ),
    },
    {
      field: "createdByLabel",
      headerName: "Created By",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[100px]",
      align: "center",
      renderCell: (row: TicketListResponse) => (
        <div className="capitalize"> {row?.createdByLabel} </div>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      extraClasses: "min-w-[180px] max-w-[180px]",
      renderCell: (row: TicketListResponse) => (
        <div className="flex justify-center">
          <MOLTicketTypeChip ticketType={row?.type as any} />
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      extraClasses: "min-w-[180px] max-w-[180px]",
      renderCell: (row: TicketListResponse) => (
        <div>
          <MOLTicketStatusChip status={row?.status as any} />
        </div>
      ),
    },
    // {
    //   field: "estTime",
    //   headerName: "ETA",
    //   extraClasses: "min-w-[130px] max-w-[130px]",
    //   renderCell: (row: TicketListResponse) => (
    // <div> {getFormattedEstimationTime(122)} </div>
    //   ),
    // },
    {
      field: "unReadMessages",
      headerName: "Unseen Messages",
      extraClasses: "min-w-[180px] max-w-[180px]",
      align: "center",
      renderCell: (row: TicketListResponse) =>
        row?.unReadMessages ? (
          <div className="bg-[#041618] rounded-full font-bold h-[23px] w-[23px] text-[10px] flex justify-center items-center -right-[6px] -top-[1px] text-white">
            {row?.unReadMessages}
          </div>
        ) : (
          "-"
        ),
    },
  ];
  return (
    <>
      <ChangeRequestView
        items={items}
        isLoading={isLoading}
        columns={columns}
      />
    </>
  );
};

export default ChangeRequestViewWrapper;
