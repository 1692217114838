import React from "react";
import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { RequirementGatheringUsersValues } from "../Add/AddRequirementGatheringUsers";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<RequirementGatheringUsersValues>;
  onClose: () => void;
  formType: "ADD" | "UPDATE";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const RequirementGatheringUsers = ({
  formikProps,
  onClose,
  formType,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add User" : "Update User"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className=" flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div>
            <ATMTextField
              name="userName"
              value={values?.userName}
              onChange={(e) => {
                setFieldValue("userName", e.target.value);
              }}
              label="UserName"
              required
              placeholder="Enter User Name"
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default RequirementGatheringUsers;
