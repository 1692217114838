/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { TicketFormValues } from "src/models/Ticket.model";
import { array, object, string } from "yup";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { useUpdateSummeryMutation } from "src/services/TicketServices";
import { useParams } from "react-router-dom";
import { showToast } from "src/utils/showToaster";

type FormName =
  | "Problem Statement"
  | "Proposed Solution"
  | "Flow"
  | "Technical Approach"
  | "Affected Areas";

type Props = {
  onClose: () => void;
  children: FC;
  formName: string;
};

const getFormattedValues = (formName: FormName, values: TicketFormValues) => {
  switch (formName) {
    case "Problem Statement":
      return { problemStatement: values.problemStatement };

    case "Proposed Solution":
      return { proposedSolution: values.proposedSolution };

    case "Flow":
      return { flow: values.flow };

    case "Technical Approach":
      return { technicalApproach: values.technicalApproach };

    case "Affected Areas":
      return { affectedAreas: values.affectedAreas };
  }
};

const EditTicketFormWrapper = ({ onClose, children, formName }: Props) => {
  // ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  const { singleItem } = useSelector((state: RootState) => state.ticket);

  const [updateTicketSummery] = useUpdateSummeryMutation();

  // Form Initial Values
  const initialValues: TicketFormValues = {
    problemStatement: singleItem[0]?.problemStatement,
    proposedSolution: singleItem[0]?.problemSolution,
    flow: singleItem[0]?.flow,
    technicalApproach: singleItem[0]?.technicalApproach,
    affectedAreas: singleItem[0]?.affectedAreas?.map((ele) => {
      return {
        projectId: {
          projectName: ele?.projectLabel,
          _id: ele?.projectId,
        },
        moduleId: {
          label: ele?.moduleLabel,
          value: ele?.moduleId,
        },
        featureId: {
          label: ele?.featureLabel,
          value: ele?.featureId,
        },
      };
    }),
  };

  // Validation Schema
  // const validationSchema = object().shape({
  //   affectedAreas: array()
  //     .of(
  //       object({
  //         array().of(
  //           object({
  //             featureId: string().required("Please select feature"),
  //             projectId: string().required("Please select project"),
  //           })
  //         )
  //       })
  //     )
  //     .required("Please enter affected areas"),
  // });

  // Handle Submit
  const handleSubmit = (
    values: TicketFormValues,
    { setSubmitting, resetForm }: FormikHelpers<TicketFormValues>
  ) => {
    setTimeout(() => {
      const formattedValues = getFormattedValues(formName as FormName, values);

      const formatedAffectedAreasValues = values?.affectedAreas?.map(
        (ele: any) => {
          return {
            projectId: ele?.projectId?._id,
            moduleId: ele?.moduleId?.value,
            featureId: ele?.featureId?.value,
          };
        }
      );

      updateTicketSummery({
        ticketId: TICKET_ID || "",
        body: {
          problemStatement: values.problemStatement,
          problemSolution: values.proposedSolution,
          flow: values.flow,
          technicalApproach: values.technicalApproach,
          affectedAreas: formatedAffectedAreasValues || [],
        },
      })
        .then((res: any) => {
          showToast("success", "updated successfully");
          setSubmitting(false);
          resetForm();
          onClose();
        })
        .catch((err: any) => {
          console.error(err);
        });
    }, 1000);
  };

  return (
    <Dialog open maxWidth="xl" fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => <Form>{children({ formikProps, onClose })}</Form>}
      </Formik>
    </Dialog>
  );
};

export default EditTicketFormWrapper;
