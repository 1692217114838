import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { IncidentTypeFormValues } from "../Add/AddIncidentTypeFormWrapper";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetAllIncidentCategoryQuery } from "src/services/IncidentCategoryService";

type Props = {
  formikProps: FormikProps<IncidentTypeFormValues>;
  onClose: () => void;
  formType: "Add" | "Update";
};

const IncidentTypeForm = ({ formikProps, onClose, formType }: Props) => {
  const { data, isLoading, isFetching } = useGetAllIncidentCategoryQuery("");

  const categoryOptions = data?.data?.map((el: any) => {
    return {
      label: el?.categoryName,
      value: el?._id,
    };
  });

  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">{formType} Incident Type</div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Category */}
        <div>
          <ATMSelect
            name="category"
            required
            label="Category"
            value={values?.categoryName}
            isLoading={isLoading || isFetching}
            onBlur={handleBlur}
            placeholder="Select feature"
            options={categoryOptions}
            onChange={(newValue) => {
              setFieldValue("categoryName", newValue);
            }}
          />
        </div>
        {/* Event Type */}
        <div>
          <ATMTextField
            name="eventType"
            required
            value={values?.eventType}
            onChange={(e) => {
              setFieldValue("eventType", e.target.value);
            }}
            label="Event Type"
            placeholder="Enter Event Type"
            onBlur={handleBlur}
          />
        </div>
        {/* Weightage */}
        <div>
          <ATMTextField
            name="weightage"
            required
            value={values?.weightage}
            onChange={(e) => {
              setFieldValue("weightage", e.target.value);
            }}
            label="Weightage"
            placeholder="Enter Weightage"
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default IncidentTypeForm;
