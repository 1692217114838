import React, { useState, useEffect } from "react";
import { FiMenu } from "react-icons/fi";
// import { IoNotifications } from "react-icons/io5";
import NotificationCard from "./NotificationCard/NotificationCard";
import UserProfileCard from "./UserProfileCard/UserProfileCard";
import { Switch, Tooltip } from "@mui/material";
import TaskNotificationCardWrapper from "./TaskNotificationTaskCard/TaskNotificationCardWrapper";
import { useGetTaskNotificationQuery } from "src/services/AuthServices";
import { setIsTaskInProgress } from "src/redux/slices/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/redux/store";
import {
  useAddPunchMutation,
  useGetAttendanceByIdQuery,
} from "src/services/AttendanceService";
import { showToast } from "src/utils/showToaster";
import AttendancePunchInWrapper from "./AttendancePunchInWrapper";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import ATMSelect from "../atoms/formFields/ATMSelect/ATMSelect";
import {
  useGetUserBreakStatusQuery,
  useGetUserInProgressTaskQuery,
  useUpdateUserStatusMutation,
} from "src/services/UserService";
import { TbSubtask } from "react-icons/tb";
import ShowInprogressTaskFormWrapper from "./ShowInprogressTask/ShowInprogressTaskFormWrapper";

interface Props {
  toggleCollapse: () => void;
  isCollapsed: boolean;
  entityName?: React.ReactNode;
}

const statusOptions = [
  {
    label: "ON BREAK",
    value: "ON BREAK",
  },
  {
    label: "ON BENCH",
    value: "ON BENCH",
  },
  // {
  //   label: "IN MEETING",
  //   value: "IN MEETING",
  // },
  {
    label: "WORKING",
    value: "WORKING",
  },
];

const Header = ({ toggleCollapse, isCollapsed, entityName }: Props) => {
  const [isShowProfileCard, setIsShowProfileCard] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [isShowAttendance, setIsShowAttendance] = useState(false);
  const [isShowInprogressTask, setIsShowInprogressTask] = useState(false);
  const [updatePunchOut] = useAddPunchMutation();
  const [isOpenAttendancePunchIn, setIsOpenAttendancePunchIn] =
    useState<boolean>(false);
  const { isTaskIsInPrgoress } = useSelector((state: RootState) => state.auth);
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const [taskData, setTaskData] = useState();
  const dispatch = useDispatch();

  const { data: attendanceData } = useGetAttendanceByIdQuery(
    userData?.userId || null,
    { refetchOnFocus: true }
  );
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (attendanceData?.data && !attendanceData?.data?.punchOutTime) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [attendanceData]);

  const {
    data: taskDataNotification,
    isLoading: isLoadingTaskData,
    isFetching: isFetchingTaskData,
  } = useGetTaskNotificationQuery("", { refetchOnFocus: true });

  const toggleSwitch = () => {
    if (isTaskIsInPrgoress) {
      setIsShowInprogressTask(true);
    } else if (isActive) {
      handleSetYourselfAway();
    } else handleSetYourselfActive();
  };

  const handleSetYourselfActive = () => {
    setIsOpenAttendancePunchIn(true);
  };

  const handleSetYourselfAway = () => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure you want Log out ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          // if (TaskId && AssigneeId) {
          updatePunchOut({
            workingFrom: attendanceData?.data?.workingFrom,
          }).then((res: any) => {
            if (res?.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              if (res?.data?.status) {
                showToast("success", "Logged out successfully");
              } else {
                showToast("error", res?.data?.message);
              }
            }
          });
          // }
        }
      },
    });
  };

  useEffect(() => {
    if (!isLoadingTaskData && !isFetchingTaskData) {
      setTaskData(taskDataNotification);
      dispatch(
        setIsTaskInProgress(
          taskDataNotification?.data?.inProgressTasks?.length ? true : false
        )
      );
      // setTaskData(taskDataNotification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDataNotification, isLoadingTaskData, isFetchingTaskData]);

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  BREAK MODULE  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>//
  const [status, setStatus] = useState<any>();
  const [inProgressTaskId, setInProgressTaskId] = useState<any>();

  //GET USER BREAK STATUS
  const {
    data: breakStatus,
    isLoading: breakStatusIsLoading,
    isFetching: breakStatusIsFetching,
  } = useGetUserBreakStatusQuery("", { refetchOnFocus: true });
  //  UPDATE STATUS
  const [updateStatus] = useUpdateUserStatusMutation();

  // GET USER INPROGRESS TASK
  const {
    data: InProgressData,
    isLoading: InProgressDataIsLoading,
    isFetching: InProgressDataIsFetching,
  } = useGetUserInProgressTaskQuery("", { refetchOnFocus: true });

  useEffect(() => {
    if (!InProgressDataIsFetching && !InProgressDataIsLoading) {
      setInProgressTaskId(InProgressData?.data?._id);
    } else {
      setInProgressTaskId(null);
    }
  }, [InProgressDataIsFetching, InProgressDataIsLoading, InProgressData]);

  useEffect(() => {
    if (breakStatus?.data?.userAction) {
      setStatus({
        label: breakStatus?.data?.userAction,
        value: breakStatus?.data?.userAction,
      });
    } else {
      setStatus({
        label: "WORKING",
        value: "WORKING",
      });
    }
  }, [breakStatusIsLoading, breakStatusIsFetching, breakStatus]);

  return (
    <>
      <div className="relative grid w-full h-full grid-cols-2 px-2 pl-0 border-b border-divider md:px-4">
        {/* Menu Icon */}

        <div className="flex items-center h-full ">
          <Tooltip title="Alt + T">
            <button
              type="button"
              onClick={toggleCollapse}
              className={`text-xl text-slate-500 px-3 block h-full  ${
                !isCollapsed && "md:hidden"
              }`}
            >
              <FiMenu />
            </button>
          </Tooltip>

          <div className="">{entityName}</div>
        </div>
        {/* Right Section */}
        <div className="flex items-center justify-end h-full gap-4 ">
          {/* <p className="hidden text-lg font-semibold md:block">Status</p> */}
          <div className="z-50 hidden text-sm md:block">
            <ATMSelect
              isClearable={false}
              name=""
              options={statusOptions}
              isLoading={breakStatusIsLoading || breakStatusIsFetching}
              value={status}
              disabled={status?.label === "IN MEETING"}
              onChange={(newValue) => {
                setStatus(newValue);
                if (
                  newValue?.value !== breakStatus?.data?.userAction &&
                  newValue?.value !== "WORKING"
                ) {
                  updateStatus({
                    taskId: inProgressTaskId || null,
                    userAction:
                      newValue?.value === "WORKING" ? null : newValue?.value,
                  }).then((res: any) => {
                    if (res.error) {
                      showToast("error", res?.error?.data?.message);
                    } else {
                      showToast("success", res?.data?.message);
                    }
                  });
                } else if (
                  newValue?.value === "WORKING" &&
                  breakStatus?.data?.userAction
                ) {
                  updateStatus({
                    taskId: inProgressTaskId || null,
                    userAction:
                      newValue?.value === "WORKING" ? null : newValue?.value,
                  }).then((res: any) => {
                    if (res.error) {
                      showToast("error", res?.error?.data?.message);
                    } else {
                      showToast("success", res?.data?.message);
                    }
                  });
                }
              }}
            />
          </div>

          <div className="flex items-center">
            <Switch
              checked={isActive}
              onChange={toggleSwitch}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span className="hidden font-semibold md:block ">
              {!isActive ? (
                <span className="text-lg font-semibold text-red-800">
                  Logged out
                </span>
              ) : (
                <span className="font-semibold text-green-800 text-md">
                  Logged in
                </span>
              )}
            </span>
            {isShowInprogressTask && (
              <ShowInprogressTaskFormWrapper
                taskData={taskData}
                setIsShowInprogressTask={setIsShowInprogressTask}
              />
            )}
          </div>

          <div className="menu-parent">
            <div className="">
              <button className="relative text-2xl text-primary-main duration-[800ms] px-1 py-1 rounded-full hover:bg-slate-200">
                {isTaskIsInPrgoress && (
                  <span className="flex  h-[10px] w-[10px] absolute top-[4px] right-[3px]">
                    <span className="absolute inline-flex w-full h-full bg-green-600 rounded-full opacity-100 animate-ping"></span>
                    <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-green-600"></span>
                  </span>
                )}
                <TbSubtask />
              </button>
            </div>
            <TaskNotificationCardWrapper taskData={taskData} />
          </div>

          <button
            onClick={() =>
              setIsShowProfileCard((isShowProfileCard) => !isShowProfileCard)
            }
            className="flex gap-5"
          >
            <div className="h-[28px] w-[28px] flex justify-center items-center font-bold bg-primary-main  text-white text-[12px]  rounded-full">
              {userData?.name?.[0]?.toUpperCase() || ""}
            </div>
          </button>
          {isShowProfileCard && (
            <UserProfileCard
              isLoadingOptions={breakStatusIsLoading || breakStatusIsFetching}
              onClickAway={() => setIsShowProfileCard(false)}
              userData={userData}
              status={status}
              setStatus={setStatus}
              updateStatus={updateStatus}
              breakStatus={breakStatus}
              inProgressTaskId={inProgressTaskId}
            />
          )}

          {isShowNotification && (
            <NotificationCard
              onClickAway={() => setIsShowNotification(false)}
            />
          )}

          {isShowAttendance && (
            <NotificationCard onClickAway={() => setIsShowAttendance(false)} />
          )}
        </div>
      </div>

      {isOpenAttendancePunchIn ? (
        <AttendancePunchInWrapper
          onClose={() => {
            setIsOpenAttendancePunchIn(false);
          }}
          setIsActive={setIsActive}
        />
      ) : null}
    </>
  );
};
export default Header;
