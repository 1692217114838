import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InterviewStatus } from "src/models/JobApplication.model";
import {
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/JobCreationStageSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useApplicationStageFeedbackMutation,
  useGetJobApplicationStagesQuery,
  useProceedNextStageMutation,
} from "src/services/JobApplicationService";
import { showToast } from "src/utils/showToaster";
import JobApplicationStage from "./JobApplicationStage";
import JobApplicationStageSideBarLayout from "./JobApplicationStageSidebarLayout";
const AddJobApplicationNotesWrapper = lazy(
  () => import("./JobApplicationNotes/Add/AddJobApplicationNotesWrapper")
);
const UpdateJobApplicationStatusWrapper = lazy(
  () =>
    import(
      "src/screens/JobApplication/UpdateJobApplicationStatus/UpdateJobApplicationStatusWrapper"
    )
);

const JobApplicationStageWrapper = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: jobId, applicationId } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const stageId = queryParams.get("stageId");
  const { items: jobApplicationList } = useSelector(
    (state: RootState) => state.jobApplication
  );

  const [answerList, setAnswerList] = useState<any>([]);
  const [isShowRejectPopup, setIsShowRejectPopup] = useState(false);
  const [isShowNotesForm, setIsShowNotesForm] = useState(false);

  const [applicationStageFeedback, applicationStageFeedbackInfo] =
    useApplicationStageFeedbackMutation();
  const [proceedNextStage, proceedNextStageInfo] =
    useProceedNextStageMutation();

  const {
    items,
    // selectedClient,
  } = useSelector((state: RootState) => state.jobCreationStage);

  //get stages list
  const { data, isLoading, isFetching } = useGetJobApplicationStagesQuery({
    jobId: jobId,
    applicationId: applicationId,
  });

  const handleInputChange = (questionInfo: any, value: any) => {
    const answerData = [...answerList];
    let formattedData = answerData?.map((answerInfo: any) => {
      if (answerInfo?._id === questionInfo?._id) {
        return {
          ...answerInfo,
          value: value,
        };
      } else {
        return answerInfo;
      }
    });
    setAnswerList(formattedData);
  };

  //submit stage feedback
  const handleSubmit = (interviewId: string, status: InterviewStatus) => {
    const formattedValues = {
      questions: answerList?.map((answerInfo: any) => {
        return {
          ...answerInfo,
          value: answerInfo?.value
            ? answerInfo?.questionType === "RANGE"
              ? JSON.stringify(answerInfo?.value)
              : answerInfo?.value
            : "",
        };
      }),
      roundStatus:
        status === "REJECTED" || status === "SHORTLISTED"
          ? "COMPLETED"
          : "ONGOING",
      shortlistingStatus:
        status === "REJECTED"
          ? "REJECTED"
          : status === "PENDING"
            ? "PENDING"
            : "SHORTLISTED",
    };
    applicationStageFeedback({
      body: formattedValues,
      interviewId: interviewId,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
        } else {
          showToast("error", res?.data?.message);
        }
      }
    });
  };

  //function to shortlist the stage for the next round in case of pending
  const handleProceedNextStage = (interviewId: string) => {
    proceedNextStage({
      interviewId: interviewId,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
        } else {
          showToast("error", res?.data?.message);
        }
      }
    });
  };

  //find the index of last stage whose proceedToNextStage is true, if stage exist then get the stageId of nextStage and if not then get the stage id of initial stage
  const findIndexOfLastStageWithProceedToNextStage = useMemo(() => {
    let previousStageIndex = data?.data?.findLastIndex(
      (stageInfo: any) => stageInfo?.interview?.proceedToNextStage
    );
    return previousStageIndex > -1
      ? data?.data?.[previousStageIndex + 1]?._id
      : data?.data?.[0]?._id;
  }, [data]);

  // Setting stage Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      if (!stageId) {
        // when user first navigate to the stage screen then redirect to the stage whose interview is gonna be scheduled
        navigate(
          `${location?.pathname}?stageId=${findIndexOfLastStageWithProceedToNextStage}`
        );
      } else {
        let stageIndex = data?.data?.findIndex(
          (stageInfo: any) => stageInfo?._id === stageId
        );
        let activeStageId =
          stageIndex > -1
            ? data?.data?.[stageIndex > 1 ? stageIndex - 1 : stageIndex]
                ?.interview?.proceedToNextStage
              ? stageId
              : findIndexOfLastStageWithProceedToNextStage
            : findIndexOfLastStageWithProceedToNextStage;
        navigate(`${location?.pathname}?stageId=${activeStageId}`);
      }
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (stageId) {
      let selectedStage = items?.find(
        (stageInfo: any) => stageInfo?._id === stageId
      )?.questions;
      let answerData = selectedStage?.map((questionInfo: any) => {
        return {
          questionType: questionInfo?.questionType,
          questionTitle: questionInfo?.questionTitle,
          _id: questionInfo?._id,
          value: "",
        };
      });
      setAnswerList(answerData);
    }
  }, [stageId, items]);

  return (
    <>
      <JobApplicationStageSideBarLayout
        listData={items}
        stageId={stageId || ""}
        onClickStage={(stageId: string) =>
          navigate(`${location?.pathname}?stageId=${stageId}`)
        }
        onAddNoteClick={() => setIsShowNotesForm(!isShowNotesForm)}
      >
        <JobApplicationStage
          answerList={answerList}
          stageData={
            items?.find((stageInfo: any) => stageInfo?._id === stageId) || null
          }
          handleInputChange={(questionInfo: any, value: any) =>
            handleInputChange(questionInfo, value)
          }
          handleSubmit={(interviewId: string, status: InterviewStatus) =>
            handleSubmit(interviewId, status)
          }
          applicationId={applicationId || ""}
          stageId={stageId || ""}
          isSubmitLoading={applicationStageFeedbackInfo?.isLoading}
          applicationStatus={
            jobApplicationList?.find(
              (applicationInfo: any) => applicationInfo?._id === applicationId
            )?.applicationStatus
          }
          handleProceedNextStage={handleProceedNextStage}
          isProceedNextStageLoading={proceedNextStageInfo?.isLoading}
          handleRejectApplication={() =>
            setIsShowRejectPopup(!isShowRejectPopup)
          }
        />
      </JobApplicationStageSideBarLayout>
      {isShowRejectPopup && (
        <UpdateJobApplicationStatusWrapper
          applicationId={applicationId || ""}
          onClose={() => setIsShowRejectPopup(false)}
          applicationStatus="REJECTED"
        />
      )}
      {isShowNotesForm && (
        <Suspense>
          <AddJobApplicationNotesWrapper
            onClose={() => setIsShowNotesForm(false)}
            applicationId={applicationId || ""}
          />
        </Suspense>
      )}
    </>
  );
};

export default JobApplicationStageWrapper;
