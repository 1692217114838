import React, { useRef } from "react";
import { FormikProps } from "formik";
import { MeetingNotesTabFormValues } from "./MeetingMomWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import MeetingMomTemplate from "./MOMTemplate/MeetingMomTemplate";
import { useReactToPrint } from "react-to-print";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";

type Props = {
  formikProps: FormikProps<MeetingNotesTabFormValues>;
  rows: any[];
  isTableLoading: boolean;
  meetingData: any;
};

const MeetingMom = ({
  formikProps,
  rows,
  isTableLoading,
  meetingData,
}: Props) => {
  const meetingMOMRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => meetingMOMRef?.current,
    documentTitle: `${meetingData?.projectName}(codiotic-connect)`
  });

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <ATMPageHeader
          hideSearchBox={true}
          hideAddButton={true}
          pageTitle="MOM (Minutes Of Meeting)"
        />
      </div>

      <MeetingMomTemplate
        ref={meetingMOMRef}
        rows={rows}
        meetingData={meetingData}
      />

      <div className="flex justify-end">
        <ATMLoadingButton className="w-[10%]" onClick={handlePrint}>
          Download
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default MeetingMom;
