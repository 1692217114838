import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { OperationsFormValues } from "src/models/Operations.model";
import { useGetAllBusinessModelQuery } from "src/services/BusinessService";

type Props = {
  formikProps: FormikProps<OperationsFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
};

const OperationForm = ({ formType = "ADD", formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;
  const { data: getBusinessModel, isLoading: isBusinessModelLoading } =
    useGetAllBusinessModelQuery("");
  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="sticky top-0 z-10 flex items-center justify-between py-1 bg-white">
        <div className="text-xl font-medium">
          {" "}
          {formType === "ADD" ? "Add Operation" : "Update Operation"}{" "}
        </div>
      </div>
      <div className="flex flex-col gap-6">
        {/* title */}
        <div className="z-10">
          <ATMTextField
            name="title"
            required
            label="Title"
            placeholder="Enter title"
            value={values?.title}
            onChange={(e: any) => {
              setFieldValue("title", e.target.value);
            }}
          />
        </div>
        <div>
          <ATMMultiSelect
            required
            name="modelName"
            value={values?.modelName}
            label="Business Model"
            placeholder="Select Business Model"
            onChange={(newValue) => {
              setFieldValue("modelName", newValue);
            }}
            options={getBusinessModel?.data?.map((ele: any) => {
              return {
                label: ele?.modelName,
                value: ele?._id,
              };
            })}
            isLoading={isBusinessModelLoading}
          />
        </div>
        {/* description */}
        <div className="z-0">
          <MOLMarkdownEditor
            name="description"
            required
            label="Description"
            value={values.description}
            placeholder="Write description or drag your files here...."
            onChange={(newValue: any) => {
              setFieldValue("description", newValue);
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-end flex-1 gap-2">
        <ATMLoadingButton
          variant="text"
          type="button"
          color="primary"
          onClick={onClose}
        >
          Cancel
        </ATMLoadingButton>
        <ATMLoadingButton type="submit" isLoading={isSubmitting}>
          {formType === "ADD" ? "Save" : "Update"}
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default OperationForm;
