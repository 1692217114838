import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/PlatformsSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useDeletePlatformMutation,
  useGetAllPlatformsWithPaginationQuery,
} from "src/services/RequirementGatheringService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import EditSubscriptionWrapper from "../Edit/EditPlatformWrapper";
import AccountsListing from "./PlatformListing";
import { useParams } from "react-router-dom";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const PlatformListingWrapper = (props: Props) => {
  const { id } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [selectedtProductId, setSelectedProductId] = useState("");

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.platforms);

  const [deletePlatform] = useDeletePlatformMutation();
  const { data, isLoading, isFetching } = useGetAllPlatformsWithPaginationQuery(
    {
      limit: rowsPerPage,
      searchValue: searchValue,
      params: ["platformName"],
      page: page,
      filterBy: [
        {
          fieldName: "workspaceId",
          value: [id],
        },
      ],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    }
  );

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Platform?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deletePlatform(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "platformTitle",
      headerName: "Platforms",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      name: UserModuleNameTypes.PLATFORM_LIST_PLATFORM_NAME,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectedProductId(row?.platformId);
                },
                acitonName: UserModuleNameTypes.ACTION_PLATFORM_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?.platformId);
                },
                acitonName: UserModuleNameTypes.ACTION_PLATFORM_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <AccountsListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
        />
      </div>
      {isOpenEditDialog && (
        <EditSubscriptionWrapper
          selectedSubscription={selectedtProductId}
          onClose={() => setIsOpenEditDialog(false)}
        />
      )}
      {/* {isOpenAddDialog ? (
        <AddSubscriptionFormWrapper
          onClose={() => setIsOpenAddDialog(false)}
        />
      ) : null} */}
    </>
  );
};

export default PlatformListingWrapper;
