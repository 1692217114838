import React, { useEffect, useState } from 'react';
import RequirementGatheringListing from './RequirementGatheringListing';
import { columnTypes } from 'src/components/UI/atoms/ATMTable/ATMTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { setPage, setRowsPerPage, setSearchValue , setIsTableLoading , setItems ,setTotalItems} from 'src/redux/slices/RequirementGatheringSliceNew';
import AddRequirementGatheringWrapper from '../Add/AddRequirementGatheringWrapper';
import { useListPaginationRequirementGatheringQuery } from 'src/services/RequirementGatheringService';

type Props = {};

const RequirementGatheringListingWrapper = (props: Props) => {
  const dispatch = useDispatch(); 
  const [isOpenAddDialog  , setIsOpenAddDialog] = useState(false)
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.requirementGatheringNewSlice);

    const { data, isLoading, isFetching } = useListPaginationRequirementGatheringQuery({
        limit: rowsPerPage,
        searchValue: searchValue,
        params: ["requirementByName"],
        page: page,
        filterBy: [],
        dateFilter: {},
        orderBy: "createdAt",
        orderByValue: -1,
        isPaginationRequired: true,
      });
    
      useEffect(() => {
        if (isLoading || isFetching) {
          dispatch(setIsTableLoading(true));
        } else {
          dispatch(setItems(data?.data || []));
          dispatch(setIsTableLoading(false));
          dispatch(setTotalItems(data?.totalItem || 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    { field: 'requirementByName', headerName: 'User Name', flex: 'flex-[1_1_0%]' },
    { field: 'title', headerName: 'Title', flex: 'flex-[1_1_0%]' },
  ];

  return (
    <div>
      <RequirementGatheringListing
        columns={columns}
        rows={items}
        onAddNew= {()=>setIsOpenAddDialog(true)}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) => dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenAddDialog && <AddRequirementGatheringWrapper onClose={()=>setIsOpenAddDialog(false)}/> }
    </div>
  );
};

export default RequirementGatheringListingWrapper;