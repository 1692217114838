import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { object, ref, string } from "yup";
import ChangeClientUserPassword from "./ChangeClientUserPassword";
import { ChangeClientUserPasswordFormValues } from "src/models/ChangePassword.model";
import { useChangeClientUserPasswordMutation } from "src/services/ClientUserServices";

type Props = {
  onClose: () => void;
  selectedClient: any;
};

const ChangeClientUserPasswordWrapper = ({
  onClose,
  selectedClient,
}: Props) => {
  const [changeClientUserPassword] = useChangeClientUserPasswordMutation();

  // Form Initial Values
  const initialValues: ChangeClientUserPasswordFormValues = {
    confirmPassword: "",
    newPassword: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    confirmPassword: string()
      .required("Please confirm your password")
      .oneOf(
        [ref("newPassword")],
        "Confirm password must be same as New Password."
      ),
    newPassword: string().required("Please enter New Password."),
  });

  // Handle Submit
  const handleSubmit = (
    values: ChangeClientUserPasswordFormValues,
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<ChangeClientUserPasswordFormValues>
  ) => {
    changeClientUserPassword({ body: values, id: selectedClient }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ChangeClientUserPassword
            formikProps={formikProps}
            onClose={onClose}
            formType="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default ChangeClientUserPasswordWrapper;
