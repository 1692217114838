import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AccountsType = {
  items: any[] | [];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  filterBy: {
    fieldName: string;
    value: string[];
  }[],
  GSTApplicable: any,
  expenseCategoryId: any,
  dateFilter: {
    startDate: string | null;
    endDate: string | null;
  };
  type: any,
};

const initialState: AccountsType = {
  items: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  rowsPerPage: 20,
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  filterBy: [],
  GSTApplicable: [],
  expenseCategoryId: [],
  dateFilter: {
    startDate: "",
    endDate: "",
  },
  type : []

};

const ClientLeadgerSlice: Slice<AccountsType> = createSlice({
  name: "clientLeadger",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string[] }[]>
    ) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setGSTApplicable: (state, action: PayloadAction<string[]>) => {
      state.GSTApplicable = action.payload;
    },
    setExpenseCategoryId: (state, action: PayloadAction<boolean>) => {
      state.expenseCategoryId = action.payload;
    },
    setDateFilter: (
      state,
      action: PayloadAction<{ startDate: string; endDate: string,  }>
    ) => {
      state.dateFilter = action.payload;
    },
    setType: (state, action: PayloadAction<boolean>) => {
      state.type = action.payload;
    },
  },
});

export const {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setFilterBy,
  setIsOpenEditFormDialog,
  setSelectedClient,
  setGSTApplicable,
  setExpenseCategoryId,
  setType
} = ClientLeadgerSlice.actions;
export default ClientLeadgerSlice.reducer;
