import apiSlice from "../ApiSlice";

export const paymentModesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //  Get  with pagination
    getPaymentModes: builder.query({
      providesTags: ["payment-mode"],
      query: (body) => {
        return {
          url: "/payment-mode/",
          method: "POST",
          body,
        };
      },
    }),

    // get All
    getAllPaymentModes: builder.query({
      providesTags: ["payment-mode"],
      query: () => {
        return {
          url: "/payment-mode/",
          method: "GET",
        };
      },
    }),

    //Add
    addPaymentMode: builder.mutation({
      invalidatesTags: ["payment-mode"],
      query: (body) => {
        return {
          url: "/payment-mode/add",
          method: "POST",
          body,
        };
      },
    }),

    //Edit
    updatePaymentMode: builder.mutation({
      invalidatesTags: ["payment-mode"],
      query: ({ id, body }) => {
        return {
          url: `/payment-mode/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get  by ID
    getPaymentModeById: builder.query({
      providesTags: ["payment-mode"],
      query: (id) => {
        return {
          url: `/payment-mode/${id}`,
          method: "GET",
        };
      },
    }),
    //Delete
    deletePaymentMode: builder.mutation({
      invalidatesTags: ["payment-mode"],
      query: (id: any) => ({
        url: `/payment-mode/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useGetPaymentModesQuery,
  useAddPaymentModeMutation,
  useUpdatePaymentModeMutation,
  useGetPaymentModeByIdQuery,
  useGetAllPaymentModesQuery,
  useDeletePaymentModeMutation,
} = paymentModesApi;
