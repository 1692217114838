import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { ExpenseCategoryFormValues } from "src/models/ExpenseCategory.model";
import { useAddGoalCategoryMutation } from "src/services/GoalCategoryService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import GoalCategoryForm from "../Layout/GoalCategoryForm";

type Props = {
  onClose: () => void;
};

const AddGoalCategoryWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addExpenseCategory] = useAddGoalCategoryMutation();

  // Form Initial Values
  const initialValues: ExpenseCategoryFormValues = {
    title: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter goal category"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ExpenseCategoryFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ExpenseCategoryFormValues>
  ) => {
    const formattedValues = {
      categoryName: values?.title,
    };

    addExpenseCategory(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <GoalCategoryForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddGoalCategoryWrapper;
