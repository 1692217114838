import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { LeaveRequestFormValues } from "src/models/LeaveReques.model";
import {
  useGetLeaveRequestByIdQuery,
  useUpdateLeaveRequestMutation,
} from "src/services/LeaveRequestService";
import { showToast } from "src/utils/showToaster";
import { date, object, string } from "yup";
import MoreThanOneDayLeaveRequestForm from "../Layouts/MoreThanOneDayLeaveRequestForm";
import { getApprovalsInfo } from "../utils/getApprovalsInfo";

type Props = {
  handleClose: any;
  leaveRequestId: string;
};

const EditMoreThanOneDayLeaveRequestFormWrapper = ({
  handleClose,
  leaveRequestId,
}: Props) => {
  // get  members
  const { codioticUsers, isDataLoading } = useGetAllCodioticUsers();
  const { data, isLoading, isFetching } =
    useGetLeaveRequestByIdQuery(leaveRequestId);
  const [updateLeaveRequest] = useUpdateLeaveRequestMutation();
  const [leaveInfo, setLeaveInfo] = useState<any>();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setLeaveInfo(data?.data);
    }
  }, [data, isLoading, isFetching]);

  const initialValues: LeaveRequestFormValues = {
    reason: leaveInfo?.reason,
    leaveStartDate: leaveInfo?.leaveStartDate || null,
    reportingDate: leaveInfo?.reportingDate || null,
    leaveType: leaveInfo?.leaveType || "",
    daysOfLeave: leaveInfo?.daysOfLeave || "",
    dayPart: "",
    leavingTime: "",
    arrivalTime: "",
    approvals: getApprovalsInfo(leaveInfo),
    buddy: {
      label: leaveInfo?.buddyName,
      value: leaveInfo?.buddy,
    },
  };

  const validationSchema = object().shape({
    leaveStartDate: string().required("Please select start date"),
    reportingDate: date()
      .required("Please select reporting date")
      .when("leaveStartDate", (leaveStartDate, schema) => {
        return schema.min(
          leaveStartDate?.[0] ? new Date(leaveStartDate?.[0]) : new Date(),
          "Reporting Date must be after Start Date"
        );
      }),
    buddy: object().required("Please select buddy"),
    reason: string().required("Please enter reason"),
    approvals: object().required("Please select buddy"),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeaveRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<LeaveRequestFormValues>
  ) => {
    const {
      approvals,
      buddy,
      leaveStartDate,
      reportingDate,
      dayPart,
      ...rest
    } = values;

    const formattedValues = {
      ...rest,
      approvals: [approvals?.value],
      leaveStartDate: moment(leaveStartDate).format("YYYY-MM-DD"),
      reportingDate: moment(reportingDate).format("YYYY-MM-DD"),
      buddy: buddy?.value,
    };

    updateLeaveRequest({
      id: leaveRequestId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <MoreThanOneDayLeaveRequestForm
              handleClose={handleClose}
              formikProps={formikProps}
              codioticUsers={codioticUsers}
              isDataLoading={isDataLoading}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditMoreThanOneDayLeaveRequestFormWrapper;
