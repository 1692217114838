import React, { useState, useEffect } from "react";
import ATMSelect from "../../atoms/formFields/ATMSelect/ATMSelect";
import { useGetAllLabelsQuery } from "src/services/ProjectServices";

type Props = {
  name?: string;
  value: any;
  onChange: (newValue: any) => void;
  onBlur?: (e: any) => void;
  label?: string;
  placeholder?: string;
  isMulti?: boolean;
  id?: string;
  disabled?: boolean,
};

const MOLLabelDropdown = ({
  name = "",
  value,
  onChange,
  onBlur,
  isMulti = false,
  label = "Label",
  placeholder = "Select Label",
  id = "",
  disabled = false,
}: Props) => {
  const [labelOptions, setLabelOptions] = useState([]);
  const {
    data: labels,
    isLoading: isLabelLoading,
    isFetching: isLabelFetching,
  } = useGetAllLabelsQuery(id || "",);

  // Setting Team Members Data
  useEffect(() => {
    if (!isLabelLoading && !isLabelFetching) {
      setLabelOptions(labels?.data?.label || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, isLabelLoading, isLabelFetching]);

  return (
    <ATMSelect
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isMulti={isMulti}
      label={label}
      placeholder={placeholder}
      options={labelOptions}
      disabled={disabled}
      isLoading={isLabelLoading || isLabelFetching}
      renderOption={(option, { context }) => {
        if (context === "menu") {
          return <div className="capitalize"> {option.labelName} </div>;
        } else {
          return <div className="capitalize"> {option.labelName} </div>;
        }
      }}
      isOptionEqualToValue={(option, selected) => {
        return (
          selected?.findIndex(
            (selectedValue: any) => selectedValue?._id === option._id
          ) > -1
        );
      }}
      isSearchedOption={(option, searchValue) => {
        return searchValue
          ? option?.data?.labelName?.toLowerCase()?.includes(searchValue)
          : true;
      }}
    />
  );
};

export default MOLLabelDropdown;
