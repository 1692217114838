import React, { useEffect, useState } from "react";
import PerformanceListing from "./PerformanceListing";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setTotalItems,
  setItems,
} from "src/redux/slices/PerformanceSlice";
import moment from "moment";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { FaUserSlash } from "react-icons/fa";
import { TbProgressAlert } from "react-icons/tb";
import NotMeDialogWrapper from "../NotMeDialog/NotMeDialogWrapper";
import ClarificationRequestDialogWrapper from "../ClarificationRequestDialog/ClarificationRequestDialogWrapper";
import {
  useCategoryWisePointerQuery,
  usePerformanceListQuery,
} from "src/services/performanceService";
import LogHistoryDialogWrapper from "../LogHistoryDialog/LogHistoryDialogWrapper";
import { HiOutlineInformationCircle } from "react-icons/hi";

type Props = {};

const PerformanceListingWrapper = (props: Props) => {
  const dispatch = useDispatch();

  const [isOpenNotMeDialog, setIsOpenNotMeDialog] = useState(false);
  const [notMeReason, setNotMeReason] = useState<any>();
  const [categoryData, setCategoryData] = useState<any>();
  const [isOpenPerformanceDialog, setIsOpenPerformanceDialog] = useState<any>();
  const [incidentCategory, setIncidentCategory] = useState<any>("");
  const [isOpenClarificationDialog, setIsOpenClarificationDialog] =
    useState(false);
  const [clarificationRemark, setClarificationRemark] = useState<any>();
  const [selectId, setSelectId] = useState("");

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.performance);

  const {
    data: categoryWiseData,
    isLoading: categoryWiseDataIsLoading,
    isFetching: categoryWiseDataIsFetching,
  } = useCategoryWisePointerQuery({
    dateFilter: {
      dateFilterKey: "addedOnDate",
      startDate:
        searchValue === null
          ? moment().startOf("month").format("YYYY-MM-DD")
          : moment(searchValue).format("YYYY-MM-DD"),
      endDate:
        searchValue === null
          ? moment().endOf("month").format("YYYY-MM-DD")
          : moment(searchValue).endOf("month").format("YYYY-MM-DD"),
    },
  });

  useEffect(() => {
    setCategoryData(categoryWiseData);
  }, [categoryWiseDataIsFetching, categoryWiseDataIsLoading, categoryWiseData]);

  const { data, isLoading, isFetching } = usePerformanceListQuery({
    limit: rowsPerPage,
    searchValue: "",
    params: ["typeName", "categoryName"],
    page: page,
    filterBy: [
      {
        fieldName: "categoryId",
        value: incidentCategory,
      },
    ],
    dateFilter: {
      startDate:
        searchValue === null
          ? moment().startOf("month").format("YYYY-MM-DD")
          : moment(searchValue).format("YYYY-MM-DD"),
      endDate:
        searchValue === null
          ? moment().endOf("month").format("YYYY-MM-DD")
          : moment(searchValue).endOf("month").format("YYYY-MM-DD"),
    },
    orderBy: "addedOnDate",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "addedOnDate",
      headerName: "Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[150px]",
      renderCell: (row: any) => (
        <div className="text-[13px] text-slate-700 font-medium">
          {row?.addedOnDate
            ? moment(row?.addedOnDate).format("DD MMM YYYY")
            : ""}
        </div>
      ),
    },
    {
      field: "categoryName",
      headerName: "category Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "typeName",
      headerName: "type Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "teamMembers",
      headerName: "Assign To",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[300px]",
      renderCell: (row: any) => (
        <div className="font-medium text-[12px] py-1">{row?.memberName}</div>
      ),
    },
    {
      field: "remark",
      headerName: "remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-h-[100px] overflow-scroll",
    },
    {
      field: "weightage",
      headerName: "Point",
      flex: "flex-[1_1_0%]",
      align: "center",
      renderCell: (row: any) => (
        <div className="font-medium text-[12px] py-1">{row?.weightage}</div>
      ),
    },
    {
      field: "notMe",
      headerName: "Not Me Request",
      align: "center",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-h-[100px] overflow-scroll",
      renderCell: (row: any) => (
        <div>
          {row?.notMe ? (
            <span className="font-medium text-green-600">Yes</span>
          ) : (
            <span className="font-medium text-red-600">No</span>
          )}
        </div>
      ),
    },
    {
      field: "isClarificationRequired",
      headerName: "Clarification Request",
      flex: "flex-[1_1_0%]",
      align: "center",
      extraClasses: "max-h-[100px] overflow-scroll",
      renderCell: (row: any) => (
        <div>
          {row?.isClarificationRequired ? (
            <span className="font-medium text-green-600">Yes</span>
          ) : (
            <span className="font-medium text-red-600">No</span>
          )}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) =>
        row?.notMe ||
        row?.isClarificationRequired ||
        row?.clarificationCount >= 2 ||
        row?.isNotmeCount >= 2 ? null : (
          <div>
            <ATMActionMenu
              options={[
                {
                  label: (
                    <div className="flex items-center font-medium gap-x-2 text-amber-600">
                      <FaUserSlash size="1.2em" color="#DB7D11" /> Not Me
                    </div>
                  ),
                  onClick: () => {
                    setIsOpenNotMeDialog(true);
                    setNotMeReason(row?.isNotMeReason);
                    setSelectId(row?._id);
                  },
                },
                {
                  label: (
                    <div className="flex items-center font-medium text-teal-600 gap-x-2">
                      <TbProgressAlert size="1.3em" color="#12968A" />{" "}
                      Clarification
                    </div>
                  ),
                  onClick: () => {
                    setIsOpenClarificationDialog(true);
                    setSelectId(row?._id);
                    setClarificationRemark(row?.clarifications);
                  },
                },
              ]}
            />
          </div>
        ),
    },
    {
      field: "logs",
      headerName: "Logs",
      flex: "flex-[1_1_0%]",
      align: "center",
      extraClasses: "max-h-[100px] overflow-scroll",
      renderCell: (row: any) => (
        <div className="cursor-pointer">
          <p
            onClick={() => {
              setIsOpenPerformanceDialog(true);
              setNotMeReason(row?.isNotMeReason);
              setClarificationRemark(row?.clarifications);
            }}
          >
            <HiOutlineInformationCircle size="1.6em" />{" "}
          </p>
        </div>
      ),
    },
  ];

  return (
    <div>
      <PerformanceListing
        columns={columns}
        isLoading={categoryWiseDataIsLoading || categoryWiseDataIsFetching}
        categoryData={categoryData}
        incidentCategory={incidentCategory}
        setIncidentCategory={setIncidentCategory}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenNotMeDialog && (
        <NotMeDialogWrapper
          onClose={() => {
            setIsOpenNotMeDialog(false);
          }}
          selectId={selectId}
          notMeReasons={notMeReason}
        />
      )}
      {isOpenClarificationDialog && (
        <ClarificationRequestDialogWrapper
          onClose={() => {
            setIsOpenClarificationDialog(false);
          }}
          selectId={selectId}
          clarificationRemark={clarificationRemark}
        />
      )}

      {isOpenPerformanceDialog && (
        <LogHistoryDialogWrapper
          notMeReason={notMeReason}
          clarificationRemark={clarificationRemark}
          onClose={() => setIsOpenPerformanceDialog(false)}
        />
      )}
    </div>
  );
};

export default PerformanceListingWrapper;
