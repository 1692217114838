import React, { useEffect, useState, Suspense } from "react";
import DeploymentRequestList from "./DeploymentRequestList";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/DeploymentSlice";
import {
  useCloseDeploymentRequestMutation,
  useDeleteDeploymentRequestByIdMutation,
  useGetAllDeploymentRequestWithPaginationQuery,
} from "src/services/DeploymentRequestService";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdClose, MdDelete, MdEdit } from "react-icons/md";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { DeploymentRequestListResponse } from "src/models/DeploymentRequest.model";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { CircularProgress } from "@mui/material";

type Props = {};

const DeploymentRequestListWrapper = (props: Props) => {
  const [isOpenAddDialog, setIsOpenAddDialog] = useState<boolean>(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);

  // Initiate Methods
  const dispatch = useDispatch();
  const [deleteDeploymentRequest] = useDeleteDeploymentRequestByIdMutation();
  const [closeDeploymentRequest] = useCloseDeploymentRequestMutation();

  // Dynamic import
  const AddDeploymentRequest = React.lazy(
    () => import("../Add/AddDeploymentRequest")
  );
  const EditDeploymentRequestWrapper = React.lazy(
    () => import("../Edit/EditDeploymentRequestWrapper")
  );

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.deployment);
  const [deploymentRequestId, setDeploymentRequestId] = useState<string>("");

  const { data, isLoading, isFetching } =
    useGetAllDeploymentRequestWithPaginationQuery({
      limit: rowsPerPage,
      searchValue: searchValue,
      params: [
        "projectId",
        "gitRepoId",
        "serverId",
        "changesInEnvFile",
        "changesInDatabase",
        "changeLog",
        "assignedToId",
        "status",
      ],
      page: page,
      filterBy: [],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });

  const handleDeleteDeploymentRequest = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Deployment Request ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteDeploymentRequest(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const handleCloseDeploymentRequest = (row: { id: string }) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to Close this Deployment Request ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          closeDeploymentRequest({ id: row.id }).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "projectName",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.DEPLOYMENT_REQUEST_LIST_PROJECT,
      renderCell: (row: DeploymentRequestListResponse) => (
        <div className="capitalize"> {row?.projectName} </div>
      ),
    },
    {
      field: "gitRepoName",
      headerName: "Git Repo",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.DEPLOYMENT_REQUEST_LIST_GIT_REPO,
      renderCell: (row: DeploymentRequestListResponse) => (
        <div className="capitalize"> {row?.gitRepoName} </div>
      ),
    },
    {
      field: "serverName",
      headerName: "Server",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.DEPLOYMENT_REQUEST_LIST_SERVER,
      renderCell: (row: DeploymentRequestListResponse) => (
        <div className="capitalize"> {row?.serverName} </div>
      ),
    },
    {
      field: "teamMemberName",
      headerName: "Team Member",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.DEPLOYMENT_REQUEST_LIST_TEAM_MEMBER,
      renderCell: (row: DeploymentRequestListResponse) => (
        <div className="capitalize"> {row?.teamMemberName} </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: DeploymentRequestListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setDeploymentRequestId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_DEPLOYMENT_REQUEST_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdClose color="#000000" /> Close
                  </div>
                ),
                onClick: () => {
                  handleCloseDeploymentRequest({ id: row?._id });
                },
                acitonName: UserModuleNameTypes.ACTION_DEPLOYMENT_REQUEST_CLOSE,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDeleteDeploymentRequest(row?._id);
                },
                acitonName:
                  UserModuleNameTypes.ACTION_DEPLOYMENT_REQUEST_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DeploymentRequestList
        columns={columns}
        rows={items}
        addNew={() => setIsOpenAddDialog(true)}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      {/* Add */}
      <Suspense fallback={<CircularProgress />}>
        {isOpenAddDialog ? (
          <AddDeploymentRequest onClose={() => setIsOpenAddDialog(false)} />
        ) : null}
      </Suspense>

      {/* Edit */}
      <Suspense fallback={<CircularProgress />}>
        {isOpenEditDialog ? (
          <EditDeploymentRequestWrapper
            onClose={() => setIsOpenEditDialog(false)}
            selectedDeploymentRequest={deploymentRequestId}
          />
        ) : null}
      </Suspense>
    </React.Fragment>
  );
};

export default DeploymentRequestListWrapper;
