import { useState, useEffect } from "react";
import { useGetAllCounrtiesQuery } from "src/services/CountryServices";

const useCountries = () => {
  const [countries, setCountries] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllCounrtiesQuery("");

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setCountries(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { countries, isDataLoading };
};

export default useCountries;
