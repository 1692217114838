import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/GoalViewSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { useParams } from "react-router-dom";
import { useGetGoalsAchivementQuery, useGetGoalsByIdQuery } from "src/services/GoalsService";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import GoalCategoryListing from "./ViewGoal";
import { CircularProgress, Tooltip } from "@mui/material";

type Props = {};

const ViewGoalWrapper = (props: Props) => {

  const { id } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.goalView);

  const [itemsGoal, setItemsGoal] = useState<any>({});

  const { isLoading: isLoadingViewGoal, isFetching: isFetchingViewGoal, data: viewGoalData } = useGetGoalsByIdQuery(
    id, { skip: !id }
  );

  useEffect(() => {
    if (!isLoadingViewGoal && !isFetchingViewGoal) {
      setItemsGoal(viewGoalData?.data || {});
    }
  }, [isLoadingViewGoal, isFetchingViewGoal, viewGoalData]);

  const { data, isLoading, isFetching } = useGetGoalsAchivementQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["remark"],
    page: page,
    filterBy: [
      {
        fieldName: 'goalId',
        value: id
      }
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);


  const columns: columnTypes[] = [
    {
      field: "achieveDate",
      headerName: "Achieve Date  ",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      // name: UserModuleNameTypes.EXPENSES_LIST_EXPENSE_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="text-slate-700 font-medium">
            {row.achieveDate ? formatedDateTimeIntoIst(row.achieveDate, "DD MMM yyyy") : '-'}
          </div>

        </div>
      ),
    },
    {
      field: "achieved",
      headerName: "Achieved  ",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      // name: UserModuleNameTypes.EXPENSES_LIST_EXPENSE_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="text-slate-700 font-medium">
            {row.achieved || '-'}
          </div>
        </div>
      ),
    },
    {
      field: "accountName",
      headerName: "Members Involved ",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[250px]",
      // name: UserModuleNameTypes.EXPENSES_LIST_VIEW_BILL,
      renderCell: (row: any) => (
        <div className="text-[12px]">
          {row?.achievedBy?.length ? row?.achievedBy?.map((el: any, index: any) => {
            return (
              <Tooltip title={el?.memberName}>
                <div
                  className="flex flex-col gap-2">
                  {index + 1}. {el?.memberName}
                </div>
              </Tooltip>
            )
          }) : '-'}
        </div>
      ),
    },
    {
      field: "goalName",
      headerName: "Goal Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      // name: UserModuleNameTypes.ACCOUNTS_LIST_ACCOUNT_NAME,
    },
    {
      field: "remark",
      headerName: "Remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      // name: UserModuleNameTypes.ACCOUNTS_LIST_ACCOUNT_NAME,
    },
  ];

  return (
    <SideNavLayout>
      {(isLoading || isFetching || isLoadingViewGoal || isFetchingViewGoal) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <div>
        <GoalCategoryListing
          itemsGoal={itemsGoal}
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
        />
      </div>

    </SideNavLayout>
  );
};

export default ViewGoalWrapper;
