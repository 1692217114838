import apiSlice from "./ApiSlice";

export const campaignGroupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL campaign
    getAllCampaignGroupList: builder.query({
      providesTags: ["campaign-groups"],
      query: () => {
        return {
          url: "campaign-group",
          method: "GET",
        };
      },
    }),
    //GET CAMPAIGN PAGINATION
    getAllCampaignGroups: builder.query({
      providesTags: ["campaign-groups"],
      query: (body) => {
        return {
          url: "campaign-group",
          method: "POST",
          body,
        };
      },
    }),

    //ADD CAMPAIGN GROUPS
    addCampaignGroup: builder.mutation({
      invalidatesTags: ["campaign-groups"],
      query: (body) => {
        return {
          url: "campaign-group/add",
          method: "POST",
          body,
        };
      },
    }),
    //EDIT CAMPAIGN GROUPS
    editCampaignGroup: builder.mutation({
      invalidatesTags: ["campaign-groups"],
      query: ({ body, id }) => {
        return {
          url: `campaign-group/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //GET CAMPAIGN GROUP BY ID
    getCampaignGroupById: builder.query({
      providesTags: ["campaign-groups"],
      query: (id) => {
        return {
          url: `campaign-group/${id}`,
          method: "GET",
        };
      },
    }),
    //DELETE CAMPAIGN GROUP BY ID
    deleteCampaignGroup: builder.mutation({
      invalidatesTags: ["campaign-groups"],
      query: (id) => {
        return {
          url: `campaign-group/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAllCampaignGroupListQuery,
  useGetAllCampaignGroupsQuery,
  useAddCampaignGroupMutation,
  useEditCampaignGroupMutation,
  useGetCampaignGroupByIdQuery,
  useDeleteCampaignGroupMutation,
} = campaignGroupApi;
