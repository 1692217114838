import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type RequirementSliceStateType = {
  requirementGatheringData: any;
  selectedPlatformData: any;
  isTableLoading: boolean;
  searchValue: string;
  selectedData: any;
};

const initialState: RequirementSliceStateType = {
  requirementGatheringData: [],
  selectedPlatformData: null,
  isTableLoading: true,
  searchValue: "",
  selectedData: null,
};

const RequirementGatheringSlice: Slice<RequirementSliceStateType> = createSlice(
  {
    name: "requirementGathering",
    initialState,
    reducers: {
      setRequirementGathering: (state, action: PayloadAction<any[] | []>) => {
        state.requirementGatheringData = action.payload;
      },
      setSelectedPlatformData: (state, action: PayloadAction<any[] | []>) => {
        state.selectedPlatformData = action.payload;
      },
      setIsTableLoading: (state, action: PayloadAction<boolean>) => {
        state.isTableLoading = action.payload;
      },
      setSearchValue: (state, action: PayloadAction<string>) => {
        state.searchValue = action.payload;
      },

      setSelectedData: (state, action: PayloadAction<string>) => {
        state.selectedData = action.payload;
      },
    },
  }
);

export const {
  setRequirementGathering,
  setSelectedPlatformData,
  setIsTableLoading,
  setSearchValue,
  setSelectedData,
} = RequirementGatheringSlice.actions;
export default RequirementGatheringSlice.reducer;
