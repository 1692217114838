/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { IconType } from "react-icons";
import { HiArrowPathRoundedSquare } from "react-icons/hi2";
import { TbListDetails } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
    setIsTableLoading,
    setItems,
    setRowsPerPage,
    setSearchValue,
    setTotalItems,
} from "src/redux/slices/JobOnboardingSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { useAllOnboardApplicationsQuery } from "src/services/JobOnboardingService";
import AccessDenied from "src/utils/AccessDenied";
import { isAuthorized } from "src/utils/authorization";
import JobApplicationSideBarLayout from "./JobOnboardingSidebarLayout/JobApplicationSidebarLayout";

export const jobApplicationTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
    {
      label: "Details",
      icon: TbListDetails,
      path: "details",
      moduleName:
        UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_DETAILS_TAB,
    },
    {
      label: "Stages",
      icon: HiArrowPathRoundedSquare,
      path: "stages",
      moduleName:
        UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB,
    },
  ];

const JobOnboardingViewLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stageId = queryParams.get("stageId");
  const currentPath = location.pathname.split("/")[5];
  const { userData } = useGetLocalStorage();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    // selectedClient,
  } = useSelector((state: RootState) => state.jobOnboarding);
  const { data, isLoading, isFetching } = useAllOnboardApplicationsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["applicantName", "applicantEmail", "applicantPhoneNumber"],
    page: page,
    filterBy: [

    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("agenda");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of jobApplicationTabs) {
      const isValue = isAuthorized(
        nav?.moduleName as keyof typeof UserModuleNameTypes
      );
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tabsRender = jobApplicationTabs?.some((nav) => {
    return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
  });

  // useEffect(() => {
  //   if (!currentPath) {
  //     navigate("user");
  //   }
  // }, []);

  return (
    <JobApplicationSideBarLayout
      listData={items}
      hideViewMore={totalItems > rowsPerPage ? true : false}
      searchValue={searchValue}
      onSearch={(value) => dispatch(setSearchValue(value))}
      onClickViewMore={() => dispatch(setRowsPerPage(rowsPerPage + 20))}
      onClickApplication={(applicationId) =>
        navigate(
          `/job-onboard/job-onboarding/view/${applicationId}/${currentPath === "stages" ? currentPath + `?stageId=${stageId}` : currentPath}`
        )
      }
    >
      {tabsRender ? (
        <div className="flex flex-col h-full">
          {/* jobApplicationTabs */}
          {jobApplicationTabs?.length && (
            <div className="flex items-center gap-3 px-4 pt-2 bg-white border-b">
              {jobApplicationTabs
                ?.filter((nav) => {
                  return isAuthorized(
                    nav?.moduleName as keyof typeof UserModuleNameTypes
                  );
                })
                ?.map((tab: any, index: any) => {
                  return (
                    <div key={index}>
                      <button
                        type="button"
                        onClick={() => navigate(tab.path)}
                        className={`h-full px-4 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${currentPath === tab.path?.split("/")[0]
                            ? "text-primary-main   border-primary-main"
                            : "text-gray-700 border-white"
                          }
         `}
                      >
                        <div className="text-lg">
                          <tab.icon />
                        </div>
                        {tab.label}
                      </button>
                    </div>
                  );
                })}
            </div>
          )}

          <div className="h-full overflow-auto grow">
            <Outlet />
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </JobApplicationSideBarLayout>
  );
};

export default JobOnboardingViewLayout;
