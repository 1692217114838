import { useState, useEffect } from "react";
import {
  // useGetProjectsQuery ,
  useGetAllMeetingNoteTypePaginationDataQuery,
} from "src/services/MeetingTypeNoteService";

export const useGetAllNeetingNoteType = () => {
  const [allMeetingNoteType, setAllMeetingNoteType] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllMeetingNoteTypePaginationDataQuery({
    limit: 0,
    searchValue: "",
    params: ["meetingNoteType"],
    page: 0,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setAllMeetingNoteType(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { allMeetingNoteType, isDataLoading };
};
