import { FormikProps } from "formik";
import ATMDialog from "src/components/UI/atoms/ATMDialog/ATMDialog";
import ATMFormSectionHeading from "src/components/UI/atoms/ATMFormSectionHeading/ATMFormSectionHeading";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLChangeJobApplicationStatus from "src/components/UI/molecules/MOLChangeJobApplicationStatus/MOLChangeJobApplicationStatus";
import MOLFileUpload from "src/components/UI/molecules/MOLFileUpload/MOLFileUpload";
import useSkills from "src/hooks/useSkills";
import {
  JobApplicationChangeStatusValues,
  JobApplicationFormValues,
  JobApplicationStatus,
} from "src/models/JobApplication.model";
import { handleValidNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<JobApplicationFormValues>;
  formHeading: "ADD" | "EDIT";
  setIsShowResume?: (value: boolean) => void;
  isShowResume?: boolean;
  handleChangeStatus: (status: JobApplicationChangeStatusValues) => void;
  applicationStatus: JobApplicationStatus;
};

export const experienceRangeOption = [
  { label: "0-1 years", value: "0-1 years: Entry Level" },
  { label: "1-3 years", value: "1-3 years: Junior Level" },
  { label: "3-5 years", value: "3-5 years: Mid Level" },
  { label: "5-7 years", value: "5-7 years: Senior Level" },
  { label: "7-10 years", value: "7-10 years: Lead Level" },
  { label: "10-15 years", value: "10-15 years: Expert Level" },
  { label: "15+ years", value: "15+ years: Veteran Level" },
];
export const joiningAvailabilityOptions = [
  { label: "Immediate Joiner", value: "Immediate Joiner" },
  { label: "Serving Notice Period", value: "Serving Notice Period" },
];
export const employmentTypeOption = [
  { label: "Full Time", value: "Full-Time" },
  { label: "Part Time", value: "Part-Time" },
  { label: "Internship", value: "Internship" },
  { label: "Contract", value: "Contract" },
  { label: "Freelance", value: "Freelance" },
];

const UpdateJobOnboardingForm = ({
  formikProps,
  formHeading,
  setIsShowResume,
  isShowResume,
  handleChangeStatus,
  applicationStatus,
}: Props) => {
  const { values, isSubmitting, setFieldValue, handleBlur, errors } =
    formikProps;

  const { skills, isDataLoading: isSkillsLoading } = useSkills();

  const skillOptions = skills?.map((el) => {
    return {
      label: el?.skillName,
      value: el?._id,
    };
  });
  return (
    <div className="flex flex-col gap-6 p-3">
      <div className="py-2 border-b">
        <div className="flex items-center justify-between">
          <div className="text-xl font-medium">
            {" "}
            {formHeading === "ADD" ? "Add" : "Update"} Job Application{" "}
          </div>
          {applicationStatus === "RECEIVED" && (
            <MOLChangeJobApplicationStatus
              handleChangeStatus={handleChangeStatus}
            />
          )}
        </div>
      </div>
      <div className="grid  grid-cols-2 xl:grid-cols-3 gap-4">
        <div className="col-span-2 xl:col-span-3 ">
          <ATMFormSectionHeading heading="Personal Information" />
        </div>
        {/* Applicant Name */}
        <div>
          <ATMTextField
            required
            name="applicantName"
            value={values.applicantName}
            onChange={(e) => setFieldValue("applicantName", e.target.value)}
            label="Name"
            placeholder="Enter Name"
            onBlur={handleBlur}
          />
        </div>
        {/* Applicant Email */}
        <div className="">
          <ATMTextField
            required
            name="applicantEmail"
            value={values.applicantEmail}
            onChange={(e) => setFieldValue("applicantEmail", e.target.value)}
            label="Email"
            placeholder="Enter Email"
            onBlur={handleBlur}
          />
        </div>
        {/* Application Mobile */}
        <div className="">
          <ATMTextField
            required
            name="applicantPhoneNumber"
            value={values.applicantPhoneNumber}
            onChange={(e) => {
              handleValidNumber(e) &&
                setFieldValue("applicantPhoneNumber", e.target.value);
            }}
            label="Mobile"
            placeholder="Enter Mobile"
            onBlur={handleBlur}
          />
        </div>
        {/* Resume Link */}
        <div className="">
          <MOLFileUpload
            required
            isShowCancel
            onCancelClick={() => setFieldValue("resumeLink", "")}
            value={values.resumeLink}
            label="Upload Resume"
            name={`resumeLink`}
            onChange={(file: any) => {
              setFieldValue("resumeLink", file);
            }}
            accept=".pdf"
            placeholder="Upload resume"
            labelClasses="text-sm text-black"
            onPreviewClick={() => setIsShowResume?.(true)}
            isShowPreview={true}
          />
        </div>
        {/* platformApplicationURL Link */}
        <div className="">
          <ATMTextField
            name="platformApplicationURL"
            value={values.platformApplicationURL}
            onChange={(e) =>
              setFieldValue("platformApplicationURL", e.target.value)
            }
            label="Platform Application URL"
            placeholder="Enter Platform Application URL"
            onBlur={handleBlur}
          />
        </div>
        <div className="col-span-2 xl:col-span-3 pt-5 ">
          <ATMFormSectionHeading heading="Experience" />
        </div>

        <div>
          <ATMMultiSelect
            name="primerySkills"
            value={values.primerySkills}
            onChange={(newValue) => setFieldValue("primerySkills", newValue)}
            onBlur={handleBlur}
            label="Primary Skills"
            placeholder="Select Primary Skills"
            options={skillOptions}
            isLoading={isSkillsLoading}
          />
        </div>
        <div className="">
          <ATMSelect
            name="experienceRange"
            value={values.experienceRange}
            onChange={(newValue) => setFieldValue("experienceRange", newValue)}
            onBlur={handleBlur}
            label="Total years of experience"
            placeholder="Select Experience"
            options={experienceRangeOption}
          />
        </div>
        {/* Experience */}
        {/* <div className="col-span-2 xl:col-span-3 pt-1  h-full flex items-end">
          <ATMCheckbox
            required
            name="isExperienced"
            checked={values.isExperienced}
            onChange={(checked: boolean, value: string) =>
              setFieldValue("isExperienced", checked)
            }
            label="Does candidate have any work experience?"
          />
        </div> */}
        {/* Experience */}

        <div className="col-span-2 xl:col-span-3 pt-5 ">
          <ATMFormSectionHeading heading="Others" />
        </div>
        {/* Employment Type */}
        <div className="">
          <ATMSelect
            name="employmentType"
            value={values.employmentType}
            onChange={(newValue) => setFieldValue("employmentType", newValue)}
            onBlur={handleBlur}
            label="Employment Type"
            placeholder="Select Employment Type"
            options={employmentTypeOption}
          />
        </div>
        {/* Joining Availability */}
        <div className="">
          <ATMSelect
            name="joiningAvailability"
            value={values.joiningAvailability}
            onChange={(newValue) =>
              setFieldValue("joiningAvailability", newValue)
            }
            onBlur={handleBlur}
            label="Availability Type"
            placeholder="Select Availability Type"
            options={joiningAvailabilityOptions}
          />
        </div>

        {/* Min Expected Packae */}
        <div className="relative">
          <div className="text-sm font-medium">
            {" "}
            Expected Salary <span className="text-red-500"> * </span>{" "}
          </div>
          <div className="flex gap-5 items-center  mt-2">
            <div className="w-[100px]">
              <ATMTextField
                name="expectedPackageMin"
                value={values.expectedPackageMin}
                onChange={(e) =>
                  handleValidNumber(e) &&
                  setFieldValue("expectedPackageMin", e.target.value)
                }
                label=""
                placeholder=""
                onBlur={handleBlur}
                className="text-center rounded-lg"
                isShowErrorMessage={false}
              />
            </div>
            <div>-</div>
            <div className="w-[100px]">
              <ATMTextField
                name="expectedPackageMax"
                value={values.expectedPackageMax}
                onChange={(e) =>
                  handleValidNumber(e) &&
                  setFieldValue("expectedPackageMax", e.target.value)
                }
                label=""
                placeholder=""
                onBlur={handleBlur}
                className="text-center rounded-lg"
                isShowErrorMessage={false}
              />
            </div>
          </div>
          {errors?.expectedPackageMin || errors?.expectedPackageMax ? (
            <p className="absolute mt-0 text-xs text-red-500 text-start">
              Please enter the expected salary range
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex justify-end py-1 bg-white sticky bottom-0 z-50">
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          {formHeading === "ADD" ? "Save" : "Update"}
        </ATMLoadingButton>
      </div>

      {isShowResume && (
        <ATMDialog onClose={() => setIsShowResume?.(false)}>
          <div
            className={`relative min-w-[80vw]  min-h-[80vh] max-h-[90vh]  overflow-auto hide-scrollbar`}
          >
            <iframe
              src={values?.resumeLink?.url}
              aria-labelledby="resume"
              width="100%"
              height="100%"
              title={values?.resumeLink?.url}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                border: "none",
                height: "100%",
                width: "100%",
              }}
            />
          </div>
        </ATMDialog>
      )}
    </div>
  );
};

export default UpdateJobOnboardingForm;
