/* eslint-disable @typescript-eslint/no-unused-vars */
// |-- Built-in Dependencies --|
import React, { useEffect, useState } from "react";

// |-- External Dependencies --|
import { BiSearchAlt2 } from "react-icons/bi";
import { IconType } from "react-icons";
import { useDispatch } from "react-redux";

// |-- Internal Dependencies --|
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
// |-- Redux --|
import { AppDispatch } from "src/redux/store";
import { TaskListResponse } from "src/models/Task.model";
import { useParams } from "react-router-dom";
import { IoFilter } from "react-icons/io5";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
// import DoubtFilterFormDialogWrapper from "../../List/DoubtFilterFormCard/DoubtFilterFormDialogWrapper";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import DoubtFilterFormDialogWrapper from "src/screens/Doubts/List/DoubtFilterFormCard/DoubtFilterFormDialogWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

// |-- Types --|
type ViewLayoutPropTypes = {
  leftbar?: boolean;
  infoCard?: React.ReactNode;
  actionIcons?: {
    icon: IconType;
    onClick: () => void;
  }[];
  listData?: any[];
  tabs?: {
    label: string;
    icon: IconType;
    path: string;
  }[];
  searchValue?: string;
  onSearch?: (value: string) => void;
  onClickDoubtView?: (doubtId: string) => void;
  children?: any;
  hideViewMore?: boolean;
  onClickViewMore?: () => void;
};

const DoubtSideBarLayout = ({
  leftbar = true,
  infoCard,
  listData,
  tabs,
  searchValue = "",
  onSearch = () => {},
  onClickDoubtView = () => {},
  children,
  onClickViewMore,
  hideViewMore = false,
}: ViewLayoutPropTypes) => {
  // const navigate = useNavigate()
  const { id: DOUBT_ID } = useParams();
  const [isOpenFilterFormDialog, setIsOpenFilterFormDialog] =
    useState<boolean>(false);

  // const location = useLocation()

  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = useState(0);

  //   useEffect(() => {
  //     if (leftbar === true) {
  //       dispatch(setIsCollapsed(true));
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  useEffect(() => {
    const activeTabIndex = window.location.pathname.split("/")[3];
    // const tabindex = tabs?.findIndex((tab) => tab.path === activeTabIndex);
    // setActiveTab(tabindex);
  }, [tabs]);
  return (
    <>
      <div className="h-[calc(100vh-50px)]">
        <div className="w-full flex  h-[calc(100%)] bg-white">
          {/* Left Section Side Bar */}
          <div className="md:block hidden">
          {leftbar === true && (
            <div className="w-[260px] h-full flex flex-col border-r">
              {/* Info Card */}
              <div className=" w-full">{infoCard}</div>

              {/* Search Bar */}

              <div className="px-2 py-2 flex items-center justify-center border-b">
                <ATMInputAdormant
                  name=""
                  value={searchValue}
                  placeholder="Search here..."
                  className="h-[40px] shadow-sm border-[1px] focus:border-black"
                  adormant={
                    <BiSearchAlt2 className="text-slate-400 text-xl " />
                  }
                  adormantProps={{
                    position: "start",
                    extraClasses: "bg-white border-0 ",
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                  }}
                />
                {/* Filter Button */}
                <ATMLoadingButton
                    variant="tonal"
                    onClick={() => setIsOpenFilterFormDialog(true)}
                  >
                    <div className="flex items-center gap-2">
                      <IoFilter />
                      Filters
                    </div>
                  </ATMLoadingButton>
              
                {/* Filter Form */}
                <DoubtFilterFormDialogWrapper
                  open={isOpenFilterFormDialog}
                  onClose={() => setIsOpenFilterFormDialog(false)}
                />
              </div>

              {/* List */}
              <div className="grow overflow-auto">
                <div className="p-2 flex flex-col min-w-[100%] overflow-y-auto gap-2">
                  {listData?.map((doubt: any) => {
                    return (
                      <div
                        key={doubt?._id}
                        className={`rounded-md border p-2 cursor-pointer hover:bg-teal-100 flex flex-col gap-2 ${
                          doubt?._id === DOUBT_ID
                            ? "bg-teal-100 border border-teal-100 shadow-sm"
                            : "bg-white"
                        }`}
                        onClick={() => onClickDoubtView(doubt?._id)}
                      >
                        <div className="flex">
                          <div className="bg-[#ebebeb] text-xs rounded-md px-1 py-[1px]">
                            <div className="text-xs text-black">
                              {doubt?.projectName && doubt?.projectName}
                            </div>
                          </div>
                        </div>
                        <div className="" style={{ userSelect: "text" }}>
                          <span
                            className="task-id text-xs font-normal"
                            style={{ userSelect: "text" }}
                          >
                            {doubt?.taskNumber}
                          </span>
                          <h3 className="w-full overflow-hidden truncate text-sm min-w-[100%] font-medium">
                            {/* {doubt?.question} */}
                            <ATMMarkdownContainer
                              markdown={doubt?.question || ""}
                            />
                          </h3>
                        </div>
                      </div>
                    );
                  })}
                  {/* View more button */}
                  {hideViewMore && (
                    <span
                      onClick={onClickViewMore}
                      className="text-black text-center text-sm cursor-pointer hover:underline"
                    >
                      View More
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          </div>

          {/* Right Section */}
          <div
            // className={`${
            //   leftbar === true ? "w-[83%]" : "w-[100%]"
            // } border-b border-r border-l rounded-r h-full p-3`}
            className="w-full h-full flex flex-col md:overflow-hidden overflow-scroll"
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default DoubtSideBarLayout;
