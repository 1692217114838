import React from "react";
import { FormikProps } from "formik";
import { FormInitialValues } from "./MarkAsDoneBugTypeModalWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import MOLIssueOriginDropDown from "src/components/UI/molecules/MOLIssueOriginDropDown/MOLIssueOriginDropDown";
import { handleValidNumber } from "src/utils/validations/numberInput";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  userOptionsList: {
    label: string;
    value: string;
  }[];
  handleClose: () => void;
};

// Reson of issue origin
const reasonOfBugOption = [
  {
    label: "Our Side",
    value: "OUR_SIDE",
  },
  {
    label: "Client Side",
    value: "CLIENT_SIDE",
  },
  {
    label: "Third Party Side",
    value: "THIRD_PARTY_SIDE",
  },
];

const MarkAsDoneBugTypeModal = ({
  formikProps,
  userOptionsList,
  handleClose,
}: Props) => {
  // Formik
  const { values, setFieldValue, isSubmitting, handleBlur, handleSubmit } =
    formikProps;

  return (
    <div className="flex flex-col rounded border border-gray-200 relative">
      {/* Heading & Clear Button */}
      <div className="flex justify-between items-center px-4 py-2 sticky top-0 z-50 bg-white">
        <div className="text-[24px] font-medium">Mark as Done</div>
        <button
          type="button"
          className="text-[#041618] font-medium"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>

      {/* Filter Form */}
      <div className="p-3">
        <div className="grid grid-cols-2 gap-4 overflow-auto flex-1 ">
          {/* Number of developer involved */}
          <div>
            <ATMTextField
              type="text"
              name="numberOfDeveloper"
              value={values.numberOfDeveloper}
              label="Developers Involved (in numbers)"
              placeholder="Enter number"
              onBlur={handleBlur}
              onChange={(e) =>
                handleValidNumber(e) &&
                setFieldValue("numberOfDeveloper", e.target.value)
              }
            />
          </div>

          <div>
            <ATMTextField
              name="timeTaken"
              value={values.timeTaken}
              label="Time Taken (in minutes)"
              placeholder="Enter Time Taken"
              onBlur={handleBlur}
              onChange={(e) =>
                handleValidNumber(e) &&
                setFieldValue("timeTaken", e.target.value)
              }
            />
          </div>

          {/* Time Taken */}

          {/* Time Taken */}
          <div className="z-10">
            <MOLIssueOriginDropDown
              name="issueFrom"
              option={reasonOfBugOption}
              label="Issue of origin"
              placeholder="Select issue origin"
              value={values.issueFrom}
              onBlur={handleBlur}
              onChange={(newValue) => {
                setFieldValue("issueFrom", newValue?.value);
              }}
            />
          </div>

          <div className="z-10 -mt-1">
            <ATMMultiSelect
              name="responsibleMembers"
              value={values.responsibleMembers}
              options={userOptionsList}
              label="Responsible Members"
              placeholder="Select members"
              onChange={(newValue) => {
                setFieldValue("responsibleMembers", newValue);
              }}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 mt-4 z-0">
          {/* Remark */}
          <div className="z-0">
            <MOLMarkdownEditor
              label="Remark (this will shown to client)"
              value={values.remark}
              placeholder="Write remark or drag your files here...."
              onChange={(newValue: any) => {
                setFieldValue("remark", newValue);
              }}
            />
          </div>
          {/* Change Log */}
          <div className="z-0">
            <MOLMarkdownEditor
              label="Change Log"
              value={values.changeLog}
              placeholder="Write change log or drag your files here...."
              onChange={(newValue: any) => {
                setFieldValue("changeLog", newValue);
              }}
            />
          </div>
          {/* Reasone of bug */}
          <div className="z-0">
            <MOLMarkdownEditor
              label="Reason of bug"
              value={values.reasonOfBug}
              placeholder="Write reason of bug or drag your files here...."
              onChange={(newValue: any) => {
                setFieldValue("reasonOfBug", newValue);
              }}
            />
          </div>
        </div>
      </div>

      {/* Apply & Cancel Buttons */}
      <div className="flex flex-col gap-2 p-3 sticky bottom-0 bg-white">
        <div>
          <ATMLoadingButton
            type="button"
            isLoading={isSubmitting}
            className="h-[40px] bg-[#041618] font-medium"
            onClick={() => handleSubmit()}
          >
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default MarkAsDoneBugTypeModal;
