import { FormikProps } from "formik";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<any>;
  onClose: () => void;
};

export const clientSideTicketStatusOption = [
  {
    label: "OPEN",
    value: "OPEN",
  },
  {
    label: "CLOSE",
    value: "CLOSE",
  },
  {
    label: "IN PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "HOLD",
    value: "HOLD",
  },
  {
    label: "DOUBT",
    value: "DOUBT",
  },
  {
    label: "READY TO DEPLOY",
    value: "READY TO DEPLOY",
  },
];

const TicketStatusDialog = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <MOLFormDialog
      title={"Change Ticket Status"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <ATMSelect
        name="status"
        label="Ticket Status"
        placeholder="Select Ticket Status"
        value={values.status}
        options={clientSideTicketStatusOption}
        onChange={(newValue) => {
          setFieldValue("status", newValue);
        }}
      />
    </MOLFormDialog>
  );
};

export default TicketStatusDialog;
