import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setItems,
  setIsTableLoading,
  setTotalItems,
} from "src/redux/slices/ChannelGroupSlice";
import { RootState } from "src/redux/store";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import AddChannelGroupFormWrapper from "../Add/AddChannelGroupFormWrapper";
import EditChannelGroupFormWrapper from "../Edit/EditChannelGroupFormWrapper";
import ChannelGroupListing from "./ChannelGroupListing";
import {
  useDeleteChannelGroupMutation,
  useGetChannelGroupsQuery,
} from "src/services/ChannelGroupService";
import { showToast } from "src/utils/showToaster";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

const ChannelGroupListingWrapper = () => {
  const dispatch = useDispatch();
  const { isTableLoading, totalItems, page, rowsPerPage, searchValue, items } =
    useSelector((state: RootState) => state.channelGroup);
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [deleteChannelGroup] = useDeleteChannelGroupMutation();
  const { data, isLoading, isFetching } = useGetChannelGroupsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["channelGroupName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    } else {
      dispatch(setIsTableLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const getActionOptions = (groupId: string) => {
    return [
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdEdit color="#000000" /> Edit
          </div>
        ),
        onClick: () => {
          setIsOpenEditForm(true);
          setSelectedGroupId(groupId);
        },
        acitonName: UserModuleNameTypes.ACTION_CHANNEL_GROUP_EDIT,
      },
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdDelete color="#000000" /> Delete
          </div>
        ),
        onClick: () => {
          showConfirmationDialog({
            title: "Hands Up",
            text: "Are you sure want to delete this Channel Group?",
            icon: "question",
            showCancelButton: true,
            next: (result) => {
              if (result?.isConfirmed) {
                deleteChannelGroup(groupId).then((res: any) => {
                  if (res.error) {
                    showToast("error", res?.error?.data?.message);
                  } else {
                    if (res?.data?.status) {
                      showToast("success", res?.data?.message);
                    } else {
                      showToast("error", res?.data?.message);
                    }
                  }
                });
              }
            },
          });
        },
        acitonName: UserModuleNameTypes.ACTION_CHANNEL_GROUP_DELETE,
      },
    ];
  };

  const columns: columnTypes[] = [
    {
      field: "channelGroupName",
      headerName: "Channel Group Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      name: UserModuleNameTypes.CHANNEL_GROUP_LIST_CHANNEL_GROUP_NAME,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => {
        const options = getActionOptions(row?._id);
        return <ATMActionMenu options={options} orientation="vertical" />;
      },
    },
  ];
  return (
    <div>
      <ChannelGroupListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
        onAddNew={() => setIsOpenAddForm(true)}
      />
      {isOpenAddForm ? (
        <AddChannelGroupFormWrapper onClose={() => setIsOpenAddForm(false)} />
      ) : null}
      {isOpenEditForm ? (
        <EditChannelGroupFormWrapper
          onClose={() => setIsOpenEditForm(false)}
          groupId={selectedGroupId}
        />
      ) : null}
    </div>
  );
};

export default ChannelGroupListingWrapper;
