import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/TimeAnalyticsSlice";
import { RootState } from "src/redux/store";
import {
  useGetAllTimeAnalyticsWithPaginationQuery
} from "src/services/RequirementService";
import RequirementList from "./TimeAnalyticsList";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  setDateFilter,
  setProjectValueFilter
} from "src/redux/slices/TimeAnalyticsSlice";
type Props = {};

const TimeAnalyticsWrapper = (props: Props) => {
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading, dateFilter, projectFilterValue } = useSelector((state: RootState) => state.timeAnalytics);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectQueryId = queryParams.get('projectId');
  const projectQueryName = queryParams.get('projectName');
  const startDateQuery = queryParams.get('startDate');
  const endDateQuery = queryParams.get('endDate');

  const dispatch = useDispatch();

  const { data, isLoading, isFetching } =
    useGetAllTimeAnalyticsWithPaginationQuery({
      dateFilter: {
        startDate: dateFilter?.startDate ? moment(dateFilter.startDate)?.format("yyyy-MM-DD") : '',
        endDate: dateFilter?.endDate ? moment(dateFilter.endDate)?.format("yyyy-MM-DD") : dateFilter.startDate ? moment().endOf('month').format('YYYY-MM-DD') : '',
      },
      projectId: projectFilterValue?.value ? projectFilterValue?.value : null
    });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (projectQueryName && projectQueryId) {
      dispatch(setProjectValueFilter({
        label: projectQueryName,
        value: projectQueryId
      }))
      dispatch(setDateFilter({
        startDate: startDateQuery ? startDateQuery : '',
        endDate: endDateQuery ? endDateQuery : ''
      }))
    }

    return () => {
      dispatch(setProjectValueFilter(''));
      dispatch(setDateFilter({
        startDate: '',
        endDate: ''
      }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const taskColumns: columnTypes[] = [
    {
      field: "taskTitle",
      headerName: "Task",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[280px] max-w-[350px]",
      renderCell: (row: any) => (
        <>
          <Tooltip title={row?.taskTitle}>
            <div className="w-full overflow-hidden truncate text-md min-w-[100%]">
              {row?.taskTitle || '-'}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      field: "totalDuration",
      headerName: "Duration",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize ",
      renderCell: (row: any) => (
        <div className="flex gap-1">
          <span className="font-semibold">
            {`${Math?.floor(row?.totalDuration / 60)}:${(
              row?.totalDuration % 60
            )?.toFixed()}  hrs`}
          </span>

          <span className="text-sm text-neutral">
            ({`${row?.totalDuration?.toFixed()}`} min)
          </span>
        </div>
      ),
    },
  ];

  const meetingColumns: columnTypes[] = [
    {
      field: "title",
      headerName: "Meeting",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize capitalize min-w-[280px] max-w-[350px]",
      renderCell: (row: any) => (
        <>
          <Tooltip title={row?.title}>
            <div className="w-full overflow-hidden truncate text-md min-w-[100%]">
              {row?.title || '-'}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      field: "durationInMinutes",
      headerName: "Duration",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize ",
      renderCell: (row: any) => (
        <div className="flex gap-1">
          <span className="font-semibold">
            {`${Math?.floor(row?.durationInMinutes / 60)}:${(
              row?.durationInMinutes % 60
            )?.toFixed()}  hrs`}
          </span>

          <span className="text-sm text-neutral">
            ({`${row?.durationInMinutes?.toFixed()}`} min)
          </span>
        </div>
      ),
    },

  ];
  return (
    <div>
      <RequirementList
        rows={items}
        taskColumns={taskColumns}
        meetingColumns={meetingColumns}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default TimeAnalyticsWrapper;
