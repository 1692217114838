import apiSlice from "./ApiSlice";

export const countryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    getAllCounrties: builder.query({
      providesTags: ["country"],
      query: () => {
        return {
          url: "/country",
          method: "GET",
        };
      },
    }),

    // GET
    getCounrties: builder.query({
      providesTags: ["country"],
      query: (body) => {
        return {
          url: "/country",
          method: "POST",
          body,
        };
      },
    }),

    // ADD COUNTRY
    addCountry: builder.mutation({
      invalidatesTags: ["country"],
      query: (body) => ({
        url: "/country/add",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllCounrtiesQuery,
  useGetCounrtiesQuery,
  useAddCountryMutation,
} = countryApi;
