import apiSlice from "./ApiSlice";

export const leaveRequestApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get All
    getAllLeaveRequestWithPagination: builder.query({
      providesTags: ["leave-request"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/leave-request",
          method: "POST",
          body,
        };
      },
    }),

    // Get
    getModules: builder.query({
      providesTags: ["modules"],
      query: (body) => {
        return {
          url: "/module",
          method: "POST",
          body,
        };
      },
    }),

    // Get Module by Id
    getLeaveRequestById: builder.query({
      providesTags: ["leave-request"],
      query: (id) => {
        return {
          url: `/leave-request/${id}`,
          method: "GET",
        };
      },
    }),

    // Add
    addLeaveRequestService: builder.mutation({
      invalidatesTags: ["leave-request"],
      query: (body) => {
        return {
          url: "/leave-request/add",
          method: "POST",
          body,
        };
      },
    }),

    // Edit
    editModule: builder.mutation({
      invalidatesTags: ["modules"],
      query: ({ id, body }) => {
        return {
          url: `/module/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Get modile by project id
    getModulesByProjectId: builder.query({
      providesTags: ["modules"],
      query: (pid) => {
        return {
          url: `module/project/${pid}`,
          method: "GET",
        };
      },
    }),

    //change approval status
    updateLeaveRequestApprovalStatus: builder.mutation({
      invalidatesTags: ["leave-request"],
      query: ({ id, body }) => {
        return {
          url: `leave-request/approvals/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //delete leave Request
    deleteLeaveRequest: builder.mutation({
      invalidatesTags: ["leave-request"],
      query: (id: string) => {
        return {
          url: `leave-request/${id}`,
          method: "DELETE",
        };
      },
    }),

    //update leave request
    updateLeaveRequest: builder.mutation({
      invalidatesTags: ["leave-request"],
      query: ({ id, body }) => {
        return {
          url: `leave-request/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //update leave request
    updateAdminLeaveRequest: builder.mutation({
      invalidatesTags: ["leave-request"],
      query: ({ id, body }) => {
        return {
          url: `leave-request/${id}/day-of-leave`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllLeaveRequestWithPaginationQuery,
  useGetModulesQuery,
  useGetLeaveRequestByIdQuery,
  useAddLeaveRequestServiceMutation,
  useEditModuleMutation,
  useGetModulesByProjectIdQuery,
  useUpdateLeaveRequestApprovalStatusMutation,
  useDeleteLeaveRequestMutation,
  useUpdateLeaveRequestMutation,
  useUpdateAdminLeaveRequestMutation
} = leaveRequestApi;
