import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import TicketIssuesForm from "../Layouts/TicketIssuesForm";
import { useParams } from "react-router-dom";
import { useUpdateTicketIssueByIdMutation } from "src/services/TicketIssuesService";
import { addTicketIssuesFormWrapperPropType } from "../Add/AddTicketIssuesFormWrapper";
import { useGetTicketIssueByIdQuery } from "src/services/TicketIssuesService";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  meetingOptions: any;
  rowId: string;
};

const EditTicketIssuesFormWrapper = ({
  onClose,
  meetingOptions,
  rowId,
}: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState(true);
  const [ticketIssues, setTicketIssues] = useState<any>();

  const { data, isLoading, isFetching } = useGetTicketIssueByIdQuery(rowId);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTicketIssues(data?.data?.[0]);
    }
  }, [data, isLoading, isFetching]);

  const [addTicketIssue] = useUpdateTicketIssueByIdMutation();

  // Get the Ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  // Form Initial Values
  const initialValues: addTicketIssuesFormWrapperPropType = {
    Issue: ticketIssues?.title || "",
    meetings:
      {
        label: ticketIssues?.sourceName,
        value: ticketIssues?.sourceId,
      } || "",
  };
  // Validation Schema
  const validationSchema = object().shape({
    Issue: string().required("Please enter Issue"),
  });

  // Handle Submit
  const handleSubmit = (
    values: addTicketIssuesFormWrapperPropType,
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<addTicketIssuesFormWrapperPropType>
  ) => {
    const formattedValues = {
      title: values?.Issue,
      sourceId: values?.meetings?.value,
      originId: TICKET_ID,
    };

    addTicketIssue({
      id: rowId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <TicketIssuesForm
            formikProps={formikProps}
            onClose={onClose}
            formType="UPDATE"
            saveNextChecked={saveNextChecked}
            setSaveNextChecked={setSaveNextChecked}
            meetingOptions={meetingOptions}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditTicketIssuesFormWrapper;
