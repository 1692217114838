import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useAddDepartmentMutation } from "src/services/DepartmentService";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import DepartmentForm from "../Layouts/DepartmentForm";
import { DepartmentFormValues } from "src/models/department.model";

type Props = {
  onClose: () => void;
};

const AddDepartmentFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addDepartment] = useAddDepartmentMutation();

  // Form Initial Values
  const initialValues: DepartmentFormValues = {
    repoName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    repoName: string().required("Please enter department name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DepartmentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DepartmentFormValues>
  ) => {
    const formattedValues = {
      departmentName: values.repoName,
    };

    addDepartment(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <DepartmentForm
            formikProps={formikProps}
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddDepartmentFormWrapper;
