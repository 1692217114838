import { FormikProps } from "formik";
import moment from "moment";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { ClipLoader } from "react-spinners";
import ATMAmountField from "src/components/UI/atoms/ATMAmountField/ATMAmountField";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMSwitchButton from "src/components/UI/atoms/formFields/ATMSwitchButton/ATMSwitchButton";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { ExpenseFormValues } from "src/models/Expense.model";
import { useGetFinanceAccountQuery } from "src/services/Finance-New-Services/AccountServices";
import { useGetFinanceCategoryQuery } from "src/services/Finance-New-Services/FinanceCategroyService";
import { useGetAllPaymentModesQuery } from "src/services/Finance-New-Services/PaymentModeServices";
import useFileUploader from "src/utils/useFileUploader";
import {
  handleValidPercent,
  handleValidWithDotNumber,
} from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<ExpenseFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const ExpenseForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const formHeading = formType === "ADD" ? "Add Expense" : "Update Expense";
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const { data: getAccount, isLoading: isAccountLoading } =
    useGetFinanceAccountQuery("");
  const { data: getpaymentMode, isLoading: isPaymentModeLoading } =
    useGetAllPaymentModesQuery("");
  const { data: getCategory, isLoading: isCategoryLoading } =
    useGetFinanceCategoryQuery("");

  const { initiateUpload, uploadedFile } = useFileUploader({
    next: (file: any) =>
      setFieldValue("attachments", [
        ...values?.attachments,
        {
          url: file?.fileUrl,
          type: file?.fileType,
          name: file?.fileTitle,
        },
      ]),
    accept: ".jpg, .jpeg, .png,  .mp4, .xls, .xlsx, .pdf, .webp, .webm",
  });

  return (
    <div className="flex flex-col gap-6 p-3">
      <div className="py-2 border-b">
        <div className="flex items-center justify-between w-[70%] m-auto">
          <div className="text-xl font-medium"> {formHeading} </div>
          <div className="flex gap-2">
            <div>
              <ATMLoadingButton
                onClick={() => onClose()}
                type="button"
                className="text-black bg-white"
              >
                Cancel
              </ATMLoadingButton>
            </div>
            <div>
              <ATMLoadingButton isLoading={isSubmitting} type="submit">
                Save
              </ATMLoadingButton>
            </div>
          </div>
        </div>
      </div>
      <div className="grid items-center grid-cols-2 xl:grid-cols-3 gap-4 w-[70%] m-auto py-4">
        {/* Title */}
        <div>
          <ATMTextField
            required
            name="expenseName"
            value={values.expenseName}
            onChange={(e) => setFieldValue("expenseName", e.target.value)}
            label="Title"
            placeholder="Enter Expense Name"
            onBlur={handleBlur}
          />
        </div>
        {/* Income Date */}
        <div>
          <ATMDatePicker
            required
            label="Expense Date"
            name="expenseDate"
            format="DD/MM/YYYY"
            value={values.expenseDate}
            onChange={(newValue) => setFieldValue("expenseDate", newValue)}
          />
        </div>
        {/* Category */}
        <div>
          <ATMSelect
            name="category"
            label="Category"
            required
            placeholder="Select Category"
            value={values.category}
            options={getCategory?.data?.map((ele: any) => {
              return {
                label: ele?.categoryName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("category", newValue);
            }}
            isLoading={isCategoryLoading}
          />
        </div>
        {/* Amount (Incl. TDS) */}
        <div>
          <ATMAmountField
            required
            name="amount"
            value={values.amount}
            onChange={(e) =>
              handleValidWithDotNumber(e) &&
              setFieldValue("amount", e.target.value)
            }
            onCurrencyChange={(newValue) => {
              setFieldValue("currency", newValue);
            }}
            currencyValue={values.currency}
            label=" Amount (Incl. TDS) "
            placeholder="Enter  Amount (Incl. TDS) "
            onBlur={handleBlur}
          />
        </div>
        {/* TDS Amount */}
        <div>
          <ATMTextField
            name="tdsAmount"
            value={values.tdsAmount}
            onChange={(e) =>
              handleValidWithDotNumber(e, { acceptPercent: false }) &&
              setFieldValue("tdsAmount", e.target.value)
            }
            label="TDS Amount"
            placeholder="Enter TDS Amount"
            onBlur={handleBlur}
          />
        </div>
        {/* GST Applicable */}
        <div className="flex items-end justify-between">
          <ATMSwitchButton
            name="gstApplicable"
            value={values.gstApplicable}
            onChange={(newValue) => {
              if (!newValue) {
                setFieldValue("gstPercentage", "");
              }
              return setFieldValue("gstApplicable", newValue);
            }}
            label="GST Applicable"
          />
          {values?.amount && (
            <div>
              {values?.currency?.currencySymbol}{" "}
              {((parseInt(values?.amount) -
                (parseInt(values?.tdsAmount) || 0)) *
                ((parseInt(values?.gstPercentage) || 0) + 100)) /
                100 +
                (parseInt(values?.tdsAmount) || 0)}
            </div>
          )}
        </div>
        {/* GST Percentage */}
        {values?.gstApplicable ? (
          <div>
            <ATMTextField
              required
              name="gstPercentage"
              value={values.gstPercentage}
              onChange={(e) =>
                handleValidPercent(e) &&
                setFieldValue("gstPercentage", e.target.value)
              }
              label="GST Percentage"
              placeholder="Enter GST Percentage"
              onBlur={handleBlur}
            />
          </div>
        ) : null}
        {/* Status */}
        <div>
          <div>Status</div>
          <div className="flex items-center gap-2 text-center">
            <div
              className={`py-1 px-4 w-[100px] border rounded-lg cursor-pointer ${values?.status === "PAID" ? "bg-green-600 text-white" : ""}`}
              onClick={() => {
                setFieldValue("status", "PAID");
                setFieldValue("dueDate", null);
              }}
            >
              Paid
            </div>
            <div
              className={`py-1 px-4  w-[100px] border rounded-lg cursor-pointer ${values?.status === "UNPAID" ? "bg-red-600 text-white" : ""}`}
              onClick={() => {
                setFieldValue("status", "UNPAID");
                setFieldValue("paymentDate", null);
              }}
            >
              UnPaid
            </div>
          </div>
        </div>
        {/* Due Date */}
        {values?.status === "UNPAID" ? (
          <div>
            <ATMDatePicker
              required
              label="Due Date"
              name="dueDate"
              format="DD/MM/YYYY"
              minDate={moment().format("yyyy-MM-DD")}
              value={values.dueDate}
              onChange={(newValue) => setFieldValue("dueDate", newValue)}
            />
          </div>
        ) : null}
        {/* Payment Date */}
        {values?.status === "PAID" ? (
          <div>
            <ATMDatePicker
              required
              label="Payment Date"
              name="paymentDate"
              format="DD/MM/YYYY"
              maxDate={moment().format("yyyy-MM-DD")}
              value={values.paymentDate}
              onChange={(newValue) => setFieldValue("paymentDate", newValue)}
            />
          </div>
        ) : null}
        {/* Account */}
        <div>
          <ATMSelect
            name="account"
            label="Account"
            required
            placeholder="Select Account"
            value={values.account}
            options={getAccount?.data?.map((ele: any) => {
              return {
                label: ele?.accountName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("account", newValue);
            }}
            isLoading={isAccountLoading}
          />
        </div>
        {/* Mode */}
        <div>
          <ATMSelect
            name="modeName"
            label="Mode"
            required
            placeholder="Select Mode"
            value={values.modeName}
            options={getpaymentMode?.data?.map((ele: any) => {
              return {
                label: ele?.modeName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("modeName", newValue);
            }}
            isLoading={isPaymentModeLoading}
          />
        </div>
        {/* Warranty Expiry Date */}
        <div>
          <ATMDatePicker
            label="Warranty Expiry Date"
            name="warrantyExpiryDate"
            format="DD/MM/YYYY"
            minDate={moment().format("yyyy-MM-DD")}
            value={values.warrantyExpiryDate}
            onChange={(newValue) =>
              setFieldValue("warrantyExpiryDate", newValue)
            }
          />
        </div>
      </div>
      {/* Description */}
      <div className="w-[70%] m-auto">
        <ATMTextArea
          name="description"
          value={values.description}
          onChange={(e) => setFieldValue("description", e)}
          label="Description"
          placeholder="Enter Description"
        />
      </div>
      {/* File Uploader */}
      <div className="w-[70%] m-auto">
        {values?.attachments?.length ? (
          <div className="grid grid-cols-4 gap-4 pb-10 md:grid-cols-6">
            {values?.attachments?.map((item: any, index: any) => {
              return (
                <div className="h-[200px]" key={index}>
                  <ATMRenderFile
                    fileInfo={item}
                    isShowCancel={true}
                    onCancelClick={() => {
                      const attachmentInfo = [...values?.attachments];
                      attachmentInfo?.splice(index, 1);
                      setFieldValue("attachments", attachmentInfo);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className="flex justify-end  w-[70%] m-auto">
        <button
          className="flex items-center gap-1 p-2 font-bold border rounded text-primary-main"
          type="button"
          onClick={initiateUpload}
          disabled={uploadedFile ? true : false}
        >
          {uploadedFile ? (
            <ClipLoader
              color={"teal"}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <HiOutlinePlusSmall size={20} />
          )}
          Add Attachments
        </button>
      </div>
    </div>
  );
};

export default ExpenseForm;
