import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { TaskListResponse } from "src/models/Task.model";
import {
  //   setPage,
  //   setRowsPerPage,
  setIsTableLoading,
} from "src/redux/slices/AllTaskSlice";
import { RootState } from "src/redux/store";
import {
  useGetAllTaskWithPaginationQuery,
  useGetTaskByIdQuery,
  useGetTimeReportOfTaskQuery,
} from "src/services/TaskService";
import SingleTaskView from "./SingleTaskView";
import TaskSideBarLayout from "./TaskSideBarLayout/TaskSideBarLayout";

const SingleTaskViewWrapper = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [singleTaskData, setSingleTaskData] = useState<TaskListResponse>();
  const [singleTaskLog, setSingleTaskLog] = useState<any>();
  const { id: TASK_ID } = useParams();
  const { state } = useLocation();

  // Access the state values and pathname through project id
  const search: any = state?.search;
  const pathname: any = state?.pathname;
  const searchParams = new URLSearchParams(search);

  // routing projectId , moduleId , featureId through inside the project task
  const routeProjectId = pathname?.split("/")?.[2]
    ? pathname?.split("/")?.[2]
    : null;
  const routeModuleId = searchParams?.get("moduleId");
  const routeFeatureId = searchParams?.get("featureId");

  // Get Single Ticket Details
  const {
    isLoading: isTicketDetailsLoading,
    isFetching: isTicketDetailsFetching,
    data: ticketData,
  } = useGetTaskByIdQuery(TASK_ID || "", {
    skip: !TASK_ID,
  });
  // Set Single Ticket Details
  useEffect(() => {
    if (!isTicketDetailsLoading && !isTicketDetailsFetching) {
      setSingleTaskData(ticketData?.data);
    }
  }, [isTicketDetailsLoading, isTicketDetailsFetching, ticketData]);

  const {
    // items,
    // totalItems,
    // page,
    // rowsPerPage,
    // isTableLoading,
    userFilterValue,
    taskStatus,
    createdByValue,
    dueDateFilterValue,
    projectFilterValue,
    moduleFilterValue,
    featureFilterValue,
    LabelFilterValue,
    layerFilterValue,
    taskAssignmentStatus,
    isArchived
  } = useSelector((state: RootState) => state.allTask);

  const [setsideBarTicketsList, setsetsideBarTicketsList] = useState<
    TaskListResponse[]
  >([]);

  const [sidebarListTotalItems, setSidebarListTotalItems] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useGetAllTaskWithPaginationQuery({
    limit: limit,
    searchValue: searchValue,
    params: ["title"],
    page: 1,
    filterBy: [
      {
        fieldName: "assignedTo",
        value: userFilterValue?.value || "",
      },
      {
        fieldName: "status",
        value: taskStatus?.value || "",
      },
      {
        fieldName: "createdBy",
        value: createdByValue?.value || "",
      },
      {
        fieldName: "dueDate",
        value: dueDateFilterValue || "",
      },
      {
        fieldName: "projectId",
        value:
          routeProjectId !== null
            ? routeProjectId
            : projectFilterValue?.value || "",
      },
      {
        fieldName: "moduleId",
        value:
          routeModuleId !== null
            ? routeModuleId
            : moduleFilterValue?.value || "",
      },
      {
        fieldName: "featureId",
        value:
          routeFeatureId !== null
            ? routeFeatureId
            : featureFilterValue?.value || "",
      },
      {
        fieldName: "label.labelName",
        value: LabelFilterValue?.label || "",
      },
      {
        fieldName: "layer.layerName",
        value: layerFilterValue?.label || "",
      },
      {
        fieldName: "stage",
        value: taskAssignmentStatus?.value || "",
      },
      {
        fieldName: "isArchived",
        value: isArchived
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      setsetsideBarTicketsList(data?.data);
      setSidebarListTotalItems(data?.totalItem || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const {
    isLoading: taskLogIsLoading,
    isFetching: taskLogIsFetching,
    data: taskLogData,
  } = useGetTimeReportOfTaskQuery(TASK_ID || "", {
    skip: !TASK_ID,
  });

  // Set Single Ticket Details
  useEffect(() => {
    if (!taskLogIsLoading && !taskLogIsFetching) {
      setSingleTaskLog(taskLogData?.data);
    }
  }, [taskLogIsLoading, taskLogIsFetching, taskLogData]);
  const afterCloseTicket = () => {
    if (setsideBarTicketsList?.length > 1) {
      const taskIdIndex = setsideBarTicketsList?.findIndex(
        (ticketList) => ticketList?._id === TASK_ID
      );
      const nextTaskId =
        setsideBarTicketsList?.length === taskIdIndex + 1
          ? setsideBarTicketsList[0]?._id
          : setsideBarTicketsList[taskIdIndex + 1]?._id;
      nextTaskId
        ? navigate(`/task/${nextTaskId}`, {
            state: {
              pathname,
              search,
            },
          })
        : navigate("/task");
    } else {
      navigate("/task");
    }
  };
  return (
    <>
      <SideNavLayout>
        <TaskSideBarLayout
          hideViewMore={sidebarListTotalItems > limit ? true : false}
          listData={setsideBarTicketsList}
          searchValue={searchValue}
          onSearch={(value) => setSearchValue(value)}
          onClickViewMore={() => setLimit(limit + 20)}
          onClickTicket={(taskId) =>
            state
              ? navigate(`/task/${taskId}`, { state })
              : navigate(`/task/${taskId}`)
          }
        >
          <SingleTaskView
            singleTaskData={singleTaskData}
            isSingleTicketLoading={isLoading}
            singleTaskLog={singleTaskLog}
            afterCloseTicket={afterCloseTicket}
          />
        </TaskSideBarLayout>
      </SideNavLayout>
    </>
  );
};

export default SingleTaskViewWrapper;
