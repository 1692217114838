import React, { useState } from "react";
import BoardLayout from "../Components/BoardLayout";
import { IoFilter } from "react-icons/io5";
import CompleteTaskFilterFormDialogWrapper from "./HolidayFilterFormCard/CompleteTaskFilterFormDialogWrapper";
import { CircularProgress } from "@mui/material";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

type Props = {
  rows: any[];
  onClick?: (taskId: string, ticketId: string) => void;
  paginationProps: {
    isTableLoading: boolean;
    searchValue: string;
    setSearchValue: (newValue: string) => void;
  };
};

const ListCompleteTask = ({
  rows,
  onClick,
  paginationProps: { isTableLoading, searchValue, setSearchValue },
}: Props) => {
  const [isOpenTicketFilterDialog, setIsOpenTicketFilterDialog] =
    useState<boolean>(false);
  return (
    <div className="flex flex-col flex-1 h-full overflow-hidden">
      {isTableLoading ? (
        <div className="flex justify-center items-center h-[400px] w-full">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="mx-2 p-2">
            {/* Filter Button */}
            <ATMLoadingButton
              variant="tonal"
              onClick={() => setIsOpenTicketFilterDialog(true)}
            >
              <div className="flex items-center gap-2">
                <IoFilter />
                Filters
              </div>
            </ATMLoadingButton>
            {/* Ticket Filter Dialog Wrapper */}
            <CompleteTaskFilterFormDialogWrapper
              open={isOpenTicketFilterDialog}
              onClose={() => setIsOpenTicketFilterDialog(false)}
            />
          </div>
          <div className="overflow-auto h-full flex flex-1  ">
            <div className="flex gap-6 overflow-auto flex-1  h-full w-full md:p-4 p-2 py-1 ">
              {rows?.map((ele: any) => {
                return (
                  <BoardLayout
                    key={ele?._id}
                    boardTitle={ele?.name}
                    activeTab={"COMPLETE"}
                    listData={ele?.tasks || []}
                    MeetingData={ele?.meetings || []}
                    onClick={onClick}
                    taskStatusLabelCounter={undefined}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ListCompleteTask;
