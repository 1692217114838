/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  setAcountFilter,
  setCategoryFilter,
  setDateFilter,
  setPaymentModeFilter,
  setTransactionTypeFilter,
} from "src/redux/slices/Finance-New-Slices/CashFlowSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { object } from "yup";
import TicketListFilterFormDialog from "./CaseFlowListFilterFormDialog";
import moment from "moment";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  transactionType: any;
  mode: any;
  category: any;
  acountType: any;
  startDate: null | string;
  endDate: null | string;
};

const CaseFlowListFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { acountFilter, transactionType, categoryFilter, paymentModeFilter } =
    useSelector((state: RootState) => state.cashFlow);

  const initialValues: FormInitialValues = {
    transactionType: transactionType,
    acountType: acountFilter,
    mode: paymentModeFilter,
    category: categoryFilter,
    startDate: null,
    endDate: null,
  };

  const validationSchema: any = object({});
  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);
    // created date from to dispatch
    dispatch(
      setDateFilter({
        startDate: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        endDate: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
        dateFilterKey: "paymentDate",
      })
    );
    // ticket type  dispatch
    dispatch(setAcountFilter(values?.acountType));
    dispatch(setCategoryFilter(values?.category));
    dispatch(setTransactionTypeFilter(values?.transactionType));
    dispatch(setPaymentModeFilter(values?.mode));

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        startDate: "",
        endDate: "",
      })
    );
    //ticket filter By change Request
    await dispatch(setAcountFilter([]));
    await dispatch(setCategoryFilter([]));
    await dispatch(setTransactionTypeFilter([]));
    await dispatch(setPaymentModeFilter([]));

    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <TicketListFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CaseFlowListFilterFormDialogWrapper;
