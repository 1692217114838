import React from 'react'
import EditTeamAttendance from './EditTeamAttendance'
import {  Dialog } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { useDeleteAttendanceMutation, useEditAttendanceMutation } from 'src/services/AttendanceService';
import { showToast } from 'src/utils/showToaster';
import moment from 'moment';
import { object } from 'yup';

type Props = {
  onClose: () => void;
  attendanceData: any
  memberid: any
};


const EditTeamAttendanceWrapper = ({ onClose, attendanceData, memberid }: Props) => {

  const lastAttendance = attendanceData?.attendance?.[attendanceData?.attendance?.length - 1];
  const punchOutTime = lastAttendance ? lastAttendance.punchOutTime : "";

  
  const [EditAttendance] = useEditAttendanceMutation()
  const [MarkAbsent] = useDeleteAttendanceMutation()


  // Form Initial Values
  const initialValues: any = {
    date: attendanceData?.date || "",
    attendanceType: attendanceData?.attendance?.length ?  {label : "PRESENT", value : "PRESENT"} : {label : "ABSENT", value : "ABSENT"} ,
    punchInTime: attendanceData?.attendance[0]?.punchInTime || "",
    punchOutTime: punchOutTime,
    teamMemberId: memberid,
    workingFrom: lastAttendance ? { label: lastAttendance?.workingFrom, value: lastAttendance?.workingFrom } : "",
  };


  // Validation Schema
  const validationSchema = object().shape({
    attendanceType: object().required("Please select attendance type?"),
    workingFrom: object().required("Please select working from?"),
    // punchInTime: string().test(
    //   "punchintimetime",
    //   "Please select punch in time",
    //   (value, context) => {
    //     const { attendanceType } = context.parent;
    //     return attendanceType?.value === "PRESENT" && !value;
    //   }
    // ),

    // punchOutTime: string().test(
    //   "punOutTime",
    //   "Please select punch out time",
    //   (value, context) => {
    //     const { attendanceType } = context.parent;
    //     return attendanceType?.value === "PRESENT" && !value;
    //   }
    // ),
    
  });


  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    const { workingFrom, punchOutTime, punchInTime, date, attendanceType, ...rest } = values;
    const formattedInTime = moment(punchInTime).format("HH:mm:ss")
    const formattedOutTime = moment(punchOutTime).format("HH:mm:ss")
  
    const PunchInTime = date + ' ' + formattedInTime;
    const PunchOutTime = date + ' ' + formattedOutTime;
  
    const formattedValues = {
      workingFrom: workingFrom?.value,
      punchInTime:  punchInTime ? PunchInTime : "",
      punchOutTime:  punchOutTime ? PunchOutTime : "",
      date: date,
      ...rest,
    };
  
    attendanceType?.value === "PRESENT" ? 
      EditAttendance(formattedValues).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      })
    : 
      MarkAbsent({ teammemberid: memberid, body:{ date : date} }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
  };
  

  return (
    <>
      <Dialog open maxWidth="md" fullWidth>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              <EditTeamAttendance formikProps={formikProps}
                onClose={onClose} />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default EditTeamAttendanceWrapper
