import React from "react";

type Props = {
  heading: string;
};

const ATMFormSectionHeading = ({ heading }: Props) => {
  return <div className="border-b text-lg font-medium py-2 text-slate-700" >{heading}</div>;
};

export default ATMFormSectionHeading;
