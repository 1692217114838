import apiSlice from "./ApiSlice";

export const EstimationCalculcatorApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //get EstimationCalculcator List
    getEstimationCalculatorData: builder.query({
      providesTags: ["est-list"],
      query: () => {
        return {
          url: "est-calculator",
          method: "GET",
        };
      },
    }),

    // getOne by Id
    getone: builder.query({
      providesTags: ["est-list"],
      query: (id) => {
        return {
          url: `est-calculator/${id}`,
          method: "GET",
        };
      },
    }),
    // add
    addEstimationCalculation: builder.mutation({
      invalidatesTags: ["est-list"],
      query: (body) => {
        return {
          url: "est-calculator/add",
          method: "POST",
          body,
        };
      },
    }),

    // upDate
    updateOne: builder.mutation({
      invalidatesTags: ["est-list"],
      query: ({ body, id }) => {
        return {
          url: `est-calculator/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetEstimationCalculatorDataQuery,
  useAddEstimationCalculationMutation,
  useGetoneQuery,
  useUpdateOneMutation,
} = EstimationCalculcatorApi;
