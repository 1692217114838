import { useEffect } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/JobCreationStageSlice";
import { RootState } from "src/redux/store";
import {
  useDeleteJobCreationStageMutation,
  useGetAllJobCreationStageQuery,
} from "src/services/JobsCreationStageService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import JobCreationStageListing from "./JobCreationStageListing";

type Props = {};

const JobCreationStageListingWrapper = (props: Props) => {
  const { id: jobId } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.jobCreationStage);

  const [deleteStage] = useDeleteJobCreationStageMutation();

  const { data, isLoading, isFetching } = useGetAllJobCreationStageQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["stageName"],
    page: page,
    filterBy: [
      {
        fieldName: "jobId",
        value: [jobId || ""],
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });
  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);
  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Stage?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteStage(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };


  const columns: columnTypes[] = [
    { field: "stageName", headerName: "Stage", flex: "flex-[1_1_0%]" },
    { field: "order", headerName: "Order", flex: "flex-[1_1_0%]" },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  navigate(`update/${row?._id}`)
                },
              },
              {
                label: (
                  <div className="flex items-center gap-x-2 text-[#991b1b] font-medium">
                    <MdDelete color="#991b1b" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                // acitonName: UserModuleNameTypes.ACTION_GOAL_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <JobCreationStageListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

    </div>
  );
};

export default JobCreationStageListingWrapper;
