import React, { useEffect } from "react";
import TaskListing from "./TaskListing";
import { useDispatch, useSelector } from "react-redux";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setTotalItems,
  setItems,
} from "src/redux/slices/TaskSlice";
import { RootState } from "src/redux/store";
import { useGetAllTaskWithPaginationQuery } from "src/services/TaskService";

const TaskListingWrapper = () => {
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    tasksearchValue,
    isTableLoading,
    userFilterValue,
    taskStatus,
    dueDateFilterValue,
    projectFilterValue,
    moduleFilterValue,
    featureFilterValue,
    labelFilterValue,
    isTaskArchived
  } = useSelector((state: RootState) => state.task);
  const { singleItem } = useSelector((state: RootState) => state.ticket);

  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useGetAllTaskWithPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue || tasksearchValue,
    params: ["title"],
    page: page,
    filterBy: [
      {
        fieldName: "ticketId",
        value: singleItem[0]?._id,
      },
      {
        fieldName: "assignedTo",
        value: userFilterValue?.value || "",
      },
      {
        fieldName: "status",
        value: taskStatus?.value || "",
      },
      {
        fieldName: "dueDate",
        value: dueDateFilterValue || "",
      },
      {
        fieldName: "projectId",
        value: projectFilterValue?.value || "",
      },
      {
        fieldName: "moduleId",
        value: moduleFilterValue?.value || "",
      },
      {
        fieldName: "featureId",
        value: featureFilterValue?.value || "",
      },
      {
        fieldName: "label.labelName",
        value: labelFilterValue?.label || "",
      },
      {
        fieldName: "isArchived",
        value: isTaskArchived,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <>
      <TaskListing
        rows={items}
        singleItem={singleItem}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </>
  );
};

export default TaskListingWrapper;
