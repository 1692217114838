import apiSlice from "./ApiSlice";

export const subscriptionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllSubscription: builder.query({
      providesTags: ["subscription"],
      query: () => {
        return {
          url: "/subscription",
          method: "GET",
        };
      },
    }),
    // Get subscription with pagination
    getSubscriptions: builder.query({
      providesTags: ["subscription"],
      query: (body) => {
        return {
          url: "/subscription",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addSubscription: builder.mutation({
      invalidatesTags: ["subscription"],
      query: (body) => {
        return {
          url: "/subscription/add",
          method: "POST",
          body,
        };
      },
    }),

    deleteSubscription: builder.mutation({
      invalidatesTags: ["subscription"],
      query: (id: any) => ({
        url: `/subscription/${id}`,
        method: "DELETE",
      }),
    }),


    //Edit
    updateSubscription: builder.mutation({
      invalidatesTags: ["subscription"],
      query: ({ id, body }) => {
        return {
          url: `/subscription/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get subscription by ID
    getSubscriptionById: builder.query({
      providesTags: ["subscription"],
      query: (id) => {
        return {
          url: `/subscription/${id}`,
          method: "GET",
        };
      },
    }),

    // Get Recurring Payment with pagination
    getRecurringPayments: builder.query({
      providesTags: ["recurring-payments"],
      query: (body) => {
        return {
          url: "/recurring-payment",
          method: "POST",
          body,
        };
      },
    }),

    //Add Recurring Payment
    addRecurringPayments: builder.mutation({
      invalidatesTags: ["recurring-payments"],
      query: (body) => {
        return {
          url: "/recurring-payment/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get Upcoming Payments with pagination
    getAllUpcomingPaymentPagination: builder.query({
      providesTags: ["subscription"],
      query: (body) => {
        return {
          url: "/upcoming-payment",
          method: "POST",
          body,
        };
      },
    }),
  }),
});
export const {
  useGetAllSubscriptionQuery,
  useGetSubscriptionsQuery,
  useAddSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetSubscriptionByIdQuery,
  useGetRecurringPaymentsQuery,
  useAddRecurringPaymentsMutation,
  useGetAllUpcomingPaymentPaginationQuery
} = subscriptionApi;
