import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setRowsPerPage,
  setTotalItems,
  setSearchValue,
  setPage,
} from "src/redux/slices/AttendanceSlice";
import { RootState } from "src/redux/store";
import {
  useAttendanceUpdateMutation,
  useGetAllAttendanceListingQuery,
} from "src/services/AttendanceService";
import AttendanceListing from "./AttendanceListing";
import { AttendanceListResponse } from "src/models/Attendance.model";
import moment from "moment";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";

const attendanceOptions = [
  {
    label: "OFFICE",
    value: "OFFICE",
  },
  {
    label: "HOME",
    value: "HOME",
  },
  {
    label: "LEAVE",
    value: "LEAVE",
  },
];

const AttendanceListingWrapper = () => {
  const dispatch = useDispatch();

  const { userData } = useGetLocalStorage();

  const [UpdateAttendance] = useAttendanceUpdateMutation();

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    dateFilter,
    // selectedClient,
  } = useSelector((state: RootState) => state.attendance);

  const { data, isLoading, isFetching } = useGetAllAttendanceListingQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["teamMemberId", "date"],
    page: page,
    filterBy: [],
    dateFilter: {
      startDate: dateFilter?.startDate ? dateFilter?.startDate : "",
      endDate: dateFilter?.endDate ? dateFilter?.endDate : "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "date",
      headerName: "Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => (
        <div className="text-[13px] text-slate-700 font-medium">
          {row?.date ? moment(row?.date).format("DD MMM YYYY") : ""}
        </div>
      ),
    },
    // {
    //   field: "isPresent",
    //   headerName: "Is-Present",
    //   flex: "flex-[1_1_0%]",
    //   extraClasses: "min-w-[100px]",
    //   renderCell: (row: AttendanceListResponse) => (
    //     <div className="text-[15px] font-medium">
    //       {row?.punchInTime ? (
    //         <span className="text-green-500">Yes</span>
    //       ) : (
    //         "No"
    //       )}
    //     </div>
    //   ),
    // },
    {
      field: "teamMemberName",
      headerName: "Member Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      // name: UserModuleNameTypes.ATTENDANCE_LIST_TEAM_MEMBER_NAME,
      renderCell: (row: AttendanceListResponse) => (
        <div> {row?.teamMemberName} </div>
      ),
    },

    {
      field: "workingFrom",
      headerName: "Working From",
      flex: "flex-[1_1_0%]",
      align: "center",
      extraClasses: "min-w-[270px] ",
      // name: UserModuleNameTypes.ATTENDANCE_LIST_WORKING_FROM,
      renderCell: (row: AttendanceListResponse) => (
        <div className="py-1">
          {userData?.userType === "SUPER_ADMIN" ? (
             <ATMSelect
              name=""
              extraClasses="w-[50px]"
              value={
                {
                  label: row?.workingFrom,
                  value: row?.workingFrom,
                } || "-"
              }
              options={attendanceOptions}
              onChange={(e) => {
                UpdateAttendance({
                  id: row?._id,
                  body: {
                    workingFrom: e?.value,
                  },
                });
              }}
            />
          ) : (
            <p className="text-md">{row?.workingFrom} </p>
          )}
        </div>
      ),
    },
    {
      field: "punchInTime",
      headerName: "Punched In Time",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[160px] py-[6px]",
      align: "center",
      // name: UserModuleNameTypes.ATTENDANCE_LIST_PINCHED_IN_TIME,
      renderCell: (row: AttendanceListResponse) => (
        <div className="py-0">
          <div className=" font-medium">
            {moment(row?.punchInTime).format("hh:mm A") || "-"}
          </div>
        </div>
      ),
    },
    {
      field: "punchOutTime",
      headerName: "Punched Out Time",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[160px]",
      align: "center",
      // name: UserModuleNameTypes.ATTENDANCE_LIST_PINCHED_OUT_TIME,
      renderCell: (row: AttendanceListResponse) => (
        <div className="font-medium">
          {row?.punchOutTime
            ? moment(row?.punchOutTime).format("hh:mm A")
            : "-"}
        </div>
      ),
    },
    {
      field: "totalWorkingHrsInMin",
      headerName: "Working Hours",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[160px]",
      align: "center",
      // name: UserModuleNameTypes.ATTENDANCE_LIST_PINCHED_OUT_TIME,
      renderCell: (row: AttendanceListResponse) => (
        <div >
          {row?.totalWorkingHrsInMin ? <> {Math.floor(row?.totalWorkingHrsInMin / 60)} hrs :{" "}
          {row?.totalWorkingHrsInMin % 60} min{" "}</> :'-' } 
        </div>
      ),
    },
    {
      field: "officeHours",
      headerName: "Office Hours",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[160px]",
      align: "center",
      // name: UserModuleNameTypes.ATTENDANCE_LIST_PINCHED_OUT_TIME,
      renderCell: (row: AttendanceListResponse) => (
        <div >
         {row?.punchOutTime ? <>  {Math.floor(
            moment(row?.punchOutTime).diff(
              moment(row?.punchInTime),
              "minutes") / 60)} hrs :  {moment(row?.punchOutTime).diff(moment(row?.punchInTime), "minutes") % 60} min</> :'-'}
        </div>
      ),
    },
  ];

  return (
    <div>
      <AttendanceListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          dateFilter,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default AttendanceListingWrapper;
