import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import {
  CloseResourceRequestFormValues,
  FILE_FORMAT,
} from "src/models/ResourceRequest.model";

import {
  useAddResourceByIdMutation,
  useCloseResourceRequestMutation,
  useGetResourceByIdQuery,
} from "src/services/ResourceRequestService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import CloseResourceRequesrForm from "./AddResourceForm";

const AddResourceWrapper = ({ resourceId, onClose }: any) => {
  const [resourceData, setResourceData] = useState<any>();
  const [isClosedRequest, setIsClosedRequest] = useState(true);
  const [closeResourceDelete] = useCloseResourceRequestMutation();
  const [addResourceRequest] = useAddResourceByIdMutation();
  const { data, isLoading, isFetching } = useGetResourceByIdQuery(resourceId);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setResourceData(data?.data);
    }
  }, [data, isLoading, isFetching]);

  const initialValues: CloseResourceRequestFormValues = {
    remark: "",
    attachments: [],
  };

  const validationSchema = object({
    remark: string().required("Please enter remark"),
  });

  const handleSubmit = (
    values: CloseResourceRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<CloseResourceRequestFormValues>
  ) => {
    const formattedValues = {
      ...values,
      attachments: values?.attachments?.map(
        (attachment: {
          url: string;
          type: "images" | "pdfs" | "videos" | "xlsx";
        }) => {
          return {
            url: attachment?.url,
            type: FILE_FORMAT[attachment.type],
          };
        }
      ),
    };
    addResourceRequest({
      id: resourceId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (isClosedRequest) {
            closeResourceDelete(resourceId);
          }
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form className="h-full">
          {isLoading || isFetching ? (
            <div className="absolute w-[80%] h-[100%] flex justify-center items-center z-10 ">
              <CircularProgress />
            </div>
          ) : (
            <div className="flex justify-center ">
              <div className="w-[90%]">
                <CloseResourceRequesrForm
                  setIsClosedRequest={setIsClosedRequest}
                  isClosedRequest={isClosedRequest}
                  formikProps={formikProps}
                  onClose={onClose}
                  resourceData={resourceData}
                />
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddResourceWrapper;
