// |-- Built-in Dependencies --|
import React from "react";
import { FormikProps } from "formik";

// |-- Internal Dependencies --|
import { FormInitialValues } from "../Add/AddStateFormWrapper";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

// |-- Types --|
type Props = {
  onClose: () => void;
  formikProps: FormikProps<FormInitialValues>;
};

const StateForm = ({ onClose, formikProps }: Props) => {
  const { values, setFieldValue, handleBlur, isSubmitting, handleSubmit } =
    formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> Add State </div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <ATMTextField 
         required
          name="stateName"
          value={values.stateName}
          placeholder="enter state name"
          label="State Name"
          onBlur={handleBlur}
          onChange={(e) => {
            setFieldValue("stateName", e.target.value);
          }}
        />
      </div>
      <div>
        <ATMLoadingButton
          isLoading={isSubmitting}
          type="submit"
          onClick={() => handleSubmit()}
        >
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default StateForm;
