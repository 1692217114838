import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { ContactFormValues } from "src/models/Contact.model";
import { useAddLeadsContactMutation } from "src/services/ContactService";
import { showToast } from "src/utils/showToaster";
import AddLeadContactsDialogForm from "./AddLeadContactsDialogForm";
import { useParams } from "react-router-dom";

type Props = {
  onClose: () => void;
};

const AddLeadContactsDialogFormWrapper = ({ onClose }: Props) => {
  const [addContact] = useAddLeadsContactMutation();
  const { leadId } = useParams();
  // Form Initial Values
  const initialValues: ContactFormValues = {
    name: "",
    email: "",
    mobile: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    name: string().required("Please enter name"),
    email: string().email(),
  });

  // Handle Submit
  const handleSubmit = (
    values: ContactFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ContactFormValues>
  ) => {
    addContact({ id: leadId, body: values }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AddLeadContactsDialogForm
            formType="ADD"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddLeadContactsDialogFormWrapper;
