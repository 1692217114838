import { Gauge, gaugeClasses } from "@mui/x-charts";
import { BsCheckCircleFill } from "react-icons/bs";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";

type Props = {
  goalsData: any;
  isGoalsDataFetching: any;
  isGoalsDataLoading: any;
};

const GoalsOfUser = ({
  goalsData,
  isGoalsDataFetching,
  isGoalsDataLoading,
}: Props) => {
  // Utility function to calculate the difference in days between two dates
  const calculateRemainingDays = (deadline: any) => {
    if (!deadline) return "-";
    const currentDate = new Date();
    const deadlineDate = new Date(deadline);

    // Calculate the difference in milliseconds
    const differenceInMs = Number(deadlineDate) - Number(currentDate);

    // Convert milliseconds to days
    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

    if (differenceInDays > 0) {
      return `${differenceInDays} days left`;
    } else if (differenceInDays === 0) {
      return "Deadline is today";
    } else {
      return "Deadline has passed";
    }
  };

  const columns: columnTypes[] = [
    {
      field: "name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize  min-w-[150px]",
      // name: UserModuleNameTypes.EXPENSES_LIST_AMOUNT,
    },
    {
      field: "goalCategoryName",
      headerName: "Category Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize  min-w-[150px]",
      // name: UserModuleNameTypes.EXPENSES_LIST_AMOUNT,
    },

    {
      field: "target",
      headerName: "Target",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[120px]",
      // name: UserModuleNameTypes.EXPENSES_LIST_CATEGORY_NAME,
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {row.target
              ? `${row?.achieved}/${row.target}`
              : `${row.achieved}/100%`}
          </div>
        </div>
      ),
    },
    {
      field: "deadline",
      headerName: "Deadline date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize  min-w-[150px]",
      // name: UserModuleNameTypes.EXPENSES_LIST_EXPENSE_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {" "}
            {row.deadline ? calculateRemainingDays(row.deadline) : "-"}
          </div>
          <div className="text-xs text-slate-700">
            {row.deadline
              ? formatedDateTimeIntoIst(row.deadline, "DD MMM yyyy")
              : "-"}
          </div>
        </div>
      ),
    },
    {
      field: "countable",
      headerName: "",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[120px]",
      // name: UserModuleNameTypes.EXPENSES_LIST_CATEGORY_NAME,
      renderCell: (row: any) => {
        let checkTarget =
          row?.type === "COUNTABLE"
            ? row?.target > row?.achieved
            : 100 > row?.achieved;
        return (
          <div>
            {checkTarget ? (
              <Gauge
                sx={(theme) => ({
                  [`& .${gaugeClasses.valueArc}`]: {
                    fill: "#52b202",
                  },
                })}
                width={100}
                height={70}
                value={row?.achieved}
                valueMax={row?.target ? row?.target : 100}
              />
            ) : (
              <div className="ml-8">
                <BsCheckCircleFill color="green" size={30} />{" "}
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg custom-shadow">
      <div className="flex items-center gap-2 p-2 border-b bg-rose-100">
        <div className="flex items-center justify-center p-2 font-semibold bg-white border rounded-md border-primary-dark size-8">
          {goalsData?.length}
        </div>
        <p className="font-medium">Goals</p>
      </div>
      <div className="h-full overflow-auto">
        <ATMTable
          columns={columns}
          rows={goalsData || []}
          rowExtraClasses={() => "h-[50px]"}
          isLoading={isGoalsDataLoading || isGoalsDataFetching}
        />
      </div>
    </div>
  );
};

export default GoalsOfUser;
