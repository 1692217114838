import { FormikProps } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import MOLClientDropdown from "src/components/UI/molecules/MOLClientDropdown/MOLClientDropdown";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { DeliveryDateFormValues } from "src/models/DeliveryDate.model";
import { ProjectListResponse } from "src/models/Project.model";
import { useGetProjectByClientQuery } from "src/services/ProjectServices";

type Props = {
  formikProps: FormikProps<DeliveryDateFormValues>;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
  onClose: () => void;
};

const DeliveryDateForm = ({
  formikProps,
  formType = "ADD",
  setSaveNextChecked,
  saveNextChecked,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const [projectData, setProjectData] = useState<any>();
  const { data, isLoading, isFetching }: any = useGetProjectByClientQuery(
    values?.clientId?._id,
    { skip: !values?.clientId?._id }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setProjectData(data?.data || []);
    }
  }, [isLoading, data, isFetching, values?.clientId?._id]);

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Date" : "Update Date"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* cleint */}
        <div className="">
          <MOLClientDropdown
            required
            name="clientId"
            value={values.clientId}
            onChange={(newValue) => {
              setFieldValue("clientId", newValue);
              setFieldValue("projectId", "");
            }}
            onBlur={handleBlur}
            label="Client"
            placeholder="Select Client"
          />
        </div>
        {/* PRoject */}
        <div className="mt-1">
          <ATMSelect
            name="projectId"
            label="Project"
            required
            placeholder="Select Project"
            value={values.projectId}
            isLoading={isLoading}
            options={projectData?.map((ele: ProjectListResponse) => {
              return {
                label: ele?.projectName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("projectId", newValue);
            }}
          />
        </div>
        {/* Delievery Date */}
        <div className="w-full mt-1">
          <ATMDatePicker
            required
            label="Date"
            name="deliveryDate"
            format="DD/MM/YYYY"
            minDate={moment().format("yyyy-MM-DD")}
            value={values.deliveryDate || null}
            onChange={(newValue) => setFieldValue("deliveryDate", newValue)}
          />
        </div>

        {/* Remark */}
        <div className="mt-1">
          <ATMTextArea
            required
            name="remark"
            value={values.remark}
            onChange={(value) => setFieldValue("remark", value)}
            label="Remark"
            placeholder="Enter Remark"
          />
        </div>
        {/* 
        {formType === "ADD" && (
          <div>
            <ATMCheckbox
              label="Save and Next"
              checked={saveNextChecked}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        )}
        <div>
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div> */}
      </div>
    </MOLFormDialog>
  );
};

export default DeliveryDateForm;
