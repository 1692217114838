import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import moment from "moment";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";

type Props = {
  formikProps: any;
  onClose: () => void;
};

const EditTeamAttendance = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, handleBlur, isSubmitting } = formikProps;

  const WorkingFrom = [
    {
      label: "OFFICE",
      value: "OFFICE",
    },
    {
      label: "HOME",
      value: "HOME",
    },
  ];

  const AttendanceType = [
    {
      label: "ABSENT",
      value: "ABSENT",
    },
    {
      label: "PRESENT",
      value: "PRESENT",
    },
  ];

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex items-center justify-between">
        <div className="text-xl font-medium">Edit attendance </div>
        <button
          type="button"
          className="p-2 rounded hover:bg-hover"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <ATMSelect
            name="attendanceType"
            label="Attendance Type"
            required
            placeholder="Select Attendance Type"
            value={values.attendanceType}
            options={AttendanceType}
            onChange={(newValue) => {
              setFieldValue("attendanceType", newValue);
              if (newValue?.value === "ABSENT") {
                setFieldValue("punchInTime", "");
                setFieldValue("punchOutTime", "");
                setFieldValue("workingFrom", "");
              }
            }}
          />
        </div>
        {/* Module Name */}
        <div>
          <ATMDatePicker
            label="Due Date"
            name="date"
            disabled
            format="DD/MM/YYYY"
            minDate={moment().format("yyyy-MM-DD")}
            value={values.date}
            onChange={(newValue) => setFieldValue("date", newValue)}
          />
        </div>

        {values?.attendanceType?.value === "PRESENT" ? (
          <>
            <div>
              <ATMTextField
                type="time"
                name="punchInTime"
                value={
                  values?.punchInTime
                    ? moment(values?.punchInTime).format("HH:mm")
                    : ""
                }
                required
                label="Punched In Time"
                className="mt-0 rounded"
                onBlur={handleBlur}
                onChange={(e) => {
                  let time = moment(e.target.value, "HH:mm");
                  setFieldValue("punchInTime", time);
                }}
              />
            </div>

            <div>
              <ATMTextField
                type="time"
                name="punchOutTime"
                value={
                  values?.punchOutTime
                    ? moment(values?.punchOutTime).format("HH:mm")
                    : ""
                }
                required
                label="Punched In Out"
                className="mt-0 rounded"
                onBlur={handleBlur}
                onChange={(e) => {
                  let time = moment(e.target.value, "HH:mm");
                  setFieldValue("punchOutTime", time);
                }}
              />
            </div>
            <div>
              <ATMSelect
                name="workingFrom"
                label="Working From"
                required
                placeholder="Select Working From"
                value={values.workingFrom}
                options={WorkingFrom}
                onChange={(newValue) => {
                  setFieldValue("workingFrom", newValue);
                }}
              />
            </div>
          </>
        ) : null}
      </div>

      <div>
        <ATMLoadingButton type="submit" isLoading={isSubmitting}>
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default EditTeamAttendance;
