import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { ReminderFormValues } from "src/models/Reminder.model";
import ReminderForm from "../Layouts/ReminderForm";
import { useAddReminderMutation } from "src/services/ReminderService";
import { showToast } from "src/utils/showToaster";
import moment from "moment";

type Props = {
  onClose: () => void;
};

const ReminderAddWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addReminder] = useAddReminderMutation();
  const userDataLJson = localStorage.getItem("userData");
  const userData = JSON?.parse(userDataLJson as string);
  let currentTime = moment();
  currentTime.add(2, "hours");
  // Form Initial Values
  const initialValues: ReminderFormValues = {
    reminder: "",
    dateTime: "",
    forWhom: [],
    source: "",
    sourceId: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    reminder: string().test(
      "is-reminder-required",
      "Reminder are required",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: ReminderFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ReminderFormValues>
  ) => {
    const FormattedValues = {
      reminder: values.reminder,
      dateTime: values?.dateTime
        ? moment(values?.dateTime).format("YYYY-MM-DD HH:mm:ss")
        : currentTime.format("YYYY-MM-DD HH:mm:ss"),
      forWhom: values?.forWhom?.length
        ? values?.forWhom?.map((forWhom: any) => forWhom?.value)
        : [userData?.userId],
      source: "REMINDER",
      sourceId: null,
    };
    addReminder(FormattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ReminderForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ReminderAddWrapper;
