import React, { useEffect, useState } from "react";
import FeatureDetailsView from "./FeatureDetailsView";
import {
  useDeleteRequirementByIdMutation,
  useGetRequirementByIdQuery,
} from "src/services/RequirementService";
import { useLocation } from "react-router-dom";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";

const FeatureDetailsViewWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const featureId = queryParams.get("featureId");

  const [featueDetails, setFeatueDetails] = useState<any>({});
  const { isLoading, isFetching, data } = useGetRequirementByIdQuery(
    featureId || "",
    {
      skip: !featureId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setFeatueDetails(data?.data);
    } else {
      setFeatueDetails({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, data, featureId]);

  const [deleteRequirement] = useDeleteRequirementByIdMutation();

  const handleDeleteRequirement = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Feature ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteRequirement(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };
  return (
    <>
      <FeatureDetailsView
        featueDetails={featueDetails}
        handleDeleteRequirement={handleDeleteRequirement}
        isLoading={isLoading && isFetching}
      />
    </>
  );
};

export default FeatureDetailsViewWrapper;
