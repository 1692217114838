import { FormikProps } from "formik";
import moment from "moment";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { LeaveRequestFormValues } from "src/models/LeaveReques.model";

type Props = {
  handleClose: any;
  formikProps: FormikProps<LeaveRequestFormValues>;
  codioticUsers: any;
  isDataLoading: any;
};

const MoreThanOneDayLeaveRequestForm = ({
  handleClose,
  formikProps,
  codioticUsers,
  isDataLoading,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={" Apply More than One Day Leave"}
      onClose={handleClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        {/* date */}
        <div className="w-full">
          <ATMDatePicker
            required
            label="Start Date"
            name="leaveStartDate"
            format="DD/MM/YYYY"
            value={values.leaveStartDate || null}
            minDate={moment().format("yyyy-MM-DD")}
            onChange={(newValue) => {
              setFieldValue("leaveStartDate", newValue);
            }}
          />
        </div>

        <div className="w-full mt-1">
          <ATMDatePicker
            required
            label="Reporting Date"
            name="reportingDate"
            format="DD/MM/YYYY"
            minDate={values?.leaveStartDate || moment().format("yyyy-MM-DD")}
            value={values.reportingDate || null}
            onChange={(newValue) => setFieldValue("reportingDate", newValue)}
          />
        </div>
        <div className="mt-1">
          <ATMSelect
            required
            name="approvals"
            label="Approver"
            placeholder="Approver"
            value={values.approvals}
            options={codioticUsers?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("approvals", newValue);
            }}
            onBlur={handleBlur}
          />
        </div>
        <div className="mt-1">
          <ATMSelect
            required
            name="buddy"
            label="Buddy"
            placeholder="Buddy"
            value={values.buddy}
            options={codioticUsers?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("buddy", newValue);
            }}
            onBlur={handleBlur}
          />
        </div>

        {/* Reason */}
        <div className="mt-1">
          <ATMTextArea
            required
            name="reason"
            value={values.reason}
            onChange={(e) => setFieldValue("reason", e)}
            label="Reason"
            placeholder="Enter Reason"
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default MoreThanOneDayLeaveRequestForm;
