import React from 'react';
import { FormikProps } from 'formik';
import ATMLoadingButton from 'src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton';
import ATMTextField from 'src/components/UI/atoms/formFields/ATMTextField/ATMTextField';
import { IncidentCategoryFormValues } from '../Add/AddIncidentCategoryFormWrapper';

type Props = {
  formikProps: FormikProps<IncidentCategoryFormValues>;
  onClose: () => void;
  formType :'Add' | 'Update'
};

const IncidentCategoryForm = ({ formikProps, onClose,formType }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className='p-4 flex flex-col gap-6'>
      <div className='flex justify-between items-center'>
        <div className='text-xl font-medium'>{formType} Category</div>
        <button type='button' className='hover:bg-hover' onClick={onClose}>
          Cancel
        </button>
      </div>
      <div className='flex flex-col gap-4'>
        <div>
          <ATMTextField
            name='categoryName'
            value={values?.categoryName}
            onChange={(e) => {
              setFieldValue('categoryName', e.target.value);
            }}
            label='Category Name'
            placeholder='Enter Category Name'
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type='submit'>
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default IncidentCategoryForm;