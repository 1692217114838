import React from "react";

export enum ENMJobApplicationStatus {
  "RECEIVED" = "Received",
  "UNDER_REVIEW" = "Under Review",
  "SHORTLISTED" = "Shortlisted",
  "IN_INTERVIEW" = "In Interview",
  "ONBOARDED" = "Onboarded",
  "REJECTED" = "Rejected",
}

export type TYPTicketStatus = keyof typeof ENMJobApplicationStatus;

type Props = {
  status: TYPTicketStatus;
};

const getClassNameByStatus = (status: TYPTicketStatus) => {
  switch (status) {
    case "RECEIVED":
      return "text-yellow-600 border-yellow-600 bg-yellow-100";

    case "SHORTLISTED":
      return "text-green-600 border-green-600 bg-green-100";

    case "IN_INTERVIEW":
      return "text-yellow-600 border-yellow-600 bg-yellow-100";

    case "UNDER_REVIEW":
      return "text-sky-500 bg-sky-100 border-sky-500";

    case "REJECTED":
      return "text-rose-500 bg-rose-100 border-rose-500";

    case "ONBOARDED":
      return "text-[#008080] border-[#008080] bg-[#00808033]";
  }
};

const MOLJobApplicationStatusChip = ({ status }: Props) => {
  const className = ` 
    font-medium
    rounded-full
    px-2
    py-[2px]
    text-[10px]
    border-[1px]
    text-center
    ${getClassNameByStatus(status)}`;

  return <div className={className}> {ENMJobApplicationStatus[status]} </div>;
};

export default MOLJobApplicationStatusChip;
