import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { ChannelFormValues } from "src/models/Channel.model";
import { useAddChannelMutation } from "src/services/ChannelService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import ChannelForm from "../Layouts/ChannelForm";

type Props = {
  onClose: () => void;
};

const AddChannelFormWrapper = ({ onClose }: Props) => {
  const [addChannel] = useAddChannelMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  // Form Initial Values
  const initialValues: ChannelFormValues = {
    channelGroupName: "",
    channelName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    channelGroupName: object().required("Please select channel group"),
    channelName: string().required("Please enter channel name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ChannelFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ChannelFormValues>
  ) => {
    const formattedValues = {
      channelName: values?.channelName,
      channelGroupId: values?.channelGroupName?.value,
    };
    addChannel(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ChannelForm
            formType="ADD"
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddChannelFormWrapper;
