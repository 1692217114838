import React, { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import DoubtFilterFormDialogWrapper from "src/screens/Doubts/List/DoubtFilterFormCard/DoubtFilterFormDialogWrapper";
import { BiChevronRight } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, Divider } from "@mui/material";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { FiArrowRight } from "react-icons/fi";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const CloseDoubtsListing = ({
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  // Filter Form Open
  const [isOpenTaskAllFilterDialog, setIsOpenTaskAllFilterDialog] =
    useState<boolean>(false);
  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  // short cut key for open filter form
  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if the pressed keys match the specified shortcut (Ctrl + Shift + F)
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "f") {
        // Open the filter dialog when the shortcut is detected
        setIsOpenTaskAllFilterDialog(true);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <div className="flex flex-col">
      {/* Page Header */}
      <div className="flex justify-between">
        <ATMPageHeader
          onSearchChange={setSearchValue}
          searchValue={searchValue}
          pageTitle="Doubts"
          hideAddButton
          isFilter
          onFilterClick={() => setIsOpenTaskAllFilterDialog(true)}
        />
      </div>
      <div className="h-[100vh] overflow-y-scroll overflow-x-hidden xs:px-1 md:px-0 bg-gray-50">
        {isTableLoading ? (
          <div className="flex justify-center items-center h-[400px]">
            <CircularProgress />
          </div>
        ) : rows?.length ? (
          <div className="flex flex-col">
            {/* // Default show Task listing view */}
            {rows?.map((row: any, index: any) =>
              isTableLoading ? (
                Array(10)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index} className="animate-pulse h-[50px] p-2">
                      <div className="h-full rounded"></div>
                    </div>
                  ))
              ) : (
                <div
                  key={index}
                  className="md:px-4 px-2 pt-4 last:pb-4 cursor-pointer"
                  onClick={() => {
                    isAuthorized(
                      UserModuleNameTypes.ACTION_DOUBTS_CLOSE_SINGLE_VIEW
                    ) &&
                      navigate(`/doubt/${row?._id}`, {
                        state: {
                          pathname,
                          search,
                        },
                      });
                  }}
                >
                  <div
                    className={`rounded-md  bg-white border border-slate-200 hover:border-primary-dark max-w-[100vw] overflow-hidden transition-all duration-500 hover:shadow-md`}
                  >
                    <div className="rounded-md bg-white">
                      <div className="h-full flex">
                        {/* ticket header */}
                        <div className="flex items-center justify-between gap-4 w-full  py-3">
                          <p className="flex items-center gap-2 font-medium text-sm leading-none px-2">
                            <span>{row?.createdByName}</span>
                            <FiArrowRight />
                            <span>{row?.assignedToUserName}</span>
                          </p>
                          <div className="md:block hidden">
                            <div className="flex items-center gap-4">
                              <p className="text-sm flex  items-center gap-2 font-semibold capitalize px-4">
                                {row?.projectName}
                                <BiChevronRight />
                                {row?.moduleName}
                                <BiChevronRight />
                                {row?.featureName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-2 pt-2 px-4">
                        <div className="block md:hidden ">
                          <p className="md:text-sm text-xs flex  items-center  gap-x-2 font-semibold capitalize ">
                            <span> {row?.projectName}</span>
                            <BiChevronRight />
                            {row?.moduleName}
                            <BiChevronRight />
                            <span className="line-clamp-1">
                              {" "}
                              {row?.featureName}
                            </span>
                          </p>
                        </div>
                        <div className="line-clamp-3">
                          <ATMMarkdownContainer
                            markdown={row?.question || "-"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-[22px]">
            Data not Found
          </div>
        )}
      </div>
      <div className="md:px-4 px-2 py-2 border-t">
        <ATMPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          rowCount={totalItems}
          rows={rows}
        />
      </div>
      <DoubtFilterFormDialogWrapper
        open={isOpenTaskAllFilterDialog}
        onClose={() => setIsOpenTaskAllFilterDialog(false)}
      />
    </div>
  );
};

export default CloseDoubtsListing;
