import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { RecurringIncomeFormProps } from "src/models/RecurringIncome.model";
import { object } from "yup";
import RecurringIncomeFormLayout from "../Layout/RecurringIncomeFormLayout";

const EditRecurringIncomeFormWrapper = () => {
  const navigate = useNavigate();
  // const { id } = useParams();

  const initialValues: RecurringIncomeFormProps = {
    amount: "",
    gstApplicable: false,
    gstPercentage: "0",
    tdsAmount: "",
    currency: "",
    category: "",
    repeat: "",
    description: "",
    recurranceDate: "",
    until: "",
    recurranceDateAndMonthForYear: null,
    days: [],
    untilDate: null,
    event: "",
  };

  // Validation Schema
  const validationSchema = object().shape({});

  // Handle Submit
  const handleSubmit = (
    values: RecurringIncomeFormProps,
    { setSubmitting, resetForm }: FormikHelpers<RecurringIncomeFormProps>
  ) => {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <RecurringIncomeFormLayout
            formType="EDIT"
            formikProps={formikProps}
            onClose={() => navigate("/finance/recurringincome")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditRecurringIncomeFormWrapper;
