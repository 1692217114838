import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { DomainFormValues } from "src/models/Domain.model";
import {
  useUpdateDomainMutation,
  useGetSingleDomainQuery,
} from "src/services/DomainServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import DomainForm from "../Layouts/DomainForm";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  selecetedDomainId: any;
};

const EditDomainFormWrapper = ({ onClose, selecetedDomainId }: Props) => {
  const [domainData, setDomainData] = useState<any>({});

  const { isLoading, isFetching, data } = useGetSingleDomainQuery(
    selecetedDomainId,
    {
      skip: !selecetedDomainId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setDomainData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const [updateDomain] = useUpdateDomainMutation();

  // Form Initial Values
  const initialValues: DomainFormValues = {
    domainName: domainData?.domainName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    domainName: string().required("Please enter domain name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DomainFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DomainFormValues>
  ) => {
    updateDomain({ id: selecetedDomainId, body: values }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <DomainForm
            formikProps={formikProps}
            onClose={onClose}
            formType="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditDomainFormWrapper;
