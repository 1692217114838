import { FormikProps } from "formik";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import {
  handleValidNumber,
  handleValidPercent,
} from "src/utils/validations/numberInput";
import { AchievementFormValues } from "../Add/AddAchievementWrapper";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<AchievementFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  selectedGoals: any;
};

const AddAchievementForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  selectedGoals,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Achievement" : "Update Achievement"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMDatePicker
            required
            label="Achieve Date"
            name="achieveDate"
            format="DD/MM/YYYY"
            value={values.achieveDate}
            onChange={(newValue) => setFieldValue("achieveDate", newValue)}
          />
        </div>

        {/* Members */}
        <ATMMultiSelect
          name="achievedById"
          required
          value={values?.achievedById}
          label="Achieved By"
          placeholder="Select achieved by"
          onChange={(newValue) => {
            setFieldValue("achievedById", newValue);
          }}
          options={selectedGoals?.membersInvolved?.map((ele: any) => {
            return {
              label: ele?.memberName,
              value: ele?.memberId,
            };
          })}
        />
        {selectedGoals?.achieved ? (
          <div className="text-m font-medium">
            Achived : {selectedGoals?.achieved}
            {selectedGoals?.type === "COUNTABLE" ? null : "%"}{" "}
          </div>
        ) : null}
        <div className="">
          <ATMTextField
            required
            name="achieved"
            value={values.achieved}
            onChange={(e) => {
              let finalUncountable: any = 100 - selectedGoals?.achieved;
              if (selectedGoals?.type === "COUNTABLE") {
                handleValidNumber(e) &&
                  setFieldValue("achieved", e.target.value);
              } else {
                if (
                  handleValidPercent(e) &&
                  e.target.value <= finalUncountable
                ) {
                  setFieldValue("achieved", e.target.value);
                }
              }
            }}
            label="Achievement"
            placeholder="Enter Achieved"
            onBlur={handleBlur}
          />
        </div>

        <div>
          <ATMTextField
            name="remark"
            value={values.remark}
            onChange={(e) => setFieldValue("remark", e.target.value)}
            label="Remark"
            placeholder="Enter remark"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default AddAchievementForm;
