import React from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
// import { isCheckActionsPermission } from "src/helpers/userAccessFun";

// import { UserModuleNameTypes } from "src/models/userAccess/UserAccess.model";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
  onAddNew: () => void;
};

const UserRoleListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
  onAddNew,
}: Props) => {
  return (
    <SideNavLayout>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div>
          <ATMPageHeader
            pageTitle="Roles"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            hideAddButton={!isAuthorized(UserModuleNameTypes.ACTION_ROLES_ADD)}
            buttonProps={{ btnName: "Add New", onClick: onAddNew }}
          />
        </div>

        {/* Table */}
        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-col overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "h-[40px]"}
              isLoading={isTableLoading}
            />
          </div>

          <div>
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      </div>
    </SideNavLayout>
  );
};

export default UserRoleListing;
