import { useState, useEffect } from "react";
import { useGetFeaturesByModuleIdQuery } from "src/services/RequirementService";

export const useGetAllFeatureByModule = (featureId: string) => {
  const [features, setFeatures] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetFeaturesByModuleIdQuery(
    featureId,
    {
      skip: !featureId,
    }
  );

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setFeatures(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { features, isDataLoading };
};
