import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { WorkspaceFormValues } from "../Add/AddWorkspaceFormWrapper";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import MOLClientDropdown from "src/components/UI/molecules/MOLClientDropdown/MOLClientDropdown";
import { useEffect, useState } from "react";
import { useGetProjectByClientQuery } from "src/services/ProjectServices";

type Props = {
  formikProps: FormikProps<WorkspaceFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  projectItems?: any;
  saveNextChecked?: any;
};

const WorkspaceForm = ({
  formikProps,
  formType,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const [ setProjectData] = useState<any>();

  const { data, isLoading, isFetching }: any = useGetProjectByClientQuery(
    values?.clientId._id,
    { skip: !values?.clientId._id }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setProjectData(data?.data || []);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, isFetching, values?.clientId._id]);
  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add New Workspace" : "Update Workspace"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Module Name */}
        <div>
          <MOLClientDropdown
            required
            name="clientId"
            value={values.clientId}
            onChange={(newValue) => setFieldValue("clientId", newValue)}
            onBlur={handleBlur}
            label="Client"
            placeholder="Select Client"
          />
        </div>
        <div>
          <ATMTextField
            name="moduleName"
            value={values.moduleName}
            required
            onChange={(e) => setFieldValue("moduleName", e.target.value)}
            label="Workspace Name"
            placeholder="Enter Workspace Name"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default WorkspaceForm;
