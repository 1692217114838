import React from "react";
import AssignToDialog from "./AssignToDialog";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { useUpdateAssigneeInTaskMutation } from "src/services/TaskService";
import { object } from "yup";

type Props = {
  onClose: any;
  assignToId: string;
  assignToName: string;
  selectedTask: string;
  projectId?: string;
};

const AssignToDialogWrapper = ({
  onClose,
  selectedTask,
  assignToId,
  assignToName,
  projectId,
}: Props) => {
  const [updateAssiginee] = useUpdateAssigneeInTaskMutation();

  const initialValues = {
    assignedTo: assignToName ? { label: assignToName, value: assignToId } : "",
  };
  // Validation Schema
  const validationSchema = object().shape({
    assignedTo: object().required("Please select assignee"),
  });

  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    const { assignedTo, ...rest } = values;
    const formattedValues = {
      ...rest,
      assignedTo: assignedTo?.value,
    };
    updateAssiginee({ id: selectedTask, body: formattedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            onClose();
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form>
            <AssignToDialog
              projectId={projectId}
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AssignToDialogWrapper;
