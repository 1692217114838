import { CircularProgress } from "@mui/material";
import moment from "moment";
import React, { Suspense, useState } from "react";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import AccordianFormLayout from "src/components/layouts/AccordianFormLayout/AccordianFormLayout";
import ProjectAddResourceWrapper from "./ProjectAddResource/ProjectAddResourceWrapper";

type Props = {
  requestData: any[];
  projectId : any
};

const getClassNameByStatus = (status: any) => {
  switch (status) {
    case "PENDING":
      return "text-[#000000] border-[#CCC] bg-[#FFFF001A]";

    case "CLOSED":
      return "text-slate-500 bg-slate-100 border-slate-500";
  }
};

const ProjectResourceRequestList = ({ requestData, projectId }: Props) => {

  const [isOpenAddDialog, setIsOpenAddDialog] = useState<boolean>(false);
  return (

    <>

      <div className="flex justify-end ">
        <ATMLoadingButton className="w-[100px] font-bold" onClick={() => setIsOpenAddDialog(true)}>
          Add
        </ATMLoadingButton>
      </div>

      <div className="p-4">
        {requestData?.length ? requestData?.map((request: any, index: number) => {
          return (
            <div key={index} className="m-2">
              <AccordianFormLayout formHeading={request?.resourceName}>
                <div className="p-2">
                  <div className="flex justify-between border border-slate-300 rounded p-3">
                    <div>
                      <p className="font-medium ">
                        <span className="text-primary-light">Resource Type</span> :{" "}
                        {request?.type}{" "}
                      </p>
                    </div>

                    <div>
                      <div className="text-end">
                        <span
                          className={`font-medium rounded-full px-2 py-[2px]  text-[14px] border-[1px] ${getClassNameByStatus(
                            request?.status
                          )}`}
                        >
                          {request?.status}
                        </span>{" "}
                      </div>
                      <div className="text-[17px] text-slate-700 font-medium text-end my-1">
                        {moment(request?.createdAt).format("DD MMM YYYY")}
                      </div>
                      <p className="font-medium ">
                        {" "}
                        <span className="text-primary-light">
                          Request Type
                        </span> : {request?.requestedFromType}{" "}
                      </p>
                      {request?.uploadedOnDate &&  <p className="font-medium ">
                  {" "}
                  <span className="text-primary-light">
                    Resource added On Date
                  </span> :  {moment(request?.uploadedOnDate).format("DD MMM YYYY")}{" "}
                </p> }
                    </div>
                  </div>

                  <div className="m-2">
                    {request?.description !== '' && <div>
                      <p className="text-slate-700 font-medium"> Description</p>
                      <div className="border border-slate-300 p-2  rounded-md">
                        <ATMMarkdownContainer
                          markdown={request?.description || "-"}
                        />
                      </div>
                    </div>} 
                    {request?.remark !== "" && (
                <div className="mt-2">
                  <p className="text-slate-700 font-medium">Remark</p>
                  <div className="border border-slate-300 p-2  rounded-md">
                    <ATMMarkdownContainer
                      markdown={request?.remark || "-"}
                    />
                  </div>
                </div>
              )}
                    <div className="grid grid-cols-3 gap-4">
                      {request?.attachments?.map((el: any, ind: number) => {
                        return (
                          <div
                            key={ind}
                            className={`${el?.type === "PDF" || el?.type === "XLSX"
                              ? "max-h-[120px]"
                              : "max-h-[350px]"
                              } `}
                          >
                            <ATMRenderFile fileInfo={el} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </AccordianFormLayout>
            </div>
          );
        }) : <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-[22px]">
          Data Not Found
        </div>}
      </div>
      <Suspense fallback={<CircularProgress />}>
        {isOpenAddDialog ? (
          <ProjectAddResourceWrapper  projectId={projectId} onClose={() => setIsOpenAddDialog(false)} />
        ) : null}
      </Suspense>
      </>

  );
};

export default ProjectResourceRequestList;
