import React from "react";
import TicketTags from "./TicketTags";
import { Form, Formik, FormikHelpers } from "formik";
import { useUpdateTagTicketMutation } from "src/services/TicketServices";
import { showToast } from "src/utils/showToaster";

type Props = {
  ticketId: any;
  initialTags?: string[];
};
const TicketTagsWrapper = ({ ticketId, initialTags = [] }: Props) => {
  const [addTicketTag] = useUpdateTagTicketMutation();

  // Form Initial Values
  const initialValues: any = {
    tags: initialTags.length ? initialTags : [],
  };

  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    addTicketTag({
      ticketId: ticketId,
      body: values,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <TicketTags formikProps={formikProps} />
        </Form>
      )}
    </Formik>
  );
};

export default TicketTagsWrapper;
