import apiSlice from "./ApiSlice";

export const businessModelApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all BusinessModel pagination
    getBusinessModelPagination: builder.query({
      providesTags: ["business-model"],
      query: (body) => {
        return {
          url: "/business-model",
          method: "POST",
          body,
        };
      },
    }),

    // Get all BusinessModel
    getAllBusinessModel: builder.query({
      providesTags: ["business-model"],
      query: () => {
        return {
          url: "/business-model",
          method: "GET",
        };
      },
    }),

    // Get single BusinessModel
    getSingleBusinessModel: builder.query({
      providesTags: ["business-model"],
      query: (id: string) => {
        return {
          url: `/business-model/${id}`,
          method: "GET",
        };
      },
    }),

    // Add BusinessModel
    addBusinessModel: builder.mutation({
      invalidatesTags: ["business-model"],
      query: (body) => {
        return {
          url: "/business-model/add",
          method: "POST",
          body,
        };
      },
    }),

    // Update social media
    updateBusinessModel: builder.mutation({
      invalidatesTags: ["business-model"],
      query: ({ id, body }) => {
        return {
          url: `/business-model/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // delete BusinessModel
    deleteBusinessModel: builder.mutation({
      invalidatesTags: ["business-model"],
      query: (id: any) => ({
        url: `/business-model/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetBusinessModelPaginationQuery,
  useGetAllBusinessModelQuery,
  useGetSingleBusinessModelQuery,
  useAddBusinessModelMutation,
  useUpdateBusinessModelMutation,
  useDeleteBusinessModelMutation,
} = businessModelApi;
