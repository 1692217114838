import React from "react";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import { FormInitialValues } from "./TaskTimeAnalyticsFilterWrapper";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};


const TaskTimeAnalyticsFilter = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <div className="flex flex-col gap-4 px-4 py-2">
        {/* Heading & Clear Button */}
        <div className="flex justify-between items-center sticky top-0 z-50">
          <div className="text-xl font-medium "> Filter </div>
          <button
            type="button"
            className="text-red-600 hover:text-red-400 font-medium"
            onClick={onReset}
          >
            Clear Filters
          </button>
        </div>
        {/* Created At */}
        <div className="mb-2">
          <div className="flex flex-col gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                maxDate={moment().format("yyyy-MM-DD")}
                value={values?.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values?.endDate}
                minDate={values?.startDate || ''}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>

      

        {/* Apply & Cancel Buttons */}
        <div className="flex flex-col gap-2  sticky bottom-0 bg-white">
          <div>
            <ATMLoadingButton
              type="submit"
              className="h-[40px]"
              isLoading={isSubmitting}
              onClick={() => handleSubmit()}
            >
              Apply
            </ATMLoadingButton>
          </div>

          <div>
            <ATMLoadingButton
              className="bg-slate-300 hover:bg-gray-200 transition-all h-[40px] border-none text-slate-700 font-medium"
              onClick={onClose}
            >
              Cancel
            </ATMLoadingButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TaskTimeAnalyticsFilter;
