import apiSlice from "./ApiSlice";

export const planApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllPlanListPaginationData: builder.query({
      providesTags: ["plan"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/plan",
          method: "POST",
          body,
        };
      },
    }),

    //Add
    addPlan: builder.mutation({
      invalidatesTags: ["plan"],
      query: (body: {
        planName: string;
        amount: number;
        recurranceDays: number;
        productId: string;
      }) => {
        return {
          url: "/plan/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get the Git Repo  by id
    getPlanById: builder.query({
      providesTags: ["plan"],
      query: (id: string) => {
        return {
          url: `/plan/${id}`,
          method: "GET",
        };
      },
    }),

    deletePlanById: builder.mutation({
      invalidatesTags: ["plan"],
      query: (id: any) => ({
        url: `/plan/${id}`,
        method: "DELETE",
      }),
    }),

    // Update Git Repo
    updatePlanById: builder.mutation({
      invalidatesTags: ["plan"],
      query: ({id,body}:{ id: string, body: any }) => {
        return {
          url: `/plan/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});
export const {
  useGetAllPlanListPaginationDataQuery,
  useDeletePlanByIdMutation,
  useUpdatePlanByIdMutation,
  useGetPlanByIdQuery,
  useAddPlanMutation,
} = planApi;
