import React, { useEffect, useState } from "react";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useGetEstimationCalculatorDataQuery } from "src/services/EstimationCalculatorServices";
import EstimationCalculatorListing from "./EstimationCalculatorListing";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import {
  setIsTableLoading,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
  setItems,
} from "src/redux/slices/EstimationCalculatorSlice";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { CLIENT_GRW } from "src/utils/constants";

const EstimationCalculatorListingWrapper = () => {
  const [meetingModeEnable, setMeetingModeEnable] = useState<boolean>(false);
  const { data, isFetching, isLoading } =
    useGetEstimationCalculatorDataQuery("");
  // Initiate Methods
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.estimationCalculcator);
  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    // get the meetingmode is enable or not
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem(
      "isMeetingModeEnable"
    ) as string;

    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";

    setMeetingModeEnable(isEnabled);
  }, []);
  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "z-0 min-w-[150px]",
      renderCell(row) {
        return (
          <span className="">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </span>
        );
      },
      name: UserModuleNameTypes.ESTIMATION_CALCULATOR_LIST_CREATED_AT,
    },
    {
      field: "title",
      headerName: "Title",
      flex: "flex-[1_1_0%]",
      extraClasses: "z-0 min-w-[150px]",
      name: UserModuleNameTypes.ESTIMATION_CALCULATOR_LIST_TITLE,
      renderCell(row) {
        return <span className="">{row?.title}</span>;
      },
    },
    {
      field: "developer",
      headerName: "Developer",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell(row) {
        return <span>{row?.fields?.length}</span>;
      },
      name: UserModuleNameTypes.ESTIMATION_CALCULATOR_LIST_DEVELOPER,
    },
    {
      field: "ctc",
      headerName: "CTC",
      hidden: meetingModeEnable,
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.ESTIMATION_CALCULATOR_LIST_CTC,
      renderCell(row) {
        // const totalCtc = row?.fields?.reduce((acc: any, el: any) => {
        //   return (
        //     acc + el?.developer?.salaryPerHour * el?.duration * el?.hoursPerDay
        //   );
        // }, 0);
        return (
          <span>
            &#8377;
            {row?.fields?.reduce((acc: any, el: any) => {
              return (
                acc +
                el?.developer?.salaryPerHour * el?.duration * el?.hoursPerDay
              );
            }, 0)}
          </span>
        );
      },
    },
    {
      field: "gctc",
      headerName: "GCTC",
      flex: "flex-[1_1_0%]",
      hidden: !CLIENT_GRW,
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.ESTIMATION_CALCULATOR_LIST_GCTC,
      renderCell(row) {
        const totalCtc = row?.fields?.reduce((acc: any, el: any) => {
          return (
            acc +
            el?.developer?.growthSalaryPerHour * el?.duration * el?.hoursPerDay
          );
        }, 0);
        return (
          <span>
            &#8377;
            {totalCtc}
          </span>
        );
      },
    },
  ];
  return (
    <>
      <SideNavLayout>
        <EstimationCalculatorListing
          columns={columns}
          rows={items}
          navigate={navigate}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
        />
      </SideNavLayout>
    </>
  );
};

export default EstimationCalculatorListingWrapper;
