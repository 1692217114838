// |-- Built-in Dependencies --|
import React from "react";

// |-- External Dependencies --|
import { useDispatch, useSelector } from "react-redux";

// |-- Internal Dependencies --|
import LocationListView from "../../Components/LocationListView";
import { showToast } from "src/utils/showToaster";

// |-- Redux --|
import { RootState, AppDispatch } from "src/redux/store";
import {
  setSearchValue,
  setSelctedLocationState,
} from "src/redux/slices/StatesSlice";
import {
  setFilterValue,
  setSelectedLocationCity,
} from "src/redux/slices/CitySlice";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

// |-- Types --|
type Props = {
  states: any[];
  onAddNew: () => void;
};

const StateListing = ({ states, onAddNew }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { searchValue }: any = useSelector((state: RootState) => state.states);
  const { selectedLocationCountries }: any = useSelector(
    (state: RootState) => state.country
  );
  const { selectedLocationState }: any = useSelector(
    (state: RootState) => state.states
  );

  function handleCountryClick(newValue: any) {
    if (selectedLocationState?.value === newValue.value) {
      dispatch(setSelctedLocationState(null));
      dispatch(setSelectedLocationCity(null));
    } else {
      dispatch(setSelctedLocationState(newValue));
      dispatch(setFilterValue(newValue.value));
    }
  }

  return (
    <LocationListView
      searchValue={searchValue}
      OnSearchChange={(newValue) => dispatch(setSearchValue(newValue))}
      listHeading="State"
      listData={states}
      hideAddButton={
        !isAuthorized(UserModuleNameTypes.ACTION_STATE_ADD)
      }
      onAddClick={() => {
        if (selectedLocationCountries === null) {
          showToast("error", "Please select country");
        } else {
          onAddNew();
        }
      }}
      onListItemClick={(newValue) => {
        if (selectedLocationCountries !== null) {
          handleCountryClick(newValue);
        }
      }}
      disabled={false}
    />
  );
};

export default StateListing;
