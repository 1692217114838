import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { JobCreationFormProps } from "src/models/JobCreation.model";
import { useAddJobCreationMutation } from "src/services/JobCreationService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import JobCreationFormLayout from "../Layout/JobCreationFormLayout";

const AddJobCreationFormWrapper = () => {
  const navigate = useNavigate();
  const [addRecurringIncome] = useAddJobCreationMutation();
  // Form Initial Values

  const initialValues: JobCreationFormProps = {
    jobTitle: '',
    jobDescription: '',
    jobRequirements: '',
    jobLocation: '',
    salaryRange: "",
    department: '',
    employmentType: '',
    experienceLevel: '',
    educationalRequirements: '',
    benefits: '',
    applicationDeadline: null,
    postingDate: null,
    applicationLink: '',
    numberOfVacancies: "",
    minExperience: "",
    maxExperience: "",
    onBoardingBeforeDate: null,
    primarySkills: [],
    secondarySkills: [],
    immediateJoiner: '',
    maximumPackageLimit: '',
    workMode: ''
  };

  // Validation Schema
  const validationSchema = object().shape({
    jobTitle: string().required('Job title is required'),
    // jobDescription: string().required('Job description is required'),
    // jobRequirements: string().required('Job requirements are required'),
    // jobLocation: string().required('Job location is required'),
    // salaryRange: string().required('Salary range is required'),
    // numberOfVacancies: string().required('Number of vacancies is required'),
    // department: object().required('Department is required'),
    // employmentType: object().required('Employment type is required'),
    // experienceLevel: object().required('Experience level is required'),
    // educationalRequirements: string().required('Educational requirements are required'),
    // applicationDeadline: string().required('Application deadline is required'),
    // postingDate: string().required('Posting date is required'),
    // onBoardingBeforeDate: string().required('Onboarding before date is required'),
    // minExperience: string().required('Minimum experience is required'),
    // maxExperience: string().required('Maximum experience is required'),
    // maximumPackageLimit: string().required('Maximum package limit is required'),
    // applicationLink: string().required('Application link is required'),
    // workMode: object().required('Work Mode is required'),

  });

  // Handle Submit
  const handleSubmit = (
    values: JobCreationFormProps,
    { setSubmitting, resetForm }: FormikHelpers<JobCreationFormProps>
  ) => {
    const formattedValues = {
      jobTitle: values.jobTitle,
      jobDescription: values.jobDescription,
      jobRequirements: values.jobRequirements,
      jobLocation: values.jobLocation,
      salaryRange: values.salaryRange,
      department: values.department ? values.department?.value : null,
      employmentType: values.employmentType?.value,
      experienceLevel: values.experienceLevel?.value,
      educationalRequirements: values.educationalRequirements,
      benefits: values.benefits,
      applicationDeadline: values.applicationDeadline ? values.applicationDeadline.toISOString() : '',
      postingDate: values.postingDate ? values.postingDate.toISOString() : '',
      numberOfVacancies: values.numberOfVacancies,
      minExperience: values.minExperience,
      maxExperience: values.maxExperience,
      onBoardingBeforeDate: values.onBoardingBeforeDate ? values.onBoardingBeforeDate.toISOString() : '',
      primarySkills: values.primarySkills?.map((skill: any) => skill.value),
      secondarySkills: values.secondarySkills?.map((skill: any) => skill.value),
      immediateOrNoticePeriod: values.immediateJoiner?.value,
      workMode: values.workMode?.value,
      maximumPackageLimit: values.maximumPackageLimit,
    };
    addRecurringIncome(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate("/job-onboard/job-creation");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <JobCreationFormLayout
            formType="ADD"
            formikProps={formikProps}
            onClose={() => navigate("/finance/recurringincome")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddJobCreationFormWrapper;
