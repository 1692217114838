import { useState, useEffect } from "react";
import { useGetAllCitiesByStateIdQuery } from "src/services/CityServices";

const useStateCities = (stateId?: string) => {
  const [stateCities, setStateCities] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllCitiesByStateIdQuery(
    stateId || "",
    { skip: !stateId }
  );

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setStateCities(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { stateCities, isDataLoading };
};

export default useStateCities;
