import React from "react";
import ATMSelect from "../../atoms/formFields/ATMSelect/ATMSelect";
import { useGetFeaturesByModuleIdQuery } from "src/services/RequirementService";
import { Options, PropsValue } from "react-select";

type Option = {
  value: string;
  label: string;
};

type Props = {
  moduleId?: any;
  label?: string;
  required?: boolean;
  size?: "small" | "medium";
  name: string;
  isLoading?: boolean;
  searchable?: boolean;
  placeholder?: string;
  readonly?: boolean;
  onBlur?: any;
  keyOfProject: number;
  options?: Options<Option | any>;
  onChange: (selectedOption: Option | any) => void;
  value: PropsValue<Option | any>;
};

const MOLAffectedAreaFeatureDropdown = ({
  label = "",
  name,
  options,
  value,
  onChange,
  isLoading,
  required = false,
  placeholder = "Select",
  size = "small",
  keyOfProject,
  onBlur,
  searchable = false,
  readonly = false,
  moduleId,
}: Props) => {
  // get features
  const {
    isLoading: isFetureLoading,
    isFetching: isFeatureFetching,
    data,
  } = useGetFeaturesByModuleIdQuery(moduleId || "", {
    skip: !moduleId,
  });

  return (
    <ATMSelect
      name={name}
      label="Feature"
      value={value}
      onBlur={onBlur}
      placeholder="Select feature"
      isLoading={isFetureLoading || isFeatureFetching}
      options={data?.data?.map((ele: any) => {
        return {
          label: ele?.featureName,
          value: ele?._id,
        };
      })}
      onChange={onChange}
    />
  );
};

export default MOLAffectedAreaFeatureDropdown;
