import React, { useEffect, useState } from "react";
import RequirementGatheringUsersListing from "./RequirementGatheringUsersListing";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/RequirementGatheringUsersSlice";
import AddRequirementGatheringUsers from "../Add/AddRequirementGatheringUsers";
import { useDeleteRequirementGatheringUsersMutation, useListPaginationRequirementGatheringUsersQuery } from "src/services/RequirementGatheringService";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdDelete, MdEdit } from "react-icons/md";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import EditRequirementGatheringUsers from "../Edit/EditRequirementGatheringUsers";
import { useParams } from "react-router-dom";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const RequirementGatheringUsersListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const { requirementId } = useParams();
  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [selectedId , setSelectedId] = useState('')
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.requirementGatheringUsers);

    const [deleteUser] = useDeleteRequirementGatheringUsersMutation()

  const { data, isLoading, isFetching } =
    useListPaginationRequirementGatheringUsersQuery({
      limit: rowsPerPage,
      searchValue: searchValue,
      params: ["user"],
      page: page,
      filterBy: [
        {
          fieldName: "requirementGatheringId",
          value: requirementId,
        },
      ],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this User?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteUser(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    { field: "user", headerName: "Name", flex: "flex-[1_0_0%]" },
    {
      field: "action",
      headerName: "Action",
    flex: "flex-[1_0_0%]" ,
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true)
                  setSelectedId(row?._id)
                },
                acitonName: UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_EDIT,
               
              },
           
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_DELETE,

              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <RequirementGatheringUsersListing
        columns={columns}
        onAddNew={() => setIsOpenAddDialog(true)}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenAddDialog && (
        <AddRequirementGatheringUsers
          onClose={() => setIsOpenAddDialog(false)}
        />
      )}
      {isOpenEditDialog && (
        <EditRequirementGatheringUsers
         selectedId={selectedId}
         onClose={()=>setIsOpenEditDialog(false)}
        />

      )}
    </div>
  );
};

export default RequirementGatheringUsersListingWrapper;
