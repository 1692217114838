import moment from "moment";
type Props = {
  resourceRequestData: {
    count: number;
    pendingResourceRequest: any;
  };
  onResourceRequestClick: (resourceId: string) => void;
};
const ResourceList = ({
  resourceRequestData,
  onResourceRequestClick,
}: Props) => {
  return (
    <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg custom-shadow">
      <div className="flex items-center gap-2 p-2 border-b bg-cyan-50">
        <div className="flex items-center justify-center p-2 font-semibold bg-white border rounded-md border-primary-dark size-8">
          {resourceRequestData?.count}
        </div>
        <p className="font-medium">Resource Requests</p>
      </div>
      {resourceRequestData?.pendingResourceRequest?.length ? (
        <div className="h-full py-2 overflow-auto">
          {resourceRequestData?.pendingResourceRequest?.map(
            (resourceInfo: any, index: any) => (
              <div
                key={index}
                className="flex items-start justify-between px-4 py-1 gap-2 cursor-pointer hover:bg-hover"
                onClick={() => onResourceRequestClick(resourceInfo?._id)}
              >
                <p className=" flex-1 text-sm line-clamp-2">
                  {resourceInfo?.resourceName}
                </p>
                <div className="w-1/6  text-xs">
                  {resourceInfo?.createdByName} 
                </div>
                <div className="w-1/6 text-end  text-primary-main  text-sm font-medium">
                  {moment(resourceInfo?.createdAt).format("DD MMM YYYY")}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-4">
          No Resourse Requests
        </div>
      )}
    </div>
  );
};

export default ResourceList;
