import { useState, useEffect, ReactNode } from "react";
import EditTicketFormWrapper from "src/screens/Ticket/Edit/EditTicketFormWrapper";

// Redux
import { useGetSingleTicketsViaIdQuery } from "src/services/TicketServices";
import { useParams } from "react-router-dom";
import { RootState } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSingleItem } from "src/redux/slices/TicketSlice";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";

type Props = {};

const CustomSection = ({
  heading,
  value,
  formComponent,
}: {
  heading: string;
  value: string | ReactNode;
  formComponent?: any;
}) => {
  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2">
        <div className="text-base font-regular text-gray-600"> {heading} </div>
        {/* <div
          className="text-blue-500 underline cursor-pointer text-sm"
          onClick={() => setIsOpenFormDialog(true)}
        >
          Edit
        </div> */}
      </div>
      
      <div className="flex overflow-auto max-h-[100vh]">
        {/* Adjust max-h-40 to your desired max height */}
        {value}
      </div>

      {isOpenFormDialog ? (
        <EditTicketFormWrapper
          onClose={() => setIsOpenFormDialog(false)}
          formName={heading}
        >
          {formComponent}
        </EditTicketFormWrapper>
      ) : null}
    </div>
  );
};

const TicketCloseSummeryWrapper = (props: Props) => {
  // Get the Ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  // redux state
  const { singleItem } = useSelector((state: RootState) => state.ticket);

  // dispatch
  const dispatch = useDispatch();

  // Get Single Ticket Details
  const {
    isLoading: isTicketDetailsLoading,
    isFetching: isTicketDetailsFetching,
    data: ticketData,
  } = useGetSingleTicketsViaIdQuery(TICKET_ID || "", {
    skip: !TICKET_ID,
  });

  // Set Single Ticket Details
  useEffect(() => {
    if (!isTicketDetailsLoading && !isTicketDetailsFetching) {
      dispatch(setSingleItem(ticketData?.data || []));
    }
  }, [isTicketDetailsLoading, isTicketDetailsFetching, ticketData, dispatch]);

  return (
    <div className="p-4 flex flex-col gap-6 overflow-auto">
      <div className="grid grid-cols-2 gap-6">
        {/* Developers Involved */}
        <CustomSection
          heading="Developers Involved (in numbers)"
          value={
            <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100 text-center">
              {singleItem[0]?.numberOfDeveloper || "-"}
            </div>
          }
        />

        {/* Time Taken */}
        <CustomSection
          heading="Time Taken (in minutes)"
          value={
            <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100 text-center">
              {singleItem[0]?.timeTaken || "-"}
            </div>
          }
        />

        {/* Issue of origin */}
        <CustomSection
          heading="Issue of origin"
          value={
            <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100 text-center capitalize">
              {singleItem?.[0]?.issueFrom?.split("_")?.join(" ")}
            </div>
          }
        />

        {/* Responsible Members */}
        <CustomSection
          heading="Responsible Members"
          value={
            <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100 text-center">
              {singleItem[0]?.responsibleMembers?.length
                ? singleItem[0]?.responsibleMembers
                    ?.map((ele) => ele?.name)
                    ?.join(" , ")
                : "-"}
            </div>
          }
        />
      </div>

      {/* Remark */}
      <CustomSection
        heading="Remark"
        value={
          <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100">
            <ATMMarkdownContainer markdown={singleItem[0]?.remark || "-"} />
          </div>
        }
      />
      {/* Change Log */}
      <CustomSection
        heading="Change Log"
        value={
          <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100">
            <ATMMarkdownContainer markdown={singleItem[0]?.changeLog || "-"} />
          </div>
        }
      />
      {/* Reason of bug */}
      <CustomSection
        heading="Reason of bug"
        value={
          <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100">
            <ATMMarkdownContainer
              markdown={singleItem[0]?.reasonOfBug || "-"}
            />
          </div>
        }
      />
    </div>
  );
};

export default TicketCloseSummeryWrapper;
