import React from "react";
import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { PlanFormValues } from "src/models/Plan.model";
import { handleValidNumber } from "src/utils/validations/numberInput";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<PlanFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const PlanForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Plan" : "Update Plan"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Organisation Name */}
        <div>
          <ATMTextField
            required
            name="planName"
            value={values.planName}
            onChange={(e) => setFieldValue("planName", e.target.value)}
            label="Plan Name"
            placeholder="Enter Plan Name"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMTextField
            required
            name="amount"
            value={values.amount}
            onChange={(e) =>
              handleValidNumber(e) && setFieldValue("amount", e.target.value)
            }
            label="Amount"
            placeholder="Enter Amount"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMTextField
            required
            name="recurranceDays"
            value={values.recurranceDays}
            onChange={(e) =>
              handleValidNumber(e) &&
              setFieldValue("recurranceDays", e.target.value)
            }
            label="Recurrance Days"
            placeholder="Enter Recurrance Days"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default PlanForm;
