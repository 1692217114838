/* eslint-disable react-hooks/rules-of-hooks */
import { Tooltip } from "@mui/material";
import React from "react";
import { PiUserFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

type Task = {
  _id: string;
  taskNumber: number;
  taskGroupId: string;
  title: string;
  taskTitle: string;
  assignedTo: string;
};

type PropTypes = {
  boardTitle?: string;
  listData: Task[];
  MeetingData?: any;
  taskStatusLabelCounter: any;
  onClick?: (taskId: string, ticketId: string) => void;
  activeTab?:any | string;
};

const getTaskStatus = (status: string) => {
  switch (status) {
    case "OPEN":
      return {
        label: "Open",
        classes: "bg-blue-500 text-white rounded-xl px-2 py-[1px] ",
      };
    case "TODO":
      return {
        label: "Todo",
        classes: "bg-[#6695cc] text-white rounded-xl px-2 ",
      };
    case "IN_PROGRESS":
      return {
        label: "In Progress",
        classes: "bg-yellow-500 text-white rounded-xl px-2 py-[1px] ",
      };
    case "BUGS":
      return {
        label: "Bug",
        classes: "bg-rose-500 text-white rounded-xl px-2 py-[1px] ",
      };
    case "BLOCKING":
      return {
        label: "Blocking",
        classes: "bg-red-500 text-white rounded-xl px-2 py-[1px] ",
      };
    case "UAT_REVIEW":
      return {
        label: "UAT Review",
        classes: "bg-lime-500 text-white rounded-xl px-2 py-[1px] ",
      };
    case "DONE":
      return {
        label: "Done",
        classes: "bg-green-500 text-white rounded-xl px-2 py-[1px] ",
      };
    case "HOLD":
      return {
        label: "Hold",
        classes: "bg-slate-500 text-white rounded-xl px-2 py-[1px] ",
      };
    case "ISSUE":
      return {
        label: "Issue",
        classes: "bg-orange-500 text-white rounded-xl px-2 py-[1px] ",
      };
    case "COMPLETE":
      return {
        label: "Complete",
        classes: "bg-teal-500 text-white rounded-xl px-2 py-[1px] ",
      };
    case "CLOSE":
      return {
        label: "Close",
        classes: "bg-yellow-500 text-white rounded-xl px-2 py-[1px] ",
      };
  }
};
const BoardLayout: React.FC<PropTypes> = ({
  boardTitle,
  listData = [],
  MeetingData = [],
  taskStatusLabelCounter,
  activeTab,
  onClick = () => { },
}) => {
  const [specificTaskStatus, setSpecificTaskStatus] = React.useState<any[]>([]);
  const [selectSpecificTaskStatus, setSelectSpecificTaskStatus] =
    React.useState<string>("");

  const navigate = useNavigate();
  const handleClickSpecificTask = (array: any[], status: any) => {
    if (specificTaskStatus?.length) {
      setSpecificTaskStatus([]);
      setSelectSpecificTaskStatus("");
    } else {
      setSpecificTaskStatus(array);
      setSelectSpecificTaskStatus(status);
    }
  };

  let totalTime = 0;
  let totalMeetingTime = 0;
  let totalTaskTime = 0;

  // Calculate totalTime
  listData?.forEach((task: any) => {
    totalTaskTime += task?.totalDuration;
  });
  MeetingData?.forEach((task: any) => {
    totalMeetingTime += task?.meetingDurationInMins;
  });


  totalTime = totalTaskTime + totalMeetingTime
  return (
    <div
      className={`rounded-md flex flex-col h-full relative z-10 bg-cyan-50 border overflow-hidden ${listData?.length === 0 ? "min-w-fit" : "md:min-w-[25%] min-w-[85%] md:w-[25%] w-[85%]"
        }`}
    >
      {listData?.length === 0 ? (
        <div className="flex flex-col gap-4 items-center p-2 bg-gray-50 h-full">
          <div className="border p-2 rounded-full">
            <PiUserFill size={16} className="rounded-full mx-auto" />
          </div>
          <div className="rounded-full cursor-pointer flex items-center gap-2 font-medium text-xs bg-gray-300 px-2 py-1">
            {listData?.length ? listData?.length : "0"}
          </div>
          <div className="text-sm font-semibold text-gray-500 vertical rotate-180">
            {boardTitle}
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center border-b  bg-cyan-200 p-2">
            {/* Developer Name Card */}
            <div className="flex items-center gap-3 text-center">
              <div className="bg-white p-2 rounded-full">
                <PiUserFill size={16} className="rounded-full mx-auto" />
              </div>
              <div className="text-sm font-semibold text-gray-800">
                {boardTitle}
              </div>
            </div>
            {/* Ticket Counter */}

            {activeTab === "COMPLETE" ? (
              `${totalTime?.toFixed()} min (${Math?.floor(totalTime / 60)}:${(totalTime % 60)?.toFixed()} hrs)`
            ) : (
              <div className="status-cunter flex items-center px-2 py-1 rounded-lg text-sm bg-primary-dark text-white relative">
                <div className="rounded-full cursor-pointer flex items-center gap-2 font-medium">
                  {listData?.length ? listData?.length : "0"}
                </div>

                <div className="absolute right-0 top-full mx-auto status-card z-[10000000]">
                  <div className="py-2 bg-transparent">
                    <div className="border w-[250px] mx-auto bg-white rounded-md shadow-lg">
                      <div className="flex items-center justify-between border-b p-2 py-1">
                        <h2 className="text-sm text-primary-main font-medium">
                          All Task Status
                        </h2>
                      </div>

                      <div className="grid grid-cols-1 gap-2 p-2">
                        <div
                          className={`border-l-2 border-blue-800 hover:bg-blue-50 px-2 py-2 flex items-center justify-between ${selectSpecificTaskStatus?.includes("OPEN") &&
                            "bg-blue-50"
                            }`}
                          onClick={() => {
                            handleClickSpecificTask(
                              taskStatusLabelCounter?.OPEN,
                              "OPEN"
                            );
                          }}
                        >
                          <span className="text-blue-800 text-sm font-semibold">
                            Open
                          </span>
                          <span className="text-blue-800 text-sm font-semibold">
                            {taskStatusLabelCounter?.OPEN?.length}
                          </span>
                        </div>

                        <div
                          className={`border-l-2 border-yellow-800 hover:bg-yellow-50 px-2 py-2 flex items-center justify-between ${selectSpecificTaskStatus?.includes("IN_PROGRESS") &&
                            "bg-yellow-50"
                            }`}
                          onClick={() => {
                            handleClickSpecificTask(
                              taskStatusLabelCounter?.IN_PROGRESS,
                              "IN_PROGRESS"
                            );
                          }}
                        >
                          <span className="text-yellow-800 text-sm font-semibold">
                            In Progress
                          </span>
                          <span className="text-yellow-800 text-sm font-semibold">
                            {taskStatusLabelCounter?.IN_PROGRESS?.length}
                          </span>
                        </div>

                        <div
                          className={`border-l-2 border-purple-800 hover:bg-purple-50 px-2 py-2 flex items-center justify-between ${selectSpecificTaskStatus?.includes("HOLD") &&
                            "bg-purple-50"
                            }`}
                          onClick={() => {
                            handleClickSpecificTask(
                              taskStatusLabelCounter?.HOLD,
                              "HOLD"
                            );
                          }}
                        >
                          <span className="text-purple-800 text-sm font-semibold">
                            Hold
                          </span>
                          <span className="text-purple-800 text-sm font-semibold">
                            {taskStatusLabelCounter?.HOLD?.length}
                          </span>
                        </div>

                        <div
                          className={`border-l-2 border-indigo-800 hover:bg-indigo-50 px-2 py-2 flex items-center justify-between ${selectSpecificTaskStatus?.includes("COMPLETE") &&
                            "bg-indigo-50"
                            }`}
                          onClick={() => {
                            handleClickSpecificTask(
                              taskStatusLabelCounter?.COMPLETE,
                              "COMPLETE"
                            );
                          }}
                        >
                          <span className="text-indigo-800 text-sm font-semibold">
                            Complete
                          </span>
                          <span className="text-indigo-800 text-sm font-semibold">
                            {taskStatusLabelCounter?.COMPLETE?.length}
                          </span>
                        </div>

                        <div
                          className={`border-l-2 border-red-800 hover:bg-red-50 px-2 py-2 flex items-center justify-between ${selectSpecificTaskStatus?.includes("BLOCKING") &&
                            "bg-red-50"
                            }`}
                          onClick={() => {
                            handleClickSpecificTask(
                              taskStatusLabelCounter?.BLOCKING,
                              "BLOCKING"
                            );
                          }}
                        >
                          <span className="text-red-800 text-sm font-semibold">
                            Block
                          </span>
                          <span className="text-red-800 text-sm font-semibold">
                            {taskStatusLabelCounter?.BLOCKING?.length}
                          </span>
                        </div>

                        <div
                          className={`border-l-2 border-green-800 hover:bg-green-50 px-2 py-2 flex items-center justify-between ${selectSpecificTaskStatus?.includes("CLOSE") &&
                            "bg-red-50"
                            }`}
                          onClick={() => {
                            handleClickSpecificTask(
                              taskStatusLabelCounter?.CLOSE,
                              "CLOSE"
                            );
                          }}
                        >
                          <span className="text-green-800 text-sm font-semibold">
                            Close
                          </span>
                          <span className="text-green-800 text-sm font-semibold">
                            {taskStatusLabelCounter?.CLOSE?.length}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/*  Task Listing */}
          <div className="p-3 flex flex-col gap-2 min-w-[100%] overflow-y-auto">
            {specificTaskStatus?.length
              ? specificTaskStatus?.map((task: any, ind: any) => (
                <div
                  key={task.taskNumber}
                  className="bg-white shadow-sm border rounded transform transition-transform hover:translate-y-[-2px] cursor-pointer"
                >
                  <div className="p-4" style={{ userSelect: "text" }}>
                    <h3
                      onClick={() => onClick(task?._id, task?.ticketId)}
                      className="task-title text-md min-w-[100%] hover:underline font-medium"
                    >
                      {activeTab === "COMPLETE"
                        ? task?.taskTitle
                        : task?.title}
                    </h3>

                    <div className="flex gap-2 pt-1">
                      <span
                        className="task-id text-xs font-normal"
                        style={{ userSelect: "text" }}
                      >
                        #{task?.taskNumber}
                      </span>
                      <span
                        className={`${getTaskStatus(task?.status)?.classes
                          } text-xs font-semibold`}
                      >
                        {getTaskStatus(task?.status)?.label}
                      </span>
                    </div>
                  </div>
                </div>
              ))
              : [...listData, ...MeetingData]?.map((task: any, ind: any) => {
                return task?.title && activeTab === "COMPLETE" ? (
                  <Tooltip title={`Meeting : ${task?.title}`}>
                    <div
                      key={task.taskNumber}
                      className="bg-white shadow-sm border rounded transform transition-transform hover:translate-y-[-2px] cursor-pointer"
                    >
                      <div className="text-xs text-black ">
                        {!task?.ticketName
                          ? `${task?.projectName} | ${task?.moduleName} | ${task?.featureName}`
                          : `${task?.projectName} | ${task?.ticketName}`}
                      </div>
                      <div className="p-2" style={{ userSelect: "text" }}>
                        <div className="flex justify-between gap-4">
                          <h3
                            onClick={() =>
                              navigate(`/meeting/${task?._id}/agenda`)
                            }
                            className="task-title text-md hover:underline line-clamp-2 font-medium"
                          >
                            {task?.taskTitle || task?.title}
                          </h3>
                          <h5 className="font-medium">
                            {task?.totalDuration ||
                              task?.meetingDurationInMins}{" "}
                            min
                          </h5>
                        </div>
                        <div className="flex gap-2 pt-1">
                          {task?.taskNumber ? (
                            <span
                              className="task-id text-xs font-normal"
                              style={{ userSelect: "text" }}
                            >
                              #{task?.taskNumber}
                            </span>
                          ) : null}
                          <span
                            className={`${getTaskStatus(task?.taskStatus)?.classes} text-xs font-semibold`}
                          >
                            {getTaskStatus(task?.taskStatus)?.label}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    key={task.taskNumber}
                    className="bg-white shadow-sm border rounded transform transition-transform hover:translate-y-[-2px] cursor-pointer "
                  >
                    <div className="text-xs text-black">
                      {!task?.ticketName
                        ? `${task?.projectName} | ${task?.moduleName} | ${task?.featureName}`
                        : `${task?.projectName} | ${task?.ticketName}`}
                    </div>
                    <div className="p-4" style={{ userSelect: "text" }}>
                      <div className="flex min-w-[100%] justify-between gap-4">
                        <h3
                          onClick={() => onClick(task?._id, task?.ticketId)}
                          className="task-title text-md hover:underline font-medium line-clamp-2 w-full"
                        >
                          {activeTab === "COMPLETE"
                            ? task?.taskTitle || task?.title
                            : task?.title}
                        </h3>
                        {activeTab === "COMPLETE" ? (
                          <h5 className="font-medium">
                            {task?.totalDuration ||
                              task?.meetingDurationInMins}{" "}
                            min
                          </h5>
                        ) : null}
                      </div>
                      <div className="flex gap-2 pt-1">
                        {task?.taskNumber ? (
                          <span
                            className="task-id text-xs font-normal"
                            style={{ userSelect: "text" }}
                          >
                            #{task?.taskNumber}
                          </span>
                        ) : null}
                        <span
                          className={`${getTaskStatus(activeTab === "COMPLETE" ? task?.taskStatus : task?.status)?.classes} text-xs font-semibold`}
                        >
                          {
                            getTaskStatus(
                              activeTab === "COMPLETE"
                                ? task?.taskStatus
                                : task?.status
                            )?.label
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default BoardLayout;
