import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { ProductFormValues } from "src/models/Product.model";
import { useAddProductMutation } from "src/services/ProductService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import ProductForm from "../Layout/ProductForm";

type Props = {
  onClose: () => void;
};

const AddProductFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addProduct] = useAddProductMutation();

  // Form Initial Values
  const initialValues: ProductFormValues = {
    productName: "",
    cost: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    productName: string().required("Please enter product name"),
    cost: string().test("cost-test", "Please enter amount", (value) => {
      return Number(value || 0) > 0;
    }),
  });

  // Handle Submit
  const handleSubmit = (
    values: ProductFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ProductFormValues>
  ) => {
    addProduct({
      productName: values?.productName,
      cost: Number(values?.cost),
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ProductForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddProductFormWrapper;
