import React from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import MeetingMarkAsDoneForm from "./QuickMeetingMarkAsDoneForm";
import { useMeetingMarkAsDoneMutation } from "src/services/MeetingServices";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  meetingId: string;
  onClose: () => void;
  actualStartTime?: string;
  actualEndTime?: string;
  meetingType?: any;
};

export type MeetingMarkAsDoneFormValues = {
  actualStartTime?: any;
  actualEndTime?: any;
  endDate: any;
  startDate: any;

  title: string;
  projectId: any;
};

const QuickMeetingMarkAsDoneFormWrapper = ({
  meetingId,
  meetingType,
  actualEndTime,
  actualStartTime,
  onClose,
}: Props) => {
  const [updateMarkAsDone] = useMeetingMarkAsDoneMutation();
  const navigate = useNavigate();

  // Form Initial Values
  const initialValues: MeetingMarkAsDoneFormValues = {
    actualStartTime: actualStartTime ? actualStartTime : "",
    actualEndTime: actualEndTime ? actualEndTime : moment(),
    endDate: actualEndTime ? moment(actualEndTime).format("YYYY MM DD") : moment(actualStartTime).format("YYYY MM DD"),
    startDate: actualStartTime ? moment(actualStartTime).format("YYYY MM DD") : "",
    title: "",
    projectId: "",
  };

  const location = useLocation();
  const currentPath = location.pathname;

  // Validation Schema
  const validationSchema = object().shape({
    actualStartTime: string().required("Actual Start Time is required"),
    actualEndTime: string().required("Actual End Time is required"),
    // actualEndTime: string()
    // .required('Actual End Time is required')
    // .test(
    //   "arrTimeTest",
    //   "Actual End Time cannot be in the future",
    //   (value, context) => {
    //     if (!value) return false;

    //     const currentTime = moment().format('HH:mm A');
    //     const selectedTime = moment(value, 'HH:mm A');

    //     // The test should return false if the selected time is in the future
    //     return !selectedTime.isAfter(currentTime);
    //   }
    // ),
    endDate: string().required("Actual End Date is required"),
    startDate: string().required("Actual Start Date is required"),
    title: string().required("Please enter title"),
    projectId: object().required("Please select project"),
  })
  // Handle Submit
  const handleSubmit = (
    values: MeetingMarkAsDoneFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingMarkAsDoneFormValues>
  ) => {
    const formatedValues = {
      actualEndDate: moment(values?.endDate).format("YYYY-MM-DD"),
      actualStartDate: moment(values?.startDate).format("YYYY-MM-DD"),
      title: values?.title || '',
      projectId: values?.projectId?.value || {},
      actualStartTime: moment(`${moment(values?.startDate).format("YYYY-MM-DD")} ${moment(values?.actualStartTime).format("HH:mm:ss")}`).format("YYYY-MM-DD HH:mm:ss"),
      actualEndTime: moment(`${moment(values?.endDate).format("YYYY-MM-DD")} ${moment(values?.actualEndTime).format("HH:mm:ss")}`).format("YYYY-MM-DD HH:mm:ss"),
    };
    updateMarkAsDone({
      id: meetingId,
      body: formatedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
          // Conditionally navigate based on the current path
          if (currentPath === "/") {
            navigate("/");
          } else {
            navigate("/meeting");
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <MeetingMarkAsDoneForm
              meetingType={meetingType}
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default QuickMeetingMarkAsDoneFormWrapper;
