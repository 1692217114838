import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { RxLapTimer } from "react-icons/rx";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
// import {
//   useGetProjectByIdQuery,
//   useGetProjectTaskStatusCountQuery,
//    useGetProjectsQuery,
// } from "src/services/ProjectServices";
import { GrStatusUnknown } from "react-icons/gr";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import AccessDenied from "src/utils/AccessDenied";
import { IoIosPeople } from "react-icons/io";
// import ScreenFeatureWrapper from "./tabs/ScreenAndFeature/List/ScreenFeatureWrapper";
import ScreenAndFeatureWrapper from "src/screens/ScreenAndFeatures/ScreenAndFeatureWrapper/ScreenAndFeatureWrapper";
import { useGetPlatformsByIdQuery } from "src/services/RequirementGatheringService";

type Props = {};

export const inquiriesEditTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
  {
    label: "DETAILS",
    icon: FaUser,
    path: "details",
    moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS,
  },
  {
    label: "TIME-ANALYTICS",
    icon: RxLapTimer,
    path: "project-analytics/tasks",
    moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_TIME_ANALYTICS,
  },
  {
    label: "STATUS",
    icon: GrStatusUnknown,
    path: "status",
    moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_STATUS,
  },
  {
    label: "RESOURCES",
    icon: VscGitPullRequestCreate,
    path: "resource-request",
    moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_RESOURCE_REQUEST,
  },
  {
    label: "FLOW",
    icon: VscGitPullRequestCreate,
    path: "flow",
    moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_FLOW,
  },
  {
    label: "MEMBERS",
    icon: IoIosPeople,
    path: "members",
    moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_MEMBERS,
  },
  {
    label: "Screen And Feature",
    icon: IoIosPeople,
    path: "screen-feature",
    // moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_MEMBERS
  },
];

const PlatformViewTabsLayout = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {  platformId } = useParams();
  const currentPath = location.pathname.split("/")[5];
  const { userData } = useGetLocalStorage();

  // api imports
  const { data, isLoading, isFetching } = useGetPlatformsByIdQuery(platformId);

  //states
  const [plateformData, setPlatformData] = useState<any>(null);
  const [showProjectList, setShowProjectList] = useState<any>(false);

  const handleProjectNameClick = () => {
    setShowProjectList(!showProjectList);
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setPlatformData(data?.data || null);
    }
  }, [isLoading, isFetching, data]);

  // const {
  //   isLoading: projectIsLoading,
  //   isFetching: projectIsFetching,
  //   data: projectData,
  // } = useGetProjectByIdQuery(id || "", {
  //   skip: !id,
  // });

  // useEffect(() => {
  //   if (!projectIsLoading && !projectIsFetching) {
  //     setProjectItems(projectData?.data || []);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [projectIsLoading, projectIsFetching, projectData]);

  React.useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("open");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of inquiriesEditTabs) {
      const isValue = isAuthorized(
        nav?.moduleName as keyof typeof UserModuleNameTypes
      );
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav?.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabsRender = inquiriesEditTabs?.some((nav) => {
    return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
  });

  const checkScreenAndFeatureTab = currentPath === "screen-feature";

  return (
    <>
      {checkScreenAndFeatureTab ? (
        <ScreenAndFeatureWrapper />
      ) : (
        <SideNavLayout>
          {tabsRender ? (
            <div className="flex flex-col h-full">
              <div className="flex flex-col justify-between gap-4 p-4 pb-0 border-b border-divider">
                <div
                  className="font-semibold capitalize"
                  onClick={handleProjectNameClick}
                >
                  {plateformData?.platformName}
                </div>
                {/* inquiriesEditTabs */}
                {inquiriesEditTabs?.length && (
                  <div className="flex items-center bg-white">
                    {inquiriesEditTabs
                      ?.filter((nav) => {
                        return isAuthorized(
                          nav?.moduleName as keyof typeof UserModuleNameTypes
                        );
                      })
                      ?.map((tab: any, index: any) => {
                        return (
                          <div key={index}>
                            <button
                              type="button"
                              onClick={() => navigate(tab.path)}
                              className={`relative flex gap-2 py-2 px-4 border-b-2 border-transparent text-xs min-w-fit font-medium hover:text-primary-dark items-center h-full transition-all duration-500 
                            ${
                              currentPath === tab.path?.split("/")[0]
                                ? "border-b-2 !border-primary-dark"
                                : "text-neutral"
                            }
                            `}
                            >
                              <div className="text-sm">
                                <tab.icon />
                              </div>
                              {tab.label}
                            </button>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              <div className="overflow-auto grow bg-blue-surface">
                <Outlet />
              </div>
            </div>
          ) : (
            <AccessDenied />
          )}
        </SideNavLayout>
      )}
    </>
  );
};

export default PlatformViewTabsLayout;
