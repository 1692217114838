import React from "react";

export enum EnumClientSideTicketStatus {
  "IN_PROGRESS" = "In Progress",
  "OPEN" = "Open",
  "CLOSE" = "Close",
  "HOLD" = "Hold",
  "DOUBT" = "Doubt",
}

export type ClientSideTicketStatusType =
  keyof typeof EnumClientSideTicketStatus;

type Props = {
  status: ClientSideTicketStatusType;
};

const getClassNameByStatus = (status: ClientSideTicketStatusType) => {
  switch (status) {
    case "OPEN":
      return "text-yellow-600 border-yellow-600 bg-yellow-100";

    case "IN_PROGRESS":
      return "text-green-600 border-green-600 bg-green-100";

    case "CLOSE":
      return "text-red-600 bg-red-100 border-red-600";
    case "HOLD":
      return "text-slate-600 border-slate-600 bg-slate-100";
    case "DOUBT":
      return "text-orange-600 border-orange-600 bg-orange-100";
  }
};

const MOLClientSideTicketStatusChip = ({ status }: Props) => {
  const className = ` 
      font-medium
    rounded-full
    px-2
    py-[2px]
    text-[10px]
    border-[1px]
    ${getClassNameByStatus(status)}`;

  return (
    <div className={className}> {EnumClientSideTicketStatus[status]} </div>
  );
};

export default MOLClientSideTicketStatusChip;
