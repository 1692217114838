import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetModulesByProjectIdQuery } from "src/services/ModuleServices";
import { useGetAllRequirementByModuleIdQuery } from "src/services/RequirementService";
import ProjectDetails from "./ProjectDetails";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

const PlatFormProjectDetailsWrapper = () => {
  // params
  const navigate = useNavigate();
  const { platformId } = useParams();
  // query
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // // Get the value of the 'moduleId' query parameter
  const moduleId = queryParams.get("moduleId");
  const featureId = queryParams.get("featureId");

  const [moduleItems, setModuleItems] = useState<any>([]);
  const [featureItems, setFeatureItems] = useState<any>([]);

  const {
    isLoading: moduleIsLoading,
    isFetching: moduleIsFetching,
    data: moduleData,
  } = useGetModulesByProjectIdQuery(platformId);

  const {
    isLoading: featureIsLoading,
    isFetching: featureIsFetching,
    data: featureData,
  } = useGetAllRequirementByModuleIdQuery(moduleId || moduleItems?.[0]?._id, {
    skip: !(moduleId || moduleItems?.[0]?._id),
  });

  useEffect(() => {
    if (!moduleIsLoading && !moduleIsFetching) {
      if (moduleData && moduleData.data) {
        setModuleItems(moduleData.data);
      } else {
        // Handle the case where moduleData or moduleData.data is null or undefined
        setModuleItems([]);
        // Depending on your requirements, you may want to handle navigation differently
        // or show an error message to the user.
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleIsLoading, moduleIsFetching, moduleData]);

  useEffect(() => {
    if (!featureIsLoading && !featureIsFetching) {
      setFeatureItems(featureData?.data);
      // (moduleId && featureData?.data[0]?._id)
      // &&
    } else {
      setFeatureItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureIsLoading, featureIsFetching, featureData, moduleData]);
  useEffect(() => {
    let currentPath = ""
    if (
      isAuthorized(UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS_FEATURES_DETAILS_TAB)
    ) {
      currentPath = "feature-details"
    } else if (isAuthorized(UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS_CHANFGE_REQUEST_TAB)) {
      currentPath = "task"
    }
    else if (isAuthorized(UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS_TASK_TAB)) {
      currentPath = "change-request"
    }

    if (
      featureItems &&
      featureItems?.length &&
      (moduleId || moduleItems?.[0]?._id)
    ) {
      let isFeatureExist =
        featureItems?.findIndex(
          (feature: any) =>
            feature?.moduleId === (moduleId || moduleItems?.[0]?._id)
        ) > -1;
      !location?.search?.includes("task") &&
        isFeatureExist &&
        navigate(
          `/project/${platformId}/details?moduleId=${moduleId || moduleItems?.[0]?._id
          }&featureId=${featureItems[0]?._id}&tab=${currentPath}`
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId, featureItems]);

  return (
    <>
      <ProjectDetails
        moduleId={moduleId || moduleItems?.[0]?._id}
        id={platformId}
        featureId={featureId}
        moduleItems={moduleItems}
        featureItems={featureItems}
        moduleIsLoading={moduleIsLoading}
        featureIsLoading={featureIsLoading}
      />
    </>
  );
};

export default PlatFormProjectDetailsWrapper;
