import React from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  onAddNew: () => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const LeadsFollowUpListing = ({
  columns,
  rows,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  return (
    <div className="flex flex-col h-full">
      {/* Page Header */}
      <div>
        <ATMPageHeader
          pageTitle="Leads FollowUp"
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          hideAddButton={
            !isAuthorized(
              UserModuleNameTypes.ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP_ADD
            )
          }
          buttonProps={{
            btnName: "Add New",
            onClick: onAddNew,
          }}
        />
      </div>

      {/* Table */}
      <div className="z-0 flex flex-col flex-1 overflow-auto">
        <ATMTable
          extraClasses="h-[60vh]"
          columns={columns}
          rows={rows}
          rowExtraClasses={() => "h-[50px]"}
          isLoading={isTableLoading}
        />
      </div>

      <div>
        <ATMPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          rowCount={totalItems}
          rows={rows}
        />
      </div>
    </div>
  );
};

export default LeadsFollowUpListing;
