/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { IconType } from "react-icons";
import { FaUserPlus } from "react-icons/fa6";
import { MdDomain, MdOutlineFeaturedPlayList } from "react-icons/md";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import AccessDenied from "src/utils/AccessDenied";
import { isAuthorized } from "src/utils/authorization";

export const inquiriesEditTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
    {
      label: "User",
      icon: FaUserPlus,
      path: "user",
      moduleName: UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB,
    },
    {
      label: "Platforms",
      icon: MdDomain,
      path: "platforms",
      moduleName: UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORMS_TAB,
    },
    {
      label: "Features",
      icon: MdOutlineFeaturedPlayList,
      path: "features",
      moduleName: UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_FEATURES_TAB,
    },
  ];

const RequirementGatheringLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname.split("/")[4];
  const { userData } = useGetLocalStorage();


  useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("agenda");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of inquiriesEditTabs) {
      const isValue = isAuthorized(
        nav?.moduleName as keyof typeof UserModuleNameTypes
      );
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tabsRender = inquiriesEditTabs?.some((nav) => {
    return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
  });

  useEffect(() => {
    if (!currentPath) {
      navigate("user");
    }
  }, []);
  return (
    <SideNavLayout>
      {tabsRender ? (

        <div className="flex flex-col h-full">
          {/* inquiriesEditTabs */}
          {inquiriesEditTabs?.length && (
            <div className="flex items-center gap-3 px-4 pt-2 bg-white border-b">
              
              {inquiriesEditTabs?.filter((nav) => {
                      return isAuthorized(
                        nav?.moduleName as keyof typeof UserModuleNameTypes
                      );
                    })?.map((tab: any, index: any) => {
                return (
                  <div key={index}>
                    <button
                      type="button"
                      onClick={() => navigate(tab.path)}
                      className={`h-full px-4 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${currentPath === tab.path?.split("/")[0]
                          ? "text-primary-main   border-primary-main"
                          : "text-gray-700 border-white"
                        }
         `}
                    >
                      <div className="text-lg">
                        <tab.icon />
                      </div>
                      {tab.label}
                    </button>
                  </div>
                );
              })}
            </div>
          )}

          <div className="h-full overflow-auto grow">
            <Outlet />
          </div>
        </div>
      ) : 
        <AccessDenied />
      }
    </SideNavLayout>
  );
};

export default RequirementGatheringLayout;
