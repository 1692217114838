import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetRequirementGatheringFormByIdQuery,
  useGetRequirementGatheringFormQuery,
  useListPaginationRequirementGatheringPlatformsQuery,
  useListPaginationRequirementGatheringUsersQuery,
} from "src/services/RequirementGatheringService";
import { object } from "yup";
import FeaturesListing from "./FeaturesListing";

export type FeaturesListingValues = {
  label: string;
  user: any;
  platform: any;
  description: any;
};

const AddRequirementGatheringPlatforms = () => {
  const { requirementId } = useParams();
  const location = useLocation();
  const requirentId = new URLSearchParams(location.search);
  const activeId = requirentId.get("id");

  const [users, setUsers] = useState<any>();
  const [features, setFeatures] = useState<any>();
  const [requirements, setRequirements] = useState<any>();
  const [singlerequirements, setSingleRequirements] = useState<any>();

  const { data, isLoading, isFetching } =
    useListPaginationRequirementGatheringUsersQuery({
      limit: 10,
      searchValue: "",
      params: ["user"],
      page: 1,
      filterBy: [
        {
          fieldName: "requirementGatheringId",
          value: requirementId,
        },
      ],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: false,
    });

  const {
    data: featureData,
    isLoading: featureDataIsLoading,
    isFetching: featureDataIsFetching,
  } = useListPaginationRequirementGatheringPlatformsQuery({
    limit: 10,
    searchValue: "",
    params: ["platformName"],
    page: 1,
    filterBy: [
      {
        fieldName: "requirementGatheringId",
        value: requirementId,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  const {
    data: requirementData,
    isLoading: requirementDataIsLoading,
    isFetching: requirementDataIsFetching,
  } = useGetRequirementGatheringFormQuery({
    limit: 10,
    searchValue: "",
    params: ["action"],
    page: 1,
    filterBy: [
      {
        fieldName: "requirementGatheringId",
        value: requirementId,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  const {
    data: singleRequirementData,
    isLoading: singleRequirementDataIsLoading,
    isFetching: singleRequirementDataIsFetching,
  } = useGetRequirementGatheringFormByIdQuery(activeId, {
    skip: !activeId,
  });

  useEffect(() => {
    if (!singleRequirementDataIsLoading && !singleRequirementDataIsFetching) {
      setSingleRequirements(singleRequirementData?.data);
    }
  }, [
    singleRequirementData,
    singleRequirementDataIsFetching,
    singleRequirementDataIsLoading,
  ]);

  useEffect(() => {
    if (!requirementDataIsFetching && !requirementDataIsLoading) {
      setRequirements(requirementData?.data);
    }
  }, [requirementData, requirementDataIsLoading, requirementDataIsFetching]);

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setUsers(data?.data);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (!featureDataIsFetching && !featureDataIsLoading) {
      setFeatures(featureData?.data);
    }
  }, [featureData, featureDataIsLoading, featureDataIsFetching]);
  // Form Initial Values
  const initialValues: FeaturesListingValues = {
    label: singlerequirements?.action || "",
    user:
      {
        value: singlerequirements?.reqUserId,
        label: singlerequirements?.reqUserName,
      } || {},
    platform:
      {
        value: singlerequirements?.reqPlatformId,
        label: singlerequirements?.reqPlatformName,
      } || {},
    description: singlerequirements?.description || "",
  };
  // Validation Schema
  const validationSchema = object().shape({
    user: object().required("Please select user"),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {(formikProps) => (
        <Form className="h-full">
          <FeaturesListing
            users={users}
            requirementId={requirementId}
            isLoading={
              singleRequirementDataIsLoading || singleRequirementDataIsFetching
            }
            activeId={activeId}
            sideNavLayoutIsLoading={
              requirementDataIsLoading || requirementDataIsFetching
            }
            requirements={requirements}
            features={features}
            formikProps={formikProps}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddRequirementGatheringPlatforms;
