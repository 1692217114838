/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import { AddTicketFormValues } from "src/models/Ticket.model";
import {
  useGetSingleTicketsViaIdQuery,
  useAddTicketMutation,
} from "src/services/TicketServices";
import TicketForm from "../Layout/TicketForm";
import { convertIntoCapitalize } from "src/utils/common/capitalize";
import { useAddTaskMutation } from "src/services/TaskService";
import { FILE_FORMAT } from "src/models/ResourceRequest.model";

type Props = {
  ticketId: string;
  onClose: () => void;
};

const DuplicateTicketsFormWrapper = ({ ticketId, onClose }: Props) => {
  const [ticketData, setTicketData] = useState<any>();
  const [addTicket] = useAddTicketMutation(); // add ticket
  const [addTask] = useAddTaskMutation(); // add task
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [isCheckedAddTask, setIsCheckedAddTask] = useState<boolean>(false);

  // Get Single Ticket Details
  const { isLoading, isFetching, data } = useGetSingleTicketsViaIdQuery(
    ticketId,
    {
      skip: !ticketId,
    }
  );

  // Set Single Ticket Details
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTicketData(data?.data?.[0]);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: AddTicketFormValues = {
    projectId: {
      _id: ticketData?.projectId,
      projectName: ticketData?.projectLabel,
    },
    title: ticketData?.title,
    attachments : [],
    priority: {
      label: convertIntoCapitalize(ticketData?.priority),
      value: ticketData?.priority,
    },
    changeRequestId: "",
    description: ticketData?.description,
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select project"),
    title: string().required("Please enter title"),
    // changeRequestId: object().required("Please select change request"),
    priority: object().required("Please select priority"),
  });

  // Handle Submit
  const handleSubmit = (
    values: AddTicketFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AddTicketFormValues>
  ) => {
    const { projectId, priority,attachments, changeRequestId, ...rest } = values;
    const formatedValue = {
      ...rest,
      projectId: projectId?._id,
      attachments: values?.attachments?.map(
        (attachment: {
          url: string;
          type: "images" | "pdfs" | "videos" | "xlsx";
        }) => {
          return {
            url: attachment?.url,
            type: FILE_FORMAT[attachment.type],
          };
        }
      ),
      priority: priority?.value,
      changeRequestId: changeRequestId.value,
    };

    // Add Ticket
    addTicket(formatedValue).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });

    const formattedValuesForTask = {
      title: values.title,
      description: values.description,
      relatedTo: "TICKET",
    };

    isCheckedAddTask &&
      addTask(formattedValuesForTask).then((res: any) => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Dialog open maxWidth="xs" fullWidth>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <TicketForm
                setSaveNextChecked={setSaveNextChecked}
                saveNextChecked={saveNextChecked}
                formikProps={formikProps}
                isCheckedAddTask={isCheckedAddTask}
                setIsCheckedAddTask={setIsCheckedAddTask}
                formType="ADD"
                onClose={onClose}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default DuplicateTicketsFormWrapper;
