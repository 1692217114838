import { useEffect } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/OperationsSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useDeleteOperationMutation,
  useGetOperationPaginationQuery,
} from "src/services/OperationsService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import OperationsList from "./OperationsList";

type Props = {};

const OperationsListingWrapper = (props: Props) => {
  const navigate = useNavigate();
  const [deleteOperation] = useDeleteOperationMutation();

  const dispatch = useDispatch<AppDispatch>();
  const { page, items, totalItems, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.operation);

  const { data, isLoading, isFetching } = useGetOperationPaginationQuery({
    limit: rowsPerPage,
    page: page,
    params: ["title", "description"],
    searchValue: searchValue,
    dateFilter: {},
    orderByValue: -1,
    orderBy: "createdAt",
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Features As Operation ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteOperation(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "title",
      headerName: "Title",
      flex: "flex-[1_1_0%]",
      extraClasses: "z-0 min-w-[200px]",
      name: UserModuleNameTypes.FEATURE_AS_OPERATIONS_TITLE,
      renderCell: (row) => {
        return <div>{row?.title || "-"}</div>;
      },
    },
    {
      field: "businessModels",
      headerName: "Business model",
      flex: "flex-[1_1_0%]",
      extraClasses: "z-0 min-w-[200px]",
      name: UserModuleNameTypes.FEATURE_AS_OPERATIONS_BUSINESS_MODEL,
      renderCell: (row) => {
        return (
          <div className="flex gap-2">
            {row?.businessModels
              ? row?.businessModels?.map((business: any) => (
                <div className="">{business?.modelName || "-"}</div>
              ))
              : "-"}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  navigate(`edit/${row?._id}`);
                },
                acitonName: UserModuleNameTypes.ACTION_FEATURE_AS_OPERATIONS_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_FEATURE_AS_OPERATIONS_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <OperationsList
        columns={columns}
        rows={items}
        addNew={() => navigate("add")}
        onRowClick={(operationId) => navigate(`view/${operationId}`)}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default OperationsListingWrapper;
