import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { DeliveryDateFormValues } from "src/models/DeliveryDate.model";
import { useAddDeliveryDateMutation } from "src/services/DeliveryDateService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import DeliveryDateForm from "../Layouts/DeliveryDateForm";

type Props = {
  onClose: () => void;
};

const AddDelliveryDateWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addDeliveryDate] = useAddDeliveryDateMutation();

  // Form Initial Values
  const initialValues: DeliveryDateFormValues = {
    projectId: "",
    clientId: "",
    remark: "",
    deliveryDate: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select project"),
    clientId: object().required("Please select client"),
    remark: string().required("Please enter remark"),
    deliveryDate: string().required("Please select delivery date"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DeliveryDateFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DeliveryDateFormValues>
  ) => {
    let formattedValues = {
      ...values,
      projectId: values?.projectId?.value || "",
      clientId: values?.clientId?._id,
    };
    addDeliveryDate(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <DeliveryDateForm
            formikProps={formikProps}
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};
export default AddDelliveryDateWrapper;
