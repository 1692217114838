import apiSlice from "./ApiSlice";

export const jobsCreationStageServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Stages of Job Creation
    getAllJobCreationStage: builder.query({
      providesTags: ["job-creation"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/job-stage",
          method: "POST",
          body,
        };
      },
    }),
    //Add   Stages of Job Creation
    addJobCreationStage: builder.mutation({
      invalidatesTags: ["job-creation"],
      query: (body) => {
        return {
          url: "job-stage/add",
          method: "POST",
          body,
        };
      },
    }),
    //Delete
    deleteJobCreationStage: builder.mutation({
      invalidatesTags: ["job-creation"],
      query: (id) => {
        return {
          url: `job-stage/${id}`,
          method: "DELETE",
        };
      },
    }),

    //Get By Id
    getJobCreationStageById: builder.query({
      providesTags: ["job-creation"],
      query: (id) => {
        return {
          url: `job-stage/${id}`,
          method: "GET",
        };
      },
    }),
    //Update
    JobCreationStageUpdate: builder.mutation({
      invalidatesTags: ["job-creation"],
      query: ({ body, id }) => {
        return {
          url: `job-stage/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
      //Add Interview Notes
      addInterviewNotes: builder.mutation({
        invalidatesTags: ["job-creation"],
        query: (body) => {
          return {
            url: "/application-notes/add-notes",
            method: "POST",
            body,
          };
        },
      }),
      // Get Interview Notes
      getInterviewNotes: builder.query({
        providesTags: ["job-creation"],
        query: (body) => {
          return {
            url: "/application-notes",
            method: "POST",
            body,
          };
        },
      }),
  }),
});

export const {
  useAddJobCreationStageMutation,
  useGetAllJobCreationStageQuery,
  useDeleteJobCreationStageMutation,
  useGetJobCreationStageByIdQuery,
  useJobCreationStageUpdateMutation,
  useAddInterviewNotesMutation ,
  useGetInterviewNotesQuery
} = jobsCreationStageServiceApi;
