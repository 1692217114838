import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { MeetingTypeFormValues } from "src/models/MeetingType.model";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import MeetingTypeForm from "../Layouts/MeetingTypeForm";
import { CircularProgress } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useEditMeetingTypeByIdMutation, useGetMeetingTypeByIdQuery } from "src/services/MeetingTypeService";

type Props = {
  onClose: () => void;
  selectedMeetingTypeId: any;
};

const EditMeetingTypeWrapper = ({ onClose, selectedMeetingTypeId }: Props) => {
  const [meetingType, setMeetingType] = useState<any>({});
  const [updateMeetingType] = useEditMeetingTypeByIdMutation();
  const { isLoading, isFetching, data } = useGetMeetingTypeByIdQuery(
    selectedMeetingTypeId,
    {
      skip: !selectedMeetingTypeId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setMeetingType(data?.data);
    }
  }, [isLoading, isFetching, data]);


  // Form Initial Values
  const initialValues: MeetingTypeFormValues = {
    meetingType: meetingType?.meetingType || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    meetingType: string().required("Please enter meeting  type"),
  });

  // Handle Submit
  const handleSubmit = (
    values: MeetingTypeFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingTypeFormValues>
  ) => {
    updateMeetingType({ id: selectedMeetingTypeId, body: values }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <MeetingTypeForm
              formikProps={formikProps}
              onClose={onClose}
              formType="EDIT"
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditMeetingTypeWrapper
