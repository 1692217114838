import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { object } from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { isMobile } from "react-device-detect";
import FinanceManagementForm from "../Layout/AccountForm";
import {
  useGetFinanceAccountsByIdQuery,
  useUpdateFinanceAccountsMutation,
} from "src/services/Finance-New-Services/AccountServices";
import { AccountFormValues } from "src/models/Account.model";

type Props = {
  selectedSubscription: string;
  onClose: () => void;
};

const EditFinanceManagementWrapper = ({
  selectedSubscription,
  onClose,
}: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetFinanceAccountsByIdQuery(selectedSubscription);

  const [updateAccount] = useUpdateFinanceAccountsMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: AccountFormValues = {
    accountName: items?.accountName || "",
    accountType: {
      label: items?.accountType,
      value: items?.accountType,
    },
    description: items?.description || "",
    openinBalance: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    accountType: object().required("Please select account type"),
  });

  const handleSubmit = (
    values: AccountFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AccountFormValues>
  ) => {
    const formattedValues = {
      accountName: values?.accountName,
      accountType: values?.accountType.value,
      description: values?.description,
    };

    updateAccount({
      id: selectedSubscription,
      body: formattedValues,
    }).then((res: any) => {
      updateAccount({
        id: selectedSubscription,
        body: formattedValues,
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
    });
  };

  return (
    <Dialog open maxWidth="sm" fullWidth fullScreen={isMobile}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <FinanceManagementForm
              formType="EDIT"
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditFinanceManagementWrapper;
