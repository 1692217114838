import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AllTaskSliceStateType = {
  items: any[] | [];
  labels: any[] | [];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  userFilterValue: any;
  taskAssignmentStatus: any;
  taskStatus: any;
  dueDateFilterValue: string | null;
  projectFilterValue: any;
  moduleFilterValue: any;
  featureFilterValue: any;
  LabelFilterValue: any;
  layerFilterValue: any;
  createdByValue: any;
  isArchived: boolean;
  filterBy: {
    fieldName: string;
    value: string[];
  }[];
  dateFilter: {
    start_date: string | null;
    end_date: string | null;
  };
  open: boolean;
};

const initialState: AllTaskSliceStateType = {
  items: [],
  labels: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  rowsPerPage: 20,
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  userFilterValue: "",
  taskStatus: "",
  dueDateFilterValue: null,
  projectFilterValue: "",
  taskAssignmentStatus: "",
  moduleFilterValue: "",
  featureFilterValue: "",
  LabelFilterValue: "",
  layerFilterValue: "",
  createdByValue: "",
  isArchived: false,
  filterBy: [],
  dateFilter: {
    start_date: null,
    end_date: null,
  },
  open: false,
};

const allTaskSlice: Slice<AllTaskSliceStateType> = createSlice({
  name: "allTaskSlice",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setLabels: (state, action: PayloadAction<any[] | []>) => {
      state.labels = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string[] }[]>
    ) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setDateFilter: (
      state,
      action: PayloadAction<{ start_date: string; end_date: string }>
    ) => {
      state.dateFilter = action.payload;
    },

    setUserValueFilter: (state, action: PayloadAction<string[]>) => {
      state.userFilterValue = action.payload;
    },

    setTaskStatusValueFilter: (state, action: PayloadAction<string[]>) => {
      state.taskStatus = action.payload;
    },

    setProjectValueFilter: (state, action: PayloadAction<string[]>) => {
      state.projectFilterValue = action.payload;
    },

    setModuleValueFilter: (state, action: PayloadAction<string[]>) => {
      state.moduleFilterValue = action.payload;
    },

    setFeatureValueFilter: (state, action: PayloadAction<string[]>) => {
      state.featureFilterValue = action.payload;
    },
    setLabelValueFilter: (state, action: PayloadAction<[{}]>) => {
      state.LabelFilterValue = action.payload;
    },
    setLayerValueFilter: (state, action: PayloadAction<[{}]>) => {
      state.layerFilterValue = action.payload;
    },
    setDueDateFilter: (state, action: PayloadAction<string | null>) => {
      state.dueDateFilterValue = action.payload;
    },
    setTaskAssignmentStatus: (state, action: PayloadAction<string | null>) => {
      state.taskAssignmentStatus = action.payload;
    },

    setCreatedByValueFilter: (state, action: PayloadAction<[{}]>) => {
      state.createdByValue = action.payload;
    },
    setIsArchivedFilter: (state, action: PayloadAction<boolean>) => {
      state.isArchived = action.payload;
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const {
  setOpen,
  setItems,
  setLabels,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setFilterBy,
  setUserValueFilter,
  setTaskStatusValueFilter,
  setDueDateFilter,
  setProjectValueFilter,
  setModuleValueFilter,
  setLabelValueFilter,
  setFeatureValueFilter,
  setTaskAssignmentStatus,
  setCreatedByValueFilter,
  setLayerValueFilter,
  setIsArchivedFilter,
  seto,
} = allTaskSlice.actions;
export default allTaskSlice.reducer;
