import { useMoveTaskToArchiveMutation } from "src/services/TaskService"
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";

const useMoveTaskToArchive=()=>{
    const [moveTaskToArchive] = useMoveTaskToArchiveMutation()
    const handleMoveTaskToArchive = (taskId: string) => {
        showConfirmationDialog({
          title: "Hands Up",
          text: "Are you sure, you want to move this task to Archive?",
          icon: "question",
          showCancelButton: true,
          next: (result) => {
            if (result?.isConfirmed) {
              moveTaskToArchive(taskId).then((res: any) => {
                if (res.error) {
                  showToast("error", res?.error?.data?.message);
                } else {
                  showToast("success", res?.data?.message);
                }
              });
            }
          },
        });
      }; 
      return {
        handleMoveTaskToArchive
      }
      

}
export default useMoveTaskToArchive