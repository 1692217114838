import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import SkillForm from "../Layouts/SkillForm";
import { SkillFormValues } from "../Add/AddSkillFormWrapper";
import {
  useUpdateSkillMutation,
  useGetSkillQuery,
} from "src/services/SkillService";
import { showToast } from "src/utils/showToaster";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  skillId: string;
};

const EditSkillFormWrapper = ({ onClose, skillId }: Props) => {
  const [skillData, setSkillData] = useState<any>();

  const { data, isLoading, isFetching } = useGetSkillQuery(skillId, {
    skip: !skillId,
  });

  const [updateSkill] = useUpdateSkillMutation();

  useEffect(() => {
    setSkillData(data?.data);
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: SkillFormValues = {
    skillName: skillData?.skillName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    skillName: string().required("Please enter skill"),
  });

  // Handle Submit
  const handleSubmit = (
    values: SkillFormValues,
    { setSubmitting, resetForm }: FormikHelpers<SkillFormValues>
  ) => {
    updateSkill({
      id: skillId,
      body: values,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <SkillForm
            formikProps={formikProps}
            onClose={onClose}
            formType="UPDATE"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditSkillFormWrapper;
