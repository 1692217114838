import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";

import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import { CircularProgress } from "@mui/material";
import DumpForm from "../Layouts/DomainForm";
import { DumpFormValues } from "src/models/Dump.model";
import {
  useGetSingleDumpQuery,
  useUpdateDumpMutation,
} from "src/services/DumpService";

type Props = {
  onClose: () => void;
  selecetedDumpId: any;
};

const EditDumpFormWrapper = ({ onClose, selecetedDumpId }: Props) => {
  const [dumpData, setDumpData] = useState<any>({});

  const { isLoading, isFetching, data } = useGetSingleDumpQuery(
    selecetedDumpId,
    {
      skip: !selecetedDumpId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setDumpData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const [updateDomain] = useUpdateDumpMutation();

  // Form Initial Values
  const initialValues: DumpFormValues = {
    projectId: {
      label: dumpData?.projectName,
      value: dumpData?.projectId,
    },
    title: dumpData?.title || "",
    layerRemark: dumpData?.layerRemark || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter dump name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DumpFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DumpFormValues>
  ) => {
    const formattedValues = {
      projectId: values?.projectId?.value,
      title: values?.title,
      layerRemark: values?.layerRemark,
    };
    updateDomain({ id: selecetedDumpId, body: formattedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <DumpForm
            formikProps={formikProps}
            onClose={onClose}
            formType="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditDumpFormWrapper;
