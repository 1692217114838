
import ATMTextField from 'src/components/UI/atoms/formFields/ATMTextField/ATMTextField'
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { isAuthorized } from 'src/utils/authorization';
import { UserModuleNameTypes } from 'src/models/UserAccess/UserAccess.model';
// |-- Types --|
type Props = {
    listHeading: string
    onAddClick: () => void
    listData: any[]
    searchValue?: string
    OnSearchChange?: (newValue: string) => void
    onListItemClick?: (item: any) => void
    disabled: boolean
    actionName?: string
    featureId?: any;
    featureIsLoading?: any
}

const LocationListView = ({
    listHeading,
    onAddClick,
    listData,
    searchValue = '',
    OnSearchChange = (newValue: string) => { },
    onListItemClick = (item: any) => { },
    disabled = false,
    featureIsLoading = false,
    actionName = '',
    featureId = " ",
}: Props) => {
    const navigate = useNavigate();

    return (
        <div className="border h-full w-full flex flex-col gap-1 rounded bg-white shadow-lg ">
            <div className="border-b  text-slate-600 px-2 text-lg h-[50px] flex items-center justify-between font-semibold ">
                {listHeading}
                {
                    isAuthorized(UserModuleNameTypes.ACTION_FEATURES_ADD) &&
                    <button
                        type="button"
                        disabled={disabled}
                        className="flex items-center gap-2 text-primary-main  text-sm h-[33px] px-4 rounded hover:bg-slate-100"
                        onClick={() => {
                            onAddClick()
                        }}
                    >
                        {' '}
                        + Add{' '}
                    </button>
                }
            </div>

            <div className="px-2 border-b">
                <ATMTextField
                    name=""
                    value={searchValue}
                    onChange={(e) => {
                        OnSearchChange(e.target.value)
                    }}
                    placeholder="Search"
                    className="h-[30px] border-none"
                />
            </div>

            <div className="max-h-[calc(100%-100px)]  overflow-hidden hover:overflow-auto">
                {featureIsLoading ? (
                    <div className="flex justify-center items-center h-[400px]">
                        <CircularProgress />{" "}
                    </div>
                ) : listData?.length ? (
                    <>
                        {listData?.map((listItem, listItemIndex) => {

                            if (searchValue) {
                                return (listItem?.featureName?.toLowerCase()?.includes(searchValue?.toLowerCase()) ?

                                    <div
                                        key={listItemIndex}
                                        onClick={() => {
                                            onListItemClick(listItem);
                                            navigate(`/project/${listItem?.projectId}/details?moduleId=${listItem?.moduleId}&featureId=${listItem?._id}&tab=feature-details`, {

                                            });
                                        }}
                                        className={`border-b border-slate-100 py-1 px-2 text-black-500 cursor-pointer text-sm ${listItem._id !== undefined &&
                                            (featureId ===
                                                listItem._id
                                            )
                                            ? 'bg-gray-300'
                                            : ''
                                            }`}
                                    >
                                        {listItem.featureName}
                                    </div>

                                    : null)
                            } else {
                                return (
                                    <div
                                        key={listItemIndex}
                                        onClick={() => {
                                            onListItemClick(listItem);
                                            navigate(`/project/${listItem?.projectId}/details?moduleId=${listItem?.moduleId}&featureId=${listItem?._id}&tab=feature-details`, {

                                            });
                                        }}
                                        className={`border-b border-slate-100 py-1 px-2 text-black-500 cursor-pointer text-sm ${listItem._id !== undefined &&
                                            (featureId ===
                                                listItem._id
                                            )
                                            ? 'bg-gray-300'
                                            : ''
                                            }`}
                                    >
                                        {listItem.featureName}
                                    </div>

                                )
                            }

                        })}
                    </>
                ) :
                    (<div className='p-2 m-5 font-bold text-slate-400'> NO DATA FOUND </div>)
                }
            </div>
        </div>
    )
}

export default LocationListView
