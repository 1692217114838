import React from "react";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";

type Props = {
  onClose: () => void;
  data: any;
  isLoading: any;
};

const PointerLogsFlowDialog = ({ onClose, data, isLoading }: Props) => {
 
  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"></div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      {isLoading ? (
        <div>
          <div>
            <h3 className="flex justify-center font-semibold text-[18px] pb-2">
              Incident Status Flow
            </h3>
            <div className="grid grid-cols-2  my-4 mx-2">
              <div className="text-[16px] border-x-2 border-t-2">
                <div className="text-[16px] border-b-2 py-1 text-center text-cyan-500 font-semibold">
                  Date-Time
                </div>
                <div className=" text-center text-[14px ] border-b  pt-2">
                  {Array(3)
                    .fill(0)
                    .map((_, index) => {
                      return (
                        <div
                          key={index}
                          className="animate-pulse  h-[100px] border-b p-2"
                        >
                          <div className="bg-slate-200 h-full rounded"> </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="text-[16px] border-t-2 border-r-2">
                <div className="text-[16px] border-b-2 py-1 text-center font-semibold text-cyan-500">
                  Remark
                </div>
                <div className=" text-center text-[14px ] border-b  pt-2">
                  {Array(3)
                    .fill(0)
                    .map((_, index) => {
                      return (
                        <div
                          key={index}
                          className="animate-pulse  h-[100px] border-b p-2"
                        >
                          <div className="bg-slate-200 h-full rounded"> </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ):   <div>
      <div>
        <h3 className="flex justify-center font-semibold text-[18px] pb-2">
          Incident Status Flow
        </h3>
        <div className="grid grid-cols-2  my-4 mx-2">
          <div className="text-[16px] border-x-2 border-t-2">
            <div className="text-[16px] border-b-2 py-1 text-center text-cyan-500 font-semibold">
              Date-Time
            </div>
            <div>
              {data?.map((el: any, ind: any) => {
                return (
                  <div
                    key={ind}
                    className="h-[100px] flex justify-center items-center text-[14px ] border-b  pt-2"
                  >
                    <div className="text-slate-700 font-medium"></div>
                    <div className="text-[13px] font-medium text-slate-400">
                      <div className="text-slate-700 font-medium">
                        {el?.createdAt
                          ? formatedDateTimeIntoIst(
                              el?.createdAt,
                              "DD MMM yyyy"
                            )
                          : "-"}
                      </div>
                      <div className="text-[13px] font-medium text-slate-400">
                        {formatedDateTimeIntoIst(el?.createdAt, "hh:mm A")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text-[16px] border-t-2 border-r-2">
            <div className="text-[16px] border-b-2 py-1 text-center font-semibold text-cyan-500">
              Remark
            </div>
            <div>
              {data?.map((el: any, ind: any) => {
                return (
                  <div
                    key={ind}
                    className="h-[100px] flex justify-center items-center text-[14px ] border-b pt-2 max-h-[250px]  overflow-scroll"
                  >
                    <p className={` font-medium py-[10px] px-[8px]`}>
                      {el?.remark}{" "}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>}
    
    </div>
  );
};

export default PointerLogsFlowDialog;
