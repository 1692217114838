import { uuidv4 } from "@mdxeditor/editor";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import {
  setSelectedData,
  setSelectedPlatformData,
  setIsTableLoading,
  // setRequirementGathering,
} from "src/redux/slices/RequirementGatheringNewSlice";
import { RootState } from "src/redux/store";
import {
  useAddFunctionalityInnerScreenMutation,
  useAddScreenMutation,
  useDeleteScreenFunctionalityMutation,
  useDeleteScreenMutation,
  useEditPlatformsMutation,
  useGetPlatformsByIdQuery,
  useUpdateScreenByIdMutation,
  useUpdateScreenFunctionalityByIdMutation,
} from "src/services/RequirementGatheringService";
import { showToast } from "src/utils/showToaster";
import {
  TbChevronDown,
  TbChevronRight,
  TbEdit,
  TbFocusCentered,
  TbGrid3X3,
  TbLayoutGrid,
  TbTrash,
} from "react-icons/tb";
import { ClickAwayListener, Divider } from "@mui/material";
import PrintScreenDeatails from "../PrintScreenDetails/PrintScreenDeatails";
import { useReactToPrint } from "react-to-print";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import ATMIconButton from "src/components/UI/atoms/ATMIconButton/ATMIconButton";

type Node = {
  _id: string;
  screenName: string;
  groupName?: string;
  functionalityName?: string;
  functionalityDescription?: string;
  screenDescription?: string;
  groupDescription?: string;
  nodeType: "group" | "screen" | "functionality";
  parent: string; // parent node ID
  children?: Node[];
};

//Navigation build tree
const buildTree = (nodes: Node[]): Node[] => {
  const nodeMap: Record<string, Node> = {};
  const tree: Node[] = [];

  // Populate nodeMap with nodes and ensure immutability
  nodes?.forEach((node) => {
    nodeMap[node?._id] = { ...node, children: [] }; // Clone node and initialize children array
  });

  // Organize nodes into tree structure
  nodes?.forEach((node) => {
    if (node.parent && nodeMap[node.parent]) {
      const parentNode = nodeMap[node.parent]!;
      parentNode.children = [
        ...(parentNode.children || []),
        nodeMap[node?._id],
      ]; // Use immutable update
    } else {
      // If node has no parent or parent is not found, it is a root node
      tree.push(nodeMap[node?._id]);
    }
  });

  return tree;
};

const ScreenAndFeaturesSidebar: React.FC = ({ item, level = 0 }: any) => {
  const { platformId } = useParams();
  const dispatch = useDispatch();
  const meetingMOMRef = useRef(null);

  //API Calls
  //screen
  const [addScreen] = useAddScreenMutation();
  const [updateScreen] = useUpdateScreenByIdMutation();
  const [deleteScreen] = useDeleteScreenMutation();

  //platform
  const [updatePlatform] = useEditPlatformsMutation();

  //functionality
  const [addFunctionality] = useAddFunctionalityInnerScreenMutation();
  const [updateFunctionality] = useUpdateScreenFunctionalityByIdMutation();
  const [deleteScreenFunctionality] = useDeleteScreenFunctionalityMutation();

  const { data, isLoading, isFetching } = useGetPlatformsByIdQuery(platformId);

  //   states
  const [plateformData, setPlatformData] = useState<any>(null);
  const [seletedItem, setSeletedItem] = useState<any>();
  const [inputValue, setInputValue] = useState<any>(
    item?.groupName || item?.screenName || item?.functionalityName || ""
  );
  const [isEditing, setIsEditing] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>(
    {}
  );

  const getFormattedData = ({
    type,
    heading,
    description,
    parent,
    _id,
  }: any) => {
    return {
      platFormId: selectedPlatformData?._id,
      type: type,
      data: {
        heading: heading,
        description: description,
      },
      parent: parent,
      _id: _id,
    };
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setPlatformData(data?.data || null);
    }
  }, [isLoading, isFetching, data]);

  const {
    requirementGatheringData,
    selectedPlatformData,
    // searchValue,
    selectedData,
    isTableLoading,
  } = useSelector((state: RootState) => state.requirementGatheringNew);

  // Group
  const handleAddgroup = (platformId: any, parent?: any) => {
    const parentId = (() => {
      if (seletedItem?.nodeType === "functionality") {
        return seletedItem?.parent;
      }

      if (seletedItem?.nodeType === "group") {
        return seletedItem?._id;
      }
      if (seletedItem?.nodeType === "screen") {
        return seletedItem?._id;
      }

      return null;
    })();

    const newGroup = {
      _id: uuidv4(),
      groupName: "custom group",
      groupDescription: "",
      customGroup: true,
      nodeType: "group",
      parent: parentId,
      // parent: parent ? parent : seletedItem?._id,
    };

    const updatedPlatformData = {
      platformName: plateformData.platformName,
      workspaceId: plateformData.workspaceId,
      groups: [...plateformData?.groups, newGroup],
    };

    // Update the platform with the new group data
    updatePlatform({
      platformId: platformId,
      body: updatedPlatformData,
    }).then((updateRes: any) => {
      if (updateRes?.error) {
        showToast("error", updateRes?.error?.data?.message);
      } else {
        showToast("success", updateRes?.data?.message);

        setSeletedItem(newGroup);
        setIsEditing(true);
        setInputValue("");
        handleToggleCollapse(seletedItem?._id || parent, true);
        dispatch(setSelectedPlatformData(updatedPlatformData));
      }
    });
  };

  const handleUpdategroup = (item: any) => {
    const updatedPlatformData = {
      platformName: plateformData.platformName,
      workspaceId: plateformData.workspaceId,
      groups: plateformData.groups.map((group: any) =>
        group?._id === item?._id ? { ...group, groupName: inputValue } : group
      ),
    };

    updatePlatform({
      platformId: platformId,
      body: updatedPlatformData,
    }).then((updateRes: any) => {
      if (updateRes?.error) {
        showToast("error", updateRes?.error?.data?.message);
      } else {
        showToast("success", updateRes?.data?.message);
        setIsEditing(false);
        setInputValue("");
        dispatch(setSelectedPlatformData(updatedPlatformData));
      }
    });
  };

  const handleDeletegroup = (groupId: any) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this group?",
      icon: "question",
      showCancelButton: true,
      next: (result: any) => {
        if (result?.isConfirmed) {
          const updatedPlatformData = {
            platformName: plateformData.platformName,
            workspaceId: plateformData.workspaceId,
            groups: plateformData.groups.filter(
              (group: any) => group?._id !== groupId
            ),
          };

          updatePlatform({
            platformId: platformId,
            body: updatedPlatformData,
          }).then((updateRes: any) => {
            if (updateRes?.error) {
              showToast("error", updateRes?.error?.data?.message);
            } else {
              showToast("success", updateRes?.data?.message);
              dispatch(setSelectedPlatformData(updatedPlatformData));
            }
          });
        }
      },
    });
  };

  // Screen
  const handleAddScreen = (platformId: any, parent?: any) => {
    const parentId = (() => {
      if (seletedItem?.nodeType === "screen") {
        return seletedItem.parent;
      }

      if (seletedItem?.nodeType === "group") {
        return seletedItem?._id;
      }

      if (seletedItem?.nodeType === "functionality") {
        return seletedItem?.parent;
      }

      return null;
    })();
    if (seletedItem?.nodeType === "functionality") {
      showToast("error", "Cannot add screen inside Functionality");
    } else {
      addScreen({
        platformId: platformId,
        screenName: `New Screen `,
        screenDescription: "",
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          showToast("success", res?.data?.message);

          const newScreen = {
            screenName: res?.data?.data?.screenName,
            screenDescription: res?.data?.data?.screenDescription,
            screenListOrder: res?.data?.data?.screenListOrder,
            workspaceId: res?.data?.data?.workspaceId,
            workspaceName: res?.data?.data?.workspaceName,
            platformId: res?.data?.data?.platformId,
            platformName: res?.data?.data?.platformName,
            _id: res?.data?.data?._id,
            nodeType: "screen",
            parent: parentId,
          };

          const updatedPlatformData = {
            platformName: plateformData.platformName,
            workspaceId: plateformData.workspaceId,
            groups: [...plateformData?.groups, newScreen],
          };

          // Update the platform with the new group data
          updatePlatform({
            platformId: platformId,
            body: updatedPlatformData,
          }).then((updateRes: any) => {
            if (updateRes?.error) {
              showToast("error", updateRes?.error?.data?.message);
            } else {
              showToast("success", updateRes?.data?.message);
              setSeletedItem(newScreen);
              setIsEditing(true);
              setInputValue("");
              handleToggleCollapse(seletedItem?._id || parent, true);
              dispatch(setSelectedPlatformData(updatedPlatformData));
            }
          });
        }
      });
    }
  };

  const handleUpdateScreen = (item: any) => {
    updateScreen({
      id: item?._id,
      body: {
        platformId: platformId,
        screenName: inputValue,
      },
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);

          const updatedPlatformData = {
            platformName: selectedPlatformData.platformName,
            workspaceId: selectedPlatformData.workspaceId,
            groups: selectedPlatformData.groups.map((group: any) =>
              group._id === res.data.data._id
                ? { ...group, screenName: inputValue }
                : group
            ),
          };

          updatePlatform({
            platformId: platformId,
            body: updatedPlatformData,
          }).then((updateRes: any) => {
            if (updateRes?.error) {
              showToast("error", updateRes?.error?.data?.message);
            } else {
              showToast("success", updateRes?.data?.message);
              setIsEditing(false);
              dispatch(setSelectedPlatformData(updatedPlatformData));
              const updatedData = getFormattedData({
                type: "screen",
                _id: res.data.data._id,
                heading: inputValue,
                description: "",
                parent: item.parent,
              });
              setInputValue("");
              dispatch(setSelectedData(updatedData));
            }
          });
        } else {
          showToast("error", res?.data?.message);
        }
      }
    });
  };

  const handleDeleteScreen = (screenId: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this screen?",
      icon: "question",
      showCancelButton: true,
      next: (result: any) => {
        if (result?.isConfirmed) {
          deleteScreen(screenId).then((res: any) => {
            if (res?.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);

              const updatedGroups = plateformData?.groups?.filter(
                (group: any) => group?._id !== screenId
              );

              const updatedPlatformData = {
                platformName: plateformData.platformName,
                workspaceId: plateformData.workspaceId,
                groups: updatedGroups,
              };

              updatePlatform({
                platformId: platformId,
                body: updatedPlatformData,
              }).then((updateRes: any) => {
                if (updateRes?.error) {
                  showToast("error", updateRes?.error?.data?.message);
                } else {
                  showToast("success", updateRes?.data?.message);

                  // Optionally update local state with the new platform data
                  dispatch(setSelectedPlatformData(updatedPlatformData));
                }
              });

              dispatch(setSelectedPlatformData(updatedPlatformData));
              dispatch(setSelectedData(null));
            }
          });
        }
      },
    });
  };

  //Functionality
  const handleAddFunctionality = (screenId: any) => {
    const parentId = (() => {
      if (seletedItem?.nodeType === "functionality") {
        return seletedItem?.parent;
      }

      if (seletedItem?.nodeType === "screen") {
        return screenId || seletedItem?._id;
      }

      return null;
    })();

    addFunctionality({
      screenId: parentId,
      // : screenId || seletedItem?._id,
      functionalityName: `New Functionality`,
      functionalityDescription: "",
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        showToast("success", res?.data?.message);

        const newFunctionality = {
          _id: res?.data?.data?._id,
          nodeType: "functionality",
          functionalityName: res?.data?.data?.functionalityName,
          parent: parentId,
          screenId: parentId,
        };

        const updatedPlatformData = {
          platformName: plateformData.platformName,
          workspaceId: plateformData.workspaceId,
          groups: [...plateformData?.groups, newFunctionality],
        };

        // Update the platform with the new group data
        updatePlatform({
          platformId: platformId,
          body: updatedPlatformData,
        }).then((updateRes: any) => {
          if (updateRes?.error) {
            showToast("error", updateRes?.error?.data?.message);
          } else {
            showToast("success", updateRes?.data?.message);
            setSeletedItem(newFunctionality);
            setIsEditing(true);
            setInputValue("");
            handleToggleCollapse(screenId, true);
            // Optionally update local state with the new platform data
            dispatch(setSelectedPlatformData(updatedPlatformData));
          }
        });
      }
    });
  };

  const handleUpdateFuncationality = (item: any) => {
    updateFunctionality({
      id: item?._id,
      body: {
        screenId: item?.parent,
        functionalityName: inputValue,
      },
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);

          const updatedPlatformData = {
            platformName: selectedPlatformData.platformName,
            workspaceId: selectedPlatformData.workspaceId,
            groups: selectedPlatformData.groups.map((group: any) =>
              group._id === res.data.data._id
                ? { ...group, functionalityName: inputValue }
                : group
            ),
          };

          updatePlatform({
            platformId: platformId,
            body: updatedPlatformData,
          }).then((updateRes: any) => {
            if (updateRes?.error) {
              showToast("error", updateRes?.error?.data?.message);
            } else {
              showToast("success", updateRes?.data?.message);
              setIsEditing(false);
              dispatch(setSelectedPlatformData(updatedPlatformData));
              const updatedData = getFormattedData({
                type: "functionality",
                _id: res.data.data._id,
                heading: inputValue,
                description: "",
                parent: item.parent,
              });
              setInputValue("");
              dispatch(setSelectedData(updatedData));
            }
          });
        } else {
          showToast("error", res?.data?.message);
        }
      }
    });
  };

  const handleDeleteFunctionality = (functionalityId: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this functionality?",
      icon: "question",
      showCancelButton: true,
      next: (result: any) => {
        if (result?.isConfirmed) {
          deleteScreenFunctionality(functionalityId).then((res: any) => {
            if (res?.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
              const updatedGroups = plateformData.groups.filter(
                (group: any) => group?._id !== functionalityId
              );
              const updatedPlatformData = {
                platformName: plateformData.platformName,
                workspaceId: plateformData.workspaceId,
                groups: updatedGroups,
              };
              updatePlatform({
                platformId: platformId,
                body: updatedPlatformData,
              }).then((updateRes: any) => {
                if (updateRes?.error) {
                  showToast("error", updateRes?.error?.data?.message);
                } else {
                  showToast("success", updateRes?.data?.message);

                  // Optionally update local state with the new platform data
                  dispatch(setSelectedPlatformData(updatedPlatformData));
                }
              });
              dispatch(setSelectedPlatformData(updatedPlatformData));
              dispatch(setSelectedData(null));
            }
          });
        }
      },
    });
  };

  // this function sends the seleted nav item data to redux state
  const handleClick = (item: any) => {
    if (item.nodeType === "group") {
      setSeletedItem(item);
      dispatch(setSelectedData(null));
    } else {
      const updatedData = getFormattedData({
        type: item.nodeType,
        _id: item?._id,
        heading: item.screenName || item.groupName || item?.functionalityName,
        description:
          item?.screenDescription ||
          item.groupDescription ||
          item.functionalityDescription,
        parent: item?.parent,
      });
      setSeletedItem(item);
      dispatch(setSelectedData(updatedData));
    }
  };

  //Navigation Item
  type NavItemProps = {
    item: Node;
    level?: number;
  };

  const handleToggleCollapse = (nodeId: string, payload: boolean) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [nodeId]: payload,
    }));
  };

  //function to handle the text input data on group, screen and feature add
  const handleUpdateInputtext = (item: any) => {
    if (item?.nodeType === "group") {
      handleUpdategroup(item);
    } else if (item?.nodeType === "screen") {
      handleUpdateScreen(item);
    } else if (item?.nodeType === "functionality") {
      handleUpdateFuncationality(item);
    }
  };

  const NavItem: React.FC<NavItemProps> = ({ item, level = 0 }) => {
    const isExpanded = expandedNodes[item?._id];
    return (
      <ClickAwayListener
        onClickAway={() => {
          setSeletedItem(null);
          setIsEditing(false);
          setInputValue(null);
        }}
      >
        <div
          className={`${
            level === 0 ? "" : "pl-3 w-full border-2-r border-black"
          }`}
        >
          <div
            onClick={() => {
              //   handleToggleCollapse(item?._id);
            }}
            className={`flex items-center justify-between w-full px-2 text-xs hover:bg-gray-3 cursor-pointer group/navItem relative  ${
              item?._id === seletedItem?._id ? "bg-gray-3" : ""
            }`}
          >
            <div className="flex items-center flex-1 gap-2 text-sm">
              {/* chevron */}
              {item.children && item.children.length > 0 ? (
                <div
                  onClick={() => {
                    handleToggleCollapse(item?._id, !expandedNodes[item?._id]);
                    setSeletedItem(item);
                  }}
                  className="rounded-md cursor-pointer "
                >
                  {isExpanded ? <TbChevronDown /> : <TbChevronRight />}
                </div>
              ) : (
                <div className="invisible">
                  <TbChevronDown />
                </div>
              )}
              {/* icons for group, screen and functionality */}
              {item.screenName ? (
                <TbGrid3X3 />
              ) : item.groupName ? (
                <TbLayoutGrid />
              ) : (
                <TbFocusCentered />
              )}

              {/* title of the item */}
              <div
                className="flex-1 py-2 text-xs"
                onClick={() => {
                  handleClick(item);
                  // handleToggleCollapse(item?._id, true);
                }}
                onDoubleClick={() => {
                  setIsEditing(true);
                  setSeletedItem(item);
                }}
              >
                {isEditing && seletedItem?._id === item?._id ? (
                  <input
                    autoFocus
                    placeholder="Enter name"
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleUpdateInputtext(item);
                      }
                    }}
                    className="outline-none"
                  />
                ) : (
                  item?.screenName || item?.groupName || item?.functionalityName
                )}
              </div>
            </div>

            <span className="absolute invisible p-[2px] text-sm bg-white rounded-lg right-4 custom-shadow group-hover/navItem:visible -top-1/2">
              {item.screenName ? (
                <div className="flex items-center">
                  <ATMIconButton
                    tooltipTitle="To add Group cltr + g"
                    variant="text"
                    icon={<TbLayoutGrid />}
                    onClick={() => handleAddgroup(platformId, item?._id)}
                  />
                  <ATMIconButton
                    tooltipTitle="To add Functionality cltr + dot"
                    variant="text"
                    icon={<TbFocusCentered />}
                    onClick={() => handleAddFunctionality(item?._id)}
                  />

                  <ATMIconButton
                    disabled={item?.children && item.children.length > 0}
                    variant="text"
                    color="error"
                    icon={<TbTrash />}
                    onClick={() => handleDeleteScreen(item?._id)}
                  />
                </div>
              ) : item.groupName ? (
                <div className="flex items-center">
                  <ATMIconButton
                    tooltipTitle="To add group cltr + g"
                    variant="text"
                    icon={<TbLayoutGrid />}
                    onClick={() => handleAddgroup(platformId, item?._id)}
                  />
                  <ATMIconButton
                    tooltipTitle="To add Screen cltr + /"
                    variant="text"
                    icon={<TbGrid3X3 />}
                    onClick={() => handleAddScreen(platformId, item?._id)}
                  />
                  <ATMIconButton
                    tooltipTitle="Edit"
                    variant="text"
                    icon={<TbEdit />}
                    onClick={() => {
                      setIsEditing(true);
                      setSeletedItem(item);
                    }}
                  />
                  <ATMIconButton
                    disabled={item?.children && item.children.length > 0}
                    variant="text"
                    color="error"
                    icon={<TbTrash />}
                    onClick={() => handleDeletegroup(item?._id)}
                  />
                </div>
              ) : (
                <ATMIconButton
                  disabled={item?.children && item.children.length > 0}
                  variant="text"
                  color="error"
                  icon={<TbTrash />}
                  onClick={() => handleDeleteFunctionality(item?._id)}
                />
              )}
            </span>
          </div>
          {isExpanded && item.children && item.children.length > 0 && (
            <div>
              {item.children.map((child) => (
                <NavItem key={child?._id} item={child} level={level + 1} />
              ))}
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  };

  //print data
  const handlePrint = useReactToPrint({
    content: () => meetingMOMRef?.current,
    documentTitle: `codiotic`,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedData) {
        if (event.ctrlKey && event.key === ",") {
          event.preventDefault();
          handlePrint();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      if (
        (isMac && event.metaKey && event.key === "/") ||
        (!isMac && event.ctrlKey && event.key === "/")
      ) {
        event.preventDefault();
        handleAddScreen(platformId);
      } else if (
        (isMac && event.metaKey && event.key === "g") ||
        (!isMac && event.ctrlKey && event.key === "g")
      ) {
        event.preventDefault();
        handleAddgroup(platformId);
      } else if (
        (isMac && event.metaKey && event.key === ".") ||
        (!isMac && event.ctrlKey && event.key === ".")
      ) {
        event.preventDefault();
        handleAddFunctionality(seletedItem?._id);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAddScreen, handleAddFunctionality, handleAddgroup]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col gap-2 p-2 pb-0">
        <div className="flex items-center gap-2">
          {isTableLoading ? (
            <ATMLoadingButton className="w-full" isLoading={true}>
              Addding
            </ATMLoadingButton>
          ) : (
            <div className="flex items-center justify-between w-full h-[38px]">
              <div className="flex-1">
                <p className="w-full text-xs">Quick Actions</p>
              </div>
              <div className="flex">
                <ATMIconButton
                  tooltipTitle="To add Group cltr + g"
                  icon={<TbLayoutGrid />}
                  variant="text"
                  onClick={() => {
                    handleAddgroup(platformId);
                    dispatch(setSelectedData(null));
                    dispatch(setIsTableLoading(true));
                  }}
                />
                <ATMIconButton
                  tooltipTitle="To add Screen cltr + /"
                  icon={<TbGrid3X3 />}
                  variant="text"
                  onClick={() => {
                    handleAddScreen(platformId);
                    dispatch(setSelectedData(null));
                    dispatch(setIsTableLoading(true));
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <Divider />
      </div>
      <div className="h-full py-4 overflow-scroll ">
        {buildTree(plateformData?.groups)?.map((nav: any) => (
          <NavItem key={nav?._id} item={nav} />
        ))}
      </div>
      <ATMLoadingButton
        variant="text"
        onClick={handlePrint}
        className="absolute top-0 right-0 w-36"
      >
        Download PDF
      </ATMLoadingButton>
      <div className="hidden">
        <PrintScreenDeatails
          screenRef={meetingMOMRef}
          requirementGatheringData={requirementGatheringData}
        />
      </div>
    </div>
  );
};

export default ScreenAndFeaturesSidebar;
