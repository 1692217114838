import { Form, Formik, FormikHelpers } from "formik";
import { IndustryFormValues } from "src/models/Industry.model";
import { useAddIndustryMutation } from "src/services/IndustryService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import IndustryForm from "../Layouts/IndustryForm";

type Props = {
  onClose: () => void;
};

const AddIndustryFormWrapper = ({ onClose }: Props) => {
  const [addIndustryName] = useAddIndustryMutation();

  // Form Initial Values
  const initialValues: IndustryFormValues = {
    industryName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    industryName: string().required("Please enter industry name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: IndustryFormValues,
    { setSubmitting, resetForm }: FormikHelpers<IndustryFormValues>
  ) => {
    const formattedValues = {
      industryName: values?.industryName,
    };
    addIndustryName(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);

          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <IndustryForm
            formType="ADD"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddIndustryFormWrapper;
