import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { array, object, string } from "yup";
import { JobCreationStageFormValues } from "src/models/JobCreationStage.model";
import JobCreationStageForm from "../Layouts/JobCreationStageForm";
import {
  useJobCreationStageUpdateMutation,
  useGetJobCreationStageByIdQuery,
} from "src/services/JobsCreationStageService";
import { showToast } from "src/utils/showToaster";
import { useNavigate, useParams } from "react-router-dom";

const EditJobCreationStageFormWrapper = () => {
  const { id, stageId } = useParams();
  const navigate = useNavigate();
  const [stageData, setStageData] = useState<any>();

  const { data, isFetching, isLoading } =
    useGetJobCreationStageByIdQuery(stageId);
  const [updateStage] = useJobCreationStageUpdateMutation();

  useEffect(() => {
    if (!isFetching || !isLoading) {
      setStageData(data?.data?.[0]);
    }
  }, [data, isFetching, isLoading]);

  // Form Initial Values
  const initialValues: JobCreationStageFormValues = {
    question: stageData?.questions?.map((el: any) => {
      return {
        questionType: {
          label: el?.questionType,
          value: el?.questionType,
        },
        labelName: el?.questionTitle,
        maximumValForRange: el?.maximumValForRange || 0,
        minimumValForRange: el?.minimumValForRange || 0,
      };
    }),
    stageName: stageData?.stageName || "",
    order: stageData?.order || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    stageName: string().required("Please enter stage name"),
    order: string().required("Please enter order"),
    question: array().of(
      object().shape({
        questionType: object().required("Please select type"),
        labelName: string().required("Please enter title"),
      })
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: JobCreationStageFormValues,
    { setSubmitting, resetForm }: FormikHelpers<JobCreationStageFormValues>
  ) => {
    const formattedValues = {
      jobId: id,
      stageName: values?.stageName,
      order: values?.order,
      questions: values?.question?.map((el: any) => {
        return {
          questionTitle: el?.labelName,
          questionType: el?.questionType?.value,
          maximumValForRange: el?.maximumValForRange || 0,
          minimumValForRange: el?.minimumValForRange || 0,
        };
      }),
    };
    updateStage({
      body: formattedValues,
      id: stageId,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate(`/job-onboard/job-creation/view/${id}/stages`);
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <JobCreationStageForm
            formikProps={formikProps}
            isLoading={isLoading || isFetching}
            formtype="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditJobCreationStageFormWrapper;
