import apiSlice from "./ApiSlice";

export const moduleApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get All
    getAllModulesWithPagination: builder.query({
      providesTags: ["modules"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/module",
          method: "POST",
          body,
        };
      },
    }),

    // Get
    getModules: builder.query({
      providesTags: ["modules"],
      query: (body) => {
        return {
          url: "/module",
          method: "POST",
          body,
        };
      },
    }),

    // Get Module by Id
    getModulesById: builder.query({
      providesTags: ["modules"],
      query: (id) => {
        return {
          url: `/module/${id}`,
          method: "GET",
        };
      },
    }),

    // Add
    addModule: builder.mutation({
      invalidatesTags: ["modules"],
      query: (body) => {
        return {
          url: "/module/add",
          method: "POST",
          body,
        };
      },
    }),

    // Edit
    editModule: builder.mutation({
      invalidatesTags: ["modules"],
      query: ({ id, body }) => {
        return {
          url: `/module/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Get modile by project id
    getModulesByProjectId: builder.query({
      providesTags: ["modules"],
      query: (pid) => {
        return {
          url: `module/project/${pid}`,
          method: "GET",
        };
      },
    }),

    //
  }),
});

export const {
  useGetAllModulesWithPaginationQuery,
  useGetModulesQuery,
  useGetModulesByIdQuery,
  useAddModuleMutation,
  useEditModuleMutation,
  useGetModulesByProjectIdQuery,
} = moduleApi;
