import apiSlice from "./ApiSlice";

export const domainApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all social media with pagination
    getDomainPagination: builder.query({
      providesTags: ["domain"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/domain",
          method: "POST",
          body,
        };
      },
    }),

    // Get all domain
    getAllDomain: builder.query({
      providesTags: ["domain"],
      query: () => {
        return {
          url: "/domain",
          method: "GET",
        };
      },
    }),

    // Add Domain
    addDomain: builder.mutation({
      invalidatesTags: ["domain"],
      query: (body) => {
        return {
          url: "/domain/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get single domain
    getSingleDomain: builder.query({
      providesTags: ["domain"],
      query: (id: string) => {
        return {
          url: `/domain/${id}`,
          method: "GET",
        };
      },
    }),

    // Update social media
    updateDomain: builder.mutation({
      invalidatesTags: ["domain"],
      query: ({ id, body }) => {
        return {
          url: `/domain/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Change Status Social Media
    ChangeStatusDomain: builder.mutation({
      invalidatesTags: ["domain"],
      query: (id) => {
        return {
          url: `domain/status-change/${id}`,
          method: "PUT",
        };
      },
    }),

    //
  }),
});

export const {
  useGetDomainPaginationQuery,
  useGetAllDomainQuery,
  useGetSingleDomainQuery,
  useAddDomainMutation,
  useUpdateDomainMutation,
  useChangeStatusDomainMutation,
} = domainApi;
