import { FormikProps } from "formik";
import moment from "moment";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { CaseFlowFormProps } from "src/models/CaseFlow.model";
import { useGetFinanceAccountQuery } from "src/services/Finance-New-Services/AccountServices";
import { useGetFinanceCategoryQuery } from "src/services/Finance-New-Services/FinanceCategroyService";
import { useGetAllPaymentModesQuery } from "src/services/Finance-New-Services/PaymentModeServices";
import { handleValidWithDotNumber } from "src/utils/validations/numberInput";
type Props = {
  formikProps: FormikProps<CaseFlowFormProps>;
  onClose: () => void;
  formType: "ADD";
};

const CashFlowFormLayout = ({
  formType = "ADD",
  formikProps,
  onClose,
}: Props) => {
  const { data: getAccount, isLoading: isAccountLoading } =
    useGetFinanceAccountQuery("");
  const { data: getpaymentMode, isLoading: isPaymentModeLoading } =
    useGetAllPaymentModesQuery("");
  const { data: getCategory, isLoading: isCategoryLoading } =
    useGetFinanceCategoryQuery("");
  const formHeading = formType === "ADD" ? "Adjust balance" : "";
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="text-xl font-medium"> {formHeading} </div>

      <div className="flex flex-col gap-2">
        <div>
          <ATMDatePicker
            required
            label="Payment Date"
            name="date"
            format="DD/MM/YYYY"
            maxDate={moment().format("yyyy-MM-DD")}
            value={values.date}
            onChange={(newValue) => setFieldValue("date", newValue)}
          />
        </div>
        <div>
          <ATMTextField
            name="amount"
            value={values.amount}
            onChange={(e) =>
              handleValidWithDotNumber(e, { acceptNegative: true }) &&
              setFieldValue("amount", e.target.value)
            }
            label="Amount"
            placeholder="Enter Amount"
            onBlur={handleBlur}
          />
        </div>
        {/* Account */}
        <div>
          <ATMSelect
            name="account"
            label="Account"
            required
            placeholder="Select Account"
            value={values.account}
            options={getAccount?.data?.map((ele: any) => {
              return {
                label: ele?.accountName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("account", newValue);
            }}
            isLoading={isAccountLoading}
          />
        </div>
        {/* Mode */}
        <div>
          <ATMSelect
            name="mode"
            label="Mode"
            required
            placeholder="Select Mode"
            value={values.mode}
            options={getpaymentMode?.data?.map((ele: any) => {
              return {
                label: ele?.modeName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("mode", newValue);
            }}
            isLoading={isPaymentModeLoading}
          />
        </div>
        {/* Category */}
        <div>
          <ATMSelect
            name="category"
            label="Category"
            required
            placeholder="Select Category"
            value={values.category}
            options={getCategory?.data?.map((ele: any) => {
              return {
                label: ele?.categoryName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("category", newValue);
            }}
            isLoading={isCategoryLoading}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <div className="w-[100px]">
          <ATMLoadingButton
            onClick={() => onClose()}
            type="button"
            className="text-black bg-white"
          >
            Cancel
          </ATMLoadingButton>
        </div>
        <div className="w-[100px]">
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default CashFlowFormLayout;
