import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { RiChatFollowUpLine } from "react-icons/ri";
import LeadsSideNavLayout from "../List/LeadsSideNavLayout/LeadsSideNavLayout";
import { useGetSingleLeadQuery } from "src/services/LeadsService";
import { CircularProgress } from "@mui/material";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import AccessDenied from "src/utils/AccessDenied";

type Props = {};

export const inquiriesEditTabs: {
  label: string;
  icon: IconType;
  path: string;
  name: keyof typeof UserModuleNameTypes
}[] = [
    {
      label: "FOLLOWUP",
      icon: RiChatFollowUpLine,
      path: "followup",
      name: UserModuleNameTypes.ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP
    },
    {
      label: "DETAILS",
      icon: FaUser,
      path: "details",
      name: UserModuleNameTypes.ACTION_LEADS_SINGLE_VIEW_DETAILS
    },
  ];

const ViewLayouts = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { leadsId } = useParams();
  const currentPath = location.pathname.split("/")[4];
  const [singleLeadData, setSingleLeadData] = useState<any>({});

  const { data, isLoading, isFetching } = useGetSingleLeadQuery(leadsId || "");

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setSingleLeadData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const { userData } = useGetLocalStorage();

  React.useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("open");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of inquiriesEditTabs) {
      const isValue = isAuthorized(nav?.name as keyof typeof UserModuleNameTypes);
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tabsRender = inquiriesEditTabs?.some((nav : any) => {
    return isAuthorized(nav?.name  as keyof typeof UserModuleNameTypes);
  })

  return (
    <>
      {tabsRender ?
        <div className="flex flex-col gap-2 pt-3 h-full">
          {/* inquiriesEditTabs */}
          {inquiriesEditTabs?.length && (
            <div className="flex gap-3 items-center  mx-4  border-b border-slate-400 bg-white ">
              {inquiriesEditTabs?.filter((nav) => {
                return isAuthorized(nav?.name  as keyof typeof UserModuleNameTypes);
              })?.map((tab: any, index: any) => {
                return (
                  <div key={index}>
                    <button
                      type="button"
                      onClick={() => navigate(tab.path)}
                      className={`h-full px-5 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${currentPath === tab.path?.split("/")[0]
                          ? "text-primary-main   border-primary-main"
                          : "text-gray-700 border-white"
                        }
         `}
                    >
                      <div className=" text-lg  ">
                        <tab.icon />
                      </div>
                      {tab.label}
                    </button>
                  </div>
                );
              })}
            </div>
          )}

          {/* Outlet */}
          <div className=" flex-1 flex flex-col overflow-auto ">
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <div className="border-b border-slate-300 p-4 pl-3 sticky top-0  bg-white z-50">
              <div className="flex gap-3">
                {/* Avatar  */}
                <div className="w-[56px] aspect-square rounded bg-primary-main text-white flex justify-center items-center text-2xl font-semibold capitalize ">
                  {singleLeadData?.name?.[0]}{" "}
                </div>

                <div className="flex flex-col">
                  {/* Name */}
                  <div className="text-primary-dark font-semibold text-xl capitalize">
                    {singleLeadData?.name}
                  </div>

                  {/* Mobile */}
                  <div className="text-primary-dark d text-slate-600">
                    {singleLeadData?.mobile}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex">
              <div>
                <LeadsSideNavLayout />
              </div>
              <div className="flex-1">
                <Outlet />
              </div>
            </div>
          </div>
        </div> : <AccessDenied />}
    </>
  );
};

export default ViewLayouts;
