import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/DeliveryDateSlice";
import { RootState } from "src/redux/store";
import DeliveryDateListing from "./DeliveryDateListing";

import { Tooltip } from "@mui/material";
import moment from "moment";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { DeliveryDateListResponse } from "src/models/DeliveryDate.model";
import {
  useDeleteDeliveryDateMutation,
  useGetAllDeliveryDateQuery,
} from "src/services/DeliveryDateService";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import ChangeStatusFormWrapper from "../ChangeStatus/ChangeStatusFormWrapper";
import EditDeliveryDateWrapper from "../Edit/EditDeliveryDateWrapper";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const DeliveryDateListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.deliveryDate);
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [deliveryDateId, setDeliveryDateId] = useState<string>("");
  const [changeStatus, setChangeStatus] = useState<any>(false);
  const [deleteDeliveryDate] = useDeleteDeliveryDateMutation();

  const handledeleteDeliveryDate = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Delivery Date ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteDeliveryDate(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const { data, isLoading, isFetching } = useGetAllDeliveryDateQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "projectId",
      "clientId",
      "deliveryDate",
      "actualDeliveryDate",
      "remark",
      "status",
      "doneById",
      "doneByUserName",
      "doneByUserType",
      "isDeleted",
      "isActive",
    ],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "deliveryDate",
    orderByValue: 1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Date - Time",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.DELIVERY_DATE_LIST_DATE_TIME,
      renderCell: (row: DeliveryDateListResponse) => (
        <div>
          <div className="font-medium text-slate-700">
            {formatedDateTimeIntoIst(row.createdAt, "DD MMM yyyy")}
          </div>
          <div className="text-[13px] font-medium text-slate-400">
            {formatedDateTimeIntoIst(row.createdAt, "hh:mm A")}
          </div>
        </div>
      ),
    },
    {
      field: "clientOrganisationName",
      headerName: "Client Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.DELIVERY_DATE_LIST_CLINET_NAME,
      renderCell: (row: DeliveryDateListResponse) => (
        <div className="capitalize"> {row?.clientOrganisationName} </div>
      ),
    },
    {
      field: "projectName",
      headerName: "Project Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.DELIVERY_DATE_LIST_PROJECT_NAME,
      renderCell: (row: DeliveryDateListResponse) => (
        <div className="capitalize"> {row?.projectName} </div>
      ),
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.DELIVERY_DATE_LIST_DELIVERY_DATE,
      renderCell: (row: DeliveryDateListResponse) => (
        <div className="capitalize">
          {" "}
          {moment(row?.deliveryDate)?.format("DD-MM-YYYY")}{" "}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.DELIVERY_DATE_LIST_STATUS,
      renderCell: (row: DeliveryDateListResponse) => (
        <div className="capitalize"> {row?.status} </div>
      ),
    },
    {
      field: "remark",
      headerName: "Remark",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.DELIVERY_DATE_LIST_REMARK,
      renderCell: (row: DeliveryDateListResponse) => (
        <Tooltip title={row?.remark}>
          <div className="w-full capitalize truncate">{row?.remark} </div>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: DeliveryDateListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <IoMdCheckmarkCircleOutline color="#000000" /> Mark as done
                  </div>
                ),
                onClick: () => {
                  setChangeStatus(true);
                  setDeliveryDateId(row?._id);
                },
                acitonName:
                  UserModuleNameTypes.ACTION_DELIVERY_DATE_MARK_AS_DONE,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditFormDialog(true);
                  setDeliveryDateId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_DELIVERY_DATE_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handledeleteDeliveryDate(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_DELIVERY_DATE_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <DeliveryDateListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenEditFormDialog ? (
        <EditDeliveryDateWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedDeliveryDate={deliveryDateId}
        />
      ) : null}
      {changeStatus ? (
        <ChangeStatusFormWrapper
          onClose={() => setChangeStatus(false)}
          selectedDeliveryDate={deliveryDateId}
        />
      ) : null}
    </div>
  );
};

export default DeliveryDateListingWrapper;
