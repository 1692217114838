import apiSlice from "./ApiSlice";

export const ServiceTicketApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllServiceTicket: builder.query({
      providesTags: ["service-ticket"],
      query: () => {
        return {
          url: "/service-ticket",
          method: "GET",
        };
      },
    }),
    // Get ServiceTicket with pagination
    getServiceTickets: builder.query({
      providesTags: ["service-ticket"],
      query: (body) => {
        return {
          url: "/service-ticket",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addServiceTicket: builder.mutation({
      invalidatesTags: ["service-ticket"],
      query: (body) => {
        return {
          url: "/service-ticket/add",
          method: "POST",
          body,
        };
      },
    }),

    deleteServiceTicket: builder.mutation({
      invalidatesTags: ["service-ticket"],
      query: (id: any) => ({
        url: `/service-ticket/${id}`,
        method: "DELETE",
      }),
    }),


    //Edit
    updateServiceTicket: builder.mutation({
      invalidatesTags: ["service-ticket"],
      query: ({ id, body }) => {
        return {
          url: `/service-ticket/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get ServiceTicket by ID
    getServiceTicketById: builder.query({
      providesTags: ["service-ticket"],
      query: (id) => {
        return {
          url: `/service-ticket/${id}`,
          method: "GET",
        };
      },
    }),
    // Get Comment by ID
    getAllCommentOfServiceTickets: builder.query({
      providesTags: ["service-ticket"],
      query: (id: string) => {
        return {
          url: `/service-ticket/${id}/comments`,
          method: "GET",
        };
      },
    }),
    // Add Comment by ID
    addCommentServiceTicket: builder.mutation({
      invalidatesTags: ["service-ticket"],
      query: (body) => {
        return {
          url: "/service-ticket/add-comment",
          method: "POST",
          body,
        };
      },
    }),
  }),
});
export const {
  useGetAllServiceTicketQuery,
  useGetServiceTicketsQuery,
  useAddServiceTicketMutation,
  useUpdateServiceTicketMutation,
  useDeleteServiceTicketMutation,
  useGetServiceTicketByIdQuery,
  useGetAllCommentOfServiceTicketsQuery,
  useAddCommentServiceTicketMutation
} = ServiceTicketApi;
