import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { AppDispatch, RootState } from "src/redux/store";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import FinanceManagementListing from "./AccountListing";
import {
  setIsTableLoading,
  setItems,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/Finance-New-Slices/AccountSlice";
import EditFinanceManagementWrapper from "../Edit/EditAccountWrapper";
import AddFinanceManagementWrapper from "../Add/AddAccountWrapper";
import AjustBalanceFormWrapper from "../AdjustBalanceDailog/AjustBalanceFormWrapper";
import { FaBalanceScale } from "react-icons/fa";
import {
  useDeleteFinanceAccountsMutation,
  useGetFinanceAccountQuery,
} from "src/services/Finance-New-Services/AccountServices";

type Props = {};

const FinanceManagementListingWrapper = (props: Props) => {
  const [adjustId, setAdjustId] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  const [isOpenAdjustAmountDialog, setIsOpenAdjustAmountDialog] =
    useState(false);
  const [selectedtProductId, setSelectedProductId] = useState("");

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.financeAccountSlice);

  const [deleteAccount] = useDeleteFinanceAccountsMutation();
  const { data, isLoading, isFetching } = useGetFinanceAccountQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["accountName", "accountType", "description"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Account?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteAccount(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "accountName",
      headerName: "Account Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      name: UserModuleNameTypes.ACCOUNTS_LIST_ACCOUNT_NAME,
    },
    {
      field: "accountType",
      headerName: "Type",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      renderCell: (row: any) => {
        return (
          <div>
            {row?.accountType === "BANK"
              ? "Bank"
              : row?.accountType === "CASH"
                ? "Cash"
                : row?.accountType === "CRYPTO"
                  ? "Crypto"
                  : ""}
          </div>
        );
      },
      name: UserModuleNameTypes.ACCOUNTS_LIST_ACCOUNT_NAME,
    },
    {
      field: "currentBalance",
      headerName: "Balance",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize font-medium",
      name: UserModuleNameTypes.ACCOUNTS_LIST_ACCOUNT_NAME,
      renderCell: (row: any) => {
        return (
          <div>
            {row?.currentBalance !== "-" ? (
              <span>&#8377; {row?.currentBalance}</span>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      name: UserModuleNameTypes.ACCOUNTS_LIST_ACCOUNT_NAME,
      renderCell: (row: any) => {
        return (
          <div>
            {row?.description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: row?.description?.replace(/\n/g, "<br>"),
                }}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: "flex-[1_1_0%]",
      align: "end",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectedProductId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_ACCOUNTS_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_ACCOUNTS_DELETE,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <FaBalanceScale color="#000000" /> Adjust Balance
                  </div>
                ),
                onClick: () => {
                  setAdjustId(row?._id);
                  setIsOpenAdjustAmountDialog(true);
                },
                acitonName: UserModuleNameTypes.ACTION_ACCOUNTS_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <FinanceManagementListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setRowsPerPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
          onAddNew={() => setIsOpenAddDialog(true)}
        />
      </div>
      {isOpenEditDialog && (
        <EditFinanceManagementWrapper
          selectedSubscription={selectedtProductId}
          onClose={() => setIsOpenEditDialog(false)}
        />
      )}
      {isOpenAddDialog ? (
        <AddFinanceManagementWrapper
          onClose={() => setIsOpenAddDialog(false)}
        />
      ) : null}
      {isOpenAdjustAmountDialog && (
        <AjustBalanceFormWrapper
          onClose={() => setIsOpenAdjustAmountDialog(false)}
          adjustId={adjustId}
        />
      )}
    </>
  );
};

export default FinanceManagementListingWrapper;
