import React from "react";

// |-- External Dependencies --|
import { IconType } from "react-icons";

// |-- Internal Dependencies --|
// |-- Redux --|
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import JobApplicationNotesListWrapper from "./JobApplicationNotes/List/JobApplicationNotesListWrapper";

// |-- Types --|
type ViewLayoutPropTypes = {
  leftbar?: boolean;
  infoCard?: React.ReactNode;
  actionIcons?: {
    icon: IconType;
    onClick: () => void;
  }[];
  listData?: any[];
  tabs?: {
    label: string;
    icon: IconType;
    path: string;
  }[];
  searchValue?: string;
  onSearch?: (value: string) => void;
  onClickViewMore?: () => void;
  onClickStage?: (stageId: string) => void;
  hideViewMore?: boolean;
  children?: any;
  isLoading?: boolean;
  stageId: string;
  onAddNoteClick: () => void;
};

const JobApplicationStageSideBarLayout = ({
  leftbar = true,
  infoCard,
  listData,
  tabs,
  searchValue = "",
  onSearch = () => {},
  onClickStage = () => {},
  onClickViewMore,
  hideViewMore = false,
  children,
  isLoading,
  stageId,
  onAddNoteClick,
}: ViewLayoutPropTypes) => {
  const getIsStageEnabled = (stageInfo: any, index: number) => {
    return stageInfo?.order === 1
      ? true
      : listData?.[index - 1]?.interview?.proceedToNextStage;
  };
  return (
    <>
      <div className="h-[calc(100vh-50px)] bg-white ">
        <div className="w-full flex h-[calc(100%)]">
          {/* Left Section Side Bar */}
          <div className="md:block hidden">
            {leftbar === true && (
              <div className="w-[250px] lg:w-[200px] h-full flex flex-col">
                {/* List */}
                <div className="grow overflow-auto">
                  <div className="p-2 flex flex-col min-w-[100%] overflow-y-auto gap-2">
                    {listData?.map((stageInfo: any, index: number) => {
                      return (
                        <div
                          key={stageInfo?._id}
                          className={` border-b p-2   flex flex-col gap-2 
                          ${getIsStageEnabled(stageInfo, index) ? (stageInfo?._id === stageId ? "bg-teal-100 border border-teal-100 shadow-sm cursor-pointer" : "bg-white cursor-pointer") : "bg-slate-50 text-slate-600 cursor-not-allowed"}
                          `}
                          onClick={() =>
                            getIsStageEnabled(stageInfo, index)
                              ? onClickStage(stageInfo?._id)
                              : null
                          }
                        >
                          <div className="flex flex-col gap-1">
                            <Tooltip title={stageInfo?.stageName}>
                              <h3 className="w-full overflow-hidden line-clamp-2 text-sm min-w-[100%] font-medium">
                                {stageInfo?.stageName}
                              </h3>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                    {/* View more button */}
                    {hideViewMore && !isLoading && (
                      <span
                        onClick={onClickViewMore}
                        className="text-black text-center text-sm cursor-pointer hover:underline"
                      >
                        View More
                      </span>
                    )}
                    {isLoading && (
                      <div className="flex justify-center w-full">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Right Section */}
          <div className={`w-full border-r border-l h-full`}>{children}</div>
          <div className="border-l border-slate-300 min-w-[300px] max-w-[300px] h-full overflow-auto py-5 px-2">
            {/* <JobApplicationNotesWrapper /> */}
            <div className="flex justify-between items-center border-b pb-2">
              <p className="text-slate-600 font-semibold text-md">Notes</p>
              <ATMLoadingButton onClick={onAddNoteClick}>Add</ATMLoadingButton>
            </div>
            <JobApplicationNotesListWrapper />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobApplicationStageSideBarLayout;
