import apiSlice from "./ApiSlice";

export const IncidentServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // ADD Pointer
    addPointer: builder.mutation({
      invalidatesTags: ["incident"],
      query: (body) => ({
        url: "/pointer/add",
        method: "POST",
        body,
      }),
    }),

    // List Pointer
    AllPointer: builder.query({
      providesTags: ["incident"],
      query: (body) => ({
        url: "/pointer",
        method: "POST",
        body,
      }),
    }),

    // Get Pointer
    getSinglePointer: builder.query({
      providesTags: ["incident"],
      query: (id: string) => {
        return {
          url: `/pointer/${id}`,
          method: "GET",
        };
      },
    }),
    // Update Pointer
    updatePointer: builder.mutation({
      invalidatesTags: ["incident"],
      query: ({ id, body }) => {
        return {
          url: `/pointer/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //DELETE Pointer
    deletePointer: builder.mutation({
      invalidatesTags: ["incident"],
      query: (id) => {
        return {
          url: `/pointer/${id}`,
          method: "DELETE",
        };
      },
    }),

    //Reject Not me Request
    notMeRejectRequest: builder.mutation({
      invalidatesTags: ["incident"],
      query: ({ id, body }) => {
        return {
          url: `/pointer/reject-not-me/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Desputed Listing
    desputedListing: builder.query({
      providesTags: ["incident"],
      query: (body) => ({
        url: "/pointer/disputed",
        method: "POST",
        body,
      }),
    }),
    //Clarification Listing
    clarificationsListing: builder.query({
      providesTags: ["incident"],
      query: (body) => ({
        url: "/pointer/clarifications",
        method: "POST",
        body,
      }),
    }),
    // Mark As Un-handled
    markAsUnhandled: builder.mutation({
      invalidatesTags: ["incident"],
      query: ({ id, body }) => {
        return {
          url: `/pointer/mark-as-unhandled/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Unhandled Listing
    unhandledListing: builder.query({
      providesTags: ["incident"],
      query: (body) => ({
        url: "/pointer/unhandled",
        method: "POST",
        body,
      }),
    }),

    //Clarification By Admin
    clarificationRequestByAdmin: builder.mutation({
      invalidatesTags: ["incident"],
      query: ({ id, body }) => {
        return {
          url: `/pointer/clarification/admin/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //Forward
    forwardIncident: builder.mutation({
      invalidatesTags: ["incident"],
      query: ({ id, body }) => {
        return {
          url: `/pointer/forward-pointer/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //Get Counter
    getPointerCounter: builder.query({
      providesTags: ["incident"],
      query: () => {
        return {
          url: `/pointer/count`,
          method: "GET",
        };
      },
    }),
    //Get Pointer logs
    getPointerLog: builder.query({
      query: (body) => ({
        url: "/pointer/logs",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useAddPointerMutation,
  useAllPointerQuery,
  useGetSinglePointerQuery,
  useDeletePointerMutation,
  useUpdatePointerMutation,
  useNotMeRejectRequestMutation,
  useDesputedListingQuery,
  useClarificationsListingQuery,
  useMarkAsUnhandledMutation,
  useUnhandledListingQuery,
  useClarificationRequestByAdminMutation,
  useForwardIncidentMutation,
  useGetPointerCounterQuery,
  useGetPointerLogQuery ,
} = IncidentServiceApi;
