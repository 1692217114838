import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { EditProjectFormValues } from "src/models/Project.model";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import { useParams, useNavigate } from "react-router-dom";
import EditProjectForm from "./EditProjectForm";
import {
  useGetProjectByIdQuery,
  useUpdateProjectByIdMutation,
} from "src/services/ProjectServices";
import CircularProgress from "@mui/material/CircularProgress";

const EditProjectFormWrapper = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetProjectByIdQuery(id || "", {
    skip: !id,
  });
  const [updateProject] = useUpdateProjectByIdMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);


  // Form Initial Values
  const initialValues: EditProjectFormValues = {
    client: {
      _id: items?.clientId,
      organisationName: items?.clientOrgLabel,
    },
    projectName: items?.projectName,
    timeQuotation : items?.timeQuotation,
    perDayWorkingHour : items?.perDayWorkingHour,
    members: items?.members?.map((ele: any) => {
      return {
        label: ele?.name,
        value: ele?._id,
      };
    }),
    label: items?.label?.map((label: any) => {
      return {
        labelName: label?.labelName,
        status: { label: label?.status || "", value: label?.status || "" },
      };
    }),
    layer: items?.layer?.map((layer: any) => {
      return {
        layerName: layer?.layerName,
      };
    }),
  };

  // Validation Schema
  // Validation Schema
  const validationSchema = object().shape({
    client: object().required("Please select client"),
    projectName: string().required("Please enter project name"),
    label: array().of(
      object().shape({
        labelName: string().required("Please enter label name"),
        status: object().required("Please select status of label"),
      })
    ),
    layer: array().of(
      object().shape({
        layerName: string().required("Please enter Layer name"),
      })
    ),
  });

  // // Handle Submit
  const handleSubmit = (
    values: EditProjectFormValues,
    { setSubmitting, resetForm }: FormikHelpers<EditProjectFormValues>
  ) => {
    const { client, members, label, layer,perDayWorkingHour, timeQuotation , ...rest } = values;
    const formattedValues = {
      ...rest,
      clientId: client?._id,
      members: members?.map((ele: any) => ele?.value),
      perDayWorkingHour : perDayWorkingHour ? perDayWorkingHour : "6.5",
      timeQuotation: timeQuotation ? parseInt(timeQuotation) : 1.5,
      label: label?.map((ele: any) => {
        return {
          labelName: ele?.labelName,
          status: ele?.status?.value,
        };
      }),
      layer: layer?.map((ele: any) => {
        return {
          layerName: ele?.layerName?.toUpperCase(),
        };
      }),
    };

    updateProject({
      id: id,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          navigate("/project");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <EditProjectForm formikProps={formikProps} />
        </Form>
      )}
    </Formik>
  );
};

export default EditProjectFormWrapper;
