import React from 'react'
import TeamAttendanceFilterCard from './TeamAttendanceFilterCard'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/redux/store'
import { setMonth, setYear } from 'src/redux/slices/TeamAttendanceSlice'

type Props = {
    open: any
    onClose: any
}

export type FormInitialValues = {
    month: any;
    year: any;
};

const TeamAttendanceFilterCardWrapper = ({ open, onClose }: Props) => {

    const dispatch = useDispatch<AppDispatch>();

    const teamAttendanceState = useSelector((state: RootState) => state.teamAttendance);
    const {
        month,
        year
    } = teamAttendanceState;

    const initialValues: FormInitialValues = {
        month: month,
        year: year
    };

    const handleSubmit = async (
        values: FormInitialValues,
        { setSubmitting }: FormikHelpers<FormInitialValues>
    ) => {
        setSubmitting(false)
        // ticket priority  dispatch
        dispatch(setYear(values.year));
        // ticket type  dispatch
        dispatch(setMonth(values.month));
        onClose();
    };


    // Reset Handler
    const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
        // status filter dispatch
        await dispatch(setYear(""));
        // project filter dispatch
        await dispatch(setMonth(""));
        formikProps.resetForm();
    };



    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formikProps) => (
                    <Form>
                        <TeamAttendanceFilterCard
                            open={open}
                            onClose={onClose}
                            formikProps={formikProps}
                            onReset={() => handleReset(formikProps)}
                        />
                    </Form>
                )}
            </Formik>

        </>
    )
}

export default TeamAttendanceFilterCardWrapper
