import { LuUsers } from "react-icons/lu";
import { RiUserSmileLine } from "react-icons/ri";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import MOLLeaveStatusChip from "src/components/UI/molecules/MOLLeaveStatusChip/MOLLeaveStatusChip";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import {
  DaysOfLeaveEnum,
  LeaveRequestListValue,
} from "src/models/LeaveReques.model";
import { convertDateInDateMonNameFormat } from "src/utils/common/dateFormat";
import ViewHalfDayLeaveRequest from "./ViewHalfDayLeaveRequest";
import ViewOneAndMultipleDayLeaveRequest from "./ViewOneAndMultipleDayLeaveRequest";
import { MdLabel } from "react-icons/md";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";

type Props = {
  items: LeaveRequestListValue;
  onRejectClick: () => void;
  onApproveClick: () => void;
  isShowRejectApproveBtn: boolean;
};

const getClassNameByStatus = (status: "PENDING" | "DECLINED" | "APPROVED") => {
  switch (status) {
    case "PENDING":
      return "text-amber-600";
    case "APPROVED":
      return "text-green-600";
    case "DECLINED":
      return "text-red-600";
  }
};

const ViewLeaveRequest = ({
  items,
  onApproveClick,
  onRejectClick,
  isShowRejectApproveBtn,
}: Props) => {
  return (
    <>
      <SideNavLayout>
        <div className="p-3 flex flex-col gap-5">
          <div className="flex justify-between  border-b-2 py-2 border-slate-200">
            <div className="flex gap-3 items-center">
              <MOLLeaveStatusChip status={items?.status} />
              <span className="font-semibold">
                {DaysOfLeaveEnum[items.daysOfLeave]} leave
              </span>
            </div>
            {isShowRejectApproveBtn && (
              <div className="flex gap-3 w-[20%] ">
                <ATMLoadingButton
                  onClick={onRejectClick}
                  className="bg-white border-primary-main text-primary-main font-semibold border "
                >
                  Reject Request
                </ATMLoadingButton>
                <ATMLoadingButton onClick={onApproveClick}>
                  Approve Request
                </ATMLoadingButton>
              </div>
            )}
          </div>
          <div className="flex gap-10 py-2">
            <div className="flex flex-col">
              <span className="text-sm">Requested By</span>
              <span className="font-medium">{items?.requestedByName}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm">Requested On</span>
              <span className="font-medium">
                {convertDateInDateMonNameFormat(items?.requestedOnDate)}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-5 border rounded-md bg-slate-50 px-3 py-4">
            <h6 className="font-medium">Leave Details</h6>
            {items?.daysOfLeave === "HALF_DAY" ? (
              <ViewHalfDayLeaveRequest
                leaveStartDate={items?.leaveStartDate}
                dayPart={items?.dayPart}
                leavingTime={items?.leavingTime}
                arrivalTime={items?.arrivalTime}
              />
            ) : (
              <ViewOneAndMultipleDayLeaveRequest
                leaveStartDate={items?.leaveStartDate}
                reportingDate={items?.reportingDate}
                noOfDays={items?.noOfDays}
              />
            )}
            <div>
              <div className="font-medium">Leave Reason</div>
              <div className="border rounded-md text-sm bg-white p-2 mt-2">
                {items?.reason}
              </div>
            </div>
          </div>
          <div className="border rounded-md grid grid-cols-2 p-3">
            {items?.approvals?.map((approval: any, ind: any) => {
              let isBuddy = approval?.approvedBy === items?.buddy;
              return (
                <div className={`${ind === 1 && "border-l "} px-3`}>
                  <div className="flex gap-3 border-b items-center pb-2">
                    <div className="rounded-full border bg-slate-50 p-1">
                      {isBuddy ? (
                        <RiUserSmileLine size={20} />
                      ) : (
                        <LuUsers size={20} />
                      )}
                    </div>
                    <span>
                      {isBuddy ? " Buddy Approval" : "Managment Approval"}
                    </span>
                  </div>
                  <div className="flex flex-col gap-4 py-3">
                    <div>
                      <p className="font-medium text-sm">Approver Name</p>
                      <p className="text-xs text-slate-500 pt-2 font-medium">
                        {approval?.name}
                      </p>
                    </div>
                    <div>
                      <p className="font-medium text-sm">Status</p>
                      <p
                        className={`${getClassNameByStatus(
                          approval?.approvalStatus
                        )} text-xs pt-2 font-medium`}
                      >
                        {approval?.approvalStatus}
                      </p>
                    </div>
                    {approval?.declinedReason && (
                      <div>
                        <p className="font-medium text-sm">Reason</p>
                        <p className="text-xs text-slate-600 pt-2 font-medium">
                          {approval?.declinedReason}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* remark */}
          <div className="border rounded-md flex flex-col p-3">
            {items?.logs?.length ? items?.logs?.map((approval: any, index: any) => {
              return (
                <div key={index} className="flex gap-3 pb-6 h-full">
                  <div className="p-2 h-fit aspect-square border rounded-full">
                    <MdLabel className="text-sm text-gray-600" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-1">
                      <p className="text-xs">
                        <div className="text-slate-700 font-medium">
                          {formatedDateTimeIntoIst(
                            approval?.createdAt,
                            "DD MMM yyyy"
                          )}
                        </div>
                        <div className="text-[13px] font-medium text-slate-400">
                          {formatedDateTimeIntoIst(approval?.createdAt, "hh:mm A")}
                        </div>
                        <div className="text-[13px] font-medium text-slate-400">
                          {approval?.updatedByName}
                        </div>
                      </p>
                    </div>
                    <span className=" text-blue-400 rounded-md">
                      {approval?.leaveRemark || '-'}
                    </span>
                  </div>
                </div>
              );
            }) :
              <div className="flex justify-center">No Logs Found</div>
            }
          </div>
        </div>
      </SideNavLayout>
    </>
  );
};

export default ViewLeaveRequest;
