import apiSlice from "./ApiSlice";

export const teamApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get Codiotic user by id
    getCodioticTeamMemberById: builder.query({
      providesTags: ["users"],
      query: (id) => {
        return {
          url: `/codiotic-user/${id}`,
          method: "GET",
        };
      },
    }),

    // Edit Codiotic user
    editCodioticTeamMember: builder.mutation({
      invalidatesTags: ["users"],
      query: ({ id, body }) => {
        return {
          url: `/codiotic-user/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Add TeamMember of codiotic
    addCodioticTeamMember: builder.mutation({
      invalidatesTags: ["users"],
      query: (body) => {
        return {
          url: "/codiotic-user/signup",
          method: "POST",
          body,
        };
      },
    }),

    // Get the Codiotic user
    getCodioticUsers: builder.query({
      providesTags: ["users"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/codiotic-user",
          method: "POST",
          body,
        };
      },
    }),

    // Edit Codiotic user
    updateMemeberStatus: builder.mutation({
      invalidatesTags: ["users"],
      query: (id) => {
        return {
          url: `/codiotic-user/status-change/${id}`,
          method: "PUT",
        };
      },
    }),

    // Get the Codiotic user
    getAllCodioticMembers: builder.query({
      providesTags: ["users", "codiotic-user"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/codiotic-user/all-members",
          method: "POST",
          body,
        };
      },
    }),

    // Get single team transation history
    getSingleTeamMemberTransactions: builder.query({
      providesTags: ["users"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/paid-salary-log/",
          method: "POST",
          body,
        };
      },
    }),

    // Edit Codiotic user
    updateMemeberMarkAsPaidStatus: builder.mutation({
      invalidatesTags: ["users"],
      query: (id) => {
        return {
          url: `/paid-salary-log/mark-as-paid/${id}`,
          method: "PUT",
        };
      },
    }),

    // Add Team Documents
    addTeamMemberDocuments: builder.mutation({
      invalidatesTags: ["users"],
      query: ({ body, userId }) => {
        return {
          url: `/codiotic-user/add-doc/${userId}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Update User Profile
    updateOurProfile: builder.mutation({
      invalidatesTags: ["users"],
      query: (body) => {
        return {
          url: `/codiotic-user/edit-my-profile`,
          method: "PUT",
          body,
        };
      },
    }),

    //GET User Profile
    getProfileData: builder.query({
      providesTags: ["users"],
      query: () => {
        return {
          url: `/codiotic-user/view`,
          method: "GET",
        };
      },
    }),

     // Salary Config
     updateSalaryConfig: builder.mutation({
      invalidatesTags: ["users"],
      query: ( body) => {
        return {
          url: `/salary-config/set-salary`,
          method: "PUT",
          body,
        };
      },
    }),
    //GET Salary Slip List
    getSalarySlipData: builder.query({
      query: (body) => {
        return {
          url: `/salary-config/`,
          method: "POST",
          body,
        };
      },
    }),
    //Get Salary Config
    getSalaryConfig: builder.query({
      providesTags: ["users"],
      query: (id) => {
        return {
          url: `/salary-config/${id}`,
          method: "GET",
        };
      },
    }),
    //GET Salary Slip Info
    getSalarySlipById: builder.query({
      query: (teamMemberId) => {
        return {
          url: `/payout/${teamMemberId}`,
          method: "GET",
        };
      },
    }),


  }),
});

export const {
  useGetCodioticTeamMemberByIdQuery,
  useEditCodioticTeamMemberMutation,
  useAddCodioticTeamMemberMutation,
  useGetCodioticUsersQuery,
  useUpdateMemeberStatusMutation,
  useGetAllCodioticMembersQuery,
  useGetSingleTeamMemberTransactionsQuery,
  useUpdateMemeberMarkAsPaidStatusMutation,
  useAddTeamMemberDocumentsMutation,
  useUpdateOurProfileMutation,
  useGetProfileDataQuery,
  useUpdateSalaryConfigMutation ,
  useGetSalaryConfigQuery,
  useGetSalarySlipDataQuery,
  useGetSalarySlipByIdQuery,
} = teamApi;
