import React from "react";
import { FieldArray, FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { JobCreationStageFormValues } from "src/models/JobCreationStage.model";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiPlus } from "react-icons/fi";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import { CircularProgress } from "@mui/material";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
interface typeOption {
  value: string;
  label: string;
}

type Props = {
  formikProps: FormikProps<JobCreationStageFormValues>;
  isLoading?: boolean;
  formtype: "EDIT" | "ADD";
};
const questionTypeOptions: typeOption[] = [
  { value: "RANGE", label: "RANGE" },
  { value: "TEXT", label: "TEXT" },
  { value: "NUMBER", label: "NUMBER" },
];

const JobCreationStageForm = ({ formikProps, formtype, isLoading }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="p-2 flex flex-col">
      {isLoading && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <div className="text-lg font-medium">
        {formtype === "ADD" ? "Add Stage" : "Update Stage"}
      </div>

      <div className="flex flex-col gap-4 p-4">
        <div className="grid lg:grid-cols-3 grid-cols-2  gap-4">
          <ATMTextField
            required
            name="stageName"
            label="Stage"
            placeholder="Enter Stage"
            value={values?.stageName}
            onChange={(e) => setFieldValue("stageName", e.target.value)}
            onBlur={handleBlur}
          />
          <ATMTextField
            required
            type="number"
            name="order"
            label="Order"
            placeholder="Enter Order"
            value={values?.order}
            onChange={(e) => setFieldValue("order", e.target.value)}
            onBlur={handleBlur}
          />
        </div>
        <div>
          <div className="text-lg font-medium">Questions</div>
          <FieldArray name="question">
            {({ push, remove }) => (
              <div className="grid grid-cols-1  gap-4">
                {values?.question?.map((label, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-6  border p-5 shadow-lg rounded-md"
                  >
                    {/* Question Type*/}
                    <div className="flex gap-4">
                      <ATMSelect
                        options={questionTypeOptions}
                        name={`question[${index}].questionType`}
                        value={values?.question[index]?.questionType}
                        onChange={(newValue) =>
                          setFieldValue(
                            `question[${index}].questionType`,
                            newValue
                          )
                        }
                        label="Question Type"
                        required
                        placeholder="Enter Question Type"
                        onBlur={handleBlur}
                      />
                      {values?.question[index]?.questionType?.value ===
                      "RANGE" && (
                      <div className="mt-1">
                          <ATMTextField
                          name={`question[${index}].minimumValForRange`}
                          value={
                            values?.question[index]?.minimumValForRange || ""
                          }
                          onChange={(e) =>
                            setFieldValue(
                              `question[${index}].minimumValForRange`,
                              e.target.value
                            )
                          }
                          label="Minimum Value"
                          required
                          placeholder="Enter Minimum Value"
                        />
                      </div>
                    )}

                    {values?.question[index]?.questionType?.value ===
                      "RANGE" && (
                       <div className="mt-1">
                         <ATMTextField
                          name={`question[${index}].maximumValForRange`}
                          value={
                            values?.question[index]?.maximumValForRange || ""
                          }
                          onChange={(e) =>
                            setFieldValue(
                              `question[${index}].maximumValForRange`,
                              e.target.value
                            )
                          }
                          label="Maximum Value"
                          required
                          placeholder="Enter Maximum Value"
                        />
                       </div>
                    )}
                    </div>

                    
                    {/* Question Title */}
                    <div className="flex gap-2 justify-between ">
                      <div className="my-3 md:my-0 w-full">
                        <ATMTextArea
                          name={`question[${index}].labelName`}
                          value={values?.question[index]?.labelName}
                          onChange={(newValue) =>
                            setFieldValue(
                              `question[${index}].labelName`,
                              newValue
                            )
                          }
                          label="Question Title"
                          required
                          placeholder="Enter Question Title"
                        />
                      </div>

                      {/* Delete Button */}
                      <button
                        type="button"
                        className={`${getInputHeight(
                          "small"
                        )}  rounded  text-red-600 flex justify-center items-center mt-4 md:mt-0`}
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin5Line size={20} />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() =>
                      push({
                        questionType: "",
                        labelName: "",
                      })
                    }
                    className="rounded  border px-2 py-1 border-gray-400 font-medium text-md text-gray-400 flex justify-center  gap-1 items-center"
                  >
                    <FiPlus size="1.3em" /> Add More
                  </button>
                </div>
              </div>
            )}
          </FieldArray>
        </div>
        <div className="flex justify-end  bg-white">
          <ATMLoadingButton
            className="w-40"
            isLoading={isSubmitting}
            type="submit"
          >
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default JobCreationStageForm;
