import { useState, useEffect } from "react";
import { useGetAllLeadsLabelListQuery } from "src/services/LeadsLabelsService";

const useLeadsLabels = () => {
  const [LeadsLabels, setLeadsLabels] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllLeadsLabelListQuery("");

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setLeadsLabels(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { LeadsLabels, isDataLoading };
};

export default useLeadsLabels;
