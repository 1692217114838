import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsTicketTableLoading, setFilterTicketGroup, setSearchValue } from "src/redux/slices/TicketGroupSlice";
import { RootState } from "src/redux/store";
import { useAddTicketGroupMutation } from "src/services/TicketGroupServices";
import { useGetAllTicketQuery } from "src/services/TicketServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import TicketGroupForm from "../Layouts/TicketGroupForm";


export type TicketGroupFormValues = {
  groupName: string;
  tickets?: any
};

const AddTicketGroupFormWrapper = () => {
  const dispatch = useDispatch()

  const {
    searchValue,
    dateFilter,

    filterTicketGroup
  } = useSelector((state: RootState) => state.ticketGroup);
  const navigate = useNavigate()
  const [ticketOptions, setTicketOptions] = useState<any>()
  const [addChangeRequest] = useAddTicketGroupMutation();

  // Ticket Query
  const { isLoading: isTicketLoading, isFetching: isTicketFetching, data: ticketData } = useGetAllTicketQuery({
    searchValue: searchValue,
    params: ["title", "ticketNumber", "createdBy", "projectName"],
    filterBy: [
      {
        fieldName: "status",
        value: filterTicketGroup?.status?.value && [filterTicketGroup?.status?.value],
      },
      {
        fieldName: "type",
        value: filterTicketGroup?.type,
      },
      {
        fieldName: "priority",
        value: filterTicketGroup?.priority,
      },
      {
        fieldName: "createdBy",
        value: filterTicketGroup?.createdBy?.value,
      },
      {
        fieldName: "tags",
        value: filterTicketGroup?.tags?.map((ele: any) => ele?.value) || [],
      },
      {
        fieldName: "tagsNot",
        value: filterTicketGroup?.tagsNot?.map((ele: any) => ele?.value) || [],
      },
      {
        fieldName: "projectId",
        value: filterTicketGroup?.projects?.value,
      },
      {
        fieldName: "clientSideTicketStatus",
        value: filterTicketGroup?.clientSideTicketStatusFilterValue?.value,
      },
      {
        fieldName: "changeRequestId",
        value: filterTicketGroup?.changeRequestId?.value,
      },
      {
        fieldName: "isArchived",
        value: filterTicketGroup?.isArchived,
      },
      ...(filterTicketGroup?.resosurceRequired
        ? [
          {
            fieldName: "resourceRequired",
            value: [],
          },
        ]
        : []),
    ],
    dateFilter: {
      startDate: dateFilter?.start_date || "",
      endDate: dateFilter?.end_date || "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (isTicketLoading || isTicketFetching) {
      dispatch(setIsTicketTableLoading(true));
    } else {
      (setTicketOptions(ticketData?.data || []));
      dispatch(setIsTicketTableLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTicketLoading, isTicketFetching, ticketData]);


  // Form Initial Values
  const initialValues: TicketGroupFormValues = {
    groupName: "",
    tickets: []
  };

  // Validation Schema
  const validationSchema = object().shape({
    groupName: string().required("Please enter ticket group"),
  });

  // Handle Submit
  const handleSubmit = (
    values: TicketGroupFormValues,
    { setSubmitting, resetForm }: FormikHelpers<TicketGroupFormValues>
  ) => {

    const formatedValues = {
      groupName: values?.groupName,
      tickets: values?.tickets
    };

    addChangeRequest(formatedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate(`/ticket-group/${res?.data?.data?._id}/ticket-details`)
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };
  useEffect(() => {
    return () => {
      dispatch(setFilterTicketGroup({
        priority: [],
        type: [],
        status: [],
        tags: [],
        tagsNot: [],
        createdBy: [],
        projects: [],
        clientSideTicketStatusFilterValue: [],
        changeRequestId: [],
        isArchived: false,
        resosurceRequired: false,
      }))
      dispatch(setSearchValue(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <TicketGroupForm
              ticketOptions={ticketOptions}
              formikProps={formikProps}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTicketGroupFormWrapper;
