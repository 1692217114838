import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { showToast } from "src/utils/showToaster";
import { array, boolean, object, string } from "yup";
import RecurringIncomeFormLayout from "../Layout/RecurringIncomeFormLayout";
import { useAddRecurringIncomeMutation } from "src/services/Finance-New-Services/RecurringIncomeService";
import { RecurringIncomeFormProps } from "src/models/RecurringIncome.model";
import { useGetBaseCurrencyQuery } from "src/services/CurrencyServices";

const AddRecurringIncomeFormWrapper = () => {
  const navigate = useNavigate();
  const [addRecurringIncome] = useAddRecurringIncomeMutation();
  const { data } = useGetBaseCurrencyQuery("");
  // Form Initial Values
  const initialValues: RecurringIncomeFormProps = {
    amount: "",
    gstApplicable: false,
    gstPercentage: "0",
    tdsAmount: "",
    currency: data?.data,
    category: "",
    repeat: "",
    description: "",
    until: "",
    recurranceDateAndMonthForYear: null,
    recurranceDate: "",
    days: [] as string[],
    untilDate: null,
    event: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    amount: string().required("Please enter amount"),
    gstApplicable: boolean().required("Please select GST applicability"),

    gstPercentage: string().test(
      "test-gstPercentage",
      "Please enter gst percentage",
      (value, context) => {
        if (context?.parent?.gstApplicable) {
          if (!value) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      }
    ),
    tdsAmount: string(),
    category: object().required("Please select category "),
    repeat: object().required("Please select repeat "),
    recurranceDateAndMonthForYear: string()
      .test(
        "test-recurranceDateAndMonthForYear",
        "Please select date",
        (value, context) => {
          if (context?.parent?.repeat?.value === "YEARLY") {
            if (!value) {
              return false;
            } else {
              return true;
            }
          }
          return true;
        }
      )
      .nullable(),
    recurranceDate: string()
      .test("test-recurranceDate", " Please select date", (value, context) => {
        if (context?.parent?.repeat?.value === "MONTHLY") {
          if (!value) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      })
      .nullable(),
    days: array().test("test-days", "Please select day", (value, context) => {
      if (context?.parent?.repeat?.value === "WEEKLY") {
        return value && value.length > 0;
      }
      return true;
    }),

    until: object().required("Please select until"),
    untilDate: string()
      .test("test-days", "Please select date", (value, context) => {
        if (context?.parent?.until?.value === "DATE") {
          if (!value) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      })
      .nullable(),
    event: string()
      .test("test-event", "Please enter number of events", (value, context) => {
        if (context?.parent?.until?.value === "EVENT") {
          if (!value) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      })
      .nullable(),
    description: string().required("Please enter description"),
    currency: object().required("Please enter currency "),
  });

  // Handle Submit
  const handleSubmit = (
    values: RecurringIncomeFormProps,
    { setSubmitting, resetForm }: FormikHelpers<RecurringIncomeFormProps>
  ) => {
    const formattedValues = {
      amount: values?.amount,
      gstApplicable: values?.gstApplicable,
      gstPercentage: values?.gstPercentage || 0,
      tdsAmount: values?.tdsAmount,
      currencyId: values?.currency?.value || data?.data?._id,
      financeCategoryId: values?.category?.value,
      recurranceType: values?.repeat?.value,
      recurranceDate: values?.recurranceDate || undefined,
      recurranceDays: values?.days,
      until: values?.until?.value,
      untilDate: moment(values?.untilDate).format("YYYY-MM-DD") || "",
      untilNoOfEvents: values?.event || 0,
      description: values?.description,
      recurranceDateAndMonthForYear: moment(values?.untilDate).format("MM-DD"),
    };
    addRecurringIncome(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate("/finance/recurringincome");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <RecurringIncomeFormLayout
            formType="ADD"
            formikProps={formikProps}
            onClose={() => navigate("/finance/recurringincome")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddRecurringIncomeFormWrapper;
