import { Skeleton } from "@mui/material";
import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { IoFilter } from "react-icons/io5";
import { PiUserFill } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import ATMAccordian from "src/components/UI/atoms/ATMAccordian/ATMAccordian";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import ColorComponent from "src/components/UI/colorComponent";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { setSearchValue } from "src/redux/slices/TicketGroupSlice";
import { RootState } from "src/redux/store";
import { useGetAddTicketGroupTimeAnylticsQuery } from "src/services/TicketGroupServices";
import { CLIENT_GRW } from "src/utils/constants";
import { TicketGroupFormValues } from "../Add/AddTicketGroupFormWrapper";
import TicketGroupListFilterFormDialogWrapper from "../Add/TicketFilterFormCard/TicketGroupListFilterFormDialogWrapper";

type Props = {
  formikProps: FormikProps<TicketGroupFormValues>;
  ticketOptions?: any;
};

const TicketGroupForm = ({
  formikProps,
  ticketOptions
}: Props) => {
  const dispatch = useDispatch();
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const { searchValue, isTicketTableLoading } = useSelector((state: RootState) => state.ticketGroup);

  const [isOpenTicketFilterDialog, setIsOpenTicketFilterDialog] = useState<boolean>(false);
  const [timeAnalyticData, setTimeAnalyticData] = useState<any>();
  const { userData } = useGetLocalStorage();
  const [isMeetingMode, setIsMeetingMode] = useState<any>(false);

  useEffect(() => {
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem("isMeetingModeEnable") as string;
    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";
    setIsMeetingMode(isEnabled);
  }, [isMeetingMode]);


  const { isLoading, isFetching, data } = useGetAddTicketGroupTimeAnylticsQuery({ tickets: values?.tickets }, {
    skip: !values?.tickets?.length
  });

  const [taskCTC, setTaskCTC] = useState<any>();
  const [taskGCTC, setTaskGCTC] = useState<any>();
  const [totalTicketTime, setTotalTicketTime] = useState<any>();
  const [selectedTickets, setSelectedTickets] = useState<any>([]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTimeAnalyticData(data?.data);
      const ctcPayment = data?.data?.reduce((acc: any, ele: any) => ele?.totalSalaryMinWise + acc, 0);
      const gctcPayemnt = data?.data?.reduce((acc: any, ele: any) => ele?.totalGrowthSalaryMinWise + acc, 0);
      const totalTicketsTime = data?.data?.reduce((acc: any, ele: any) => ele?.durationInMinutes + acc, 0);
      setTaskCTC(ctcPayment);
      setTaskGCTC(gctcPayemnt);
      setTotalTicketTime(totalTicketsTime);
    }
  }, [isLoading, isFetching, data]);

  const toggleTicketSelection = (ticket: any) => {
    const isSelected = values?.tickets.includes(ticket._id);

    if (isSelected) {
      setFieldValue("tickets", values?.tickets?.filter((id: any) => id !== ticket._id));
      setSelectedTickets((prev: any) => prev.filter((t: any) => t._id !== ticket._id));
    } else {
      setFieldValue("tickets", [...values?.tickets, ticket._id]);
      setSelectedTickets((prev: any) => [...prev, ticket]);
    }
  };


  return (
    <SideNavLayout>
      <div className="p-4 flex flex-col gap-4">
        <div className="flex justify-between items-center sticky top-0 py-2 bg-white z-20">
          <div className="text-xl font-medium"> Add Ticket Group </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <ATMTextField
              name="groupName"
              required
              value={values.groupName}
              onChange={(e) => setFieldValue("groupName", e.target.value)}
              label="Group Name"
              placeholder="Enter Group name"
              onBlur={handleBlur}
            />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex gap-4 items-center">
              <div className="w-[300px]">
                <ATMInputAdormant
                  name=""
                  className=""
                  value={searchValue}
                  onChange={(e) => dispatch(setSearchValue(e.target.value))}
                  adormant={<BiSearch />}
                  adormantProps={{ position: 'start', extraClasses: 'bg-white border-0' }}
                  inputProps={{ className: 'bg-white' }}
                  placeholder="Search..."
                />
              </div>
              <div className="w-[]">
                <ATMLoadingButton
                  onClick={() => setIsOpenTicketFilterDialog(true)}
                  className="">
                  <div className="flex items-center gap-2">
                    <IoFilter />
                    Filters
                  </div>
                </ATMLoadingButton>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 items center justify-between">
              <div className="h-[350px] overflow-scroll border rounded p-2">
                {isTicketTableLoading ? (
                  <div className="flex flex-col md:px-2 py-1">
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                  </div>
                ) : (
                  <div className="">
                    <div className="font-medium mb-2">Tickets : </div>
                    {ticketOptions?.length ? ticketOptions?.map((ticket: any) => (
                      <div className="border-b my-1" key={ticket._id}>
                        <ATMCheckbox
                          id={ticket._id}
                          checked={values?.tickets?.includes(ticket._id)}
                          onChange={() => toggleTicketSelection(ticket)}
                          label={
                            <div>
                              <h3 className="w-full line-clamp-1 text-md font-medium">
                                {ticket.title}
                              </h3>
                            </div>
                          }
                        />
                      </div>
                    )) : (
                      <div className="flex justify-center my-2"> No Ticket Found! </div>
                    )}
                  </div>
                )}
              </div>
              {values?.tickets?.length ? (
                <div className="h-[350px] overflow-scroll border rounded p-2">
                  <div className="font-medium mb-2">Selected Tickets : {selectedTickets?.length ? selectedTickets?.length : null}</div>
                  {selectedTickets?.length ? selectedTickets.map((ticket: any) => (
                    <div className="border-b my-1" key={ticket._id}>
                      <ATMCheckbox
                        id={ticket._id}
                        checked={true}
                        onChange={() => toggleTicketSelection(ticket)}
                        label={
                          <div>
                            <h3 className="w-full line-clamp-1 text-md font-medium">
                              {ticket?.title}
                            </h3>
                          </div>
                        }
                      />
                    </div>
                  )) : (
                    <div className="flex justify-center my-2"> No Tickets Selected! </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <p className="text-md flex items-center">
            <span className="">Total Duration In Tickets :</span>
            {totalTicketTime ? (
              <p className="font-medium">
                <span className="ml-1">
                  {`${Math?.floor(totalTicketTime / 60)}:${(totalTicketTime % 60)?.toFixed()} hrs`}
                </span>
                <span className="text-sm text-neutral ml-1">
                  ({`${totalTicketTime?.toFixed()}`} min)
                </span>
              </p>
            ) : (
              "0 min"
            )}
          </p>
          {timeAnalyticData?.length ?
            <ATMAccordian
              heading={"Timeline"}
              extraClass="border bg-white h-fit rounded-md p-2"
              isOpen={false}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
                {timeAnalyticData?.length ? timeAnalyticData?.map((el: any, ind: any) => {
                  return (
                    <div
                      key={ind}
                      className="flex flex-col gap-6 border bg-white h-fit items-center rounded-md p-4"
                    >
                      <div className="flex flex-col items-center gap-2">
                        <div className="border p-2 rounded-full aspect-square">
                          <PiUserFill
                            size={16}
                            className="rounded-full mx-auto"
                          />
                        </div>
                        <div className="flex flex-col items-center">
                          <div className="text-lg font-medium">
                            <ColorComponent
                              inputString={el?.codioticUserName}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="font-medium flex items-center gap-1">
                        <span className="text-lg">
                          {`${Math?.floor(el?.durationInMinutes / 60)}:${(
                            el?.durationInMinutes % 60
                          )?.toFixed()}  hrs`}
                        </span>

                        <span className="text-sm text-neutral">
                          ({`${el?.durationInMinutes?.toFixed()}`} min)
                        </span>
                      </div>
                    </div>
                  );
                }) : null}
              </div>
            </ATMAccordian> : null}

          {userData?.userType === "SUPER_ADMIN" && (
            <div>
              {timeAnalyticData?.length ? (
                <div className="flex justify-end gap-6 w-full px-4">
                  {!isMeetingMode && (
                    <div>
                      <span className="font-semibold text-sm text-cyan-600">CTC : </span>
                      <span className="px-1">₹{taskCTC || 0} </span>
                    </div>
                  )}
                  {CLIENT_GRW === true ? (
                    <div>
                      <span className="font-semibold text-sm text-green-600">
                        {!isMeetingMode ? "GCTC" : "CTC"} :
                      </span>
                      <span className="px-1">₹{taskGCTC || 0} </span>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="flex justify-end sticky bottom-0 py-2 bg-white">
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div>
      </div>
      {
        <TicketGroupListFilterFormDialogWrapper
          open={isOpenTicketFilterDialog}
          onClose={() => setIsOpenTicketFilterDialog(false)}
        />
      }
    </SideNavLayout>

  );
};

export default TicketGroupForm;
