import React from "react";
import StatusChangeDialog from "./StatusChangeDialog";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { useUpdateTaskStatusMutation } from "src/services/TaskService";
import { object } from "yup";

type Props = {
  onClose: any;
  assignToId: string;
  assignToName: string;
  selectedTask: string;
  projectId: string;
  labelId: any;
};

const StatusChangeDialogWrapper = ({
  onClose,
  selectedTask,
  projectId,
  assignToId,
  assignToName,
  labelId,
}: Props) => {
  const [updateTaskStatus] = useUpdateTaskStatusMutation();

  const initialValues = {
    assignedTo: { label: assignToName, value: assignToId },
    requestedLabel: { labelName: labelId[0]?.labelName, _id: labelId[0]?._id },
  };

  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    const { requestedLabel, assignedTo, ...rest } = values;
    const formattedValues = {
      ...rest,
      assignedTo: assignedTo?.value,
      requestedLabel: requestedLabel?.labelName,
    };
    updateTaskStatus({ id: selectedTask, body: formattedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };
  // Validation Schema
  const validationSchema = object().shape({
    assignedTo: object().required("Please Select assignee"),
    requestedLabel: object().required("Please Select Label"),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <StatusChangeDialog
            projectId={projectId}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default StatusChangeDialogWrapper;
