import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { DoubtsFormValue } from "src/models/Doubts.model";
import {
  useEditDoubtMutation,
  useGetDoubtByIdQuery,
} from "src/services/DoubtsServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import DoubtsForm from "../Layouts/DoubtsForm";

type Props = {
  doubtId: string;
  onClose: () => void;
};

const EditDoubtsFormWrapper = ({ doubtId, onClose }: Props) => {
  const [doubtData, setDoubtData] = useState<any>();

  const { isLoading, isFetching, data } = useGetDoubtByIdQuery(doubtId, {
    skip: !doubtId,
  });

  const [editDoubt] = useEditDoubtMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setDoubtData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: DoubtsFormValue = {
    project: {
      label: doubtData?.projectName,
      value: doubtData?.projectId,
    },
    module: {
      label: doubtData?.moduleName,
      value: doubtData?.moduleId,
    },
    feature: {
      label: doubtData?.featureName,
      value: doubtData?.featureId,
    },
    askTo: {
      label: doubtData?.assignedToUserName,
      value: doubtData?.askToId,
    },
    question: doubtData?.question,
  };

  // Validation Schema
  const validationSchema = object().shape({
    project: object().required("Please select project"),
    // module: object().required("Please select module"),
    // feature: object().required("Please select feature"),
    askTo: object().required("Please select ask to assignee"),
    question: string().required("Please enter the question"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DoubtsFormValue,
    { setSubmitting, resetForm }: FormikHelpers<DoubtsFormValue>
  ) => {
    const { project, module, feature, askTo, ...rest } = values;
    const formattedValues = {
      projectId: project?.value,
      moduleId: module?.value,
      featureId: feature?.value,
      askToId: askTo?.value,
      ...rest,
    };

    editDoubt({ id: doubtId, body: formattedValues }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <DoubtsForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditDoubtsFormWrapper;
