import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { AppDispatch, RootState } from "src/redux/store";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import PaymentModeListing from "./PaymentModeListing";
import EditPaymentModeWrapper from "../Edit/EditPaymentModeWrapper";
import AddPaymentModeWrapper from "../Add/AddPaymentModeWrapper";
import {
  useDeletePaymentModeMutation,
  useGetPaymentModesQuery,
} from "src/services/Finance-New-Services/PaymentModeServices";
import {
  setIsTableLoading,
  setItems,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/Finance-New-Slices/PaymentModeSlice";

type Props = {};

const PaymentModeListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  const [selectedtPaymentId, setSelectedPaymentId] = useState("");

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.paymentMode);

  const [deletePaymentMode] = useDeletePaymentModeMutation();
  const { data, isLoading, isFetching } = useGetPaymentModesQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["modeName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Payment Mode?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deletePaymentMode(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "modeName",
      headerName: "Payment mode",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      name: UserModuleNameTypes.ACCOUNTS_LIST_ACCOUNT_NAME,
      renderCell: (row: any) => {
        return (
          <div>
            {row?.modeName !== "-" ? <span>{row?.modeName}</span> : "-"}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: "flex-[1_1_0%]",
      align: "end",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectedPaymentId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_ACCOUNTS_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_ACCOUNTS_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <PaymentModeListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setRowsPerPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
          onAddNew={() => setIsOpenAddDialog(true)}
        />
      </div>
      {isOpenEditDialog && (
        <EditPaymentModeWrapper
          paymentId={selectedtPaymentId}
          onClose={() => setIsOpenEditDialog(false)}
        />
      )}
      {isOpenAddDialog ? (
        <AddPaymentModeWrapper onClose={() => setIsOpenAddDialog(false)} />
      ) : null}
    </>
  );
};

export default PaymentModeListingWrapper;
