import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTable from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import AddTicketFormWrapper from "./AddTicket/AddTicketFormWrapper";

type Props = {
  isLoading: any;
  columns: any;
  items: any;
};

const TicketGroupDetailsView = ({ items, isLoading, columns }: Props) => {
  const navigate = useNavigate();

  const [addTicketDialogOpen, setAddTicketDialogOpen] = useState(false);

  return (
    <div className="flex flex-col flex-1 overflow-auto bg-gray-50">
      <div className="flex justify-end mb-1">
        <ATMLoadingButton
          onClick={() => setAddTicketDialogOpen(true)}
          className="w-[12%]"
        >
          Tickets Add
        </ATMLoadingButton>
      </div>
      <div className="h-[75vh] overflow-scroll">
        <ATMTable
          columns={columns}
          rows={items || []}
          rowExtraClasses={() => "h-[50px]"}
          isLoading={isLoading}
          disableRowClick={
            !isAuthorized(
              UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_SINGLE_VIEW
            )
          }
          onRowClick={(row) => navigate(`/ticket/${row?._id}/details`)}
        />
      </div>

      {addTicketDialogOpen && (
        <AddTicketFormWrapper onClose={() => setAddTicketDialogOpen(false)} />
      )}
    </div>
  );
};

export default TicketGroupDetailsView;
