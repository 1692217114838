import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetSalarySlipByIdQuery } from "src/services/TeamServices";
import SalarySlipView from "./SalarySlipView";
import { CircularProgress } from "@mui/material";

const SalarySlipViewWrapper = () => {
  const { teamMemberId } = useParams();
  const { data, isLoading, isFetching } =
    useGetSalarySlipByIdQuery(teamMemberId);
  const [salaryInfo, setSalaryInfo] = useState();
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setSalaryInfo(data);
    }
  }, [data, isLoading, isFetching]);
  return (
    <SideNavLayout>
      {isLoading || isFetching ? (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      ) : (
        <SalarySlipView salaryInfo={salaryInfo} />
      )}
    </SideNavLayout>
  );
};

export default SalarySlipViewWrapper;
