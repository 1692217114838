import apiSlice from "./ApiSlice";

export const operationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all Operation pagination
    getOperationPagination: builder.query({
      providesTags: ["operation"],
      query: (body) => {
        return {
          url: "/operation",
          method: "POST",
          body,
        };
      },
    }),

    // Get all Operation
    getAllOperation: builder.query({
      providesTags: ["operation"],
      query: () => {
        return {
          url: "/operation",
          method: "GET",
        };
      },
    }),

    // Get single Operation
    getSingleOperation: builder.query({
      providesTags: ["operation"],
      query: (id: string) => {
        return {
          url: `/operation/${id}`,
          method: "GET",
        };
      },
    }),

    // Add Operation
    addOperation: builder.mutation({
      invalidatesTags: ["operation"],
      query: (body) => {
        return {
          url: "/operation/add",
          method: "POST",
          body,
        };
      },
    }),

    // Update social media
    updateOperation: builder.mutation({
      invalidatesTags: ["operation"],
      query: ({ id, body }) => {
        return {
          url: `/operation/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // delete Operation
    deleteOperation: builder.mutation({
      invalidatesTags: ["operation"],
      query: (id: any) => ({
        url: `/operation/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetOperationPaginationQuery,
  useGetAllOperationQuery,
  useGetSingleOperationQuery,
  useAddOperationMutation,
  useUpdateOperationMutation,
  useDeleteOperationMutation,
} = operationsApi;
