import { useEffect } from "react";
import { FcPrint } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/RoundTrackingReportSlice";
import { RootState } from "src/redux/store";
import { useGetRoundTrackingReportQuery } from "src/services/PayoutService";
import RoundTrackingReportListing from "./RoundTrackingReportListing";
import { useParams } from "react-router-dom";

const RoundTrackingReportListingWrapper = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.roundTrackingReport);
  const {
    data: incomeList,
    isFetching,
    isLoading,
  } = useGetRoundTrackingReportQuery(id || "", { skip: !id });

  const columns = [
    {
      field: "fullName",
      headerName: "Member name",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div className="capitalize">{row?.fullName}</div>
      ),
    },
    {
      field: "year",
      headerName: "Year",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.year || "-"}</div>,
    },
    {
      field: "month",
      headerName: "Month",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.month || "-"}</div>,
    },

    {
      field: "onBoardingBeforeDate",
      headerName: "Total Earning",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => {
        const totalEarnings =
          row?.earnings?.reduce((sum: any, el: any) => sum + el.value, 0) || 0;
        return <div>{totalEarnings || "-"}</div>;
      },
    },
    {
      field: "onBoardingBeforeDate",
      headerName: "Total Deducation",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => {
        const totalDeductions =
          row?.deductions?.reduce((sum: any, el: any) => sum + el.value, 0) ||
          0;
        return <div>{totalDeductions || "-"}</div>;
      },
    },

    {
      field: "daysOfAbsent",
      headerName: "Total Absent",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.daysOfAbsent || "-"}</div>,
    },
    {
      field: "netAmount",
      headerName: "Net Payment",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.netAmount || "-"}</div>,
    },
    {
      field: "onBoardingBeforeDate",
      headerName: "Download payout",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div
          onClick={() => {
            window.open(`/profile/salarySlip/${row?._id}`, "_blank");
          }}
          className="cursor-pointer"
        >
          {" "}
          <FcPrint size={16} />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => <div></div>,
    },
  ];
  // Setting Team Members Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(incomeList?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(incomeList?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeList, isLoading, isFetching]);
  return (
    <>
      <RoundTrackingReportListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </>
  );
};

export default RoundTrackingReportListingWrapper;
