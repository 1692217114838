import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CashFlowSliceStateType = {
  items: any[] | [];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  acountFilter: any;
  transactionType: any;
  categoryFilter: any;
  paymentModeFilter: any;
  filterBy: {
    fieldName: string;
    value: string;
  }[];
  dateFilter: {
    startDate: string | null;
    endDate: string | null;
    dateFilterKey: string;
  };
};

const initialState: CashFlowSliceStateType = {
  items: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  rowsPerPage: 20,
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  acountFilter: [],
  transactionType: [],
  categoryFilter: [],
  paymentModeFilter: [],
  filterBy: [],
  dateFilter: {
    startDate: "",
    endDate: "",
    dateFilterKey: "paymentDate",
  },
};

const caseFlowSlice: Slice<CashFlowSliceStateType> = createSlice({
  name: "caseFlowSlice",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string }[]>
    ) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setAcountFilter: (state, action: PayloadAction<string[]>) => {
      state.acountFilter = action.payload;
    },
    setTransactionTypeFilter: (state, action: PayloadAction<string[]>) => {
      state.transactionType = action.payload;
    },
    setPaymentModeFilter: (state, action: PayloadAction<string[]>) => {
      state.paymentModeFilter = action.payload;
    },
    setCategoryFilter: (state, action: PayloadAction<string[]>) => {
      state.categoryFilter = action.payload;
    },
    setDateFilter: (
      state,
      action: PayloadAction<{
        startDate: string;
        endDate: string;
        dateFilterKey: string;
      }>
    ) => {
      state.dateFilter = action.payload;
    },
  },
});

export const {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setFilterBy,
  setAcountFilter,
  setTransactionTypeFilter,
  setCategoryFilter,
  setPaymentModeFilter,
} = caseFlowSlice.actions;
export default caseFlowSlice.reducer;
