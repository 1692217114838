// |-- External Dependencies --|
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type LocationSelectType = {
  value: string;
  label: string;
};

export interface CitytSliceStateType {
  items: any[] | [];
  allDistricts: any[] | [];
  selectedCity: any | null;
  selectedLocationCity: LocationSelectType | null;
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  selectedId: string;
  filterValue: string;
}

const initialState: CitytSliceStateType = {
  items: [],
  allDistricts: [],
  selectedCity: null,
  selectedLocationCity: null,
  totalItems: 0,
  isTableLoading: false,
  page: 1,
  rowsPerPage: 10,
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  selectedId: "",
  filterValue: "",
};

const citySlice: any = createSlice({
  name: "district",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setSelectedId: (state, action: PayloadAction<string>) => {
      state.selectedId = action.payload;
    },
    setSelectedCity: (state, action: PayloadAction<any | null>) => {
      state.selectedCity = action.payload;
    },
    setSelectedLocationCity: (
      state,
      action: PayloadAction<LocationSelectType | null>
    ) => {
      state.selectedLocationCity = action.payload;
    },
    setFilterValue: (state, action: PayloadAction<string>) => {
      state.filterValue = action.payload;
    },
    setAllCity: (state, action: PayloadAction<any[] | []>) => {
      state.allDistricts = action.payload;
    },
  },
});

export const {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setSelectedId,
  setSelectedDistrict,
  setSelectedLocationCity,
  setFilterValue,
  setAllCity,
} = citySlice.actions;
export default citySlice.reducer;
