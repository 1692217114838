import React from "react";
import moment from "moment";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import { FormInitialValues } from "./TaskTimeAnalyticsFilterWrapper";
import { FormikProps } from "formik";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};


const TaskTimeAnalyticsFilter = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  return (
    <MOLFilterDialog
    title={"Filters"}
    onClose={onClose}
    isSubmitting={isSubmitting}
    clearFilter={onReset}
    handleSubmit={handleSubmit}
    open={open}
  >
     <div className="flex flex-col gap-4 px-4 py-2">
        {/* Created At */}
        <div className="mb-2">
          <div className="flex flex-col gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                maxDate={moment().format("yyyy-MM-DD")}
                value={values?.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values?.endDate}
                minDate={values?.startDate || ''}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>
      </div>
  </MOLFilterDialog>
    
  );
};

export default TaskTimeAnalyticsFilter;
