import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { ExpenseCategoryFormValues } from "src/models/ExpenseCategory.model";

type Props = {
  formikProps: FormikProps<ExpenseCategoryFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const ExpenseCategoryForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={
        formType === "ADD" ? "Add Expense Category" : "Update Expense Category"
      }
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      {/* Organisation Name */}
      <div>
        <ATMTextField
          required
          name="title"
          value={values.title}
          onChange={(e) => setFieldValue("title", e.target.value)}
          label="Expense Category"
          placeholder="Enter Expense Category"
          onBlur={handleBlur}
        />
      </div>
    </MOLFormDialog>
  );
};

export default ExpenseCategoryForm;
