import apiSlice from "./ApiSlice";

export const campaignApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL CAMPAIGN
    getAllCampaignList: builder.query({
      providesTags: ["campaign"],
      query: () => {
        return {
          url: "campaign",
          method: "GET",
        };
      },
    }),

    //GET CAMPAIGN PAGINATION
    getAllCampaigns: builder.query({
      providesTags: ["campaign"],
      query: (body) => {
        return {
          url: "campaign",
          method: "POST",
          body,
        };
      },
    }),

    //ADD CAMPAIGN
    addCampaign: builder.mutation({
      invalidatesTags: ["campaign"],
      query: (body) => {
        return {
          url: "campaign/add",
          method: "POST",
          body,
        };
      },
    }),
    //EDIT CAMPAIGN
    editCampaign: builder.mutation({
      invalidatesTags: ["campaign"],
      query: ({ body, id }) => {
        return {
          url: `campaign/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //GET CAMPAIGN BY ID
    getCampaignById: builder.query({
      providesTags: ["campaign"],
      query: (id) => {
        return {
          url: `campaign/${id}`,
          method: "GET",
        };
      },
    }),
    //DELETE CAMPAIGN BY ID
    deleteCampaign: builder.mutation({
      invalidatesTags: ["campaign"],
      query: (id) => {
        return {
          url: `campaign/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAllCampaignListQuery,
  useGetAllCampaignsQuery,
  useAddCampaignMutation,
  useEditCampaignMutation,
  useGetCampaignByIdQuery,
  useDeleteCampaignMutation,
} = campaignApi;
