import React from "react";
import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { MeetingTypeFormValues } from "src/models/MeetingType.model";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<MeetingTypeFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const MeetingTypeForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Meeting Type" : "Update Meeting Type"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          {/* Currency Name */}
          <div>
            <ATMTextField
              required
              name="meetingType"
              value={values.meetingType}
              onChange={(e) => setFieldValue("meetingType", e.target.value)}
              label="Meeting  Type"
              placeholder="Enter Meeting  Type"
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default MeetingTypeForm;
