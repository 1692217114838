import React from "react";
import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { ContactFormValues } from "src/models/Contact.model";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<ContactFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
};

const AddLeadContactsDialogForm = ({ formikProps, onClose, formType }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Lead Contact" : "Update Contact"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMTextField
            name="name"
            required
            value={values.name}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
            }}
            label="Name"
            placeholder="Enter Name"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMTextField
            name="email"
            value={values?.email}
            onChange={(e) => {
              setFieldValue("email", e.target.value);
            }}
            label="Email"
            placeholder="Enter Email"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMTextField
            name="mobile"
            value={values?.mobile}
            onChange={(e) => {
              setFieldValue("mobile", e.target.value);
            }}
            label="Mobile"
            placeholder="Enter Mobile"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default AddLeadContactsDialogForm;
