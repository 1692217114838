import React from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  onAddNew: () => void;
  currentSalary: any;
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const TeamSalaryTabList = ({
  columns,
  rows,
  currentSalary,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  return (
    <div className="flex flex-col h-full">
      {/* Page Header */}
      <div>
        <ATMPageHeader
          hideSearchBox
          pageTitle="Salary History"
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          buttonProps={{
            btnName: "Add Increment",
            onClick: onAddNew,
          }}
          hideAddButton={
            !isAuthorized(
              UserModuleNameTypes.ACTION_TEAMS_VIEW_SALARY_SUMMARY_TAB_ADD_INCREMENT
            )
          }
        />
      </div>
      <div className="shadow-lg p-3 my-1 mb-3 w-[240px] bg-gray-50 rounded">
        <span className="font-medium text-[18px]">Current Salary</span> :{" "}
        <span> ₹ {currentSalary || 0} </span>
      </div>

      {/* Table */}
      <div className="flex flex-col flex-1 overflow-auto">
        <ATMTable
          columns={columns}
          rows={rows}
          rowExtraClasses={() => "h-[50px]"}
          isLoading={isTableLoading}
        />
      </div>

      <div>
        <ATMPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          rowCount={totalItems}
          rows={rows}
        />
      </div>
    </div>
  );
};

export default TeamSalaryTabList;
