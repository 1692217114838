import { FormikProps } from "formik";
import moment from "moment";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMRadioButtonGroup from "src/components/UI/atoms/formFields/ATMRadioButtonGroup/ATMRadioButtonGroup";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { useGoalCategory } from "src/hooks/useGoalCategory";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import { GoalsFormValues } from "src/models/Goals.model";
import { handleValidNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<GoalsFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const GoalForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  // hook
  const { goalCategory, isDataLoading } = useGoalCategory();

  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Goal" : "Update Goal"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Goal Category  */}
        <div className="">
          <ATMSelect
            required
            name="goalCategoryId"
            value={values.goalCategoryId}
            onChange={(newValue: any) => {
              setFieldValue("goalCategoryId", newValue);
            }}
            onBlur={handleBlur}
            label="Goal Category"
            placeholder="Select Goal Category"
            options={goalCategory?.map((ele: any) => {
              return {
                label: ele?.categoryName,
                value: ele?._id,
              };
            })}
            isLoading={isDataLoading}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return <div className="capitalize"> {option.label} </div>;
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            isOptionEqualToValue={(option, selected) => {
              return (
                selected?.findIndex(
                  (selectedValue: any) => selectedValue?.value === option.value
                ) > -1
              );
            }}
            isSearchedOption={(option, searchValue) => {
              return searchValue
                ? option?.data?.label?.toLowerCase()?.includes(searchValue)
                : true;
            }}
          />
        </div>

        {/* Amount */}
        <div>
          <ATMTextField
            required
            name="name"
            value={values.name}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
            }}
            label="Name"
            placeholder="Enter Name"
            className="mt-0 rounded"
            onBlur={handleBlur}
          />
        </div>

        <div className="">
          <ATMRadioButtonGroup
            label="Types"
            required={true}
            name="type"
            value={values.type}
            options={[
              {
                label: "COUNTABLE",
                value: "COUNTABLE",
              },
              {
                label: "UNCOUNTABLE",
                value: "UNCOUNTABLE",
              },
            ]}
            onChange={(newValue) => {
              setFieldValue("type", newValue);
              setFieldValue("target", 0);
            }}
          />
        </div>

        <div>
          <ATMDatePicker
            required
            label="Dead Line Date"
            name="deadline"
            format="DD/MM/YYYY"
            value={values.deadline}
            minDate={moment().format("yyyy-MM-DD")}
            onChange={(newValue) => setFieldValue("deadline", newValue)}
          />
        </div>

        {/* Amount */}
        {values?.type === "COUNTABLE" && (
          <div>
            <ATMTextField
              required
              name="target"
              value={values.target}
              onChange={(e) => {
                handleValidNumber(e) && setFieldValue("target", e.target.value);
              }}
              label="Target"
              placeholder="Enter Target"
              className="mt-0 rounded"
              onBlur={handleBlur}
            />
          </div>
        )}

        {/* Members */}
        <ATMMultiSelect
          name="members"
          required
          value={values?.members}
          label="Members Involved"
          placeholder="Select members"
          isLoading={isCodioticUserDataLoading}
          onChange={(newValue) => {
            setFieldValue("members", newValue);
          }}
          options={codioticUsers?.map((ele: CodioticUserListResponse) => {
            return {
              label: ele?.name,
              value: ele?._id,
            };
          })}
        />

        {/* Me */}

        <div>
          <ATMSelect
            required
            name="measuredBy"
            label="Measured"
            placeholder="Select measured"
            value={values.measuredBy}
            isLoading={isCodioticUserDataLoading}
            options={codioticUsers?.map((ele: CodioticUserListResponse) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("measuredBy", newValue);
            }}
          />
        </div>
        {/* {formType === "ADD" && (
          <div>
            <ATMCheckbox
              label="Save and Next"
              checked={saveNextChecked}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        )}
        <div>
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div> */}
      </div>
    </MOLFormDialog>
  );
};

export default GoalForm;
