import { FormikProps } from "formik";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<any>;
  onReset: () => void;
  onClose: () => void;
};

const AttendanceDateFilter = ({ formikProps, onReset, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  return (
    <MOLFilterDialog
      title={"Filters"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      clearFilter={onReset}
      handleSubmit={handleSubmit}
      open
    >
      <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">
        {/* Created At */}
        <div className="mb-2">
          <div className="flex gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                value={values?.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values?.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>
      </div>
    </MOLFilterDialog>
  );
};

export default AttendanceDateFilter;
