import apiSlice from "./ApiSlice";

export const leadsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL leadsLabel
    getAllLeadsListPagination: builder.query({
      providesTags: ["leads"],
      query: (body: any) => {
        return {
          url: "lead",
          method: "POST",
          body,
        };
      },
    }),

    // GET ALL leadsLabel
    getAllLeadsFollowup: builder.query({
      providesTags: ["followups"],
      query: (body) => {
        return {
          url: "lead-followup",
          method: "POST",
          body,
        };
      },
    }),
    // Add
    addLeads: builder.mutation({
      invalidatesTags: ["leads"],
      query: (body) => {
        return {
          url: "/lead/add",
          method: "POST",
          body,
        };
      },
    }),
    // Get the Lead by id
    getSingleLead: builder.query({
      providesTags: ["leads"],
      query: (id: string) => {
        return {
          url: `/lead/${id}`,
          method: "GET",
        };
      },
    }),
    // Get the Lead by id
    UpdateLead: builder.mutation({
      invalidatesTags: ["leads"],
      query: ({ id, body }: any) => {
        return {
          url: `/lead/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //ADD LEAD FOLLOWUP
    addLeadFollowup: builder.mutation({
      invalidatesTags: ["followups"],
      query: (body) => {
        return {
          url: "/lead-followup/add",
          method: "POST",
          body,
        };
      },
    }),
    //EDIT LEAD FOLLOWUP
    editLeadFollowup: builder.mutation({
      invalidatesTags: ["followups"],
      query: ({ body, id }) => {
        return {
          url: `/lead-followup/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //DELETE LEAD FOLLOWUP
    deleteLeadFollowup: builder.mutation({
      invalidatesTags: ["followups"],
      query: (id) => {
        return {
          url: `/lead-followup/${id}`,
          method: "DELETE",
        };
      },
    }),

    //GET FOLLOWUP By ID
    getLeadsFollowupById: builder.query({
      providesTags: ["followups"],
      query: (id) => {
        return {
          url: `lead-followup/${id}`,
          method: "GET",
        };
      },
    }),

    // Add Lead in CSV files
    addLeadsIntoCsvFormat: builder.mutation({
      // invalidatesTags: ["followups"],
      query: (body) => {
        return {
          url: "lead/bulk-lead-upload",
          method: "POST",
          body,
        };
      },
    }),
    //ADD LEAD FOLLOWUP
    getPendingLeadFollowup: builder.query({
      providesTags: ["followups"],
      query: (body) => {
        return {
          url: "/lead-followup/today-followup",
          method: "GET",
        };
      },
    }),
    //Update star Leads 
    giveStarInLead: builder.mutation({
      invalidatesTags: ["leads"],
      query: ( id:any ) => {
        return {
          url: `/lead/starred/${id}`,
          method: "PUT",
        };
      },
    }),

  }),
});

export const {
  useGetAllLeadsFollowupQuery,
  useAddLeadFollowupMutation,
  useEditLeadFollowupMutation,
  useDeleteLeadFollowupMutation,
  useGetLeadsFollowupByIdQuery,
  useAddLeadsMutation,
  useUpdateLeadMutation,
  useGetSingleLeadQuery,
  useGetAllLeadsListPaginationQuery,
  useAddLeadsIntoCsvFormatMutation,
  useGetPendingLeadFollowupQuery ,
  useGiveStarInLeadMutation
} = leadsApi;
