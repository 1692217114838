import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { DepartmentFormValues } from "src/models/department.model";

type Props = {
  formikProps: FormikProps<DepartmentFormValues>;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
  onClose: () => void;
};

const DepartmentForm = ({
  formikProps,
  formType = "ADD",
  setSaveNextChecked,
  saveNextChecked,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Department" : "Update Department"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="">
        {/* Module Name */}
        <ATMTextField
          required
          name="repoName"
          value={values.repoName}
          onChange={(e) => setFieldValue("repoName", e.target.value)}
          label="Department Name"
          placeholder="Enter Department Name"
          onBlur={handleBlur}
        />
      </div>
    </MOLFormDialog>
  );
};

export default DepartmentForm;
