import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { ContactLeadformValues } from "src/models/ContactLead.model";
import ContactLeadForm from "../Layouts/ContactLeadForm";
import { useAddContactLeadsMutation } from "src/services/ContactService";
import { showToast } from "src/utils/showToaster";
import { useParams } from "react-router-dom";

type Props = {
  onClose: () => void;
};

const AddContactLeadFormWrapper = ({ onClose }: Props) => {
  const [addContactLeads] = useAddContactLeadsMutation();
  const { contactId } = useParams();

  // Form Initial Values
  const initialValues: ContactLeadformValues = {
    summaryNote: "",
    organizationName: "",
    assignedTo: "",
    countryId: "",
    channelId: "",
    campaignId: "",
    domainId: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    organizationName: string().required("Please enter organization name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ContactLeadformValues,
    { setSubmitting, resetForm }: FormikHelpers<ContactLeadformValues>
  ) => {
    const formattedValues = {
      contactId: contactId,
      summaryNote: values?.summaryNote,
      organizationName: values?.organizationName,
      countryId: values?.countryId?.value,
      channelId: values?.channelId?.value,
      campaignId: values?.campaignId?.value,
      domainId: values?.domainId?.value,
    };
    addContactLeads(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ContactLeadForm
            formType="ADD"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddContactLeadFormWrapper;
