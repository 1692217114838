/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import MeetingnNotesListFilterFormDialogWrapper from "./MeetingNotesFilterFormCard/MeetingNotesListFilterFormDialogWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

type Props = {
  rows: any;
  isLoading: boolean;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const MeetingNotesList = ({
  rows,
  isLoading,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenMeetingFilterDialog, setIsOpenMeetingFilterDialog] =
    useState<boolean>(false);

  return (
    <SideNavLayout>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="p-2 md:p-4 py-2 border-b">
          <div className="flex px-2 gap-4 items-center">
            <ATMPageHeader
              pageTitle="Meeting Notes"
              hideAddButton 
              isFilter
              onFilterClick={()=>setIsOpenMeetingFilterDialog(true)}
              searchValue={searchValue}
              onSearchChange={setSearchValue}
            />
          </div>
          {/* Ticket Filter Dialog Wrapper */}
          <MeetingnNotesListFilterFormDialogWrapper
            open={isOpenMeetingFilterDialog}
            onClose={() => setIsOpenMeetingFilterDialog(false)}
          />

          {isLoading ? (
            Array(10)
              .fill(0)
              .map((_, index) => {
                return (
                  <div key={index} className="animate-pulse  h-[100px] p-1">
                    <div className="bg-slate-200 h-full rounded"> </div>
                  </div>
                );
              })
          ) : (
            <div className="h-[100vh] overflow-y-scroll overflow-x-hidden xs:px-0 md:px-0 bg-gray-50">
              {rows ? (
                <>
                  {rows?.length ? (
                    rows?.map((row: any, index: any) => {
                      return isTableLoading ? (
                        Array(10)
                          .fill(0)
                          .map((_, index) => {
                            return (
                              <div
                                key={index}
                                className="animate-pulse h-[50px] p-2"
                              >
                                <div className="h-full rounded"></div>
                              </div>
                            );
                          })
                      ) : (
                        <div
                          key={index}
                          className="flex items-center px-2 pt-2 last:pb-2"
                        >
                          <div
                            className={`rounded-md border max-w-[100vw] ${
                              row?.ticketId ? "bg-slate-200" : "bg-white"
                            } flex-1`}
                            // onDoubleClick={() =>
                            //   meetingData?.status !== "FREEZE" &&
                            //   setSelectedNoteId(row?._id)
                            // }
                          >
                            <div className="px-4 pt-3 pb-1">
                              <div className="text-md capitalize font-medium">
                                {`${row?.projectName} - ${row?.meetingTitle}`}
                              </div>
                              <div className="flex gap-1">
                                {row?.ticketNumber && (
                                  <p className="font-medium text-slate-700 text-sm">
                                    {row?.ticketNumber}
                                  </p>
                                )}
                                -
                                {row?.ticketTitle && (
                                  <p className="font-medium text-slate-700  text-sm">
                                    [{row?.ticketTitle}]
                                  </p>
                                )}
                              </div>
                              {row?.title && (
                                <p className="font-medium text-slate-700">
                                  {row?.title}
                                </p>
                              )}
                              <div className="text-slate-600">
                                <ATMMarkdownContainer
                                  markdown={row?.notes || "-"}
                                />
                              </div>
                            </div>

                            <div className="px-3 py-1 text-xs text-right">
                              <span>{row?.clientUserId}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-sm">
                      Data not Found
                    </div>
                  )}
                  <div className="p-4 py-2 border-t">
                    <ATMPagination
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onPageChange={setPage}
                      onRowsPerPageChange={setRowsPerPage}
                      rowCount={totalItems}
                      rows={rows}
                    />
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-xl">
                  Data not Found
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </SideNavLayout>
  );
};

export default MeetingNotesList;
