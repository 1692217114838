import apiSlice from "./ApiSlice";
import { v4 as uuid } from "uuid";

const deviceId = localStorage.getItem("deviceId") || uuid();
const clientUserApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get All the client user
    getAllUsers: builder.query({
      providesTags: ["users"],
      query: (id: string) => {
        return {
          url: "/user",
          method: "GET",
        };
      },
    }),

    // Add the client user
    addTeamMember: builder.mutation({
      invalidatesTags: ["users"],
      query: (body) => {
        return {
          url: "/user/signup",
          method: "POST",
          body,
        };
      },
    }),

    // Get all the client user with pagination
    getAllUsersWithPagination: builder.query({
      providesTags: ["users"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/user",
          method: "POST",
          body,
        };
      },
    }),

    // Get the client user by id
    getUsersById: builder.query({
      providesTags: ["users"],
      query: (id: string) => {
        return {
          url: `/user/${id}`,
          method: "GET",
        };
      },
    }),

    // Update client user
    updateClientUser: builder.mutation({
      invalidatesTags: ["users"],
      query: ({ id, body }) => {
        return {
          url: `/user/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    changeClientUserPassword: builder.mutation({
      invalidatesTags: ["users"],
      query: ({ id, body }) => {
        return {
          url: `/user/change-password/${id}`,
          method: "PUT",
          headers: {
            "device-id": deviceId,
          },
          body,
        };
      },
      
    }),


    // Change Status of client user
    changeStatus: builder.mutation({
      invalidatesTags: ["users"],
      query: (id: string) => {
        return {
          url: `/user/status-change/${id}`,
          method: "PUT",
        };
      },
      
    }),

    //
  }),
});

export const {
  useGetAllUsersQuery,
  useAddTeamMemberMutation,
  useGetAllUsersWithPaginationQuery,
  useGetUsersByIdQuery,
  useUpdateClientUserMutation,
  useChangeClientUserPasswordMutation,
  useChangeStatusMutation,
} = clientUserApi;
