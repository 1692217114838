import React, { useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { PiWarningCircleFill } from "react-icons/pi";
import { twMerge } from "tailwind-merge";

type Props = {
  formHeading: string;
  children: React.ReactNode;
  isError?: boolean;
  isOpen?: boolean,
  lableExtraClasses?: string,
  formHeadingExtraClass?: string ,
  iconExtraClass? : string
};

const AccordianFormLayout = ({
  formHeading,
  children,
  isError = false,
  isOpen = false,
  lableExtraClasses,
  formHeadingExtraClass ,
  iconExtraClass
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(!isOpen);

  const toggleCollapsed = () => {
    setIsCollapsed((prev) => !prev);
  };
  return (
    <div className={`flex flex-col shadow-sm border rounded`}>
      <div
        onClick={toggleCollapsed}
        className={twMerge(`cursor-pointer px-3  py-[6px]  bg-white flex justify-between border-b ${formHeadingExtraClass} ${isError ? "text-slate-500" : "text-slate-500"
          }`)}
      >
        <div className={twMerge(`flex gap-3 items-end `)}>
          <span className={twMerge(`font-medium text-xl  ${lableExtraClasses}`)}> {formHeading} </span>

          {isError ? (
            <div className="flex items-center gap-1 text-red-400 text-sm font-medium  ">
              <PiWarningCircleFill size={20} />
              This Form Contains Error
            </div>
          ) : null}
        </div>

        <div className={twMerge(`${isCollapsed ? "" : "rotate-90"} transition-all ${iconExtraClass} mt-[2px]`)}>
          <BiChevronRight size={24} />
        </div>
      </div>

      {isCollapsed ? null : <div>{children}</div>}
    </div>
  );
};

export default AccordianFormLayout;
