import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setMeetingCTC, setMeetingGCTC } from "src/redux/slices/TimeAnalyticsProject";
import { RootState } from "src/redux/store";
import { useGetAllProjectsMeetingTimeTakenPaginationQuery } from "src/services/ProjectServices";
import MeetingTimeLogAnalytics from "./MeetingTimeLogAnalytics";

const PlatformMeetingTimeLogAnalyticsWrapper = () => {
  const { id } = useParams();
  const dispatch = useDispatch()
  const [meetingTimeData, setMeetingTimeData] = useState([])

  const { dateFilter } = useSelector(
    (state: RootState) => state.projectTimeAnalytics
  );

  const { data, isLoading, isFetching } =
    useGetAllProjectsMeetingTimeTakenPaginationQuery({
      projectId: id,
      dateFilter: {
        startDate: dateFilter?.startDate || "",
        endDate: dateFilter?.endDate || "",
      },
    });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      (setMeetingTimeData(data?.data?.[0]?.meetings));
      const ctcPayment = data?.data?.[0]?.meetings?.reduce(
        (acc: any, ele: any) => {
          return ele?.totalSalaryMinWise + acc;
        },
        0
      );

      const gctcPayemnt = data?.data?.[0]?.meetings?.reduce(
        (acc: any, ele: any) => {
          return ele?.totalGrowthSalaryMinWise + acc;
        },
        0
      );
      dispatch(setMeetingCTC(ctcPayment || 0));
      dispatch(setMeetingGCTC(gctcPayemnt || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, data]);
  return (
    <>
      {(isLoading || isFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <MeetingTimeLogAnalytics
        meetingTimeData={meetingTimeData}
      />
    </>
  );
};

export default PlatformMeetingTimeLogAnalyticsWrapper;
