import React from "react";
import ProjectModule from "./ProjectModule";

type Props = {
  moduleId: any;
  moduleItems: any;
  moduleIsLoading: any;
  moduleInfo: any;
};

const ProjectModuleWrapper = ({
  moduleId,
  moduleItems,
  moduleIsLoading,
  moduleInfo,
}: Props) => {
  return (
    <>
      <ProjectModule
        moduleInfo={moduleInfo}
        items={moduleItems}
        moduleId={moduleId}
        moduleIsLoading={moduleIsLoading}
      />
    </>
  );
};

export default ProjectModuleWrapper;
