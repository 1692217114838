import apiSlice from "./ApiSlice";

export const leadLabelApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all social media with pagination
    getLeadLabelPagination: builder.query({
      providesTags: ["leadLabelName"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/lead-label",
          method: "POST",
          body,
        };
      },
    }),

    // Get all social media
    getAllLeadLabel: builder.query({
      providesTags: ["leadLabelName"],
      query: () => {
        return {
          url: "/client",
          method: "GET",
        };
      },
    }),

    // Add social media
    addLeadLabel: builder.mutation({
      invalidatesTags: ["leadLabelName"],
      query: (body) => {
        return {
          url: "/lead-label/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get single social media
    getSingleLeadLabel: builder.query({
      providesTags: ["leadLabelName"],
      query: (id: string) => {
        return {
          url: `/lead-label/${id}`,
          method: "GET",
        };
      },
    }),

    // Update social media
    updateLeadLabel: builder.mutation({
      invalidatesTags: ["leadLabelName"],
      query: ({ id, body }) => {
        return {
          url: `/lead-label/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Change Status Social Media
    ChangeStatusLeadLabel: builder.mutation({
      invalidatesTags: ["leadLabelName"],
      query: (id) => {
        return {
          url: `/lead-label/status-change/${id}`,
          method: "PUT",
        };
      },
    }),

    //
  }),
});

export const {
  useGetLeadLabelPaginationQuery,
  useGetAllLeadLabelQuery,
  useGetSingleLeadLabelQuery,
  useAddLeadLabelMutation,
  useUpdateLeadLabelMutation,
  useChangeStatusLeadLabelMutation,
} = leadLabelApi;
