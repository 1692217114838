import React, { useEffect} from "react";
import { useGetAllProjectssTimeTakenPaginationQuery } from "src/services/ProjectServices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { useParams } from "react-router-dom";
import {
  setItems,
  setTaskCTC,
  setTaskGCTC,
  setTotalPayment
} from "src/redux/slices/TimeAnalyticsProject";
import { CircularProgress } from "@mui/material";
import TaskTimeLogAnalytics from "./TaskTimeLogAnalytics";

const TaskTimeLogAnalyticsWrapper = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const { dateFilter } = useSelector(
    (state: RootState) => state.projectTimeAnalytics
  );

  const { data, isLoading, isFetching } =
    useGetAllProjectssTimeTakenPaginationQuery({
      projectId: id,
      dateFilter: {
        startDate: dateFilter?.startDate || "",
        endDate: dateFilter?.endDate || "",
      },
    });
  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data?.[0]?.timelogs));
      dispatch(setTotalPayment(data));
      const ctcPayment = data?.data?.[0]?.timelogs?.reduce(
        (acc: any, ele: any) => {
          return  ele?.totalSalaryMinWise + acc;
        },
        0
      );

      const gctcPayemnt = data?.data?.[0]?.timelogs?.reduce(
        (acc: any, ele: any) => {
          return  ele?.totalGrowthSalaryMinWise + acc;
        },
        0
      );
      dispatch(setTaskCTC(ctcPayment || 0));
      dispatch(setTaskGCTC(gctcPayemnt || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, data]);
  return (
    <>
      {(isLoading || isFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <TaskTimeLogAnalytics />
    </>
  );
};

export default TaskTimeLogAnalyticsWrapper;
