import apiSlice from "./ApiSlice";

export const userAccessApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //***** LIST BY USER ROLE *****/
    getAllUserRole: builder.query({
      providesTags: ["userAccess"],
      query: (body: any) => ({
        url: "user-access",
        method: "POST",
        body,
      }),
    }),

    //***** DELETE OF USER ROLE *****/
    deleteUserRole: builder.mutation({
      invalidatesTags: ["userAccess"],
      query: (id: string) => ({
        url: `user-access/${id}`,
        method: "DELETE",
      }),
    }),

    //***** ADD *****/
    addUserAccess: builder.mutation({
      invalidatesTags: ["userAccess"],
      query: (body: any) => ({
        url: "/user-access/add",
        method: "POST",
        body,
      }),
    }),

    //***** get *****/
    getUserAccess: builder.query({
      providesTags: ['userAccess'],
      query: ({
        userId,
        positionId,
      }: {
        userId: string | null
        positionId: string
      }) => {
        let params: any = { positionId: positionId }

        if (userId !== null) {
          params.userId = userId
        }

        return {
          url: `/user-access/`,
          params,
          method: 'GET',
        }
      },
    }),

    //***** Update *****/
    updateUserAccess: builder.mutation({
      invalidatesTags: ["userAccess"],
      query: ({ body, userRole }: { body: any; userRole: string }) => ({
        url: `user-access/user-role/${userRole}`,
        method: "PUT",
        body,
      }),
    }),

    //***** Update by userId *****/
    updateUserAccessByUserId: builder.mutation({
      invalidatesTags: ["userAccess"],
      query: ({ id, body }) => ({
        url: `user-access/${id}`,
        method: "PUT",
        body: body,
      }),
    }),

    //***** user access already added *****/
    isUserAccessExists: builder.query({
      providesTags: ["userAccess"],
      query: (id) => ({
        url: `position/${id}/user-access`,
        method: "GET",
      }),
    }),

    // // **** Delete
    // deleteCartonBoxBarcode: builder.mutation({
    //     invalidatesTags: ['CartonBoxBarcode', 'attributeGroup'],
    //     query: (id) => ({
    //         url: `/cartonbox-barcode/${id}`,
    //         method: 'DELETE',
    //     }),
    // }),
  }),
});
export const {
  useGetAllUserRoleQuery,
  useDeleteUserRoleMutation,
  useAddUserAccessMutation,
  useGetUserAccessQuery,
  useUpdateUserAccessMutation,
  useIsUserAccessExistsQuery,
  useUpdateUserAccessByUserIdMutation,
} = userAccessApi;
