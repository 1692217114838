import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type FinanceDashboardSliceStateType = {

  dateFilter: {
    startDate: string | null;
    endDate: string | null;
  };
  dateFilterQuarterly: {
    startDate: string | null;
    endDate: string | null;
  };
};

const initialState: FinanceDashboardSliceStateType = {

  dateFilter: {
    startDate: '',
    endDate: '',
  },
  dateFilterQuarterly: {
    startDate: '',
    endDate: '',
  },
};

const FinanceDashboardSlice: Slice<FinanceDashboardSliceStateType> = createSlice({
  name: "financeDashboard",
  initialState,
  reducers: {
    setDateFilter: (state, action: PayloadAction<{ startDate: string; endDate: string, dateFilterKey: string }>) => {
      state.dateFilter = action.payload;
    },
    setDateFilterQuarterly: (state, action: PayloadAction<{ startDate: string; endDate: string, dateFilterKey: string }>) => {
      state.dateFilterQuarterly = action.payload;
    },
  },
});

export const {
  setDateFilterQuarterly,
  setDateFilter,
} = FinanceDashboardSlice.actions;
export default FinanceDashboardSlice.reducer;