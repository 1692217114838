// |-- External Dependencies --|
import { createSlice, Slice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
// import {
//     UserModuleActionTypes,
//     UserModuleNameTypes,
// } from 'src/models/userAccess/UserAccess.model'

// |-- Internal Dependencies --|

export interface fieldTypes {
    fieldId: string
    fieldName: string
    fieldValue: string
}
export interface moduleActionTypes {
    actionId: string
    actionUrl: string
    actionName: string
    parentGroup: string[]
    fields: fieldTypes[]
}

export interface ModulesTypes {
    moduleId: string
    moduleName: string
    parentGroup:string[]
    moduleAction: moduleActionTypes[]
}

export interface userAccesTypes {
    userId: string | null
    userRole: string
    userName: string
    modules: ModulesTypes[]
}
export type InitialStateType = {
    userAccessItems: userAccesTypes
    checkUserAccess: userAccesTypes
}

const initialState: InitialStateType = {
    checkUserAccess: {
        userId: null,
        userRole: '',
        userName: '',
        modules: [],
    },
    userAccessItems: {
        userId: null,
        userRole: '',
        userName: '',
        modules: [],
    },
}

const userAccesSlice: Slice<InitialStateType> = createSlice({
    name: 'userAcces',
    initialState,
    reducers: {
        setUserModule: (state, action: PayloadAction<ModulesTypes[] | []>) => {
            state.userAccessItems = {
                ...state.userAccessItems,
                modules: [...action.payload],
            }
        },
        setUserAccess: (state, action: PayloadAction<ModulesTypes[]>) => {
            state.userAccessItems.modules = action.payload
        },

        setCheckUserAccess: (state, action: PayloadAction<ModulesTypes[]>) => {
            state.checkUserAccess.modules = action.payload
        },
    },
})

export const { setUserModule, setUserAccess, setCheckUserAccess } =
    userAccesSlice.actions

export default userAccesSlice.reducer
