import { useEffect, useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/LeadsFollowUpListingSlice";
import { RootState } from "src/redux/store";
import { useGetAllLeadsFollowupQuery } from "src/services/LeadsService";
import { convertDateInDateMonNameFormat } from "src/utils/common/dateFormat";
import AddLeadsFollowUpFormWrapper from "../Add/AddLeadsFollowUpFormWrapper";
import EditLeadsFollowUpForm from "../Edit/EditLeadsFollowUpForm";
import LeadsFollowUpListing from "./LeadsFollowUpListing";
import moment from "moment";
import { Tooltip } from "@mui/material";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const LeadsFollowUpListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const { leadsId } = useParams();

  const [isOpenAddFrom, setIsOpenAddForm] = useState(false);
  const [isOpenEditFrom, setIsOpenEditForm] = useState(false);
  const [selectedFollowupId, setSelectedFollowupId] = useState("");
  const { totalItems, page, rowsPerPage, searchValue, isTableLoading, items } =
    useSelector((state: RootState) => state.leadsFollowUp);
  const { data, isLoading, isFetching } = useGetAllLeadsFollowupQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "leadId",
      "summaryNote",
      "nextFollowupDate",
      "nextFollowupTime",
      "leadLabelName",
      "createdById",
      "createdByName",
    ],
    page: page,
    filterBy: [
      {
        fieldName: "leadId",
        value: leadsId || "",
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    } else {
      dispatch(setIsTableLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const getActionOptions = (row: any) => {
    return [
      {
        label: (
          <div className="flex items-center gap-2 text-secondary-main">
            <RxPencil1 className="text-lg" /> Edit
          </div>
        ),
        onClick: () => {
          setIsOpenEditForm(true);
          setSelectedFollowupId(row?._id);
        },
        acitonName: UserModuleNameTypes.ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP_EDIT,
      },
    ];
  };

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: "flex-[1_1_0%]",
      renderCell: (row: any) => {
        return (
          <div className="py-1">
            <div className="text-[15px] text-slate-700 font-medium">
              {moment(row?.createdAt).format("DD MMM YYYY")}
            </div>
            <div className="text-[15px] text-slate-500  text-xs font-medium">
              {moment(row?.createdAt).format("hh:mm A")}
            </div>
          </div>
        );
      },
    },
    { field: "createdByName", headerName: "Created By", flex: "flex-[1_1_0%]" },
    { field: "leadLabelName", headerName: "Lead Label", flex: "flex-[1_1_0%]" },
    {
      field: "nextFollowupDate",
      headerName: "Next Followup",
      flex: "flex-[1_1_0%]",
      renderCell: (row: any) => {
        return (
          <div className="flex flex-col gap-1">
            {row?.nextFollowupDate
              ? convertDateInDateMonNameFormat(row?.nextFollowupDate)
              : "-"}

            {row?.nextFollowupTime ? (
              <div className="text-xs text-slate-500">
                {row?.nextFollowupTime}
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "summaryNote",
      headerName: "Summary",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[300px]",
      renderCell: (row: any) => {
        return (
          <Tooltip title={row?.summaryNote}>
            <div className="w-full overflow-hidden font-medium truncate cursor-pointer">
              {row?.summaryNote}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "quotation",
      headerName: "Quotation",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[300px]",
      renderCell: (row: any) => {
        return (
          <Tooltip title={row?.quotation}>
            <div className="w-full overflow-hidden font-medium truncate cursor-pointer">
              {row?.quotation}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => {
        const options = getActionOptions(row);
        return <ATMActionMenu options={options} orientation="vertical" />;
      },
    },
  ];

  return (
    <div>
      <LeadsFollowUpListing
        columns={columns}
        rows={items}
        onAddNew={() => setIsOpenAddForm(true)}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenAddFrom ? (
        <AddLeadsFollowUpFormWrapper
          onClose={() => setIsOpenAddForm(false)}
          leadId={leadsId || ""}
        />
      ) : null}
      {isOpenEditFrom ? (
        <EditLeadsFollowUpForm
          onClose={() => setIsOpenEditForm(false)}
          leadId={leadsId || ""}
          followupId={selectedFollowupId}
        />
      ) : null}
    </div>
  );
};

export default LeadsFollowUpListingWrapper;
