import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import TicketDetails from "./TicketDetails";

type Props = {};

const TicketChatWrapper = (props: Props) => {
  const { singleItem } = useSelector((state: RootState) => state.ticket);
  return (
    <div className="h-full ">
      <TicketDetails ticketDetails={singleItem[0]} />
    </div>
  );
};

export default TicketChatWrapper;
