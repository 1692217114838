import React, {
  useEffect,
  useState,
  // useState
} from "react";
import moment from "moment";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { TicketListResponse } from "src/models/Ticket.model";
import MOLPriorityChip from "src/components/UI/molecules/MOLPriorityChip/MOLPriorityChip";
import MOLTicketTypeChip from "src/components/UI/molecules/MOLTicketTypeChip/MOLTicketTypeChip";
import MOLTicketStatusChip from "src/components/UI/molecules/MOLTicketStatusChip/MOLTicketStatusChip";
import TicketDetailsView from "./TicketDetailsView";
// import { useGetAllTicketQuery } from "src/services/TicketServices";
import { useGetAllTicketWithClosedStatusQuery } from "src/services/ChangeRequestServices";
import { useParams } from "react-router-dom";
import { setItems, setIsTableLoading } from "src/redux/slices/TicketSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import MOLClientSideTicketStatusChip from "src/components/UI/molecules/MOLClientSideTicketStatusChip/MOLClientSideTicketStatusChip";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
// import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";

const TicketDetailsViewWrapper = () => {
  const { id: CHANGE_REQUEST_ID } = useParams();

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const dispatch = useDispatch();

  const { items, searchValue, isTableLoading, dateFilter } = useSelector(
    (state: RootState) => state.ticket
  );

  const { isLoading, isFetching, data } = useGetAllTicketWithClosedStatusQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["title", "ticketNumber"],
    page: page,
    filterBy: [
      {
        fieldName: "changeRequestId",
        value: CHANGE_REQUEST_ID,
      },
    ],
    dateFilter: {
      startDate: dateFilter?.start_date || "",
      endDate: dateFilter?.end_date || "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      setTotalItems(data?.totalItem);
    }
  }, [isLoading, isFetching, data, dispatch]);

  enum TicketPriorityEnum {
    low = "LOW",
    medium = "MEDIUM",
    high = "HIGH",
    urgent = "URGENT",
  }

  const columns: columnTypes[] = [
    {
      field: "ticketNumber",
      headerName: "Ticket Id",
      flex: "flex-[2_2_0%]",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_ID,
      renderCell: (row: TicketListResponse) => (
        <div className="text-blue-500"> {row?.ticketNumber} </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date-Time",
      extraClasses: "min-w-[120px] max-w-[120px]",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_DATE_TIME,
      renderCell: (row: TicketListResponse) => (
        <div className="py-1">
          <div className="text-[15px] text-slate-500 font-medium">
            {moment(row?.createdAt).format("hh:mm A")}
          </div>
          <div className="text-[15px] text-slate-700 font-medium">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Ticket Title",
      flex: "flex-[2_2_0%]",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_TITLE,
      extraClasses: "min-w-[200px]",
      renderCell: (row: TicketListResponse) => (
        <div className="w-full overflow-hidden truncate">{row?.title}</div>
      ),
    },
    {
      field: "projectLabel",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_PROJECT,
      extraClasses: "min-w-[100px]",
      renderCell: (row: TicketListResponse) => (
        <div className="capitalize">{row?.projectLabel} </div>
      ),
    },
    {
      field: "priority",
      headerName: "Priority",
      extraClasses: "min-w-[100px] max-w-[110px]",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_PRIORITY,
      renderCell: (row: TicketListResponse) => (
        <div>
          <MOLPriorityChip priority={row?.priority as TicketPriorityEnum} />
        </div>
      ),
    },
    {
      field: "createdByLabel",
      headerName: "Created By",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_CREATED_BY,
      extraClasses: "min-w-[100px]",
      align: "center",
      renderCell: (row: TicketListResponse) => (
        <div className="capitalize"> {row?.createdByLabel} </div>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      extraClasses: "min-w-[180px] max-w-[180px]",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_TYPE,
      renderCell: (row: TicketListResponse) => (
        <div className="flex justify-center">
          <MOLTicketTypeChip ticketType={row?.type as any} />
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Stage",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_STAGE,
      extraClasses: "min-w-[180px] max-w-[180px]",
      renderCell: (row: TicketListResponse) => (
        <div>
          <MOLTicketStatusChip status={row?.status as any} />
        </div>
      ),
    },
    {
      field: "clientSideTicketStatus",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_STATUS,
      extraClasses: "min-w-[180px] max-w-[180px]",
      renderCell: (row: TicketListResponse) => (
        <MOLClientSideTicketStatusChip
          status={row?.clientSideTicketStatus as any}
        />
      ),
    },
    {
      field: "unReadMessages",
      headerName: "Unseen Messages",
      extraClasses: "min-w-[180px] max-w-[180px]",
      align: "center",
      name: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_UNSEEN_MESSAGE,
      renderCell: (row: TicketListResponse) =>
        row?.unReadMessages ? (
          <div className="bg-[#041618] rounded-full font-bold h-[23px] w-[23px] text-[10px] flex justify-center items-center -right-[6px] -top-[1px] text-white">
            {row?.unReadMessages}
          </div>
        ) : (
          "-"
        ),
    },
  ];
  return (
    <TicketDetailsView
      items={items}
      columns={columns}
      isLoading={isTableLoading}
      totalItems={totalItems}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={(e: number) => setPage(e)}
      setRowsPerPage={(e) => setRowsPerPage(e)}
    />
  );
};

export default TicketDetailsViewWrapper;
