import React, { useState } from "react";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import AllTaskFilterFormCardDialogWrapper from "./AllTaskFilterFormCard/AllTaskFilterFormCardDialogWrapper";
// import useMoveTaskToArchive from "src/hooks/useMoveTaskToArchive";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
// |-- Icons --|
import { BiSearch } from "react-icons/bi";
import { IoFilter } from "react-icons/io5";
import { TbGrid3X3, TbList } from "react-icons/tb";
import AllTaskBoardLayout from "./Components/TaskBoardlayout";
import AllTaskListLayout from "./Components/TaskListLayout";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import { Divider, Skeleton } from "@mui/material";

type Props = {
  rows: any[];
  labels?: any;
  onAddNew?: any;
  currentPath?: string;
  ProjectId?: string;
  open: any;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const AllTaskListing = ({
  rows,
  labels,
  onAddNew,
  currentPath,
  open,
  ProjectId,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenTaskAllFilterDialog, setIsOpenTaskAllFilterDialog] =
    useState<boolean>(false);
  //toggle between board and list view
  const [isGridSelected, setIsGridSelected] = useState(false);

  const handleGridClick = () => {
    setIsGridSelected(true);
  };

  const handleListClick = () => {
    setIsGridSelected(false);
  };

  // short cut key for open filter form
  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if the pressed keys match the specified shortcut (Ctrl + Shift + F)
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "f") {
        // Open the filter dialog when the shortcut is detected
        setIsOpenTaskAllFilterDialog(true);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div
          className={`flex gap-2 px-2 md:p-4 py-2 border-b shadow-sm flex-col`}
        >
          <div className="flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center w-full gap-2">
                <ATMPageHeader pageTitle="Task" hideAddButton hideSearchBox />
                <div className="flex items-center w-full gap-4 ">
                  <div
                    onClick={handleListClick}
                    className={`p-2 rounded-md hover:bg-cyan-200 hover:text-white	 border cursor-pointer ${
                      !isGridSelected
                        ? "bg-cyan-400 text-white font-semibold"
                        : ""
                    }`}
                  >
                    <TbList />
                  </div>
                  <div
                    onClick={handleGridClick}
                    className={`p-2 rounded-md hover:bg-cyan-200 hover:text-white	 border cursor-pointer ${
                      isGridSelected
                        ? "bg-cyan-400	 text-white font-semibold"
                        : ""
                    }`}
                  >
                    <TbGrid3X3 />
                  </div>
                </div>
              </div>
              {isAuthorized(UserModuleNameTypes.ACTION_TASK_ADD) && (
                <p
                  className="px-2 text-xs text-teal-600 underline md:hidden"
                  onClick={() => {
                    onAddNew();
                  }}
                >
                  Add New Task
                </p>
              )}
            </div>
            <div className="flex items-center w-full gap-4 md:justify-end">
              {/* Search Box */}
              <div className="xs:w-full md:max-w-[300px]">
                <ATMInputAdormant
                  name=""
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  adormant={<BiSearch />}
                  adormantProps={{
                    position: "start",
                    extraClasses: "bg-white border-0",
                  }}
                  inputProps={{ className: "bg-white" }}
                  placeholder="Search..."
                />
              </div>
              {/* Filter Button */}
              {currentPath === `/project/${ProjectId}/details` ? null : (
                <>
                  <ATMLoadingButton
                    variant="tonal"
                    onClick={() => setIsOpenTaskAllFilterDialog(true)}
                  >
                    <div className="flex items-center gap-2">
                      <IoFilter />
                      Filters
                    </div>
                  </ATMLoadingButton>
                </>
              )}

              {/* Add Button */}
              {isAuthorized(UserModuleNameTypes.ACTION_TASK_ADD) && (
                <div className="hidden md:block">
                  <ATMLoadingButton
                    onClick={() => {
                      onAddNew();
                    }}
                  >
                    Add New Task
                  </ATMLoadingButton>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* View */}
        {isTableLoading ? (
          Array(10)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className={`rounded-md bg-gray-50 border border-slate-200  max-w-[100vw] transition-all duration-500 hover:shadow-md m-2`}
              >
                <div className="flex py-1 md:px-2">
                  <div className="flex items-center gap-2 px-2">
                    <Skeleton variant="circular" width={24} height={24} />
                    <p className="items-center hidden md:flex">
                      <span className="flex flex-wrap items-center gap-1 text-xs font-medium">
                        <Skeleton variant="text" width={30} height={10} />
                        <Skeleton variant="text" width={30} height={10} />
                        <Skeleton variant="text" width={30} height={10} />
                        <Skeleton variant="text" width={30} height={10} />
                      </span>
                    </p>
                  </div>
                </div>

                <Divider />

                <div className="flex flex-col justify-between w-full gap-4 p-2 md:p-4 md:flex-row">
                  <div className="flex flex-col gap-1">
                    <p className="flex items-center text-neutral md:hidden">
                      <span className="flex flex-wrap items-center gap-1 text-xs font-medium">
                        <Skeleton variant="text" width={200} height={10} />

                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </span>
                    </p>
                    <div className="flex flex-col gap-1">
                      {" "}
                      <Skeleton animation="wave" />
                      <Skeleton variant="text" width={180} height={15} />
                    </div>
                  </div>
                  <div className="flex items-end justify-end gap-2 md:gap-4 md:py-0">
                    <Skeleton variant="rounded" width={130} height={40} />
                    <Skeleton variant="rounded" width={130} height={40} />
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div className="flex items-center flex-1 w-full gap-4 overflow-scroll">
            {isGridSelected ? (
              <AllTaskBoardLayout rows={rows} labels={labels} />
            ) : (
              <AllTaskListLayout rows={rows} isTableLoading={isTableLoading} />
            )}
          </div>
        )}

        <div className="p-4 py-2 border-t">
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>

      {/* Filter  Dialog */}
      {isOpenTaskAllFilterDialog && (
        <AllTaskFilterFormCardDialogWrapper
          open={isOpenTaskAllFilterDialog}
          onClose={() => setIsOpenTaskAllFilterDialog(false)}
        />
      )}
    </>
  );
};

export default AllTaskListing;
