import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";
import { MdEdit, MdPerson, MdLabel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import { setTaskSearchValue } from "src/redux/slices/TaskSlice";
import { RootState } from "src/redux/store";
import AssignToDialogWrapper from "../AssignToDialog/AssignToDialogWrapper";
import StatusChangeDialogWrapper from "../StatusChangeDialog/StatusChangeDialogWrapper";
import EditTaskFormWrapper from "../../Edit/EditTaskFormWrapper";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { Divider } from "@mui/material";
// import { useDeleteTaskByIdMutation } from "src/services/TaskService";
// import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
// import { showToast } from "src/utils/showToaster";

type Props = {
  setTaskCollapse: any;
  items: any;
  allTaskData: any;
};

const TimelineTask = ({
  setTaskCollapse,
  items: itemssss,
  allTaskData,
}: Props) => {
  const { items, tasksearchValue } = useSelector(
    (state: RootState) => state.task
  );
  const [assigneeId, setAssigneeId] = useState<any>({});
  const [isOpenAssignTo, setIsOpenAssignTo] = useState(false);
  const [taskId, setTaskId] = useState<string>("");

  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (index: any) => {
    // Update the selected row when a row is clicked
    setSelectedRow(items[index]);
  };

  const labelHistory = [
    {
      user: "Ruchika Purohit",
      action: "added",
      label: "TODO",
      timestamp: "1 day ago",
    },
    {
      user: "Ruchika Purohit",
      action: "assigned to",
      assignee: "ahirwar.isha",
      timestamp: "1 day ago",
    },
    // Add more label history entries as needed
  ];
  // const [deleteTask] = useDeleteTaskByIdMutation();

  const [isCopied, setIsCopied] = useState(false);
  const [isOpenStatusChange, setIsOpenStatusChange] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [labelId, setLabelId] = useState<any>({});

  // const handleDeleteTask = (row: string) => {
  //   showConfirmationDialog({
  //     title: "Hands Up",
  //     text: "Are you sure want to delete this Task?",
  //     icon: "question",
  //     showCancelButton: true,
  //     next: (result) => {
  //       if (result?.isConfirmed) {
  //         deleteTask(row).then((res: any) => {
  //           if (res.error) {
  //             showToast("error", res?.error?.data?.message);
  //           } else {
  //             showToast("success", res?.data?.message);
  //           }
  //         });
  //       }
  //     },
  //   });
  // };

  const handleCopyClick = (branchName: any) => {
    navigator.clipboard
      .writeText(branchName)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Unable to copy text: ", err);
      });
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="h-[100vh] overflow-y-scroll overflow-x-hidden xs:px-3 md:px-0">
          <>
            <div className="flex w-full h-full">
              <div className="w-[15%] p-2 border-r">
                {/* mini side nav */}
                <div className="flex justify-between">
                  <span>
                    <ATMInputAdormant
                      name=""
                      value={tasksearchValue}
                      onChange={(e) =>
                        dispatch(setTaskSearchValue?.(e.target.value))
                      }
                      adormant={<BiSearch />}
                      adormantProps={{
                        position: "start",
                        extraClasses: "bg-white border-0",
                      }}
                      inputProps={{ className: "bg-white" }}
                      placeholder="Search..."
                    />
                  </span>
                </div>

                {items?.map((task: any, index: any) => {
                  return (
                    <div
                      className={`bg-white shadow-md p-2 my-2 border border-slate-300 rounded-md overflow-auto ${selectedRow === task ? "bg-gray-200" : ""
                        }`}
                      onClick={() => handleRowClick(index)}
                      key={index}
                      style={{ maxHeight: "[20px]" }}
                    >
                      <h3 className="text-sm line-clamp-2 font-semibold">
                        {task?.title}
                      </h3>
                      <div className="flex justify-between mt-2">
                        <p className="text-gray-600"> {index + 1}</p>
                        <p className={`text-sm font-bold text-red-400`}>
                          {task?.label[0]?.labelName}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* description div */}
              <div className="flex-1 gap-2 bg-white rounded-md p-2 px-4 relative">
                {/* status, branch name and options */}
                <div className="flex justify-between border-b pb-1">
                  <div className="flex items-center gap-2">
                    <p
                      className="text-end flex items-center"
                      onClick={() => setTaskCollapse(false)}
                    >
                      <FaArrowLeft />
                    </p>
                    <span
                      className="text-xs bg-green-500 h-fit px-2 py-1 text-white font-medium rounded-md"
                      onClick={() => {
                        if (itemssss?.assignedTo !== null) {
                          setIsOpenStatusChange(true);
                          setTaskId(itemssss?._id);
                          setProjectId(itemssss?.projectId);
                          setAssigneeId([
                            itemssss?.assignedTo,
                            itemssss?.assigneeName,
                          ]);
                          setLabelId(itemssss?.label);
                        }
                      }}
                    >
                      {itemssss?.label[0]?.labelName || "-"}
                    </span>
                    <span className="text-xs text-gray-600">
                      Created 2 days ago by Ruchika Purohit (
                      {itemssss?.taskNumber})
                    </span>
                  </div>

                  <div className="flex items-center gap-4">
                    <span
                      onClick={() => handleCopyClick(itemssss?.branchName)}
                      className="flex items-center bg-gray-100 h-fit hover:bg-gray-300 text-sm px-2 py-1 gap-2 rounded-xl hover:rounded-full transition-all ease-linear cursor-pointer border border-white hover:border"
                    >
                      {itemssss?.branchName || "-"}
                      <button disabled={isCopied}>
                        {isCopied ? <LuCopyCheck /> : <LuCopy />}
                      </button>
                    </span>
                    {/* <span className="p-2 border border-slate-400 rounded-md m-1" onClick={() => { }}>
                      {" "}
                      Close task
                    </span> */}
                    <div className="items-end text-sm">
                      <ATMActionMenu
                        options={[
                          {
                            label: (
                              <div className="flex gap-x-2 items-center">
                                <MdEdit color="#000000" /> Edit
                              </div>
                            ),
                            onClick: () => {
                              setIsOpenEditFormDialog(true);
                              setTaskId(itemssss?._id);
                              setProjectId(itemssss?.projectId);
                            },
                          },
                          // {
                          //   label: (
                          //     <div className="flex gap-x-2 items-center">
                          //       <MdDelete color="#000000" /> Delete
                          //     </div>
                          //   ),
                          //   onClick: () => {
                          //     handleDeleteTask(itemssss?._id)
                          //   }
                          // },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 py-2">
                  {/* title and assigne */}
                  <div className="flex items-center justify-between font-medium text-xl">
                    <h1>{itemssss?.title}</h1>
                    <div
                      className="font-medium text-primary text-sm flex items-center gap-4 bg-gray-100 px-4 py-2 rounded-lg capitalize cursor-pointer h-fit"
                      onClick={() => {
                        setIsOpenAssignTo(true);
                        setTaskId(itemssss?._id);
                        setAssigneeId([
                          itemssss?.assignedTo,
                          itemssss?.assigneeName,
                        ]);
                      }}
                    >
                      {itemssss?.assignedTo === null
                        ? "Unassigned"
                        : itemssss?.assigneeName}
                      <p>
                        <MdEdit />
                      </p>
                    </div>
                  </div>
                  {/* description */}
                  <p className="text-sm pb-2">
                    <div
                      className="leading-relaxed"
                      dangerouslySetInnerHTML={{
                        __html: itemssss?.description,
                      }}
                    />
                  </p>

                  <Divider />

                  {/* timeline */}
                  <div className="text-sm flex justify-between mx-auto relative pt-2">
                    {/* Label History */}
                    <ul>
                      {labelHistory?.map((change, index) => (
                        <li key={index} className="flex items-center relative">
                          <div className="flex items-center">
                            {change.label && (
                              <>
                                <div className="flex gap-3 pb-6">
                                  <div className="p-2 h-fit aspect-square border rounded-full">
                                    <MdLabel className="text-sm text-gray-600" />
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <div className="flex flex-col gap-1">
                                      <p className="text-xs">
                                        12:30 PM, 12-12-2023
                                      </p>
                                      <span className="flex items-center gap-2">
                                        <p className="text-sm text-teal-600 font-medium">
                                          {/* {change.user}  */}@
                                          {change.user
                                            .toLowerCase()
                                            .replace(/\s+/g, "")}
                                        </p>
                                        <p className="underline">
                                          {change.action}
                                        </p>
                                        <span
                                          className={`px-2 bg-${change.label.toLowerCase()} bg-sky-300 h-fit text-white font-medium rounded-md`}
                                        >
                                          {change.label}
                                        </span>
                                      </span>
                                    </div>
                                    <span className="bg-gray-50 p-2 rounded-md">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Quasi deserunt natus
                                      autem soluta. Fugit, pariatur consequatur
                                      quis minus magnam reiciendis ullam saepe,
                                      qui rem vel laboriosam inventore
                                      necessitatibus non distinctio esse
                                      eligendi doloremque autem.
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                            {change.assignee && (
                              <div className="flex gap-3 pb-6">
                                <div className="p-2 h-fit aspect-square border rounded-full">
                                  <MdPerson className="text-sm text-gray-600" />
                                </div>
                                <div className="flex flex-col gap-3">
                                  <div className="flex flex-col gap-1">
                                    <p className="text-xs">
                                      12:30 PM, 12-12-2023
                                    </p>
                                    <div className="flex gap-2 items-center">
                                      <p className="text-sm text-teal-600 font-medium">
                                        @
                                        {change.user
                                          .toLowerCase()
                                          .replace(/\s+/g, "")}
                                      </p>
                                      <p className="underline">assigned to</p>
                                      <p className="text-sm text-pink-500 font-medium">
                                        @
                                        {change.assignee
                                          .toLowerCase()
                                          .replace(/\s+/g, "")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      {isOpenAssignTo ? (
        <AssignToDialogWrapper
          onClose={() => setIsOpenAssignTo(false)}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}

      {isOpenStatusChange ? (
        <StatusChangeDialogWrapper
          onClose={() => setIsOpenStatusChange(false)}
          projectId={projectId}
          labelId={labelId}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}
      {isOpenEditFormDialog ? (
        <EditTaskFormWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedTask={taskId}
        />
      ) : null}
    </>
  );
};

export default TimelineTask;
