import { FormikProps } from "formik";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { useGetAllIncidentCategoryQuery } from "src/services/IncidentCategoryService";
import { useGetPointerAssignUserQuery } from "src/services/UserService";

type Props = {
  formikProps: FormikProps<any>;
  open:boolean ;
  onReset: () => void;
  onClose: () => void;
};

const IncidentDateFilterDialog = ({ formikProps, onReset, onClose , open }: Props) => {
  const { userData } = useGetLocalStorage();

  const { data, isLoading, isFetching } = useGetAllIncidentCategoryQuery("");

  const categoryOptions = data?.data?.map((el: any) => {
    return {
      label: el?.categoryName,
      value: el?._id,
    };
  });

  const {
    data: assignUserData,
    isLoading: assignUserDataIsLoading,
    isFetching: assignUserDataIsFetching,
  } = useGetPointerAssignUserQuery(userData?.userId, {
    skip: !userData?.userId,
  });

  const assignUserOption = assignUserData?.data?.assigned_users?.map(
    (ele: any) => {
      return {
        label: ele?.name,
        value: ele?._id,
      };
    }
  );

  const { values, setFieldValue, isSubmitting, handleSubmit, handleBlur } =
    formikProps;

  return (
    <MOLFilterDialog
      title={"Filters"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      clearFilter={onReset}
      handleSubmit={handleSubmit}
      open={open}
    >
      <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">

        {/* Filter Form Field */}

        {/* Created At */}
        <div className="mb-2 flex flex-col gap-4">
          {/* Category */}
          <div>
            <ATMSelect
              name="category"
              required
              label="Category"
              value={values?.category}
              onBlur={handleBlur}
              placeholder="Select Category"
              isLoading={isFetching || isLoading}
              options={categoryOptions}
              onChange={(newValue) => {
                setFieldValue("category", newValue);
                setFieldValue("eventType", "");
              }}
            />
          </div>
          {/* Member */}
          <div>
            <ATMSelect
              name="memberId"
              required
              label="Member"
              value={values?.memberId}
              onBlur={handleBlur}
              placeholder="Select Member"
              isLoading={assignUserDataIsLoading || assignUserDataIsFetching}
              options={assignUserOption}
              onChange={(newValue) => {
                setFieldValue("memberId", newValue);
              }}
            />
          </div>
          <div className="flex gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                value={values?.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values?.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>
      </div>
    </MOLFilterDialog>
  );
};

export default IncidentDateFilterDialog;
