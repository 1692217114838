import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type TaskSliceStateType = {
  items: any[] | [];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  tasksearchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  userFilterValue: any;
  taskStatus: any;
  dueDateFilterValue: any;
  projectFilterValue: any;
  moduleFilterValue: any;
  featureFilterValue: any;
  labelFilterValue: any;
  layerFilterValue: any;
  isTaskArchived:boolean,
  filterBy: {
    fieldName: string;
    value: string;
  }[];
  dateFilter: {
    start_date: string | null;
    end_date: string | null;
  };
};

const initialState: TaskSliceStateType = {
  items: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  rowsPerPage: 20,
  searchValue: "",
  tasksearchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  userFilterValue: "",
  taskStatus: "",
  dueDateFilterValue: "",
  projectFilterValue: "",
  moduleFilterValue: "",
  featureFilterValue: "",
  labelFilterValue: "",
  layerFilterValue: "",
  isTaskArchived:false,
  filterBy: [
    {
      fieldName: "companyName",
      value: "",
    },
    {
      fieldName: "ticketId",
      value: "",
    },
  ],
  dateFilter: {
    start_date: null,
    end_date: null,
  },
};

const taskSlice: Slice<TaskSliceStateType> = createSlice({
  name: "taskSlice",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setTaskSearchValue: (state, action: PayloadAction<string>) => {
      state.tasksearchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string }[]>
    ) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setDateFilter: (
      state,
      action: PayloadAction<{ start_date: string; end_date: string }>
    ) => {
      state.dateFilter = action.payload;
    },
    setUserValueFilter: (state, action: PayloadAction<string[]>) => {
      state.userFilterValue = action.payload;
    },

    setTaskStatusValueFilter: (state, action: PayloadAction<string[]>) => {
      state.taskStatus = action.payload;
    },

    setProjectValueFilter: (state, action: PayloadAction<string[]>) => {
      state.projectFilterValue = action.payload;
    },

    setModuleValueFilter: (state, action: PayloadAction<string[]>) => {
      state.moduleFilterValue = action.payload;
    },

    setFeatureValueFilter: (state, action: PayloadAction<string[]>) => {
      state.featureFilterValue = action.payload;
    },
    setLabelValueFilter: (state, action: PayloadAction<[{}]>) => {
      state.labelFilterValue = action.payload;
    },
    setLayerValueFilter: (state, action: PayloadAction<[{}]>) => {
      state.labelFilterValue = action.payload;
    },
    setDueDateFilter: (state, action: PayloadAction<string | null>) => {
      state.dueDateFilterValue = action.payload;
    },
    setIsTaskArchivedFilter: (state, action: PayloadAction<boolean>) => {
      state.isTaskArchived = action.payload;
    },
  },
});

export const {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTaskSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setFilterBy,
  setUserValueFilter,
  setTaskStatusValueFilter,
  setDueDateFilter,
  setProjectValueFilter,
  setModuleValueFilter,
  setFeatureValueFilter,
  setLabelValueFilter,
  setLayerValueFilter,
  setIsTaskArchivedFilter
} = taskSlice.actions;
export default taskSlice.reducer;
