import { Form, Formik, FormikHelpers } from "formik";
import { BusinessFormValues } from "src/models/Business.model";
import { useAddBusinessModelMutation } from "src/services/BusinessService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import BusinessForm from "../Layouts/BusinessForm";

type Props = {
  onClose: () => void;
};

const AddBusinessFormWrapper = ({ onClose }: Props) => {
  const [addBusinessModel] = useAddBusinessModelMutation();

  // Form Initial Values
  const initialValues: BusinessFormValues = {
    modelName: "",
    industryName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    modelName: string().required("Please enter model name"),
    industryName: array().required("Please Select Industry"),
  });

  // Handle Submit
  const handleSubmit = (
    values: BusinessFormValues,
    { setSubmitting, resetForm }: FormikHelpers<BusinessFormValues>
  ) => {
    const formattedValues = {
      industries: values?.industryName?.map((el: any) => el?.value),
      modelName: values?.modelName,
    };
    addBusinessModel(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);

          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <BusinessForm
            formType="ADD"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddBusinessFormWrapper;
