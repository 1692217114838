import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useAddAchivementMutation } from "src/services/GoalsService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import GoalCategoryForm from "../Layout/AddAchievementForm";

type Props = {
  onClose: () => void;
  selectedGoals: any;
};

export type AchievementFormValues = {
  achievedById: any;
  achieved: any;
  achieveDate: any;
  remark: any;
};

const AddAchievementWrapper = ({ onClose, selectedGoals }: Props) => {
  const [addExpenseCategory] = useAddAchivementMutation();

  // Form Initial Values
  const initialValues: AchievementFormValues = {
    achievedById: [],
    achieved: "",
    achieveDate: moment(),
    remark: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    achieved: string().required("Please enter achieved goal"),
    achieveDate: string().required("Please enter achieved goal"),
    achievedById: array()
      .min(1, "Please select member")
      .required("Please select member"),
  });

  // Handle Submit
  const handleSubmit = (
    values: AchievementFormValues,
    { setSubmitting }: FormikHelpers<AchievementFormValues>
  ) => {
    const formattedValues = {
      goalId: selectedGoals?._id,
      achieved: values?.achieved,
      achievedBy: values?.achievedById?.map((ele: any) => ele?.value),
      achieveDate: values?.achieveDate
        ? moment(values?.achieveDate).format("YYYY-MM-DD")
        : "",
      remark: values?.remark,
    };

    addExpenseCategory(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <GoalCategoryForm
            selectedGoals={selectedGoals}
            formType="ADD"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddAchievementWrapper;
