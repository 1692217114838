
export enum ENMLeaveStatus {
  "PENDING" = "Pending",
  "APPROVED" = "Approved",
  "DECLINED" = "Declined",
}

export type TYPLeaveStatus = keyof typeof ENMLeaveStatus;

type Props = {
  status: TYPLeaveStatus;
};

const getClassNameByStatus = (status: TYPLeaveStatus) => {
  switch (status) {
    case "PENDING":
      return "text-[#000000] border-[#CCC] bg-[#FFFF001A]";

    case "APPROVED":
      return "text-[#008000] border-[#008000] bg-[#00800033]";

    case "DECLINED":
      return "text-white bg-red-500 border-red-500";
  }
};

const MOLLeaveStatusChip = ({ status }: Props) => {
  const className = ` 
    font-medium
    rounded-full
    px-2
    py-[2px]
    border
    text-[13px]
    border-[1px]
    ${getClassNameByStatus(status)}`;

  return <div className={className}> {ENMLeaveStatus[status]} </div>;
};

export default MOLLeaveStatusChip;
