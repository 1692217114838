import React, { useState } from "react";
import { MdDelete, MdEdit, MdNote } from "react-icons/md";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import EditTicketIssuesFormWrapper from "../Edit/EditTicketIssuesFormWrapper";
import { useDeleteTicketIssueByIdMutation } from "src/services/TicketIssuesService";
import { showToast } from "src/utils/showToaster";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import IssueToTaskFormWrapper from "../IssueToTask/IssueToTaskFormWrapper";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";

type Props = {
  onAddNew: () => void;
  issueData: any;
  meetingOptions: any;
  projectId: any;
};

const TicketIssuesListing = ({
  onAddNew,
  issueData,
  meetingOptions,
  projectId,
}: Props) => {
  const [rowId, setRowId] = useState<any>();
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);
  const [issueToTask, setIssueToTask] = useState(false);
  const [issueTitle, setIssueTitle] = useState<any>();

  const [deleteTicketIssue] = useDeleteTicketIssueByIdMutation();

  return (
    <>
      <div className="flex justify-end m-2">
        <ATMLoadingButton className="w-28" onClick={onAddNew}>
          Add
        </ATMLoadingButton>
      </div>
      <div>
        {issueData?.map((el: any) => {
          return (
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="border p-3 m-2 rounded col-span-11">
                <p className="text-xs pb-2">
                  <ATMMarkdownContainer markdown={el?.title || "-"} />
                </p>
              </div>
              <div className="col-span-1">
                {" "}
                <ATMActionMenu
                  options={[
                    {
                      label: (
                        <div className="flex items-center gap-x-2">
                          <MdEdit color="#000000" /> Edit
                        </div>
                      ),
                      onClick: () => {
                        setRowId(el?._id);
                        setIsOpenEditForm(true);
                      },
                    },
                    { 
                      label: (
                        <div className="flex items-center gap-x-2">
                          <MdNote color="#000000" /> Issue to Task
                        </div>
                      ),
                      onClick: () => {
                        setIssueToTask(true);
                        setIssueTitle(el?.title);
                        setRowId(el?._id);
                      },
                    },

                    {
                      label: (
                        <div className="flex items-center gap-x-2 text-red-700">
                          <MdDelete color="#EF4444" /> Delete
                        </div>
                      ),
                      onClick: () => {
                        showConfirmationDialog({
                          title: "Hands Up",
                          text: "Are you sure you want to delete this Issue ?",
                          icon: "question",
                          showCancelButton: true,
                          next: (result) => {
                            if (result?.isConfirmed) {
                              deleteTicketIssue(el?._id).then((res: any) => {
                                if (res?.error) {
                                  showToast("error", res?.error?.data?.message);
                                } else {
                                  if (res?.data?.status) {
                                    showToast("success", res?.data?.message);
                                  } else {
                                    showToast("error", res?.data?.message);
                                  }
                                }
                              });
                            }
                          },
                        });
                      },
                    },
                  ]}
                />
              </div>
            </div>
          );
        })}
      </div>
      {isOpenEditForm ? (
        <EditTicketIssuesFormWrapper
          rowId={rowId}
          meetingOptions={meetingOptions}
          onClose={() => setIsOpenEditForm(false)}
        />
      ) : null}
      {issueToTask ? (
        <IssueToTaskFormWrapper
          rowId={rowId}
          issueTitle={issueTitle}
          onClose={() => setIssueToTask(false)}
          id={projectId}
          formType="Add"
        />
      ) : null}
    </>
  );
};

export default TicketIssuesListing;
