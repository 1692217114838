import { Autocomplete, Chip, TextField } from "@mui/material";

type prop = {
  option: any;
  label: string;
  required: boolean;
  disable?: boolean;
  value: any;
  onChange: (value: any) => void;
};

const ATMTagAutoComplete = ({
  option,
  label,
  required = false,
  disable = false,
  value,
  onChange,
}: prop) => {
  return (
    <div>
      {label && (
        <label className="text-slate-700 font-medium">
          {" "}
          {label} {required && <span className="text-red-500"> * </span>}{" "}
        </label>
      )}
      <Autocomplete
        disabled={disable}
        className="outline-none border-none"
        multiple
        value={value}
        id="tags-filled"
        onChange={(e: any, value: any) => {
          onChange(value);
        }}
        options={option?.map((option: any) => option?.title)}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value?.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Enter tag name"
            size="small"
            fullWidth
            className="bg-white outline-none focus-within:border-none focus:border-none"
            InputProps={{
              ...params.InputProps,
              sx: {
                "&:focus": {
                  border: "none", // Remove border on focus
                  fontSize: 4,
                },
                "&:hover": {
                  boxShadow: "none", // Remove box-shadow on hover
                },
              },
            }}
          />
        )}
      />
    </div>
  );
};

export default ATMTagAutoComplete;
