import React, { useEffect, useState } from "react";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/DesputedIncidentSlice";
import { useDesputedListingQuery } from "src/services/IncidentService";
import moment from "moment";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import DesputedIncidentListing from "./DesputedIncidentListing";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaRegCircleXmark } from "react-icons/fa6";
import MarkAsUnhandledDialogWrapper from "../MarkAsUnhandledDialog/MarkAsUnhandledDialogWrapper";
import RejectNotMeRequestDialogWrapper from "../RejectNotMeRequestDialog/RejectNotMeRequestDialogWrapper";
import { RiShareForwardLine } from "react-icons/ri";
import ForwardIncidentWrapper from "../ForwardIncident/ForwardIncidentWrapper";

type Props = {};

const DesputedIncidentListingWrapper = (props: Props) => {
  const dispatch = useDispatch();

  const [pointerId, setPointerId] = useState<any>("");
  const [isOpenUnhandledDialog, setIsOpenUnhandledDialog] = useState(false);
  const [isOpenForwardDialog, setIsOpenForwardDialog] = useState(false);
  const [teamMemberId, setTeamMemberId] = useState<any>();
  const [isOpenNotMeDialog, setIsOpenNotMeDialog] = useState(false);
  const [notMeReason, setNotMeReason] = useState<any>();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    dateFilter,
  } = useSelector((state: RootState) => state.desputedIncident);

  const { data, isLoading, isFetching } = useDesputedListingQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["typeName", "categoryName"],
    page: page,
    filterBy: [],
    dateFilter: {
      // dateFilterKey: "addedOnDate",
      startDate: dateFilter?.startDate ? dateFilter?.startDate : "",
      endDate: dateFilter?.endDate ? dateFilter?.endDate : "",
    },
    orderBy: "addedOnDate",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "addedOnDate",
      name: UserModuleNameTypes.DESPUTED_INCIDENT_LIST_ADDED_ON_DATE,
      headerName: "Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[150px]",
      renderCell: (row: any) => (
        <div className="text-[13px] text-slate-700 font-medium">
          {row?.addedOnDate
            ? moment(row?.addedOnDate).format("DD MMM YYYY")
            : ""}
        </div>
      ),
    },
    {
      field: "categoryName",
      name: UserModuleNameTypes.DESPUTED_INCIDENT_LIST_CATEGORY_NAME,
      headerName: "category Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "typeName",
      name: UserModuleNameTypes.DESPUTED_INCIDENT_LIST_TYPE_NAME,
      headerName: "type Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "teamMembers",
      name: UserModuleNameTypes.DESPUTED_INCIDENT_LIST_ASSIGN_TO,
      headerName: "Assign To",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[300px]",
      renderCell: (row: any) => (
        <div className="font-medium text-[12px] py-1">{row?.memberName}</div>
      ),
    },
    {
      field: "remark",
      name: UserModuleNameTypes.DESPUTED_INCIDENT_LIST_REMARK,
      headerName: "remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-h-[100px] overflow-scroll",
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center text-red-700 gap-x-2">
                    <FaRegCircleXmark /> Reject Request
                  </div>
                ),
                onClick: () => {
                  setPointerId(row?._id);
                  setNotMeReason(row?.isNotMeReason);
                  setIsOpenNotMeDialog(true);
                },
                acitonName:
                  UserModuleNameTypes.ACTION_INCIDENT_REJCT_NOT_ME_REQUEST,
              },
              {
                label: (
                  <div className="flex items-center font-medium text-teal-600 gap-x-2">
                    <RiShareForwardLine size="1.3em" color="#12968A" /> Forward
                  </div>
                ),
                onClick: () => {
                  setPointerId(row?._id);
                  setIsOpenForwardDialog(true);
                  setTeamMemberId(row?.memberId);
                },
                acitonName: UserModuleNameTypes.ACTION_INCIDENT_FORWARD,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <IoIosCheckmarkCircleOutline size="1.4em" />
                    Mark As Unhandled
                  </div>
                ),
                onClick: () => {
                  setPointerId(row?._id);
                  setIsOpenUnhandledDialog(true);
                },
                acitonName:
                  UserModuleNameTypes.ACTION_INCIDENT_MARK_AS_UNHANDLED,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <DesputedIncidentListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setRowsPerPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenUnhandledDialog && (
        <MarkAsUnhandledDialogWrapper
          selectId={pointerId}
          onClose={() => setIsOpenUnhandledDialog(false)}
        />
      )}
      {isOpenNotMeDialog && (
        <RejectNotMeRequestDialogWrapper
          selectId={pointerId}
          onClose={() => setIsOpenNotMeDialog(false)}
          notMeReasons={notMeReason}
        />
      )}
      {isOpenForwardDialog && (
        <ForwardIncidentWrapper
          teamMemberId={teamMemberId}
          onClose={() => setIsOpenForwardDialog(false)}
          pointerId={pointerId}
        />
      )}
    </div>
  );
};

export default DesputedIncidentListingWrapper;
