import { FormikProps } from "formik";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { DumpFormValues } from "src/models/Dump.model";

type Props = {
  formikProps: FormikProps<DumpFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const DumpForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Dump" : "Update Dump"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Project */}
        <div className="z-50">
          <ATMSelect
            name="projectId"
            label="Project"
            placeholder="Select Project"
            value={values.projectId}
            isLoading={isAllProjectsDataLoading}
            options={allProjects?.map((ele: any) => {
              return {
                label: ele?.projectName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("projectId", newValue);
            }}
          />
        </div>

        {/* Layer Remark */}
        <div>
          <ATMTextArea
            name="layerRemark"
            className="rounded"
            value={values.layerRemark}
            onChange={(e) => setFieldValue("layerRemark", e)}
            label="Layer Remark"
            placeholder="Enter Layer Remark"
          />
        </div>
        {/* Dump Title */}
        <div className="z-10">
          <MOLMarkdownEditor
            required
            label="Title"
            placeholder="Write Title or drag your files here...."
            value={values?.title}
            onChange={(e: any) => {
              setFieldValue("title", e);
            }}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default DumpForm;
