import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import AddPaymentWrapper from "../AddPayment/AddPaymentWrapper";
import AddMilestoneWrapper from "../AddMilestone/AddMilestoneWrapper";
import AddExpenseWrapper from "../AddExpense/AddExpenseWrapper";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import ClientLedgerFilterFormDialogWrapper from "./ClientLedgerFilterFormCard/ClientLedgerFilterFormDialogWrapper";
// import ExpensesListFilterFormDialogWrapper from "./ExpensesFilterFormCard/ExpensesListFilterFormDialogWrapper";

type Props = {
  columns: columnTypes[];
  rows: any[];
  ledgerData: any;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const ClientLedgerListing = ({
  columns,
  rows,
  ledgerData,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isFilterDialog, setIsFilterDialog] = useState<boolean>(false);
  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  const [isOpenAddExpenseDialog, setIsOpenAddExpenseDialog] = useState(false);
  const [isOpenAddPaymentDialog, setIsOpenAddPaymentDialog] = useState(false);
  const [totalamountview, setTotalAmountView] = useState(false);
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex justify-between w-full">
          <div className="md:p-4 p-2">
            <ATMPageHeader
              pageTitle="Ledger"
              hideSearchBox
              hideAddButton={true}
            />
          </div>

          <div className="flex items-center w-full gap-2 md:justify-end px-4">
            <div
              className="cursor-pointer"
              onClick={() => setTotalAmountView(!totalamountview)}
            >
              {" "}
              {totalamountview ? (
                <FaSortAmountUp />
              ) : (
                <FaSortAmountDownAlt />
              )}{" "}
            </div>
            {/* Filter Button */}
            <ATMLoadingButton
              variant="tonal"
              onClick={() => setIsFilterDialog(true)}
            >
              <div className="flex items-center gap-2">
                <IoFilter />
                Filters
              </div>
            </ATMLoadingButton>

            {/* Search Box */}
            <div className="xs:w-full md:max-w-[300px]">
              <ATMInputAdormant
                name=""
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                adormant={<BiSearch />}
                adormantProps={{
                  position: "start",
                  extraClasses: "bg-white border-0",
                }}
                inputProps={{ className: "bg-white" }}
                placeholder="Search..."
              />
            </div>

            {isAuthorized(
              UserModuleNameTypes.ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB_ADD_MILESTONE
            ) && (
              <div className="relative z-20 mt-5 md:mt-0">
                <ATMLoadingButton
                  onClick={() => {
                    setIsOpenAddDialog(true);
                  }}
                >
                  Milestone
                </ATMLoadingButton>
              </div>
            )}
            {isAuthorized(
              UserModuleNameTypes.ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB_ADD_EXPENSE
            ) && (
              <div className="relative z-20 mt-5 md:mt-0">
                <ATMLoadingButton
                  onClick={() => {
                    setIsOpenAddExpenseDialog(true);
                  }}
                >
                  Expense
                </ATMLoadingButton>
              </div>
            )}
            {isAuthorized(
              UserModuleNameTypes.ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB_ADD_PAYMENT
            ) && (
              <div className="relative z-20 mt-5 md:mt-0">
                <ATMLoadingButton
                  onClick={() => {
                    setIsOpenAddPaymentDialog(true);
                  }}
                >
                  Payment
                </ATMLoadingButton>
              </div>
            )}
          </div>
        </div>
        {totalamountview && (
          <div className="mb-3 grid xl:grid-cols-5  sm:grid-cols-3  gap-4 mt-2 px-2">
            <div className="flex flex-col shadow-md bg-gray-50 justify-center items-center xl:col-start-1 hover:bg-hover py-1">
              <span className="font-semibold text-lg text-primary-main">
                Total Expense{" "}
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalExpense || 0}
              </span>
            </div>
            <div className="flex flex-col shadow-md bg-gray-50 justify-center items-center hover:bg-hover py-1">
              <span className="font-semibold text-lg text-primary-main">
                Total Milestone{" "}
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalMilestone || 0}
              </span>
            </div>
            <div className="flex flex-col shadow-md bg-gray-50 justify-center items-center hover:bg-hover py-1">
              <span className="font-semibold text-lg text-primary-main">
                Total Payment{" "}
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalPayment || 0}
              </span>
            </div>
            <div className="flex flex-col shadow-md bg-gray-50 justify-center items-center hover:bg-hover py-1">
              <span className="font-semibold text-lg text-primary-main">
                Total Amount <span className="text-[10px]">(with GST) </span>{" "}
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalLedgerAmountWithGst || 0}
              </span>
            </div>
            <div className="flex flex-col shadow-md bg-gray-50 justify-center items-center hover:bg-hover py-1">
              <span className="font-semibold text-lg text-primary-main">
                Total Amount <span className="text-[10px]">(without GST) </span>
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalLedgerAmountWithOutGst || 0}
              </span>
            </div>
          </div>
        )}
        {/* Table */}
        <div className="flex-1 overflow-auto flex flex-col md:px-4 px-2">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "min-h-[50px]"}
            isLoading={isTableLoading}
          />
        </div>

        <div className="md:p-4 p-2 py-2">
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
      {isOpenAddDialog ? (
        <AddMilestoneWrapper onClose={() => setIsOpenAddDialog(false)} />
      ) : null}

      {isOpenAddPaymentDialog ? (
        <AddPaymentWrapper onClose={() => setIsOpenAddPaymentDialog(false)} />
      ) : null}
      {isOpenAddExpenseDialog ? (
        <AddExpenseWrapper onClose={() => setIsOpenAddExpenseDialog(false)} />
      ) : null}
      {isFilterDialog ? (
        <ClientLedgerFilterFormDialogWrapper
          open={isFilterDialog}
          onClose={() => setIsFilterDialog(false)}
        />
      ) : null}
    </>
  );
};

export default ClientLedgerListing;
