import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import {
  useAddMilestoreMutation,
  useGetDefaultCurrencyQuery,
} from "src/services/ClientServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import AddMilestoneForm from "./AddExpenseForm";
import { useParams } from "react-router-dom";
import moment from "moment";

type Props = {
  onClose: () => void;
};

export type MilestonFormValues = {
  milestoneDate: any;
  amount: string;
  taxAmount: any;
  remark: string;
  currencyId: any;
  accountId: any;
  expenseCategoryId: any;
  taxPercent: any;
};

const AddExpenseWrapper = ({ onClose }: Props) => {
  const { clientId } = useParams();
  const [currencyDefault, setCurrencyDefault] = useState<any>({});
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addMilestone] = useAddMilestoreMutation();

  const { isLoading, isFetching, data } = useGetDefaultCurrencyQuery(clientId, {
    skip: !clientId,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCurrencyDefault(data?.data || {});
    }
  }, [isLoading, isFetching, data]);
  // Form Initial Values
  const initialValues: MilestonFormValues = {
    milestoneDate: null,
    taxPercent: 18,
    amount: "",
    taxAmount: "",
    remark: "",
    expenseCategoryId: "",
    currencyId: {
      label: currencyDefault ? currencyDefault?.defaultCurrencyName : "",
      value: currencyDefault ? currencyDefault?.currencyId : "",
    },
    accountId: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    milestoneDate: string().required("Please enter expense date"),
    amount: string().test("cost-test", "Please enter amount", (value) => {
      return Number(value || 0) > 0;
    }),
    currencyId: object().required("Please select currency"),
    expenseCategoryId: object().required("Please select expense category"),
    accountId: object().required("Please select account"),
  });

  // Handle Submit
  const handleSubmit = (
    values: MilestonFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MilestonFormValues>
  ) => {
    const formattedValues = {
      type: "EXPENSE",
      clientId: clientId,
      expenseDate: values?.milestoneDate
        ? moment(values?.milestoneDate).format("YYYY-MM-DD")
        : "",
      amount: values?.amount,
      taxAmount: values?.taxAmount,
      remark: values?.remark,
      currencyId: values?.currencyId?.value,
      expenseCategoryId: values?.expenseCategoryId?.value,
      accountId: values?.accountId?.value,
    };

    addMilestone(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AddMilestoneForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddExpenseWrapper;
