import React, { useEffect, useState } from 'react'
import TimelineTask from './TimelineTask'
import { useGetAllTaskQuery, useGetTaskByIdQuery } from 'src/services/TaskService';

type Props = {
    setTaskCollapse: any,
    TASK_ID: string
}
const TimelineTaskWrapper = ({ setTaskCollapse, TASK_ID }: Props) => {


    const [items, setItems] = useState()
    const [allTaskData, setAllTaskData] = useState()
    // Get Single Ticket Details
    const {
        isLoading: isTicketDetailsLoading,
        isFetching: isTicketDetailsFetching,
        data: ticketData,
    } = useGetTaskByIdQuery(TASK_ID || "", {
        skip: !TASK_ID,
    });
    // Set Single Ticket Details
    useEffect(() => {
        if (!isTicketDetailsLoading && !isTicketDetailsFetching) {
            setItems(ticketData?.data || []);
        }
    }, [isTicketDetailsLoading, isTicketDetailsFetching, ticketData]);

    const {
        isLoading: isTaskLoading,
        isFetching: isTaskFetching,
        data: taskData,
    } = useGetAllTaskQuery("");

    useEffect(() => {
        if(isTaskLoading && isTaskFetching){
            setAllTaskData(taskData)
        }
    }, [isTaskLoading, isTaskFetching,taskData])

    


    return (
        <>
            <TimelineTask setTaskCollapse={setTaskCollapse} items={items} allTaskData={allTaskData}/>
        </>
    )
}

export default TimelineTaskWrapper
