import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import MOLPriorityChip from "src/components/UI/molecules/MOLPriorityChip/MOLPriorityChip";
import MOLTicketStatusChip from "src/components/UI/molecules/MOLTicketStatusChip/MOLTicketStatusChip";
import MOLTicketTypeChip from "src/components/UI/molecules/MOLTicketTypeChip/MOLTicketTypeChip";
import { RootState } from "src/redux/store";
import TicketList from "./TicketList";

// TS Modals
import { TicketListResponse } from "src/models/Ticket.model";

// Redux
import { Tooltip } from "@mui/material";
import { FaFileArchive } from "react-icons/fa";
import { IoDuplicate } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import ATMCopyToClipboard from "src/components/UI/atoms/formFields/ATMCopyToClipboard/ATMCopyToClipboard";
import MOLClientSideTicketStatusChip from "src/components/UI/molecules/MOLClientSideTicketStatusChip/MOLClientSideTicketStatusChip";
import {
  setIsOpenAddDialog,
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
} from "src/redux/slices/TicketSlice";
import {
  useGetAllTicketQuery,
  useMoveTicketToArchiveMutation,
} from "src/services/TicketServices";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import AddTicketFormWrapper from "../Add/AddTicketFormWrapper";
import DuplicateTicketsFormWrapper from "../Duplicate/DuplicateTicketsFormWrapper";
import EditTicketWrapper from "../EditTicket/EditTicketWrapper";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const TicketListWrapper = (props: Props) => {
  const [selectedTicketId, setSelectedTicketId] = useState<string>("");
  const [isCopied, setIsCopied] = useState(false);
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [isOpenDuplicateFormDialog, setIsOpenDuplicateFormDialog] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const [moveTicketToArchive] = useMoveTicketToArchiveMutation();

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    sortValue,
    dateFilter,
    priority,
    type,
    isOpenAddDialog,
    status,
    projects,
    tags,
    createdBy,
    clientSideTicketStatusFilterValue,
    changeRequestId,
    isArchived,
    resosurceRequired,
    tagsNot,
  } = useSelector((state: RootState) => state.ticket);
  const { isLoading, isFetching, data } = useGetAllTicketQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["title", "ticketNumber", "createdBy", "projectName"],
    page: page,
    filterBy: [
      {
        fieldName: "status",
        value: status?.value && [status?.value],
      },
      {
        fieldName: "type",
        value: type,
      },
      {
        fieldName: "priority",
        value: priority,
      },
      {
        fieldName: "createdBy",
        value: createdBy?.value,
      },
      {
        fieldName: "tags",
        value: tags?.map((ele: any) => ele?.value) || [],
      },
      {
        fieldName: "tagsNot",
        value: tagsNot?.map((ele: any) => ele?.value) || [],
      },
      {
        fieldName: "projectId",
        value: projects?.value,
      },
      {
        fieldName: "clientSideTicketStatus",
        value: clientSideTicketStatusFilterValue?.value,
      },
      {
        fieldName: "changeRequestId",
        value: changeRequestId?.value,
      },
      {
        fieldName: "isArchived",
        value: isArchived,
      },
      ...(resosurceRequired
        ? [
            {
              fieldName: "resourceRequired",
              value: [],
            },
          ]
        : []),
    ],
    dateFilter: {
      startDate: dateFilter?.start_date || "",
      endDate: dateFilter?.end_date || "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem));
    }
  }, [isLoading, isFetching, data, dispatch]);

  enum TicketPriorityEnum {
    low = "LOW",
    medium = "MEDIUM",
    high = "HIGH",
    urgent = "URGENT",
  }

  const getATMMenuOptions = (row: TicketListResponse) => {
    if (row?.status === "UNMAPPED") {
      return [
        {
          label: (
            <div className="flex items-center gap-x-2">
              <MdEdit color="#000000" /> Edit
            </div>
          ),
          onClick: () => {
            setIsOpenEditFormDialog(true);
            setSelectedTicketId(row?._id);
          },
          actionName: UserModuleNameTypes.ACTION_TIKCETS_EDIT,
        },
        {
          label: (
            <div className="flex items-center gap-x-2">
              <IoDuplicate color="#000000" />
              Duplicate
            </div>
          ),
          onClick: () => {
            setIsOpenDuplicateFormDialog(true);
            setSelectedTicketId(row?._id);
          },
          actionName: UserModuleNameTypes.ACTION_TIKCETS_MOVE_TO_ARCHIVE,
        },
      ];
    } else if (!row?.isArchived) {
      return [
        {
          label: (
            <div className="flex items-center gap-x-2">
              <FaFileArchive color="#000000" /> Move to Archive
            </div>
          ),
          onClick: () => {
            showConfirmationDialog({
              title: "Hands Up",
              text: "Are you sure want to move this ticket to archive?",
              icon: "question",
              showCancelButton: true,
              next: (result) => {
                if (result?.isConfirmed) {
                  moveTicketToArchive(row?._id).then((res: any) => {
                    if (res.error) {
                      showToast("error", res?.error?.data?.message);
                    } else {
                      showToast("success", res?.data?.message);
                    }
                  });
                }
              },
            });
          },
          actionName: UserModuleNameTypes.ACTION_TIKCETS_DUPLICATE,
        },
      ];
    } else {
      return [];
    }
  };

  const columns: columnTypes[] = [
    {
      field: "ticketNumber",
      headerName: "#",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px] max-w-[200px]",
      name: UserModuleNameTypes.TIKCETS_LIST_TICKET_NUMBER,
      renderCell: (row: TicketListResponse) => (
        <div className="flex gap-x-2">
          <div
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            className="bg-stone-100 p-[1px] flex justify-between items-center rounded-xl  text-xs gap-4 cursor-pointer"
          >
            <ATMCopyToClipboard
              tooltipTitle={isCopied ? "Copied" : "Copy"}
              copyText={row?.ticketNumber}
              onCopy={() => {
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 1000);
              }}
            >
              <p>{row?.ticketNumber}</p>
            </ATMCopyToClipboard>
          </div>
          {row?.unReadMessages ? (
            <div className="bg-[#041618] rounded-full font-bold h-[23px] w-[23px] text-[10px] flex justify-center items-center -right-[6px] -top-[1px] text-white">
              {row?.unReadMessages}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date-Time",
      flex: "flex-[2_2_0%]",
      extraClasses: "min-w-[100px] max-w-[100px]",
      name: UserModuleNameTypes.TIKCETS_LIST_DATE_TIME,
      renderCell: (row: TicketListResponse) => (
        <div className="py-0">
          <div className="text-[10px] text-neutral font-medium">
            {moment(row?.createdAt).format("hh:mm A")}
          </div>
          <div className="text-xs font-medium">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: "flex-[2_2_0%]",
      align: "center",
      extraClasses: "min-w-[200px]",
      name: UserModuleNameTypes.TIKCETS_LIST_TITLE,
      renderCell: (row: TicketListResponse) => (
        <Tooltip title={row?.title}>
          <div className="w-full overflow-hidden font-medium truncate">
            {row?.title}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[80px]",
      name: UserModuleNameTypes.TIKCETS_LIST_PROJECT,
      renderCell: (row: TicketListResponse) => (
        <div className="capitalize">{row?.projectName} </div>
      ),
    },
    {
      field: "createdByLabel",
      headerName: "Created By",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[110px] justify-center",
      name: UserModuleNameTypes.TIKCETS_LIST_CREATED_BY,
      align: "center",
      renderCell: (row: TicketListResponse) => (
        <div className="capitalize text-[12px]"> {row?.createdByLabel} </div>
      ),
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[100px] max-w-[110px]",
      name: UserModuleNameTypes.TIKCETS_LIST_PRIORITY,
      renderCell: (row: TicketListResponse) => (
        <div>
          <MOLPriorityChip priority={row?.priority as TicketPriorityEnum} />
        </div>
      ),
    },

    {
      field: "type",
      headerName: "Type",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[160px] justify-center",
      name: UserModuleNameTypes.TIKCETS_LIST_TYPE,
      renderCell: (row: TicketListResponse) => (
        <div className="flex justify-center">
          <MOLTicketTypeChip ticketType={row?.type as any} />
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Stage",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px] max-w-[160px] justify-center",
      name: UserModuleNameTypes.TIKCETS_LIST_STAGE,
      renderCell: (row: TicketListResponse) => (
        <div>
          <MOLTicketStatusChip status={row?.status as any} />
        </div>
      ),
    },
    {
      field: "clientSideTicketStatus",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[160px] justify-center",
      name: UserModuleNameTypes.TIKCETS_LIST_STATUS,
      renderCell: (row: TicketListResponse) => (
        <MOLClientSideTicketStatusChip
          status={row?.clientSideTicketStatus as any}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: TicketListResponse) => {
        const menuOptions = getATMMenuOptions(row);
        return !row?.isArchived && <ATMActionMenu options={menuOptions} />;
      },
    },
  ];

  return (
    <>
      <TicketList
        columns={columns}
        rows={items}
        addNew={() => dispatch(setIsOpenAddDialog(true))}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          sortValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          setSortValue: (newValue) => dispatch(setSortValue(newValue)),
        }}
      />
      {isOpenAddDialog ? (
        <AddTicketFormWrapper
          onClose={() => dispatch(setIsOpenAddDialog(false))}
          sourceName={"INTERNAL"}
        />
      ) : null}

      {isOpenEditFormDialog ? (
        <EditTicketWrapper
          ticketId={selectedTicketId}
          onClose={() => setIsOpenEditFormDialog(false)}
        />
      ) : null}

      {isOpenDuplicateFormDialog ? (
        <DuplicateTicketsFormWrapper
          ticketId={selectedTicketId}
          onClose={() => setIsOpenDuplicateFormDialog(false)}
        />
      ) : null}
    </>
  );
};

export default TicketListWrapper;
