import React, { useState, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSelectedClient,
  setTotalItems,
} from "src/redux/slices/ClientSlice";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { AppDispatch, RootState } from "src/redux/store";
import { useGetClientsPaginationQuery } from "src/services/ClientServices";
import ClientList from "./ClientList";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { CircularProgress } from "@mui/material";

type Props = {};

const ClientListWrapper = (props: Props) => {
  const [isOpenAddDialog, setIsOpenAddDialog] = useState<boolean>(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);

  // Initiate Methods
  const dispatch = useDispatch<AppDispatch>();

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    selectedClient,
  } = useSelector((state: RootState) => state.client);

  // Dynamic import
  const AddClientFormWrapper = React.lazy(
    () => import("../Add/AddClientFormWrapper")
  );
  const EditClientFormWrapper = React.lazy(
    () => import("../Edit/EditClientFormWrapper")
  );

  const { data, isLoading, isFetching } = useGetClientsPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["email", "mobile", "organisationName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "organisationName",
    orderByValue: 1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "organisationName",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.CLIENTS_LIST_ORGANISATION_NAME,
    },
    {
      field: "mobile",
      headerName: "Number",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.CLIENTS_LIST_MOBILE,
    },
    {
      field: "email",
      headerName: "Email",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[250px]",
      name: UserModuleNameTypes.CLIENTS_LIST_EMAIL,
    },
    {
      field: "currencyName",
      headerName: "Currency",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.CLIENTS_LIST_CURRENCY,
      renderCell: (row: any) => <div> {row?.currencySymbol || "-"} </div>,
    },
    {
      field: "action",
      headerName: "Action",
      // flex: "flex-[1_0_0%]",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: "Edit",
                onClick: () => {
                  setIsOpenEditDialog(true);
                  dispatch(setSelectedClient(row));
                },
                acitonName: UserModuleNameTypes.ACTION_CLIENTS_EDIT,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ClientList
        columns={columns}
        rows={items}
        addNew={() => setIsOpenAddDialog(true)}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      <Suspense fallback={<CircularProgress />}>
        {isOpenAddDialog ? (
          <AddClientFormWrapper onClose={() => setIsOpenAddDialog(false)} />
        ) : null}
      </Suspense>

      <Suspense fallback={<CircularProgress />}>
        {isOpenEditDialog ? (
          <EditClientFormWrapper
            selectedClient={selectedClient}
            onClose={() => setIsOpenEditDialog(false)}
          />
        ) : null}
      </Suspense>
    </React.Fragment>
  );
};

export default ClientListWrapper;
