import { FormikProps } from "formik";
import moment from "moment";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";

type Props = {
  formikProps: FormikProps<any>;
  isLoading: boolean;
};
const ScheduledRoundForm = ({ formikProps, isLoading }: Props) => {
  const { values, setFieldValue, handleBlur } = formikProps;
  //get list of all the codiotic user
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
      <div className="col-span-2">
        <p className="text-slate-600 font-semibold text-md">Schedule Round</p>
      </div>
      {/* Scheduled date */}
      <div>
        <ATMDatePicker
          required
          label="Scheduled Date"
          format="DD/MM/YYYY"
          value={values.scheduledDate}
          onChange={(newValue) => setFieldValue("scheduledDate", newValue)}
        />
      </div>
      <div>
        <ATMSelect
          name="interviewerId"
          label="Responsible Member"
          placeholder="Select Member"
          value={values.interviewerId}
          isLoading={isCodioticUserDataLoading}
          options={codioticUsers?.map((ele: CodioticUserListResponse) => {
            return {
              label: ele?.name,
              value: ele?._id,
            };
          })}
          onChange={(newValue) => {
            setFieldValue("interviewerId", newValue);
          }}
        />
      </div>
      <div>
        <ATMTextField
          type="time"
          name="scheduledStartTime"
          value={moment(values.scheduledStartTime, "HH:mm").format("HH:mm")}
          required
          label="Scheduled Time"
          placeholder="Enter Scheduled Time"
          className="mt-0 rounded"
          onBlur={handleBlur}
          onChange={(e) => {
            let time = moment(e.target.value, "HH:mm");
            setFieldValue("scheduledStartTime", time);
          }}
        />
      </div>
      <div className="col-span-2 flex justify-end pt-2">
        <ATMLoadingButton type="submit" isLoading={isLoading}>
          Schedule
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default ScheduledRoundForm;
