import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsOpenEditFormDialog,
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSelectedClient,
  setTotalItems,
} from "src/redux/slices/SocialMediaSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useGetSocialMediaPaginationQuery,
  useChangeStatusSocialMediaMutation,
} from "src/services/SocialMediaServices";
import EditSocialMediaFormWrapper from "../Edit/EditSocialMediaFormWrapper";
import SocialMediaList from "./SocialMediaList";
import { SocialMediaListResponse } from "src/models/SocialMedia.model";
import { MdBlock } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { showToast } from "src/utils/showToaster";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const SocialMediaListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedSocialMediaId, setselectedSocialMediaId] =
    useState<string>("");
  const [activeUpdateSocialMedia] = useChangeStatusSocialMediaMutation();

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    isOpenEditFormDialog,
    // selectedClient,
  } = useSelector((state: RootState) => state.socialMedia);

  const { data, isLoading, isFetching } = useGetSocialMediaPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["socialMediaName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleStatusChangeSocialMedia = (id: string) => {
    activeUpdateSocialMedia(id)
      .then((res: any) => {
        showToast("success", "status change successfully");
      })
      .catch((err: any) => {
        console.error("error", err);
      });
  };

  const columns: columnTypes[] = [
    {
      field: "socialMediaName",
      headerName: "Social Media Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      name: UserModuleNameTypes.SOCIAL_MEDIA_LIST_SOCIAL_MEDIA_NAME,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: SocialMediaListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  dispatch(setIsOpenEditFormDialog(true));
                  dispatch(setSelectedClient(row));
                  setselectedSocialMediaId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_SOCIAL_MEDIA_EDIT,
              },
              {
                label: row.isActive ? (
                  <div className="flex items-center gap-x-2">
                    <MdBlock color="red" /> Deactivate
                  </div>
                ) : (
                  <div className="flex items-center gap-x-2">
                    <VscActivateBreakpoints color="#00a67e" /> Activate
                  </div>
                ),
                onClick: () => {
                  handleStatusChangeSocialMedia(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_SOCIAL_MEDIA_DEACTIVE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <SocialMediaList
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      {isOpenEditFormDialog ? (
        <EditSocialMediaFormWrapper
          onClose={() => dispatch(setIsOpenEditFormDialog(false))}
          selectedSocialMediaId={selectedSocialMediaId}
        />
      ) : null}
    </div>
  );
};

export default SocialMediaListingWrapper;
