import { CircularProgress } from "@mui/material";
import { FormikProps } from "formik";
import { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { FaFilePdf } from "react-icons/fa6";
import { HiOutlineDocument } from "react-icons/hi";
import { TbX } from "react-icons/tb";
import ATMDialog from "src/components/UI/atoms/ATMDialog/ATMDialog";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import MOLFileUpload from "src/components/UI/molecules/MOLFileUpload/MOLFileUpload";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { OnBoardStageFormValues } from "src/models/OnBoardStage.model";
import { extractFileInfoFromUrl } from "src/utils/common/extractFileInfoFromUrl";

type Props = {
  formikProps: FormikProps<OnBoardStageFormValues>;
  formtype: "ADD" | "EDIT";
  isLoading?: boolean;
  onclose: () => void;
};

const OnBoardStageForm = ({
  formikProps,
  formtype,
  isLoading = false,
  onclose,
}: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;
  const [isShowResume, setIsShowResume] = useState(false);
  const [documentFileUrl, setDocumentFileUrl] = useState("")

  const sections = [
    {
      fieldKey: "shareToCandidate",
      label: "Share To Candidate",
      data: values.shareToCandidate,
    },
    {
      fieldKey: "collectFromCandidate",
      label: "Collect From Candidate",
      data: values.collectFromCandidate,
    },
    {
      fieldKey: "forOfficial",
      label: "For Official",
      data: values.forOfficial,
    },
    {
      fieldKey: "credentialsShare",
      label: "Credentials",
      data: values.credentialsShare,
    },
    {
      fieldKey: "otherRequirements",
      label: "Other Requirements",
      data: values.otherRequirements,
    },
  ];

  // Ensure each section's array has at least one object if it's empty
  sections.forEach((section) => {
    if (section.data.length === 0) {
      setFieldValue(section.fieldKey, [
        {
          title: "",
          description: "",
          completeWithinDays: "",
          responsibleUserId: "",
          file: [],
          isMandatory: false,
        },
      ]);
    }
  });

  //For PDF
  const PdfFile = ({ fileInfo, isShowPreview, onPreviewClick }: any) => {
    const fileName = fileInfo?.url?.split("/")?.at(-1) || "";
    return (
      <>
        {isShowPreview ? (
          <div
            className="flex flex-col gap-2 flex-wrap justify-center items-center cursor-pointer"
            onClick={() => onPreviewClick?.()}
          >
            <FaFilePdf color="red" size={25} />
            <span className="font-medium text-center text-xs  break-all w-full">
              {fileName}
            </span>
            <div className="flex gap-1 hover:underline text-blue-500">
              <AiOutlineEye />
              <span className="text-xs ">Preview</span>
            </div>
          </div>
        ) : (
          <a href={fileInfo?.url} target="_blank" rel="noreferrer">
            <div className="flex flex-col gap-2 flex-wrap justify-center items-center">
              <FaFilePdf color="red" size={25} />
              <span className="font-medium text-center text-xs  break-all w-full">
                {fileName}
              </span>
            </div>
          </a>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col">
      {isLoading ? (
        <div className=" w-[100%] h-screen flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      ) : (
        <div>

          <div className="grid grid-cols-2 gap-4">
            {/* Sections */}
            <div className="col-span-2 space-y-2">
              {sections?.map((section) => {
                return (
                  <div key={section.fieldKey} className="p-4">
                    <div className="p-2 font-medium border-l-4 border-primary-main text-primary-main">
                      {section.label}
                    </div>

                    <div className="px-4 py-2 space-y-2">
                      <ul className="space-y-2">
                        {section?.data?.map((fieldData, fieldIndex) => {
                          return (
                            <li
                              key={fieldIndex}
                              className="flex flex-col space-y-2 gap-3 group"
                            >
                              <div className="flex flex-col space-y-2 justify-between p-2 rounded cursor-pointer">
                                <div>
                                  <span className="font-medium text-md text-slate-700">
                                    {fieldData.title}
                                  </span>
                                </div>

                                {/* Description */}
                                {fieldData.description ?

                                  <div className="overflow-auto">
                                    <MOLMarkdownEditor
                                      name={`${section.fieldKey}[${fieldIndex}].description`}
                                      value={fieldData.description}
                                      onChange={(html) =>
                                        setFieldValue(
                                          `${section.fieldKey}[${fieldIndex}].description`,
                                          html
                                        )
                                      }
                                      label=""
                                      placeholder="Start writing here..."
                                      extraClassName="overflow-auto"
                                    />
                                  </div> : null
                                }


                                {fieldData.file?.length ?
                                  <div className="flex flex-col gap-4">
                                    {fieldData?.file?.map(
                                      (file: any, fileIndex: any) => {
                                        const url =
                                          extractFileInfoFromUrl(file) as any;
                                        return (
                                          <div
                                            key={fileIndex}
                                            className="relative flex items-center gap-2 p-2 bg-white border-2 rounded-md"
                                          >
                                            {url.type === "images" ? (
                                              <img
                                                src={file}
                                                alt="document"
                                                className="w-[50px] h-[50px] rounded-md border"
                                              />
                                            ) : url.type === "pdfs" ? (
                                              <>
                                                <PdfFile
                                                  fileInfo={file}
                                                  isShowPreview={true}
                                                  onPreviewClick={() => {
                                                    setDocumentFileUrl(file)
                                                    setIsShowResume(true)
                                                  }}
                                                />


                                              </>
                                            ) : (
                                              <div className="w-[50px] h-[50px] flex justify-center items-center text-xl border rounded-md bg-gray-50">
                                                <HiOutlineDocument />
                                              </div>
                                            )}

                                            <div className="w-[200px] truncate text-xs font-semibold">
                                              {url.name}
                                            </div>

                                            <div
                                              onClick={() => {
                                                const newList = [...fieldData.file];
                                                newList.splice(fileIndex, 1);
                                                setFieldValue(
                                                  `${section.fieldKey}[${fieldIndex}].file`,
                                                  newList
                                                );
                                              }}
                                              className="absolute w-[28px] h-[28px] cursor-pointer hover:bg-gray-50 rounded-full border text-[20px] -top-[14px] -right-[14px] flex justify-center items-center border-gray-400 text-gray-500"
                                            >
                                              <TbX />
                                            </div>
                                          </div>
                                        );

                                      }
                                    )}
                                  </div> : null}

                                <div className="flex col-span-full">
                                  <MOLFileUpload
                                    showValue={false}
                                    name={`${section.fieldKey}[${fieldIndex}].file`}
                                    label=""
                                    value={fieldData.file}
                                    onChange={(newFile: any) => {
                                      const updatedFiles = [
                                        ...fieldData.file,
                                        newFile.url,
                                      ];
                                      setFieldValue(
                                        `${section.fieldKey}[${fieldIndex}].file`,
                                        updatedFiles
                                      );
                                    }}
                                    placeholder="Select file"
                                  />
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="sticky bottom-0 bg-white py-2 flex items-center justify-end gap-4 p-4 col-span-full">
              <ATMLoadingButton type="submit" isLoading={isSubmitting}>
                Save
              </ATMLoadingButton>
            </div>
          </div>
          {isShowResume && (
            <ATMDialog onClose={() => setIsShowResume(false)}>
              <div
                className={`relative min-w-[80vw]  min-h-[80vh] max-h-[90vh]  overflow-auto hide-scrollbar`}
              >
                <iframe
                  src={documentFileUrl}
                  aria-labelledby="resume"
                  width="100%"
                  height="100%"
                  title={documentFileUrl}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    border: "none",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            </ATMDialog>
          )}
        </div>
      )}
    </div>
  );
};

export default OnBoardStageForm;
