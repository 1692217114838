import React from "react";
import { FormikProps } from "formik";
import { LoginFormInitialValues } from "./LoginFormWrapper";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMPassword from "src/components/UI/atoms/formFields/ATMPassword/ATMPassword";
import { FcGoogle } from "react-icons/fc";

import "../../App.css";

type Props = {
  formikProps: FormikProps<LoginFormInitialValues>;
  isInvalidPassword: boolean;
};

const LoginForm = ({ formikProps, isInvalidPassword }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <>
      <div className="bg-img-loginui min-h-screen py-16 flex justify-center items-center">
        <div className="bg-slate-400 flex flex-col lg:flex-row justify-center p-8 xl:p-20 sm:p-8  xs:p-0 backdrop-blur-md bg-opacity-50">
          <div className="bg-white w-full lg:w-1/2 py-10 flex justify-center lg:py-14 px-6 lg:px-12">
            <div>
              <div>
                <span>
                  {/* <div className="">
                    <IoFlash className="text-blue-700 text-[30px]" />
                  </div> */}
                  <div className="font-semibold text-4xl text-center text-primary-main logo-glow">
                    Codiotic
                  </div>
                  <p className="text-[24px] font-semibold mt-2">Login</p>
                  <p className="text-[14px]">
                    Stay updated on your professional world
                  </p>
                </span>
              </div>

              <div className="">
                <button className="border py-2 2xl:px-20 xl:px-20 lg:px-16 md:px-14 xs:px-11  rounded-3xl mt-8  flex items-center gap-2  font-semibold">
                  <FcGoogle className="text-[20px]" /> Sign in with Google
                </button>
              </div>
              <div className="flex justify-center items-center gap-3 mt-2">
                <p className="border-t border-2 w-14"></p>
                <p className="text-[12px] text-slate-400">
                  or sign in with Email
                </p>
                <p className="border-t border-2 w-14"></p>
              </div>
              <div>
                {/* Email */}
                <div className="">
                  <ATMTextField 
                  required
                    name="email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    label="Email"
                    placeholder="Enter Email"
                    onBlur={handleBlur}
                    className="border-blue-900 rounded-full "
                  />
                </div>

                {/* Password */}
                <div className="mt-5">
                  <ATMPassword 
                  required
                    name="password"
                    value={values.password}
                    onChange={(e) => setFieldValue("password", e.target.value)}
                    label="Password"
                    placeholder="Enter Password"
                    onBlur={handleBlur}
                    extraClasses="border-blue-900 rounded-full px-3 bg-white "
                  />
                </div>
              </div>
              <div className="mt-6">
                <ATMLoadingButton
                  isLoading={isSubmitting}
                  className="rounded-3xl"
                  type="submit"
                >
                  Login
                </ATMLoadingButton>
              </div>
            </div>
          </div>
          <div className="bg-blue-800 w-full lg:w-1/2 hidden  lg:block">
            <img
              className="w-full h-full object-cover"
              src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?size=626&ext=jpg&ga=GA1.1.1448711260.1706745600&semt=sph"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
