import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import { getTimeInHours } from "src/utils/constants/ticketUtils";
import { setDateFilter } from "src/redux/slices/ChangeRequestActivityLogSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
  };
  dateFilter: {
    start_date: string | null;
    end_date: string | null;
  };
  };

const getRandomColor = () => {
  var r = Math.floor(Math.random() * 256);
  var g = Math.floor(Math.random() * 256);
  var b = Math.floor(Math.random() * 256);
  return `rgba(${r}, ${g}, ${b}, 0.7)`;
};

const ChangeRequestLog = ({
  columns,
  rows,
  paginationProps: { isTableLoading },
  dateFilter,
  }: Props) => {
  const dispatch = useDispatch();
  const userData = rows?.reduce((acc, activityData: any) => {
    acc[activityData.userName] = Number(activityData?.totalDuration || 0) / 60;
    return acc;
  }, {});
  const reportData = rows?.reduce((acc, activityData: any) => {
    return [...acc, (activityData?.totalDuration || 0) / 60];
  }, []);
  // get total duration spent in chage request
  const getTotalDuration = useMemo(() => {
    return rows?.reduce((sum, activityData: any) => {
      return (sum += Number(activityData?.totalDuration || 0));
    }, 0);
  }, [rows]);

  // data for bar graph
  const data = {
    labels: Object.keys(userData),
    datasets: [
      {
        label: `Time Spent`,
        data: Object.values(userData),
        backgroundColor: reportData?.map((data: any[]) => {
          return getRandomColor();
        }),
        barThickness: 60,
      },
    ],
  };

  // bar graph options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          generateLabels: (chart: any) => {
            return [
              {
                text: `Total Time Taken (${getTimeInHours(getTotalDuration)})`,
              },
            ];
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (this: any, tooltipItem: any) {
            // Customize the tooltip label
            const value = getTimeInHours(Number(tooltipItem.raw || 0) * 60);
            return `Time Spent: ${value}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: Math.round(
          (reportData?.sort((a: number, b: number) => b - a)?.[0] || 0) +
            300 / 60
        ), // Set the maximum value on the y-axis
        stepSize: 1, // Set the interval between ticks on the y-axis
        precision: 0, //
        ticks: {
          callback: (value: any) => `${value}h`,
        },
      },
    },
  };

  return (
    <div className="flex flex-col">
      <div className=" flex justify-end items-end gap-2 px-3 py-4">
        <ATMDatePicker
          label="Start Date"
          onChange={(startDateValue) =>
            dispatch(
              setDateFilter({
                ...dateFilter,
                start_date: startDateValue
                  ? moment(startDateValue)?.format("yyyy-MM-DD")
                  : "",
              })
            )
          }
          value={
            dateFilter?.start_date ? new Date(dateFilter?.start_date) : null
          }
        />
        <ATMDatePicker
          label="End Date"
          onChange={(endDateValue) =>
            dispatch(
              setDateFilter({
                ...dateFilter,
                end_date: endDateValue
                  ? moment(endDateValue)?.format("yyyy-MM-DD")
                  : "",
              })
            )
          }
          value={dateFilter?.end_date ? new Date(dateFilter?.end_date) : null}
        />
        <ATMLoadingButton
          className="w-[120px]"
          onClick={() => {
            dispatch(
              setDateFilter({
                start_date: "",
                end_date: "",
              })
            );
          }}
        >
          Clear Filter
        </ATMLoadingButton>
      </div>
      {rows?.length ? (
        <div className="grid grid-cols-3 gap-10 py-3">
          {/* Table */}
          <div className="col-span-1 overflow-auto flex flex-col px-2 md:px-4">
            <div className="pb-2">
              <span className="font-medium">Total Duration</span> -{" "}
              {getTimeInHours(getTotalDuration)} ({getTotalDuration} min)
            </div>
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "min-h-[50px]"}
              isLoading={isTableLoading}
            />
          </div>
          <div className="col-span-2">
            <Bar options={options} data={data} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[70vh]">
          No Logs Found
        </div>
      )}
    </div>
  );
};

export default ChangeRequestLog;
