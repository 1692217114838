import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";

import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import UpcomingListFilterFormDialogWrapper from "./UpcomingPaymentFilterFormCard/UpcomingListFilterFormDialogWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

type Props = {
  columns: columnTypes[];
  rows: any[];
  ledgerData: any;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const UpcomingPaymentsListing = ({
  columns,
  rows,
  ledgerData,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [totalamountview, setTotalAmountView] = useState(false);
  const [isOpenFilterDialog, setIsOpenFilterDialog] = useState<boolean>(false);

  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex justify-between">
          <div className="w-full">
            <ATMPageHeader
              pageTitle="Upcoming Payments"
              hideSearchBox
              hideAddButton={true}
            />
          </div>

          <div className="flex items-center w-full gap-2 px-4 md:justify-end">
            <div
              className="cursor-pointer"
              onClick={() => setTotalAmountView(!totalamountview)}
            >
              {" "}
              {totalamountview ? (
                <FaSortAmountUp />
              ) : (
                <FaSortAmountDownAlt />
              )}{" "}
            </div>
            {/* Search Box */}
            <div className="xs:w-full md:max-w-[300px]">
              <ATMInputAdormant
                name=""
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                adormant={<BiSearch />}
                adormantProps={{
                  position: "start",
                  extraClasses: "bg-white border-0",
                }}
                inputProps={{ className: "bg-white" }}
                placeholder="Search..."
              />
            </div>

            {/* Filter Button */} 
            <ATMLoadingButton
                    variant="tonal"
                    onClick={() => setIsOpenFilterDialog(true)}
                  >
                    <div className="flex items-center gap-2">
                      <IoFilter />
                      Filters
                    </div>
                  </ATMLoadingButton>
          
            {/* Ticket Filter Dialog Wrapper */}
            <UpcomingListFilterFormDialogWrapper
              open={isOpenFilterDialog}
              onClose={() => setIsOpenFilterDialog(false)}
            />
          </div>
        </div>
        {totalamountview && (
          <div className="grid gap-4 px-4 mt-2 mb-3 xl:grid-cols-5 sm:grid-cols-3">
            <div className="flex flex-col items-center justify-center py-1 shadow-md bg-gray-50 hover:bg-hover">
              <span className="text-lg font-semibold text-primary-main">
                Total Expense{" "}
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalExpense || 0}
              </span>
            </div>
            <div className="flex flex-col items-center justify-center py-1 shadow-md bg-gray-50 hover:bg-hover">
              <span className="text-lg font-semibold text-primary-main">
                Total Milestone{" "}
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalMilestone || 0}
              </span>
            </div>
            <div className="flex flex-col items-center justify-center py-1 shadow-md bg-gray-50 hover:bg-hover">
              <span className="text-lg font-semibold text-primary-main">
                Total Payment{" "}
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalPayment || 0}
              </span>
            </div>
            <div className="flex flex-col items-center justify-center py-1 shadow-md bg-gray-50 hover:bg-hover">
              <span className="text-lg font-semibold text-primary-main">
                Total Amount <span className="text-[10px]">(with GST) </span>{" "}
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalLedgerAmountWithGst || 0}
              </span>
            </div>
            <div className="flex flex-col items-center justify-center py-1 shadow-md bg-gray-50 hover:bg-hover">
              <span className="text-lg font-semibold text-primary-main">
                Total Amount <span className="text-[10px]">(without GST) </span>
              </span>
              <span className="text-[18px] font-medium">
                ₹{ledgerData?.totalLedgerAmountWithOutGst || 0}
              </span>
            </div>
          </div>
        )}
        {/* Table */}
        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-col overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "h-[50px]"}
              isLoading={isTableLoading}
            />
          </div>

          <div>
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingPaymentsListing;
