import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { AddCommentFormValues } from "./AddCommentFormWrapper";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

type Props = {
  formikProps: FormikProps<AddCommentFormValues>;
};

const AddCommentForm = ({ formikProps }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <div className="w-full pb-5 pt-2 bg-white custom-flex">
      {/* Text Area */}

      {/* Description */}
      <div className="z-0 border rounded-md">
        <MOLMarkdownEditor
          label=""
          value={values.comment}
          placeholder="Write comment or drag your files here...."
          onChange={(newValue: any) => {
            setFieldValue("comment", newValue);
          }}
        />
      </div>

      <div className="flex justify-end pt-2">
        <ATMLoadingButton
          className="md:w-40 w-26 px-6 md:px-0"
          disabled={values.comment?.length ? false : true}
          isLoading={isSubmitting}
          type="submit"
        >
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default AddCommentForm;
