import apiSlice from "./ApiSlice";

export const meetingApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Meetings
    getAllMeetings: builder.query({
      providesTags: ["meeting"],
      query: () => {
        return {
          url: "/meeting",
          method: "GET",
        };
      },
    }),

    // Get Metting With Pagination
    getMeetings: builder.query({
      providesTags: ["meeting"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/meeting",
          method: "POST",
          body,
        };
      },
    }),

    // Get Module by Id
    getMeetingById: builder.query({
      providesTags: ["meeting"],
      query: (id) => {
        return {
          url: `/meeting/${id}`,
          method: "GET",
        };
      },
    }),


    getTaskByMeetingId: builder.query({
      providesTags: ["meeting"],
      query: (id) => {
        return {
          url: `/meeting/in-progress-tasks/${id}`,
          method: "GET",
        };
      },
    }),

    // Add
    addMeeting: builder.mutation({
      invalidatesTags: ["meeting"],
      query: (body) => {
        return {
          url: "/meeting/add",
          method: "POST",
          body,
        };
      },
    }),



    // Update
    updateMeeting: builder.mutation({
      invalidatesTags: ["meeting"],
      query: ({ id, body }) => {
        return {
          url: `/meeting/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Delete
    deleteMeeting: builder.mutation({
      invalidatesTags: ["meeting"],
      query: (id: string) => {
        return {
          url: `/meeting/${id}`,
          method: "DELETE",
        };
      },
    }),

    // Update Attendees
    updateAttendeesMeeting: builder.mutation({
      invalidatesTags: ["meeting"],
      query: ({ id, body }) => {
        return {
          url: `/meeting/attendees/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Mark as Done
    meetingMarkAsDone: builder.mutation({
      invalidatesTags: ["meeting"],
      query: ({ id, body }) => {
        return {
          url: `/meeting/mark-as-done/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    /*** NOTES ***/
    // Get Notes With Pagination
    getMeetingNotes: builder.query({
      providesTags: ["meeting", "tickets"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/notes",
          method: "POST",
          body,
        };
      },
    }),

    // Add Meeting Notes
    addMeetingNotes: builder.mutation({
      invalidatesTags: ["meeting"],
      query: (body) => {
        return {
          url: "/notes/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get Notes by noteId
    getMeetingNotesByNoteId: builder.query({
      providesTags: ["meeting"],
      query: (id) => {
        return {
          url: `/notes/${id}`,
          method: "GET",
        };
      },
    }),

    // Update Notes of meeting
    updateNoteMeeting: builder.mutation({
      invalidatesTags: ["meeting"],
      query: ({ id, body }) => {
        return {
          url: `/notes/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    joinMeetingbyId: builder.mutation({
      invalidatesTags: ["meeting"],
      query: ({ id, body }) => {
        return {
          url: `/meeting/join-leave/${id}`,
          method: "PUT",
          body,
        };
      },
    }),


    startInternalMeeting: builder.mutation({
      invalidatesTags: ["meeting", "dashboard", "task"],
      query: ({ id, body }) => {
        return {
          url: `/meeting/${id}/start`,
          method: "PUT",
          body
        };
      },
    }),

    // Delete Meeting Notes
    deleteMeetingNotes: builder.mutation({
      invalidatesTags: ["meeting"],
      query: (id: string) => {
        return {
          url: `/notes/${id}`,
          method: "DELETE",
        };
      },
    }),

    // Get Metting Notes Project Wise
    getMeetingsNotesProjectWise: builder.query({
      providesTags: ["meeting"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/notes/project-wise",
          method: "POST",
          body,
        };
      },
    }),

    // Quick Meeting Add
    addQuickMeeting: builder.mutation({
      invalidatesTags: ["meeting"],
      query: (body) => {
        return {
          url: "/meeting/quick-add",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllMeetingsQuery,
  useGetMeetingsQuery,
  useGetMeetingByIdQuery,
  useAddMeetingMutation,
  useUpdateMeetingMutation,
  useGetTaskByMeetingIdQuery,
  useStartInternalMeetingMutation,
  useDeleteMeetingMutation,
  useUpdateAttendeesMeetingMutation,
  useMeetingMarkAsDoneMutation,
  useJoinMeetingbyIdMutation,
  useAddQuickMeetingMutation,
  /***** Meeting Notes *****/
  useGetMeetingNotesQuery,
  useAddMeetingNotesMutation,
  useGetMeetingNotesByNoteIdQuery,
  useUpdateNoteMeetingMutation,
  useDeleteMeetingNotesMutation,
  useGetMeetingsNotesProjectWiseQuery
} = meetingApi;
