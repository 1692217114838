import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setRecurranceType } from "src/redux/slices/RecurringPaymentSlice";
import { AppDispatch, RootState } from "src/redux/store";
import RecurringPaymentListFilterFormDialog from "./RecurringPaymentListFilterFormDialog";

type Props = {
  open: boolean;
  onClose: () => void;
};

const RecurringPaymentListFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const meetingState = useSelector((state: RootState) => state.recurringPayment);
  const { recurranceType } = meetingState;

  const initialValues: any = {
    recurranceType: {
      label:recurranceType ,
      value : recurranceType
    }
  };

  // Submit Handler
  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    setSubmitting(false);

    // project filter dispatch
    dispatch(setRecurranceType(values.recurranceType?.value));

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<any>) => {
    // created date filter dispatch

    // project filter dispatch
    await dispatch(setRecurranceType([]));

    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <RecurringPaymentListFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RecurringPaymentListFilterFormDialogWrapper;
