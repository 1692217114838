import React  from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { ForwardIncidentValues } from "./ForwardIncidentWrapper";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import { useGetPointerAssignUserQuery } from "src/services/UserService";

type Props = {
  formikProps: FormikProps<ForwardIncidentValues>;
  onClose: () => void;
  teamMemberId: string;
};

const ForwardIncident = ({ formikProps, onClose, teamMemberId }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const userDataLJson = localStorage.getItem("userData");
  const userData = JSON?.parse(userDataLJson as string);

  const {
    data: assignUserData,
    isLoading: assignUserDataIsLoading,
    isFetching: assignUserDataIsFetching,
  } = useGetPointerAssignUserQuery(userData?.userId, {
    skip: !userData?.userId,
  });
 
  const assignUserOption = assignUserData?.data?.assigned_users
    ?.filter((el: any) => el?._id !== teamMemberId)
    ?.map((ele: any) => {
      return {
        label: ele?.name,
        value: ele?._id,
      };
    });

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">Forward Incident</div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Field Label */}
        <div className="">
          <ATMMultiSelect
            name="assignName"
            required
            label="Assign"
            value={values?.assignName}
            onBlur={handleBlur}
            isLoading={assignUserDataIsLoading || assignUserDataIsFetching}
            placeholder="Select Assign"
            options={assignUserOption}
            onChange={(newValue) => {
              setFieldValue("assignName", newValue);
            }}
          />
        </div>
        <div className="">
          <ATMTextArea
            required
            name="reason"
            value={values?.reason}
            onChange={(e) => setFieldValue("reason", e)}
            label="Remark"
            placeholder="Enter Remark"
            className="rounded"
          />
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default ForwardIncident;
