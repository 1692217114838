// |-- Built-in Dependencies --|
import React, { useState } from "react";

// |-- External Dependencies --|
import { BiSearchAlt2 } from "react-icons/bi";
import { IconType } from "react-icons";

// |-- Internal Dependencies --|
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import { useParams } from "react-router-dom";
import { IoFilter } from "react-icons/io5";
import TicketListFilterFormDialogWrapper from "../../List/TicketFilterFormCard/TicketListFilterFormDialogWrapper";
import { Tooltip } from "@mui/material";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

// |-- Types --|
type ViewLayoutPropTypes = {
  leftbar?: boolean;
  infoCard?: React.ReactNode;
  actionIcons?: {
    icon: IconType;
    onClick: () => void;
  }[];
  listData?: any[];
  tabs?: {
    label: string;
    icon: IconType;
    path: string;
  }[];
  searchValue?: string;
  onSearch?: (value: string) => void;
  onClickViewMore?: () => void;
  onClickChangeRequestView?: (TICKET_ID: string) => void;
  hideViewMore?: boolean;
  children?: any;
};

const StatusComponent = ({ status }: any) => {
  const getStatusColor = (status: any) => {
    switch (status) {
      case "OPEN":
        return {
          label: "Open",
          className: "bg-green-500",
        };
      case "CLOSE":
        return {
          label: "Close",
          className: "bg-red-500",
        };
      case "IN_PROGRESS":
        return {
          label: "In Progress",
          className: "bg-blue-500",
        };
      case "HOLD":
        return {
          label: "Hold",
          className: "bg-slate-500",
        };
      case "DOUBT":
        return {
          label: "Doubt",
          className: "bg-orange-500",
        };
      default:
        return {
          label: "",
          className: "bg-gray-500",
        };
    }
  };

  const statusColor = getStatusColor(status);

  return (
    <div
      className={`px-2 py-1 text-[10px] rounded-md text-white font-semibold , ${statusColor?.className}`}
    >
      {statusColor?.label}
    </div>
  );
};

const TicketSideBarLayoutSideBarLayout = ({
  leftbar = true,
  infoCard,
  listData,
  tabs,
  searchValue = "",
  onSearch = () => {},
  onClickChangeRequestView = () => {},
  onClickViewMore,
  hideViewMore = false,
  children,
}: ViewLayoutPropTypes) => {
  // const navigate = useNavigate()
  const { ticketId: TICKET_ID } = useParams();
  const [isOpenTicketFilterDialog, setIsOpenTicketFilterDialog] =
    useState<boolean>(false);

  return (
    <>
      <div className="h-[calc(100vh-50px)] border-r">
        <div className="w-full flex  h-[calc(100%)] bg-white">
          {/* Left Section Side Bar */}
          <div className="md:block hidden">
            {leftbar === true && (
              <div className="w-[250px]  h-full flex flex-col">
                {/* Info Card */}
                <div className=" w-full">{infoCard}</div>

                {/* Search Bar */}

                <div className="px-2 py-2 flex items-center justify-center ">
                  <ATMInputAdormant
                    name=""
                    value={searchValue}
                    placeholder="Search here..."
                    className="h-[40px] shadow-sm border-[1px] focus:border-black"
                    adormant={
                      <BiSearchAlt2 className="text-slate-400 text-xl " />
                    }
                    adormantProps={{
                      position: "start",
                      extraClasses: "bg-white border-0 ",
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />
                  {/* Filter Button */}
                  <ATMLoadingButton
                    variant="tonal"
                    onClick={() => setIsOpenTicketFilterDialog(true)}
                  >
                    <div className="flex items-center gap-2">
                      <IoFilter />
                      Filters
                    </div>
                  </ATMLoadingButton>

                  {/* Filter Form */}
                  <TicketListFilterFormDialogWrapper
                    open={isOpenTicketFilterDialog}
                    onClose={() => setIsOpenTicketFilterDialog(false)}
                  />
                </div>

                {/* List */}
                <div className="grow overflow-auto">
                  <div className="px-[8px] flex flex-col min-w-[100%] overflow-y-auto">
                    {listData?.map((ele: any) => {
                      return (
                        <div
                          key={ele?._id}
                          className={`shadow-sm mb-2 border-[1px] border-slate-100 rounded cursor-pointer relative ${
                            ele?._id === TICKET_ID ? "bg-blue-300" : "bg-white"
                          }`}
                          onClick={() => onClickChangeRequestView(ele?._id)}
                        >
                          <div className="flex gap-x-2 py-2 px-2">
                            <div className="bg-[#ebebeb] text-xs rounded-md px-1 py-[1px]">
                              <div className="text-xs text-black">
                                {ele?.projectName &&
                                  `${
                                    ele?.projectName ? ele?.projectName : ""
                                  }  ${
                                    ele?.moduleLabel
                                      ? " | " + ele?.moduleLabel + " | "
                                      : ""
                                  }  ${
                                    ele?.featureLabel ? ele?.featureLabel : ""
                                  }`}
                              </div>
                            </div>
                          </div>

                          <div className="p-4" style={{ userSelect: "text" }}>
                            <Tooltip title={ele?.title}>
                              <h3 className="w-full overflow-hidden truncate text-md min-w-[100%] hover:underline font-medium">
                                {ele?.title}
                              </h3>
                            </Tooltip>

                            <div className="flex text-xs  justify-between mt-1 font-semibold text-red-500 ">
                              <span className="task-id mt-1">
                                #{ele?.ticketNumber}
                              </span>
                              <div className="flex justify-end">
                                {ele?.unReadMessages ? (
                                  <div className="bg-[#041618] rounded-full font-bold h-[23px] w-[23px] text-[10px] flex justify-center items-center -right-[6px] -top-[1px] text-white">
                                    {ele?.unReadMessages}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="flex justify-end absolute bottom-0 right-0">
                              <StatusComponent
                                status={ele?.clientSideTicketStatus}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* View more button */}
                    {hideViewMore && (
                      <span
                        onClick={onClickViewMore}
                        className="text-black text-center text-sm cursor-pointer hover:underline select-none"
                      >
                        View More
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketSideBarLayoutSideBarLayout;
