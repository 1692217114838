import React from "react";
import TicketStatusDialog from "./TicketStatusDialog";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { useChangeClientSideTicketStatusMutation } from "src/services/TicketServices";

type Props = {
  onClose: any;
  ticketId: any;
  clientSideTicketStatusValue: any;
};

const TicketStatusDialogWrapper = ({
  onClose,
  ticketId,
  clientSideTicketStatusValue,
}: Props) => {
  const [updateTicketStatus] = useChangeClientSideTicketStatusMutation();

  const initialValues = {
    status: {
      label: clientSideTicketStatusValue,
      value: clientSideTicketStatusValue,
    },
  };

  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    const { status, ...rest } = values;
    const formattedValues = {
      ...rest,
      status: status?.value,
    };
    updateTicketStatus({
      ticketId: ticketId,
      status: formattedValues?.status,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <TicketStatusDialog formikProps={formikProps} onClose={onClose} />
        </Form>
      )}
    </Formik>
  );
};

export default TicketStatusDialogWrapper;
