import { uuidv4 } from "@mdxeditor/editor";
import { CircularProgress } from "@mui/material";
import { FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  TbChevronDown,
  TbChevronRight,
  TbFocusCentered,
  TbGrid3X3,
  TbLayoutGrid,
  TbTrash,
} from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ATMIconButton from "src/components/UI/atoms/ATMIconButton/ATMIconButton";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import {
  useAddRequirementGatheringFormMutation,
  useDeleteStoryMutation,
  useGetStoryNavigationQuery,
  useUpdateRequirementGatheringFormMutation,
  useUpdateStoryNavigationMutation,
} from "src/services/RequirementGatheringService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { FeaturesListingValues } from "./FeaturesListingWrapper";
import PrintScreenDetails from "./PrintScreenDetails/PrintScreenDeatails";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

type Props = {
  formikProps: FormikProps<FeaturesListingValues>;
  users: any;
  features: any;
  requirements: any;
  activeId: any;
  isLoading: boolean;
  requirementId: any;
  sideNavLayoutIsLoading: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Select = ({ options, onChange, value, placeholder }: any) => {
  return (
    <select
      className="text-lg font-medium rounded w-fit focus:outline-none focus:border-blue-500 "
      onChange={onChange}
      value={value}
    >
      <option value="" disabled hidden className="!text-slate-400 text-lg">
        {placeholder}
      </option>
      {options?.map((option: any) => (
        <option key={option?.value} value={option?.value}>
          {option?.label}
        </option>
      ))}
    </select>
  );
};

const buildTree = (nodes: any[]): any[] => {
  const nodeMap: Record<string, any> = {};
  const tree: any[] = [];

  nodes?.forEach((node) => {
    nodeMap[node?._id] = { ...node, children: [] };
  });

  nodes?.forEach((node) => {
    if (node.parentId && nodeMap[node.parentId]) {
      const parentNode = nodeMap[node.parentId]!;
      parentNode.children = [
        ...(parentNode.children || []),
        nodeMap[node?._id],
      ];
    } else {
      tree.push(nodeMap[node?._id]);
    }
  });

  return tree;
};

const FeaturesListing = ({
  formikProps,
  users,
  features,
  isLoading,
  activeId,
  requirementId,
  requirements,
  sideNavLayoutIsLoading,
}: Props) => {
  const navigate = useNavigate();
  const { values, setFieldValue, isSubmitting, setSubmitting, resetForm } =
    formikProps;
  const [isEditing, setIsEditing] = useState(false);
  const [updatedStoryNavigation] = useUpdateStoryNavigationMutation();
  const [addRequirement] = useAddRequirementGatheringFormMutation();
  const [updateRequirement] = useUpdateRequirementGatheringFormMutation();
  const [deleteRequirement] = useDeleteStoryMutation();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [inputValue, setInputValue] = useState<any>(
    selectedItem?.groupName || ""
  );
  const {
    data: storyNavigation,
    isFetching,
    isLoading: storyNavigationLoading,
  } = useGetStoryNavigationQuery({
    limit: 10,
    searchValue: "",
    params: ["nav"],
    page: 0,
    filterBy: [
      {
        fieldName: "requirementGatheringId",
        value: requirementId,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  const [storyNavigationData, setStoryNavigationData] = useState<any>(null);

  useEffect(() => {
    if (!storyNavigationLoading && !isFetching) {
      setStoryNavigationData(storyNavigation?.data || null);
    }
  }, [storyNavigationLoading, isFetching, storyNavigation]);

  //
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>(
    {}
  );

  // useEffect(() => {
  //   const handleKeyDown = (event: any) => {
  //     if (event.ctrlKey && event.key === "s") {
  //       handleSubmit();
  //       event.preventDefault();
  //     }
  //     if (event.ctrlKey && event.key === "a") {
  //       navigate(`/requirement-gathering/view/${requirementId}/features`);
  //       setFieldValue("user", "");
  //       setFieldValue("label", "");
  //       setFieldValue("platform", "");
  //       setFieldValue("description", "");
  //       event.preventDefault(); // Prevent the default select all action
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const userOptions = users?.map((el: any) => {
    return {
      label: el?.user,
      value: el?._id,
    };
  });

  const featuresOptions = features?.map((el: any) => {
    return {
      label: el?.platformName,
      value: el?._id,
    };
  });

  //Group
  const handleAddGroup = (platformId: any, parentId?: any) => {
    // Create a new group
    const newGroup = {
      _id: uuidv4(),
      groupName: "custom group",
      customGroup: true,
      nodeType: "group",
      parentId: parentId || "",
    };

    // Fetch existing story navigation data
    const existingGroups = storyNavigation?.data?.[0]?.nav || [];

    // Add the new group to the existing groups
    const updatedGroups = [...existingGroups, newGroup];

    // Prepare the updated data
    const updatedGroup = {
      nav: updatedGroups,
    };

    // Call the mutation to update the story navigation
    updatedStoryNavigation({
      Id: requirementId,
      body: updatedGroup,
    }).then((updateRes: any) => {
      if (updateRes?.error) {
        showToast("error", updateRes?.error?.data?.message);
      } else {
        showToast("success", updateRes?.data?.message);
        setSelectedItem(newGroup);
        setIsEditing(true);
        handleToggleCollapse(parentId, true);
      }
    });
  };
  const handleUpdateGroup = (item: any) => {
    const updatedGroupData = {
      nav: storyNavigationData?.[0]?.nav?.map((group: any) =>
        group?._id === item?._id ? { ...group, groupName: inputValue } : group
      ),
    };

    // Call the mutation to update the story navigation
    updatedStoryNavigation({
      Id: requirementId,
      body: updatedGroupData,
    }).then((updateRes: any) => {
      if (updateRes?.error) {
        showToast("error", updateRes?.error?.data?.message);
      } else {
        showToast("success", updateRes?.data?.message);
        setIsEditing(false);
        setInputValue("");
      }
    });
  };

  const handleDeletegroup = (groupId: any) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this group?",
      icon: "question",
      showCancelButton: true,
      next: (result: any) => {
        if (result?.isConfirmed) {
          const updatedPlatformData = {
            nav: storyNavigationData?.[0]?.nav?.filter(
              (group: any) => group?._id !== groupId
            ),
          };

          updatedStoryNavigation({
            Id: requirementId,
            body: updatedPlatformData,
          }).then((updateRes: any) => {
            if (updateRes?.error) {
              showToast("error", updateRes?.error?.data?.message);
            } else {
              showToast("success", updateRes?.data?.message);
            }
          });
        }
      },
    });
  };

  //story/requirement
  const handleAddRequirement = (parentId?: any) => {
    // Call the mutation to add the requirement
    addRequirement({
      requirementGatheringId: requirementId,
      reqUserId: values?.user?.value || users?.[0]?._id,
      reqPlatformId: values?.platform?.value || features?.[0]?._id,
      action: "action",
      description: "description",
      parentId: parentId || "",
    }).then((addReqRes: any) => {
      if (addReqRes?.error) {
        showToast("error", addReqRes?.error?.data?.message);
      } else {
        showToast("success", addReqRes?.data?.message);

        // Fetch existing nav data
        const existingNav = storyNavigation?.data?.[0]?.nav || [];

        // Add the new requirement to the nav array
        const updatedNav = [
          ...existingNav,
          {
            ...addReqRes?.data?.data, // Spread the newly added requirement data
          },
        ];

        // Update the story navigation with the new nav data
        updatedStoryNavigation({
          Id: requirementId,
          body: {
            nav: updatedNav,
          },
        }).then((updateNavRes: any) => {
          if (updateNavRes?.error) {
            showToast("error", updateNavRes?.error?.data?.message);
          } else {
            showToast("success", updateNavRes?.data?.message);
            const newItem = addReqRes?.data?.data;
            setSelectedItem(newItem);
            navigate(
              `/requirement-gathering/view/${requirementId}/features?id=${newItem?._id}`
            );
            handleToggleCollapse(parentId, true);
          }
        });
      }
    });
  };

  const handleDeleteRequirement = (id: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this requirement?",
      icon: "question",
      showCancelButton: true,
      next: (result: any) => {
        if (result?.isConfirmed) {
          deleteRequirement(id).then((res: any) => {
            if (res?.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);

              const updatedStory = storyNavigationData?.[0]?.nav?.filter(
                (story: any) => story?._id !== id
              );

              const updatedStoryNavigationData = {
                nav: updatedStory,
              };

              updatedStoryNavigation({
                Id: requirementId,
                body: updatedStoryNavigationData,
              }).then((updateRes: any) => {
                if (updateRes?.error) {
                  showToast("error", updateRes?.error?.data?.message);
                } else {
                  showToast("success", updateRes?.data?.message);
                }
              });
            }
          });
        }
      },
    });
  };

  const handleToggleCollapse = (nodeId: string, payload: boolean) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [nodeId]: payload,
    }));
  };

  const NavItem = ({ item, level = 0 }: any) => {
    const isExpanded = expandedNodes[item?._id];
    return (
      <div
        className={`${level === 0 ? "" : "pl-3 w-full border-2-r border-black"}`}
      >
        <div
          onClick={() => {
            setExpandedNodes((prevState) => ({
              ...prevState,
              [item?._id]: !prevState[item?._id],
            }));
            setSelectedItem(item);
          }}
          className={`flex items-center justify-between w-full px-2 text-xs hover:bg-gray-3 cursor-pointer group/navItem relative ${
            item?._id === selectedItem?._id ? "bg-gray-3" : ""
          }`}
        >
          <div className="flex items-center flex-1 gap-2 text-sm">
            {item.children && item.children.length > 0 ? (
              <div className="rounded-md cursor-pointer ">
                {isExpanded ? <TbChevronDown /> : <TbChevronRight />}
              </div>
            ) : (
              <div className="invisible">
                <TbChevronDown />
              </div>
            )}

            {/* icons */}
            {item?.groupName ? <TbLayoutGrid /> : <TbGrid3X3 />}

            {/* text */}
            <div className="flex-1 py-2 text-xs">
              {isEditing && selectedItem?._id === item?._id ? (
                <input
                  autoFocus
                  placeholder="Enter group name"
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleUpdateGroup(item);
                    }
                  }}
                  className="outline-none"
                />
              ) : item?.groupName ? (
                <div
                  className="flex items-center flex-1 gap-4 text-xs"
                  onClick={() => {
                    setSelectedItem(null);
                  }}
                >
                  {/* <TbLayoutGrid className="text-sm" /> */}
                  {item?.groupName}
                </div>
              ) : (
                <div
                  className="flex items-center flex-1 gap-4 text-xs"
                  onClick={() => navigate(`?id=${item?._id}`)}
                >
                  {/* <TbGrid3X3 /> */}
                  {item?.reqUserName}
                </div>
              )}
            </div>
          </div>

          {/* //Absolute icons */}
          <span className="absolute invisible p-[2px] text-sm bg-white rounded-lg right-4 custom-shadow group-hover/navItem:visible -top-1/2">
            {item.groupName ? (
              <div className="flex items-center">
                <ATMIconButton
                  tooltipTitle="To add Group cltr + g"
                  variant="text"
                  icon={<TbLayoutGrid />}
                  onClick={() => handleAddGroup(requirementId, item?._id)}
                />
                <ATMIconButton
                  tooltipTitle="To add Functionality cltr + dot"
                  variant="text"
                  icon={<TbFocusCentered />}
                  onClick={() => handleAddRequirement(item?._id)}
                />

                <ATMIconButton
                  disabled={item?.children && item.children.length > 0}
                  variant="text"
                  color="error"
                  icon={<TbTrash />}
                  onClick={() => handleDeletegroup(item?._id)}
                />
              </div>
            ) : (
              <ATMIconButton
                disabled={item?.children && item.children.length > 0}
                variant="text"
                color="error"
                icon={<TbTrash />}
                onClick={() => handleDeleteRequirement(item?._id)}
              />
            )}
          </span>
        </div>
        {isExpanded && item.children && item.children.length > 0 && (
          <div>
            {item.children.map((child: any) => (
              <NavItem key={child?._id} item={child} level={level + 1} />
            ))}
          </div>
        )}
      </div>
    );
  };

  // Handle Submit
  const handleSubmit = () => {
    const formattedValues = {
      requirementGatheringId: requirementId,
      reqUserId: values?.user?.value,
      reqPlatformId: values?.platform?.value,
      action: values?.label,
      description: values?.description,
    };

    updateRequirement({
      Id: activeId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          const resdata = res?.data?.data;
          const updatedGroupData = {
            nav: storyNavigationData?.[0]?.nav?.map((group: any) =>
              group?._id === selectedItem?._id
                ? {
                    ...group,
                    reqUserId: resdata?.reqUserId,
                    description: resdata?.description,
                    reqPlatformId: resdata?.reqPlatformId,
                    reqPlatformName: resdata?.reqPlatformName,
                    requirementGatheringId: resdata?.requirementGatheringId,
                    action: resdata?.action,
                    reqUserName: resdata?.reqUserName,
                  }
                : group
            ),
          };
          // Call the mutation to update the story navigation
          updatedStoryNavigation({
            Id: requirementId,
            body: updatedGroupData,
          }).then((updateRes: any) => {
            if (updateRes?.error) {
              showToast("error", updateRes?.error?.data?.message);
            } else {
              showToast("success", updateRes?.data?.message);
              setIsEditing(false);
              setInputValue("");
            }
          });
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      if (
        (isMac && event.metaKey && event.key === "/") ||
        (!isMac && event.ctrlKey && event.key === "/")
      ) {
        event.preventDefault();
        handleAddRequirement(
          selectedItem?.nodeType === "group"
            ? selectedItem?._id
            : selectedItem?.parentId
        );
      } else if (
        (isMac && event.metaKey && event.key === "g") ||
        (!isMac && event.ctrlKey && event.key === "g")
      ) {
        event.preventDefault();
        handleAddGroup(
          requirementId,
          selectedItem?.nodeType !== "group"
            ? selectedItem?.parentId
            : selectedItem?._id
        );
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAddRequirement, handleAddGroup]);

  const meetingMOMRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => meetingMOMRef?.current,
    documentTitle: `codiotic`,
  });
  return (
    <div className="flex h-full">
      <div className="w-[300px] border-r p-2 h-full">
        <div className="flex items-center justify-between w-full h-[38px]">
          <div className="flex-1">
            <p className="w-full text-xs">Quick Actions</p>
          </div>
          <div className="flex">
            <ATMIconButton
              tooltipTitle="To add Group cltr + g"
              icon={<TbLayoutGrid />}
              variant="text"
              onClick={() =>
                handleAddGroup(
                  requirementId,
                  selectedItem?.nodeType !== "group"
                    ? selectedItem?.parentId
                    : selectedItem?._id
                )
              }
            />
            <ATMIconButton
              tooltipTitle="To add Functionality cltr + /"
              icon={<TbGrid3X3 />}
              variant="text"
              // onClick={() => handleAddRequirement()}
              onClick={() =>
                handleAddRequirement(
                  selectedItem?.nodeType === "group"
                    ? selectedItem?._id
                    : selectedItem?.parentId
                )
              }
            />
          </div>
        </div>
        {buildTree(storyNavigation?.data?.[0]?.nav)?.map((nav: any) => {
          return <NavItem key={nav?._id} item={nav} />;
        })}
      </div>
      <div className="w-full h-full p-4">
        {selectedItem?.nodeType === "group" ? null : isLoading ? (
          <div className="flex items-end justify-center w-full h-60">
            <CircularProgress />{" "}
          </div>
        ) : storyNavigation?.data?.[0]?.nav.length ? (
          <div className="flex flex-col h-full">
            <div className="flex-1 h-full">
              <div className="flex items-center gap-2">
                <div className="mt-2 text-base font-medium text-slate-600">
                  As a
                </div>
                <div className="z-50 custom-select border-b">
                  <ATMSelect
                    name="user"
                    required
                    placeholder="Select User"
                    value={values.user}
                    options={userOptions}
                    onChange={(newValue) => {
                      setFieldValue("user", newValue);
                    }}
                    extraClasses=""
                  />
                </div>

                {values?.user && (
                  <div className="mt-2 text-base font-medium text-slate-600 ">
                    , I can
                  </div>
                )}
                {values?.user && (
                  <div className="!w-fit mt-2 border-b">
                    <ATMTextField
                      name="title"
                      value={values.label}
                      onChange={(e) => setFieldValue("label", e.target.value)}
                      placeholder="Enter Screen Label"
                      className="border-0 outline-none !w-fit outline-white"
                    />
                  </div>
                )}
                {values?.label && (
                  <div className="z-50  custom-select border-b">
                    <ATMSelect
                      name="platform"
                      required
                      placeholder="Select Platform"
                      value={values.platform}
                      options={featuresOptions}
                      onChange={(newValue) => {
                        setFieldValue("platform", newValue);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="z-0 mt-10">
                <MOLMarkdownEditor
                  placeholder="Write description or drag your files here...."
                  value={values?.description}
                  onChange={(newValue: any) =>
                    setFieldValue("description", newValue)
                  }
                  extraClassName="border-0"
                />
              </div>
              <ATMLoadingButton
                variant="text"
                onClick={handlePrint}
                className="absolute text-blue-600 h-7 right-5 top-14 w-36"
              >
                Download PDF
              </ATMLoadingButton>
            </div>
            <div>
              <ATMLoadingButton
                className="flex items-end"
                isLoading={isSubmitting}
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </ATMLoadingButton>
            </div>
          </div>
        ) : null}
      </div>
      <div className="hidden">
        <PrintScreenDetails
          screenRef={meetingMOMRef}
          requirementGatheringData={storyNavigationData || []}
          featuresData={features || []}
          users={users || []}
        />
      </div>
    </div>
  );
};

export default FeaturesListing;
