import { FormikProps } from "formik";
import React from "react";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { FormInitialValues } from "./TeamAttendanceFilterCardWrapper";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};

const TeamAttendanceFilterCard = ({
  open,
  onClose,
  formikProps,
  onReset,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;
  const months = [
    {
      label: "January",
      value: 1,
    },
    {
      label: "February",
      value: 2,
    },
    {
      label: "March",
      value: 3,
    },
    {
      label: "April",
      value: 4,
    },
    {
      label: "May",
      value: 5,
    },
    {
      label: "June",
      value: 6,
    },
    {
      label: "July",
      value: 7,
    },
    {
      label: "August",
      value: 8,
    },
    {
      label: "September",
      value: 9,
    },
    {
      label: "October",
      value: 10,
    },
    {
      label: "November",
      value: 11,
    },
    {
      label: "December",
      value: 12,
    },
  ];
  const years = [];
  for (let year = 2000; year <= 2050; year++) {
    years.push({
      label: year.toString(),
      value: year,
    });
  }
  return (
    <MOLFilterDialog
      title={"Filters"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      clearFilter={onReset}
      handleSubmit={handleSubmit}
      open={open}
    >
      <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">
        {/* Priority */}

        <div>
          <ATMSelect
            label="Month"
            name="month"
            value={values.month}
            placeholder="Select Month"
            options={months}
            onChange={(newValue) => setFieldValue("month", newValue)}
          />
        </div>
        <div>
          <ATMSelect
            label="Year"
            name="year"
            value={values.year}
            placeholder="Select Year"
            options={years}
            onChange={(newValue) => setFieldValue("year", newValue)}
          />
        </div>
      </div>
    </MOLFilterDialog>
  );
};

export default TeamAttendanceFilterCard;
