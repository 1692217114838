import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Form, Formik, FormikHelpers } from 'formik';
import { array, object } from 'yup';
import ForwardIncident from './ForwardIncident';
import { useForwardIncidentMutation } from 'src/services/IncidentService';
import { showToast } from 'src/utils/showToaster';

export type ForwardIncidentValues ={
    assignName : any ;
    reason:any;
}


type Props = {
  onClose: () => void;
  pointerId:string ;
  teamMemberId :string ;
};

const ForwardIncidentWrapper = ({onClose , pointerId , teamMemberId}: Props) => { 

  const [forwardIncident] = useForwardIncidentMutation()

  // Form Initial Values
  const initialValues: ForwardIncidentValues = {
    assignName: '',
    reason:'',
  };

  // Validation Schema
  const validationSchema = object().shape({
    assignName: array()
    .min(1, "Please select member")
    .required("Please select member"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ForwardIncidentValues,
    { setSubmitting, resetForm }: FormikHelpers<ForwardIncidentValues>
  ) => { 
   const formattedValues ={
    forwardReason : values?.reason ,
    forwardTo : values?.assignName ? values?.assignName?.map((el:any)=>el?.value) : []
   } 

    forwardIncident({
      id: pointerId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth='xs' fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <ForwardIncident teamMemberId={teamMemberId} formikProps={formikProps} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ForwardIncidentWrapper;