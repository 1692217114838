import React, { useEffect, useState } from "react";
import TicketTimeAnalysis from "./TicketTimeAnalysis";
import { useGetSingleTicketTimeAnalysisViaIdQuery } from "src/services/TicketServices";
import { useParams } from "react-router-dom";

type Props = {};

const TicketTimeAnalysisWrapper = (props: Props) => {
  // Get the Ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  const [ticketTimeAnalysisData, setTicketTimeAnalysisData] = useState()

  // Get Single Ticket Details
  const { isLoading, isFetching, data, } = useGetSingleTicketTimeAnalysisViaIdQuery(TICKET_ID || "", { skip: !TICKET_ID, });
  // Set Single Ticket Details
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTicketTimeAnalysisData(data?.data || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, data]);

  return (
    <div className="h-full">
          <TicketTimeAnalysis
        ticketTimeAnalysisData={ticketTimeAnalysisData}
      />
    </div>
  );
};

export default TicketTimeAnalysisWrapper;


