import React, { useEffect, useState } from "react";
import ContactListing from "./ContactListing";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems
} from "src/redux/slices/ContactSlice";
import { useGetAllContactsQuery } from "src/services/ContactService";
import AddContactFormWrapper from "../Add/AddContactFormWrapper";
import { MdEdit } from "react-icons/md";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import EditContactFormWrapper from "../Edit/EditContactFormWrapper";

type Props = {};

const ContactListingWrapper = (props: Props) => {
  const dispatch = useDispatch(); 
  const [isOpenAddDialog , setIsOpenAddDialog] = useState(false)
  const [isOpenEditDialog , setIsOpenEditDialog] = useState(false)
  const [selectedId , setSelectedId] = useState('')
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.contact);

  const { data, isLoading, isFetching } = useGetAllContactsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["name"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  }); 

  // Set All contact Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    { field: "name", headerName: "Name", flex: "flex-[1_1_0%]" },
    { field: "email", headerName: "Email", flex: "flex-[1_1_0%]" },
    { field: "mobile", headerName: "Mobile", flex: "flex-[1_1_0%]" },
    {
        field: "action",
        headerName: "Action",
        extraClasses: "min-w-[100px]",
        renderCell: (row: any) => (
          <div>
            <ATMActionMenu
              options={[
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdEdit color="#000000" /> Edit
                    </div>
                  ),
                  onClick: () => {
                    setIsOpenEditDialog(true)
                    setSelectedId(row?._id)
                  },
                },
                // {
                //   label: (
                //     <div className="flex items-center gap-x-2 text-[#991b1b] font-medium">
                //       <MdDelete color="#991b1b" /> Delete
                //     </div>
                //   ),
                //   onClick: () => {
                //     handleDelete(row?._id);
                //   },
                //   // acitonName: UserModuleNameTypes.ACTION_GOAL_DELETE,
                // },
              ]}
            />
          </div>
        ),
      },
  ];

  return (
    <div>
      <ContactListing
        columns={columns}
        rows={items}
        onAddNew={()=>setIsOpenAddDialog(true)}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenAddDialog && <AddContactFormWrapper onClose={()=>{setIsOpenAddDialog(false)}} />}
      {isOpenEditDialog && <EditContactFormWrapper selectedId={selectedId} onClose={()=>{setIsOpenEditDialog(false)}} />}
    </div>
  );
};

export default ContactListingWrapper;
