import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { ProjectListResponse } from "src/models/Project.model";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { ResourceRequestFormValues } from "src/models/ResourceRequest.model";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { useGetProjectWiseMembers } from "src/hooks/useGetProjectWiseMembers";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<ResourceRequestFormValues>;
  onClose: () => void;
  formType: "ADD" | "UPDATE";
  setSaveNextChecked?: any;
  projectItems?: any;
  saveNextChecked?: any;
  resourceRequiredResourceName?: any;
};

const ResourceRequestForm = ({
  formikProps,
  formType,
  onClose,
  setSaveNextChecked,
  resourceRequiredResourceName,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  // get project wise members
  const { allProjectWiseMembers, isDataLoading: isAllCodioticUserLoading } =
    useGetProjectWiseMembers(values?.projectId?.value || null);
  const resourceRequestFromTypeOptions = [
    {
      label: "CLIENT",
      value: "CLIENT",
    },
    {
      label: "CODIOTIC_USER",
      value: "CODIOTIC_USER",
    },
  ];
  return (
    <MOLFormDialog
      title={
        formType === "ADD"
          ? "Add New Resource Request"
          : "Update  Resource Request"
      }
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Project */}
        <div>
          <ATMSelect
            name="projectId"
            label="Project"
            required
            placeholder="Select Project"
            value={values.projectId}
            // disabled={projectItems?.length}
            isLoading={isAllProjectsDataLoading}
            options={allProjects?.map((ele: ProjectListResponse) => {
              return {
                label: ele?.projectName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("projectId", newValue);
              setFieldValue("requestedFromId", "");
            }}
          />
        </div>

        {/* resourceName Name */}
        <div>
          <ATMTextField
            name="resourceName"
            value={values.resourceName}
            required
            onChange={(e) => setFieldValue("resourceName", e.target.value)}
            label="Resource Name"
            placeholder="Enter Resource Name"
            onBlur={handleBlur}
            disabled={!!resourceRequiredResourceName} // Conditionally set disabled prop
          />
        </div>

        {/* Description */}
        <div className="z-0">
          <MOLMarkdownEditor
            label="Description"
            value={values.description}
            placeholder="Write description or drag your files here...."
            onChange={(newValue: any) => {
              setFieldValue("description", newValue);
            }}
          />
        </div>

        {/* requestedFromType */}
        <div>
          <ATMSelect
            name="requestedFromType"
            label="Requested From"
            required
            placeholder="Select Requested From"
            value={values?.requestedFromType}
            options={resourceRequestFromTypeOptions}
            onChange={(newValue) => {
              setFieldValue("requestedFromType", newValue);
              setFieldValue("requestedFromId", "");
            }}
          />
        </div>

        {/* requestedFromId */}
        {values?.requestedFromType.value === "CODIOTIC_USER" && (
          <div>
            <ATMSelect
              name="requestedFromId"
              label="Requested From"
              required
              placeholder="Select Requested From User"
              value={values?.requestedFromId}
              isLoading={isAllCodioticUserLoading}
              options={allProjectWiseMembers?.members?.map((ele: any) => {
                return {
                  label: ele?.name,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("requestedFromId", newValue);
              }}
            />
          </div>
        )}
        {/* {formType === "ADD" && (
          <div>
            <ATMCheckbox
              label="Save and Next"
              checked={saveNextChecked}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        )}
        <div>
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div> */}
      </div>
    </MOLFormDialog>
  );
};

export default ResourceRequestForm;
