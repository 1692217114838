import { FormikProps } from "formik";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { BusinessFormValues } from "src/models/Business.model";
import { useGetAllIndustryQuery } from "src/services/IndustryService";

type Props = {
  formikProps: FormikProps<BusinessFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
};

const BusinessForm = ({ formType = "ADD", formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;
  const { data: getIndustry, isLoading: isIndustryLoading } =
    useGetAllIndustryQuery("");
  return (
    <MOLFormDialog
      title={
        formType === "ADD" ? "Add Business Model" : "Update Business Model"
      }
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div className="z-10">
          <ATMTextField
            name="modelName"
            required
            label="Model"
            placeholder="Enter Model Name"
            value={values?.modelName}
            onChange={(e: any) => {
              setFieldValue("modelName", e.target.value);
            }}
          />
        </div>

        {/* industry name */}
        <div>
          <ATMMultiSelect
            required
            name="industryName"
            value={values?.industryName}
            label="Industry"
            placeholder="Select Industry Name"
            onChange={(newValue) => {
              setFieldValue("industryName", newValue);
            }}
            options={getIndustry?.data?.map((ele: any) => {
              return {
                label: ele?.industryName,
                value: ele?._id,
              };
            })}
            isLoading={isIndustryLoading}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default BusinessForm;
