import React, { useState } from 'react'
import AddConversation from './AddConversation'
import { Form, Formik, FormikHelpers } from 'formik'
import moment from 'moment';
import { showToast } from 'src/utils/showToaster';
import { useNavigate } from 'react-router-dom';
import { useAddMeetingMutation } from 'src/services/MeetingServices';
import { object } from 'yup';
import { meetingWithOptions } from './AddConversation';
import { communicationTypeOptions } from './AddConversation';

const AddConversationWrapper = () => {

    const [saveNextChecked, setSaveNextChecked] = useState(true);
    const [addConversation] = useAddMeetingMutation();

    const navigate = useNavigate()
    const initialValues: any = {
        projectId: "",
        mode: "CALL",
        meetingTypeId: "",
        scheduledDate: moment(),
        scheduledStartTime: moment(),
        clientUsers: [],
        meetingWith: meetingWithOptions?.find((options) => options?.value === "CLIENT"),
        communicationType: communicationTypeOptions?.find((options) => options?.value === "CALL"),
    }


    // Validation Schema
    const validationSchema = object().shape({
        communicationType: object().required("Communication Type is required"),
        meetingWith: object().required("Meeting with is required"),
        scheduledDate: object().required("Scheduled Date is required"),
        scheduledStartTime: object().required("Scheduled start time is required"),
        projectId: object().required("Project is required"),
        meetingTypeId: object().required("Meeting type is required")

    });


    // Handle Submit
    const handleSubmit = (
        values: any,
        { setSubmitting, resetForm }: FormikHelpers<any>
    ) => {
        const {
            scheduledStartTime,
            meetingWith,
            communicationType,
            scheduledDate,
            meetingTypeId,
            projectId,
            ...rest
        } = values;

        const formatedValues = {
            scheduledStartTime: moment(scheduledStartTime).format(
                "HH:mm:ss"
            ) || "",
            scheduledDate: moment(scheduledDate).format("YYYY-MM-DD"),
            meetingTypeId: meetingTypeId?.value,
            communicationType: communicationType?.value,
            meetingWith: meetingWith?.value,
            requiredThings: ["Mobile"],
            projectId: projectId?.value,
            meetingCreateType: "CONVERSATION",
            ...rest,
        };

        addConversation(formatedValues).then((res: any) => {
            if (res?.error) {
                showToast("error", res?.error?.data?.message);
            } else {
                if (res?.data?.status) {
                    showToast("success", res?.data?.message);
                    resetForm();
                    navigate(`/meeting/${res?.data?.data?._id}/notes`);
                } else {
                    showToast("error", res?.data?.message);
                }
            }
            setSubmitting(false);
        });
    };


    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formikProps) => (
                    <Form>
                        <AddConversation
                            formType="Add"
                            formikProps={formikProps}
                            setSaveNextChecked={setSaveNextChecked}
                            saveNextChecked={saveNextChecked}
                        />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AddConversationWrapper
