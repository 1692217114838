import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { LeadsValues } from "src/models/Leads.model";
import LeadsForm from "../Layouts/LeadsForm";
import moment from "moment";
import useSocialMedias from "src/hooks/useSocialMedias";
import { useAddLeadsMutation } from "src/services/LeadsService";
import { showToast } from "src/utils/showToaster";
import { useNavigate } from "react-router-dom";

const AddLeadsFormWrapper = () => {
  const { SocailMedia: socialMedias } = useSocialMedias();
  const [addLeads] = useAddLeadsMutation();

  const navigate = useNavigate()

  const socialMediaIntialValue = socialMedias.map((social: any) => {
    return {
      mediaName: social?.socialMediaName,
      mediaId: social?._id,
      value: "",
    };
  });

  // Form Initial Values
  const initialValues: LeadsValues = {
    assigned_to: "",
    name: "",
    mobile: "",
    email: "",
    alternate_1: "",
    alternate_2: "",
    organization: "",
    country: {},
    state: {},
    district: {},
    summary_note: "",
    next_followUp_date: "",
    next_followup_time: null,
    leads_label: "",
    channel: "",
    campaign: "",
    domains: "",
    socialMedias: socialMediaIntialValue,
  };

  // Validation Schema
  const validationSchema = object().shape({
    name: string(),
    email: string().email(),
    mobile: string().trim().required("Please enter mobile")
  });

  // Handle Submit
  const handleSubmit = (
    values: LeadsValues,
    { setSubmitting, resetForm }: FormikHelpers<LeadsValues>
  ) => {

    const formatedValues = {
      name: values?.name,
      mobile: values?.mobile,
      email: values?.email,
      altNumber1: values?.alternate_1,
      altNumber2: values?.alternate_2,
      campaignId: values?.campaign?.value,
      channelId: values?.channel?.value,
      assignedTo: values?.assigned_to?.value,
      countryId: values?.country?.value,
      cityId: values?.district?.value,
      stateId: values?.state?.value,
      domainId: values?.domains?.value,
      leadLabelName: values?.leads_label?.value,
      nextFollowupDate: values?.next_followUp_date
        ? moment(values?.next_followUp_date).format("yyyy-MM-DD")
        : '',
      nextFollowupTime: values?.next_followup_time
        ? moment(values?.next_followup_time).format("hh:mm A")
        : '',
      summaryNote: values?.summary_note,
      organizationName: values?.organization,
      socialMediaId: values?.socialMedias?.map((el:any)=> {
        return{
          mediaId : el?.mediaId ,
          value :el?.value
        }
      } ),
    };
    addLeads(formatedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message); 
          navigate('/sales&marketing/leads')
          resetForm()
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });

  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <LeadsForm formikProps={formikProps} />
        </Form>
      )}
    </Formik>
  );
};

export default AddLeadsFormWrapper;
