import { useState, useEffect } from "react";
import { useGetProjectWiseMembersQuery } from "src/services/TaskService";

export const useGetProjectWiseMembers = (projectId: string | null) => {
  const [allProjectWiseMembers, setAllProjectWiseMembers] = useState<any>();
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetProjectWiseMembersQuery(
    projectId || null,
    {
      skip: !projectId,
    }
  );

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setAllProjectWiseMembers(result?.[0] as any);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { allProjectWiseMembers, isDataLoading };
};
