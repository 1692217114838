import React from "react";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import moment from "moment";

type Props = {
  rows: any[];
  meetingData: any;
  ref?: any;
};

const MeetingMomTemplate = React.forwardRef(
  ({ rows, meetingData }: Props, ref: any) => {

    return (
      <div ref={ref} className="p-4">
        <div className="h-auto mt-8">
          {/* MOM Project name & meeting held date*/}
          <div className="pl-8">
            <div className="flex justify-between">
              <div className="text-lg font-semibold text-[20px] capitalize">
                {meetingData?.projectName}
              </div>
            </div>

            {/* Meeting Agenda */}
            <div className="flex mt-4 gap-x-4">
              <div>
                <span className="text-sm font-semibold">Agenda</span>
              </div>
              {" - "}
              <div>
                {meetingData?.agenda?.map((ele: any, ind: number) => {
                  return (
                    <div className="flex gap-x-2">
                      <span>{ind + 1}.</span>
                      <ATMMarkdownContainer markdown={ele} />
                    </div>
                  );
                })}
              </div>
              {/* <div>
                {meetingData?.agenda?.map((ele: any) => (
                  <>
                    <ATMMarkdownContainer markdown={ele} />
                  </>
                ))}
              </div> */}
            </div>

            {/* Client User & Codiotic User */}
            <div className="flex mt-2 gap-x-4">
              <div>
                <span className="text-sm font-semibold">Attendees</span>
              </div>
              {" - "}
              <div className="capitalize">
                {meetingData?.actualClientUsers?.map(
                  (ele: string) => ele + " , "
                )}
              </div>
            </div>
            <div className="flex mt-2 gap-x-4">
              <div>
                <span className="text-sm font-semibold">Company</span>
              </div>
              {" - "}
              <div className="capitalize">
                {meetingData?.actualCodioticUsers?.map(
                  (ele: any) => ele?.name + " , "
                )}
              </div>
            </div>

            {/* Meeting Location */}
            <div className="flex mt-2 mb-2 gap-x-4">
              <div>
                <span className="text-sm font-semibold">Location</span>
              </div>
              {" - "}
              <div className="capitalize">
                {meetingData?.mode === "ONLINE"
                  ? "ONLINE"
                  : meetingData?.address}
              </div>
            </div>
            {/* Time */}
            <div className="flex mb-6 gap-x-4">
              <div>
                <span className="text-sm font-semibold">Date Time</span>
              </div>
              {" - "}
              <div>
                {meetingData?.actualStartTime ? (
                  <span className="text-sm">
                    {moment(meetingData?.actualStartTime).format("DD MMM YYYY")}
                    {", "}
                    {moment(meetingData?.actualStartTime).format("h:mm A")}{" "}
                    {meetingData?.actualEndTime
                      ? "to " +
                      moment(meetingData?.actualEndTime).format("h:mm A")
                      : null}
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          {/* <Divider /> */}

          {rows?.length
            ? rows?.map((ele: any, ind: number) => {
              return (
                <div className="flex flex-1 mt-2 gap-x-2  shadow-md p-2">
                  <span className="text-[17px] font-bold mt-1">
                    {ind + 1}
                    {"."}
                  </span>
                  <div className="px-4 py-1">
                    <div className="flex gap-1">
                      {ele?.ticketNumber && (
                        <p className="font-medium text-slate-700 text-sm">
                          #{ele?.ticketNumber}
                        </p>
                      )}
                      -
                      {ele?.ticketTitle && (
                        <p className="font-medium text-slate-700  text-sm">
                          [{ele?.ticketTitle}]
                        </p>
                      )}
                    </div>
                    {ele?.title && (
                      <p className="font-medium text-slate-700 capitalize text-sm">
                        {ele?.title}
                      </p>
                    )}
                    <ATMMarkdownContainer markdown={ele?.notes || "-"} />{" "}
                    {ele?.clientUserId && (
                      <span className="flex text-xs font-bold opacity-90 gap-x-2">
                        ({ele?.clientUserId})
                      </span>
                    )}
                  </div>
                </div>
              );
            })
            : null}
        </div>
      </div>
    );
  }
);

export default MeetingMomTemplate;
