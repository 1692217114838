import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";

import CircularProgress from "@mui/material/CircularProgress";
import {
  useGetServiceTicketByIdQuery,
  useUpdateServiceTicketMutation,
} from "src/services/ServiceTicketService";
import { ServiceTicketFormValues } from "../Add/AddServiceTicketFormWrapper";
import SubscriptionForm from "../Layout/ServiceTicketForm";

type Props = {
  selectedServiceTicket: string;
  onClose: () => void;
};

const EditServiceTicketWrapper = ({
  selectedServiceTicket,
  onClose,
}: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetServiceTicketByIdQuery(
    selectedServiceTicket
  );

  const [updateServiceCategory] = useUpdateServiceTicketMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: ServiceTicketFormValues = {
    problemName: items?.problemName || "",
    departmentId: {
      label: items?.departmentName,
      value: items?.departmentId,
    },
  };

  // Validation Schema
  const validationSchema = object().shape({
    problemName: string().required("Please enter problem"),
    departmentId: object().required("Please select department "),
  });
  const handleSubmit = (
    values: ServiceTicketFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ServiceTicketFormValues>
  ) => {
    const formattedValues = {
      problemName: values?.problemName,
      departmentId: values?.departmentId?.value,
    };

    updateServiceCategory({
      id: selectedServiceTicket,
      body: formattedValues,
    }).then((res: any) => {
      updateServiceCategory({
        id: selectedServiceTicket,
        body: formattedValues,
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <SubscriptionForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditServiceTicketWrapper;
