import { IconType } from "react-icons";
import { MdCurrencyRupee, MdMeetingRoom } from "react-icons/md";
import { UserModuleNameTypes } from "./models/UserAccess/UserAccess.model";
import { TbMapPin } from "react-icons/tb";
import { TbCategoryPlus } from "react-icons/tb";
import { SiActivitypub } from "react-icons/si";
import { GiSkills } from "react-icons/gi";

type NavItemTypeWithoutChild = {
  label: string;
  path: string;
  icon: IconType;
  name?: string;
  children?: never;
};

type NavItemTypeWithChild = {
  label: string;
  path?: never;
  icon: IconType;
  children?: {
    label: string;
    path: string;
    icon: IconType;
  }[];
};

export type NavItemType = NavItemTypeWithChild | NavItemTypeWithoutChild;

export const ConfigurationNavigation: NavItemType[] = [
  {
    label: "Location",
    icon: TbMapPin,
    path: "location",
    name: UserModuleNameTypes.NAV_LOCATION,
  },
  {
    label: "Currency",
    icon: MdCurrencyRupee,
    path: "currency",
    name: UserModuleNameTypes.NAV_CURRENCY,
  },
  {
    label: "Meeting Notes Type",
    icon: MdMeetingRoom,
    path: "meeting-notes-type",
    name: UserModuleNameTypes.NAV_MEETING_NOTES_TYPE,
  },
  {
    label: "Meeting Type",
    icon: MdMeetingRoom,
    path: "meeting-type",
    name: UserModuleNameTypes.NAV_MEETING_TYPE,
  },
  {
    label: "Incident Category",
    icon: TbCategoryPlus,
    path: "incidentCategory",
    name: UserModuleNameTypes.NAV_MEETING_TYPE,
  },
  {
    label: "Incident Type",
    icon: SiActivitypub,
    path: "incidentType",
    name: UserModuleNameTypes.NAV_MEETING_TYPE,
  },
  {
    label: "Skill",
    icon: GiSkills,
    path: "skill",
    name: UserModuleNameTypes.NAV_MEETING_TYPE,
  },
];
