import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { ContactLeadformValues } from "src/models/ContactLead.model";
import ContactLeadForm from "../Layouts/ContactLeadForm";
import {
  useContactLeadUpdateMutation,
  useGetLeadByIdQuery,
} from "src/services/ContactService";
import { showToast } from "src/utils/showToaster";
import { useParams } from "react-router-dom";

type Props = {
  onClose: () => void;
  leadId: any;
};

const EditContactLeadFormWrapper = ({ onClose, leadId }: Props) => {
  const [leadData, setLeadData] = useState<any>();
  const { data, isLoading, isFetching } = useGetLeadByIdQuery(leadId);


  useEffect(() => {
    if (!isLoading && !isFetching) {
      setLeadData(data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const [updateContactLeads] = useContactLeadUpdateMutation();
  const { contactId } = useParams();

  // Form Initial Values
  const initialValues: ContactLeadformValues = {
    summaryNote: leadData?.summaryNote || "",
    organizationName: leadData?.organizationName || "",
    assignedTo: "",
    countryId:
      {
        label: leadData?.countryName,
        value: leadData?.countryId,
      } || "",
    channelId:
      {
        label: leadData?.channelName,
        value: leadData?.channelId,
      } || "",
    campaignId:
      {
        label: leadData?.campaignName,
        value: leadData?.campaignId,
      } || "",
    domainId:
      {
        label: leadData?.domainName,
        value: leadData?.domainId,
      } || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    organizationName: string().required("Please enter organization name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ContactLeadformValues,
    { setSubmitting, resetForm }: FormikHelpers<ContactLeadformValues>
  ) => {
    const formattedValues = {
      contactId: contactId,
      summaryNote: values?.summaryNote,
      organizationName: values?.organizationName,
      countryId: values?.countryId?.value,
      channelId: values?.channelId?.value,
      campaignId: values?.campaignId?.value,
      domainId: values?.domainId?.value,
    };
    updateContactLeads({
        body :formattedValues ,
        id  : leadId
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ContactLeadForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditContactLeadFormWrapper;
