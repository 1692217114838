import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsTableLoading,
  setRequirementGathering,
  setSelectedPlatformData,
  setSelectedData,
} from "src/redux/slices/RequirementGatheringNewSlice";
import { RootState } from "src/redux/store";
import { useGetAllRequirementGatheringWithPaginationQuery } from "src/services/RequirementGatheringService";
// import ScreenAndFeatureSidebar from "../ScreenAndFeatureSidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
// import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import ATMTab from "src/components/UI/atoms/ATMTab/ATMTab";
import { TbArrowLeft, TbUser } from "react-icons/tb";
import ScreenAndFeaturesSidebar from "./ScreenAndFeaturesSidebar/ScreenAndFeaturesSidebar";
import ScreenFeatureList from "./List/ScreenFeatureList";
import PrintScreenDeatails from "./PrintScreenDetails/PrintScreenDeatails";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
// import { BiArrowBack } from "react-icons/bi";
// import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
// import ScreenFeatureList from "./ScreenFeatureList";
// import PrintScreenDeatails from "../PrintScreenDetails/PrintScreenDeatails";

const ScreenAndFeaturesLayout = ({ NavigationData }: any) => {
  const navigate = useNavigate();
  const meetingMOMRef = useRef(null);
  const { id, platformId } = useParams();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(platformId);

  const {
    searchValue,
    selectedPlatformData,
    selectedData,
    requirementGatheringData,
  } = useSelector((state: RootState) => state.requirementGatheringNew);

  const { data, isLoading, isFetching } =
    useGetAllRequirementGatheringWithPaginationQuery({
      searchValue: searchValue,
      params: ["platformName"],
      filterBy: [
        {
          fieldName: "workspaceId",
          value: [id],
        },
      ],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: false,
    });

  const handleSelectedData = (requirementGathering: any) => {
    let selectedPlatform = requirementGathering?.find(
      (item: any) => item._id === selectedTab
    );
    dispatch(setSelectedPlatformData(selectedPlatform));
  };

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      if (!selectedPlatformData) {
        const updatedFeature = {
          platFormId: data?.data?.[0]._id,
          screenIndex: 0,
          type: "screen",
          screenId: null,
          featureIndex: null,
          featureId: null,
          features: null,
          data: {
            heading: "",
            description: "",
          },
        };
        dispatch(setSelectedData(updatedFeature));
        const exitPlatformData = data?.data?.filter((platform: any) => {
          return platform?._id === platformId;
        });

        dispatch(setSelectedPlatformData(exitPlatformData?.[0]));
        dispatch(setRequirementGathering(data?.data || []));
      } else {
        handleSelectedData(data?.data);
        dispatch(setRequirementGathering(data?.data || []));
      }
      dispatch(setIsTableLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching, selectedTab]);

  const handlePrint = useReactToPrint({
    content: () => meetingMOMRef?.current,
    documentTitle: `codiotic`,
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedData) {
        if (event.ctrlKey && event.key === ",") {
          event.preventDefault();
          handlePrint();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackToWorkspace = () => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to back to workspace?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          navigate("/workspace");
          dispatch(setSelectedData(null));
        }
      },
    });
  };

  const tab = data?.data?.map((item: any) => ({
    label: item.platformName,
    icon: TbUser,
    path: item._id,
    onClick: () => {
      setSelectedTab(item?._id);
      dispatch(setSelectedPlatformData(item));
      dispatch(setSelectedData(null));
      navigate(`/workspace/${id}/platform/${item._id}/screen-feature`);
    },
  }));

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <div className="">
        <ATMTab
          children={
            <div
              onClick={() => {
                handleBackToWorkspace();
              }}
              className="flex items-center cursor-pointer"
            >
              <TbArrowLeft size={20} />
            </div>
          }
          childrenLabel={""}
          tabs={tab}
          selected={(tabItem) => tabItem.path === selectedTab}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <div className="min-w-[250px] border-r border-divider shadow-md">
          <ScreenAndFeaturesSidebar />
        </div>
        {/* <div className="relative flex-1"> */}
        <ScreenFeatureList />
        {/* </div> */}
        <div className="hidden">
          <PrintScreenDeatails
            screenRef={meetingMOMRef}
            requirementGatheringData={requirementGatheringData}
          />
        </div>
      </div>
    </div>
  );
};

export default ScreenAndFeaturesLayout;
