import React from "react";
import ATMSelect from "../atoms/formFields/ATMSelect/ATMSelect";
import ATMLoadingButton from "../atoms/ATMLoadingButton/ATMLoadingButton";

type Props = {
  formikProps: any;
  onClose: any;
};
const AttedancePunchIn = ({ onClose, formikProps }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  const WorkingFrom = [
    {
      label: "OFFICE",
      value: "OFFICE",
    },
    {
      label: "HOME",
      value: "HOME",
    },
  ];

  return (
    <>
      <div className="p-4 flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <div className="text-xl font-medium">Working From</div>
          <button
            type="button"
            className="hover:bg-hover p-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
        <div className="flex flex-col gap-4">
          {/* Project */}
          <div>
            <ATMSelect
              name="workingFrom"
              label="Working From"
              required
              placeholder="Select Working From"
              value={values.workingFrom}
              options={WorkingFrom}
              onChange={(newValue) => {
                setFieldValue("workingFrom", newValue);
              }}
            />
          </div>
        </div>
        <div>
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </>
  );
};

export default AttedancePunchIn;
