import React from "react";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useLocation, useNavigate } from "react-router-dom";
import ATMTab from "src/components/UI/atoms/ATMTab/ATMTab";
import { ImProfile } from "react-icons/im";
import { IoBarChartOutline } from "react-icons/io5";
import { BsCalendar3 } from "react-icons/bs";
import PerformanceListingWrapper from "../Incident/Performance/List/PerformanceListingWrapper";
import AttendanceListingWrapper from "../Attendance/List/AttendanceListingWrapper";
import DetailsWrapper from "./Details/DetailsWrapper";
import { TbReceipt } from "react-icons/tb";
import SalarySlipListingWrapper from "../SalarySlip/List/SalarySlipListingWrapper";

const ProfileLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // TO GET TAB VALUE
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("tab");

  React.useEffect(() => {
    if (activeTab === null) {
      navigate("/profile/?tab=details");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case "details":
        return <DetailsWrapper />;
      case "performance":
        return <PerformanceListingWrapper />;
      case "attendance":
        return <AttendanceListingWrapper />;
      case "salarySlip":
        return <SalarySlipListingWrapper />;
      default:
        return;
    }
  };

  const tab: any[] = [
    {
      label: "Details",
      icon: ImProfile,
      path: "details",
      onClick: () => {
        navigate("/profile/?tab=details");
      },
    },
    {
      label: "Performance",
      icon: IoBarChartOutline,
      path: "performance",
      onClick: () => {
        navigate("/profile/?tab=performance");
      },
    },
    {
      label: "Attendance",
      icon: BsCalendar3,
      path: "attendance",
      onClick: () => {
        navigate("/profile/?tab=attendance");
      },
    },
    {
      label: "Salary Slip",
      icon: TbReceipt,
      path: "salarySlip",
      onClick: () => {
        navigate("/profile/?tab=salarySlip");
      },
    },
  ];

  return (
    <SideNavLayout>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="sticky top-0 w-full bg-white z-10 ">
          <ATMTab
            tabs={tab}
            selected={(tab: any) => tab.path === activeTab}
            childrenLabel={activeTab}
            children={<></>}
          />
        </div>
        <div className="flex-1 block h-full overflow-auto">
          {renderTabContent()}
        </div>
      </div>
    </SideNavLayout>
  );
};

export default ProfileLayout;
