import {
  IconBuildingFactory2,
  IconBusinessplan,
  IconPropeller,
} from "@tabler/icons-react";
import { IconType } from "react-icons";
import { UserModuleNameTypes } from "./models/UserAccess/UserAccess.model";

type NavItemTypeWithoutChild = {
  label: string;
  path: string;
  icon: IconType;
  name?: string;
  children?: never;
};

type NavItemTypeWithChild = {
  label: string;
  path?: never;
  icon: IconType;
  children?: {
    label: string;
    path: string;
    icon: IconType;
  }[];
};

export type NavItemType = NavItemTypeWithChild | NavItemTypeWithoutChild;

export const EnterpriseSuiteNavigation: NavItemType[] = [
  {
    label: "Industry",
    icon: IconBuildingFactory2,
    path: "industry",
    name: UserModuleNameTypes.NAV_INDUSTRY,
  },
  {
    label: "Business",
    icon: IconBusinessplan,
    path: "business",
    name: UserModuleNameTypes.NAV_BUSINESS,
  },
  {
    label: "Operations",
    icon: IconPropeller,
    path: "operation",
    name: UserModuleNameTypes.NAV_OPERATIONS,
  },
];
