import React from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import BoardLayout from "./Components/BoardLayout";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { CircularProgress } from "@mui/material";
import { TeamViewListResponseType } from "src/models/TeamView.model";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ViewInProgressTicketsWrapper from "./ViewInProgressTickets/ViewInProgressTicketsWrapper";
import ListCompleteTaskWrapper from "./ViewCompleteTask/ListCompleteTaskWrapper";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { useLocation, useNavigate } from "react-router-dom";
// import { setSearchValue as completetaskSearchValue } from 'src/redux/slices/TeamViewTodayTaskSlice';

type TaskListResponse = {
  _id: string;
  taskNumber: number;
  taskGroupId: string;
  ticketId: string | null;
  title: string;
  status: string;
  assignedTo: string;
};

type Props = {
  rows: TeamViewListResponseType[];
  onClick?: (taskId: string, ticketId: string) => void;
  paginationProps: {
    isTableLoading: boolean;
    searchValue: string;
    setSearchValue: (newValue: string) => void;
  };
};

const TeamViewListing = ({
  rows,
  onClick,
  paginationProps: { isTableLoading, searchValue, setSearchValue },
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  // TO GET TAB VALUE
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("tab");

  function counterOfTaskLabel(taskList: TaskListResponse[]) {
    let allTaskStatus: any = {
      OPEN: [],
      TODO: [],
      IN_PROGRESS: [],
      BUGS: [],
      BLOCKING: [],
      UAT_REVIEW: [],
      DONE: [],
      HOLD: [],
      ISSUE: [],
      COMPLETE: [],
      CLOSE: [],
    };

    taskList?.forEach((ele: TaskListResponse) => {
      let statusKey = ele?.status as keyof typeof allTaskStatus;
      for (statusKey in allTaskStatus) {
        if (statusKey === ele?.status) {
          // allTaskStatus[statusKey]++;
          allTaskStatus[statusKey] = [...allTaskStatus[statusKey], ele];
        }
      }
    });
    return allTaskStatus;
  }
  React.useEffect(() => {
    if (activeTab === null) {
      if (isAuthorized(UserModuleNameTypes.ACTION_TEAM_VIEW_ALL_TASK)) {
        navigate("/team-view/?tab=allTask");
      } else if (
        isAuthorized(UserModuleNameTypes.ACTION_TEAM_VIEW_INPROGRESS)
      ) {
        navigate("/team-view/?tab=inprogress");
      } else {
        navigate("/team-view/?tab=complete");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case "inprogress":
        return <ViewInProgressTicketsWrapper />;
      case "complete":
        return <ListCompleteTaskWrapper />;
      default:
        return (
          <div className="flex flex-1 overflow-auto w-full ">
            {isTableLoading ? (
              <div className="flex justify-center items-center h-[400px] w-full">
                <CircularProgress />
              </div>
            ) : (
              <div className="flex gap-6 w-full overflow-auto p-4 py-1 flex-1">
                {rows?.map((ele: TeamViewListResponseType) => {
                  return (
                    <BoardLayout
                      key={ele?._id}
                      boardTitle={ele?.name}
                      listData={ele?.tasks}
                      activeTab={activeTab}
                      taskStatusLabelCounter={counterOfTaskLabel(
                        ele?.tasks as any
                      )}
                      onClick={onClick}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <SideNavLayout>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="sticky top-0 md:p-4 p-2 bg-white z-10 md:flex block justify-end ">
          <div className="flex gap-2 md:mb-0 mb-2">
            {isAuthorized(UserModuleNameTypes.ACTION_TEAM_VIEW_ALL_TASK) && (
              <ATMLoadingButton
                onClick={() => {
                  navigate("/team-view/?tab=allTask");
                }}
                className={`${
                  activeTab === "allTask"
                    ? "bg-blue-400 text-white font-semibold"
                    : "bg-white text-black "
                } w-32`}
              >
                All Task
              </ATMLoadingButton>
            )}
            {isAuthorized(UserModuleNameTypes.ACTION_TEAM_VIEW_INPROGRESS) && (
              <ATMLoadingButton
                onClick={() => {
                  navigate("/team-view/?tab=inprogress");
                }}
                className={`${
                  activeTab === "inprogress"
                    ? "bg-blue-400 text-white  font-semibold"
                    : "bg-white text-black"
                } w-32`}
              >
                In Progress
              </ATMLoadingButton>
            )}
            {isAuthorized(UserModuleNameTypes.ACTION_TEAM_VIEW_COMPLETE) && (
              <ATMLoadingButton
                onClick={() => {
                  navigate("/team-view/?tab=complete");
                }}
                className={`${
                  activeTab === "complete"
                    ? "bg-blue-400 text-white  font-semibold"
                    : "bg-white text-black"
                } w-32`}
              >
                COMPLETE
              </ATMLoadingButton>
            )}
          </div>
          <ATMPageHeader
            searchValue={searchValue}
            hideAddButton={true}
            onSearchChange={(e) => {
              setSearchValue(e);
              // completetaskSearchValue(e)
            }}
          />
        </div>
        {renderTabContent()}

        {/* {activeTab === "INPROGRESS" ? (
          <ViewInProgressTicketsWrapper />
        ) : (
          <div className="flex flex-1 overflow-auto w-full ">
            {isTableLoading ? (
              <div className="flex justify-center items-center h-[400px] w-full">
                <CircularProgress />
              </div>
            ) : (
              <div className="flex gap-6 w-full overflow-auto p-4 py-1 flex-1">
                {rows?.map((ele: TeamViewListResponseType) => {
                  return (
                    <BoardLayout
                      key={ele?._id}
                      boardTitle={ele?.name}
                      listData={ele?.tasks}
                      taskStatusLabelCounter={counterOfTaskLabel(
                        ele?.tasks as any
                      )}
                      onClick={onClick}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )} */}
      </div>
    </SideNavLayout>
  );
};

export default TeamViewListing;
