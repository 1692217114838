import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import AddCommentForm from "./AddCommentForm";
import { useParams } from "react-router-dom";
import { useAddCommentServiceTicketMutation } from "src/services/ServiceTicketService";

export type AddCommentFormValues = {
  assignee?: "";
  comment?: "";
};

const AddCommentFormWrapper = () => {
  // Doubt Id from params
  const { id } = useParams();
  const [changeAssigneeOnDoubt] = useAddCommentServiceTicketMutation();

  // Form Initial Values
  const initialValues: AddCommentFormValues = {
    comment: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    comment: string(),
  });

  // Handle Submit
  const handleSubmit = (
    values: AddCommentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AddCommentFormValues>
  ) => {
    changeAssigneeOnDoubt({
      message: values?.comment,
      commentRelatedToId: id || '',
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", "Added Successfully");
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AddCommentForm formikProps={formikProps} />
        </Form>
      )}
    </Formik>
  );
};

export default AddCommentFormWrapper;
