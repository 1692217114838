import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { NotMeDialogWrapperValues } from "./NotMeDialogWrapper";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";

type Props = {
  formikProps: FormikProps<NotMeDialogWrapperValues>;
  onClose: () => void;
  notMeReasons : any ;
};

const NotMeDialog = ({ formikProps, onClose , notMeReasons }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">Not Me Reason</div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      {notMeReasons?.length ? (
        <div >
          {notMeReasons?.map((el: any, ind: any) => {
            return (
            <div className="mb-3">
               <p className="bg-slate-100 px-3 p-1 font-medium  rounded">
                {el?.adminReason ? el?.adminReason : el?.userReason}
              </p>
              <p className="flex justify-end text-xs">
                {el?.adminReason ? 'Reply' : 'You'}
              </p>
            </div>
            );
          })}
        </div>
      ) : null}
      <div className="flex flex-col gap-4">
        <div className="">
          <ATMTextArea
            required
            name="isNotMeReason"
            value={values?.isNotMeReason}
            onChange={(e) => setFieldValue("isNotMeReason", e)}
            label="Reason"
            placeholder="Enter Reason"
            className="rounded"
          />
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default NotMeDialog;
