import React from "react";

type Props = {
  usersOnBreak: { name: string; email: string }[];
};

const OnBreakUsers = ({ usersOnBreak }: Props) => {
  return (
    <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg custom-shadow bg-break">
      <div className="flex items-center gap-2 p-2 border-b bg-sky-200	">
        <div className="flex items-center justify-center p-2 font-semibold bg-white border rounded-md border-primary-dark size-8">
          {usersOnBreak?.length}
        </div>
        <p className="font-medium">On Break</p>
      </div>
      {usersOnBreak?.length ? (
        <ul className="h-full p-2 overflow-auto">
          {usersOnBreak?.map((ele, ind) => {
            return (
              <li
                key={ind}
                className="py-2 px-1 rounded hover:bg-slate-100 cursor-pointer"
              >
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate capitalize">
                    {ele?.name}
                  </p>
                  <p className="text-sm text-gray-500 truncate">{ele?.email}</p>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-4">
          No Users On Break
        </div>
      )}
    </div>
  );
};

export default OnBreakUsers;
