export enum ENMMeetingStatus {
  "FREEZE" = "Client Approval Done",
  "ONGOING" = "Ongoing Meeting",
  "PENDING" = "Not started",
  "COMPLETED" = "Meeting is done",
}

export type TYPMeetingStatus = keyof typeof ENMMeetingStatus;

type Props = {
  status: TYPMeetingStatus;
};

const getClassNameByStatus = (status: TYPMeetingStatus) => {
  switch (status) {
    case "FREEZE":
      return "text-primarty-dark bg-slate-50 border border-slate-200";
    case "COMPLETED":
      return "text-primarty-dark bg-green-50 border border-green-200";
    case "PENDING":
      return "text-primarty-dark bg-yellow-50 border border-yellow-200";
    case "ONGOING":
      return "text-primarty-dark bg-blue-50 border border-blue-200";
  }
};

const MOLMeetingStatusChip = ({ status }: Props) => {
  const className = ` 
        font-medium
        rounded-full
        px-2
        py-[2px]
        text-[12px]
        ${getClassNameByStatus(status)}`;

  return (
    ENMMeetingStatus[status] && (
      <div className={className}> {ENMMeetingStatus[status]} </div>
    )
  );
};

export default MOLMeetingStatusChip;
