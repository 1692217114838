import { FormikProps } from "formik";
import React from "react";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { useGetAllNeetingType } from "src/hooks/useMeetingType";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import { MeetingTypeListResponse } from "src/models/MeetingType.model";
import { ProjectListResponse } from "src/models/Project.model";

type Props = {
  formikProps: FormikProps<any>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const InternalMeetingAdd = ({
  formikProps,
  formType = "ADD",
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  // get all codiotic user hook
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  // get all meetng note type hook
  const { allMeetingType, isDataLoading: isAllMeetingtypeDataLoading } =
    useGetAllNeetingType();

  return (
    <MOLFormDialog
      title={
        formType === "ADD" ? "Add Internal Meeting" : " Update Internal Meeting"
      }
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
      checkboxText="Start Meeting"
    >
      <div className="flex flex-col gap-4">
        {/* Organisation Name */}
        <div>
          <ATMTextField
            required
            name="title"
            value={values.title}
            onChange={(e) => setFieldValue("title", e.target.value)}
            label="Title"
            placeholder="Enter Title"
            onBlur={handleBlur}
          />
        </div>
        {/* Project */}
        <div className="mt-1">
          <ATMSelect
            required
            name="projectId"
            label="Project"
            placeholder="Select Project"
            value={values.projectId}
            isLoading={isAllProjectsDataLoading}
            options={allProjects?.map((ele: ProjectListResponse) => {
              return {
                label: ele?.projectName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("projectId", newValue);
            }}
          />
        </div>

        {/* Codiotic User */}
        <div>
          <ATMMultiSelect
            name="codioticUsers"
            label="Codiotic User"
            placeholder="Select Codiotic User"
            value={values.codioticUsers}
            isLoading={isCodioticUserDataLoading}
            options={codioticUsers?.map((ele: CodioticUserListResponse) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("codioticUsers", newValue);
            }}
          />
        </div>

        <div>
          <ATMSelect
            required
            name="meetingTypeId"
            label="Meeting Type"
            placeholder="Select Meeting Type"
            value={values.meetingTypeId}
            isLoading={isAllMeetingtypeDataLoading}
            options={allMeetingType?.map((ele: MeetingTypeListResponse) => {
              return {
                label: ele?.meetingType,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("meetingTypeId", newValue);
            }}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default InternalMeetingAdd;
