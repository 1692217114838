import apiSlice from "./ApiSlice";

export const IncidentCategoryServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // List Category
    AllIncidentCategory: builder.query({
      providesTags: ["incident"],
      query: (body) => ({
        url: "/category",
        method: "POST",
        body,
      }),
    }),
    // ADD Categopry
    addIncidentCategory: builder.mutation({
      invalidatesTags: ["incident"],
      query: (body) => ({
        url: "/category/add",
        method: "POST",
        body,
      }),
    }),
    // Get single Category
    getIncidentCategory: builder.query({
      providesTags: ["incident"],
      query: (id: string) => {
        return {
          url: `/category/${id}`,
          method: "GET",
        };
      },
    }),

    // Update Category
    updateIncidentCategory: builder.mutation({
      invalidatesTags: ["incident"],
      query: ({ id, body }) => {
        return {
          url: `/category/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //DELETE Category
    deleteIncidentCategtory: builder.mutation({
      invalidatesTags: ["incident"],
      query: (id) => {
        return {
          url: `/category/${id}`,
          method: "DELETE",
        };
      },
    }),

    // Get All Category
    getAllIncidentCategory: builder.query({
      providesTags: ["incident"],
      query: (id: string) => {
        return {
          url: `/category`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useAddIncidentCategoryMutation,
  useAllIncidentCategoryQuery,
  useGetIncidentCategoryQuery,
  useUpdateIncidentCategoryMutation,
  useDeleteIncidentCategtoryMutation,
  useGetAllIncidentCategoryQuery,
} = IncidentCategoryServiceApi;
