import React from "react";
import { FormikProps } from "formik";
import { FormInitialValues } from "./AssignTicketTeamMemberModalWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  userOptions: {
    label: string;
    value: string;
  }[];
  handleClose: () => void;
};

const AssignTicketTeamMemberModal = ({
  formikProps,
  handleClose,
  userOptions,
}: Props) => {
  // Formik
  const { values, setFieldValue, isSubmitting, handleBlur, handleSubmit } =
    formikProps;

  return (
    <div className="flex flex-col rounded border border-gray-200 relative">
      {/* Heading & Clear Button */}
      <div className="flex justify-between items-center px-4 py-2 sticky top-0 z-50">
        <div className="text-[24px] font-medium"> Assign members </div>
        <button
          type="button"
          className="text-[#041618] font-medium"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>

      {/* Project */}
      <div className="p-3 flex-1 mb-2">
        <ATMMultiSelect
          name="assignedTo"
          value={values.assignedTo}
          options={userOptions}
          label="Team members"
          placeholder="Select team members"
          onBlur={handleBlur}
          onChange={(newValue) => {
            setFieldValue("assignedTo", newValue);
          }}
        />
      </div>

      {/* Apply & Cancel Buttons */}
      <div className="flex flex-col gap-2 p-3 sticky bottom-0 bg-white">
        <div>
          <ATMLoadingButton
            type="button"
            isLoading={isSubmitting}
            className="h-[40px] bg-[#041618] font-medium"
            onClick={() => handleSubmit()}
          >
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default AssignTicketTeamMemberModal;
