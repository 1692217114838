/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import ExpensesListFilterFormDialog from "./ExpensesListFilterFormDialog";
import { setGSTApplicable, setExpenseCategoryId, setDateFilter } from "src/redux/slices/ExpensesSlice";
type Props = {
  open: boolean;
  onClose: () => void;
};



const ExpensesListFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const meetingState = useSelector((state: RootState) => state.expenses);
  const {
    dateFilter,
    GSTApplicable,
    expenseCategoryId
  } = meetingState;


  const initialValues: any = {
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
    GSTApplicable: GSTApplicable,
    expenseCategoryId: expenseCategoryId
  };


  // Submit Handler
  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    setSubmitting(false);

    // created date from to dispatch
    dispatch(
      setDateFilter({
        startDate: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        endDate: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
          dateFilterKey : 'expenseDate'
      })
    );

    // project filter dispatch
    dispatch(setExpenseCategoryId(values.expenseCategoryId));

    dispatch(setGSTApplicable(values?.GSTApplicable));


    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<any>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        startDate: "",
        endDate: "",
      })
    );

    // project filter dispatch
    await dispatch(setExpenseCategoryId([]));
    await dispatch(setGSTApplicable([]));


    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ExpensesListFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ExpensesListFilterFormDialogWrapper;
