import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { PlanFormValues } from "src/models/Plan.model";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import PlanForm from "../Layouts/PlanForm";
import { useAddPlanMutation } from "src/services/PlanService";

type Props = {
  onClose: () => void;
  productId: string;
};

const AddPlanWrapper = ({ onClose, productId }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addPlan] = useAddPlanMutation();

  // Form Initial Values
  const initialValues: PlanFormValues = {
    planName: "",
    amount: "",
    recurranceDays: "0",
  };

  // Validation Schema
  const validationSchema = object().shape({
    planName: string().required("Please enter plan name"),
    amount: string().test("cost-test", "Please enter amount", (value) => {
      return Number(value || 0) > 0;
    }),
    recurranceDays: string().required("Please enter recurrance  days"),
  });

  // Handle Submit
  const handleSubmit = (
    values: PlanFormValues,
    { setSubmitting, resetForm }: FormikHelpers<PlanFormValues>
  ) => {
    addPlan({
      planName: values?.planName,
      amount: Number(values?.amount),
      recurranceDays: Number(values?.recurranceDays),
      productId: productId,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <PlanForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddPlanWrapper;
