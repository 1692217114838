import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/redux/store";
import SingleTicketTaskView from "./SingleTicketTaskView";
import TicketTaskSideBarLayout from "./TaskSideBarLayout/TicketTaskSideBarLayout";
import {
  useGetAllTaskWithPaginationQuery,
  useGetTaskByIdQuery,
} from "src/services/TaskService";
import { useParams } from "react-router-dom";
import { TaskListResponse } from "src/models/Task.model";
import { setIsTableLoading } from "src/redux/slices/AllTaskSlice";

const SingleTicketTaskViewWrapper = () => {
  //
  const { singleItem } = useSelector((state: RootState) => state.ticket);

  // taskId
  const { id: TASK_ID } = useParams();

  // ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  const [searchValue, setSearchValue] = useState<string>("");
  const [singleTaskData, setSingleTaskData] = useState<TaskListResponse>();

  // Get Single Ticket Details
  const {
    isLoading: isTicketDetailsLoading,
    isFetching: isTicketDetailsFetching,
    data: ticketData,
  } = useGetTaskByIdQuery(TASK_ID || "", {
    skip: !TASK_ID,
  });
  // Set Single Ticket Details
  useEffect(() => {
    if (!isTicketDetailsLoading && !isTicketDetailsFetching) {
      setSingleTaskData(ticketData?.data);
    }
  }, [isTicketDetailsLoading, isTicketDetailsFetching, ticketData]);

  const {
    userFilterValue,
    taskStatus,
    dueDateFilterValue,
    projectFilterValue,
    moduleFilterValue,
    featureFilterValue,
  } = useSelector((state: RootState) => state.task);

  const [setsideBarTicketsList, setsetsideBarTicketsList] = useState<
    TaskListResponse[]
  >([]);

  const [limit, setLimit] = useState<number>(20);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useGetAllTaskWithPaginationQuery({
    limit: limit,
    searchValue: searchValue,
    params: ["title"],
    page: 1,
    filterBy: [
      {
        fieldName: "ticketId",
        value: singleItem[0]?._id,
      },
      {
        fieldName: "assignedTo",
        value: userFilterValue?.value || "",
      },
      {
        fieldName: "status",
        value: taskStatus?.value || "",
      },
      {
        fieldName: "dueDate",
        value: dueDateFilterValue || "",
      },
      {
        fieldName: "projectId",
        value: projectFilterValue?.value || "",
      },
      {
        fieldName: "moduleId",
        value: moduleFilterValue?.value || "",
      },
      {
        fieldName: "featureId",
        value: featureFilterValue?.value || "",
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      setsetsideBarTicketsList(data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const afterCloseTicket = () => {
    if (setsideBarTicketsList?.length > 1) {
      const taskIdIndex = setsideBarTicketsList?.findIndex(
        (ticketList) => ticketList?._id === TASK_ID
      );
      const nextTaskId =
        setsideBarTicketsList?.length === taskIdIndex + 1
          ? setsideBarTicketsList[0]?._id
          : setsideBarTicketsList[taskIdIndex + 1]?._id;
      nextTaskId
        ? navigate(`/ticket/${TICKET_ID}/task/${nextTaskId}`)
        : navigate(`/ticket/${TICKET_ID}/task`);
    } else {
      navigate(`/ticket/${TICKET_ID}/task`);
    }
  };

  return (
    <>
      <TicketTaskSideBarLayout
        listData={setsideBarTicketsList}
        searchValue={searchValue}
        onSearch={(value) => setSearchValue(value)}
        onClickViewMore={() => setLimit(limit + 20)}
        onClickTicket={(taskId) =>
          navigate(`/ticket/${TICKET_ID}/task/${taskId}`)
        }
      >
        <SingleTicketTaskView
          singleTaskData={singleTaskData}
          isSingleTicketLoading={isLoading}
          afterCloseTicket={afterCloseTicket}
        />
      </TicketTaskSideBarLayout>
    </>
  );
};

export default SingleTicketTaskViewWrapper;
