import { useState, useEffect } from "react";
import { useGetAllCampaignGroupListQuery } from "src/services/CampaignGroupsService";
const useCampaignGroup = () => {
    const [CampaignGroup, setCampaignGroup] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const { data, isLoading, isFetching } = useGetAllCampaignGroupListQuery("");

    useEffect(() => {
        if (isLoading || isFetching) {
            setIsDataLoading(true);
        } else {
            const result: any[] = data?.data;
            setCampaignGroup(result || []);
            setIsDataLoading(false);
        }
    }, [data, isLoading, isFetching]);

    return { CampaignGroup, isDataLoading };
};

export default useCampaignGroup;
