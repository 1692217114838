import { Form, Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import AddConversation from '../AddConversation/AddConversation'
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetMeetingByIdQuery, useUpdateMeetingMutation } from 'src/services/MeetingServices';
import { showToast } from 'src/utils/showToaster';
import { object, string } from 'yup';

const EditConversationWrapper = () => {

    const getMeetingWithLabelAndValue = (value: string) => {
        switch (value) {
            case "INTERNAL_TEAM":
                return {
                    label: "Internal Team",
                    value: "INTERNAL_TEAM",
                };
            case "CLIENT":
                return {
                    label: "Client",
                    value: "CLIENT",
                };
            case "THIRD_PARTY_WITH_INTERNAL_TEAM":
                return {
                    label: "Internal Team (Third Party)",
                    value: "THIRD_PARTY_WITH_INTERNAL_TEAM",
                };
            case "THIRD_PARTY_WITH_CLIENT":
                return {
                    label: "Client (Third Party)",
                    value: "THIRD_PARTY_WITH_CLIENT",
                };
            default:
                break;
        }
    };



    const [meetingData, setMeetingData] = useState<any>();
    const [updateMeeting] = useUpdateMeetingMutation();
    const navigate = useNavigate();
    const { id: MEETING_ID } = useParams();
    const { isLoading, isFetching, data } = useGetMeetingByIdQuery(
        MEETING_ID || "",
        {
            skip: !MEETING_ID,
        }
    );

    useEffect(() => {
        if (!isLoading && !isFetching) {
            setMeetingData(data?.data);
        }
    }, [isLoading, isFetching, data]);

    const initialValues: any = {
        projectId: {
            label: meetingData?.projectName,
            value: meetingData?.projectId
        },
        title: meetingData?.title || "",
        meetingTypeId: { label: meetingData?.meetingTypeName, value: meetingData?.meetingTypeId },
        mode: meetingData?.mode || "CALL",
        scheduledDate: meetingData?.scheduledDate,
        scheduledStartTime: meetingData?.scheduledStartTime,
        scheduledEndTime: meetingData?.scheduledEndTime,
        meetingWith: getMeetingWithLabelAndValue(meetingData?.meetingWith),
        clientUsers: meetingData?.clientUsers || [],
        codioticUsers:
            meetingData?.codioticUsers?.map((ele: any) => {
                return {
                    label: ele?.name,
                    value: ele?._id,
                };
            }) || [],
        communicationType: { label: meetingData?.communicationType, value: meetingData?.communicationType },
    }

    // Validation Schema
    const validationSchema = object().shape({
        title: string().required("Title is required"),
        communicationType: object().required("Communication Type is required"),
        meetingWith: object().required("Meeting with is required"),
        meetingTypeId: object().required("Meeting type is required"),
    });

    // Handle Submit
    const handleSubmit = (
        values: any,
        { setSubmitting, resetForm }: FormikHelpers<any>
    ) => {
        const {
            codioticUsers,
            scheduledStartTime,
            scheduledEndTime,
            meetingWith,
            notes,
            meetingTypeId,
            communicationType,
            scheduledDate,
            projectId,
            ...rest
        } = values;

        const formatedValues = {
            projectId: projectId?.value,
            scheduledStartTime: moment(values?.scheduledStartTime, "YYYY-MM-DD HH:mm:ss", true).isValid()
                ? moment(scheduledStartTime).format("HH:mm:ss")
                : values.scheduledStartTime,
            scheduledDate: moment(scheduledDate).format("YYYY-MM-DD"),
            communicationType: communicationType?.value,
            meetingWith: meetingWith?.value,
            meetingTypeId: meetingTypeId?.value,
            requiredThings: ["Mobile"],
            meetingCreateType: "CONVERSATION",
            ...rest,
        };

        updateMeeting({ id: MEETING_ID, body: formatedValues }).then((res: any) => {
            if (res?.error) {
                showToast("error", res?.error?.data?.message);
            } else {
                if (res?.data?.status) {
                    showToast("success", res?.data?.message);
                    resetForm();
                    navigate("/meeting");
                } else {
                    showToast("error", res?.data?.message);
                }
            }
            setSubmitting(false);
        });
    };


    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formikProps) => (
                <Form>
                    <AddConversation formType="Edit" formikProps={formikProps} />
                </Form>
            )}
        </Formik>
    )
}

export default EditConversationWrapper
