import React from 'react'
import { getAllStatus } from 'src/utils/common/statusenum';

const ProjectStatusView = ({CountData}:any) => { 
  
    // Group objects by status
  const groupedData = CountData?.reduce((acc: any, item: any) => {
    const { status } = item;
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status]?.push(item);
    return acc;
  }, {});
  
  return (
    <div className="grid grid-cols-3 flex-wrap gap-4 p-3">
    {groupedData && Object.keys(groupedData)?.map((status) => (
      <div key={status}  className={`shadow-lg py-3  ${getAllStatus(status)?.className} rounded-lg` }>
        <h3 className='text-xl font-semibold  text-center mb-1 text-white'>{getAllStatus(status)?.text} </h3>
        <div className="flex flex-wrap gap-4 justify-center">
          {groupedData?.[status]?.map((item: any, index: any) => ( 
              <div 
              key={index}
              className={`p-2 rounded text-sm font-bold bg-white transition-all hover:shadow-md`}
            >
              {item?.labelName}
              <span className="h-[30px] px-2 rounded-full text-sm text-black">
                {item?.count}
              </span>
            </div>
           
          ))}
        </div>
      </div>
    ))}
  </div>
)}

export default ProjectStatusView