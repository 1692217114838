import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCodioticTeamMemberByIdQuery } from 'src/services/TeamServices';
import ClientDetails from './DocumentListing';

const DocumentListingWrapper = () => {

    const { teamMemberId } = useParams()

    const [teamMemberData, setTeamMemberData] = useState<any>();

    const { isLoading, isFetching, data } = useGetCodioticTeamMemberByIdQuery(
        teamMemberId,
        {
            skip: !teamMemberId,
        }
    );

    useEffect(() => {
        if (!isLoading && !isFetching) {
            setTeamMemberData(data?.data);
        }
    }, [isLoading, isFetching, data]);

    return (
        <>
            <ClientDetails isLoading={isLoading || isFetching} items={teamMemberData} />
        </>
    )
}

export default DocumentListingWrapper

