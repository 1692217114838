import apiSlice from "./ApiSlice";

export const dumpApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Get all social media with pagination
        getDumpPagination: builder.query({
            providesTags: ["dump"],
            query: (body: PaginationPayloadType) => {
                return {
                    url: "/dump",
                    method: "POST",
                    body,
                };
            },
        }),

        // Get all Dump
        getAllDump: builder.query({
            providesTags: ["dump"],
            query: () => {
                return {
                    url: "/Dump",
                    method: "GET",
                };
            },
        }),

        // Add Dump
        addDump: builder.mutation({
            invalidatesTags: ["dump"],
            query: (body) => {
                return {
                    url: "/dump/add",
                    method: "POST",
                    body,
                };
            },
        }),

        // Get single Dump
        getSingleDump: builder.query({
            providesTags: ["dump"],
            query: (id: string) => {
                return {
                    url: `/dump/${id}`,
                    method: "GET",
                };
            },
        }),

        // Update social media
        updateDump: builder.mutation({
            invalidatesTags: ["dump"],
            query: ({ id, body }) => {
                return {
                    url: `/dump/${id}`,
                    method: "PUT",
                    body,
                };
            },
        }),

        // Change Status Social Media
        ChangeStatusDump: builder.mutation({
            invalidatesTags: ["dump"],
            query: (id) => {
                return {
                    url: `Dump/status-change/${id}`,
                    method: "PUT",
                };
            },
        }),

        //
    }),
});

export const {
    useGetDumpPaginationQuery,
    useGetAllDumpQuery,
    useGetSingleDumpQuery,
    useAddDumpMutation,
    useUpdateDumpMutation,
    useChangeStatusDumpMutation,
} = dumpApi;
