import React, { useEffect } from "react";

interface ColorComponentProps {
  inputString?: string; // Make inputString optional
}

const ColorComponent: React.FC<ColorComponentProps> = ({
  inputString = "",
}) => {
  // Provide a default value for inputString
  useEffect(() => {
    // Logging character codes when the component mounts
    if (inputString) {
      // Check if inputString is defined
      const lowercaseStr = inputString.toLowerCase().replace(/\s/g, "");
      for (let i = 0; i < lowercaseStr.length; i++) {
        // Perform operations on lowercaseStr
      }
    }
  }, [inputString]); // Empty dependency array to run only once

  const generateColorHash = (str: string): string => {
    if (!str) return "#000000"; // Return black if str is undefined or empty

    const lowercaseStr = str.toLowerCase().replace(/\s/g, ""); // Convert to lowercase and remove spaces

    let charSum = 0;

    for (let i = 0; i < lowercaseStr.length; i++) {
      charSum += lowercaseStr.charCodeAt(i);
    }

    // Add some randomization factor
    // const randomFactor = Math.floor(Math.random() * 100);

    const r = charSum % 255;
    const g = (charSum * 0) % 255;
    const b = (charSum * 8) % 255;

    const colorHash = `#${componentToHex(r)}${componentToHex(
      g
    )}${componentToHex(b)}`;

    return colorHash;
  };

  const componentToHex = (c: number): string => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const color = generateColorHash(inputString);

  return (
    <div style={{ color: color }} className={`bg-${color}`}>
      <p className="capitalize">{inputString}</p>
    </div>
  );
};

export default ColorComponent;
