import moment from "moment";
import { useEffect, useState } from "react";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import { FormikProps } from "formik";
import ATMRadioButtonGroup from "src/components/UI/atoms/formFields/ATMRadioButtonGroup/ATMRadioButtonGroup";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetChangeRequestQuery } from "src/services/ChangeRequestServices";
import { useGetAllUsersQuery } from "src/services/ClientUserServices";
import { useGetProjectsQuery } from "src/services/ProjectServices";
import { ticketPriorites, ticketTypes } from "src/utils/constants/ticketUtils";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";
import { clientSideTicketStatusOption } from "src/screens/Ticket/View/TicketTabs/TicketStatusDialog/TicketStatusDialog";
import { FormInitialValues } from "./TicketGroupListFilterFormDialogWrapper";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
  tagsOptions: any;
  tagsIsLoading: boolean ;
};

const ticketStatusOptions = [
  {
    label: "Open",
    value: "OPEN",
  },
  {
    label: "Assigned",
    value: "ASSIGNED",
  },
  {
    label: "Closed",
    value: "CLOSED",
  },
  {
    label: "Mapped",
    value: "MAPPED",
  },
  {
    label: "Pending",
    value: "UNMAPPED",
  },
  {
    label: "Done",
    value: "DONE",
  },
  {
    label: "Approval done",
    value: "APPROVAL_DONE",
  },
  {
    label: "Approval pending",
    value: "APPROVAL_PENDING",
  },
];

const TicketListFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  tagsOptions,
  onClose,
  tagsIsLoading ,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  const [projectOptions, setProjectOptions] = useState([]);

  const {
    data: projects,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
  } = useGetProjectsQuery("");

  // Setting Team Members Data
  useEffect(() => {
    if (!isProjectLoading && !isProjectFetching) {
      const filterProjectOptions = projects?.data?.map((project: any) => ({
        label: project.projectName,
        value: project._id,
      }));
      setProjectOptions(filterProjectOptions || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, isProjectLoading, isProjectFetching]);

  // created by

  const [clientUserOptions, setClientUserOptions] = useState([]);

  const {
    data: clientUserData,
    isLoading: isClientUserLoading,
    isFetching: isClientUserFetching,
  } = useGetAllUsersQuery("");

  // Setting Team Members Data
  useEffect(() => {
    if (!isClientUserLoading && !isClientUserFetching) {
      const filterClientOptions = clientUserData?.data?.map((client: any) => ({
        label: client.name,
        value: client._id,
      }));
      setClientUserOptions(filterClientOptions || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUserData, isClientUserLoading, isClientUserFetching]);

  //Change Request Listing
  const {
    data: changeRequestData,
    isLoading: changeRequestDataIsLoading,
    isFetching: changeRequestDataIsFetching,
  } = useGetChangeRequestQuery({
    limit: 10,
    searchValue: "",
    params: ["clientId", "requestName"],
    page: 1,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });
  const ChangeRequestOption = changeRequestData?.data?.map((el: any) => {
    return {
      label: `${el?.requestName} (${el?.projectName})`,
      value: el?._id,
    };
  });

  return (
  <MOLFilterDialog 
  title={"Filters"}
  onClose={onClose}
  isSubmitting={isSubmitting}
  clearFilter={onReset}
  handleSubmit={handleSubmit}
  open={open}
  >
      <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">

        {/* Filter Form Field */}
        {/* Priority */}

        <div>
          <ATMSelect
            label="Project"
            name="project"
            value={values.project}
            placeholder="Select Project"
            options={projectOptions}
            onChange={(newValue) => setFieldValue("project", newValue)}
          />
        </div>

        {/* Created By */}
        <div className="">
          <ATMSelect
            label="Created by client user"
            name="createdBy"
            value={values.createdBy}
            options={clientUserOptions}
            placeholder="Select Created By"
            onChange={(newValue) => {
              setFieldValue("createdBy", newValue);
            }}
          />
        </div>

        <ATMRadioButtonGroup
          label="Priority"
          required={false}
          value={values.priority}
          options={ticketPriorites}
          allowMultipleSelection
          onChange={(newValue) => {
            setFieldValue("priority", newValue);
          }}
        />

        {/* Type */}
        <ATMRadioButtonGroup
          required={false}
          label="Type"
          allowMultipleSelection
          value={values.type}
          options={ticketTypes}
          onChange={(newValue) => setFieldValue("type", newValue)}
        />

        {/* Status */}
        <div>
          <ATMSelect
            name="status"
            label="Status"
            placeholder="Select Status"
            value={values.status}
            options={ticketStatusOptions}
            onChange={(newValue) => {
              setFieldValue("status", newValue || "");
            }}
          />
        </div>

        {/* <div>
          <ATMSelect
            label="Tags"
            name="tags"
            value={values.tags}
            placeholder="Select Tags"
            options={tagsOptions}
            onChange={(newValue) => setFieldValue("tags", newValue)}
          />
        </div> */} 
         <div className="mr-3">
          <ATMMultiSelect
            name="tags"
            value={values?.tags}
            label="Tags"
            placeholder="Select Tags"
            isLoading={tagsIsLoading}
            options={tagsOptions}
            onChange={(newValue) => {
              setFieldValue("tags", newValue);
            }}
            // options={codioticUsers?.map(
            //   (ele: CodioticUserListResponse) => {
            //     return {
            //       label: ele?.name,
            //       value: ele?._id,
            //     };
            //   }
            // )}
          />
        </div>
        {/* Tags Is Not */}
        <div className="mr-3">
          <ATMMultiSelect
            name="tagsNot"
            value={values?.tagsNot}
            label="Tags Is Not"
            placeholder="Select Tags Is Not"
            isLoading={tagsIsLoading}
            options={tagsOptions}
            onChange={(newValue) => {
              setFieldValue("tagsNot", newValue);
            }}
            // options={codioticUsers?.map(
            //   (ele: CodioticUserListResponse) => {
            //     return {
            //       label: ele?.name,
            //       value: ele?._id,
            //     };
            //   }
            // )}
          />
        </div>

        {/* Created At */}
        <div className="mb-2">
          <div className="text-slate-500 font-medium text-sm"> Created At </div>
          <div className="flex gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                maxDate={moment().format("yyyy-MM-DD")}
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>

        {/*Change Request*/}
        <div className="">
          <ATMSelect
            label="Change Request"
            name="changeRequestId"
            isLoading={
              changeRequestDataIsLoading || changeRequestDataIsFetching
            }
            value={values?.changeRequestId}
            options={ChangeRequestOption}
            placeholder="Select Change Request"
            onChange={(newValue) => {
              setFieldValue("changeRequestId", newValue);
            }}
          />
        </div>

        {/* Client Side Ticket Filter */}
        <div className="">
          <ATMSelect
            label="Client Side Ticket Status"
            name="clientSideTicketStatus"
            value={values.clientSideTicketStatus}
            options={clientSideTicketStatusOption}
            placeholder="Select Client Status"
            onChange={(newValue) => {
              setFieldValue("clientSideTicketStatus", newValue);
            }}
          />
        </div>

        {/* Project */}

        {/* Archived  */}
        <div className="">
          <ATMCheckbox
            label="Archived Tickets"
            name="isArchived"
            checked={values.isArchived}
            onChange={(checked) => {
              setFieldValue("isArchived", checked);
            }}
          />
        </div>
        <div className="">
          <ATMCheckbox
            label="Resource Required"
            name="resosurceRequired"
            checked={values.resosurceRequired}
            onChange={(checked) => {
              setFieldValue("resosurceRequired", checked);
            }}
          />
        </div>
      </div>
  </MOLFilterDialog>
  );
};

export default TicketListFilterFormDialog;
