import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import LeaveRequestApprovalForm from "./LeaveRequestApprovalForm";

type Props = {
  onClose: () => void;
  handleApprovalSubmit: any;
};

const LeaveRequestApprovalFormWrapper = ({
  onClose,
  handleApprovalSubmit,
}: Props) => {
  // Form Initial Values

  const initialValues: any = {
    approvalStatus: "DECLINED",
    declinedReason: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    declinedReason: string().required("Please enter reason"),
  });

  // Handle Submit
  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    handleApprovalSubmit({
      values: values,
      afterSubmit: () => {
        resetForm();
        setSubmitting(false);
        onClose();
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <LeaveRequestApprovalForm
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default LeaveRequestApprovalFormWrapper;
