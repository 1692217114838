import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
// import { number, object, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { useCreateETATicketMutation } from "src/services/TicketServices";
import { array, number, object } from "yup";
import { useGetAllLayersQuery } from "src/services/ProjectServices";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import CreateETAModal from "../Layout/CreateETAModal";

type Props = {
  ticketId: string;
  open: boolean;
  handleClose: () => void;
};

export type FormInitialValues = {
  layerWiseEst: any;
  numberOfMembers: number;
};

const CreateETAModalWrapper = ({
  ticketId,
  open = false,
  handleClose,
}: Props) => {
  // user options

  const { singleItem } = useSelector((state: RootState) => state.ticket);

  const [createETAForTicket] = useCreateETATicketMutation();
  const [layerOptions, setLayerOptions] = useState([]);

  // Form Initial Values
  const initialValues: FormInitialValues = {
    layerWiseEst: layerOptions.map((layerName: any) => {
      return {
        layerName: layerName?.layerName,
        estTime: 0,
      };
    }),
    numberOfMembers: 1,
  };

  // Validation Schema
  const validationSchema = object().shape({
    layerWiseEst: array().of(
      object().shape({
        estTime: number()
          .typeError("Estimate time must be a number")
          .positive("Estimate time must be a positive number")
          .integer("Estimate time must be an integer")
          .required("Estimate time is required"),
      })
    ),
    numberOfMembers: number().required("Number of members is required"),
  });

  const {
    data: layers,
    isLoading: isLayerLoading,
    isFetching: isLayerFetching,
  } = useGetAllLayersQuery(singleItem[0]?.projectId || "");

  // Setting Team Members Data
  useEffect(() => {
    if (!isLayerLoading && !isLayerFetching) {
      setLayerOptions(layers?.data?.layer || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, isLayerLoading, isLayerFetching]);

  const handleSubmit = (
    values: FormInitialValues,
    { setSubmitting, resetForm }: FormikHelpers<FormInitialValues>
  ) => {
    // Convert estTime values to numbers
    const valuesWithNumberEstTime = {
      ...values,
      layerWiseEst: values.layerWiseEst?.map((layer: any) => ({
        ...layer,
        estTime: Number(layer.estTime), // Convert estTime to number
      })),
    };

    createETAForTicket({
      ticketId: ticketId,
      body: valuesWithNumberEstTime,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <CreateETAModal
              formikProps={formikProps}
              handleClose={handleClose}
              formType="ADD"

            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateETAModalWrapper;
