import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";

type Props = {
  onDateChange: (value: any) => void;
  salarySlipInfo: any;
  dateFilter: null | string | Date;
  onSalarySlipClick: (salaryId: string) => void;
};
const SalarySlipListing = ({
  onDateChange,
  salarySlipInfo,
  dateFilter,
  onSalarySlipClick,
}: Props) => {
  return (
    <div className="p-8">
      <div className="grid grid-cols-4 gap-3">
        <ATMDatePicker
          label="Select Year and month"
          format="MM/YYYY"
          value={dateFilter}
          onChange={(newValue) => onDateChange(newValue)}
          views={["year", "month"]}
        />
        {/* <ATMDatePicker
          label="Month"
          format="MM/YYYY"
          value={dateFilter?.month}
          onChange={(newValue) => onMonthChange(newValue)}
          views={["month"]}
          disabled={!dateFilter?.year} // Disable month picker until a year is selected
        /> */}
      </div>
      {salarySlipInfo?.length ? (
        <div className="grid grid-cols-4 gap-4 pt-4">
          {salarySlipInfo?.map((salaryInfo: any) => {
            return (
              <div
                onClick={() => onSalarySlipClick(salaryInfo?._id)}
                className="relative flex flex-col items-center justify-center w-full h-full gap-4 px-3 pt-3 pb-3 mt-3 border-2 border-dashed rounded cursor-pointer border-slate-400 "
              >
                <div className="font-semibold text-slate-500">
                  {/* {salaryInfo?.effectiveDate
                    ? moment(salaryInfo?.effectiveDate).format("MMMM YYYY")
                    : "-"} */}
                  {salaryInfo?.month} {salaryInfo?.year}

                </div>
                <div className="flex gap-4 text-xs">
                  <div className="font-semibold text-slate-500">
                    <p>Net Salary:</p>
                    <p>Pay Date:</p>
                  </div>
                  <div className="font-semibold text-slate-600">
                    <p> &#8377; {salaryInfo?.netAmount}</p>
                    <p>
                      {/* {salaryInfo?.effectiveDate
                        ? moment(salaryInfo?.effectiveDate).format(
                            "DD MMMM YYYY"
                          )
                        : "-"} */}
                      {salaryInfo?.month} {salaryInfo?.year}

                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center w-full pt-10">No Data Found</div>
      )}
    </div>
  );
};

export default SalarySlipListing;
