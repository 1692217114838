import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import SkillForm from "../Layouts/SkillForm";
import { useAddSkillMutation } from "src/services/SkillService";
import { showToast } from "src/utils/showToaster";

export type SkillFormValues = {
  skillName: string;
};

type Props = {
  onClose: () => void;
};

const AddSkillFormWrapper = ({ onClose }: Props) => {
  const [addSkill] = useAddSkillMutation();

  // Form Initial Values
  const initialValues: SkillFormValues = {
    skillName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    skillName: string().required("Please enter skill"),
  });

  // Handle Submit
  const handleSubmit = (
    values: SkillFormValues,
    { setSubmitting, resetForm }: FormikHelpers<SkillFormValues>
  ) => {
    addSkill(values).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <SkillForm
            formikProps={formikProps}
            onClose={onClose}
            formType="ADD"
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddSkillFormWrapper;
