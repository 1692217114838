import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { MeetingListResponse } from "src/models/Meeting.model";
import {
  useGetMeetingByIdQuery,
  useUpdateAttendeesMeetingMutation,
} from "src/services/MeetingServices";
import AttendeesTab from "./AttendeesTab";
import { object } from "yup";
import { showToast } from "src/utils/showToaster";

type Props = {};

export type AddAttendeesFormValues = {
  actualClientUsers?: string[];
  actualCodioticUsers?: any;
};

const AttendeesTabWrapper = (props: Props) => {
  const { meetingId: MEETING_ID } = useParams();
  const [meetingData, setMeetingData] = useState<MeetingListResponse>();

  const { isLoading, isFetching, data } = useGetMeetingByIdQuery(
    MEETING_ID || "",
    {
      skip: !MEETING_ID,
    }
  );

  const [updateAttendees] = useUpdateAttendeesMeetingMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setMeetingData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: AddAttendeesFormValues = {
    // actualClientUsers: meetingData?.actualClientUsers?.map((ele) => {
    //   return {
    //     label: ele?.name,
    //     value: ele?._id,
    //   };
    // }),
    actualClientUsers: [],
    actualCodioticUsers: meetingData?.actualCodioticUsers?.map((ele) => {
      return {
        label: ele?.name,
        value: ele?._id,
      };
    }),
  };

  // Validation Schema
  const validationSchema = object().shape({});

  // Handle Submit
  const handleSubmit = (
    values: AddAttendeesFormValues,
    { setSubmitting }: FormikHelpers<AddAttendeesFormValues>
  ) => {
    const formatedValues = {
      actualClientUsers: values?.actualClientUsers,
      // actualClientUsers: values?.actualClientUsers?.map((ele) => ele?.value),
      actualCodioticUsers: values?.actualCodioticUsers?.map(
        (ele: any) => ele?.value
      ),
    };

    updateAttendees({ id: MEETING_ID, body: formatedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AttendeesTab
            formikProps={formikProps}
            clientUsersList={meetingData?.actualClientUsers || []}
            codioticUsersList={meetingData?.actualCodioticUsers || []}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AttendeesTabWrapper;
