import React, { useEffect, useState } from "react";
// import moment from "moment";
// import { object, string } from "yup";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import LeadsFilterFormCardDialog from "./LeadsFilterFormCardDialog";
import {
  setLeadLabelFilterValue,
  setFollowUpDateFilterValue,
  setStarLeads
} from "src/redux/slices/LeadsListingSlice";
import moment from "moment";
import { useGetAllLabelsAllProjectQuery } from "src/services/ProjectServices";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  leadLabel: any;
  followUpdate: any;
  starLeads:any;
};

const LeadsFilterFormCardDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const { data, isLoading, isFetching } = useGetAllLabelsAllProjectQuery("");
  const [items, setItems] = useState<any>([]);
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data ? data?.data : []);
    }
  }, [isLoading, isFetching, data]);

  const allLeadState = useSelector((state: RootState) => state.leads);
  const { leadLabel, followUpdate , starLeads } = allLeadState;

  // Initial Values
  const initialValues: FormInitialValues = {
    leadLabel: leadLabel,
    followUpdate: followUpdate,
    starLeads:{
      label:starLeads ? 'YES' : starLeads === false ? 'NO' : '',
      value: starLeads ? 'YES' : starLeads === false ? 'NO' : ''
    }
  };

  // const validationSchema: any = object({
  //   startDate: string().required("please select start date"),
  //   endDate: string().required("please select end date"),
  // });

  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);

    // set codiotic user filter value
    dispatch(setLeadLabelFilterValue(values.leadLabel));

    // set task due date filter value
    dispatch(
      setFollowUpDateFilterValue(
        values.followUpdate
          ? moment(values.followUpdate)?.format("yyyy-MM-DD")
          : ""
      )
    );

    //set star Leads
    dispatch(
      setStarLeads(values?.starLeads?.value)
    ) ;

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // set task due date filter value
    await dispatch(setLeadLabelFilterValue(null));

    //  set project value
    await dispatch(setFollowUpDateFilterValue(""));
     //set star Leads
     await dispatch(
      setStarLeads('')
    )

    // reset formik props
    formikProps.resetForm();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <LeadsFilterFormCardDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            AllLabels={items}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default LeadsFilterFormCardDialogWrapper;
