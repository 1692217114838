import React, { useEffect } from "react";
import { useGetAllAttendanceTeamListingQuery } from "src/services/AttendanceService";
import { RootState } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/TeamAttendanceSlice";
import TeamAttendance2 from "./TeamAttendance2";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";

const TeamAttendanceWrapper = () => {
  const dispatch = useDispatch();

  const {
    items,
    page,
    month,
    year,
    searchValue,
    isTableLoading,
    // selectedClient,
  } = useSelector((state: RootState) => state.teamAttendance);

  const { data, isLoading, isFetching } = useGetAllAttendanceTeamListingQuery({
    limit: 10,
    searchValue: searchValue,
    year: Number(year?.value),
    month: Number(month?.value),
    params: ["_id", "name"],
    page: page,
    filterBy: [],
    dateFilter: {
      dateFilterKey: "dateWiseAttendance.date",
      startDate: "",
      endDate: "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <>
      <SideNavLayout>
        <TeamAttendance2
          items={items}
          isTableLoading={isTableLoading}
          month={month}
          year={year}
        />
      </SideNavLayout>
    </>
  );
};

export default TeamAttendanceWrapper;
