import React from "react";
import { PiUserFill } from "react-icons/pi";
import ColorComponent from "src/components/UI/colorComponent";

const TicketTimeAnalysis = ({ ticketTimeAnalysisData }: any) => {
  return (
    <div className="flex flex-col bg-white h-full">
      {ticketTimeAnalysisData?.length ? (
        // eslint-disable-next-line array-callback-return
        ticketTimeAnalysisData?.map((user: any) => {
          if (user?.status === "IN_PROGRESS" && user?.users?.length) {
            return (
              <>
                <div className="p-4 border-b">
                  <p className="text-md flex items-center">
                    <span className="">Total Duration In Ticket :</span>
                    {user?.totalDurationInMinutes ? (
                      <p className="font-medium">
                        <span className="ml-1">
                          {`${Math?.floor(
                            user?.totalDurationInMinutes / 60
                          )}:${(
                            user?.totalDurationInMinutes % 60
                          )?.toFixed()}  hrs`}
                        </span>
                        <span className="text-sm text-neutral ml-1">
                          ({`${user?.totalDurationInMinutes?.toFixed()}`} min)
                        </span>
                      </p>
                    ) : (
                      "0 min"
                    )}
                    {/* {user?.totalDurationInMinutes
                      ? `${user?.totalDurationInMinutes?.toFixed()} min (${Math?.floor(
                          user?.totalDurationInMinutes / 60
                        )}:${(
                          user?.totalDurationInMinutes % 60
                        )?.toFixed()}  hrs)`
                      : `0 min`} */}
                  </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full bg-gray-50 p-4 h-full">
                  {user?.users?.map((el: any, ind: any) => {
                    return (
                      <div
                        key={ind}
                        className="flex flex-col gap-6 border bg-white h-fit items-center rounded-md p-4"
                      >
                        <div className="flex flex-col items-center gap-2">
                          <div className="border p-2 rounded-full aspect-square">
                            <PiUserFill
                              size={16}
                              className="rounded-full mx-auto"
                            />
                          </div>
                          <div className="flex flex-col items-center">
                            <div className="text-lg font-medium">
                              <ColorComponent
                                inputString={el?.codioticUserName}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="font-medium flex items-center gap-1">
                          <span className="text-lg">
                            {`${Math?.floor(el?.durationInMinutes / 60)}:${(
                              el?.durationInMinutes % 60
                            )?.toFixed()}  hrs`}
                          </span>

                          <span className="text-sm text-neutral">
                            ({`${el?.durationInMinutes?.toFixed()}`} min)
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          }
        })
      ) : (
        <>
          {!ticketTimeAnalysisData?.length && (
            <div className="flex justify-center items-center h-80 text-[22px] text-red-700 font-semibold">
              Data not Found{" "}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TicketTimeAnalysis;
