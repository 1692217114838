/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { AddAttendeesFormValues } from "./AttendeesTabWrapper";
import { FormikProps } from "formik";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

// MODELS
import { CodioticUserListResponse } from "src/models/CodioticUser.model";

// HOOKS
// import { useGetAllClientUser } from "src/hooks/useGetAllClientUser";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
// import { IoIosRemoveCircle } from "react-icons/io";
import { showToast } from "src/utils/showToaster";
import { TbUserMinus } from "react-icons/tb";

type Props = {
  formikProps: FormikProps<AddAttendeesFormValues>;
  clientUsersList: string[];
  codioticUsersList: {
    name: string;
    _id: string;
  }[];
};

const AttendeesTab = ({
  formikProps,
  clientUsersList,
  codioticUsersList,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  // local
  const [clientUsersLocalList, setClientLocalUsersList] =
    useState<string[]>(clientUsersList);

  const [clientUserName, setClientUserName] = useState<string>("");
  // get all client user hook
  // const { allClientUser, isDataLoading: isClientUserDataLoading } =
  // useGetAllClientUser();

  // get all codiotic user hook
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  useEffect(() => {
    setClientLocalUsersList(clientUsersList);
  }, [clientUsersList]);

  return (
    <div>
      <div className="p-4">
        <div className="flex flex-col gap-4 md:flex-row">
          {/* Client User */}
          <div className="flex flex-col min-w-[250px] rounded-md custom-shadow">
            <div className="p-2 border-b">
              <ATMTextField
                className="mb-1 rounded-[4px]"
                required
                name="actualClientUsers"
                label="Client User"
                placeholder="Select Client User"
                value={clientUserName}
                onChange={(e) => {
                  setClientUserName(e.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    // Trigger the onChange callback or perform any other action
                    if (!clientUsersLocalList?.includes(clientUserName)) {
                      setClientLocalUsersList([
                        ...clientUsersLocalList,
                        clientUserName.trim(),
                      ]);
                      setFieldValue("actualClientUsers", [
                        ...clientUsersLocalList,
                        clientUserName.trim(),
                      ]);
                      setClientUserName("");
                    } else {
                      showToast("error", "name already exist");
                    }
                  }
                }}
              />
            </div>
            {/* Client User */}
            <div>
              {/* <h1 className="font-bold text-md"> Client User </h1> */}
              <ol className="list-decimal list-inside">
                {clientUsersLocalList?.map((ele: any, index: any) => {
                  return (
                    <li
                      key={index}
                      className="relative px-2 py-2 cursor-pointer hover:bg-hover group/list border-b-[1px]"
                    >
                      {ele}{" "}
                      <span
                        onClick={() => {
                          const newFilterd = clientUsersLocalList?.filter(
                            (userName: any) => {
                              if (userName !== ele) {
                                return userName;
                              }
                            }
                          );
                          setClientLocalUsersList(newFilterd);
                          setFieldValue(
                            "actualClientUsers",
                            newFilterd
                          );
                        }} 

                        className="absolute flex items-center invisible gap-2 px-2 py-1 text-sm transform -translate-y-1/2 rounded-md cursor-pointer group-hover/list:visible right-2 top-1/2 hover:bg-primary-dark hover:text-white"
                      >
                        <TbUserMinus />
                        Remove
                      </span>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>

          {/* Codiotic User */}
          <div className="flex flex-col min-w-[250px] rounded-md custom-shadow">
            <div className="p-2 py-3 border-b">
              <ATMMultiSelect
                required
                name="actualCodioticUsers"
                label="Codiotic User"
                placeholder="Select Codiotic User"
                value={values.actualCodioticUsers}
                isLoading={isCodioticUserDataLoading}
                options={codioticUsers?.map((ele: CodioticUserListResponse) => {
                  return {
                    label: ele?.name,
                    value: ele?._id,
                  };
                })}
                onChange={(newValue) => {
                  setFieldValue("actualCodioticUsers", newValue);
                  setFieldValue("actualClientUsers", clientUsersLocalList);
                }}
              />
            </div>
            <div>
              {/* <h1 className="font-bold text-md"> Codiotic User </h1> */}
              <ol className="list-decimal list-inside">
                {codioticUsersList?.map((ele, ind) => {
                  return (
                    <li
                      key={ind}
                      className="border-b-[1px] border-slate-300 px-2 py-2 last:border-none"
                    >
                      {ele?.name}
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end px-4 mt-7">
        <ATMLoadingButton
          type="button"
          isLoading={isSubmitting}
          className="w-[10%]"
          onClick={() => handleSubmit()}
        >
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default AttendeesTab;
