import { Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";

type Props = {
  heading: any;
  subHeading?: any;
  children: React.ReactNode;
  headingOnClick?: any;
  subHeadingOnClick?: any;
  headingExtraClass?: any;
  isOpen?: boolean; // New prop
  extraClass?: any;
  editable?: any;
  onUpdate?: (value: any) => void;
  inputValue?: any;
};

const ATMAccordian = ({
  subHeadingOnClick,
  subHeading,
  heading,
  children,
  headingOnClick,
  headingExtraClass,
  extraClass,
  isOpen = false, // Default value
  editable = false,
  inputValue,
  onUpdate,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(!isOpen);

  useEffect(() => {
    setIsCollapsed(!isOpen);
  }, [isOpen]);

  const toggleCollapsed = () => {
    setIsCollapsed((prev) => !prev);
  };
  return (
    <div className={`flex flex-col rounded ${extraClass}`}>
      <div
        className={`p-1 px-1.5 h-fit flex items-center gap-2 ${headingExtraClass}`}
      >
        <div
          onClick={toggleCollapsed}
          className={`${
            isCollapsed ? "" : "bg-white rounded-md rotate-90"
          } transition-all cursor-pointer h-full`}
        >
          <BiChevronRight size={20} />
        </div>
        <div className="flex items-center justify-between w-full gap-2 group">
          <div
            onClick={headingOnClick}
            className={`cursor-pointer overflow-hidden col-span-9 w-full`}
          >
            {editable ? (
              <input
                placeholder="groupname"
                value={inputValue}
                onBlur={(e) => {
                  onUpdate?.(e.target?.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onUpdate?.(e.currentTarget?.value);
                  }
                }}
                className="outline-none"
              />
            ) : (
              <Tooltip title={heading}>
                <span className={`font-medium text-sm  truncate capitalize `}>
                  {" "}
                  {heading}{" "}
                </span>
              </Tooltip>
            )}
          </div>
          <div
            onClick={subHeadingOnClick}
            className={`col-span-3 cursor-pointer group-hover:opacity-100 opacity-0 transition-all `}
          >
            <span className={`font-medium text-xl`}> {subHeading} </span>
          </div>
        </div>
      </div>
      {isCollapsed ? null : <div className="p-1 bg-white">{children}</div>}
    </div>
  );
};

export default ATMAccordian;
