import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PayoutFormProps } from "src/models/Payout.model";
import { RootState } from "src/redux/store";
import { useAddPayoutMutation, useGetSalaryConfigQuery, useGetWorkingDayQuery } from "src/services/PayoutService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import JobCreationFormLayout from "../Layout/PayoutLayout";

const AddPayoutFormWrapper = ({ onClose }: any) => {

  const [addPayout] = useAddPayoutMutation();
  // Form Initial Values

  const { getSelectedUser, month, year } = useSelector((state: RootState) => state.payout);

  const { data, isLoading, isFetching } = useGetSalaryConfigQuery(getSelectedUser, { skip: !getSelectedUser });
  const { data: getworkingDayData, isLoading: isWorkingDayLoading, isFetching: isWorkingDayFetching } = useGetWorkingDayQuery({
    teamMemberId: getSelectedUser,
    year: year ? moment(year).format('YYYY') : moment().format('YYYY'),
    month: month ? moment(month).format('MMMM') : moment().format('MMMM')
  },
    { skip: !getSelectedUser });

  const [userData, setUserData] = useState<any>()
  const [userWorkingDayData, setUserWorkingDayData] = useState<any>()

  useEffect(() => {
    if (!isLoading || !isFetching) {
      (setUserData(data?.data || []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (!isWorkingDayLoading || !isWorkingDayFetching) {
      (setUserWorkingDayData(getworkingDayData?.data || []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getworkingDayData, isWorkingDayLoading, isWorkingDayFetching]);


  const initialValues: PayoutFormProps = {
    teamMemberId: '',
    year: moment().startOf('year').toDate() || null,
    month: moment().startOf('month').toDate() || null,
    totalWorkingDays: '',
    daysOfAbsent: '',
    netAmount: '',
    earning: [],
    deducation: [],
  };

  // Validation Schema
  const validationSchema = object({
    teamMemberId: object().required('Please select team member'),
    year: string().required(' Required'),
    month: string().required('Required'),
    totalWorkingDays: string().required('Required'),
    daysOfAbsent: string().required('Please total absent day Required'),
    netAmount: string().required('Required'),
    earning: array().of(
      object({
        labelName: string().required('Required'),
        value: string().required('Required'),
      })
    ),
    deducation: array().of(
      object({
        labelName: string().required('Required'),
        value: string().required('Required'),
      })
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: PayoutFormProps,
    { setSubmitting, resetForm }: FormikHelpers<PayoutFormProps>
  ) => {
    const formattedValues = {
      teamMemberId: values?.teamMemberId?.value,
      year: values?.year ? moment(values?.year).format('YYYY') : '',
      month: values?.month ? moment(values?.month).format('MMMM') : '',
      totalWorkingDays: values?.totalWorkingDays,
      daysOfAbsent: values?.daysOfAbsent,
      netAmount: values?.netAmount,
      earnings: values?.earning?.map((el: any) => el),
      deductions: values?.deducation?.map((el: any) => el),
    };
    addPayout(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          onClose()
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <JobCreationFormLayout
            userData={userData}
            userWorkingDayData={userWorkingDayData}
            formType="ADD"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddPayoutFormWrapper;
