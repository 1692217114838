import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  OnConnect,
  Node,
  Edge,
  Controls,
  MiniMap,
  Background,
  BackgroundVariant,
  OnConnectStart,
  OnConnectEnd,
  useReactFlow,
  NodeMouseHandler,
  EdgeMouseHandler,
  MarkerType,
} from "reactflow";

import "reactflow/dist/style.css";
import ATMTextUpdaterNode from "../../atoms/ATMTextUpdaterNode/ATMTextUpdaterNode";
import { v4 } from "uuid";
import ATMDownloadFlow from "../../atoms/ATMDownloadFlow/ATMDownloadFlow";
import MOLFlowToolbar, { ShapeName } from "../MOLFlowToolbar/MOLFlowToolbar";

const getId = () => {
  return v4();
};

type NodeData = { label: string; bgColor: string; shape: ShapeName };
type Props = {
    initialNodes?: Node<NodeData>[];
    onSubmit:(nodes:Node[] , edges: Edge[] )=>void ;
  initialEdges:Edge[];
};

const nodetypes = { rectNode: ATMTextUpdaterNode };

const MOLFlowChart = ({
    initialNodes,
  initialEdges,
  onSubmit
}: Props) => {
  const reactFlowWrapper = useRef<any>(null);
  const connectingNodeId = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const edgeLabelRef = useRef<any>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges); 

  useEffect(()=>{
   setNodes(initialNodes || [])
   setEdges(initialEdges || [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[initialEdges , initialNodes])

  const focus = () => {
    inputRef?.current?.focus();
  }; 

  const [seletedNodeId, setSeletedNodeId] = useState<string>("");
  const [selectedEdgeId, setSelectedEdgeId] = useState<string>("");

  const { screenToFlowPosition } = useReactFlow();

  const onConnectStart: OnConnectStart = useCallback(
    (_, { nodeId, handleId, handleType }) => {
      connectingNodeId.current = { nodeId, handleId };
    },
    []
  );

  const onConnect: OnConnect = useCallback((params:any) => {
    // reset the start node on connections
    connectingNodeId.current = null;
    setEdges((eds) => addEdge(params, eds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConnectEnd: OnConnectEnd = useCallback(
    (event: any) => {
      if (!connectingNodeId.current?.nodeId) return;

      const targetIsPane =
        event?.target?.classList?.contains("react-flow__pane");

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
        const edgeId = getId();
        const newNode: Node<NodeData> = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          data: { label: `Node`, bgColor: "bg-white", shape: "Rectangle" },
          type: "rectNode",
          selected: true,
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({
            id: edgeId,
            source: connectingNodeId.current?.nodeId,
            target: id,
            sourceHandle: connectingNodeId.current?.handleId,
            targetHandle: "c",
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: "gray",
            },
            style: {
              stroke: "gray",
              strokeWidth: 2,
            },
            selected: true,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [screenToFlowPosition]
  );

  const handleNodeDoubleClick: NodeMouseHandler = (e:any, node:any) => {
    setSeletedNodeId(node?.id);
    focus();
  };

  const handleEdgeClick: EdgeMouseHandler = (e:any, edge:any) => {
    setSelectedEdgeId(edge?.id);
    edgeLabelRef?.current?.focus();
  };

  return (
    <div className="flex w-full h-full">
      {/* Flow Chart */}
      <div className="flex-1 h-full" ref={reactFlowWrapper}>
        <ReactFlow
          id="react-flow-container"
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          fitView
          nodeTypes={nodetypes}
          nodeOrigin={[0.5, 0]}
          onNodeDoubleClick={handleNodeDoubleClick}
          defaultEdgeOptions={{
            type: "smoothstep",
            animated: false,
          }}
          onEdgeDoubleClick={handleEdgeClick}
          snapToGrid
          snapGrid={[20, 20]}
        >
          <Controls />
          <MiniMap />
          <Background variant={BackgroundVariant.Cross} gap={20} size={2} />
          <ATMDownloadFlow />
        </ReactFlow> 
       
      </div>

      {/* Toolbar */}
      <MOLFlowToolbar
        selectedNodeId={seletedNodeId}
        selectedEdgeId={selectedEdgeId}
        inputRef={inputRef}
        edgeLabelRef={edgeLabelRef}
        nodes={nodes}
        edges={edges} 
        onSubmit={onSubmit}
      /> 

 
    </div>
  );
};

export default MOLFlowChart;
