import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useEditTicketGroupMutation, useGetTicketGroupByIdQuery } from "src/services/TicketGroupServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import { TicketGroupFormValues } from "../Add/AddTicketGroupFormWrapper";
import EditTicketGroupForm from "./EditTicketGroupForm";

type Props = {
  onClose: () => void;
  selectedChangeRequestId: string;
};

const EditTicketGroupFormWrapper = ({
  onClose,
  selectedChangeRequestId,
}: Props) => {
  const [ticketGroupData, setTicketGroupData] = useState<any>();
  const { isLoading, isFetching, data } = useGetTicketGroupByIdQuery(
    selectedChangeRequestId,
    {
      skip: !selectedChangeRequestId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTicketGroupData(data?.data);
    }
  }, [isLoading, isFetching, data]);



  const [updateTicketGroup] = useEditTicketGroupMutation();

  // Form Initial Values
  const initialValues: TicketGroupFormValues = {
    groupName: ticketGroupData?.groupName,

  };

  // Validation Schema
  const validationSchema = object().shape({
    groupName: string().required("Please enter request name")
  });

  // Handle Submit
  const handleSubmit = (
    values: TicketGroupFormValues,
    { setSubmitting, resetForm }: FormikHelpers<TicketGroupFormValues>
  ) => {

    const formatedValues = {
      groupName: values?.groupName
    };

    updateTicketGroup({ id: selectedChangeRequestId, body: formatedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <EditTicketGroupForm
              onClose={onClose}
              formikProps={formikProps}
              formType="EDIT"
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditTicketGroupFormWrapper;
