import React, { useEffect, useState } from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLLayerDropdown from "src/components/UI/molecules/MOLLayerDropdown/MOLLayerDropdown ";
import MOLProjecttDropdown from "src/components/UI/molecules/MOLProjectDropdown/MOLProjectDropdown";
import { AllTaskFormValues } from "src/models/AllTask.model";
import { useGetModulesByProjectIdQuery } from "src/services/ModuleServices";
import { useGetAllRequirementByModuleIdQuery } from "src/services/RequirementService";
import { useGetAllTicketWithoutPaginationQuery } from "src/services/TicketServices";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import moment from "moment";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import { useGetProjectWiseMembers } from "src/hooks/useGetProjectWiseMembers";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

type Props = {
  formikProps: FormikProps<AllTaskFormValues>;
  RelatedToType: any;
  formType?: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  isLoading?: any;
  saveNextChecked?: any;
};

const AllTaskAddForm = ({
  formikProps,
  RelatedToType,
  formType = "ADD",
  isLoading = false,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const formHeading = formType === "ADD" ? "Add New Task" : "Update Task";

  const { values, setFieldValue, handleBlur, isSubmitting } = formikProps;

  const [moduleOptions, setModuleOptions] = useState([]);
  const [featureOptions, setFeatureOptions] = useState([]);
  const [ticketOptions, setTicketOptions] = useState([]);

  // get project wise members
  const { allProjectWiseMembers, isDataLoading } = useGetProjectWiseMembers(
    values?.projectId?._id
  );

  // get module
  const {
    isLoading: isModuleLoading,
    isFetching: isModuleFetching,
    data: moduleData,
  } = useGetModulesByProjectIdQuery(values.projectId?._id || "", {
    skip: !values.projectId?._id,
  });

  //ticket api call
  const {
    isLoading: isTicketLoading,
    isFetching: isTicketFetching,
    data: ticketData,
  } = useGetAllTicketWithoutPaginationQuery("");
  //ticket useefffect

  useEffect(() => {
    if (!isTicketLoading && !isTicketFetching) {
      const filteredOption = ticketData?.data?.map((ele: any) => {
        return {
          label: ele?.title,
          value: ele?._id,
        };
      });
      setTicketOptions(filteredOption);
    }
  }, [isTicketLoading, isTicketFetching, ticketData]);

  //module useefffec
  useEffect(() => {
    if (!isModuleLoading && !isModuleFetching) {
      const filteredOption = moduleData?.data?.map((ele: any) => {
        return {
          label: ele?.moduleName,
          value: ele?._id,
        };
      });
      setModuleOptions(filteredOption);
    }
  }, [isModuleLoading, isModuleFetching, moduleData]);

  // get features
  const {
    isLoading: isFeatureLoading,
    isFetching: isFeatureFetching,
    data: featureData,
  } = useGetAllRequirementByModuleIdQuery(values.moduleId?.value || "", {
    skip: !values.moduleId?.value,
  });

  //feature useeffcet
  useEffect(() => {
    if (!isFeatureLoading && !isFeatureLoading) {
      const filteredOption = featureData?.data?.map((ele: any) => {
        return {
          label: ele?.featureName,
          value: ele?._id,
        };
      });
      setFeatureOptions(filteredOption);
    }
  }, [isFeatureLoading, isFeatureFetching, featureData]);

  // Redux state for login user data
  const { userData } = useSelector((state: RootState) => state?.auth);

  return (
    <>
      <div className="p-4 flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <div className="text-xl font-medium"> {formHeading} </div>
        </div>

        <div className="flex flex-col gap-4">
          {/* title */}
          <div className="">
            <ATMTextArea
              required
              name="title"
              value={values.title}
              onChange={(e) => setFieldValue("title", e)}
              label="Task Title"
              placeholder="Enter Task Title"
            />
          </div>
          {/* description */}
          <div className="z-0">
            <MOLMarkdownEditor
              label="Description"
              value={values.description}
              placeholder="Write description or drag your files here...."
              onChange={(newValue: any) => {
                setFieldValue("description", newValue);
              }}
            />
          </div>

          <div>
            <ATMTextField
              name="estimation"
              value={values.estimation}
              onChange={(e) => setFieldValue("estimation", e.target.value)}
              label="Estimation (in Mins)"
              placeholder="Enter Estimation (in Mins)"
              onBlur={handleBlur}
            />
          </div>
          <div>
            <ATMDatePicker
              label="Due Date"
              name="dueDate"
              format="DD/MM/YYYY"
              minDate={moment().format("yyyy-MM-DD")}
              value={values.dueDate}
              onChange={(newValue) => setFieldValue("dueDate", newValue)}
            />
          </div>
          {/* related to */}
          <div>
            <ATMSelect
              required
              name="relatedTo"
              value={values.relatedTo}
              onBlur={handleBlur}
              label="Select Related To  "
              placeholder="Related To"
              options={RelatedToType}
              renderOption={(option, { context }) => {
                if (context === "menu") {
                  return <div className="capitalize"> {option.label} </div>;
                } else {
                  return <div className="capitalize"> {option.label} </div>;
                }
              }}
              isOptionEqualToValue={(option, selected) => {
                return (
                  selected?.findIndex(
                    (selectedValue: any) =>
                      selectedValue?.value === option.value
                  ) > -1
                );
              }}
              isSearchedOption={(option, searchValue) => {
                return searchValue
                  ? option?.data?.label?.toLowerCase()?.includes(searchValue)
                  : true;
              }}
              onChange={(newValue: any) => {
                setFieldValue("relatedTo", newValue);
                if (newValue.value === "PROJECT") {
                  setFieldValue("ticketId", "");
                  setFieldValue("deploymentId", "");
                } else if (newValue.value === "TICKET") {
                  setFieldValue("projectId", "");
                  setFieldValue("moduleId", "");
                  setFieldValue("featureId", "");
                  setFieldValue("deploymentId", "");
                  setFieldValue("layer", []);
                  setFieldValue("label", []);
                }
              }}
            />
          </div>
          {values?.relatedTo?.value === "PROJECT" ? (
            <>
              {/* projects */}
              <div className=" mt-1">
                <MOLProjecttDropdown
                  required
                  name="projectId"
                  placeholder="Project Type"
                  value={values.projectId}
                  onChange={(newValue) => {
                    setFieldValue("projectId", newValue);
                  }}
                  onBlur={handleBlur}
                />
              </div>

              {/* Module */}
              <div className=" mt-1">
                <ATMSelect
                  name="moduleId"
                  value={values.moduleId}
                  onChange={(newValue: any) => {
                    setFieldValue("moduleId", newValue);
                  }}
                  onBlur={handleBlur}
                  label="Module"
                  placeholder="Select module"
                  options={moduleOptions}
                  isLoading={isModuleLoading || isModuleFetching}
                  renderOption={(option, { context }) => {
                    if (context === "menu") {
                      return <div className="capitalize"> {option.label} </div>;
                    } else {
                      return <div className="capitalize"> {option.label} </div>;
                    }
                  }}
                  isOptionEqualToValue={(option, selected) => {
                    return (
                      selected?.findIndex(
                        (selectedValue: any) =>
                          selectedValue?._id === option._id
                      ) > -1
                    );
                  }}
                  isSearchedOption={(option, searchValue) => {
                    return searchValue
                      ? option?.data?.label
                          ?.toLowerCase()
                          ?.includes(searchValue)
                      : true;
                  }}
                />
              </div>

              {/* Feature */}
              <div className="">
                <ATMSelect
                  name="featureId"
                  value={values.featureId}
                  onChange={(newValue: any) => {
                    setFieldValue("featureId", newValue);
                  }}
                  onBlur={handleBlur}
                  label="Feature"
                  placeholder="Select Feature"
                  options={featureOptions}
                  isLoading={isFeatureLoading || isFeatureFetching}
                  renderOption={(option, { context }) => {
                    if (context === "menu") {
                      return <div className="capitalize"> {option.label} </div>;
                    } else {
                      return <div className="capitalize"> {option.label} </div>;
                    }
                  }}
                  isOptionEqualToValue={(option, selected) => {
                    return (
                      selected?.findIndex(
                        (selectedValue: any) =>
                          selectedValue?._id === option._id
                      ) > -1
                    );
                  }}
                  isSearchedOption={(option, searchValue) => {
                    return searchValue
                      ? option?.data?.label
                          ?.toLowerCase()
                          ?.includes(searchValue)
                      : true;
                  }}
                />
              </div>
              {formType === "EDIT" ? null : (
                <div className="">
                  <MOLLayerDropdown
                    name="layer"
                    placeholder="Layer"
                    isMulti
                    value={values.layer}
                    onChange={(newValue) => {
                      setFieldValue("layer", newValue);
                    }}
                    onBlur={handleBlur}
                    id={values.projectId?._id}
                  />
                </div>
              )}

              <div>
                <ATMSelect
                  name="assignedTo"
                  label="Assignee"
                  placeholder="Select Assigner"
                  value={values.assignedTo}
                  isLoading={isDataLoading}
                  options={allProjectWiseMembers?.members?.map((ele: any) => {
                    return {
                      label: ele?.name,
                      value: ele?._id,
                    };
                  })}
                  renderOption={(option, { context }) => {
                    if (context === "menu") {
                      return (
                        <div className="capitalize flex gap-x-3">
                          {option.label}
                          {option?.value === userData?.userId && (
                            <span className="bg-green-500 py-[2px] flex items-center px-[8px] text-center rounded-full text-white text-[10px]">
                              It's you
                            </span>
                          )}
                        </div>
                      );
                    } else {
                      return <div className="capitalize"> {option.label} </div>;
                    }
                  }}
                  onChange={(newValue) => {
                    setFieldValue("assignedTo", newValue);
                  }}
                />
              </div>
            </>
          ) : null}
          {values?.relatedTo?.value === "TICKET" ? (
            <>
              <div className="">
                <ATMSelect
                  name="ticketId"
                  value={values.ticketId}
                  onChange={(newValue: any) => {
                    setFieldValue("ticketId", newValue);
                  }}
                  onBlur={handleBlur}
                  label="Ticket"
                  placeholder="Select Ticket"
                  options={ticketOptions}
                  isLoading={isTicketLoading || isTicketFetching}
                  renderOption={(option, { context }) => {
                    if (context === "menu") {
                      return <div className="capitalize"> {option.label} </div>;
                    } else {
                      return <div className="capitalize"> {option.label} </div>;
                    }
                  }}
                  isOptionEqualToValue={(option, selected) => {
                    return (
                      selected?.findIndex(
                        (selectedValue: any) =>
                          selectedValue?.value === option.value
                      ) > -1
                    );
                  }}
                  isSearchedOption={(option, searchValue) => {
                    return searchValue
                      ? option?.data?.label
                          ?.toLowerCase()
                          ?.includes(searchValue)
                      : true;
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
        {formType === "ADD" && (
          <div>
            <ATMCheckbox
              label="Save and Next"
              checked={saveNextChecked}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        )}
        <div className="flex justify-end">
          <ATMLoadingButton
            className="w-36 p-2"
            isLoading={isSubmitting}
            type="submit"
          >
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </>
  );
};

export default AllTaskAddForm;
