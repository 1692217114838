import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  addNew: any;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const BusinessList = ({
  rows,
  addNew,
  columns,
  paginationProps: {
    page,
    setPage,
    totalItems,
    searchValue,
    rowsPerPage,
    isTableLoading,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  return (
    <div className="flex flex-col h-full">
      {/* Page Header */}
      <div className="flex justify-between px-2">
        <ATMPageHeader
          pageTitle="Business Models"
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          hideAddButton={!isAuthorized(UserModuleNameTypes.ACTION_BUSINESS_ADD)}
          buttonProps={{
            btnName: "Add New",
            onClick: addNew,
          }}
        />
      </div>

      {/* Table */}
      <div className="flex flex-col overflow-hidden">
        <div className="flex flex-col overflow-auto">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "h-[50px]"}
            isLoading={isTableLoading}
          />
        </div>

        <div>
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessList;
