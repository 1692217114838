import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { IndustryFormValues } from "src/models/Industry.model";

type Props = {
  formikProps: FormikProps<IndustryFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
};

const IndustryForm = ({ formType = "ADD", formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Industry" : "Update Industry"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        {/* industry name */}
        <div className="z-10">
          <ATMTextField
            required
            name="industryName"
            value={values.industryName}
            onChange={(e) => setFieldValue("industryName", e.target.value)}
            label="Industry"
            placeholder="Enter Industry"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default IndustryForm;
