import React, { useState, useEffect } from "react";
import ATMSelect from "../../atoms/formFields/ATMSelect/ATMSelect";
import { useGetProjectsQuery } from "src/services/ProjectServices";

type Props = {
  required?: boolean;
  name?: string;
  value: any;
  onChange: (newValue: any) => void;
  onBlur?: (e: any) => void;
  label?: string;
  placeholder?: string;
  isMulti?: boolean;
};

const MOLProjecttDropdown = ({
  required = false,
  name = "",
  value,
  onChange,
  onBlur,
  label = "Project",
  placeholder = "Select Project",
  isMulti = false,
}: Props) => {
  const [projectOptions, setProjectOptions] = useState([]);

  const {
    data: projects,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
  } = useGetProjectsQuery("");

  // Setting Team Members Data
  useEffect(() => {
    if (!isProjectLoading && !isProjectFetching) {
      setProjectOptions(projects?.data || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, isProjectLoading, isProjectFetching]);


  return (
    <ATMSelect
      required={required}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      isMulti={isMulti}
      placeholder={placeholder}
      options={projectOptions}
      isLoading={isProjectLoading || isProjectFetching}
      renderOption={(option, { context }) => {
        if (context === "menu") {
          return <div className="capitalize"> {option.projectName} </div>;
        } else {
          return <div className="capitalize"> {option.projectName} </div>;
        }
      }}
      isOptionEqualToValue={(option, selected) => {
        return (
          selected?.findIndex(
            (selectedValue: any) => selectedValue?._id === option._id
          ) > -1
        );
      }}
      isSearchedOption={(option, searchValue) => {
        return searchValue
          ? option?.data?.projectName?.toLowerCase()?.includes(searchValue)
          : true;
      }}
    />
  );
};

export default MOLProjecttDropdown;
