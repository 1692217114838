import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import ExpenseForm from '../Layout/ExpenseForm';
import { ExpenseFormValues } from 'src/models/Expense.model';

type Props = {
  onClose: () => void;
};

const EditExpenseWrapper = ({ onClose }: Props) => {
  // Form Initial Values
  const initialValues: ExpenseFormValues = {
    expenseDate: '',
    financeCategoryId: '',
    expenseName: '',
    amount: '',
    currency: '',
    tdsAmount: '',
    category: '',
    account: '',
    gstPercentage: '',
    status: '',
    dueDate: '',
    paymentDate: '',
    modeName: '',
    gstApplicable: false,
    description: '',
    warrantyExpiryDate: null,
    attachments: []
  };

  // Validation Schema
  const validationSchema = object().shape({
    name: string().required('Please enter name'),
    age: string().required('Please enter age'),
  });

  // Handle Submit
  const handleSubmit = (
    values: ExpenseFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ExpenseFormValues>
  ) => {
    setTimeout(() => {
      setSubmitting(false);
      resetForm();
    }, 1000);
  };

  return (
    <Dialog open maxWidth='lg' fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <ExpenseForm
              formikProps={formikProps}
              onClose={onClose}
              formType='EDIT' />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditExpenseWrapper;