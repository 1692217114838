import apiSlice from "./ApiSlice";

export const payoutApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    // GET ALL JOB CREATION 
    getAllPayoutListing: builder.query({
      providesTags: ["payout"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/payout",
          method: "POST",
          body,
        };
      },
    }),

    //ADD JOB CREATION 
    addPayout: builder.mutation({
      invalidatesTags: ["payout"],
      query: (body) => {
        return {
          url: "/payout/add",
          method: "POST",
          body,
        };
      },
    }),

    //GET JOB CREATION  BY ID
    getPayoutById: builder.query({
      providesTags: ["payout"],
      query: (id) => {
        return {
          url: `/payout/${id}`,
          method: "GET",
        };
      },
    }),

    //JOB CREATION UPDATE
    PayoutUpdate: builder.mutation({
      invalidatesTags: ["payout"],
      query: ({ body, id }) => {
        return {
          url: `/payout/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //JOB CREATION DELETE

    deletePayout: builder.mutation({
      invalidatesTags: ["payout"],
      query: (id) => {
        return {
          url: `/payout/${id}`,
          method: "DELETE",
        };
      },
    }),

    //Get Salary Config
    getSalaryConfig: builder.query({
      providesTags: ["payout"],
      query: (id) => {
        return {
          url: `/salary-config/${id}`,
          method: "GET",
        };
      },
    }),
    //Get WORKING DAY Config
    getWorkingDay: builder.query({
      providesTags: ["payout"],
      query: (body) => {
        return {
          url: `/codiotic-user/working-days`,
          method: "POST",
          body
        };
      },
    }),

    //Get Round Tracking Report
    getRoundTrackingReport: builder.query({
      providesTags: ["payout"],
      query: (id) => {
        return {
          url: `/job/report/${id}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllPayoutListingQuery,
  useAddPayoutMutation,
  useGetPayoutByIdQuery,
  usePayoutUpdateMutation,
  useDeletePayoutMutation,
  useGetSalaryConfigQuery,
  useGetWorkingDayQuery,
  useGetRoundTrackingReportQuery
} = payoutApi;
