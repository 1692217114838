import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  setIsOpenAddFormDialog,
  setIsOpenEditFormDialog,
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/BusinessSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useDeleteBusinessModelMutation,
  useGetBusinessModelPaginationQuery,
} from "src/services/BusinessService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import AddBusinessFormWrapper from "../Add/AddBusinessFormWrapper";
import EditBusinessFormWrapper from "../Edit/EditBusinessFormWrapper";
import BusinessList from "./BusinessList";

type Props = {};

const BusinessListingWrapper = (props: Props) => {
  const [selectedBusinessModelId, setSelectedBusinessModelId] =
    useState<string>("");
  const [deleteBusinessModelName] = useDeleteBusinessModelMutation();

  const dispatch = useDispatch<AppDispatch>();
  const {
    page,
    items,
    totalItems,
    rowsPerPage,
    searchValue,
    isTableLoading,
    isOpenEditFormDialog,
    isOpenAddFormDialog,
  } = useSelector((state: RootState) => state.business);

  const { data, isLoading, isFetching } = useGetBusinessModelPaginationQuery({
    limit: rowsPerPage,
    page: page,
    params: ["modelName"],
    searchValue: searchValue,
    dateFilter: {},
    orderByValue: -1,
    orderBy: "createdAt",
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Business Model ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteBusinessModelName(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "modelName",
      headerName: "Model Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "z-0 min-w-[200px]",
      name: UserModuleNameTypes.BUSINESS_LIST_BUSINESS_NAME,
      renderCell: (row) => {
        return <div>{row?.modelName || "-"}</div>;
      },
    },
    {
      field: "industries",
      headerName: "Industry Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "z-0 min-w-[200px]",
      name: UserModuleNameTypes.BUSINESS_LIST_INDUSTRY_NAME,
      renderCell: (row) => {
        return (
          <div className="flex gap-2">
            {row?.industries
              ? row?.industries?.map((industry: any) => (
                  <div className="">{industry?.industryName}</div>
                ))
              : "-"}
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  dispatch(setIsOpenEditFormDialog(true));
                  setSelectedBusinessModelId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_BUSINESS_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_BUSINESS_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <BusinessList
        columns={columns}
        rows={items}
        addNew={() => dispatch(setIsOpenAddFormDialog(true))}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      {isOpenAddFormDialog ? (
        <AddBusinessFormWrapper
          onClose={() => dispatch(setIsOpenAddFormDialog(false))}
        />
      ) : null}
      {isOpenEditFormDialog ? (
        <EditBusinessFormWrapper
          onClose={() => dispatch(setIsOpenEditFormDialog(false))}
          selectedBusinessId={selectedBusinessModelId}
        />
      ) : null}
    </div>
  );
};

export default BusinessListingWrapper;
