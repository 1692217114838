import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { ExpenseFormValues } from "src/models/Expenses.model";
import { FILE_FORMAT } from "src/models/ResourceRequest.model";
import { useAddExpensesMutation } from "src/services/ExpensesService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import ExpenseCategoryForm from "../Layout/ExpensesForm";
import moment from "moment";

type Props = {
  onClose: () => void;
};

const AddExpensesWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addExpenses] = useAddExpensesMutation();

  // Form Initial Values
  const initialValues: ExpenseFormValues = {
    expenseDate: null,
    amount: "",
    accountId: "",
    expenseCategoryId: "",
    GSTApplicable: false,
    billPhoto: [],
    expiryDate: null,
    remark: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    expenseDate: string().required("Please enter expense date"),
    amount: string().required("Please enter amount"),
    accountId: object().required("Please select account"),
    expenseCategoryId: object().required("Please select expense category"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ExpenseFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ExpenseFormValues>
  ) => {
    const formattedValues = {
      expenseDate: values?.expenseDate
        ? moment(values?.expenseDate).format("YYYY-MM-DD")
        : "",
      amount: values?.amount,
      accountId: values?.accountId?.value,
      expenseCategoryId: values?.expenseCategoryId?.value,
      GSTApplicable: values?.GSTApplicable,
      billPhoto: values?.billPhoto?.map(
        (attachment: {
          url: string;
          type: "images" | "pdfs" | "videos" | "xlsx";
        }) => {
          return {
            url: attachment?.url,
            type: FILE_FORMAT[attachment.type],
          };
        }
      ),
      expiryDate: values?.expiryDate
        ? moment(values?.expiryDate).format("YYYY-MM-DD")
        : "",
      remark: values?.remark,
    };

    addExpenses(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ExpenseCategoryForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddExpensesWrapper;
