/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import {  setDateFilter } from "src/redux/slices/UnhandledIncidentSlice";
import UnhandledDateFilterDialog from "./UnhandledDateFilterDialog";
type Props = {
  onClose: () => void;
};



const UnhandledDateFilterDialogWrapper = ({ onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const meetingState = useSelector((state: RootState) => state.unhandledIncident);
  const {
    dateFilter,
  } = meetingState;


  const initialValues: any = {
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
  };


  // Submit Handler
  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    setSubmitting(false);

    // created date from to dispatch
    dispatch(
      setDateFilter({
        startDate: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        endDate: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
          dateFilterKey : 'expenseDate'
      })
    );

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<any>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        startDate: "",
        endDate: "",
      })
    );

    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <UnhandledDateFilterDialog
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UnhandledDateFilterDialogWrapper;
