import apiSlice from "./ApiSlice";

export const IncidentTypeServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // List Event Type
    AllIncidentEventType: builder.query({
      providesTags: ["incident"],
      query: (body) => ({
        url: "/type",
        method: "POST",
        body,
      }),
    }),

    // ADD Event Type
    addEventType: builder.mutation({
      invalidatesTags: ["incident"],
      query: (body) => ({
        url: "/type/add",
        method: "POST",
        body,
      }),
    }),
    // Get Event Type
    getEventType: builder.query({
      providesTags: ["incident"],
      query: (id: string) => {
        return {
          url: `/type/${id}`,
          method: "GET",
        };
      },
    }),

    // Update Event Type
    updateEventType: builder.mutation({
      invalidatesTags: ["incident"],
      query: ({ id, body }) => {
        return {
          url: `/type/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // Get Event Type
    getAllEventType: builder.query({
      providesTags: ["incident"],
      query: (id: string) => {
        return {
          url: `/type`,
          method: "GET",
        };
      },
    }),
    //DELETE Event Type
    deleteType: builder.mutation({
      invalidatesTags: ["incident"],
      query: (id) => {
        return {
          url: `/type/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useAllIncidentEventTypeQuery,
  useAddEventTypeMutation,
  useGetEventTypeQuery,
  useUpdateEventTypeMutation,
  useGetAllEventTypeQuery,
  useDeleteTypeMutation,
} = IncidentTypeServiceApi;
