import React from "react";
import { IconType } from "react-icons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { RiChatFollowUpLine } from "react-icons/ri";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import {
  setCodioticUserByFilter,
  setProjectValueFilter,
  setModuleValueFilter,
  setFeatureValueFilter,
} from "src/redux/slices/DoubtSlice";
import { useDispatch } from "react-redux";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
type Props = {};

export const DoubtTabs: {
  label: string;
  icon: IconType;
  path: string;
  name: string;
}[] = [
    {
      label: "OPEN",
      icon: RiChatFollowUpLine,
      name: UserModuleNameTypes.ACTION_DOUBTS_OPEN_LIST,
      path: "open",
    },
    {
      label: "CLOSE",
      icon: FaUser,
      name: UserModuleNameTypes.ACTION_DOUBTS_CLOSE_LIST,
      path: "close",
    },
  ];

const ViewDoubtsLayout = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const currentPath = location.pathname.split("/")[2];
  const handleResetState = () => {
    // set User filter filter dispatch
    dispatch(setCodioticUserByFilter(""));

    //  set project value
    dispatch(setProjectValueFilter(""));

    // set module value
    dispatch(setModuleValueFilter(""));

    // set feature value
    dispatch(setFeatureValueFilter(""));
  };
  const { userData } = useGetLocalStorage();

  React.useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      navigate("open");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of DoubtTabs) {
      const isValue = isAuthorized(nav?.name as keyof typeof UserModuleNameTypes);
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SideNavLayout>
      <div className="flex flex-col gap-2 pt-3 h-full">
        {/* DoubtTabs */}
        {DoubtTabs?.length && (
          <div className="flex gap-3 items-center  mx-4  border-b border-slate-400 bg-white ">
            {DoubtTabs?.filter((nav) => {
              return isAuthorized(nav?.name as keyof typeof UserModuleNameTypes);
            })?.map((tab: any, index: any) => {
              return (
                <div key={index}>
                  <button
                    type="button"
                    onClick={() => {
                      navigate(tab.path);
                      handleResetState();
                    }}
                    className={`h-full px-5 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${currentPath === tab.path?.split("/")[0]
                        ? "text-primary-main   border-primary-main"
                        : "text-gray-700 border-white"
                      }
         `}
                  >
                    <div className=" text-lg  ">
                      <tab.icon />
                    </div>
                    {tab.label}
                  </button>
                </div>
              );
            })}
          </div>
        )}

        {/* Outlet */}
        <div className=" flex-1 flex flex-col overflow-auto ">
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </SideNavLayout>
  );
};

export default ViewDoubtsLayout;
