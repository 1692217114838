import apiSlice from "./ApiSlice";

export const resourceRequestApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL Channel
    // Get all the client user with pagination
    getAllResourceRequest: builder.query({
      providesTags: ["resource-request"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/resource-request",
          method: "POST",
          body,
        };
      },
    }),

    // Add
    addResourceRequest: builder.mutation({
      invalidatesTags: ["resource-request", "ticket"],
      query: (body) => {
        return {
          url: "/resource-request/add/",
          method: "POST",
          body,
        };
      },
    }),

    addProjectResourceRequest: builder.mutation({
      invalidatesTags: ["resource-request", "ticket", "project", "resource"],
      query: (body) => {
        return {
          url: "/resource/add",
          method: "POST",
          body,
        };
      },
    }),


    getResourceByProjectId: builder.query({
      providesTags: ["resource-request", "ticket", "project", "resource"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/resource",
          method: "POST",
          body,
        };
      },
    }),


    //EDIT getResourceById
    editResourceById: builder.mutation({
      invalidatesTags: ["resource-request"],
      query: ({ body, id }) => {
        return {
          url: `/resource-request/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //GET getResourceById BY ID
    getResourceById: builder.query({
      providesTags: ["resource-request"],
      query: (id) => {
        return {
          url: `/resource-request/${id}`,
          method: "GET",
        };
      },
    }),


    //GET getResourceById BY ID
    getResourceByClientId: builder.query({
      providesTags: ["resource-request"],
      query: (id) => {
        return {
          url: `/client/resources/${id}`,
          method: "GET",
        };
      },
    }),

    //CLOSE Resource BY ID
    closeResourceRequest: builder.mutation({
      invalidatesTags: ["resource-request"],
      query: (id) => {
        return {
          url: `/resource-request/mark-as-close/${id}`,
          method: "PUT",
        };
      },
    }),
    // Add
    addResourceById: builder.mutation({
      invalidatesTags: ["resource-request"],
      query: ({ body, id }) => {
        return {
          url: `/resource-request/add-resource/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllResourceRequestQuery,
  useAddResourceRequestMutation,
  useGetResourceByIdQuery,
  useAddProjectResourceRequestMutation,
  useEditResourceByIdMutation,
  useGetResourceByProjectIdQuery,
  useCloseResourceRequestMutation,
  useGetResourceByClientIdQuery,
  useAddResourceByIdMutation
} = resourceRequestApi;
