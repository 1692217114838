import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetTaskCountQuery } from "src/services/TaskService";
import ProjectStatusView from "./ProjectStatusView";
import { CircularProgress } from "@mui/material";

const PlatformProjectStatusViewWrapper = () => {
  const { id } = useParams();
  const [totalCountData, setTotalCountData] = useState<any>([]);
  const {
    data: CountData,
    isLoading: CountDataIsLoading,
    isFetching: CountDataIsFetching,
  } = useGetTaskCountQuery(id);

  useEffect(() => {
    if (!CountDataIsLoading && !CountDataIsFetching) {
        setTotalCountData(CountData?.data)
    }
  }, [CountDataIsLoading ,CountDataIsFetching ,CountData ]);

  return CountDataIsFetching || CountDataIsLoading ? (
    <div className='flex justify-center items-center h-72' ><CircularProgress /></div>
  ) : (
   <ProjectStatusView CountData={totalCountData}/>
  );
};

export default PlatformProjectStatusViewWrapper;
