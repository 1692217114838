import React, { useEffect, useState } from "react";
import HoldJoinMeetingTask from "./HoldJoinMeetingTask";
import { CircularProgress, Dialog } from "@mui/material";
import { showToast } from "src/utils/showToaster";
import { useGetInProgressTaskQuery, useUpdateTaskStatusMutation } from "src/services/TaskService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { useJoinMeetingbyIdMutation } from "src/services/MeetingServices";

type Props = {
  onClose: any;
  JoinMeeting: any
};

const HoldJoinMeetingTaskWrapper = ({
  onClose,
  JoinMeeting,
}: Props) => {

  const [taskData, setTaskData] = useState()
  const { data, isLoading, isFetching } = useGetInProgressTaskQuery("")
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTaskData(data?.data);
    }
  }, [isLoading, isFetching, data]);
  const userDataLJson = localStorage.getItem("userData");
  const userData = JSON?.parse(userDataLJson as string);
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const [joinYourMeeting] = useJoinMeetingbyIdMutation()

  const handleJoinMeeting = () => {
    // showConfirmationDialog({
    //   title: "Heads Up",
    //   text: "Are you sure want to join the meeting?",
    //   icon: "question",
    //   showCancelButton: true,
    //   next: (result) => {
    //     if (result?.isConfirmed) {
    joinYourMeeting({ id: JoinMeeting, body: { type: "JOIN", userId: userData?.userId } }).then((res: any) => {
      if (res.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        showToast("success", res?.data?.message);
        onClose()
      }
    });
    //     }
    //   },
    // });
  }


  const handleHoldJoinMeeting = (row: string) => {
    showConfirmationDialog({
      title: "Heads Up",
      text: "Are you sure want to hold the task and join the meeting?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          const formattedValues = {
            assignedTo: userData?.userId,
            requestedLabel: "HOLD",
          };
          updateTaskStatus({ id: row, body: formattedValues }).then(
            (res: any) => {
              if (res?.error) {
                showToast("error", res?.error?.data?.message);
              } else {
                if (res?.data?.status) {
                  showToast("success", res?.data?.message);
                  joinYourMeeting({ id: JoinMeeting, body: { type: "JOIN", userId: userData?.userId } }).then((res: any) => {
                    if (res.error) {
                      showToast("error", res?.error?.data?.message);
                    } else {
                      showToast("success", res?.data?.message);
                      onClose()
                    }
                  });
                } else {
                  showToast("error", res?.data?.message);
                }
              }
            }
          );
        }
      },
    });
  }





  return (
    <>
      <Dialog open maxWidth="sm">
        {(isLoading || isFetching) && (
          <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
            <CircularProgress />
          </div>
        )}
        <HoldJoinMeetingTask onClose={onClose} taskData={taskData} handleHoldJoinMeeting={handleHoldJoinMeeting} handleJoinMeeting={handleJoinMeeting} />
      </Dialog>
    </>
  );
};

export default HoldJoinMeetingTaskWrapper;
