import React, { useState, useEffect } from "react";
import ATMSelect from "../../atoms/formFields/ATMSelect/ATMSelect";
import { useGetAllLayersQuery } from "src/services/ProjectServices";

type Props = {
  name?: string;
  value: any;
  onChange: (newValue: any) => void;
  onBlur?: (e: any) => void;
  isMulti?: boolean;
  label?: string;
  placeholder?: string;
  id?: string | null;
};

const MOLLayerDropdown = ({
  name = "",
  value,
  onChange,
  onBlur,
  isMulti = false,
  label = "Layer",
  placeholder = "Select Layer",
  id = "",
}: Props) => {
  const [layerOptions, setLayerOptions] = useState([]);
  const {
    data: layers,
    isLoading: isLayerLoading,
    isFetching: isLayerFetching,
  } = useGetAllLayersQuery(id || "", {
    skip: !id,
  });

  // Setting Team Members Data
  useEffect(() => {
    if (!isLayerLoading && !isLayerFetching) {
      setLayerOptions(layers?.data?.layer || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, isLayerLoading, isLayerFetching]);

  return (
    <ATMSelect
      name={name}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      options={layerOptions}
      isLoading={isLayerLoading || isLayerFetching}
      renderOption={(option, { context }) => {
        if (context === "menu") {
          return <div className="capitalize"> {option.layerName} </div>;
        } else {
          return <div className="capitalize"> {option.layerName} </div>;
        }
      }}
      isOptionEqualToValue={(option, selected) => {
        return (
          selected?.findIndex(
            (selectedValue: any) => selectedValue?._id === option._id
          ) > -1
        );
      }}
      isSearchedOption={(option, searchValue) => {
        return searchValue
          ? option?.data?.layerName?.toLowerCase()?.includes(searchValue)
          : true;
      }}
    />
  );
};

export default MOLLayerDropdown;
