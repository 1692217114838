import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import {  object, string } from "yup";
import IncidentForm from "../Layouts/IncidentForm";
import { useUpdatePointerMutation, useGetSinglePointerQuery } from "src/services/IncidentService";
import moment from "moment";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
  pointerId :string ;
};

export type IncidentFormValues = {
  Date: any;
  remark: string;
  assignName: any;
  category: any;
  eventType: any;
  isMarkAsUnhandled: boolean ;
};

const EditIncidentFormWrapper = ({ onClose , pointerId }: Props) => {  

  const [pointerData, setPointerData] = useState<any>();

 const {data , isLoading , isFetching} = useGetSinglePointerQuery(pointerId ,{skip:!pointerId})

  useEffect(()=>{
    setPointerData(data?.data?.[0])
  },[data , isLoading , isFetching]) 

  const [updatePointer] = useUpdatePointerMutation();

  // Form Initial Values
  const initialValues: IncidentFormValues = {
    Date: pointerData?.addedOnDate ||'',
    isMarkAsUnhandled :pointerData?.teamMembers?.length ? false : true ,
    remark: pointerData?.remark ||'',
    assignName: pointerData?.teamMembers?.length && pointerData?.teamMembers?.map((el:any)=>{
      return{
        label:el?.memberName ,
        value: el?.memberId

      }
    })
    ,
    category: {
      label:pointerData?.categoryName ,
      value : pointerData?.categoryId
    },
    eventType:{
      label:pointerData?.typeName ,
      value: pointerData?.typeId
    },
  };

  // Validation Schema
  const validationSchema = object().shape({
    remark: string().required("Please enter age"),
    // assignName: array()
    // .min(1, "Please select member")
    // .test("installmentTest", "Please select assign", (value, context) => {
    //   const { isMarkAsUnhandled } = context.parent;
    //   if (!isMarkAsUnhandled || value) {
    //     return true;
    //   } else {
    //     return true;
    //   }
    // }),
    category :object().required("Please select category"),
    eventType :object().required("Please select type"),
    // Date: object().required("date is required"),
  });

  // Handle Submit
  const handleSubmit = (
    values: IncidentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<IncidentFormValues>
  ) => {
    const formattedValues = {
      categoryId: values?.category?.value,
      typeId: values?.eventType?.value,
      markAsUnhandled: values?.isMarkAsUnhandled,
      remark: values?.remark,
      teamMembers:values?.assignName?.length ?  values?.assignName?.map((el: any) => el?.value) : null,
      addedOnDate: values?.Date
        ? moment(values?.Date).format("YYYY-MM-DD")
        : "",
    };

    updatePointer({
      id: pointerId ,
      body : formattedValues 
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <Formik
      enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <IncidentForm formikProps={formikProps} onClose={onClose} formType="Update" />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditIncidentFormWrapper;
