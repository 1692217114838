import { MdLabel } from "react-icons/md";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";

type Props = {
  items: any;
};

const TicketGroupTimeLogView = ({
  items,
}: Props) => {
  return (
    <div className="flex flex-col p-4 bg-gray-50 h-full">
      <div id="scrollableDiv" className="w-full ">

        {items?.map((timeLine: any, index: number) => (
          <div key={index} className="flex gap-3 pb-2">
            <div className="p-2 h-fit aspect-square border rounded-full">
              <MdLabel className="text-sm text-gray-600" />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-1">
                <p className="text-xs">
                  <div className="text-slate-700 font-medium">
                    {formatedDateTimeIntoIst(
                      timeLine?.createdAt,
                      "DD MMM yyyy"
                    )}
                  </div>
                  <div className="text-[13px] font-medium text-slate-400">
                    {formatedDateTimeIntoIst(timeLine?.createdAt, "hh:mm A")}
                  </div>
                </p>
                <div className="w-1/2 rounded">
                </div>
                {timeLine?.tickets?.length ?
                <div className="flex flex-col gap-1  overflow-y-hidden">
                  <div className=" text-md font-medium">Tickets :-</div>
                  <div className="flex flex-wrap gap-1 w-full overflow-hidden">
                    [ {timeLine?.tickets?.map((ticket: any) => {
                      return (
                        <div className="flex  gap-1 text-sm text-slate-400"> {`${ticket},`} </div>
                      )
                    })}]
                  </div>
                </div> : null}

              </div>
              <span className="bg-gray-50 text-blue-400 rounded-md">
                {timeLine?.remark}
              </span>
            </div>
          </div>
        ))}
        {/* </InfiniteScroll> */}
      </div>
    </div>
  );
};

export default TicketGroupTimeLogView;
