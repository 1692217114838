import React from 'react'
import { CircularProgress, Divider } from '@mui/material'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import ATMActionMenu from 'src/components/UI/atoms/ATMActionMenu/ATMActionMenu'
import { UserModuleNameTypes } from 'src/models/UserAccess/UserAccess.model'
import { formatedDateTimeIntoIst } from 'src/utils/formUtils/dateTimeFormate'

type Props = {
  featueDetails: any;
  isLoading: any;
  handleDeleteRequirement: any;
};

const FeatureDetailsView = ({
  featueDetails,
  isLoading,
  handleDeleteRequirement,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center h-[400px]">
            <CircularProgress />{" "}
          </div>
        ) : featueDetails ? (
          <div className="last:pb-4 p-2 w-full  overflow-auto ">
            <div className=" flex justify-between bg-white">
              <div className=" gap-4 items-center">
                <div className="flex flex-col gap-2">
                  <p className="text-xs text-slate-600">
                    {formatedDateTimeIntoIst(
                      featueDetails?.createdAt,
                      "DD MMM yyyy"
                    )}
                  </p>
                  <p className="text-sm">
                    <span className="font-medium">
                      {featueDetails?.projectName}{" "}
                    </span>{" "}
                    {featueDetails?.moduleName
                      ? `(${featueDetails?.moduleName})`
                      : null}
                  </p>
                </div>
                <ATMActionMenu
                  options={[
                    {
                      label: (
                        <div className="flex gap-x-2 items-center">
                          <MdEdit color="#000000" /> Edit
                        </div>
                      ),
                      onClick : () => navigate(`${location.pathname}/edit?moduleId=${featueDetails?.moduleId}&featureId=${featueDetails?._id}&tab=feature-details`),
                      // onClick: () => {
                      //   navigate(`/requirement/${featueDetails?._id}`);
                      // },,
                      acitonName:UserModuleNameTypes.ACTION_FEATURES_EDIT

                    },
                    {
                      label: (
                        <div className="flex gap-x-2 items-center">
                          <MdDelete color="#000000" /> Delete
                        </div>
                      ),
                      onClick: () => {
                        handleDeleteRequirement(featueDetails?._id);
                      },
                      acitonName:UserModuleNameTypes.ACTION_FEATURES_DELETE

                    },
                  ]}
                />
              </div>
              <ATMActionMenu
                options={[
                  {
                    label: (
                      <div className="flex gap-x-2 items-center">
                        <MdEdit color="#000000" /> Edit
                      </div>
                    ),
                    onClick: () =>
                      navigate(
                        `${location.pathname}/edit?moduleId=${featueDetails?.moduleId}&featureId=${featueDetails?._id}&tab=feature-details`
                      ),
                    // onClick: () => {
                    //   navigate(`/requirement/${featueDetails?._id}`);
                    // },
                  },
                  {
                    label: (
                      <div className="flex gap-x-2 items-center">
                        <MdDelete color="#000000" /> Delete
                      </div>
                    ),
                    onClick: () => {
                      handleDeleteRequirement(featueDetails?._id);
                    },
                  },
                ]}
              />
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-6">
                <div className="flex flex-col gap-1">
                  <p className="text-xs">Feature Name</p>
                  <p className="text-base font-semibold">
                    {featueDetails.featureName}{" "}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-xs">Feature Type</p>
                  <p className="text-base font-semibold">
                    {featueDetails?.featureType}
                  </p>
                </div>
              </div>

              <div>
                {featueDetails?.formFields?.length ? (
                  <div className="flex flex-col gap-1">
                    {/* <p className="text-xs">Form</p> */}
                    <div className="">
                      <table className="w-full border-collapse border border-gray-300 rounded-md">
                        <thead>
                          <tr>
                            <th className="border border-gray-300 p-2">Name</th>
                            <th className="border border-gray-300 p-2">Type</th>
                            <th className="border border-gray-300 p-2">
                              Validation
                            </th>
                            <th className="border border-gray-300 p-2">
                              Logic
                            </th>
                            <th className="border border-gray-300 p-2">
                              Data Source
                            </th>
                            <th className="border border-gray-300 p-2">
                              Is Required
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {featueDetails?.formFields?.map(
                            (formfield: any, formfieldIndex: any) => (
                              <tr key={formfieldIndex}>
                                <td className="border border-gray-300 p-2 text-wrap text-center">
                                  {formfield?.name || "-"}
                                </td>
                                <td className="border border-gray-300 p-2 text-wrap text-center capitalize">
                                  {formfield?.type?.toLowerCase() || "-"}
                                </td>
                                <td className="border border-gray-300 p-2 text-wrap text-center">
                                  {formfield?.validation || "-"}
                                </td>
                                <td className="border border-gray-300 p-2 text-wrap text-center">
                                  {formfield?.logic || "-"}
                                </td>
                                <td className="border border-gray-300 p-2 text-wrap text-center">
                                  {formfield?.dataSource || "-"}
                                </td>
                                <td className="border border-gray-300 p-2 text-wrap text-center">
                                  {formfield?.isRequired === true
                                    ? "True"
                                    : "False" || "-"}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}

                {featueDetails?.table?.length ||
                featueDetails?.tableFields?.length ||
                featueDetails?.columns?.length ? (
                  <div className="flex flex-col gap-2">
                    <div className="text-xs">
                      Table - <span>{featueDetails?.tableName}</span>
                    </div>

                    <div className="flex gap-4">
                      {featueDetails?.table?.length ? (
                        <div className="flex-1 flex flex-col gap-2 p-2 bg-slate-50 rounded-md">
                          <p className="text-sm font-medium">Table Action :</p>
                          <div className="flex flex-wrap gap-2">
                            {featueDetails?.table?.map(
                              (table: any, tableActionIndex: number) => (
                                <p
                                  key={tableActionIndex}
                                  className="rounded-md bg-white px-2 p-1 flex border text-wrap text-sm"
                                  style={{
                                    width: "fit-content",
                                  }}
                                >
                                  {table?.tableAction}
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      ) : null}

                      {featueDetails?.tableFields?.length ? (
                        <div className="flex-1 flex flex-col gap-2 p-2 bg-slate-50 rounded-md">
                          <p className="text-sm font-medium">Table Action :</p>
                          <div className="flex flex-wrap gap-2">
                            {featueDetails?.tableFields?.map(
                              (tableField: any, tableFieldIndex: number) => (
                                <p
                                  key={tableFieldIndex}
                                  className="rounded-md bg-white px-2 p-1 flex border text-wrap text-sm"
                                  style={{
                                    width: "fit-content",
                                  }}
                                >
                                  {tableField.columnName}
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      ) : null}

                      {featueDetails?.columns?.length ? (
                        <div className="flex-1 flex flex-col gap-2 p-2 bg-slate-50 rounded-md">
                          <p className="text-sm font-medium">
                            Columns Actions :
                          </p>
                          <div className="flex flex-wrap gap-2">
                            {featueDetails?.columns?.map(
                              (columns: any, columnsIndex: number) => (
                                <p
                                  key={columnsIndex}
                                  className="rounded-md bg-white px-2 p-1 flex border text-wrap text-sm"
                                  style={{
                                    width: "fit-content",
                                  }}
                                >
                                  {columns?.columnActions}
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>

              <Divider />

              <div className=" flex flex-col gap-6">
                <div className="flex flex-col gap-1">
                  <div className="text-xs font-medium">Requirement Logic</div>
                  <div
                    className="font-sm font-medium"
                    dangerouslySetInnerHTML={{
                      __html: featueDetails?.requirementLogic,
                    }}
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <div className="text-xs font-medium">Requirement Flow</div>
                  <div
                    className="font-sm font-medium"
                    dangerouslySetInnerHTML={{
                      __html: featueDetails?.requirementFlow,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className=" font-bold text-slate-400 flex justify-center items-center h-[400px]">
            No data found
          </div>
        )}{" "}
      </div>
    </>
  );
};

export default FeatureDetailsView;
