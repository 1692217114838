import { useState, useEffect } from "react";
import { useGetAllCurrencyQuery } from "src/services/CurrencyServices";
const useCurrency = () => {
    const [currency, setCurrency] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const { data, isLoading, isFetching } = useGetAllCurrencyQuery("");

    useEffect(() => {
        if (isLoading || isFetching) {
            setIsDataLoading(true);
        } else {
            const result: any[] = data?.data;
            setCurrency(result || []);
            setIsDataLoading(false);
        }
    }, [data, isLoading, isFetching]);

    return { currency, isDataLoading };
};

export default useCurrency;
