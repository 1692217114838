import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import ExpenseListing from "./ExpenseListing";
import {
  setIsTableLoading,
  setItems,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
  setPage,
} from "src/redux/slices/Finance-New-Slices/ExpenseSlice";
import EditExpenseWrapper from "../Edit/EditExpenseWrapper";
import moment from "moment";
import { useGetExpenseQuery } from "src/services/Finance-New-Services/ExpenseService";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import PayExpenseFormWrapper from "../PaymentInForm/PayExpenseFormWrapper";

type Props = {};

const ExpenseListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenPaymentDialog, setIsOpenPaymentDialog] = useState(false);
  const [paymentId, setPaymentId] = useState("");

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.expense);

  const { data, isLoading, isFetching } = useGetExpenseQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["expenseName", "amount"],
    page: page,
    filterBy: [],
    // dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const StatusColumn = ({ row }: { row: any }) => {
    switch (row?.status) {
      case "PAID":
        return (
          <div>
            <div className="font-medium text-green-600"> Paid </div>
            <div className="text-xs italic text-slate-600">
              on {moment(row?.paymentDate).format("DD MMM YYYY")}
            </div>
          </div>
        );
      case "UNPAID":
        return (
          <div>
            <div className="font-medium text-red-600"> Unpaid </div>
            <div className="text-xs italic text-slate-600">
              due on {moment(row?.dueDate).format("DD MMM YYYY")}
            </div>
          </div>
        );

      default:
        return <></>;
    }
  };

  const columns = [
    {
      field: "expenseName",
      headerName: "Title",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.expenseName}</div>,
    },
    {
      field: "expenseDate",
      headerName: "Expense Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>{moment(row?.expenseDate).format("DD MMM YYYY")}</div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount (Incl. TDS)",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.currencySymbol} {row?.amount}
        </div>
      ),
    },
    {
      field: "gstPercentage",
      headerName: "GST",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.gstApplicable ? (
            <>
              {" "}
              {row?.currencySymbol} {(row?.amount * row?.gstPercentage) / 100} (
              {row?.gstPercentage}%)
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      field: "tdsAmount",
      headerName: "TDS Amt",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.currencySymbol} {row?.tdsAmount}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <StatusColumn row={row} />,
    },
    {
      field: "account",
      headerName: "Account",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.accountName || "-"}</div>,
    },
    {
      field: "paymentMode",
      headerName: "Mode",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.paymentModeName || "-"}</div>,
    },
    {
      field: "category",
      headerName: "Category",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.financeCategoryName || "-"}</div>,
    },
    {
      field: "description",
      headerName: "Description",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.description || "-"}</div>,
    },
    {
      field: "warrantyExpiryDate",
      headerName: "Warranty Expiry",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.warrantyExpiryDate
            ? moment(row?.warrantyExpiryDate).format("DD MMM YYYY")
            : "-"}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: "flex-[1_1_0%]",
      renderCell: (row: any) => (
        <div>
          {row?.status === "UNPAID" && (
            <ATMLoadingButton
              onClick={() => {
                setIsOpenPaymentDialog(true);
                setPaymentId(row?._id);
              }}
            >
              Pay
            </ATMLoadingButton>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <ExpenseListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
          onAddNew={() => true}
        />
      </div>
      {isOpenEditDialog && (
        <EditExpenseWrapper
          onClose={() => {
            setIsOpenEditDialog(false);
          }}
        />
      )}
      {isOpenPaymentDialog && (
        <PayExpenseFormWrapper
          onClose={() => setIsOpenPaymentDialog(false)}
          paymentId={paymentId}
        />
      )}
    </>
  );
};

export default ExpenseListingWrapper;
