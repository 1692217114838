import { FieldArray, FormikProps } from "formik";
import { GrFormAdd } from "react-icons/gr";
import { RiDeleteBin5Line } from "react-icons/ri";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import MOLProjecttDropdown from "src/components/UI/molecules/MOLProjectDropdown/MOLProjectDropdown";
import { ServerFormValues } from "src/models/Server.model";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { handleValidNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<ServerFormValues>;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
  LabelType?: any;
  onClose: () => void;
};

const ServerType = [
  {
    label: "Web",
    value: "WEB",
  },
  {
    label: "Admin",
    value: "ADMIN",
  },
  {
    label: "API",
    value: "API",
  },
];

const ServiceType = [
  {
    label: "Redis",
    value: "REDIS",
  },
  {
    label: "MongoDB",
    value: "MONGODB",
  },
];
const ServerForm = ({
  formikProps,
  formType = "ADD",
  setSaveNextChecked,
  saveNextChecked,
  LabelType,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add New Server" : "Update Client"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* //server name */}
        <div className="">
          <ATMTextField
            required
            name="serverName"
            value={values.serverName}
            onChange={(e) => setFieldValue("serverName", e.target.value)}
            label="Server Name"
            placeholder=" Server Name"
            onBlur={handleBlur}
          />
        </div>
        {/* Server Label */}
        <div className="">
          <ATMSelect
            required
            name="label"
            value={values.label}
            options={LabelType}
            onChange={(e) => setFieldValue("label", e)}
            label="Server Label"
            placeholder=" Server Label"
            onBlur={handleBlur}
          />
        </div>
        {/* //server Config */}
        <div className="">
          <ATMTextField
            required
            name="serverConfig"
            value={values.serverConfig}
            onChange={(e) => setFieldValue("serverConfig", e.target.value)}
            label="Server Config"
            placeholder=" Server Config"
            onBlur={handleBlur}
          />
        </div>

        <p className="mt-1 font-medium underline text-md text-primary-light">
          Server Credentials
        </p>

        {/* server credentials */}
        <FieldArray name="serverCredentials">
          {({ push, remove }) => (
            <div className="flex flex-col gap-6">
              {values?.serverCredentials?.map((serverCredentials, index) => (
                <div key={index} className="items-end block gap-4 md:flex">
                  {/* server user Name */}
                  <div className="">
                    <ATMTextField
                      required
                      name={`serverCredentials[${index}].userName`}
                      value={values.serverCredentials[index].userName}
                      onChange={(e) =>
                        setFieldValue(
                          `serverCredentials[${index}].userName`,
                          e.target.value
                        )
                      }
                      label="Server  User Name"
                      placeholder=" Server User Name"
                      onBlur={handleBlur}
                    />
                  </div>

                  {/* // server password */}
                  <div className="py-3 md:py-0">
                    <ATMTextField
                      required
                      name={`serverCredentials[${index}].password`}
                      value={values.serverCredentials[index].password}
                      onChange={(e) =>
                        setFieldValue(
                          `serverCredentials[${index}].password`,
                          e.target.value
                        )
                      }
                      label=" Server Password"
                      placeholder=" Server Password"
                      onBlur={handleBlur}
                    />
                  </div>

                  {/* server remark */}
                  <div className="">
                    <ATMTextField
                      required
                      name={`serverCredentials[${index}].remark`}
                      value={values.serverCredentials[index].remark}
                      onChange={(e) =>
                        setFieldValue(
                          `serverCredentials[${index}].remark`,
                          e.target.value
                        )
                      }
                      label=" Server Remark"
                      placeholder=" Server Remark"
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="py-3 md:py-0">
                    <ATMSelect
                      required
                      name={`serverCredentials[${index}].type`}
                      label="Server  Type"
                      placeholder="Select  Type"
                      onBlur={handleBlur}
                      value={values?.serverCredentials[index]?.type}
                      onChange={(newValue) => {
                        setFieldValue(
                          `serverCredentials[${index}].type`,
                          newValue
                        );
                      }}
                      options={ServerType}
                    />
                  </div>

                  {/* Delete Button */}
                  <button
                    type="button"
                    className={`${getInputHeight(
                      "small"
                    )} aspect-square rounded bg-red-400 text-white flex justify-center items-center md:mt-0 mt-2`}
                    onClick={() => remove(index)}
                  >
                    <RiDeleteBin5Line size={20} />
                  </button>
                </div>
              ))}

              {/* Add More Button */}
              <button
                type="button"
                onClick={() =>
                  push({
                    userName: "",
                    password: "",
                    remark: "",
                    type: "",
                  })
                }
                className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
              >
                Add{" "}
                {values?.serverCredentials?.length
                  ? "More"
                  : "Server Credentials"}
                <span>
                  <GrFormAdd
                    color="#ADB8C8"
                    size="1.5em"
                    className="font-semibold"
                  />
                </span>
              </button>
            </div>
          )}
        </FieldArray>
        <p className="font-medium underline text-md text-primary-light">
          Service
        </p>

        {/* service  */}
        <FieldArray name="service">
          {({ push, remove }) => (
            <div className="flex flex-col gap-6">
              {values?.service?.map((service, index) => (
                <div key={index} className="items-end block gap-4 md:flex">
                  {/* // server port */}
                  <div className="">
                    <ATMTextField
                      required
                      name={`service[${index}].port`}
                      value={values.service[index].port}
                      onChange={(e) =>
                        handleValidNumber(e) &&
                        setFieldValue(`service[${index}].port`, e.target.value)
                      }
                      label=" Server Port"
                      placeholder=" Server Port"
                      onBlur={handleBlur}
                    />
                  </div>

                  {/* server remark */}
                  <div className="py-3 md:py-0">
                    <ATMTextField
                      required
                      name={`service[${index}].remark`}
                      value={values.service[index].remark}
                      onChange={(e) =>
                        setFieldValue(
                          `service[${index}].remark`,
                          e.target.value
                        )
                      }
                      label=" Server Remark"
                      placeholder=" Server Remark"
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="">
                    <ATMSelect
                      required
                      name={`service[${index}].type`}
                      label="Service  Type"
                      placeholder="Select Service Type"
                      onBlur={handleBlur}
                      value={values?.service[index]?.type}
                      onChange={(newValue) => {
                        setFieldValue(`service[${index}].type`, newValue);
                      }}
                      options={ServiceType}
                    />
                  </div>

                  {/* Delete Button */}
                  <button
                    type="button"
                    className={`${getInputHeight(
                      "small"
                    )} aspect-square rounded bg-red-400 text-white flex justify-center items-center mt-5 md:mt-0`}
                    onClick={() => remove(index)}
                  >
                    <RiDeleteBin5Line size={20} />
                  </button>
                </div>
              ))}

              {/* Add More Button */}
              <button
                type="button"
                onClick={() =>
                  push({
                    port: "",
                    remark: "",
                    type: "",
                  })
                }
                className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400  flex  justify-center items-center gap-[2px]"
              >
                Add {values?.service?.length ? "More" : "Service"}
                <span>
                  <GrFormAdd
                    color="#ADB8C8"
                    size="1.5em"
                    className="font-semibold"
                  />
                </span>
              </button>
            </div>
          )}
        </FieldArray>

        {/* Project */}
        <div className="">
          <MOLProjecttDropdown
            required
            name="projectId"
            value={values.projectId}
            isMulti
            onChange={(newValue) => {
              setFieldValue("projectId", newValue);
            }}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default ServerForm;
