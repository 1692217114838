import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import {
  FullDayLeaveType,
  LeaveRequestFormValues,
} from "src/models/LeaveReques.model";
import {
  useGetLeaveRequestByIdQuery,
  useUpdateAdminLeaveRequestMutation,
} from "src/services/LeaveRequestService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import { WhichPart } from "../Layouts/HalfDayLeaveRequestForm";
import UpdateLeaveRequestForm from "./UpdateLeaveRequestForm";

type Props = {
  handleClose: any;
  leaveRequestId: string;
};

const UpdateLeaveRequestFormWrapper = ({
  handleClose,
  leaveRequestId,
}: Props) => {
  // get  members
  const { codioticUsers, isDataLoading } = useGetAllCodioticUsers();
  const { data, isLoading, isFetching } =
    useGetLeaveRequestByIdQuery(leaveRequestId);
  const [updateLeaveRequest] = useUpdateAdminLeaveRequestMutation();
  const [leaveInfo, setLeaveInfo] = useState<any>();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setLeaveInfo(data?.data);
    }
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: LeaveRequestFormValues = {
    type: {
      label: leaveInfo?.daysOfLeave || "",
      value: leaveInfo?.daysOfLeave || "",
    },
    reason: leaveInfo?.reason || "",
    leaveStartDate: leaveInfo?.leaveStartDate || "",
    reportingDate: leaveInfo?.reportingDate,
    leaveType: {
      label:
        FullDayLeaveType?.find((part) => part?.value === leaveInfo?.leaveType)
          ?.label || "",
      value: leaveInfo?.leaveType || "",
    },
    daysOfLeave: leaveInfo?.daysOfLeave || "",
    dayPart: {
      label:
        WhichPart?.find((part) => part?.value === leaveInfo?.dayPart)?.label ||
        "",
      value: leaveInfo?.dayPart || "",
    },
    leavingTime: leaveInfo?.leavingTime || "",
    arrivalTime: leaveInfo?.arrivalTime || "",
    remark: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    dayPart: object().test(
      "arrTimeTest",
      "Please select day part",
      (value: any, context) => {
        const { type } = context.parent;
        if (value?.value) {
          return true;
        } else if (type?.value !== "HALF_DAY") {
          return true; // Skip validation if type is HALF_DAY or if dayPart has a value
        }
        return false; // Require validation if dayPart is empty and type is not HALF_DAY
      }
    ),
    leaveType: object().test(
      "leaveType",
      "Please select Leave type",
      (value: any, context) => {
        const { type } = context.parent;
        if (value?.value) {
          return true;
        } else if (type?.value !== "ONE_DAY") {
          return true; // Skip validation if type is FULL_DAY or if dayPart has a value
        } else {
          return false; // Require validation if dayPart is empty and type is not HALF_DAY
        }
      }
    ),
    reportingDate: string().test(
      "arrTimeTest",
      "Please enter date",
      (value: any, context) => {
        const { type } = context.parent;
        if (value) {
          return true;
        } else if (type?.value !== "MULTIPLE_DAYS") {
          return true; // Skip validation if type is FULL_DAY or if dayPart has a value
        } else if (!value) {
          return false;
        }
        return false; // Require validation if dayPart is empty and type is not HALF_DAY
      }
    ),
    leaveStartDate: string().required("Please enter date"),
    arrivalTime: string().test(
      "arrTimeTest",
      "Please select arrival time",
      (value, context) => {
        const { dayPart, type } = context.parent;
        if (type?.value !== "HALF_DAY") {
          return true; // Skip validation if type is HALF_DAY
        }
        return (dayPart?.value === "FIRST_HALF" ||
          dayPart?.value === "FOR_HOURS") &&
          value
          ? true
          : dayPart?.value === "SECOND_HALF"
            ? true
            : false;
      }
    ),
    leavingTime: string().test(
      "arrTimeTest",
      "Please select arrival time",
      (value, context) => {
        const { dayPart, type } = context.parent;
        if (type?.value !== "HALF_DAY") {
          return true; // Skip validation if type is HALF_DAY
        }
        return (dayPart?.value === "SECOND_HALF" ||
          dayPart?.value === "FOR_HOURS") &&
          value
          ? true
          : dayPart?.value === "FIRST_HALF"
            ? true
            : false;
      }
    ),
    reason: string().required("Please enter reason"),
    remark: string().required("Please enter remark"),
    type: object().required("Please select type"),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeaveRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<LeaveRequestFormValues>
  ) => {
    const {
      leaveType,
      buddy,
      leaveStartDate,
      reportingDate,
      dayPart,
      type,
      ...rest
    } = values;

    const formattedValues = {
      ...rest,
      leaveType: leaveType?.value,
      dayPart: dayPart?.value,
      leaveStartDate: moment(leaveStartDate).format("YYYY-MM-DD"),
      reportingDate: reportingDate
        ? moment(reportingDate).format("YYYY-MM-DD")
        : type?.value === "ONE_DAY"
          ? moment(leaveStartDate).add(1, "days").format("YYYY-MM-DD")
          : moment(leaveStartDate).format("YYYY-MM-DD"),
      buddy: buddy?.value,
      leavingTime: dayPart?.value === "FIRST_HALF" ? "" : values.leavingTime,
      arrivalTime: dayPart?.value === "SECOND_HALF" ? "" : values.arrivalTime,
      daysOfLeave: type?.value,
    };

    if (type?.value === "MULTIPLE_DAYS") {
      delete formattedValues.dayPart;
    }

    updateLeaveRequest({
      id: leaveRequestId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <UpdateLeaveRequestForm
              handleClose={handleClose}
              formikProps={formikProps}
              codioticUsers={codioticUsers}
              isDataLoading={isDataLoading}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateLeaveRequestFormWrapper;
