import React, { useEffect, useMemo, useState } from "react";
import { HiChatAlt2, HiViewGrid } from "react-icons/hi";
import { FaDoorClosed } from "react-icons/fa6";
import { TbListDetails } from "react-icons/tb";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTab from "src/components/UI/atoms/ATMTab/ATMTab";
import MOLTicketStatusChip from "src/components/UI/molecules/MOLTicketStatusChip/MOLTicketStatusChip";
import MOLTicketTypeChip from "src/components/UI/molecules/MOLTicketTypeChip/MOLTicketTypeChip";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { getFormattedEstimationTime } from "src/helpers/getFormattedEstimationTime";
import { setTotalUnSeenMessageCount } from "src/redux/slices/ChatSlice";
import { useGetMessageCountQuery } from "src/services/ChatServices";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setSingleItem } from "src/redux/slices/TicketSlice";
import { RootState } from "src/redux/store";
import {
  useDeleteResourceRequiredByIdMutation,
  // useCloseTicketMutation,
  useGetAllTicketQuery,
  useGetSingleTicketTimeAnalysisViaIdQuery,
  useGetSingleTicketsViaIdQuery,
} from "src/services/TicketServices";
import AssignTicketDetailsModalWrapper from "../Components/AssignTicketDetailsModal/AssignTicketDetailsModalWrapper";
import AssignTicketTeamMemberModalWrapper from "../Components/AssignTicketTeamMemberModal/AssignTicketTeamMemberModalWrapper";
import CreateETAModalWrapper from "../Components/CreateETAModal/AddETAModal.tsx/CreateETAModalWrapper";
import MarkAsDoneBugTypeModalWrapper from "../Components/MarkAsDoneBugTypeModal/MarkAsDoneBugTypeModalWrapper";
import MarkAsDoneModalWrapper from "../Components/MarkAsDoneModal/MarkAsDoneModalWrapper";
import TicketSideBarLayoutSideBarLayout from "./TicketSideBarLayout/TicketSideBarLayoutSideBarLayout";
import EditChangeRequestFormWrapper from "./TicketTabs/EditChangeRequestForm/EditChangeRequestFormWrapper";
import TicketStatusDialogWrapper from "./TicketTabs/TicketStatusDialog/TicketStatusDialogWrapper";
import { MdAddTask, MdDelete } from "react-icons/md";
import { FaStopwatch } from "react-icons/fa";
import { FaBoxTissue } from "react-icons/fa";
import AddTaskFormWrapper from "./TicketTabs/Task/Add/AddTaskFormWrapper";
import { Chip, Divider, Skeleton } from "@mui/material";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import AccessDenied from "src/utils/AccessDenied";
import EditETAModalWrapper from "../Components/CreateETAModal/EditETAModal.tsx/EditETAModalWrapper";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import RequiredResourceWrapper from "./RequiredResource/RequiredResourceWrapper";
import ATMActionMenu, {
  OptionType,
} from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { IoDuplicate } from "react-icons/io5";
import AddResourceRequestFormWrapper from "src/screens/ResourceRequest/Add/AddResourceRequestFormWrapper";
import { showToast } from "src/utils/showToaster";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import moment from "moment";
import TicketTagsWrapper from "./Tags/TicketTagsWrapper";
import AddTicketFormWrapper from "../Add/AddTicketFormWrapper";

type Props = {};

const InfoField = ({
  label,
  value,
  onClick,
}: {
  label: string;
  value: any;
  onClick?: () => void;
}) => {
  return (
    <div className="px-4 py-2 border-b">
      <div className="flex items-center justify-between">
        <div className="pb-1 text-xs text-neutral">{label}</div>
        {onClick && (
          <span
            onClick={onClick}
            className="text-xs font-bold text-teal-600 cursor-pointer"
          >
            EDIT
          </span>
        )}
      </div>
      <div className="text-sm font-medium"> {value} </div>
    </div>
  );
};

enum TicketTypeEnum {
  bug = "BUG",
  support = "SUPPORT",
  change_request = "CHANGE_REQUEST",
  other = "OTHER",
  task = "TASK",
}

enum TicketStatusEnum {
  unMapped = "UNMAPPED",
  mapped = "MAPPED",
  approval_done = "APPROVAL_DONE",
  done = "DONE",
}

const TicketViewLayout = (props: Props) => {
  // Local State
  const [isShowAssignModal, setIsShowAssignModal] = useState<boolean>(false);

  const [isShowAssignTeamMemberModal, setIsShowAssignTeamMemberModal] =
    useState<boolean>(false);

  const [isShowCreateETAModal, setIsShowCreateETAModal] =
    useState<boolean>(false);
  const [isShowEditETAModal, setIsShowEditETAModal] = useState<boolean>(false);

  const [isShowMarkAsDoneModal, setIsShowMarkAsDoneModal] =
    useState<boolean>(false);

  // Edit Change Request Form
  const [isShowEditChangeRequestModal, setIsShowEditChangeRequestModal] =
    useState<boolean>(false);

  const [clientSideTicketStatus, setClientSideTicketStatus] =
    useState<boolean>(false);

  const [clientSideTicketStatusValue, setClientSideTicketStatusValue] =
    useState<any>("");

  const { unSeenMessageCount } = useSelector((state: RootState) => state.chat);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ticket redux state
  const {
    singleItem,
    page,
    dateFilter,
    priority,
    type,
    status,
    tags,
    projects,
    createdBy,
    clientSideTicketStatusFilterValue,
    changeRequestId,
    isArchived,
  } = useSelector((state: RootState) => state.ticket);

  // get Userinformation from localStorage
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  const currentPath = pathname?.split("/")?.[3];
  const insideTheChangeRequest = pathname?.split("/")?.[1];
  const params = useParams();

  const TICKET_ID = params?.ticketId;
  const [showResourceRequiredData, setShowResourceRequiredData] =
    useState(false); // State to manage visibility of data

  const handleResourceRequiredClick = () => {
    if (singleItem[0]?.resourceRequired?.length) {
      setShowResourceRequiredData(!showResourceRequiredData); // Toggle visibility of data if resourceRequired has length
    }
  };
  // this change request id will come when we inside the change request module
  const CHANGE_REQUEST_ID = params?.id;

  // Sidebar layout states
  const [openNewTicketViewDialog, setOpenTicketViewDialog] = useState(false);
  const [meetingModeEnable, setMeetingModeEnable] = useState<boolean>(false);
  const [sidebarList, setSidebarList] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sidebarListTotalItems, setSidebarListTotalItems] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [formType, setFormType] = useState("");
  const [saveResourceRequestChecked, setSaveResourceRequestChecked] =
    useState(false);
  const [saveTicketResourceRequestId, setSaveTicketResourceRequestId] =
    useState<any>("");

  const [saveResourceRequestProjectId, setSaveResourceRequestProjectId] =
    useState<string>("");
  const [saveResourceRequestProjectLabel, setSaveResourceRequestProjectLabel] =
    useState<any>("");
  const [saveResourceRequestSourceId, setSaveResourceRequestSourceId] =
    useState<any>("");
  const [saveResourceRequestResourceName, setSaveResourceRequestResourceName] =
    useState<any>("");
  const [deleteResourceRequiredById] = useDeleteResourceRequiredByIdMutation();
  const [addResourceRequestForm, openAddResourceRequestForm] =
    useState<boolean>(false);
  const handleOpenResourceRequestForm = (
    projectId: any,
    projectLabel: any,
    sourceId: any,
    resourceName: any
  ) => {
    openAddResourceRequestForm(true);
    setSaveResourceRequestProjectId(projectId);
    setSaveResourceRequestProjectLabel(projectLabel);
    setSaveResourceRequestSourceId(sourceId);
    setSaveResourceRequestResourceName(resourceName);
  };

  const handleDeleteResourceRequired = (
    resourceRequiredId: string,
    ticketId: any
  ) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Resource?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          const body = {
            ticketId: ticketId,
          };
          deleteResourceRequiredById({ resourceRequiredId, body }).then(
            (res: any) => {
              if (res.error) {
                showToast("error", res?.error?.data?.message);
              } else {
                showToast("success", res?.data?.message);
              }
            }
          );
        }
      },
    });
  };

  const handleTicketNumberCopy = (e: any, ticketNumber: any) => {
    e.stopPropagation(); // Stop propagation to prevent triggering handleTicketClick
    navigator.clipboard.writeText(ticketNumber); // Copy ticketNumber to clipboard
    showToast("success", "Copied!!");
    // setCopiedIndex(index); // Set the copied index
    // setTimeout(() => setCopiedIndex(null), 2000); // Reset copied index after 2 seconds
  };

  const {
    data: allTicketData,
    isLoading: isAllTicketLoading,
    isFetching: isAllTicketFetching,
  } = useGetAllTicketQuery({
    limit: limit,
    searchValue: searchValue,
    params: ["title", "ticketNumber"],
    page: page,
    filterBy: [
      {
        fieldName: "status",
        value: status?.value && [status?.value],
      },
      {
        fieldName: "type",
        value: type,
      },
      {
        fieldName: "priority",
        value: priority,
      },
      {
        fieldName: "createdBy",
        value: createdBy?.value,
      },
      {
        fieldName: "tags",
        value: tags?.map((ele: any) => ele?.value) || [],
      },
      {
        fieldName: "projectId",
        value: projects?.value,
      },
      {
        fieldName: "changeRequestId",
        value:
          insideTheChangeRequest === "change-request"
            ? CHANGE_REQUEST_ID
            : changeRequestId?.value,
      },
      {
        fieldName: "clientSideTicketStatus",
        value: clientSideTicketStatusFilterValue?.value,
      },
      {
        fieldName: "isArchived",
        value: isArchived,
      },
    ],
    dateFilter: {
      startDate: dateFilter?.start_date || "",
      endDate: dateFilter?.end_date || "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (!isAllTicketLoading && !isAllTicketFetching) {
      setSidebarList(allTicketData?.data);
      setSidebarListTotalItems(allTicketData?.totalItem || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTicketData, isAllTicketLoading, isAllTicketFetching]);

  const {
    isLoading,
    isFetching,
    data: messageCountData,
  } = useGetMessageCountQuery({
    ticketId: TICKET_ID || "",
    userId: userData?.userId,
  });

  // Get Single Ticket Details
  const {
    isLoading: isTicketDetailsLoading,
    isFetching: isTicketDetailsFetching,
    data: ticketData,
  } = useGetSingleTicketsViaIdQuery(TICKET_ID || "", {
    skip: !TICKET_ID,
  });
  // Set Single Ticket Details
  useEffect(() => {
    if (!isTicketDetailsLoading && !isTicketDetailsFetching) {
      dispatch(setSingleItem(ticketData?.data || []));
    }
  }, [isTicketDetailsLoading, isTicketDetailsFetching, ticketData, dispatch]);

  // get single ticket time analsis
  const [ticketTimeAnalysisData, setTicketTimeAnalysisData] = useState<any>();

  // Get Single Ticket Details
  const {
    isLoading: isLoadingTicketSingleTimeAnalysis,
    isFetching: isFetchingTicketSingleTimeAnalysis,
    data: dataTicketSingleTimeAnalysis,
  } = useGetSingleTicketTimeAnalysisViaIdQuery(TICKET_ID || "", {
    skip: !TICKET_ID,
  });
  // Set Single Ticket Details
  useEffect(() => {
    if (
      !isLoadingTicketSingleTimeAnalysis &&
      !isFetchingTicketSingleTimeAnalysis
    ) {
      setTicketTimeAnalysisData(dataTicketSingleTimeAnalysis?.data || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingTicketSingleTimeAnalysis,
    isFetchingTicketSingleTimeAnalysis,
    dataTicketSingleTimeAnalysis,
  ]);

  const getTaskDoneCount = () => {
    return singleItem?.[0]?.tasks?.reduce((sum, task) => {
      if (task?.status === "COMPLETE" || task?.status === "CLOSE") {
        return (sum += task?.taskCount);
      }
      return sum;
    }, 0);
  };
  const getTotalTaskCount = () => {
    return singleItem?.[0]?.tasks?.reduce((sum, task) => {
      return (sum += task?.taskCount);
    }, 0);
  };

  const totalTaskCount =
    useMemo(
      () => getTotalTaskCount(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [singleItem]
    ) || 0;
  const completedTaskCount =
    useMemo(
      () => getTaskDoneCount(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [singleItem]
    ) || 0;

  const tabs: any[] = [
    {
      label: "Ticket Details",
      icon: TbListDetails,
      path: "details",
      onClick: () => {
        navigate("details");
      },
      name: UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_TICKET_DETAILS,
    },
    {
      label: "Summary",
      icon: HiViewGrid,
      path: "summary",
      onClick: () => {
        navigate("summary");
      },
      name: UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_SUMMARY,
    },
    {
      label: "Chat",
      icon: HiChatAlt2,
      path: "chat",
      name: UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_CHAT,
      onClick: () => {
        navigate("chat");
      },
      children: unSeenMessageCount ? (
        <div className="bg-[#041618] rounded-full font-bold h-[23px] w-[23px] text-[10px] flex justify-center items-center -right-[6px] -top-[1px] text-white absolute">
          {unSeenMessageCount}
        </div>
      ) : (
        <></>
      ),
    },
    {
      label: "Task",
      icon: MdAddTask,
      path: "task",
      name: UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_TASK,
      onClick: () => {
        navigate("task");
      },
      children: (
        <>
          {totalTaskCount === 0 ? null : (
            <div>({completedTaskCount + "/" + totalTaskCount})</div>
          )}
        </>
      ),
    },
    {
      label: "Time Analysis",
      icon: FaStopwatch,
      path: "ticket-time-analysis",
      name: UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_TIME_ANALYIS,
      onClick: () => {
        navigate("ticket-time-analysis");
      },
    },
    {
      label: "Close Summery",
      icon: FaDoorClosed,
      name: UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_CLOSE_SUMMARY,
      path: "ticket-close-summery",
      onClick: () => {
        navigate("ticket-close-summery");
      },
    },
    {
      label: "Issues",
      icon: FaBoxTissue,
      name: UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW_ISSUES,
      path: "issues",
      onClick: () => {
        navigate("issues");
      },
    },
  ];

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setTotalUnSeenMessageCount(messageCountData?.data));
    }
  }, [isFetching, isLoading, messageCountData, dispatch]);

  // get the meeting mode initial value
  useEffect(() => {
    // get the meetingmode is enable or not
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem(
      "isMeetingModeEnable"
    ) as string;

    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";

    setMeetingModeEnable(isEnabled);
  }, []);

  React.useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("open");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of tabs) {
      const isValue = isAuthorized(
        nav?.name as keyof typeof UserModuleNameTypes
      );
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabsRender = tabs?.some((nav) => {
    return isAuthorized(nav?.name as keyof typeof UserModuleNameTypes);
  });

  if (!tabsRender) {
    return currentPath === "details" ? (
      <SideNavLayout>
        {" "}
        <AccessDenied />{" "}
      </SideNavLayout>
    ) : (
      <AccessDenied />
    );
  }

  // add the 30% of actual time if actual time is less then est time
  function calculateActualTime(actualTime: number, estimatedTime: number) {
    // Calculate 20% of estimated time
    const twentyPercentOfEstTime = 0.2 * estimatedTime;

    // Check if actual time is less than 20% of estimated time
    if (actualTime < twentyPercentOfEstTime) {
      // Add 30% of estimated time to the actual time
      const additionalTime = 0.3 * estimatedTime;
      return actualTime + additionalTime;
    } else {
      // Otherwise, return the actual time

      return actualTime;
    }
  }
  return (
    <>
      {insideTheChangeRequest === "change-request" ? (
        <div className="flex flex-col h-full">
          <div className="flex">
            <div className="">
              <TicketSideBarLayoutSideBarLayout
                hideViewMore={sidebarListTotalItems > limit ? true : false}
                listData={sidebarList}
                searchValue={searchValue}
                onSearch={(value: any) => {
                  setSearchValue(value);
                }}
                onClickViewMore={() => setLimit(limit + 10)}
                onClickChangeRequestView={(TICKET_ID: any) => {
                  navigate(
                    `/change-request/${CHANGE_REQUEST_ID}/ticket-details/${TICKET_ID}/details`
                  );
                }}
              />
            </div>
            <div className="flex flex-col w-full ">
              {/*Info Header */}
              <div className="items-center justify-between block p-2 py-2 border-b md:flex md:p-4">
                <div className="flex items-center gap-4">
                  {/* Ticket Type */}
                  {singleItem[0]?.status === "UNMAPPED" ? (
                    <div
                      className="cursor-pointer"
                      onClick={() => setIsShowAssignModal(!isShowAssignModal)}
                    >
                      <div className="font-medium px-2 py-[2px]  text-[14px] text-[#196674] underline">
                        Unassigned
                      </div>
                    </div>
                  ) : (
                    <span
                      onClick={() => setIsShowAssignModal(!isShowAssignModal)}
                    >
                      <MOLTicketTypeChip
                        ticketType={singleItem[0]?.type as any}
                      />
                    </span>
                  )}

                  {/* Title */}
                  <div className="">
                    <div className="md:text-[18px] text-[14px] capitalize">
                      {singleItem[0]?.title}
                    </div>
                    {singleItem[0]?.ticketNumber ? (
                      <div className="">
                        <Chip
                          label={
                            "Ticket Number - " + singleItem[0]?.ticketNumber ||
                            "-"
                          }
                          onClick={(e) =>
                            handleTicketNumberCopy(
                              e,
                              singleItem[0]?.ticketNumber
                            )
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div>
                    {// eslint-disable-next-line array-callback-return
                    ticketTimeAnalysisData?.map((user: any) => {
                      if (
                        user?.status === "IN_PROGRESS" &&
                        user?.users?.length
                      ) {
                        return (
                          <div className="font-medium text-red-400">
                            Duration In Ticket :{" "}
                            {user?.totalDurationInMinutes
                              ? `${user?.totalDurationInMinutes?.toFixed()} min (${Math?.floor(
                                  user?.totalDurationInMinutes / 60
                                )}:${(
                                  user?.totalDurationInMinutes % 60
                                )?.toFixed()}  hrs)`
                              : `0 min`}
                          </div>
                        );
                      }
                    })}
                  </div>
                  {/* Ticket Status */}
                  <div>
                    <MOLTicketStatusChip
                      status={singleItem[0]?.status as any}
                    />
                  </div>
                  {
                    <span
                      className="px-2 py-1 text-xs font-medium text-white bg-green-500 rounded-md h-fit"
                      onClick={() => {
                        setClientSideTicketStatus(true);
                        setClientSideTicketStatusValue(
                          singleItem[0]?.clientSideTicketStatus
                        );
                      }}
                    >
                      {singleItem[0]?.clientSideTicketStatus || "-"}
                    </span>
                  }

                  {/*  CHANGE_REQUEST FLOW  */}
                  {/* Create ETA button render when ticket type is CHANGE_REQUEST */}
                  {singleItem[0]?.type === TicketTypeEnum.change_request &&
                  !singleItem[0]?.numberOfMembers ? (
                    <div>
                      <ATMLoadingButton
                        className="bg-primary-dark"
                        onClick={() =>
                          setIsShowCreateETAModal(!isShowCreateETAModal)
                        }
                      >
                        Create ETA
                      </ATMLoadingButton>
                    </div>
                  ) : null}
                  {singleItem[0]?.type === TicketTypeEnum.change_request &&
                  singleItem[0]?.numberOfMembers &&
                  singleItem[0]?.status === "APPROVAL_PENDING" ? (
                    <div>
                      <ATMLoadingButton
                        className="bg-primary-dark"
                        onClick={() =>
                          setIsShowEditETAModal(!isShowEditETAModal)
                        }
                      >
                        Update ETA
                      </ATMLoadingButton>
                    </div>
                  ) : null}

                  {/* Create ETA Modal */}
                  <CreateETAModalWrapper
                    ticketId={TICKET_ID || ""}
                    open={isShowCreateETAModal}
                    handleClose={() =>
                      setIsShowCreateETAModal(!isShowCreateETAModal)
                    }
                  />
                  {/* Edit ETA Modal */}
                  <EditETAModalWrapper
                    ticketId={TICKET_ID || ""}
                    open={isShowEditETAModal}
                    handleClose={() =>
                      setIsShowEditETAModal(!isShowEditETAModal)
                    }
                    estInfo={{
                      layerWiseEst: singleItem?.[0]?.layerWiseEst,
                      noOfDeveloper: singleItem?.[0]?.numberOfMembers,
                    }}
                  />

                  {/* Assign to team member Modal */}
                  <AssignTicketTeamMemberModalWrapper
                    ticketId={TICKET_ID || ""}
                    open={isShowAssignTeamMemberModal}
                    handleClose={() =>
                      setIsShowAssignTeamMemberModal(
                        !isShowAssignTeamMemberModal
                      )
                    }
                  />

                  {/* Close Ticket modal */}
                  {singleItem[0]?.type === TicketTypeEnum.bug ? (
                    <MarkAsDoneBugTypeModalWrapper
                      ticketId={TICKET_ID || ""}
                      open={isShowMarkAsDoneModal}
                      handleClose={() =>
                        setIsShowMarkAsDoneModal(!isShowMarkAsDoneModal)
                      }
                    />
                  ) : (
                    <MarkAsDoneModalWrapper
                      ticketId={TICKET_ID || ""}
                      open={isShowMarkAsDoneModal}
                      handleClose={() =>
                        setIsShowMarkAsDoneModal(!isShowMarkAsDoneModal)
                      }
                    />
                  )}
                </div>
              </div>

              {/* Tabs */}
              <div className="">
                <ATMTab
                  tabs={tabs}
                  selected={(tab: any) => tab.path === currentPath}
                  childrenLabel={currentPath}
                  children={<></>}
                />
              </div>

              <div className="flex flex-1 overflow-auto">
                {/* Main Section */}
                <div className="w-[calc(100%-300px)] h-full">
                  <Outlet />
                </div>

                {/* Info Side Bar */}
                <div className="border-l border-slate-300 min-w-[300px] max-w-[300px] h-full overflow-auto">
                  <InfoField
                    label="Created On"
                    value={moment(singleItem[0]?.createdAt).format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                  />

                  <InfoField
                    label="Created By"
                    value={singleItem[0]?.createdByLabel}
                  />
                  {singleItem[0]?.estTime ? (
                    <InfoField
                      label="Estimated Time (in Mins)"
                      value={
                        getFormattedEstimationTime(
                          parseInt(singleItem[0]?.estTime)
                        ) || "-"
                      }
                    />
                  ) : null}
                  {singleItem[0]?.timeTaken ? (
                    <InfoField
                      label="Time Spent"
                      value={
                        getFormattedEstimationTime(singleItem[0]?.timeTaken) ||
                        ""
                      }
                    />
                  ) : null}
                  {singleItem[0]?.numberOfDeveloper ? (
                    <InfoField
                      label="No. Of Developers"
                      value={singleItem[0]?.numberOfDeveloper?.toString() || ""}
                    />
                  ) : null}
                  <div>
                    <InfoField
                      label="Change Request"
                      onClick={() => setIsShowEditChangeRequestModal(true)}
                      value={
                        singleItem[0]?.changeRequestName
                          ? singleItem[0]?.changeRequestName
                          : "-"
                      }
                    />
                  </div>

                  <div className="flex">
                    <InfoField
                      label="Source"
                      value={singleItem[0]?.sourceName || "No Source"}
                    />

                    <InfoField
                      label="Source Title"
                      value={singleItem[0]?.sourceTitle || "No Source Title"}
                    />
                  </div>

                  <TicketTagsWrapper
                    ticketId={TICKET_ID}
                    initialTags={singleItem[0]?.tags}
                  />

                  <div className="p-2 px-1">
                    <p
                      className={`text-neutral ${
                        singleItem[0]?.resourceRequired?.length
                          ? null
                          : "bg-slate-200"
                      } p-2  font-bold  text-xs pb-1 cursor-pointer justify-between  flex`}
                      onClick={handleResourceRequiredClick}
                    >
                      <span>Required Resources</span>
                      <span>^</span>
                    </p>
                    <div>
                      {showResourceRequiredData && (
                        <div>
                          {singleItem[0]?.resourceRequired?.map(
                            (resourceRequired, index) => (
                              <div
                                key={index}
                                className={`text-slate-800 text-sm flex ${
                                  resourceRequired?.resourceRequestId
                                    ? "bg-slate-200"
                                    : "bg-white"
                                } justify-between`}
                              >
                                <div className="flex">
                                  <span className="p-2">{index + 1}.</span>
                                  <div className="flex items-center p-2">
                                    <span className="inline-block max-w-full">
                                      {resourceRequired?.resource}
                                    </span>
                                  </div>
                                </div>
                                <span className="mt-2">
                                  <div className="items-end">
                                    <ATMActionMenu
                                      options={
                                        [
                                          {
                                            label: (
                                              <div className="flex items-center gap-x-2">
                                                <IoDuplicate color="#000000" />
                                                To Resource Request
                                              </div>
                                            ),
                                            onClick: () => {
                                              handleOpenResourceRequestForm(
                                                singleItem[0]?.projectId,
                                                singleItem[0]?.projectLabel,
                                                singleItem[0]?._id,
                                                resourceRequired?.resource
                                              );
                                            },
                                          },

                                          {
                                            label: (
                                              <div className="flex items-center gap-x-2">
                                                <MdDelete color="#000000" />{" "}
                                                Delete
                                              </div>
                                            ),
                                            onClick: () => {
                                              handleDeleteResourceRequired(
                                                resourceRequired?._id,
                                                singleItem[0]?._id
                                              );
                                            },
                                          },
                                        ].filter(Boolean) as OptionType[]
                                      }
                                    />
                                  </div>
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {isShowEditChangeRequestModal && (
                    <EditChangeRequestFormWrapper
                      changeRequestId={singleItem[0]?.changeRequestName}
                      ProjectId={singleItem[0]?.projectId}
                      changeRequestLabel={singleItem[0]?.changeRequestName}
                      onClose={() => setIsShowEditChangeRequestModal(false)}
                    />
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="p-4 border-t">
                  <span>
                    <ATMCheckbox
                      label="Resource Required?"
                      checked={saveResourceRequestChecked}
                      inputClasses="h-[15px]"
                      labelClasses="text-sm select-none"
                      onChange={(checked) => {
                        setSaveResourceRequestChecked(checked);
                        setSaveTicketResourceRequestId(TICKET_ID || "");
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SideNavLayout>
          <div className="flex flex-col h-full">
            <div className="flex">
              <div className="">
                <TicketSideBarLayoutSideBarLayout
                  hideViewMore={sidebarListTotalItems > limit ? true : false}
                  listData={sidebarList}
                  searchValue={searchValue}
                  onSearch={(value) => {
                    setSearchValue(value);
                  }}
                  onClickViewMore={() => setLimit(limit + 20)}
                  onClickChangeRequestView={(crId) => {
                    navigate(`/ticket/${crId}/details`);
                  }}
                />
              </div>
              {isTicketDetailsLoading || isTicketDetailsFetching ? (
                <div className="w-full flex flex-col h-[calc(100vh-50px)]">
                  {/*Info Header */}
                  <div className="items-center justify-between block p-2 py-2 border-b md:flex md:p-4 h-[95px]">
                    <div className="flex items-center gap-4">
                      <div className="font-medium   w-[130px]">
                        <Skeleton animation="wave" height={40} />
                      </div>
                      {/* Title */}
                      <div className="">
                        <Skeleton animation="wave" height={60} width={400} />
                        <Skeleton animation="wave" height={50} width={130} />
                      </div>
                    </div>

                    <div className="flex items-center gap-4">
                      <div>
                        <Skeleton animation="wave" height={70} width={130} />
                      </div>
                      <div>
                        <Skeleton animation="wave" height={70} width={130} />
                      </div>

                      <div className="font-medium">
                        <Skeleton animation="wave" height={40} width={130} />
                      </div>

                      {/* Ticket Status */}
                      <div>
                        <Skeleton animation="wave" width={70} />
                      </div>
                      <Skeleton animation="wave" width={70} />
                      {/*  CHANGE_REQUEST FLOW  */}
                    </div>
                  </div>
                  {/* Tabs */}
                  <div className="">
                    <ATMTab
                      tabs={tabs}
                      selected={(tab: any) => tab.path === currentPath}
                      childrenLabel={currentPath}
                      children={<></>}
                    />
                  </div>

                  <div className="flex-1 block h-full overflow-auto md:flex md:overflow-hidden">
                    {/* Main Section */}
                    <div className="md:w-[calc(100%-250px)] w-full md:h-full h-[75%]">
                      <div className="grid grid-cols-2 gap-6 m-10">
                        <Skeleton variant="rectangular" height={170} />
                        <Skeleton variant="rectangular" height={170} />
                        <Skeleton variant="rectangular" height={170} />
                        <Skeleton variant="rectangular" height={170} />
                      </div>
                    </div>
                    <div className="block my-2 md:hidden">
                      <Divider />
                    </div>
                    {/* Info Side Bar */}
                    <div className="md:min-w-[250px] min-w-full md:max-w-[250px] max-w-full h-full overflow-auto border-l px-3">
                      <Skeleton animation="wave" height={70} />

                      <Skeleton animation="wave" height={70} />

                      <Skeleton animation="wave" height={70} />
                      <Skeleton animation="wave" height={70} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full flex flex-col h-[calc(100vh-50px)]">
                  {/*Info Header */}
                  <div className="items-center justify-between block p-2 py-2 border-b md:flex md:p-4">
                    <div className="flex items-center gap-4">
                      {/* Ticket Type */}
                      {singleItem[0]?.status === "UNMAPPED" ? (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            setIsShowAssignModal(!isShowAssignModal)
                          }
                        >
                          <div className="font-medium px-2 py-[2px]  text-[14px] text-[#196674] underline">
                            Unassigned
                          </div>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setIsShowAssignModal(!isShowAssignModal)
                          }
                        >
                          <MOLTicketTypeChip
                            ticketType={singleItem[0]?.type as any}
                          />
                        </span>
                      )}

                      {/* Assign ticket details modal */}
                      <AssignTicketDetailsModalWrapper
                        initialData={singleItem[0]}
                        ticketId={TICKET_ID || ""}
                        open={isShowAssignModal}
                        handleClose={() =>
                          setIsShowAssignModal(!isShowAssignModal)
                        }
                      />

                      {/* Title */}
                      <div className="">
                        <div className="md:text-[18px] text-[14px] capitalize">
                          {singleItem[0]?.title}
                        </div>
                        {singleItem[0]?.ticketNumber ? (
                          <div className="">
                            <Chip
                              label={
                                "Ticket Number - " +
                                  singleItem[0]?.ticketNumber || "-"
                              }
                              onClick={(e) =>
                                handleTicketNumberCopy(
                                  e,
                                  singleItem[0]?.ticketNumber
                                )
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex items-center gap-4">
                      <div>
                        <ATMLoadingButton
                          onClick={() => setOpenTicketViewDialog(true)}
                        >
                          Creat new ticket
                        </ATMLoadingButton>
                      </div>
                      <div>
                        <ATMLoadingButton
                          // disabled={singleItem[0]?.status === "UNMAPPED"}
                          title="Please assign the ticket first"
                          onClick={() => {
                            setProjectId(singleItem[0]?.projectId);
                            setIsOpenAddFormDialog(true);
                            setFormType("TICKET_TO_TASK");
                          }}
                        >
                          Ticket to task
                        </ATMLoadingButton>
                      </div>
                      {ticketTimeAnalysisData?.length ? (
                        // eslint-disable-next-line array-callback-return
                        ticketTimeAnalysisData?.map((user: any) => {
                          const meetingModeTime = calculateActualTime(
                            user?.totalDurationInMinutes,
                            parseInt(singleItem[0]?.estTime as any)
                          );
                          if (
                            user?.status === "IN_PROGRESS" &&
                            user?.users?.length
                          ) {
                            return (
                              <div className="font-medium text-red-400 md:text-[16px] text-[14px]">
                                Duration In Ticket :{" "}
                                {meetingModeEnable
                                  ? `${meetingModeTime?.toFixed()} min (${Math?.floor(
                                      meetingModeTime / 60
                                    )}:${(
                                      meetingModeTime % 60
                                    )?.toFixed()}  hrs)`
                                  : user?.totalDurationInMinutes
                                    ? `${user?.totalDurationInMinutes?.toFixed()} min (${Math?.floor(
                                        user?.totalDurationInMinutes / 60
                                      )}:${(
                                        user?.totalDurationInMinutes % 60
                                      )?.toFixed()}  hrs)`
                                    : `0 min`}
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="font-medium text-red-400 md:text-[16px] text-[14px]">
                          {" "}
                          Duration In Ticket : 0 min
                        </div>
                      )}

                      {/* Ticket Status */}
                      <div>
                        <MOLTicketStatusChip
                          status={singleItem[0]?.status as any}
                        />
                      </div>

                      {
                        <span
                          className="px-2 py-1 text-xs font-medium text-white bg-green-500 rounded-md h-fit"
                          onClick={() => {
                            setClientSideTicketStatus(true);
                            setClientSideTicketStatusValue(
                              singleItem[0]?.clientSideTicketStatus
                            );
                          }}
                        >
                          {singleItem[0]?.clientSideTicketStatus || "-"}
                        </span>
                      }
                      {/*  CHANGE_REQUEST FLOW  */}
                      {/* Create ETA button render when ticket type is CHANGE_REQUEST */}
                      {singleItem[0]?.type === TicketTypeEnum.change_request &&
                      !singleItem[0]?.numberOfMembers ? (
                        <div>
                          <ATMLoadingButton
                            className="bg-primary-dark"
                            onClick={() =>
                              setIsShowCreateETAModal(!isShowCreateETAModal)
                            }
                          >
                            Create ETA
                          </ATMLoadingButton>
                        </div>
                      ) : null}
                      {singleItem[0]?.type === TicketTypeEnum.change_request &&
                      singleItem[0]?.numberOfMembers &&
                      singleItem[0]?.status === "APPROVAL_PENDING" ? (
                        <div>
                          <ATMLoadingButton
                            className="bg-primary-dark"
                            onClick={() =>
                              setIsShowEditETAModal(!isShowEditETAModal)
                            }
                          >
                            Update ETA
                          </ATMLoadingButton>
                        </div>
                      ) : null}

                      {/*  SUPPORT FLOW  && BUG FLOW */}
                      {/* Close Ticket button render when ticket is assign to team member */}
                      {singleItem[0]?.status === TicketStatusEnum.mapped &&
                        singleItem[0]?.type !==
                          TicketTypeEnum.change_request && (
                          <div>
                            <ATMLoadingButton
                              className="bg-green-400"
                              onClick={() =>
                                setIsShowMarkAsDoneModal(!isShowMarkAsDoneModal)
                              }
                            >
                              Close Ticket
                            </ATMLoadingButton>
                          </div>
                        )}

                      {singleItem[0]?.type === TicketTypeEnum.change_request &&
                        singleItem[0]?.status ===
                          TicketStatusEnum.approval_done &&
                        singleItem[0]?.isEtaApproved === true && (
                          <div>
                            <ATMLoadingButton
                              className="bg-green-400"
                              onClick={() =>
                                setIsShowMarkAsDoneModal(!isShowMarkAsDoneModal)
                              }
                            >
                              Close Ticket
                            </ATMLoadingButton>
                          </div>
                        )}

                      {/* Create ETA Modal */}
                      <CreateETAModalWrapper
                        ticketId={TICKET_ID || ""}
                        open={isShowCreateETAModal}
                        handleClose={() =>
                          setIsShowCreateETAModal(!isShowCreateETAModal)
                        }
                      />
                      {/* Edit ETA Modal */}
                      <EditETAModalWrapper
                        ticketId={TICKET_ID || ""}
                        open={isShowEditETAModal}
                        handleClose={() =>
                          setIsShowEditETAModal(!isShowEditETAModal)
                        }
                        estInfo={{
                          layerWiseEst: singleItem?.[0]?.layerWiseEst,
                          noOfDeveloper: singleItem?.[0]?.numberOfMembers,
                        }}
                      />

                      {/* Assign to team member Modal */}
                      <AssignTicketTeamMemberModalWrapper
                        ticketId={TICKET_ID || ""}
                        open={isShowAssignTeamMemberModal}
                        handleClose={() =>
                          setIsShowAssignTeamMemberModal(
                            !isShowAssignTeamMemberModal
                          )
                        }
                      />

                      {/* Close Ticket modal */}
                      {singleItem[0]?.type === TicketTypeEnum.bug ? (
                        <MarkAsDoneBugTypeModalWrapper
                          ticketId={TICKET_ID || ""}
                          open={isShowMarkAsDoneModal}
                          handleClose={() =>
                            setIsShowMarkAsDoneModal(!isShowMarkAsDoneModal)
                          }
                        />
                      ) : (
                        <MarkAsDoneModalWrapper
                          ticketId={TICKET_ID || ""}
                          open={isShowMarkAsDoneModal}
                          handleClose={() =>
                            setIsShowMarkAsDoneModal(!isShowMarkAsDoneModal)
                          }
                        />
                      )}
                    </div>
                  </div>
                  {/* Tabs */}
                  <div className="">
                    <ATMTab
                      tabs={tabs}
                      selected={(tab: any) => tab.path === currentPath}
                      childrenLabel={currentPath}
                      children={<></>}
                    />
                  </div>

                  <div className="flex-1 block h-full overflow-auto md:flex md:overflow-hidden">
                    {/* Main Section */}
                    <div className="md:w-[calc(100%-250px)] w-full md:h-full h-[75%]">
                      <Outlet />
                    </div>
                    <div className="block my-2 md:hidden">
                      <Divider />
                    </div>
                    {/* Info Side Bar */}
                    <div className="md:min-w-[250px] min-w-full md:max-w-[250px] max-w-full h-full overflow-auto border-l">
                      <InfoField
                        label="Created On"
                        value={moment(singleItem[0]?.createdAt).format(
                          "DD-MM-YYYY hh:mm A"
                        )}
                      />

                      <InfoField
                        label="Created By"
                        value={singleItem[0]?.createdByLabel}
                      />
                      {singleItem[0]?.estTime ? (
                        <InfoField
                          label="Estimated Time (in Mins)"
                          value={
                            getFormattedEstimationTime(
                              parseInt(singleItem[0]?.estTime)
                            ) || "-"
                          }
                        />
                      ) : null}
                      {singleItem[0]?.timeTaken ? (
                        <InfoField
                          label="Time Spent"
                          value={
                            getFormattedEstimationTime(
                              singleItem[0]?.timeTaken
                            ) || ""
                          }
                        />
                      ) : null}
                      {singleItem[0]?.numberOfDeveloper ? (
                        <InfoField
                          label="No. Of Developers"
                          value={
                            singleItem[0]?.numberOfDeveloper?.toString() || ""
                          }
                        />
                      ) : null}

                      <div>
                        <InfoField
                          label="Change Request"
                          onClick={() => setIsShowEditChangeRequestModal(true)}
                          value={
                            singleItem[0]?.changeRequestName
                              ? singleItem[0]?.changeRequestName
                              : "-"
                          }
                        />
                      </div>

                      <div className="flex">
                        <InfoField
                          label="Source"
                          value={singleItem[0]?.sourceName || "No Source"}
                        />

                        <InfoField
                          label="Source Title"
                          value={
                            singleItem[0]?.sourceTitle || "No Source Title"
                          }
                        />
                      </div>

                      <TicketTagsWrapper
                        ticketId={TICKET_ID}
                        initialTags={singleItem[0]?.tags}
                      />

                      <div className="p-2 px-1">
                        <p
                          className={`text-neutral ${
                            singleItem[0]?.resourceRequired?.length
                              ? null
                              : "bg-slate-200"
                          } p-2  font-bold  text-xs pb-1 cursor-pointer justify-between  flex`}
                          onClick={handleResourceRequiredClick}
                        >
                          <span>Required Resources</span>
                          <span> ^</span>
                        </p>
                        <div>
                          {showResourceRequiredData && (
                            <div>
                              {singleItem[0]?.resourceRequired?.map(
                                (resourceRequired, index) => (
                                  <div
                                    key={index}
                                    className={`text-slate-800 text-sm flex ${
                                      resourceRequired?.resourceRequestId
                                        ? "bg-slate-200"
                                        : "bg-white"
                                    } justify-between`}
                                  >
                                    <div className="flex">
                                      <span className="p-2">{index + 1}.</span>
                                      <div className="flex items-center p-2">
                                        <span className="inline-block max-w-full">
                                          {resourceRequired?.resource}
                                        </span>
                                      </div>
                                    </div>
                                    <span className="mt-2">
                                      <div className="items-end">
                                        <ATMActionMenu
                                          options={
                                            [
                                              {
                                                label: (
                                                  <div className="flex items-center gap-x-2">
                                                    <IoDuplicate color="#000000" />
                                                    To Resource Request
                                                  </div>
                                                ),
                                                onClick: () => {
                                                  handleOpenResourceRequestForm(
                                                    singleItem[0]?.projectId,
                                                    singleItem[0]?.projectLabel,
                                                    singleItem[0]?._id,
                                                    resourceRequired?.resource
                                                  );
                                                },
                                              },

                                              {
                                                label: (
                                                  <div className="flex items-center gap-x-2">
                                                    <MdDelete color="#000000" />{" "}
                                                    Delete
                                                  </div>
                                                ),
                                                onClick: () => {
                                                  handleDeleteResourceRequired(
                                                    resourceRequired?._id,
                                                    singleItem[0]?._id
                                                  );
                                                },
                                              },
                                            ].filter(Boolean) as OptionType[]
                                          }
                                        />
                                      </div>
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {isShowEditChangeRequestModal && (
                        <EditChangeRequestFormWrapper
                          changeRequestId={singleItem[0]?.changeRequestName}
                          changeRequestLabel={singleItem[0]?.changeRequestName}
                          ProjectId={singleItem[0]?.projectId}
                          onClose={() => setIsShowEditChangeRequestModal(false)}
                        />
                      )}

                      {clientSideTicketStatus ? (
                        <TicketStatusDialogWrapper
                          onClose={() => setClientSideTicketStatus(false)}
                          ticketId={TICKET_ID || ""}
                          clientSideTicketStatusValue={
                            clientSideTicketStatusValue
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="p-4 border-t">
                      <span>
                        <ATMCheckbox
                          label="Resource Required?"
                          checked={saveResourceRequestChecked}
                          inputClasses="h-[15px]"
                          labelClasses="text-sm select-none"
                          onChange={(checked) => {
                            setSaveResourceRequestChecked(checked);
                            setSaveTicketResourceRequestId(TICKET_ID || "");
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </SideNavLayout>
      )}
      {isOpenAddFormDialog ? (
        <AddTaskFormWrapper
          onClose={() => setIsOpenAddFormDialog(false)}
          id={projectId}
          formType={formType}
        />
      ) : null}
      {saveResourceRequestChecked ? (
        <RequiredResourceWrapper
          TicketId={saveTicketResourceRequestId}
          onClose={() => setSaveResourceRequestChecked(false)}
        />
      ) : null}

      {addResourceRequestForm ? (
        <AddResourceRequestFormWrapper
          onClose={() => openAddResourceRequestForm(false)}
          projectId={saveResourceRequestProjectId}
          projectLabel={saveResourceRequestProjectLabel}
          resourceRequiredSourceName="TICKET"
          resourceRequiredSourceId={saveResourceRequestSourceId}
          resourceRequiredResourceName={saveResourceRequestResourceName}
        />
      ) : null}

      {openNewTicketViewDialog && (
        <AddTicketFormWrapper
          onClose={() => setOpenTicketViewDialog(false)}
          sourceName={"INTERNAL"}
        />
      )}
      {/* Assign ticket details modal */}
      <AssignTicketDetailsModalWrapper
        initialData={singleItem[0]}
        ticketId={TICKET_ID || ""}
        open={isShowAssignModal}
        handleClose={() => setIsShowAssignModal(!isShowAssignModal)}
      />
    </>
  );
};

export default TicketViewLayout;
