import { Form, Formik, FormikHelpers } from "formik";
import { OperationsFormValues } from "src/models/Operations.model";
import { useAddOperationMutation } from "src/services/OperationsService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import OperationForm from "../Layouts/OperationForm";
import { useNavigate } from "react-router-dom";

const AddOperationFormWrapper = () => {
  const navigate = useNavigate();
  const [addOperation] = useAddOperationMutation();

  // Form Initial Values
  const initialValues: OperationsFormValues = {
    title: "",
    description: "",
    modelName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter title"),
    modelName: array().required("Please Select Industry"),
    description: string().required("Please enter description"),
  });
  // Handle Submit
  const handleSubmit = (
    values: OperationsFormValues,
    { setSubmitting, resetForm }: FormikHelpers<OperationsFormValues>
  ) => {
    const formattedValues = {
      title: values?.title || "",
      description: values?.description || "",
      businessModels: values?.modelName?.map((el: any) => el?.value) || "",
    };
    addOperation(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate("/enterprisesuite/operation");
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <OperationForm
            formType="ADD"
            formikProps={formikProps}
            onClose={() => navigate("/enterprisesuite/operation")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddOperationFormWrapper;
