import React, { useEffect, useState } from "react";
import SkillListing from "./SkillListing";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
  setItems,
  setIsTableLoading,
} from "src/redux/slices/SkillSlice";
import {
  useAllskillListPaginationQuery,
  useDeleteskillMutation,
} from "src/services/SkillService";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdDelete, MdEdit } from "react-icons/md";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import AddSkillFormWrapper from "../Add/AddSkillFormWrapper";
import { showToast } from "src/utils/showToaster";
import EditSkillFormWrapper from "../Edit/EditSkillFormWrapper";

type Props = {};

const SkillListingWrapper = (props: Props) => {
  const dispatch = useDispatch();

  const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);
  const [skillId, setSkillId] = useState<any>("");

  const [deleteSkill] = useDeleteskillMutation();

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.skill);

  const { data, isLoading, isFetching } = useAllskillListPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["skillName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "skillName",
      headerName: "Name",
      name: UserModuleNameTypes.INCIDENT_CATEGORY_LIST_INCIDENT_CATEGORY_NAME,
      flex: "flex-[1_1_0%]",
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditForm(true);
                  setSkillId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_INCIDENT_CATEGORY_EDIT,
              },
              {
                label: (
                  <div className="flex items-center font-medium text-red-700 gap-x-2">
                    <MdDelete color="#b91c1c" /> Delete
                  </div>
                ),
                onClick: () => {
                  showConfirmationDialog({
                    title: "Hands Up",
                    text: "Are you sure want to delete this Skill?",
                    icon: "question",
                    showCancelButton: true,
                    next: (result) => {
                      if (result?.isConfirmed) {
                        deleteSkill(row?._id).then((res: any) => {
                          if (res.error) {
                            showToast("error", res?.error?.data?.message);
                          } else {
                            showToast("success", res?.data?.message);
                          }
                        });
                      }
                    },
                  });
                },
                acitonName: UserModuleNameTypes.ACTION_INCIDENT_CATEGORY_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <SkillListing
        columns={columns}
        rows={items}
        onAddNew={() => setIsOpenAddFormDialog(true)}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenAddFormDialog && (
        <AddSkillFormWrapper
          onClose={() => {
            setIsOpenAddFormDialog(false);
          }}
        />
      )}
      {isOpenEditForm && (
        <EditSkillFormWrapper
          onClose={() => setIsOpenEditForm(false)}
          skillId={skillId}
        />
      )}
    </div>
  );
};

export default SkillListingWrapper;
