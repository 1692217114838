import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { RootState } from "src/redux/store";
import {
  useAddFunctionalityInnerScreenMutation,
  useEditPlatformsMutation,
  useUpdateScreenByIdMutation,
  useUpdateScreenFunctionalityByIdMutation,
} from "src/services/RequirementGatheringService";
import { showToast } from "src/utils/showToaster";
import { useParams } from "react-router-dom";
import {
  // setRequirementGathering,
  setSelectedPlatformData,
} from "src/redux/slices/RequirementGatheringNewSlice";

const ScreenFeatureList = () => {
  const { selectedData, selectedPlatformData } = useSelector(
    (state: RootState) => state.requirementGatheringNew
  );

  const { platformId } = useParams();
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [textEditor, setTextEditor] = useState("");
  //
  const [funcInputValue, setFuncInputValue] = useState("");
  const [funcTextEditor, setFuncTextEditor] = useState("");

  const [updateScreen, updateScreenInfo] = useUpdateScreenByIdMutation();
  const [updatePlatform] = useEditPlatformsMutation();
  const [addFunctionality] = useAddFunctionalityInnerScreenMutation();
  const [updateScreenFunctionality, updateScreenFunctionalityInfo] =
    useUpdateScreenFunctionalityByIdMutation();

  // const [showFeatures, setShowFeatures] = useState(false);
  useEffect(() => {
    if (selectedData?.data?.heading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedData]);

  useEffect(() => {
    setInputValue(selectedData?.data?.heading || "");
    setTextEditor(selectedData?.data?.description || "");
  }, [selectedData]);

  const handleSubmitNewData = (handleSelectedData: any) => {
    if (handleSelectedData.type === "screen") {
      updateScreen({
        id: handleSelectedData?._id,
        body: {
          platformId: handleSelectedData?.platFormId,
          screenName: inputValue,
          screenDescription: textEditor,
        },
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            const updatedScreen = {
              _id: res?.data?.data?._id,
              screenName: res?.data?.data?.screenName,
              screenDescription: res?.data?.data?.screenDescription,
            };

            const updatedPlatformData = {
              platformName: selectedPlatformData.platformName,
              workspaceId: selectedPlatformData.workspaceId,
              groups: selectedPlatformData.groups?.map((group: any) =>
                group._id === res.data.data._id
                  ? { ...group, ...updatedScreen }
                  : group
              ),
            };

            updatePlatform({
              platformId: platformId,
              body: updatedPlatformData,
            });
          } else {
            showToast("error", res?.data?.message);
          }
        }
      });
    } else if (handleSelectedData.type === "functionality") {
      updateScreenFunctionality({
        id: handleSelectedData?._id,
        body: {
          screenId: handleSelectedData?.parent,
          functionalityName: inputValue,
          functionalityDescription: textEditor,
        },
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            const updatedFunctionality = {
              _id: res?.data?.data?._id,
              functionalityName: res?.data?.data?.functionalityName,
              functionalityDescription:
                res?.data?.data?.functionalityDescription,
            };

            const updatedPlatformData = {
              workspaceId: selectedPlatformData?.workspaceId,
              platformName: selectedPlatformData?.platformName,
              groups: selectedPlatformData.groups?.map((group: any) =>
                group._id === res.data.data._id
                  ? { ...group, ...updatedFunctionality }
                  : group
              ),
            };

            updatePlatform({
              platformId: platformId,
              body: updatedPlatformData,
            });
          } else {
            showToast("error", res?.data?.message);
          }
        }
      });
    }
  };

  const handleAddFunctionality = () => {
    addFunctionality({
      screenId: selectedData?._id,
      functionalityName: `New Functionality`,
      functionalityDescription: "",
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        showToast("success", res?.data?.message);

        const newFunctionality = {
          functionalityName: funcInputValue,
          functionalityDescription: funcTextEditor,
          screenId: selectedData?._id,
          _id: res?.data?.data?._id,
          parent: res?.data?.data?.screenId,
          nodeType: "functionality",
        };

        const updatedPlatformData = {
          platformName: selectedPlatformData.platformName,
          workspaceId: selectedPlatformData.workspaceId,
          groups: [...selectedPlatformData?.groups, newFunctionality],
        };

        // Update the platform with the new group data
        updatePlatform({
          platformId: platformId,
          body: updatedPlatformData,
        }).then((updateRes: any) => {
          if (updateRes?.error) {
            showToast("error", updateRes?.error?.data?.message);
          } else {
            showToast("success", updateRes?.data?.message);
            dispatch(setSelectedPlatformData(updatedPlatformData));
            setFuncInputValue("");
            setFuncTextEditor("");
          }
        });
      }
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "s") {
        if (inputValue) {
          event.preventDefault();
          handleSubmitNewData(selectedData);
        }
      } else if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        handleAddFunctionality();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, textEditor, selectedData, handleAddFunctionality]);

  // const breadcrumbs: BreadcrumbType[] = [
  //     { label: "Home", path: "/" },
  //     { label: "About", path: "/about" }
  // ];

  return (
    <div className="relative flex w-full h-full">
      <div className="flex flex-col flex-1 transition-all duration-300">
        {selectedData?.data?.heading && (
          <>
            <div className="flex-1 h-full gap-2 p-2 overflow-auto">
              {/* BreadCrumbs */}
              {/* <div className="h-[40px]">
                        <ATMBreadCrumbs breadcrumbs={breadcrumbs} />
                    </div> */}
              <div className="flex flex-col">
                <div className="mb-2 text-xs font-semibold w-fit text-blue">
                  {selectedData?.type === "screen"
                    ? "#Screen"
                    : "#Functionality"}
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-lg">
                    <input
                      type="text"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder={`${
                        selectedData?.type === "screen"
                          ? "Enter Screen Name"
                          : "Enter functionality Name"
                      }`}
                      className="w-full text-2xl font-medium rounded focus:outline-none focus:border-blue-500"
                      style={{ caretColor: "blue" }}
                      ref={inputRef}
                    />
                  </div>
                </div>
                <div className="z-0 mt-2">
                  <MOLMarkdownEditor
                    placeholder="Write description or drag your files here...."
                    value={textEditor}
                    onChange={(e: any) => setTextEditor(e)}
                    extraClassName="border-0"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center w-full gap-4 p-2 bg-white border-t">
              <ATMLoadingButton
                title="ctrl+s"
                isLoading={
                  updateScreenInfo.isLoading ||
                  updateScreenFunctionalityInfo.isLoading
                }
                onClick={() => handleSubmitNewData(selectedData)}
                // className="w-36"
                type="submit"
                disabled={!inputValue}
              >
                {selectedData?.type === "screen"
                  ? "Save Screen"
                  : "Save Functionality"}
              </ATMLoadingButton>
              <div className="text-xs text-gray">
                {selectedData?.type === "screen"
                  ? " TIP: Press Cltr + s to save screen details"
                  : " TIP: Press Cltr + s to save functionality details"}
              </div>
            </div>
          </>
        )}
      </div>

      {selectedData?.type === "screen" && (
        <div className="flex flex-col flex-1 transition-all duration-300 border-l">
          {/* {selectedData?.data?.heading && ( */}
          <div className="flex-1 h-full gap-2 p-2 overflow-auto">
            <div className="mb-2 text-xs font-semibold w-fit text-blue">
              #Functionality
            </div>
            <div className="flex items-center justify-between">
              <div className="text-lg">
                <input
                  // autoFocus
                  type="text"
                  value={funcInputValue}
                  onChange={(e) => setFuncInputValue(e.target.value)}
                  placeholder="Enter functionality Name"
                  className="w-full text-2xl font-medium rounded focus:outline-none focus:border-blue-500"
                  style={{ caretColor: "blue" }}
                  ref={inputRef}
                />
              </div>
            </div>
            <div className="z-0 mt-2">
              <MOLMarkdownEditor
                placeholder="Write description"
                value={funcTextEditor}
                onChange={(e: any) => setFuncTextEditor(e)}
                extraClassName="border-0"
              />
            </div>
          </div>
          <div className="flex items-center w-full gap-4 p-2 bg-white border-t">
            <ATMLoadingButton
              variant="tonal"
              onClick={() => {
                handleAddFunctionality();
              }}
            >
              Save Feature
            </ATMLoadingButton>
            <div className="text-xs text-gray">
              TIP: Press Cltr + f to save functionality details
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScreenFeatureList;
