import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetChangeRequestReportQuery } from "src/services/ChangeRequestServices";
import ChangeRequestLog from "./ChangeRequestLog";
import { RootState } from "src/redux/store";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { getTimeInHours } from "src/utils/constants/ticketUtils";
import {
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/ChangeRequestActivityLogSlice";
import { CircularProgress } from "@mui/material";

const ChangeRequestLogWrapper = () => {
  const dispatch = useDispatch();
  const { id: CHANGE_REQUEST_ID } = useParams();
  const { items, isTableLoading, dateFilter } = useSelector(
    (state: RootState) => state.changeRequestActivityLog
  );
  const { data, isLoading, isFetching } = useGetChangeRequestReportQuery({
    dateFilter: {
      startDate: dateFilter?.start_date || "",
      endDate: dateFilter?.end_date || "",
    },
    changeRequestId: CHANGE_REQUEST_ID,
  });
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      let modifiedData = data?.data?.map((reportData: any) => {
        return {
          ...reportData,
          isActive: true,
        };
      });
      dispatch(setItems(modifiedData || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);
  const columns: columnTypes[] = [
    {
      field: "userName",
      headerName: "Username",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[120px]",
      renderCell: (row: any) => <span>{row?.userName}</span>,
    },
    {
      field: "totalDuration",
      headerName: "Duration",
      flex: "flex-[1_1_0%]",
      extraClasses: " min-w-[120px]",
      renderCell: (row: any) => (
        <span>{getTimeInHours(row?.totalDuration || 0)}</span>
      ),
    },
  ];

  return (
    <>
      {isLoading || isFetching ? (
        <div className="absolute w-[80%] h-[80%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      ) : (
        <ChangeRequestLog
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
          }}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
};

export default ChangeRequestLogWrapper;
