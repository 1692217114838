import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useAddWorkspaceMutation } from "src/services/RequirementGatheringService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import WorkspaceForm from "../Layouts/WorkspaceForm";

type Props = {
  onClose: () => void;
};

export type WorkspaceFormValues = {
  moduleName: string;
  clientId: any | null;
};

const AddWorkspaceFormWrapper = ({ onClose }: Props) => {
  const [addWorkspace] = useAddWorkspaceMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  // Form Initial Values
  const initialValues: WorkspaceFormValues = {
    moduleName: "",
    clientId: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    moduleName: string().required("Please enter workspace name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: WorkspaceFormValues,
    { setSubmitting, resetForm }: FormikHelpers<WorkspaceFormValues>
  ) => {
    const formattedValues = {
      workspaceName: values?.moduleName || "",
      clientId: values?.clientId._id || "",
    };
    addWorkspace(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <WorkspaceForm
            formType="ADD"
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddWorkspaceFormWrapper;
