import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import AddExpensesWrapper from "../Add/AddGoalWrapper";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const GoalListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  // const [isOpenMeetingFilterDialog, setIsOpenMeetingFilterDialog] = useState<boolean>(false);
  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex justify-between">
            <ATMPageHeader
              pageTitle="Goals"
              searchValue={searchValue}
              onSearchChange={setSearchValue}
              hideAddButton={
                !isAuthorized(UserModuleNameTypes.ACTION_GOAL_ADD)
              }
              buttonProps={{
                btnName: "Add New",
                onClick: ()=>{ setIsOpenAddDialog(true)},
              }}
            />
        </div>
        {/* Table */}
        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-col overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "h-[50px]"}
              isLoading={isTableLoading}
              disableRowClick={
                !isAuthorized(UserModuleNameTypes.ACTION_GOAL_VIEW)
              }
              onRowClick={(row) => navigate(`${row?._id}`)}
            />
          </div>

          <div>
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      </div>
      {isOpenAddDialog ? (
        <AddExpensesWrapper onClose={() => setIsOpenAddDialog(false)} />
      ) : null}
    </>
  );
};

export default GoalListing;
