import apiSlice from "./ApiSlice";
import { ChangeRequestFormValues } from "src/models/ChangeRequest.model";

export const changeRequestApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get All
    getAllChangeRequest: builder.query({
      providesTags: ["change-request"],
      query: () => {
        return {
          url: `change-request`,
          method: "GET",
        };
      },
    }),

    // Get By Id
    getChangeRequestById: builder.query({
      providesTags: ["change-request"],
      query: (id: any) => {
        return {
          url: `change-request/${id}`,
          method: "GET",
        };
      },
    }),

    // Get All With Pagination
    getChangeRequest: builder.query({
      providesTags: ["change-request"],
      query: (body: PaginationPayloadType) => {
        return {
          url: `change-request`,
          method: "POST",
          body,
        };
      },
    }),

    // Get All With Pagination
    getAllChangeRequestLogs: builder.query({
      providesTags: ["change-request", "ticket"],
      query: (body: PaginationPayloadType) => {
        return {
          url: `change-request-log`,
          method: "POST",
          body,
        };
      },
    }),

    // localhost:3009/v1/change-request-log/
    // ADD Change Request
    addChangeRequest: builder.mutation({
      invalidatesTags: ["change-request"],
      query: (body: ChangeRequestFormValues) => {
        return {
          url: "change-request/add",
          method: "POST",
          body,
        };
      },
    }),

    // EDIT Change Request
    editChangeRequest: builder.mutation({
      invalidatesTags: ["change-request"],
      query: ({ id, body }: { id: string; body: ChangeRequestFormValues }) => {
        return {
          url: `change-request/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Delet Change Request
    deleteChangeRequest: builder.mutation({
      invalidatesTags: ["change-request"],
      query: (id: string) => {
        return {
          url: `change-request/${id}`,
          method: "DELETE",
        };
      },
    }),

    // EDIT Change Request
    raiseChangeRequestETAApproval: builder.mutation({
      invalidatesTags: ["change-request"],
      query: (id) => {
        return {
          url: `change-request/raise-eta-approval/${id}`,
          method: "PUT",
        };
      },
    }),

    // Update estimation delivery date after raised change request
    updateEstimationDeliveryDate: builder.mutation({
      invalidatesTags: ["change-request"],
      query: ({ id, body }) => {
        return {
          url: `change-request/est-time/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Get By Id
    getChangeRequestEstimatedTimeById: builder.query({
      providesTags: ["change-request"],
      query: (id: string) => {
        return {
          url: `change-request/estimated-time/${id}`,
          method: "GET",
        };
      },
    }),

    // Get EST Time
    getEstTimeForPrepareMeeting: builder.query({
      providesTags: ["change-request"],
      query: (id: string) => {
        return {
          url: `change-request/ticket-est-time/${id}`,
          method: "GET",
        };
      },
    }),

    // Update Prepare Change Request
    addPrepareChangeRequest: builder.mutation({
      invalidatesTags: ["change-request"],
      query: ({ id, body }) => {
        return {
          url: `change-request/prepare/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // Get Change Request Report
    getChangeRequestReport: builder.query({
      query: (body) => {
        return {
          url: `analytics/change-request-report`,
          method: "POST",
          body,
        };
      },
    }),

    // Get All Ticket with closed status
    getAllTicketWithClosedStatus: builder.query({
      providesTags: ["tickets"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/change-request/tickets ",
          method: "POST",
          body,
        };
      },
    }),
    // Get All Ticket with and without ETA
    getAllTicketWithETAStatus: builder.query({
      query: (changeRequestId) => {
        return {
          url: `/change-request/tickets-esttime/${changeRequestId}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllChangeRequestQuery,
  useGetChangeRequestByIdQuery,
  useGetChangeRequestQuery,
  useAddChangeRequestMutation,
  useRaiseChangeRequestETAApprovalMutation,
  useUpdateEstimationDeliveryDateMutation,
  useEditChangeRequestMutation,
  useGetAllChangeRequestLogsQuery,
  useDeleteChangeRequestMutation,
  useGetChangeRequestEstimatedTimeByIdQuery,
  useGetEstTimeForPrepareMeetingQuery,
  useAddPrepareChangeRequestMutation,
  useGetChangeRequestReportQuery,
  useGetAllTicketWithClosedStatusQuery,
  useGetAllTicketWithETAStatusQuery
} = changeRequestApi;
