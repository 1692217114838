import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import ContactForm from '../Layouts/ContactForm';
import { ContactFormValues } from 'src/models/Contact.model';
import { useContactUpdateMutation, useGetContactByIdQuery } from 'src/services/ContactService';
import { showToast } from 'src/utils/showToaster';

type Props = {
  onClose: () => void;
  selectedId:any
};

const EditContactFormWrapper = ({ onClose ,selectedId }: Props) => { 
    const [contactData, setContactData] = useState<any>();

    const { data, isFetching, isLoading } =
    useGetContactByIdQuery(selectedId);
    const [updateContact] = useContactUpdateMutation();
  
    useEffect(() => {
      if (!isFetching || !isLoading) {
        setContactData(data?.data);
      }
    }, [data, isFetching, isLoading]);
  // Form Initial Values 
  const initialValues: ContactFormValues = {
    name:contactData?.name || '',
    email:contactData?.email ||'' ,
    mobile :contactData?.mobile ||''
  };

  // Validation Schema
  const validationSchema = object().shape({
    name: string().required('Please enter name'),
    email:string().email()
  });

  // Handle Submit
  const handleSubmit = (
    values: ContactFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ContactFormValues>
  ) => {
    updateContact({
        body: values,
        id: selectedId,
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            onClose()
            resetForm();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
  };

  return (
      <Formik
      enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <ContactForm formType='EDIT' formikProps={formikProps} onClose={onClose} />
          </Form>
        )}
      </Formik>
  );
};

export default EditContactFormWrapper;