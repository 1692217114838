import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { AllTaskFormValues } from "src/models/AllTask.model";
import AllTaskAddForm from "../Layouts/AllTaskAddForm";
import { useAddTaskMutation } from "src/services/TaskService";
import { showToast } from "src/utils/showToaster";
import { useNavigate } from "react-router-dom";
import { string, object } from "yup";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";

const RelatedToType = [
  {
    label: "Project",
    value: "PROJECT",
  },
  {
    label: "Ticket",
    value: "TICKET",
  },
];
const AddAllTaskWrapper = () => {
  const navigate = useNavigate();
  const [addTask] = useAddTaskMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  const initialValues: AllTaskFormValues = {
    title: "",
    layer: [],
    label: [],
    estimation: "",
    featureId: "",
    projectId: "",
    moduleId: "",
    description: "",
    ticketId: "",
    deploymentId: "",
    assignedTo: "",
    dueDate: "",
    relatedTo: "",
  };

  const handleSubmit = (
    values: AllTaskFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AllTaskFormValues>
  ) => {
    const {
      projectId,
      relatedTo,
      deploymentId,
      moduleId,
      ticketId,
      featureId,
      assignedTo,
      label,
      ...rest
    } = values;
    const formattedValues = {
      ...rest,
      deploymentId: deploymentId?.value,
      relatedTo: relatedTo?.value,
      featureId: featureId?.value,
      moduleId: moduleId?.value,
      projectId: projectId?._id,
      assignedTo: assignedTo?.value,
      ticketId: ticketId?.value,
      label: [{
        labelName: "OPEN",
        status: "OPEN"
      }],
    };
    addTask(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            navigate("/task");
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  const validationSchema = object().shape({
    title: string().required("Please enter title"),
    relatedTo: object().required("Related To is required"),
    projectId: object().test(
      "projectId",
      "project is required",
      (value, context) => {
        const { relatedTo } = context.parent;
        if (relatedTo?.value === "PROJECT" && !value) {
          return false;
        } else {
          return true;
        }
      }
    ),
    // moduleId: object().test(
    //   "moduleId",
    //   "module is required",
    //   (value, context) => {
    //     const { relatedTo } = context.parent;
    //     if (relatedTo?.value === "PROJECT" && !value) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    // featureId: object().test(
    //   "featureId",
    //   "feature is required",
    //   (value, context) => {
    //     const { relatedTo } = context.parent;
    //     if (relatedTo?.value === "PROJECT" && !value) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    // assignedTo: object().test(
    //   "assignedTo",
    //   "assignee is required",
    //   (value, context) => {
    //     const { relatedTo } = context.parent;
    //     if (relatedTo?.value === "PROJECT" && !value) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    ticketId: object().test(
      "ticketId",
      "Ticket is required",
      (value, context) => {
        const { relatedTo } = context.parent;
        if (relatedTo?.value === "TICKET" && !value) {
          return false;
        } else {
          return true;
        }
      }
    ),
  });

  return (
    <>
      <SideNavLayout>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              <AllTaskAddForm
                formikProps={formikProps}
                setSaveNextChecked={setSaveNextChecked}
                saveNextChecked={saveNextChecked}
                RelatedToType={RelatedToType}
              />
            </Form>
          )}
        </Formik>
      </SideNavLayout>

    </>
  );
};

export default AddAllTaskWrapper;
