import React, { useEffect, useState } from "react";
import CallActivitiesTab from "./CallActivitiesTab";
import { useParams } from "react-router-dom";
import { useGetContactLeadAllLogsQuery } from "src/services/ContactService";

const CallActivitiesTabWrapper = () => {
  const { leadId } = useParams();
  const [callsData, setCallsData] = useState<any>();
  const { data, isLoading, isFetching } = useGetContactLeadAllLogsQuery({
    limit: 10,
    searchValue: "",
    params: ["description"],
    page: 0,
    filterBy: [
      {
        fieldName: "leadId",
        value: leadId,
      },
      {
        fieldName: "logType",
        value: "CALL",
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCallsData(data?.data);
    }
  }, [data, isLoading, isFetching]);

  return (
    <CallActivitiesTab
      isLoading={isLoading || isFetching}
      callsData={callsData}
    />
  );
};

export default CallActivitiesTabWrapper;
