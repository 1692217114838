// |-- Built-in Dependencies --|
import React from "react";

// |-- External Dependencies --|
import { useDispatch, useSelector } from "react-redux";

// |-- Internal Dependencies --|
import LocationListView from "../../Components/LocationListView";

// |-- Redux --|
import { setSelectedLocationCountry } from "src/redux/slices/CountrySlice";
import { RootState, AppDispatch } from "src/redux/store";
import {
  setFilterValue,
  setSelctedLocationState,
} from "src/redux/slices/StatesSlice";
import { setSelectedLocationCity } from "src/redux/slices/CitySlice";
import { setFilterValue as setDistrictFilterValue } from "src/redux/slices/CitySlice";
import { setSearchValue } from "src/redux/slices/CountrySlice";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

// |-- Types --|
type Props = {
  contries: any[];
  items: any;
  onAddNew: () => void;
};

const CountryListing = ({ contries, items, onAddNew }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedLocationCountries, searchValue }: any = useSelector(
    (state: RootState) => state.country
  );

  function handleCountryClick(newValue: any) {
    if (selectedLocationCountries?.value === newValue.value) {
      dispatch(setSelectedLocationCountry(null));
      dispatch(setSelctedLocationState(null));
      dispatch(setSelectedLocationCity(null));
      dispatch(setDistrictFilterValue(""));
    } else {
      dispatch(setSelectedLocationCountry(newValue));
      dispatch(setFilterValue(newValue.value));
    }
  }

  return (
    <>
      <LocationListView
         hideAddButton={
          !isAuthorized(UserModuleNameTypes.ACTION_COUNTRY_ADD)
        }
        listHeading="Country"
        listData={contries}
        searchValue={searchValue}
        OnSearchChange={(newValue) => dispatch(setSearchValue(newValue))}
        onAddClick={() => {
          onAddNew();
        }}
        onListItemClick={(newValue) => {
          handleCountryClick(newValue);
        }}
        disabled={false}
      />
    </>
  );
};

export default CountryListing;
