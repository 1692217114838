import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetTimeLogsQuery } from "src/services/TicketGroupServices";
import TicketGroupTimeLogView from "./TicketGroupTimeLogView";
import { CircularProgress } from "@mui/material";

const TicketGroupTimeLogViewWrapper = () => {
  const { id } = useParams()
  const [timeLogsData, setTimeLogsData] = useState<any>()

  const { data, isLoading, isFetching } = useGetTimeLogsQuery(id);

  // Setting Project Data
  useEffect(() => {
    if (!isLoading || !isFetching) {
      setTimeLogsData(data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);
  return (
    <>
      {(isLoading || isFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <TicketGroupTimeLogView
        items={timeLogsData}
      />
    </>
  );
};

export default TicketGroupTimeLogViewWrapper;
