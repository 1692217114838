import { Formik, Form, FormikHelpers } from "formik";
import React from "react";
import OnBoardStageForm from "../Layout/OnBoardStageForm";
import { object, string } from "yup";
import { OnBoardStageFormValues } from "src/models/OnBoardStage.model";
import { useAddOnBoardStageMutation } from "src/services/OnBoardStageService";
import { showToast } from "src/utils/showToaster";
import { useNavigate } from "react-router-dom";
import { transformObject } from "../Edit/EditOnBoardStageFormWrapper";

type Props = {};

const AddOnBoardStageFormWrapper = (props: Props) => {
  const navigate = useNavigate();
  const [addOnBoardStage] = useAddOnBoardStageMutation();
  const initialValues: OnBoardStageFormValues = {
    stageName: "",
    stageOrder: "",
    shareToCandidate: [],
    collectFromCandidate: [],
    forOfficial: [],
    credentialsShare: [],
    otherRequirements: [],
  };

  const validationSchema = object().shape({
    stageName: string().required("Please enter stage name"),
    stageOrder: string().required("Please enter order"),
  });

  const handleSubmit = (
    values: OnBoardStageFormValues,
    { setSubmitting, resetForm }: FormikHelpers<OnBoardStageFormValues>
  ) => {
    const formattedValues = {
      stageName: values?.stageName || "",
      stageOrder: values?.stageOrder || "",
      shareToCandidate: transformObject(values?.shareToCandidate) || [],
      collectFromCandidate: transformObject(values?.collectFromCandidate) || [],
      forOfficial: transformObject(values?.forOfficial) || [],
      credentialsShare: transformObject(values?.credentialsShare) || [],
      otherRequirements: transformObject(values?.otherRequirements) || [],
    };
    addOnBoardStage(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate("/job-onboard/on-board-stage");
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <OnBoardStageForm
            formikProps={formikProps}
            formtype="ADD"
            onclose={() => navigate("/job-onboard/on-board-stage")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddOnBoardStageFormWrapper;
