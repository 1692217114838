import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetContactByIdQuery,
  useGetLeadByIdQuery,
} from "src/services/ContactService";
import ViewContactLead from "./ViewContactLead";

const ViewContactLeadWrapper = () => {
  const { leadId, contactId } = useParams();
  const [leadDetails, setLeadDetails] = useState<any>();

  const [contactDetails, setContactDetails] = useState<any>();
  const {
    data: leadsData,
    isLoading: leadsDataIsLoading,
    isFetching: leadsDataIsFetching,
  } = useGetLeadByIdQuery(leadId);
  const {
    data: contactData,
    isFetching: contactDataIsFetching,
    isLoading: contactDataIsLoading,
  } = useGetContactByIdQuery(contactId);

 

  useEffect(() => {
    if (!leadsDataIsFetching && !leadsDataIsLoading) {
      setLeadDetails(leadsData?.data);
    }
  }, [leadsData, leadsDataIsLoading, leadsDataIsFetching]);

  useEffect(() => {
    if (!contactDataIsFetching && !contactDataIsLoading) {
      setContactDetails(contactData?.data);
    }
  }, [contactData, contactDataIsLoading, contactDataIsFetching]);

  return (
    <ViewContactLead contactDetails={contactDetails}   leadDetails={leadDetails} contactId={contactId} />
  )
};

export default ViewContactLeadWrapper;
