import apiSlice from "./ApiSlice";

export const positionApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Get
        getAllPositionWithPagination: builder.query({
            providesTags: ["position"],
            query: (body: PaginationPayloadType) => {
                return {
                    url: "/position",
                    method: "POST",
                    body,
                };
            },
        }),

        // Single Product Delete 
        deletePosition: builder.mutation({
            invalidatesTags: ["position"],
            query: (id: any) => ({
                url: `/position/${id}`,
                method: "DELETE",
            }),
        }),
        // Get
        getPosition: builder.query({
            providesTags: ["position"],
            query: () => {
                return {
                    url: "/position/",
                    method: "GET",
                };
            },
        }),

        // Add
        addPosition: builder.mutation({
            invalidatesTags: ["position"],
            query: (body) => {
                return {
                    url: "/position/add",
                    method: "POST",
                    body,
                };
            },
        }),


        // Get the Git Repo  by id
        getPositionById: builder.query({
            providesTags: ["position"],
            query: (id: string) => {
                return {
                    url: `/position/${id}`,
                    method: "GET",
                };
            },
        }),

        // Update Git Repo
        updatePositionById: builder.mutation({
            invalidatesTags: ["position"],
            query: ({ id, body }) => {
                return {
                    url: `/position/${id}`,
                    method: "PUT",
                    body,
                };
            },
        }),
        //
        // Get the position under department
        getPositionDepartmentById: builder.query({
            providesTags: ["position"],
            query: (id: string) => {
                return {
                    url: `/department/${id}/positions`,
                    method: "GET",
                };
            },
        }),
    }),
});

export const {
    useGetAllPositionWithPaginationQuery,
    useAddPositionMutation,
    useGetPositionQuery,
    useGetPositionByIdQuery,
    useUpdatePositionByIdMutation,
    useDeletePositionMutation,
    useGetPositionDepartmentByIdQuery
} = positionApi;
