import { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import CaseFlowListFilterFormDialogWrapper from "./CaseFlowFilterFormCard/CaseFlowListFilterFormDialogWrapper";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const CashFlowListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenTicketFilterDialog, setIsOpenTicketFilterDialog] =
    useState<boolean>(false);
  // const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex justify-between">
        <ATMPageHeader
            pageTitle="Cash Flow"
            searchValue={searchValue}
            isFilter
            onSearchChange={setSearchValue}
            onFilterClick={()=>{ setIsOpenTicketFilterDialog(true)}}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_TIKCETS_ADD)
            }
            buttonProps={{
              btnName: "Add New",
              onClick: ()=>{console.log('Hello')},
            }}
          />
        </div>
        {/* Table */}
        <div className="flex flex-col flex-1 px-2 overflow-auto md:px-4">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "min-h-[50px]"}
            isLoading={isTableLoading}
            disableRowClick={
              !isAuthorized(UserModuleNameTypes.ACTION_GOAL_VIEW)
            }
            // onRowClick={(row) => navigate(`/finance/incomeform/${row?._id}`)}
          />
        </div>

        <div className="p-2 py-2 md:p-4">
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
      <CaseFlowListFilterFormDialogWrapper
              open={isOpenTicketFilterDialog}
              onClose={() => setIsOpenTicketFilterDialog(false)}
            />
    </>
  );
};

export default CashFlowListing;
