import React, { useEffect, useState } from "react";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/UnhandledIncidentSlice";
import { useUnhandledListingQuery } from "src/services/IncidentService";
import moment from "moment";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import UnhandledIncidentListing from "./UnhandledIncidentListing";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { RiShareForwardLine } from "react-icons/ri";
import ForwardIncidentWrapper from "../../DesputedIncident/ForwardIncident/ForwardIncidentWrapper";

type Props = {};

const UnhandledIncidentListingWrapper = (props: Props) => {
  const dispatch = useDispatch();

  const [pointerId, setPointerId] = useState<any>("");
  const [teamMemberId, setTeamMemberId] = useState<any>();

  const [isOpenForwardDialog, setIsOpenForwardDialog] = useState(false);
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    dateFilter,
  } = useSelector((state: RootState) => state.unhandledIncident);

  const { data, isLoading, isFetching } = useUnhandledListingQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["typeName", "categoryName"],
    page: page,
    filterBy: [],
    dateFilter: {
      startDate: dateFilter?.startDate ? dateFilter?.startDate : "",
      endDate: dateFilter?.endDate ? dateFilter?.endDate : "",
    },
    orderBy: "addedOnDate",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "moveToUnhandledOnDate",
      headerName: "MOVE TO UNHANDLED",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize max-w-[150px]",
      name: UserModuleNameTypes.UNHANDLED_INCIDENT_LIST_MOVE_ON_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {row.moveToUnhandledOnDate
              ? formatedDateTimeIntoIst(
                  row.moveToUnhandledOnDate,
                  "DD MMM yyyy"
                )
              : "-"}
          </div>
          <div className="text-[13px] font-medium text-slate-400">
            {formatedDateTimeIntoIst(row.createdAt, "hh:mm A")}
          </div>
        </div>
      ),
    },
    {
      field: "addedOnDate",
      headerName: "Date",
      name: UserModuleNameTypes.UNHANDLED_INCIDENT_LIST_ADDED_ON_DATE,
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[150px]",
      renderCell: (row: any) => (
        <div className="text-[13px] text-slate-700 font-medium">
          {row?.addedOnDate
            ? moment(row?.addedOnDate).format("DD MMM YYYY")
            : ""}
        </div>
      ),
    },
    {
      field: "categoryName",
      headerName: "category Name",
      name: UserModuleNameTypes.UNHANDLED_INCIDENT_LIST_CATEGORY_NAME,
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "typeName",
      headerName: "type Name",
      name: UserModuleNameTypes.UNHANDLED_INCIDENT_LIST_TYPE_NAME,
      flex: "flex-[1_1_0%]",
      extraClasses: "max-w-[250px]",
    },
    {
      field: "unhandledReason",
      name: UserModuleNameTypes.UNHANDLED_INCIDENT_LIST_REMARK,
      headerName: "remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "max-h-[100px] overflow-scroll",
      renderCell: (row: any) => (
        <div>{row?.unhandledReason ? row?.unhandledReason : row?.remark} </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center font-medium text-teal-600 gap-x-2">
                    <RiShareForwardLine size="1.3em" color="#12968A" /> Forward
                  </div>
                ),
                onClick: () => {
                  setPointerId(row?._id);
                  setIsOpenForwardDialog(true);
                  setTeamMemberId(row?.memberId);
                },
                acitonName: UserModuleNameTypes.ACTION_INCIDENT_FORWARD,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <UnhandledIncidentListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setRowsPerPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenForwardDialog && (
        <ForwardIncidentWrapper
          teamMemberId={teamMemberId}
          onClose={() => setIsOpenForwardDialog(false)}
          pointerId={pointerId}
        />
      )}
    </div>
  );
};

export default UnhandledIncidentListingWrapper;
