import { Form, Formik, FormikHelpers } from "formik";
import { ResourceRequestFormValues } from "src/models/ResourceRequest.model";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import { useEffect, useState } from "react";
import ResourceRequestForm from "../Layout/ResourceRequestForm";
import {
  useEditResourceByIdMutation,
  useGetResourceByIdQuery,
} from "src/services/ResourceRequestService";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  resourceId: string;
};

const EditResourceRequestFormWrapper = ({ onClose, resourceId }: Props) => {
  const [editResourceRequest] = useEditResourceByIdMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);
  const [resourceData, setResourceData] = useState<any>();

  const { isLoading, isFetching, data } = useGetResourceByIdQuery(resourceId);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setResourceData(data?.data);
    }
  }, [isLoading, isFetching, data]);
  // Form Initial Values
  const initialValues: ResourceRequestFormValues = {
    projectId: {
      label: resourceData?.projectName,
      value: resourceData?.projectId,
    },
    resourceName: resourceData?.resourceName,
    description: resourceData?.description,
    requestedFromId: {
      label: resourceData?.requestedFromName,
      value: resourceData?.requestedFromId,
    },
    requestedFromType: {
      label: resourceData?.requestedFromType,
      value: resourceData?.requestedFromType,
    },
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select project"),
    resourceName: string().required("Please enter resource"),
    requestedFromType: object().required("Please select project"),
    requestedFromId: object().test(
      "requestedFromId",
      "Request from is required",
      (value, context) => {
        const { requestedFromType } = context.parent;
        if (requestedFromType?.value === "CODIOTIC_USER" && !value) {
          return false;
        } else {
          return true;
        }
      }
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: ResourceRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ResourceRequestFormValues>
  ) => {
    const formattedValues = {
      projectId: values?.projectId?.value,
      resourceName: values?.resourceName,
      description: values?.description,
      requestedFromId: values?.requestedFromId?.value,
      requestedFromType: values?.requestedFromType.value,
    };
    editResourceRequest({ body: formattedValues, id: resourceId }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <ResourceRequestForm
            formType="UPDATE"
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditResourceRequestFormWrapper;
