import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetAllTicketQuery } from "src/services/TicketServices";
import QuotationView from "./QuotationView";
import { CircularProgress } from "@mui/material";

const QuotationViewWrapper = () => {
  const { id: CHANGE_REQUEST_ID } = useParams();
  const [changeRequestTickets, setChangeRequestTickets] = useState([]);
  const { isLoading, isFetching, data } = useGetAllTicketQuery({
    limit: 20,
    searchValue: "",
    params: ["title", "ticketNumber", "createdBy"],
    page: 1,
    filterBy: [
      {
        fieldName: "changeRequestId",
        value: CHANGE_REQUEST_ID,
      },
    ],
    dateFilter: {
      startDate: "",
      endDate: "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setChangeRequestTickets(data?.data || []);
    }
  }, [isLoading, isFetching, data]);
  if(isLoading || isFetching){
    return <div className="flex justify-center items-center h-full">
        <CircularProgress/>
    </div>
  }
  return <QuotationView ticketList={changeRequestTickets}/>
};

export default QuotationViewWrapper;
