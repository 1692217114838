import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsOpenEditFormDialog,
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/MeetingTypeSlice";
import { AppDispatch, RootState } from "src/redux/store";
import EditMeetingTypeWrapper from "../Edit/EditMeetingTypeWrapper";
import MeetingTypeListing from "./MeetingTypeListing";
import { MdBlock } from "react-icons/md";
import { MdEdit, MdDelete } from "react-icons/md";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { showToast } from "src/utils/showToaster";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { MeetingTypeListResponse } from "src/models/MeetingType.model";
import {
  useChangeStatusMeetingTypeMutation,
  useDeleteMeetingTypeMutation,
  useGetAllMeetingTypePaginationDataQuery,
} from "src/services/MeetingTypeService";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const MeetingTypeListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedMeetingTypeId, setselectedMeetingTypeId] =
    useState<string>("");
  const [activeUpdateMeetingType] = useChangeStatusMeetingTypeMutation();
  const [deleteMeetingType] = useDeleteMeetingTypeMutation();

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    isOpenEditFormDialog,
    // selectedClient,
  } = useSelector((state: RootState) => state.meetingType);

  const { data, isLoading, isFetching } =
    useGetAllMeetingTypePaginationDataQuery({
      limit: rowsPerPage,
      searchValue: searchValue,
      params: ["meetingType"],
      page: page,
      filterBy: [],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleStatusChangeMeetingType = (id: string) => {
    activeUpdateMeetingType(id)
      .then((res: any) => {
        showToast("success", "status change successfully");
      })
      .catch((err: any) => {
        console.error("error", err);
      });
  };

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Meeting Type?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteMeetingType(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "meetingType",
      headerName: "Meeting Type",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      name: UserModuleNameTypes.MEETING_TYPE_LIST_MEETING_TYPE,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: MeetingTypeListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  dispatch(setIsOpenEditFormDialog(true));
                  setselectedMeetingTypeId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_MEETING_TYPE_EDIT,
              },
              {
                label: row.isActive ? (
                  <div className="flex items-center gap-x-2">
                    <MdBlock color="red" /> Deactivate
                  </div>
                ) : (
                  <div className="flex items-center gap-x-2">
                    <VscActivateBreakpoints color="#00a67e" /> Activate
                  </div>
                ),
                onClick: () => {
                  handleStatusChangeMeetingType(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_MEETING_TYPE_DEACTIVE,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_MEETING_TYPE_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <MeetingTypeListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      {isOpenEditFormDialog ? (
        <EditMeetingTypeWrapper
          onClose={() => dispatch(setIsOpenEditFormDialog(false))}
          selectedMeetingTypeId={selectedMeetingTypeId}
        />
      ) : null}
    </div>
  );
};

export default MeetingTypeListingWrapper;
