import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import ProfileDetailsForm from "./ProfileDetailsForm";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import moment from "moment";
import {
  useGetProfileDataQuery,
  useUpdateOurProfileMutation,
} from "src/services/TeamServices";
import { showToast } from "src/utils/showToaster";
import { CircularProgress } from "@mui/material";

export type ProfileDetailsFormValues = {
  name: string;
  email?: string;
  fatherName: string;
  personalEmail: string;
  mobile: string;
  whatsAppMobile: string;
  emergencyContactNo: string;
  dobAsPerDocument: string;
  dobActually: string;
  joiningDate: string;
  bloodGroup: string;
  maritalStatus: any;
  gender: any;
  localAddress: string;
  permanentAddress: string;
  skills: any;
  medicalHistory: string;
  allergic: string;

  // Bank Details
  bankName: string;
  accountNumber: string;
  bankIfscCode: string;
  bankBranchName: string;
  cancelCheque: any;

  // Documents
  identityProof: any;
  localAddressProof: any;
  PermanentAddressProof: any;
  passportSizePhoto: any;

  academicHistory: [
    {
      collgeSchoolName: string;
      courseName: string;
      universityBoard: string;
      courseType: string;
      collgeSchoolTier: string;
      EducationCertificate: any;
      starYear: string;
      endYear: string;
      currentlyPursuing: boolean;
    },
  ];
  workHistory: [
    {
      companyName: string;
      jobTitle: string;
      jobDescription: string;
      starYear: string;
      endYear: string;
      workExperienceCertificate : [
        {
          url:any
        }
      ] ;
    },
  ];
};

const ProfileDetailsFormWrapper = () => {
  const [updateProfile] = useUpdateOurProfileMutation();

  const [userDetails, setUserDetails] = useState<any>();

  const { data, isLoading, isFetching } = useGetProfileDataQuery("");

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setUserDetails(data?.data);
    }
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: ProfileDetailsFormValues = {
    name: userDetails?.name || "",
    fatherName: userDetails?.fatherName || "",
    personalEmail: userDetails?.personalEmail || "",
    mobile: userDetails?.mobile || "",
    whatsAppMobile: userDetails?.whatsAppMobile || "",
    emergencyContactNo: userDetails?.emergencyContactNo || "",
    dobAsPerDocument: userDetails?.dobAsPerDocument || "",
    dobActually: userDetails?.dobActually || "",
    joiningDate: userDetails?.joiningDate || "",
    bloodGroup: userDetails?.bloodGroup || "",
    maritalStatus: {
      label: userDetails?.maritalStatus,
      value: userDetails?.maritalStatus,
    },
    gender: {
      label: userDetails?.gender,
      value: userDetails?.gender,
    },
    localAddress: userDetails?.localAddress || "",
    permanentAddress: userDetails?.permanentAddress || "",
    skills:
      userDetails?.skills?.map((el: any) => {
        return {
          label: el?.skillName,
          value: el?.skillId,
        };
      }) || "",
    medicalHistory: userDetails?.medicalHistory || "",
    allergic: userDetails?.allergic || "",

    // Bank Details
    bankName: userDetails?.bankName || "",
    accountNumber: userDetails?.accountNumber || "",
    bankIfscCode: userDetails?.bankIfscCode || "",
    bankBranchName: userDetails?.bankBranchName || "",
    cancelCheque:userDetails?.cancelChequeUrl ?  {
      url: userDetails?.cancelChequeUrl ,
    }  :  "",

    // Documents
    identityProof: userDetails?.identityProofUrl
      ? {
          url: userDetails?.identityProofUrl,
        }
      : "",
    localAddressProof: userDetails?.localAddressProofUrl
      ? { url: userDetails?.localAddressProofUrl }
      : "",
    PermanentAddressProof: userDetails?.PermanentAddressProofUrl
      ? { url: userDetails?.PermanentAddressProofUrl }
      : "",
    passportSizePhoto: userDetails?.passportSizePhotoUrl
      ? { url: userDetails?.passportSizePhotoUrl }
      : "",

    academicHistory: userDetails?.academicHistory?.length
      ? userDetails?.academicHistory?.map((el: any) => {
          return {
            collgeSchoolName: el?.collgeSchoolName || "-",
            universityBoard: el?.universityBoard || "-",
            courseName: el?.courseName || "-",
            courseType:
              {
                label: el?.courseType,
                value: el?.courseType,
              } || "",
            collgeSchoolTier:
              {
                label: el?.collgeSchoolTier,
                value: el?.collgeSchoolTier,
              } || "-",
            EducationCertificate: el?.EducationCertificate ?  { url: el?.EducationCertificate }  : "",
            starYear: el?.starYear ? moment(el?.starYear).year() : "",
            endYear: el?.endYear ? moment(el?.endYear).year() : " ",
            currentlyPursuing: el?.currentlyPursuing,
          };
        })
      : [
          {
            collgeSchoolName: "",
            universityBoard: "",
            courseName: "",
            courseType: "",
            collgeSchoolTier: "",
            EducationCertificate: "",
            starYear: "",
            endYear: "",
            currentlyPursuing: false,
          },
        ],
    workHistory: userDetails?.workHistory?.length
      ? userDetails?.workHistory?.map((el: any) => {
          return {
            companyName: el?.companyName || " ",
            jobTitle: el?.jobTitle || " ",
            jobDescription: el?.jobDescription || " ",
            starYear: el?.starYear ? moment(el?.starYear).year() : "",
            endYear: el?.endYear ? moment(el?.endYear).year() : " ",
            workExperienceCertificate :el?.workExperienceCertificate?.map((exp:any)=>{
              return{
                url : {url:exp} ,
              }
            })
          };
        })
      : [
          {
            companyName: "",
            jobTitle: "",
            jobDescription: "",
            starYear: "",
            endYear: "",
            workExperienceCertificate : [
              {
                url:''
              }
            ] 
          },
        ],
  };

  // Validation Schema
  const validationSchema = object().shape({
    name: string().required("Please enter name"),
    personalEmail: string().email().required("Please enter personal email"),
    mobile:string().required("Please enter mobile"),
    fatherName:string().required("Please enter father Name"),
    whatsAppMobile:string().required("Please enter whatsApp Number"),
    emergencyContactNo :string().required("Please enter emergency contact number"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ProfileDetailsFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ProfileDetailsFormValues>
  ) => {
    const formattedValues = {
      name: values?.name,
      email: userDetails?.email,
      mobile: values?.mobile,
      personalEmail: values?.personalEmail,
      dobAsPerDocument: values?.dobAsPerDocument
        ? moment(values?.dobAsPerDocument).format("YYYY-MM-DD")
        : "",
      dobActually: values?.dobActually
        ? moment(values?.dobActually).format("YYYY-MM-DD")
        : "",
      joiningDate: values?.joiningDate
        ? moment(values?.joiningDate).format("YYYY-MM-DD")
        : "",
      fatherName: values?.fatherName,
      whatsAppMobile: values?.whatsAppMobile,
      emergencyContactNo: values?.emergencyContactNo,
      bloodGroup: values?.bloodGroup,
      maritalStatus: values?.maritalStatus?.value || "",
      gender: values?.gender?.value,
      localAddress: values?.localAddress,
      permanentAddress: values?.permanentAddress,
      medicalHistory: values?.medicalHistory,
      allergic: values?.allergic,
      skills:values?.skills ?  values?.skills?.map((el: any) => el?.value) : [],
      // Bank Details
      bankName: values?.bankName,
      accountNumber: values?.accountNumber,
      bankIfscCode: values?.bankIfscCode,
      bankBranchName: values?.bankBranchName,
      cancelChequeUrl: values?.cancelCheque?.url,
      //Documents
      identityProofUrl: values?.identityProof?.url,
      localAddressProofUrl: values?.localAddressProof?.url,
      PermanentAddressProofUrl: values?.PermanentAddressProof?.url,
      passportSizePhotoUrl: values?.passportSizePhoto?.url,
      // Academic History
      academicHistory:values?.academicHistory ? values?.academicHistory?.map((el: any) => {
        return {
          collgeSchoolName: el?.collgeSchoolName,
          universityBoard: el?.universityBoard,
          courseName: el?.courseName,
          courseType: el?.courseType?.value,
          collgeSchoolTier: el?.collgeSchoolTier?.value,
          EducationCertificate: el?.EducationCertificate?.url,
          starYear: el?.starYear
            ? moment(el?.starYear)
                .year()
                ?.toString()
            : "",
          endYear: el?.endYear
            ? moment(el?.endYear)
                .year()
                ?.toString()
            : "",
          currentlyPursuing: el?.currentlyPursuing,
        };
      }) : [],
      //Work History
      workHistory:values?.workHistory ? values?.workHistory?.map((el: any) => {
        return {
          companyName: el?.companyName,
          jobTitle: el?.jobTitle,
          jobDescription: el?.jobDescription,
          workExperienceCertificate :el?.workExperienceCertificate?.map((exp:any)=>exp?.url?.url),
          starYear: el?.starYear
            ? moment(el?.starYear)
                .year()
                ?.toString()
            : "",
          endYear: el?.endYear
            ? moment(el?.endYear)
                .year()
                ?.toString()
            : "",
        };
      }) :[],
    };
    updateProfile(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <SideNavLayout>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <ProfileDetailsForm formikProps={formikProps} />
          </Form>
        )}
      </Formik>
    </SideNavLayout>
  );
};

export default ProfileDetailsFormWrapper;
