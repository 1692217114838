import apiSlice from "./ApiSlice";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Login
    getAllUser: builder.query({
      providesTags: ["codiotic-user"],
      query: () => {
        return {
          url: "/codiotic-user",
          method: "GET",
        };
      },
    }),

    getAllTeamTaskWithPagination: builder.query({
      providesTags: ["codiotic-user"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/codiotic-user/team-view",
          method: "POST",
          body,
        };
      },
    }),

    //Get All Task In Progress
    getAllInProgressTask: builder.query({
      providesTags: ["codiotic-user, task"],
      query: (name: string) => {
        return {
          url: "/codiotic-user/in-progress-tasks",
          method: "GET",
          params: { name },
        };
      },
    }),
    //Get All Task In Progress

    getAllTodayTask: builder.query({
      providesTags: ["codiotic-user, task, "],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/logs/user-today-tasks-time-report",
          method: "POST",
          body,
        };
      },
    }),

    // Set User Active And Away
    updateUserStatus: builder.mutation({
      invalidatesTags: ["codiotic-user" ,"task"],
      query: (body:any) => {
        return {
          url: `/codiotic-user/add-break-bench`,
          method: "PUT",
          body
        };
      },
    }),

    //Get User In progress Task
    getUserInProgressTask: builder.query({
      providesTags: ["codiotic-user" ,"task"],
      query: () => {
        return {
          url: "/codiotic-user/task",
          method: "GET",
        };
      },
    }),

    //Get User Break
    getUserBreakStatus: builder.query({
      providesTags: ["codiotic-user" ,"task"],
      query: () => {
        return {
          url: "/codiotic-user/on-break-bench",
          method: "GET",
        };
      },
    }),

      // Get Pointer
      getPointerAssignUser: builder.query({
        providesTags: ["incident"],
        query: (id: string) => {
          return {
            url: `/codiotic-user/assigned-user/${id}`,
            method: "GET",
          };
        },
      }),
     

  }),
});

export const {
  useGetAllUserQuery,
  useGetAllTeamTaskWithPaginationQuery,
  useGetAllTodayTaskQuery,
  useGetAllInProgressTaskQuery,
  useUpdateUserStatusMutation ,
  useGetUserInProgressTaskQuery ,
  useGetUserBreakStatusQuery,
  useGetPointerAssignUserQuery
} = userApi;
