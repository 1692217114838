import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { DeliveryDateFormValues } from "src/models/DeliveryDate.model";
import {
  useGetDeliveryDateByIdQuery,
  useUpdateDeliveryDateMutation,
} from "src/services/DeliveryDateService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import DeliveryDateForm from "../Layouts/DeliveryDateForm";

type Props = {
  selectedDeliveryDate: string;
  onClose: () => void;
};

const EditDeliveryDateWrapper = ({ selectedDeliveryDate, onClose }: Props) => {
  const [deliveryDateInfo, setDeliveryDateInfo] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetDeliveryDateByIdQuery(selectedDeliveryDate);
  const [updateDeliveryDate] = useUpdateDeliveryDateMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setDeliveryDateInfo(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: DeliveryDateFormValues = {
    projectId: {
      label: deliveryDateInfo?.projectName,
      value: deliveryDateInfo?.projectId,
    },
    clientId: {
      organisationName: deliveryDateInfo?.clientOrganisationName,
      _id: deliveryDateInfo?.clientId,
    },
    remark: deliveryDateInfo?.remark || "",
    deliveryDate: deliveryDateInfo?.deliveryDate || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select project"),
    clientId: object().required("Please select client"),
    remark: string().required("Please enter remark"),
    deliveryDate: string().required("Please select delivery date"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DeliveryDateFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DeliveryDateFormValues>
  ) => {
    let formattedValues = {
      ...values,
      projectId: values?.projectId?.value || "",
      clientId: values?.clientId?._id || "",
    };

    updateDeliveryDate({
      id: selectedDeliveryDate,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <DeliveryDateForm
            formikProps={formikProps}
            formType="EDIT"
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditDeliveryDateWrapper;
