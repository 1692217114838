/* eslint-disable array-callback-return */
import { FormikProps } from "formik";
import moment from "moment";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { MeetingMarkAsDoneFormValues } from "./QuickMeetingMarkAsDoneFormWrapper";

// HOOKS
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { ProjectListResponse } from "src/models/Project.model";
type Props = {
  formikProps: FormikProps<MeetingMarkAsDoneFormValues>;
  onClose: () => void;
  meetingType?: any
};

const QuickMeetingMarkAsDoneForm = ({ formikProps, meetingType, onClose }: Props) => {

  const { values, setFieldValue, isSubmitting, handleBlur  } = formikProps;

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> {meetingType === "INTERNAL_TEAM" ? "End Quick Meeting" : "Mark as done"}</div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="grid md:grid-cols-1 xs:grid-cols-1 gap-x-4 md:gap-y-6 gap-y-6">
        {/* Title */}
        <div>
          <ATMTextField
            name="title"
            value={values.title}
            required
            label="Title"
            placeholder="Enter title"
            onBlur={handleBlur}
            onChange={(e) => setFieldValue("title", e.target.value)}
          />
        </div>

        {/* Project */}
        <div className="mt-1">
          <ATMSelect
            required
            name="projectId"
            label="Project"
            placeholder="Select Project"
            value={values.projectId}
            isLoading={isAllProjectsDataLoading}
            options={allProjects?.map((ele: ProjectListResponse) => {
              return {
                label: ele?.projectName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("projectId", newValue);
            }}
          />
        </div>
        {/* Actual End Date */}

        <div>
          <ATMDatePicker
            label="Start Date"
            name="startDate"
            format="DD/MM/YYYY"
            value={values.startDate}
            onChange={(newValue) => setFieldValue("startDate", newValue)}
          />
        </div>

        <div>
          <ATMDatePicker
            label="End Date"
            name="endDate"
            format="DD/MM/YYYY"
            value={values.endDate}
            onChange={(newValue) => setFieldValue("endDate", newValue)}
          />
        </div>
        {/* Actual Start Time */}

        <div className="mb-2">
          <ATMTextField
            type='time'
            name="actualStartTime"
            value={moment(values.actualStartTime).format('HH:mm')}
            required
            label="Actual Start Meeting Time"
            placeholder="Enter Actual Start Meeting Time"
            className="mt-0 rounded"
            onBlur={handleBlur}
            onChange={(e) => {
              let time = moment(e.target.value, 'HH:mm')
              setFieldValue('actualStartTime', time);
            }}
          />
        </div>

        {/* Actual End Time */}
        <div className="mb-2">
          <ATMTextField
            type='time'
            name="actualEndTime"
            value={moment(values.actualEndTime).format('HH:mm')}
            required
            label="Actual End Meeting Time"
            placeholder="Enter Actual End Meeting Time"
            className="mt-0 rounded"
            onBlur={handleBlur}
            onChange={(e) => {
              let time = moment(e.target.value, 'HH:mm A')
              setFieldValue('actualEndTime', time);
            }}
          />
        </div>
      </div>

      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default QuickMeetingMarkAsDoneForm;
