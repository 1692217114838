import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAddMilestoreMutation,
  useGetDefaultCurrencyQuery,
} from "src/services/ClientServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import AddPaymentForm from "./AddPaymentForm";
import moment from "moment";

type Props = {
  onClose: () => void;
};

export type PaymentFormValues = {
  amount: string;
  paymentDate: any;
  taxAmount: any;
  remark: string;
  tdsAmount: string;
  conversionLoss: string;
  mode: any;
  accountId: any;
  taxPercent: any;
  currencyId: any;
};

const AddPaymentWrapper = ({ onClose }: Props) => {
  const { clientId } = useParams();

  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addPayment] = useAddMilestoreMutation();

  const [currencyDefault, setCurrencyDefault] = useState<any>({});

  const { isLoading, isFetching, data } = useGetDefaultCurrencyQuery(clientId);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCurrencyDefault(data?.data || {});
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: PaymentFormValues = {
    amount: "",
    paymentDate: null,
    taxAmount: "",
    remark: "",
    tdsAmount: "",
    conversionLoss: "",
    mode: "",
    accountId: "",
    taxPercent: 18,
    currencyId: currencyDefault
      ? {
          label: currencyDefault.defaultCurrencyName,
          value: currencyDefault.currencyId,
        }
      : null,
  };

  // Validation Schema
  const validationSchema = object().shape({
    paymentDate: string().required("Please enter payment date"),
    amount: string().test("cost-test", "Please enter amount", (value) => {
      return Number(value || 0) > 0;
    }),
    accountId: object().required("Please select account"),
    mode: object().required("Please select mode"),
    currencyId: object().required("Please select currency"),
  });

  // Handle Submit
  const handleSubmit = (
    values: PaymentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<PaymentFormValues>
  ) => {
    const formattedValues = {
      type: "PAYMENT",
      clientId: clientId,
      amount: values?.amount,
      paymentDate: values?.paymentDate
        ? moment(values?.paymentDate).format("YYYY-MM-DD")
        : "",
      taxAmount: values?.taxAmount,
      remark: values?.remark,
      tdsAmount: values?.tdsAmount ? values?.tdsAmount : 0,
      conversionLoss: values?.conversionLoss ? values?.conversionLoss : 0,
      mode: values?.mode?.value,
      accountId: values?.accountId?.value,
      currencyId: values?.currencyId?.value,
    };

    addPayment(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AddPaymentForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddPaymentWrapper;
