import apiSlice from "../ApiSlice";

export const cashFlowApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // case flow data with pagination
    caseFlowList: builder.query({
      providesTags: ["cash-flow"],
      query: (body) => {
        return {
          url: "/cash-flow",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { useCaseFlowListQuery } = cashFlowApi;
