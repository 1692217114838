import React, { useEffect, useState } from 'react'
import ClientDetails from './ClientDetails'
import { useGetSingleClientQuery } from 'src/services/ClientServices';
import { useParams } from 'react-router-dom';
import { columnTypes } from 'src/components/UI/atoms/ATMTable/ATMTable';

const ClientDetailsWrapper = () => {

    const { clientId } = useParams()
    const [items, setItems] = useState<any>([])

    const { data, isLoading, isFetching } = useGetSingleClientQuery(clientId || "");

    useEffect(() => {
        if (!isLoading && !isFetching) {
            setItems(data?.data || []);
        }
    }, [isLoading, isFetching, data]);

    const columns: columnTypes[] = [
        {
            field: "name",
            headerName: "Name",
            flex: "flex-[1_1_0%]",
            extraClasses: "capitalize min-w-[150px]",
        },
        {
            field: "email",
            headerName: "email",
            flex: "flex-[1_1_0%]",
            extraClasses: "min-w-[150px]",
        },
        {
            field: "mobile",
            headerName: "mobile",
            flex: "flex-[1_1_0%]",
            extraClasses: "min-w-[250px]",
        },
    ]
    return (
        <>
            <ClientDetails isLoading={isLoading || isFetching} items={items} columns={columns} />
        </>
    )
}

export default ClientDetailsWrapper

