import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/RecurringPaymentSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { useGetRecurringPaymentsQuery } from "src/services/SubscriptionService";

import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import RecurringListing from "./RecurringListing";

type Props = {};

const RecurringListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [recurringPaymentData, setRecurringPaymentData] = useState({})
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading, recurranceType } =
    useSelector((state: RootState) => state.recurringPayment);

  const { data, isLoading, isFetching } = useGetRecurringPaymentsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["remark", "clientName"],
    page: page,
    filterBy: [{
      fieldName: "recurranceType",
      value: recurranceType,
    },],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      setRecurringPaymentData(data || {})
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);


  // Function to calculate the next date based on recurrence type
  const getNextDate = (currentDateStr: any, recurrenceType: any): any => {

    const currentDate = new Date(currentDateStr);
    switch (recurrenceType) {
      case "DAILY":
        return new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Add 1 day
      case "WEEKLY":
        return new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // Add 1 week
      case "MONTHLY":
        // Calculate next month's date
        const nextMonthDate = new Date(currentDate);
        nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);  // Calculate next month's date by adding 1 month to currentDate
        return nextMonthDate;
      case "QUARTERLY":
        // Calculate next quarter's date
        const nextQuarterDate = new Date(currentDate);
        nextQuarterDate.setMonth(nextQuarterDate.getMonth() + 3); // Calculate next quarter's date by adding 3 months to currentDate
        return nextQuarterDate;
      case "HALF YEARLY":
        // Calculate next half-year's date
        const nextHalfYearDate = new Date(currentDate);
        nextHalfYearDate.setMonth(nextHalfYearDate.getMonth() + 6);  // Calculate next half-year's date by adding 6 months to currentDate
        return nextHalfYearDate;
      case "YEARLY":
        // Calculate next year's date
        const nextYearDate = new Date(currentDate);
        nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);   // Calculate next year's date by adding 1 year to currentDate
        return nextYearDate;
      default:
        return currentDate; 
    }
  }


  const columns: columnTypes[] = [
    {
      field: "clientName",
      headerName: "Client Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[180px]",
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_CLIENT_NAME,
    },
    {
      field: "startDate",
      headerName: "Start date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_START_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="text-slate-700 font-medium">
            {row.startDate ? formatedDateTimeIntoIst(row.startDate, "DD MMM yyyy") : '-'}
          </div>
        </div>
      ),
    },
    {
      field: "expiryDate",
      headerName: "Expriy date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_EXPIRY_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="text-slate-700 font-medium">
            {row.expiryDate ? formatedDateTimeIntoIst(row.expiryDate, "DD MMM yyyy") : '-'}
          </div>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: "flex-[1_1_0%] ",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_AMOUNT,
    },
    {
      field: "taxAmount",
      headerName: "Tax Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          {row?.taxAmount ? (row?.taxAmount).toFixed(2) : '-'}
        </div>
      ),
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_TAX_AMOUNT,
    },

    {
      field: "tdsAmount",
      headerName: "TDS Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          {row?.tdsAmount ? (row?.tdsAmount).toFixed(2) : '-'}
        </div>
      ),
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_TDS_AMOUNT,
    },
    {
      field: "conversionLoss",
      headerName: "Conversion Loss",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          {row?.conversionLoss ? (row?.conversionLoss).toFixed(2) : '-'}
        </div>
      ),
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_CONVERSION_LOSS,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          {(row?.amount + row?.taxAmount + row?.tdsAmount + row?.conversionLoss).toFixed(2) || '-'}
        </div>
      ),
      // name: UserModuleNameTypes.EXPENSES_LIST_CATEGORY_NAME,
    },

    {
      field: "recurranceType",
      headerName: "Recurrance Type",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_RECURRANCE_TYPE,
      renderCell: (row: any) => (
        <div className="">
          {row.recurranceType ? row.recurranceType : '-'}
        </div>
      ),
    },
    {
      field: "recurranceDay",
      headerName: "Recurrance On Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_RECURRANCE_ON,
      renderCell: (row: any) => (
        <div className="">
          { row.recurranceDay || '-'}
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Next Payment Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_NEXT_PAYMENT_DATE,
      renderCell: (row: any) => {
        const nextDate = getNextDate(row?.startDate, row?.recurranceType);
        const formattedNextDate = formatedDateTimeIntoIst(nextDate, "DD MMM yyyy")
        return (
          <div>
            {row?.recurranceType ? formattedNextDate : '-'}
          </div>
        )
      }
    },
    {
      field: "remark",
      headerName: "Payment Remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[200px]",
      renderCell: (row: any) => (
        <div className="">
          {row.remark || '-'}
        </div>
      ),
      name: UserModuleNameTypes.RECURRING_PAYMENTS_LIST_REMARK,
    },
  ];

  return (
    <>
      <div>
        <RecurringListing
          columns={columns}
          rows={items}
          recurringPaymentData={recurringPaymentData}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
        />
      </div>
      {/* {isOpenEditDialog && <EditSubscriptionWrapper
        selectedSubscription={selectedtProductId}
        onClose={() => setIsOpenEditDialog(false)}
      />} */}

    </>
  );
};

export default RecurringListingWrapper;
