import React, { useEffect } from "react";
import ProjectFlowTab from "./ProjectFlowTab";
import {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setIsTableLoading,
  setTotalItems,
} from "src/redux/slices/ProjectFlowSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useGetAllProjectFlowQuery } from "src/services/ProjectServices";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdEdit } from "react-icons/md";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";

const ProjectFlowTabWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    sortValue,
    dateFilter,
  } = useSelector((state: RootState) => state.projectFlow);

  const { isLoading, isFetching, data } = useGetAllProjectFlowQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["flowName", "flow", "projectId"],
    page: page,
    filterBy: [
      {
        fieldName: "projectId",
        value: id,
      },
    ],
    dateFilter: {
      startDate: dateFilter?.start_date || "",
      endDate: dateFilter?.end_date || "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem));
    }
  }, [isLoading, isFetching, data, dispatch]);

  const columns: columnTypes[] = [
    {
      field: "flowName",
      headerName: "Flow Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row: any) => (
        <Tooltip title={row?.flowName}>
          <div className="w-full overflow-hidden font-medium truncate">
            {row?.flowName}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "flow",
      headerName: "Flow ",
      flex: "flex-[2_2_0%]",
      extraClasses: "min-w-[200px]",
      renderCell: (row: any) => (
        <Tooltip title={row?.flow}>
          <div className="line-clamp-3">
            <ATMMarkdownContainer markdown={row?.flow || "-"} />
          </div>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  navigate(`edit/${row?._id}`);
                },
              },
            ]}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <ProjectFlowTab
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          sortValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          setSortValue: (newValue) => dispatch(setSortValue(newValue)),
        }}
      />
    </>
  );
};

export default ProjectFlowTabWrapper;
