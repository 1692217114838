import React from "react";
import { useNavigate } from "react-router-dom";
import ATMTable from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {
  isLoading: any;
  columns: any;
  items: any;

  totalItems: number;
  page: number;
  rowsPerPage: number;
  setPage: (newPage: number) => void;
  setRowsPerPage: (newLimit: number) => void;
};

const TicketDetailsView = ({
  items,
  isLoading,
  columns,
  totalItems,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col flex-1 overflow-auto ">
      <ATMTable
        columns={columns}
        rows={items || []}
        rowExtraClasses={() => "h-[40px]"}
        isLoading={isLoading}
        disableRowClick={
          !isAuthorized(
            UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_SINGLE_VIEW
          )
        }
        onRowClick={(row) => navigate(`${row?._id}/details`)}
      />
      <div>
        <ATMPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          rowCount={totalItems}
          rows={items}
        />
      </div>
    </div>
  );
};

export default TicketDetailsView;
