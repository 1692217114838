import React from 'react';
import {  useNavigate } from 'react-router-dom';
import ATMPageHeader from 'src/components/UI/atoms/ATMPageHeader/ATMPageHeader';
import ATMPagination from 'src/components/UI/atoms/ATMPagination/ATMPagination';
import ATMTable, {
  columnTypes,
} from 'src/components/UI/atoms/ATMTable/ATMTable';
import SideNavLayout from 'src/components/layouts/SideNavLayout/SideNavLayout';

type Props = {
  columns: columnTypes[];
  rows: any[];
  onAddNew:()=>void ;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const ContactLeadListing = ({
  columns,
  rows,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate()
  return (
    <SideNavLayout>
      <div className='flex flex-col '>
        {/* Page Header */}
        <div className='p-4'>
          <ATMPageHeader
            pageTitle='Contact Leads'
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            buttonProps={{
              btnName: 'Add New',
              onClick: onAddNew,
            }}
          />
        </div>

        {/* Table */}
        <div className='flex-1 overflow-auto flex flex-col'>
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => 'min-h-[40px]'}
              isLoading={isTableLoading}
              onRowClick={(row)=>navigate(`${row?._id}?contact=overview`)}
            />
        </div>

        <div className='p-4 py-2 flex justify-end'>
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
      </div>
    </SideNavLayout>
  );
};

export default ContactLeadListing;