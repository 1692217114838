import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useAddServiceTicketMutation } from "src/services/ServiceTicketService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import ServiceTicketForm from "../Layout/ServiceTicketForm";

type Props = {
  onClose: () => void;
};

export type ServiceTicketFormValues = {
  problemName: string;
  departmentId: any;
};

const AddServiceTicketFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addServiceTicket] = useAddServiceTicketMutation();

  // Form Initial Values
  const initialValues: ServiceTicketFormValues = {
    problemName: "",
    departmentId: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    problemName: string().required("Please enter problem"),
    departmentId: object().required("Please select department "),
  });

  // Handle Submit
  const handleSubmit = (
    values: ServiceTicketFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ServiceTicketFormValues>
  ) => {
    const formattedValues = {
      problemName: values?.problemName,
      departmentId: values?.departmentId?.value,
    };

    addServiceTicket(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ServiceTicketForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddServiceTicketFormWrapper;
