import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { date, object, string } from "yup";
import AddIncreamentForm from "./AddIncreamentForm";
import { useAddTeamMemberIncrementMutation } from "src/services/TeamSalaryTabService";
import { showToast } from "src/utils/showToaster";
import { useParams } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@mui/material";

export type IncreamentFormValues = {
  incrementPercent: string;
  incrementEffectiveDate: any;
  oldSalary: string;
  newSalary: string;
};

type Props = {
  onClose: () => void;
  currentSalary: any;
  isLoading: boolean;
};

const AddIncreamentFormWrapper = ({
  onClose,
  currentSalary,
  isLoading,
}: Props) => {
  const { teamMemberId } = useParams();

  const [addsalaryIncrement] = useAddTeamMemberIncrementMutation();

  // Form Initial Values
  const initialValues: IncreamentFormValues = {
    incrementPercent: "",
    incrementEffectiveDate: "",
    oldSalary: currentSalary || "0",
    newSalary: currentSalary || "0",
  };

  // Validation Schema
  const validationSchema = object().shape({
    incrementPercent: string().required("Please enter increment percent"),
    incrementEffectiveDate: date().required("Please enter date"),
  });

  // Handle Submit
  const handleSubmit = (
    values: IncreamentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<IncreamentFormValues>
  ) => {
    const formattedValues = {
      teamMemberId: teamMemberId,
      incrementEffectiveDate: values?.incrementEffectiveDate
        ? moment(values?.incrementEffectiveDate).format("yyyy-MM-DD")
        : "",
      incrementPercent: values?.incrementPercent,
    };
    addsalaryIncrement(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {isLoading && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <AddIncreamentForm formikProps={formikProps} onClose={onClose} />
        </Form>
      )}
    </Formik>
  );
};

export default AddIncreamentFormWrapper;
