import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { handleValidNumber } from "src/utils/validations/numberInput";
import { FormInitialValues } from "../AddETAModal.tsx/CreateETAModalWrapper";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  handleClose: () => void;
  formType: "ADD" | "EDIT";
};

const CreateETAModal = ({ formikProps, handleClose, formType }: Props) => {
  // Formik
  const { values, setFieldValue, isSubmitting, handleBlur, handleSubmit } =
    formikProps;

  return (
    <div className="flex flex-col rounded border border-gray-200 relative">
      {/* Heading & Clear Button */}
      <div className="flex justify-between items-center px-4 py-2 sticky top-0 z-50">
        <div className="text-[24px] font-medium">
          {" "}
          {formType === "ADD" ? "Create" : "Update"} ETA{" "}
        </div>
        <button
          type="button"
          className="text-[#041618] font-medium"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>

      {/* Filter Form */}
      <div className="p-3 overflow-auto flex-1 ">
        <div className="flex flex-col gap-4">
          {/* Estimated Time (hh:mm) */}
          {values?.layerWiseEst?.map((layerList: any, index: number) => {
            return (
              <div key={index} className="">
                <ATMTextField
                  name={`layerWiseEst[${index}].estTime`} // Update the name attribute
                  value={layerList.estTime}
                  onChange={(e) => {
                    const updatedLayerWiseEst = [...values.layerWiseEst];
                    updatedLayerWiseEst[index].estTime = e.target.value;
                    handleValidNumber(e) &&
                      setFieldValue("layerWiseEst", updatedLayerWiseEst);
                  }}
                  label={`${layerList?.layerName} Estimate Time (in Mins)`}
                  placeholder={`${layerList?.layerName} Estimate Time (in Mins)`}
                  onBlur={handleBlur}
                />
              </div>
            );
          })}

          {/* Project */}
          <div className="">
            <ATMTextField
              type="number"
              label="No. of Members"
              name="numberOfMembers"
              value={values.numberOfMembers as any}
              placeholder="Enter number of members"
              onBlur={handleBlur}
              onChange={(e) =>
                handleValidNumber(e) &&
                setFieldValue("numberOfMembers", e.target.value)
              }
            />
          </div>
        </div>
      </div>

      {/* Apply & Cancel Buttons */}
      <div className="flex flex-col gap-2 p-3 sticky bottom-0 bg-white">
        <div>
          <ATMLoadingButton
            type="button"
            isLoading={isSubmitting}
            className="h-[40px] bg-[#041618] font-medium"
            onClick={() => handleSubmit()}
          >
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default CreateETAModal;
