import React from "react";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import AccessDenied from "src/utils/AccessDenied";
import { isAuthorized } from "src/utils/authorization";
import { twMerge } from "tailwind-merge";

export interface columnTypes {
  field: string;
  headerName: string;
  flex?: string;
  renderCell?: (row: any) => string | React.ReactNode | void;
  align?: "start" | "center" | "end";
  extraClasses?: string;
  hidden?: boolean;
  noAuthRequired?: boolean;
  // accessAction? : string;
  name?: string;
}
interface ATMTablePropTypes<T> {
  columns: columnTypes[];
  rows: T[];
  isCheckbox?: boolean;
  selectedRows?: T[];
  onRowSelect?: (row: any) => void;
  extraClasses?: string;
  onRowClick?: (row: any) => void;
  rowExtraClasses?: (row: any) => void;
  isLoading?: boolean;
  idKey?: string;
  noDataMessage?: React.ReactNode;
  disableRowClick?: boolean;
  columnTextClasses?: string;
}

const ATMTable = <T extends {}>({
  columns,
  rows,
  selectedRows = [],
  onRowSelect,
  isCheckbox = false,
  extraClasses = "",
  onRowClick,
  rowExtraClasses,
  isLoading = false,
  idKey = "_id",
  noDataMessage = "No Data Found",
  disableRowClick = false,
  columnTextClasses = "text-[12px]",
}: ATMTablePropTypes<T>) => {
  const tabsRender = columns?.some((nav) => {
    if (nav.field === "action") {
      return false;
    }
    return isAuthorized(nav?.name as keyof typeof UserModuleNameTypes);
  });

  if (!tabsRender) {
    return (
      <>
        <AccessDenied />{" "}
      </>
    );
  }
  return (
    <div
      className={twMerge(
        `relative flex flex-col border-y border-gray-4 h-full overflow-auto ${extraClasses}`
      )}
    >
      {/* Columns */}
      <div className="flex items-center border-collapse  px-4 z-[10] sticky top-0 ">
        {/* Checkbox */}
        {rows.length && isCheckbox ? (
          <div className={`w-[20px]`}>
            <input
              type="checkbox"
              className={`w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray `}
              checked={selectedRows.length === rows.length}
              onChange={(e) => {
                e.stopPropagation();
                selectedRows.length === rows.length
                  ? onRowSelect && onRowSelect([])
                  : onRowSelect && onRowSelect(rows);
              }}
            />
          </div>
        ) : null}

        {columns
          ?.filter((nav) => {
            return isAuthorized(nav?.name as keyof typeof UserModuleNameTypes);
          })
          .map((column, index) => {
            return (
              !column.hidden && (
                <div
                  key={column.field}
                  className={`${
                    column.flex
                  } text-sm font-medium uppercase border-r text-neutral flex py-1 !min-w-[150px] px-2 last-of-type:border-r-0  border-b border-gray-4 bg-gray-2 justify-${
                    column.align || "start"
                  }  ${column.extraClasses}`}
                >
                  <div className={`${columnTextClasses}`}>
                    {column.headerName}
                  </div>
                </div>
              )
            );
          })}
      </div>

      <div className="flex-1 px-4">
        {isLoading ? (
          Array(10)
            .fill(0)
            .map((_, index) => {
              return (
                <div key={index} className="animate-pulse h-[50px] p-1">
                  <div className="h-full rounded bg-slate-200"> </div>
                </div>
              );
            })
        ) : rows.length ? (
          rows?.map((row: any, rowIndex) => (
            <div
              onClick={() => !disableRowClick && onRowClick?.(row)}
              key={row[idKey] || rowIndex}
              className={`flex items-center border-gray-4 ${
                row?.isActive === false
                  ? "bg-slate-300"
                  : " bg-white hover:bg-hover"
              }  ${rowIndex === rows.length - 1 && "rounded-b"} ${
                !disableRowClick && onRowClick && "cursor-pointer"
              } ${rowExtraClasses && rowExtraClasses(row)} ${
                rowIndex !== rows.length - 1 && ""
              }`}
            >
              {/* Checkbox */}
              {isCheckbox ? (
                <div className={`w-[20px]`}>
                  <input
                    type="checkbox"
                    checked={
                      selectedRows?.findIndex(
                        (ele: any) => ele._id === row._id
                      ) !== -1
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      onRowSelect &&
                        onRowSelect((selectedRows: any) =>
                          selectedRows?.findIndex(
                            (ele: any) => ele._id === row._id
                          ) === -1
                            ? [...selectedRows, row]
                            : selectedRows?.filter(
                                (selectedRow: any) =>
                                  selectedRow._id !== row._id
                              )
                        );
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray "
                  />
                </div>
              ) : null}

              {columns
                ?.filter((nav) => {
                  return isAuthorized(
                    nav?.name as keyof typeof UserModuleNameTypes
                  );
                })
                ?.map((column, index) => {
                  return (
                    !column.hidden && (
                      <div
                        key={column.field}
                        className={`${
                          column.flex
                        } text-sm text-ellipsis text-black min-h-[38px] !min-w-[150px] h-full items-center border-r last-of-type:border-r-0   border-b px-2 flex justify-${
                          column.align || "start"
                        } ${column.extraClasses}`}
                      >
                        {column.renderCell
                          ? column.renderCell(row)
                          : row[column.field]}
                      </div>
                    )
                  );
                })}
            </div>
          ))
        ) : (
          <div className="flex justify-center w-full py-5 text-slate-500">
            {noDataMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default ATMTable;
