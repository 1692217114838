import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { DoubtsFormValue } from "src/models/Doubts.model";
import { useAddDoubtMutation } from "src/services/DoubtsServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import DoubtsForm from "../Layouts/DoubtsForm";

type Props = {
  onClose: () => void;
  projectItems?: any;
};

const AddDoubtsFormWrapper = ({ onClose, projectItems }: Props) => {
  const [addDoubt] = useAddDoubtMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  // Form Initial Values
  const initialValues: DoubtsFormValue = {
    project: "",
    module: "",
    feature: "",
    askTo: "",
    question: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    project: object().required("Please select project"),
    // module: object().required("Please select module"),
    // feature: object().required("Please select feature"),
    askTo: object().required("Please select ask to assignee"),
    question: string().required("Please enter the question"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DoubtsFormValue,
    { setSubmitting, resetForm }: FormikHelpers<DoubtsFormValue>
  ) => {
    const { project, module, feature, askTo, ...rest } = values;
    const formattedValues = {
      projectId: project?.value,
      moduleId: module?.value,
      featureId: feature?.value,
      askToId: askTo?.value,
      ...rest,
    };

    addDoubt(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <DoubtsForm
            projectItems={projectItems}
            formType="ADD"
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddDoubtsFormWrapper;
