import apiSlice from "./ApiSlice";

export const TeamSalaryTabService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get All History
    getMemberSalaryHistory: builder.query({
      providesTags: ["salary-increment"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "salary-inc-history/",
          method: "POST",
          body,
        };
      },
    }),

    // Add the client user
    addTeamMemberIncrement: builder.mutation({
      invalidatesTags: ["salary-increment"],
      query: (body) => {
        return {
          url: "/salary-inc-history/add",
          method: "POST",
          body,
        };
      },
    }),
    // Single Product Delete
    getTeamMemberCurrentsalary: builder.query({
      providesTags: ["salary-increment"],
      query: (id: any) => ({
        url: `/codiotic-user/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddTeamMemberIncrementMutation,
  useGetMemberSalaryHistoryQuery,
  useGetTeamMemberCurrentsalaryQuery
  
} = TeamSalaryTabService;
