import React, { useState } from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { MeetingNotesTabFormValues } from "./NotesTabWrapper";
import { CircularProgress } from "@mui/material";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdDelete, MdEdit, MdNote } from "react-icons/md";
import MeetingMarkAsDoneFormWrapper from "./MeetingMarkAsDoneForm/MeetingMarkAsDoneFormWrapper";
import AddMeetingNotesFormWrapper from "./AddNotes/AddMeetingNotesFormWrapper";
import EditMeetingNotesFormWrapper from "./EditNotes/EditMeetingNotesFormWrapper";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { useParams } from "react-router-dom";
import AddTicketFormWrapper from "src/screens/Ticket/Add/AddTicketFormWrapper";
import {
  setIsOpenAddDialog,
  setSaveNextChecked,
} from "src/redux/slices/TicketSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { BsInfoCircleFill } from "react-icons/bs";
import QuickMeetingMarkAsDoneFormWrapper from "./QuickMeetingMarkAsDoneForm/QuickMeetingMarkAsDoneFormWrapper";

type Props = {
  formikProps: FormikProps<MeetingNotesTabFormValues>;
  rows: any[];
  isTableLoading: boolean;
  meetingData: any;
  handleDeleteMeetingNotes: (id: string) => void;
  notesSearchValue: string;
  setNotesSearchValue?: (value: string) => void;
};

const NotesTab = ({
  formikProps,
  rows,
  isTableLoading,
  meetingData,
  handleDeleteMeetingNotes,
  notesSearchValue,
  setNotesSearchValue = () => { },
}: Props) => {
  const [isOpenMarkAsDoneModel, setIsOpenMarkAsDoneModel] = useState<boolean>(false);
  const [isOpenQuickMarkAsDoneModel, setIsOpenQuickMarkAsDoneModel] = useState<boolean>(false);
  const [meetingId, setMeetingId] = useState<any>("");
  const [selectedNoteId, setSelectedNoteId] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [projectId, setProjectId] = useState<any>("");
  const [sourceName, setSourceName] = useState<any>("");
  const [projectName, setProjectName] = useState<any>("");
  const [noteTitle, setNoteTitle] = useState<any>("");

  const { isOpenAddDialog } = useSelector((state: RootState) => state.ticket);
  const dispatch = useDispatch();

  const { meetingId: routeMeetingId } = useParams();

  const handleNotetoTicketClick = (row: any) => {
    setDescription(row?.notes || "");
    setProjectId(row?.projectId);
    setProjectName(row?.projectName);
    setMeetingId(routeMeetingId);
    setSourceName("MEETING");
    setNoteTitle(row?.title);
  };
  return (
    <>
      <div className="flex flex-col h-full bg-gray-50">
        <div className="flex items-center justify-between p-4 py-2 bg-white border-b">
          <div className="text-xl font-medium">
            {selectedNoteId !== "" ? "Update" : "Add"} Notes{" "}
          </div>
          <div>
            {meetingData?.meetingCreateType === 'QUICK' ?
              <div>
                {meetingData?.status !== "COMPLETED" && (
                  <ATMLoadingButton
                    disabled={meetingData?.mode !== 'CALL' && meetingData?.actualStartTime === '' ? true : false}
                    className="bg-primary-light"
                    onClick={() => {
                      setIsOpenQuickMarkAsDoneModel(true);
                      setMeetingId(meetingData?._id);
                    }}
                  >
                    Mark As Done
                  </ATMLoadingButton>
                )}
              </div> :
              <div>
                {meetingData?.status !== "COMPLETED" && (
                  <ATMLoadingButton
                    disabled={meetingData?.mode !== 'CALL' && meetingData?.actualStartTime === '' ? true : false}
                    className="bg-primary-light"
                    onClick={() => {
                      setIsOpenMarkAsDoneModel(true);
                      setMeetingId(meetingData?._id);
                    }}
                  >
                    Mark As Done
                  </ATMLoadingButton>
                )}
              </div>
            }
            {isOpenMarkAsDoneModel ? (
              <MeetingMarkAsDoneFormWrapper
                meetingId={meetingId}
                actualStartTime={meetingData?.actualStartTime}
                onClose={() => {
                  setIsOpenMarkAsDoneModel(false);
                  setMeetingId("");
                }}
              />
            ) : null}

            {isOpenQuickMarkAsDoneModel ? (
              <QuickMeetingMarkAsDoneFormWrapper
                meetingId={meetingId}
                // meetingType={meetingData?.meetingType}
                actualStartTime={meetingData?.actualStartTime}
                onClose={() => {
                  setIsOpenQuickMarkAsDoneModel(false);
                  setMeetingId("");
                }}
              />
            ) : null}
          </div>
        </div>

        <div className="flex h-full gap-4 p-4 overflow-auto">
          <div className="w-1/2 h-full p-4 bg-white border border-dashed rounded-md">
            {/* ADD NOTE WRAPPER */}
            {
              meetingData?.mode !== 'CALL' && meetingData?.actualStartTime === '' ?
                <div className="flex flex-col justify-center items-center mt-[125px]">
                  <span className="text-red-400">
                    <BsInfoCircleFill size={24} />
                  </span>
                  <span className="text-red-300 text-lg">Please start the meeting first.
                  </span>
                </div>
                :
                <>
                  {selectedNoteId === "" && (
                    <AddMeetingNotesFormWrapper
                      // meetingId={meetingData?._id}
                      actualClientUsers={meetingData?.actualClientUsers}
                    />
                  )}
                </>
            }

            {/* EDIT NOTE WRAPPER */}
            {selectedNoteId !== "" && (
              <EditMeetingNotesFormWrapper
                noteId={selectedNoteId}
                actualClientUsers={meetingData?.actualClientUsers}
                onClickRenderAddForm={() => setSelectedNoteId("")}
              />
            )}
            <div className="flex justify-end -mt-1">
              {selectedNoteId !== "" && (
                <button
                  onClick={() => setSelectedNoteId("")}
                  className="border bg-primary-dark text-white rounded px-4 py-2 h-[42px] flex gap-2 justify-center items-center "
                >
                  Cancel Edit Note
                </button>
              )}
            </div>
          </div>

          <div className="w-1/2 h-full bg-white border border-dashed rounded-md">
            <div className="flex flex-col h-full">
              {/* Page Header */}
              <div className="p-4 py-2 border-b">
                <ATMPageHeader
                  pageTitle="All Notes"
                  hideAddButton={true}
                  searchValue={notesSearchValue}
                  onSearchChange={(value) => setNotesSearchValue(value)}
                />
              </div>

              <div className="h-full overflow-x-hidden overflow-y-scroll">
                {isTableLoading ? (
                  <div className="flex justify-center items-center h-[400px]">
                    <CircularProgress />{" "}
                  </div>
                ) : rows?.length ? (
                  rows?.map((row: any, index: any) => {
                    return isTableLoading ? (
                      Array(10)
                        .fill(0)
                        .map((_, index) => {
                          return (
                            <div
                              key={index}
                              className="animate-pulse h-[50px] p-2"
                            >
                              <div className="h-full rounded"></div>
                            </div>
                          );
                        })
                    ) : (
                      <div
                        key={index}
                        className="flex items-center px-4 pt-4 last:pb-4"
                      >
                        <div
                          className={`rounded-md border max-w-[100vw] ${row?.ticketId ? "bg-slate-200" : "bg-white"
                            } flex-1`}
                          onDoubleClick={() =>
                            meetingData?.status !== "FREEZE" &&
                            setSelectedNoteId(row?._id)
                          }
                        >
                          <div className="px-4 pt-3 pb-1">
                            <div className="flex gap-1">
                              {row?.ticketNumber && (
                                <p className="font-medium text-slate-700 text-sm">
                                  {row?.ticketNumber}
                                </p>
                              )}
                              -
                              {row?.ticketTitle && (
                                <p className="font-medium text-slate-700  text-sm">
                                  [{row?.ticketTitle}]
                                </p>
                              )}
                            </div>
                            {row?.title && (
                              <p className="font-medium text-slate-700">
                                {row?.title}
                              </p>
                            )}
                            <div className="text-slate-600">
                              <ATMMarkdownContainer
                                markdown={row?.notes || "-"}
                              />
                            </div>
                          </div>

                          <div className="px-3 py-1 text-xs text-right">
                            <span>{row?.clientUserId}</span>
                          </div>
                        </div>

                        {/* Action Popup */}
                        {meetingData?.status !== "FREEZE" && (
                          <ATMActionMenu
                            options={[
                              {
                                label: (
                                  <div className="flex items-center gap-x-2">
                                    <MdEdit color="#000000" /> Edit
                                  </div>
                                ),
                                onClick: () => {
                                  setSelectedNoteId(row?._id);
                                },
                              },
                              {
                                label: (
                                  <div className="flex items-center gap-x-2">
                                    <MdNote color="#000000" /> Notes to Ticket
                                  </div>
                                ),
                                onClick: () => {
                                  dispatch(setIsOpenAddDialog(true));
                                  handleNotetoTicketClick(row);
                                  dispatch(setSaveNextChecked(false));
                                },
                              },

                              {
                                label: (
                                  <div className="flex items-center gap-x-2">
                                    <MdDelete color="#000000" /> Delete
                                  </div>
                                ),
                                onClick: () => {
                                  handleDeleteMeetingNotes(row?._id);
                                },
                              },
                            ]}
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-sm">
                    Data not Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenAddDialog ? (
        <AddTicketFormWrapper
          onClose={() => dispatch(setIsOpenAddDialog(false))}
          meetingId={meetingId}
          description={description}
          projectId={projectId}
          sourceName={sourceName}
          title={noteTitle}
          projectName={projectName}
        />
      ) : null}


    </>
  );
};

export default NotesTab;
