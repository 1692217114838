import { FormikProps } from "formik";
import moment from "moment";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { SubscriptionFormValues } from "src/models/Subscription.model";
import { handleValidNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<SubscriptionFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const SubscriptionForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const weeklyOptions = [
    {
      label: "MONDAY",
      value: "MONDAY",
    },
    {
      label: "TUESDAY",
      value: "TUESDAY",
    },
    {
      label: "WEDNESDAY",
      value: "WEDNESDAY",
    },
    {
      label: "THRUSDAY",
      value: "THRUSDAY",
    },
    {
      label: "FRIDAY",
      value: "FRIDAY",
    },
    {
      label: "SATURDAY",
      value: "SATURDAY",
    },
    {
      label: "SUNDAY",
      value: "SUNDAY",
    },
  ];
  const recurranceTypeOptions = [
    {
      label: "DAILY",
      value: "DAILY",
    },
    {
      label: "MONTHLY",
      value: "MONTHLY",
    },
    {
      label: "WEEKLY",
      value: "WEEKLY",
    },
    {
      label: "YEARLY",
      value: "YEARLY",
    },
  ];
  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Subscription" : "Update Subscription"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Organisation Name */}
        <div>
          <ATMTextField
            required
            name="title"
            value={values.title}
            onChange={(e) => setFieldValue("title", e.target.value)}
            label="Title"
            placeholder="Enter Title"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMTextField
            required
            name="amount"
            value={values.amount}
            onChange={(e) =>
              handleValidNumber(e) && setFieldValue("amount", e.target.value)
            }
            label="Amount"
            placeholder="Enter Amount"
            onBlur={handleBlur}
          />
        </div>
        <div className="mt-2">
          <ATMCheckbox
            label="Recurring"
            checked={values?.isRecurssion}
            inputClasses="h-[15px]"
            labelClasses="text-sm select-none"
            onChange={(checked) => {
              setFieldValue("isRecurssion", checked);
            }}
          />
        </div>
        {values?.isRecurssion === true && (
          <div>
            <ATMSelect
              name="recurranceType"
              label="Reccurance Type"
              placeholder="Select reccurance type"
              value={values?.recurranceType}
              options={recurranceTypeOptions}
              onChange={(newValue) => {
                setFieldValue("recurranceType", newValue);
              }}
            />
          </div>
        )}
        {values?.recurranceType?.value === "WEEKLY" && (
          <div>
            <ATMSelect
              name="recurranceDay"
              label="Day"
              placeholder="Select Day"
              value={values?.recurranceDay}
              options={weeklyOptions}
              onChange={(newValue) => {
                setFieldValue("recurranceDay", newValue);
              }}
            />
          </div>
        )}
        {(values?.recurranceType?.value === "YEARLY" ||
          values?.recurranceType?.value === "MONTHLY") && (
          <div>
            <ATMDatePicker
              label="Day"
              name="recurranceDay"
              format={
                values?.recurranceType?.value === "YEARLY" ? "DD/MM" : "DD"
              }
              minDate={moment().format("yyyy-MM-DD")}
              value={values.recurranceDay}
              onChange={(newValue) => setFieldValue("recurranceDay", newValue)}
            />
          </div>
        )}
      </div>
    </MOLFormDialog>
  );
};

export default SubscriptionForm;
