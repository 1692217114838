import { IconType } from "react-icons";
import { MdOutlineCampaign } from "react-icons/md";

import { SiMediafire } from "react-icons/si";
import { UserModuleNameTypes } from "./models/UserAccess/UserAccess.model";

type NavItemTypeWithoutChild = {
  label: string;
  path: string;
  icon: IconType;
  children?: never;
  name: string;
};

type NavItemTypeWithChild = {
  label: string;
  path?: never;
  icon: IconType;
  name: string;
  children?: {
    label: string;
    path: string;
    icon: IconType;
    name: string;
  }[];
};

export type NavItemType = NavItemTypeWithChild | NavItemTypeWithoutChild;

export const JobOnbaordNavigation: NavItemType[] = [
  {
    label: "Job Creation",
    icon: MdOutlineCampaign,
    path: "job-creation",
    name: UserModuleNameTypes.NAV_JOB_CREATION,
  },
  {
    label: "On-Board Stage",
    icon: MdOutlineCampaign,
    path: "on-board-stage",
    name: UserModuleNameTypes.NAV_JOB_CREATION,
  },
  {
    label: "Payout",
    icon: SiMediafire,
    path: "payout",
    name: UserModuleNameTypes.NAV_PAYOUT,
  },
  {
    label: "Job Onboarding",
    icon: SiMediafire,
    path: "job-onboarding",
    name: UserModuleNameTypes.NAV_PAYOUT,
  },
];
