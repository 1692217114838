// |-- Built-in Dependencies --|
import React from "react";
import { FormikProps } from "formik";

// |-- Internal Dependencies --|
import { FormInitialValues } from "../Add/AddCountryFormWrapper";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMFilePickerWrapper from "src/components/UI/atoms/formFields/ATMFileUploader/ATMFileUploaderWrapper";

// |-- Types --|
type Props = {
  onClose: () => void;
  formikProps: FormikProps<FormInitialValues>;
};

const CountryForm = ({ onClose, formikProps }: Props) => {
  const { values, setFieldValue, handleBlur, isSubmitting, handleSubmit } =
    formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> Add Country </div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <div>
            <ATMTextField 
            required
              name="countryName"
              value={values.countryName}
              placeholder="Enter country name"
              label="Country Name"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue("countryName", e.target.value)}
            />
          </div>
          <div className="mt-4">
            <ATMTextField 
            required
              name="countryCode"
              value={values.countryCode}
              placeholder="Enter country code"
              label="Country Code"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue("countryCode", e.target.value)}
            />
          </div>
          <div className="mt-4">
            <ATMTextField 
            required
              name="phone"
              value={values.phone}
              placeholder="Enter phone regular expression"
              label="Phone (Regular Expression)"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue("phone", e.target.value)}
            />
          </div>
          <div className="mt-4">
            <ATMFilePickerWrapper
              disabled
              name="flag"
              label="Flag image"
              placeholder="select flag image"
              selectedFile={values.countryFlag}
              onSelect={(imgUrl) => {
                setFieldValue("countryFlag", imgUrl);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <ATMLoadingButton
          isLoading={isSubmitting}
          type="submit"
          onClick={() => handleSubmit()}
        >
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default CountryForm;
