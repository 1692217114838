import { IconType } from "react-icons";
import { FaUser } from "react-icons/fa";
import { RiChatFollowUpLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import AllTaskListingWrapper from "src/screens/AllTask/List/AllTaskListingWrapper";
import ChangeRequestViewWrapper from "./ChangeRequestView/ChangeRequestViewWrapper";
import FeatureDetailsViewWrapper from "./FeatureDetailView/FeatureDetailsViewWrapper";
import ProjectFeatureWrapper from "./ProjectFeature/ProjectFeatureWrapper";
import ProjectModuleWrapper from "./ProjectModule/ProjectModuleWrapper";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import React from "react";

type Props = {
  id: any;
  moduleId: any;
  featureId: any;
  moduleItems: any;
  moduleIsLoading: any;
  featureIsLoading: any;
  featureItems: any;
};

export const projectdetailsTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
    {
      label: "FEATURE-DETAILS",
      icon: FaUser,
      path: "feature-details",
      moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS_FEATURES_DETAILS_TAB
    },
    {
      label: "TASK",
      icon: RiChatFollowUpLine,
      path: "task",
      moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS_TASK_TAB
    },
    {
      label: "CHANGE-REQUEST",
      icon: MdOutlinePublishedWithChanges,
      path: "change-request",
      moduleName: UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS_CHANFGE_REQUEST_TAB
    },

  ];

const ProjectDetails = ({
  id,
  moduleId,
  moduleItems,
  featureId,
  featureItems,
  moduleIsLoading,
  featureIsLoading,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedModuleInfo = moduleItems?.find(
    (module: any) => module?._id === moduleId
  );
  // const currentPath = location.pathname.split("/")[5];
  const queryParams = new URLSearchParams(location.search);
  const currentPath = queryParams.get("tab");


  // Get the value of the 'moduleId' query parameter
  return (
    <div className="w-full h-full p-4 overflow-auto grid grid-cols-12 gap-2">
      {/* Module */}
      <div className=" h-full col-span-2">
        <ProjectModuleWrapper
          moduleItems={moduleItems}
          moduleId={moduleId}
          moduleIsLoading={moduleIsLoading}
          moduleInfo={selectedModuleInfo}
        />
      </div>

      {/* Features */}
      <div className="h-full col-span-2">
        <ProjectFeatureWrapper
          featureItems={featureItems}
          singleModuleData={selectedModuleInfo}
          featureId={featureId}
          featureIsLoading={featureIsLoading}
        />
      </div>
      <div className="flex flex-col gap-2 h-full flex-grow col-span-8 bg-white border shadow-md rounded overflow-hidden">
        {/* projectdetailsTabs */}
        {projectdetailsTabs?.length && (
          <div className="flex gap-3 items-center border-b bg-white">
            {projectdetailsTabs?.filter((nav) => {
              return isAuthorized(nav?.moduleName  as keyof typeof UserModuleNameTypes);
            })?.map((tab: any, index: any) => {
              return (
                <div key={index}>
                  <button
                    type="button"
                    onClick={() =>
                      navigate(
                        `${location.pathname}?moduleId=${moduleId}&featureId=${featureId}&tab=${tab.path}`
                      )
                    }
                    className={`h-full p-4 py-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${currentPath === tab.path?.split("/")[0]
                        ? "text-primary-main   border-primary-main"
                        : "text-gray-700 border-white"
                      }
         `}
                  >
                    <div className="text-sm">
                      <tab.icon />
                    </div>
                    {tab.label}
                  </button>
                </div>
              );
            })}
          </div>
        )}

        <div className="grow overflow-auto ">
          {currentPath === "feature-details" ? (
            isAuthorized(UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS_FEATURES_DETAILS_TAB) && <FeatureDetailsViewWrapper />
          ) : currentPath === "change-request" ? (
            isAuthorized(UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS_TASK_TAB) && <ChangeRequestViewWrapper />
          ) : currentPath === "task" ? (
            isAuthorized(UserModuleNameTypes.ACTION_PROJECTS_VIEW_DETAILS_CHANFGE_REQUEST_TAB) && <AllTaskListingWrapper />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
