import { FormikProps } from "formik";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import { FormInitialValues } from "./CaseFlowListFilterFormDialogWrapper";
import { useGetFinanceAccountQuery } from "src/services/Finance-New-Services/AccountServices";
import { useGetAllPaymentModesQuery } from "src/services/Finance-New-Services/PaymentModeServices";
import { useGetFinanceCategoryQuery } from "src/services/Finance-New-Services/FinanceCategroyService";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};

const accountTypeOptions = [
  {
    label: "In Flow",
    value: "IN",
  },
  {
    label: "Out Flow",
    value: "OUT",
  },
];

const CaseFlowListFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { data: getAccount, isLoading: isAccountLoading } =
    useGetFinanceAccountQuery("");
  const { data: getpaymentMode, isLoading: isPaymentModeLoading } =
    useGetAllPaymentModesQuery("");
  const { data: getCategory, isLoading: isCategoryLoading } =
    useGetFinanceCategoryQuery("");
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  return (
    <MOLFilterDialog 
    title={"Filters"}
    onClose={onClose}
    isSubmitting={isSubmitting}
    clearFilter={onReset}
    handleSubmit={handleSubmit}
    open={open}
    >
   <div className="flex flex-col gap-4 px-2 py-4 md:px-4 md:py-2">
        <div>
          <ATMMultiSelect
            name="transactionType"
            value={values?.transactionType}
            label="TransactionType"
            placeholder="Select TransactionType"
            options={accountTypeOptions}
            onChange={(newValue) => {
              setFieldValue("transactionType", newValue);
            }}
          />
        </div>
        {/* Account */}
        <div>
          <ATMMultiSelect
            name="acountType"
            value={values?.acountType}
            label="Account"
            placeholder="Select acountType"
            onChange={(newValue) => {
              setFieldValue("acountType", newValue);
            }}
            options={getAccount?.data?.map((ele: any) => {
              return {
                label: ele?.accountName,
                value: ele?._id,
              };
            })}
            isLoading={isAccountLoading}
          />
        </div>
        {/* Mode */}
        <div>
          <ATMMultiSelect
            name="mode"
            value={values?.mode}
            label="Mode"
            placeholder="Select Mode"
            onChange={(newValue) => {
              setFieldValue("mode", newValue);
            }}
            options={getpaymentMode?.data?.map((ele: any) => {
              return {
                label: ele?.modeName,
                value: ele?._id,
              };
            })}
            isLoading={isPaymentModeLoading}
          />
        </div>
        {/* Category */}
        <div>
          <ATMMultiSelect
            name="category"
            value={values?.category}
            label="Category"
            placeholder="Select Category"
            onChange={(newValue) => {
              setFieldValue("category", newValue);
            }}
            options={getCategory?.data?.map((ele: any) => {
              return {
                label: ele?.modeName,
                value: ele?._id,
              };
            })}
            isLoading={isCategoryLoading}
          />
        </div>
        {/* Created At */}
        <div className="mb-2">
          <div className="flex items-center gap-2">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>
      
      </div>

    </MOLFilterDialog>
  );
};

export default CaseFlowListFilterFormDialog;
