import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type InitialStateType = {
    isCollapsed: boolean;
};

const initialState: InitialStateType = {
    isCollapsed: false,
};

const configurationSlice: any = createSlice({
    name: "configurationLayout",
    initialState,
    reducers: {
        setIsCollapsed: (state, action: PayloadAction<boolean>) => {
            state.isCollapsed = action.payload;
        },
    },
});

export const { setIsCollapsed } = configurationSlice.actions;
export default configurationSlice.reducer;
