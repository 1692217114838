import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { DomainFormValues } from "src/models/Domain.model";
import DomainForm from "../Layouts/DomainForm";
import { useAddDomainMutation } from "src/services/DomainServices";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
};

const AddDomainFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addDomain] = useAddDomainMutation();

  // Form Initial Values
  const initialValues: DomainFormValues = {
    domainName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    domainName: string().required("Please enter domain name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DomainFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DomainFormValues>
  ) => {
    addDomain(values).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <DomainForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddDomainFormWrapper;
