import { CircularProgress } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { ChangeRequestListResponse } from "src/models/ChangeRequest.model";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValueListing,
  setTotalItems,
} from "src/redux/slices/TicketGroupSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useDeleteTicketGroupMutation,
  useGetAllTicketGroupPaginationQuery,
} from "src/services/TicketGroupServices";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import EditTicketGroupFormWrapper from "../Edit/EditTicketGroupFormWrapper";
import ChangeRequestList from "./TicketGroupList";

type OptionType = {
  label: React.ReactNode;
  onClick: () => void;
};

type Props = {};

const TicketGroupListWrapper = (props: Props) => {
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);

  const [selectedChangeRequestId, setSelectedChangeRequestId] =
    useState<string>("");

  // Redux State
  const { items, totalItems, page, rowsPerPage, searchValueListing, isTableLoading } =
    useSelector((state: RootState) => state.ticketGroup);

  // Initiate Methods
  const dispatch = useDispatch<AppDispatch>();

  const [deleteChangeRequest] = useDeleteTicketGroupMutation();

  const { data, isLoading, isFetching } = useGetAllTicketGroupPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValueListing,
    params: ["groupName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.TICKET_GROUP_LIST_CREATED_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="text-[10px] font-medium text-neutral">
            {formatedDateTimeIntoIst(row.createdAt, "hh:mm A")}
          </div>
          <div className="text-xs font-medium">
            {formatedDateTimeIntoIst(row.createdAt, "DD MMM yyyy")}
          </div>
        </div>
      ),
    },
    {
      field: "groupName",
      headerName: "Group Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[180px]",
      name: UserModuleNameTypes.TICKET_GROUP_LIST_CHANGE_GROUP_NAME,
    },
    {
      field: "totalTickets",
      headerName: "Total Tickets",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.TICKET_GROUP_LIST_TOTAL_TICKETS,
      renderCell: (row: any) => (
        <div className="items-center font-bold">{row?.tickets?.length}</div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: ChangeRequestListResponse) => (
        <div>
          <ATMActionMenu
            options={
              [
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdEdit color="#000000" /> Edit
                    </div>
                  ),
                  onClick: () => {
                    setIsOpenEditDialog(true);
                    setSelectedChangeRequestId(row?._id);
                  },
                  acitonName: UserModuleNameTypes.ACTION_TICKET_GROUP_EDIT,
                },
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdDelete color="#000000" /> Delete
                    </div>
                  ),
                  onClick: () => {
                    showConfirmationDialog({
                      title: "Hands Up",
                      text: "Are you sure want to delete this Ticket group?",
                      icon: "question",
                      showCancelButton: true,
                      next: (result) => {
                        if (result?.isConfirmed) {
                          deleteChangeRequest(row?._id).then((res: any) => {
                            if (res.error) {
                              showToast("error", res?.error?.data?.message);
                            } else {
                              showToast("success", res?.data?.message);
                            }
                          });
                        }
                      },
                    });
                  },
                  acitonName: UserModuleNameTypes.ACTION_TICKET_GROUP_DELETE,
                },
              ] as unknown as OptionType[] // Explicitly cast the array to OptionType[]
            }
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ChangeRequestList
        columns={columns}
        rows={items}
        addNew={() => { }}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValueListing,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValueListing(newValue)),
        }}
      />


      {/* Edit Form */}
      <Suspense fallback={<CircularProgress />}>
        {isOpenEditDialog ? (
          <EditTicketGroupFormWrapper
            onClose={() => setIsOpenEditDialog(false)}
            selectedChangeRequestId={selectedChangeRequestId}
          />
        ) : null}
      </Suspense>
    </React.Fragment>
  );
};

export default TicketGroupListWrapper;
