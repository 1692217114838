import { FieldArray } from "formik";
import React from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";

type Props = {
  formikProps: any;
  onClose: any;
};
const RequiredResource = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;
  return (
    <MOLFormDialog
      title={"Add Resource Required"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <FieldArray name="resourceRequired">
          {({ push, remove }) => (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {values?.resourceRequired?.map(
                (resourceRequired: any, index: any) => (
                  <div key={index} className="md:flex block items-end gap-2">
                    {/* ResourceRequired Name */}
                    <div className="">
                      <ATMTextArea
                        name={`resourceRequired[${index}]`}
                        value={resourceRequired} // Corrected
                        onChange={(e) =>
                          setFieldValue(`resourceRequired[${index}]`, e)
                        }
                        label="Resource Required"
                        required
                        placeholder="Resource Required"
                        // onBlur={handleBlur}
                      />
                    </div>
                    {/* Delete Button */}
                    <button
                      type="button"
                      className={`${getInputHeight(
                        "small"
                      )} aspect-square rounded bg-red-400 text-white flex justify-center items-center mt-4 md:mt-0`}
                      onClick={() => remove(index)}
                    >
                      <RiDeleteBin5Line size={20} />
                    </button>
                  </div>
                )
              )}
              {/* Add More Button */}
              <button
                type="button"
                onClick={() => push("")}
                className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400"
              >
                Add{" "}
                {values?.resourceRequired?.length
                  ? "More"
                  : "Resource Required"}
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </MOLFormDialog>
  );
};

export default RequiredResource;
