import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { ReminderFormValues } from "src/models/Reminder.model";
import {
  useEditReminderByIdMutation,
  useGetReminderByIdQuery,
} from "src/services/ReminderService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import ReminderForm from "../Layouts/ReminderForm";

type Props = {
  selectedReminderId: string;
  onClose: () => void;
};

const ReminderEditWrapper = ({ selectedReminderId, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetReminderByIdQuery(selectedReminderId);
  const [updateReminder] = useEditReminderByIdMutation();
  const userDataLJson = localStorage.getItem("userData");
  const userData = JSON?.parse(userDataLJson as string);
  let currentTime = moment();
  currentTime.add(2, "hours");

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: ReminderFormValues = {
    reminder: items?.reminder,
    dateTime: items?.dateTime,
    forWhom: items?.forWhom?.map((formWhom: any) => ({
      label: formWhom.name,
      value: formWhom?._id,
    })),
    source: items?.source,
    sourceId: items?.sourceId,
  };

  // Validation Schema
  const validationSchema = object().shape({
    reminder: string().test(
      "is-reminder-required",
      "Reminder are required",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: ReminderFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ReminderFormValues>
  ) => {
    const FormattedValues = {
      reminder: values.reminder,
      dateTime: values?.dateTime
        ? moment(values?.dateTime).format("YYYY-MM-DD HH:mm:ss")
        : currentTime.format("YYYY-MM-DD HH:mm:ss"),
      forWhom: values?.forWhom?.length
        ? values?.forWhom?.map((forWhom: any) => forWhom?.value)
        : [userData?.userId],
      source: "REMINDER",
      sourceId: null,
    };
    updateReminder({ id: selectedReminderId, body: FormattedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <ReminderForm
            formikProps={formikProps}
            formType="EDIT"
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ReminderEditWrapper;
