import { useState, useEffect } from "react";
import { useGetAllChannelGroupListQuery } from "src/services/ChannelGroupService";
const useChannelGroups = () => {
    const [ChannelGroups, setChannelGroups] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const { data, isLoading, isFetching } = useGetAllChannelGroupListQuery("");

    useEffect(() => {
        if (isLoading || isFetching) {
            setIsDataLoading(true);
        } else {
            const result: any[] = data?.data;
            setChannelGroups(result || []);
            setIsDataLoading(false);
        }
    }, [data, isLoading, isFetching]);

    return { ChannelGroups, isDataLoading };
};

export default useChannelGroups;
