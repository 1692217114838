import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

export type TeamViewTodayTaskSliceStateType = {
  items: any[] | [];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  filterBy: {
    fieldName: string;
    value: string[];
  }[];
  dateFilter: {
    dateFilterKeyForMeeting : string
    startDate: string | null;
    endDate: string | null;
  };
  // dateFilterForMeeting: {
  //   startDate: string | null;
  //   endDate: string | null;
  //   dateFilterKey: string| null;
  // }
};

const initialState: TeamViewTodayTaskSliceStateType = {
  items: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  rowsPerPage: 20,
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  filterBy: [],
  dateFilter: {
    dateFilterKeyForMeeting : "scheduledDate",
    startDate: moment().format("yyyy-MM-DD"),
    endDate: moment().format("yyyy-MM-DD"),
  },
  // dateFilterForMeeting: {
  //   startDate: moment().format("yyyy-MM-DD"),
  //   endDate: moment().format("yyyy-MM-DD"),
  //   dateFilterKey: "scheduledDate",
  // }
};

const teamViewTodayTaskSlice: Slice<TeamViewTodayTaskSliceStateType> = createSlice({
  name: "teamViewTodayTaskSlice",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string[] }[]>
    ) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setDateFilter: (state, action: PayloadAction<{ startDate: string; endDate: string ; dateFilterKeyForMeeting : string}>) => {
      state.dateFilter = action.payload;
    },
    // setDateFilterForMeeting: (state, action: PayloadAction<{ startDate: string; endDate: string; dateFilterKey: string }>) => {
    //   state.dateFilterForMeeting = action.payload;
    // },
  },
});

export const {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setFilterBy,
} = teamViewTodayTaskSlice.actions;
export default teamViewTodayTaskSlice.reducer;
