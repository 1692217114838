import React from "react";
import ATMSelect from "../../atoms/formFields/ATMSelect/ATMSelect";
import { useGetModulesByProjectIdQuery } from "src/services/ModuleServices";
import { Options, PropsValue } from "react-select";

type Option = {
  value: string;
  label: string;
};

type Props = {
  projectId?: any;
  label?: string;
  required?: boolean;
  size?: "small" | "medium";
  name: string;
  isLoading?: boolean;
  searchable?: boolean;
  placeholder?: string;
  readonly?: boolean;
  onBlur?: any;
  keyOfProject: number;
  options?: Options<Option | any>;
  onChange: (selectedOption: Option | any) => void;
  value: PropsValue<Option | any>;
};

const MOLAffectedAreaModuleDropdown = ({
  label = "",
  name,
  options,
  value,
  onChange,
  isLoading,
  required = false,
  placeholder = "Select",
  size = "small",
  keyOfProject,
  onBlur,
  searchable = false,
  readonly = false,
  projectId,
}: Props) => {
  // get module via project id
  const {
    isLoading: isModuleLoading,
    isFetching: isModuleFetching,
    data,
  } = useGetModulesByProjectIdQuery(projectId || "", {
    skip: !projectId,
  });

  

  return (
    <ATMSelect
      name={name}
      label="Module"
      value={value}
      onBlur={onBlur}
      placeholder="Select module"
      isLoading={isModuleLoading || isModuleFetching}
      options={data?.data?.map((ele: any) => {
        return {
          label: ele?.moduleName,
          value: ele?._id,
        };
      })}
      onChange={onChange}
    />
  );
};

export default MOLAffectedAreaModuleDropdown;
