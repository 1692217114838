import React from "react";
import { DaysPartEnum } from "src/models/LeaveReques.model";
import {
  convertDateInDateMonNameFormat,
  getTimeFromDate,
} from "src/utils/common/dateFormat";
type Props = {
  leaveStartDate: string;
  dayPart: "SECOND_HALF" | "FIRST_HALF" | "FOR_HOURS" | "";
  leavingTime: string;
  arrivalTime: string;
};

const ViewHalfDayLeaveRequest = ({
  leaveStartDate,
  dayPart,
  leavingTime,
  arrivalTime,
}: Props) => {
  return (
    <div className="grid grid-cols-12 gap-6 ">
      <div className="col-span-3">
        <p className="font-medium text-sm">From</p>
        <div className="border rounded-md text-sm bg-white p-2">
          {convertDateInDateMonNameFormat(leaveStartDate)}
        </div>
      </div>
      <div className="col-span-3">
        <p className="font-medium text-sm">Day Part</p>
        <div className="border rounded-md text-sm bg-white p-2">
          {dayPart ? DaysPartEnum[dayPart] : "-"}
        </div>
      </div>
      <div className="col-span-3">
        <p className="font-medium text-sm">Leaving Time</p>
        <div className="border rounded-md text-sm bg-white p-2">
          {getTimeFromDate(leavingTime)}
        </div>
      </div>
      <div className="col-span-3">
        <p className="font-medium text-sm">Reporting Time</p>
        <div className="border rounded-md text-sm bg-white p-2">
          {getTimeFromDate(arrivalTime)}
        </div>
      </div>
    </div>
  );
};

export default ViewHalfDayLeaveRequest;
