import React from "react";
import { TbEdit, TbTrash } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { useDeleteWorkspaceMutation } from "src/services/RequirementGatheringService";
import { showToast } from "src/utils/showToaster";
import ATMIconButton from "src/components/UI/atoms/ATMIconButton/ATMIconButton";

type Props = {
  rows: any[];
  addNew: (newValue: boolean) => void;
  openAddPlatformDialog: (workspaceId: string) => void;
  openEditDialog: (moduleId: string) => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const WorkspaceList = ({
  rows,
  addNew,
  openAddPlatformDialog,
  openEditDialog,
  paginationProps: { searchValue, setSearchValue },
}: Props) => {
  const navigate = useNavigate();
  const [deletePlatform] = useDeleteWorkspaceMutation();

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this workspace?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deletePlatform(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  return (
    <SideNavLayout>
      <div className="flex flex-col h-full overflow-hidden">
        <div>
          <ATMPageHeader
            pageTitle="Workspace"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_WORKSPACE_ADD)
            }
            buttonProps={{
              btnName: "Add New",
              onClick: () => {
                addNew(true);
              },
            }}
          />
        </div>
        <div className="grid flex-1 grid-cols-1 gap-4 p-4 pt-0 overflow-scroll md:grid-cols-3 xl:grid-cols-4">
          {rows?.map((row) => (
            <div
              key={row._id}
              className="flex flex-col w-full transition-all duration-300 bg-white border rounded-lg hover:shadow-lg border-divider group/workspaceCard min:h-fit max-h-full:"
            >
              <div className="flex flex-col gap-2 p-4">
                <div className="flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center w-8 h-8 text-gray-700 bg-gray-200 rounded-full">
                      {row?.workspaceName.charAt(0).toUpperCase()}
                    </div>
                    <div className="flex-1">
                      <h2 className="text-sm font-semibold">
                        {row?.workspaceName}
                      </h2>
                    </div>
                  </div>
                  <span className="flex items-center invisible gap-1 group-hover/workspaceCard:visible">
                    <ATMIconButton
                      variant="text"
                      color="primary"
                      onClick={() => openEditDialog(row?._id)}
                      icon={<TbEdit />}
                    />
                    <ATMIconButton
                      variant="text"
                      color="error"
                      onClick={() => handleDelete(row?._id)}
                      icon={<TbTrash />}
                    />
                  </span>
                </div>
              </div>
              <div className="flex-1">
                <div className="flex flex-wrap gap-2 p-4 pt-0">
                  {row?.platformData?.map((platform: any) => (
                    <span
                      key={platform._id}
                      onClick={() =>
                        navigate(
                          `/workspace/${row._id}/platform/${platform._id}/details`
                        )
                      }
                      className="px-2 py-1 text-xs font-semibold text-purple-700 bg-purple-100 rounded cursor-pointer h-fit"
                    >
                      {platform?.platformName}
                    </span>
                  ))}
                  <button
                    onClick={() => openAddPlatformDialog(row._id)}
                    className="px-2 py-1 text-xs font-semibold text-gray-700 bg-gray-200 rounded h-fit"
                  >
                    + Add Platform
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-between gap-2 p-4 border-t border-divider">
                <p className="text-sm">{row?.clientOrgName}</p>
                <span className="px-2 py-1 text-xs font-semibold text-green-800 bg-green-200 rounded">
                  Active
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </SideNavLayout>
  );
};

export default WorkspaceList;
