import React from "react";
import { TbArrowsDiagonal } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { PiArrowsInSimpleBold } from "react-icons/pi";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import { FormikProps } from "formik";

import { IoIosArrowDown } from "react-icons/io";
import { Summary } from "./AddNoteLogDialogFormWrapper";
import ATMContactSelect from "src/components/UI/atoms/ATMContactSelect/ATMContactSelect";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

type textEditorDialogType = {
  contactBy: string;
  editorValue?: string;
  showInFullWidth: boolean;
  setShowInFullWidth: any;
  onClose: any;
  formikProps: FormikProps<Summary>;
  userOptions: any;
};

const actionTypeOptions = [
  { label: "Call", value: "CALL" },
  { label: "Email", value: "EMAIL" },
  { label: "To-do", value: "TO-DO" },
];

const actionOnOptions = [
  { label: "Today", value: "TODAY" },
  { label: "Tommorow", value: "TOMMOROW" },
  { label: "In-2 business days", value: "2-BUSINESS-DAYS" },
  { label: "In-3 business days", value: "3-BUSINESS-DAYS" },
  { label: "In 1 week", value: "1 WEEK" },
  { label: "In 2 weeks", value: "1 WEEKS" },
  { label: "In 1 month", value: "1 MONTH" },
  { label: "In 2 months", value: "2 MONTHS" },
  { label: "In 3 months", value: "3 MONTHS" },
  { label: "In 6 months", value: "6 MONTHS" },
  { label: "Custom Date", value: "CUSTOM-DATE" },
];

const callOutComeOptions = [
  { label: "Busy", value: "BUSY" },
  { label: "Connect", value: "CONNECT" },
  { label: "Left live message", value: "LEFT LIVE MESSAGE" },
  { label: "Left voicemail", value: "LEFT VOICE MAIL" },
  { label: "No answer", value: "NO ANSWER" },
  { label: "Wrong number", value: "WRONG NUMBER" },
];
const callDirectionOptions = [
  { label: "Inbound", value: "INBOUND" },
  { label: "Outbound", value: "OUTBOUND" },
];

const meetingOutComesOptions = [
  { label: "Scheduled", value: "SCHEDULED" },
  { label: "Completed", value: "COMPLETED" },
  { label: "Rescheduled", value: "RESCHEDULED" },
  { label: "No Show", value: "NOSHOW" },
  { label: "Canceled", value: "CANCELED" },
];

const meetingDurationOptions = [
  { label: "15 Minutes", value: "15 MINUTES" },
  { label: "30 Minutes", value: "30 MINUTES" },
  { label: "45 Minutes", value: "45 MINUTES" },
  { label: "1 Hour", value: "1 HOUR" },
  { label: "1 Hour 15 Minutes", value: "1HOUR 15MINUTES" },
  { label: "1 Hour 30 Minutes", value: "1HOUR 30MINUTES" },
  { label: "1 Hour 45 Minutes", value: "1HOUR 45MINUTES" },
  { label: "2 Hour", value: "2 HOUR" },
  { label: "2 Hour 15 Minutes", value: "2HOUR 15MINUTES" },
  { label: "2 Hour 30 Minutes", value: "2HOUR 30MINUTES" },
  { label: "2 Hour 45 Minutes", value: "2HOUR 45MINUTES" },
  { label: "3 Hour", value: "3 HOUR" },
  { label: "3 Hour 15 Minutes", value: "3HOUR 15MINUTES" },
  { label: "3 Hour 30 Minutes", value: "3HOUR 30MINUTES" },
  { label: "3 Hour 45 Minutes", value: "3HOUR 45MINUTES" },
  { label: "4 Hour", value: "4 HOUR" },
  { label: "4 Hour 15 Minutes", value: "4HOUR 15MINUTES" },
  { label: "4 Hour 30 Minutes", value: "4HOUR 30MINUTES" },
  { label: "4 Hour 45 Minutes", value: "4HOUR 45MINUTES" },
  { label: "5 Hour", value: "5 HOUR" },
  { label: "5 Hour 15 Minutes", value: "5HOUR 15MINUTES" },
  { label: "5 Hour 30 Minutes", value: "5HOUR 30MINUTES" },
  { label: "5 Hour 45 Minutes", value: "5HOUR 45MINUTES" },
  { label: "6 Hour", value: "6 HOUR" },
  { label: "6 Hour 15 Minutes", value: "6HOUR 15MINUTES" },
  { label: "6 Hour 30 Minutes", value: "6HOUR 30MINUTES" },
  { label: "6 Hour 45 Minutes", value: "6HOUR 45MINUTES" },
  { label: "6 Hour", value: "6 HOUR" },
  { label: "6 Hour 15 Minutes", value: "6HOUR 15MINUTES" },
  { label: "6 Hour 30 Minutes", value: "6HOUR 30MINUTES" },
  { label: "6 Hour 45 Minutes", value: "6HOUR 45MINUTES" },
  { label: "7 Hour", value: "7 HOUR" },
  { label: "7 Hour 15 Minutes", value: "7HOUR 15MINUTES" },
  { label: "7 Hour 30 Minutes", value: "7HOUR 30MINUTES" },
  { label: "7 Hour 45 Minutes", value: "7HOUR 45MINUTES" },
  { label: "8 Hour", value: "8 HOUR" },
];

const AddNoteLogDialogForm = ({
  contactBy,
  onClose,
  editorValue = "",
  showInFullWidth,
  setShowInFullWidth,
  formikProps,
  userOptions,
}: textEditorDialogType) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <div>
      <div className="bg-slate-500 p-2 flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <IoIosArrowDown
            className="font-semibold"
            size="1.3em"
            color="#fbfbfb"
          />
          <p className="text-white capitalize font-semibold">
            {contactBy === "SMS" ? "SMS" : contactBy?.toLowerCase()}
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <div
            className="hover:bg-slate-600  p-1 cursor-pointer rounded-full"
            onClick={() => setShowInFullWidth(!showInFullWidth)}
          >
            {showInFullWidth ? (
              <PiArrowsInSimpleBold size="1.3em" color="#fbfbfb" />
            ) : (
              <TbArrowsDiagonal size="1.3em" color="#fbfbfb" />
            )}
          </div>
          <span
            className="hover:bg-slate-600 p-1 cursor-pointer rounded-full"
            onClick={onClose}
          >
            <RxCross2 size="1.4em" color="#fbfbfb" />
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-4 py-3">
        {contactBy !== "NOTE" && (
          <div className="grid grid-cols-3 mx-4 border-b pb-3 gap-4">
            <ATMContactSelect
              label={contactBy === "MEETING" ? "Attendees" : "Contacted"}
              value={values?.contactedBy}
              onChange={(e: any) =>
                setFieldValue("contactedBy", e.target.value)
              }
              options={userOptions}
            />
            {contactBy === "CALL" && (
              <ATMContactSelect
                label="Call outcome"
                value={values?.callOutCome}
                onChange={(e: any) =>
                  setFieldValue("callOutCome", e.target.value)
                }
                options={callOutComeOptions}
              />
            )}
            {contactBy === "CALL" && (
              <ATMContactSelect
                label="Directions"
                value={values?.callDirection}
                onChange={(e: any) =>
                  setFieldValue("callDirection", e.target.value)
                }
                options={callDirectionOptions}
              />
            )}
            {contactBy === "MEETING" && (
              <ATMContactSelect
                label="Outcome"
                value={values?.meetingOutCome}
                onChange={(e: any) =>
                  setFieldValue("meetingOutCome", e.target.value)
                }
                options={meetingOutComesOptions}
              />
            )}
            {contactBy === "MEETING" && (
              <ATMContactSelect
                label="Duration"
                value={values?.meetingDuration}
                onChange={(e: any) =>
                  setFieldValue("meetingDuration", e.target.value)
                }
                options={meetingDurationOptions}
              />
            )}
            <div className="flex flex-col">
              <label className="text-[12px] mx-1 mb-1">Date</label>
              <input
                type="date"
                className="!outline-0  w-32 bg-blue-50 text-primary-dark font-medium text-[14px] border rounded px-1"
                value={values?.contactedOnDate || null}
                onChange={(e) => {
                  setFieldValue("contactedOnDate", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-[12px] mx-1 mb-1">Time</label>
              <input
                type="time"
                className="!outline-0  w-32  text-primary-dark font-medium text-[14px] border rounded px-1"
                value={values?.contactedOnTime || null}
                onChange={(e) => {
                  setFieldValue("contactedOnTime", e.target.value);
                }}
              />
            </div>
          </div>
        )}
        <div className="mx-3 ">
          <MOLMarkdownEditor
            extraClassName="min-h-[200px] max-h-96 overflow-y-auto"
            key={editorValue}
            required
            value={values?.note}
            placeholder="Note..."
            onChange={(newValue: any) => {
              setFieldValue("note", newValue);
            }}
          />
        </div>
        <div className="flex gap-1 mx-4 items-center">
          <ATMCheckbox
            inputClasses="w-4"
            checked={values?.nextActionRequired}
            onChange={() => {
              setFieldValue("nextActionRequired", !values?.nextActionRequired);
            }}
            label="Create a"
            extraClasses="text-[14px] font-medium mt-1"
          />
          <ATMContactSelect
            value={values?.actionType}
            onChange={(e: any) => setFieldValue("actionType", e.target.value)}
            options={actionTypeOptions}
          />
          <p className="text-[14px] font-medium text-slate-600">
            task to follow up
          </p>

          {values?.nextActionOn === "CUSTOM-DATE" ? (
            <input
              type="date"
              className="!outline-0 w-[130px] text-primary-dark font-medium text-[14px] border rounded-lg p-1"
              value={values?.nextActionDate || null}
              onChange={(e) => {
                setFieldValue("nextActionDate", e.target.value);
              }}
            />
          ) : (
            <ATMContactSelect
              value={values?.nextActionOn}
              onChange={(e: any) =>
                setFieldValue("nextActionOn", e.target.value)
              }
              options={actionOnOptions}
            />
          )}
        </div>
      </div>
      <div className="mt-2 border-t py-2">
        <ATMLoadingButton
          isLoading={isSubmitting}
          type="submit"
          className={`bg-gray-200 text-slate-500 py-2 px-1  cursor-pointer  mx-4 hover:text-white hover:bg-slate-500 ${
            contactBy !== "NOTE" ? "w-28" : "w-24"
          }`}
        >
          {contactBy !== "NOTE" ? "Log activity" : "Add Note"}
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default AddNoteLogDialogForm;
