/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from "@mui/material";
import { FormikProps } from "formik";
import moment from "moment";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { useGetAllMeetings } from "src/hooks/useGetAllMeetings";
import { PrepareChangeRequestFormValue } from "./PrepareChangeRequestFormWrapper";
import { handleValidNumber } from "src/utils/validations/numberInput";
// import { MeetingListResponse } from "src/models/Meeting.model";

type Props = {
  formikProps: FormikProps<PrepareChangeRequestFormValue>;
  onClose: () => void;
};

const PrepareChangeRequestForm = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const { allMeetings, isDataLoading: isAllClientLoading } =
    useGetAllMeetings();

  function convertMinutesToHoursAndMinutes(totalMinutes: number) {
    if (typeof totalMinutes !== "number" || totalMinutes < 0) {
      throw new Error("Input must be a non-negative number.");
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }

  // input value change on meeting
  const handleMeetingOnChange = (value: string, ind: any, ele: any) => {
    const arr = [...values.meetings];
    arr[ind] = {
      ...ele,
      meetingTimeInMins: value,
      totalMainHrs: Number(value) * ele?.actualCodioticUser,
    };

    setFieldValue("meetings", arr);
  };

  //
  const handleAnalysisOnChange = (value: string, ind: any, ele: any) => {
    const arr = [...values.analysisMeetings];
    arr[ind] = {
      ...ele,
      meetingTimeInMins: value,
      totalMainHrs: Number(value) * ele?.actualCodioticUser,
    };

    setFieldValue("analysisMeetings", arr);
  };

  // onSelect Change
  const handleSelectOnChange = (newValue: any) => {
    setFieldValue("meetings", newValue);
  };

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> Prepare Change Request </div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>

      <div className="flex flex-col gap-4">
        <h1 className="font-semibold text-[18px]">Meetings</h1>
        {/* Meetings */}
        <div>
          <ATMMultiSelect
            name="meetingId"
            required
            value={values?.meetings}
            // value={values.meetings}
            label="Meetings"
            placeholder="Select Meetings"
            isLoading={isAllClientLoading}
            onBlur={handleBlur}
            options={allMeetings?.map((ele) => {
              const meetingTimeInMins = ele?.actualEndTime
                ? moment(ele?.actualEndTime).diff(
                    ele?.actualStartTime,
                    "minutes"
                  )
                : 0;
              const actualCodioticUser = ele?.actualCodioticUsers?.length || 0;

              return {
                actualCodioticUser,
                meetingId: ele?._id,
                meetingTimeInMins,
                meetingTitle: ele?.title,
                totalMainHrs:
                  Number(meetingTimeInMins) * Number(actualCodioticUser),
              };
            })}
            getOptionLabel={(option) => option?.meetingTitle}
            getOptionValue={(option) => option?._id}
            isSearchedOption={(option, searchValue) => {
              return searchValue
                ? option?.data?.meetingTitle
                    ?.toLowerCase()
                    ?.includes(searchValue)
                : true;
            }}
            isOptionEqualToValue={(option, selected) => {
              return (
                selected?.findIndex(
                  (selectedValue: any) =>
                    selectedValue?.meetingId === option.meetingId
                ) > -1
              );
            }}
            onChange={(newValue) => {
              handleSelectOnChange(newValue);
            }}
          />
        </div>

        <div className="grid md:grid-cols-5 grid-cols-1 gap-2">
          {/* Meeting */}
          {values.meetings?.map((ele, ind) => {
            return (
              <div
                className="bg-white p-3 rounded-lg shadow-md md:w-60 w-full"
                key={ind}
              >
                <div className="mb-3">
                  <p className="text-gray-500 text-sm">Meeting Name</p>
                  <p className="text-gray-800 font-semibold">
                    {ele?.meetingTitle}
                  </p>
                </div>

                {/* Total Codiotic User */}
                <div className="mb-3">
                  <p className="text-gray-500 text-sm">Total Codiotic User</p>

                  <div className="flex gap-x-2">
                    <span className="text-sm text-gray-800 font-semibold">
                      {ele?.actualCodioticUser}
                    </span>
                  </div>
                </div>

                {/* Total Time (in minutes) */}
                <div className="mb-4">
                  <p className="text-gray-500 text-sm">
                    Total Time (in minutes)
                  </p>
                  <div className="flex gap-x-2">
                    <input
                      className="border-[1px] border-black outline-none px-2 py-1 rounded"
                      value={ele?.meetingTimeInMins}
                      onChange={(e) => {
                        handleValidNumber(e) &&
                          handleMeetingOnChange(e?.target.value, ind, ele);
                      }}
                    />
                  </div>
                </div>

                {/* Total hours of developers (in minutes) */}
                <div className="mb-4">
                  <p className="text-gray-500 text-sm">Total Man Hours</p>
                  <div className="flex gap-x-2">
                    <span className="text-sm px-1">
                      <span className="font-semibold">
                        {ele?.totalMainHrs}{" "}
                      </span>{" "}
                      {" Minutes -"} (
                      <span className="font-semibold">
                        {" "}
                        {
                          convertMinutesToHoursAndMinutes(ele?.totalMainHrs)
                            ?.hours
                        }
                      </span>
                      h{" "}
                      <span className="font-semibold">
                        {
                          convertMinutesToHoursAndMinutes(ele?.totalMainHrs)
                            ?.minutes
                        }
                      </span>
                      m)
                    </span>
                  </div>
                </div>

                {/* Date Time */}
                {/* <div className="mb-4">
                  <p className="text-gray-500 text-sm">Actual Date & Time</p>
                  <p className="text-gray-800 text-sm font-semibold">
                    {moment(ele?.actualStartTime).format("MMMM D, YYYY")}
                    <div></div>
                    <div className="flex gap-x-2">
                      <span className="text-sm">
                        {moment(ele?.actualStartTime).format("hh:mm A")}
                      </span>
                      <span className="text-sm">To</span>
                      <span className="text-sm">
                        {moment(ele?.actualEndTime).format("hh:mm A")}
                      </span>
                    </div>
                  </p>
                </div> */}
              </div>
            );
          })}
        </div>
        <Divider />

        {/* Analysis Time */}
        <h1 className="font-semibold text-[18px]">Analysis Time</h1>
        {/* Meetings */}
        <div>
          <ATMMultiSelect
            name="analysisMeetings"
            required
            value={values?.analysisMeetings}
            label="Analysis Meetings"
            placeholder="Select Analysis Meetings"
            isLoading={isAllClientLoading}
            onBlur={handleBlur}
            options={allMeetings?.map((ele) => {
              const meetingTimeInMins = ele?.actualEndTime
                ? moment(ele?.actualEndTime).diff(
                    ele?.actualStartTime,
                    "minutes"
                  )
                : 0;
              const actualCodioticUser = ele?.actualCodioticUsers?.length || 0;

              return {
                actualCodioticUser,
                meetingId: ele?._id,
                meetingTimeInMins,
                meetingTitle: ele?.title,
                totalMainHrs:
                  Number(meetingTimeInMins) * Number(actualCodioticUser),
              };
            })}
            getOptionLabel={(option) => option?.meetingTitle}
            getOptionValue={(option) => option?._id}
            isSearchedOption={(option, searchValue) => {
              return searchValue
                ? option?.data?.meetingTitle
                    ?.toLowerCase()
                    ?.includes(searchValue)
                : true;
            }}
            isOptionEqualToValue={(option, selected) => {
              return (
                selected?.findIndex(
                  (selectedValue: any) =>
                    selectedValue?.meetingId === option.meetingId
                ) > -1
              );
            }}
            onChange={(newValue) => {
              setFieldValue("analysisMeetings", newValue);
            }}
          />
        </div>

        <div className="grid md:grid-cols-5 grid-cols-1 gap-4">
          {/* Meeting */}
          {values.analysisMeetings?.map((ele, ind) => {
            return (
              <div
                className="bg-white p-3 rounded-lg shadow-md md:w-60 w-full"
                key={ind}
              >
                <div className="mb-3">
                  <p className="text-gray-500 text-sm">Meeting Name</p>
                  <p className="text-gray-800 font-semibold">
                    {ele?.meetingTitle}
                  </p>
                </div>

                {/* Total Codiotic User */}
                <div className="mb-3">
                  <p className="text-gray-500 text-sm">Total Codiotic User</p>

                  <div className="flex gap-x-2">
                    <span className="text-sm text-gray-800 font-semibold">
                      {ele?.actualCodioticUser}
                    </span>
                  </div>
                </div>

                {/* Total Time (in minutes) */}
                <div className="mb-4">
                  <p className="text-gray-500 text-sm">
                    Total Time (in minutes)
                  </p>
                  <div className="flex gap-x-2">
                    <input
                      readOnly
                      className="border-[1px] border-black outline-none px-2 py-1 rounded"
                      value={ele?.meetingTimeInMins}
                      onChange={(e) => {
                        handleAnalysisOnChange(e?.target.value, ind, ele);
                      }}
                    />
                  </div>
                </div>

                {/* Total hours of developers (in minutes) */}
                <div className="mb-4">
                  <p className="text-gray-500 text-sm">
                    Total Man Hours (in minutes)
                  </p>
                  <div className="flex gap-x-2">
                    <span className="text-sm px-1">
                      {ele?.totalMainHrs} (
                      <span className="font-semibold">
                        {" "}
                        {
                          convertMinutesToHoursAndMinutes(ele?.totalMainHrs)
                            ?.hours
                        }
                      </span>
                      h{" "}
                      <span className="font-semibold">
                        {
                          convertMinutesToHoursAndMinutes(ele?.totalMainHrs)
                            ?.minutes
                        }
                      </span>
                      m){" "}
                    </span>
                  </div>
                </div>
                {/*  Date & Time  */}
                {/* <div className="mb-4">
                  <p className="text-gray-500 text-sm">Actual Date & Time</p>
                  <p className="text-gray-800 text-sm font-semibold">
                    {moment(ele?.actualStartTime).format("MMMM D, YYYY")}
                    <div></div>
                    <div className="flex gap-x-2">
                      <span className="text-sm">
                        {moment(ele?.actualStartTime).format("hh:mm A")}
                      </span>
                      <span className="text-sm">To</span>
                      <span className="text-sm">
                        {moment(ele?.actualEndTime).format("hh:mm A")}
                      </span>
                    </div>
                  </p>
                </div> */}
              </div>
            );
          })}
        </div>
        <Divider />

        {/* dev And Qa Time */}
        <h1 className="font-semibold text-[18px]">Development and QA Time</h1>
        <div className="md:flex block gap-6">
          <ATMTextField
            required
            type="text"
            name="devAndQaTime"
            label="Development & Qa Time"
            value={values.devAndQaTime as any}
            placeholder="Enter Dev & QA Time"
            onBlur={handleBlur}
            onChange={(e) =>
              handleValidNumber(e) &&
              setFieldValue("devAndQaTime", e.target.value)
            }
          />
          <ATMTextField
            required
            type="text"
            name="numberOfDeveloper"
            label="No. of developers"
            value={values?.numberOfDeveloper as any}
            placeholder="Enter No. of developers"
            onBlur={handleBlur}
            onChange={(e) =>
              handleValidNumber(e) &&
              setFieldValue("numberOfDeveloper", e.target.value)
            }
          />
        </div>

        <Divider />
        {/* Deployment Time */}
        <h1 className="font-semibold text-[18px]">Deployment Time</h1>
        <div className="md:flex block gap-6">
          <ATMTextField
            required
            type="text"
            name="timeSpent"
            label="Time Spent"
            value={values?.deploymentTime?.timeSpent as any}
            placeholder="Enter Time Spent"
            onBlur={handleBlur}
            onChange={(e) =>
              handleValidNumber(e) &&
              setFieldValue("deploymentTime.timeSpent", e.target.value)
            }
          />
          <ATMTextField
            required
            type="text"
            name="requiredMembers"
            label="Required Members"
            value={values?.deploymentTime?.requiredMembers as any}
            placeholder="Enter Required Members"
            onBlur={handleBlur}
            onChange={(e) =>
              handleValidNumber(e) &&
              setFieldValue("deploymentTime.requiredMembers", e.target.value)
            }
          />
        </div>

        {/* Demo Time */}
        <Divider />
        <h1 className="font-semibold text-[18px]">Demo Time</h1>
        <div className="md:flex block gap-6">
          <ATMTextField
            required
            type="text"
            name="timeSpent"
            label="Time Spent"
            value={values?.demoTime?.timeSpent as any}
            placeholder="Enter Time Spent"
            onBlur={handleBlur}
            onChange={(e) =>
              handleValidNumber(e) &&
              setFieldValue("demoTime.timeSpent", e.target.value)
            }
          />
          <ATMTextField
            required
            type="text"
            name="requiredMembers"
            label="Required Members"
            value={values?.demoTime?.requiredMembers as any}
            placeholder="Enter Required Members"
            onBlur={handleBlur}
            onChange={(e) =>
              handleValidNumber(e) &&
              setFieldValue("demoTime.requiredMembers", e.target.value)
            }
          />
        </div>

        {/* Demo Time */}
        <Divider />
        <h1 className="font-semibold text-[18px]">Amount</h1>
        <div className="md:flex block gap-6">
          <ATMTextField
            required
            type="text"
            name="costEst"
            label="Amount"
            value={values?.costEst as any}
            placeholder="Enter amount"
            onBlur={handleBlur}
            onChange={(e) =>
              handleValidNumber(e) && setFieldValue("costEst", e.target.value)
            }
          />
        </div>
      </div>

      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default PrepareChangeRequestForm;
