import React from "react";

// |-- External Dependencies --|
import { IconType } from "react-icons";
import { BiSearchAlt2 } from "react-icons/bi";

// |-- Internal Dependencies --|
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
// |-- Redux --|
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, useParams } from "react-router-dom";

// |-- Types --|
type ViewLayoutPropTypes = {
  leftbar?: boolean;
  infoCard?: React.ReactNode;
  actionIcons?: {
    icon: IconType;
    onClick: () => void;
  }[];
  listData?: any[];
  tabs?: {
    label: string;
    icon: IconType;
    path: string;
  }[];
  searchValue?: string;
  onSearch?: (value: string) => void;
  onClickViewMore?: () => void;
  onClickProduct?: (productId: string) => void;
  hideViewMore?: boolean;
  children?: any;
  isLoading?: boolean;
};

const JobCreationSideBarLayout = ({
  leftbar = true,
  infoCard,
  listData,
  tabs,
  searchValue = "",
  onSearch = () => {},
  onClickProduct = () => {},
  onClickViewMore,
  hideViewMore = false,
  children,
  isLoading,
}: ViewLayoutPropTypes) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <div className="h-[calc(100vh-50px)] bg-white">
        <div className="w-full flex h-[calc(100%)]">
          {/* Left Section Side Bar */}
          <div className="hidden md:block">
            {leftbar === true && (
              <div className="w-[250px] h-full flex flex-col">
                {/* Search Bar */}

                <div className="flex items-center justify-start w-full p-2 border-b">
                  <ATMInputAdormant
                    name=""
                    value={searchValue}
                    placeholder="Search here..."
                    className="h-[40px] shadow-sm border-[1px] focus:border-black w-full"
                    adormant={
                      <BiSearchAlt2 className="text-xl text-slate-400 " />
                    }
                    adormantProps={{
                      position: "start",
                      extraClasses: "bg-white border-0 ",
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />
                </div>

                {/* List */}
                <div className="overflow-auto grow">
                  <div className="p-2 flex flex-col min-w-[100%] overflow-y-auto gap-2">
                    {listData?.map((jobCreationInfo: any) => {
                      return (
                        <div
                          key={jobCreationInfo?._id}
                          className={` border-b p-2 cursor-pointer hover:bg-teal-100 flex flex-col gap-2 ${
                            jobCreationInfo?._id === id
                              ? "bg-teal-100 border border-teal-100 shadow-sm"
                              : "bg-white"
                          }`}
                          onClick={() => {
                            navigate(
                              `/job-onboard/job-creation/view/${jobCreationInfo?._id}/job-details`
                            );
                          }}
                        >
                          <div className="flex flex-col gap-1">
                            <Tooltip title={jobCreationInfo?.jobTitle}>
                              <h3 className="w-full overflow-hidden line-clamp-2 text-sm min-w-[100%] font-medium">
                                {jobCreationInfo?.jobTitle}
                              </h3>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                    {/* View more button */}
                    {hideViewMore && !isLoading && (
                      <span
                        onClick={onClickViewMore}
                        className="text-sm text-center text-black cursor-pointer hover:underline"
                      >
                        View More
                      </span>
                    )}
                    {isLoading && (
                      <div className="flex justify-center w-full">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Right Section */}
          <div className={`w-full border-r border-l h-full overflow-hidden `}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCreationSideBarLayout;
