import { useState, useEffect, ReactNode } from "react";
import EditTicketFormWrapper from "src/screens/Ticket/Edit/EditTicketFormWrapper";
// Modals
import EditTicketProblemStatement from "src/screens/Ticket/Edit/EditTicketProblemStatement";
import EditTicketProposedSolution from "src/screens/Ticket/Edit/EditTicketProposedSolution";
import EditTicketFlow from "src/screens/Ticket/Edit/EditTicketFlow";
import EditTicketTechnicalApproach from "src/screens/Ticket/Edit/EditTicketTechnicalApproach";
import EditTicketAffectedAreas from "src/screens/Ticket/Edit/EditTicketAffectedAreas";
// Redux
import { useGetSingleTicketsViaIdQuery } from "src/services/TicketServices";
import { useParams } from "react-router-dom";
import { RootState } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSingleItem } from "src/redux/slices/TicketSlice";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";

type Props = {};

const CustomSection = ({
  heading,
  value,
  formComponent,
}: {
  heading: string;
  value: string | ReactNode;
  formComponent?: any;
}) => {
  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2">
        <div className="text-base font-regular text-gray-600"> {heading} </div>
        <div
          className="text-blue-500 underline cursor-pointer text-sm"
          onClick={() => setIsOpenFormDialog(true)}
        >
          Edit
        </div>
      </div>
      <div className="flex overflow-auto max-h-[100vh]">
        {/* Adjust max-h-40 to your desired max height */}
        {value}
      </div>

      {isOpenFormDialog ? (
        <EditTicketFormWrapper
          onClose={() => setIsOpenFormDialog(false)}
          formName={heading}
        >
          {formComponent}
        </EditTicketFormWrapper>
      ) : null}
    </div>
  );
};

const TicketSummary = (props: Props) => {
  // Get the Ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  // redux state
  const { singleItem } = useSelector((state: RootState) => state.ticket);

  // dispatch
  const dispatch = useDispatch();

  // Get Single Ticket Details
  const {
    isLoading: isTicketDetailsLoading,
    isFetching: isTicketDetailsFetching,
    data: ticketData,
  } = useGetSingleTicketsViaIdQuery(TICKET_ID || "", {
    skip: !TICKET_ID,
  });

  // Set Single Ticket Details
  useEffect(() => {
    if (!isTicketDetailsLoading && !isTicketDetailsFetching) {
      dispatch(setSingleItem(ticketData?.data || []));
    }
  }, [isTicketDetailsLoading, isTicketDetailsFetching, ticketData, dispatch]);

  return (
    <div className="p-4 flex flex-col gap-6 overflow-auto">
      {/* Problem Statement */}
      <CustomSection
        heading="Problem Statement"
        value={
          <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100">
            <ATMMarkdownContainer
              markdown={singleItem[0]?.problemStatement || "-"}
            />
          </div>
        }
        formComponent={EditTicketProblemStatement}
      />

      {/* Proposed Solution */}
      <CustomSection
        heading="Proposed Solution"
        value={
          <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100">
            <ATMMarkdownContainer
              markdown={singleItem[0]?.problemSolution || "-"}
            />
          </div>
        }
        formComponent={EditTicketProposedSolution}
      />

      {/* Flow */}
      <CustomSection
        heading="Flow"
        value={
          <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100">
            <ATMMarkdownContainer markdown={singleItem[0]?.flow || "-"} />
          </div>
        }
        formComponent={EditTicketFlow}
      />

      {/* Technical Approach */}
      <CustomSection
        heading="Technical Approach"
        value={
          <div className="bg-gray-50 p-2 w-full rounded-md border border-gray-100">
            <ATMMarkdownContainer
              markdown={singleItem[0]?.technicalApproach || "-"}
            />
          </div>
        }
        formComponent={EditTicketTechnicalApproach}
      />

      <CustomSection
        heading="Affected Areas"
        formComponent={EditTicketAffectedAreas}
        value={
          singleItem[0]?.affectedAreas?.length ? (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Project</th>
                    <th>Module</th>
                    <th>Feature</th>
                  </tr>
                </thead>
                <tbody>
                  {singleItem[0]?.affectedAreas?.map((ele) => {
                    return (
                      <tr className="border-b border-gray-300 hover:bg-gray-100">
                        <td className="py-2 px-4">{ele?.projectLabel}</td>
                        <td className="py-2 px-4">{ele?.moduleLabel}</td>
                        <td className="py-2 px-4">{ele?.featureLabel}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            "-"
          )
        }
      />
    </div>
  );
};

export default TicketSummary;
