import apiSlice from "../ApiSlice";

export const expenseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get expenses with pagination
    getExpense: builder.query({
      providesTags: ["expense"],
      query: (body) => {
        return {
          url: "/expense/",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addExpense: builder.mutation({
      invalidatesTags: ["expense"],
      query: (body) => {
        return {
          url: "/expense/add",
          method: "POST",
          body,
        };
      },
    }),
    //Delete
    deleteExpense: builder.mutation({
      invalidatesTags: ["expense"],
      query: (id: any) => ({
        url: `/expense/${id}`,
        method: "DELETE",
      }),
    }),

    //Edit
    updateExpense: builder.mutation({
      invalidatesTags: ["expense"],
      query: ({ id, body }) => {
        return {
          url: `/expense/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get expense by ID
    getExpenseById: builder.query({
      providesTags: ["expense"],
      query: (id) => {
        return {
          url: `/expense/${id}`,
          method: "GET",
        };
      },
    }),
    // payment-in update
    updateExpensePaymentIn: builder.mutation({
      invalidatesTags: ["expense"],
      query: ({ body, id }) => {
        return {
          url: `/expense/payment-out/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});
export const {
  useGetExpenseQuery,
  useAddExpenseMutation,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
  useGetExpenseByIdQuery,
  useUpdateExpensePaymentInMutation
} = expenseApi;
