import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  TicketListResponse,
  SingleTicketResponseType,
} from "src/models/Ticket.model";

export type TicketSliceStateType = {
  items: TicketListResponse[] | [];
  singleItem: SingleTicketResponseType[];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  priority: string[];
  type: string[];
  status: string[];
  codioticUser: any;
  projectFilterValue: any;
  moduleFilterValue: any;
  featureFilterValue: any;
  filterBy: {
    fieldName: string;
    value: string[];
  }[];
  dateFilter: {
    start_date: string | null;
    end_date: string | null;
  };
  estimatedTimeFilter: {
    start_time: string | null;
    end_time: string | null;
  };
  doubtCount: number;
};

const initialState: TicketSliceStateType = {
  items: [],
  singleItem: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  rowsPerPage: 20,
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  priority: [],
  type: [],
  status: [],
  codioticUser: [],
  projectFilterValue: "",
  moduleFilterValue: "",
  featureFilterValue: "",
  filterBy: [
    {
      fieldName: "priority",
      value: [],
    },
    {
      fieldName: "type",
      value: [],
    },
    {
      fieldName: "status",
      value: [],
    },
    {
      fieldName: "createdBy",
      value: [],
    },
    {
      fieldName: "project",
      value: [],
    },
  ],
  dateFilter: {
    start_date: null,
    end_date: null,
  },
  estimatedTimeFilter: {
    start_time: null,
    end_time: null,
  },
  doubtCount: 0,
};

const doubtSlice: Slice<TicketSliceStateType> = createSlice({
  name: "doubtSlice",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setSingleItem: (
      state,
      action: PayloadAction<SingleTicketResponseType[] | []>
    ) => {
      state.singleItem = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string[] }[]>
    ) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setDateFilter: (
      state,
      action: PayloadAction<{ start_date: string; end_date: string }>
    ) => {
      state.dateFilter = action.payload;
    },
    setCodioticUserByFilter: (state, action: PayloadAction<string[]>) => {
      state.codioticUser = action.payload;
    },
    setProjectValueFilter: (state, action: PayloadAction<string[]>) => {
      state.projectFilterValue = action.payload;
    },
    setModuleValueFilter: (state, action: PayloadAction<string[]>) => {
      state.moduleFilterValue = action.payload;
    },
    setFeatureValueFilter: (state, action: PayloadAction<string[]>) => {
      state.featureFilterValue = action.payload;
    },
    setDoubtCount: (state, action: PayloadAction<number>) => {
      state.doubtCount = action.payload;
    },
  },
});

export const {
  setItems,
  setSingleItem,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setPriorityFilter,
  setTypeFilter,
  setStatusFilter,
  setCodioticUserByFilter,
  setProjectsFilter,
  setEstimatedTimeFilter,
  setFilterBy,
  setProjectValueFilter,
  setModuleValueFilter,
  setFeatureValueFilter,
  setDoubtCount,
} = doubtSlice.actions;
export default doubtSlice.reducer;
