import { ErrorMessage } from "formik";
import React from "react";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { twMerge } from "tailwind-merge";

export type ATMTextFieldPropTypes = {
  name: string;
  value: string | string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  size?: "small" | "medium" | "large";
  type?: any;
  isShowErrorMessage?: boolean;
} & Omit<React.ComponentProps<"input">, "size">;

const ATMTextField = ({
  name,
  value,
  className = "bg-white rounded-lg",
  onChange,
  label,
  required,
  size = "small",
  type = "text",
  isShowErrorMessage = true,
  ...rest
}: ATMTextFieldPropTypes) => {
  return (
    <div className="relative ">
      <div className="flex flex-col">
        {label && (
          <label className="text-sm font-medium">
            {" "}
            {label} {required && <span className="text-red-500"> * </span>}{" "}
          </label>
        )}
        <input
          type={type}
          name={name}
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          className={twMerge(
            `outline-blue-8 ${getInputHeight(
              size
            )} px-2 border border-gray-8   ${label && "mt-1"}  ${className}`
          )}
          {...rest}
        />
      </div>
      {name && isShowErrorMessage && (
        <ErrorMessage name={name}>
          {(errMsg) => (
            <p className="absolute mt-0 text-xs text-red-500 text-start">
              {" "}
              {errMsg}{" "}
            </p>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ATMTextField;