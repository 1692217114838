import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { array, object, string } from "yup";
import SalaryConfigureDialogForm from "./SalaryConfigureDialogForm";
import {
  useUpdateSalaryConfigMutation,
  useGetSalaryConfigQuery,
} from "src/services/TeamServices";
import { showToast } from "src/utils/showToaster";
import moment from "moment";
import { CircularProgress } from "@mui/material";

export type SalaryConfigureDialogFormValues = {
  effectiveDate: any;
  earning: {
    fieldName: string;
    salary: string;
  }[];
  deducation: {
    fieldName: string;
    salary: string;
  }[];
};

type Props = {
  onClose: () => void;
  selectedTeamMemberId: string;
};

const SalaryConfigureDialogFormWrapper = ({
  onClose,
  selectedTeamMemberId,
}: Props) => {
  const [configData, setConfigData] = useState<any>();

  const [updateSalaryConfig] = useUpdateSalaryConfigMutation();
  const { data, isLoading, isFetching } =
    useGetSalaryConfigQuery(selectedTeamMemberId);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setConfigData(data?.data);
    }
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: SalaryConfigureDialogFormValues = {
    effectiveDate: configData?.effectiveDate,
    earning: configData?.earnings?.length
      ? configData?.earnings?.map((el: any) => {
          return {
            fieldName: el?.labelName,
            salary: el?.value,
          };
        })
      : [
          {
            fieldName: "Basic",
            salary: "",
          },
          {
            fieldName: "HRA",
            salary: "",
          },
          {
            fieldName: "DA",
            salary: "",
          },
          {
            fieldName: "TA",
            salary: "",
          },
        ],
    deducation: configData?.deductions?.length
      ? configData?.deductions?.map((el: any) => {
          return {
            fieldName: el?.labelName,
            salary: el?.value,
          };
        })
      : [
          {
            fieldName: "PT",
            salary: "",
          },
          {
            fieldName: "Medical",
            salary: "",
          },
          {
            fieldName: "LOP",
            salary: "",
          },
        ],
  };

  // Validation Schema
  const validationSchema = object().shape({
    earning: array().of(
      object().shape({
        fieldName: string().required("Please enter name"),
        salary: string().required("Please enter salary"),
      })
    ),
    effectiveDate: string().required("Please select  date"),
    deducation: array().of(
      object().shape({
        fieldName: string().required("Please enter name"),
        salary: string().required("Please enter salary"),
      })
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: SalaryConfigureDialogFormValues,
    { setSubmitting, resetForm }: FormikHelpers<SalaryConfigureDialogFormValues>
  ) => {
    const formattedValues = {
      teamMemberId: selectedTeamMemberId,
      effectiveDate: values?.effectiveDate
        ? moment(values?.effectiveDate).format("yyyy-MM-DD")
        : "",
      earnings: values?.earning?.map((el: any) => {
        return {
          labelName: el?.fieldName,
          value: el?.salary,
        };
      }),
      deductions: values?.deducation?.map((el: any) => {
        return {
          labelName: el?.fieldName,
          value: el?.salary,
        };
      }),
    };
    updateSalaryConfig(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <SalaryConfigureDialogForm
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SalaryConfigureDialogFormWrapper;
