import apiSlice from "./ApiSlice";

export const accountsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllAccounts: builder.query({
      providesTags: ["accounts"],
      query: () => {
        return {
          url: "/accounts",
          method: "GET",
        };
      },
    }),
    // Get Accounts with pagination
    getAccounts: builder.query({
      providesTags: ["accounts"],
      query: (body) => {
        return {
          url: "/accounts",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addAccounts: builder.mutation({
      invalidatesTags: ["accounts"],
      query: (body) => {
        return {
          url: "/accounts/add",
          method: "POST",
          body,
        };
      },
    }),

    deleteAccounts: builder.mutation({
      invalidatesTags: ["accounts"],
      query: (id: any) => ({
        url: `/accounts/${id}`,
        method: "DELETE",
      }),
    }),


    //Edit
    updateAccounts: builder.mutation({
      invalidatesTags: ["accounts"],
      query: ({ id, body }) => {
        return {
          url: `/accounts/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get Accounts by ID
    getAccountsById: builder.query({
      providesTags: ["accounts"],
      query: (id) => {
        return {
          url: `/accounts/${id}`,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useGetAllAccountsQuery,
  useGetAccountsQuery,
  useAddAccountsMutation,
  useUpdateAccountsMutation,
  useDeleteAccountsMutation,
  useGetAccountsByIdQuery
} = accountsApi;
