import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setDateFilter,
  setFilterTicketGroup
} from "src/redux/slices/TicketGroupSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { useGetAllTagsQuery } from "src/services/TicketServices";
import TicketListFilterFormDialog from "./TicketGroupListFilterFormDialog";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  startDate: string | null;
  endDate: string | null;
  priority: string[];
  type: string[];
  tags: any;
  tagsNot: any;
  status: string[];
  project: string[];
  createdBy: string[];
  resosurceRequired: boolean;
  clientSideTicketStatus: any;
  changeRequestId: any;
  isArchived: boolean;
};

const TicketGroupListFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const ticketState = useSelector((state: RootState) => state.ticketGroup);
  const {
    dateFilter,
    filterTicketGroup
  } = ticketState;
  const { data: AllTagsData, isFetching: AllTagsDataIsFetching, isLoading: AllTagsDataIsLoading } = useGetAllTagsQuery("");
  const tagsOptions = AllTagsData?.data?.map((el: any) => {
    return {
      label: el?.tagName,
      value: el?.tagName,
    };
  }); 

  const initialValues: FormInitialValues = {
    startDate: dateFilter.start_date,
    endDate: dateFilter.end_date,
    priority: filterTicketGroup?.priority,
    changeRequestId: filterTicketGroup?.changeRequestId,
    type: filterTicketGroup?.type,
    tags: filterTicketGroup?.tags,
    tagsNot: filterTicketGroup?.tagsNot,
    status: filterTicketGroup?.status,
    project: filterTicketGroup?.projects,
    createdBy: filterTicketGroup?.createdBy,
    clientSideTicketStatus: filterTicketGroup?.clientSideTicketStatusFilterValue,
    isArchived: filterTicketGroup?.isArchived,
    resosurceRequired: filterTicketGroup?.resosurceRequired,
  };


  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    // created date from to dispatch
    await dispatch(
      setDateFilter({
        start_date: values?.startDate
          ? moment(values?.startDate)?.format("yyyy-MM-DD")
          : "",
        end_date: values?.endDate
          ? moment(values?.endDate)?.format("yyyy-MM-DD")
          : "",
      })
    );
    await dispatch(setFilterTicketGroup({
      priority: values?.priority || [],
      type: values?.type || [],
      status: values?.status || [],
      tags: values?.tags || [],
      tagsNot: values?.tagsNot || [],
      createdBy: values?.createdBy || [],
      projects: values?.project || [],
      clientSideTicketStatusFilterValue: values?.clientSideTicketStatus || [],
      changeRequestId: values?.changeRequestId || [],
      isArchived: values?.isArchived,
      resosurceRequired: values?.resosurceRequired,
    }))

    setSubmitting(false);
    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        start_date: null,
        end_date: null,
      })
    );

    await dispatch(setFilterTicketGroup({
      priority: [],
      type: [],
      status: [],
      tags: [],
      tagsNot: [],
      createdBy: [],
      projects: [],
      clientSideTicketStatusFilterValue: [],
      changeRequestId: [],
      isArchived: false,
      resosurceRequired: false,
    }))

    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <TicketListFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            tagsOptions={tagsOptions}
            tagsIsLoading={AllTagsDataIsFetching || AllTagsDataIsLoading}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TicketGroupListFilterFormDialogWrapper;
