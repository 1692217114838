import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import AddCommentForm from "./AddCommentForm";
import { useChangeAssigneeOnDoubtMutation } from "src/services/DoubtsServices";
import { useParams } from "react-router-dom";

type Props = {
  currentAssignee: any;
};

export type AddCommentFormValues = {
  assignee?: "";
  comment?: "";
};

const AddCommentFormWrapper = ({ currentAssignee }: Props) => {
  // Doubt Id from params
  const { id: DOUBT_ID } = useParams();
  const [changeAssigneeOnDoubt] = useChangeAssigneeOnDoubtMutation();

  // Form Initial Values
  const initialValues: AddCommentFormValues = {
    assignee: currentAssignee?.value,
    comment: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    comment: string(),
  });

  // Handle Submit
  const handleSubmit = (
    values: AddCommentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AddCommentFormValues>
  ) => {
    changeAssigneeOnDoubt({
      id: DOUBT_ID,
      body: {
        assignedTo: values.assignee,
        comment: values.comment,
      },
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", "Added Successfully");
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AddCommentForm formikProps={formikProps} />
        </Form>
      )}
    </Formik>
  );
};

export default AddCommentFormWrapper;
