import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { MeetingNoteTypeFormValues } from "src/models/MeetingTypeNotes.model";
import MeetingTypeNoteForm from "../Layouts/MeetingTypeNoteForm";
import { useAddMeetingNoteTypeMutation } from "src/services/MeetingTypeNoteService";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
};

const AddMeetingNoteTypeWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addMeetingNoteType] = useAddMeetingNoteTypeMutation();

  // Form Initial Values
  const initialValues: MeetingNoteTypeFormValues = {
    meetingNoteType: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    meetingNoteType: string().required("Please enter meeting note type"),
  });

  // Handle Submit
  const handleSubmit = (
    values: MeetingNoteTypeFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingNoteTypeFormValues>
  ) => {
    addMeetingNoteType(values).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <MeetingTypeNoteForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddMeetingNoteTypeWrapper;
