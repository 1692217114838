import apiSlice from "./ApiSlice";

export const stateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    getAllCitiesByStateId: builder.query({
      query: (stateId: string) => {
        return {
          url: stateId ? `/city?stateId=${stateId}` : `/city`,
          method: "GET",
        };
      },
    }),

    // GET
    getCity: builder.query({
      providesTags: ["city"],
      query: (body) => ({
        url: "/city",
        method: "POST",
        body,
      }),
    }),

    // ADD CITY
    addCity: builder.mutation({
      invalidatesTags: ["city"],
      query: (body) => ({
        url: "/city/add",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllCitiesByStateIdQuery,
  useGetCityQuery,
  useAddCityMutation,
} = stateApi;
