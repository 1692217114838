import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { EditServerFormValues } from "src/models/Server.model";
import {
  useGetServerByIdQuery,
  useUpdateServerByIdMutation,
} from "src/services/ServerService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import EditServerForm from "./EditServerForm";

type Props = {
  selectedServer: string;
  onClose: () => void;
};

const LabelType = [
  {
    label: "UAT",
    value: "UAT",
  },
  {
    label: "CLIENT",
    value: "CLIENT",
  },
];

const ServerType = [
  {
    label: "Web",
    value: "WEB",
  },
  {
    label: "Admin",
    value: "ADMIN",
  },
  {
    label: "API",
    value: "API",
  },
];

const ServiceType = [
  {
    label: "Redis",
    value: "REDIS",
  },
  {
    label: "MongoDB",
    value: "MONGODB",
  },
];
const EditServerFormWrapper = ({ selectedServer, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetServerByIdQuery(selectedServer);
  const [updateGitRepo] = useUpdateServerByIdMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: EditServerFormValues = {
    serverName: items?.serverName || "",
    serverConfig: items?.serverConfig || "",
    label: { label: items?.label, value: items?.label },
    projectId: items?.projectId || [],
    serverCredentials: (items?.serverCredentials || [])?.map((item: any) => ({
      type:
        ServerType?.find((serverType) => serverType?.value === item?.type) ||
        null,
      userName: item?.userName || "",
      password: item?.password || "",
      remark: item?.remark || "",
    })),
    service: (items?.service || [])?.map((item: any) => ({
      type:
        ServiceType?.find((ServiceType) => ServiceType?.value === item?.type) ||
        null,
      port: item?.port || 0,
      remark: item?.remark || "",
    })),
  };

  // Validation Schema
  const validationSchema = object().shape({
    serverName: string().required("Please enter repo  name."),
    serverConfig: string().required("Please enter repo url."),
    serverCredentials: array().of(
      object().shape({
        type: object().required("Server Credential Type is required"),
        userName: string().required("Please enter the username"),
        password: string().required("Please enter the password"),
        remark: string().required("Please enter remark"),
      })
    ),
    service: array().of(
      object().shape({
        remark: string().required("Please enter the remark"),
        type: object().required(" Service type is required"),
      })
    ),
    projectId: array()
      .test("nonEmpty", "Please enter at least one project", function (value) {
        return value && value.length > 0;
      })
      .of(
        object().shape({
          _id: string().required("required"),
          projectName: string().required("Please enter field type"),
        })
      ),
  });

  // Handle Submit
  const handleSubmit = (
    values: EditServerFormValues,
    { setSubmitting, resetForm }: FormikHelpers<EditServerFormValues>
  ) => {
    const { projectId, serverCredentials, label, service, ...rest } = values;
    const formattedValues = {
      ...rest,
      label: label?.value,
      projectId: (projectId || [])?.map((project: any) => project?._id),
      serverCredentials: (serverCredentials || [])?.map(
        (serverCredential: any) => ({
          password: serverCredential?.password,
          userName: serverCredential?.userName,
          remark: serverCredential?.remark,
          type: serverCredential?.type?.value,
        })
      ),
      service: (service || [])?.map((service: any) => ({
        port: service?.port || 0,
        remark: service?.remark,
        type: service?.type?.value,
      })),
    };
    updateGitRepo({ id: selectedServer, body: formattedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <EditServerForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
            LabelType={LabelType}
            ServerType={ServerType}
            ServiceType={ServiceType}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditServerFormWrapper;
