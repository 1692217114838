import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { CurrencyFormValues } from "src/models/Currency.model";
import {
  useGetSingleCurrencyQuery,
  useUpdateCurrencyMutation,
} from "src/services/CurrencyServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import CurrencyForm from "../Layouts/CurrencyForm";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  selectedSocialMediaId: any;
};

const EditCurrencyFormWrapper = ({ onClose, selectedSocialMediaId }: Props) => {
  const [currencyData, setCurrencyData] = useState<any>({});
  const { isLoading, isFetching, data } = useGetSingleCurrencyQuery(
    selectedSocialMediaId,
    {
      skip: !selectedSocialMediaId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCurrencyData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const [updateCurrency] = useUpdateCurrencyMutation();

  // Form Initial Values
  const initialValues: CurrencyFormValues = {
    currencyName: currencyData?.currencyName || "",
    currencySymbol: currencyData?.currencySymbol || "",
    currencyValueInINR: currencyData?.currencyValueInINR || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    currencyName: string().required("Please enter currency name"),
    currencySymbol: string(),
    currencyValueInINR: string().required("Please enter currency value in INR"),
  });

  // Handle Submit
  const handleSubmit = (
    values: CurrencyFormValues,
    { setSubmitting, resetForm }: FormikHelpers<CurrencyFormValues>
  ) => {
    updateCurrency({ id: selectedSocialMediaId, body: values }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <CurrencyForm
            formikProps={formikProps}
            onClose={onClose}
            formType="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditCurrencyFormWrapper;
