import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { PaymentModeFormValues } from "src/models/PaymentMode.model";

type Props = {
  formikProps: FormikProps<PaymentModeFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const PaymentModeForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const formHeading =
    formType === "ADD" ? "Add Payment Mode" : "Update Payment Mode";
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  return (
    <div className="flex flex-col gap-6 p-3">
      <div className="flex items-center justify-between">
        <div className="text-xl font-medium"> {formHeading} </div>
        <button
          type="button"
          className="p-2 rounded hover:bg-hover"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Organisation Name */}
        <div>
          <ATMTextField
            required
            name="modeName"
            value={values.modeName}
            onChange={(e) => setFieldValue("modeName", e.target.value)}
            label="Payment Mode"
            placeholder="Enter Payment Mode"
            onBlur={handleBlur}
          />
        </div>
        {formType === "ADD" && (
          <div className="mt-2">
            <ATMCheckbox
              label="Save and Next"
              checked={saveNextChecked}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        )}

        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default PaymentModeForm;
