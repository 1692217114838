import { Skeleton } from "@mui/material";
import { FormikProps } from "formik";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import {
  setTicketOptionSearchValue
} from "src/redux/slices/TicketGroupSlice";
import { RootState } from "src/redux/store";
import { AddTicketFormValues } from "./AddTicketFormWrapper";
type Props = {
  formikProps: FormikProps<AddTicketFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  estimatedData?: any;
  ticketOptions? :  any
};

const AddTicketForm = ({
  formikProps,
  onClose,
  formType = "ADD",
  estimatedData,
  ticketOptions
}: Props) => {
  const dispatch = useDispatch()

  const { values, setFieldValue, isSubmitting } = formikProps;

    // Redux State
    const { ticketOptionSearchValue, isTicketTableLoading} =  useSelector((state: RootState) => state.ticketGroup);

  const toggleTicketSelection = (ticketId: any) => {
    const isSelected = values?.tickets.includes(ticketId);

    if (isSelected) {
      setFieldValue("tickets", values?.tickets?.filter((id: any) => id !== ticketId));
    } else {
      setFieldValue("tickets", [...values?.tickets, ticketId]);
    }
  };
  
  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> Add Ticket</div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>

      <div className="flex flex-col gap-4">
        <div className="w-[300px]">
          <ATMInputAdormant
            name=""
            value={ticketOptionSearchValue}
            onChange={(e) => dispatch(setTicketOptionSearchValue(e.target.value))}
            adormant={<BiSearch />}
            adormantProps={{
              position: 'start',
              extraClasses: 'bg-white border-0',
            }}
            inputProps={{ className: 'bg-white' }}
            placeholder="Search..."
          />
        </div>
        <div className="h-[350px] overflow-scroll border rounded p-2">
          {isTicketTableLoading ?
            <div className="flex flex-col md:px-2 py-1">
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
            </div> :
            <div>
              {ticketOptions?.length ? ticketOptions?.map((ticket: any) => (
                <div className="border-b my-1" key={ticket._id}>
                  <ATMCheckbox
                    id={ticket._id}
                    checked={values.tickets.includes(ticket._id)}
                    onChange={() => toggleTicketSelection(ticket._id)}
                    label={
                      <div>
                        {/* <Tooltip title={ticket.title}> */}
                          <h3 className="w-full line-clamp-2 text-md  font-medium">
                            {ticket.title}
                          </h3>
                        {/* </Tooltip> */}
                      </div>
                    }
                  />
                </div>
              )) : 
              <div className="flex justify-center my-2"> No Ticket Found! </div>
              }
            </div>
          }
        </div>
      </div>
      
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default AddTicketForm;
