import { FormikProps } from "formik";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { ShortListApplicationFormValue } from "./UpdateJobApplicationStatusWrapper";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";

type Props = {
  handleClose: () => void;
  isSubmitting: boolean;
  formikProps: FormikProps<ShortListApplicationFormValue>;
  heading: string;
};
const UpdateJobApplicationStatus = ({
  handleClose,
  isSubmitting,
  formikProps,
  heading,
}: Props) => {
  const { values, setFieldValue } = formikProps;
  return (
    <MOLFormDialog
      title={heading}
      onClose={handleClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        {/* remark */}
        <div>
          <ATMTextArea
            required
            name="remark"
            value={values.remark}
            onChange={(value) => {
              setFieldValue("remark", value);
            }}
            label="Remark"
            placeholder="Enter remark"
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default UpdateJobApplicationStatus;
