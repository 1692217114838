// |-- Built-in Dependencies --|
import React from "react";
import { FormikProps } from "formik";

// |-- Internal Dependencies --|
import ATMFilePickerWrapper from "src/components/UI/atoms/formFields/ATMFileUploader/ATMFileUploaderWrapper";
import { FormInitialValues } from "./AddCsvFileWrapper";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

// |-- Types --|
type Props = {
  onClose: () => void;
  formikProps: FormikProps<FormInitialValues>;
};

const CsvBulkUploadForm = ({ onClose, formikProps }: Props) => {
  const {
    values,
    setFieldValue,
    // handleBlur,
    isSubmitting,
    handleSubmit,
  } = formikProps;

  return (
    <MOLFormDialog
      title={"Add Leads"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
      handleSubmit={handleSubmit}
    >
      <ATMFilePickerWrapper
        accept="*"
        name={`files`}
        selectedFile={values.files}
        label="CSV File"
        placeholder="select csv file"
        onSelect={(imgUrl) => {
          setFieldValue(`files`, imgUrl);
        }}
      />
    </MOLFormDialog>
  );
};

export default CsvBulkUploadForm;
