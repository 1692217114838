import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import ATMLoadingButton from 'src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton';
import ATMDatePicker from 'src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker';
import ATMSelect from 'src/components/UI/atoms/formFields/ATMSelect/ATMSelect';
import ATMTextField from 'src/components/UI/atoms/formFields/ATMTextField/ATMTextField';
import SideNavLayout from 'src/components/layouts/SideNavLayout/SideNavLayout';
import { useGetAllProjects } from 'src/hooks/useGetAllProject';
import { useGetAllNeetingType } from 'src/hooks/useMeetingType';
import { MeetingTypeListResponse } from 'src/models/MeetingType.model';

type Props = {
  formikProps: FormikProps<any>;
  formType: "Add" | "Edit";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};
export const meetingWithOptions = [
  {
    label: "Internal Team",
    value: "INTERNAL_TEAM",
  },
  {
    label: "Client",
    value: "CLIENT",
  },
  {
    label: "Internal Team (Third Party)",
    value: "THIRD_PARTY_WITH_INTERNAL_TEAM",
  },
  {
    label: "Client (Third Party)",
    value: "THIRD_PARTY_WITH_CLIENT",
  },
]
export const communicationTypeOptions = [
  {
    label: "CALL",
    value: "CALL",
  },
  {
    label: "EMAIL",
    value: "EMAIL",
  },
  {
    label: "WHATSAPP MESSAGE",
    value: "WHATSAPP MESSAGE",
  },
  {
    label: "WHATSAPP CALL",
    value: "WHATSAPP CALL",
  },
]

const AddConversation = ({ formType, formikProps }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur , handleSubmit } = formikProps;
  const [clientUserName, setClientUserName] = React.useState("");
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  // get all meetng note type hook
  const { allMeetingType, isDataLoading: isAllMeetingtypeDataLoading } =
    useGetAllNeetingType();

  return (

    <>
      <SideNavLayout>
        <div className="p-4 flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <div className="text-xl font-medium">
              {formType === "Add" ? "Add New" : "Update"} Call
            </div>
          </div>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-x-4 md:gap-y-6 gap-y-6">

            {/* Project */}
            <div className="mt-1">
              <ATMSelect
                required
                name="projectId"
                label="Project"
                placeholder="Select Project"
                value={values.projectId}
                isLoading={isAllProjectsDataLoading}
                options={allProjects?.map((ele: any) => {
                  return {
                    label: ele?.projectName,
                    value: ele?._id,
                  };
                })}
                onChange={(newValue) => {
                  setFieldValue("projectId", newValue);
                }}
              />
            </div>

            <div className="mt-1">
              <ATMSelect
                required
                name="communicationType"
                label="Communication Type"
                placeholder="Communication Type"
                value={values.communicationType}
                options={communicationTypeOptions}
                onChange={(newValue) => {
                  setFieldValue("communicationType", newValue);
                }}
              />
            </div>
            <div>
              <ATMDatePicker
                required
                label="Scheduled Date"
                name="scheduledDate"
                format="DD/MM/YYYY"
                // minDate={moment().format("yyyy-MM-DD")}
                value={values.scheduledDate}
                onChange={(newValue) => setFieldValue("scheduledDate", newValue)}
              />
            </div>

           
            <div className="mb-2">
              <ATMTextField
                type='time'
                name="scheduledStartTime"
                value={moment(values.scheduledStartTime, 'HH:mm').format('HH:mm')}
                required
                label="Scheduled End Time"
                placeholder="Enter Scheduled End Time"
                className="mt-0 rounded"
                onBlur={handleBlur}
                onChange={(e) => {
                  let time = moment(e.target.value, 'HH:mm')
                  setFieldValue('scheduledStartTime', time);
                }}
              />
            </div>

            <div>
              <ATMSelect
                required
                name="meetingTypeId"
                label="Meeting Type"
                placeholder="Select Meeting Type"
                value={values.meetingTypeId}
                isLoading={isAllMeetingtypeDataLoading}
                options={allMeetingType?.map((ele: MeetingTypeListResponse) => {
                  return {
                    label: ele?.meetingType,
                    value: ele?._id,
                  };
                })}
                onChange={(newValue) => {
                  setFieldValue("meetingTypeId", newValue);
                }}
              />
            </div>


            {/* Meeting With */}
            <div className="mt-1">
              <ATMSelect
                required
                name="meetingWith"
                label="Meeting With"
                placeholder="Select Meeting With"
                value={values.meetingWith}
                options={meetingWithOptions}
                onChange={(newValue) => {
                  setFieldValue("meetingWith", newValue);
                }}
              />
            </div>



            {values.meetingWith?.value === "CLIENT" || values?.meetingWith?.value === "THIRD_PARTY_WITH_CLIENT" ? (
              <>
                <div className="-mt-1 flex items-center gap-x-2">
                  <ATMTextField
                    name=""
                    label="Client User"
                    placeholder="Select Client User"
                    value={clientUserName}
                    onChange={(e) => {
                      setClientUserName(e?.target?.value);
                    }} 
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Trigger the onChange callback or perform any other action
                        setFieldValue("clientUsers", [
                          ...values.clientUsers,
                          clientUserName,
                        ]);
                        setClientUserName("");
                        event.preventDefault();
                        return false;
                      }
                    }}
                  />

                  {/* <div
                    onClick={() => {
                      setFieldValue("clientUsers", [
                        ...values.clientUsers,
                        clientUserName,
                      ]);
                      setClientUserName("");
                    }}
                    className="bg-primary-main px-1 py-2 h-10 text-white text-sm rounded mt-7 cursor-pointer"
                  >
                    Add
                  </div> */}
                </div>

                <div>
                  {values.clientUsers.length
                    ? values.clientUsers?.map((ele: any, ind: any) => {
                      return (
                        <div className="flex gap-x-2 items-center">
                          {ind + 1} {" - "} {ele}{" "}
                          <IoIosRemoveCircleOutline
                            className="cursor-pointer"
                            onClick={() => {
                              const newFiltered = values.clientUsers?.filter(
                                (clientUserEle: any) => clientUserEle !== ele
                              );

                              // Check if newFiltered is defined before updating state
                              if (newFiltered !== undefined) {
                                setFieldValue("clientUsers", newFiltered);
                              }
                            }}

                          />
                          <br />
                        </div>
                      );
                    })
                    : null}
                </div>
              </>
            ) : null}




          </div>

          <div>
            <ATMLoadingButton isLoading={isSubmitting} type="button"  onClick={() => handleSubmit()}>
              Save
            </ATMLoadingButton>
          </div>
        </div>
      </SideNavLayout>
    </>
  )
}

export default AddConversation
