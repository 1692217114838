import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import store from "src/redux/store";

export type PermissionType = keyof typeof UserModuleNameTypes;

export const isAuthorized: (
    permission: keyof typeof UserModuleNameTypes
) => boolean | undefined = (permission) => {
    const { permissions, userData } = store.getState().auth
    if (userData?.userType === "SUPER_ADMIN") {
        return true
    }
    return permission ? permissions?.includes(UserModuleNameTypes[permission]) : true;
};