/* eslint-disable array-callback-return */
import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";

// MODELS
import { MeetingFormValues } from "src/models/Meeting.model";
import { ProjectListResponse } from "src/models/Project.model";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";

// HOOKS
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import { MdOutlineRemoveCircle } from "react-icons/md";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { useGetAllNeetingType } from "src/hooks/useMeetingType";
import { MeetingTypeListResponse } from "src/models/MeetingType.model";
import moment from "moment";

type Props = {
  formikProps: FormikProps<MeetingFormValues>;
  formType: "Add" | "Edit";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const MeetingForm = ({
  formikProps,
  formType,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur , handleSubmit} = formikProps;

  const [clientUserName, setClientUserName] = React.useState("");
  const [requiredThings, setRequiredThings] = React.useState<any>("");
  const [agenda, setAgenda] = React.useState<string>("");

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  // get all codiotic user hook
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  // get all meetng note type hook
  const { allMeetingType, isDataLoading: isAllMeetingtypeDataLoading } =
    useGetAllNeetingType();
 
  return (
    <SideNavLayout>
      <div className="p-4 flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <div className="text-xl font-medium">
            {formType === "Add" ? "Add New" : "Update"} Meeting
          </div>
        </div>
        <div className="grid md:grid-cols-3 xs:grid-cols-1 gap-x-4 md:gap-y-6 gap-y-6">
          {/* Title */}
          <div>
            <ATMTextField
              name="title"
              value={values.title}
              required
              label="Title"
              placeholder="Enter title"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue("title", e.target.value)}
            />
          </div>

          {/* Project */}
          <div className="mt-1">
            <ATMSelect
              required
              name="projectId"
              label="Project"
              placeholder="Select Project"
              value={values.projectId}
              isLoading={isAllProjectsDataLoading}
              options={allProjects?.map((ele: ProjectListResponse) => {
                return {
                  label: ele?.projectName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("projectId", newValue);
              }}
            />
          </div>

          {/* Mode */}
          <div className="mt-1">
            <ATMSelect
              required
              name="mode"
              label="Mode"
              placeholder="Select Mode"
              value={values.mode}
              isLoading={isAllProjectsDataLoading}
              options={[
                {
                  label: "Online",
                  value: "ONLINE",
                },
                {
                  label: "Offline",
                  value: "OFFLINE",
                },
              ]}
              onChange={(newValue) => {
                setFieldValue("mode", newValue);
              }}
            />
          </div>

          {/* Link || Address Accroding to Mode type */}
          {values?.mode?.value === "ONLINE" && (
            <div>
              <ATMTextField
                name="meetingLink"
                value={values.meetingLink}
                required
                label="Meeting Link"
                placeholder="Enter link"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("meetingLink", e.target.value)}
              />
            </div>
          )}
          {values?.mode?.value === "OFFLINE" && (
            <div>
              <ATMTextField
                name="address"
                value={values.address}
                required
                label="Meeting Address"
                placeholder="Enter address"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("address", e.target.value)}
              />
            </div>
          )}

          <div>
            <ATMDatePicker
              required
              label="Scheduled Date"
              name="scheduledDate"
              format="DD/MM/YYYY"
              // minDate={moment().format("yyyy-MM-DD")}
              value={values.scheduledDate}
              onChange={(newValue) => setFieldValue("scheduledDate", newValue)}
            />
          </div>

          <div className="mb-2">
            <ATMTextField
              type="time"
              name="scheduledStartTime"
              value={moment(values.scheduledStartTime, "HH:mm").format("HH:mm")}
              required
              label="Scheduled Start Time"
              placeholder="Enter Scheduled Start Time"
              className="mt-0 rounded"
              onBlur={handleBlur}
              onChange={(e) => {
                let time = moment(e.target.value, "HH:mm");
                setFieldValue("scheduledStartTime", time);
              }}
            />
          </div>

          <div className="mb-2">
            <ATMTextField
              type="time"
              name="scheduledEndTime"
              value={moment(values.scheduledEndTime, "HH:mm").format("HH:mm")}
              required
              label="Scheduled End Time"
              placeholder="Enter Scheduled End Time"
              className="mt-0 rounded"
              onBlur={handleBlur}
              onChange={(e) => {
                let time = moment(e.target.value, "HH:mm");
                setFieldValue("scheduledEndTime", time);
              }}
            />
          </div>

          {/* Meeting With */}
          <div className="mt-1">
            <ATMSelect
              required
              name="meetingWith"
              label="Meeting With"
              placeholder="Select Meeting With"
              value={values.meetingWith}
              isLoading={isAllProjectsDataLoading}
              options={[
                {
                  label: "Internal Team",
                  value: "INTERNAL_TEAM",
                },
                {
                  label: "Client",
                  value: "CLIENT",
                },
                {
                  label: "Internal Team (Third Party)",
                  value: "THIRD_PARTY_WITH_INTERNAL_TEAM",
                },
                {
                  label: "Client (Third Party)",
                  value: "THIRD_PARTY_WITH_CLIENT",
                },
              ]}
              onChange={(newValue) => {
                setFieldValue("meetingWith", newValue);
              }}
            />
          </div>

          <div>
            <ATMSelect
              required
              name="meetingTypeId"
              label="Meeting Type"
              placeholder="Select Meeting Type"
              value={values.meetingTypeId}
              isLoading={isAllMeetingtypeDataLoading}
              options={allMeetingType?.map((ele: MeetingTypeListResponse) => {
                return {
                  label: ele?.meetingType,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("meetingTypeId", newValue);
              }}
            />
          </div>

          {/* Codiotic User */}
          <div>
            <ATMMultiSelect
              name="codioticUsers"
              label="Codiotic User"
              placeholder="Select Codiotic User"
              value={values.codioticUsers}
              isLoading={isCodioticUserDataLoading}
              options={codioticUsers?.map((ele: CodioticUserListResponse) => {
                return {
                  label: ele?.name,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("codioticUsers", newValue);
              }}
            />
          </div>
          <div className="flex flex-col ">
            <div className="-mt-1 flex items-center gap-x-2">
              <ATMTextField
                name="requiredThings"
                value={requiredThings}
                label="Required Things"
                required
                placeholder="Enter required things"
                onBlur={handleBlur}
                onChange={(e) => {
                  setRequiredThings(e.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") { 
                    // Trigger the onChange callback or perform any other action
                    setFieldValue("requiredThings", [
                      ...values.requiredThings,
                      requiredThings,
                    ]); 
                    setRequiredThings("");
                    event.preventDefault();
                    return false;
                  }
                }}
              />

              {/* <div
                onClick={() => {
                  setFieldValue("requiredThings", [
                    ...values.requiredThings,
                    requiredThings,
                  ]);
                  setRequiredThings("");
                }}
                className="bg-primary-main px-1 py-2 h-10 text-white text-sm rounded mt-7 cursor-pointer"
              >
                Add
              </div> */}
            </div>
            <div className="mt-2">
              {values.requiredThings.length
                ? values.requiredThings?.map((ele: any, ind: any) => {
                    return (
                      <div key={ind}>
                        <span className="flex gap-x-2 items-center">
                          {ind + 1} {" - "} {ele}{" "}
                          <IoIosRemoveCircleOutline
                            className="cursor-pointer"
                            onClick={() => {
                              const newFilterd = values.requiredThings?.filter(
                                (requiredEle: any) => {
                                  if (requiredEle !== ele) {
                                    return requiredEle;
                                  }
                                }
                              );
                              setFieldValue("requiredThings", newFilterd);
                            }}
                          />
                        </span>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="-mt-1 flex items-center gap-x-2">
              <ATMTextField
                name=""
                label="Client User"
                placeholder="Select Client User"
                value={clientUserName}
                onChange={(e) => {
                  setClientUserName(e?.target?.value);
                }} 
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    // Trigger the onChange callback or perform any other action
                    setFieldValue("clientUsers", [
                      ...values.clientUsers,
                      clientUserName,
                    ]);
                    setClientUserName("");
                    event.preventDefault();
                    return false;
                  }
                }}
              />

              {/* <div
                onClick={() => {
                  setFieldValue("clientUsers", [
                    ...values.clientUsers,
                    clientUserName,
                  ]);
                  setClientUserName("");
                }}
                className="bg-primary-main px-1 py-2 h-10 text-white text-sm rounded mt-7 cursor-pointer"
              >
                Add
              </div> */}
            </div>
            <div>
              {values.clientUsers.length
                ? values.clientUsers?.map((ele, ind) => {
                    return (
                      <div className="flex gap-x-2 items-center">
                        {ind + 1} {" - "} {ele}{" "}
                        <IoIosRemoveCircleOutline
                          className="cursor-pointer"
                          onClick={() => {
                            const newFilterd = values.clientUsers?.filter(
                              (clientUserEle: any) => {
                                if (clientUserEle !== ele) {
                                  return clientUserEle;
                                }
                              }
                            );
                            setFieldValue("clientUsers", newFilterd);
                          }}
                        />
                        <br />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          {/* END */}
        </div>

        {/* Agenda */}
        <div className=" relative z-0 -mt-2">
          <MOLMarkdownEditor
            label="Agenda"
            // value={values.agenda}
            value={agenda}
            placeholder="Write agenda or drag your files here...."
            height={100}
            onChange={(newValue: any) => {
              setAgenda(newValue);
            }}
          />
          <div
            className="bg-primary-main px-3 py-[0.3rem] rounded text-xs font-semibold text-white absolute right-0 bottom-0 z-[50000] cursor-pointer"
            onClick={() => {
              setFieldValue("agenda", [...values.agenda, agenda]);
              setAgenda("");
            }}
          >
            Add
          </div>
        </div>
        <div className="">
          {values?.agenda?.length
            ? values?.agenda?.map((ele: any, ind: number) => {
                return (
                  <div className="flex gap-x-4 items-center">
                    <span>{ind + 1}.</span>
                    <ATMMarkdownContainer markdown={ele} />
                    <div className="ml-4">
                      <MdOutlineRemoveCircle
                        className="cursor-pointer"
                        size={16}
                        onClick={() => {
                          const newFilterd = values?.agenda?.filter(
                            (agendaEle: any) => {
                              if (agendaEle !== ele) {
                                return agendaEle;
                              }
                            }
                          );
                          setFieldValue("agenda", newFilterd);
                        }}
                      />
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        {formType === "Add" && (
          <div>
            <ATMCheckbox
              label="Start Meeting"
              checked={saveNextChecked}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        )}
        <div>
          <ATMLoadingButton isLoading={isSubmitting}   type="button"  onClick={() => handleSubmit()}>
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </SideNavLayout>
  );
};

export default MeetingForm;
