import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { ProductFormValues } from "src/models/Product.model";
import { handleValidNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<ProductFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const ProductForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Product" : "Update Product"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Organisation Name */}
        <div>
          <ATMTextField
            required
            name="productName"
            value={values.productName}
            onChange={(e) => setFieldValue("productName", e.target.value)}
            label="Product Name"
            placeholder="Enter Product Name"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMTextField
            required
            name="cost"
            value={values.cost}
            onChange={(e) =>
              handleValidNumber(e) && setFieldValue("cost", e.target.value)
            }
            label="Amount"
            placeholder="Enter Amount"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default ProductForm;
