import React from "react";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { navigation } from "../../../navigation";
import { setIsCollapsed } from "../../../redux/slices/SideNavLayoutSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import Header from "../../UI/Header/Header";
import VerticalNavBar from "../../UI/VerticalNavBar/VerticalNavBar";
import { useGetDoubtCountQuery } from "src/services/DoubtsServices";
import { useGetDeploymentRequestCountQuery } from "src/services/DeploymentRequestService";
import { setDoubtCount } from "src/redux/slices/DoubtSlice";
import { setDeploymentCount } from "src/redux/slices/DeploymentSlice";

type Props = {
  children: ReactNode;
  entityName?: ReactNode;
};

const SideNavLayout = ({ children, entityName }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const sideNavLayoutState: any = useSelector(
    (state: RootState) => state.sideNavLayout
  );

  const { isCollapsed } = sideNavLayoutState;

  const toggleCollapse = () => {
    dispatch(setIsCollapsed(!isCollapsed));
  };
  const location = useLocation();
  const currentPath = `/${location.pathname?.split("/")[1]}`;

  // showing doubt count in side nav layout
  const {
    isLoading: isDoubtCountLoading,
    isFetching: isDoubtCountFetching,
    data: doubtCount,
  } = useGetDoubtCountQuery("");

  React.useEffect(() => {
    if (!isDoubtCountLoading && !isDoubtCountFetching) {
      dispatch(setDoubtCount(doubtCount?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoubtCountLoading, isDoubtCountFetching, doubtCount]);

  // showing doubt count in side nav layout
  const {
    isLoading: isDeploymentLoading,
    isFetching: isDeploymentFetching,
    data: deploymentCount,
  } = useGetDeploymentRequestCountQuery("");

  React.useEffect(() => {
    if (!isDeploymentLoading && !isDeploymentFetching) {
      dispatch(setDeploymentCount(deploymentCount?.data));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeploymentLoading, isDeploymentFetching, deploymentCount]);

  return (
    <div
      className="relative flex w-screen h-dvh"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.altKey && e.key === "t") {
          toggleCollapse();
        }
      }}
    >
      {/* Side Navigation Bar */}
      <VerticalNavBar
        toggleCollapse={toggleCollapse}
        isCollapsed={isCollapsed}
        navigation={navigation}
        isPathEqualtoNavItem={(navItem: any) => navItem.path === currentPath}
      />

      <div
        className={`h-full flex flex-col transition-all duration-500 ${
          isCollapsed ? "w-full" : "w-full md:w-[calc(100%-250px)]"
        } `}
      >
        {/* Header */}
        <div className="h-[50px]">
          <Header
            toggleCollapse={toggleCollapse}
            isCollapsed={isCollapsed}
            entityName={entityName}
          />
        </div>

        <div className="flex-1 overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default SideNavLayout;
