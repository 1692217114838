import { useState, useEffect } from "react";
import { useGetModulesByProjectIdQuery } from "src/services/ModuleServices";

export const useGetAllModulesByProject = (projectId: string) => {
  const [modules, setModules] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetModulesByProjectIdQuery(
    projectId,
    {
      skip: !projectId,
    }
  );

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setModules(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { modules, isDataLoading };
};
