import { CircularProgress, Divider } from '@mui/material'
import { useEffect, useState } from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import ATMActionMenu from 'src/components/UI/atoms/ATMActionMenu/ATMActionMenu'
import ATMPageHeader from 'src/components/UI/atoms/ATMPageHeader/ATMPageHeader'
import ATMRenderFile from 'src/components/UI/atoms/ATMRenderFile/ATMRenderFile'
import AddDocumentFormWrapper from '../Add/AddDocumentFormWrapper'
import { isAuthorized } from 'src/utils/authorization'
import { UserModuleNameTypes } from 'src/models/UserAccess/UserAccess.model'

type Props = {
    isLoading: any,
    items: any
}

const DocumentListing = ({ isLoading, items }: Props) => {
    const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);

    const [collapsedStates, setCollapsedStates] = useState<boolean[]>([]);

    useEffect(() => {
        if (items?.documents) {
            setCollapsedStates(Array(items?.documents.length).fill(true)); // Set all items?.documents to 'false' initially
        }
    }, [items?.documents]);

    const handleToggleCollapse = (index: number) => {
        setCollapsedStates((prevStates) => {
            const newCollapsedStates = prevStates.map(
                (state, i) => (i === index ? !state : true) // Toggle the clicked item, close others
            );
            return newCollapsedStates;
        });
    };
    return (
        <>
            <div className="">
                <div className='px-4 mt-2'>
                    <div className="md:p-4 p-2">
                        <ATMPageHeader
                            pageTitle="Documents"
                            hideSearchBox
                            buttonProps={{
                                btnName: "Add New",
                                onClick: () => {
                                    setIsOpenAddFormDialog(true);
                                },
                            }}
                            hideAddButton={
                                !isAuthorized(UserModuleNameTypes.ACTION_TEAMS_VIEW_DOCUMENTS_TAB_ADD_DOCUMENTS)
                              }
                        />

                    </div>
                    <div className="h-[100vh] overflow-y-scroll overflow-x-hidden xs:px-0 md:px-0 bg-gray-50">
                        {isLoading ? (
                            <div className="flex justify-center items-center h-[400px]">
                                <CircularProgress />{" "}
                            </div>
                        ) : items?.documents?.length ? (
                            items?.documents?.map((row: any, index: any) => {
                                return isLoading ? (
                                    Array(10)
                                        .fill(0)
                                        .map((_, index) => {
                                            return (
                                                <div key={index} className="animate-pulse h-[50px] p-2">
                                                    <div className="h-full rounded"></div>
                                                </div>
                                            );
                                        })
                                ) : (
                                    <div key={index} className="px-2 md:px-4 pt-4 last:pb-4">
                                        <div className="rounded-md border max-w-[100vw] bg-white">
                                            <div
                                                onClick={() => handleToggleCollapse(index)}
                                                className={`flex justify-between items-center p-4 py-2 cursor-pointer ${collapsedStates[index] ? "" : "border-b bg-slate-100"
                                                    }`}
                                            >
                                                <div className="flex gap-4 items-center">
                                                    <button className="flex items-center rounded-full p-2 border">
                                                        {collapsedStates[index] ? (
                                                            <FaAngleDown className="" />
                                                        ) : (
                                                            <FaAngleUp className="" />
                                                        )}
                                                    </button>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm">
                                                            <span className="font-medium">
                                                                {row?.documentName}{" "}
                                                            </span>{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                                <ATMActionMenu
                                                    options={[
                                                        {
                                                            label: (
                                                                <div className="flex gap-x-2 items-center">
                                                                    <MdEdit color="#000000" /> Edit
                                                                </div>
                                                            ),
                                                            onClick: () => {
                                                                // navigate(`/requirement/${row?._id}`);
                                                            },
                                                            // acitonName: UserModuleNameTypes.ACTION_FEATURES_EDIT
                                                        },

                                                    ]}
                                                />
                                            </div>

                                            {collapsedStates[index] ? null : (
                                                <div className="p-4 flex flex-col gap-4">
                                                    <div className="">
                                                        {items?.documents.length ? (
                                                            <div className="grid grid-cols-3 gap-4 pb-4">
                                                                    {row?.documentUrl?.map((urlItem: any, urlIndex: any) => {
                                                                        return (
                                                                            <div className="h-[200px]" key={urlIndex}>
                                                                                <ATMRenderFile
                                                                                    fileInfo={urlItem} // Assuming urlItem has a property named 'url' that holds the file info
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                    )}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <Divider />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-sm">
                                Data not Found
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isOpenAddFormDialog &&
                <AddDocumentFormWrapper
                    onClose={() => {
                        setIsOpenAddFormDialog(false);
                    }}
                />
            }
        </>
    )
}

export default DocumentListing
