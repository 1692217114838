import React, { useEffect, useState } from "react";
import EmailActivitiesTab from "./EmailActivitiesTab";
import { useGetContactLeadAllLogsQuery } from "src/services/ContactService";
import { useParams } from "react-router-dom";

const EmailActivitiesTabWrapper = () => {
  const { leadId } = useParams();
  const [emailsData, setEmailsData] = useState<any>();
  const { data, isLoading, isFetching } = useGetContactLeadAllLogsQuery({
    limit: 10,
    searchValue: "",
    params: ["description"],
    page: 0,
    filterBy: [
      {
        fieldName: "leadId",
        value: leadId,
      },
      {
        fieldName: "logType",
        value: "EMAIL",
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setEmailsData(data?.data);
    }
  }, [data, isLoading, isFetching]);

  return (
    <EmailActivitiesTab
      isLoading={isLoading || isFetching}
      emailsData={emailsData}
    />
  );
};

export default EmailActivitiesTabWrapper;
