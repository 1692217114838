import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/Finance-New-Slices/RecurringIncomeSlice";
import { RootState } from "src/redux/store";
import { useRecurringIncomeListQuery } from "src/services/Finance-New-Services/RecurringIncomeService";
import RecurringIncomeListing from "./RecurringIncomeListing";

enum DaysOptionsEnum {
  "SUNDAY" = "San",
  "MONDAY" = "Mon",
  "TUESDAY" = "Tue",
  "WEDNESDAY" = "Wed",
  "THRUSDAY" = "Thr",
  "FRIDAY" = "Fri",
  "SATURDAY" = "Sat",
}

const RecurringIncomeListingWrapper = () => {
  const dispatch = useDispatch();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.recurringIncome);
  const {
    data: incomeList,
    isFetching,
    isLoading,
  } = useRecurringIncomeListQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["status", "financeCategoryName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });
  function getShortenedDays(days: string[]): string {
    return days
      .map((day) => DaysOptionsEnum[day as keyof typeof DaysOptionsEnum])
      .filter(Boolean)
      .join(" ");
  }
  const columns = [
    {
      field: "description",
      headerName: "Description",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.description || "-"}</div>,
    },
    {
      field: "amount",
      headerName: "Amount (Incl. TDS)",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.currencySymbol} {row?.amount}
        </div>
      ),
    },
    {
      field: "gstPercentage",
      headerName: "GST",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.gstApplicable ? (
            <>
              {" "}
              {row?.currency?.symbol} {(row?.amount * row?.gstPercentage) / 100}{" "}
              ({row?.gstPercentage}%)
            </>
          ) : (
            "-  "
          )}
        </div>
      ),
    },
    {
      field: "tdsAmount",
      headerName: "TDS Amt",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.currency?.symbol} {row?.tdsAmount}
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.financeCategoryName || "-"}</div>,
    },
    {
      field: "recurranceType",
      headerName: "Repeat",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.recurranceType || "-"}</div>,
    },
    {
      field: "until",
      headerName: "Until",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.until || "-"}</div>,
    },
    {
      field: "untilDate",
      headerName: "Until Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.until !== "DATE"
            ? "-"
            : moment(row?.untilDate).format("DD MMM YYYY") || "-"}
        </div>
      ),
    },
    {
      field: "untilNoOfEvents",
      headerName: "Until No. of Events",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.untilNoOfEvents || "-"}</div>,
    },
    {
      field: "recurranceDate",
      headerName: "Recurrance Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.recurranceType === "MONTHLY" || row?.recurranceType === "YEARLY"
            ? moment(row?.recurranceDate).format("DD MMM YYYY") || "-"
            : "-"}
        </div>
      ),
    },
    {
      field: "recurranceDays",
      headerName: "Recurrance Days",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.recurranceType === "WEEKLY"
            ? getShortenedDays(row?.recurranceDays) || "-"
            : "-"}
        </div>
      ),
    },
  ];
  // Setting Team Members Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(incomeList?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(incomeList?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeList, isLoading, isFetching]);
  return (
    <>
      <RecurringIncomeListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </>
  );
};

export default RecurringIncomeListingWrapper;
