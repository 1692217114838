import React, { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";

import AddDomainFormWrapper from "../Add/AddDomainFormWrapper";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const DomainList = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div>
          <ATMPageHeader
            pageTitle="Domains"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            hideAddButton={!isAuthorized(UserModuleNameTypes.ACTION_DOMAIN_ADD)}
            buttonProps={{
              btnName: "Add New",
              onClick: () => {
                setIsOpenAddFormDialog(true);
              },
            }}
          />
        </div>

        {/* Table */}
        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-col overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "h-[50px]"}
              isLoading={isTableLoading}
            />
          </div>

          <div>
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      </div>

      {isOpenAddFormDialog ? (
        <AddDomainFormWrapper onClose={() => setIsOpenAddFormDialog(false)} />
      ) : null}
    </>
  );
};

export default DomainList;
