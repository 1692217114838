import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { isMobile } from "react-device-detect";
import PaymentModeForm from "../Layout/PaymentModeForm";
import {
  useGetPaymentModeByIdQuery,
  useUpdatePaymentModeMutation,
} from "src/services/Finance-New-Services/PaymentModeServices";
import { PaymentModeFormValues } from "src/models/PaymentMode.model";

type Props = {
  paymentId: string;
  onClose: () => void;
};

const EditPaymentModeWrapper = ({ paymentId, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetPaymentModeByIdQuery(paymentId);

  const [updatePaymentMode] = useUpdatePaymentModeMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: PaymentModeFormValues = {
    modeName: items?.modeName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    modeName: string().required("Please enter payment mode"),
  });

  const handleSubmit = (
    values: PaymentModeFormValues,
    { setSubmitting, resetForm }: FormikHelpers<PaymentModeFormValues>
  ) => {
    const formattedValues = {
      modeName: values?.modeName,
    };
    updatePaymentMode({
      id: paymentId,
      body: formattedValues,
    }).then((res: any) => {
      updatePaymentMode({
        id: paymentId,
        body: formattedValues,
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
    });
  };

  return (
    <Dialog open maxWidth="sm" fullWidth fullScreen={isMobile}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <PaymentModeForm
              formType="EDIT"
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditPaymentModeWrapper;
