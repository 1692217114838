import React from "react";
import Dialog from "@mui/material/Dialog";
import MarkAsDoneModal from "./MarkAsDoneModal";
import { number, object, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { useMarkAsDoneMutation } from "src/services/TicketServices";

type Props = {
  ticketId: string;
  open: boolean;
  handleClose: () => void;
};

export type FormInitialValues = {
  numberOfDeveloper: string;
  timeTaken: string;
  remark: string;
  changeLog: string;
};

const MarkAsDoneModalWrapper = ({
  ticketId,
  open = false,
  handleClose,
}: Props) => {
  // user options

  const [markAsDoneTicket] = useMarkAsDoneMutation();

  // Form Initial Values
  const initialValues: FormInitialValues = {
    numberOfDeveloper: "",
    timeTaken: "",
    remark: "",
    changeLog: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    numberOfDeveloper: number().required(
      "Please enter developers involved number"
    ),
    timeTaken: string().required("Please enter time"),
    remark: string(),
    changeLog: string(),
  });

  const handleSubmit = (
    values: FormInitialValues,
    { setSubmitting, resetForm }: FormikHelpers<FormInitialValues>
  ) => {
    markAsDoneTicket({
      ticketId: ticketId,
      body: values,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <MarkAsDoneModal
              formikProps={formikProps}
              handleClose={handleClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default MarkAsDoneModalWrapper;
