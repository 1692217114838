import React from "react";
import {
  Panel,
  useReactFlow,
  getViewportForBounds,
  getNodesBounds,
} from "reactflow";
import { toPng } from "html-to-image";
import { MdOutlineFileDownload } from "react-icons/md";


function downloadImage(dataUrl: string) {
  const a = document.createElement("a");

  a.setAttribute("download", "reactflow.png");
  a.setAttribute("href", dataUrl);
  a.click();
}

const imageWidth = 1500;
const imageHeight = 768;

const ATMDownloadFlow =()=> {
  const { getNodes } = useReactFlow();
  const onClick = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getNodesBounds(getNodes());
    const transform: any = getViewportForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2
    );

    toPng(document.querySelector(".react-flow__viewport") as HTMLElement, {
      backgroundColor: "#1a365d",
      width: imageWidth,
      height: imageHeight,
      style: {
        // width: imageWidth,
        // height: imageHeight,
        transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.zoom})`,
      },
    }).then(downloadImage);
  };

  return (
    <Panel position="top-right">
      <button className="download-btn bg-sky-500 p-2 text-white font-medium" onClick={onClick} >
     <span className="flex gap-2 items-center"> <MdOutlineFileDownload size='1.3em'/> Download Image</span>  
      </button>
    </Panel>
  );
}

export default ATMDownloadFlow;