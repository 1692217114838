import React, { useEffect, useState } from "react";
import TicketChat from "./TicketChat";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
// Redux

import {
  // useGetAllMessagesQuery,
  useGetAllMessagesMutation,
  useSendMessageMutation,
  useEditMessageMutation,
  useUnSeenMessageUpdateMutation,
  useDeleteMessageMutation,
  usePinMessageMutation,
  useUnPinMessageMutation,
} from "src/services/ChatServices";
import { setItems } from "src/redux/slices/ChatSlice";

type Props = {};

const TicketChatWrapper = (props: Props) => {
  const [chatInputValue, setChatInputValue] = useState<string>("");
  const [isEditedState, setIsEditedState] = useState<boolean>(false);
  const [editedMessageId, setEditedMessageId] = useState<string>("");
  const [isDeleted, setIsDeleted] = useState(false); // State to track deleted messages
  const [isReply, setIsReply] = useState(false); // State to track deleted messages
  const [isPin, setIsPin] = useState(false);
  const [isVisibleToClient, setIsVisibleToClient] = useState<boolean>(true);

  // URL
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  // Get the login user information from localStorage
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  const { items } = useSelector((state: RootState) => state.chat);

  const dispatch = useDispatch();

  // API
  const [getAllMessagge] = useGetAllMessagesMutation();

  // ADD MESSAGE
  const [sendChatMessage, sendMessageInfo] = useSendMessageMutation();
  const [editSentMessage] = useEditMessageMutation();

  //
  const [updateUnSeenMessage] = useUnSeenMessageUpdateMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const [pinMessage] = usePinMessageMutation();
  const [unPinMessage] = useUnPinMessageMutation();

  const [replyMessageId, setReplyMessageId] = useState<string | null>(null);
  const [replyMessageText, setReplyMessageText] = useState<string>("");
  const [replyMessageLabel, setReplyMessageLabel] = useState<string>("");
  const [
    onPinClick,
    // setOnPinClick
  ] = useState(false);

  // Set the all chat's when component mounted
  useEffect(() => {
    getAllMessagge(TICKET_ID || "")
      .then((res: any) => {
        dispatch(setItems(res?.data?.data || []));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [TICKET_ID, getAllMessagge, dispatch]);

  // Use isDeleted state to refetch messages when a message is deleted
  useEffect(() => {
    if (isDeleted || isReply || isPin) {
      getAllMessagge(TICKET_ID || "")
        .then((res: any) => {
          dispatch(setItems(res?.data?.data || []));
        })
        .catch((err: any) => {
          console.error(err);
        });

      // Set isDeleted back to false after refetching
      setIsDeleted(false);
      setIsReply(false);
      setIsPin(false);
    }
  }, [isDeleted, isPin, isReply, TICKET_ID, getAllMessagge, dispatch]);

  // Send Message function
  const handleSendMessage = () => {
    if (isEditedState) {
      editSentMessage({
        msgId: editedMessageId,
        body: {
          message: chatInputValue,
        },
      })
        .then((res: any) => {
          setChatInputValue("");
          setIsEditedState(false);
          const a: any[] = [...items];
          const indexOfeditedMessageObj = a?.findIndex(
            (ele) => ele?._id === editedMessageId
          );
          a?.splice(indexOfeditedMessageObj, 1, res?.data?.data);
          dispatch(setItems(a));
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      sendChatMessage({
        message: chatInputValue,
        ticketId: TICKET_ID || "",
        // userId: userData?.userId,
        messageParentId: replyMessageId,
        messageParentText: replyMessageText,
        parentMessageUserLabel: replyMessageLabel,
        isVisibleToClient: isVisibleToClient,
      })
        .then((res: any) => {
          // Create a new chat item with the current keys.
          if (replyMessageId) {
            setIsReply(true);
            setChatInputValue("");
            setReplyMessageText("");
            setReplyMessageLabel("");
            setReplyMessageId(null);
            return;
          }
          const a: any = [...items];

          // Push the new chat with the previous chat items in redux.
          a.unshift({
            _id: res?.data?.data?._id,
            message: chatInputValue,
            time: res?.data?.data?.createdAt,
            userLabel: userData?.name,
            userId: userData?.userId,
          });

          dispatch(setItems(a));

          // Reset the chat input value after all actions are performed.
          setChatInputValue("");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  // Delete Message function
  const handleDeleteMessage = (messageId: string) => {
    deleteMessage(messageId)
      .then((res) => {
        setIsDeleted(true); // Set isDeleted state to true
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  // message reply
  const handlePinMessage = (messageId: string, message: string) => {
    if (TICKET_ID) {
      pinMessage({
        messageId: messageId,
        ticketId: TICKET_ID,
        message: message,
        // pinById: userData?.userId,
      })
        .then((res) => {
          setIsPin(true);
          return res;
        })
        .catch((err) => {
          return err;
        });
    }
  };

  const handleUnPinMessage = (messagePinId: string) => {
    unPinMessage(messagePinId)
      .then((res) => {
        setIsPin(true);
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  // update seen message api when component mounted and unmounted
  useEffect(() => {
    updateUnSeenMessage({
      ticketId: TICKET_ID || "",
      userId: userData?.userId,
    })
      .then((res) => res)
      .catch((err) => {
        console.error("err", err);
      });

    return () => {
      updateUnSeenMessage({
        ticketId: TICKET_ID || "",
        userId: userData?.userId,
      })
        .then((res) => res)
        .catch((err) => {
          console.error("err", err);
        });
    };
  }, [TICKET_ID, userData?.userId, updateUnSeenMessage]);

  return (
    <div className="h-full">
      {onPinClick ? (
        <div className="absolute flex flex-col gap-2 bg-[#f5f5f5] m-3 shadow-md rounded-lg w-[20%] p-2 border">
          <div className="p-4 bg-[#ffffff] rounded-lg border">
            <div className="flex justify-between">
              <div className="text-sm mb-1 text-primary-main text-[#00A3A1]">
                Anuj joshi
              </div>
              <div className="flex justify-end">
                <span
                  className="cursor-pointer text-[14px] text-gray-600 hover:text-[#00A3A1] hover:underline"
                  onClick={() => {}}
                >
                  Un-pin
                </span>
              </div>
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure sed,
              perferendis, incidunt inventore hic, dolore labore quis provident
              officia aut odio eaque vel pariatur omnis impedit voluptate
              molestias cupiditate numquam.
            </div>
          </div>
          <div className="p-4 bg-[#ffffff] rounded-lg border">
            <div className="flex justify-between">
              <div className="text-sm mb-1 font-medium text-primary-main text-[#00A3A1]">
                Anuj joshi
              </div>
              <div className="flex justify-end">
                <span
                  className="cursor-pointer font-bold text-[14px] text-[#00A3A1]"
                  onClick={() => {}}
                >
                  Un-pin
                </span>
              </div>
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure sed,
              perferendis, incidunt inventore hic, dolore labore quis provident
              officia aut odio eaque vel pariatur omnis impedit voluptate
              molestias cupiditate numquam.
            </div>
          </div>
        </div>
      ) : null}
      <TicketChat
        chatList={items}
        chatInputValue={chatInputValue}
        isMessageSentLoading={sendMessageInfo?.isLoading}
        isVisibleToClient={isVisibleToClient}
        isMessageEditState={isEditedState}
        handleCancelEditMessage={() => {
          setIsEditedState(false);
          setChatInputValue("");
          setEditedMessageId("");
        }}
        setIsVisibleToClient={(value) => setIsVisibleToClient(value)}
        handleDeleteMessage={(messageId) => handleDeleteMessage(messageId)} // delete the message function
        handlePinMessage={(messageId: string, message: string) =>
          handlePinMessage(messageId, message)
        }
        handleUnPinMessage={(messagePinId: string) =>
          handleUnPinMessage(messagePinId)
        }
        handleChatInputChange={(e) => {
          setChatInputValue(e as any);
        }}
        handleEditMessage={(messageId: string, message: string) => {
          setEditedMessageId(messageId);
          setChatInputValue(message);
          setIsEditedState(true);
        }}
        onSubmitChat={() => {
          handleSendMessage();
        }}
        setReplyMessageId={(messageId: any) => setReplyMessageId(messageId)}
        setReplyMessageText={(messageText: any) =>
          setReplyMessageText(messageText)
        }
        setReplyMessageLabel={(messageLabel: any) =>
          setReplyMessageLabel(messageLabel)
        }
      />
    </div>
  );
};

export default TicketChatWrapper;
