import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import {
  useEditWorkspaceMutation,
  useGetWorkspaceByIdQuery,
} from "src/services/RequirementGatheringService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import { WorkspaceFormValues } from "../Add/AddWorkspaceFormWrapper";
import WorkspaceForm from "../Layouts/WorkspaceForm";

type Props = {
  moduleId: string;
  onClose: () => void;
};

const EditWorkspaceFormWrapper = ({ moduleId, onClose }: Props) => {
  const [moduleData, setModuleData] = useState<any>();

  const { isLoading, isFetching, data } = useGetWorkspaceByIdQuery(moduleId, {
    skip: !moduleId,
  });
  const [editWorkspace] = useEditWorkspaceMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setModuleData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: WorkspaceFormValues = {
    moduleName: moduleData?.workspaceName,
    clientId: {
      _id: moduleData?.clientId,
    },
  };

  // Validation Schema
  const validationSchema = object().shape({
    moduleName: string().required("Please enter workspace name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: WorkspaceFormValues,
    { setSubmitting, resetForm }: FormikHelpers<WorkspaceFormValues>
  ) => {
    const formattedValues = {
      workspaceName: values?.moduleName || "",
      clientId: values?.clientId._id || "",
    };
    editWorkspace({ id: moduleId, body: formattedValues }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <WorkspaceForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditWorkspaceFormWrapper;
