import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import HoliDayCalenderForm from "../Layouts/HoliDayCalendarForm";
import { HoliDayCalenderFormValues } from "src/models/HoliDayCalender.model";
import {
  useEditHoliDayMutation,
  useViewHoliDayQuery,
} from "src/services/HolidayCalendarServices";
import { showToast } from "src/utils/showToaster";
import { CircularProgress } from "@mui/material";
import moment from "moment";

type Props = {
  onClose: () => void;
  holidayId: string;
};

const EditHolidayCalendarFormWrapper = ({ onClose, holidayId }: Props) => {
  //STATE
  const [holidayData, setHoliDayData] = useState<any>();
  //API
  const [editHoliDayCalendar] = useEditHoliDayMutation();
  const { data, isFetching, isLoading } = useViewHoliDayQuery(holidayId);

  //USEffect
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setHoliDayData(data?.data);
    }
  }, [isFetching, isLoading, data]);

  // Form Initial Values
  const initialValues: HoliDayCalenderFormValues = {
    date: holidayData?.date,
    optional: holidayData?.optional,
    holidayName: holidayData?.holidayName,
  };

  // Validation Schema
  const validationSchema = object().shape({
    date: string().required("Please enter date"),
    optional: string().required("Required"),
    holidayName: string().required("Holiday Name is Required"),
  });

  // Handle Submit
  const handleSubmit = (
    values: HoliDayCalenderFormValues,
    { setSubmitting, resetForm }: FormikHelpers<HoliDayCalenderFormValues>
  ) => {
    const formattedValues = {
      holidayName: values?.holidayName,
      date: moment(values?.date).format("YYYY-MM-DD"),
      optional: values?.optional,
    };
    editHoliDayCalendar({
      id: holidayId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <HoliDayCalenderForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditHolidayCalendarFormWrapper;
