// |-- Built-in Dependencies --|
import React from "react";

// |-- External Dependencies --|
import { Formik } from "formik";
import { object, string } from "yup";
import Dialog from "@mui/material/Dialog";

// |-- Internal Dependencies --|
import { showToast } from "src/utils/showToaster";
import { useAddCountryMutation } from "src/services/CountryServices";
import CountryForm from "../Layout/CountryForm";
import { isMobile } from "react-device-detect";

// |-- Types --|
type Props = {
  onClose: () => void;
};

export type FormInitialValues = {
  countryName: string;
  countryCode: string;
  countryFlag: string;
  phone: string;
};
const AddCountryFormWrapper = ({ onClose }: Props) => {
  const [addCountry] = useAddCountryMutation();

  const initialValues: FormInitialValues = {
    countryName: "",
    countryCode: "",
    countryFlag: "India",
    phone: "",
  };

  const validationSchema = object({
    countryName: string().required("country name is required"),
    countryCode: string().required("country code is required"),
    countryFlag: string().required("country flag is required"),
    phone: string().required("phone number is required"),
  });

  const onSubmitHandler = (values: FormInitialValues) => {
    setTimeout(() => {
      addCountry({
        countryName: values.countryName,
        countryCode: values.countryCode,
        countryFlag: values.countryFlag,
        phone: values.phone,
      })
        .then((res: any) => {
          if (res?.data?.status) {
            showToast("success", "added successfully!");
            onClose();
          } else {
            showToast("error", res?.data?.message);
            onClose();
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }, 1000);
  };

  return (
    <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(formikProps) => {
          return <CountryForm onClose={onClose} formikProps={formikProps} />;
        }}
      </Formik>
    </Dialog>
  );
};

export default AddCountryFormWrapper;
