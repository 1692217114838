import React, { useState } from "react";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { PiNotePencilBold } from "react-icons/pi";
import { IoMdArrowDropdown } from "react-icons/io";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { showToast } from "src/utils/showToaster";
import { FaPen } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { ATMShowField } from "../../../OverView/OverView";
import AddNoteLogDialogFormWrapper from "../../../../AddNoteLogDialog/AddNoteLogDialogFormWrapper";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

type Props = {
  notesData: any;
  editorValue?: string;
  updateContactNote: any;
  isLoading: boolean;
  updateIsLoading: boolean;
};

const NoteActivitiesTab = ({
  notesData,
  updateIsLoading,
  updateContactNote,
  editorValue = "",
  isLoading,
}: Props) => {
  const { leadId } = useParams();
  const [isOpenEditNote, setIsOpenEditNote] = useState(false);
  const [wantToEditNote, setWantToEditNote] = useState(false);
  const [description, setDescription] = useState("");
  const [actionId, setActionId] = useState("");

  return (
    <div>
      <div className="flex justify-end">
        <ATMLoadingButton
          className="bg-slate-700  !h-[32px] text-white w-32  text-[14px] font-medium"
          onClick={() => setIsOpenEditNote(true)}
        >
          Create Notes
        </ATMLoadingButton>

        {isOpenEditNote && (
          <AddNoteLogDialogFormWrapper
            contactBy="NOTE"
            onClose={() => setIsOpenEditNote(false)}
          />
        )}
      </div>
      <div className="flex flex-col gap-4 h-full overflow-y-scroll mt-3">
        {isLoading ? (
          Array(6)
            .fill(0)
            .map((_, index) => {
              return (
                <div key={index} className="animate-pulse  h-[95px]">
                  <div className="bg-slate-200 h-full rounded"> </div>
                </div>
              );
            })
        ) : notesData?.length ? (
          notesData?.map((el: any, ind: any) => {
            return (
              <div className="border rounded p-2">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex gap-2 text-[13px] items-center">
                    <div className="rounded-full bg-slate-100 p-2 flex justify-center">
                      <PiNotePencilBold size="1.2em" color="#425b76" />
                    </div>
                    <span className="text-slate-500">
                      {el?.createdByName} created a{" "}
                      <span className="font-semibold">Note</span>
                    </span>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="flex items-center text-cyan-600 text-[13px] font-semibold">
                      Action <IoMdArrowDropdown />{" "}
                    </div>
                    <div className="flex gap-1">
                      <div className="text-slate-400 font-medium text-[11px]">
                        {formatedDateTimeIntoIst(el?.createdAt, "DD MMM yyyy")},
                      </div>
                      <div className="text-[11px] font-medium text-slate-400">
                        {formatedDateTimeIntoIst(el?.createdAt, "hh:mm A")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-5 gap-4 px-2">
                  <ATMShowField label="Create By" value={el?.createdByName} />
                  {el?.reminderForDate && (
                    <ATMShowField
                      label="Reminder Date"
                      value={el?.reminderForDate?.toLowerCase()}
                    />
                  )}
                  {el?.reminderOn && (
                    <ATMShowField label="Reminder On" value={el?.reminderOn} />
                  )}
                </div>
                <div className="grid grid-cols-12 gap-4 group/item hover:bg-hover items-center px-2 py-1">
                  <div className="col-span-11">
                    {wantToEditNote && actionId === el?._id ? (
                      <div>
                        <MOLMarkdownEditor
                          extraClassName="min-h-[200px] max-h-96 overflow-y-auto"
                          key={editorValue}
                          required
                          value={
                            el?.description ? el?.description : description
                          }
                          placeholder="Add Summary here...."
                          onChange={(newValue: any) => {
                            setDescription(newValue);
                          }}
                        />
                        <div className="mt-2 border-t py-2">
                          <ATMLoadingButton
                            isLoading={updateIsLoading}
                            onClick={() => {
                              updateContactNote({
                                id: actionId,
                                body: {
                                  leadId: leadId,
                                  description:
                                    description?.trim() ||
                                    el?.description?.trim(),
                                },
                              }).then((res: any) => {
                                if (res?.error) {
                                  showToast("error", res?.error?.data?.message);
                                } else {
                                  if (res?.data?.status) {
                                    showToast("success", res?.data?.message);
                                    setWantToEditNote(false);
                                  } else {
                                    showToast("error", res?.data?.message);
                                  }
                                }
                              });
                            }}
                            type="submit"
                            className="bg-slate-500  p-2 w-24 cursor-pointer  mx-4 hover:text-white"
                          >
                            Update
                          </ATMLoadingButton>
                        </div>
                      </div>
                    ) : (
                      el?.description
                    )}
                  </div>
                  <div className="invisible col-span-1  group-hover/item:visible cursor-pointer">
                    <span
                      onClick={() => {
                        setWantToEditNote(!wantToEditNote);
                        setActionId(el?._id);
                      }}
                    >
                      <FaPen size="1.2em" color="#425b76" />{" "}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex justify-center items-end h-[120px] text-red-700 font-medium text-lg">
            No Data Found
          </div>
        )}
      </div>
    </div>
  );
};

export default NoteActivitiesTab;
