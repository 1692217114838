import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type TimeAnalyticsProjectSliceStateType = {
  items: any[] | [];
  totalItems: number;
  meetingCTC: any;
  meetingGCTC: any;
  taskCTC: any;
  taskGCTC: any;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  filterBy: {
    fieldName: string;
    value: string[];
  }[];
  projectId: string,
  dateFilter: {
    startDate: string | null;
    endDate: string | null;
  };
  meetingDateFilter: {
    startDate: string | null;
    endDate: string | null;
  };
  totalPayment: any,
  projectName? : any
};

const initialState: TimeAnalyticsProjectSliceStateType = {
  items: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  meetingCTC: 0,
  meetingGCTC: 0,
  taskCTC: 0,
  taskGCTC: 0,
  rowsPerPage: 20,
  projectId: "",
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  filterBy: [
    {
      fieldName: "companyName",
      value: [],
    },
    {
      fieldName: "country",
      value: [],
    },
  ],
  dateFilter: {
    startDate: null,
    endDate: null,
  },
  meetingDateFilter: {
    startDate: null,
    endDate: null,
  },
  totalPayment: {},
  projectName :  ''
};

const timeAnalyticsProjectSlice: Slice<TimeAnalyticsProjectSliceStateType> = createSlice({
  name: "timeAnalyticsProjectSlice",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setProjectId: (state, action: PayloadAction<string>) => {
      state.projectId = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string[] }[]>
    ) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setDateFilter: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      state.dateFilter = action.payload;
    },
    setMeetingDateFilter: (state, action: PayloadAction<{ startDate: string; endDate: string }>) => {
      state.meetingDateFilter = action.payload;
    },
    setMeetingCTC: (state, action: PayloadAction<any>) => {
      state.meetingCTC = action.payload;
    },
    setMeetingGCTC: (state, action: PayloadAction<any>) => {
      state.meetingGCTC = action.payload;
    },
    setTaskCTC: (state, action: PayloadAction<any>) => {
      state.taskCTC = action.payload;
    },
    setTaskGCTC: (state, action: PayloadAction<any>) => {
      state.taskGCTC = action.payload;
    },
    setTotalPayment: (state, action: PayloadAction<any>) => {
      state.totalPayment = action.payload;
    },
    setProjectName: (state, action: PayloadAction<any>) => {
      state.projectName = action.payload;
    },
  },
});

export const {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setProjectId,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setFilterBy,
  setMeetingCTC,
  setMeetingGCTC,
  setTaskCTC,
  setTaskGCTC,
  setMeetingDateFilter,
  setTotalPayment,
  setProjectName
} = timeAnalyticsProjectSlice.actions;
export default timeAnalyticsProjectSlice.reducer;
