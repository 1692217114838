import React, { useState, useEffect } from "react";

const TimerComponent = ({ createdAt }: any) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    // Parse the createdAt string to a Date object
    const createDate = new Date(createdAt);

    // Calculate the time difference in seconds
    const initialTimeDifference = Math.floor(
      (Date.now() - createDate.getTime()) / 1000
    );

    // Set the initial timer value
    setTimer(initialTimeDifference);

    // Update the timer every second
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [createdAt]);

  // Convert seconds to hh:mm:ss format
  // const formatTime = (seconds: any) => {
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);
  //   // const remainingSeconds = seconds % 60;

  //   return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
  //     2,
  //     "0"
  //   )}`;
  // };
  const formatTime = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(2, "0")}`;
    ;
  };

  return <div>{formatTime(timer)}</div>;
};

export default TimerComponent;
