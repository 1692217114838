import React, { useEffect, useState } from "react";
import NoteActivitiesTab from "./NoteActivitiesTab";
import {
  useGetContactLeadAllNoteQuery,
  useUpdateContactLeadNoteMutation,
} from "src/services/ContactService";
import { useParams } from "react-router-dom";

const NoteActivitiesTabWrapper = () => {
  const { leadId } = useParams();
  const [leadTaskData, setLeadTaskData] = useState<any>();
  const [updateLeadNote, updateLeadNoteInfo] =
    useUpdateContactLeadNoteMutation();

  const { data, isLoading, isFetching } = useGetContactLeadAllNoteQuery({
    limit: 10,
    searchValue: "",
    params: ["description"],
    page: 0,
    filterBy: [
      {
        fieldName: "leadId",
        value: leadId,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setLeadTaskData(data?.data);
    }
  }, [data, isLoading, isFetching]);

  return (
    <NoteActivitiesTab
      isLoading={isLoading || isFetching}
      updateIsLoading={updateLeadNoteInfo?.isLoading}
      notesData={leadTaskData}
      updateContactNote={updateLeadNote}
    />
  );
};

export default NoteActivitiesTabWrapper;
