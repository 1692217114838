import { FormikProps } from 'formik';
import ATMLoadingButton from 'src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton';
import ATMCheckbox from 'src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox';
import ATMMultiSelect from 'src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect';
import ATMSelect from 'src/components/UI/atoms/formFields/ATMSelect/ATMSelect';
import { useGetAllCodioticUsers } from 'src/hooks/useGetAllCodioticUser';
import { useGetAllNeetingType } from 'src/hooks/useMeetingType';
import { CodioticUserListResponse } from 'src/models/CodioticUser.model';
import { MeetingTypeListResponse } from 'src/models/MeetingType.model';

type Props = {
    formikProps: FormikProps<any>;
    onClose: () => void;
    formType: "ADD" | "EDIT";
    setSaveNextChecked?: any;
    saveNextChecked?: any;
};

const AddQuicklMeetingForm = (
    {
        formikProps,
        formType = "ADD",
        onClose,
        setSaveNextChecked,
        saveNextChecked,
    }: Props
) => {
    const formHeading =
        formType === "ADD" ? "Add Quick Meeting" : "Update Quick Meeting";
    const { values, setFieldValue, isSubmitting } = formikProps;


    // get all codiotic user hook
    const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
        useGetAllCodioticUsers();

    // get all meetng note type hook
    const { allMeetingType, isDataLoading: isAllMeetingtypeDataLoading } =
        useGetAllNeetingType();

    return (
        <div className="p-4 flex flex-col gap-6">
            <div className="flex justify-between items-center">
                <div className="text-xl font-medium"> {formHeading} </div>
                <button
                    type="button"
                    className="hover:bg-hover p-2 rounded"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </div>
            <div className="flex flex-col gap-4">

                {/* Codiotic User */}
                <div>
                    <ATMMultiSelect
                        required
                        name="codioticUsers"
                        label="Codiotic User"
                        placeholder="Select Codiotic User"
                        value={values.codioticUsers}
                        isLoading={isCodioticUserDataLoading}
                        options={codioticUsers?.map((ele: CodioticUserListResponse) => {
                            return {
                                label: ele?.name,
                                value: ele?._id,
                            };
                        })}
                        onChange={(newValue) => {
                            setFieldValue("codioticUsers", newValue);
                        }}
                    />
                </div>

                <div>
                    <ATMSelect
                        required
                        name="meetingTypeId"
                        label="Meeting Type"
                        placeholder="Select Meeting Type"
                        value={values.meetingTypeId}
                        isLoading={isAllMeetingtypeDataLoading}
                        options={allMeetingType?.map((ele: MeetingTypeListResponse) => {
                            return {
                                label: ele?.meetingType,
                                value: ele?._id,
                            };
                        })}
                        onChange={(newValue) => {
                            setFieldValue("meetingTypeId", newValue);
                        }}
                    />
                </div>
            </div>
            <div>
                <ATMCheckbox
                    label="Start Meeting"
                    checked={saveNextChecked}
                    inputClasses="h-[15px]"
                    labelClasses="text-sm select-none"
                    onChange={(checked) => {
                        setSaveNextChecked(checked);
                    }}
                />
            </div>
            <div>
                <ATMLoadingButton isLoading={isSubmitting} type="submit">
                    Save
                </ATMLoadingButton>
            </div>
        </div>
    )
}

export default AddQuicklMeetingForm
