import apiSlice from "./ApiSlice";

export const JobOnboardingServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // List Event Type
    allOnboardApplications: builder.query({
      providesTags: ["job-application"],
      query: (body) => ({
        url: "/onboarding-candidate/",
        method: "POST",
        body,
      }),
    }),


    //add job application stage
    getJobOnboardingStages: builder.query({
      providesTags: ["job-stage"],
      query: ({ candidateId }) => {
        return {
          url: `onboarded-recored/candidate/${candidateId}`,
          method: "GET",
        };
      },
    }),

    //employment-sop  UPDATE
    OnBoardedRecoredAdd: builder.mutation({
      invalidatesTags: ["job-stage"],
      query: ({ body }) => {
        return {
          url: `/onboarded-recored/add`,
          method: "POST",
          body,
        };
      },
    }),

    //employment-sop  UPDATE
    OnBoardStageUpdate: builder.mutation({
      invalidatesTags: ["job-stage"],
      query: ({ body, id }) => {
        return {
          url: `/onboarded-recored/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useAllOnboardApplicationsQuery,
  useGetJobOnboardingStagesQuery,
  useOnBoardStageUpdateMutation,
  useOnBoardedRecoredAddMutation
} = JobOnboardingServiceApi;
