import { useState, useEffect } from "react";
import { useGetAllStatesQuery } from "src/services/StateServices";

const useCountryStates = (countryId?: string) => {
  const [countryStates, setCountryStates] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllStatesQuery(
    countryId || "",
    { skip: !countryId }
  );

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setCountryStates(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { countryStates, isDataLoading };
};

export default useCountryStates;
