import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import moment from "moment";

type DoubtListPropType = {
  askToId: string;
  createdAt: string;
  createdById: string;
  createdByName: string;
  featureId: string;
  isActive: true;
  isCommentStarted: false;
  isCommentedByAssignee: false;
  isDeleted: false;
  moduleId: string;
  projectId: string;
  question: string;
  side: string;
  status: string;
  updatedAt: string;
  __v: 0;
  _id: string;
};

type Props = {
  doubtRequestData: {
    count: number;
    pendingDoubtsCount: DoubtListPropType[];
  };
  onDoubtClick: (doubtId: string) => void;
};

const DoubtsList = ({ doubtRequestData, onDoubtClick }: Props) => {
  return (
    <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg custom-shadow">
      <div className="flex items-center gap-2 p-2 border-b bg-red-50">
        <div className="flex items-center justify-center p-2 font-semibold bg-white border rounded-md border-primary-dark size-8">
          {doubtRequestData?.count}
        </div>
        <p className="font-medium">Doubts</p>
      </div>
      {doubtRequestData?.pendingDoubtsCount.length ? (
        <div className="h-full py-2 overflow-auto">
          {doubtRequestData?.pendingDoubtsCount?.map(
            (doubtInfo: DoubtListPropType, index) => (
              <div
                key={index}
                className="flex items-start justify-between px-4 py-1 gap-3 cursor-pointer hover:text-black hover:bg-hover text-neutral"
                onClick={() => onDoubtClick(doubtInfo?._id)}
              >
                <div className="dashboard-doubt-list flex-1  line-clamp-2 text-sm">
                  <ATMMarkdownContainer markdown={doubtInfo?.question} />
                </div>
                <div className="w-1/6 text-xs">{doubtInfo?.createdByName}</div>
                <div className="w-1/6 text-end  text-primary-main  text-sm font-medium">
                  {moment(doubtInfo?.createdAt).format("DD MMM YYYY")}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-4">
          No Doubts
        </div>
      )}
    </div>
  );
};

export default DoubtsList;
