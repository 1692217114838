import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPermissions } from "src/redux/slices/AuthSlice";
import {
  ModulesTypes,
  fieldTypes,
  moduleActionTypes,
} from "src/redux/slices/access/userAcessSlice";
import { useGetUserAccessQuery } from "src/services/UserAccessServices";
const useGetUserAccess = () => {
  const [getAllPermission, setPermission] = useState<any[]>([]);
  //   const [isDataLoading, setIsDataLoading] = useState(true);
  const userDataLJson = localStorage.getItem("userData");
  const userData = JSON?.parse(userDataLJson as string);
  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useGetUserAccessQuery(
    {
      positionId: userData?.positionId,
      userId: userData?.userId,
    },
    {
      skip: !userData?.positionId && !userData?.userId,
      refetchOnFocus: false,
    }
  );
  const getPermisssion = (result: any) => {
    let permissions: string[] = [];
    result?.module?.forEach((moduleitem: ModulesTypes) => {
      permissions = [
        ...permissions,
        moduleitem?.moduleName,
        ...moduleitem?.parentGroup,
      ];
      moduleitem.moduleAction.forEach((actionItem: moduleActionTypes) => {
        permissions = [...permissions, actionItem?.actionId];
        actionItem.fields.forEach((fieldItem: fieldTypes) => {
          permissions = [...permissions, fieldItem?.fieldId];
        });
      });
    });
    return Array.from(new Set(permissions));
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      if (data?.status) {
        const result: any[] = data?.data;
        let updateUserData = {
          ...userData,
          userType: data?.userType,
        };
        localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(updateUserData));
        const permission = getPermisssion(result);
        dispatch(setPermissions(permission));
        setPermission(permission);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return { getAllPermission, isDataLoading: isLoading };
};

export default useGetUserAccess;
