import React, { useEffect } from "react";
import RequirementList from "./RequirementList";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/RequirementSlice";
import {
  useDeleteRequirementByIdMutation,
  useGetAllRequirementWithPaginationQuery,
} from "src/services/RequirementService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";

type Props = {};

const RequirementListWrapper = (props: Props) => {
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.requirement);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteRequirement] = useDeleteRequirementByIdMutation();

  const handleDeleteRequirement = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Requirement ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteRequirement(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const { data, isLoading, isFetching } =
    useGetAllRequirementWithPaginationQuery({
      limit: rowsPerPage,
      searchValue: searchValue,
      params: ["projectName", "moduleName", "featureName", "featureType"],
      page: page,
      filterBy: [],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <div>
      <RequirementList
        handleDeleteRequirement={handleDeleteRequirement}
        rows={items}
        onAddNew={() => navigate("/requirement/add")}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default RequirementListWrapper;
