import apiSlice from "./ApiSlice";

export const attendanceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL Channel Groups
    getAllAttendanceListing: builder.query({
      providesTags: ["attendance"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/attendance",
          method: "POST",
          body,
        };
      },
    }),


    getAllAttendanceTeamListing: builder.query({
      providesTags: ["attendance"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/attendance/team",
          method: "POST",
          body,
        };
      },
    }),

    // localhost:3009/v1/attendance/punch

    //GET CHANNEL GROUPS PAGINATION
    addPunch: builder.mutation({
      invalidatesTags: ["attendance"],
      query: (body) => {
        return {
          url: "/attendance/punch",
          method: "POST",
          body,
        };
      },
    }),

    //ATTENDACE PUNCH OUT
    attendanceUpdate: builder.mutation({
      invalidatesTags: ["attendance"],
      query: ({ body, id }) => {
        return {
          url: `/attendance/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //ATTENDACE PUNCH OUT
    AttendancePunchOut: builder.mutation({
      invalidatesTags: ["attendance"],
      query: ({ body, id }) => {
        return {
          url: `/attendance/${id}/punch-out`,
          method: "PUT",
          body,
        };
      },
    }),


    DeleteAttendance: builder.mutation({
      invalidatesTags: ["attendance"],
      query: ({ body, teammemberid }) => {
        return {
          url: `/attendance/${teammemberid}`,
          method: "DELETE",
          body,
        };
      },
    }),

    //GET CHANNEL GROUPS PAGINATION
    EditAttendance: builder.mutation({
      invalidatesTags: ["attendance"],
      query: (body) => {
        return {
          url: "/attendance/update-member-wise",
          method: "POST",
          body,
        };
      },
    }),


    //ADD CHANNEL GROUPS
    addChannelGroup: builder.mutation({
      invalidatesTags: ["channel-groups"],
      query: (body) => {
        return {
          url: "channel-group/add",
          method: "POST",
          body,
        };
      },
    }),


    //GET CHANNEL GROUP BY ID
    getAttendanceById: builder.query({
      providesTags: ["attendance"],
      query: (id) => {
        return {
          url: `/attendance/${id}`,
          method: "GET",
        };
      },
    }),

    getMemberAttendance: builder.query({
      providesTags: ["attendance"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/attendance/working-hrs",
          method: "POST",
          body,
        };
      },
    }),

  }),
});

export const {
  useGetAllAttendanceListingQuery,
  useGetAllAttendanceTeamListingQuery,
  useAddPunchMutation,
  useEditAttendanceMutation,
  useDeleteAttendanceMutation,
  useAttendancePunchOutMutation,
  useGetAttendanceByIdQuery,
  useAttendanceUpdateMutation ,
  useGetMemberAttendanceQuery
} = attendanceApi;
