import React from "react";
// import { useLocation } from 'react-router-dom';
import ATMTable from "src/components/UI/atoms/ATMTable/ATMTable";

type Props = {
  items: any;
  isLoading: any;
  columns: any;
};

const ChangeRequestView = ({ items, isLoading, columns }: Props) => {
  // const navigate = useNavigate()
  // const location = useLocation();

  return (
    <div className="flex flex-col flex-1 overflow-auto">
      <ATMTable
        columns={columns}
        rows={items || []}
        rowExtraClasses={() => "h-[50px]"}
        isLoading={isLoading}
        // onRowClick={(row) => navigate(`${location.pathname}/ticket/${row?._id}`)}
      />
    </div>
  );
};

export default ChangeRequestView;
