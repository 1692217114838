import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setTotalItems,
  setItems,
  setLabels,
  setUserValueFilter,
} from "src/redux/slices/AllTaskSlice";
import { RootState } from "src/redux/store";
import { useGetAllTaskWithPaginationQuery } from "src/services/TaskService";
import AllTaskListing from "./AllTaskListing";
import AddAllTaskInsideProjectWrapper from "../Add/AddTaskInsideProject/AddAllTaskInsideProjectWrapper";
import { useGetAllLabelsAllProjectQuery } from "src/services/ProjectServices";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";

const AllTaskListingWrapper = () => {
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    userFilterValue,
    taskStatus,
    createdByValue,
    dueDateFilterValue,
    projectFilterValue,
    moduleFilterValue,
    featureFilterValue,
    LabelFilterValue,
    layerFilterValue,
    taskAssignmentStatus,
    isArchived,
    open,
  } = useSelector((state: RootState) => state.allTask);

  const { userData } = useSelector((state: RootState) => state?.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const featureId = queryParams.get("featureId");
  const moduleId = queryParams.get("moduleId");
  const currentPath = location.pathname;
  const { id: ProjectId } = useParams();
  const [openTaskAddTaskOpen, setOpenTaskAddTaskOpen] = useState<any>(false);
  const {
    data: labelData,
    // isLoading,
    // isFetching,
  } = useGetAllLabelsAllProjectQuery("");
    // get all codiotic user hook
    const { codioticUsers} =
    useGetAllCodioticUsers();
    useEffect(() => {
      if (userData?.userId && userData.userType === "CODIOTIC_USER") {
        const userdataValue = codioticUsers?.find(
          (ele: CodioticUserListResponse) => ele?._id === userData?.userId
        );
        const userOptions = {
          label: userdataValue?.name,
          value: userdataValue?._id,
        };
        if (userdataValue) {
          dispatch(setUserValueFilter(userOptions));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.userId, codioticUsers]);

  const { data, isLoading, isFetching } = useGetAllTaskWithPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["projectName"],
    page: page,
    filterBy: [
      {
        fieldName: "assignedTo",
        value: userFilterValue?.value,
      },
      {
        fieldName: "status",
        value: taskStatus?.value || "",
      },
      {
        fieldName: "createdBy",
        value: createdByValue?.value || "",
      },
      {
        fieldName: "dueDate",
        value: dueDateFilterValue || "",
      },
      {
        fieldName: "projectId",
        value: ProjectId ? ProjectId : projectFilterValue?.value || "",
      },
      {
        fieldName: "moduleId",
        value: moduleId ? moduleId : moduleFilterValue?.value || "",
      },
      {
        fieldName: "featureId",
        value: featureId ? featureId : featureFilterValue?.value || "",
      },
      {
        fieldName: "label.labelName",
        value: LabelFilterValue?.label || "",
      },
      {
        fieldName: "layer.layerName",
        value: layerFilterValue?.label || "",
      },
      {
        fieldName: "stage",
        value: taskAssignmentStatus?.value || "",
      },
      {
        fieldName: "isArchived",
        value: isArchived,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setLabels(labelData || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, labelData, isLoading, isFetching]);

  return (
    <>
      {currentPath === `/project/${ProjectId}/details` ? (
        <AllTaskListing
          rows={items}
          open={open}
          labels={labelData || []}
          currentPath={currentPath}
          onAddNew={() => {
            setOpenTaskAddTaskOpen(true);
          }}
          ProjectId={ProjectId}
          paginationProps={{
            isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage: any) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage: any) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue: any) =>
              dispatch(setSearchValue(newValue)),
          }}
        />
      ) : (
        <SideNavLayout>
          <AllTaskListing
            rows={items}
            open={open}
            labels={labelData}
            currentPath={currentPath}
            onAddNew={() => navigate("/task/add")}
            paginationProps={{
              isTableLoading,
              totalItems,
              page,
              rowsPerPage,
              searchValue,
              setPage: (newPage: any) => dispatch(setPage(newPage)),
              setRowsPerPage: (newRowsPerPage: any) =>
                dispatch(setRowsPerPage(newRowsPerPage)),
              setSearchValue: (newValue: any) =>
                dispatch(setSearchValue(newValue)),
            }}
          />
        </SideNavLayout>
      )}
      {openTaskAddTaskOpen ? (
        <AddAllTaskInsideProjectWrapper
          onClose={() => setOpenTaskAddTaskOpen(false)}
          taskFeatureId={featureId}
          taskProjectId={ProjectId}
          taskModuleTo={moduleId}
          taskRelatedTo={"PROJECT"}
        />
      ) : null}
    </>
  );
};

export default AllTaskListingWrapper;
