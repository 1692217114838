import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { useGetSingleOperationQuery } from "src/services/OperationsService";

const OperationView = () => {
  const { id: selectedOperationId } = useParams();
  const [OperationData, setOperationData] = useState<any>({});

  const { isLoading, isFetching, data } = useGetSingleOperationQuery(
    selectedOperationId || ""
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setOperationData(data?.data);
    }
  }, [isLoading, isFetching, data]);
  return (
    <div className="flex flex-col gap-8 p-4 ">
      <div>
        <div className="text-xs uppercase ">Title</div>
        <div className="text-lg ">{OperationData?.title}</div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-xs uppercase ">Business Models</div>
        <div className="flex flex-wrap gap-4 ">
          {OperationData?.businessModels?.map((industry: any) => (
            <div className="px-4 rounded-xl bg-red-50 w-fit">
              {industry?.modelName}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-xs uppercase ">Description</div>
        <div className="">
          <ATMMarkdownContainer markdown={OperationData?.description || "-"} />
        </div>
      </div>
    </div>
  );
};

export default OperationView;
