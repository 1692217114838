import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
  onAddNew: () => void;
};

const PlansListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
  onAddNew,
}: Props) => {
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div>
          <ATMPageHeader
            pageTitle="Plan"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_SOCIAL_MEDIA_ADD)
            }
            hideSearchBox
            buttonProps={{
              btnName: "Add New",
              onClick: onAddNew,
            }}
          />
        </div>

        {/* Table */}
        <div className="flex flex-col flex-1 overflow-auto">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "h-[50px]"}
            isLoading={isTableLoading}
          />
        </div>

        <div className="hidden p-2 py-2 md:p-4">
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

export default PlansListing;
