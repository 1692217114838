import apiSlice from "./ApiSlice";

export const TicketGroupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get All
    getAllChangeRequest: builder.query({
      providesTags: ["ticket-group"],
      query: () => {
        return {
          url: `ticket-group`,
          method: "GET",
        };
      },
    }),

    // Get By Id
    getTicketGroupById: builder.query({
      providesTags: ["ticket-group"],
      query: (id: any) => {
        return {
          url: `ticket-group/${id}`,
          method: "GET",
        };
      },
    }),

    // Get All With Pagination
    getAllTicketGroupPagination: builder.query({
      providesTags: ["ticket-group"],
      query: (body: PaginationPayloadType) => {
        return {
          url: `ticket-group`,
          method: "POST",
          body,
        };
      },
    }),

    // ADD Ticket group
    addTicketGroup: builder.mutation({
      invalidatesTags: ["ticket-group"],
      query: (body: any) => {
        return {
          url: "ticket-group/add",
          method: "POST",
          body,
        };
      },
    }),

    // EDIT Ticket group
    editTicketGroup: builder.mutation({
      invalidatesTags: ["ticket-group"],
      query: ({ id, body }: { id: any; body: any }) => {
        return {
          url: `ticket-group/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Delet Ticket group
    deleteTicketGroup: builder.mutation({
      invalidatesTags: ["ticket-group"],
      query: (id: string) => {
        return {
          url: `ticket-group/${id}`,
          method: "DELETE",
        };
      },
    }),

    // Get Time Logs
    getTimeLogs: builder.query({
      providesTags: ["ticket-group"],
      query: (id: any) => {
        return {
          url: `/ticket-group/${id}/logs`,
          method: "GET"
        };
      },
    }),

    // SINGLE GROUP TICKET 
    getAllTicketGroupSingle: builder.query({
      providesTags: ["ticket-group"],
      query: (id: any) => {
        return {
          url: `ticket-group/${id}/tickets`,
          method: "GET",
        };
      },
    }),
    // TIME ANALYTICS
    getTicketGroupTimeAnyltics: builder.query({
      providesTags: ["ticket-group"],
      query: (id: any) => {
        return {
          url: `ticket-group/task-time-logs/${id}`,
          method: "GET",
        };
      },
    }),

    // TIME ANALYTICS ADD GROUP
    getAddTicketGroupTimeAnyltics: builder.query({
      providesTags: ["ticket-group"],
      query: (body: any) => {
        return {
          url: `ticket-group/ticket-time-logs`,
          method: "POST",
          body
        };
      },
    }),

    // DELETE Ticket group
    deleteTicketGroupTicket: builder.mutation({
      invalidatesTags: ["ticket-group"],
      query: ({ id, body }: { id: any; body: any }) => {
        return {
          url: `ticket-group/${id}/delete-ticket`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllChangeRequestQuery,
  useGetAllTicketGroupPaginationQuery,
  useAddTicketGroupMutation,
  useEditTicketGroupMutation,
  useDeleteTicketGroupMutation,
  useGetTicketGroupByIdQuery,
  useGetTimeLogsQuery,
  useGetAllTicketGroupSingleQuery,
  useGetTicketGroupTimeAnylticsQuery,
  useDeleteTicketGroupTicketMutation,
  useGetAddTicketGroupTimeAnylticsQuery
} = TicketGroupApi;
