import { useState } from 'react';
import AddModuleFormWrapper from 'src/screens/Module/Add/AddModuleFormWrapper';
import LocationListView from './LocationListView';

type Props = {
  items: any;
  moduleIsLoading: any
  moduleId: any;
  moduleInfo:any
};

const ProjectModule = ({ items, moduleId,moduleIsLoading,moduleInfo }: Props) => {
  const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("")

  return (
    <>
      <LocationListView
        moduleId={moduleId}
        listHeading='Modules'
        onAddClick={() => {
          setIsOpenAddFormDialog(true);
        }}
        listData={items}
        disabled={false}
        moduleIsLoading={moduleIsLoading}
        OnSearchChange={setSearchValue}
        searchValue={searchValue}
      />
      {isOpenAddFormDialog ? (
        <AddModuleFormWrapper projectItems={moduleInfo} onClose={() => setIsOpenAddFormDialog(false)} />
      ) : null}
    </>


  )
}

export default ProjectModule
