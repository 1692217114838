import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/PlanSlice";
import { AppDispatch, RootState } from "src/redux/store";
import PlansListing from "./PlansListing";
import {
  useDeletePlanByIdMutation,
  useGetAllPlanListPaginationDataQuery,
} from "src/services/PlanService";
import { PlanListResponse } from "src/models/Plan.model";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdDelete, MdEdit } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import EditPlanWrapper from "../Edit/EditPlanWrapper";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { useParams } from "react-router-dom";
import AddPlanWrapper from "../Add/AddPlanWrapper";

type Props = {};

const PlansListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { productId } = useParams();

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.plan);
  const [deletePlan] = useDeletePlanByIdMutation();

  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [isOpenAddDialog, setIsOpenAddDialog] = useState<boolean>(false);
  const [selectPlanId, setSelectPlanId] = useState<string>("");

  const { data, isLoading, isFetching } = useGetAllPlanListPaginationDataQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["planName", "amount", "recurranceDays"],
    page: page,
    filterBy: [
      {
        fieldName: "productId",
        value: productId,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Plan?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deletePlan(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "planName",
      headerName: "Plan Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
    },
    {
      field: "recurranceDays",
      headerName: "Recurrance Days",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: PlanListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectPlanId(row?._id);
                },
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <PlansListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
          onAddNew={() => setIsOpenAddDialog(true)}
        />
      </div>

      {isOpenAddDialog && (
        <AddPlanWrapper
          onClose={() => setIsOpenAddDialog(false)}
          productId={productId || ""}
        />
      )}

      <Suspense fallback={<CircularProgress />}>
        {isOpenEditDialog ? (
          <EditPlanWrapper
            onClose={() => setIsOpenEditDialog(false)}
            getPlanId={selectPlanId}
            productId={productId || ""}
          />
        ) : null}
      </Suspense>
    </>
  );
};

export default PlansListingWrapper;
