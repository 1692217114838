export const getApprovalsInfo = (leaveInfo: any) => {
  return leaveInfo?.approvals
    ?.filter((approval: any) => approval?.approvedBy !== leaveInfo?.buddy)
    ?.map((approval: any) => {
      return {
        label: approval?.name,
        value: approval?.approvedBy,
      };
    })?.[0] || null;
};
