// |-- Built-in Dependencies --|
import React, { useState, useEffect } from "react";

// |-- External Dependencies --|
import { useDispatch, useSelector } from "react-redux";

// |-- Internal Dependencies --|
import CountryListing from "./CountryListing";
import AddCountryFormWrapper from "../Add/AddCountryFormWrapper";

// |-- Redux --|
import { AppDispatch, RootState } from "src/redux/store";
import { setItems } from "src/redux/slices/CountrySlice";
import { useGetCounrtiesQuery } from "src/services/CountryServices";

const CountryListingWrapper = () => {
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);

  const { items, searchValue }: any = useSelector(
    (state: RootState) => state.country
  );
  const { data, isLoading, isFetching } = useGetCounrtiesQuery({
    limit: 500,
    searchValue: searchValue,
    params: ["countryName"],
    page: 0,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
  });
  const dispatch = useDispatch<AppDispatch>();

  const contries = items?.map((elem: any) => {
    return {
      label: elem.countryName,
      value: elem._id,
    };
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <>
      <CountryListing
        contries={contries}
        items={contries}
        onAddNew={() => setIsOpenAddForm(true)}
      />
      {isOpenAddForm ? (
        <AddCountryFormWrapper onClose={() => setIsOpenAddForm(false)} />
      ) : null}
    </>
  );
};

export default CountryListingWrapper;
