import React from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { useClarificationRequestByAdminMutation } from "src/services/IncidentService";
import { showToast } from "src/utils/showToaster";
import ClarificationAddByAdmin from "./ClarificationAddByAdmin";


type Props = {
  onClose: () => void;
  selectId: string;
  clarificationRemark:any
};

export type ClarificationAddByAdminWrapperValues = {
  clarification: string;
};

const ClarificationAddByAdminWrapper = ({ onClose, selectId ,clarificationRemark }: Props) => {
  const [updateClarification] = useClarificationRequestByAdminMutation();

  // Form Initial Values
  const initialValues: ClarificationAddByAdminWrapperValues = {
    clarification: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    clarification: string().required("Please enter Not me Reason"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ClarificationAddByAdminWrapperValues,
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<ClarificationAddByAdminWrapperValues>
  ) => {
    updateClarification({
      id: selectId,
      body: values,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <ClarificationAddByAdmin formikProps={formikProps} onClose={onClose} clarificationRemark={clarificationRemark} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ClarificationAddByAdminWrapper;
