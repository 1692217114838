import React from "react";
import { ErrorMessage } from "formik";

type Props = {
  label?: string;
  required?: boolean;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  minRows?: number;
  name?: string;
  disable?: boolean;
  readonly?: boolean;
};

const ATMTextArea = ({
  label,
  required = false,
  value,
  onChange,
  className,
  placeholder,
  minRows = 3,
  name = "",
  disable = false,
  readonly = false,
  ...rest
}: Props) => {
  return (
    <div className="relative ">
      {label && (
        <label className="text-sm font-medium">
          {label} {required && <span className="text-red-500"> * </span>}
        </label>
      )}
      <textarea
        disabled={disable}
        readOnly={readonly}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        rows={minRows}
        className={`w-full p-2 bg-white text-slate-700 border border-slate-400 outline-blue-400 rounded-lg ${
          disable ? "opacity-50" : ""
        }  ${label && "mt-1"}  ${className}`}
        placeholder={placeholder}
        {...rest}
      />

      {name && (
        <ErrorMessage name={name}>
          {(errMsg) => (
            <p className="absolute mt-0 text-xs text-red-500 text-start">
              {errMsg}
            </p>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ATMTextArea;
