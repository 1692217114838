import apiSlice from "./ApiSlice";

export const socialMediaApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all social media with pagination
    getSocialMediaPagination: builder.query({
      providesTags: ["socialMedia"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/social-media",
          method: "POST",
          body,
        };
      },
    }),

    // Get all social media
    getAllSocialMedia: builder.query({
      providesTags: ["socialMedia"],
      query: () => {
        return {
          url: "/social-media",
          method: "GET",
        };
      },
    }),

    // Add social media
    addSocialMedia: builder.mutation({
      invalidatesTags: ["socialMedia"],
      query: (body) => {
        return {
          url: "/social-media/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get single social media
    getSingleSocialMedia: builder.query({
      providesTags: ["socialMedia"],
      query: (id: string) => {
        return {
          url: `/social-media/${id}`,
          method: "GET",
        };
      },
    }),

    // Update social media
    updateSocialMedia: builder.mutation({
      invalidatesTags: ["socialMedia"],
      query: ({ id, body }) => {
        return {
          url: `/social-media/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Change Status Social Media
    ChangeStatusSocialMedia: builder.mutation({
      invalidatesTags: ["socialMedia"],
      query: (id) => {
        return {
          url: `social-media/status-change/${id}`,
          method: "PUT",
        };
      },
    }),

    //
  }),
});

export const {
  useGetSocialMediaPaginationQuery,
  useGetAllSocialMediaQuery,
  useGetSingleSocialMediaQuery,
  useAddSocialMediaMutation,
  useUpdateSocialMediaMutation,
  useChangeStatusSocialMediaMutation,
} = socialMediaApi;
