import React from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
// import { object } from "yup";
import { showToast } from "src/utils/showToaster";
import EditChangeRequestForm from "./EditChangeRequestForm";
import { useUpdateSummeryMutation } from "src/services/TicketServices";
import { useParams } from "react-router-dom";

type Props = {
  onClose: () => void;
  changeRequestId: any;
  changeRequestLabel: any;
  ProjectId: any;
};

export type InitialValueOfForm = { changeRequestId: any };

const EditChangeRequestFormWrapper = ({
  changeRequestId,
  changeRequestLabel,
  ProjectId,
  onClose,
}: Props) => {
  // ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  const [updateTicketSummery] = useUpdateSummeryMutation();

  // Form Initial Values
  const initialValues: InitialValueOfForm = {
    changeRequestId: {
      label: changeRequestLabel,
      value: changeRequestId,
    },
  };

  // Validation Schema
  // const validationSchema = object().shape({
  //   changeRequestId: object(),
  // });

  // Handle Submit
  const handleSubmit = (
    values: InitialValueOfForm,
    { setSubmitting, resetForm }: FormikHelpers<InitialValueOfForm>
  ) => {
    const formatedValues = {
      changeRequestId: values.changeRequestId?.value
        ? values.changeRequestId?.value
        : null,
    };
    
    updateTicketSummery({
      ticketId: TICKET_ID || "",
      body: formatedValues,
    })
      .then((res: any) => {
        showToast("success", "updated successfully");
        setSubmitting(false);
        resetForm();
        onClose();
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <EditChangeRequestForm
              formType="EDIT"
              formikProps={formikProps}
              onClose={onClose}
              ProjectId={ProjectId}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditChangeRequestFormWrapper;
