import { FormikProps } from "formik";
import moment from "moment";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { LeaveRequestFormValues } from "src/models/LeaveReques.model";

type Props = {
  handleClose: any;
  formikProps: FormikProps<LeaveRequestFormValues>;
  codioticUsers: any;
  isDataLoading: any;
};

export const WhichPart = [
  {
    label: "FIRST HALF",
    value: "FIRST_HALF",
  },
  {
    label: "SECOND HALF",
    value: "SECOND_HALF",
  },
  {
    label: "For Hours",
    value: "FOR_HOURS",
  },
];

const HalfDayLeaveRequestForm = ({
  handleClose,
  formikProps,
  codioticUsers,
  isDataLoading,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={"Half Day Leave"}
      onClose={handleClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMSelect
            required
            name="dayPart"
            label="Which Part?"
            placeholder="Which Part?"
            value={values.dayPart}
            options={WhichPart?.map((ele: any) => {
              return {
                label: ele?.label,
                value: ele?.value,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("dayPart", newValue);
            }}
          />
        </div>

        {/* date */}
        <div className="w-full mt-1">
          <ATMDatePicker
            required
            label="Date"
            name="leaveStartDate"
            format="DD/MM/YYYY"
            value={values.leaveStartDate || null}
            minDate={moment().format("yyyy-MM-DD")}
            onChange={(newValue) => {
              setFieldValue("leaveStartDate", newValue);
              setFieldValue("reportingDate", newValue);
            }}
          />
        </div>

        {values?.dayPart?.value === "SECOND_HALF" ||
        values?.dayPart?.value === "FOR_HOURS" ? (
          <div className="mb-2 mt-1">
            <ATMTextField
              type="time"
              name="leavingTime"
              value={moment(values.leavingTime, "HH:mm").format("HH:mm")}
              required
              label="Leaving time"
              placeholder="Enter Leaving time"
              onBlur={handleBlur}
              onChange={(e) => {
                let time = moment(e.target.value, "HH:mm");
                setFieldValue("leavingTime", time);
              }}
            />
          </div>
        ) : null}
        {values?.dayPart?.value === "FIRST_HALF" ||
        values?.dayPart?.value === "FOR_HOURS" ? (
          <div className="mb-2 mt-1">
            <ATMTextField
              type="time"
              name="arrivalTime"
              value={moment(values.arrivalTime, "HH:mm").format("HH:mm")}
              required
              label="Arrival time"
              placeholder="Enter Arrival time"
              onBlur={handleBlur}
              onChange={(e) => {
                let time = moment(e.target.value, "HH:mm");
                setFieldValue("arrivalTime", time);
              }}
            />
          </div>
        ) : null}
        <div>
          <ATMSelect
            required
            name="approvals"
            label="Approver"
            placeholder="Approver"
            value={values.approvals}
            options={codioticUsers?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            isLoading={isDataLoading}
            onChange={(newValue) => {
              setFieldValue("approvals", newValue);
            }}
          />
        </div>
        <div className="mt-1">
          <ATMSelect
            required
            name="buddy"
            label="Buddy"
            placeholder="Buddy"
            isLoading={isDataLoading}
            value={values.buddy}
            options={codioticUsers?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("buddy", newValue);
            }}
          />
        </div>

        {/* Reason */}
        <div className="mt-1">
          <ATMTextArea
            required
            name="reason"
            value={values.reason}
            onChange={(e) => setFieldValue("reason", e)}
            label="Reason"
            placeholder="Enter Reason"
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default HalfDayLeaveRequestForm;
