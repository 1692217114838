import React from "react";
import Dialog from "@mui/material/Dialog";
import PointerLogsFlowDialog from "./PointerLogsFlowDialog";
import { useGetPointerLogQuery } from "src/services/IncidentService";

type Props = {
  onClose: () => void;
  pointerId :any ;
};

const PointerLogsFlowDialogWrapper = ({ onClose ,pointerId }: Props) => { 

    const {data , isLoading , isFetching} =useGetPointerLogQuery({
        filterBy: [
            {
                "fieldName": "groupId",
                "value": pointerId
            }
        ],
    })

  return (
    <Dialog open maxWidth='md' fullWidth>
      <PointerLogsFlowDialog 
       data={data?.data}
       isLoading ={isLoading || isFetching}
      onClose={onClose} />
    </Dialog>
  );
};

export default PointerLogsFlowDialogWrapper;
