import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const JobCreationListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex justify-between ">
          <div className="w-full">
            <ATMPageHeader
              pageTitle="Job Creation"
              hideSearchBox
              hideAddButton={true}
            />
          </div>

          <div className="flex items-center w-full gap-2 px-4 md:justify-end">
            {/* Search Box */}
            <div className="xs:w-full md:max-w-[300px]">
              <ATMInputAdormant
                name=""
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                adormant={<BiSearch />}
                adormantProps={{
                  position: "start",
                  extraClasses: "bg-white border-0",
                }}
                inputProps={{ className: "bg-white" }}
                placeholder="Search..."
              />
            </div>

            {isAuthorized(UserModuleNameTypes.ACTION_JOB_CREATION_ADD) && (
              <div className="relative z-20 mt-5 md:mt-0">
                <ATMLoadingButton
                  onClick={() => {
                    navigate("/job-onboard/job-creation/add");
                  }}
                >
                  Add
                </ATMLoadingButton>
              </div>
            )}
          </div>
        </div>
        {/* Table */}
        <div className="flex flex-col flex-1 overflow-auto">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "min-h-[40px]"}
            isLoading={isTableLoading}
            disableRowClick={
              !isAuthorized(UserModuleNameTypes.ACTION_JOB_CREATION_VIEW)
            }
            onRowClick={(row) => navigate(`/job-onboard/job-creation/view/${row?._id}/job-details`)}
          />
        </div>

        <div className="p-2 py-2 md:p-4">
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

export default JobCreationListing;
