import React from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
type Props = {
  columns: columnTypes[];
  rows: any[];
  navigate: any;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const EstimationCalculatorListing = ({
  columns,
  rows,
  navigate,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  return (
    <>
      <div className="flex flex-col h-full">
        <div className="">
          <ATMPageHeader
            pageTitle="Estimation Calculation"
            hideAddButton={
              !isAuthorized(
                UserModuleNameTypes.ACTION_ESTIMATION_CALCULATOR_ADD
              )
            }
            hideSearchBox
            buttonProps={{
              btnName: "Calculator",
              onClick: () => navigate("/estimations/estimation-calculator"),
            }}
          />
        </div>
        {/* Table */}
        <div className="flex flex-col flex-1 overflow-auto">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "h-[50px]"}
            isLoading={isTableLoading}
            disableRowClick={
              !isAuthorized(
                UserModuleNameTypes.ACTION_ESTIMATION_CALCULATOR_VIEW
              )
            }
            onRowClick={(row) => navigate(`/estimations/${row?._id}`)}
          />
        </div>

        <div>
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

export default EstimationCalculatorListing;
