import { CircularProgress } from "@mui/material";
import React from "react";
// import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTagAutoComplete from "src/components/UI/atoms/ATMTagAutoComplete/ATMTagAutoComplete";
import { useGetAllTagsQuery } from "src/services/TicketServices";

type Props = {
  formikProps: any;
};
const TicketTags = ({ formikProps }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  const { data: AllTagsData } = useGetAllTagsQuery("");
  const tagsOptions = AllTagsData?.data?.map((el: any) => {
    return {
      title: el?.tagName,
    };
  });

  return (
    <div className="relative">
      {isSubmitting && (
        <div className="absolute top-1/2 left-1/2 flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress size={18} />
        </div>
      )}
      <ATMTagAutoComplete
        disable={isSubmitting}
        value={values?.tags}
        onChange={(value: any) => {
          setFieldValue("tags", value);
        }}
        label=""
        required
        option={tagsOptions || []}
      />

      {/* <ATMLoadingButton
        isLoading={isSubmitting}
        type="submit"
        className=" h-[30px] text-sm mt-2"
      >
        Save
      </ATMLoadingButton> */}
    </div>
  );
};

export default TicketTags;
