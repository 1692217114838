import React from "react";
import { useNavigate } from "react-router-dom";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  onAddNew: () => void;
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const RequirementGatheringListing = ({
  columns,
  rows,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();
  return (
    <SideNavLayout>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div>
          <ATMPageHeader
            pageTitle="Requirement Gatherings"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            buttonProps={{
              btnName: "Add New",
              onClick: onAddNew,
            }}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_ADD)
            }
          />
        </div>

        {/* Table */}
        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-col overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "min-h-[40px]"}
              isLoading={isTableLoading}
              onRowClick={(items) => navigate(`view/${items?._id}`)}
              disableRowClick={
                !isAuthorized(UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW)
              }
            />
          </div>

          <div className="p-4 py-2">
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      </div>
    </SideNavLayout>
  );
};

export default RequirementGatheringListing;
