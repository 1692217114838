import Tooltip from "@mui/material/Tooltip";
import { IoCheckmarkSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { TbQuestionMark } from "react-icons/tb";
import ATMLoadingButton from "../../atoms/ATMLoadingButton/ATMLoadingButton";
import { JobApplicationChangeStatusValues } from "src/models/JobApplication.model";

type Props = {
  handleChangeStatus: (status: JobApplicationChangeStatusValues) => void;
};
const MOLChangeJobApplicationStatus = ({ handleChangeStatus }: Props) => {
  return (
    <div className=" flex flex-col gap-2">
      <div className="flex ">
        <Tooltip title="Shortlist">
          <ATMLoadingButton
            variant="tonal"
            color="success"
            className="rounded-none p-2"
            onClick={() => handleChangeStatus("SHORTLISTED")}
          >
            <IoCheckmarkSharp size={20} className=" text-green-500 text-bold" />
          </ATMLoadingButton>
        </Tooltip>
        <Tooltip title="May be">
          <ATMLoadingButton
            variant="tonal"
            color="tertiary"
            className="rounded-none p-2"
            onClick={() => handleChangeStatus("UNDER_REVIEW")}
          >
            <TbQuestionMark size={20} className=" text-yellow-500 text-bold" />
          </ATMLoadingButton>
        </Tooltip>
        <Tooltip title="Reject">
          <ATMLoadingButton
            variant="tonal"
            color="error"
            className="rounded-none p-2"
            onClick={() => handleChangeStatus("REJECTED")}
          >
            <RxCross2 size={20} className=" text-red-500 text-bold" />
          </ATMLoadingButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default MOLChangeJobApplicationStatus;
