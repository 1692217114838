import { CircularProgress } from "@mui/material";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import AccordianFormLayout from "src/components/layouts/AccordianFormLayout/AccordianFormLayout";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetResourceByIdQuery } from "src/services/ResourceRequestService";

const getClassNameByStatus = (status: any) => {
  switch (status) {
    case "PENDING":
      return "text-[#000000] border-[#CCC] bg-[#FFFF001A]";

    case "CLOSED":
      return "text-slate-500 bg-slate-100 border-slate-500";
  }
};

const ResourceRequestViewWrapper = () => {
  const { id } = useParams();
  const [requestData, setRequestData] = React.useState<any>([]);

  const { data, isLoading, isFetching } = useGetResourceByIdQuery(id, {
    skip: !id,
  });

  React.useEffect(() => {
    if (!isFetching && !isLoading) {
      setRequestData(data?.data);
    }
  }, [isFetching, isLoading, data]);

  return (
    <SideNavLayout>
      <div className="m-2">
        {(isLoading || isFetching) && (
          <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
            <CircularProgress />
          </div>
        )}
        <AccordianFormLayout formHeading={"Resources"} isOpen={true}>
          <div className="p-2">
            <div className="md:flex block justify-between  rounded p-3">
              <div>
                <p className="font-medium ">
                  <span className="text-primary-light">Request From</span> :{" "}
                  {requestData?.requestedFromName}{" "}
                </p>
                <p className="font-medium ">
                  {" "}
                  <span className="text-primary-light">Request To</span> :{" "}
                  {requestData?.createdByName}{" "}
                </p>
                {requestData?.status === "CLOSED" && (
                  <p className="font-medium ">
                    {" "}
                    <span className="text-primary-light">Closed By</span> :{" "}
                    {requestData?.closedByName}{" "}
                  </p>
                )}
              </div>

              <div>
                <div className="md:text-end text-start my-1 md:my-0">
                  <span
                    className={`font-medium rounded-full px-2 py-[2px]  text-[14px] border-[1px] ${getClassNameByStatus(
                      requestData?.status
                    )}`}
                  >
                    {requestData?.status}
                  </span>{" "}
                </div>
                <div className="text-[17px] text-slate-700 font-medium md:text-end text-start my-1">
                  {moment(requestData?.createdAt).format("DD MMM YYYY")}
                </div>
                <p className="font-medium ">
                  {" "}
                  <span className="text-primary-light">
                    Request Type
                  </span> : {requestData?.requestedFromType}{" "}
                </p> 
                {requestData?.uploadedOnDate &&  <p className="font-medium ">
                  {" "}
                  <span className="text-primary-light">
                    Resource added On Date
                  </span> :  {moment(requestData?.uploadedOnDate).format("DD MMM YYYY")}{" "}
                </p> }
               
              </div>
            </div>

            <div className="m-2">
              {requestData?.description !== "" && (
                <div>
                  <p className="text-slate-700 font-medium"> Description</p>
                  <div className="border border-slate-300 p-2  rounded-md">
                    <ATMMarkdownContainer
                      markdown={requestData?.description || "-"}
                    />
                  </div>
                </div>
              )}
               {requestData?.remark !== "" && (
                <div className="mt-2">
                  <p className="text-slate-700 font-medium">Remark</p>
                  <div className="border border-slate-300 p-2  rounded-md">
                    <ATMMarkdownContainer
                      markdown={requestData?.remark || "-"}
                    />
                  </div>
                </div>
              )}
              <div className="grid md:grid-cols-3 grid-cols-2 gap-4">
                {requestData?.attachments?.length ? (
                  requestData?.attachments?.map((el: any, ind: number) => {
                    return (
                      <div
                        key={ind}
                        className={`${
                          el?.type === "PDF" || el?.type === "XLSX"
                            ? "max-h-[120px]"
                            : "max-h-[350px]"
                        } `}
                      >
                        <ATMRenderFile fileInfo={el} />
                      </div>
                    );
                  })
                ) : (
                  <div> No Resources Uploaded yet</div>
                )}
              </div>
            </div>
          </div>
        </AccordianFormLayout>
      </div>
    </SideNavLayout>
  );
};

export default ResourceRequestViewWrapper;
