import React, { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import HolidayListFilterFormDialogWrapper from "./HolidayFilterFormCard/HolidayListFilterFormDialogWrapper";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
type Props = {
  columns: columnTypes[];
  rows: any[];
  onAddNew: () => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const HolidayCalendarListing = ({
  columns,
  rows,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenTicketFilterDialog, setIsOpenTicketFilterDialog] =
    useState<boolean>(false);
  return (
    <SideNavLayout>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex justify-between px-2 " >
        <ATMPageHeader
            pageTitle="HoliDay Calendar"
            searchValue={searchValue}
            isFilter
            onSearchChange={setSearchValue}
            onFilterClick={()=>{setIsOpenTicketFilterDialog(true)}}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_TIKCETS_ADD)
            }
            buttonProps={{
              btnName: "Add New",
              onClick: onAddNew,
            }}
          />
        </div>
        {/* Table */}
        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-col overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "h-[50px]"}
              isLoading={isTableLoading}
            />
          </div>

          <div>
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
          {/* Ticket Filter Dialog Wrapper */}
          <HolidayListFilterFormDialogWrapper
              open={isOpenTicketFilterDialog}
              onClose={() => setIsOpenTicketFilterDialog(false)}
            />
      </div>
    </SideNavLayout>
  );
};

export default HolidayCalendarListing;
