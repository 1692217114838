import apiSlice from "./ApiSlice";

export const productApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllProducts: builder.query({
      providesTags: ["product"],
      query: () => {
        return {
          url: "/product",
          method: "GET",
        };
      },
    }),
    // Get products with pagination
    getProducts: builder.query({
      providesTags: ["product"],
      query: (body) => {
        return {
          url: "/product",
          method: "POST",
          body,
        };
      },
    }),
    //Add
    addProduct: builder.mutation({
      invalidatesTags: ["product"],
      query: (body: { productName: string; cost: number }) => {
        return {
          url: "/product/add",
          method: "POST",
          body,
        };
      },
    }),
    //Edit
    updateProduct: builder.mutation({
      invalidatesTags: ["product"],
      query: ({ id, body }) => {
        return {
          url: `/product/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Get product by ID
    getProductById: builder.query({
      providesTags: ["product"],
      query: (id) => {
        return {
          url: `/product/${id}`,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useGetAllProductsQuery,
  useGetProductsQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useGetProductByIdQuery
} = productApi;
