import React from "react";
import { FormikProps } from "formik";
import { useSelector } from "react-redux";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetProjectWiseMembers } from "src/hooks/useGetProjectWiseMembers";
import { RootState } from "src/redux/store";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<any>;
  onClose: any;
  projectId?: string;
};

const AssignToDialog = ({ formikProps, onClose, projectId }: Props) => {
  // Redux state for login user data
  const { userData } = useSelector((state: RootState) => state?.auth);

  const { allProjectWiseMembers, isDataLoading } = useGetProjectWiseMembers(
    projectId || ""
  );
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <MOLFormDialog
      title={"Edit Assignee"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        {/* ASSIGN TO MEMBER */}
        <div>
          <ATMSelect
            name="assignedTo"
            label="Project Members"
            placeholder="Select Assigner"
            value={values.assignedTo}
            isLoading={isDataLoading}
            options={allProjectWiseMembers?.members?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return (
                  <div className="capitalize flex gap-x-3">
                    {option.label}
                    {option?.value === userData?.userId && (
                      <span className="bg-green-500 py-[2px] flex items-center px-[8px] text-center rounded-full text-white text-[10px]">
                        It's you
                      </span>
                    )}
                  </div>
                );
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            onChange={(newValue) => {
              setFieldValue("assignedTo", newValue);
            }}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default AssignToDialog;
