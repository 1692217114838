import apiSlice from "./ApiSlice";

export const deploymentRequestApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllDeploymentRequestWithPagination: builder.query({
      providesTags: ["deployment-request"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/deploy-request",
          method: "POST",
          body,
        };
      },
    }),

    // Get
    getAllDeploymentRequest: builder.query({
      providesTags: ["deployment-request"],
      query: () => {
        return {
          url: "/deploy-request",
          method: "GET",
        };
      },
    }),

    // Add
    addDeploymentRequest: builder.mutation({
      invalidatesTags: ["deployment-request"],
      query: (body) => {
        return {
          url: "/deploy-request/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get the Git Repo  by id
    getDeploymentRequestById: builder.query({
      providesTags: ["deployment-request"],
      query: (id: string) => {
        return {
          url: `/deploy-request/${id}`,
          method: "GET",
        };
      },
    }),

    // Single Product Delete
    deleteDeploymentRequestById: builder.mutation({
      invalidatesTags: ["deployment-request"],
      query: (id: any) => ({
        url: `/deploy-request/${id}`,
        method: "DELETE",
      }),
    }),

    // Update Git Repo
    closeDeploymentRequest: builder.mutation({
      invalidatesTags: ["deployment-request"],
      query: ({ id }) => {
        return {
          url: `/deploy-request/${id}/mark-as-close`,
          method: "PUT",
        };
      },
    }),
    // Update Git Repo
    updateDeploymentRequestById: builder.mutation({
      invalidatesTags: ["deployment-request"],
      query: ({ id, body }) => {
        return {
          url: `/deploy-request/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // get doubt count
    getDeploymentRequestCount: builder.query({
      providesTags: ["deployment-request"],
      query: () => {
        return {
          url: "/deploy-request/counts",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllDeploymentRequestWithPaginationQuery,
  useAddDeploymentRequestMutation,
  useCloseDeploymentRequestMutation,
  useGetAllDeploymentRequestQuery,
  useGetDeploymentRequestByIdQuery,
  useUpdateDeploymentRequestByIdMutation,
  useDeleteDeploymentRequestByIdMutation,
  useGetDeploymentRequestCountQuery,
} = deploymentRequestApi;
