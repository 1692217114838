import React, { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useNavigate } from "react-router-dom";
import TicketListFilterFormDialogWrapper from "./TicketFilterFormCard/TicketListFilterFormDialogWrapper";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

// const sortByOptions: SortByOption[] = [
//   {
//     label: "Priority Low To High",
//     field: "priority",
//     value: "ASC",
//   },
//   {
//     label: "Priority High To Low",
//     field: "priority",
//     value: "DESC",
//   },
//   {
//     label: "ETA Low To High",
//     field: "eta",
//     value: "ASC",
//   },
//   {
//     label: "ETA High To Low",
//     field: "eta",
//     value: "DESC",
//   },
// ];

type Props = {
  columns: columnTypes[];
  rows: any[];
  addNew: () => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    sortValue: {
      field: string;
      value: "DESC" | "ASC";
    } | null;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
    setSortValue: (
      newValue: {
        field: string;
        value: "DESC" | "ASC";
      } | null
    ) => void;
  };
};

const TicketList = ({
  columns,
  rows,
  addNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    sortValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
    setSortValue,
  },
}: Props) => {
  const [isOpenTicketFilterDialog, setIsOpenTicketFilterDialog] =
    useState<boolean>(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if the pressed keys match the specified shortcut (Ctrl + Shift + F)
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "f") {
        // Open the filter dialog when the shortcut is detected
        setIsOpenTicketFilterDialog(true);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <SideNavLayout>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex items-center justify-between w-full">
          {/* pageheader */}
          <ATMPageHeader
            pageTitle="Tickets"
            searchValue={searchValue}
            isFilter
            onSearchChange={setSearchValue}
            onFilterClick={() => setIsOpenTicketFilterDialog(true)}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_TIKCETS_ADD)
            }
            buttonProps={{
              btnName: "Add New",
              onClick: addNew,
            }}
          />
        </div>
        {/* <div className="hidden">
              <MOLSortByDropdown
                options={sortByOptions}
                selected={sortValue}
                onSelect={(selected) => {
                  const { label, ...rest } = selected;
                  setSortValue(rest);
                }}
              />
            </div> */}
        {/* Table */}
        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-col overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "h-[50px]"}
              isLoading={isTableLoading}
              onRowClick={(row) => navigate(`${row?._id}/details`)}
              disableRowClick={
                !isAuthorized(UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW)
              }
            />
          </div>

          <div>
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
        {/* Ticket Filter Dialog Wrapper */}
        <TicketListFilterFormDialogWrapper
          open={isOpenTicketFilterDialog}
          onClose={() => setIsOpenTicketFilterDialog(false)}
        />
      </div>
    </SideNavLayout>
  );
};

export default TicketList;
