import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { IncidentCategoryFormValues } from "../Add/AddIncidentCategoryFormWrapper";
import IncidentCategoryForm from "../Layouts/IncidentCategoryForm";
import { showToast } from "src/utils/showToaster";
import { CircularProgress } from "@mui/material";
import { useGetIncidentCategoryQuery, useUpdateIncidentCategoryMutation } from "src/services/IncidentCategoryService";

type Props = {
  onClose: () => void;
  categoryId: string;
};

const EditIncidentCategoryFormWrapper = ({ onClose, categoryId }: Props) => {
  const [categoryData, setCategoryData] = useState<any>();

  const [editCategory] = useUpdateIncidentCategoryMutation();

  const { data, isLoading, isFetching } = useGetIncidentCategoryQuery(
    categoryId,
    { skip: !categoryId }
  );

  useEffect(() => {
    setCategoryData(data?.data);
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: IncidentCategoryFormValues = {
    categoryName: categoryData?.categoryName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    categoryName: string().required("Please enter name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: IncidentCategoryFormValues,
    { setSubmitting, resetForm }: FormikHelpers<IncidentCategoryFormValues>
  ) => {
    editCategory({
      id: categoryId,
      body: values,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
             {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <IncidentCategoryForm formikProps={formikProps} onClose={onClose} formType='Update' />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditIncidentCategoryFormWrapper;
