import { IconType } from "react-icons";
import { GiExpense } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import {
  MdAccountBalance,
  MdOutlinePayment,
  MdOutlineUpcoming,
  MdSubscriptions,
} from "react-icons/md";
import { SiExpensify } from "react-icons/si";
import { TbLayoutDashboard } from "react-icons/tb";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type NavItemTypeWithoutChild = {
  label: string;
  path: string;
  icon: IconType;
  name?: string;
  children?: never;
};

type NavItemTypeWithChild = {
  label: string;
  path?: never;
  icon: IconType;
  children?: {
    label: string;
    path: string;
    icon: IconType;
  }[];
};

export type NavItemType = NavItemTypeWithChild | NavItemTypeWithoutChild;

export const FinanceNavigation: NavItemType[] = [
  {
    label: "Subscription",
    icon: MdSubscriptions,
    path: "subscription",
    name: UserModuleNameTypes.NAV_SUBSCRIPTION,
  },
  {
    label: "Dashboard",
    icon: TbLayoutDashboard,
    path: "dashboard",
    name: UserModuleNameTypes.NAV_FINANCE_DASHBOARD,
  },
  {
    label: "Recurring Payments",
    icon: MdOutlinePayment,
    path: "recurring-payments",
    name: UserModuleNameTypes.NAV_RECURRING_PAYMENTS,
  },
  {
    label: "Upcoming Payments",
    icon: MdOutlineUpcoming,
    path: "upcoming-payments",
    name: UserModuleNameTypes.NAV_UPCOMING_PAYMENTS,
  },
  {
    label: "Accounts",
    icon: MdAccountBalance,
    path: "accounts",
    name: UserModuleNameTypes.NAV_ACCOUNTS,
  },
  {
    label: "Expense Category",
    icon: SiExpensify,
    path: "expense-category",
    name: UserModuleNameTypes.NAV_EXPENSE_CATEGORY,
  },
  {
    label: "Expenses",
    icon: GrTransaction,
    path: "expenses",
    name: UserModuleNameTypes.NAV_EXPENSES,
  },

  // New Finance
  {
    label: "Dashboard New",
    icon: TbLayoutDashboard,
    path: "dashboard-new",
    name: UserModuleNameTypes.NAV_FINANCE_DASHBOARD,
  },

  {
    label: "Incomes New",
    icon: GrTransaction,
    path: "income-new",
    // name: UserModuleNameTypes.NAV_EXPENSES,
  },

  {
    label: "Recurring-Incomes",
    icon: GrTransaction,
    path: "recurringincome",
    // name: UserModuleNameTypes.NAV_EXPENSES,
  },

  {
    label: "Cash-FLow",
    icon: GrTransaction,
    path: "cashflow",
    // name: UserModuleNameTypes.NAV_EXPENSES,
  },

  {
    label: "Account",
    icon: MdAccountBalance,
    path: "account-finance",
    // name: UserModuleNameTypes.NAV_EXPENSES,
  },

  {
    label: "Payment Mode",
    icon: MdOutlinePayment,
    path: "payment-mode",
    // name: UserModuleNameTypes.NAV_EXPENSES,
  },

  {
    label: "Expense New",
    icon: GiExpense,
    path: "expense-new",
  },
];
