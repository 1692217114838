import React from "react";
import { FormikProps } from "formik";
import { LeadsFollowupValues } from "src/models/LeadsFollowUp.model";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import useLeadsLabels from "src/hooks/useLeadsLabels";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import moment from "moment";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<LeadsFollowupValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
};

const IsCommunicatedOption = [
  {
    label: "YES",
    value: "YES",
  },
  {
    label: "NO",
    value: "NO",
  },
];

const CommunicationMethodOption = [
  {
    label: "CALL",
    value: "CALL",
  },
  {
    label: "TEXT",
    value: "TEXT",
  },
  {
    label: "EMAIL",
    value: "EMAIL",
  },
];

const LeadFollowupForm = ({ formikProps, onClose, formType }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const { LeadsLabels, isDataLoading } = useLeadsLabels();

  const leadLabelOptions = LeadsLabels?.map((leadsLabel) => {
    return {
      label: leadsLabel?.leadLabelName,
      value: leadsLabel?.leadLabelName,
    };
  });

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add FollowUp" : "Update FollowUp"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        {/* Is Communicated */}
        <ATMSelect
          name="is_communicated"
          value={values?.is_communicated}
          onChange={(newValue) => {
            setFieldValue("is_communicated", newValue);
          }}
          label="Is Communicated"
          placeholder="Select Is Communicated"
          options={IsCommunicatedOption}
        />

        {/* Communication Method */}
        {values?.is_communicated?.value === "YES" && (
          <ATMSelect
            name="communication_method"
            value={values?.communication_method}
            onChange={(newValue) => {
              setFieldValue("communication_method", newValue);
            }}
            label="Communication Method"
            placeholder="Select Communication Method"
            options={CommunicationMethodOption}
          />
        )}

        {/* Next FollowUp Required */}
        {values?.is_communicated?.value === "YES" && (
          <ATMSelect
            name="next_followUp_required"
            value={values?.next_followUp_required}
            onChange={(newValue) => {
              setFieldValue("next_followUp_required", newValue);
            }}
            label="Next FollowUp Required"
            placeholder="Select Next FollowUp Required"
            options={IsCommunicatedOption}
          />
        )}

        {/* Next Followup  Date */}
        {values?.next_followUp_required?.value === "YES" && (
          <ATMDatePicker
            name="next_followUp_date"
            value={values?.next_followUp_date || null}
            onChange={(newValue) =>
              setFieldValue("next_followUp_date", newValue)
            }
            label="Next Followup  Date "
          />
        )}

        {/* Next Followup Time */}
        {values?.next_followUp_required?.value === "YES" && (
          <ATMTextField
            type="time"
            name="next_followup_time"
            value={moment(values.next_followup_time, "HH:mm").format("HH:mm")}
            required
            label="Next Followup Time"
            className="mt-0 rounded"
            onBlur={handleBlur}
            onChange={(e) => {
              let time = moment(e.target.value, "HH:mm");
              setFieldValue("next_followup_time", time);
            }}
          />
        )}

        {/* Leads Label */}
        <ATMSelect
          name="leads_label"
          value={values?.leads_label}
          onChange={(newValue) => {
            setFieldValue("leads_label", newValue);
          }}
          label="Leads Label"
          placeholder="Select Leads Label"
          options={leadLabelOptions}
          isLoading={isDataLoading}
        />

        {/* Summary Note */}
        {values?.is_communicated?.value === "YES" && (
          <ATMTextArea
            name="summary_note"
            value={values?.summary_note}
            onChange={(newValue) => {
              setFieldValue("summary_note", newValue);
            }}
            label="Summary Note"
            placeholder="Select Summary Note"
          />
        )}

        {/* Quotation */}
        {values?.is_communicated?.value === "YES" && (
          <ATMTextArea
            name="quotation"
            value={values?.quotation}
            onChange={(newValue) => {
              setFieldValue("quotation", newValue);
            }}
            label="Quotation"
            placeholder="Select Quotation"
          />
        )}
      </div>
    </MOLFormDialog>
  );
};

export default LeadFollowupForm;
