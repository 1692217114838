import { Form, Formik } from "formik";
import { useUpdateJobApplicationStatusMutation } from "src/services/JobApplicationService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import UpdateJobApplicationStatus from "./UpdateJobApplicationStatus";
import { JobApplicationChangeStatusValues } from "src/models/JobApplication.model";
import { useNavigate } from "react-router-dom";

type Props = {
  onClose: () => void;
  applicationId: string;
  applicationStatus: JobApplicationChangeStatusValues;
};

export type ShortListApplicationFormValue = {
  remark: string;
};

const UpdateJobApplicationStatusWrapper = ({
  onClose,
  applicationId,
  applicationStatus,
}: Props) => {
  const navigate = useNavigate()
  const [updateApplicationStatus, { isLoading }] =
    useUpdateJobApplicationStatusMutation();
  const initialValues = {
    remark: "",
  };
  const validationSchema = object({
    remark: string().required("Please enter remark"),
  });

  const handleSubmit = (values: ShortListApplicationFormValue) => {
    const formattedValues = {
      remark: values?.remark,
      applicationStatus: applicationStatus,
    };
    updateApplicationStatus({
      applicationId: applicationId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          onClose();
          if (applicationStatus === 'ONBOARDED') {
            navigate('/job-onboard/job-onboarding')
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <UpdateJobApplicationStatus
            handleClose={onClose}
            formikProps={formikProps}
            isSubmitting={isLoading}
            heading={
              applicationStatus === "REJECTED"
                ? "Reject Job Application"
                : applicationStatus === "SHORTLISTED"
                  ? "Shortlist Job Application"
                  : "Hold Job Application"
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default UpdateJobApplicationStatusWrapper;
