import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { MeetingMarkAsDoneFormValues } from "./MeetingMarkAsDoneFormWrapper";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import moment from "moment";

type Props = {
  formikProps: FormikProps<MeetingMarkAsDoneFormValues>;
  onClose: () => void;
  meetingType?: any
};

const MeetingMarkAsDoneForm = ({ formikProps, meetingType, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> {meetingType === "INTERNAL_TEAM" ? "End Meeting" : "Mark as done"}</div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Actual End Date */}

        <div>
          <ATMDatePicker
            label="Start Date"
            name="startDate"
            format="DD/MM/YYYY"
            value={values.startDate}
            onChange={(newValue) => setFieldValue("startDate", newValue)}
          />
        </div>

        <div>
          <ATMDatePicker
            label="End Date"
            name="endDate"
            format="DD/MM/YYYY"
            value={values.endDate}
            onChange={(newValue) => setFieldValue("endDate", newValue)}
          />
        </div>
        {/* Actual Start Time */}

        <div className="mb-2">
          <ATMTextField
            type='time'
            name="actualStartTime"
            value={moment(values.actualStartTime).format('HH:mm')}
            required
            label="Actual Start Meeting Time"
            placeholder="Enter Actual Start Meeting Time"
            className="mt-0 rounded"
            onBlur={handleBlur}
            onChange={(e) => {
              let time = moment(e.target.value, 'HH:mm')
              setFieldValue('actualStartTime', time);
            }}
          />
        </div>

        {/* Actual End Time */}
        <div className="mb-2">

          <ATMTextField
            type='time'
            name="actualEndTime"
            value={moment(values.actualEndTime).format('HH:mm')}
            required
            label="Actual End Meeting Time"
            placeholder="Enter Actual End Meeting Time"
            className="mt-0 rounded"
            onBlur={handleBlur}
            onChange={(e) => {
              let time = moment(e.target.value, 'HH:mm A')
              setFieldValue('actualEndTime', time);
            }}
          />
        </div>
      </div>

      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default MeetingMarkAsDoneForm;
