import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/Finance-New-Slices/CashFlowSlice";
import { RootState } from "src/redux/store";
import { useCaseFlowListQuery } from "src/services/Finance-New-Services/CashFlowService";
import AddCashFlowFormWrapper from "../Add/AddCashFlowFormWrapper";
import CashFlowListing from "./CashFlowListing";
type Props = {};

const CashFlowListingListingWrapper = (props: Props) => {
  const [isOpenPaymentInDialog, setIsOpenPaymentInDialog] = useState(false);
  const dispatch = useDispatch();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    acountFilter,
    paymentModeFilter,
    transactionType,
    dateFilter,
  } = useSelector((state: RootState) => state.cashFlow);

  const { data, isFetching, isLoading } = useCaseFlowListQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "description",
      "transactionType",
      "accountName",
      "paymentModeName",
      "addedByName",
    ],
    page: page,
    filterBy: [
      {
        fieldName: "transactionType",
        value: transactionType?.map((ele: any) => ele?.value) || [],
      },
      {
        fieldName: "category",
        value: "",
      },
      {
        fieldName: "paymentModeName",
        value: paymentModeFilter?.map((ele: any) => ele?.label) || [],
      },
      {
        fieldName: "accountName",
        value: acountFilter?.map((ele: any) => ele?.label) || [],
      },
    ],
    dateFilter: dateFilter,
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });
  const columns = [
    {
      field: "paymentDate",
      headerName: "Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>{moment(row?.paymentDate).format("DD MMM YYYY")}</div>
      ),
    },
    {
      field: "totalCalculatedAmount",
      headerName: "Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          {row?.transactionType === "OUT" ? (
            <div className="text-red-500">
              {" "}
              {row?.currencySymbol}
              {row?.totalCalculatedAmount}
            </div>
          ) : (
            <div className="text-green-500">
              {" "}
              {row?.currencySymbol}
              {row?.totalCalculatedAmount}
            </div>
          )}
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.financeCategoryName || "-"}</div>,
    },
    {
      field: "account",
      headerName: "Account",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.accountName || "-"}</div>,
    },
    {
      field: "paymentMode",
      headerName: "Mode",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => <div>{row?.paymentModeName || "-"}</div>,
    },
  ];
  // Setting Team Members Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <>
      <CashFlowListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenPaymentInDialog && (
        <AddCashFlowFormWrapper
          onClose={() => setIsOpenPaymentInDialog(false)}
          paymentId={""}
        />
      )}
    </>
  );
};

export default CashFlowListingListingWrapper;
