import { FormikProps } from "formik";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMRadioButtonGroup from "src/components/UI/atoms/formFields/ATMRadioButtonGroup/ATMRadioButtonGroup";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";
import { useExpenesCategory } from "src/hooks/useExpenesCategory";

type Props = {
  formikProps: FormikProps<any>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};


const ExpensesListFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;
  const { expenesCategory, isDataLoading } = useExpenesCategory();

  return (
 <MOLFilterDialog 
 title={"Filters"}
 onClose={onClose}
 isSubmitting={isSubmitting}
 clearFilter={onReset}
 handleSubmit={handleSubmit}
 open={open}
 >
  <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">
        {/* Created At */}
        <div className="mb-2">
          <div className="flex gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>

        {/* expenseCategoryId  */}
        <div>
          <ATMSelect
            required
            name="expenseCategoryId"
            label="Category"
            placeholder="Select Category"
            value={values.expenseCategoryId}
            options={expenesCategory?.map((ele: any) => {
              return {
                label: ele?.categoryName,
                value: ele?._id,
              };
            })}
            isLoading={isDataLoading}
            onChange={(newValue) => {
              setFieldValue("expenseCategoryId", newValue);
            }}
          />
        </div>
        <div>
          <ATMRadioButtonGroup
            label="GST GSTApplicable"
            required={false}
            value={values.GSTApplicable}
            options={[
              {
                label: "Yes",
                value: "true",
              },
              {
                label: "No",
                value: "false",
              },
            ]}
            onChange={(newValue) => {
              setFieldValue("GSTApplicable", newValue);
            }}
          />
        </div>
      </div>
 </MOLFilterDialog>
  );
};

export default ExpensesListFilterFormDialog;
