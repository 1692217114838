import React from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useNavigate } from "react-router-dom";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  isClientRoute?: boolean;
  addNew: () => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const ChangeRequestList = ({
  columns,
  rows,
  isClientRoute,
  addNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      {isClientRoute ? (
        <div className="flex flex-col h-full">
          {/* Page Header */}
         <div className="flex justify-between px-2 ">
         <ATMPageHeader
            pageTitle="Change Request"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_CHANGE_REQUEST_ADD)
            }
            buttonProps={{
              btnName: "Add New",
              onClick: () => addNew(),
            }}
          />
         </div>

          {/* Table */}
          <div className="flex flex-col flex-1 overflow-auto">
            <ATMTable
              columns={columns}
              rows={rows}
              rowExtraClasses={() => "min-h-[50px]"}
              disableRowClick={
                !isAuthorized(
                  UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW
                )
              }
              onRowClick={(row) =>
                navigate(`/change-request/${row?._id}/ticket-details`)
              }
              isLoading={isTableLoading}
            />
          </div>

          <div className="p-2 py-2 md:p-4">
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      ) : (
        <SideNavLayout>
          <div className="flex flex-col">
            {/* Page Header */}

            <div>
              <ATMPageHeader
                pageTitle="Change Request"
                searchValue={searchValue}
                onSearchChange={setSearchValue}
                hideAddButton={
                  !isAuthorized(UserModuleNameTypes.ACTION_CHANGE_REQUEST_ADD)
                }
                buttonProps={{
                  btnName: "Add New",
                  onClick: () => addNew(),
                }}
              />
            </div>

            {/* Table */}
            <div className="flex flex-col overflow-hidden">
              <div className="flex flex-col overflow-auto">
                <ATMTable
                  columns={columns}
                  rows={rows}
                  rowExtraClasses={() => "h-[50px]"}
                  disableRowClick={
                    !isAuthorized(
                      UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW
                    )
                  }
                  onRowClick={(row) => navigate(`${row?._id}/ticket-details`)}
                  isLoading={isTableLoading}
                />
              </div>

              <div>
                <ATMPagination
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={setPage}
                  onRowsPerPageChange={setRowsPerPage}
                  rowCount={totalItems}
                  rows={rows}
                />
              </div>
            </div>
          </div>
        </SideNavLayout>
      )}
    </>
  );
};

export default ChangeRequestList;
