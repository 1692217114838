import { useState, useEffect } from "react";
import { useGetAllLayerOfProjectQuery } from "src/services/ProjectServices";

export const useGetAllLayerProject = () => {
  const [allProjectLayer, setAllProjectLayer] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllLayerOfProjectQuery("");

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setAllProjectLayer(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { allProjectLayer, isDataLoading };
};
