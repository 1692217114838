import React, { useEffect, useState } from 'react'
import SMSActivitiesTab from './SMSActivitiesTab'
import { useParams } from 'react-router-dom';
import { useGetContactLeadAllLogsQuery } from 'src/services/ContactService';

const SMSActivitiesTabWrapper = () => {
    const { leadId } = useParams();
    const [smsData, setSmsData] = useState<any>();
    const { data, isLoading, isFetching } = useGetContactLeadAllLogsQuery({
      limit: 10,
      searchValue: "",
      params: ["description"],
      page: 0,
      filterBy: [
        {
          fieldName: "leadId",
          value: leadId,
        },
        {
          fieldName: "logType",
          value: "SMS",
        },
      ],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });
  
    useEffect(() => {
      if (!isLoading && !isFetching) {
        setSmsData(data?.data);
      }
    }, [data, isLoading, isFetching]);
  return (
    <SMSActivitiesTab smsData={smsData} isLoading={isLoading || isFetching} />
  )
}

export default SMSActivitiesTabWrapper