import apiSlice from "../ApiSlice";

export const financeCategoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //getFinanceCategory
    getFinanceCategory: builder.query({
      query: () => {
        return {
          url: "/finance-categroy",
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetFinanceCategoryQuery } = financeCategoryApi;
