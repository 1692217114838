import React from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import AddTaskDialogForm from "./AddTaskDialogForm";
import { useGetAllCodioticMembersQuery } from "src/services/TeamServices";
import { useAddContactLeadTaskMutation } from "src/services/ContactService";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
};

export type addContactTaskValuesType = {
  taskName: string;
  activityDate: any;
  activityTime: any;
  taskType: string;
  priority: string;
  queue: any;
  assignedTo: string;
  description: any;
  wantToAddReminder: string;
  reminderForDate: any;
};

const AddTaskDialogFormWrapper = ({ onClose }: Props) => {
  const {leadId } = useParams();

  const [addLeadTask] = useAddContactLeadTaskMutation();

  const { data: teamMemberData } = useGetAllCodioticMembersQuery({
    limit: 10,
    searchValue: "",
    params: ["name", "mobile", "email"],
    page: 1,
    filterBy: [{ fieldName: "isActive", value: true }],
    dateFilter: {},
    orderBy: "name",
    orderByValue: 1,
    isPaginationRequired: false,
  });

  const userOptions = teamMemberData?.data?.map((el: any) => {
    return {
      label: el?.name,
      value: el?._id,
    };
  });

  // Form Initial Values
  const initialValues: addContactTaskValuesType = {
    taskName: "",
    activityDate: null,
    activityTime: null,
    taskType: "",
    priority: "",
    queue: [],
    assignedTo: "",
    description: "",
    wantToAddReminder: "",
    reminderForDate: null,
  };

  // Handle Submit
  const handleSubmit = (
    values: addContactTaskValuesType,
    { setSubmitting, resetForm }: FormikHelpers<addContactTaskValuesType>
  ) => {
    const formatedValues = {
      leadId: leadId,
      taskName: values?.taskName,
      activityDate: values?.activityDate,
      priority: values?.priority,
      taskAction: values?.taskType,
      assignedToId: values?.assignedTo,
      description: values?.description,
      wantToAddReminder:
        values?.wantToAddReminder === "No reminder" ? false : true,
      reminderOn: values?.wantToAddReminder,
      reminderForDate: values?.reminderForDate || "",
    };

    addLeadTask(formatedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog className="notelogDialog" open maxWidth="sm" fullWidth>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formikProps) => (
          <Form>
            <AddTaskDialogForm
              userOptions={userOptions}
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddTaskDialogFormWrapper;
