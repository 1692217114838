import React from 'react'

type Props = {}

const FinanceDashboard = (props: Props) => {
  return (
    <div>FinanceDashboard</div>
  )
}

export default FinanceDashboard