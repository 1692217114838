import React, { useState, useEffect } from "react";
import { FieldArray, FormikProps } from "formik";
import { RiDeleteBin5Line } from "react-icons/ri";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLProjecttDropdown from "src/components/UI/molecules/MOLProjectDropdown/MOLProjectDropdown";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { EditRequirementFormValues } from "src/models/Requirement.model";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { showToast } from "src/utils/showToaster";
import { useGetModulesByProjectIdQuery } from "src/services/ModuleServices";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import { GrFormAdd } from "react-icons/gr";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

type Props = {
  formikProps: FormikProps<EditRequirementFormValues>;
  featureType: any;
  id: any;
  currentPath: any;
  fieldType: any;
};

const EditRequirement = ({
  formikProps,
  featureType,
  fieldType,
  id,
  currentPath,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const [moduleOptions, setModuleOptions] = useState([]);

  // get module
  const {
    isLoading: isModuleLoading,
    isFetching: isModuleFetching,
    data: moduleData,
  } = useGetModulesByProjectIdQuery(values.projectId?._id || "", {
    skip: !values.projectId?._id,
  });

  useEffect(() => {
    if (!isModuleLoading && !isModuleFetching) {
      const filteredOption = moduleData?.data?.map((ele: any) => {
        return {
          label: ele?.moduleName,
          _id: ele?._id,
        };
      });
      setModuleOptions(filteredOption);
    }
  }, [isModuleLoading, isModuleFetching, moduleData]);

  return (
    <>
      {currentPath === `/project/${id}/details/edit` ? (
        <div className="p-4 flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <div className="text-xl font-medium"> Edit New Requirement </div>
          </div>
          <div className="flex flex-col gap-4">
            {/* Project  */}
            <div className="">
              <MOLProjecttDropdown
                name="projectId"
                placeholder="Project Type"
                value={values.projectId}
                onChange={(newValue) => {
                  setFieldValue("projectId", newValue);
                  if (newValue?._id !== values?.projectId?._id) {
                    setFieldValue("moduleId", "");
                  }
                }}
                onBlur={handleBlur}
              />
            </div>

            {/* Module */}
            <div className="">
              <ATMSelect
                name="moduleId"
                value={values.moduleId}
                onChange={(newValue: any) => {
                  setFieldValue("moduleId", newValue);
                }}
                onBlur={handleBlur}
                label="Module"
                placeholder="Select module"
                options={moduleOptions}
                isLoading={isModuleLoading || isModuleFetching}
                renderOption={(option, { context }) => {
                  if (context === "menu") {
                    return <div className="capitalize"> {option.label} </div>;
                  } else {
                    return <div className="capitalize"> {option.label} </div>;
                  }
                }}
                isOptionEqualToValue={(option, selected) => {
                  return (
                    selected?.findIndex(
                      (selectedValue: any) => selectedValue?._id === option._id
                    ) > -1
                  );
                }}
                isSearchedOption={(option, searchValue) => {
                  return searchValue
                    ? option?.data?.label?.toLowerCase()?.includes(searchValue)
                    : true;
                }}
              />
            </div>

            {/* Feature */}
            <div className="flex-1">
              <ATMTextField
                name="featureName"
                value={values.featureName}
                onChange={(e) => setFieldValue("featureName", e.target.value)}
                label="Feature Name"
                placeholder="Enter feature name"
                onBlur={handleBlur}
              />
            </div>

            {/* feature type dropdown */}
            <div className="">
              <ATMSelect
                name="featureType"
                value={values.featureType}
                label="Feature Type"
                placeholder="Feature Type"
                options={featureType}
                onChange={(newValue) => {
                  if (newValue?.value === "VIEW") {
                    showToast("error", "you can not select view");
                  } else if (newValue?.value === "TABLE") {
                    setFieldValue("featureType", newValue);
                    setFieldValue("formFields", []);
                  } else if (newValue?.value === "FORM") {
                    setFieldValue("featureType", newValue);
                    setFieldValue("tableFields", []);
                    setFieldValue("viewFields", []);
                    setFieldValue("columns", []);
                    setFieldValue("table", []);
                    setFieldValue("tableName", "");
                  } else if (newValue?.value === "OTHER") {
                    setFieldValue("featureType", newValue);
                    setFieldValue("tableFields", []);
                    setFieldValue("viewFields", []);
                    setFieldValue("columns", []);
                    setFieldValue("table", []);
                    setFieldValue("tableName", "");
                    setFieldValue("formFields", []);
                  }
                }}
                onBlur={handleBlur}
              />
            </div>

            {values?.featureType?.value === "FORM" ? (
              <>
                {/* form fields array  */}
                <p className=" text-md text-red-500 underline">Form Fields</p>

                <FieldArray name="formFields">
                  {({ push, remove }) => (
                    <div className="flex flex-col gap-6">
                      {values?.formFields?.map((formFields, index) => (
                        <div key={index} className="flex gap-4 items-end">
                          {/* // form fields name */}
                          <div className="">
                            <ATMTextField
                              name={`formFields[${index}].name`}
                              value={values.formFields[index].name}
                              onChange={(e) =>
                                setFieldValue(
                                  `formFields[${index}].name`,
                                  e.target.value
                                )
                              }
                              label=" Field Name"
                              placeholder=" Field Name"
                              onBlur={handleBlur}
                            />
                          </div>

                          {/* server remark */}
                          <div className="">
                            <ATMSelect
                              name={`formFields[${index}].type`}
                              value={values.formFields[index].type}
                              label="Field Type"
                              placeholder="Field Type"
                              options={fieldType}
                              onChange={(e) =>
                                setFieldValue(`formFields[${index}].type`, e)
                              }
                              onBlur={handleBlur}
                            />
                          </div>

                          <div>
                            <ATMTextField
                              name={`formFields[${index}].validation`}
                              value={values.formFields[index].validation}
                              onBlur={handleBlur}
                              onChange={(e) =>
                                setFieldValue(
                                  `formFields[${index}].validation`,
                                  e.target.value
                                )
                              }
                              label="Field Validation"
                              placeholder="Field Validation"
                            />
                          </div>

                          <div>
                            <ATMTextField
                              name={`formFields[${index}].logic`}
                              value={values.formFields[index].logic}
                              onChange={(e) =>
                                setFieldValue(
                                  `formFields[${index}].logic`,
                                  e.target.value
                                )
                              }
                              label="Additional Logic"
                              placeholder="Additional Logic"
                              onBlur={handleBlur}
                            />
                          </div>

                          <div>
                            <ATMTextField
                              name={`formFields[${index}].dataSource`}
                              value={values.formFields[index].dataSource}
                              onChange={(e) =>
                                setFieldValue(
                                  `formFields[${index}].dataSource`,
                                  e.target.value
                                )
                              }
                              label=" Data Source"
                              placeholder=" Data Source"
                              onBlur={handleBlur}
                            />
                          </div>

                          <div>
                            <ATMCheckbox
                              label="Required"
                              checked={values.formFields[index].isRequired}
                              onChange={(checked) => {
                                setFieldValue(
                                  `formFields[${index}].isRequired`,
                                  checked
                                );
                              }}
                            />
                          </div>

                          {/* Delete Button */}
                          <button
                            type="button"
                            className={`${getInputHeight(
                              "small"
                            )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                            onClick={() => remove(index)}
                          >
                            <RiDeleteBin5Line size={20} />
                          </button>
                        </div>
                      ))}

                      {/* Add More Button */}
                      <button
                        type="button"
                        onClick={() =>
                          push({
                            name: "",
                            type: "",
                            validation: "",
                            logic: "",
                            isRequired: false,
                          })
                        }
                        className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
                      >
                        Add{" "}
                        {values?.formFields?.length ? "More" : "Form Fields"}
                        <span>
                          <GrFormAdd
                            color="#ADB8C8"
                            size="1.5em"
                            className="font-semibold"
                          />
                        </span>
                      </button>
                    </div>
                  )}
                </FieldArray>
              </>
            ) : null}

            {values?.featureType?.value === "TABLE" ? (
              <>
                {/* table fields  */}

                <div className="">
                  <ATMTextField
                    name={`tableName`}
                    value={values.tableName}
                    onChange={(e) => setFieldValue(`tableName`, e.target.value)}
                    label=" Table Name"
                    placeholder=" Table Name"
                    onBlur={handleBlur}
                  />
                </div>
                <p className=" text-md text-red-500 underline">Table Actions</p>

                <FieldArray name="table">
                  {({ push, remove }) => (
                    <>
                      <div className="grid grid-cols-6 gap-6">
                        {values?.table?.map((table, index) => (
                          <div key={index} className="flex gap-4 items-end">
                            {/* // form fields name */}
                            <div className="">
                              <ATMTextField
                                name={`table[${index}].tableAction`}
                                value={values.table[index].tableAction}
                                onChange={(e) =>
                                  setFieldValue(
                                    `table[${index}].tableAction`,
                                    e.target.value
                                  )
                                }
                                label=" Table Actions"
                                placeholder="Enter Table Actions"
                                onBlur={handleBlur}
                              />
                            </div>

                            {/* Delete Button */}
                            <button
                              type="button"
                              className={`${getInputHeight(
                                "small"
                              )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin5Line size={20} />
                            </button>
                          </div>
                        ))}
                      </div>
                      {/* Add More Button */}
                      <button
                        type="button"
                        onClick={() =>
                          push({
                            tableAction: "",
                          })
                        }
                        className="rounded border-dashed border border-slate-500 py-2 px-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
                      >
                        Add {values?.table?.length ? "More" : "Table Fields"}
                        <span>
                          <GrFormAdd
                            color="#ADB8C8"
                            size="1.5em"
                            className="font-semibold"
                          />
                        </span>
                      </button>
                    </>
                  )}
                </FieldArray>
                <p className=" text-md text-red-500 underline">Column Names</p>
                <FieldArray name="tableFields">
                  {({ push, remove }) => (
                    <>
                      <div className="grid grid-cols-6 gap-6">
                        {values?.tableFields?.map((tableFields, index) => (
                          <div key={index} className="flex gap-4 items-end">
                            {/* // form fields name */}
                            <div className="">
                              <ATMTextField
                                name={`tableFields[${index}].columnName`}
                                value={values.tableFields[index].columnName}
                                onChange={(e) =>
                                  setFieldValue(
                                    `tableFields[${index}].columnName`,
                                    e.target.value
                                  )
                                }
                                label=" Column Name"
                                placeholder=" Column Name"
                                onBlur={handleBlur}
                              />
                            </div>

                            {/* Delete Button */}
                            <button
                              type="button"
                              className={`${getInputHeight(
                                "small"
                              )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin5Line size={20} />
                            </button>
                          </div>
                        ))}
                      </div>
                      {/* Add More Button */}
                      <button
                        type="button"
                        onClick={() =>
                          push({
                            columnName: "",
                          })
                        }
                        className="rounded border-dashed border border-slate-500 py-2 px-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
                      >
                        Add{" "}
                        {values?.tableFields?.length ? "More" : "Table Fields"}
                        <span>
                          <GrFormAdd
                            color="#ADB8C8"
                            size="1.5em"
                            className="font-semibold"
                          />
                        </span>
                      </button>
                    </>
                  )}
                </FieldArray>
                <p className=" text-md text-red-500 underline">
                  Column Actions
                </p>

                <FieldArray name="columns">
                  {({ push, remove }) => (
                    <>
                      <div className="grid grid-cols-6 gap-6">
                        {values?.columns?.map((columns, index) => (
                          <div key={index} className="flex gap-4 items-end">
                            {/* // form fields name */}
                            <div className="">
                              <ATMTextField
                                name={`columns[${index}].columnActions`}
                                value={values.columns[index].columnActions}
                                onChange={(e) =>
                                  setFieldValue(
                                    `columns[${index}].columnActions`,
                                    e.target.value
                                  )
                                }
                                label="Column Actions"
                                placeholder="Enter Column Actions"
                                onBlur={handleBlur}
                              />
                            </div>

                            {/* Delete Button */}
                            <button
                              type="button"
                              className={`${getInputHeight(
                                "small"
                              )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin5Line size={20} />
                            </button>
                          </div>
                        ))}
                      </div>
                      {/* Add More Button */}
                      <button
                        type="button"
                        onClick={() =>
                          push({
                            columnActions: "",
                          })
                        }
                        className="rounded border-dashed border border-slate-500 py-2 px-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
                      >
                        Add{" "}
                        {values?.columns?.length ? "More" : "Column Actions"}
                        <span>
                          <GrFormAdd
                            color="#ADB8C8"
                            size="1.5em"
                            className="font-semibold"
                          />
                        </span>
                      </button>
                    </>
                  )}
                </FieldArray>
              </>
            ) : null}

            {/* View Fields  */}
            {values?.featureType?.value === "VIEW" ? (
              <>
                <p className=" text-md text-red-500 underline">View Fields</p>
                <FieldArray name="viewFields">
                  {({ push, remove }) => (
                    <div className="flex flex-col gap-6">
                      {values?.viewFields?.map((viewFields, index) => (
                        <div key={index} className="flex gap-4 items-end">
                          {/* // view tabs name */}
                          <div className="">
                            <ATMTextField
                              name={`viewFields[${index}].tabs`}
                              value={values.viewFields[index].tabs}
                              onChange={(e) =>
                                setFieldValue(
                                  `viewFields[${index}].tabs`,
                                  e.target.value
                                )
                              }
                              label="View Tabs"
                              placeholder=" View Tabs"
                              onBlur={handleBlur}
                            />
                          </div>

                          {/* ddetailsVisible */}
                          <div className="">
                            <ATMTextField
                              name={`viewFields[${index}].detailsVisible`}
                              value={values.viewFields[index].detailsVisible}
                              onChange={(e) =>
                                setFieldValue(
                                  `viewFields[${index}].detailsVisible`,
                                  e.target.value
                                )
                              }
                              label="Details Visible"
                              placeholder="Details Visible"
                              onBlur={handleBlur}
                            />
                          </div>

                          <div>
                            <ATMTextField
                              name={`viewFields[${index}].logic`}
                              value={values.viewFields[index].logic}
                              onChange={(e) =>
                                setFieldValue(
                                  `viewFields[${index}].logic`,
                                  e.target.value
                                )
                              }
                              label="View Logic"
                              placeholder="View Logic"
                              onBlur={handleBlur}
                            />
                          </div>

                          {/* Delete Button */}
                          <button
                            type="button"
                            className={`${getInputHeight(
                              "small"
                            )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                            onClick={() => remove(index)}
                          >
                            <RiDeleteBin5Line size={20} />
                          </button>
                        </div>
                      ))}

                      {/* Add More Button */}
                      <button
                        type="button"
                        onClick={() =>
                          push({
                            tabs: "",
                            detailsVisible: "",
                            logic: "",
                          })
                        }
                        className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
                      >
                        Add{" "}
                        {values?.viewFields?.length ? "More" : "View Fields"}
                        <span>
                          <GrFormAdd
                            color="#ADB8C8"
                            size="1.5em"
                            className="font-semibold"
                          />
                        </span>
                      </button>
                    </div>
                  )}
                </FieldArray>
              </>
            ) : null}

            {/* Requirement Logic */}
            <div className="z-0">
              <MOLMarkdownEditor
                label="Requirement Logic"
                value={values.requirementLogic}
                placeholder="Write requirement logic or drag your files here...."
                onChange={(newValue: any) => {
                  setFieldValue("requirementLogic", newValue);
                }}
              />
            </div>
            {/* Requirement Flow */}
            <div className="z-0">
              <MOLMarkdownEditor
                label="Requirement Flow"
                value={values.requirementFlow}
                placeholder="Write requirement flow or drag your files here...."
                onChange={(newValue: any) => {
                  setFieldValue("requirementFlow", newValue);
                }}
              />
            </div>
          </div>
          <div>
            <ATMLoadingButton isLoading={isSubmitting} type="submit">
              Save
            </ATMLoadingButton>
          </div>
        </div>
      ) : (
        <SideNavLayout>
          <div className="p-4 flex flex-col gap-6">
            <div className="flex justify-between items-center">
              <div className="text-xl font-medium"> Edit New Requirement </div>
            </div>
            <div className="flex flex-col gap-4">
              {/* Project  */}
              <div className="">
                <MOLProjecttDropdown
                  name="projectId"
                  placeholder="Project Type"
                  value={values.projectId}
                  onChange={(newValue) => {
                    setFieldValue("projectId", newValue);
                    if (newValue?._id !== values?.projectId?._id) {
                      setFieldValue("moduleId", "");
                    }
                  }}
                  onBlur={handleBlur}
                />
              </div>

              {/* Module */}
              <div className="">
                <ATMSelect
                  name="moduleId"
                  value={values.moduleId}
                  onChange={(newValue: any) => {
                    setFieldValue("moduleId", newValue);
                  }}
                  onBlur={handleBlur}
                  label="Module"
                  placeholder="Select module"
                  options={moduleOptions}
                  isLoading={isModuleLoading || isModuleFetching}
                  renderOption={(option, { context }) => {
                    if (context === "menu") {
                      return <div className="capitalize"> {option.label} </div>;
                    } else {
                      return <div className="capitalize"> {option.label} </div>;
                    }
                  }}
                  isOptionEqualToValue={(option, selected) => {
                    return (
                      selected?.findIndex(
                        (selectedValue: any) =>
                          selectedValue?._id === option._id
                      ) > -1
                    );
                  }}
                  isSearchedOption={(option, searchValue) => {
                    return searchValue
                      ? option?.data?.label
                          ?.toLowerCase()
                          ?.includes(searchValue)
                      : true;
                  }}
                />
              </div>

              {/* Feature */}
              <div className="flex-1">
                <ATMTextField
                  name="featureName"
                  value={values.featureName}
                  onChange={(e) => setFieldValue("featureName", e.target.value)}
                  label="Feature Name"
                  placeholder="Enter feature name"
                  onBlur={handleBlur}
                />
              </div>

              {/* feature type dropdown */}
              <div className="">
                <ATMSelect
                  name="featureType"
                  value={values.featureType}
                  label="Feature Type"
                  placeholder="Feature Type"
                  options={featureType}
                  onChange={(newValue) => {
                    if (newValue?.value === "VIEW") {
                      showToast("error", "you can not select view");
                    } else if (newValue?.value === "TABLE") {
                      setFieldValue("featureType", newValue);
                      setFieldValue("formFields", []);
                    } else if (newValue?.value === "FORM") {
                      setFieldValue("featureType", newValue);
                      setFieldValue("tableFields", []);
                      setFieldValue("viewFields", []);
                      setFieldValue("columns", []);
                      setFieldValue("table", []);
                      setFieldValue("tableName", "");
                    } else if (newValue?.value === "OTHER") {
                      setFieldValue("featureType", newValue);
                      setFieldValue("tableFields", []);
                      setFieldValue("viewFields", []);
                      setFieldValue("columns", []);
                      setFieldValue("table", []);
                      setFieldValue("tableName", "");
                      setFieldValue("formFields", []);
                    }
                  }}
                  onBlur={handleBlur}
                />
              </div>

              {values?.featureType?.value === "FORM" ? (
                <>
                  {/* form fields array  */}
                  <p className=" text-md text-red-500 underline">Form Fields</p>

                  <FieldArray name="formFields">
                    {({ push, remove }) => (
                      <div className="flex flex-col gap-6">
                        {values?.formFields?.map((formFields, index) => (
                          <div key={index} className="flex gap-4 items-end">
                            {/* // form fields name */}
                            <div className="">
                              <ATMTextField
                                name={`formFields[${index}].name`}
                                value={values.formFields[index].name}
                                onChange={(e) =>
                                  setFieldValue(
                                    `formFields[${index}].name`,
                                    e.target.value
                                  )
                                }
                                label=" Field Name"
                                placeholder=" Field Name"
                                onBlur={handleBlur}
                              />
                            </div>

                            {/* server remark */}
                            <div className="">
                              <ATMSelect
                                name={`formFields[${index}].type`}
                                value={values.formFields[index].type}
                                label="Field Type"
                                placeholder="Field Type"
                                options={fieldType}
                                onChange={(e) =>
                                  setFieldValue(`formFields[${index}].type`, e)
                                }
                                onBlur={handleBlur}
                              />
                            </div>

                            <div>
                              <ATMTextField
                                name={`formFields[${index}].validation`}
                                value={values.formFields[index].validation}
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  setFieldValue(
                                    `formFields[${index}].validation`,
                                    e.target.value
                                  )
                                }
                                label="Field Validation"
                                placeholder="Field Validation"
                              />
                            </div>

                            <div>
                              <ATMTextField
                                name={`formFields[${index}].logic`}
                                value={values.formFields[index].logic}
                                onChange={(e) =>
                                  setFieldValue(
                                    `formFields[${index}].logic`,
                                    e.target.value
                                  )
                                }
                                label="Additional Logic"
                                placeholder="Additional Logic"
                                onBlur={handleBlur}
                              />
                            </div>

                            <div>
                              <ATMTextField
                                name={`formFields[${index}].dataSource`}
                                value={values.formFields[index].dataSource}
                                onChange={(e) =>
                                  setFieldValue(
                                    `formFields[${index}].dataSource`,
                                    e.target.value
                                  )
                                }
                                label=" Data Source"
                                placeholder=" Data Source"
                                onBlur={handleBlur}
                              />
                            </div>

                            <div>
                              <ATMCheckbox
                                label="Required"
                                checked={values.formFields[index].isRequired}
                                onChange={(checked) => {
                                  setFieldValue(
                                    `formFields[${index}].isRequired`,
                                    checked
                                  );
                                }}
                              />
                            </div>

                            {/* Delete Button */}
                            <button
                              type="button"
                              className={`${getInputHeight(
                                "small"
                              )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin5Line size={20} />
                            </button>
                          </div>
                        ))}

                        {/* Add More Button */}
                        <button
                          type="button"
                          onClick={() =>
                            push({
                              name: "",
                              type: "",
                              validation: "",
                              logic: "",
                              isRequired: false,
                            })
                          }
                          className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px] "
                        >
                          Add{" "}
                          {values?.formFields?.length ? "More" : "Form Fields"}
                          <span>
                            <GrFormAdd
                              color="#ADB8C8"
                              size="1.5em"
                              className="font-semibold"
                            />
                          </span>
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </>
              ) : null}

              {values?.featureType?.value === "TABLE" ? (
                <>
                  {/* table fields  */}

                  <div className="">
                    <ATMTextField
                      name={`tableName`}
                      value={values.tableName}
                      onChange={(e) =>
                        setFieldValue(`tableName`, e.target.value)
                      }
                      label=" Table Name"
                      placeholder=" Table Name"
                      onBlur={handleBlur}
                    />
                  </div>
                  <p className=" text-md text-red-500 underline">
                    Table Actions
                  </p>

                  <FieldArray name="table">
                    {({ push, remove }) => (
                      <>
                        <div className="grid grid-cols-6 gap-6">
                          {values?.table?.map((table, index) => (
                            <div key={index} className="flex gap-4 items-end">
                              {/* // form fields name */}
                              <div className="">
                                <ATMTextField
                                  name={`table[${index}].tableAction`}
                                  value={values.table[index].tableAction}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `table[${index}].tableAction`,
                                      e.target.value
                                    )
                                  }
                                  label=" Table Actions"
                                  placeholder="Enter Table Actions"
                                  onBlur={handleBlur}
                                />
                              </div>

                              {/* Delete Button */}
                              <button
                                type="button"
                                className={`${getInputHeight(
                                  "small"
                                )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin5Line size={20} />
                              </button>
                            </div>
                          ))}
                        </div>
                        {/* Add More Button */}
                        <button
                          type="button"
                          onClick={() =>
                            push({
                              tableAction: "",
                            })
                          }
                          className="rounded border-dashed border border-slate-500 py-2 px-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
                        >
                          Add {values?.table?.length ? "More" : "Table Fields"}
                          <span>
                            <GrFormAdd
                              color="#ADB8C8"
                              size="1.5em"
                              className="font-semibold"
                            />
                          </span>
                        </button>
                      </>
                    )}
                  </FieldArray>
                  <p className=" text-md text-red-500 underline">
                    Column Names
                  </p>
                  <FieldArray name="tableFields">
                    {({ push, remove }) => (
                      <>
                        <div className="grid grid-cols-6 gap-6">
                          {values?.tableFields?.map((tableFields, index) => (
                            <div key={index} className="flex gap-4 items-end">
                              {/* // form fields name */}
                              <div className="">
                                <ATMTextField
                                  name={`tableFields[${index}].columnName`}
                                  value={values.tableFields[index].columnName}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `tableFields[${index}].columnName`,
                                      e.target.value
                                    )
                                  }
                                  label=" Column Name"
                                  placeholder=" Column Name"
                                  onBlur={handleBlur}
                                />
                              </div>

                              {/* Delete Button */}
                              <button
                                type="button"
                                className={`${getInputHeight(
                                  "small"
                                )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin5Line size={20} />
                              </button>
                            </div>
                          ))}
                        </div>
                        {/* Add More Button */}
                        <button
                          type="button"
                          onClick={() =>
                            push({
                              columnName: "",
                            })
                          }
                          className="rounded border-dashed border border-slate-500 py-2 px-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px]"
                        >
                          Add{" "}
                          {values?.tableFields?.length
                            ? "More"
                            : "Table Fields"}
                          <span>
                            <GrFormAdd
                              color="#ADB8C8"
                              size="1.5em"
                              className="font-semibold"
                            />
                          </span>
                        </button>
                      </>
                    )}
                  </FieldArray>
                  <p className=" text-md text-red-500 underline">
                    Column Actions
                  </p>

                  <FieldArray name="columns">
                    {({ push, remove }) => (
                      <>
                        <div className="grid grid-cols-6 gap-6">
                          {values?.columns?.map((columns, index) => (
                            <div key={index} className="flex gap-4 items-end">
                              {/* // form fields name */}
                              <div className="">
                                <ATMTextField
                                  name={`columns[${index}].columnActions`}
                                  value={values.columns[index].columnActions}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `columns[${index}].columnActions`,
                                      e.target.value
                                    )
                                  }
                                  label="Column Actions"
                                  placeholder="Enter Column Actions"
                                  onBlur={handleBlur}
                                />
                              </div>

                              {/* Delete Button */}
                              <button
                                type="button"
                                className={`${getInputHeight(
                                  "small"
                                )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin5Line size={20} />
                              </button>
                            </div>
                          ))}
                        </div>
                        {/* Add More Button */}
                        <button
                          type="button"
                          onClick={() =>
                            push({
                              columnActions: "",
                            })
                          }
                          className="rounded border-dashed border border-slate-500 py-2 px-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px] "
                        >
                          Add{" "}
                          {values?.columns?.length ? "More" : "Column Actions"}
                          <span>
                            <GrFormAdd
                              color="#ADB8C8"
                              size="1.5em"
                              className="font-semibold"
                            />
                          </span>
                        </button>
                      </>
                    )}
                  </FieldArray>
                </>
              ) : null}

              {/* View Fields  */}
              {values?.featureType?.value === "VIEW" ? (
                <>
                  <p className=" text-md text-red-500 underline">View Fields</p>
                  <FieldArray name="viewFields">
                    {({ push, remove }) => (
                      <div className="flex flex-col gap-6">
                        {values?.viewFields?.map((viewFields, index) => (
                          <div key={index} className="flex gap-4 items-end">
                            {/* // view tabs name */}
                            <div className="">
                              <ATMTextField
                                name={`viewFields[${index}].tabs`}
                                value={values.viewFields[index].tabs}
                                onChange={(e) =>
                                  setFieldValue(
                                    `viewFields[${index}].tabs`,
                                    e.target.value
                                  )
                                }
                                label="View Tabs"
                                placeholder=" View Tabs"
                                onBlur={handleBlur}
                              />
                            </div>

                            {/* ddetailsVisible */}
                            <div className="">
                              <ATMTextField
                                name={`viewFields[${index}].detailsVisible`}
                                value={values.viewFields[index].detailsVisible}
                                onChange={(e) =>
                                  setFieldValue(
                                    `viewFields[${index}].detailsVisible`,
                                    e.target.value
                                  )
                                }
                                label="Details Visible"
                                placeholder="Details Visible"
                                onBlur={handleBlur}
                              />
                            </div>

                            <div>
                              <ATMTextField
                                name={`viewFields[${index}].logic`}
                                value={values.viewFields[index].logic}
                                onChange={(e) =>
                                  setFieldValue(
                                    `viewFields[${index}].logic`,
                                    e.target.value
                                  )
                                }
                                label="View Logic"
                                placeholder="View Logic"
                                onBlur={handleBlur}
                              />
                            </div>

                            {/* Delete Button */}
                            <button
                              type="button"
                              className={`${getInputHeight(
                                "small"
                              )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin5Line size={20} />
                            </button>
                          </div>
                        ))}

                        {/* Add More Button */}
                        <button
                          type="button"
                          onClick={() =>
                            push({
                              tabs: "",
                              detailsVisible: "",
                              logic: "",
                            })
                          }
                          className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400 flex justify-center items-center gap-[2px] "
                        >
                          Add{" "}
                          {values?.viewFields?.length ? "More" : "View Fields"}
                          <span>
                            <GrFormAdd
                              color="#ADB8C8"
                              size="1.5em"
                              className="font-semibold"
                            />
                          </span>
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </>
              ) : null}

              {/* Requirement Logic */}
              <div className="z-0">
                <MOLMarkdownEditor
                  label="Requirement Logic"
                  value={values.requirementLogic}
                  placeholder="Write requirement logic or drag your files here...."
                  onChange={(newValue: any) => {
                    setFieldValue("requirementLogic", newValue);
                  }}
                />
              </div>
              {/* Requirement Flow */}
              <div className="z-0">
                <MOLMarkdownEditor
                  label="Requirement Flow"
                  value={values.requirementFlow}
                  placeholder="Write requirement flow or drag your files here...."
                  onChange={(newValue: any) => {
                    setFieldValue("requirementFlow", newValue);
                  }}
                />
              </div>
            </div>
            <div>
              <ATMLoadingButton isLoading={isSubmitting} type="submit">
                Save
              </ATMLoadingButton>
            </div>
          </div>
        </SideNavLayout>
      )}
    </>
  );
};

export default EditRequirement;
