import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
type Props = {
  notesList: any;
};

const JobApplicationNotesList = ({ notesList }: Props) => {
  return notesList?.length ? (
    <div className="flex-1 flex flex-col gap-2 pt-3">
      {notesList?.map((el: any, ind: any) => {
        return (
          <div key={ind} className="border-b bg-slate-100 p-1 rounded">
            <ATMMarkdownContainer markdown={el?.notes} />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default JobApplicationNotesList;
