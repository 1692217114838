import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { JobCreationFormProps } from "src/models/JobCreation.model";
import { object, string } from "yup";
import JobCreationFormLayout from "../Layout/JobCreationFormLayout";
import { showToast } from "src/utils/showToaster";
import { useGetJobCreationByIdQuery, useJobCreationUpdateMutation } from "src/services/JobCreationService";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

const EditJobCreationFormWrapper = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetJobCreationByIdQuery(id, { skip: !id });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data || {});
    }
  }, [isLoading, isFetching, data]);

  const [updateJobCreation] = useJobCreationUpdateMutation();

  const initialValues: JobCreationFormProps = {
    jobTitle: items?.jobTitle || '',
    jobDescription: items?.jobDescription || '',
    jobRequirements: items?.jobRequirements || '',
    jobLocation: items?.jobLocation || '',
    salaryRange: items?.salaryRange || '',
    department: items?.department ? {
      label: items?.departmentName,
      value: items?.department,
    } : {},
    employmentType: items?.employmentType ? {
      label: items?.employmentType,
      value: items?.employmentType,
    } : {},
    experienceLevel: items?.experienceLevel ? {
      label: items?.experienceLevel,
      value: items?.experienceLevel,
    } : {},
    educationalRequirements: items?.educationalRequirements || '',
    benefits: items?.benefits || '',
    applicationDeadline: items?.applicationDeadline || null,
    postingDate: items?.postingDate || null,
    applicationLink: items?.applicationLink || '',
    numberOfVacancies: items?.numberOfVacancies || '',
    minExperience: items?.minExperience || '',
    maxExperience: items?.maxExperience || '',
    onBoardingBeforeDate: items?.onBoardingBeforeDate || null,

    primarySkills: items?.primarySkills?.map((el: any) => {
      return {
        label: el?.skillName,
        value: el?.skillId,
      };
    }),
    secondarySkills: items?.secondarySkills?.length ? items?.secondarySkills?.map((skill: any) => ({
      label: skill?.skillName,
      value: skill?.skillId,
    })) : [],
    immediateJoiner: items?.immediateOrNoticePeriod ? {
      label: items?.immediateOrNoticePeriod,
      value: items?.immediateOrNoticePeriod,
    } : {},
    maximumPackageLimit: items?.maximumPackageLimit || '',
    workMode: items?.workMode ? {
      label: items?.workMode,
      value: items?.workMode,
    } : {}

  };


  // Validation Schema
  const validationSchema = object().shape({
    jobTitle: string().required('Job title is required'),
    // jobDescription: string().required('Job description is required'),
    // jobRequirements: string().required('Job requirements are required'),
    // jobLocation: string().required('Job location is required'),
    // salaryRange: string().required('Salary range is required'),
    // numberOfVacancies: string().required('Number of vacancies is required'),
    // department: object().required('Department is required'),
    // employmentType: object().required('Employment type is required'),
    // experienceLevel: object().required('Experience level is required'),
    // educationalRequirements: string().required('Educational requirements are required'),
    // applicationDeadline: string().required('Application deadline is required'),
    // postingDate: string().required('Posting date is required'),
    // onBoardingBeforeDate: string().required('Onboarding before date is required'),
    // minExperience: string().required('Minimum experience is required'),
    // maxExperience: string().required('Maximum experience is required'),
    // maximumPackageLimit: string().required('Maximum package limit is required'),
    // applicationLink: string().required('Application link is required'),
    // workMode: object().required('Work Mode is required'),
  });

  // Handle Submit
  const handleSubmit = (
    values: JobCreationFormProps,
    { setSubmitting, resetForm }: FormikHelpers<JobCreationFormProps>
  ) => {
    const formattedValues = {
      jobTitle: values.jobTitle ? values.jobTitle : "",
      jobDescription: values.jobDescription || '',
      jobRequirements: values.jobRequirements || '',
      jobLocation: values.jobLocation || '',
      salaryRange: values.salaryRange || '',
      department: values.department ? values.department?.value : null,
      employmentType: values.employmentType?.value || '',
      experienceLevel: values.experienceLevel?.value || '',
      educationalRequirements: values.educationalRequirements || '',
      benefits: values.benefits || '',
      applicationDeadline: values.applicationDeadline ? values.applicationDeadline : '',
      postingDate: values.postingDate ? values.postingDate : '',
      numberOfVacancies: values.numberOfVacancies?.toString() || '',
      minExperience: values.minExperience?.toString() || '',
      maxExperience: values.maxExperience?.toString() || '',
      onBoardingBeforeDate: values.onBoardingBeforeDate ? values.onBoardingBeforeDate : "",
      primarySkills: values.primarySkills?.map((skill: any) => skill.value) || [],
      secondarySkills: values.secondarySkills?.map((skill: any) => skill.value) || [],
      immediateOrNoticePeriod: values.immediateJoiner?.value || '',
      workMode: values.workMode?.value || '',
      maximumPackageLimit: values.maximumPackageLimit?.toString() || '',
    };
    updateJobCreation({ body: formattedValues, id: id }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate("/job-onboard/job-creation/");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <JobCreationFormLayout
            formType="EDIT"
            formikProps={formikProps}
            onClose={() => navigate("/finance/recurringincome")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditJobCreationFormWrapper;
