import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setIsTableLoading, setItems, setTotalItems, setSearchValue } from 'src/redux/slices/TeamViewTodayTaskSlice';
import { RootState } from 'src/redux/store';
import { useGetAllTodayTaskQuery } from 'src/services/UserService';
import ListCompleteTask from './ListCompleteTask';

const ListCompleteTaskWrapper = () => {

    const { items, page, rowsPerPage, dateFilter, isTableLoading } = useSelector(
        (state: RootState) => state.teamViewTodayTaskSlice
    );
    const { searchValue } = useSelector((state: RootState) => state.teamView);
    const dispatch = useDispatch()
    const { data: completeData, isLoading: isLoadingCompleteData, isFetching: isFetchingCompleteData } = useGetAllTodayTaskQuery({
        limit: rowsPerPage,
        searchValue: searchValue,
        params: [
            "tasks.taskStatus",
            "tasks.previousStatus",
            "tasks.taskTitle",
            "tasks.taskNumber",
        ],
        page: page,
        filterBy: [
            // {
            //     fieldName: "tasks.taskStatus",
            //     value: "COMPLETE"
            // }
        ],
        dateFilter: {
            endDate: dateFilter?.endDate,
            startDate: dateFilter?.startDate,
            dateFilterKeyForMeeting: "scheduledDate"
        },
        // dateFilterForMeeting: dateFilterForMeeting,
        orderBy: "createdAt",
        orderByValue: -1,
        isPaginationRequired: true,
    });

    // Setting Module Data
    useEffect(() => {
        if (isLoadingCompleteData || isFetchingCompleteData) {
            dispatch(setIsTableLoading(true));
        } else { 
            let sortData = [...completeData?.data]?.sort((a, b) => a?.name?.localeCompare(b?.name));
            dispatch(setItems(sortData || []));
            dispatch(setIsTableLoading(false));
            dispatch(setTotalItems(completeData?.totalItem || 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completeData, isLoadingCompleteData, isFetchingCompleteData]);
    return (
        <>
            <ListCompleteTask
                rows={items}
                onClick={(taskId, ticketId) => {
                    if (ticketId === null) {
                        window.open(`/task/${taskId}`, "_blank");
                    } else {
                        window.open(`/ticket/${ticketId}/task/${taskId}`, "_blank");
                    }
                }}
                paginationProps={{
                    isTableLoading,
                    searchValue,
                    setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
                }}
            />
        </>
    )
}

export default ListCompleteTaskWrapper
