import { FormikProps } from "formik";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import useChannelGroups from "src/hooks/useChannelGroups";
import { ChannelFormValues } from "src/models/Channel.model";

type Props = {
  formikProps: FormikProps<ChannelFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const ChannelForm = ({
  formikProps,
  formType,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const { ChannelGroups, isDataLoading } = useChannelGroups();
  const channelGroupsOptions = ChannelGroups?.map((group) => {
    return {
      label: group?.channelGroupName,
      value: group?._id,
    };
  });

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add New Channel" : "Update Channel"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Channel Group Name */}
        <div>
          <ATMSelect
            name="channelGroupName"
            value={values?.channelGroupName}
            onChange={(value) => setFieldValue("channelGroupName", value)}
            placeholder="Select Channel Group Name"
            options={channelGroupsOptions}
            label="Channel Group Name"
            required
            isLoading={isDataLoading}
          />
        </div>
        <div>
          <ATMTextField
            name="channelName"
            value={values.channelName}
            required
            onChange={(e) => setFieldValue("channelName", e.target.value)}
            label="Channel Name"
            placeholder="Enter Channel Name"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default ChannelForm;
