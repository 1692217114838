import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
// import { FaUser } from "react-icons/fa";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
// import { BiBook } from "react-icons/bi";
// import { SlNote } from "react-icons/sl";
// import { RiInfraredThermometerFill } from "react-icons/ri";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { isAuthorized } from "src/utils/authorization";
import AccessDenied from "src/utils/AccessDenied";
import { MeetingListResponse } from "src/models/Meeting.model";
import { useGetMeetingByIdQuery } from "src/services/MeetingServices";
import { TbClipboardText, TbFocus, TbNotes, TbUsers } from "react-icons/tb";
import MOLMeetingStatusChip from "src/components/UI/molecules/MOLMeetingStatusChip/MOLMeetingStatusChip";

type Props = {};

export const inquiriesEditTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
  {
    label: "Agenda",
    icon: TbFocus,
    path: "agenda",
    moduleName: UserModuleNameTypes.ACTION_MEETING_SINGLE_VIEW_AGENDA,
  },
  {
    label: "Attendees",
    icon: TbUsers,
    path: "attendees",
    moduleName: UserModuleNameTypes.ACTION_MEETING_SINGLE_VIEW_ATTENDEES,
  },
  {
    label: "Notes",
    icon: TbNotes,
    path: "notes",
    moduleName: UserModuleNameTypes.ACTION_MEETING_SINGLE_VIEW_NOTES,
  },
  {
    label: "MOM",
    icon: TbClipboardText,
    path: "mom",
    moduleName: UserModuleNameTypes.ACTION_MEETING_SINGLE_VIEW_MOM,
  },
];

const MeetingViewTabsLayout = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname.split("/")[3];
  const { userData } = useGetLocalStorage();

  const [meetingData, setMeetingData] = useState<MeetingListResponse>();

  const { meetingId: MEETING_ID } = useParams();

  const { isLoading, isFetching, data } = useGetMeetingByIdQuery(
    MEETING_ID || "",
    {
      skip: !MEETING_ID,
    }
  );

  useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("agenda");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of inquiriesEditTabs) {
      const isValue = isAuthorized(
        nav?.moduleName as keyof typeof UserModuleNameTypes
      );
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tabsRender = inquiriesEditTabs?.some((nav) => {
    return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setMeetingData(data?.data || []);
    }
  }, [isLoading, isFetching, data]);

  return (
    <>
      <SideNavLayout>
        {tabsRender ? (
          <div className="flex flex-col h-full">
            <div className="flex flex-col gap-3">
              {meetingData ? (
                <div className="flex items-center gap-4 p-4 pb-0">
                  <span className="text-lg font-medium">
                    {meetingData?.title}{" "}
                  </span>
                  <span className="px-2 py-[2px] text-xs bg-gray-100 border border-gray-500 rounded-full">
                    {meetingData?.meetingTypeName}
                  </span>
                  <MOLMeetingStatusChip status={meetingData?.status} />
                </div>
              ) : null}
              {/* Change Request Tabs and Outlet */}
              {inquiriesEditTabs?.length && (
                <div className="flex items-center border-b border-collapse">
                  {inquiriesEditTabs
                    ?.filter((nav) => {
                      return isAuthorized(
                        nav?.moduleName as keyof typeof UserModuleNameTypes
                      );
                    })
                    ?.map((tab: any, index: any) => {
                      return (
                        <div key={index}>
                          <button
                            type="button"
                            onClick={() => navigate(tab.path)}
                            className={`h-full text-sm flex items-center gap-2 transition-all px-4 py-1 font-medium
                            ${
                              currentPath === tab.path?.split("/")[0]
                                ? "text-primary-dark border-b-2 border-primary-dark"
                                : "text-neutral border-b-2 border-white"
                            }
                            `}
                          >
                            <div className="">
                              <tab.icon />
                            </div>
                            {tab.label}
                          </button>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
            <div className="overflow-auto grow ">
              <Outlet />
            </div>
          </div>
        ) : (
          <AccessDenied />
        )}
      </SideNavLayout>
    </>
  );
};

export default MeetingViewTabsLayout;
