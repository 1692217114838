import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { MeetingNoteTypeFormValues } from "src/models/MeetingTypeNotes.model";
import {
  useGetMeetingNoteTypeByIdQuery,
  useEditMeetingNoteTypeByIdMutation,
} from "src/services/MeetingTypeNoteService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import MeetingTypeNoteForm from "../Layouts/MeetingTypeNoteForm";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  selectedMeetingNoteTypeId: any;
};

const EditMeetingNoteTypeWrapper = ({
  onClose,
  selectedMeetingNoteTypeId,
}: Props) => {
  const [meetingNoteType, setMeetingTypeData] = useState<any>({});
  const [updateMeetingNoteType] = useEditMeetingNoteTypeByIdMutation();
  const { isLoading, isFetching, data } = useGetMeetingNoteTypeByIdQuery(
    selectedMeetingNoteTypeId,
    {
      skip: !selectedMeetingNoteTypeId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setMeetingTypeData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: MeetingNoteTypeFormValues = {
    meetingNoteType: meetingNoteType?.meetingNoteType || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    meetingNoteType: string().required("Please enter meeting note type"),
  });

  // Handle Submit
  const handleSubmit = (
    values: MeetingNoteTypeFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingNoteTypeFormValues>
  ) => {
    updateMeetingNoteType({ id: selectedMeetingNoteTypeId, body: values }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <MeetingTypeNoteForm
            formikProps={formikProps}
            onClose={onClose}
            formType="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditMeetingNoteTypeWrapper;
