import { TbCheckbox } from "react-icons/tb";

type MeetingDataPropType = {
  actualEndTime: string;
  actualStartTime: string;
  meetingHostId: string;
  status: "ONGOING";
  title: string;
  _id: string;
};
type Props = {
  meetingData: MeetingDataPropType[];
  onMeetingClick: (meetingId: string) => void;
  onMarkAsDoneClick: (meetingData: MeetingDataPropType) => void;
};

const OnGoingMeetings = ({
  meetingData,
  onMeetingClick,
  onMarkAsDoneClick,
}: Props) => {

  const userDataLJson = localStorage.getItem("userData");
  const userData = JSON?.parse(userDataLJson as string);

  return (
    <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg custom-shadow">
      <div className="flex items-center gap-2 p-2 border-b bg-green-50">
        <div className="flex items-center justify-center p-2 font-semibold bg-white border rounded-md border-primary-dark size-8">
          {meetingData.length}
        </div>
        <p className="font-medium">Ongoing meetings</p>
      </div>
      {meetingData.length ? (
        <div className="h-full py-2 overflow-auto">
          {meetingData?.map((meetingInfo: MeetingDataPropType) => (
            <div
              key={meetingInfo?._id}
              className="flex items-center justify-between px-4 py-1 hover:bg-hover"
            >
              <div className="flex flex-1 cursor-pointer">
                <p
                  className="text-sm line-clamp-2"
                  onClick={() => onMeetingClick(meetingInfo?._id)}
                >
                  {meetingInfo?.title}
                </p>
              </div>
              {userData?.userId === meetingInfo?.meetingHostId || userData?.userType === "SUPER_ADMIN" ? (
                <div
                  className="flex items-center gap-2 px-3 py-2 font-semibold rounded-md w-fit text-primary-dark hover:bg-primary-light hover:text-white cursor-pointer"
                  onClick={() => onMarkAsDoneClick(meetingInfo)}
                >
                  <TbCheckbox />
                  <p className="text-xs">Mark as done</p>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-4">
          No On-going meeting present
        </div>
      )}
    </div>
  );
};

export default OnGoingMeetings;
