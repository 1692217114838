import React, { useEffect, useState } from "react";
import TaskForm from "../Layouts/TicketTaskForm";
import { Form, Formik, FormikHelpers } from "formik";
import { TaskFormValues } from "src/models/Task.model";
import {
  useAddTaskMutation,
  useGetTaskByIdQuery,
} from "src/services/TaskService";
import { showToast } from "src/utils/showToaster";
import { RootState } from "src/redux/store";
import { useSelector } from "react-redux";
import { object, string } from "yup";
import moment from "moment";

type Props = {
  onClose: () => void;
  selectedTask: string;
  projectId: string;
};

const DuplicateTaskFormWrapper = ({
  onClose,
  selectedTask,
  projectId,
}: Props) => {
  const [items, setItems] = useState<any>({});

  const [AddTask] = useAddTaskMutation();
  const { singleItem } = useSelector((state: RootState) => state.ticket);
  const [saveNextChecked, setSaveNextChecked] = useState(true);
  const { isLoading, isFetching, data } = useGetTaskByIdQuery(selectedTask);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const initialValues: TaskFormValues = {
    title: items?.title || "",
    layer: items?.layer || "",
    label: items?.label?.map((label: any) => {
      return {
        labelName: label?.labelName,
      };
    }),
    estimation: items?.estimation || "",
    featureId: items?.featureId || "",
    moduleId: items?.moduleId || "",
    projectId: items?.projectId || "",
    description: items?.description || "",
    ticketId: "",
    relatedTo: "",
    dueDate: items?.dueDate ? items?.dueDate : "",
  };
  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter Task Title"),
  });

  // Handle Submit
  const handleSubmit = (
    values: TaskFormValues,
    { setSubmitting, resetForm }: FormikHelpers<TaskFormValues>
  ) => {
    const { ticketId, relatedTo, label, dueDate, ...rest } = values;

    const formattedValues = {
      ...rest,
      relatedTo: "TICKET",
      ticketId: singleItem[0]?._id,
      dueDate: dueDate ? moment(dueDate).format("yyyy-MM-DD") : "",
      label: [
        {
          labelName: "OPEN",
          status: "OPEN",
        },
      ],
    };

    AddTask(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form>
            <TaskForm
              formikProps={formikProps}
              onClose={onClose}
              saveNextChecked={saveNextChecked}
              setSaveNextChecked={setSaveNextChecked}
              id={projectId}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DuplicateTaskFormWrapper;
