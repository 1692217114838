/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { getToken } from "firebase/messaging";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useHandShakeFirebaseTokenMutation } from "src/services/NotificationService";
import { messaging } from "../../firebase";
// import { showToast } from "src/utils/showToaster";
import { CircularProgress, Skeleton } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenMarkAsDoneModel } from "src/redux/slices/MeetingSlice";
import { RootState } from "src/redux/store";
import {
  useGetAllActivityOfUserQuery,
  useGetDashboardReminderQuery,
  useGetPendingDeploymentRequestQuery,
  useGetPendingDoubtRequestQuery,
  useGetPendingResourceRequestQuery,
  useGetDashboardGoalsQuery,
  useGetUserEventsQuery,
} from "src/services/DashbordService";
import { FIREBASE_VAP_ID_KEY } from "src/utils/constants";
import { DeploymentRequest } from "../Dashboard/Component/DeploymentRequest";
import DoubtsList from "../Dashboard/Component/DoubtsList";
import OnGoingMeetings from "../Dashboard/Component/OnGoingMeetings";
import Reminder from "../Dashboard/Component/Reminder";
import ResourceList from "../Dashboard/Component/ResourceList";
import ScheduledMeetings from "../Dashboard/Component/ScheduledMeetings";
import UserTimeLineCalendarWrapper from "../Dashboard/Component/UserTimeLineCalendar/UserTimeLineCalendarWrapper";
import MeetingMarkAsDoneFormWrapper from "../Meeting/View/tabs/NotesTab/MeetingMarkAsDoneForm/MeetingMarkAsDoneFormWrapper";
import LeavesOfUsers from "../Dashboard/Component/LeavesOfUsers";
import OnBreakUsers from "../Dashboard/Component/OnBreakUsers";
import MemberBirthDays from "../Dashboard/Component/MemberBirthDays";
import GoalsOfUser from "../Dashboard/Component/GoalsOfUser";
import OnBenchUsers from "../Dashboard/Component/OnBenchUsers";
import QuickMeetingMarkAsDoneFormWrapper from "../Meeting/View/tabs/NotesTab/QuickMeetingMarkAsDoneForm/QuickMeetingMarkAsDoneFormWrapper";

const LandingPage = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.auth);
  const { isOpenMarkAsDoneModel } = useSelector(
    (state: RootState) => state.meeting
  );
  const [codioticUser, setCodioticUser] = useState<any>("");
  const [dateForTimeline, setDateForTimeline] = useState<any>(
    moment().format("YYYY-MM-DD")
  );
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [selectedMeetingId, setSelectedMeetingId] = useState<any>("");
  const [actualStartTime, setActualStartTime] = useState("");
  const [actualEndTime, setActualEndTime] = useState("");
  const [deploymentRequestData, setDeploymentRequestData] = useState<any>();
  const [doubtRequestData, setDoubtRequestData] = useState<any>();
  const [resourceRequestData, setResourceRequestData] = useState<any>();
  const [reminderDashboardData, setReminderDashboardData] = useState<any>();
  const [goalsDashboardData, setGoalsDashboardData] = useState<any>([]);
  const [memberActivityDetailsData, setMemberActivityDetailsData] = useState<any>();
  const [isOpenQuickMarkAsDoneModel, setIsOpenQuickMarkAsDoneModel] = useState<boolean>(false);

  //get deployment request data
  const {
    data: deploymentData,
    isLoading: isLoadingDeploymentData,
    isFetching: isFetchingDeploymentData,
  } = useGetPendingDeploymentRequestQuery("");
  //get doubt request data
  const {
    isLoading: isDoubtDataLoading,
    isFetching: isDoubtDataFetching,
    data: doubtData,
  } = useGetPendingDoubtRequestQuery("");
  //get doubt request data
  const {
    isLoading: isResourceDataLoading,
    isFetching: isResourceDataFetching,
    data: resourceData,
  } = useGetPendingResourceRequestQuery("");

  const {
    isLoading: isReminderDataLoading,
    isFetching: isReminderDataFetching,
    data: reminderData,
  } = useGetDashboardReminderQuery("");

  // GET GOALS
  const {
    isLoading: isGoalsDataLoading,
    isFetching: isGoalsDataFetching,
    data: goalsData,
  } = useGetDashboardGoalsQuery("");

  //get user activity
  const { data, isLoading, isFetching } = useGetAllActivityOfUserQuery({
    codioticUserId: codioticUser?.value || userData?.userId,
    dateFilter: {
      startDate: moment(dateForTimeline).format("YYYY-MM-DD"),
      endDate: moment(dateForTimeline).format("YYYY-MM-DD"),
    },
    projectId: "",
  });

  //get user Details
  const {
    data: memberActivityDetails,
    isLoading: memberActivityDetailsIsLoading,
    isFetching: memberActivityDetailsIsFetching,
  } = useGetUserEventsQuery({
    codioticUserId: codioticUser?.value || userData?.userId,
    dateFilter: {
      startDate: moment(dateForTimeline).format("YYYY-MM-DD"),
      endDate: moment(dateForTimeline).format("YYYY-MM-DD"),
    },
    projectId: "",
  });

  const [handeShakeFirebaseToken] = useHandShakeFirebaseTokenMutation();
  const accessToken = localStorage.getItem("authToken");
  const navigate = useNavigate();
  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken, navigate]);
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setDashboardData(data?.data);
    }
  }, [data, isLoading, isFetching]);
  useEffect(() => {
    if (!isLoadingDeploymentData && !isFetchingDeploymentData) {
      setDeploymentRequestData(deploymentData?.data);
    }
  }, [deploymentData, isLoadingDeploymentData, isFetchingDeploymentData]);

  useEffect(() => {
    if (!isReminderDataLoading && !isReminderDataFetching) {
      setReminderDashboardData(reminderData?.data);
    }
  }, [reminderData, isReminderDataLoading, isReminderDataFetching]);

  useEffect(() => {
    if (!isDoubtDataLoading && !isDoubtDataFetching) {
      setDoubtRequestData(doubtData?.data);
    }
  }, [doubtData, isDoubtDataLoading, isDoubtDataFetching]);
  useEffect(() => {
    if (!isResourceDataLoading && !isResourceDataFetching) {
      setResourceRequestData(resourceData?.data);
    }
  }, [resourceData, isResourceDataLoading, isResourceDataFetching]);

  useEffect(() => {
    if (!memberActivityDetailsIsLoading && !memberActivityDetailsIsFetching) {
      setMemberActivityDetailsData(memberActivityDetails?.data);
    }
  }, [
    memberActivityDetailsIsFetching,
    memberActivityDetailsIsLoading,
    memberActivityDetails,
  ]);

  useEffect(() => {
    if (!isGoalsDataLoading && !isGoalsDataFetching) {
      setGoalsDashboardData(goalsData?.data);
    }
  }, [goalsData, isGoalsDataLoading, isGoalsDataFetching]);


  // const playNotificationSound = () => {
  //   // Create an audio element and set its source to your notification sound file
  //   const audio = new Audio(notificationSound);
  //   // Play the sound
  //   audio.play();
  // };

  const getFirebaseToken = () => {
    getToken(messaging, {
      vapidKey: FIREBASE_VAP_ID_KEY,
    })
      .then((token) => {
        localStorage.setItem("notifiToken", token);
        handeShakeFirebaseToken({
          firebaseToken: token,
        }).then((res: any) => {
          localStorage.setItem("notifiToken", token);
          // showToast("success", "you are allowed for notification");
          // playNotificationSound();
        });
      })
      .catch((err) => {
        console.error("error in getting token", err);
        // showToast("error", "you are not allowed for notification");
      });
  };

  const getRequestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // const token = await messaging.getToken();
        getFirebaseToken();
      } else {
        alert("permission rejected");
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };
  return (
    <>
      <SideNavLayout>
        <div className="flex flex-col lg:flex-row lg:h-full overflow-hidden bg-[#fcfcfc]">

          <div className="flex flex-col gap-4 p-4 overflow-auto">
            {isLoading ||
              isFetching ||
              memberActivityDetailsIsFetching ||
              memberActivityDetailsIsLoading ? (
              <div className="grid w-full h-full  gap-4  lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1">
                {Array(6)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <div key={index}>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          width={360}
                          height={500}
                        />
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div>
                {goalsDashboardData?.length ? (
                  <div className="h-[500px] overflow-auto">
                    <GoalsOfUser isGoalsDataLoading={isGoalsDataLoading} isGoalsDataFetching={isGoalsDataFetching} goalsData={goalsDashboardData} />
                  </div>
                ) : null}
                <div className="grid w-full gap-4  lg:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 mt-2">
                  {memberActivityDetailsData?.membersOnBreak?.length ? (
                    <div className="h-[500px] ">
                      <OnBreakUsers
                        usersOnBreak={memberActivityDetailsData?.membersOnBreak}
                      />
                    </div>
                  ) : null}
                  {memberActivityDetailsData?.membersOnBench?.length ? (
                    <div className="h-[500px] ">
                      <OnBenchUsers
                        usersOnBench={memberActivityDetailsData?.membersOnBench}
                      />
                    </div>
                  ) : null}
                  {memberActivityDetailsData?.membersOnLeave?.length ? (
                    <div className="h-[500px] ">
                      <LeavesOfUsers
                        usersOnLeave={memberActivityDetailsData?.membersOnLeave}
                      />
                    </div>
                  ) : null}
                  {memberActivityDetailsData?.upcomingBirthdays?.length ? (
                    <div className="h-[500px]">
                      <MemberBirthDays
                        birthDayDetails={
                          memberActivityDetailsData?.upcomingBirthdays
                        }
                      />
                    </div>
                  ) : null}
                  {memberActivityDetailsData?.onGoingMeetings?.length ? (
                    <div className="h-[500px]">
                      <OnGoingMeetings
                        meetingData={
                          memberActivityDetailsData?.onGoingMeetings || []
                        }
                        onMeetingClick={(meetingId) =>
                          window.open(`/meeting/${meetingId}/agenda`, "_blank")
                        }
                        onMarkAsDoneClick={(meetingData) => {
                          if (meetingData?.title === 'UNTITLED') {
                            setIsOpenQuickMarkAsDoneModel(true)
                          } else {
                            dispatch(setIsOpenMarkAsDoneModel(true));
                          }
                          setSelectedMeetingId(meetingData?._id);
                          setActualStartTime(meetingData?.actualStartTime);
                          setActualEndTime(meetingData?.actualEndTime);
                        }}
                      />
                    </div>
                  ) : null}

                  {memberActivityDetailsData?.todayMeeting?.length ? (
                    <div className="h-[500px]">
                      <ScheduledMeetings
                        meetingData={
                          memberActivityDetailsData?.todayMeetings || []
                        }
                        onMeetingClick={(meetingId) =>
                          window.open(`/meeting/${meetingId}/agenda`, "_blank")
                        }
                      />{" "}
                    </div>
                  ) : null
                  }
                  {
                    deploymentRequestData?.pendingDeploymentRequest?.length ? (
                      <div className="h-[500px]">
                        <DeploymentRequest
                          deploymentRequest={deploymentRequestData}
                          onDeploymentRequestClick={() =>
                            window.open(`/deployment-request`, "_blank")
                          }
                        />
                      </div>
                    ) : null
                  }
                  {
                    resourceRequestData?.pendingResourceRequest?.length ? (
                      <div className="h-[500px] ">
                        <ResourceList
                          resourceRequestData={resourceRequestData}
                          onResourceRequestClick={(resourceId) =>
                            window.open(
                              `/resource-request/${resourceId}/view`,
                              "_blank"
                            )
                          }
                        />
                      </div>
                    ) : null
                  }
                  {
                    doubtRequestData?.pendingDoubtsCount.length ? (
                      <div className="h-[500px] ">
                        <DoubtsList
                          doubtRequestData={doubtRequestData}
                          onDoubtClick={(doubtId) =>
                            window.open(`/doubt/${doubtId}`, "_blank")
                          }
                        />
                      </div>
                    ) : null
                  }
                  {
                    reminderData?.reminders?.length ? (
                      <div className="h-[500px] ">
                        <Reminder
                          reminderData={reminderDashboardData}
                          onReminderClick={(reminderId) =>
                            window.open(`/reminder`, "_blank")
                          }
                        />
                      </div>
                    ) : null
                  }
                </div >
              </div>
            )}
          </div>

          <div className="md:min-w-[500px] border-l bg-white">
            {isLoading ||
              isFetching ||
              memberActivityDetailsIsFetching ||
              memberActivityDetailsIsLoading ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                width={500}
                height={1000}
              />
            ) : (
              <UserTimeLineCalendarWrapper
                userData={userData}
                setSelectedCodioticUser={setCodioticUser}
                selectedCodioticUser={codioticUser}
                setDateForTimeline={setDateForTimeline}
                dateForTimeline={dateForTimeline}
                activityData={dashboardData}
              />
            )}
          </div>
        </div >
      </SideNavLayout >
      {
        isOpenMarkAsDoneModel ? (
          <MeetingMarkAsDoneFormWrapper
            meetingId={selectedMeetingId}
            onClose={() => {
              dispatch(setIsOpenMarkAsDoneModel(false));
            }}
            actualStartTime={actualStartTime}
            actualEndTime={actualEndTime}
          />
        ) : null}

      {isOpenQuickMarkAsDoneModel ? (
        <QuickMeetingMarkAsDoneFormWrapper
          meetingId={selectedMeetingId}
          // meetingType={meetingType}
          actualStartTime={actualStartTime}
          actualEndTime={actualEndTime}
          onClose={() => {
            setIsOpenQuickMarkAsDoneModel(false);
          }}
        />
      ) : null}
    </>
  );
};

export default LandingPage;
