import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import IncidentCategoryForm from '../Layouts/IncidentCategoryForm';
import { showToast } from 'src/utils/showToaster';
import { useAddIncidentCategoryMutation } from 'src/services/IncidentCategoryService';



export type IncidentCategoryFormValues={
    categoryName : string ;
}

type Props = {
  onClose: () => void;
};

const AddIncidentCategoryFormWrapper = ({ onClose }: Props) => { 

  const [addCategory] = useAddIncidentCategoryMutation()

  // Form Initial Values
  const initialValues: IncidentCategoryFormValues = {
    categoryName: '',
  };

  // Validation Schema
  const validationSchema = object().shape({
    categoryName: string().required('Please enter name'),
  });

  // Handle Submit
  const handleSubmit = (
    values: IncidentCategoryFormValues,
    { setSubmitting, resetForm }: FormikHelpers<IncidentCategoryFormValues>
  ) => {
    addCategory(values).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          
            resetForm();
            onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth='xs' fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <IncidentCategoryForm formikProps={formikProps} onClose={onClose} formType='Add' />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddIncidentCategoryFormWrapper;