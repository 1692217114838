import React, { useState, useEffect, Suspense } from "react";
import TeamList from "./TeamList";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "src/redux/store";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/TeamSlice";
import {
  useUpdateMemeberStatusMutation,
  useGetAllCodioticMembersQuery,
} from "src/services/TeamServices";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdEdit, MdOutlinePublishedWithChanges } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { CircularProgress } from "@mui/material";
import { showToast } from "src/utils/showToaster";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import moment from "moment";
import SalaryConfigureDialogFormWrapper from "./SalaryConfigureDialog/SalaryConfigureDialogFormWrapper";

type Props = {};

const TeamListWrapper = (props: Props) => {
  const [isOpenAddDialog, setIsOpenAddDialog] = useState<boolean>(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [selectedTeamMemberId, setSelectedTeamMemberId] = useState<string>("");
  const [isOpenSalaryConfigDialog, setIsOpenSalaryConfigDialog] =
    useState(false);

  // Initiate Methods
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.team);

  // Redux state for login user data
  const { userData } = useSelector((state: RootState) => state?.auth);

  // Dynamic import
  const AddTeamFormWrapper = React.lazy(
    () => import("../Add/AddTeamFormWrapper")
  );
  const EditTeamFormWrapper = React.lazy(
    () => import("../Edit/EditTeamFormWrapper")
  );

  const { data, isLoading, isFetching } = useGetAllCodioticMembersQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["name", "mobile", "email"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "name",
    orderByValue: 1,
    isPaginationRequired: true,
  });

  const [updateActiveStatus] = useUpdateMemeberStatusMutation();

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[180px]",
      name: UserModuleNameTypes.TEAM_LIST_NAME,
      renderCell: (row: any) => (
        <div className="flex items-center justify-center capitalize gap-x-4">
          {row?.name}
          <div className="capitalize">
            {row?._id === userData?.userId && (
              <span className="bg-green-500 py-[2px] flex items-center px-[8px] text-center rounded-full text-white text-[10px]">
                It's you
              </span>
            )}
          </div>
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Number",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.TEAM_LIST_PROJECT_NUMBER,
    },
    {
      field: "email",
      headerName: "Email",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[250px]",
      name: UserModuleNameTypes.TEAM_LIST_LAYER_EMAIL,
    },
    {
      field: "growthSalary",
      headerName: "Growth Salary",
      extraClasses: "min-w-[120px] max-w-[120px]",
      name: UserModuleNameTypes.TEAM_LIST_LAYER_SALARY,
      renderCell: (row: any) => (
        <div className="py-1">
          <div className="text-[15px] ">
            {row?.growthSalary ? row?.growthSalary : "-"}
          </div>
        </div>
      ),
    },
    {
      field: "requestedOnDate1",
      headerName: "DOB(As Per Documents)",
      extraClasses: "min-w-[180px] max-w-[180px]",
      name: UserModuleNameTypes.TEAM_LIST_LAYER_DOB_AS_PER_DOCUMENTS,
      renderCell: (row: any) => (
        <div className="py-1">
          <div className="text-[15px] ">
            {row?.dobAsPerDocument
              ? moment(row?.dobAsPerDocument).format("DD MMM YYYY")
              : "-"}
          </div>
        </div>
      ),
    },
    {
      field: "requestedOnDate2",
      headerName: "DOB(Actulay)",
      extraClasses: "min-w-[120px] max-w-[120px]",
      name: UserModuleNameTypes.TEAM_LIST_LAYER_DOB_AS_PER_ACTULAY,
      renderCell: (row: any) => (
        <div className="py-1">
          <div className="text-[15px] ">
            {row?.dobActually
              ? moment(row?.dobActually).format("DD MMM YYYY")
              : "-"}
          </div>
        </div>
      ),
    },
    {
      field: "requestedOnDate3",
      headerName: "Joining Date",
      extraClasses: "min-w-[120px] max-w-[120px]",
      name: UserModuleNameTypes.TEAM_LIST_LAYER_JOINING_DATE,
      renderCell: (row: any) => (
        <div className="py-1">
          <div className="text-[15px] ">
            {row?.joiningDate
              ? moment(row?.joiningDate).format("DD MMM YYYY")
              : "-"}
          </div>
        </div>
      ),
    },
    {
      field: "positionName",
      headerName: "Position",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.TEAM_LIST_LAYER_POSITION,
      renderCell: (row: any) => (
        <>
          {row?.positionName ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                navigate("/user-access", {
                  state: {
                    positionId: row?.positionId,
                    departmentName: row?.departmentName,
                    departmentId: row?.departmentId,
                    positionName: row?.positionName,
                    userId: row?._id,
                  },
                });
              }}
              className="text-blue-800 underline cursor-pointer"
            >
              {row?.positionName?.trim()?.replaceAll("_", " ") || "-"}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      field: "departmentName",
      headerName: "Department",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.TEAM_LIST_LAYER_DEPARTMENT,
      renderCell: (row: any) => (
        <div className="">{row?.departmentName || "-"}</div>
      ),
    },
    {
      field: "isActive",
      headerName: "isActive",
      flex: "flex-[1_1_0%]",
      extraClasses: "py-2",
      align: "center",
      renderCell: (row: any) => {
        return (
          <div className="font-medium">
            {row?.isActive ? (
              <span className="text-green-600">Yes</span>
            ) : (
              <span className="text-red-600">No</span>
            )}{" "}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      // flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectedTeamMemberId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_TEAMS_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <RiMoneyEuroCircleFill /> Salary Configure
                  </div>
                ),
                onClick: () => {
                  setIsOpenSalaryConfigDialog(true);
                  setSelectedTeamMemberId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_TEAMS_SALARY_CONFIGURE,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdOutlinePublishedWithChanges /> Change Status
                  </div>
                ),
                onClick: () => {
                  showConfirmationDialog({
                    title: "Hands Up",
                    text: "Are you sure want to change the status of member ?",
                    icon: "question",
                    showCancelButton: true,
                    next: (result) => {
                      if (result?.isConfirmed) {
                        updateActiveStatus(row?._id).then((res: any) => {
                          if (res?.data?.status) {
                            showToast("success", "status changed successful");
                          } else {
                            showToast("error", res?.data?.message);
                          }
                        });
                      }
                    },
                  });
                },
                acitonName: UserModuleNameTypes.ACTION_TEAMS_CHANGE_STATUS,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <TeamList
        columns={columns}
        rows={items}
        addNew={() => setIsOpenAddDialog(true)}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      <Suspense fallback={<CircularProgress />}>
        {isOpenAddDialog ? (
          <AddTeamFormWrapper onClose={() => setIsOpenAddDialog(false)} />
        ) : null}
      </Suspense>
      <Suspense fallback={<CircularProgress />}>
        {isOpenSalaryConfigDialog ? (
          <SalaryConfigureDialogFormWrapper 
          selectedTeamMemberId={selectedTeamMemberId}
            onClose={() => {
              setIsOpenSalaryConfigDialog(false);
            }}
          />
        ) : null}
      </Suspense>

      <Suspense fallback={<CircularProgress />}>
        {isOpenEditDialog ? (
          <EditTeamFormWrapper
            teamMemberId={selectedTeamMemberId}
            onClose={() => setIsOpenEditDialog(false)}
          />
        ) : null}
      </Suspense>
    </React.Fragment>
  );
};

export default TeamListWrapper;
