export const getAllStatus = (status: string) => {
  switch (status) {
    case "OPEN":
      return {
        text: "OPEN",
        className: "bg-yellow-600",
      };
    case "IN_PROGRESS":
      return {
        text: "IN PROGRESS",
        className: "bg-green-600",
      };
    case "CLOSE":
      return {
        text: "CLOSE",
        className: "bg-gray-500",
      };
    case "TODO":
      return {
        text: "TODO",
        className: "bg-sky-900",
      };
    case "BUGS":
      return {
        text: "BUGS",
        className: "bg-red-700",
      };
    case "BLOCKING":
      return {
        text: "BUGS",
        className: "bg-rose-700	",
      };
    case "UAT_REVIEW":
      return {
        text: "UAT REVIEW",
        className: "bg-blue-600",
      };
    case "DONE":
      return {
        text: "DONE",
        className: "bg-green-800",
      };
    case "HOLD":
      return {
        text: "HOLD",
        className: "bg-orange-600",
      };
    case "ISSUE":
      return {
        text: "ISSUE",
        className: "bg-red-900",
      };
    case "COMPLETE":
      return {
        text: "COMPLETE",
        className: "bg-lime-700",
      };
  }
};
