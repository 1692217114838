import { Form, Formik, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { FILE_FORMAT } from "src/models/ResourceRequest.model";
import { useAddTeamMemberDocumentsMutation } from "src/services/TeamServices";
import { showToast } from "src/utils/showToaster";
import { array, object } from "yup";
import AddIncreamentForm from "./AddDocumentForm";

export type IncreamentFormValues = {
  documentName: any;
  url: any[];
};

type Props = {
  onClose: () => void;
};

const AddDocumentFormWrapper = ({ onClose }: Props) => {
  const [addDocument] = useAddTeamMemberDocumentsMutation();
  const { teamMemberId } = useParams();

  // Form Initial Values
  const initialValues: IncreamentFormValues = {
    documentName: "",
    url: [],
  };

  // Validation Schema
  const validationSchema = object().shape({
    documentName: object().required("Please select document name"),
    url: array()
      .required("Required things is required")
      .min(1, "At least one documents is required"),
  });

  // Handle Submit
  const handleSubmit = (
    values: IncreamentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<IncreamentFormValues>
  ) => {
    const formattedValues = {
      documentName: values?.documentName?.value,
      documentUrl: values?.url?.map(
        (attachment: {
          url: string;
          type: "images" | "pdfs" | "videos" | "xlsx";
        }) => {
          return {
            url: attachment?.url,
            type: FILE_FORMAT[attachment.type],
          };
        }
      ),
    };
    addDocument({ body: formattedValues, userId: teamMemberId }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AddIncreamentForm formikProps={formikProps} onClose={onClose} />
        </Form>
      )}
    </Formik>
  );
};

export default AddDocumentFormWrapper;
