import React from "react";
import Dialog from "@mui/material/Dialog";
import LogHistoryDialog from "./LogHistoryDialog";

type Props = {
  onClose: () => void;
  notMeReason: any;
  clarificationRemark: any;
};

const LogHistoryDialogWrapper = ({ onClose ,clarificationRemark , notMeReason }: Props) => {
  return (
    <Dialog open maxWidth="sm" fullWidth>
      <LogHistoryDialog
       notMeReason={notMeReason}
       clarificationRemark={clarificationRemark}
      onClose={onClose} />
    </Dialog>
  );
};

export default LogHistoryDialogWrapper;
