import { useState, useEffect } from "react";
import { useGetAllSkillQuery } from "src/services/SkillService";

const useSkills = () => {
    const [skills, setSkills] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const { data, isLoading, isFetching } = useGetAllSkillQuery("");

    useEffect(() => {
        if (isLoading || isFetching) {
            setIsDataLoading(true);
        } else {
            const result: any[] = data?.data;
            setSkills(result || []);
            setIsDataLoading(false);
        }
    }, [data, isLoading, isFetching]);

    return { skills, isDataLoading };
};

export default useSkills;
