import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { ProductListResponse } from "src/models/Product.model";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/ProductSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { useGetProductsQuery } from "src/services/ProductService";
import ProductListing from "./ProductListing";
import EditProductFormWrapper from "../Edit/EditProductFormWrapper";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const ProductListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [selectedtProductId, setSelectedProductId] = useState("");

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.product);

  const { data, isLoading, isFetching } = useGetProductsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["productName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "productName",
      headerName: "Product Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      name: UserModuleNameTypes.PRODUCT_LIST_PRODUCT_NAME,
    },
    {
      field: "cost",
      headerName: "Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      name: UserModuleNameTypes.PRODUCT_LIST_AMOUNT,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: ProductListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectedProductId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_PRODUCT_EDIT,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <SideNavLayout>
      <div>
        <ProductListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
        />
      </div>
      {isOpenEditDialog && (
        <EditProductFormWrapper
          productId={selectedtProductId}
          onClose={() => setIsOpenEditDialog(false)}
        />
      )}
    </SideNavLayout>
  );
};

export default ProductListingWrapper;
