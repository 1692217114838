// |-- Built-in Dependencies --|
import React from "react";

// |-- External Dependencies --|
import { Formik } from "formik";
// import { object } from "yup";

// |-- Internal Dependencies --|
import { showToast } from "src/utils/showToaster";
import { useAddLeadsIntoCsvFormatMutation } from "src/services/LeadsService";
import CsvBulkUploadForm from "./CsvBulkUploadForm";

// |-- Types --|
type Props = {
  onClose: () => void;
};

export type FormInitialValues = {
  files: any;
};
const AddCsvFileWrapper = ({ onClose }: Props) => {
  const [addLeadsIntoCsvFormat] = useAddLeadsIntoCsvFormatMutation();

  const initialValues: FormInitialValues = {
    files: "",
  };

  // const validationSchema = object({});

  const onSubmitHandler = (values: FormInitialValues) => {
    const formData = new FormData();
    formData.append("file", values.files);

    setTimeout(() => {
      addLeadsIntoCsvFormat(formData)
        .then((res: any) => {
          if (res?.data?.status) {
            showToast("success", "added successfully!");
            onClose();
          } else {
            showToast("error", res?.data?.message);
            onClose();
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }, 1000);
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {(formikProps) => {
        return (
          <CsvBulkUploadForm onClose={onClose} formikProps={formikProps} />
        );
      }}
    </Formik>
  );
};

export default AddCsvFileWrapper;
