import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ATMTab from "src/components/UI/atoms/ATMTab/ATMTab";
import TaskActivitiesTabWrapper from "./Tabs/TaskActivitiesTab/TaskActivitiesTabWrapper";
import EmailActivitiesTabWrapper from "./Tabs/EmailActivitiesTab/EmailActivitiesTabWrapper";
import CallActivitiesTabWrapper from "./Tabs/CallActivitiesTab/CallActivitiesTabWrapper";
import MeetingActivitiesTabWrapper from "./Tabs/MeetingActivitiesTab/MeetingActivitiesTabWrapper";
import SMSActivitiesTabWrapper from "./Tabs/SMSActivitiesTab/SMSActivitiesTabWrapper";
import NoteActivitiesTabWrapper from "./Tabs/NoteActivitiesTab/NoteActivitiesTabWrapper";

const ActivitiesLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = new URLSearchParams(location.search);
  const selectedTab = activeTab.get("tab");

  useEffect(() => {
    if (!selectedTab) {
      navigate("?contact=activities&tab=task");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const tab: any[] = [
    {
      label: "Task",
      path: "task",
      onClick: () => {
        navigate("?contact=activities&tab=task");
      },
    },
    {
      label: "Notes",
      path: "notes",
      onClick: () => {
        navigate("?contact=activities&tab=notes");
      },
    },
    {
      label: "Emails",
      path: "emails",
      onClick: () => {
        navigate("?contact=activities&tab=emails");
      },
    },
    {
      label: "Calls",
      path: "calls",
      onClick: () => {
        navigate("?contact=activities&tab=calls");
      },
    },
    {
      label: "Meetings",
      path: "meetings",
      onClick: () => {
        navigate("?contact=activities&tab=meetings");
      },
    },

    {
      label: "SMS",
      path: "sms",
      onClick: () => {
        navigate("?contact=activities&tab=sms");
      },
    },
  ];

  return (
    <div>
       <div className="flex flex-col h-full overflow-hidden">
          <div className="sticky top-0 md:p-4 p-2 bg-white z-10 md:flex block ">
            <div className="">
              <ATMTab
                tabs={tab}
                selected={(tab: any) => tab.path === selectedTab}
                childrenLabel={selectedTab}
                children={<></>}
              />
            </div>
          </div>
          {/* {renderTabContent()} */}

          <div>
            {selectedTab && (
              <div className=" p-1">
                {selectedTab === "task" && <TaskActivitiesTabWrapper />}
                {selectedTab === "notes" && <NoteActivitiesTabWrapper />}
                {selectedTab === "emails" && <EmailActivitiesTabWrapper />}
                {selectedTab === "calls" && <CallActivitiesTabWrapper />}
                {selectedTab === "meetings" && <MeetingActivitiesTabWrapper />}
                {selectedTab === "sms" && <SMSActivitiesTabWrapper />}
              </div>
            )}
          </div>
        </div>
    </div>
  );
};

export default ActivitiesLayout;
