import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { IncidentFormValues } from "../Add/AddIncidentFormWrapper";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import moment from "moment";
import { useGetPointerAssignUserQuery } from "src/services/UserService";
import { useGetAllIncidentCategoryQuery } from "src/services/IncidentCategoryService";
import { useAllIncidentEventTypeQuery } from "src/services/IncidentTypeService";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";

type Props = {
  formikProps: FormikProps<IncidentFormValues>;
  onClose: () => void;
  formType: "Add" | "Update";
};

const IncidentForm = ({ formikProps, onClose, formType }: Props) => {
  const { userData } = useGetLocalStorage();


  const { values, setFieldValue, isSubmitting, handleBlur } =
    formikProps;

  const { data, isLoading, isFetching } = useGetAllIncidentCategoryQuery("");

  const categoryOptions = data?.data?.map((el: any) => {
    return {
      label: el?.categoryName,
      value: el?._id,
    };
  });
  const {
    data: incidentTypeData,
    isLoading: incidentTypeDataIsLoading,
    isFetching: incidentTypeDataIsFetching,
  } = useAllIncidentEventTypeQuery({
    limit: 10,
    searchValue: "",
    params: ["categoryName", "typeName"],
    page: 1,
    filterBy: [
      {
        fieldName: "categoryId",
        value: [values?.category?.value || null],
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  const IncidentTypeOption = incidentTypeData?.data?.map((el: any) => {
    return {
      label: el?.typeName,
      value: el?._id,
    };
  });

  const {
    data: assignUserData,
    isLoading: assignUserDataIsLoading,
    isFetching: assignUserDataIsFetching,
  } = useGetPointerAssignUserQuery(userData?.userId, {
    skip: !userData?.userId,
  });

  const assignUserOption = assignUserData?.data?.assigned_users?.map(
    (ele: any) => {
      return {
        label: ele?.name,
        value: ele?._id,
      };
    }
  );

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">{formType} Incident</div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Category */}
        <div>
          <ATMSelect
            name="category"
            required
            label="Category"
            value={values?.category}
            onBlur={handleBlur}
            placeholder="Select feature"
            isLoading={isFetching || isLoading}
            options={categoryOptions}
            onChange={(newValue) => {
              setFieldValue("category", newValue);
              setFieldValue("eventType", "");
            }}
          />
        </div>
        {/* Event Type */}
        <div>
          <ATMSelect
            required
            name="eventType"
            label="Event Type"
            value={values?.eventType}
            onBlur={handleBlur}
            isLoading={incidentTypeDataIsFetching || incidentTypeDataIsLoading}
            placeholder="Select Event Type"
            options={IncidentTypeOption}
            onChange={(newValue) => {
              setFieldValue("eventType", newValue);
            }}
          />
        </div>
       
          <div>
            <ATMCheckbox 
              disabled={formType === 'Update' ? true : false}
              label="Mark As Unhandled"
              checked={values?.isMarkAsUnhandled}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setFieldValue("isMarkAsUnhandled", checked);
              }}
            />
          </div>
     

        {/* Assign Name */}
        {!values?.isMarkAsUnhandled && (
          <div>
            <ATMMultiSelect
              name="assignName"
              disabled={formType === "Update" ? true : false}
              required
              label="Assign"
              value={values?.assignName}
              onBlur={handleBlur}
              isLoading={assignUserDataIsFetching || assignUserDataIsLoading}
              placeholder="Select Assign"
              options={assignUserOption}
              onChange={(newValue) => {
                setFieldValue("assignName", newValue);
              }}
            />
          </div>
        )}
        {/* Date */}
        <div>
          <ATMDatePicker
            label="Date"
            name="Date"
            maxDate={moment().format("yyyy-MM-DD")}
            format="DD/MM/YYYY"
            value={values?.Date}
            onChange={(newValue) => setFieldValue("Date", newValue)}
          />
        </div>
        {/* Remark */}
        <div className="">
          <ATMTextField
            required
            name="remark"
            value={values?.remark}
            onChange={(e) => setFieldValue("remark", e.target.value)}
            label="Remark"
            placeholder="Enter Remark"
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default IncidentForm;
