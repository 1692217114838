import { useEffect, useRef } from "react";
// import { useGetAllTicketWithoutPaginationQuery } from "src/services/TicketService";
import { useParams } from "react-router-dom";
// import { useGetByIdChangeRequestQuery } from "src/services/ChangeRequestService";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import {
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/TicketSlice";
import { RootState } from "src/redux/store";
import { useGetAllTicketQuery } from "src/services/TicketServices";
import TicketDownloadSummary from "./TicketDownloadSummary";
import TicketSummary from "./TicketSummary";

const TicketSummaryWrapper = () => {
  const dispatch = useDispatch();

  const { items, page, rowsPerPage, searchValue, dateFilter } = useSelector(
    (state: RootState) => state.ticket
  );
  const { singleItemInfo: changeRequestDetails } = useSelector(
    (state: RootState) => state.changeRequest
  );
  const changeRequestRef = useRef(null);

  //USESTATE
  const { id: CHANGE_REQUEST_ID } = useParams();


  const {
    data: ticketsData,
    isLoading: isLoadingTicketData,
    isFetching: isFetchingTicketData,
  } = useGetAllTicketQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["title", "ticketNumber"],
    page: page,
    filterBy: [
      {
        fieldName: "changeRequestId",
        value: CHANGE_REQUEST_ID,
      },
    ],
    dateFilter: {
      startDate: dateFilter?.start_date || "",
      endDate: dateFilter?.end_date || "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (isLoadingTicketData || isFetchingTicketData) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(ticketsData?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(ticketsData?.totalItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingTicketData, isFetchingTicketData, ticketsData]);


  const handlePrint = useReactToPrint({
    content: () => changeRequestRef?.current,
    documentTitle: `${changeRequestDetails?.requestName}(codiotic-connect)`,
  });
  if (isLoadingTicketData || isFetchingTicketData) {
    return (
      <Backdrop open>
        <CircularProgress size={50} />
      </Backdrop>
    );
  }

  return (
    <>
      <div className="flex justify-end  bg-white z-10  px-6">
        <ATMLoadingButton
          className="xl:w-[10%] md:w-[15%] w-[25%]  px-4"
          onClick={() => {
            // setTimeout(() => {
            handlePrint();
            // }, 1000);
          }}
        >
          Download
        </ATMLoadingButton>
      </div>
      <div className="hidden">
        <TicketDownloadSummary
          ref={changeRequestRef}
          getAllTicketData={items}
          changeRequestDetails={changeRequestDetails}
        />
      </div>
      <TicketSummary
        getAllTicketData={items}
        changeRequestDetails={changeRequestDetails}
      />
    </>
  );
};

export default TicketSummaryWrapper;
