import React, { useEffect, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import LoginForm from "./LoginForm";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";
import { showToast } from "src/utils/showToaster";

import { AppDispatch } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken, setUserData } from "src/redux/slices/AuthSlice";
import { useLoginMutation } from "src/services/AuthServices";
import {
  authTokenKeyName,
  refreshTokenKeyName,
} from "src/utils/configs/authConfig";
import { RootState } from "src/redux/store";

export type LoginFormInitialValues = {
  email: string;
  password: string;
};

const LoginFormWrapper = () => {
  const [isInvalidPassword, setIsInvalidPassword] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const userDataState = useSelector((state: RootState) => state.auth);

  const { accessToken } = userDataState;
  const afterLogin = (res: any) => {
    localStorage.setItem(authTokenKeyName, res?.token);
    localStorage.setItem(refreshTokenKeyName, res?.refreshToken);
    // localStorage.setItem("userType", res?.userType);
    // localStorage.setItem("deviceId", res?.deviceId);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        name: res?.name,
        mobile: res?.mobile,
        email: res?.email,
        userId: res?.userId,
        userType: res?.userType,
        positionId: res?.positionId,
        departmentId: res?.departmentId,
        lastLoginDateAndTime: res?.lastLoginDateAndTime,
        lastLoginIpAddress: res?.lastLoginIpAddress,
      })
    );
    dispatch(setAccessToken(res?.accessToken));
    dispatch(
      setUserData(
        {
          name: res?.name,
          mobile: res?.mobile,
          email: res?.email,
          userId: res?.userId,
          userType: res?.userType,
          positionId: res?.positionId,
          departmentId: res?.departmentId,
          lastLoginDateAndTime: res?.lastLoginDateAndTime,
          lastLoginIpAddress: res?.lastLoginIpAddress,
        } || null
      )
    );
  };
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const initialValues: LoginFormInitialValues = {
    email: "",
    password: "",
  };

  const validationSchema = object({
    email: string().email("invalid email").required("email is required"),
    password: string().required("password is required"),
  });

  const handleSubmit = async (
    values: LoginFormInitialValues,
    { setSubmitting }: FormikHelpers<LoginFormInitialValues>
  ) => {
    const { email, password } = values;
    const formatedValues = {
      email: email?.toLowerCase()?.trim(),
      password: password?.trim(),
    };
    setSubmitting(true);
    try {
      await login(formatedValues).then((res: any) => {
        setSubmitting(false);
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            afterLogin(res?.data?.data);
            navigate("/welcome");
          } else {
            showToast("error", res?.data?.message);
            setIsInvalidPassword(true);
          }
        }
      });
    } catch (error) {
      // Handle any other errors that occur
      setIsInvalidPassword(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      navigate("/");
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setIsInvalidPassword(false);
    }, 1000);
  }, [isInvalidPassword]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <LoginForm
              formikProps={formikProps}
              isInvalidPassword={isInvalidPassword}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginFormWrapper;
