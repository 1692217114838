/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import moment from "moment";
import { object, string } from "yup";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import TicketListFilterFormDialog from "./TicketListFilterFormDialog";
import {
  setDateFilter,
  setPriorityFilter,
  setTypeFilter,
  setStatusFilter,
  setCreatedByFilter,
  setProjectsFilter,
  setClientSideTicketStatusFilter,
  setChangeRequestId,
  setIsArchivedFilter,
  setResourceRequiredFilter,
  setTags,
  setTagsNot
} from "src/redux/slices/TicketSlice";
import { useGetAllTagsQuery } from "src/services/TicketServices";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  startDate: string | null;
  endDate: string | null;
  priority: string[];
  type: string[];
  tags: any;
  tagsNot : any;
  status: string[];
  project: string[];
  createdBy: string[];
  resosurceRequired: boolean;
  clientSideTicketStatus: any;
  changeRequestId: any;
  isArchived: boolean;
};

const TicketListFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const ticketState = useSelector((state: RootState) => state.ticket);
  const {
    dateFilter,
    priority,
    type,
    status,
    tags,
    projects,
    createdBy,
    clientSideTicketStatusFilterValue,
    changeRequestId,
    isArchived,
    resosurceRequired,
    tagsNot
  } = ticketState;

  const { data: AllTagsData , isFetching : AllTagsDataIsFetching , isLoading : AllTagsDataIsLoading  } = useGetAllTagsQuery("");
  const tagsOptions = AllTagsData?.data?.map((el: any) => {
    return {
      label: el?.tagName,
      value: el?.tagName,
    };
  });

  const initialValues: FormInitialValues = {
    startDate: dateFilter.start_date,
    endDate: dateFilter.end_date,
    priority: priority,
    changeRequestId: changeRequestId,
    type: type,
    tags: tags,
    tagsNot:tagsNot ,
    status: status,
    project: projects,
    createdBy: createdBy,
    clientSideTicketStatus: clientSideTicketStatusFilterValue,
    isArchived: isArchived,
    resosurceRequired: resosurceRequired,
  };

  const validationSchema: any = object({
    startDate: string().required("please select start date"),
    endDate: string().required("please select end date"),
  });

  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);

    // created date from to dispatch
    dispatch(
      setDateFilter({
        start_date: values?.startDate
          ? moment(values?.startDate)?.format("yyyy-MM-DD")
          : "",
        end_date: values?.endDate
          ? moment(values?.endDate)?.format("yyyy-MM-DD")
          : "",
      })
    );

    // ticket priority  dispatch
    dispatch(setPriorityFilter(values?.priority));

    // ticket type  dispatch
    dispatch(setTypeFilter(values?.type));

    // ticket status  dispatch
    dispatch(setStatusFilter(values?.status));

    // createdBy filter dispatch
    dispatch(setCreatedByFilter(values?.createdBy));

    // project filter dispatch
    dispatch(setProjectsFilter(values?.project));

    // ticket filter via client side ticket status
    dispatch(setClientSideTicketStatusFilter(values?.clientSideTicketStatus));

    // tags
    dispatch(setTags(values?.tags));

    //Not Tags
     dispatch(setTagsNot(values?.tagsNot));

    //ticket filter By change Request
    dispatch(setChangeRequestId(values?.changeRequestId));

    dispatch(setIsArchivedFilter(values?.isArchived));

    dispatch(setResourceRequiredFilter(values?.resosurceRequired));
    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        start_date: "",
        end_date: "",
      })
    );

    // priority filter dispatch
    await dispatch(setPriorityFilter([]));

    // type filter dispatch
    await dispatch(setTypeFilter([]));

    // status filter dispatch
    await dispatch(setStatusFilter(""));
    
    //Tags
    await dispatch(setTags([]));

    //Tags Not
    await dispatch(setTagsNot([]));

    // project filter dispatch

    await dispatch(setCreatedByFilter([]));

    // project filter dispatch
    await dispatch(setProjectsFilter([]));

    // client side ticket status
    await dispatch(setClientSideTicketStatusFilter(""));

    //ticket filter By change Request
    await dispatch(setChangeRequestId(""));
    await dispatch(setIsArchivedFilter(false));

    dispatch(setResourceRequiredFilter(false));

    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <TicketListFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            tagsOptions={tagsOptions} 
            tagsIsLoading ={AllTagsDataIsFetching || AllTagsDataIsLoading}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TicketListFilterFormDialogWrapper;
