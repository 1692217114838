import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsOpenEditFormDialog,
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/ReminderSlice";
import { AppDispatch, RootState } from "src/redux/store";
import ReminderEditWrapper from "../Edit/ReminderEditWrapper";
import ReminderListing from "./ReminderListing";
import { MdBlock } from "react-icons/md";
import { MdEdit, MdDelete } from "react-icons/md";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { showToast } from "src/utils/showToaster";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { ReminderListResponse } from "src/models/Reminder.model";
import {
  useChangeStatusReminderMutation,
  useDeleteReminderMutation,
  useGetAllReminderPaginationDataQuery,
} from "src/services/ReminderService";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const ReminderListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedReminderId, setselectedReminderId] = useState<string>("");
  const [activeUpdateReminder] = useChangeStatusReminderMutation();
  const [deleteReminder] = useDeleteReminderMutation();

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    isOpenEditFormDialog,
    // selectedClient,
  } = useSelector((state: RootState) => state.reminder);

  const { data, isLoading, isFetching } = useGetAllReminderPaginationDataQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["source", "reminder"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleStatusChangeReminder = (id: string) => {
    activeUpdateReminder(id)
      .then((res: any) => {
        showToast("success", "status change successfully");
      })
      .catch((err: any) => {
        console.error("error", err);
      });
  };

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Reminder?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteReminder(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "dateTime",
      headerName: "Date & Time",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.REMINDER_LIST_DATE_TIME,
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {formatedDateTimeIntoIst(row.dateTime, "DD MMM yyyy")}
          </div>
          <div className="text-[13px] font-medium text-slate-400">
            {formatedDateTimeIntoIst(row.dateTime, "hh:mm A")}
          </div>
        </div>
      ),
    },
    {
      field: "reminder",
      headerName: "Reminder",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.REMINDER_LIST_REMINDER,
      renderCell: (row: any) => (
        <div>
          <ATMMarkdownContainer markdown={row?.reminder || "-"} />
        </div>
      ),
    },
    {
      field: "source",
      headerName: "Source",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.REMINDER_LIST_SOURCE,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: ReminderListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  dispatch(setIsOpenEditFormDialog(true));
                  setselectedReminderId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_REMINDER_EDIT,
              },
              {
                label: row.isActive ? (
                  <div className="flex items-center gap-x-2">
                    <MdBlock color="red" /> Deactivate
                  </div>
                ) : (
                  <div className="flex items-center gap-x-2">
                    <VscActivateBreakpoints color="#00a67e" /> Activate
                  </div>
                ),
                onClick: () => {
                  handleStatusChangeReminder(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_REMINDER_DEACTIVE,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_REMINDER_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <SideNavLayout>
        <ReminderListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
        />
      </SideNavLayout>

      {isOpenEditFormDialog ? (
        <ReminderEditWrapper
          onClose={() => dispatch(setIsOpenEditFormDialog(false))}
          selectedReminderId={selectedReminderId}
        />
      ) : null}
    </div>
  );
};

export default ReminderListingWrapper;
