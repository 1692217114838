import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MeetingListResponse } from "src/models/Meeting.model";
import ATMPageHeading from "src/components/UI/atoms/ATMPageHeading/ATMPageHeading";
import { useGetMeetingByIdQuery } from "src/services/MeetingServices";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import moment from "moment";
import { Divider } from "@mui/material";
import HoldStartMeetingTaskWrapper from "src/screens/Meeting/List/HoldStartMeetingTask/HoldStartMeetingTaskWrapper";

type Props = {};

const AgendaTabWrapper = (props: Props) => {
  const [meetingData, setMeetingData] = useState<MeetingListResponse>();
  const [HoldJoinMeetingTask, setHoldJoinMeetingTask] = useState(false)
  const { meetingId: MEETING_ID } = useParams();

  const { isLoading, isFetching, data } = useGetMeetingByIdQuery(
    MEETING_ID || "",
    {
      skip: !MEETING_ID,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setMeetingData(data?.data);
      if (data?.data?.actualStartTime  === '') {
        setHoldJoinMeetingTask(true)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, data]);

  return (
    <div className="flex flex-col h-full md:flex-row">
      <div className="flex flex-col flex-1 gap-4 p-4">
        <div className="flex flex-col gap-2">
          <ATMPageHeading>Meeting Agenda</ATMPageHeading>
          {/* <div className="mt-5 text-sm">{meetingData?.agenda}</div> */}
          {/* <ATMMarkdownContainer markdown={meetingData?.agenda || "-"} /> */}

          <div className="flex flex-col gap-2 p-4 py-0 rounded-md">
            {meetingData?.agenda?.map((ele: string, ind: string) => {
              return (
                <div className="flex gap-4 cursor-pointer" key={ind}>
                  <input type="checkbox" id={`${ind}`} />
                  {/* <label htmlFor={`${ind}`}>{ele}</label> */}
                  <ATMMarkdownContainer markdown={ele || "-"} />
                </div>
              );
            })}
          </div>
        </div>

        <Divider />

        {/* Required Things */}
        <div className="flex flex-col gap-2">
          <ATMPageHeading>Required Things</ATMPageHeading>

          <div className="p-4 py-0 rounded-md">
            {meetingData?.requiredThings?.map((ele: string, ind: string) => {
              return (
                <div className="flex gap-4 cursor-pointer" key={ind}>
                  <input type="checkbox" id={`${ele + ind}`} />
                  <label htmlFor={`${ele + ind}`}>{ele}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Divider />

      {/* Meeting End And Id */}
      <div className="md:w-[300px] border-l flex flex-col h-full">
        {/* <ATMPageHeading>Meeting Details</ATMPageHeading> */}

        {/* <h2 className="mb-4 text-xl font-bold">Meeting Details</h2> */}

        <div className="flex flex-col gap-2 p-3 border-b">
          <p className="text-sm text-gray-500">Scheduled Date & Time</p>
          <p className="font-semibold text-gray-800">
            {moment(meetingData?.createdAt).format("MMMM D, YYYY")}
            <br />
            <div className="flex items-center gap-x-2">
              <span>
                {moment(meetingData?.scheduledStartTime, "HH:mm:ss").format("hh:mm A")}
              </span>
              {meetingData?.scheduledEndTime && (
                <div className="flex gap-x-2">
                  <span>To</span>
                  <span>
                    {moment(meetingData?.scheduledEndTime, "HH:mm:ss").format("hh:mm A")}
                  </span>
                </div>
              )}
            </div>
          </p>
        </div>

        {meetingData?.actualStartTime && (
          <div className="flex flex-col gap-2 p-3 border-b">
            <p className="text-sm text-gray-500">Actual End Date & Time</p>
            <p className="font-semibold text-gray-800">
              {moment(meetingData?.actualStartTime).format("MMMM D, YYYY")}
              <br />
              <div className="flex gap-x-2">
                <span>
                  {moment(meetingData?.actualStartTime).format("hh:mm A")}
                </span>
                <span>To</span>
                <span>
                  {meetingData?.actualEndTime
                    ? moment(meetingData?.actualEndTime).format("hh:mm A")
                    : "-"}
                </span>
              </div>
            </p>
          </div>
        )}

        <div className="flex flex-col gap-2 p-3 border-b">
          <p className="text-sm text-gray-500">Meeting ID</p>
          <p className="font-semibold text-gray-800">{meetingData?._id}</p>
        </div>

        <div className="flex flex-col gap-2 p-3 border-b">
          {meetingData?.mode === "ONLINE" ? (
            <a
              href={`${meetingData?.meetingLink}`}
              target="_blank"
              rel="noreferrer"
              className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
            >
              Join Meeting
            </a>
          ) : (
            meetingData?.address && (
              <>
                <p className="text-sm text-gray-500">Address</p>
                <span>{meetingData?.address}</span>
              </>
            )
          )}
        </div>
      </div>

      {HoldJoinMeetingTask && (
        <HoldStartMeetingTaskWrapper
          onClose={() => {
            setHoldJoinMeetingTask(false);
            // setMeetingStartId("");
          }}
          meetingStartId={MEETING_ID}
        />
      )}
    </div>
  );
};

export default AgendaTabWrapper;
