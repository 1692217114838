import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsTableLoading,
  setItems,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/TeamViewSlice";
import { RootState } from "src/redux/store";
import { useGetAllTeamTaskWithPaginationQuery } from "src/services/UserService";
import TeamViewListing from "./TeamViewListing";

const TeamViewListingWrapper = () => {
  const { items, page, rowsPerPage, searchValue, isTableLoading } = useSelector(
    (state: RootState) => state.teamView
  );
  const { userData } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useGetAllTeamTaskWithPaginationQuery(
    {
      limit: rowsPerPage,
      searchValue: searchValue,
      params: [
        "taskGroupId",
        "title",
        "taskStatus",
        "assignedTo",
        "taskNumber",
        "name",
      ],
      page: page,
      filterBy: [],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    }
    // {
    //   refetchOnFocus: true,
    // }
  );

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
      // setIsRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const setUserFirstPositionBoard = (items: any[]) => {
    let newArray: any[] = [];

    for (let ele of items) {
      if (ele?._id === userData?.userId) {
        newArray = [ele, ...newArray];
      } else {
        newArray = [...newArray, ele];
      }
    } 
    let sortData = [...newArray]?.sort((a, b) => a?.name?.localeCompare(b?.name));
    return sortData;
  };

  return (
    <>
      <TeamViewListing
        rows={setUserFirstPositionBoard(items)}
        onClick={(taskId, ticketId) => {
          if (ticketId === null) {
            window.open(`/task/${taskId}`, "_blank");
          } else {
            window.open(`/ticket/${ticketId}/task/${taskId}`, "_blank");
          }
        }}
        paginationProps={{
          isTableLoading,
          searchValue,
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </>
  );
};

export default TeamViewListingWrapper;
