import moment from "moment";
import { MeetingListResponse } from "src/models/Meeting.model";

type Props = {
  meetingData: MeetingListResponse[];
  onMeetingClick: (meetingId: string) => void;
};

const ScheduledMeetings = ({ meetingData, onMeetingClick }: Props) => {
  return (
    <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg custom-shadow">
      <div className="flex items-center gap-2 p-2 border-b bg-yellow-50">
        <div className="flex items-center justify-center p-2 font-semibold bg-white border rounded-md border-primary-dark size-8">
          {meetingData.length}
        </div>
        <p className="font-semibold">Scheduled meetings</p>
      </div>
      {meetingData.length ? (
        <div className="h-full py-2 overflow-auto">
          {meetingData?.map((meetingInfo: MeetingListResponse) => (
            <div
              key={meetingInfo?._id}
              className="flex items-center justify-between px-4 py-1 hover:bg-hover"
            >
              <div className="flex cursor-pointer">
                <p
                  className="text-sm line-clamp-2"
                  onClick={() => onMeetingClick(meetingInfo?._id)}
                >
                  {meetingInfo?.title}
                </p>
              </div>
              <div className="w-[150px] text-end  text-primary-main  text-sm font-medium">
                {moment(meetingInfo?.scheduledStartTime).format("hh:mm A")}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-4">
          No Meeting Scheduled
        </div>
      )}
    </div>
  );
};

export default ScheduledMeetings;
