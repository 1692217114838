import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { isMobile } from "react-device-detect";
import { CaseFlowFormProps } from "src/models/CaseFlow.model";
import { object, string } from "yup";
import CashFlowFormLayout from "../Layout/CashFlowFormLayout";

type Props = {
  onClose: () => void;
  paymentId?: any;
};

const AddCashFlowFormWrapper = ({ onClose, paymentId }: Props) => {
  // Form Initial Values
  const initialValues: CaseFlowFormProps = {
    date: null,
    amount: "",
    category: "",
    account: "",
    mode: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    conversionLoss: string(),
    account: object().required("Please Select account"),
    mode: object().required("Please select mode"),
    remark: string().required("Please enter remark"),
    paymentDate: string().required("Please select date"),
  });

  // Handle Submit
  const handleSubmit = (
    values: CaseFlowFormProps,
    { setSubmitting, resetForm }: FormikHelpers<CaseFlowFormProps>
  ) => {};

  return (
    <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <CashFlowFormLayout
              formType="ADD"
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddCashFlowFormWrapper;
