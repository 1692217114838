export const extractFileInfoFromUrl = (url: string) => {
  const regex =
    /^https:\/\/fm-api\.codioticdemo\.com\/uploads\/CODIOTIC_CONNECT_ADMIN\/([^/]+)\/([^/]+)$/;
  const match = url.match(regex);

  if (match) {
    return {
      type: match[1],
      name: match[2],
    };
  }

  return null;
};
