// |-- Built-in Dependencies --|
import React, { useState, useEffect } from "react";

// |-- External Dependencies --|
import { useDispatch, useSelector } from "react-redux";

// |-- Internal Dependencies --|
import StateListing from "./StateListing";
import AddStateFormWrapper from "../Add/AddStateFormWrapper";

// |-- Redux --|
import { AppDispatch, RootState } from "src/redux/store";
import { setItems } from "src/redux/slices/StatesSlice";
import { useGetStateQuery } from "src/services/StateServices";

const StateListingWrapper = () => {
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { items }: any = useSelector((state: RootState) => state.states);

  const { searchValue: searchValueState, filterValue }: any = useSelector(
    (state: RootState) => state.states
  );

  const states = items?.map((ele: any) => {
    return { label: ele.stateName, value: ele._id };
  });

  const { data, isLoading, isFetching } = useGetStateQuery(
    {
      limit: 60,
      searchValue: searchValueState,
      params: ["stateName", "countryId"],
      page: 0,
      filterBy: [
        {
          fieldName: "countryId",
          value: filterValue ? filterValue : [],
        },
      ],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
    },
    {
      skip: !filterValue || filterValue?.length === 0,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <>
      <StateListing states={states} onAddNew={() => setIsOpenAddForm(true)} />
      {isOpenAddForm && (
        <AddStateFormWrapper onClose={() => setIsOpenAddForm(false)} />
      )}
    </>
  );
};

export default StateListingWrapper;
