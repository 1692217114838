import { ErrorMessage, FormikProps } from "formik";
import moment from "moment";
import { useSelector } from "react-redux";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { ReminderFormValues } from "src/models/Reminder.model";
import { RootState } from "src/redux/store";

type Props = {
  formikProps: FormikProps<ReminderFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const ReminderForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, setFieldTouched } = formikProps;
  const { codioticUsers, isDataLoading } = useGetAllCodioticUsers();
  const { userData } = useSelector((state: RootState) => state?.auth);
  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Reminder" : "Update Reminder"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Reminder  */}
        <div className="z-0">
          <MOLMarkdownEditor
            label="Reminder"
            value={values.reminder}
            placeholder="Write reminder  or drag your files here...."
            onChange={(newValue: any) => {
              setFieldValue("reminder", newValue);
              setFieldTouched("reminder", false);
            }}
          />

          <ErrorMessage
            name="reminder"
            component="reminder"
            className="font-poppins absolute text-[14px] text-start mt-0 text-red-500"
          />
        </div>

        <div className="w-full">
          <ATMDatePicker
            label="Date & Time"
            name="dateTime"
            format="DD/MM/YYYY"
            minDate={moment().format("YYYY-MM-DD")}
            value={values.dateTime}
            onChange={(newValue) => setFieldValue("dateTime", newValue)}
          />
        </div>

        <div>
          <ATMMultiSelect
            name="For Whom"
            label="For Whom"
            placeholder="Select forWhom"
            value={values.forWhom}
            isLoading={isDataLoading}
            options={codioticUsers?.map((ele) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return (
                  <div className="flex capitalize gap-x-3">
                    {option.label}
                    {option?.value === userData?.userId && (
                      <span className="bg-green-500 py-[2px] flex items-center px-[8px] text-center rounded-full text-white text-[10px]">
                        It's you
                      </span>
                    )}
                  </div>
                );
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            onChange={(newValue) => {
              setFieldValue("forWhom", newValue);
            }}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default ReminderForm;
