import React, { useEffect, useState } from "react";
import HolidayCalendarListing from "./HolidayCalendarListing";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setItems,
  setTotalItems,
  setIsTableLoading,
} from "src/redux/slices/HolidayCalendarSlice";
import AddHolidayCalendarFormWrapper from "../Add/AddHolidayCalendarFormWrapper";
import { RxPencil1 } from "react-icons/rx";
import { MdDeleteOutline } from "react-icons/md";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import {
  useGetHolidayCalendarPaginationQuery,
  useDeleteHoliDayMutation,
} from "src/services/HolidayCalendarServices";
import { showToast } from "src/utils/showToaster";
import EditHolidayCalendarFormWrapper from "../Edit/EditHolidayCalendarFormWrapper";
import moment from "moment";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";

type Props = {};

const HolidayCalendarListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    dateFilter,
  } = useSelector((state: RootState) => state.holidayCalendar);
  const [isOpenAddFrom, setIsOpenAddForm] = useState(false);
  const [isOpenEditFrom, setIsOpenEditForm] = useState(false);
  const [holidayId, setHolidayId] = useState("");

  const [deleteHoliDay] = useDeleteHoliDayMutation();

  const { data, isLoading, isFetching } = useGetHolidayCalendarPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["holidayName", "date", "optional"],
    page: page,
    filterBy: [],
    dateFilter: dateFilter,
    orderBy: "date",
    orderByValue: 1,
    isPaginationRequired: true,
  });

  const getActionOptions = (row: any) => {
    return [
      {
        label: (
          <div className="flex items-center gap-2 text-secondary-main">
            <RxPencil1 className="text-lg" /> Edit
          </div>
        ),
        onClick: () => {
          setIsOpenEditForm(true);
          setHolidayId(row?._id);
        },
        acitonName: UserModuleNameTypes.ACTION_HOLIDAY_CALENDAR_EDIT,
      },
      {
        label: (
          <div className="flex items-center gap-2 font-semibold text-red-600">
            <MdDeleteOutline className="text-lg" /> Delete
          </div>
        ),
        onClick: () => {
          showConfirmationDialog({
            title: "Heads Up",
            text: "Are you sure want to Delete this Holiday Calendar ?",
            icon: "question",
            showCancelButton: true,
            next: (result) => {
              if (result.isConfirmed) {
                deleteHoliDay(row?._id).then((res: any) => {
                  if (res?.error) {
                    showToast("error", res?.error?.data?.message);
                  } else {
                    if (res?.data?.status) {
                      showToast("success", res?.data?.message);
                    } else {
                      showToast("error", res?.data?.message);
                    }
                  }
                });
              }
            },
          });
        },
        acitonName: UserModuleNameTypes.ACTION_HOLIDAY_CALENDAR_DELETE,
      },
    ];
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setRowsPerPage(data?.pageSize || 0));
      dispatch(setPage(data?.currentPage || 0));
      dispatch(setIsTableLoading(false));
    } else {
      dispatch(setIsTableLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "date",
      headerName: "Date",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.HOLIDAY_CALENDAR_LIST_DATE,
      renderCell: (row: any) => (
        <span>
          <div className="">{moment(row?.date).format("DD MMM YYYY")}</div>{" "}
        </span>
      ),
    },
    {
      field: "holidayName",
      extraClasses: "py-2 min-w-[140px]",
      name: UserModuleNameTypes.HOLIDAY_CALENDAR_LIST_HOLIDAY_NAME,
      headerName: "Holiday Name",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: any) => <span> {row?.holidayName} </span>,
    },
    // {
    //   field: "branchId",
    //   headerName: " Branch Name",
    //   flex: "flex-[1.5_1.5_0%]",
    //   renderCell: (row: any) => {
    //     return row?.branchId?.map((branch: any) => {
    //       return <Chip label={branch?.branchName} key={branch?._id} className="my-2 me-2" />;
    //     })
    //   },
    // },
    {
      field: "optional",
      headerName: "Optional",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.HOLIDAY_CALENDAR_LIST_OPTIONAL,
      renderCell: (row: any) => <span> {row.optional ? "YES" : "NO"} </span>,
    },
    {
      noAuthRequired: true,
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => {
        const options = getActionOptions(row);
        return <ATMActionMenu options={options} orientation="vertical" />;
      },
    },
  ];

  return (
    <div>
      <HolidayCalendarListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
        onAddNew={() => setIsOpenAddForm(true)}
      />
      {isOpenAddFrom ? (
        <AddHolidayCalendarFormWrapper
          onClose={() => setIsOpenAddForm(false)}
        />
      ) : null}

      {isOpenEditFrom ? (
        <EditHolidayCalendarFormWrapper
          holidayId={holidayId}
          onClose={() => setIsOpenEditForm(false)}
        />
      ) : null}
    </div>
  );
};

export default HolidayCalendarListingWrapper;
