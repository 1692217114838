import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/GoalsSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useDeleteGoalsMutation,
  useGetGoalsQuery,
} from "src/services/GoalsService";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import EditSubscriptionWrapper from "../Edit/EditGoalWrapper";
import AddAchievementWrapper from "./Achievements/Add/AddAchievementWrapper";
import AccountsListing from "./GoalListing";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const GoalListingWrapper = (props: Props) => {
  const { userData } = useGetLocalStorage();

  const dispatch = useDispatch<AppDispatch>();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenAchivementDialog, setIsOpenAchivementDialog] = useState(false);

  const [selectedtProductId, setSelectedProductId] = useState("");
  const [selectedtGoal, setSelectedGoal] = useState("");

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    dateFilter,
  } = useSelector((state: RootState) => state.goals);

  const [deleteExpense] = useDeleteGoalsMutation();

  const { data, isLoading, isFetching } = useGetGoalsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["name"],
    page: page,
    filterBy: [],
    dateFilter: dateFilter,
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this goal?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteExpense(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };
  // Utility function to calculate the difference in days between two dates
  const calculateRemainingDays = (deadline: any) => {
    if (!deadline) return "-";
    const currentDate = new Date();
    const deadlineDate = new Date(deadline);

    // Calculate the difference in milliseconds
    const differenceInMs = Number(deadlineDate) - Number(currentDate);

    // Convert milliseconds to days
    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

    if (differenceInDays > 0) {
      return `${differenceInDays} days left`;
    } else if (differenceInDays === 0) {
      return "Deadline is today";
    } else {
      return "Deadline has passed";
    }
  };

  const columns: columnTypes[] = [
    {
      field: "goalCategoryName",
      headerName: "Goal Category Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize  min-w-[150px]",
      name: UserModuleNameTypes.NAV_GOAL_LIST_NAME,
    },

    {
      field: "name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize  min-w-[150px]",
      name: UserModuleNameTypes.NAV_GOAL_LIST_NAME,
    },

    {
      field: "type",
      headerName: "Type",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.NAV_GOAL_LIST_TYPE,
    },
    {
      field: "target",
      headerName: "Target",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[120px]",
      name: UserModuleNameTypes.NAV_GOAL_LIST_TARGET,
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {row.target
              ? `${row?.achieved}/${row.target}`
              : `${row.achieved}/100%`}
          </div>
        </div>
      ),
    },
    {
      field: "achieved",
      headerName: "Pending",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[120px]",
      name: UserModuleNameTypes.NAV_GOAL_LIST_PENDING,
      renderCell: (row: any) => {
        let finalPending = Math.max(
          (row?.type === "COUNTABLE" ? row.target : 100) - row.achieved,
          0
        );
        return (
          <div>
            <div className="font-medium text-slate-700">
              {row?.target ? finalPending : `${finalPending}%`}
            </div>
          </div>
        );
      },
    },
    {
      field: "deadline",
      headerName: "Deadline date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize  min-w-[200px]",
      name: UserModuleNameTypes.NAV_GOAL_LIST_DEADLINE,
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {" "}
            {row.deadline ? calculateRemainingDays(row.deadline) : "-"}
          </div>
          <div className="text-xs text-slate-700">
            {row.deadline
              ? formatedDateTimeIntoIst(row.deadline, "DD MMM yyyy")
              : "-"}
          </div>
        </div>
      ),
    },
    {
      field: "accountName",
      headerName: "Members Involved ",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[250px]",
      name: UserModuleNameTypes.NAV_GOAL_LIST_MEMBERS,
      renderCell: (row: any) => (
        <div className="text-[12px]">
          {row?.membersInvolved?.length
            ? row?.membersInvolved?.map((el: any, index: any) => {
                return (
                  <Tooltip title={el?.memberName}>
                    <div className="flex flex-col gap-2">
                      {index + 1}. {el?.memberName}
                    </div>
                  </Tooltip>
                );
              })
            : "-"}
        </div>
      ),
    },
    {
      field: "measuredByName",
      headerName: "Measured By Name ",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.NAV_GOAL_LIST_MEASURED_BY,
    },
    {
      field: "target",
      headerName: "Achievement",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.NAV_GOAL_LIST_ACHIEVEMENT,
      renderCell: (row: any) => {
        let isConditionMet = false;
        if (row?.type === "COUNTABLE") {
          isConditionMet = row?.target > row?.achieved;
        } else if (row?.type === "UNCOUNTABLE") {
          isConditionMet = row?.achieved < 100;
        }
        return (
          <div>
            <div className="font-medium text-slate-700 ">
              {isConditionMet && row?.measuredById === userData?.userId ? (
                <ATMLoadingButton
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setIsOpenAchivementDialog(true);
                    setSelectedGoal(row);
                  }}
                >
                  Achievement
                </ATMLoadingButton>
              ) : (
                "-"
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectedProductId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_GOAL_EDIT,
              },

              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_GOAL_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <SideNavLayout>
      <div>
        <AccountsListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
          // onAddNew={() => setIsOpenAddDialog(true)}
        />
      </div>
      {isOpenEditDialog && (
        <EditSubscriptionWrapper
          selectedSubscription={selectedtProductId}
          onClose={() => setIsOpenEditDialog(false)}
        />
      )}

      {isOpenAchivementDialog && (
        <AddAchievementWrapper
          selectedGoals={selectedtGoal}
          onClose={() => setIsOpenAchivementDialog(false)}
        />
      )}
    </SideNavLayout>
  );
};

export default GoalListingWrapper;
