import { useEffect } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/JobCreationSlice";
import { RootState } from "src/redux/store";
import { useDeleteJobCreationMutation, useGetAllJobCreationListingQuery } from "src/services/JobCreationService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import JobCreationListing from "./JobCreationListing";
import moment from "moment";
import { setIsCollapsed } from "src/redux/slices/SideNavLayoutSlice";

const JobCreationListingWrapper = () => {
    const location = useLocation();

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [deleteJobCreation] = useDeleteJobCreationMutation()
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } = useSelector((state: RootState) => state.jobCreation);
  const {
    data: incomeList,
    isFetching,
    isLoading,
  } = useGetAllJobCreationListingQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["jobTitle",
      "jobLocation"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    // Ensure the sidebar state persists when navigating
    const handleLocationChange = location.pathname === '/job-onboard/job-creation'
    if (handleLocationChange) {
      dispatch(setIsCollapsed(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this goal?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteJobCreation(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns = [
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      flex: 'flex-[1_1_0%]',
      extraClasses: '',
      renderCell: (row: any) => <div>{row?.jobTitle || '-'}</div>,
    },
    {
      field: 'numberOfVacancies',
      headerName: 'Number of Vacancies',
      flex: 'flex-[1_1_0%]',
      extraClasses: '',
      renderCell: (row: any) => <div>{row?.numberOfVacancies || '-'}</div>,
    },
    {
      field: 'applicationDeadline',
      headerName: 'Application Deadline',
      flex: 'flex-[1_1_0%]',
      extraClasses: '',
      renderCell: (row: any) => (
        <div>{row?.applicationDeadline ? moment(row?.applicationDeadline).format('DD MMM YYYY') : '-'}</div>
      ),
    },
    {
      field: 'onBoardingBeforeDate',
      headerName: 'Onboarding Before Date',
      flex: 'flex-[1_1_0%]',
      extraClasses: '',
      renderCell: (row: any) => (
        <div> {row?.onBoardingBeforeDate ? moment(row?.onBoardingBeforeDate).format('DD MMM YYYY') : '-'}</div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  // setIsOpenEditDialog(true);
                  // setSelectedProductId(row?._id);
                  navigate(`/job-onboard/job-creation/edit/${row?._id}`)
                },
                // acitonName: UserModuleNameTypes.ACTION_GOAL_EDIT,
              },

              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                // acitonName: UserModuleNameTypes.ACTION_GOAL_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];
  // Setting Team Members Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(incomeList?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(incomeList?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeList, isLoading, isFetching]);
  return (
    <>
      <JobCreationListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </>
  );
};

export default JobCreationListingWrapper;
