import {
    Icon,
    IconCheck,
    IconCircle,
    IconRectangle,
  } from "@tabler/icons-react";
  import { ChangeEvent, KeyboardEvent } from "react";
  import { Edge, Node, useReactFlow } from "reactflow";
import ATMLoadingButton from "../../atoms/ATMLoadingButton/ATMLoadingButton";
  
  type Props = {
    selectedNodeId: string;
    selectedEdgeId: string;
    inputRef: any;
    edgeLabelRef: any;
    nodes: Node[];
    edges: Edge[];
    onSubmit:(nodes:Node[] , edges: Edge[] )=>void ;
  };
  
  const bgColorOptions = [
    "bg-sky-400",
    "bg-red-400",
    "bg-yellow-400",
    "bg-green-400",
    "bg-gray-400",
    "bg-white",
  ];
  
  export type ShapeName = "Rectangle" | "Circle" | "Parallelogram";
  
  const shapeOptions: { icon: Icon; title: ShapeName }[] = [
    { icon: IconRectangle, title: "Rectangle" },
    { icon: IconCircle, title: "Circle" },
    { icon: IconRectangle, title: "Parallelogram" },
  ];
  
  const strokeColorOptions = ["#ec4b4b", "#17c117", "#fadb0f", "#1888e4", "gray"];
  
  const MOLFlowToolbar = ({
    selectedNodeId,
    selectedEdgeId,
    inputRef,
    edgeLabelRef,
    nodes,
    edges,
    onSubmit
  }: Props) => {
    const { setNodes, setEdges } = useReactFlow();
  
    const handleLabelChange = (type: "Node" | "Edge", e: any, id: string) => {
      switch (type) {
        case "Node":
          setNodes((nodes : any) => {
            return nodes?.map((node: any) => {
              if (node?.id === id) {
                return {
                  ...node,
                  data: { ...node?.data, label: e.target.value },
                };
              } else {
                return node;
              }
            });
          });
          break;
  
        case "Edge":
          setEdges((edges: any) => {
            return edges?.map((edge : any) => {
              if (edge?.id === id) {
                return {
                  ...edge,
                  label: e.target.value,
                };
              } else {
                return edge;
              }
            });
          });
          break;
  
        default:
          break;
      }
    };
  
    const selectedEdge: Edge | undefined = edges?.find(
      (edge) => edge.id === selectedEdgeId
    );
    const selectedNode: Node | undefined = nodes?.find(
      (node) => node.id === selectedNodeId
    );
    return (
      <div className="h-full overflow-auto w-[300px] border flex flex-col gap-4 bg-gray-50 shadow rounded-md">
        <>
          <div className="p-3 font-semibold border-b border-slate-400 text-slate-600">
            Configure Node
          </div>
  
          <div className="flex flex-col gap-4 divide-y">
            {/* Node Customization */}
            {selectedNodeId ? (
              <div className="flex flex-col gap-1 px-3">
                <div className="text-sm font-semibold text-slate-500"> Node</div>
  
                {/* Shapes */}
                <div>
                  <RenderLabel label="Shape" />
                  <div className="flex gap-2 ">
                    {shapeOptions?.map((shape , ind) => {
                      return (
                        <shape.icon 
                        key={ind}
                          onClick={() => {
                            setNodes((nds : any) =>
                              nds?.map((nd : any) => {
                                if (nd?.id === selectedNodeId) {
                                  nd.data = {
                                    ...nd.data,
                                    shape: shape.title,
                                  };
                                }
  
                                return nd;
                              })
                            );
                          }}
                          size={30}
                          className="text-gray-500"
                          stroke={1}
                        />
                      );
                    })}
                  </div>
                </div>
  
                {/* Node Label */}
                <ATMTextArea
                  label="Node Label"
                  value={selectedNode?.data?.label || ""}
                  onChange={(e) =>
                    handleLabelChange("Node", e, selectedNodeId || "")
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      inputRef?.current?.blur();
                    }
                  }}
                  textAreaRef={inputRef}
                  placeholder="Enter Label"
                />
  
                {/* Background Color */}
                <ColorSelector
                  label="Background Color"
                  options={bgColorOptions}
                  isSelected={(color) => color === selectedNode?.data?.bgColor}
                  onSelect={(color) => {
                    setNodes((nds : any) =>
                      nds?.map((nd : any) => {
                        if (nd?.id === selectedNodeId) {
                          nd.data = {
                            ...nd.data,
                            bgColor: color,
                          };
                        }
  
                        return nd;
                      })
                    );
                  }}
                />
              </div>
            ) : (
              <div className="flex justify-center items-center text-xs text-slate-400 min-h-[200px]">
                No Node Selected
              </div>
            )}
  
            {/* Edge Customization */}
            {selectedEdgeId ? (
              <div className="flex flex-col gap-1 p-3">
                <div className="text-sm font-semibold text-slate-500">
                  Connector
                </div>
                {/* Edge Label */}
                <ATMTextArea
                  label="Connector Label"
                  value={(selectedEdge?.label as string) || ""}
                  onChange={(e) =>
                    handleLabelChange("Edge", e, selectedEdgeId || "")
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      edgeLabelRef?.current?.blur();
                    }
                  }}
                  textAreaRef={edgeLabelRef}
                  placeholder="Enter Edge Label"
                />
  
                {/* Edge Color */}
                <ColorSelector
                  label="Connector Color"
                  options={strokeColorOptions}
                  isSelected={(color) => color === selectedEdge?.style?.stroke}
                  onSelect={(color) => {
                    setEdges((eds:any) =>
                      eds?.map((ed: any) => {
                        if (ed?.id === selectedEdgeId) {
                          return {
                            ...ed,
                            style: {
                              ...ed.style,
                              stroke: color,
                            },
                            markerEnd: {
                              ...ed.markerEnd,
                              color: color,
                            },
                          };
                        } else {
                          return ed;
                        }
                      })
                    );
                  }}
                  isEdgeColors
                />
  
                {/* Animate Connector */}
                <div
                  onClick={() => {
                    setEdges((eds : any) =>
                      eds?.map((ed: Edge) => {
                        if (ed?.id === selectedEdgeId) {
                          return {
                            ...ed,
                            animated: !ed?.animated,
                          };
                        } else {
                          return ed;
                        }
                      })
                    );
                  }}
                  className="flex items-center gap-2 pt-4 cursor-pointer"
                >
                  <div
                    className={`h-[20px] aspect-square border border-slate-500 rounded transition-all flex justify-center items-center cursor-pointer ${
                      selectedEdge?.animated ? "bg-primary-light text-white" : ""
                    } `}
                  >
                    {selectedEdge?.animated && (
                      <IconCheck size={14} strokeWidth={4} />
                    )}
                  </div>
  
                  <div> Animated </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center text-xs text-slate-400 min-h-[200px]">
                No Connector Selected
              </div>
            )}
          </div>
        </> 
        <div className="flex justify-end mt-5 items-end mx-4">
        <ATMLoadingButton className=" bg-primary-dark cursor-pointer" onClick={()=>{onSubmit(nodes , edges)}}>
            Save
        </ATMLoadingButton>

      </div>
      </div>
    );
  };
  
  export default MOLFlowToolbar;
  
  type ColorSelectorProps = {
    label: string;
    isSelected: (color: string) => boolean;
    onSelect: (color: string) => void;
    options: string[];
    isEdgeColors?: boolean;
  };
  
  const ColorSelector = ({
    isSelected,
    onSelect,
    label,
    options,
    isEdgeColors = false,
  }: ColorSelectorProps) => {
    return (
      <div>
        <RenderLabel label={label} />
        <div className="flex gap-3 py-1 ">
          {options?.map((color , index) => {
            const isColorSelected = isSelected(color);
            return (
              <button 
                key={index}
                type="button"
                onClick={() => onSelect(color)}
                className={`h-[20px] w-[20px] rounded hover:scale-105 ${
                  !isEdgeColors && color
                }  ${
                  isColorSelected && "shadow scale-105 ring-2 ring-slate-400"
                } `}
                style={
                  isEdgeColors
                    ? {
                        backgroundColor: color,
                      }
                    : undefined
                }
              ></button>
            );
          })}
        </div>
      </div>
    );
  };
  
  const RenderLabel = ({ label }: { label: string }) => {
    return <label className="text-xs font-medium text-slate-700">{label}</label>;
  };
  
  type ATMTextAreaProps = {
    textAreaRef: any;
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    label: string;
    placeholder: string;
  };
  
  const ATMTextArea = ({
    textAreaRef,
    value,
    onChange,
    onKeyDown,
    label,
    placeholder,
  }: ATMTextAreaProps) => {
    const handleTextareaResize = (e: any) => {
      e.currentTarget.style.height = "40px";
      e.currentTarget.style.height = `${e?.currentTarget.scrollHeight}px`;
    };
  
    return (
      <div className="">
        <RenderLabel label={label} />
        <textarea
          ref={textAreaRef}
          onInput={handleTextareaResize}
          value={value}
          onChange={onChange}
          className="w-full border border-gray-300 rounded h-[48px] focus:outline-primary-main p-4 hide-scroll text-sm"
          placeholder={placeholder}
          onKeyDown={onKeyDown}
        />
      </div>
    );
  };