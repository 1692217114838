import { ErrorMessage } from "formik";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { ClipLoader } from "react-spinners";
import useFileUploader from "src/utils/useFileUploader";
import { twMerge } from "tailwind-merge";
import ATMRenderFile from "../../atoms/ATMRenderFile/ATMRenderFile";

type Props = {
  onChange: any;
  name: string;
  label?: string;
  required?: boolean;
  value: any;
  onCancelClick?: () => void;
  isShowCancel?: boolean;
  accept?: string;
  placeholder?: string;
  labelClasses?: string;
  isShowPreview?: boolean;
  onPreviewClick?: () => void;
  showValue?: boolean
};

const MOLFileUpload = ({
  onChange,
  name,
  label,
  required,
  value,
  onCancelClick,
  isShowCancel,
  accept = ".jpg, .jpeg, .png,  .mp4, .xls, .xlsx, .pdf, .webp, .webm",
  placeholder = "Select Image",
  labelClasses,
  isShowPreview,
  onPreviewClick,
  showValue = true
}: Props) => {
  const { initiateUpload, uploadedFile } = useFileUploader({
    next: (file: any) => {
      let data = {
        url: file?.fileUrl,
        type: file?.fileType,
        name: file?.fileTitle,
      };
      return onChange(data);
    },
    accept: accept,
  });
  return (
    <>
      <div className="flex flex-col">
        {label && (
          <label
            className={twMerge("text-slate-700 font-medium", labelClasses)}
          >
            {" "}
            {label} {required && <span className="text-red-500"> * </span>}{" "}
          </label>
        )}
        <button
          className={`text-primary-main flex gap-1 font-bold items-center border rounded p-2 ${label && "mt-1"
            }`}
          type="button"
          onClick={initiateUpload}
          disabled={uploadedFile ? true : false}
        >
          {uploadedFile ? (
            <ClipLoader
              color={"teal"}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <HiOutlinePlusSmall size={20} />
          )}
          {placeholder}
        </button>
        {name && (
          <ErrorMessage name={name}>
            {(errMsg) => (
              <p className="font-poppins text-[14px] text-start mt-0 text-red-500">
                {errMsg}
              </p>
            )}
          </ErrorMessage>
        )}
        {showValue &&
          <div>
            {value ? (
              <div className="gap-4 pb-10">
                <div className="h-[200px]">
                  <ATMRenderFile
                    fileInfo={value}
                    isShowCancel={isShowCancel}
                    onCancelClick={onCancelClick}
                    isShowPreview={isShowPreview}
                    onPreviewClick={onPreviewClick}
                  />
                </div>
              </div>
            ) : null}
          </div>}
      </div>
    </>
  );
};

export default MOLFileUpload;
