import { FieldArray, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { TbTrash } from "react-icons/tb";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { EstimationCalculatorFormValues } from "src/models/EstimationCalculator.model";
import { CLIENT_GRW } from "src/utils/constants";
import {
  handleValidForHour,
  handleValidNumber,
} from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<EstimationCalculatorFormValues>;
};

const EstimationCalculatorForm = ({ formikProps }: Props) => {
  const [meetingModeEnable, setMeetingModeEnable] = useState<boolean>(false);
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();
  useEffect(() => {
    // get the meetingmode is enable or not
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem(
      "isMeetingModeEnable"
    ) as string;

    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";

    setMeetingModeEnable(isEnabled);
  }, []);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.shiftKey && e.key === "Enter") {
        e.preventDefault();
        formikProps.setFieldValue("items", [
          ...values.items,
          { developer: null, duration: "", hours: "" },
        ]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [formikProps, values.items]);

  useEffect(() => {
    values.items.forEach((item: any, index: any) => {
      if (!item.developer) {
        setFieldValue(`items[${index}].duration`, "");
        setFieldValue(`items[${index}].hours`, "");
      }
    });
  }, [values.items, setFieldValue]);

  const totalCtc = values.items?.reduce((acc: any, el: any) => {
    const total = el?.developer?.salaryPerHour
      ? el?.developer?.salaryPerHour * el?.duration * el?.hoursPerDay
      : 0;
    return acc + total;
  }, 0);

  const totalGctc = values.items?.reduce((acc: any, el: any) => {
    const total = el?.developer?.growthSalaryPerHour
      ? el?.developer?.growthSalaryPerHour * el?.duration * el?.hoursPerDay
      : 0;
    return acc + total;
  }, 0);
  const user = codioticUsers?.map((el: any) => {
    const { _id, name, ...rest } = el;
    return { developerId: _id, developerName: name, ...rest };
  });
  return (
    <div className="flex flex-col gap-5 p-2">
      <div className="w-full md:w-[300px]">
        <ATMTextField
          required
          name="title"
          value={values.title}
          onChange={(e) => setFieldValue("title", e.target.value)}
          label="Title"
          placeholder="Enter name"
          onBlur={handleBlur}
        />
      </div>

      <div className="grid grid-cols-1 gap-5 p-1 text-sm font-semibold rounded-sm md:grid-cols-7 bg-sky-100">
        <div className="col-span-2 ">Select Developer</div>
        <div className="col-span-1 ">Duration In Days</div>
        <div className="col-span-1 ">Hours Per Day</div>
        {meetingModeEnable ? null : (
          <div className="col-span-1 w-[100px] text-center">CTC</div>
        )}
        {CLIENT_GRW === true ? (
          <div className="col-span-1 w-[200px] text-center">GCTC</div>
        ) : null}
      </div>

      <FieldArray name="items">
        {({ insert, remove, push }) => (
          <div className="flex flex-col gap-5">
            {values.items.length > 0 &&
              values.items.map((item: any, index: number) => (
                <div
                  key={index}
                  className="grid items-end grid-cols-1 gap-5 mt-2 md:grid-cols-7"
                >
                  <div className="col-span-2">
                    <ATMSelect
                      required
                      name={`items[${index}].developer`}
                      value={values?.items[index]?.developer}
                      onChange={(newValue: any) =>
                        setFieldValue(`items[${index}].developer`, newValue)
                      }
                      onBlur={handleBlur}
                      placeholder="Select Developer"
                      options={user}
                      isLoading={isCodioticUserDataLoading}
                      renderOption={(option, { context }: any) => (
                        <div className="capitalize">{option.developerName}</div>
                      )}
                      isOptionEqualToValue={(option, selected) =>
                        selected?.findIndex(
                          (selectedValue: any) =>
                            selectedValue?.developerId === option.developerId
                        ) > -1
                      }
                      isSearchedOption={(option, searchValue) =>
                        searchValue
                          ? option?.data?.developerName
                              ?.toLowerCase()
                              ?.includes(searchValue)
                          : true
                      }
                      autoFocus={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <ATMTextField
                      required
                      name={`items.${index}.duration`}
                      value={values.items[index].duration}
                      onChange={(e) =>
                        handleValidNumber(e) &&
                        setFieldValue(`items.${index}.duration`, e.target.value)
                      }
                      placeholder="Enter days"
                      onBlur={handleBlur}
                      disabled={!values?.items[index].developer}
                    />
                  </div>
                  <div className="col-span-1">
                    <ATMTextField
                      required
                      name={`items.${index}.hoursPerDay`}
                      value={values.items[index].hoursPerDay}
                      onChange={(e) =>
                        handleValidForHour(e) &&
                        setFieldValue(
                          `items.${index}.hoursPerDay`,
                          e.target.value
                        )
                      }
                      placeholder="Enter hours "
                      onBlur={handleBlur}
                      disabled={!values?.items[index].developer}
                    />
                  </div>

                  {meetingModeEnable ? null : (
                    <div className="col-span-1 w-[100px] text-center ">
                      {values.items[index].developer &&
                      values?.items[index]?.duration &&
                      values?.items[index]?.hoursPerDay ? (
                        <span>
                          &#8377;
                          {values?.items[index]?.developer?.salaryPerHour *
                            values?.items[index]?.duration *
                            values?.items[index]?.hoursPerDay}
                        </span>
                      ) : (
                        "--"
                      )}
                    </div>
                  )}

                  {CLIENT_GRW === true ? (
                    <div className="text-center col-span-1 w-[200px]">
                      {values.items[index].developer &&
                      values?.items[index]?.duration &&
                      values?.items[index]?.hoursPerDay ? (
                        <span>
                          &#8377;
                          {values?.items[index]?.developer
                            ?.growthSalaryPerHour *
                            values?.items[index]?.duration *
                            values?.items[index]?.hoursPerDay}
                        </span>
                      ) : (
                        "--"
                      )}
                    </div>
                  ) : null}

                  <div>
                    <ATMLoadingButton
                      className="bg-transparent text-red-500 border-0 font-bold w-[20px] h-[38px]"
                      onClick={() => remove(index)}
                    >
                      <TbTrash size={20} />
                    </ATMLoadingButton>
                  </div>
                </div>
              ))}

            <div className="">
              <ATMLoadingButton
                className="bg-transparent bg-gray-100 border-2 border-dashed text-slate-950"
                onClick={() =>
                  push({ developer: null, duration: "", hoursPerDay: "" })
                }
              >
                Add Developer
              </ATMLoadingButton>
            </div>

            <div className="grid grid-cols-1 gap-5 p-1 text-sm font-semibold rounded-sm md:grid-cols-7 bg-sky-100">
              <div className="col-span-2 text-center">
                <div>Total Developers</div>
                <div>
                  {values.items?.filter((el: any) => el?.developer).length}
                </div>
              </div>
              <div className="col-span-1 "></div>
              <div className="col-span-1 "></div>
              {meetingModeEnable ? null : (
                <div className="col-span-1 w-[100px] text-center ">
                  <div>Total CTC</div>
                  <div className="">&#8377;{totalCtc}</div>
                </div>
              )}
              {CLIENT_GRW === true ? (
                <div className="text-center col-span-2 w-[200px]">
                  <div>Total GCTC</div>
                  <div>&#8377;{totalGctc}</div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </FieldArray>

      <div className="flex justify-end">
        <div className="w-[200px]">
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default EstimationCalculatorForm;
