import { FormikProps } from "formik";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import useCampaignGroup from "src/hooks/useCampaignGroup";
import useChannel from "src/hooks/useChannels";
import { CampaignFormValues } from "src/models/Campaign.model";

type Props = {
  formikProps: FormikProps<CampaignFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const CampaignForm = ({
  formikProps,
  formType,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const { CampaignGroup, isDataLoading: isCampaignGroupLoading } =
    useCampaignGroup();
  const { Channel, isDataLoading: isChannelDataLoading } = useChannel();
  const campaignGroupOptions = CampaignGroup?.map((campaign) => {
    return {
      label: campaign?.campaignGroupName,
      value: campaign?._id,
    };
  });
  const channelOptions = Channel?.map((channel) => {
    return {
      label: channel?.channelName,
      value: channel?._id,
    };
  });

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Campaign" : "Update Campaign"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMSelect
            name="campaignGroupName"
            value={values?.campaignGroupName}
            onChange={(value) => setFieldValue("campaignGroupName", value)}
            placeholder="Select Campaign Group Name"
            options={campaignGroupOptions}
            isLoading={isCampaignGroupLoading}
            label="Campaign Group Name"
            required
          />
        </div>
        <div>
          <ATMMultiSelect
            name="channelName"
            value={values?.channelName}
            onChange={(value: any) => setFieldValue("channelName", value)}
            placeholder="Select Channel  Name"
            options={channelOptions}
            isLoading={isChannelDataLoading}
            label="Channel  Name"
            required
          />
        </div>
        {/* Campaign Group Name */}
        <div>
          <ATMTextField
            name="campaignName"
            value={values.campaignName}
            required
            onChange={(e) => setFieldValue("campaignName", e.target.value)}
            label="Campaign Name"
            placeholder="Enter Campaign Name"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default CampaignForm;
