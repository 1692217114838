import React from "react";

// |-- External Dependencies --|
import CountryListingWrapper from "./Country/List/CountryListingWrapper";
import StateListingWrapper from "./State/List/StateListingWrapper";
import CityListingWrapper from "./City/List/CityListingWrapper";

const Locations = () => {
  return (
    <>
      <div className="w-full h-full flex gap-4  p-3 overflow-auto">
        {/* Country */}
        <div className="h-full ">
          <CountryListingWrapper />
        </div>

        {/* State */}
        <div className="h-full ">
          <StateListingWrapper />
        </div>

        {/* City */}
        <div className="h-full">
          <CityListingWrapper />
        </div>
      </div>
    </>
  );
};

export default Locations;
