import React from "react";
import {
  HiChevronDoubleDown,
  HiChevronDoubleUp,
  HiChevronUp,
} from "react-icons/hi";
import { TfiLineDouble } from "react-icons/tfi";

export enum ENMPriority {
  "LOW" = "Low",
  "MEDIUM" = "Medium",
  "HIGH" = "High",
  "URGENT" = "URGENT",
}

export type TYPPriority = keyof typeof ENMPriority;

type Props = {
  priority: TYPPriority;
};

const getClassNameByPriroty = (priority: TYPPriority) => {
  switch (priority) {
    case "LOW":
      return "text-green-600"; // Green for low priority

    case "MEDIUM":
      return "text-yellow-600"; // Blue for medium priority

    case "HIGH":
      return "text-red-600"; // Red for high priority

    case "URGENT":
      return "text-orange-600"; // A slightly darker red for urgent priority
  }
};

const getIconsByPriroty = (priority: TYPPriority) => {
  switch (priority) {
    case "LOW":
      return <HiChevronDoubleDown size={18} />;

    case "MEDIUM":
      return <TfiLineDouble size={18} />;

    case "HIGH":
      return <HiChevronUp size={18} />;

    case "URGENT":
      return <HiChevronDoubleUp size={18} />;
  }
};

const MOLPriorityChip = ({ priority }: Props) => {
  const className = ` 
  font-medium
  rounded-full
  py-1
  px-2
  text-xs
  flex 
  items-center
  gap-2
    ${getClassNameByPriroty(priority)}`;

  return (
    <div className={className}>
      {getIconsByPriroty(priority)} {ENMPriority[priority]}{" "}
    </div>
  );
};

export default MOLPriorityChip;
