import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { FaUser } from "react-icons/fa";
import { FaTicketSimple } from "react-icons/fa6";
import { RiTimerFill } from "react-icons/ri";
import { RxLapTimer } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { setSingleItemInfo } from "src/redux/slices/ChangeRequestSlice";
import { RootState } from "src/redux/store";
import { useGetChangeRequestByIdQuery } from "src/services/ChangeRequestServices";
import AccessDenied from "src/utils/AccessDenied";
import { isAuthorized } from "src/utils/authorization";

type Props = {};

export const inquiriesEditTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
  {
    label: "TICKET DETAILS",
    icon: FaUser,
    path: "ticket-details",
    moduleName:
      UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS,
  },
  {
    label: "TIME LOGS",
    icon: RxLapTimer,
    path: "time-log",
    moduleName: UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TIME_LOGS,
  },
  {
    label: "TICKET SUMMARY",
    icon: FaTicketSimple,
    path: "time-summary",
    moduleName:
      UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_SUMMARY,
  },
  {
    label: "Change Request Logs",
    icon: RxLapTimer,
    path: "logs",
    moduleName:
      UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_CHANGE_REQUEST_LOGS,
  },
  {
    label: "Time Quotation",
    icon: RiTimerFill,
    path: "time-quotation",
    moduleName:
      UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_CHANGE_REQUEST_LOGS,
  },
];

const ChangeRequestViewTabsLayout = (props: Props) => {
  const dispatch = useDispatch();
  const [meetingModeEnable, setMeetingModeEnable] = useState<boolean>(false);

  const { singleItemInfo: changeRequestItems } = useSelector(
    (state: RootState) => state.changeRequest
  );

  const location = useLocation();

  const id = location.pathname.split("/")[2];
  const {
    isLoading: isChangeRequestLoading,
    isFetching: isChangeRequestFetching,
    data: changeRequestData,
  } = useGetChangeRequestByIdQuery(id || "", {
    skip: !id,
  });

  useEffect(() => {
    if (!isChangeRequestLoading && !isChangeRequestFetching) {
      dispatch(setSingleItemInfo(changeRequestData?.data || []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeRequestLoading, isChangeRequestFetching, changeRequestData]);

  const navigate = useNavigate();

  const currentPath = location.pathname.split("/")[3];
  const { userData } = useGetLocalStorage();

  React.useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("open");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of inquiriesEditTabs) {
      const isValue = isAuthorized(
        nav?.moduleName as keyof typeof UserModuleNameTypes
      );
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav.path);
        break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get Meeting mode is on or off
  useEffect(() => {
    // get the meetingmode is enable or not
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem(
      "isMeetingModeEnable"
    ) as string;

    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";
    setMeetingModeEnable(isEnabled);
  }, []);

  const tabsRender = inquiriesEditTabs?.some((nav) => {
    return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
  });

  return (
    <>
      <SideNavLayout>
        {tabsRender ? (
          <div className="flex flex-col gap-2 pt-3 h-full">
            <div className="text-blue-500 font-semibold underline px-2 m-2">
              {changeRequestItems?.requestName}
            </div>
            {inquiriesEditTabs?.length && (
              <div className="flex gap-3 items-center  mx-4  border-b border-slate-400 bg-white">
                {inquiriesEditTabs
                  ?.filter((nav) => {
                    return isAuthorized(
                      nav?.moduleName as keyof typeof UserModuleNameTypes
                    );
                  })
                  ?.filter((meetingMode) => {
                    if (meetingModeEnable) {
                      return (
                        meetingMode?.moduleName !==
                          UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_CHANGE_REQUEST_LOGS &&
                        meetingMode?.moduleName !==
                          UserModuleNameTypes.ACTION_CHANGE_REQUEST_SINGLE_VIEW_TIME_QUOTATION
                      );
                    }
                    return meetingMode;
                  })
                  ?.map((tab: any, index: any) => {
                    return (
                      <div key={index}>
                        <button
                          type="button"
                          onClick={() => navigate(tab.path)}
                          className={`h-full px-5 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${
          currentPath === tab.path?.split("/")[0]
            ? "text-primary-main   border-primary-main"
            : "text-gray-700 border-white"
        }
         `}
                        >
                          <div className=" text-lg  ">
                            <tab.icon />
                          </div>
                          {tab.label}
                        </button>
                      </div>
                    );
                  })}
              </div>
            )}
            <div className="grow overflow-auto ">
              <Outlet />
            </div>
          </div>
        ) : (
          <AccessDenied />
        )}
      </SideNavLayout>
    </>
  );
};

export default ChangeRequestViewTabsLayout;
