import React, { useEffect } from "react";
import ScreenAndFeaturesLayout from "../ScreenAndFeatureLayout";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setSelectedPlatformData } from "src/redux/slices/RequirementGatheringNewSlice";

const ScreenAndFeatureWrapper = () => {
  const { platformId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // Simulate selecting a platform (replace with actual logic)
    const selectedPlatform = {
      _id: platformId,
    };

    // Dispatch action to set selected platform data
    dispatch(setSelectedPlatformData(selectedPlatform));
  }, [dispatch, platformId]);

  return (
    <div>
      <ScreenAndFeaturesLayout />
    </div>
  );
};

export default ScreenAndFeatureWrapper;
