import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddOneDayLeaveRequestFormWrapper from "src/screens/LeaveRequest/Add/AddOneDayLeaveRequestFormWrapper";
import AddMoreThanOneDayLeaveRequestFormWrapper from "src/screens/LeaveRequest/Add/AddMoreThanOneDayLeaveRequestFormWrapper";
import AddHalfDayLeaveRequestFormWrapper from "src/screens/LeaveRequest/Add/AddHalfDayLeaveRequestFormWrapper";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showOneDayRequest, setShowOneDayRequest] = React.useState(false);
  const [showMoreThanOneDayRequest, setShowMoreThanOneDayRequest] =
    React.useState(false);
  const [showHalfDayRequest, setShowHalfDayRequest] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowOneDayRequest(false);
    setShowMoreThanOneDayRequest(false);
    setShowHalfDayRequest(false);
  };

  const handleFullDayClick = () => {
    setShowOneDayRequest(true);
    setAnchorEl(null);
  };

  const handleHalfDayClick = () => {
    setShowHalfDayRequest(true);
    setAnchorEl(null);
  };

  const handleMoreThanOneDayClick = () => {
    setShowMoreThanOneDayRequest(true);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<>{/* // <KeyboardArrowDownIcon /> */}</>}
        style={{
          backgroundColor: "#0c4349",
        }}
      >
        <span className="text-[10px] md:text-[16px]"> Leave Request</span>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleHalfDayClick} disableRipple>
          {/* {ICON} */}
          Half Day
        </MenuItem>
        <MenuItem onClick={handleFullDayClick} disableRipple>
          {/* {ICON} */}
          Full Day
        </MenuItem>
        <MenuItem onClick={handleMoreThanOneDayClick} disableRipple>
          {/* {ICON} */}
          More than 1 day
        </MenuItem>
      </StyledMenu>
      {showOneDayRequest && (
        <AddOneDayLeaveRequestFormWrapper handleClose={handleClose} />
      )}
      {showMoreThanOneDayRequest && (
        <AddMoreThanOneDayLeaveRequestFormWrapper handleClose={handleClose} />
      )}

      {showHalfDayRequest && (
        <AddHalfDayLeaveRequestFormWrapper handleClose={handleClose} />
      )}
    </div>
  );
}
