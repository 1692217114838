import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { TicketGroupFormValues } from "../Add/AddTicketGroupFormWrapper";

type Props = {
    formikProps: FormikProps<TicketGroupFormValues>;
    onClose: () => void;
    formType: "ADD" | "EDIT";
    setSaveNextChecked?: any;
    saveNextChecked?: any;
};


const EditTicketGroupForm = ({
    formType = "ADD",
    formikProps,
    onClose,
    setSaveNextChecked,
    saveNextChecked,
}: Props) => {
    const formHeading = "Update Ticket Group";
    const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

    return (
        <div className="flex flex-col gap-6 p-3">
            <div className="flex items-center justify-between">
                <div className="text-xl font-medium"> {formHeading} </div>
                <button
                    type="button"
                    className="p-2 rounded hover:bg-hover"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </div>
            <div className="flex flex-col gap-4">
                {/* Account Name */}
                <div>
                    <ATMTextField
                        name="groupName"
                        required
                        value={values.groupName}
                        onChange={(e) => setFieldValue("groupName", e.target.value)}
                        label="Group Name"
                        placeholder="Enter Group name"
                        onBlur={handleBlur}
                    />
                </div>

                {formType === "ADD" && (
                    <div className="">
                        <ATMCheckbox
                            label="Save and Next"
                            checked={saveNextChecked}
                            inputClasses="h-[15px]"
                            labelClasses="text-sm select-none"
                            onChange={(checked) => {
                                setSaveNextChecked(checked);
                            }}
                        />
                    </div>
                )}

                <ATMLoadingButton isLoading={isSubmitting} type="submit">
                    Save
                </ATMLoadingButton>
            </div>
        </div>
    );
};

export default EditTicketGroupForm;
