import { FormikProps } from "formik";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import useDepartment from "src/hooks/useDepartment";
import { ServiceTicketFormValues } from "../Add/AddServiceTicketFormWrapper";

type Props = {
  formikProps: FormikProps<ServiceTicketFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const ServiceTicketForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
 
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const { department, isDataLoading } = useDepartment();

  const departmentOptions = department?.map((depart) => {
    return {
      label: depart?.departmentName,
      value: depart?._id,
    };
  });
  return (
    <MOLFormDialog
      title={
        formType === "ADD" ? "Add Service Ticket" : "Update Service Ticket"
      }
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Organisation Name */}
        <div>
          <ATMTextField
            required
            name="problemName"
            value={values.problemName}
            onChange={(e) => setFieldValue("problemName", e.target.value)}
            label="Problem"
            placeholder="Enter Problem"
            onBlur={handleBlur}
          />
        </div>

        <div>
          <ATMSelect
            required
            name="departmentId"
            label="Department"
            placeholder="Select Department"
            value={values.departmentId}
            isLoading={isDataLoading}
            options={departmentOptions}
            onChange={(newValue) => {
              setFieldValue("departmentId", newValue);
            }}
          />
        </div>
        {/* {formType === "ADD" && (
          <div>
            <ATMCheckbox
              label="Save and Next"
              checked={saveNextChecked}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        )}
        <div>
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div> */}
      </div>
    </MOLFormDialog>
  );
};

export default ServiceTicketForm;
