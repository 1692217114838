import { FormikProps } from "formik";
import moment from "moment";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { PaymentFormProps } from "src/models/Expense.model";
import { useGetFinanceAccountQuery } from "src/services/Finance-New-Services/AccountServices";
import { useGetPaymentModesQuery } from "src/services/Finance-New-Services/PaymentModeServices";
type Props = {
    formikProps: FormikProps<PaymentFormProps>;
    onClose: () => void;
};

const PayExpenseForm = ({ formikProps, onClose }: Props) => {
    const { data: getAccount, isLoading: isAccountLoading } =
        useGetFinanceAccountQuery("");
    const { data: getpaymentMode, isLoading: isPaymentModeLoading } =
        useGetPaymentModesQuery("");
    const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

    return (
        <div className="flex flex-col gap-6 p-4">
            <div className="text-xl font-medium"> Pay Expense </div>

            <div className="flex flex-col gap-2">
                <div>
                    <ATMDatePicker
                        required
                        label="Payment Date"
                        name="paymentDate"
                        format="DD/MM/YYYY"
                        maxDate={moment().format("yyyy-MM-DD")}
                        value={values.paymentDate}
                        onChange={(newValue) => setFieldValue("paymentDate", newValue)}
                    />
                </div>
                {/* Account */}
                <div>
                    <ATMSelect
                        name="account"
                        label="Account"
                        required
                        placeholder="Select Account"
                        value={values.account}
                        options={getAccount?.data?.map((ele: any) => {
                            return {
                                label: ele?.accountName,
                                value: ele?._id,
                            };
                        })}
                        onChange={(newValue) => {
                            setFieldValue("account", newValue);
                        }}
                        isLoading={isAccountLoading}
                    />
                </div>
                {/* Mode */}
                <div>
                    <ATMSelect
                        name="mode"
                        label="Mode"
                        required
                        placeholder="Select Mode"
                        value={values.mode}
                        options={getpaymentMode?.data?.map((ele: any) => {
                            return {
                                label: ele?.modeName,
                                value: ele?._id,
                            };
                        })}
                        onChange={(newValue) => {
                            setFieldValue("mode", newValue);
                        }}
                        isLoading={isPaymentModeLoading}
                    />
                </div>
                <div>
                    <ATMTextField
                        name="remark"
                        value={values.remark}
                        onChange={(e) => setFieldValue("remark", e.target.value)}
                        label="Remark"
                        placeholder="Enter Remark"
                        onBlur={handleBlur}
                    />
                </div>
            </div>
            <div className="flex justify-end gap-2">
                <div className="w-[100px]">
                    <ATMLoadingButton
                        onClick={() => onClose()}
                        type="button"
                        className="text-black bg-white"
                    >
                        Cancel
                    </ATMLoadingButton>
                </div>
                <div className="w-[100px]">
                    <ATMLoadingButton isLoading={isSubmitting} type="submit">
                        Save
                    </ATMLoadingButton>
                </div>
            </div>
        </div>
    );
};

export default PayExpenseForm;