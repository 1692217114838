import React, { useMemo, useState, useEffect } from "react";
import { Divider } from "@mui/material";
import moment from "moment";
import { BiCheck, BiChevronDown } from "react-icons/bi";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";

import {
  getEstimationInDays,
  getTimeInHours,
} from "src/utils/constants/ticketUtils";
export const HTMLFormatRegex = /^\s*<.*>\s*$/;

// import { getDataWithRightFormat } from "src/screens/Tickets/View/TicketTabs/TicketDetails/TicketDetails";

type Props = {
  getAllTicketData: any;
  changeRequestDetails: any;
};

export const getDataWithRightFormat = (data: string) => {
  if (data) {
    if (HTMLFormatRegex.test(data)) {
      return <div dangerouslySetInnerHTML={{ __html: data }} />;
    } else {
      return <ATMMarkdownContainer markdown={data} />;
    }
  } else {
    return "-";
  }
};

const TicketSummaryInfo = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  const getValue = useMemo(() => getDataWithRightFormat(value), [value]);
  return (
    <div className="flex flex-col gap-1 ">
      <div className="text-base text-neutral">{label}</div>
      {value ? (
        <div className="p-3 rounded-md bg-surface-light">{getValue}</div>
      ) : (
        "-"
      )}
    </div>
  );
};

const TicketSummary = React.forwardRef(
  ({ getAllTicketData, changeRequestDetails }: Props) => {
    //get meetings time, man hour time, and developer
    const getMeetingTime = (changeRequestDetails: any) => {
      // eslint-disable-next-line array-callback-return
      let totalMeetingTime = 0;
      let totalManTime = 0;

      return changeRequestDetails?.meetings?.reduce(
        (acc: any, meetingData: any) => {
          totalMeetingTime += Number(meetingData?.meetingTimeInMins || 0);
          totalManTime += Number(meetingData?.totalMainHrs || 0);

          return {
            ...acc,
            meetingTime: totalMeetingTime,
            manTime: totalManTime,
          };
        },
        {}
      );
    };

    //get analysis time, man hour time, and developer
    const getAnalysisMeetingTime = (changeRequestDetails: any) => {
      // eslint-disable-next-line array-callback-return
      let totalMeetingTime = 0;
      let totalManTime = 0;

      return changeRequestDetails?.analysisMeetings?.reduce(
        (acc: any, meetingData: any) => {
          totalMeetingTime += Number(meetingData?.meetingTimeInMins || 0);
          totalManTime += Number(meetingData?.totalMainHrs || 0);

          return {
            ...acc,
            meetingTime: totalMeetingTime,
            manTime: totalManTime,
          };
        },
        {}
      );
    };
    const meetingData = useMemo(
      () => getMeetingTime(changeRequestDetails),
      [changeRequestDetails]
    );
    const analysisMeetingTimeData = useMemo(
      () => getAnalysisMeetingTime(changeRequestDetails),
      [changeRequestDetails]
    );
    // const [approveETA, { isLoading: isApproving }] = useChangeRequestApproveEtaRequestMutation();

    const Accordian = ({ ticketData }: { ticketData: any }) => {
      const [isOpen, setIsOpen] = useState(false);
      const getDescription = useMemo(
        () => getDataWithRightFormat(ticketData?.description),
        [ticketData?.description]
      );

      return (
        <div className="border rounded-md shadow-sm ">
          <div
            onClick={() => setIsOpen((prev: any) => !prev)}
            className="flex items-start justify-between py-1 cursor-pointer"
          >
            <div className="flex items-start gap-4 py-2 capitalize">
              <div
                className={`p-2 rounded-full  transition-all duration-300   ${
                  isOpen && "rotate-180"
                } `}
              >
                <BiChevronDown size={24} />
              </div>
              <div className="">
                {ticketData?.title}

                <div className=" text-[13px] pt- font-medium text-slate-500">
                  <span className="">Estimated Time - </span>
                  <span className="">
                    {" "}
                    {getTimeInHours(ticketData?.estTime || 0)}
                  </span>
                </div>
                <div className=" text-[13px] font-medium text-slate-500">
                  <span className="">No. of Developers - </span>
                  <span className=""> {ticketData?.numberOfMembers || 0}</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-end p-2">
              <span
                className="text-xs text-slate-600 text-end "
                onClick={(e) => {
                  window.open(`/ticket/${ticketData?._id}/details`, "_blank");
                  e.stopPropagation();
                }}
              >
                #{ticketData?.ticketNumber}
              </span>
              {ticketData?.status === "APPROVAL_DONE" ? (
                <div className="flex items-center justify-end gap-2 pt-2 ">
                  <div className="bg-green-100 text-green-600 rounded-full p-0.5 font-semibold border border-green-600 text-base">
                    <BiCheck size={16} />
                  </div>
                  <span className="text-base"> Approved </span>
                </div>
              ) : null}
            </div>
          </div>

          {isOpen ? (
            <>
              <Divider />

              <div className="flex flex-col gap-4 p-4">
                {getDescription}

                <div className="flex flex-col gap-4 divide-y divide-dashed">
                  <TicketSummaryInfo
                    label="Problem Statement"
                    value={ticketData?.problemStatement}
                  />
                  <TicketSummaryInfo
                    label="Proposed Solution"
                    value={ticketData?.problemSolution}
                  />
                  <TicketSummaryInfo label="Flow" value={ticketData?.flow} />
                  <TicketSummaryInfo
                    label="Technical Approach"
                    value={ticketData?.technicalApproach}
                  />

                  <div className="flex flex-col gap-1 pt-2 ">
                    <div className="text-base text-neutral">Affected Areas</div>
                    {ticketData?.affectedAreas?.length ? (
                      <div className="p-3 rounded-md bg-surface-light">
                        {ticketData?.affectedAreas?.map((el: any, ind: any) => {
                          return (
                            <div key={ind}>
                              <span className="text-sm font-semibold">
                                {" "}
                                {`( ${el?.projectLabel || "-"} )`}
                              </span>{" "}
                              {el?.featureLabel || "-"}{" "}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      );
    };

    const [meetingModeEnable, setMeetingModeEnable] = useState<boolean>(false);

    // Get Meeting mode is on or off
    useEffect(() => {
      // get the meetingmode is enable or not
      // Retrieve the stored string value from localStorage
      const storedValue: string = localStorage.getItem(
        "isMeetingModeEnable"
      ) as string;

      // Convert the stored string value into a boolean
      const isEnabled: boolean = storedValue === "true";
      setMeetingModeEnable(isEnabled);
    }, []);

    return (
      <div className="h-screen p-4">
        <div className="grid h-full grid-cols-12 gap-4 p-2">
          {/* Tickets */}
          <div className="flex flex-col flex-1 col-span-8">
            <div className="flex justify-between items-center  py-4 shadow-[0_10px_35px_-25px_rgba(0,0,0,0.3)]">
              <div className="flex items-center gap-4 p-2 rounded-full ">
                {/* <button
                  type="button"
                  onClick={() => navigate("/change-request")}
                  className="p-2 transition-all rounded-full hover:bg-gray-100"
                >
                  <IoArrowBack size={24} />
                </button> */}

                <div className="ml-5 text-xl font-semibold capitalize text-slate-600">
                  {" "}
                  {changeRequestDetails?.requestName}
                  <div className="text-sm font-semibold text-slate-500">
                    {" "}
                    {moment(changeRequestDetails?.createdAt).format(
                      "DD MMM yyyy"
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
            <Divider />

            <div className="flex flex-col flex-1 gap-4 py-4 overflow-auto">
              {getAllTicketData?.map((ticketData: any) => {
                return <Accordian ticketData={ticketData} />;
              })}
            </div>
          </div>

          {/* Change request Summary */}
          <div className="h-full col-span-4 py-4">
            <div className="flex flex-col gap-4 p-4 border rounded-md border-sky-200 bg-sky-50 ">
              <div className="text-xl font-medium text-sky-600"> Overview </div>

              <Divider className="bg-sky-200" />
              {/* Meeting and call  */}
              <div className="">
                <div className="font-semibold text-slate-700">
                  Meetings and calls
                </div>
                <div className="pt-3 text-base text-neutral">
                  <div className="flex justify-between">
                    <div>No. of meetings </div>
                    <div className="font-semibold text-slate-600">
                      {changeRequestDetails?.meetings?.length}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Total Spent Time </div>
                    <div className="font-semibold text-slate-600">
                      {getTimeInHours(meetingData?.meetingTime || 0)}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Total man hours spent</div>
                    <div className="font-semibold text-slate-600">
                      {getTimeInHours(meetingData?.manTime || 0)}
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="bg-sky-200" />

              {/* Analysis Meeting */}
              <div className="">
                <div className="font-semibold text-slate-700">Analysis</div>
                <div className="pt-3 text-base text-neutral">
                  {/* <div className="flex justify-between">
                    <div>No. of meetings </div>
                    <div className="font-semibold text-slate-600">
                      {changeRequestDetails?.analysisMeetings?.length}
                    </div>
                  </div> */}
                  <div className="flex justify-between">
                    <div>Analysis Time (Man Hours)</div>
                    <div className="font-semibold text-slate-600">
                      {getTimeInHours(analysisMeetingTimeData?.manTime || 0)}
                    </div>
                  </div>
                  {/* <div className="flex justify-between">
                    <div>Total Spent Time </div>
                    <div className="font-semibold text-slate-600">
                      {analysisMeetingTimeData?.meetingTime || 0} min
                    </div>
                  </div> */}
                </div>
              </div>
              <Divider className="bg-sky-200" />
              {/* Implementation and Deployment */}
              <div className="">
                <div className="font-semibold text-slate-700">
                  Implementation & Deployment
                </div>
                <div className="text-[14px] text-slate-500 font-semibold pt-3">
                  Development & QA
                </div>
                <div className="py-2 text-base text-neutral">
                  <div className="flex justify-between">
                    <div>Required Team Size </div>
                    <div className="font-semibold text-slate-600">
                      {changeRequestDetails?.numberOfDeveloper}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Time Required</div>
                    <div className="font-semibold text-slate-600">
                      {getTimeInHours(changeRequestDetails?.devAndQaTime || 0)}
                    </div>
                  </div>
                </div>
                <Divider className="bg-sky-100" />
                <div className="text-[14px] text-slate-500 font-semibold pt-3">
                  Deployment
                </div>
                <div className="pt-2 text-base text-neutral">
                  <div className="flex justify-between">
                    <div>Required Team Size </div>
                    <div className="font-semibold text-slate-600">
                      {changeRequestDetails?.deploymentTime?.requiredMembers ||
                        0}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Time Required</div>
                    <div className="font-semibold text-slate-600">
                      {getTimeInHours(
                        changeRequestDetails?.deploymentTime?.timeSpent || 0
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="bg-sky-200" />
              {/* Demo and Training */}
              <div className="">
                <div className="font-semibold text-slate-700">
                  Demo & Training
                </div>
                <div className="pt-3 text-base text-neutral">
                  <div className="flex justify-between">
                    <div>Team Size </div>
                    <div className="font-semibold text-slate-600">
                      {changeRequestDetails?.demoTime?.requiredMembers || 0}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Duration of meeting</div>
                    <div className="font-semibold text-slate-600">
                      {getTimeInHours(
                        changeRequestDetails?.demoTime?.timeSpent || 0
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Total man hours</div>
                    <div className="font-semibold text-slate-600">
                      {getTimeInHours(
                        Number(changeRequestDetails?.demoTime?.timeSpent || 0) *
                          Number(
                            changeRequestDetails?.demoTime?.requiredMembers || 0
                          )
                      )}{" "}
                    </div>
                  </div>
                </div>
              </div>

              <Divider className="bg-sky-200" />
              <div>
                <div className="font-semibold text-slate-700">Total</div>
              </div>

              <div className="flex justify-between">
                <div className="text-base text-neutral"> Estimated Time </div>

                <div className="text-sm font-semibold text-slate-600">
                  {getEstimationInDays({
                    time:
                      Number(changeRequestDetails?.devAndQaTime || 0) +
                      Number(
                        changeRequestDetails?.deploymentTime?.timeSpent || 0
                      ) +
                      Number(changeRequestDetails?.demoTime?.timeSpent || 0),
                    workingHours:
                      changeRequestDetails?.projectPerDayWorkingHour || 0,
                  })}
                </div>
              </div>

              <div className="flex justify-between">
                <div className="text-base text-neutral">
                  {" "}
                  Estimated Cost{" "}
                  <span className="text-xs">(Excluding Taxes)</span>{" "}
                </div>

                {!meetingModeEnable && (
                  <div className="flex items-center text-sm font-semibold text-slate-600">
                    <div>&#8377; {changeRequestDetails?.costEst} </div>
                  </div>
                )}
              </div>

              <div className="flex justify-between">
                <div className="text-base text-neutral">
                  {" "}
                  Estimated Delivery Date{" "}
                </div>

                <div className="text-sm font-semibold text-slate-600">
                  {changeRequestDetails?.estDeliveryDate
                    ? moment(changeRequestDetails?.estDeliveryDate).format(
                        "DD MMM YYYY"
                      )
                    : "--"}
                </div>
              </div>
            </div>

            {changeRequestDetails?.isEtaApproved && (
              <div className="flex items-center gap-4 p-4 mt-2 border border-green-600 rounded-md bg-green-50 ">
                <div className="text-white bg-green-600 rounded-full p-0.5 font-semibold text-base flex justify-center items-center ">
                  <BiCheck size={24} />
                </div>

                <div className="flex-1 text-base">
                  {" "}
                  Approved by{" "}
                  <span className="italic font-semibold capitalize">
                    {" "}
                    {changeRequestDetails?.approvedByName}{" "}
                  </span>{" "}
                  on{" "}
                  <span className="italic font-semibold ">
                    {" "}
                    {changeRequestDetails?.approvedOnDate}{" "}
                  </span>{" "}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default TicketSummary;
