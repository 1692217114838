import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { LeadLabelFormValues } from "src/models/LeadLabel.model";
import {
  useGetSingleLeadLabelQuery,
  useUpdateLeadLabelMutation,
} from "src/services/LeadLabelService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import SocialMediaForm from "../Layouts/SocialMediaForm";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  selectedSocialMediaId: any;
};

const EditLeadLabelFormWrapper = ({
  onClose,
  selectedSocialMediaId,
}: Props) => {
  const [socialMediaData, setSocialMediaData] = useState<any>({});
  const { isLoading, isFetching, data } = useGetSingleLeadLabelQuery(
    selectedSocialMediaId,
    {
      skip: !selectedSocialMediaId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setSocialMediaData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const [updateLeadLabel] = useUpdateLeadLabelMutation();

  // Form Initial Values
  const initialValues: LeadLabelFormValues = {
    leadLabelName: socialMediaData?.leadLabelName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    leadLabelName: string().required("Please enter lead label name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeadLabelFormValues,
    { setSubmitting, resetForm }: FormikHelpers<LeadLabelFormValues>
  ) => {
    updateLeadLabel({ id: selectedSocialMediaId, body: values }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <SocialMediaForm
            formikProps={formikProps}
            onClose={onClose}
            formType="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditLeadLabelFormWrapper;
