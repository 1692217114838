// |-- Built-in Dependencies --|
import React from "react";

// |-- External Dependencies --|
import { useSelector } from "react-redux";
import { object, string } from "yup";
import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";

// |-- Internal Dependencies --|
import StateForm from "../Layout/StateForm";
import { useAddStateMutation } from "src/services/StateServices";
import { showToast } from "src/utils/showToaster";

// |-- Redux --|
import { RootState } from "src/redux/store";
import { isMobile } from "react-device-detect";

// |-- Types --|
type Props = {
  onClose: () => void;
};

export type FormInitialValues = {
  stateName: string;
};

const AddStateFormWrapper = ({ onClose }: Props) => {
  const [AddState] = useAddStateMutation();
  const { selectedLocationCountries }: any = useSelector(
    (state: RootState) => state?.country
  );

  const initialValues: FormInitialValues = {
    stateName: "",
  };

  const validationSchema = object({
    stateName: string().required("state name is required"),
  });

  const onSubmitHandler = (values: FormInitialValues) => {
    setTimeout(() => {
      AddState({
        stateName: values.stateName,
        countryId: selectedLocationCountries?.value || "",
      }).then((res: any) => {
        if ("data" in res) {
          if (res?.data?.status) {
            showToast("success", "added successfully!");
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        } else {
          showToast("error", "Something went wrong");
        }
      });
    }, 1000);
  };

  return (
    <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(formikProps) => {
          return <StateForm onClose={onClose} formikProps={formikProps} />;
        }}
      </Formik>
    </Dialog>
  );
};

export default AddStateFormWrapper;
