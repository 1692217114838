/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import {
  setDateFilter,
  setCategoryId,
  setMemberId,
} from "src/redux/slices/IncidentSlice";
import IncidentDateFilterDialog from "./IncidentDateFilterDialog";
type Props = {
  onClose: () => void;
  open:boolean
};

const IncidentDateFilterDialogWrapper = ({ onClose , open }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const meetingState = useSelector((state: RootState) => state.incident);
  const { dateFilter, categoryId, memberId } = meetingState;

  const initialValues: any = {
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
    category: {
      label: categoryId?.label,
      value: categoryId?.value,
    },
    memberId:{
      label: memberId?.label,
      value: memberId?.value,
    },
  };

  // Submit Handler
  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    setSubmitting(false);

    // created date from to dispatch
    dispatch(
      setDateFilter({
        startDate: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        endDate: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
        dateFilterKey: "expenseDate",
      })
    );
    dispatch(setCategoryId(values?.category));
    dispatch(setMemberId(values?.memberId));

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<any>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        startDate: "",
        endDate: "",
      })
    );

    await dispatch(setCategoryId(""));
    await dispatch(setMemberId(''));
    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <IncidentDateFilterDialog 
          open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default IncidentDateFilterDialogWrapper;
