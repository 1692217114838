import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { MeetingFormValues } from "src/models/Meeting.model";
import { useAddMeetingMutation } from "src/services/MeetingServices";
import { array, object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import { useState } from "react";
import MeetingForm from "../Layout/MeetingForm";
import { useNavigate } from "react-router-dom";
import moment from "moment";

type Props = {};

const AddMeetingFormWrapper = (props: Props) => {
  const navigate = useNavigate();

  const [addMeeting] = useAddMeetingMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  // Form Initial Values
  const initialValues: MeetingFormValues = {
    title: "",
    meetingTypeId: "",
    projectId: "",
    mode: "",
    scheduledDate: moment(),
    scheduledStartTime: moment(),
    scheduledEndTime: moment().add(30, 'minutes'),
    clientUsers: [],
    codioticUsers: [],
    meetingWith: "",
    address: "",
    meetingLink: "",
    requiredThings: [],
    agenda: [],
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Title is required"),
    projectId: object().required("Project is required"),
    mode: object().required("Mode is required"),
    scheduledDate: object().required("Scheduled Date is required"),
    scheduledStartTime: string().required("Scheduled start time is required"),
    scheduledEndTime: string().required("Scheduled end time is required"),
    // clientUsers: object().required("Client user is required"),
    // codioticUsers: object().required("Codiotic user is required"),
    address: string().test(
      "link-test",
      "Meeting address is required",
      (value, context) => {
        const { mode } = context.parent;
        return mode?.value === "OFFLINE" ? (value ? true : false) : true;
      }
    ),
    meetingLink: string().test(
      "link-test",
      "Meeting link is required",
      (value, context) => {
        const { mode } = context.parent;
        return mode?.value === "ONLINE" ? (value ? true : false) : true;
      }
    ),
    meetingWith: object().required("Meeting with is required"),
    // address: string().required("address is required"),
    // meetingLink: string().required("link is required"),
    meetingTypeId: object().required("Meeting type is required"),
    requiredThings: array()
      .required("Required things is required")
      .min(1, "At least one required thing is required"),
    // agenda: string().required("agenda is required"),
  });

  // Handle Submit
  const handleSubmit = (
    values: MeetingFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingFormValues>
  ) => {
    const {
      projectId,
      mode,
      codioticUsers,
      scheduledStartTime,
      scheduledEndTime,
      meetingWith,
      meetingTypeId,
      requiredThings,
      scheduledDate,
      agenda,
      ...rest
    } = values;

    const formatedValues = {
      projectId: projectId?.value,
      mode: mode?.value,
      codioticUsers: codioticUsers?.map((ele) => ele?.value),
      scheduledStartTime: moment(scheduledStartTime).format(
        "HH:mm:ss"
      ),
      scheduledDate: moment(scheduledDate).format("YYYY-MM-DD"),
      scheduledEndTime: moment(scheduledEndTime).format("HH:mm:ss"),
      meetingWith: meetingWith?.value,
      meetingTypeId: meetingTypeId?.value,
      requiredThings: values.requiredThings,
      agenda: values.agenda,
      meetingCreateType: "SCHEDULED",
      ...rest,
    };

    addMeeting(formatedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          if (saveNextChecked) {
            showToast("success", res?.data?.message);
            navigate(`/meeting/${res?.data?.data?._id}/agenda`)
            // resetForm();
          } else {
            navigate("/meeting");
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <MeetingForm
            formType="Add"
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddMeetingFormWrapper;
