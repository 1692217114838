import Dialog from "@mui/material/Dialog";
import CreateETAModal from "../Layout/CreateETAModal";
// import { number, object, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import {
    useUpdateETATicketMutation
} from "src/services/TicketServices";
import { showToast } from "src/utils/showToaster";
import { array, number, object } from "yup";

type Props = {
  ticketId: string;
  open: boolean;
  handleClose: () => void;
  estInfo?: any;
};

export type FormInitialValues = {
  layerWiseEst: any;
  numberOfMembers: number;
};

const EditETAModalWrapper = ({
  ticketId,
  open = false,
  handleClose,
  estInfo,
}: Props) => {
  // user options


  const [updateETAForTicket] = useUpdateETATicketMutation();

  // Form Initial Values
  const initialValues: FormInitialValues = {
    layerWiseEst: estInfo?.layerWiseEst?.map((layerName: any) => {
      return {
        layerName: layerName?.layerName,
        estTime: layerName?.estTime,
      };
    }),
    numberOfMembers: estInfo?.noOfDeveloper,
  };

  // Validation Schema
  const validationSchema = object().shape({
    layerWiseEst: array().of(
      object().shape({
        estTime: number()
          .typeError("Estimate time must be a number")
          .positive("Estimate time must be a positive number")
          .integer("Estimate time must be an integer")
          .required("Estimate time is required"),
      })
    ),
    numberOfMembers: number().required("Number of members is required"),
  });

  const handleSubmit = (
    values: FormInitialValues,
    { setSubmitting, resetForm }: FormikHelpers<FormInitialValues>
  ) => {
    // Convert estTime values to numbers
    const valuesWithNumberEstTime = {
      ...values,
      layerWiseEst: values.layerWiseEst?.map((layer: any) => ({
        ...layer,
        estTime: Number(layer.estTime), // Convert estTime to number
      })),
    };

    updateETAForTicket({
      ticketId: ticketId,
      body: valuesWithNumberEstTime,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <CreateETAModal
              formikProps={formikProps}
              handleClose={handleClose}
              formType="EDIT"
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditETAModalWrapper;
