import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import ScheduledRoundForm from "./ScheduledRoundForm";
import { useScheduleInterviewMutation } from "src/services/JobApplicationService";

type Props = {
  applicationId: string;
  stageId: string;
};
type ScheduledRoundFormValue = {
  scheduledDate: any;
  scheduledStartTime: any;
  interviewerId: any;
};
const ScheduledRoundFormWrapper = ({ applicationId, stageId }: Props) => {
  const [scheduleRound,scheduleRoundInfo] = useScheduleInterviewMutation();

  const initialValues: ScheduledRoundFormValue = {
    scheduledDate: moment(),
    scheduledStartTime: moment(),
    interviewerId: "",
  };
  const validationSchema = object({
    scheduledDate: object().required("Scheduled Date is required"),
    scheduledStartTime: string().required("Scheduled start time is required"),
    interviewerId: object().required("Responsible member is required"),
  });
  const handleSubmit = (
    values: ScheduledRoundFormValue,
    { setSubmitting }: FormikHelpers<ScheduledRoundFormValue>
  ) => {
    const { scheduledDate, scheduledStartTime, interviewerId } = values;

    const formatedValues = {
      stageId: stageId,
      jobApplicationId: applicationId,
      interviewDateTime: `${moment(scheduledDate).format("YYYY-MM-DD")} ${moment(scheduledStartTime).format("HH:mm:ss")}`,
      interviewerId: interviewerId?.value,
    };
    scheduleRound({ body: formatedValues }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ScheduledRoundForm formikProps={formikProps} isLoading={scheduleRoundInfo?.isLoading} />
        </Form>
      )}
    </Formik>
  );
};

export default ScheduledRoundFormWrapper;
