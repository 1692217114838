import React, { useState, useEffect } from "react";
import { CircularProgress, Divider } from "@mui/material";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { IoDuplicate } from "react-icons/io5";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  rows: any[];
  handleDeleteRequirement: any;
  onAddNew: () => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const RequirementList = ({
  rows,
  handleDeleteRequirement,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();

  const [collapsedStates, setCollapsedStates] = useState<boolean[]>([]);

  useEffect(() => {
    if (rows) {
      setCollapsedStates(Array(rows.length).fill(true)); // Set all items to 'false' initially
    }
  }, [rows]);

  const handleToggleCollapse = (index: number) => {
    setCollapsedStates((prevStates) => {
      const newCollapsedStates = prevStates.map(
        (state, i) => (i === index ? !state : true) // Toggle the clicked item, close others
      );
      return newCollapsedStates;
    });
  };

  return (
    <>
      <SideNavLayout>
        <div className="flex flex-col h-full">
          {/* Page Header */}
          <div className="">
            <ATMPageHeader
              pageTitle="Requirement"
              searchValue={searchValue}
              onSearchChange={setSearchValue}
              hideAddButton={
                !isAuthorized(UserModuleNameTypes.ACTION_FEATURES_ADD)
              }
              buttonProps={{
                btnName: "Add New",
                onClick: () => onAddNew(),
              }}
            />
          </div>

          <div className="h-[100vh] overflow-y-scroll overflow-x-hidden xs:px-0 md:px-0 bg-gray-50">
            {isTableLoading ? (
              <div className="flex justify-center items-center h-[400px]">
                <CircularProgress />{" "}
              </div>
            ) : rows?.length ? (
              rows?.map((row: any, index: any) => {
                return isTableLoading ? (
                  Array(10)
                    .fill(0)
                    .map((_, index) => {
                      return (
                        <div key={index} className="animate-pulse h-[50px] p-2">
                          <div className="h-full rounded"></div>
                        </div>
                      );
                    })
                ) : (
                  <div key={index} className="px-2 pt-4 md:px-4 last:pb-4">
                    <div className="rounded-md border max-w-[100vw] bg-white">
                      <div
                        onClick={() => handleToggleCollapse(index)}
                        className={`flex justify-between items-center p-4 py-2 cursor-pointer ${
                          collapsedStates[index] ? "" : "border-b bg-slate-100"
                        }`}
                      >
                        <div className="flex items-center gap-4">
                          <button className="flex items-center p-2 border rounded-full">
                            {collapsedStates[index] ? (
                              <FaAngleDown className="" />
                            ) : (
                              <FaAngleUp className="" />
                            )}
                          </button>
                          <div className="flex flex-col">
                            <p className="text-xs text-slate-600">
                              {formatedDateTimeIntoIst(
                                row?.createdAt,
                                "DD MMM yyyy"
                              )}
                            </p>
                            <p className="text-sm">
                              <span className="font-medium">
                                {row?.projectName}{" "}
                              </span>{" "}
                              {row?.moduleName ? `(${row?.moduleName})` : null}
                            </p>
                          </div>
                        </div>
                        <ATMActionMenu
                          options={[
                            {
                              label: (
                                <div className="flex items-center gap-x-2">
                                  <MdEdit color="#000000" /> Edit
                                </div>
                              ),
                              onClick: () => {
                                navigate(`/requirement/${row?._id}`);
                              },
                              acitonName:
                                UserModuleNameTypes.ACTION_FEATURES_EDIT,
                            },
                            {
                              label: (
                                <div className="flex items-center gap-x-2">
                                  <IoDuplicate color="#000000" /> Duplicate
                                </div>
                              ),
                              onClick: () => {
                                navigate(`/requirement/duplicate/${row?._id}`);
                              },
                              acitonName:
                                UserModuleNameTypes.ACTION_FEATURES_DUPLICATE,
                            },
                            {
                              label: (
                                <div className="flex items-center gap-x-2">
                                  <MdDelete color="#000000" /> Delete
                                </div>
                              ),
                              onClick: () => {
                                handleDeleteRequirement(row?._id);
                              },
                              acitonName:
                                UserModuleNameTypes.ACTION_FEATURES_DELETE,
                            },
                          ]}
                        />
                      </div>

                      {collapsedStates[index] ? null : (
                        <div className="flex flex-col gap-4 p-4">
                          <div className="flex gap-6">
                            <div className="flex flex-col gap-1">
                              <p className="text-xs">Feature Name</p>
                              <p className="text-base font-semibold">
                                {row.featureName}{" "}
                              </p>
                            </div>
                            <div className="flex flex-col gap-1">
                              <p className="text-xs">Feature Type</p>
                              <p className="text-base font-semibold">
                                {row?.featureType}
                              </p>
                            </div>
                          </div>

                          <div>
                            {row?.formFields?.length ? (
                              <div className="flex flex-col gap-1">
                                {/* <p className="text-xs">Form</p> */}
                                <div className="w-full overflow-x-auto">
                                  <table className="w-full overflow-scroll border border-collapse border-gray-300 rounded-md">
                                    <thead>
                                      <tr>
                                        <th className="p-2 border border-gray-300">
                                          Name
                                        </th>
                                        <th className="p-2 border border-gray-300">
                                          Type
                                        </th>
                                        <th className="p-2 border border-gray-300">
                                          Validation
                                        </th>
                                        <th className="p-2 border border-gray-300">
                                          Logic
                                        </th>
                                        <th className="p-2 border border-gray-300">
                                          Data Source
                                        </th>
                                        <th className="p-2 border border-gray-300">
                                          Is Required
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {row?.formFields?.map(
                                        (
                                          formfield: any,
                                          formfieldIndex: any
                                        ) => (
                                          <tr key={formfieldIndex}>
                                            <td className="p-2 text-center border border-gray-300 text-wrap">
                                              {formfield?.name || "-"}
                                            </td>
                                            <td className="p-2 text-center capitalize border border-gray-300 text-wrap">
                                              {formfield?.type?.toLowerCase() ||
                                                "-"}
                                            </td>
                                            <td className="p-2 text-center border border-gray-300 text-wrap">
                                              {formfield?.validation || "-"}
                                            </td>
                                            <td className="p-2 text-center border border-gray-300 text-wrap">
                                              {formfield?.logic || "-"}
                                            </td>
                                            <td className="p-2 text-center border border-gray-300 text-wrap">
                                              {formfield?.dataSource || "-"}
                                            </td>
                                            <td className="p-2 text-center border border-gray-300 text-wrap">
                                              {formfield?.isRequired === true
                                                ? "True"
                                                : "False" || "-"}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : null}

                            {row?.table?.length ||
                            row?.tableFields?.length ||
                            row?.columns?.length ? (
                              <div className="flex flex-col gap-2">
                                <div className="text-xs">
                                  Table - <span>{row?.tableName}</span>
                                </div>

                                <div className="flex gap-4">
                                  {row?.table?.length ? (
                                    <div className="flex flex-col flex-1 gap-2 p-2 rounded-md bg-slate-50">
                                      <p className="text-sm font-medium">
                                        Table Action :
                                      </p>
                                      <div className="flex flex-wrap gap-2">
                                        {row?.table?.map(
                                          (
                                            table: any,
                                            tableActionIndex: number
                                          ) => (
                                            <p
                                              key={tableActionIndex}
                                              className="flex p-1 px-2 text-sm bg-white border rounded-md text-wrap"
                                              style={{
                                                width: "fit-content",
                                              }}
                                            >
                                              {table?.tableAction}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : null}

                                  {row?.tableFields?.length ? (
                                    <div className="flex flex-col flex-1 gap-2 p-2 rounded-md bg-slate-50">
                                      <p className="text-sm font-medium">
                                        Table Action :
                                      </p>
                                      <div className="flex flex-wrap gap-2">
                                        {row?.tableFields?.map(
                                          (
                                            tableField: any,
                                            tableFieldIndex: number
                                          ) => (
                                            <p
                                              key={tableFieldIndex}
                                              className="flex p-1 px-2 text-sm bg-white border rounded-md text-wrap"
                                              style={{
                                                width: "fit-content",
                                              }}
                                            >
                                              {tableField.columnName}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : null}

                                  {row?.columns?.length ? (
                                    <div className="flex flex-col flex-1 gap-2 p-2 rounded-md bg-slate-50">
                                      <p className="text-sm font-medium">
                                        Columns Actions :
                                      </p>
                                      <div className="flex flex-wrap gap-2">
                                        {row?.columns?.map(
                                          (
                                            columns: any,
                                            columnsIndex: number
                                          ) => (
                                            <p
                                              key={columnsIndex}
                                              className="flex p-1 px-2 text-sm bg-white border rounded-md text-wrap"
                                              style={{
                                                width: "fit-content",
                                              }}
                                            >
                                              {columns?.columnActions}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <Divider />

                          <div className="flex flex-col gap-6 ">
                            <div className="flex flex-col gap-1">
                              <div className="text-sm font-bold">
                                Requirement Logic
                              </div>
                              <div className="p-3 rounded bg-slate-200">
                                <ATMMarkdownContainer
                                  markdown={row?.requirementLogic || "-"}
                                />
                              </div>
                            </div>

                            <div className="flex flex-col gap-1">
                              <div className="text-sm font-bold">
                                Requirement Flow
                              </div>
                              <div className="p-3 rounded bg-slate-200">
                                <ATMMarkdownContainer
                                  markdown={row?.requirementFlow || "-"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-sm">
                Data not Found
              </div>
            )}
          </div>

          <div className="p-4 py-2 border-t">
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
        </div>
      </SideNavLayout>
    </>
  );
};

export default RequirementList;
