import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ATMLoadingButton from "../../atoms/ATMLoadingButton/ATMLoadingButton";
import { useUpdateTaskStatusMutation } from "src/services/TaskService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { setTaskNotificationData } from "src/redux/slices/AuthSlice";
import { useDispatch } from "react-redux";

type Props = {
  taskNotificationList: any;
};

const TaskNotificationCard = ({ taskNotificationList }: Props) => {

  const dispatch = useDispatch();
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const navigate = useNavigate();
  const handleCloseTask = (id: any, assigneeId: any) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to Hold this Task ?",
      icon: "question",
      showCancelButton: true,
      next: (result:any) => {
        if (result?.isConfirmed) {
          const formattedValues = {
            assignedTo: assigneeId,
            requestedLabel: "HOLD",
          };
          // if (TaskId && AssigneeId) {
          updateTaskStatus({ id: id, body: formattedValues }).then(
            (res: any) => {
              if (res?.error) {
                showToast("error", res?.error?.data?.message);
              } else {
                if (res?.data?.status) {
                  showToast("success", res?.data?.message);
                } else {
                  showToast("error", res?.data?.message);
                }
              }
            }
          );
          // }
        }
      },
    });
  };

  useEffect(() => {
    if (taskNotificationList?.data?.inProgressTasks?.length) {
      dispatch(setTaskNotificationData(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskNotificationList]);

  return (
    <>
        <div className="absolute top-[50px] right-[70px] w-[350px] max-h-[400px] overflow-auto shadow-xl rounded  z-50">
          <div className="flex flex-col gap-3 bg-white border w-full">
            <div className="border-b mt-3">
              <div className="flex items-center px-2 h-[40px] sticky top-0 bg-white">
                <div className="text-yellow-500 rounded-xl px-2 py-[1px] font-bold underline">
                  In Progress Task
                </div>
              </div>
              {taskNotificationList?.data?.inProgressTasks &&
                taskNotificationList?.data?.inProgressTasks.length > 0 ? (
                taskNotificationList?.data?.inProgressTasks?.map(
                  (option: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={`flex flex-col px-2 m-1 p-2 text-slate-500  hover:bg-slate-100 ${index !== taskNotificationList?.data?.inProgressTasks.length - 1 && "border-b"} border-slate-100 rounded-md`}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="flex text-[13px] text-slate-400">
                          <div>Task no. : {option?.taskNumber}</div>
                        </div>
                        <div className="w-full flex flex-col px-2">
                          <div className="flex justify-between items-center">
                            <div
                              className="flex-grow line-clamp-3 capitalize"
                              onClick={() =>
                                navigate(`/task/${option?._id}`)
                              }
                            >
                              {option?.title}.
                            </div>
                            <div>
                              <ATMLoadingButton
                                onClick={() => {
                                  handleCloseTask(
                                    option?._id,
                                    option?.assignedTo
                                  );
                                }}
                              >
                                Hold
                              </ATMLoadingButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="font-semibold p-2 text-center">
                  No tasks in progress
                </div>
              )}
            </div>
            <div className="border-b">
              <div className="flex items-center px-2 h-[40px] sticky top-0 bg-white">
                <div className="text-[#7f3eb4] rounded-xl px-2 py-[1px] font-bold underline">
                  Hold Task
                </div>
              </div>
              {taskNotificationList?.data?.holdTasks &&
                taskNotificationList.data.holdTasks.length > 0 ? (
                taskNotificationList.data.holdTasks.map(
                  (option: any, index: any) => (
                    <div
                      key={index}
                      className={`flex flex-col px-2 m-1 p-2 text-slate-500  hover:bg-slate-100 ${index !== taskNotificationList?.data?.holdTasks.length - 1 && "border-b"} border-slate-100 rounded-md`}
                      onClick={() => navigate(`/task/${option?._id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="flex text-[13px] text-slate-400">
                        <div>Task no. : {option?.taskNumber}</div>
                      </div>
                      <div className="capitalize">{option?.title}</div>
                    </div>
                  )
                )
              ) : (
                <div className="font-semibold p-2 text-center">
                  No tasks in progress
                </div>
              )}
            </div>
          </div>
        </div>
 
    </>
  );
};

export default TaskNotificationCard;
