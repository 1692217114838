import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { LeadsFollowupValues } from "src/models/LeadsFollowUp.model";
import LeadFollowupForm from "../Layout/LeadFollowupForm";
import { useAddLeadFollowupMutation } from "src/services/LeadsService";
import moment from "moment";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
  leadId: string;
};

const AddLeadsFollowUpFormWrapper = ({ onClose, leadId }: Props) => {
  const [addLeadFollowup] = useAddLeadFollowupMutation();
  // Form Initial Values
  const initialValues: LeadsFollowupValues = {
    is_communicated: "",
    next_followUp_required: "",
    quotation: "",
    communication_method: "",
    summary_note: "",
    next_followUp_date: "",
    next_followup_time: null,
    leads_label: "",
    leadId: leadId,
  };

  // Validation Schema
  const validationSchema = object().shape({
    is_communicated: object().required("Please select  is communicated"),
    // communication_method: object().required(
    //   "Please select communication method"
    // ),
    summary_note: string().test(
      "address-test",
      "summary note is required",
      (value, context) => {
        const { is_communicated } = context.parent;
        // const pervious = perviousMembershipPrice;
        if (is_communicated?.value === "YES" && !value) {
          return false;
        }
        return true;
      }
    ),
    next_followUp_required: object().test(
      "address-test",
      "Please select next followup is required",
      (value, context) => {
        const { is_communicated } = context.parent;
        // const pervious = perviousMembershipPrice;
        if (is_communicated?.value === "YES" && !value) {
          return false;
        }
        return true;
      }
    ),
    next_followUp_date: string()
      .nullable()
      .test(
        "address-test",
        "next followup date is required",
        (value, context) => {
          const { next_followUp_required } = context.parent;
          // const pervious = perviousMembershipPrice;
          if (next_followUp_required?.value === "YES" && !value) {
            return false;
          }
          return true;
        }
      ),
    next_followup_time: string()
      .nullable()
      .test(
        "address-test",
        "next followup time is required",
        (value, context) => {
          const { next_followUp_required } = context.parent;
          // const pervious = perviousMembershipPrice;
          if (next_followUp_required?.value === "YES" && !value) {
            return false;
          }
          return true;
        }
      ),
    leads_label: object().test(
      "address-test",
      "Please select leads label",
      (value, context) => {
        const { is_communicated } = context.parent;
        // const pervious = perviousMembershipPrice;
        if (is_communicated?.value === "YES" && !value) {
          return false;
        }
        return true;
      }
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeadsFollowupValues,
    { setSubmitting, resetForm }: FormikHelpers<LeadsFollowupValues>
  ) => {
    const formattedValues = {
      leadId: values?.leadId,
      summaryNote: values?.summary_note,
      quotation: values?.quotation,
      communicationMethod: values?.communication_method?.value || "",
      isCommunicated: values?.is_communicated?.value || "",
      nextFollowupRequired:
        values?.next_followUp_required?.value === "YES" ? true : false,

      nextFollowupDate: values?.next_followUp_date
        ? moment(values?.next_followUp_date).format("yyyy-MM-DD")
        : "",
      nextFollowupTime: values?.next_followup_time
        ? moment(values?.next_followup_time).format("hh:mm A")
        : "",
      leadLabelName: values?.leads_label?.value,
    };
    addLeadFollowup(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <LeadFollowupForm
            formikProps={formikProps}
            onClose={onClose}
            formType="ADD"
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddLeadsFollowUpFormWrapper;
