import React from "react";
import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { SkillFormValues } from "../Add/AddSkillFormWrapper";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<SkillFormValues>;
  onClose: () => void;
  formType: "ADD" | "UPDATE";
};

const SkillForm = ({ formikProps, onClose, formType }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Skill" : "Update Skill"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMTextField
            name="skillName"
            required
            value={values?.skillName}
            onChange={(e) => {
              setFieldValue("skillName", e.target.value);
            }}
            label="Skill Name"
            placeholder="Enter Skill Name"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default SkillForm;
