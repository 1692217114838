import React from "react";
import RequiredResource from "./RequiredResource";
import { Form, Formik, FormikHelpers } from "formik";
import { useAddRequiredRessourceMutation } from "src/services/TicketServices";
import { showToast } from "src/utils/showToaster";

type Props = {
  TicketId: any;
  onClose: any;
};
const RequiredResourceWrapper = ({ TicketId, onClose }: Props) => {
  const [addRequiredResource] = useAddRequiredRessourceMutation();

  // Form Initial Values
  const initialValues: any = {
    resourceRequired: [],
  };

  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    // Convert estTime values to numbers
    const valuesWithNumberEstTime = {
      resourceRequired: values?.resourceRequired?.map(
        (requiredResource: any) => {
          return { resource: requiredResource };
        }
      ),
    };

    addRequiredResource({
      ticketId: TicketId,
      body: valuesWithNumberEstTime,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <RequiredResource formikProps={formikProps} onClose={onClose} />
        </Form>
      )}
    </Formik>
  );
};

export default RequiredResourceWrapper;
