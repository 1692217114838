import React from "react";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { FormikProps } from "formik";
import { FormInitialValues } from "./DoubtFilterFormDialogWrapper";

// MODELS
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import { ProjectListResponse } from "src/models/Project.model";
import { ModulesListResponse } from "src/models/Module.model";
import { FeatureListResponseType } from "src/models/Feature.model";

// HOOKS
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { useGetAllModulesByProject } from "src/hooks/useGetAllModulesByProject";
import { useGetAllFeatureByModule } from "src/hooks/useGetAllFeatureByModule";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
  // doubtOptions : any
};

const DoubtFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
  // doubtOptions,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  // get all codiotic user hook
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  // get module by project id hook
  const { modules, isDataLoading: isModulesDataLoading } =
    useGetAllModulesByProject(values?.project?.value);

  // get feature by module id hook
  const { features, isDataLoading: isFeaturesDataLoading } =
    useGetAllFeatureByModule(values?.module?.value);

  return (
    <MOLFilterDialog
      title={"Filters"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      clearFilter={onReset}
      handleSubmit={handleSubmit}
      open={open}
    >
      <div className="flex flex-col gap-4 px-4 py-2">
        <div className="flex flex-col gap-4">
          {/* User */}
          <div>
            <ATMSelect
              name="codioticUser"
              label="User"
              placeholder="Select User"
              value={values.codioticUser}
              isLoading={isCodioticUserDataLoading}
              options={codioticUsers?.map((ele: CodioticUserListResponse) => {
                return {
                  label: ele?.name,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("codioticUser", newValue);
              }}
            />
          </div>

          {/* Project */}
          <div>
            <ATMSelect
              name="project"
              label="Project"
              placeholder="Select Project"
              value={values.project}
              isLoading={isAllProjectsDataLoading}
              options={allProjects?.map((ele: ProjectListResponse) => {
                return {
                  label: ele?.projectName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("project", newValue);
              }}
            />
          </div>

          {/* Modules by project id */}
          <div>
            <ATMSelect
              name="module"
              label="Module"
              placeholder="Select Module"
              value={values.module}
              isLoading={isModulesDataLoading}
              options={modules?.map((ele: ModulesListResponse) => {
                return {
                  label: ele?.moduleName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("module", newValue);
              }}
            />
          </div>

          {/* Feature by module id */}
          <div>
            <ATMSelect
              name="feature"
              label="Feature"
              placeholder="Select Feature"
              value={values.feature}
              isLoading={isFeaturesDataLoading}
              options={features?.map((ele: FeatureListResponseType) => {
                return {
                  label: ele?.featureName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("feature", newValue);
              }}
            />
          </div>
        </div>
      </div>
    </MOLFilterDialog>
  );
};

export default DoubtFilterFormDialog;
