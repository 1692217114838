import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useOnBoardedRecoredAddMutation } from "src/services/JobOnboardingService";
import { showToast } from "src/utils/showToaster";
import { object } from "yup";
import OnBoardStageForm from "../Layout/OnBoardStageForm";

export const transformObject = (obj: any[]) => {
  return Array.isArray(obj)
    ? obj.map((el: any) => {
      return {
        file: el?.file,
        isMandatory: el?.isMandatory,
        completeWithinDays: el?.completeWithinDays ? el?.completeWithinDays : 0,
        title: el?.title,
        description: el?.description,
        responsibleUserId: el?.responsibleUserId?._id,
        responsibleUserName: el?.responsibleUserId?.name,
      };
    })
    : [];
};

const transformObjectForPreFilled = (obj: any[]) => {
  return Array.isArray(obj)
    ? obj.map((el: any) => {
      return {
        file: el?.file,
        isMandatory: el?.isMandatory,
        completeWithinDays: el?.completeWithinDays ? el?.completeWithinDays : 0,
        title: el?.title,
        description: el?.description,
        responsibleUserId: {
          _id: el?.responsibleUserId,
          name: el?.responsibleUserName,
        },
      };
    })
    : [];
};

const EditOnBoardStageFormWrapper = ({ stageData }: any) => {
  const { applicationId } = useParams();

  const navigate = useNavigate();
  const [updateOnBoardStage] = useOnBoardedRecoredAddMutation();

  const initialValues: any = {
    stageName: stageData?.stageName,
    stageId: stageData?.stageId,
    stageOrder: stageData?.stageOrder,
    shareToCandidate:
      transformObjectForPreFilled(stageData?.shareToCandidate) || [],
    collectFromCandidate: stageData?.collectFromCandidate || [],
    forOfficial: stageData?.forOfficial || [],
    credentialsShare: stageData?.credentialsShare || [],
    otherRequirements: stageData?.otherRequirements || [],
  };

  const validationSchema = object().shape({
    // stageName: string().required("Please enter stage name"),
    // stageOrder: string().required("Please enter order"),
  });

  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    const formattedValues = {
      onboardingId: applicationId || "",
      stageName: values?.stageName,
      stageId: values?.stageId,
      stageOrder: values?.stageOrder,
      shareToCandidate: transformObject(values?.shareToCandidate) || [],
      collectFromCandidate: transformObject(values?.collectFromCandidate) || [],
      forOfficial: transformObject(values?.forOfficial) || [],
      credentialsShare: transformObject(values?.credentialsShare) || [],
      otherRequirements: transformObject(values?.otherRequirements) || [],
    };
    updateOnBoardStage({
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          // navigate("/job-onboard/on-board-stage");
          // resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <OnBoardStageForm
            formikProps={formikProps}
            formtype="EDIT"
            isLoading={false}
            onclose={() => navigate("/job-onboard/on-board-stage")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditOnBoardStageFormWrapper;
