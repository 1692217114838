import React, { useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { PiUserFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import ProjectTimeAnalyticsFilterWrapper from "../TaskTimeAnalyticsFilter/TaskTimeAnalyticsFilterWrapper";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import ColorComponent from "src/components/UI/colorComponent";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { Link, useParams } from "react-router-dom";
import { CLIENT_GRW } from "src/utils/constants";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

const TaskTimeLogAnalytics = () => {
  const { id: PROJECT_ID } = useParams();
  const { dateFilter } = useSelector(
    (state: RootState) => state.projectTimeAnalytics
  );
  const { userData } = useGetLocalStorage();
  const [isOpenFilterDialog, setIsOpenFilterDialog] = useState(false);
  const timeAnalytic = useSelector(
    (state: RootState) => state.projectTimeAnalytics
  );
  const { items, taskCTC, taskGCTC, projectName } = timeAnalytic;
  const [isMeetingMode, setIsMeetingMode] = useState<any>(false);

  useEffect(() => {
    // get the meetingmode is enable or not
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem(
      "isMeetingModeEnable"
    ) as string;

    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";

    setIsMeetingMode(isEnabled);
  }, [isMeetingMode]);
  return (
    <div>
      {/* Filter Button */}
      <div className="flex justify-end w-full gap-4 px-6">
        <Link
          to={{
            pathname: "/time-analytics",
            search: `?projectName=${projectName}&projectId=${PROJECT_ID}&startDate=${dateFilter.startDate ? dateFilter.startDate : ""}&endDate=${dateFilter.endDate ? dateFilter.endDate : ""}`,
          }}
          className={`rounded flex gap-4 p-2 md:p-4 hover:bg-teal-100 hover:border-teal-600 justify-center items-center border aspect-square md:aspect-auto bg-teal-50 ${getInputHeight(
            "small"
          )}`}
        >
          <p className="hidden text-neutral md:block">View</p>
        </Link>
        <ATMLoadingButton
          variant="tonal"
          onClick={() => setIsOpenFilterDialog(true)}
        >
          <div className="flex items-center gap-2">
            <IoFilter />
            Filters
          </div>
        </ATMLoadingButton>

        {/* Ticket Filter Dialog Wrapper */}
        <ProjectTimeAnalyticsFilterWrapper
          open={isOpenFilterDialog}
          onClose={() => setIsOpenFilterDialog(false)}
        />
      </div>

      <div className="flex flex-col gap-2 p-4 pt-0">
        <p className="text-[20px] font-medium"> Tasks Time : </p>
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          {items?.length
            ? items?.map((el: any, ind: any) => {
                return (
                  <div
                    key={ind}
                    className="flex flex-col items-center gap-4 p-4 bg-white border rounded-md"
                  >
                    <div className="flex flex-col items-center gap-2">
                      <div className="p-2 border rounded-full aspect-square">
                        <PiUserFill
                          size={16}
                          className="mx-auto rounded-full"
                        />
                      </div>
                      <div className="flex flex-col items-center">
                        <div className="text-lg font-medium">
                          <ColorComponent inputString={el?.codioticUserName} />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center gap-1 font-medium">
                      <span className="text-lg">
                        {`${Math?.floor(el?.durationInMinutes / 60)}:${(
                          el?.durationInMinutes % 60
                        )?.toFixed()}  hrs`}
                      </span>

                      <span className="text-sm text-neutral">
                        ({`${el?.durationInMinutes?.toFixed()}`} min)
                      </span>
                      {/* {`${el?.durationInMinutes?.toFixed()} 
                      min (
                        ${Math?.floor(el?.durationInMinutes / 60)}
                      :
                      ${(el?.durationInMinutes % 60)?.toFixed()}  hrs)`} */}
                    </div>
                    <div className="flex gap-4 text-center ">
                      <div className="flex flex-col">
                        <span className="text-sm font-semibold text-slate-500">
                          Task Time
                        </span>
                        <div className="flex items-center gap-1 font-medium">
                          <span className="text-sm">
                            {`${Math?.floor(el?.taskTimeInMinutes / 60)}:${(
                              el?.taskTimeInMinutes % 60
                            )?.toFixed()}  hrs`}
                          </span>

                          <span className="text-xs text-neutral">
                            ({`${el?.taskTimeInMinutes?.toFixed()}`} min)
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm font-semibold text-slate-500">
                          Issue Time
                        </span>
                        <div className="flex items-center gap-1 font-medium">
                          <span className="text-sm">
                            {`${Math?.floor(el?.issueTimeInMinutes / 60)}:${(
                              el?.issueTimeInMinutes % 60
                            )?.toFixed()}  hrs`}
                          </span>

                          <span className="text-xs text-neutral">
                            ({`${el?.issueTimeInMinutes?.toFixed()}`} min)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {!items?.length && (
        <div className="flex justify-center items-center h-80 text-[22px] text-red-700 font-semibold">
          Data not Found{" "}
        </div>
      )}
      {userData?.userType === "SUPER_ADMIN" && (
        <div>
          {items?.length ? (
            <div className="flex justify-end w-full gap-6 px-4">
              {!isMeetingMode && (
                <div>
                  <span className="text-sm font-semibold text-cyan-600">
                    CTC :{" "}
                  </span>
                  <span className="px-1">₹{taskCTC || 0} </span>
                </div>
              )}
              {CLIENT_GRW === true ? (
                <div>
                  <span className="text-sm font-semibold text-green-600">
                    {!isMeetingMode ? "GCTC" : "CTC"} :{" "}
                  </span>
                  <span className="px-1">₹{taskGCTC || 0} </span>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default TaskTimeLogAnalytics;
