import { FormikProps } from 'formik';
import React from 'react'
import ATMLoadingButton from 'src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton';
import ATMCheckbox from 'src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox';
import ATMSelect from 'src/components/UI/atoms/formFields/ATMSelect/ATMSelect';
import ATMTextField from 'src/components/UI/atoms/formFields/ATMTextField/ATMTextField';
import { FeatureAddFormValues } from 'src/models/Feature.model';

type Props = {
  formikProps: FormikProps<FeatureAddFormValues>;
  onClose: () => void;
  formType: "Add" | "Edit";
  setSaveNextChecked?: any
  singleModuleData?: any
  saveNextChecked?: any
  moduleOptions:any
};


const AddassignTicketFeatureForm = ({ formikProps,moduleOptions , singleModuleData, formType, onClose, setSaveNextChecked, saveNextChecked }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">
          {formType === "Add" ? "Add New" : "Update"} Feature
        </div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Project */}
        <div>
          <ATMSelect
            name="project"
            label="Project"
            placeholder="Select Project"
            value={values.project}
            disabled={singleModuleData}
            onChange={(newValue) => {
              setFieldValue("project", newValue);
            } } options={[]}          />
        </div>
        {/* Module */}
        <div>
          <ATMSelect
            name="moduleId"
            label="Module"
            placeholder="Select Module"
            value={values.moduleId}
            options={moduleOptions} 
            onChange={(newValue) => {
              setFieldValue("moduleId", newValue);
            }}
          />
        </div>

        {/* Feature Name */}
        <div>
          <ATMTextField
            name="featureName"
            value={values.featureName}
            onChange={(e) => setFieldValue("featureName", e.target.value)}
            label="Feature Name"
            placeholder="Enter Feature Name"
            onBlur={handleBlur}
          />
        </div>
      </div>
      {formType === "Add" && (
        <div>
          <ATMCheckbox
            label="Save and Next"
            checked={saveNextChecked}
            onChange={(checked) => {
              setSaveNextChecked(checked);
            }}
          />
        </div>
      )}
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
}

export default AddassignTicketFeatureForm
