import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetTicketGroupTimeAnylticsQuery } from "src/services/TicketGroupServices";
import TimeAnalyticsView from "./TimeAnalyticsView";

const TimeAnalyticsViewWrapper = () => {
  const { id: TICKET_GROUP_ID } = useParams();
  const [timeAnalyticData, setTimeAnalyticData] = useState<any>()
  const { isLoading, isFetching, data } = useGetTicketGroupTimeAnylticsQuery(TICKET_GROUP_ID, {
    skip: !TICKET_GROUP_ID
  });
  const [taskCTC, setTaskCTC] = useState<any>()
  const [taskGCTC, setTaskGCTC] = useState<any>()
  const [totalTicketTime, setTotalTicketTime] = useState<any>()

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTimeAnalyticData(data?.data)
      const ctcPayment = data?.data?.reduce(
        (acc: any, ele: any) => {
          return ele?.totalSalaryMinWise + acc;
        },
        0
      );

      const gctcPayemnt = data?.data?.reduce(
        (acc: any, ele: any) => {
          return ele?.totalGrowthSalaryMinWise + acc;
        },
        0
      );

      const totalTicketsTime = data?.data?.reduce(
        (acc: any, ele: any) => {
          return ele?.durationInMinutes + acc;
        },
        0
      );
      setTaskCTC(ctcPayment)
      setTaskGCTC(gctcPayemnt)
      setTotalTicketTime(totalTicketsTime)
    }
  }, [isLoading, isFetching, data]);
  
  if (isLoading || isFetching) {
    return <div className="flex justify-center items-center h-full">
      <CircularProgress />
    </div>
  }
  return <TimeAnalyticsView timeAnalyticData={timeAnalyticData} taskCTC={taskCTC} taskGCTC={taskGCTC} totalTicketTime={totalTicketTime}/>
};

export default TimeAnalyticsViewWrapper;
