import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { LeaveRequestFormValues } from "src/models/LeaveReques.model";
import {
  useGetLeaveRequestByIdQuery,
  useUpdateLeaveRequestMutation,
} from "src/services/LeaveRequestService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import OneDayLeaveRequestForm from "../Layouts/OneDayLeaveRequestForm";
import { getApprovalsInfo } from "../utils/getApprovalsInfo";
import { CircularProgress } from "@mui/material";

type Props = {
  handleClose: any;
  leaveRequestId: string;
};

const EditOneDayLeaveRequestFormWrapper = ({
  handleClose,
  leaveRequestId,
}: Props) => {
  // get  members
  const { codioticUsers, isDataLoading } = useGetAllCodioticUsers();
  const { data, isLoading, isFetching } =
    useGetLeaveRequestByIdQuery(leaveRequestId);
  const [updateLeaveRequest] = useUpdateLeaveRequestMutation();
  const [leaveInfo, setLeaveInfo] = useState<any>();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setLeaveInfo(data?.data);
    }
  }, [data, isLoading, isFetching]);

  const initialValues: LeaveRequestFormValues = {
    reason: leaveInfo?.reason,
    leaveStartDate: leaveInfo?.leaveStartDate,
    reportingDate: leaveInfo?.reportingDate,
    leaveType: {
      label: leaveInfo?.leaveType,
      value: leaveInfo?.leaveType,
    },
    daysOfLeave: leaveInfo?.daysOfLeave,
    dayPart: "",
    leavingTime: "",
    arrivalTime: "",
    approvals: getApprovalsInfo(leaveInfo),
    buddy: {
      label: leaveInfo?.buddyName,
      value: leaveInfo?.buddy,
    },
  };

  const validationSchema = object({
    leaveStartDate: string().required("Please select date"),
    buddy: object().required("Please select buddy"),
    reason: string().required("Please enter reason"),
    approvals: object().required("Please select approval"),
    leaveType: object().required("Please select type of leave"),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeaveRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<LeaveRequestFormValues>
  ) => {
    const {
      leaveType,
      approvals,
      buddy,
      leaveStartDate,
      reportingDate,
      dayPart,
      ...rest
    } = values;

    const formattedValues = {
      ...rest,
      leaveType: leaveType?.value,
      approvals: [approvals?.value],
      leaveStartDate: moment(leaveStartDate).format("YYYY-MM-DD"),
      reportingDate: moment(leaveStartDate).add(1, "days").format("YYYY-MM-DD"),
      buddy: buddy?.value,
    };

    updateLeaveRequest({
      id: leaveRequestId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <OneDayLeaveRequestForm
              handleClose={handleClose}
              formikProps={formikProps}
              codioticUsers={codioticUsers}
              isDataLoading={isDataLoading}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditOneDayLeaveRequestFormWrapper;
