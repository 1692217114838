import React from "react"; //  { ChangeEvent }

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { MdExpandMore } from "react-icons/md";

import ATMPageHeading from "src/components/UI/atoms/ATMPageHeading/ATMPageHeading";
import { fieldTypes, moduleActionTypes, setCheckUserAccess } from "src/redux/slices/access/userAcessSlice";
import {
  ModulesTypes,
} from "src/redux/slices/access/userAcessSlice";
import { mergeUserModules } from "../mergeJson";
import useDepartment from "src/hooks/useDepartment";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { useDispatch } from "react-redux";
import { showToast } from "src/utils/showToaster";
// |-- Types --|
type Props = {
  apiStatus?: boolean;
  formType: string;
  formikProps: FormikProps<any>;
};

const UserAccess = ({
  formikProps,
  apiStatus,
  formType,
}: Props) => {
  const dispatch = useDispatch()
  const { values, setFieldValue, } = formikProps;
  const modules: ModulesTypes[] = [...mergeUserModules];
  const { department, isDataLoading } = useDepartment();
  const departmentOptions = department?.map((depart) => {
    return {
      label: depart?.departmentName,
      value: depart?._id,
    };
  });

  const handleUserModuleAccess = (module: ModulesTypes, moduleValue: boolean) => {

    const moduleAccess = modules?.find(
      (moduleitem: any) => moduleitem?.moduleId === module.moduleId
    );
    if (moduleAccess) {
      let value = values.module ? [...values.module] : [];
      if (moduleValue) {
        value.push(moduleAccess);
      } else {
        let valueRemove = values?.module.filter(
          (moduleitem: any) => moduleitem.moduleId !== module.moduleId
        );
        value = valueRemove;
      }
      setFieldValue('module', value)
      dispatch(setCheckUserAccess(value))
    }
  };

  const isCheckedModule = (module: ModulesTypes) => {
    const isExistModule = values?.module?.some(
      (moduleitem: any) => moduleitem.moduleId === module.moduleId
    );
    return isExistModule || false;
  };


  const handleUserActionAccess = (
    module: ModulesTypes,
    action: moduleActionTypes,
    actionValue: boolean
  ) => {

    const moduleIndex = values.module?.findIndex(
      (moduleitem: any) => moduleitem.moduleId === module.moduleId
    );
    if (moduleIndex >= 0) {
      let moduleValue = [...values?.module];
      let moduleAction = [
        ...values?.module[moduleIndex].moduleAction,
      ];

      if (actionValue) {
        action.parentGroup.forEach((groupName) => {
          let isParent = moduleAction?.find(
            (actionitem) => actionitem.actionName === groupName
          );
          if (!isParent) {
            const ActiveModule = modules?.find(
              (moduleitem) => moduleitem.moduleId === module.moduleId
            );
            let ViewAction = ActiveModule?.moduleAction?.find(
              (actionitem: moduleActionTypes) =>
                actionitem.actionName === groupName
            );
            if (ViewAction) {
              moduleAction.push(ViewAction);
            }
          }
        });

        moduleAction.push(action);
      } else {
        let isChildRemove = moduleAction?.filter(
          (actionitem: moduleActionTypes) => {
            if (
              !actionitem.parentGroup.includes(action.actionName) &&
              actionitem.actionId !== action.actionId
            ) {
              return actionitem;
            }
            return false;
          }
        );

        moduleAction = isChildRemove;
      }
      if (moduleAction.length === 0) {
        showToast("error", "Please Select atleast one action");
        return;
      }

      moduleValue[moduleIndex] = {
        ...moduleValue[moduleIndex],
        moduleAction: moduleAction,
      };
      // dispatch(setCheckUserAccess(moduleValue));
      setFieldValue('module', moduleValue)
    } else {
      // let addModule = [...userAccessItems?.modules]
      // addModule.push(module) 
      let moduleAction: moduleActionTypes[] = []
      if (actionValue) {
        action.parentGroup.forEach((groupName) => {
          let isParent = moduleAction?.find(
            (actionitem) => actionitem.actionName === groupName
          );
          if (!isParent) {
            const ActiveModule = modules?.find(
              (moduleitem) => moduleitem.moduleId === module.moduleId
            );
            let ViewAction = ActiveModule?.moduleAction?.find(
              (actionitem: moduleActionTypes) =>
                actionitem.actionName === groupName
            );
            if (ViewAction) {
              moduleAction.push(ViewAction);
            }
          }
        });

        moduleAction.push(action);

      } else {
        let isChildRemove = moduleAction?.filter(
          (actionitem: moduleActionTypes) => {
            if (
              !actionitem.parentGroup.includes(action.actionName) &&
              actionitem.actionId !== action.actionId
            ) {
              return actionitem;
            }
            return false;
          }
        );

        moduleAction = isChildRemove;
      }
      let moduleValue = [...values?.module];
      let newModule = {
        ...module,
        moduleAction: moduleAction,
      };
      moduleValue.push(newModule)
      setFieldValue('module', moduleValue)
    }
  };

  const isCheckedModuleAction = (
    module: ModulesTypes,
    actions: moduleActionTypes
  ) => {
    const isExistModule = values?.module?.find(
      (moduleitem: any) => moduleitem.moduleId === module.moduleId
    );
    return (
      isExistModule?.moduleAction?.some(
        (actionItems: any) => actionItems.actionId === actions.actionId
      ) || false
    );
  };
  const isCheckedModuleActionField = (
    module: ModulesTypes,
    actions: moduleActionTypes,
    field: fieldTypes
  ) => {
    const isExistModule = values.module?.find(
      (moduleitem: any) => moduleitem.moduleId === module.moduleId
    );
    const isExistModuleAction = isExistModule?.moduleAction?.find(
      (actionItems: any) => actionItems.actionId === actions.actionId
    );

    return (
      isExistModuleAction?.fields?.some(
        (fieldItem: any) => fieldItem.fieldId === field.fieldId
      ) || false
    );
  };

  const handleUserFieldAccess = (
    module: ModulesTypes,
    actions: moduleActionTypes,
    field: fieldTypes,
    fieldValue: boolean
  ) => {
    let clonedUserAccessItems = JSON.parse(JSON.stringify(values));

    const moduleIndex = clonedUserAccessItems.module?.findIndex(
      (moduleitem: ModulesTypes) => moduleitem.moduleId === module.moduleId
    );
    const moduleActionIndex = clonedUserAccessItems.module[
      moduleIndex
    ]?.moduleAction.findIndex(
      (actionItem: moduleActionTypes) =>
        actionItem.actionId === actions.actionId
    );  

    if (moduleIndex >= 0 && moduleActionIndex >= 0) {
      let moduleValue = [...clonedUserAccessItems?.module];
      let moduleActionField = [
        ...moduleValue[moduleIndex]?.moduleAction[moduleActionIndex]?.fields,
      ];

      if (fieldValue) {
        moduleActionField.push(field);
      } else {
        let valueRemove = moduleActionField?.filter(
          (fieldItem) => fieldItem.fieldId !== field.fieldId
        );
        moduleActionField = valueRemove;
      }
 
      moduleValue[moduleIndex].moduleAction[moduleActionIndex] = {
        ...moduleValue[moduleIndex].moduleAction[moduleActionIndex],
        fields: [...moduleActionField],
      };
      
      setFieldValue('module', moduleValue)
    }
  };


  // States
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  const [expanded0, setExpanded0] = React.useState<number | false>(false);
  const handleChange0 =
    (panel: number) => (event: React.SyntheticEvent, isExpanded0: boolean) => {
      setExpanded0(isExpanded0 ? panel : false);
    };

  const getReplaceUnderScoreToSpace = (name: string) => {
    let navReplace = name?.replaceAll("NAV", "")
    return navReplace?.replaceAll("_", " ");
  }

  return (
    <div className="h-[calc(100vh-55px)] bg-white">
      <div className="p-4 flex flex-col gap-2  ">


        {/* Page Heading */}
        <div className="pt-1">
          <ATMPageHeading> Modules </ATMPageHeading>
        </div>

        <div className="grow max-h-full bg-white border bg-1 rounded shadow  bg-cover bg-no-repeat">
          <div className="flex justify-between px-3 h-[60px] items-center border-b border-slate-300">
            {/* Form Step Label */}
            <div className="text-xl font-medium">
              Modules Details
            </div>

            {/* BUTTON - Add SO */}
            <div>
              <button
                type="submit"
                disabled={apiStatus}
                // onClick={() => {
                //   handleUserAccessSubmit();
                // }}
                className={`bg-primary-main rounded py-1 px-5 text-white border border-primary-main ${apiStatus ? "disabled:opacity-25" : ""
                  }`}
              >
                Update
              </button>
            </div>
          </div>
          <div className="flex gap-4 border-b border-slate-300">
            <div className="flex gap-4 items-center p-2">
              <div className="flex items-center gap-2">
                <h4 className="font-bold">Position :</h4>{" "}
                <ATMTextField
                  disabled={formType === "Add"}
                  readOnly={formType === "Add"}
                  name="positionId"
                  label=""
                  value={values?.positionId || ""}
                  onChange={(e) => {
                    // setUserRoleState(e?.target?.value || "");
                  }}
                  placeholder="Select Role"
                />
              </div>
              <div className=" flex gap-2 items-center">
                <h4 className="font-bold">Department :</h4>{" "}

                <ATMSelect
                  name="departmentId"
                  value={values?.departmentId}
                  onChange={(value) => {
                    setFieldValue('departmentId', value)
                  }}
                  placeholder="Select department"
                  options={departmentOptions}
                  isLoading={isDataLoading}
                  label=""
                  disabled={formType === "Add"}
                />
              </div>
            </div>
          </div>

          <div className="py-3 px-2 border-b border-slate-300">
            <div className="grid grid-cols-1 gap-1">
              <div className="flex flex-col gap-3">
                {modules?.map((module: ModulesTypes, ind: number) => {
                  return (
                    <Accordion
                      key={ind}
                      className="shadow-lg border "
                      expanded={expanded === ind}
                      onChange={handleChange(ind)}
                    >
                      <AccordionSummary
                        expandIcon={<MdExpandMore />}
                        aria-controls={`panel-${ind}`}
                        id={`panel-${ind}`}
                      >
                        <span className="text-primary-main font-medium ">
                          <div className="font-bold text-medium  gap-2 flex">
                            <input
                              className="cursor-pointer"
                              id={`${module?.moduleId}`}
                              type={"checkbox"}
                              checked={isCheckedModule(module)}
                              onChange={(e) =>
                                handleUserModuleAccess(module, e.target.checked)
                              }
                            />
                            <label

                              className="select-none cursor-pointer"
                            // htmlFor={`${module?.moduleId}`}
                            >
                              {getReplaceUnderScoreToSpace(module?.moduleName)}
                            </label>
                          </div>
                        </span>
                      </AccordionSummary>

                      <AccordionDetails className="border-t border-slate-300  ">
                        <div className="py-3">
                          <ul className="pt-2  grid grid-cols-4 gap-1">
                            {module?.moduleAction.map(
                              (actionsItems: moduleActionTypes, index: any) => {
                                return (
                                  <li className="" key={actionsItems.actionId}>
                                    <div className="gap-2 flex px-3 py-1">
                                      <input
                                        className="cursor-pointer"
                                        id={`${actionsItems?.actionId}`}
                                        type={"checkbox"}
                                        checked={isCheckedModuleAction(
                                          module,
                                          actionsItems
                                        )}
                                        disabled={actionsItems.actionName === "LIST"}
                                        onChange={(e) =>
                                          handleUserActionAccess(
                                            module,
                                            actionsItems,
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <div className="flex flex-cols-1 justify-around">
                                        {actionsItems?.fields.length ? (
                                          <Accordion
                                            key={index}
                                            className="shadow-lg border "
                                            expanded={expanded0 === index}
                                            onChange={handleChange0(index)}
                                          >
                                            <AccordionSummary
                                              expandIcon={<MdExpandMore />}
                                              aria-controls={`panel-${index}`}
                                              id={`panel-${index}`}
                                            >
                                              <span className="text-primary-main font-medium">
                                                <label
                                                  className="select-none mb-1 cursor-pointer"
                                                  htmlFor={`${actionsItems?.actionId}`}
                                                >
                                                  {getReplaceUnderScoreToSpace(
                                                    actionsItems.actionName
                                                  )}
                                                </label>
                                              </span>
                                            </AccordionSummary>

                                            <AccordionDetails className="border-t border-slate-300 ">
                                              <div className="py-3 ">
                                                <div className="px-4 py-1 border flex flex-col justify-between">
                                                  {actionsItems?.fields?.map(
                                                    (
                                                      field: any,
                                                      index: any
                                                    ) => {
                                                      return (
                                                        <ul key={field.fieldId}>
                                                          <li
                                                            className=""
                                                            key={index}
                                                          >
                                                            <div className="gap-2 flex px-3">
                                                              <input
                                                                className="cursor-pointer"
                                                                id={`${field?.fieldId}`}
                                                                type={
                                                                  "checkbox"
                                                                }
                                                                checked={isCheckedModuleActionField(
                                                                  module,
                                                                  actionsItems,
                                                                  field
                                                                )}
                                                                onChange={(e) =>
                                                                  handleUserFieldAccess(
                                                                    module,
                                                                    actionsItems,
                                                                    field,
                                                                    e.target
                                                                      .checked
                                                                  )
                                                                }
                                                              />
                                                              <label
                                                                className="select-none cursor-pointer"
                                                                htmlFor={`${field?.fieldId}`}
                                                              >
                                                                {
                                                                  field?.fieldName
                                                                }
                                                              </label>
                                                            </div>
                                                          </li>
                                                        </ul>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            </AccordionDetails>
                                          </Accordion>
                                        ) : (
                                          <label
                                            className="select-none"
                                            htmlFor={`${actionsItems?.actionId}`}
                                          >
                                            {getReplaceUnderScoreToSpace(
                                              actionsItems.actionName
                                            )}
                                          </label>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default UserAccess;
