import React from "react";
// import moment from "moment";
// import { object, string } from "yup";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import AllTaskFilterFormCardDialog from "./DumpFilterFormDialog";

import { setProjectValueFilter } from "src/redux/slices/DumpSlice";
// import moment from "moment";

type Props = {
  open: boolean;
  onClose: () => void;
};


export type FormInitialValues = {
  project: any;
  // status: any
};

const DumpFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const allDoubtState = useSelector((state: RootState) => state.dump);
  const {
    projectFilterValue,
    // status,
  } = allDoubtState;

  // Initial Values
  const initialValues: FormInitialValues = {
    project: projectFilterValue,
    // status: status
  };


  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);

    //  set project value
    dispatch(setProjectValueFilter(values.project));

 
    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {

    //  set project value
    await dispatch(setProjectValueFilter(""));


    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AllTaskFilterFormCardDialog
            open={open}
            // doubtOptions={doubtOptions}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default DumpFilterFormDialogWrapper;
