import React, { ReactNode } from "react";
import { ErrorMessage } from "formik";
import Select, {
  StylesConfig,
  GroupBase,
  FormatOptionLabelMeta,
  Options,
  PropsValue,
} from "react-select";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import { IoMdAddCircle } from "react-icons/io";
type Option = {
  value: string;
  label: string;
};

type Props = {
  label?: string;
  required?: boolean;
  size?: "small" | "medium";
  name: string;
  isLoading?: boolean;
  searchable?: boolean;
  placeholder?: string;
  extraClasses?: string;
  renderValue?: (selected: any) => ReactNode;
  getValue?: (selected: any) => any;
  isOptionEqualToValue?: (option: any, selected: any) => boolean;
  isSearchedOption?: (option: any, searchValue: string) => boolean;
  showAddButton?: boolean;
  onAddNew?: (formValues: any, closeForm: () => void) => void;
  readonly?: boolean;
  onBlur?: any;
  showTopAddButton?: boolean;
  onClickShareAddButton?: (newValue: boolean) => void;
  options: Options<Option | any>;
  onChange: (selectedOption: Option | any) => void;
  renderOption?: (
    option: Option | any,
    meta: FormatOptionLabelMeta<Option | any>
  ) => React.ReactNode;
  disabled?: boolean;
  value: PropsValue<Option | any>;
  isOptionSelected?: (option: any, selectedValue: any) => boolean;
  menuPlacement?: "auto" | "top" | "bottom";
  menuPosition?: "fixed" | "absolute";
  isMulti?: any;
  extraMinWidthClass?: any;
  isClearable?: boolean;
  autoFocus?: boolean;
};

const ATMSelect = ({
  label = "",
  name,
  options,
  value,
  onChange,
  required = false,
  renderOption,
  isLoading = false,
  isOptionEqualToValue,
  disabled = false,
  menuPlacement = "bottom",
  isSearchedOption,
  placeholder = "Select",
  isClearable = true,
  size = "small",
  menuPosition = "fixed",
  isMulti = false,
  searchable = false,
  extraClasses,
  renderValue = (selected) => selected?.label,
  getValue = (selected) => selected?.value,
  showAddButton = false,
  onAddNew,
  readonly = false,
  showTopAddButton = false,
  onBlur,
  onClickShareAddButton,
  extraMinWidthClass = 250,
  autoFocus = false,
}: Props) => {
  const customStyles: StylesConfig<Option, false, GroupBase<Option>> = {
    option: (styles: any, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "var(--blue-4)"
            : isFocused
              ? "var(--blue-2)"
              : undefined,

        color: isDisabled
          ? undefined
          : isSelected
            ? "black"
            : isFocused
              ? undefined
              : undefined,
      };
    },

    menu: (styles: any) => {
      return {
        ...styles,
        minWidth: extraMinWidthClass,
        zIndex: 10000000,
        borderRadius: "0.5rem",
      };
    },

    container: (styles: any, { isFocused }) => {
      return {
        ...styles,
        minWidth: extraMinWidthClass,
        height: getInputHeight(size, true),
      };
    },

    control: (styles: any, { isFocused }) => {
      return {
        ...styles,
        height: "100%",
        border: isFocused ? "2px solid var(--primary-dark)" : undefined,
        boxShadow: "none",
      };
    },

    // Add any custom styles here if needed
  };

  return (
    <>
      <div className="relative mt-1 bg-inherit" id="select-container">
        <div className="flex justify-between">
          {label && (
            <label
              className={`text-sm font-medium  ${disabled && "opacity-40"} `}
            >
              {label} {required && <span className="text-red-500"> * </span>}
            </label>
          )}

          {showTopAddButton && onClickShareAddButton && (
            <button
              type="button"
              className="items-end ml-2 text-2xl font-bold text-blue-500 cursor-pointer"
              onClick={() => {
                onClickShareAddButton(true);
              }}
            >
              <IoMdAddCircle />
            </button>
          )}
        </div>
        <div className="mt-1">
          <Select
            isMulti={isMulti}
            options={options}
            isClearable={isClearable}
            value={value}
            onChange={(option) => onChange(option)}
            styles={customStyles}
            formatOptionLabel={renderOption}
            isDisabled={disabled}
            isOptionSelected={isOptionEqualToValue}
            menuPlacement={menuPlacement}
            isLoading={isLoading}
            placeholder={placeholder}
            filterOption={
              isSearchedOption
                ? (option, inputValue) =>
                    isSearchedOption?.(option, inputValue?.toLowerCase())
                : undefined
            }
            className="border-none"
            onBlur={onBlur}
            menuPosition={menuPosition}
            autoFocus={autoFocus}
          />
        </div>
        {name && (
          <ErrorMessage name={name}>
            {(errMsg) => (
              <p className="absolute mt-0 text-xs text-red-500 text-start">
                {errMsg}
              </p>
            )}
          </ErrorMessage>
        )}
      </div>
    </>
  );
};
export default ATMSelect;
