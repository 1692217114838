import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useGetAllTicketWithETAStatusQuery } from "src/services/ChangeRequestServices";
import ETAStatusList from "./ETAStatusList";

type Props = {
  onClose: () => void;
  changeRequestId: string;
};
const ETAStatusListWrapper = ({ onClose, changeRequestId }: Props) => {
  const [ticketInfo, setTicketInfo] = useState({});
  const { data, isLoading, isFetching } =
    useGetAllTicketWithETAStatusQuery(changeRequestId);
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTicketInfo(data?.data || {});
    }
  }, [data, isLoading, isFetching]);
  return (
    <Dialog open maxWidth="md" fullWidth fullScreen={isMobile}>
      <ETAStatusList ticketInfo={ticketInfo} isLoading={isLoading} isFetching={isFetching} onClose={onClose} />
    </Dialog>
  );
};

export default ETAStatusListWrapper;
