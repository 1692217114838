import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object } from "yup";
import { showToast } from "src/utils/showToaster";
import {
  useGetMeetingByIdQuery,
  // notes
  useGetMeetingNotesQuery,
  useAddMeetingNotesMutation,
  useDeleteMeetingNotesMutation,
} from "src/services/MeetingServices";
import NotesTab from "./NotesTab";
import { useParams } from "react-router-dom";
import { MeetingListResponse } from "src/models/Meeting.model";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";

type Props = {};

export type MeetingNotesTabFormValues = {
  meetingId: any;
  clientUserId: any;
  notes: any;
};

const NotesTabWrapper = (props: Props) => {
  const [meetingNotes, setMeetingNotes] = useState<any[]>([]);
  const [notesSearchValue, setNotesSearchValue] = useState<string>("");

  const [meetingData, setMeetingData] = useState<MeetingListResponse>();

  const { meetingId: MEETING_ID } = useParams();
  const [addMeetingNotes] = useAddMeetingNotesMutation();
  const [deleteMeetingNotes] = useDeleteMeetingNotesMutation();

  const { isLoading, isFetching, data } = useGetMeetingByIdQuery(
    MEETING_ID || "",
    {
      skip: !MEETING_ID,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setMeetingData(data?.data || []);
    }
  }, [isLoading, isFetching, data]);

  const {
    isLoading: isNotesLoading,
    isFetching: isNotesFetching,
    data: notesData,
  } = useGetMeetingNotesQuery({
    limit: 100,
    searchValue: notesSearchValue,
    params: [
      "meetingId",
      "clientUserId",
      "notes",
      "createdById",
      "createdByUserName",
      "isDeleted",
      "isActive",
    ],
    page: 0,
    filterBy: [
      {
        fieldName: "meetingId",
        value: MEETING_ID,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isNotesLoading && !isNotesFetching) {
      setMeetingNotes(notesData?.data);
    }
  }, [isNotesLoading, isNotesFetching, notesData]);

  // Form Initial Values
  const initialValues: MeetingNotesTabFormValues = {
    meetingId: MEETING_ID,
    clientUserId: "",
    notes: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    // notes: string().required("notes is required"),
  });

  // Handle Submit
  const handleSubmit = (
    values: MeetingNotesTabFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingNotesTabFormValues>
  ) => {
    const { clientUserId, ...rest } = values;
    const formatedValues = {
      clientUserId: clientUserId?.value,
      ...rest,
    };

    addMeetingNotes(formatedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  const handelDeleteMeetingNotes = (notedId: string) => {
    showConfirmationDialog({
      title: "Heads Up",
      text: "Are you sure want to delete this note ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteMeetingNotes(notedId).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  return (
    <Formik
      // enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form className="h-full">
          <NotesTab
            rows={meetingNotes || []}
            formikProps={formikProps}
            meetingData={meetingData}
            isTableLoading={false}
            handleDeleteMeetingNotes={
              (noteId) => handelDeleteMeetingNotes(noteId) // delete meeting notes
            }
            notesSearchValue={notesSearchValue}
            setNotesSearchValue={(value: string) => setNotesSearchValue(value)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default NotesTabWrapper;
