import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { LeadsValues } from "src/models/Leads.model";
import ViewLeadsFrom from "./ViewLeadsFrom";
import {
  useGetSingleLeadQuery,
  useUpdateLeadMutation,
} from "src/services/LeadsService";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { showToast } from "src/utils/showToaster";

const ViewLeadsFromWrapper = () => {
  const { leadsId } = useParams();
  const [singleLeadData, setSingleLeadData] = useState<any>({});

  const { data, isLoading, isFetching } = useGetSingleLeadQuery(leadsId || '');
  const [UpdateLead] = useUpdateLeadMutation();

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setSingleLeadData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: LeadsValues = {
    assigned_to: {
      label: singleLeadData?.assignedToUserName,
      value: singleLeadData?.assignedTo,
    },
    name: singleLeadData?.name,
    mobile: singleLeadData?.mobile,
    email: singleLeadData?.email,
    alternate_1: singleLeadData?.altNumber1,
    alternate_2: singleLeadData?.altNumber2,
    organization: singleLeadData?.organizationName,
    country: {
      label: singleLeadData?.countryName,
      value: singleLeadData?.countryId,
    },
    state: {
      label: singleLeadData?.stateName,
      value: singleLeadData?.stateId,
    },
    district: {
      label: singleLeadData?.cityName,
      value: singleLeadData?.cityId,
    },
    summary_note: singleLeadData?.summaryNote,
    next_followUp_date: singleLeadData?.nextFollowupDate,
    next_followup_time: singleLeadData?.nextFollowupTime ? moment(
      singleLeadData?.nextFollowupTime?.split("-")?.[0]?.trim(),
      "hh:mm A"
    )
      : null,
    leads_label: {
      label: singleLeadData?.leadLabelName,
      value: singleLeadData?.leadLabelName,
    },
    channel: {
      label: singleLeadData?.channelName,
      value: singleLeadData?.channelId,
    },
    campaign: {
      label: singleLeadData?.campaignName,
      value: singleLeadData?.campaignId,
    },
    domains: {
      label: singleLeadData?.domainName,
      value: singleLeadData?.domainId,
    },
    socialMedias: singleLeadData?.socialMediaId,
  };

  // Validation Schema
  const validationSchema = object().shape({
    mobile: string().required("Please enter mobile"),
    email: string().email(),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeadsValues,
    { setSubmitting, resetForm }: FormikHelpers<LeadsValues>
  ) => {
    const formatedValues = {
      name: values?.name || '',
      mobile: values?.mobile || '',
      email: values?.email || '',
      altNumber1: values?.alternate_1 || '',
      altNumber2: values?.alternate_2 || '',
      campaignId: values?.campaign?.value || null,
      channelId: values?.channel?.value || null,
      assignedTo: values?.assigned_to?.value || null,
      countryId: values?.country?.value || null,
      cityId: values?.district?.value || null,
      stateId: values?.state?.value || null,
      domainId: values?.domains?.value || null,
      organizationName: values?.organization || '',
      socialMediaId: values?.socialMedias?.map((el: any) => {
        return {
          mediaId: el?.mediaId,
          value: el?.value,
        };
      }),
    };
    UpdateLead({
      id: leadsId,
      body: formatedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <ViewLeadsFrom formikProps={formikProps} />
        </Form>
      )}
    </Formik>
  );
};

export default ViewLeadsFromWrapper;
