import React from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { array, object, string } from "yup";
import IncidentForm from "../Layouts/IncidentForm";
import { useAddPointerMutation } from "src/services/IncidentService";
import moment from "moment";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
};

export type IncidentFormValues = {
  Date: any;
  remark: string;
  assignName: any;
  category: any;
  eventType: any;
  isMarkAsUnhandled: boolean;
};

const AddIncidentFormWrapper = ({ onClose }: Props) => {
  const [addPointer] = useAddPointerMutation();

  // Form Initial Values
  const initialValues: IncidentFormValues = {
    Date: moment(),
    remark: "",
    assignName: "",
    category: "",
    eventType: "",
    isMarkAsUnhandled: false,
  };

  // Validation Schema
  const validationSchema = object().shape({
    remark: string().required("Please enter remark"),
    assignName: array()
      .min(1, "Please select member")
      .test("installmentTest", "Please select assign", (value, context) => {
        const { isMarkAsUnhandled } = context.parent;
        if (!isMarkAsUnhandled || value) {
          return true;
        } else {
          return true;
        }
      }),
    category: object().required("Please select category"),
    eventType: object().required("Please select type"),
    Date: object().required("date is required"),
  });

  // Handle Submit
  const handleSubmit = (
    values: IncidentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<IncidentFormValues>
  ) => {
    const formattedValues = {
      categoryId: values?.category?.value,
      typeId: values?.eventType?.value,
      remark: values?.remark,
      markAsUnhandled: values?.isMarkAsUnhandled,
      teamMembers: values?.assignName?.length
        ? values?.assignName?.map((el: any) => el?.value)
        : null,
      addedOnDate: values?.Date
        ? moment(values?.Date).format("YYYY-MM-DD")
        : "",
    };

    addPointer(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <IncidentForm
              formikProps={formikProps}
              onClose={onClose}
              formType="Add"
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddIncidentFormWrapper;
