import { FieldArray, FormikProps } from "formik";
import { useEffect, useState } from "react";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMPassword from "src/components/UI/atoms/formFields/ATMPassword/ATMPassword";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLClientDropdown from "src/components/UI/molecules/MOLClientDropdown/MOLClientDropdown";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { ClientUserFormValues } from "src/models/ClientUser.model";
import { useGetProjectByClientQuery } from "src/services/ProjectServices";
import { handleValidNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<ClientUserFormValues>;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: (checked: boolean) => void;
  saveNextChecked?: boolean;
  onClose: () => void;
};

const ClientUserForm = ({
  formikProps,
  formType = "ADD",
  setSaveNextChecked,
  saveNextChecked,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const [projectData, setProjectData] = useState<any>();
  const { data, isLoading, isFetching }: any = useGetProjectByClientQuery(
    values?.clientId?._id,
    { skip: !values?.clientId?._id }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setProjectData(data?.data || []);
    }
  }, [isLoading, data, isFetching, values?.clientId?._id]);

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Client User" : "Update Client User"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          {/* Client */}
          <MOLClientDropdown
            required
            name="clientId"
            value={values.clientId}
            onChange={(newValue) => setFieldValue("clientId", newValue)}
            onBlur={handleBlur}
            label="Client"
            placeholder="Select Client"
          />

          {/* Name */}
          <ATMTextField
            required
            name="name"
            value={values.name}
            onChange={(e) => setFieldValue("name", e.target.value)}
            label="Name"
            placeholder="Enter Name"
            onBlur={handleBlur}
          />

          {/* Email */}
          <ATMTextField
            required
            name="email"
            value={values.email}
            onChange={(e) => setFieldValue("email", e.target.value)}
            label="Email"
            placeholder="Enter Email"
            onBlur={handleBlur}
          />

          {/* Mobile Number */}
          <ATMTextField
            required
            name="mobile"
            value={values.mobile}
            onChange={(e) =>
              handleValidNumber(e) && setFieldValue("mobile", e.target.value)
            }
            label="Mobile Number"
            placeholder="Enter Mobile Number"
            onBlur={handleBlur}
          />

          {/* Projects */}
          <div className="mb-2">
            <p className="text-red-500 underline text-md">Projects</p>
            <FieldArray name="projectId">
              {({ push, remove }) => (
                <div className="grid grid-cols-1 gap-4 p-3 mt-1 md:grid-cols-2 bg-slate-100">
                  {projectData?.length ? (
                    projectData.map((project: any, index: any) => {
                      const isChecked = values?.projectId?.some(
                        (valueProject: any) => valueProject._id === project._id
                      );
                      return (
                        <div key={index} className="flex items-end gap-2">
                          <ATMCheckbox
                            id={index}
                            checked={isChecked}
                            onChange={(checked) => {
                              if (checked) {
                                push({
                                  _id: project._id,
                                  projectName: project.projectName,
                                });
                              } else {
                                const index = values.projectId.findIndex(
                                  (valueProject: any) =>
                                    valueProject._id === project._id
                                );
                                remove(index);
                              }
                            }}
                            label={project.projectName}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="font-semibold">No project found</div>
                  )}
                </div>
              )}
            </FieldArray>
          </div>

          <ATMCheckbox
            id="etaApprove"
            checked={values.canApproveETA}
            onChange={(checked) => setFieldValue("canApproveETA", checked)}
            label="ETA Approve Access"
            labelClasses="select-none"
          />

          <ATMCheckbox
            id="closeTicket"
            checked={values.canCloseTicket}
            onChange={(checked) => setFieldValue("canCloseTicket", checked)}
            label="Close Ticket Access"
            labelClasses="select-none"
          />

          {/* Password */}
          {formType === "ADD" && (
            <ATMPassword
              required
              name="password"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
              label="Password"
              placeholder="Enter Password"
              onBlur={handleBlur}
            />
          )}
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default ClientUserForm;
