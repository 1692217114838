import { FormikProps } from "formik";
import ATMRadioButtonGroup from "src/components/UI/atoms/formFields/ATMRadioButtonGroup/ATMRadioButtonGroup";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<any>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};

const ClientLedgerFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  return (
   <MOLFilterDialog 
   title={"Filters"}
   onClose={onClose}
   isSubmitting={isSubmitting}
   clearFilter={onReset}
   handleSubmit={handleSubmit}
   open={open}
   >
     <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">
        {/* Filter Form Field */}
        <div className="">
          <ATMRadioButtonGroup
            label="Types"
            required={false}
            value={values.recurranceType}
            options={[
              {
                label: 'MILESTONE',
                value: 'MILESTONE',
              },
              {
                label: 'EXPENSE',
                value: 'EXPENSE',
              },
              {
                label: 'PAYMENT',
                value: 'PAYMENT',
              }
            ]}
            allowMultipleSelection
            onChange={(newValue) => {
              setFieldValue("recurranceType", newValue);
            }}
          />
        </div>
      </div>
   </MOLFilterDialog>
  );
};

export default ClientLedgerFilterFormDialog;
