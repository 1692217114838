import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { EstimationCalculatorFormValues } from "src/models/EstimationCalculator.model";
import { useAddEstimationCalculationMutation } from "src/services/EstimationCalculatorServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import EstimationCalculatorForm from "../Layout/EstimationCalculatorForm";
import { CLIENT_GRW } from "src/utils/constants";

const AddEstimationCalculatorFormWrapper = () => {
  const [addCalculation] = useAddEstimationCalculationMutation();
  const navigate = useNavigate();

  // Form Initial Values
  const initialValues: EstimationCalculatorFormValues = {
    items: [{ developer: null, duration: "", hoursPerDay: "" }],
    title: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter title"),
  });

  // Handle Submit
  const handleSubmit = (
    values: EstimationCalculatorFormValues,
    { setSubmitting, resetForm }: FormikHelpers<EstimationCalculatorFormValues>
  ) => {
    const formattedValues = {
      title: values?.title,
      fields: values?.items?.map((el: any) => {
        return {
          developer: {
            developerId: el?.developer?.developerId,
            developerName: el?.developer?.developerName,
            salaryPerHour: el?.developer?.salaryPerHour,
            growthSalaryPerHour: CLIENT_GRW ? 0 : el?.developer?.growthSalaryPerHour,
          },
          duration: el?.duration,
          hoursPerDay: el?.hoursPerDay,
        };
      }),
    };

    addCalculation(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate("/estimations");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <SideNavLayout>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <EstimationCalculatorForm formikProps={formikProps} />
            </Form>
          )}
        </Formik>
      </SideNavLayout>
    </>
  );
};

export default AddEstimationCalculatorFormWrapper;
