import React, { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import DoubtFilterFormDialogWrapper from "./DoubtFilterFormCard/DoubtFilterFormDialogWrapper";
import { BiChevronRight } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, Divider } from "@mui/material";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { FiArrowRight } from "react-icons/fi";
import TimerComponent from "./Timer/Timer";
import { BsClock } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  rows: any[];
  addNew: () => void;
  onEdit: (id: string) => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const DoubtsList = ({
  rows,
  addNew,
  onEdit,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  // Filter Form Open
  const [isOpenTaskAllFilterDialog, setIsOpenTaskAllFilterDialog] =
    useState<boolean>(false);
  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  // short cut key for open filter form
  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if the pressed keys match the specified shortcut (Ctrl + Shift + F)
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "f") {
        // Open the filter dialog when the shortcut is detected
        setIsOpenTaskAllFilterDialog(true);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <div className="flex flex-col h-full">
      {/* Page Header */}
      <div className="flex justify-between">
        <ATMPageHeader
          searchValue={searchValue}
          isFilter
          onFilterClick={() => setIsOpenTaskAllFilterDialog(true)}
          hideAddButton={
            !isAuthorized(UserModuleNameTypes.ACTION_DOUBTS_OPEN_ADD)
          }
          buttonProps={{
            btnName: "Add New",
            onClick: addNew,
          }}
          onSearchChange={setSearchValue}
          pageTitle="Doubts"
        />
      </div>

      <div className="h-[100vh] overflow-y-scroll overflow-x-hidden xs:px-1 md:px-0 bg-gray-50 ">
        {isTableLoading ? (
          <div className="flex justify-center items-center h-[400px]">
            <CircularProgress />
          </div>
        ) : rows?.length ? (
          <div className="flex flex-col">
            {/* // Default show Task listing view */}
            {rows?.map((row: any, index: any) =>
              isTableLoading ? (
                Array(10)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index} className="animate-pulse h-[50px] p-2">
                      <div className="h-full rounded"></div>
                    </div>
                  ))
              ) : (
                <div
                  key={index}
                  className="w-full px-2 pt-4 cursor-pointer md:px-4 last:pb-4"
                  onClick={() => {
                    isAuthorized(
                      UserModuleNameTypes.ACTION_DOUBTS_OPEN_SINGLE_VIEW
                    ) &&
                      navigate(`/doubt/${row?._id}`, {
                        state: {
                          pathname,
                          search,
                        },
                      });
                  }}
                >
                  <div
                    className={`rounded-md bg-white border border-slate-200 hover:border-primary-dark  overflow-x-scroll overflow-hidden transition-all duration-500 hover:shadow-md`}
                  >
                    <div className="bg-white rounded-md">
                      <div className="flex h-full">
                        {/* ticket header */}
                        <div className="flex items-center justify-between w-full gap-2 md:gap-4">
                          <p className="flex items-center gap-2 text-sm font-medium leading-none">
                            <span className="flex items-center gap-2 bg-teal-100 md:w-[120px] w-[100px] md:text-sm text-xs px-4 p-2">
                              <BsClock />
                              <TimerComponent createdAt={row?.createdAt} />
                            </span>
                            <span className="text-xs w-fit md:text-sm">
                              {row?.createdByName}
                            </span>
                            <FiArrowRight />
                            <span className="text-xs md:text-sm">
                              {row?.assignedToUserName}
                            </span>
                          </p>
                          <div className="flex items-center gap-4">
                            <div className="hidden md:block">
                              <p className="flex items-center gap-2 px-4 text-sm font-semibold capitalize ">
                                {row?.projectName}
                                {row?.moduleName ? (
                                  <>
                                    <BiChevronRight />
                                    {row?.moduleName}
                                    {row?.featureName ? (
                                      <>
                                        <BiChevronRight />
                                        {row?.featureName}
                                      </>
                                    ) : null}
                                  </>
                                ) : null}
                              </p>
                            </div>
                            <div>
                              <div className="items-end">
                                <ATMActionMenu
                                  options={[
                                    {
                                      label: (
                                        <div className="flex items-center gap-x-2">
                                          <MdEdit color="#000000" /> Edit
                                        </div>
                                      ),
                                      onClick: () => onEdit(row?._id),
                                      acitonName:
                                        UserModuleNameTypes.ACTION_DOUBTS_OPEN_EDIT,
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-2 px-4 pt-2">
                        <div className="block md:hidden ">
                          <p className="flex items-center text-xs font-semibold capitalize md:text-sm gap-x-2 ">
                            <span> {row?.projectName}</span>
                            <BiChevronRight />
                            {row?.moduleName}
                            <BiChevronRight />
                            <span className="line-clamp-1">
                              {" "}
                              {row?.featureName}
                            </span>
                          </p>
                        </div>
                        <div className="line-clamp-3">
                          <ATMMarkdownContainer
                            markdown={row?.question || "-"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-[22px]">
            Data not Found
          </div>
        )}
      </div>
      <div className="px-4 py-2 border-t md:px-2">
        <ATMPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          rowCount={totalItems}
          rows={rows}
        />
      </div>
      <DoubtFilterFormDialogWrapper
        open={isOpenTaskAllFilterDialog}
        onClose={() => setIsOpenTaskAllFilterDialog(false)}
      />
    </div>
  );
};

export default DoubtsList;
