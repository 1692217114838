export type LeaveRequestFormValues = {
  reason: string;
  leaveStartDate: string;
  leaveType: any;
  daysOfLeave: string;
  dayPart: any;
  reportingDate: string;
  leavingTime: string;
  arrivalTime: string;
  approvals?: any;
  buddy?: any;
  type?: any
  remark? : any
};

export type FullDayLeaveRequestFormValue = {
  reason: string;
  leaveStartDate: string;
  leaveType: string;
  daysOfLeave: string;
  dayPart: string;
  reportingDate: string;
  leavingTime: string;
  arrivalTime: string;
  approvals: string[];
  buddy: string;
};

export type LeaveRequestListValue = {
  logs?: any;
  remark?: any;
  arrivalTime: string
  buddy: string;
  buddyName: string;
  createdAt: string;
  dayPart: "SECOND_HALF" | "FIRST_HALF" | "FOR_HOURS" | "";
  daysOfLeave: "ONE_DAY" | "HALF_DAY" | "MULTIPLE_DAYS";
  isActive: boolean;
  isDeleted: boolean;
  leaveStartDate: string;
  leaveType: string;
  leavingTime: string;
  reason: string;
  reportingDate: string;
  requestedBy: string;
  requestedByName: string;
  requestedOnDate: string;
  status: "PENDING" | "APPROVED" | "DECLINED";
  updatedAt: string;
  _id: string;
  approvals: any[];
  noOfDays: number
};

export const FullDayLeaveType = [
  {
    label: "Emergency",
    value: "EMERGENCY",
  },
  {
    label: "Planned",
    value: "PLANNED",
  },
];

export enum DaysOfLeaveEnum {
  HALF_DAY = "Half Day",
  MULTIPLE_DAYS = "Multiple Days",
  ONE_DAY = "One Day",
}

export enum DaysPartEnum {
  FOR_HOURS = "For Hours",
  FIRST_HALF = "First Half",
  SECOND_HALF = "Second Half"
}
