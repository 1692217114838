import moment from "moment";
import React from "react";

type Props = {
  birthDayDetails: { name: string; email: string , dobActually:string }[];
};

const MemberBirthDays = ({ birthDayDetails }: Props) => {
  return (
    <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg custom-shadow bg-birthDay ">
      <div className="flex items-center gap-2 p-2 border-b bg-rose-200">
        <div className="flex items-center justify-center p-2 font-semibold bg-white border rounded-md border-primary-dark size-8">
          {birthDayDetails?.length}
        </div>
        <p className="font-medium">This Month BirthDay's</p>
      </div>
     <div>
     {birthDayDetails?.length ? (
        <ul className="h-full p-2 overflow-auto">
          {birthDayDetails?.map((ele, ind) => {
            return (
              <li
                key={ind}
                className="p-2 rounded hover:bg-gray-50 cursor-pointer"
              >
               <div className="flex justify-between">
               <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate capitalize">
                    {ele?.name}
                  </p>
                </div>
                <p className="text-rose-700 text-sm font-medium"> { moment(ele?.dobActually).format("DD MMM")} </p>
               </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-4">
          No Resourse Requests
        </div>
      )}
     </div>
    </div>
  );
};

export default MemberBirthDays;
