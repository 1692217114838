/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  useDispatch,
  useSelector,
  // useSelector
} from "react-redux";
import { useNavigate } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import {
  //   setPage,
  //   setRowsPerPage,
  setIsTableLoading,
  //   setSearchValue,
  //   setTotalItems,
  //   setItems,
} from "src/redux/slices/DoubtSlice";
// import { RootState } from "src/redux/store";
import SingleDoubtView from "./SingleDoubtView";
import { useParams } from "react-router-dom";
import { TaskListResponse } from "src/models/Task.model";
import DoubtSideBarLayout from "./TaskSideBarLayout/DoubtSideBarLayout";
import {
  useGetDoubtByIdQuery,
  useGetDoubtsQuery,
  // useAddCommentOnDoubtMutation,
  // useGetDoubtsLogsQuery,
  useMarkAsDoneDoubtMutation,
  useChangeAssigneeOnDoubtMutation,
  useGetAllCommentOfDoubtQuery,
} from "src/services/DoubtsServices";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { RootState } from "src/redux/store";

const SingleDoubtViewWrapper = () => {
  const { id: DOUBT_ID } = useParams();

  const [searchValue, setSearchValue] = useState<string>("");
  const [doubtData, setDoubtData] = useState<any>();
  const [sidebarDoubtList, setSidebarDoubtList] = useState<TaskListResponse[]>(
    []
  );
  const [comment, setComment] = useState<string>("");
  const [currentAssignee, setCurrentAssignee] = useState<any>("");
  const [doubtLogs, setDoubtLogs] = useState<any>();
  const [sidebarListTotalItems, setSidebarListTotalItems] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);

  const {
    codioticUser,
    projectFilterValue,
    moduleFilterValue,
    featureFilterValue,
  } = useSelector((state: RootState) => state.doubts);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Get Doubt Data
  const {
    isLoading: isSingleDoubtDataLoading,
    isFetching: isSingleDoubtDataFetching,
    data: singleDoubtData,
  } = useGetDoubtByIdQuery(DOUBT_ID, {
    skip: !DOUBT_ID,
  });

  useEffect(() => {
    setCurrentAssignee({
      label: doubtData?.assignedToUserName,
      value: doubtData?.askToId,
    });
  }, [doubtData]);

  useEffect(() => {
    if (!isSingleDoubtDataLoading && !isSingleDoubtDataFetching) {
      setDoubtData(singleDoubtData?.data);
    }
  }, [isSingleDoubtDataLoading, isSingleDoubtDataFetching, singleDoubtData]);

  // const [addCommentOnDoubt] = useAddCommentOnDoubtMutation();
  const [changeAssigneeOnDoubt] = useChangeAssigneeOnDoubtMutation();
  const [addMarkAsDoneOnDoubt] = useMarkAsDoneDoubtMutation();

  const handleSubmitComment = (changeAssigneeId?: string, comment?: string) => {
    changeAssigneeOnDoubt({
      id: DOUBT_ID,
      body: {
        assignedTo: changeAssigneeId,
        comment: comment,
      },
    })
      .then((res: any) => {
        if (res.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          showToast("success", res?.data?.message);
          setComment("");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const { data, isLoading, isFetching } = useGetDoubtsQuery({
    limit: limit,
    searchValue: searchValue,
    params: [
      "projectId",
      "moduleId",
      "featureId",
      "question ",
      "askToId",
      "createdById",
      "createdByName",
      "side",
      "status",
    ],
    page: 1,
    filterBy: [ 
      {
        fieldName: "status",
        value: doubtData?.status || "",
      },
      {
        fieldName: "askToId",
        value: codioticUser?.value || "",
      },
      {
        fieldName: "projectId",
        value: projectFilterValue?.value || "",
      },
      {
        fieldName: "moduleId",
        value: moduleFilterValue?.value || "",
      },
      {
        fieldName: "featureId",
        value: featureFilterValue?.value || "",
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Single Doubt Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      setSidebarDoubtList(data?.data);
      setSidebarListTotalItems(data?.totalItem || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  // Doubts logs
  const {
    data: doubtLogsData,
    isLoading: isDoubtLogsLoading,
    isFetching: isDoubtLogsFetching,
  } = useGetAllCommentOfDoubtQuery(DOUBT_ID || "", {
    skip: !DOUBT_ID,
  });

  // Single Doubt Logs
  useEffect(() => {
    if (!isDoubtLogsLoading && !isDoubtLogsFetching) {
      setDoubtLogs(doubtLogsData?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doubtLogsData, isDoubtLogsLoading, isDoubtLogsFetching]);

  const handleMarkAsSolved = (DOUBT_ID: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to mark as done this doubt ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          addMarkAsDoneOnDoubt(DOUBT_ID).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
              navigate("/doubt/open");
            }
          });
        }
      },
    });
  };

  return (
    <>
      <SideNavLayout>
        <DoubtSideBarLayout
          hideViewMore={sidebarListTotalItems > limit ? true : false}
          listData={sidebarDoubtList}
          searchValue={searchValue}
          onSearch={(value: any) => setSearchValue(value)}
          onClickViewMore={() => setLimit(limit + 20)}
          onClickDoubtView={(doubtId: any) => {
            navigate(`/doubt/${doubtId}`);
          }}
        >
          <SingleDoubtView
            isLoading={isLoading}
            singleDoubtData={doubtData}
            doubtsLogListing={doubtLogs}
            onMarkAsResolved={() => handleMarkAsSolved(DOUBT_ID || "")} // mark as resolved on click button
            currentAssignee={currentAssignee}
            onChangeAssignee={(newValue: any) => {
              handleSubmitComment(newValue?.value, comment);
            }}
          />
        </DoubtSideBarLayout>
      </SideNavLayout>
    </>
  );
};

export default SingleDoubtViewWrapper;
