import { useState, useEffect } from "react";
import { useGetAllGoalCategoryQuery } from "src/services/GoalCategoryService";

export const useGoalCategory = () => {
  const [goalCategory, setgoalCategory] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllGoalCategoryQuery("");

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setgoalCategory(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { goalCategory, isDataLoading };
};
