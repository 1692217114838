
import { useEffect, useState } from "react";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useParams } from "react-router-dom";
import { useGetAllCommentOfServiceTicketsQuery, useGetServiceTicketByIdQuery } from "src/services/ServiceTicketService";
import SingleDoubtView from "./SingleServiceTicketView";

const SingleServiceTicketViewWrapper = () => {
  const { id } = useParams();

  const [doubtLogs, setDoubtLogs] = useState<any>();
  const [doubtData, setDoubtData] = useState<any>();

  // Get Doubt Data
  const {
    isLoading: isSingleDoubtDataLoading,
    isFetching: isSingleDoubtDataFetching,
    data: singleDoubtData,
  } = useGetServiceTicketByIdQuery(id, {
    skip: !id,
  });

  // Doubts logs
  const {
    data: doubtLogsData,
    isLoading: isDoubtLogsLoading,
    isFetching: isDoubtLogsFetching,
  } = useGetAllCommentOfServiceTicketsQuery(id || "", {
    skip: !id,
  });
  // Single Doubt Logs
  useEffect(() => {
    if (!isDoubtLogsLoading && !isDoubtLogsFetching) {
      setDoubtLogs(doubtLogsData?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doubtLogsData, isDoubtLogsLoading, isDoubtLogsFetching]);

  // single service ticket data
  useEffect(() => {
    if (!isSingleDoubtDataLoading && !isSingleDoubtDataFetching) {
      setDoubtData(singleDoubtData?.data);
    }
  }, [isSingleDoubtDataLoading, isSingleDoubtDataFetching, singleDoubtData]);

  return (
    <SideNavLayout>
      <SingleDoubtView
        doubtData={doubtData}
        doubtsLogListing={doubtLogs}
      />
    </SideNavLayout>
  );
};

export default SingleServiceTicketViewWrapper;
