import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import UserTimeLineCalendar from "./UserTimeLineCalendar";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";

type Props = {
  userData: any;
  setSelectedCodioticUser: any;
  selectedCodioticUser: string;
  activityData: any;
  setDateForTimeline: any
  dateForTimeline: any
};
const UserTimeLineCalendarWrapper = ({
  userData,
  setSelectedCodioticUser,
  selectedCodioticUser,
  setDateForTimeline,
  dateForTimeline,
  activityData,
}: Props) => {

  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } = useGetAllCodioticUsers();

  return (
    <div className="flex flex-col h-full gap-2">
      {userData?.userType === "SUPER_ADMIN" && (
        <div className="flex justify-end w-full px-2 py-1 ">
          {/* Select User */}
          <div className="flex-grow pr-2">
            <ATMSelect
              extraClasses=""
              name=""
              label=""
              placeholder="Select User"
              value={selectedCodioticUser}
              isLoading={isCodioticUserDataLoading}
              options={codioticUsers?.map((ele: CodioticUserListResponse) => {
                return {
                  label: ele?.name,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setSelectedCodioticUser(newValue);
              }}
            />
          </div>
        </div>
      )}

      <div className="flex-grow px-2 py-1">
        <ATMDatePicker
          label=""
          name=""
          format="DD/MM/YYYY"
          value={dateForTimeline}
          onChange={(newValue) => setDateForTimeline(newValue)}
        />
      </div>
      {/* <div className="h-full"> */}
      <UserTimeLineCalendar data={activityData} />
      {/* </div> */}
    </div>
  );
};

export default UserTimeLineCalendarWrapper;
