import React from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import IncidentTypeForm from "../Layouts/IncidentTypeForm";
import { showToast } from "src/utils/showToaster";
import { useAddEventTypeMutation } from "src/services/IncidentTypeService";

type Props = {
  onClose: () => void;
};

export type IncidentTypeFormValues = {
  categoryName: any;
  eventType: string;
  weightage: string;
};

const AddIncidentTypeFormWrapper = ({ onClose }: Props) => { 

  const [addEventType] = useAddEventTypeMutation()

  // Form Initial Values
  const initialValues: IncidentTypeFormValues = {
    categoryName: "",
    eventType: "",
    weightage: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    eventType: string().required("Please enter name"),
    weightage: string().required("Please enter age"),
  });

  // Handle Submit
  const handleSubmit = (
    values: IncidentTypeFormValues,
    { setSubmitting, resetForm }: FormikHelpers<IncidentTypeFormValues>
  ) => {
    const formattedValues ={
      typeName : values?.eventType ,
      categoryId : values?.categoryName?.value ,
      weightage : values?.weightage
    }

    addEventType(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
            resetForm();
            onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <IncidentTypeForm formikProps={formikProps} onClose={onClose} formType='Add' />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddIncidentTypeFormWrapper;
