import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { LeadsFollowupValues } from "src/models/LeadsFollowUp.model";
import LeadFollowupForm from "../Layout/LeadFollowupForm";
import {
  useEditLeadFollowupMutation,
  useGetLeadsFollowupByIdQuery,
} from "src/services/LeadsService";
import moment from "moment";
import { showToast } from "src/utils/showToaster";
import { CircularProgress } from "@mui/material";

type Props = {
  onClose: () => void;
  leadId: string;
  followupId: string;
};

const EditLeadsFollowUpForm = ({ onClose, leadId, followupId }: Props) => {
  const [editLeadFollowup] = useEditLeadFollowupMutation();
  const { data, isLoading, isFetching } =
    useGetLeadsFollowupByIdQuery(followupId);
  const [followupInfo, setFollowupInfo] = useState<any>();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setFollowupInfo(data?.data);
    }
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: LeadsFollowupValues = {
    is_communicated: {
      label: followupInfo?.isCommunicated,
      value: followupInfo?.isCommunicated,
    },
    quotation: followupInfo?.quotation,
    next_followUp_required: followupInfo?.nextFollowupRequired
      ? {
          label: "YES",
          value: "YES",
        }
      : {
          label: "NO",
          value: "NO",
        },
    communication_method: {
      label: followupInfo?.communicationMethod,
      value: followupInfo?.communicationMethod,
    },
    summary_note: followupInfo?.summaryNote || "",
    next_followUp_date: followupInfo?.nextFollowupDate
      ? followupInfo?.nextFollowupDate
      : "",
    next_followup_time: followupInfo?.nextFollowupTime
      ? moment(
          followupInfo?.nextFollowupTime?.split("-")?.[0]?.trim(),
          "hh:mm A"
        )
      : null,
    leads_label: {
      label: followupInfo?.leadLabelName,
      value: followupInfo?.leadLabelName,
    },
    leadId: leadId,
  };

  // Validation Schema
  // Validation Schema
  const validationSchema = object().shape({
    is_communicated: object().required("Please select  is communicated"),
    communication_method: object().required(
      "Please select communication method"
    ),
    summary_note: string().test(
      "address-test",
      "summary note is required",
      (value, context) => {
        const { is_communicated } = context.parent;
        // const pervious = perviousMembershipPrice;
        if (is_communicated?.value === "YES" && !value) {
          return false;
        }
        return true;
      }
    ),
    next_followUp_required: object().test(
      "address-test",
      "Please select next followup is required",
      (value, context) => {
        const { is_communicated } = context.parent;
        // const pervious = perviousMembershipPrice;
        if (is_communicated?.value === "YES" && !value) {
          return false;
        }
        return true;
      }
    ),
    next_followUp_date: string()
      .nullable()
      .test(
        "address-test",
        "next followup date is required",
        (value, context) => {
          const { next_followUp_required } = context.parent;
          // const pervious = perviousMembershipPrice;
          if (next_followUp_required?.value === "YES" && !value) {
            return false;
          }
          return true;
        }
      ),
    next_followup_time: string()
      .nullable()
      .test(
        "address-test",
        "next followup time is required",
        (value, context) => {
          const { next_followUp_required } = context.parent;
          // const pervious = perviousMembershipPrice;
          if (next_followUp_required?.value === "YES" && !value) {
            return false;
          }
          return true;
        }
      ),
    leads_label: object().test(
      "address-test",
      "Please select leads label",
      (value, context) => {
        const { is_communicated } = context.parent;
        // const pervious = perviousMembershipPrice;
        if (is_communicated?.value === "YES" && !value) {
          return false;
        }
        return true;
      }
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeadsFollowupValues,
    { setSubmitting, resetForm }: FormikHelpers<LeadsFollowupValues>
  ) => {
    const formattedValues = {
      leadId: values?.leadId,
      quotation: values?.quotation,
      communicationMethod: values?.communication_method?.value || "",
      isCommunicated: values?.is_communicated?.value || "",
      nextFollowupRequired:
        values?.next_followUp_required?.value === "YES" ? true : false,
      summaryNote: values?.summary_note,
      nextFollowupDate: values?.next_followUp_date
        ? moment(values?.next_followUp_date).format("yyyy-MM-DD")
        : "",
      nextFollowupTime: values?.next_followup_time
        ? moment(values?.next_followup_time).format("hh:mm A")
        : "",
      leadLabelName: values?.leads_label?.value,
    };
    editLeadFollowup({
      id: followupId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <LeadFollowupForm
            formikProps={formikProps}
            onClose={onClose}
            formType="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditLeadsFollowUpForm;
