import React from "react";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { FormikProps } from "formik";
import { FormInitialValues } from "./AllTaskFilterFormCardDialogWrapper";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

// MODELS
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import { ProjectListResponse } from "src/models/Project.model";
import { ModulesListResponse } from "src/models/Module.model";
import { FeatureListResponseType } from "src/models/Feature.model";

// HOOKS
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { useGetAllLayerProject } from "src/hooks/useGetAllLayerProject";
import { useGetAllModulesByProject } from "src/hooks/useGetAllModulesByProject";
import { useGetAllFeatureByModule } from "src/hooks/useGetAllFeatureByModule";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  AllLabels: any;
  onClose: () => void;
};

const taskAssignmentStatusOptions = [
  {
    label: "ASSIGNED",
    value: "ASSIGNED",
  },
  {
    label: "UNASSIGNED",
    value: "PENDING",
  },
];

const taskStatusOption = [
  {
    label: "OPEN",
    value: "OPEN",
  },
  {
    label: "TODO",
    value: "TODO",
  },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "BUGS",
    value: "BUGS",
  },
  {
    label: "BLOCKING",
    value: "BLOCKING",
  },
  {
    label: "UAT_REVIEW",
    value: "UAT_REVIEW",
  },
  {
    label: "DONE",
    value: "DONE",
  },
  {
    label: "HOLD",
    value: "HOLD",
  },
  {
    label: "ISSUE",
    value: "ISSUE",
  },
  {
    label: "COMPLETE",
    value: "COMPLETE",
  },
  {
    label: "CLOSE",
    value: "CLOSE",
  },
];

const AllTaskFilterFormCardDialog = ({
  open,
  formikProps,
  onReset,
  AllLabels,
  onClose,
}: Props) => {
  
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;
  // get all codiotic user hook
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  // get module by project id hook
  const { modules, isDataLoading: isModulesDataLoading } =
    useGetAllModulesByProject(values?.project?.value);

  // get feature by module id hook
  const { features, isDataLoading: isFeaturesDataLoading } =
    useGetAllFeatureByModule(values?.module?.value);

  // get all layer of project
  const { allProjectLayer, isDataLoading: isLayersDataLoading } =
    useGetAllLayerProject();

  // Redux state for login user data
  const { userData } = useSelector((state: RootState) => state?.auth);
 

  return (
    <MOLFilterDialog
      title={"Filters"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      clearFilter={onReset}
      handleSubmit={handleSubmit}
      open={open}
    >
      <div className="flex flex-col gap-4">
        {/* User */}
        <div>
          <ATMSelect
            name="codioticUser"
            label="User"
            placeholder="Select User"
            value={values.codioticUser}
            isLoading={isCodioticUserDataLoading}
            options={codioticUsers?.map((ele: CodioticUserListResponse) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return (
                  <div className="flex capitalize gap-x-3">
                    {option.label}
                    {option?.value === userData?.userId && (
                      <span className="bg-green-500 py-[2px] flex items-center px-[8px] text-center rounded-full text-white text-[10px]">
                        It's you
                      </span>
                    )}
                  </div>
                );
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            onChange={(newValue) => {
              setFieldValue("codioticUser", newValue);
            }}
          />
        </div>

        {/* Project Label */}
        <div>
          <ATMSelect
            name="label.labelName"
            label="Label"
            placeholder="Select Label"
            value={values?.label}
            // options={AllLabels}
            options={AllLabels?.map((ele: any) => {
              return {
                label: ele?.labelName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              // setFieldValue("label.labelName", newValue);
              setFieldValue("label", newValue);
            }}
          />
        </div>

        {/* Project Layer */}
        <div>
          <ATMSelect
            name="layer.layerName"
            label="Project Layers"
            placeholder="Select Layer"
            value={values?.layer}
            isLoading={isLayersDataLoading}
            options={allProjectLayer?.map((ele: any) => {
              return {
                label: ele?.layerName?.toUpperCase(),
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("layer", newValue);
            }}
          />
        </div>

        {/* Status */}
        <div>
          <ATMSelect
            name="status"
            label="Status"
            placeholder="Select Status"
            value={values.status}
            options={taskStatusOption}
            onChange={(newValue) => {
              setFieldValue("status", newValue);
            }}
          />
        </div>

        {/* Task Assigned Status */}
        <div>
          <ATMSelect
            name="taskAssignmentStatus"
            label="Task Assigned Status"
            placeholder="Select Task Assigned Status"
            value={values?.taskAssignmentStatus}
            options={taskAssignmentStatusOptions}
            onChange={(newValue) => {
              setFieldValue("taskAssignmentStatus", newValue);
            }}
          />
        </div>

        {/* Tickets */}
        <div>
          <ATMSelect
            name="createdBy"
            label="Created By"
            placeholder="Select Created By User"
            value={values.createdBy}
            isLoading={isCodioticUserDataLoading}
            options={codioticUsers?.map((ele: CodioticUserListResponse) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return (
                  <div className="flex capitalize gap-x-3">
                    {option.label}
                    {option?.value === userData?.userId && (
                      <span className="bg-green-500 py-[2px] flex items-center px-[8px] text-center rounded-full text-white text-[10px]">
                        It's you
                      </span>
                    )}
                  </div>
                );
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            onChange={(newValue) => {
              setFieldValue("createdBy", newValue);
            }}
          />
        </div>

        {/* Due Date filter */}
        <div className="w-full">
          <ATMDatePicker
            label="Due date"
            name="dueDate"
            format="DD/MM/YYYY"
            value={values.dueDate}
            onChange={(newValue) => setFieldValue("dueDate", newValue)}
          />
        </div>

        <div>
          <ATMSelect
            name="project"
            label="Project"
            placeholder="Select Project"
            value={values.project}
            isLoading={isAllProjectsDataLoading}
            options={allProjects?.map((ele: ProjectListResponse) => {
              return {
                label: ele?.projectName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("project", newValue);
            }}
          />
        </div>

        {/* Modules by project id */}
        <div>
          <ATMSelect
            name="module"
            label="Module"
            placeholder="Select Module"
            value={values.module}
            isLoading={isModulesDataLoading}
            options={modules?.map((ele: ModulesListResponse) => {
              return {
                label: ele?.moduleName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("module", newValue);
            }}
          />
        </div>

        {/* Feature by module id */}
        <div>
          <ATMSelect
            name="feature"
            label="Feature"
            placeholder="Select Feature"
            value={values.feature}
            isLoading={isFeaturesDataLoading}
            options={features?.map((ele: FeatureListResponseType) => {
              return {
                label: ele?.featureName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("feature", newValue);
            }}
          />
        </div>
        {/* Archived  */}
        <div className="">
          <ATMCheckbox
            label="Archived Task"
            name="isArchived"
            checked={values.isArchived}
            onChange={(checked) => {
              setFieldValue("isArchived", checked);
            }}
          />
        </div>
      </div>
    </MOLFilterDialog>
  );
};

export default AllTaskFilterFormCardDialog;
