import apiSlice from "./ApiSlice";

export const serverApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllServerWithPagination: builder.query({
      providesTags: ["server"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/server",
          method: "POST",
          body,
        };
      },
    }),

    // Get
    getServer: builder.query({
      providesTags: ["server"],
      query: () => {
        return {
          url: "/server",
          method: "GET",
        };
      },
    }),

    // Add
    addServer: builder.mutation({
      invalidatesTags: ["server"],
      query: (body) => {
        return {
          url: "/server/add",
          method: "POST",
          body,
        };
      },
    }),


    // Get the Git Repo  by id
    getServerById: builder.query({
      providesTags: ["server"],
      query: (id: string) => {
        return {
          url: `/server/${id}`,
          method: "GET",
        };
      },
    }),

    
    // Single Product Delete 
    deleteServerById: builder.mutation({
      invalidatesTags: ["server"],
      query: (id: any) => ({
        url: `/server/${id}`,
        method: "DELETE",
      }),
    }),
    // Update Git Repo
    updateServerById: builder.mutation({
      invalidatesTags: ["server"],
      query: ({ id, body }) => {
        return {
          url: `/server/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //
  }),
});

export const {
  useGetAllServerWithPaginationQuery,
  useAddServerMutation,
  useGetServerByIdQuery,
  useDeleteServerByIdMutation,
  useUpdateServerByIdMutation,
  useGetServerQuery,
} = serverApi;
