import React from 'react'
import ProjectAddResource from './ProjectAddResource'
import { Form, Formik, FormikHelpers } from 'formik'
import { Dialog } from '@mui/material'
import { showToast } from 'src/utils/showToaster'
import { object, string } from 'yup'
import { useAddProjectResourceRequestMutation } from 'src/services/ResourceRequestService'

type Props = {
  onClose: any
  projectId : any
}
const ProjectAddResourceWrapper = ({ onClose, projectId }: Props) => {

  const [addProjectResource] = useAddProjectResourceRequestMutation()
  // Form Initial Values
  const initialValues: any = {
    description: "",
    resourceName: "",
    type: "",
    projectId :projectId,
    remark : "",
    internalResourceFor : "",

  };

  // Validation Schema
  const validationSchema = object().shape({
    description: string().required("Please enter description"),
    resourceName: string().required("Please enter resource name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    const { internalResourceFor, type  , ...rest } = values;
    const formattedValues = {
      ...rest,
      internalResourceFor: values.internalResourceFor ?  values.internalResourceFor?.map((ele: any) => ele?.value) : [],
      type : values?.type ? values?.type?.value : "PUBLIC"
    };
    addProjectResource(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };


  return (
    <>
      <Dialog open maxWidth="md" fullWidth >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <ProjectAddResource
                formikProps={formikProps}
                onClose={onClose}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default ProjectAddResourceWrapper
