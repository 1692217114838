import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { LeaveRequestFormValues } from "src/models/LeaveReques.model";
import { useAddLeaveRequestServiceMutation } from "src/services/LeaveRequestService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import HalfDayLeaveRequestForm from "../Layouts/HalfDayLeaveRequestForm";

type Props = {
  handleClose: any;
};

const AddHalfDayLeaveRequestFormWrapper = ({ handleClose }: Props) => {
  // get  members
  const { codioticUsers, isDataLoading } = useGetAllCodioticUsers();

  const [addLeaveRequest] = useAddLeaveRequestServiceMutation();
  // Form Initial Values
  const initialValues: LeaveRequestFormValues = {
    reason: "",
    leaveStartDate: "",
    reportingDate: "",
    leaveType: "PLANNED",
    daysOfLeave: "HALF_DAY",
    dayPart: "",
    leavingTime: "",
    arrivalTime: "",
    approvals: null,
    buddy: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    dayPart: object().required("Please select day part"),
    leaveStartDate: string().required("Please enter date"),
    arrivalTime: string().test(
      "arrTimeTest",
      "Please select arrival time",
      (value, context) => {
        const { dayPart } = context.parent;
        return (dayPart?.value === "FIRST_HALF" ||
          dayPart?.value === "FOR_HOURS") &&
          value
          ? true
          : dayPart?.value === "SECOND_HALF"
            ? true
            : false;
      }
    ),
    leavingTime: string().test(
      "arrTimeTest",
      "Please select leaving time",
      (value, context) => {
        const { dayPart } = context.parent;
        return (dayPart?.value === "SECOND_HALF" ||
          dayPart?.value === "FOR_HOURS") &&
          value
          ? true
          : dayPart?.value === "FIRST_HALF"
            ? true
            : false;
      }
    ),
    buddy: object().required("Please select buddy"),
    reason: string().required("Please enter reason"),
    approvals: object().required("Please select buddy"),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeaveRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<LeaveRequestFormValues>
  ) => {
    const {
      leaveType,
      approvals,
      buddy,
      leaveStartDate,
      reportingDate,
      dayPart,
      ...rest
    } = values;

    const formattedValues = {
      ...rest,
      leaveType: leaveType,
      dayPart: dayPart?.value,
      approvals: [approvals?.value],
      leaveStartDate: moment(leaveStartDate).format("YYYY-MM-DD"),
      reportingDate: moment(reportingDate).format("YYYY-MM-DD"),
      buddy: buddy?.value,
    };

    addLeaveRequest(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <HalfDayLeaveRequestForm
              handleClose={handleClose}
              formikProps={formikProps}
              codioticUsers={codioticUsers}
              isDataLoading={isDataLoading}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddHalfDayLeaveRequestFormWrapper;
