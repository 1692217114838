import { Form, Formik, FormikHelpers } from "formik";
import { ModuleFormValues } from "src/models/Module.model";
import { useAddModuleMutation } from "src/services/ModuleServices";
import { object, string } from "yup";
import ModuleForm from "../Layouts/ModuleForm";
import { showToast } from "src/utils/showToaster";
import { useState } from "react";

type Props = {
  onClose: () => void;
  projectItems?: any;
  projectValueModule?: any;
};

const AddModuleFormWrapper = ({
  onClose,
  projectItems,
  projectValueModule,
}: Props) => {
  const [addModule] = useAddModuleMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  // Form Initial Values
  const initialValues: ModuleFormValues = {
    project: projectValueModule
      ? {
          label: projectValueModule?.projectName,
          value: projectValueModule?._id,
        }
      : "",
    moduleName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    project: object().required("Please select project"),
    moduleName: string().required("Please enter module name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ModuleFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ModuleFormValues>
  ) => {
    const { project, ...rest } = values;
    const formattedValues = { ...rest, projectId: project?.value || "" };
    addModule(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ModuleForm
            projectItems={projectItems}
            formType="ADD"
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddModuleFormWrapper;
