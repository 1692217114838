import React, { useEffect, useState } from "react";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { useLocation, useNavigate } from "react-router-dom";
import DesputedIncidentListingWrapper from "./DesputedIncident/List/DesputedIncidentListingWrapper";
import IncidentListingWrapper from "./Incident/List/IncidentListingWrapper";
import ClarificationIncidentListingWrapper from "./ClarificationIncident/List/ClarificationIncidentListingWrapper";
import UnhandledIncidentListingWrapper from "./UnhandledIncident/List/UnhandledIncidentListingWrapper";
import ATMTab from "src/components/UI/atoms/ATMTab/ATMTab";
import { CiBasketball } from "react-icons/ci";
import { RxCrossCircled } from "react-icons/rx";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useGetPointerCounterQuery } from "src/services/IncidentService";

const IncidentLayout = () => { 

  const [countData , setCountData] = useState<any>()

  const navigate = useNavigate();
  const location = useLocation();

  // TO GET TAB VALUE
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("tab");

  const {data , isLoading , isFetching} = useGetPointerCounterQuery('')

  useEffect(()=>{
    setCountData(data?.data)
  },[data , isLoading , isFetching])


  React.useEffect(() => {
    if (activeTab === null) {
      if (isAuthorized(UserModuleNameTypes.ACTION_INCIDENT_ALL_INCIDENT)) {
        navigate("/incident/?tab=incident");
      } else if (
        isAuthorized(UserModuleNameTypes.ACTION_INCIDENT_DESPUTED_INCIDENT)
      ) {
        navigate("/incident/?tab=desputed");
      } 
      else if (
        isAuthorized(UserModuleNameTypes.ACTION_INCIDENT_CLARIFICATION_INCIDENT)
      ) {
        navigate("/incident/?tab=clarification");
      } 
      else {
        navigate("/incident/?tab=unhandled");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 

  const renderTabContent = () => {
    switch (activeTab) {
      case "desputed":
        return <DesputedIncidentListingWrapper/>  ;
      case "clarification":
        return <ClarificationIncidentListingWrapper/> ;
        case "unhandled":
            return <UnhandledIncidentListingWrapper/> ;
      default:
        return (
         <IncidentListingWrapper/>
        );
    }
  };


  const tab :any[]=[
    {
      label:'All Incident',
      icon :CiBasketball ,
      path : 'incident',
      onClick: () => {
        navigate("/incident/?tab=incident");
      },
      name: UserModuleNameTypes.ACTION_INCIDENT_ALL_INCIDENT,
    } ,
    {
      label:'Desputed',
      icon :RxCrossCircled ,
      count :countData?.disputedCount || '0',
      path : 'desputed',
      onClick: () => {
        navigate("/incident/?tab=desputed");
      },
      name: UserModuleNameTypes.ACTION_INCIDENT_DESPUTED_INCIDENT,
    },
    {
      label:'Clarification Request',
      icon :AiOutlineExclamationCircle ,
      count :countData?.clarificationCount || '0',
      path : 'clarification',
      onClick: () => {
        navigate("/incident/?tab=clarification");
      },
      name: UserModuleNameTypes.ACTION_INCIDENT_CLARIFICATION_INCIDENT,
    },
    {
      label:'Unhandled',
      icon :IoMdCheckmarkCircleOutline ,
      count :countData?.unhandledCount || '0',
      path : 'unhandled',
      onClick: () => {
        navigate("/incident/?tab=unhandled");
      },
      name: UserModuleNameTypes.ACTION_INCIDENT_UNHANDLED_INCIDENT,
    }

  ]

  return (
    <SideNavLayout>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="sticky top-0 md:p-4 p-2 bg-white z-10 md:flex block ">
        <div className="">
                <ATMTab
                  tabs={tab}
                  selected={(tab: any) => tab.path === activeTab}
                  childrenLabel={activeTab}
                  children={<></>}
                />
              </div>
          {/* <div className="flex gap-4 md:mb-0 mb-2">
            {isAuthorized(UserModuleNameTypes.ACTION_INCIDENT_ALL_INCIDENT) && (
              <p
                onClick={() => {
                  navigate("/incident/?tab=incident");
                }}
                className={`${
                  activeTab === "incident"
                    ? " text-cyan-500 border-cyan-500 font-semibold border-b-2 cursor-pointer"
                    : " text-black font-medium"
                } w-24 text-center cursor-pointer`}
              >
                All Incident
              </p>
            )}
            {isAuthorized(UserModuleNameTypes.ACTION_INCIDENT_DESPUTED_INCIDENT) && (
              <p
                onClick={() => {
                  navigate("/incident/?tab=desputed");
                }}
                className={`${
                  activeTab === "desputed"
                    ? "border-b-2 cursor-pointer text-amber-600  font-semibold border-amber-600"
                    : "bg-white text-black font-medium"
                } w-20 text-center cursor-pointer`}
              >
                Desputed
              </p>
            )} 
             {isAuthorized(UserModuleNameTypes.ACTION_INCIDENT_CLARIFICATION_INCIDENT) && (
              <p
                onClick={() => {
                  navigate("/incident/?tab=clarification");
                }}
                className={`${
                  activeTab === "clarification"
                    ? "border-b-2 cursor-pointer text-green-700  font-semibold border-green-700"
                    : "bg-white text-black font-medium"
                } w-44 text-center cursor-pointer`}
              >
             Clarification Request
              </p>
            )}
            {isAuthorized(UserModuleNameTypes.ACTION_INCIDENT_UNHANDLED_INCIDENT) && (
              <p
                onClick={() => {
                  navigate("/incident/?tab=unhandled");
                }}
                className={`${
                  activeTab === "unhandled"
                    ? "text-red-500 border-b-2 font-semibold border-red-500"
                    : "bg-white text-black font-medium"
                } w-[90px] text-center cursor-pointer`}
              >
              Unhandled
              </p>
            )}
             
          </div> */}
        </div>
        {renderTabContent()}

      
      </div>
    </SideNavLayout>
  );
};

export default IncidentLayout;
