import moment from "moment";
import React from "react";
import { CiEdit } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { CiFileOn } from "react-icons/ci";

const Field = ({ label, value }: any) => {
  return (
    <div className="flex justify-between items-center">
      <span className="text-neutral font-medium text-sm">{label}</span>
      <span className=" text-sm">{value}</span>
    </div>
  );
};

const Section = ({ heading, extraClass }: any) => {
  return (
    <p className={`border-b p-2 text-md font-medium mx-2 ${extraClass}`}>
      {heading}
    </p>
  );
};

const FileImage = ({ label, url }: any) => {
  return (
    <div className="border shadow rounded-lg ">
      <div className="p-3  flex gap-2 items-center">
        <CiFileOn size="1.4em" />
        <div
          className="cursor-pointer font-medium text-slate-600"
          onClick={() => {
            window.open(`${url}`, "_blank");
          }}
        >
          {label}.jpg
        </div>
      </div>
    </div>
  );
};

const Details = ({ userDetails, userId }: any) => {
  const navigate = useNavigate();

  return (
    <div className=" p-4 rounded-md bg-gray-100">
      {/* Profile */}
      <div className="flex justify-between border-b  p-4 bg-white rounded-lg">
        {/* Avatar  */}
        <div className="flex gap-5 items-center">
          <div className=" flex justify-center items-center font-bold ">
            <img
              alt="profile"
              className="h-[64px] w-[64px] rounded-full border-2 border-primary-main"
              src={userDetails?.passportSizePhotoUrl || ""}
            />
          </div>
          <div className="flex flex-col">
            <span className="text-md font-medium mt-2 capitalize">
              {userDetails?.name}{" "}
            </span>
            <span className="text-slate-600 text-sm font-medium">
              {userDetails?.employeeId}
            </span>
          </div>
        </div>
        {/* Edit Details */}
        <div className="flex justify-end items-center">
          <div
            onClick={() => {
              navigate(`/profile/${userId}`);
            }}
            className="bg-slate-100 text-sm text-slate-600 px-2 py-1 rounded-3xl flex gap-1 items-center cursor-pointer hover:bg-primary-main hover:text-white font-medium"
          >
            <CiEdit size="1.4em" /> Edit Details
          </div>
        </div>
      </div>
      {/* Personal Details */}
      <div className="shadow mt-3 bg-white rounded-lg">
        <Section heading={"Personal Details"} extraClass="text-teal-600" />
        <div className="grid grid-cols-12 md:gap-8 xs:gap-4 md:py-0 xs:py-4">
          <div className="xl:col-span-4  md:col-span-6 xs:col-span-12  md:py-4">
            <div className="flex flex-col gap-4 px-4">
              <Field
                label={"Father Name"}
                value={userDetails?.fatherName || "-"}
              />
              <Field
                label={"Email"}
                value={userDetails?.personalEmail || "-"}
              />
              <Field label={"Mobile"} value={userDetails?.mobile || "-"} />
              <Field
                label={"Date Of Birth"}
                value={
                  userDetails?.dobAsPerDocument
                    ? moment(userDetails?.dobAsPerDocument).format(
                        "DD MMM YYYY"
                      )
                    : "-"
                }
              />
            </div>
          </div>
          <div className=" xl:col-span-4  md:col-span-6 xs:col-span-12 md:py-4 ">
            <div className="flex flex-col gap-4 px-4">
              <Field
                label={"Joining Date"}
                value={
                  userDetails?.joiningDate
                    ? moment(userDetails?.joiningDate).format("DD MMM YYYY")
                    : "-"
                }
              />
              <Field
                label={"Blood Group"}
                value={userDetails?.bloodGroup || "-"}
              />
              <Field
                label={"Marital Status"}
                value={userDetails?.maritalStatus || "-"}
              />
              <Field
                label={"Emergency Contact No"}
                value={userDetails?.emergencyContactNo || "-"}
              />
            </div>
          </div>
          <div className=" xl:col-span-4  md:col-span-6 xs:col-span-12 md:py-4 ">
            <div className="flex flex-col gap-4 px-4">
              <Field
                label={"Skills"}
                value={
                  <div className="flex gap-1">
                    {userDetails?.skills?.map((el: any, ind: number) => (
                      <div className="font-medium text-[12px] ">{`${el?.skillName} ${
                        ind !== el?.skillName?.length - 1 ? "," : ""
                      }`}</div>
                    ))}{" "}
                  </div>
                }
              />
              <Field
                label={"Medical History"}
                value={userDetails?.medicalHistory || "-"}
              />
              <Field label={"Allergic"} value={userDetails?.allergic || "-"} />
             
            </div>
          </div>
        </div>
      </div>

      {/* Bank Details , Address */}
      <div className="grid grid-cols-12 gap-6">
        {/* Job Details */}
        <div className="xl:col-span-4  md:col-span-6 xs:col-span-12 shadow mt-3 bg-white rounded-lg">
          <Section heading={"Job Details"} extraClass="text-cyan-600" />
          <div className="flex flex-col gap-4 px-3 py-4">
            <Field
              label={"Job Title"}
              value={userDetails?.positionName || "-"}
            />
            <Field label={"Job Type"} value={userDetails?.jobType || "-"} />
            <Field
              label={"Department"}
              value={userDetails?.departmentName || "-"}
            />
            <Field label={"Work Email"} value={userDetails?.email || "-"} />
          </div>
        </div>
        {/* Bank Details */}
        <div className="xl:col-span-4  md:col-span-6 xs:col-span-12 shadow mt-3 bg-white rounded-lg">
          <Section heading="Bank Details" extraClass="text-amber-700	" />
          <div className="flex flex-col gap-4 px-4 py-4">
            <Field label={"Bank Name"} value={userDetails?.bankName || "-"} />
            <Field
              label={"Account Number"}
              value={userDetails?.accountNumber || "-"}
            />
            <Field
              label={"IFSC Code"}
              value={userDetails?.bankIfscCode || "-"}
            />
            <Field
              label={"Branch"}
              value={userDetails?.bankBranchName || "-"}
            /> 
             {userDetails?.cancelChequeUrl && (
                    <Field
                      label={"Cancel Cheque"}
                      value={
                        <FileImage
                          label="Cancel Cheque"
                          url={userDetails?.cancelChequeUrl || ""}
                        />
                      }
                    />
                  )}
          </div>
        </div>
        {/* Address */}
        <div className="xl:col-span-4  md:col-span-6 xs:col-span-12 shadow mt-3 bg-white rounded-lg">
          <Section heading="Address" extraClass="text-indigo-600" />
          <div className="flex flex-col gap-4 px-3 py-4">
            <Field
              label={"Local Address"}
              value={userDetails?.localAddress || "-"}
            />
            <Field
              label={"Permanent Address"}
              value={userDetails?.permanentAddress || "-"}
            />
          </div>
        </div>
      </div>
      {/* Work History */}
      <div className="shadow mt-3 bg-white rounded-lg">
        <Section heading={"Work History"} extraClass="text-sky-600" />
        <div className="grid grid-cols-12 gap-6 p-4">
          {userDetails?.workHistory?.map((el: any, ind: any) => {
            return (
              <div className=" xl:col-span-4  md:col-span-6 xs:col-span-12 py-4 border shadow rounded-lg">
                <div className="flex flex-col gap-4 px-4">
                  <Field
                    label={"Company Name"}
                    value={el?.companyName || "-"}
                  />
                  <Field label={"Job Title"} value={el?.jobTitle || "-"} />
                  <Field
                    label={"Star Year"}
                    value={el?.starYear ? moment(el?.starYear).year() : "-"}
                  />
                  <Field
                    label={"End Year"}
                    value={el?.endYear ? moment(el?.endYear).year() : "-"}
                  />
                {el?.workExperienceCertificate && (
                  el?.workExperienceCertificate?.map((ele:any , ind:any)=>{
                    return(
                      <Field
                      label={`Work Experience Certificate ${ind + 1}`}
                      value={
                        <FileImage
                          label={`Certificate ${ind + 1}`}
                          url={ele || ""}
                        />
                      }
                    />
                    )
                  })
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Academic History */}
      <div className="shadow mt-3 bg-white rounded-lg">
        <Section heading={"Academic History"} extraClass="text-fuchsia-600" />
        <div className="grid grid-cols-12 gap-6 p-4">
          {userDetails?.academicHistory?.map((el: any, ind: any) => {
            return (
              <div className=" xl:col-span-4  md:col-span-6 xs:col-span-12 py-4 border shadow rounded-lg">
                <div className="flex flex-col gap-4 px-4">
                  <Field
                    label={"Collge School Name"}
                    value={el?.collgeSchoolName || "-"}
                  />
                  <Field label={"Course Name"} value={el?.courseName || "-"} />
                  <Field label={"Course Type"} value={el?.courseType || "-"} />
                  <Field
                    label={"University Board"}
                    value={el?.universityBoard || "-"}
                  />
                  <Field
                    label={"Collge School Tier"}
                    value={el?.collgeSchoolTier || "-"}
                  />
                  <Field
                    label={"Star Year"}
                    value={el?.starYear ? moment(el?.starYear).year() : "-"}
                  />
                  <Field
                    label={"End Year"}
                    value={el?.endYear ? moment(el?.endYear).year() : "-"}
                  />
                  {el?.EducationCertificate && (
                    <Field
                      label={"Education Certificate"}
                      value={
                        <FileImage
                          label="Certificate"
                          url={el?.EducationCertificate || ""}
                        />
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Documents */}
      <div className="shadow mt-3 bg-white rounded-lg">
        <Section heading={"Documents"} extraClass="text-orange-600" />
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-6 p-4">
         
          {userDetails?.identityProofUrl && (
            <FileImage
              label="Identity Card"
              url={userDetails?.identityProofUrl || ""}
            />
          )}
          {userDetails?.PermanentAddressProofUrl && (
            <FileImage
              label="Permanent Address Proof"
              url={userDetails?.PermanentAddressProofUrl || ""}
            />
          )}
          {userDetails?.localAddressProofUrl && (
            <FileImage
              label="Local Address Proof"
              url={userDetails?.localAddressProofUrl || ""}
            />
          )}
          {userDetails?.passportSizePhotoUrl && (
            <FileImage
              label="Passport Size Photo"
              url={userDetails?.passportSizePhotoUrl || ""}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
