/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import ExpensesListFilterFormDialog from "./MeetingNotesListFilterFormDialog";
import { setMeetingId, setProjectId, setDateFilter, setMeetingTagId } from "src/redux/slices/MeetingNotesSlice";
type Props = {
  open: boolean;
  onClose: () => void;
};

const MeetingnNotesListFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const meetingState = useSelector((state: RootState) => state.meetingNotes);
  const {
    dateFilter,
    meetingId,
    projectId,
    meetingType,
    meetingTag
  } = meetingState;

  const initialValues: any = {
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
    meetingType: meetingType,
    meetingId: meetingId,
    projectId: projectId,
    meetingTag: meetingTag
  };


  // Submit Handler
  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    setSubmitting(false);

    // created date from to dispatch
    dispatch(
      setDateFilter({
        startDate: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        endDate: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
      })
    );

    // project filter dispatch
    dispatch(setMeetingId(values.meetingId || []));

    dispatch(setProjectId(values?.projectId || []));
    dispatch(setMeetingTagId(values?.meetingTag));

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<any>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        startDate: "",
        endDate: "",
      })
    );

    // project filter dispatch
    await dispatch(setMeetingId([]));
    await dispatch(setProjectId([]));
    await dispatch(setMeetingTagId([]));


    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ExpensesListFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MeetingnNotesListFilterFormDialogWrapper;
