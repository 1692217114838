import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";

import CircularProgress from "@mui/material/CircularProgress";
import { GoalsFormValues } from "src/models/Goals.model";
import {
  useGetGoalsByIdQuery,
  useUpdateGoalsMutation,
} from "src/services/GoalsService";
import SubscriptionForm from "../Layout/GoalForm";
import moment from "moment";

type Props = {
  selectedSubscription: string;
  onClose: () => void;
};

const EditGoalWrapper = ({ selectedSubscription, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetGoalsByIdQuery(selectedSubscription);

  const [updateExpense] = useUpdateGoalsMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data || {});
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: GoalsFormValues = {
    goalCategoryId: {
      label: items?.goalCategoryName,
      value: items?.goalCategoryId,
    },
    deadline: items?.deadline,
    type: items?.type,
    name: items?.name || "",
    target: items?.target,
    members: items?.membersInvolved?.map((ele: any) => {
      return {
        label: ele?.memberName,
        value: ele?.memberId,
      };
    }),
    measuredBy: {
      label: items?.measuredByName,
      value: items?.measuredById,
    },
  };

  // Validation Schema
  const validationSchema = object().shape({
    deadline: string().required("Please enter deadline date"),
    name: string().required("Please enter name"),
    type: string().required("Please select type"),
    goalCategoryId: object().required("Please select goal category"),
    measuredBy: object().required("Please select measured"),
    members: array()
      .min(1, "Please select member")
      .required("Please select member"),
  });
  const handleSubmit = (
    values: GoalsFormValues,
    { setSubmitting, resetForm }: FormikHelpers<GoalsFormValues>
  ) => {
    const formattedValues = {
      goalCategoryId: values?.goalCategoryId?.value,
      deadline: values?.deadline
        ? moment(values?.deadline).format("YYYY-MM-DD")
        : "",
      type: values?.type,
      name: values?.name,
      target: values?.target,
      measuredById: values?.measuredBy?.value,
      membersInvolved: values?.members?.map((ele: any) => ele?.value),
    };

    updateExpense({
      id: selectedSubscription,
      body: formattedValues,
    }).then((res: any) => {
      updateExpense({
        id: selectedSubscription,
        body: formattedValues,
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <SubscriptionForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditGoalWrapper;
