import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsTableLoading,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems
} from "src/redux/slices/MeetingNotesSlice";
import { RootState } from "src/redux/store";
import { useGetMeetingNotesQuery } from "src/services/MeetingServices";
import MeetingNotesList from "./MeetingNotesList";

type Props = {};

const MeetingNotesListWrapper = (props: Props) => {
  const { totalItems, page, rowsPerPage, searchValue, isTableLoading, meetingId, projectId, meetingTag, dateFilter } =
    useSelector((state: RootState) => state.meetingNotes);

  const [meetingNotes, setMeetingNotes] = useState<any[]>([]);
  const dispatch = useDispatch();

  const {
    isLoading: isNotesLoading,
    isFetching: isNotesFetching,
    data: notesData,
  } = useGetMeetingNotesQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "meetingId",
      "clientUserId",
      "notes",
      "createdById",
      "createdByUserName",
      "isDeleted",
      "isActive",
    ],
    page: page,
    filterBy: [
      {
        fieldName: "projectId",
        value: projectId?.value ? [projectId?.value] : [],
      },
      {
        fieldName: "meetingId",
        value: meetingId?.value ? [meetingId?.value] : [],
      },
      {
        fieldName: "meetingNoteTypeId._id",
        value: meetingTag?.value ? [meetingTag?.value] : [],
      }
    ],
    dateFilter: dateFilter,
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isNotesLoading && !isNotesFetching) {
      setMeetingNotes(notesData?.data);
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(notesData?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotesLoading, isNotesFetching, notesData]);

  // Setting Module Data

  return (
    <div>
     
      <MeetingNotesList 
      isLoading ={isNotesLoading || isNotesFetching}
        rows={meetingNotes}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default MeetingNotesListWrapper;
