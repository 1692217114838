import apiSlice from "./ApiSlice";

export const RequirementGatheringApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllRequirementGatheringWithPagination: builder.query({
      providesTags: ["requirement-gathering", "platforms"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/platform/",
          method: "POST",
          body,
        };
      },
    }),

    // Add Screen
    addScreen: builder.mutation({
      // invalidatesTags: ["platforms"],
      query: (body) => {
        return {
          url: "/screen/add",
          method: "POST",
          body,
        };
      },
    }),

    // Update Screen By Id
    updateScreenById: builder.mutation({
      // invalidatesTags: ["requirement-gathering"],
      query: ({ id, body }) => {
        return {
          url: `/screen/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Add Feature
    addFunctionalityInnerScreen: builder.mutation({
      // invalidatesTags: ["requirement-gathering"],
      query: (body) => {
        return {
          url: "/functionality/add",
          method: "POST",
          body,
        };
      },
    }),

    // Update Feature Screen By Id
    updateScreenFunctionalityById: builder.mutation({
      // invalidatesTags: ["requirement-gathering"],
      query: ({ id, body }) => {
        return {
          url: `/functionality/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //delete single screen
    deleteScreen: builder.mutation({
      // invalidatesTags: ["requirement-gathering"],
      query: (screenId: any) => {
        return {
          url: `/screen/${screenId}`,
          method: "DELETE",
        };
      },
    }),

    //delete screen inner feature
    deleteScreenFunctionality: builder.mutation({
      // invalidatesTags: ["requirement-gathering"],
      query: (featureId: any) => {
        return {
          url: `/functionality/${featureId}`,
          method: "DELETE",
        };
      },
    }),

    // Workspace Apiss

    // Get All
    getAllWorkspaceWithPagination: builder.query({
      providesTags: ["workspace"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/workspace",
          method: "POST",
          body,
        };
      },
    }),
    getAllWorkspaceWithoutPagination: builder.query({
      providesTags: ["workspace"],
      query: (params) => {
        return {
          url: "/workspace",
          method: "GET",
          params,
        };
      },
    }),

    // Get
    getWorkspace: builder.query({
      providesTags: ["workspace"],
      query: (body) => {
        return {
          url: "/workspace",
          method: "POST",
          body,
        };
      },
    }),

    // Get Module by Id
    getWorkspaceById: builder.query({
      providesTags: ["workspace"],
      query: (id) => {
        return {
          url: `/workspace/${id}`,
          method: "GET",
        };
      },
    }),

    // Add
    addWorkspace: builder.mutation({
      invalidatesTags: ["workspace"],
      query: (body) => {
        return {
          url: "/workspace/add",
          method: "POST",
          body,
        };
      },
    }),

    // Edit
    editWorkspace: builder.mutation({
      // invalidatesTags: ["workspace"],
      query: ({ id, body }) => {
        return {
          url: `/workspace/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // DELETE
    deleteWorkspace: builder.mutation({
      invalidatesTags: ["workspace"],
      query: (id: any) => ({
        url: `/workspace/${id}`,
        method: "DELETE",
      }),
    }),

    // Platforms Apis

    // Get All
    getAllPlatformsWithPagination: builder.query({
      providesTags: ["platforms"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/platform",
          method: "POST",
          body,
        };
      },
    }),

    // Get
    getPlatforms: builder.query({
      providesTags: ["platforms"],
      query: (body) => {
        return {
          url: "/platform",
          method: "POST",
          body,
        };
      },
    }),

    // Get Module by Id
    getPlatformsById: builder.query({
      providesTags: ["platforms"],
      query: (id) => {
        return {
          url: `/platform/${id}`,
          method: "GET",
        };
      },
    }),

    // Add
    addPlatforms: builder.mutation({
      invalidatesTags: ["platforms", "workspace"],
      query: (body) => {
        return {
          url: "/platform/add",
          method: "POST",
          body,
        };
      },
    }),

    // Edit
    editPlatforms: builder.mutation({
      invalidatesTags: ["platforms"],
      query: ({ platformId, body }) => {
        return {
          url: `/platform/${platformId}`,
          method: "PUT",
          body,
        };
      },
    }),

    // DELETE
    deletePlatform: builder.mutation({
      invalidatesTags: ["platforms"],
      query: (id: any) => ({
        url: `/platform/${id}`,
        method: "DELETE",
      }),
    }),

    ///////////////////////////////////////////////// Requirement Gathering \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    // Add Requirement Gathering
    addRequirementGathering: builder.mutation({
      invalidatesTags: ["requirement"],
      query: (body) => {
        return {
          url: "/requirement-gathering/add",
          method: "POST",
          body,
        };
      },
    }),
    listPaginationRequirementGathering: builder.query({
      providesTags: ["requirement"],
      query: (body) => {
        return {
          url: "/requirement-gathering",
          method: "POST",
          body,
        };
      },
    }),
    // Add Requirement Gathering User
    addRequirementGatheringUsers: builder.mutation({
      invalidatesTags: ["requirement"],
      query: (body) => {
        return {
          url: "/requirement-user/add",
          method: "POST",
          body,
        };
      },
    }),
    listPaginationRequirementGatheringUsers: builder.query({
      providesTags: ["requirement"],
      query: (body) => {
        return {
          url: "/requirement-user",
          method: "POST",
          body,
        };
      },
    }),
    // DELETE
    deleteRequirementGatheringUsers: builder.mutation({
      invalidatesTags: ["requirement"],
      query: (id: any) => ({
        url: `/requirement-user/${id}`,
        method: "DELETE",
      }),
    }),
    getRequirementGatheringUsersById: builder.query({
      providesTags: ["requirement"],
      query: (id) => {
        return {
          url: `/requirement-user/${id}`,
          method: "GET",
        };
      },
    }),
    // Edit
    editRequirementGatheringUsers: builder.mutation({
      invalidatesTags: ["requirement"],
      query: ({ Id, body }) => {
        return {
          url: `/requirement-user/${Id}`,
          method: "PUT",
          body,
        };
      },
    }),
     // Add Requirement Gathering User
     addRequirementGatheringPlatforms: builder.mutation({
      invalidatesTags: ["requirement"],
      query: (body) => {
        return {
          url: "/requirement-platform/add",
          method: "POST",
          body,
        };
      },
    }),
    listPaginationRequirementGatheringPlatforms: builder.query({
      providesTags: ["requirement"],
      query: (body) => {
        return {
          url: "/requirement-platform",
          method: "POST",
          body,
        };
      },
    }),
    // DELETE
    deleteRequirementGatheringPlatforms: builder.mutation({
      invalidatesTags: ["requirement"],
      query: (id: any) => ({
        url: `/requirement-platform/${id}`,
        method: "DELETE",
      }),
    }),
    getRequirementGatheringPlatformsById: builder.query({
      providesTags: ["requirement"],
      query: (id) => {
        return {
          url: `/requirement-platform/${id}`,
          method: "GET",
        };
      },
    }),
    // Edit
    editRequirementGatheringPlatforms: builder.mutation({
      invalidatesTags: ["requirement"],
      query: ({ Id, body }) => {
        return {
          url: `/requirement-platform/${Id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // ADD 
    addRequirementGatheringForm: builder.mutation({
      invalidatesTags: ["requirement"],
      query: (body) => {
        return {
          url: "/story/add",
          method: "POST",
          body,
        };
      },
    }),

    getRequirementGatheringForm: builder.query({
      providesTags: ["requirement"],
      query: (body) => {
        return {
          url: "/story",
          method: "POST",
          body,
        };
      },
    }),
    getRequirementGatheringFormById: builder.query({
      providesTags: ["requirement"],
      query: (id) => {
        return {
          url: `/story/${id}`,
          method: "GET",
        };
      },
    }),

     // Edit
     updateRequirementGatheringForm: builder.mutation({
      invalidatesTags: ["requirement"],
      query: ({ Id, body }) => {
        return {
          url: `/story/${Id}`,
          method: "PUT",
          body,
        };
      },
    }),
    
    
    
    //stories navigation
    updateStoryNavigation: builder.mutation({
     invalidatesTags: ["requirement"],
     query: ({ Id, body }) => {
       return {
         url: `/story-nav/${Id}`,
         method: "PUT",
         body,
       };
     },
   }),
    getStoryNavigation: builder.query({
     providesTags: ["requirement"],
     query: (body) => {
       return {
         url: `/story-nav/`,
         method: "POST",
       body
        };
     },
   }),
    deleteStory: builder.mutation({
     invalidatesTags: ["requirement"],
     query: ( Id ) => {
       return {
         url: `/story/${Id}`,
         method: "DELETE",
       };
     },
   }),

  }),
});

export const {
  useGetAllRequirementGatheringWithPaginationQuery,
  useAddScreenMutation,
  useUpdateScreenByIdMutation,
  useAddFunctionalityInnerScreenMutation,
  useUpdateScreenFunctionalityByIdMutation,
  useDeleteScreenMutation,
  useDeleteScreenFunctionalityMutation,

  // WorkSpace Apis

  useGetAllWorkspaceWithPaginationQuery,
  useGetAllWorkspaceWithoutPaginationQuery,
  useAddWorkspaceMutation,
  useEditWorkspaceMutation,
  useGetWorkspaceByIdQuery,
  useDeleteWorkspaceMutation,

  // Platforms Apis

  useGetAllPlatformsWithPaginationQuery,
  useAddPlatformsMutation,
  useEditPlatformsMutation,
  useGetPlatformsByIdQuery,
  useDeletePlatformMutation,

  // Requiremt Gathering
  useAddRequirementGatheringMutation,
  useListPaginationRequirementGatheringQuery,
  useAddRequirementGatheringUsersMutation,
  useListPaginationRequirementGatheringUsersQuery,
  useDeleteRequirementGatheringUsersMutation,
  useGetRequirementGatheringUsersByIdQuery,
  useEditRequirementGatheringUsersMutation,

  useAddRequirementGatheringPlatformsMutation ,
  useDeleteRequirementGatheringPlatformsMutation,
  useEditRequirementGatheringPlatformsMutation ,
  useGetRequirementGatheringPlatformsByIdQuery,
  useListPaginationRequirementGatheringPlatformsQuery ,

  useAddRequirementGatheringFormMutation,
  useGetRequirementGatheringFormQuery,
  useGetRequirementGatheringFormByIdQuery ,
  useUpdateRequirementGatheringFormMutation,

  //
  useUpdateStoryNavigationMutation,
  useGetStoryNavigationQuery,
  useDeleteStoryMutation
} = RequirementGatheringApi;
