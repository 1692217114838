import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserAcess from "../Layout/UserAccess";

import { Form, Formik, FormikHelpers } from "formik";
import { useLocation } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useAddUserAccessMutation, useGetUserAccessQuery } from "src/services/UserAccessServices";
import { showToast } from "src/utils/showToaster";

const AddUserAccessWrapper = () => {
  const location = useLocation()
  const [apiStatus, setApiStatus] = useState(false);
  const dataPrefilled = location?.state
  const navigate = useNavigate();
  const [addUserAccess] = useAddUserAccessMutation();
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetUserAccessQuery({
      userId: dataPrefilled?.userId ? (dataPrefilled?.userId as string) : null,
      positionId: dataPrefilled?.positionId as string,
    },
      {
        // skip: !userData?.companyId,
      });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: any = {
    module: items?.module || [],
    departmentId: {
      label: dataPrefilled?.departmentName,
      value: dataPrefilled?.departmentId,
    },
    positionId: dataPrefilled?.positionName?.replaceAll("_", ' ') || '',
    userId: ''
  };

  // Handle Submit
  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    let modules = values?.module?.map((module: any) => {
      let { _id, moduleAction, ...rest }: any = module;
      let action = moduleAction?.map((action: any) => {
        let { _id, fields, ...rest }: any = action;
        let field = fields?.map((action: any) => {
          let { _id, ...rest }: any = action;
          return { ...rest }
        });
        return { ...rest, fields: [...field] };
      });
      return { ...rest, moduleAction: [...action] };
    });
    const formattedValues = {
      userId: dataPrefilled?.userId ? dataPrefilled?.userId : null,
      positionId: dataPrefilled?.positionId,
      departmentId: values?.departmentId?.value || '',
      module: modules
    }
    setApiStatus(true);
    setTimeout(() => {
      addUserAccess(formattedValues).then((res) => {
        if ("data" in res) {
          if (res?.data?.status) {
            showToast("success", "User Access Added successfully!");
            navigate(`/user-role`);
          } else {
            showToast("error", res?.data?.message);
          }
        } else {
          showToast("error", "Something went wrong");
        }
        setApiStatus(false);
      });
    }, 1000);
  };

  return (
    <SideNavLayout>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <UserAcess
              formikProps={formikProps}
              apiStatus={apiStatus}
              formType={"Add"}
            />
          </Form>
        )}
      </Formik>

    </SideNavLayout>
  );
};

export default AddUserAccessWrapper;
