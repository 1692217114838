import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useState } from "react";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import SubscriptionForm from "../Layout/RecurringPaymentsForm";
import { useAddRecurringPaymentsMutation } from "src/services/SubscriptionService";

type Props = {
  onClose: () => void;
};

export type RecurringPaymentsFormValues = {
  client: any;
  isRecurssion: boolean;
  recurranceType: any;
  amount: string;
  recurranceDay?: any;
  endOfTheMonth: boolean;
  paymentRemark: string;
  expiryDate: any;
  startDate: any;
  taxAmount: any;
  taxPercent: any;
  tdsAmount: string;
  conversionLoss: string;
  currencyId: any;
};

const AddRecurringPaymentsFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);

  const [addRecurringPayment] = useAddRecurringPaymentsMutation();

  // Form Initial Values
  const initialValues: RecurringPaymentsFormValues = {
    client: "",
    isRecurssion: false,
    recurranceType: "",
    amount: "",
    recurranceDay: "",
    endOfTheMonth: false,
    paymentRemark: "",
    expiryDate: null,
    startDate: null,
    taxPercent: 18,
    taxAmount: "",
    tdsAmount: "",
    conversionLoss: "",
    currencyId: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    client: object().required("Please select client"),
    startDate: string().required("Please enter start date"),
    amount: string().test("cost-test", "Please enter amount", (value) => {
      return Number(value || 0) > 0;
    }),
    currencyId: object().required("Please select currency"),
  });

  // Handle Submit
  const handleSubmit = (
    values: RecurringPaymentsFormValues,
    { setSubmitting, resetForm }: FormikHelpers<RecurringPaymentsFormValues>
  ) => {
    const formattedValues = {
      clientId: values?.client?.value,
      isRecurssion: values?.isRecurssion,
      recurranceType: values?.recurranceType
        ? values?.recurranceType?.value
        : "",
      amount: values?.amount,
      recurranceDay:
        values?.recurranceType?.value === "WEEKLY"
          ? values?.recurranceDay?.value
          : values?.recurranceType?.value === "MONTHLY"
            ? moment(values?.recurranceDay).format("DD")
            : values?.recurranceType?.value === "YEARLY"
              ? moment(values?.recurranceDay).format("DD-MM")
              : "",
      endOfTheMonth: values?.endOfTheMonth,
      remark: values?.paymentRemark,
      expiryDate: values?.expiryDate
        ? moment(values?.expiryDate).format("YYYY-MM-DD")
        : "",
      startDate: values?.startDate
        ? moment(values?.startDate).format("YYYY-MM-DD")
        : "",
      currencyId: values?.currencyId?.value,

      tdsAmount: values?.tdsAmount ? values?.tdsAmount : 0,
      conversionLoss: values?.conversionLoss ? values?.conversionLoss : 0,
      taxAmount: values?.taxAmount,
    };

    addRecurringPayment(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <SubscriptionForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddRecurringPaymentsFormWrapper;
