import { ErrorMessage } from "formik";
import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  id?: string | number;
  name?: string;
  checked: boolean;
  onChange: (checked: boolean, value: string) => void;
  value?: string;
  label?: any;
  disabled?: boolean;
  required?: boolean;
  extraClasses?: string;
  inputClasses?: string;
  labelClasses?: string;
};

const ATMCheckbox = ({
  id = "",
  name,
  checked,
  onChange,
  value,
  label,
  disabled = false,
  required = false,
  extraClasses = "",
  inputClasses = "",
  labelClasses = "",
}: Props) => {
  return (
    <div className={twMerge(`relative ${extraClasses}`)}>
      <label
        htmlFor={`${id}`}
        className="inline-flex items-center cursor-pointer"
      >
        <input
          id={`${id}`}
          type="checkbox"
          checked={checked}
          value={value}
          onChange={(e) => onChange(e.target.checked, e.target.value)}
          className={twMerge(`w-5 h-5 rounded text-green-700 cursor-pointer ${inputClasses}`)}
          disabled={disabled}
        />
        {label && (
          <span
            className={twMerge(
              `ml-2  ${disabled ? "text-slate-300" : "text-slate-600"}`,
              `${labelClasses}`
            )}
          >
            {label}
          </span>
        )}
      </label>

      {name && (
        <ErrorMessage name={name}>
          {(errMsg) => (
            <p className="font-poppins absolute text-[14px] text-start mt-0 text-red-500">
              {" "}
              {errMsg}{" "}
            </p>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ATMCheckbox;
