import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetLeaveRequestByIdQuery,
  useUpdateLeaveRequestApprovalStatusMutation,
} from "src/services/LeaveRequestService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import LeaveRequestApprovalFormWrapper from "../RequestApprovalForm/LeaveRequestApprovalFormWrapper";
import ViewLeaveRequest from "./ViewLeaveRequest";

const ViewLeaveRequestWrapper = () => {
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const { id } = useParams();
  const [items, setItems] = useState<any>({});
  const [isShowDisApprovalDialog, setIsShowDisApprovalDialog] = useState(false);
  const [changeLeaveRequestApproval] =
    useUpdateLeaveRequestApprovalStatusMutation();

  const { isLoading, isFetching, data } = useGetLeaveRequestByIdQuery(
    id || "",
    {
      skip: !id,
    }
  );

  const handleLeaveRequestApproval = ({
    values,
    afterSubmit,
  }: {
    values: any;
    afterSubmit?: () => void;
  }) => {
    changeLeaveRequestApproval({
      id: id,
      body: values,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          afterSubmit?.();
        } else {
          showToast("error", res?.data?.message);
        }
      }
    });
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  return (
    <>
      {(isLoading || isFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <ViewLeaveRequest
        items={items}
        onRejectClick={() => setIsShowDisApprovalDialog(true)}
        onApproveClick={() => {
          showConfirmationDialog({
            title: "Attention",
            text: "Are you sure want to approve this Leave Request?",
            icon: "question",
            showCancelButton: true,
            next: (result) => {
              if (result?.isConfirmed) {
                handleLeaveRequestApproval({
                  values: {
                    approvalStatus: "APPROVED",
                    declinedReason: "",
                  },
                });
              }
            },
          });
        }}
        isShowRejectApproveBtn={
         items?.approvals?.findIndex(
              (approval: any) =>
                approval?.approvedBy === userData?.userId &&
                approval?.approvalStatus === "PENDING"
            ) !== -1
        }
      />
      {isShowDisApprovalDialog ? (
        <LeaveRequestApprovalFormWrapper
          onClose={() => setIsShowDisApprovalDialog(false)}
          handleApprovalSubmit={handleLeaveRequestApproval}
        />
      ) : null}
    </>
  );
};

export default ViewLeaveRequestWrapper;
