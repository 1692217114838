import apiSlice from './ApiSlice';

export const holidayCalendarApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    // Get Mode Filter Pagination List
    getHolidayCalendarPagination: builder.query({
      providesTags: ["holiday"],
      query: (body) => {
        return {
          url: "/holiday",
          method: "POST",
          body,
        };
      },
    }),

    //ADD
    addHoliDay: builder.mutation({
      invalidatesTags: ["holiday"],
      query: (body) => {
        return {
          url: "/holiday/add",
          method: "POST",
          body,
        };
      },
    }),
    // Delete
    deleteHoliDay: builder.mutation({
      invalidatesTags: ["holiday"],
      query: (id: string) => {
        return {
          url: `/holiday/${id}`,
          method: 'DELETE',
        };
      },
    }),
    //Edit
    editHoliDay: builder.mutation({
      invalidatesTags: ["holiday"],
      query: ({ body, id }: any) => {
        return {
          url: `/holiday/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //Single View
    viewHoliDay: builder.query({
      providesTags: ["holiday"],
      query: (id: any) => {
        return {
          url: `/holiday/${id}`,
          method: "GET",
        };
      },
    }),


  }),
});

export const { useGetHolidayCalendarPaginationQuery, useAddHoliDayMutation, useDeleteHoliDayMutation, useEditHoliDayMutation, useViewHoliDayQuery } = holidayCalendarApi;