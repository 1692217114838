import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import EstimationCalculatorForm from "../Layout/EstimationCalculatorForm";
import { EstimationCalculatorFormValues } from "src/models/EstimationCalculator.model";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import {
  useGetoneQuery,
  useUpdateOneMutation,
} from "src/services/EstimationCalculatorServices";
import { showToast } from "src/utils/showToaster";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const EditEstimationCalculatorFormWrapper = () => {
  const estimationId = useParams();
  const id = estimationId?.id;
  const [update] = useUpdateOneMutation();
  const { data, isFetching, isLoading } = useGetoneQuery(id);
  const navigate = useNavigate();

  // Form Initial Values
  const initialValues: EstimationCalculatorFormValues = {
    items: data?.data?.fields || [
      { developer: null, duration: "", hoursPerDay: "" },
    ],
    title: data?.data?.title || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter title"),
  });

  // Handle Submit
  const handleSubmit = (
    values: EstimationCalculatorFormValues,
    { setSubmitting, resetForm }: FormikHelpers<EstimationCalculatorFormValues>
  ) => {
    const formattedValues = {
      title: values?.title,
      fields: values?.items?.map((el: any, ind: number) => {
        return {
          developer: {
            developerId: el?.developer?.developerId,
            developerName: el?.developer?.developerName,
            salaryPerHour: el?.developer?.salaryPerHour,
            growthSalaryPerHour: el?.developer?.growthSalaryPerHour,
          },
          duration: el?.duration,
          hoursPerDay: el?.hoursPerDay,
        };
      }),
    };
    update({ id: id, body: formattedValues }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          navigate("/estimations");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <SideNavLayout>
        {isLoading && isFetching ? (
          <div className="flex items-center justify-center w-full h-screen">
            <CircularProgress />
          </div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <Form>
                <EstimationCalculatorForm formikProps={formikProps} />
              </Form>
            )}
          </Formik>
        )}
      </SideNavLayout>
    </>
  );
};

export default EditEstimationCalculatorFormWrapper;
