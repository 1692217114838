import React from 'react'
import ATMLoadingButton from 'src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton'
import ATMMultiSelect from 'src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect'
import ATMSelect from 'src/components/UI/atoms/formFields/ATMSelect/ATMSelect'
import ATMTextArea from 'src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea'
import ATMTextField from 'src/components/UI/atoms/formFields/ATMTextField/ATMTextField'
import MOLMarkdownEditor from 'src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor'
import { useGetAllCodioticUsers } from 'src/hooks/useGetAllCodioticUser'
import { CodioticUserListResponse } from 'src/models/CodioticUser.model'

type Props = {
  formikProps: any
  onClose: any
}
const ProjectAddResource = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const ResourceForType = [
    {
      label: "INTERNAL",
      value: "INTERNAL"
    },
    {
      label: "PUBLIC",
      value: "PUBLIC"
    },
    {
      label: "PRIVATE",
      value: "PRIVATE"
    },
  ]

  // get all codiotic user hook
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  return (
    <>
      <div className="p-4 flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <div className="text-xl font-medium">
            Add New Resource
          </div>
          <button
            type="button"
            className="hover:bg-hover p-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
        <div className="flex flex-col gap-4">
          {/* Project */}
          <div>
            <ATMSelect
              name="type"
              label="Type"
              required
              placeholder="Type"
              value={values.type}
              options={ResourceForType}
              onChange={(newValue) => {
                setFieldValue("type", newValue);
              }}
            />
          </div>

          <div className="">
            <ATMTextField
              required
              name="resourceName"
              value={values.resourceName}
              onChange={(e) => setFieldValue("resourceName", e.target.value)}
              label="Resource Name"
              placeholder="Enter Resource Name"
              onBlur={handleBlur}
            />
          </div>

          {values?.type?.value === "INTERNAL" ?(
            <div>
              <ATMMultiSelect
                name="internalResourceFor"
                label="Codiotic User"
                placeholder="Select Codiotic User"
                value={values.internalResourceFor}
                isLoading={isCodioticUserDataLoading}
                options={codioticUsers?.map((ele: CodioticUserListResponse) => {
                  return {
                    label: ele?.name,
                    value: ele?._id,
                  };
                })}
                onChange={(newValue) => {
                  setFieldValue("internalResourceFor", newValue);
                }}
              />
            </div>

          ) : null}

          <div className="">
            <ATMTextArea
              required
              name="remark"
              value={values.remark}
              onChange={(e) => setFieldValue("remark", e)}
              label="Remark"
              placeholder="Enter Remark"
            />
          </div>

          <div className="z-0">
            <MOLMarkdownEditor
              label="Description"
              value={values.description}
              placeholder="Write description or drag your files here...."
              onChange={(newValue: any) => {
                setFieldValue("description", newValue);
              }}
            />
          </div>

        </div>
        <div>
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </>
  )
}

export default ProjectAddResource
