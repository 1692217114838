import { FormikProps } from "formik";
import moment from "moment";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<any>;
  onClose: () => void;
};

const ChangeStatusForm = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <MOLFormDialog
      title={"Mark as done"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <ATMDatePicker
        required
        label="Date"
        name="actualDeliveryDate"
        format="DD/MM/YYYY"
        minDate={moment().format("yyyy-MM-DD")}
        value={values.actualDeliveryDate || null}
        onChange={(newValue) => setFieldValue("actualDeliveryDate", newValue)}
      />
    </MOLFormDialog>
  );
};

export default ChangeStatusForm;
