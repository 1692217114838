import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Header from "src/components/UI/Header/Header";
import VerticalNavBar from "src/components/UI/VerticalNavBar/VerticalNavBar";
import { EnterpriseSuiteNavigation } from "src/EnterpriseSuiteNavigation";
import { setIsCollapsed } from "src/redux/slices/SideNavLayoutSlice";
import { AppDispatch, RootState } from "src/redux/store";

type Props = {
  children?: React.ReactNode | string;
};

const EnterpriseSuiteLayout = ({ children }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const sideNavLayoutState: any = useSelector(
    (state: RootState) => state.sideNavLayout
  );

  const { isCollapsed } = sideNavLayoutState;

  const toggleCollapse = () => {
    dispatch(setIsCollapsed(!isCollapsed));
  };
  const location = useLocation();
  const currentPath = `${location.pathname?.split("/")[2]}`;

  return (
    <div className="relative flex w-screen h-screen" tabIndex={0}>
      {/* Side Navigation Bar */}
      <VerticalNavBar
        toggleCollapse={toggleCollapse}
        isCollapsed={isCollapsed}
        navigation={EnterpriseSuiteNavigation}
        isPathEqualtoNavItem={(navItem: any) => navItem.path === currentPath}
      />

      <div
        className={`h-full flex flex-col transition-all duration-500 ${isCollapsed ? "w-full" : "w-[calc(100%-250px)]"
          } `}
      >
        {/* Header */}
        <div className="h-[50px]">
          <Header toggleCollapse={toggleCollapse} isCollapsed={isCollapsed} />
        </div>

        <div className="flex-1 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default EnterpriseSuiteLayout;
