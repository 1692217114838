import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "src/utils/constants";
import { RootState } from "src/redux/store";
import { Mutex } from "async-mutex";
import {
  authTokenKeyName,
  refreshTokenKeyName,
  clearLocalStorage,
} from "src/utils/configs/authConfig";
import { setAccessToken, setRefreshToken } from "src/redux/slices/AuthSlice";
const mutex = new Mutex();

const tagTypes = [
  "users",
  "clients",
  "incident",
  "modules",
  "features",
  "projects",
  "meeting-type",
  "tickets",
  "meeting-note-type",
  "leave-request",
  "change-reques",
  "dashboard",
  "doubt",
  "reminder",
  "deployment-request",
  "task",
  "attendance",
  "resource-request",
  "codiotic-user",
  "change-request-log",
  "leads",
  "holiday",
  "dump",
  "department",
  "issue",
  "expence-category",
  "expence",
  "salary-increment",
  "recurring-payments",
  "requirement-gathering",
  "workspace",
  "platforms",
  "time-analytics",
  "ticket-group",
  "est-list",
  "requirement",
  "job-application",
  "payout",
  "contact",
  "job-stage"
];

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token =
      (getState() as RootState)?.auth?.accessToken ||
      localStorage.getItem(authTokenKeyName);
    if (token && endpoint !== "getAccessModules") {
      headers.set("x-access-token", token);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error && result?.error?.status === 401) {
    if (!mutex?.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult: any = await baseQuery(
          {
            url: "/codiotic-user/refresh",
            method: "POST",
            body: { refreshToken: localStorage.getItem(refreshTokenKeyName) },
          },
          api,
          extraOptions
        );
        if (refreshResult?.data) {
          localStorage.setItem(
            authTokenKeyName,
            refreshResult?.data?.data?.token
          );
          localStorage.setItem(
            refreshTokenKeyName,
            refreshResult?.data?.data?.refreshToken
          );
          api.dispatch(setAccessToken(refreshResult?.data?.data?.token));
          api.dispatch(
            setRefreshToken(refreshResult?.data?.data?.refreshToken)
          );
          // localStorage.setItem(
          //   "userData",
          //   JSON.stringify({
          //     name: refreshResult?.data?.data?.name,
          //     mobile: refreshResult?.data?.data?.mobile,
          //     email: refreshResult?.data?.data?.email,
          //     userId: refreshResult?.data?.data?.userId,
          //   })
          // );
          result = await baseQuery(args, api, extraOptions);
        } else {
          clearLocalStorage();
          window.location.replace("/login");
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  tagTypes: tagTypes,
  endpoints: () => ({}),
});

export default apiSlice;
