import { memo } from "react";
import {
  Handle,
  NodeProps,
  NodeToolbar,
  Position,
} from "reactflow";
import { ShapeName } from "../../molecules/MOLFlowToolbar/MOLFlowToolbar";

const getClassNamesByShapeName = (shapeName: ShapeName) => {
  switch (shapeName) {
    case "Rectangle":
      return `min-h-[40px] min-w-[150px] max-w-[300px] border rounded p-4 flex items-center justify-center transition-all duration-300`;

    case "Parallelogram":
      return `min-h-[40px] skew-x-6 min-w-[150px] max-w-[300px] border rounded p-4 flex items-center justify-center transition-all duration-300`;

    case "Circle":
      return `min-w-[100px] aspect-square max-w-[150px] border rounded-full flex items-center justify-center p-4 text-center  transition-all duration-300`;
    default:
      break;
  }
};

const ATMTextUpdaterNode = ({ data, id, selected }: NodeProps) => {
  return (
    <div className="p-2 group">
      <div className="relative ">
        <Handle
          type="target"
          id="c"
          position={Position.Top}
          className={
            "invisible group-hover:visible !w-[14px] !h-[14px] !bg-primary-light absolute !-top-4"
          }
        />

        <Handle
          type="target"
          id="d"
          position={Position.Left}
          className={
            "invisible group-hover:visible !w-[14px] !h-[14px] !bg-primary-light  absolute !-left-4"
          }
        />

        <NodeToolbar>
          <div className="rounded bg-slate-900"></div>
        </NodeToolbar>

        <div
          className={`${getClassNamesByShapeName(data?.shape)} ${
            data?.bgColor
          } ${selected && "ring-2 ring-primary-lighter shadow-xl"} ${
            data?.bgColor === "bg-white"
              ? "border-gray-600 text-gray-700"
              : "border-transparent text-white"
          }`}
        >
          {data?.label}
        </div>

        <Handle
          type="source"
          id="a"
          position={Position.Right}
          className={
            "invisible group-hover:visible !w-[14px] !h-[14px] !bg-primary-light absolute !-right-4"
          }
        />
        <Handle
          type="source"
          id="b"
          position={Position.Bottom}
          className={
            "invisible group-hover:visible !w-[14px] !h-[14px] !bg-primary-light  absolute !-bottom-4"
          }
        />
      </div>
    </div>
  );
};

export default memo(ATMTextUpdaterNode);