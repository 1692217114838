import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import MarkAsDoneBugTypeModal from "./MarkAsDoneBugTypeModal";
import { array, number, object, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { useMarkAsDoneMutation } from "src/services/TicketServices";
import { useGetAllUserQuery } from "src/services/UserService";

type Props = {
  ticketId: string;
  open: boolean;
  handleClose: () => void;
};

export type FormInitialValues = {
  numberOfDeveloper: string;
  timeTaken: string;
  remark: string;
  changeLog: string;
  responsibleMembers: string[];
  issueFrom: string;
  reasonOfBug: string;
};

const MarkAsDoneBugTypeModalWrapper = ({
  ticketId,
  open = false,
  handleClose,
}: Props) => {
  // user options

  const [userOption, setUserOption] = useState();

  const {
    data: users,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useGetAllUserQuery("");

  // Setting Team Members Data
  useEffect(() => {
    if (!isUserLoading && !isUserFetching) {
      const filteredUser = users?.data?.map((ele: any) => {
        return {
          label: ele?.name,
          value: ele?._id,
        };
      });
      setUserOption(filteredUser || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, isUserLoading, isUserFetching]);

  const [markAsDoneTicket] = useMarkAsDoneMutation();

  // Form Initial Values
  const initialValues: FormInitialValues = {
    numberOfDeveloper: "",
    timeTaken: "",
    issueFrom: "",
    responsibleMembers: [],
    remark: "",
    changeLog: "",
    reasonOfBug: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    numberOfDeveloper: number().required(
      "Please enter developers involved number"
    ),
    timeTaken: string().required("Please enter time"),
    issueFrom: string().required("Please select issue origin"),
    responsibleMembers: array().required(
      "please select the responsible members"
    ),
    remark: string(),
    changeLog: string(),
    reasonOfBug: string(),
  });

  const handleSubmit = (
    values: FormInitialValues,
    { setSubmitting, resetForm }: FormikHelpers<FormInitialValues>
  ) => {
    const resposibleMembersIds = values?.responsibleMembers?.map(
      (ele: any) => ele?.value
    );

    markAsDoneTicket({
      ticketId: ticketId,
      body: {
        responsibleMembers: resposibleMembersIds,
        issueFrom: values.issueFrom,
        changeLog: values.changeLog,
        remark: values.remark,
        numberOfDeveloper: values.numberOfDeveloper,
        timeTaken: values.timeTaken,
        reasonOfBug: values.reasonOfBug,
      },
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <MarkAsDoneBugTypeModal
              formikProps={formikProps}
              userOptionsList={userOption || []}
              handleClose={handleClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default MarkAsDoneBugTypeModalWrapper;
