import React from "react";
import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { ContactLeadformValues } from "src/models/ContactLead.model";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import useCampaign from "src/hooks/useCampaign";
import useChannel from "src/hooks/useChannels";
import useDomains from "src/hooks/useDomains";
import useCountries from "src/hooks/useCountries";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";

type Props = {
  formikProps: FormikProps<ContactLeadformValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
};

const ContactLeadForm = ({ formikProps, onClose, formType }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const { Campaign, isDataLoading: isCampaignLoading } = useCampaign();
  const { Channel, isDataLoading: isChannelLoading } = useChannel();
  const { Domains, isDataLoading: isDomainsLoading } = useDomains();
  const { countries, isDataLoading: isCountryLoading } = useCountries();

  // Campaign  Option
  const CampaignNameOption = Campaign?.map((el: any) => {
    return {
      label: el?.campaignName,
      value: el?._id,
    };
  });
  // Channel  Option
  const ChannelNameOption = Channel?.map((el: any) => {
    return {
      label: el?.channelName,
      value: el?._id,
    };
  });
  // Domains Option
  const DomainsNameOption = Domains?.map((el: any) => {
    return {
      label: el?.domainName,
      value: el?._id,
    };
  });

  // Country Option
  const CountryOption = countries?.map((el: any) => {
    return {
      label: el?.countryName,
      value: el?._id,
    };
  });

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Lead" : "Update Lead"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      hideSavenNext
    >
     <div className="flex flex-col gap-4">
     <div className="grid grid-cols-2 gap-4">
        <div>
          <ATMTextField
            name="organizationName"
            required
            value={values?.organizationName}
            onChange={(e) => {
              setFieldValue("organizationName", e.target.value);
            }}
            label="Organization Name"
            placeholder="Enter Organization Name"
            onBlur={handleBlur}
          />
        </div>
        {/* Country */}
        <div>
          <ATMSelect
            name="countryId"
            value={values?.countryId}
            onChange={(newValue) => {
              setFieldValue("countryId", newValue);
            }}
            label="Country"
            placeholder="Select Country"
            options={CountryOption}
            isLoading={isCountryLoading}
          />
        </div>
         {/* Channel */}
         <div className="">
          <ATMSelect
            name="channelId"
            value={values?.channelId}
            onChange={(newValue) => {
              setFieldValue("channelId", newValue);
            }}
            label="Channel"
            placeholder="Select Channel"
            options={ChannelNameOption}
            isLoading={isChannelLoading}
          />
        </div>
        {/* Campaign */}
        <div className="">
          <ATMSelect
            name="campaignId"
            value={values?.campaignId}
            onChange={(newValue) => {
              setFieldValue("campaignId", newValue);
            }}
            label="Campaign"
            placeholder="Select Campaign"
            options={CampaignNameOption}
            isLoading={isCampaignLoading}
          />
        </div>
        {/* Domains */}
        <div className="">
          <ATMSelect
            name="domainId"
            value={values?.domainId}
            onChange={(newValue) => {
              setFieldValue("domainId", newValue);
            }}
            label="Domains"
            placeholder="Select Domains"
            options={DomainsNameOption}
            isLoading={isDomainsLoading}
          />
        </div>
    
      </div>
        {/* Summary Note */}
        <div>
        <ATMTextArea
          name="summaryNote"
          value={values?.summaryNote}
          onChange={(newValue) => {
            setFieldValue("summaryNote", newValue);
          }}
          label="Note"
          placeholder="Select Summary Note"
        />
      </div>
     </div>
     
    </MOLFormDialog>
  );
};

export default ContactLeadForm;
