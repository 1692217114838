import React from "react";
import { FormikProps } from "formik";
import { FormInitialValues } from "./MarkAsDoneModalWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { handleValidNumber } from "src/utils/validations/numberInput";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  handleClose: () => void;
};

const MarkAsDoneModal = ({ formikProps, handleClose }: Props) => {
  // Formik
  const { values, setFieldValue, isSubmitting, handleBlur, handleSubmit } =
    formikProps;

  return (
    <div className="flex flex-col rounded border border-gray-200 relative">
      {/* Heading & Clear Button */}
      <div className="flex justify-between items-center px-4 py-2 sticky top-0 z-50 bg-white">
        <div className="text-[24px] font-medium">Mark as Done</div>
        <button
          type="button"
          className="text-[#041618] font-medium"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>

      {/* Filter Form */}
      <div className="p-3 overflow-auto flex-1 ">
        <div className="flex flex-col gap-4">
          {/* Number of developer involved */}
          <div className="mb-2">
            <ATMTextField
              type="text"
              name="numberOfDeveloper"
              value={values.numberOfDeveloper}
              label="Developers Involved (in numbers)"
              placeholder="Enter number"
              onBlur={handleBlur}
              onChange={(e) =>
                handleValidNumber(e) &&
                setFieldValue("numberOfDeveloper", e.target.value)
              }
            />
          </div>

          {/* Time Taken */}
          <div>
            <ATMTextField
              name="timeTaken"
              label="Time Taken (in minutes)"
              value={values.timeTaken}
              onChange={(e) => setFieldValue("timeTaken", e.target.value)}
              placeholder="Enter Time Taken"
              onBlur={handleBlur}
            />
          </div>

          {/* Remark */}
          <div className="z-0">
            <MOLMarkdownEditor
              label="Remark (this will shown to client)"
              value={values.remark}
              placeholder="Write remark or drag your files here...."
              onChange={(newValue: any) => {
                setFieldValue("remark", newValue);
              }}
            />
          </div>

          {/* Change Log */}
          <div className="z-0">
            <MOLMarkdownEditor
              label="Change Log"
              value={values.changeLog}
              placeholder="Write change log or drag your files here...."
              onChange={(newValue: any) => {
                setFieldValue("changeLog", newValue);
              }}
            />
          </div>
        </div>
      </div>

      {/* Apply & Cancel Buttons */}
      <div className="flex flex-col gap-2 p-3 sticky bottom-0 bg-white">
        <div>
          <ATMLoadingButton
            type="button"
            isLoading={isSubmitting}
            className="h-[40px] bg-[#041618] font-medium"
            onClick={() => handleSubmit()}
          >
            Save
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default MarkAsDoneModal;
