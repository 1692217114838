import apiSlice from "./ApiSlice";

export const channelGroupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL Channel Groups
    getAllChannelGroupList: builder.query({
      providesTags: ["channel-groups"],
      query: () => {
        return {
          url: "channel-group",
          method: "GET",
        };
      },
    }),

    //GET CHANNEL GROUPS PAGINATION
    getChannelGroups: builder.query({
      providesTags: ["channel-groups"],
      query: (body) => {
        return {
          url: "channel-group",
          method: "POST",
          body,
        };
      },
    }),

    //ADD CHANNEL GROUPS
    addChannelGroup: builder.mutation({
      invalidatesTags: ["channel-groups"],
      query: (body) => {
        return {
          url: "channel-group/add",
          method: "POST",
          body,
        };
      },
    }),
    //EDIT CHANNEL GROUPS
    editChannelGroup: builder.mutation({
      invalidatesTags: ["channel-groups"],
      query: ({ body, id }) => {
        return {
          url: `channel-group/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    //GET CHANNEL GROUP BY ID
    getChannelGroupById: builder.query({
      providesTags: ["channel-groups"],
      query: (id) => {
        return {
          url: `channel-group/${id}`,
          method: "GET",
        };
      },
    }),
    //DELETE CHANNEL GROUP BY ID
    deleteChannelGroup: builder.mutation({
      invalidatesTags: ["channel-groups"],
      query: (id) => {
        return {
          url: `channel-group/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAllChannelGroupListQuery,
  useGetChannelGroupsQuery,
  useAddChannelGroupMutation,
  useEditChannelGroupMutation,
  useGetChannelGroupByIdQuery,
  useDeleteChannelGroupMutation,
} = channelGroupApi;
