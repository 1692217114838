/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import moment from "moment";
import { object, string } from "yup";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import {
  setDateFilter,
} from "src/redux/slices/TimeAnalyticsProject";
import TaskTimeAnalyticsFilter from "./TaskTimeAnalyticsFilter";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  startDate: string | null;
  endDate: string | null;
};

const TaskTimeAnalyticsFilterWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const projectTimeState = useSelector((state: RootState) => state.projectTimeAnalytics);
  const { dateFilter } =
    projectTimeState;

  const initialValues: FormInitialValues = {
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
  };

  const validationSchema: any = object({
    startDate: string().required("please select start date"),
    endDate: string().required("please select end date"),
  });

  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);

    // created date from to dispatch
    dispatch(
      setDateFilter({
        startDate: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : '',
        endDate: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
      })
    );

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        startDate: null,
        endDate: null,
      })
    );
    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <TaskTimeAnalyticsFilter
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TaskTimeAnalyticsFilterWrapper;
