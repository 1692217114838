import { FormikProps } from "formik";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import { useGetFinanceCategoryQuery } from "src/services/Finance-New-Services/FinanceCategroyService";
import { FormInitialValues } from "./IncomeListFilterFormDialogWrapper";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};

const statusTypeOptions = [
  {
    label: "Paid",
    value: "PAID",
  },
  {
    label: "Unpaid",
    value: "UNPAID",
  },
];
const gstApplicableOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const IncomeFlowListFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { data: getCategory, isLoading: isCategoryLoading } =
    useGetFinanceCategoryQuery("");
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  return (
  <MOLFilterDialog 
  title={"Filters"}
  onClose={onClose}
  isSubmitting={isSubmitting}
  clearFilter={onReset}
  handleSubmit={handleSubmit}
  open={open}
  >
     <div className="flex flex-col gap-4 px-2 py-4 md:px-4 md:py-2">
        <div>
          <ATMMultiSelect
            name="status"
            value={values?.status}
            label="Status"
            placeholder="Select status"
            options={statusTypeOptions}
            onChange={(newValue) => {
              setFieldValue("status", newValue);
            }}
          />
        </div>

        {/* Account */}
        <div>
          <ATMMultiSelect
            name="gstApplicable"
            value={values?.gstApplicable}
            label="GST Applicable"
            placeholder="Select gstApplicable"
            options={gstApplicableOptions}
            onChange={(newValue) => {
              setFieldValue("gstApplicable", newValue);
            }}
          />
        </div>

        {/* Category */}
        <div>
          <ATMMultiSelect
            name="category"
            value={values?.category}
            label="Category"
            placeholder="Select Category"
            onChange={(newValue) => {
              setFieldValue("category", newValue);
            }}
            options={getCategory?.data?.map((ele: any) => {
              return {
                label: ele?.categoryName,
                value: ele?._id,
              };
            })}
            isLoading={isCategoryLoading}
          />
        </div>
        {/* Created At */}
        <div className="mb-2">
          <div className="flex items-center gap-2">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>
        
      </div>
  </MOLFilterDialog>
  );
};

export default IncomeFlowListFilterFormDialog;
