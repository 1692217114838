// |-- Built-in Dependencies --|
import React from "react";

// |-- External Dependencies --|
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";
import Dialog from "@mui/material/Dialog";

// |-- Internal Dependencies --|
import { showToast } from "src/utils/showToaster";
import { useAddCityMutation } from "src/services/CityServices";
import CityForm from "../Layout/CityForm";

// |-- Redux --|
import { RootState } from "src/redux/store";
import { isMobile } from "react-device-detect";

// |-- Types --|
type Props = {
  onClose: () => void;
};

export type FormInitialValues = {
  cityName: string;
  tier: string | any;
};

const AddCityFormWrapper = ({ onClose }: Props) => {
  const [addCity] = useAddCityMutation();
  //   const { userData } = useSelector((state: RootState) => state?.auth);
  const { selectedLocationCountries }: any = useSelector(
    (state: RootState) => state?.country
  );
  const { selectedLocationState }: any = useSelector(
    (state: RootState) => state?.states
  );

  const initialValues: FormInitialValues = {
    cityName: "",
    tier: "",
  };

  const validationSchema = object({
    cityName: string().required("please enter city name"),
    // tier: string().required("please enter city name"),   // TODO
  });

  const onSubmitHandler = (values: FormInitialValues) => {
    setTimeout(() => {
      addCity({
        cityName: values.cityName,
        tier: values.tier?.value,
        stateId: selectedLocationState?.value || "",
        countryId: selectedLocationCountries?.value || "",
      }).then((res: any) => {
        if ("data" in res) {
          if (res?.data?.status) {
            showToast("success", "Added successfully!");
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        } else {
          showToast("error", "Something went wrong");
        }
      });
    }, 1000);
  };

  return (
    <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(formikProps) => {
          return <CityForm onClose={onClose} formikProps={formikProps} />;
        }}
      </Formik>
    </Dialog>
  );
};

export default AddCityFormWrapper;
