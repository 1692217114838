import { Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import { useGetAllLeadsListPaginationQuery } from "src/services/LeadsService";



const LeadsSideNavLayout = () => {
  const [leadsListData, setLeadsListData] = useState<any>();
  const [searchValue , setSearchValue] = useState('')
  const navigate=useNavigate()

  const {leadsId} = useParams()

  const { data, isLoading, isFetching } = useGetAllLeadsListPaginationQuery({
    limit: 10,
    searchValue: searchValue,
    params: [
      "name",
      "email",
      "mobile",
      "countryId",
      "stateId",
      "cityId",
      "channelId",
      "campaignId",
      "domainId",
      "socialMediaId",
      "assignedTo",
      "summaryNote",
      "leadLabelName",
      "organizationName",
      "nextFollowupDate",
      "nextFollowupTime",
      "createdById",
      "createdByName",
    ],
    page: 1,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setLeadsListData(data?.data || []);
    }
  }, [data, isLoading, isFetching]);

  return (
    <div>
      <div className="h-[calc(100vh-50px)] border-r">
        <div className="w-full flex  h-[calc(100%)] bg-white">
          <div className="w-[250px]  h-full flex flex-col">
            <div className="p-3 border-b">
              <ATMInputAdormant
                name=""
                value={searchValue}
                placeholder="Search here..."
                className="h-[40px] shadow-sm border-[1px] focus:border-black"
                adormant={<BiSearchAlt2 className="text-slate-400 text-xl " />}
                adormantProps={{
                  position: "start",
                  extraClasses: "bg-white border-0 ",
                }}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                }}
              />
            </div>
            <div className="grow overflow-auto">
              <div className="px-[8px] flex flex-col min-w-[100%] overflow-y-auto">
                {leadsListData?.map((el: any, ind: any) => {
                  return (
                    <div key={ind} className={`border-b cursor-pointer ${el?._id === leadsId ? 'bg-blue-200':'bg-slate-50'}`} onClick={()=>{
                        navigate(`/sales&marketing/leads/${el?._id}/followup`)
                    }}>
                      <div className="flex gap-x-2 py-2 px-3">
                        <div className="bg-[#ebebeb] text-xs rounded-md px-1 py-[1px]">
                          <div className="text-xs text-black">
                            {el?.leadLabelName}
                          </div>
                        </div>
                      </div>
                      <div className="p-4" style={{ userSelect: "text" }}>
                        <Tooltip title={el?.name}>
                          <h3 className="w-full overflow-hidden truncate text-md min-w-[100%] hover:underline font-medium">
                            {el?.name}
                          </h3>
                        </Tooltip>

                        <div className="flex text-xs  justify-between mt-1 font-semibold">
                          <span className="task-id mt-1">{el?.mobile}</span>
                        </div>
                        <div className="flex text-xs  justify-between mt-1 font-semibold">
                          {el?.nextFollowupDate && (
                            <div className="py-1">
                              <div className="text-[13px] text-slate-700 font-medium">
                                {moment(el?.nextFollowupDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </div>
                              <div className="text-[12px] text-slate-500  text-xs font-medium">
                                {el?.nextFollowupTime &&
                                  moment(
                                    el?.nextFollowupTime,
                                    "hh:mm A"
                                  ).format("hh:mm a")}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsSideNavLayout;
