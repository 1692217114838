import React from 'react'
type Props = {
  onClose: any,
  taskData: any
  handleJoinMeeting: any
  handleHoldJoinMeeting: any
}
const HoldJoinMeetingTask = ({ taskData, onClose, handleJoinMeeting, handleHoldJoinMeeting }: Props) => {
  return (
    <>
      <div className="text-center">
        <div className='flex justify-between items-center'>
          <span className="font-semibold text-md p-2">Join and Hold Task</span>
          <button
            type="button"
            className="hover:bg-hover p-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
        {taskData ? (
          <div className='flex px-1 m-2'>
            <span className='px-2 mx-2'> 1. </span>
            <span className='px-2 mx-2'>{taskData?.title}</span>
            <span className='px-2 mx-2'>
              <button
                className="bg-blue-500 text-white px-3 py-1 font-semibold text-xs rounded-md hover:bg-blue-600 transition duration-300"
                onClick={() => { handleHoldJoinMeeting(taskData?._id) }}
              >
                Hold & Join
              </button>
            </span>
            <span className='px-2 mx-2'>
              <button
                className="bg-blue-500 text-white px-3 py-1 font-semibold text-xs rounded-md hover:bg-blue-600 transition duration-300"
                onClick={() => { handleJoinMeeting() }}
              >
                Join Meeting
              </button>
            </span>
          </div>
        ) : (
          <div className=' px-1 m-2'>
            <span>
              There are currently no tasks in progress. Kindly click the button to join the meeting.
            </span>
            <span className='px-2 mx-2'>
              <button
                className="bg-blue-500 text-white px-3 py-1 font-semibold text-xs rounded-md hover:bg-blue-600 transition duration-300"
                onClick={() => { handleJoinMeeting() }}
              >
                Join Meeting
              </button>
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default HoldJoinMeetingTask
