import { useNavigate } from "react-router-dom";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
  onAddNew: () => void;
};

const ExpenseListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
  onAddNew,
}: Props) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="p-2 md:p-4">
          <ATMPageHeader
            pageTitle="Expense"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
           
            buttonProps={{
              btnName: "Add New",
              onClick: ()=>{navigate("/finance/expenseform");},
            }}
          />
        </div>

        {/* Table */}
        <div className="flex flex-col flex-1 px-2 overflow-auto md:px-4">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "min-h-[50px]"}
            isLoading={isTableLoading}
          />
        </div>

        <div className="p-2 py-2 md:p-4">
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

export default ExpenseListing;