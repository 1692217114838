 import { FormikProps } from "formik";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";
import { useGetAllMeetings } from "src/hooks/useGetAllMeetings";
import { useGetAllNeetingNoteType } from "src/hooks/useMeetingNoteType";
import { useProjects } from "src/hooks/useProject";

type Props = {
  formikProps: FormikProps<any>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};

const MeetingNotesListFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  const { projects, isDataLoading } = useProjects();
  const { allMeetings, isDataLoading: isMeetingDataLoading } =
    useGetAllMeetings();
  const { allMeetingNoteType, isDataLoading: isAllMeetingNotetypeDataLoading } =
    useGetAllNeetingNoteType();

  return (
    <MOLFilterDialog
      title={"Filters"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      clearFilter={onReset}
      handleSubmit={handleSubmit}
      open={open}
    >
      <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">
        {/* Created At */}
        <div className="mb-2">
          <div className="flex gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>

        {/* Project  */}
        <div>
          <ATMSelect
            required
            name="projectId"
            label="Projects"
            placeholder="Select Project"
            value={values.projectId}
            options={projects?.map((ele: any) => {
              return {
                label: ele?.projectName,
                value: ele?._id,
              };
            })}
            isLoading={isDataLoading}
            onChange={(newValue) => {
              setFieldValue("projectId", newValue);
            }}
          />
        </div>
        {/* Meetings  */}
        <div>
          <ATMSelect
            required
            name="meetingId"
            label="Meetings"
            placeholder="Select Meeting"
            value={values.meetingId}
            options={allMeetings?.map((ele: any) => {
              return {
                label: ele?.title,
                value: ele?._id,
              };
            })}
            isLoading={isMeetingDataLoading}
            onChange={(newValue) => {
              setFieldValue("meetingId", newValue);
            }}
          />
        </div>

        <div>
          <ATMSelect
            required
            name="meetingTag"
            label="Meetings Tags"
            placeholder="Select Meeting Tags"
            value={values.meetingTag}
            options={allMeetingNoteType?.map((ele: any) => {
              return {
                label: ele?.meetingNoteType,
                value: ele?._id,
              };
            })}
            isLoading={isAllMeetingNotetypeDataLoading}
            onChange={(newValue) => {
              setFieldValue("meetingTag", newValue);
            }}
          />
        </div>
      </div>
    </MOLFilterDialog>
  );
};

export default MeetingNotesListFilterFormDialog;
