import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLProjecttDropdown from "src/components/UI/molecules/MOLProjectDropdown/MOLProjectDropdown";
import { AddTicketFormValues } from "src/models/Ticket.model";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import { ClipLoader } from "react-spinners";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import useFileUploader from "src/utils/useFileUploader";
import { useDispatch } from "react-redux";
// import ATMTagAutoComplete from "src/components/UI/atoms/ATMTagAutoComplete/ATMTagAutoComplete";
// import { useGetAllTagsQuery } from "src/services/TicketServices";

type Props = {
  formikProps: FormikProps<AddTicketFormValues>;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
  isCheckedAddTask?: boolean;
  setIsCheckedAddTask?: any;
  onClose: () => void;
};

const TICKET_PRIORITY = [
  {
    label: "Low",
    value: "LOW",
  },
  {
    label: "Medium",
    value: "MEDIUM",
  },
  {
    label: "High",
    value: "HIGH",
  },
  {
    label: "Urgent",
    value: "URGENT",
  },
];

const TicketForm = ({
  formikProps,
  formType,
  setSaveNextChecked,
  saveNextChecked,
  isCheckedAddTask = false,
  setIsCheckedAddTask,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const { initiateUpload, uploadedFile } = useFileUploader({
    next: (file: any) =>
      setFieldValue("attachments", [
        ...values?.attachments,
        {
          url: file?.fileUrl,
          type: file?.fileType,
          name: file?.fileTitle,
        },
      ]),
    accept: ".jpg, .jpeg, .png,  .mp4, .xls, .xlsx, .pdf, .webp, .webm",
  });

  //Tags Data
  // const {
  //   data: AllTagsData,
  // } = useGetAllTagsQuery("");
  // const tagsOptions = AllTagsData?.data?.map((el: any) => {
  //   return {
  //     title: el?.tagName,
  //   };
  // });

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">
          {formType === "ADD" ? "ADD New" : "Update"} Ticket
        </div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Project */}
        <div className="z-20">
          <MOLProjecttDropdown
            required
            name="projectId"
            value={values.projectId}
            onChange={(newValue) => {
              setFieldValue("projectId", newValue);
            }}
            onBlur={handleBlur}
          />
        </div>

        {/* Title */}
        <div>
          <ATMTextField
            required
            name="title"
            value={values.title}
            onChange={(e) => setFieldValue("title", e.target.value)}
            label="Title"
            placeholder="Enter title"
            onBlur={handleBlur}
          />
        </div>

        {/* Module Name */}
        <div>
          <ATMSelect
            required
            name="priority"
            value={values.priority}
            label="Priority"
            placeholder="Select ticket priority"
            options={TICKET_PRIORITY}
            onBlur={handleBlur}
            menuPosition="absolute"
            onChange={(newValue) => {
              setFieldValue("priority", newValue);
            }}
          />
        </div>

        {/* Description */}
        <div className="z-0">
          <MOLMarkdownEditor
            label="Description"
            value={values.description}
            placeholder="Write description or drag your files here...."
            onChange={(newValue: any) => {
              setFieldValue("description", newValue);
            }}
          />
        </div>

        {values?.attachments?.length ? (
          <div className="grid md:grid-cols-12 grid-cols-6 gap-4 pb-10">
            <div className="md:col-span-12 col-span-6 text-slate-700 font-medium text-sm">
              Attachments
            </div>
            {values?.attachments?.map((item: any, index: any) => {
              return (
                <div className="col-span-3 h-[200px]" key={index}>
                  <ATMRenderFile
                    fileInfo={item}
                    isShowCancel={true}
                    onCancelClick={() => {
                      const attachmentInfo = [...values?.attachments];
                      attachmentInfo?.splice(index, 1);
                      setFieldValue("attachments", attachmentInfo);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="flex justify-end mt-3">
          <button
            className="text-primary-main flex gap-1 font-bold items-center"
            type="button"
            onClick={initiateUpload}
            disabled={uploadedFile ? true : false}
          >
            {uploadedFile ? (
              <ClipLoader
                color={"teal"}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <HiOutlinePlusSmall size={20} />
            )}
            Add Attachment
          </button>
        </div>
      </div>

      {/* {formType === "ADD" && (
       
      )} */}
      <div>
        <ATMCheckbox
          id='isVisibleToClient'
          label="Is Visible To Client"
          checked={values?.isVisibleToClient}
          inputClasses="h-[15px]"
          labelClasses="text-sm select-none"
          onChange={(checked) => {
            setFieldValue("isVisibleToClient", checked);
          }}
        />
      </div>
      {formType === "ADD" && (
        <div className="flex justify-between">
          <div>
            <ATMCheckbox
              label="Save and Next"
              checked={saveNextChecked}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                dispatch(setSaveNextChecked(checked));
              }}
            />
          </div>

          <div>
            <ATMCheckbox
              label="Add Task"
              checked={isCheckedAddTask}
              onChange={(checked) => {
                dispatch(setIsCheckedAddTask(checked));
              }}
            />
          </div>
        </div>
      )}
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default TicketForm;
