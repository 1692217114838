import { FormikProps } from "formik";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { ClipLoader } from "react-spinners";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import useFileUploader from "src/utils/useFileUploader";
import { IncreamentFormValues } from "./AddDocumentFormWrapper";
import { ErrorMessage } from "formik";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<IncreamentFormValues>;
  onClose: () => void;
};

const AddDocumentForm = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  const { initiateUpload, uploadedFile } = useFileUploader({
    next: (file: any) =>
      setFieldValue("url", [
        ...values.url,
        {
          url: file?.fileUrl,
          type: file?.fileType,
          name: file?.fileTitle,
        },
      ]),
    accept: ".jpg, .jpeg, .png,  .mp4, .xls, .xlsx, .pdf, .webp, .webm",
  });

  return (
    <MOLFormDialog
      title={"Add Documents"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMSelect
            required
            name="documentName"
            label="Document"
            placeholder="Select Document"
            value={values.documentName}
            options={[
              {
                label: "Aadhar Card",
                value: "AADHAR CARD",
              },
              {
                label: "Marksheet",
                value: "MARSHEET",
              },
              {
                label: "Degree",
                value: "DEGREE",
              },
              {
                label: "Passport Photo",
                value: "PASSPORT PHOTO",
              },
              {
                label: "Bank Passbook",
                value: "BANK PASSBOOK",
              },
            ]}
            onChange={(newValue) => {
              setFieldValue("documentName", newValue);
            }}
          />
        </div>

        {values.url?.length ? (
          <div className="grid grid-cols-1  gap-4 pb-10">
            <div className="md:col-span-12 col-span-6 text-slate-700 font-medium text-sm">
              Documents
            </div>
            {values?.url?.map((item: any, index: any) => {
              return (
                <div className="col-span-12 h-[200px]" key={index}>
                  <ATMRenderFile
                    fileInfo={item}
                    isShowCancel={true}
                    onCancelClick={() => {
                      const attachmentInfo = [...values?.url];
                      attachmentInfo?.splice(index, 1);
                      setFieldValue("url", attachmentInfo);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="flex flex-col justify-end mt-3">
          <button
            className="text-primary-main flex gap-1 font-bold items-center border rounded p-2"
            type="button"
            onClick={initiateUpload}
            disabled={uploadedFile ? true : false}
          >
            {uploadedFile ? (
              <ClipLoader
                color={"teal"}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <HiOutlinePlusSmall size={20} />
            )}
            Add Documents
          </button>
          <ErrorMessage name={"url" as any}>
            {(errMsg) => (
              <p className="font-poppins text-[14px] text-start mt-0 text-red-500">
                {errMsg}
              </p>
            )}
          </ErrorMessage>

          {/* <div className="font-poppins text-[14px] text-start mt-0 text-red-500"> {errorsOfFormik ? errorsOfFormik?.url : null}</div> */}
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default AddDocumentForm;
