import ATMPageHeading from "src/components/UI/atoms/ATMPageHeading/ATMPageHeading";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
type Props = {
  columns: columnTypes[];
  rows: any[];
  itemsGoal: any;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const ViewGoal = ({
  columns,
  rows,
  itemsGoal,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const firstLetter = itemsGoal?.name?.charAt(0).toUpperCase();
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="px-4 my-6">
          <ATMPageHeading>Goal Details :- </ATMPageHeading>
          <div className="flex justify-between p-4 border rounded-md text-md ">
            <div className="relative flex flex-col items-start">
              <div className="flex items-center">
                <span className="absolute top-0 bottom-0 left-0 flex items-center p-5 text-lg font-bold rounded bg-sky-50">
                  {firstLetter}
                </span>
                <div className="px-6 ml-10">
                  <div className="font-semibold capitalize">
                    {itemsGoal?.name}
                  </div>
                  <div className="flex text-grey-500">
                    <span className="">{itemsGoal?.goalCategoryName}</span>
                    <span className="px-2">|</span>
                    <span className="">
                      {formatedDateTimeIntoIst(
                        itemsGoal.deadline,
                        "DD MMM yyyy"
                      )}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="flex flex-col flex-1 overflow-auto">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "h-[50px]"}
            isLoading={isTableLoading}
          />
        </div>

        <div>
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

export default ViewGoal;
