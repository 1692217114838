import React, { useEffect, useState } from "react";
import Details from "./Details";
import {  useGetProfileDataQuery } from "src/services/TeamServices";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { CircularProgress } from "@mui/material";

const DetailsWrapper = () => {

  const { userData } = useGetLocalStorage();

  const [userDetails, setUserDetails] = useState<any>();

  const { data, isLoading, isFetching } =useGetProfileDataQuery('');

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setUserDetails(data?.data);
    }
  }, [data, isLoading, isFetching]);

  return (
    <>
     {(isLoading || isFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
    <Details userDetails={userDetails} userId={userData?.userId} />
    </>
  );
};

export default DetailsWrapper;
