import React, { useEffect, useState } from "react";
import { PiUserFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import ColorComponent from "src/components/UI/colorComponent";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { RootState } from "src/redux/store";
import { CLIENT_GRW } from "src/utils/constants";

const MeetingTimeLogAnalytics = ({ meetingTimeData }: any) => {
  const { userData } = useGetLocalStorage();
  const { meetingCTC, meetingGCTC } = useSelector(
    (state: RootState) => state.projectTimeAnalytics
  );
  const [isMeetingMode, setIsMeetingMode] = useState<any>(false);

  useEffect(() => {
    // get the meetingmode is enable or not
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem(
      "isMeetingModeEnable"
    ) as string;

    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";

    setIsMeetingMode(isEnabled);
  }, [isMeetingMode]);

  return (
    <div>
      {/* Filter Button */}
      <div className="flex flex-col gap-2 p-4">
        <p className="text-[20px] font-medium">Meeting Time : </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
          {meetingTimeData?.length
            ? meetingTimeData?.map((el: any, ind: any) => {
                return (
                  <div
                    key={ind}
                    className="flex flex-col gap-6 border bg-white items-center rounded-md p-4"
                  >
                    <div className="flex flex-col items-center gap-2">
                      <div className="border p-2 rounded-full aspect-square">
                        <PiUserFill
                          size={16}
                          className="rounded-full mx-auto"
                        />
                      </div>
                      <div className="flex flex-col items-center">
                        <div className="text-lg font-medium">
                          <ColorComponent inputString={el?.username} />
                        </div>
                      </div>
                    </div>

                    <div className="font-medium flex items-center gap-1">
                      <span className="text-lg">
                        {`${Math?.floor(el?.totalDurationInMinutes / 60)}:${(
                          el?.totalDurationInMinutes % 60
                        )?.toFixed()}  hrs`}
                      </span>

                      <span className="text-sm text-neutral">
                        ({`${el?.totalDurationInMinutes?.toFixed()}`} min)
                      </span>

                      {/* {`${el?.totalDurationInMinutes?.toFixed()} min (${Math?.floor(
                        el?.totalDurationInMinutes / 60
                      )}:${(el?.totalDurationInMinutes % 60)?.toFixed()}  hrs)`} */}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        {!meetingTimeData?.length && (
          <div className="flex justify-center items-center h-80 text-[22px] text-red-700 font-semibold">
            Data not Found{" "}
          </div>
        )}
      </div>
      {userData?.userType === "SUPER_ADMIN" && (
        <div>
          {meetingTimeData?.length ? (
            <div className="flex justify-end gap-6 w-full px-4">
              {!isMeetingMode && (
                <div>
                  <span className="font-semibold text-sm text-cyan-600">
                    CTC :{" "}
                  </span>
                  <span className="px-1">₹{meetingCTC || 0} </span>
                </div>
              )}
              {CLIENT_GRW === true ? (
                <div>
                  <span className="font-semibold text-sm text-green-600">
                    {!isMeetingMode ? "GCTC" : "CTC"} :{" "}
                  </span>
                  <span className="px-1">₹{meetingGCTC || 0} </span>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default MeetingTimeLogAnalytics;
