import { useState, useEffect } from "react";
import {
  // useGetProjectsQuery ,
  useGetAllMeetingTypePaginationDataQuery,
} from "src/services/MeetingTypeService";

export const useGetAllNeetingType = () => {
  const [allMeetingType, setAllMeetingType] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { data, isLoading, isFetching } = useGetAllMeetingTypePaginationDataQuery({
    limit: 0,
    searchValue: "",
    params: ["meetingType"],
    page: 0,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsDataLoading(true);
    } else {
      const result: any[] = data?.data;
      setAllMeetingType(result || []);
      setIsDataLoading(false);
    }
  }, [data, isLoading, isFetching]);

  return { allMeetingType, isDataLoading };
};
