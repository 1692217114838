import { FormikProps } from "formik";
import moment from "moment";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import {
  FullDayLeaveType,
  LeaveRequestFormValues,
} from "src/models/LeaveReques.model";

type Props = {
  handleClose: any;
  formikProps: FormikProps<LeaveRequestFormValues>;
  codioticUsers: any;
  isDataLoading: any;
};

const OneDayLeaveRequestForm = ({
  handleClose,
  formikProps,
  codioticUsers,
  isDataLoading,
}: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <MOLFormDialog
      title={"Apply Full Day Leave"}
      onClose={handleClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMSelect
            required
            name="leaveType"
            label="Type"
            placeholder="Select Type"
            value={values.leaveType}
            options={FullDayLeaveType?.map((ele: any) => {
              return {
                label: ele?.label,
                value: ele?.value,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("leaveType", newValue);
            }}
          />
        </div>
        {/* date */}
        <div className="w-full">
          <ATMDatePicker
            required
            label="Date"
            name="leaveStartDate"
            format="DD/MM/YYYY"
            value={values.leaveStartDate || null}
            minDate={moment().format("yyyy-MM-DD")}
            onChange={(newValue) => {
              setFieldValue("leaveStartDate", newValue);
            }}
          />
        </div>

        <div className="mt-1">
          <ATMSelect
            required
            name="approvals"
            label="Approver"
            placeholder="Approver"
            value={values.approvals}
            isLoading={isDataLoading}
            options={codioticUsers?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("approvals", newValue);
            }}
          />
        </div>
        <div className="mt-1">
          <ATMSelect
            required
            name="buddy"
            label="Buddy"
            placeholder="Buddy"
            value={values.buddy}
            isLoading={isDataLoading}
            options={codioticUsers?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("buddy", newValue);
            }}
          />
        </div>

        {/* Reason */}
        <div className="mt-1">
          <ATMTextArea
            required
            name="reason"
            value={values.reason}
            onChange={(e) => setFieldValue("reason", e)}
            label="Reason"
            placeholder="Enter Reason"
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default OneDayLeaveRequestForm;
