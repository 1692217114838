import React from "react";
import { FormikProps } from "formik";
import { HoliDayCalenderFormValues } from "src/models/HoliDayCalender.model";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSwitchButton from "src/components/UI/atoms/formFields/ATMSwitchButton/ATMSwitchButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<HoliDayCalenderFormValues>;
  onClose: () => void;
  formType?: "ADD" | "EDIT";
};

const HoliDayCalenderForm = ({ formType, formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Holiday" : "Update Holiday"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        {/* HoliDay Name */}
        <div className="">
          <ATMTextField
            name="holidayName"
            value={values.holidayName}
            onChange={(e) => setFieldValue("holidayName", e.target.value)}
            label="Holiday Name"
            placeholder="Enter Holiday Name"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMDatePicker
            name="date"
            value={values.date || null}
            onChange={(newValue) => setFieldValue("date", newValue)}
            label="Select Date"
          />
        </div>
        <div>
          <ATMSwitchButton
            name="optional"
            value={values.optional}
            onChange={(newValue) => setFieldValue("optional", newValue)}
            label="Is-Optional"
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default HoliDayCalenderForm;
