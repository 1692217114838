import React from "react";


type Props = {
  onClose: () => void;
  notMeReason: any;
  clarificationRemark: any;
};

const LogHistoryDialog = ({
  onClose,
  clarificationRemark,
  notMeReason,
}: Props) => {
  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">History</div>
        <button type="button" className="hover:bg-hover" onClick={onClose}>
          Cancel
        </button>
      </div>
      <div>
        {notMeReason?.length ? (
          <div>
            {notMeReason?.map((el: any, ind: any) => {
              return (
                <div className="mb-3">
                  <p className="bg-slate-100 px-3 p-1 font-medium  rounded">
                    {el?.adminReason ? el?.adminReason : el?.userReason}
                  </p>
                  <p className="flex justify-end text-xs">
                    {el?.adminReason ? "Not Me Reply" : "You (not me request)"}
                  </p>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <div>
        {clarificationRemark?.length ? (
          <div>
            {clarificationRemark?.map((el: any, ind: any) => {
              return (
                <div className="mb-3">
                  <p className="bg-slate-100 px-3 p-1 font-medium  rounded">
                    {el?.adminReason ? el?.adminReason : el?.userReason}
                  </p>
                  <p className="flex justify-end text-xs">
                    {el?.adminReason ? "Clarification" : "You (clarification request)"}
                  </p>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className="flex justify-center">
        {(!notMeReason?.length && !clarificationRemark?.length) ? (
          <div className="font-semibold text-red-700 text-lg">
            Logs Not Found
          </div>
         ) : null}
      </div>
    </div>
  );
};

export default LogHistoryDialog;
