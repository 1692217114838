import React, { useEffect, useState } from "react";
import { ClickAwayListener, Divider, Switch } from "@mui/material";
import { MdOutlineLogout } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import ChangePasswordFormWrapper from "./ChangePassword/ChangePasswordFormWrapper";
import { useGetCodioticTeamMemberByIdQuery } from "src/services/TeamServices";
import moment from "moment";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import ATMSelect from "../../atoms/formFields/ATMSelect/ATMSelect";
import { showToast } from "src/utils/showToaster";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { CLIENT_GRW } from "src/utils/constants";

type UserProfileCardPropTypes = {
  onClickAway: () => void;
  updateStatus :any ,
  breakStatus:any ,
  inProgressTaskId :any ,
  setStatus :any ;
  isLoadingOptions :boolean ; 
  status :any ;
  userData: {
    name: string;
    mobile: string;
    email: string;
    memberId: string;
    userId: string;
    lastLoginDateAndTime: string;
    lastLoginIpAddress: string;
  };
}; 

const statusOptions = [
  {
    label: "ON BREAK",
    value: "ON BREAK",
  },
  {
    label: "ON BENCH",
    value: "ON BENCH",
  },
  {
    label: "WORKING",
    value: "WORKING",
  },
];

const UserProfileCard = ({
  onClickAway,
  setStatus , 
  inProgressTaskId ,   breakStatus , updateStatus ,isLoadingOptions ,
  status ,
  userData,
}: UserProfileCardPropTypes) => { 

  const navigate = useNavigate() 
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];

  const [isOpenChangePasswordDialog, setIsOpenChangePasswordDialog] =
    useState<boolean>(false);

  // const navigate = useNavigate()
  const [teamMemberData, setTeamMemberData] = useState<any>();
  const [meetingModeEnable, setMeetingModeEnable] = useState<boolean>(false);

  //  GET USER DETAILS DATA
  const { isLoading, isFetching, data } = useGetCodioticTeamMemberByIdQuery(
    userData?.userId,
    {
      skip: !userData?.userId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTeamMemberData(data?.data);
    }
  }, [isLoading, isFetching, data]);


  useEffect(() => {
    // get the meetingmode is enable or not
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem(
      "isMeetingModeEnable"
    ) as string;

    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";

    setMeetingModeEnable(isEnabled);
  }, []);

  // MEETING MODE CHANGE
  const handleMeetingMode = (value: boolean) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: `Are you sure you want to ${
        meetingModeEnable ? "disable" : "enable "
      } meeting mode ? This action requires reloading the window.`,
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          setMeetingModeEnable(value);
          localStorage.setItem("isMeetingModeEnable", String(value));
          window.location.reload();
        }
      },
    });
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className="w-[300px] overflow-hidden rounded-lg bg-white border shadow-lg absolute top-full right-[20px] animate-[fade_0.5s_ease-in-out] z-[1000]">
        <div className="flex items-center gap-3 p-4 py-2">
          <div className="flex items-center justify-center text-white rounded-full size-8 bg-primary-main ">
            {userData?.name?.[0]?.toUpperCase()}
          </div>

          <div className="flex-1 overflow-auto">
            <div className="text-slate-700"> {userData?.name || ""} </div>
            {/* <Tooltip title={userData?.email || ""} className="bg-white">
              <div className="overflow-hidden text-sm text-slate-500 text-ellipsis">
                {userData?.email || ""}
              </div>
            </Tooltip> */}
          </div>
        </div>
        <Divider />
        <div className="flex flex-col gap-3 px-2 py-3 border-b">
          <div className="flex justify-between text-sm">
            <span className="text-neutral"> Last Login : </span>
            <span className="">
              {moment(userData?.lastLoginDateAndTime).format(
                "YYYY-MM-DD hh:mm:ss"
              )}{" "}
            </span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-neutral"> Last IP : </span>
            <span className="">{userData.lastLoginIpAddress} </span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-neutral"> Remaining Leaves : </span>
            <span className="">{teamMemberData?.leaveWallet || 0} Days</span>
          </div>
        {CLIENT_GRW ?(<div className="flex items-center justify-between text-sm">
            <span className="text-neutral"> Meeting Mode : </span>
            <Switch
              size="small"
              // disabled={true}
              inputProps={{ "aria-label": "controlled" }}
              checked={meetingModeEnable}
              onChange={(event) => handleMeetingMode(event?.target?.checked)}
            />
          </div>): null}
        </div>
         <div className=" p-2 border-b  md:hidden">
         <ATMSelect
            isClearable={false}
            name=""
            options={statusOptions}
            isLoading={isLoading}
            value={status}
            onChange={(newValue) => {
              setStatus(newValue);

              if (
                newValue?.value !== breakStatus?.data?.userAction &&
                newValue?.value !== "WORKING"
              ) {
                updateStatus({
                  taskId: inProgressTaskId || null,
                  userAction:
                    newValue?.value === "WORKING" ? null : newValue?.value,
                }).then((res: any) => {
                  if (res.error) {
                    showToast("error", res?.error?.data?.message);
                  } else {
                    showToast("success", res?.data?.message);
                  }
                });
              } else if (
                newValue?.value === "WORKING" &&
                breakStatus?.data?.userAction
              ) {
                updateStatus({
                  taskId: inProgressTaskId || null,
                  userAction:
                    newValue?.value === "WORKING" ? null : newValue?.value,
                }).then((res: any) => {
                  if (res.error) {
                    showToast("error", res?.error?.data?.message);
                  } else {
                    showToast("success", res?.data?.message);
                  }
                });
              }
            }} />
         </div>
        <div
          onClick={() => navigate("/profile")}
          className="flex items-center w-full gap-2 px-4 py-2 rounded-sm cursor-pointer hover:bg-gray-50 border-b "
        >
          <FaRegUserCircle className="text-sm" />
          <div className={`${currentPath ==='profile' ? 'text-primary-main font-medium' :''} text-sm`}>My  Profile</div>
        </div>
        <div
          onClick={() => setIsOpenChangePasswordDialog(true)}
          className="flex items-center px-4 py-2 gap-2 w-full rounded-sm hover:bg-gray-50 cursor-pointer border-b "
        >
          <RiLockPasswordFill className="text-sm" />
          <div className="text-sm ">Change Password</div>
        </div>

        {/* Change Password Dialog */}
        {isOpenChangePasswordDialog ? (
          <ChangePasswordFormWrapper
            onClose={() => setIsOpenChangePasswordDialog(false)}
          />
        ) : null}

        <div className="flex items-center w-full gap-2 px-4 py-2 text-sm text-center cursor-pointer hover:bg-gray-50">
          <MdOutlineLogout className="text-sm" />
          <div
            className=""
            onClick={() => {
              localStorage.clear();
              localStorage.removeItem("hasExecuted");
              setTimeout(() => {
                window.location.href = "/login";
              }, 200);
              // navigate("/login", { replace: true });
            }}
          >
            Sign out
          </div>
        </div>
        
      </div>
    </ClickAwayListener>
  );
};

export default UserProfileCard;
