import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Header from "src/components/UI/Header/Header";
import VerticalNavBar from "src/components/UI/VerticalNavBar/VerticalNavBar";
import { setIsCollapsed } from "src/redux/slices/SideNavLayoutSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { FinanceNavigation } from "./FinanceNavigation";

type Props = {
  children?: React.ReactNode | string;
};

const FinanceLayout = ({ children }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const { isCollapsed }: any = useSelector(
    (state: RootState) => state.sideNavLayout
  );
  const toggleCollapse = () => {
    dispatch(setIsCollapsed(!isCollapsed));
  };
  const location = useLocation();
  const currentPath = `${location.pathname?.split("/")[2]}`;

  return (
    <div className="relative flex w-screen h-screen" tabIndex={0}>
      {/* Side Navigation Bar */}
      <VerticalNavBar
        toggleCollapse={toggleCollapse}
        isCollapsed={isCollapsed}
        navigation={FinanceNavigation}
        isPathEqualtoNavItem={(navItem: any) => navItem.path === currentPath}
      />

      <div
        className={`h-full flex flex-col transition-all duration-500 ${isCollapsed ? "w-full" : "w-[calc(100%-250px)]"
          } `}
      >
        {/* Header */}
        <div className="h-[50px]">
          <Header toggleCollapse={toggleCollapse} isCollapsed={isCollapsed} />
        </div>

        <div className="flex-1 overflow-auto">
          <Outlet />
        </div>
      </div>

      {/* {!isCollapsed ?
        <div className="absolute bottom-0 left-0 z-50 w-[230px] p-4">
          <button
            type="button"
            className="flex items-center justify-center w-full gap-2 px-4 py-2 font-semibold bg-white rounded-lg text-primary-main text-start group/item "
            onClick={() => {
              // localStorage.removeItem('hasExecuted');
              navigate("/welcome");
            }}
          >
            <FiChevronsLeft
              size={24}
              className="transition-all duration-500 group-hover/item:-translate-x-2"
            />
            <span className="leading-none"> Back To Menu</span>
          </button>
        </div>
        : null
      } */}
    </div>
  );
};

export default FinanceLayout;
