import apiSlice from "./ApiSlice";

export const onBoardStageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    getAllOnBoardStageListing: builder.query({
      providesTags: ["on-board-stage"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/employment-sop",
          method: "POST",
          body,
        };
      },
    }),

    //ADD employment-sop
    addOnBoardStage: builder.mutation({
      invalidatesTags: ["on-board-stage"],
      query: (body) => {
        return {
          url: "/employment-sop/add",
          method: "POST",
          body,
        };
      },
    }),

    //GET employment-sop   BY ID
    getOnBoardStageById: builder.query({
      providesTags: ["on-board-stage"],
      query: (id) => {
        return {
          url: `/employment-sop/${id}`,
          method: "GET",
        };
      },
    }),

    //employment-sop  UPDATE
    OnBoardStageUpdate: builder.mutation({
      invalidatesTags: ["on-board-stage"],
      query: ({ body, id }) => {
        return {
          url: `/employment-sop/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //employment-sop  DELETE

    deleteOnBoardStage: builder.mutation({
      invalidatesTags: ["on-board-stage"],
      query: (id) => {
        return {
          url: `/employment-sop/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAllOnBoardStageListingQuery,
  useAddOnBoardStageMutation,
  useGetOnBoardStageByIdQuery,
  useOnBoardStageUpdateMutation,
  useDeleteOnBoardStageMutation,
} = onBoardStageApi;
