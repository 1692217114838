import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";

import CircularProgress from "@mui/material/CircularProgress";
import SubscriptionForm from "../Layout/ExpenseCategoryForm";
import { ExpenseCategoryFormValues } from "src/models/ExpenseCategory.model";
import {
  useGetExpenseCategoryByIdQuery,
  useUpdateExpenseCategoryMutation,
} from "src/services/ExpenseCategoryService";

type Props = {
  selectedSubscription: string;
  onClose: () => void;
};

const EditSubscriptionWrapper = ({ selectedSubscription, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetExpenseCategoryByIdQuery(selectedSubscription);

  const [updateExpenseCategory] = useUpdateExpenseCategoryMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: ExpenseCategoryFormValues = {
    title: items?.categoryName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter title"),
  });
  const handleSubmit = (
    values: ExpenseCategoryFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ExpenseCategoryFormValues>
  ) => {
    const formattedValues = {
      categoryName: values?.title,
    };

    updateExpenseCategory({
      id: selectedSubscription,
      body: formattedValues,
    }).then((res: any) => {
      updateExpenseCategory({
        id: selectedSubscription,
        body: formattedValues,
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <SubscriptionForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditSubscriptionWrapper;
