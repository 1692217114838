import React from "react";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { FormikProps } from "formik";
import { FormInitialValues } from "./DumpFilterFormDialogWrapper";

// MODELS
import { ProjectListResponse } from "src/models/Project.model";

// HOOKS
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};

const DumpFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  return (
    <MOLFilterDialog
      title={"Filters"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      clearFilter={onReset}
      handleSubmit={handleSubmit}
      open={open}
    >
      <div className="flex flex-col gap-4 px-4 py-2">
        <div className="flex flex-col gap-4">
          {/* Project */}
          <div>
            <ATMSelect
              name="project"
              label="Project"
              placeholder="Select Project"
              value={values.project}
              isLoading={isAllProjectsDataLoading}
              options={allProjects?.map((ele: ProjectListResponse) => {
                return {
                  label: ele?.projectName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("project", newValue);
              }}
            />
          </div>
        </div>
      </div>
    </MOLFilterDialog>
  );
};

export default DumpFilterFormDialog;
