import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { array, object, string } from "yup";
import { JobCreationStageFormValues } from "src/models/JobCreationStage.model";
import JobCreationStageForm from "../Layouts/JobCreationStageForm";
import { showToast } from "src/utils/showToaster";
import { useParams } from "react-router-dom";
import { useAddJobCreationStageMutation } from "src/services/JobsCreationStageService";

const AddJobCreationStageFormWrapper = () => {
  const { id } = useParams();
  const [addStage] = useAddJobCreationStageMutation();

  // Form Initial Values
  const initialValues: JobCreationStageFormValues = {
    question: [
      {
        questionType: "",
        labelName: "",
      },
    ],
    stageName: "",
    order: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    stageName: string().required("Please enter stage name"),
    order: string().required("Please enter order"),
    question: array().of(
      object().shape({
        questionType: object().required("Please select type"),
        labelName: string().required("Please enter title"),
      })
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: JobCreationStageFormValues,
    { setSubmitting, resetForm }: FormikHelpers<JobCreationStageFormValues>
  ) => {
    const formattedValues = {
      jobId: id,
      stageName: values?.stageName,
      order: values?.order,
      questions: values?.question?.map((el: any) => {
        return {
          questionTitle: el?.labelName,
          questionType: el?.questionType?.value,
          maximumValForRange: el?.maximumValForRange || 0,
          minimumValForRange: el?.minimumValForRange || 0,
        };
      }),
    };
    addStage(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <JobCreationStageForm formikProps={formikProps} formtype="ADD" />
        </Form>
      )}
    </Formik>
  );
};

export default AddJobCreationStageFormWrapper;
