import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { CampaignGroupFormValues } from "src/models/CampaignGroup.model";

type Props = {
  formikProps: FormikProps<CampaignGroupFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const CampaignGroupForm = ({
  formikProps,
  formType,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={
        formType === "ADD" ? "Add Campaign Group" : "Update Campaign Group"
      }
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <ATMTextField
        name="campaignGroupName"
        value={values.campaignGroupName}
        required
        onChange={(e) => setFieldValue("campaignGroupName", e.target.value)}
        label="Campaign Group Name"
        placeholder="Enter Campaign Group Name"
        onBlur={handleBlur}
      />
    </MOLFormDialog>
  );
};

export default CampaignGroupForm;
