import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { TaskFormValues } from "src/models/Task.model";
import { useAddTaskMutation } from "src/services/TaskService";
import { showToast } from "src/utils/showToaster";
import { string, object } from "yup";
import AddTaskInsideProject from "./AddTaskInsideProjectForm";

type Props = {
  taskFeatureId: any;
  taskProjectId: any;
  taskModuleTo: any;
  taskRelatedTo: any;
  onClose: () => void;
};
const AddAllTaskInsideProjectWrapper = ({
  taskFeatureId,
  taskProjectId,
  onClose,
  taskModuleTo,
  taskRelatedTo,
}: Props) => {
  const [addTask] = useAddTaskMutation();
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  const initialValues: TaskFormValues = {
    title: "",
    layer: [],
    label: [],
    estimation: "",
    featureId: taskFeatureId,
    projectId: taskProjectId,
    moduleId: taskModuleTo,
    description: "",
    assignedTo: "",
    dueDate: "",
    relatedTo: taskRelatedTo,
  };

  const handleSubmit = (
    values: TaskFormValues,
    { setSubmitting, resetForm }: FormikHelpers<TaskFormValues>
  ) => {
    const { assignedTo, label, ...rest } = values;
    const formattedValues = {
      ...rest,
      assignedTo: assignedTo?.value,
      label: [
        {
          labelName: "OPEN",
          status: "OPEN",
        },
      ],
    };
    addTask(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  const validationSchema = object().shape({
    title: string().required("Please enter title"),
    assignedTo: object().test(
      "assignedTo",
      "assignee is required",
      (value, context) => {
        const { relatedTo } = context.parent;
        if (relatedTo?.value === "PROJECT" && !value) {
          return false;
        } else {
          return true;
        }
      }
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Form>
          <AddTaskInsideProject
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddAllTaskInsideProjectWrapper;
