import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { LeaveRequestFormValues } from "src/models/LeaveReques.model";
import {
  useGetLeaveRequestByIdQuery,
  useUpdateLeaveRequestMutation,
} from "src/services/LeaveRequestService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import HalfDayLeaveRequestForm, {
  WhichPart,
} from "../Layouts/HalfDayLeaveRequestForm";
import { getApprovalsInfo } from "../utils/getApprovalsInfo";

type Props = {
  handleClose: any;
  leaveRequestId: string;
};

const EditHalfDayLeaveRequestFormWrapper = ({
  handleClose,
  leaveRequestId,
}: Props) => {
  // get  members
  const { codioticUsers, isDataLoading } = useGetAllCodioticUsers();
  const { data, isLoading, isFetching } =
    useGetLeaveRequestByIdQuery(leaveRequestId);
  const [updateLeaveRequest] = useUpdateLeaveRequestMutation();
  const [leaveInfo, setLeaveInfo] = useState<any>();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setLeaveInfo(data?.data);
    }
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: LeaveRequestFormValues = {
    reason: leaveInfo?.reason,
    leaveStartDate: leaveInfo?.leaveStartDate,
    reportingDate: leaveInfo?.reportingDate,
    leaveType: "PLANNED",
    daysOfLeave: leaveInfo?.daysOfLeave,
    dayPart: {
      label: WhichPart?.find((part) => part?.value === leaveInfo?.dayPart)
        ?.label,
      value: leaveInfo?.dayPart,
    },
    leavingTime: leaveInfo?.leavingTime,
    arrivalTime: leaveInfo?.arrivalTime,
    approvals: getApprovalsInfo(leaveInfo),
    buddy: {
      label: leaveInfo?.buddyName,
      value: leaveInfo?.buddy,
    },
  };

  // Validation Schema
  const validationSchema = object().shape({
    dayPart: object().required("Please select day part"),
    leaveStartDate: string().required("Please enter date"),
    arrivalTime: string().test(
      "arrTimeTest",
      "Please select arrival time",
      (value, context) => {
        const { dayPart } = context.parent;
        return (dayPart?.value === "FIRST_HALF" ||
          dayPart?.value === "FOR_HOURS") &&
          value
          ? true
          : dayPart?.value === "SECOND_HALF"
            ? true
            : false;
      }
    ),
    leavingTime: string().test(
      "arrTimeTest",
      "Please select leaving time",
      (value, context) => {
        const { dayPart } = context.parent;
        return (dayPart?.value === "SECOND_HALF" ||
          dayPart?.value === "FOR_HOURS") &&
          value
          ? true
          : dayPart?.value === "FIRST_HALF"
            ? true
            : false;
      }
    ),
    buddy: object().required("Please select buddy"),
    reason: string().required("Please enter reason"),
    approvals: object().required("Please select buddy"),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeaveRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<LeaveRequestFormValues>
  ) => {
    const {
      leaveType,
      approvals,
      buddy,
      leaveStartDate,
      reportingDate,
      dayPart,
      ...rest
    } = values;

    const formattedValues = {
      ...rest,
      leaveType: leaveType,
      dayPart: dayPart?.value,
      approvals: [approvals?.value],
      leaveStartDate: moment(leaveStartDate).format("YYYY-MM-DD"),
      reportingDate: moment(reportingDate).format("YYYY-MM-DD"),
      buddy: buddy?.value,
      leavingTime: dayPart?.value === "FIRST_HALF" ? "" : values.leavingTime,
      arrivalTime: dayPart?.value === "SECOND_HALF" ? "" : values.arrivalTime,
    };

    updateLeaveRequest({
      id: leaveRequestId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          handleClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <HalfDayLeaveRequestForm
              handleClose={handleClose}
              formikProps={formikProps}
              codioticUsers={codioticUsers}
              isDataLoading={isDataLoading}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditHalfDayLeaveRequestFormWrapper;
