import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { JobApplicationFormValues } from "src/models/JobApplication.model";
import { useAddJobApplicationMutation } from "src/services/JobApplicationService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import JobApplicationForm from "../Layouts/JobApplicationForm";
import moment from "moment";

const AddJobApplicationFormWrapper = () => {
  const { id: jobId } = useParams();
  const [addApplication] = useAddJobApplicationMutation();
  const navigate = useNavigate();
  // Form Initial Values
  const initialValues: JobApplicationFormValues = {
    jobId: "",
    platform: "",
    applicantName: "",
    applicantEmail: "",
    applicantPhoneNumber: "",
    resumeLink: "",
    platformApplicationURL: "",
    experience: [{
      profile: '',
      company: '',
      city: '',
      country: '',
      from: null,
      to: null,
      isPresent: false
    }],
    primerySkills: [],
    expectedPackageMin: "",
    expectedPackageMax: "",
    isExperienced: false,
    experienceRange: "",
    employmentType: "",
    joiningAvailability: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    applicantName: string().required("Please enter name"),
    applicantEmail: string()
      .email("Please enter valid email")
      .required("Please enter email"),
    applicantPhoneNumber: string()
      .min(10, "Invalid number")
      .max(10, "Invalid number")
      .required("Please enter mobile number"),
    resumeLink: object().required("Please upload resume"),
    // platformApplicationURL: string().url("Please enter valid link"),
    // joiningAvailability: object().required("Please select joining type"),
    // employmentType: object().required("Please select employment type"),
    // experienceRange: object().test(
    //   "exp-test",
    //   "Please select experience",
    //   (value, context) => {
    //     const { isExperienced } = context.parent;
    //     return isExperienced ? (value ? true : false) : true;
    //   }
    // ),
    // expectedPackageMin: string().required("Please enter min package"),
    // expectedPackageMax: string().required("Please enter max package"),
    // primerySkills: array()
    //   .min(1, "Please enter the skills")
    //   .required("Please enter the skills"),
  });

  // Handle Submit
  const handleSubmit = (
    values: JobApplicationFormValues,
    { setSubmitting, resetForm }: FormikHelpers<JobApplicationFormValues>
  ) => {
    const formattedValues = {
      jobId: jobId || "",
      platform: "admin",
      applicantName: values?.applicantName,
      applicantEmail: values?.applicantEmail,
      applicantPhoneNumber: values?.applicantPhoneNumber,
      resumeLink: values?.resumeLink?.url,
      platformApplicationURL: values?.platformApplicationURL,
      primarySkills: values.primerySkills?.map((skill: any) => skill.value),
      expectedPackageMin: values?.expectedPackageMin || 0,
      expectedPackageMax: values?.expectedPackageMax || 0,
      isExperienced: values?.isExperienced,
      experienceRange: values?.experienceRange?.value,
      employmentType: values?.employmentType?.value || "",
      joiningAvailability: values?.joiningAvailability?.value || "",
      experience: (values?.experience || [])?.map(
        (experience: any) => ({
          profile: experience?.profile,
          company: experience?.company,
          city: experience?.city,
          country: experience?.country,
          from: experience?.from ? moment(experience?.from).format('YYYY-MM-DD') : '',
          to: experience?.to ? moment(experience?.to).format('YYYY-MM-DD') : '',
          isPresent: experience?.isPresent
        })
      ),
    };
    addApplication(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          navigate(`/job-onboard/job-creation/view/${jobId}/job-application`);
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <JobApplicationForm
            formikProps={formikProps}
            formHeading="ADD"
            onClose={() =>
              navigate(
                `/job-onboard/job-creation/view/${jobId}/job-application`
              )
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddJobApplicationFormWrapper;
