import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setType } from "src/redux/slices/ClientLeadgerSlice";
import { AppDispatch, RootState } from "src/redux/store";
import ClientLedgerFilterFormDialog from "./ClientLedgerFilterFormDialog";

type Props = {
  open: boolean;
  onClose: () => void;
};

const ClientLedgerFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const meetingState = useSelector((state: RootState) => state.clientLeadger);
  const { type } = meetingState;

  const initialValues: any = {
    recurranceType: type
  };

  // Submit Handler
  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    setSubmitting(false);

    // project filter dispatch
    dispatch(setType(values.recurranceType) || []);

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<any>) => {
    // created date filter dispatch

    // project filter dispatch
    await dispatch(setType([]));

    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ClientLedgerFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ClientLedgerFilterFormDialogWrapper;
