import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { BiPaperPlane } from "react-icons/bi";
import { MdBlock, MdEdit } from "react-icons/md";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { ClientUserListResponse } from "src/models/ClientUser.model";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/ClientUserSlice";
import { RootState } from "src/redux/store";
import { useGetClientRefreshTokenMutation } from "src/services/AuthServices";
import {
  useChangeStatusMutation,
  useGetAllUsersWithPaginationQuery,
} from "src/services/ClientUserServices";
import { showToast } from "src/utils/showToaster";
import EditClientUserFormWrapper from "../Edit/EditClientUserFormWrapper";
import ClientUserList from "./ClientUserList";
import { CLIENT_PANEL_URL } from "src/utils/constants";
import { RiLockPasswordFill } from "react-icons/ri";
import ChangeClientUserPasswordWrapper from "./ChangeClientUserPassword/ChangeClientUserPasswordWrapper";

type Props = {};

const ClientUserListWrapper = (props: Props) => {
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);

  const [clientId, setClientId] = useState<string>("");
  const [openClientUserPassword, setIsOpenClientUserPassword] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.clientUser);

  const { data, isLoading, isFetching } = useGetAllUsersWithPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["name", "email", "mobile"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "name",
    orderByValue: 1,
    isPaginationRequired: true,
  });

  const [activeUpdateClientUser] = useChangeStatusMutation();
  const [getClientRefreshToken, getClientRefreshTokenInfo] =
    useGetClientRefreshTokenMutation();

  // Setting Team Members Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleChangeUserStatus = (id: string) => {
    activeUpdateClientUser(id)
      .then((res: any) => {
        showToast("success", "status change successfully");
      })
      .catch((err: any) => {
        console.error("error", err);
      });
  };

  const columns: columnTypes[] = [
    {
      field: "name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.CLIENT_USERS_LIST_NAME,
    },
    {
      field: "mobile",
      headerName: "Number",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.CLIENT_USERS_LIST_MOBILE,
    },
    {
      field: "email",
      headerName: "Email",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[220px]",
      name: UserModuleNameTypes.CLIENT_USERS_LIST_EMAIL,
    },
    {
      field: "clientOrgLabel",
      headerName: "Client",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[220px]",
      name: UserModuleNameTypes.CLIENT_USERS_LIST_CIENT,
      renderCell: (row: ClientUserListResponse) => (
        <div className="capitalize"> {row?.clientOrgLabel} </div>
      ),
    },
    {
      field: "canApproveETA",
      headerName: "ETA Access",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.CLIENT_USERS_LIST_ETA_ACCESS,
      renderCell: (row: ClientUserListResponse) => (
        <div> {row?.canApproveETA ? "Yes" : "No"} </div>
      ),
    },
    {
      field: "canCloseTicket",
      headerName: "Close Ticket Access",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.CLIENT_USERS_LIST_CLOSE_TICKET_ACCESS,
      renderCell: (row: ClientUserListResponse) => (
        <div> {row?.canCloseTicket ? "Yes" : "No"} </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      // flex: "flex-[0.75_0.75_0%]",
      // align: "end",
      extraClasses: "min-w-[100px]",
      renderCell: (row: ClientUserListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditFormDialog(true);
                  setClientId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_CLIENT_USERS_EDIT,
              },
              {
                label: row.isActive ? (
                  <div className="flex items-center gap-x-2">
                    <MdBlock color="red" /> Deactivate
                  </div>
                ) : (
                  <div className="flex items-center gap-x-2">
                    <VscActivateBreakpoints color="#00a67e" /> Activate
                  </div>
                ),
                onClick: () => handleChangeUserStatus(row?._id),
                acitonName: UserModuleNameTypes.ACTION_CLIENT_USERS_DEACTIVE,
              },
              {
                label: row?.isActive && (
                  <div className="flex items-center gap-x-2">
                    <BiPaperPlane color="#000000" />
                    Go To Client Panel
                  </div>
                ),
                onClick: () => {
                  getClientRefreshToken(row?._id).then((res: any) => {
                    if (res?.error) {
                      showToast("error", res?.error?.data?.message);
                    } else {
                      if (res?.data?.status) {
                        window.open(
                          `${CLIENT_PANEL_URL}/?refreshToken=${res?.data?.refreshToken}`,
                          "_blank"
                        );
                      } else {
                        showToast("error", res?.data?.message);
                      }
                    }
                  });
                },
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <RiLockPasswordFill color="#000000" /> Change Password
                  </div>
                ),
                onClick: () => {
                  setClientId(row?._id);
                  setIsOpenClientUserPassword(true);
                },
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      {getClientRefreshTokenInfo?.isLoading && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <ClientUserList
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenEditFormDialog ? (
        <EditClientUserFormWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedClient={clientId}
        />
      ) : null}
      {openClientUserPassword ? (
        <ChangeClientUserPasswordWrapper
          onClose={() => setIsOpenClientUserPassword(false)}
          selectedClient={clientId}
        />
      ) : null}
    </div>
  );
};

export default ClientUserListWrapper;
