import React, { useEffect } from "react";
import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { IncreamentFormValues } from "./AddIncreamentFormWrapper";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<IncreamentFormValues>;
  onClose: () => void;
};

const AddIncreamentForm = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  useEffect(() => {
    if (Number(values?.incrementPercent) > 0) {
      setFieldValue(
        "newSalary",
        Number(values?.oldSalary) * (Number(values?.incrementPercent) / 100) +
          Number(values?.oldSalary)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.incrementPercent]);

  return (
    <MOLFormDialog
      title={"Add Increment "}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMTextField
            name="incrementPercent"
            required
            value={values?.incrementPercent}
            onChange={(e) => {
              setFieldValue("incrementPercent", e.target.value);
            }}
            label="Increment Percent"
            placeholder="Enter Increment Percent"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMDatePicker
            required
            label="Increment Effective Date"
            name="incrementEffectiveDate"
            format="DD/MM/YYYY"
            value={values?.incrementEffectiveDate}
            onChange={(newValue) =>
              setFieldValue("incrementEffectiveDate", newValue)
            }
          />
        </div>
        <div>
          <ATMTextField
            disabled
            name="newSalary"
            value={values?.newSalary}
            onChange={(e) => {
              setFieldValue("newSalary", e.target.value);
            }}
            label="New Salary"
            placeholder="Enter New Salary"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMTextField
            disabled
            name="oldSalary"
            value={values?.oldSalary}
            onChange={(e) => {
              setFieldValue("oldSalary", e.target.value);
            }}
            label="Old Salary"
            placeholder="Enter Old Salary"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default AddIncreamentForm;
