/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import moment from "moment";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import MeetingListFilterFormDialog from "./MeetingListFilterFormDialog";
import { setProjectsFilter, setMeetingWithFilter, setCodioticUser, setModeFilter, setMeetingTypeFilter, setDateFilter, setNotCompletedMeeting } from "src/redux/slices/MeetingSlice";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  startDate: string | null;
  endDate: string | null;
  project: string[];
  meetingWith: string[];
  mode: string[];
  meetingTypeFilter: string[]
  codioticUser: any
  notCompletedMeeting: any
};

const MeetingListFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const meetingState = useSelector((state: RootState) => state.meeting);
  const {
    projects,
    meetingWith,
    mode,
    notCompletedMeeting,
    dateFilter,
    codioticUser,
    meetingTypeFilter,
  } = meetingState;

  // Initial Values
  //   const initialValues: FormInitialValues = {   // TODO -> if we want to set initial date from starting date to end month
  //     startDate: dateFilter.start_date
  //       ? dateFilter.start_date
  //       : moment().startOf("month").format("YYYY-MM-DD"),
  //     endDate: dateFilter.end_date
  //       ? dateFilter.end_date
  //       : moment().endOf("month").format("YYYY-MM-DD"),
  //   };

  const initialValues: FormInitialValues = {
    startDate: dateFilter.start_date,
    endDate: dateFilter.end_date,
    project: projects,
    meetingWith: meetingWith,
    mode: mode,
    codioticUser: codioticUser,
    meetingTypeFilter: meetingTypeFilter,
    notCompletedMeeting: notCompletedMeeting
  };


  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);

    // created date from to dispatch
    dispatch(
      setDateFilter({
        start_date: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        end_date: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
      })
    );

    // project filter dispatch
    dispatch(setProjectsFilter(values.project));

    dispatch(setMeetingWithFilter(values?.meetingWith));
    dispatch(setCodioticUser(values?.codioticUser));

    dispatch(setModeFilter(values?.mode));

    dispatch(setNotCompletedMeeting(values.notCompletedMeeting));


    // ticket filter via client side ticket status
    dispatch(setMeetingTypeFilter(values.meetingTypeFilter));

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        start_date: "",
        end_date: "",
      })
    );



    // project filter dispatch
    await dispatch(setProjectsFilter([]));
    await dispatch(setNotCompletedMeeting([]));

    await dispatch(setMeetingWithFilter([]));
    await dispatch(setCodioticUser([]));

    await dispatch(setModeFilter([]));

    // client side ticket status
    await dispatch(setMeetingTypeFilter([]));

    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <MeetingListFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MeetingListFilterFormDialogWrapper;
