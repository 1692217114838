import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { DeliveryDateChangeStatusValues } from "src/models/DeliveryDate.model";
import { useChangeStatusDeliveryDateMutation } from "src/services/DeliveryDateService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import ChangeStatusForm from "./ChangeStatusForm";

type Props = {
  onClose: () => void;
  selectedDeliveryDate: string;
};

const ChangeStatusFormWrapper = ({ onClose, selectedDeliveryDate }: Props) => {
  const [changeStatus] = useChangeStatusDeliveryDateMutation();

  // Form Initial Values
  const initialValues: DeliveryDateChangeStatusValues = {
    actualDeliveryDate: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    actualDeliveryDate: string().required("Please select date "),
  });

  // Handle Submit
  const handleSubmit = (
    values: DeliveryDateChangeStatusValues,
    { setSubmitting, resetForm }: FormikHelpers<DeliveryDateChangeStatusValues>
  ) => {
    changeStatus({
      id: selectedDeliveryDate,
      body: {
        actualDeliveryDate: moment(values?.actualDeliveryDate)?.format(
          "YYYY-MM-DD"
        ),
      },
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ChangeStatusForm formikProps={formikProps} onClose={onClose} />
        </Form>
      )}
    </Formik>
  );
};
export default ChangeStatusFormWrapper;
