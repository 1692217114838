import apiSlice from "./ApiSlice";

export const dashbordApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    getAllActivityOfUser: builder.query({
      providesTags: ["dashboard", "meeting" , "task"],
      query: (body) => {
        return {
          url: "/dashboard/today-activity",
          method: "POST",
          body,
        };
      },
    }),
     // GET ACTIVITY COUNTS 
     getUserEvents: builder.query({
      providesTags: ["dashboard", "meeting" , 'task'],
      query: (body) => {
        return {
          url: "/dashboard/counts",
          method: "POST",
          body,
        };
      },
    }),
    //Get deployment request data
    getPendingDeploymentRequest: builder.query({
      providesTags: ["deployment-request"],
      query: () => {
        return {
          url: "/dashboard/deployment-count",
          method: "GET",
        };
      },
    }),
    //Get deployment request data
    getPendingResourceRequest: builder.query({
      providesTags: ["resource-request"],
      query: () => {
        return {
          url: "/dashboard/resource-count",
          method: "GET",
        };
      },
    }),
    //Get deployment request data
    getPendingDoubtRequest: builder.query({
      providesTags: ["doubts"],
      query: () => {
        return {
          url: "/dashboard/doubt-count",
          method: "GET",
        };
      },
    }),


    //Get deployment request data
    getDashboardReminder: builder.query({
      providesTags: ["reminder"],
      query: () => {
        return {
          url: "/dashboard/reminders",
          method: "GET",
        };
      },
    }),

    // Financr Report
    getDashboardFinanceReport: builder.query({
      providesTags: ["dashboard"],
      query: (body) => {
        return {
          url: "/finance-dashboard/",
          method: "POST",
          body,
        };
      },
    }),
    getFinanceDashboardQuarterlyReport: builder.query({
      providesTags: ["dashboard"],
      query: (body) => {
        return {
          url: "/finance-dashboard/quarterly-report",
          method: "POST",
          body,
        };
      },
    }),
    // Goals Query
    getDashboardGoals: builder.query({
      providesTags: ["dashboard"],
      query: () => {
        return {
          url: "/dashboard/goals",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllActivityOfUserQuery,
  useGetPendingDeploymentRequestQuery,
  useGetDashboardReminderQuery,
  useGetPendingDoubtRequestQuery,
  useGetPendingResourceRequestQuery,
  useGetDashboardFinanceReportQuery,
  useGetDashboardGoalsQuery,
  useGetFinanceDashboardQuarterlyReportQuery ,
  useGetUserEventsQuery
} = dashbordApi;
