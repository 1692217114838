import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { EditRequirementFormValues } from "src/models/Requirement.model";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import EditRequirement from "./EditRequirement";
import {
  useGetRequirementByIdQuery,
  useUpdateRequirementByIdMutation,
} from "src/services/RequirementService";
import CircularProgress from "@mui/material/CircularProgress";

const featureType = [
  {
    label: "Other",
    value: "OTHER",
  },
  {
    label: "Table",
    value: "TABLE",
  },
  {
    label: "Form",
    value: "FORM",
  },
  {
    label: "View",
    value: "VIEW",
  },
];

const fieldType = [
  {
    label: "Select",
    value: "SELECT",
  },
  {
    label: "Multi-Select",
    value: "MULTISELECT",
  },
  {
    label: "Text-Field",
    value: "TEXTFIELD",
  },
  {
    label: "Check-box",
    value: "CHECKBOX",
  },
  {
    label: "Radio",
    value: "RADIO",
  },
  {
    label: "Switch",
    value: "SWITCH",
  },
  {
    label: "HTML-Editor",
    value: "HTMLEDITOR",
  },
  {
    label: "TextArea",
    value: "TEXTAREA",
  },
  {
    label: "File Uploader",
    value: "FILEUPLOADER",
  },
];

const EditRequirementWrapper = () => {
  const { singleFeatureId } = useParams();
  const { id } = useParams();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const moduleId = queryParams.get("moduleId");
  const featureId = queryParams.get("featureId");
  const currentPath = location.pathname;
  const [items, setItems] = useState<any>({});

  const idOrFeatureId =
    currentPath === `/project/${id}/details/edit` ? featureId : singleFeatureId;

  const { isLoading, isFetching, data } = useGetRequirementByIdQuery(
    idOrFeatureId || "",
    {
      skip: !idOrFeatureId, // Skipping the query if idOrFeatureId is falsy (null or undefined)
    }
  );

  const [updateRequirement] = useUpdateRequirementByIdMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: EditRequirementFormValues = {
    projectId: { _id: items?.projectId, projectName: items?.projectName },
    moduleId: { _id: items?.moduleId, label: items?.moduleName },
    featureName: items?.featureName,
    // featureId: { _id: items?.featureId, featureName: items?.featureName },
    featureType: { label: items?.featureType, value: items?.featureType },
    requirementFlow: items?.requirementFlow,
    requirementLogic: items?.requirementLogic,
    tableName: items?.tableName,
    formFields: (items?.formFields || [])?.map((item: any) => ({
      name: item?.name || "",
      type:
        {
          label: item?.type,
          value: item?.type,
        } || "",
      validation: item?.validation || "",
      logic: item?.logic || "",
      isRequired: item?.isRequired,
    })),

    tableFields: (items?.tableFields || [])?.map((item: any) => ({
      columnName: item?.columnName || "",
    })),

    table: (items?.table || [])?.map((item: any) => ({
      tableAction: item?.tableAction || "",
    })),
    columns: (items?.columns || [])?.map((item: any) => ({
      columnActions: item?.columnActions || "",
    })),
    viewFields: (items?.viewFields || [])?.map((item: any) => ({
      tabs: item?.tabs || "",
      detailsVisible: item?.detailsVisible || "",
      logic: item?.logic || "",
    })),
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select project name"),
    moduleId: object().required("Please select module name"),
    featureName: string().required("Please enter feature name"),
    featureType: object().required("Please select feature type"),
  });

  // // Handle Submit
  const handleSubmit = (
    values: EditRequirementFormValues,
    { setSubmitting, resetForm }: FormikHelpers<EditRequirementFormValues>
  ) => {
    const formattedValues = {
      projectId: values?.projectId?._id,
      moduleId: values?.moduleId?._id,
      featureName: values?.featureName,
      featureType: values?.featureType?.value,
      requirementFlow: values?.requirementFlow,
      requirementLogic: values?.requirementLogic,
      tableName: values?.tableName,
      formFields: values?.formFields?.map((ele: any) => {
        return {
          isRequired: ele?.isRequired,
          logic: ele?.logic,
          dataSource: ele?.dataSource,
          name: ele?.name,
          type: ele?.type?.value,
          validation: ele?.validation,
        };
      }),
      tableFields:
        values?.tableFields[0]?.columnName === "" ? [] : values?.tableFields,
      table: values?.table[0]?.tableAction === "" ? [] : values?.table,
      columns: values?.columns[0]?.columnActions === "" ? [] : values?.columns,
      viewFields: values?.viewFields,
    };
    updateRequirement({
      id: idOrFeatureId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          if (currentPath === `/project/${id}/details/edit`) {
            navigate(
              `/project/${id}/details/?moduleId=${moduleId}&featureId=${featureId}&tab=feature-details`
            );
          } else {
            navigate("/requirement");
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <EditRequirement
            formikProps={formikProps}
            id={id}
            currentPath={currentPath}
            featureType={featureType}
            fieldType={fieldType}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditRequirementWrapper;
