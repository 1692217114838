import { ErrorMessage, FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { MeetingNoteTypeListResponse } from "src/models/MeetingTypeNotes.model";
import { MeetingNotesTabFormValues } from "../AddNotes/AddMeetingNotesFormWrapper";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
// import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";

type Props = {
  formikProps: FormikProps<MeetingNotesTabFormValues>;
  formType?: "Add" | "Edit";
  actualClientUsers: any[];
  isAllMeetingNotetypeDataLoading?: boolean;
  allMeetingNoteType: any;
  showSelect?: any;
  setShowSelect?: any;
};

const AddMeetingNotesForm = ({
  formikProps,
  formType,
  actualClientUsers,
  isAllMeetingNotetypeDataLoading,
  allMeetingNoteType,
  showSelect,
  setShowSelect,
}: Props) => {
  const {
    values,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    handleBlur,
    setFieldTouched,
  } = formikProps;

  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  return (
    <>
      {/* Notes Editor & save button */}
      <div className="flex flex-col h-full gap-4 overflow-hidden">
        {/* Notes */}
        <div className="z-0 my-1 meeting ">
          <MOLMarkdownEditor
            label=""
            value={values.notes}
            placeholder="Write notes or drag your files here...."
            onChange={(newValue: any) => {
              setFieldValue("notes", newValue);
              setFieldTouched("notes", false);
            }}
          />

          <ErrorMessage
            name="notes"
            component="div"
            className="font-poppins absolute text-[14px] text-start mt-0 text-red-500"
          />
        </div>
        <div className="grid grid-cols-2 gap-2">
          {/* Meeting note type */}
          <div className="col-span-1">
            <ATMMultiSelect
              required
              name="meetingNoteTypeId"
              label=""
              placeholder="Select Note Type"
              value={values.meetingNoteTypeId}
              isLoading={isAllMeetingNotetypeDataLoading}
              options={allMeetingNoteType?.map(
                (ele: MeetingNoteTypeListResponse) => {
                  return {
                    label: ele?.meetingNoteType,
                    value: ele?._id,
                  };
                }
              )}
              onChange={(newValue) => {
                setFieldValue("meetingNoteTypeId", newValue);
              }}
            />
          </div>

          {/* Client User */}
          <div className="col-span-1">
            <ATMSelect
              required
              name="clientUserId"
              label=""
              placeholder="Select Client User"
              value={values?.clientUserId}
              options={actualClientUsers?.map((ele: any) => {
                return {
                  label: ele,
                  value: ele,
                };
              })}
              onChange={(newValue: any) => {
                setFieldValue("clientUserId", newValue);
              }}
            />
          </div>
          {/* Note Title */}
          <div className="pt-2 ">
            <ATMTextField
              name="title"
              value={values.title}
              onChange={(e) => setFieldValue("title", e.target.value)}
              label=""
              className="w-full pr-2 rounded border-slate-200"
              placeholder="Enter Title"
              onBlur={handleBlur}
            />
          </div>
          {formType === "Add" && (
            <div className="mt-5 ml-2">
              <ATMCheckbox
                label="Reminder?"
                name="setReminder"
                checked={showSelect}
                onChange={(checked) => {
                  setShowSelect(checked);
                  if (!checked) {
                    setFieldValue("userData", []);
                  }
                }}
              />
            </div>
          )}
          <div>
            <div className="grid grid-cols-2 mt-2 items-center">
              {formType === "Add" && showSelect && (
                <div className="mr-3">
                  <ATMMultiSelect
                    name="userData"
                    value={values.userData}
                    onChange={(newValue) => {
                      setFieldValue("userData", newValue);
                    }}
                    label="For Whom?"
                    placeholder="Select For Whom?"
                    isLoading={isCodioticUserDataLoading}
                    options={codioticUsers?.map(
                      (ele: CodioticUserListResponse) => {
                        return {
                          label: ele?.name,
                          value: ele?._id,
                        };
                      }
                    )}
                  />
                </div>
              )}

              <div className=" mt-7">
                <ATMLoadingButton
                  isLoading={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  Save
                </ATMLoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMeetingNotesForm;
