/* eslint-disable @typescript-eslint/no-unused-vars */
import { PieChart } from "react-minimal-pie-chart";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import {
  setDateFilter,
  setDateFilterQuarterly
} from "src/redux/slices/FinanceDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { LineChart } from "@mui/x-charts";

type Props = {
  financeReportData: any
  financeQuarterlyReportData: any
};

const FinanceDashboardListing = ({
  financeReportData,
  financeQuarterlyReportData
}: Props) => {
  const dispatch = useDispatch()
  const {
    dateFilter,
    dateFilterQuarterly
  } = useSelector((state: RootState) => state.financeDashboard);

  const chartData = [
    { title: "Expense", value: financeReportData?.expenseAmt, color: "#b54747" },
    { title: "Transaction", value: financeReportData?.transactionAmt || 0, color: "#1b8040" },
  ];

  const expenseData = financeQuarterlyReportData?.map((data: any) => data?.totalExpense) || [];
  const transactionData = financeQuarterlyReportData?.map((data: any) => data?.totalTransactions) || [];
  const xLabels = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return (
    <div className="px-4">
      <div className="mt-4 text-lg md:text-xl font-medium"> Finance Dashboard</div>
      <div className="grid grid-cols-12 h-full mt-8 gap-6">
        <div className="col-span-5">
          <div className="flex ">
            <div className="flex-grow px-2 py-1">
              <ATMDatePicker
                label="Start Date"
                name=""
                format="DD/MM/YYYY"
                value={dateFilter.startDate}
                onChange={(newValue) => {
                  dispatch(setDateFilter({ ...dateFilter, startDate: newValue }));
                }}
              />
            </div>
            <div className="flex-grow px-2 py-1">
              <ATMDatePicker
                label="End Date"
                name=""
                format="DD/MM/YYYY"
                value={dateFilter.endDate}
                onChange={(newValue) => {
                  dispatch(setDateFilter({ ...dateFilter, endDate: newValue }));
                }}
              />
            </div>
          </div>
          <div className="w-[300px] m-0 p-0 h-[300px] ">
            {financeReportData ?
              <PieChart
                animate
                animationDuration={500}
                animationEasing="ease-out"
                center={[50, 50]}
                viewBoxSize={[90, 90]}
                data={chartData}
                labelPosition={68}
                lengthAngle={360}
                lineWidth={50}
                paddingAngle={0}
                radius={30}
                startAngle={0}
                className=""
              /> :
              <div className="ml-5">
                <img height={200} src="/piechartnodata.jpg" alt='no data found' />
              </div>
            }
          </div>
          <div className="font-semibold text-[18px]">
            Total Transactions : <span className="text-green-600"> &#8377; {financeReportData?.transactionAmt || 0} </span>{" "}
          </div>
          <div className="font-semibold text-[18px]">
            Total Expense :{" "}
            <span className=" text-red-600 "> &#8377; {financeReportData?.expenseAmt || 0}</span>{" "}
          </div>

        </div>
        <div className="col-span-7">
          {/* <div className="flex-grow px-2 py-1">
            <ATMDatePicker
              label="Start Date"
              name=""
              format="YYYY"
              value={dateFilterQuarterly.startDate}
              onChange={(newValue) => {
                dispatch(setDateFilterQuarterly({ ...dateFilter, startDate: newValue }));
              }}
            />
          </div> */}
          <LineChart
            width={520}
            height={420}
            series={[
              { data: transactionData, label: 'Transaction' },
              { data: expenseData, label: 'Expense' },
            ]}
            xAxis={[{ scaleType: 'point', data: xLabels }]}
          />
        </div>
      </div>
    </div>
  );
};

export default FinanceDashboardListing;
