import moment from "moment";
import React, { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { FaSms } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import {
  IoIosArrowDown,
  IoIosArrowForward,
} from "react-icons/io";
import { PiNotePencilBold } from "react-icons/pi";
import { SiGotomeeting } from "react-icons/si";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import AddLeadContactsDialogFormWrapper from "../../AddLeadContactsDialog/AddLeadContactsDialogFormWrapper";
type Props = {
  leadDetails: any;
  actionsData: any;
  actionDataIsLoading: boolean;
};
export const ATMShowField = ({ label, value }: any) => {
  return (
    <div className="flex flex-col  ">
      {label && <label className="text-[12px] mx-1">{label}</label>}
      <div className="text-[13px]  font-medium text-primary-dark px-1">
        {value}{" "}
      </div>
    </div>
  );
};
const IconSwitch = (actionType: string) => {
  switch (actionType) {
    case "NOTE":
      return {
        name: "note",
        icon: <PiNotePencilBold size="1.2em" color="#425b76" />,
      };
    case "EMAIL":
      return {
        name: "email",
        icon: <AiOutlineMail size="1.2em" color="#425b76" />,
      };
    case "CALL":
      return {
        name: "call",
        icon: <FiPhoneCall size="1.2em" color="#425b76" />,
      };
    case "MEETING":
      return {
        name: "meeting",
        icon: <SiGotomeeting size="1.2em" color="#425b76" />,
      };
    case "SMS":
      return {
        name: "sms",
        icon: <FaSms size="1.2em" color="#425b76" />,
      };
    default:
      return {
        name: "note",
        icon: <PiNotePencilBold size="1.2em" color="#425b76" />,
      };
  }
};

const OverView = ({ leadDetails, actionsData, actionDataIsLoading }: Props) => {
  const [isExpanedActivity, setIsExpanedActivity] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [isOpenAddContactDialog , setIsOpenAddContactDialog] = useState<any>()
  return (
    <div className="flex flex-col overflow-hidden ">
      <div className="m-2 shadow-lg p-3">
        <p className="text-slate-800 font-semibold text-sm">Data highlights</p>
        <div className="flex justify-evenly mt-2">
          <div>
            <p className="text-slate-800 font-semibold text-[13px]">
              CREATE DATE
            </p>
            <div className="flex gap-1">
              <div className="text-slate-800  text-[12px]">
                {formatedDateTimeIntoIst(leadDetails?.createdAt, "MM/DD/yyyy")},
              </div>
              <div className="text-[12px]  text-slate-800">
                {formatedDateTimeIntoIst(leadDetails?.createdAt, "hh:mm A")}
              </div>
            </div>
          </div>
          <div>
            <p className="text-slate-800 font-semibold text-[13px]">
              LIFECYCLE STAGE
            </p>
            <p className="text-xs">{leadDetails?.lifecycleStage || ""} </p>
          </div>
          <div>
            <p className="text-slate-800 font-semibold text-[13px]">
              LAST ACTIVITY DATE
            </p>
            <p className="text-xs">Today</p>
          </div>
        </div>
      </div>
      <div className="m-2 shadow-lg p-3  border rounded h-full overflow-hidden">
        <p className="text-slate-800 font-semibold text-sm px-2">
          Recent activities
        </p>
        <div className="flex flex-col gap-2 h-full overflow-y-scroll">
          {actionDataIsLoading ? (
            Array(6)
              .fill(0)
              .map((_, index) => {
                return (
                  <div key={index} className="animate-pulse  h-[95px]">
                    <div className="bg-slate-200 h-full rounded"> </div>
                  </div>
                );
              })
          ) : actionsData?.length ? (
            actionsData?.map((el: any, ind: any) => {
              if (el?.actionType === "TASK") {
                return (
                  <div
                    key={ind}
                    className="shadow-sm mt-2  border py-2 px-3 rounded-md"
                  >
                    <div
                      className={`flex justify-between  ${
                        isExpanedActivity && selectedId === el?._id
                          ? "border-b pb-2"
                          : ""
                      }`}
                    >
                      <div
                        className="flex gap-1 items-center text-[14px] cursor-pointer"
                        onClick={() => {
                          setIsExpanedActivity(!isExpanedActivity);
                          setSelectedId(el?._id);
                        }}
                      >
                        {isExpanedActivity && selectedId === el?._id ? (
                          <IoIosArrowDown color="#00a4bd" />
                        ) : (
                          <IoIosArrowForward color="#00a4bd" />
                        )}
                        <span className="text-slate-800 font-medium">Task</span>
                        <span>assigned to</span>
                        <span>{el?.assignedToName}</span>
                      </div>
                      <div className="flex items-center gap-1">
                       <div className="text-cyan-600 text-[13px] font-semibold"> Created At :</div>{" "}
                        <div className="text-[11px] font-medium text-slate-400">{moment(el?.createdAt).format("DD MMM yyyy hh:mm A")}</div>
                      </div>
                    </div>
                    {isExpanedActivity && selectedId === el?._id ? (
                      <div className="px-4">
                        <div className="grid grid-cols-6 py-2 border-b">
                          {el?.reminderForDate && (
                            <ATMShowField
                              value={moment(el?.reminderForDate).format(
                                "DD MMM yyyy"
                              )}
                              label="Due date"
                            />
                          )}
                          <ATMShowField
                            value={moment(el?.activityDate).format(
                              "DD MMM yyyy"
                            )}
                            label="Activity Date"
                          />
                          <ATMShowField
                            value={el?.taskName}
                            label="Task Name"
                          />
                          <ATMShowField value={el?.queueName} label="Queue" />
                          <ATMShowField
                            value={el?.taskAction?.toLowerCase()}
                            label="Action"
                          />
                        </div>
                        <div className="grid grid-cols-6 py-2">
                          <ATMShowField
                            value={el?.assignedToName}
                            label="Assigned To"
                          />
                          <ATMShowField
                            value={el?.createdByName}
                            label="CreatedBy"
                          />
                          <ATMShowField
                            value={el?.priority?.toLowerCase()}
                            label="Priority"
                          />
                          {el?.reminderOn && (
                            <ATMShowField
                              value={el?.reminderOn?.toLowerCase()}
                              label="ReminderOn"
                            />
                          )}
                        </div>
                        <div className="text-[13px] text-primary-dark font-medium  hover:bg-blue-50  rounded-lg p-2">
                          <ATMMarkdownContainer markdown={el?.description} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              } else {
                return (
                  <div
                    key={ind}
                    className="shadow-sm mt-2  border py-1 px-3 rounded-md"
                  >
                    <div
                      className={`flex justify-between  ${
                        isExpanedActivity && selectedId === el?._id
                          ? "border-b pb-2"
                          : ""
                      }`}
                    >
                      <div
                        onClick={() => {
                          setIsExpanedActivity(!isExpanedActivity);
                          setSelectedId(el?._id);
                        }}
                        className="flex gap-1 items-center text-[14px] cursor-pointer"
                      >
                        {isExpanedActivity && selectedId === el?._id ? (
                          <IoIosArrowDown color="#00a4bd" />
                        ) : (
                          <IoIosArrowForward color="#00a4bd" />
                        )}
                        <div className="rounded-full bg-slate-100 p-2 flex justify-center">
                          {IconSwitch(el?.logType)?.icon}
                        </div>
                        <span className="text-slate-500">
                          {el?.createdByName} create a{" "}
                          <span className="font-semibold">
                            {IconSwitch(el?.logType)?.name}{" "}
                          </span>
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        <div className="flex items-center text-cyan-600 text-[13px] font-semibold">
                        Created At : 
                        </div>
                        <div className="flex gap-1">
                          <div className="text-slate-400 font-medium text-[11px]">
                            {formatedDateTimeIntoIst(
                              el?.createdAt,
                              "DD MMM yyyy"
                            )}
                            ,
                          </div>
                          <div className="text-[11px] font-medium text-slate-400">
                            {formatedDateTimeIntoIst(el?.createdAt, "hh:mm A")}
                          </div>
                        </div>
                      </div>
                    </div>
                    {isExpanedActivity && selectedId === el?._id ? (
                      <div>
                        <div className="grid xl:grid-cols-6 lg:grid-cols-3 grid-cols-2 gap-4 my-3 border-b">
                          {el?.date && (
                            <ATMShowField
                              label="Activity Date "
                              value={formatedDateTimeIntoIst(
                                el?.date,
                                "DD MMM yyyy"
                              )}
                            />
                          )}
                          {el?.time && (
                            <ATMShowField
                              label="Activity Time"
                              value={moment(el?.time, "HH:mm").format("h:mm A")}
                            />
                          )}
                          {el?.assignedToName && (
                            <ATMShowField
                              label="Assigned To"
                              value={el?.assignedToName?.toLowerCase()}
                            />
                          )}

                          {el?.direction && (
                            <ATMShowField
                              label="Call Direction"
                              value={el?.direction?.toLowerCase()}
                            />
                          )}
                          {el?.callOutcome && (
                            <ATMShowField
                              label="Call Out Come"
                              value={el?.callOutcome?.toLowerCase()}
                            />
                          )}
                          {el?.meetingOutcome && (
                            <ATMShowField
                              label="Meeting OutCome"
                              value={el?.meetingOutcome?.toLowerCase()}
                            />
                          )}
                          {el?.meetingDuration && (
                            <ATMShowField
                              label="Meeting Duration"
                              value={el?.meetingDuration?.toLowerCase()}
                            />
                          )}
                          {el?.reminderForDate && (
                            <ATMShowField
                              label="Reminder Date"
                              value={el?.reminderForDate}
                            />
                          )}
                          {el?.reminderOn && (
                            <ATMShowField
                              label="Reminder On"
                              value={el?.reminderOn?.toLowerCase()}
                            />
                          )}
                          {el?.reminderRecordSource && (
                            <ATMShowField
                              label="Reminder Source"
                              value={el?.reminderRecordSource?.toLowerCase()}
                            />
                          )}
                        </div>
                        <div className="text-[13px] text-primary-dark font-medium  hover:bg-blue-50  rounded-lg p-2">
                          <ATMMarkdownContainer markdown={el?.description} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              }
            })
          ) : (
            <div className="flex justify-center items-end h-[120px] text-red-700 font-medium text-lg">
              No Data Found
            </div>
          )}
        </div>
      </div>
      <div className="m-2 shadow-lg p-3  border rounded h-full overflow-hidden">
        <div className="flex justify-between items-center">
          <p className="text-slate-800 font-semibold text-sm px-2">Contacts</p>
          <button onClick={()=>{
            setIsOpenAddContactDialog(true)
          }} className="text-[16px] border border-slate-800  rounded px-2 py-1 font-medium  hover:bg-hover">
            + Add
          </button>
        </div>
        <div className="overflow-x-auto mt-4">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-1 border-b-2 border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-1 border-b-2 border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-1 border-b-2 border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Mobile
                </th>
                {/* Add more columns as needed */}
              </tr>
            </thead>
            <tbody>
             
                {leadDetails?.contacts?.map((el:any , ind:number)=>{
                  return(
                    <tr key={ind}  className="hover:bg-gray-50">
                    <td className="px-6 py-2 border-b border-gray-200 text-sm leading-5 text-gray-900">
                    {el?.name || ''}
                  </td>
                  <td className="px-6 py-2 border-b border-gray-200 text-sm leading-5 text-gray-900">
                  {el?.email || ''}
                </td>
                <td className="px-6 py-2 border-b border-gray-200 text-sm leading-5 text-gray-900">
                {el?.mobile || ''}
                </td>
                  </tr>
                  )
                })}
                
              
            </tbody>
          </table>
        </div>
      </div>
      {isOpenAddContactDialog ? <AddLeadContactsDialogFormWrapper onClose={()=>setIsOpenAddContactDialog(false)} /> : null}
    </div>
  );
};

export default OverView;
