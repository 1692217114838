import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { LeadsValues } from "src/models/Leads.model";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { handleValidNumber } from "src/utils/validations/numberInput";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import useCampaign from "src/hooks/useCampaign";
import useChannel from "src/hooks/useChannels";
import useDomains from "src/hooks/useDomains";
import useCountries from "src/hooks/useCountries";
import useCountryStates from "src/hooks/useCountryStates";
import useStateCities from "src/hooks/useStateCities";
import useLeadsLabels from "src/hooks/useLeadsLabels";
import AccordianFormLayout from "src/components/layouts/AccordianFormLayout/AccordianFormLayout";
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import moment from "moment";

type Props = {
  formikProps: FormikProps<LeadsValues>;
};


const LeadsForm = ({ formikProps }: Props) => {
  const { values, setFieldValue, handleBlur, isSubmitting } = formikProps;

  const { Campaign, isDataLoading: isCampaignLoading } = useCampaign();
  const { Channel, isDataLoading: isChannelLoading } = useChannel();
  const { Domains, isDataLoading: isDomainsLoading } = useDomains();
  const { LeadsLabels, isDataLoading: isLeadsLabelsLoading } = useLeadsLabels();
  // get all codiotic user hook
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  // Country State City
  const { countries, isDataLoading: isCountryLoading } = useCountries();
  const { countryStates, isDataLoading: isCountryStateLoading } =
    useCountryStates(values?.country?.value);
  const { stateCities, isDataLoading: isStateCitiesLoading } = useStateCities(
    values?.state?.value
  );

  // Campaign  Option
  const CampaignNameOption = Campaign?.map((el: any) => {
    return {
      label: el?.campaignName,
      value: el?._id,
    };
  });
  // Channel  Option
  const ChannelNameOption = Channel?.map((el: any) => {
    return {
      label: el?.channelName,
      value: el?._id,
    };
  });
  // Domains Option
  const DomainsNameOption = Domains?.map((el: any) => {
    return {
      label: el?.domainName,
      value: el?._id,
    };
  });

  // Country Option
  const CountryOption = countries?.map((el: any) => {
    return {
      label: el?.countryName,
      value: el?._id,
    };
  });

  // State  Option
  const StateOption = countryStates?.map((el: any) => {
    return {
      label: el?.stateName,
      value: el?._id,
    };
  });
  // City Option
  const CityOption = stateCities?.map((el: any) => {
    return {
      label: el?.cityName,
      value: el?._id,
    };
  });
  // Leads Label  Option
  const LeadsLabelOption = LeadsLabels?.map((el: any) => {
    return {
      label: el?.leadLabelName,
      value: el?.leadLabelName,
    };
  });


  return (
    <div className="p-5 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">ADD LEADS</div>
      </div>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 xs:grid-cols-1 gap-4">
        {/* Assigned To */}
        <div className="mt-2">
          <ATMSelect
            name="assigned_to"
            value={values.assigned_to}
            onChange={(newValue) => {
              setFieldValue("assigned_to", newValue);
            }}
            label="Assigned To"
            placeholder="Select Assigned To"
            isLoading={isCodioticUserDataLoading}
            options={codioticUsers?.map((ele: CodioticUserListResponse) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
          />
        </div>
        {/* Name */}
        <div>
          <ATMTextField
            name="name"
            value={values?.name}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
            }}
            label="Name"
            onBlur={handleBlur}
          />
        </div>
        {/* Email */}
        <div className="">
          <ATMTextField
            name="email"
            value={values?.email}
            onChange={(e) => {
              setFieldValue("email", e.target.value);
            }}
            label="email"
            onBlur={handleBlur}
          />
        </div>
        {/* Mobile */}
        <div className="">
          <ATMTextField
            required
            name="mobile"
            value={values?.mobile}
            onChange={(e) => {
              handleValidNumber(e) && setFieldValue("mobile", e.target.value);
            }}
            label="Mobile"
            onBlur={handleBlur}
          />
        </div>

        {/* Alternate Mobile 1 */}
        <div className="">
          <ATMTextField
            name="alternate_1"
            value={values?.alternate_1}
            onChange={(e) => {
              handleValidNumber(e) &&
                setFieldValue("alternate_1", e.target.value);
            }}
            label="Alternate Mobile 1"
            onBlur={handleBlur}
          />
        </div>
        {/* Alternate Mobile 2 */}
        <div className="">
          <ATMTextField
            name="alternate_2"
            value={values?.alternate_2}
            onChange={(e) => {
              handleValidNumber(e) &&
                setFieldValue("alternate_2", e.target.value);
            }}
            label="Alternate Mobile 1"
            onBlur={handleBlur}
          />
        </div>
      </div>
      <AccordianFormLayout formHeading="SOCIAL MEDIA" isOpen={false} lableExtraClasses='text-primary-main font-medium'>

        <div className='grid grid-cols-3 gap-4  p-3' >
          {values?.socialMedias?.map((media: any, index: number) => {
            return (
              <ATMTextField
                name={`socialMedias.${[index]}.value`}
                value={values?.socialMedias?.[index]?.value}
                onChange={(e) => {
                  setFieldValue(`socialMedias.${[index]}.value`, e.target.value);
                }}
                label={`${media?.mediaName}`}
                onBlur={handleBlur}
              />
            )
          })}

        </div>
      </AccordianFormLayout>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 xs:grid-cols-1 gap-4">
        {/* Organization */}
        <div className="">
          <ATMTextField
            name="organization"
            value={values?.organization}
            onChange={(e) => {
              setFieldValue("organization", e.target.value);
            }}
            label="Organization Name"
            onBlur={handleBlur}
          />
        </div>
        {/* Country */}
        <div className="mt-2">
          <ATMSelect
            name="country"
            value={values?.country}
            onChange={(newValue) => {
              setFieldValue("country", newValue);
              setFieldValue("state", "");
              setFieldValue("district", "");
            }}
            label="Country"
            placeholder="Select Country"
            options={CountryOption}
            isLoading={isCountryLoading}
          />
        </div>
        {/* State */}
        <div className="">
          <ATMSelect
            name="state"
            value={values?.state}
            onChange={(newValue) => {
              setFieldValue("state", newValue);
              setFieldValue("district", "");
            }}
            label="State"
            placeholder="Select State"
            options={StateOption}
            isLoading={isCountryStateLoading}
          />
        </div>
        {/* District */}
        <div className="">
          <ATMSelect
            name="district"
            value={values?.district}
            onChange={(newValue) => {
              setFieldValue("district", newValue);
            }}
            label="District"
            placeholder="Select District"
            options={CityOption}
            isLoading={isStateCitiesLoading}
          />
        </div>
        {/* Next Followup  Date */}
        <div>
          <ATMDatePicker
            name="next_followUp_date"
            value={values?.next_followUp_date || null}
            onChange={(newValue) =>
              setFieldValue("next_followUp_date", newValue)
            }
            label="Next Followup  Date "
          />
        </div>
        {/* Next Followup Time */}
        <div className="mt-1">
        
          <ATMTextField
            type='time'
            name="next_followup_time"
            value={moment(values.next_followup_time, 'HH:mm').format('HH:mm')}
            required
            label="Next Followup Time"
            className="mt-0 rounded"
            onBlur={handleBlur}
            onChange={(e) => {
              let time = moment(e.target.value, 'HH:mm')
              setFieldValue('next_followup_time', time);
            }}
          />
        </div>
        {/* Leads Label */}
        <div className="">
          <ATMSelect
            name="leads_label"
            value={values?.leads_label}
            onChange={(newValue) => {
              setFieldValue("leads_label", newValue);
            }}
            label="Leads Label"
            placeholder="Select Leads Label"
            options={LeadsLabelOption}
            isLoading={isLeadsLabelsLoading}
          />
        </div>
        {/* Channel */}
        <div className="">
          <ATMSelect
            name="channel"
            value={values?.channel}
            onChange={(newValue) => {
              setFieldValue("channel", newValue);
            }}
            label="Channel"
            placeholder="Select Channel"
            options={ChannelNameOption}
            isLoading={isChannelLoading}
          />
        </div>
        {/* Campaign */}
        <div className="">
          <ATMSelect
            name="campaign"
            value={values?.campaign}
            onChange={(newValue) => {
              setFieldValue("campaign", newValue);
            }}
            label="Campaign"
            placeholder="Select Campaign"
            options={CampaignNameOption}
            isLoading={isCampaignLoading}
          />
        </div>
        {/* Domains */}
        <div className="">
          <ATMSelect
            name="domains"
            value={values?.domains}
            onChange={(newValue) => {
              setFieldValue("domains", newValue);
            }}
            label="Domains"
            placeholder="Select Domains"
            options={DomainsNameOption}
            isLoading={isDomainsLoading}
          />
        </div>
      </div>
      {/* Summary Note */}
      <div>
        <ATMTextArea
          name="summary_note"
          value={values?.summary_note}
          onChange={(newValue) => {
            setFieldValue("summary_note", newValue);
          }}
          label="Summary Note"
          placeholder="Select Summary Note"
        />
      </div>
      <div className='flex justify-end'>
        <ATMLoadingButton isLoading={isSubmitting} type="submit" className='flex justify-center w-40 text-[18px]'>
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default LeadsForm;
