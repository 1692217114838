import React from "react";
import { FormikProps } from "formik";
import moment from "moment";
import { useSelector } from "react-redux";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLLayerDropdown from "src/components/UI/molecules/MOLLayerDropdown/MOLLayerDropdown ";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { useGetProjectWiseMembers } from "src/hooks/useGetProjectWiseMembers";
import { TaskFormValues } from "src/models/Task.model";
import { RootState } from "src/redux/store";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<TaskFormValues>;
  onClose: () => void;
  saveNextChecked: any;
  setSaveNextChecked: any;
};

const AddTaskInsideProject = ({
  formikProps,
  onClose,
  saveNextChecked,
  setSaveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  // get project wise members
  const { allProjectWiseMembers, isDataLoading } = useGetProjectWiseMembers(
    values?.projectId || null
  );

  // Redux state for login user data
  const { userData } = useSelector((state: RootState) => state?.auth);

  return (
    <MOLFormDialog
      title={"Add New Task"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* //Task title  */}
        <div className="">
          <ATMTextArea
            name="title"
            value={values.title}
            onChange={(e) => setFieldValue("title", e)}
            label="Task Title"
            placeholder="Enter Task Title"
            className="rounded"
          />
        </div>

        {/* description */}
        <div className="z-0">
          <MOLMarkdownEditor
            label="Description"
            value={values.description}
            placeholder="Write description or drag your files here...."
            onChange={(newValue: any) => {
              setFieldValue("description", newValue);
            }}
          />
        </div>
        <div>
          <ATMTextField
            name="estimation"
            value={values.estimation}
            onChange={(e) => setFieldValue("estimation", e.target.value)}
            label="Estimation (in Mins)"
            placeholder="Enter Estimation (in Mins)"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMDatePicker
            label="Due Date"
            name="dueDate"
            format="DD/MM/YYYY"
            minDate={moment().format("yyyy-MM-DD")}
            value={values.dueDate}
            onChange={(newValue) => setFieldValue("dueDate", newValue)}
          />
        </div>
        <div className="">
          <MOLLayerDropdown
            name="layer"
            placeholder="Layer"
            isMulti
            value={values.layer}
            onChange={(newValue) => {
              setFieldValue("layer", newValue);
            }}
            onBlur={handleBlur}
            id={values.projectId}
          />
        </div>

        <div>
          <ATMSelect
            name="assignedTo"
            label="Assignee"
            placeholder="Select Assigner"
            value={values.assignedTo}
            isLoading={isDataLoading}
            options={allProjectWiseMembers?.members?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return (
                  <div className="capitalize flex gap-x-3">
                    {option.label}
                    {option?.value === userData?.userId && (
                      <span className="bg-green-500 py-[2px] flex items-center px-[8px] text-center rounded-full text-white text-[10px]">
                        It's you
                      </span>
                    )}
                  </div>
                );
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            onChange={(newValue) => {
              setFieldValue("assignedTo", newValue);
            }}
          />
        </div>
      </div>
      {/* <div>
        <ATMCheckbox
          label="Save and Next"
          checked={saveNextChecked}
          onChange={(checked) => {
            setSaveNextChecked(checked);
          }}
        />
      </div> */}
    </MOLFormDialog>
  );
};

export default AddTaskInsideProject;
