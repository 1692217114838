import React, { useState } from "react";
import IssueToTaskForm from "./IssueToTaskForm";
import { Form, Formik, FormikHelpers } from "formik";
import { TaskFormValues } from "src/models/Task.model";
import { useAddTaskMutation } from "src/services/TaskService";
import { showToast } from "src/utils/showToaster";
import { RootState } from "src/redux/store";
import { useSelector } from "react-redux";
import { object, string } from "yup";
import moment from "moment";

type Props = {
  onClose: () => void;
  id: string | undefined;
  formType?: any;
  issueTitle: any;
  rowId: any;
};

const IssueToTaskFormWrapper = ({
  onClose,
  id,
  formType,
  issueTitle,
  rowId,
}: Props) => {
  const [AddTask] = useAddTaskMutation();
  const { singleItem } = useSelector((state: RootState) => state.ticket);
  const [saveNextChecked, setSaveNextChecked] = useState(true);
  const initialValues: TaskFormValues = {
    title: "",
    layer: [],
    label: [],
    estimation: "",
    featureId: singleItem[0]?.featureId || null,
    moduleId: singleItem[0]?.moduleId || null,
    projectId: singleItem[0]?.projectId || null,
    description: issueTitle || "",
    ticketId: "",
    relatedTo: "",
    dueDate: "",
    assignedTo: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter Task Title"),
  });

  // Handle Submit
  const handleSubmit = (
    values: TaskFormValues,
    { setSubmitting, resetForm }: FormikHelpers<TaskFormValues>
  ) => {
    const { ticketId, relatedTo, label, dueDate, ...rest } = values;

    const formattedValues = {
      ...rest,
      relatedTo: "TICKET",
      assignedTo: values?.assignedTo?.value,
      ticketId: singleItem[0]?._id,
      issueId: rowId || "",
      dueDate: dueDate ? moment(dueDate).format("yyyy-MM-DD") : "",
      label: [
        {
          labelName: "OPEN",
          status: "OPEN",
        },
      ],
    };

    AddTask(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Form>
          <IssueToTaskForm
            formType={formType}
            formikProps={formikProps}
            onClose={onClose}
            saveNextChecked={saveNextChecked}
            setSaveNextChecked={setSaveNextChecked}
            id={id}
          />
        </Form>
      )}
    </Formik>
  );
};

export default IssueToTaskFormWrapper;
