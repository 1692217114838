import IndustryList from "./IndustryList";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import EditIndustryFormWrapper from "../../Industry/Edit/EditIndustryFormWrapper";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  useDeleteIndustryMutation,
  useGetIndustryPaginationQuery,
} from "src/services/IndustryService";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import {
  setPage,
  setItems,
  setTotalItems,
  setSearchValue,
  setRowsPerPage,
  setIsTableLoading,
  setIsOpenAddFormDialog,
  setIsOpenEditFormDialog,
} from "src/redux/slices/IndustrySlice";
import { showToast } from "src/utils/showToaster";
import AddIndustryFormWrapper from "../Add/AddIndustryFormWrapper";

type Props = {};

const IndustryListingWrapper = (props: Props) => {
  const [selectedIndustryId, setSelectedIndustryId] = useState<string>("");
  const [deleteIndustryName] = useDeleteIndustryMutation();

  const dispatch = useDispatch<AppDispatch>();
  const {
    page,
    items,
    totalItems,
    rowsPerPage,
    searchValue,
    isTableLoading,
    isOpenEditFormDialog,
    isOpenAddFormDialog,
  } = useSelector((state: RootState) => state.industry);

  const { data, isLoading, isFetching } = useGetIndustryPaginationQuery({
    limit: rowsPerPage,
    page: page,
    params: ["industryName"],
    searchValue: searchValue,
    dateFilter: {},
    orderByValue: -1,
    orderBy: "createdAt",
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Industry ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteIndustryName(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "industryName",
      headerName: "Industry Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "z-0 min-w-[200px]",
      name: UserModuleNameTypes.INDUSTRY_LIST_INDUSTRY_NAME,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  dispatch(setIsOpenEditFormDialog(true));
                  setSelectedIndustryId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_INDUSTRY_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_INDUSTRY_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <IndustryList
        columns={columns}
        rows={items}
        addNew={() => dispatch(setIsOpenAddFormDialog(true))}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      {isOpenAddFormDialog ? (
        <AddIndustryFormWrapper
          onClose={() => dispatch(setIsOpenAddFormDialog(false))}
        />
      ) : null}
      {isOpenEditFormDialog ? (
        <EditIndustryFormWrapper
          onClose={() => dispatch(setIsOpenEditFormDialog(false))}
          selectedIndustryId={selectedIndustryId}
        />
      ) : null}
    </div>
  );
};

export default IndustryListingWrapper;
