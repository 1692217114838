import apiSlice from "./ApiSlice";

export const meetingNoteTypeApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Get All
        getAllMeetingNoteTypePaginationData: builder.query({
            providesTags: ["meeting-note-type"],
            query: (body: PaginationPayloadType) => {
                return {
                    url: "/meeting-note-type",
                    method: "POST",
                    body,
                };
            },
        }),

        // Get Module by Id
        getMeetingNoteTypeById: builder.query({
            providesTags: ["meeting-note-type"],
            query: (id) => {
                return {
                    url: `/meeting-note-type/${id}`,
                    method: "GET",
                };
            },
        }),

        // Add
        addMeetingNoteType: builder.mutation({
            invalidatesTags: ["meeting-note-type"],
            query: (body) => {
                return {
                    url: "/meeting-note-type/add",
                    method: "POST",
                    body,
                };
            },
        }),


        // Edit
        editMeetingNoteTypeById: builder.mutation({
            invalidatesTags: ["meeting-note-type"],
            query: ({ id, body }) => {
                return {
                    url: `/meeting-note-type/${id}`,
                    method: "PUT",
                    body,
                };
            },
        }),

           //delete leave Request
           changeDefaultStatusMeetingNoteType: builder.mutation({
            invalidatesTags: ["meeting-note-type"],
            query: (id: string) => {
                return {
                    url: `meeting-note-type/set-as-default/${id}`,
                    method: "PUT",
                };
            },
        }),


        //delete leave Request
        deleteMeetingNoteType: builder.mutation({
            invalidatesTags: ["meeting-note-type"],
            query: (id: string) => {
                return {
                    url: `meeting-note-type/${id}`,
                    method: "DELETE",
                };
            },
        }),


        // Change Status currency
        changeStatusMeetingNoteType: builder.mutation({
            invalidatesTags: ["meeting-note-type"],
            query: (id) => {
                return {
                    url: `/meeting-note-type/status-change/${id}`,
                    method: "PUT",
                };
            },
        }),
    }),
});

export const {
    useGetAllMeetingNoteTypePaginationDataQuery,
    useEditMeetingNoteTypeByIdMutation,
    useDeleteMeetingNoteTypeMutation,
    useChangeDefaultStatusMeetingNoteTypeMutation,
    useChangeStatusMeetingNoteTypeMutation,
    useAddMeetingNoteTypeMutation,
    useGetMeetingNoteTypeByIdQuery
} = meetingNoteTypeApi;
