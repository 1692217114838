import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { Size, getInputHeight } from "src/utils/formUtils/getInputHeight";
import { twMerge } from "tailwind-merge";

export interface ATMInputAdormantPropTypes {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  adormant: string | React.ReactNode;
  adormantProps: {
    position?: "start" | "end";
    onClick?: () => void;
    extraClasses?: string;
  };
  type?: "text" | "password" | "number";
  placeholder?: string;
  className?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  size?: Size;
  inputProps?: { className: string };
  autoFocusShortcut?: string;
}

const ATMInputAdormant = ({
  name,
  value,
  onChange,
  type = "text",
  placeholder = "",
  className = "",
  label,
  required = false,
  disabled = false,
  readonly = false,
  adormant,
  adormantProps = {
    position: "start",
  },
  size = "small",
  inputProps,
  autoFocusShortcut = "Ctrl+K",
}: ATMInputAdormantPropTypes) => {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if the pressed keys match the specified shortcut
      // worked only with ctrl + shift + k
      if (event.ctrlKey && event.key.toLowerCase() === "k") {
        // Set focus on the input when the shortcut key is pressed
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="w-full">
      {label && (
        <label className="text-gray-11">
          {" "}
          {label} {required && <span className="text-red-500"> * </span>}{" "}
        </label>
      )}

      <div
        className={twMerge(
          `${getInputHeight(
            size
          )} w-full border border-gray-8 rounded-lg overflow-hidden ${
            label && "mt-1"
          } flex ${isFocus && "border border-blue-10"} ${className} `
        )}
      >
        {adormantProps.position === "start" && (
          <div
            onClick={() => adormantProps.onClick && adormantProps.onClick()}
            className={twMerge(
              `w-[15%] h-full flex justify-center items-center bg-slate-300 border-r border-slate-400 rounded-l ${
                adormantProps.onClick && "cursor-pointer"
              } ${adormantProps.extraClasses}`
            )}
          >
            {adormant}
          </div>
        )}

        <input
          ref={inputRef}
          type={type}
          name={name}
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          placeholder={placeholder}
          className={`w-full h-full p-1 text-slate-700 border-0 outline-0  ${inputProps?.className} `}
          disabled={disabled}
          readOnly={readonly}
          onFocus={(event) => {
            setIsFocus(true);
            event.target.select();
          }}
          onBlur={() => {
            setIsFocus(false);
          }}
        />

        {adormantProps.position === "end" && (
          <div
            onClick={() => adormantProps.onClick && adormantProps.onClick()}
            className={twMerge(
              `w-[15%] h-full flex justify-center items-center bg-slate-300 border-l border-slate-400 rounded-r ${
                adormantProps.onClick && "cursor-pointer"
              }  ${adormantProps.extraClasses}`
            )}
          >
            {adormant}
          </div>
        )}
      </div>

      {name && (
        <ErrorMessage name={name}>
          {(errMsg) => (
            <p className="font-poppins absolute text-[14px] text-start mt-0 text-red-500">
              {errMsg}
            </p>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ATMInputAdormant;
