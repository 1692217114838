import { useState, useEffect } from "react";
import { useGetAllDomainQuery } from "src/services/DomainServices";

const useDomains = () => {
    const [Domains, setDomains] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const { data, isLoading, isFetching } = useGetAllDomainQuery("");

    useEffect(() => {
        if (isLoading || isFetching) {
            setIsDataLoading(true);
        } else {
            const result: any[] = data?.data;
            setDomains(result || []);
            setIsDataLoading(false);
        }
    }, [data, isLoading, isFetching]);

    return { Domains, isDataLoading };
};

export default useDomains;
