import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { isMobile } from "react-device-detect";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import AdjustBalanceForm from "./AdjustBalanceForm";
import { useGetBaseCurrencyQuery } from "src/services/CurrencyServices";
import { AdjustBalanceFormValues } from "src/models/Account.model";
import { useUpdateAdjustAmountMutation } from "src/services/Finance-New-Services/AccountServices";

type Props = {
  onClose: () => void;
  adjustId?: any;
};

const AjustBalanceFormWrapper = ({ onClose, adjustId }: Props) => {
  const [addAdjustAmount] = useUpdateAdjustAmountMutation();
  const { data } = useGetBaseCurrencyQuery("");

  // Form Initial Values
  const initialValues: AdjustBalanceFormValues = {
    amount: "",
    adjustType: "CREDIT",
    description: "",
    currency: data?.data,
    account: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    amount: string().required("Please enter amount"),
    description: string().required("Please enter description "),
    account: object().required("Please select account"),
  });

  // Handle Submit
  const handleSubmit = (
    values: AdjustBalanceFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AdjustBalanceFormValues>
  ) => {
    const formattedValues = {
      adjustType: values?.adjustType,
      remark: values?.description,
      amount: values?.amount,
      accountId: values?.account?.value,
    };
    addAdjustAmount({
      id: adjustId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <AdjustBalanceForm
              formType="ADD"
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AjustBalanceFormWrapper;
