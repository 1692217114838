import React, { useEffect, useState } from 'react'
import OverView from './OverView'
import { useGetLeadActionsQuery } from 'src/services/ContactService'
import { useParams } from 'react-router-dom'
type Props ={
    leadDetails:any
}

const OverViewWrapper = ({leadDetails}:Props) => {  
  const {leadId} = useParams()
  const [actionsData , setActionsData] = useState<any>()
  const {data:actionData, isLoading:actionDataIsLoading , isFetching:actionDataIsFetching} = useGetLeadActionsQuery(leadId)

  useEffect(()=>{
    if (!actionDataIsFetching && !actionDataIsLoading) {
      setActionsData(actionData?.data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[actionDataIsLoading , actionDataIsFetching ,actionData ])
  return (
   <OverView actionDataIsLoading={actionDataIsLoading || actionDataIsFetching} actionsData={actionsData} leadDetails={leadDetails} />
  )
}

export default OverViewWrapper