import { ReactNode } from "react";
import ATMLoadingButton from "../../atoms/ATMLoadingButton/ATMLoadingButton";
import ATMDialog from "../../atoms/ATMDialog/ATMDialog";
import ATMCheckbox from "../../atoms/formFields/ATMCheckbox/ATMCheckbox";

type Props = {
  onClose: () => void;
  children: ReactNode;
  title: string;
  isSubmitting: boolean;
  formType?: "ADD" | "EDIT" | "UPDATE";
  saveNextChecked?: boolean;
  setSaveNextChecked?: (checked: boolean) => void;
  hideSavenNext?: boolean;
  handleSubmit?: () => void;
  checkboxText?: string;
  fullScreen?: boolean;
};

const MOLFormDialog = ({
  onClose,
  children,
  title,
  isSubmitting,
  formType = "ADD",
  saveNextChecked = false,
  setSaveNextChecked = () => {},
  hideSavenNext = false,
  handleSubmit,
  checkboxText,
  fullScreen = false,
}: Props) => {
  return (
    <ATMDialog fullScreen={fullScreen}>
      <div
        className={`flex flex-col relative ${fullScreen ? "w-[100vw] h-[100vh]" : "min-w-[30vw] max-h-[90vh] "}  overflow-auto hide-scrollbar`}
      >
        {/* Header */}
        <div className="sticky top-0 flex items-center justify-between p-4 pb-2 z-[10000] bg-white">
          <span className="font-semibold text-md">{title}</span>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-4">{children}</div>

        {/* Actions */}
        <div className="sticky bottom-0 flex items-center justify-between gap-2 px-4 py-3 bg-blue-2">
          {formType === "EDIT" || hideSavenNext ? null : (
            <div className="flex items-center justify-between">
              <ATMCheckbox
                id={"save"}
                label={checkboxText ? checkboxText : "Save and Next"}
                checked={saveNextChecked}
                inputClasses="h-[15px]"
                labelClasses="text-sm select-none"
                onChange={(checked) => setSaveNextChecked(checked)}
              />
            </div>
          )}
          <div className="flex items-center justify-end flex-1 gap-2">
            <ATMLoadingButton
              variant="text"
              type="button"
              color="primary"
              onClick={onClose}
            >
              Cancel
            </ATMLoadingButton>
            <ATMLoadingButton
              type="submit"
              isLoading={isSubmitting}
              onClick={handleSubmit}
            >
              Save
            </ATMLoadingButton>
          </div>
        </div>
      </div>
    </ATMDialog>
  );
};

export default MOLFormDialog;
