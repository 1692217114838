import apiSlice from "./ApiSlice";

const taskApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Add the client user
    addTask: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: (body) => {
        return {
          url: "/task/add",
          method: "POST",
          body,
        };
      },
    }),

    // Edit Ticket
    closeTask: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: ({ taskId, body }) => {
        return {
          url: `task/${taskId}/mark-as-close `,
          method: "PUT",
          body,
        };
      },
    }),

    // Get all the client user with pagination
    getAllTaskWithPagination: builder.query({
      providesTags: ["task"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/task",
          method: "POST",
          body,
        };
      },
    }),

    // Get all task without pagination

    getAllTask: builder.query({
      providesTags: ["task", "tickets"],
      query: () => {
        return {
          url: "/task/",
          method: "GET",
        };
      },
    }),

    // Get the client user by id
    getTaskById: builder.query({
      providesTags: ["task", "tickets"],
      query: (id: string) => {
        return {
          url: `/task/${id}`,
          method: "GET",
        };
      },
    }),

    // get user in progress task by token 

    getInProgressTask: builder.query({
      providesTags: ["task", "tickets"],
      query: () => {
        return {
          url: `/task/inprogress`,
          method: "GET",
        };
      },
    }),

    updateTaskById: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: ({ id, body }) => {
        return {
          url: `/task/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    updateAssigneeInTask: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: ({ id, body }) => {
        return {
          url: `/task/${id}/assigned-to`,
          method: "PUT",
          body,
        };
      },
    }),

    // update task status
    updateTaskStatus: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: ({ id, body }) => {
        return {
          url: `/task/change-label/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Single task Delete
    deleteTaskById: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: (id: any) => ({
        url: `/task/${id}`,
        method: "DELETE",
      }),
    }),

    // Get Logs of Task
    getLogOfTask: builder.query({
      providesTags: ["task", "tickets"],
      query: (body: PaginationPayloadType) => ({
        url: `/logs`,
        method: "POST",
        body,
      }),
    }),

    // Time Report of Task
    getTimeReportOfTask: builder.query({
      providesTags: ["task", "tickets"],
      query: (taskId: string) => ({
        url: `/logs/time-report/${taskId}?status=IN_PROGRESS`,
        method: "GET",
      }),
    }),

    // project wise memebrs
    getProjectWiseMembers: builder.query({
      query: (projectId: string | null) => ({
        url: `/project/${projectId}/members`,
        method: "GET",
      }),
    }),
    // Task Label count wise
    getTaskCount: builder.query({
      providesTags: ["task", "tickets"],
      query: (id: any) => {
        return {
          url: `/project/${id}/task-count`,
          method: "GET",
        };
      },
    }),

    // Move task to archive
    moveTaskToArchive: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: (taskId: string) => {
        return {
          url: `task/move-to-archived/${taskId} `,
          method: "PUT",
        };
      },
    }),
  }),
});

export const {
  useAddTaskMutation,
  useCloseTaskMutation,
  useGetAllTaskWithPaginationQuery,
  useGetAllTaskQuery,
  useGetTaskByIdQuery,
  useUpdateTaskByIdMutation,
  useUpdateAssigneeInTaskMutation,
  useDeleteTaskByIdMutation,
  useGetInProgressTaskQuery,
  useUpdateTaskStatusMutation,
  useGetLogOfTaskQuery,
  useGetTimeReportOfTaskQuery,
  useGetProjectWiseMembersQuery,
  useGetTaskCountQuery,
  useMoveTaskToArchiveMutation
} = taskApi;
