import React from "react";
// import moment from "moment";
// import { object, string } from "yup";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import AllTaskFilterFormCardDialog from "./DoubtFilterFormDialog";
import {
  setCodioticUserByFilter,
  setProjectValueFilter,
  setModuleValueFilter,
  setFeatureValueFilter,
} from "src/redux/slices/DoubtSlice";
// import moment from "moment";

type Props = {
  open: boolean;
  onClose: () => void;
};


// const doubtOptions = [
//   {
//     label: "open",
//     value: "OPEN",
//   },
//   {
//     label: "solved",
//     value: "SOLVED",

//   }
// ]
export type FormInitialValues = {
  codioticUser: any;
  project: any;
  module: any;
  feature: any;
  // status: any
};

const DoubtFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const allDoubtState = useSelector((state: RootState) => state.doubts);
  const {
    codioticUser,
    projectFilterValue,
    moduleFilterValue,
    featureFilterValue,
    // status,
  } = allDoubtState;

  // Initial Values
  const initialValues: FormInitialValues = {
    codioticUser: codioticUser,
    project: projectFilterValue,
    module: moduleFilterValue,
    feature: featureFilterValue,
    // status: status
  };


  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);

    // set codiotic user filter value
    dispatch(setCodioticUserByFilter(values.codioticUser));

    //  set project value
    dispatch(setProjectValueFilter(values.project));

    // set module value
    dispatch(setModuleValueFilter(values.module));

    // set feature value
    dispatch(setFeatureValueFilter(values.feature));

    // dispatch(setStatusFilter(values.status.value));
 
    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // set User filter filter dispatch
    await dispatch(setCodioticUserByFilter(""));

    //  set project value
    await dispatch(setProjectValueFilter(""));

    // set module value
    await dispatch(setModuleValueFilter(""));

    // set feature value
    await dispatch(setFeatureValueFilter(""));

    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AllTaskFilterFormCardDialog
            open={open}
            // doubtOptions={doubtOptions}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default DoubtFilterFormDialogWrapper;
