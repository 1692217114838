import React, { useEffect, useState } from "react";

import { useGetCodioticTeamMemberByIdQuery } from "src/services/TeamServices";
import TeamProfileTab from "./TeamProfileTab";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const TeamProfileTabWrapper = () => {
  const {teamMemberId} = useParams()

  const [userDetails, setUserDetails] = useState<any>();

  const { data, isLoading, isFetching } = useGetCodioticTeamMemberByIdQuery(
    teamMemberId,
    { skip: !teamMemberId }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setUserDetails(data?.data);
    }
  }, [data, isLoading, isFetching]);

  return (
   <>
    {(isLoading || isFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
  <TeamProfileTab userDetails={userDetails}/>
   </>)
};

export default TeamProfileTabWrapper;
