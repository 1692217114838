import apiSlice from "./ApiSlice";

export const leadsLabelApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL leadsLabel
    getAllLeadsLabelList: builder.query({
      query: () => {
        return {
          url: 'lead-label',
          method: "GET",
        };
      },
    }),

  }),
});

export const {
 useGetAllLeadsLabelListQuery ,
} = leadsLabelApi;
