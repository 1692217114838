import { FormikProps } from "formik";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMRadioButtonGroup from "src/components/UI/atoms/formFields/ATMRadioButtonGroup/ATMRadioButtonGroup";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<any>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};


const UpcomingListFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  return (
   <MOLFilterDialog 
   title={"Filters"}
   onClose={onClose}
   isSubmitting={isSubmitting}
   clearFilter={onReset}
   handleSubmit={handleSubmit}
   open={open}
   >
       <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">
        {/* Created At */}
        <div className="mb-2">
          <div className="flex gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
          {/* Filter Form Field */}

          <div className="mt-2">
            <ATMRadioButtonGroup
              label="Types"
              required={false}
              value={values.type}
              options={[
                {
                  label: 'MILESTONE',
                  value: 'MILESTONE',
                },
                {
                  label: 'EXPENSE',
                  value: 'EXPENSE',
                },
                {
                  label: 'PAYMENT',
                  value: 'PAYMENT',
                }
              ]}
              allowMultipleSelection
              onChange={(newValue) => {
                setFieldValue("type", newValue);
              }}
            />
          </div>

        </div>

      </div>
   </MOLFilterDialog>
  );
};

export default UpcomingListFilterFormDialog;
