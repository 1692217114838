import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import DumpForm from "../Layouts/DomainForm";
import { DumpFormValues } from "src/models/Dump.model";
import { useAddDumpMutation } from "src/services/DumpService";

type Props = {
  onClose: () => void;
};

const AddDumpFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addDump] = useAddDumpMutation();

  // Form Initial Values
  const initialValues: DumpFormValues = {
    projectId: "",
    title: "",
    layerRemark: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter dump name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DumpFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DumpFormValues>
  ) => {
    const formattedValues = {
      projectId: values?.projectId?.value,
      title: values?.title,
      layerRemark: values?.layerRemark,
    };
    addDump(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <DumpForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddDumpFormWrapper;
