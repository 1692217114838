import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { TicketFormValues } from "src/models/Ticket.model";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";

type Props = {
  formikProps: FormikProps<TicketFormValues>;
  onClose: () => void;
};

const EditTicketProposedSolution = ({ formikProps, onClose }: Props) => {
  const {
    values,
    setFieldValue,
    isSubmitting,
    //  handleBlur
  } = formikProps;

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex items-center justify-between">
        <div className="text-xl font-medium"> Update Proposed Solution </div>
        <button
          type="button"
          className="p-2 rounded hover:bg-hover"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4 fullScreenEditor">
        <div>
          <MOLMarkdownEditor
            label="Proposed Solution"
            value={values.proposedSolution}
            placeholder="Write tickt proposed solution or drag your files here...."
            onChange={(newValue: any) => {
              setFieldValue("proposedSolution", newValue);
            }}
          />
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default EditTicketProposedSolution;
