import moment from "moment";
import React, { useState } from "react";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { ATMShowField } from "../../../OverView/OverView";
import AddTaskDialogFormWrapper from "../../../../AddTaskDialog/AddTaskDialogFormWrapper";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

type Props = {
  contactData: any;
  isLoading: boolean;
};

const TaskActivitiesTab = ({ contactData, isLoading }: Props) => {
  const [isOpenAddTask, setIsOpenAddTask] = useState(false);
  const [isExpanedActivity, setIsExpanedActivity] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(0);
  return (
    <div>
      <div className="flex justify-end">
        <ATMLoadingButton
          onClick={() => {
            setIsOpenAddTask(true);
          }}
          className="bg-slate-700  !h-[32px] text-white w-32  text-[14px]"
        >
          Create Task
        </ATMLoadingButton>
      </div>
      <div className="flex flex-col gap-4 my-3 ">
        {isLoading ? (
          Array(6)
            .fill(0)
            .map((_, index) => {
              return (
                <div key={index} className="animate-pulse  h-[95px]">
                  <div className="bg-slate-200 h-full rounded"> </div>
                </div>
              );
            })
        ) : contactData?.length ? (
          contactData?.map((el: any, ind: any) => {
            return (
              <div key={ind} className="shadow-sm mt-2  border py-2 px-3 rounded-md">
                <div
                  className={`flex justify-between  ${
                    isExpanedActivity && selectedId === el?._id
                      ? "border-b pb-2"
                      : ""
                  }`}
                >
                  <div
                    onClick={() => {
                      setIsExpanedActivity(!isExpanedActivity);
                      setSelectedId(el?._id);
                    }}
                    className="flex gap-1 items-center text-[14px] cursor-pointer"
                  >
                    {isExpanedActivity && selectedId === el?._id ? (
                      <IoIosArrowDown color="#00a4bd" />
                    ) : (
                      <IoIosArrowForward color="#00a4bd" />
                    )}
                    <span className="text-slate-800 font-medium">Task</span>
                    <span>assigned to</span>
                    <span>{el?.assignedToName}</span>
                  </div>
                  <div className="text-[12px] text-gray-400">
                    Created At : {moment(el?.createdAt).format("DD MMM yyyy hh:mm A")}
                  </div>
                </div>
                {isExpanedActivity && selectedId === el?._id ? (
                  <div className="px-4">
                    <div className="grid grid-cols-6 py-2 border-b">
                      <ATMShowField
                        value={el?.reminderForDate? moment(el?.reminderForDate).format(
                          "DD MMM yyyy"
                        ): el?.reminderForDate}
                        label="Reminder On"
                      />
                      <ATMShowField
                        value={moment(el?.activityDate).format("DD MMM yyyy")}
                        label="Activity Date"
                      />
                      <ATMShowField value={el?.taskName} label="Task Name" />
                      <ATMShowField value={el?.queueName} label="Queue" />
                      <ATMShowField
                        value={el?.taskAction?.toLowerCase()}
                        label="Action"
                      />
                    </div>
                    <div className="grid grid-cols-6 py-2">
                      <ATMShowField
                        value={el?.assignedToName}
                        label="Assigned To"
                      />
                      <ATMShowField
                        value={el?.createdByName}
                        label="CreatedBy"
                      />
                      <ATMShowField
                        value={el?.priority?.toLowerCase()}
                        label="Priority"
                      />
                    </div>
                    <div className="text-[13px] text-primary-dark font-medium  hover:bg-blue-50  rounded-lg p-2">
                      <ATMMarkdownContainer markdown={el?.description} />
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })
        ) : (
          <div className="flex justify-center items-end h-[120px] text-red-700 font-medium text-lg">
            No Data Found
          </div>
        )}
      </div>
      {isOpenAddTask && (
        <AddTaskDialogFormWrapper onClose={() => setIsOpenAddTask(false)} />
      )}
    </div>
  );
};

export default TaskActivitiesTab;
