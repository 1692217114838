import { FormikProps } from "formik";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { ModuleFormValues } from "src/models/Module.model";
import { ProjectListResponse } from "src/models/Project.model";

type Props = {
  formikProps: FormikProps<ModuleFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  projectItems?: any;
  saveNextChecked?: any;
};

const ModuleForm = ({
  formikProps,
  projectItems,
  formType,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Module" : "Update Module"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Project */}
        <div>
          <ATMSelect
            name="project"
            label="Project"
            required
            placeholder="Select Project"
            value={values.project}
            isLoading={isAllProjectsDataLoading}
            options={allProjects?.map((ele: ProjectListResponse) => {
              return {
                label: ele?.projectName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("project", newValue);
            }}
          />
        </div>

        {/* Module Name */}
        <div>
          <ATMTextField
            name="moduleName"
            value={values.moduleName}
            required
            onChange={(e) => setFieldValue("moduleName", e.target.value)}
            label="Module Name"
            placeholder="Enter Module Name"
            onBlur={handleBlur}
          />
        </div>
      </div>
      
    </MOLFormDialog>
  );
};

export default ModuleForm;
