import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { BusinessFormValues } from "src/models/Business.model";
import {
  useGetSingleBusinessModelQuery,
  useUpdateBusinessModelMutation,
} from "src/services/BusinessService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import BusinessForm from "../Layouts/BusinessForm";

type Props = {
  onClose: () => void;
  selectedBusinessId: any;
};

const EditBusinessFormWrapper = ({ onClose, selectedBusinessId }: Props) => {
  const [businessModelData, setBusinessModelData] = useState<any>({});

  const { isLoading, isFetching, data } = useGetSingleBusinessModelQuery(
    selectedBusinessId,
    {
      skip: !selectedBusinessId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setBusinessModelData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const [updateDomain] = useUpdateBusinessModelMutation();

  // Form Initial Values
  const initialValues: BusinessFormValues = {
    industryName: businessModelData?.industries?.map((industry: any) => {
      return {
        label: industry?.industryName,
        value: industry?.industryId,
      };
    }),
    modelName: businessModelData?.modelName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    modelName: string().required("Please enter model name"),
    industryName: array().required("Please Select Industry"),
  });

  // Handle Submit
  const handleSubmit = (
    values: BusinessFormValues,
    { setSubmitting, resetForm }: FormikHelpers<BusinessFormValues>
  ) => {
    const formattedValues = {
      industries: values?.industryName?.map((el: any) => el?.value),
      modelName: values?.modelName,
    };
    updateDomain({ id: selectedBusinessId, body: formattedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <BusinessForm
            formikProps={formikProps}
            onClose={onClose}
            formType="EDIT"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditBusinessFormWrapper;
