import { FormikProps } from "formik";
import { useState } from "react";
import { FiDelete } from "react-icons/fi";
import { MdPersonOutline } from "react-icons/md";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { OnBoardStageFormValues } from "src/models/OnBoardStage.model";
import StageDocumentDataFormDialog from "../components/StageDocumentDataFormDialog";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { CircularProgress } from "@mui/material";

type Props = {
  formikProps: FormikProps<OnBoardStageFormValues>;
  formtype: "ADD" | "EDIT";
  isLoading?: boolean;
  onclose: () => void;
};

const OnBoardStageForm = ({
  formikProps,
  formtype,
  isLoading = false,
  onclose,
}: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  const [dialogData, setDialogData] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const sections = [
    {
      fieldKey: "shareToCandidate",
      label: "Share To Candidate",
      data: values.shareToCandidate,
    },
    {
      fieldKey: "collectFromCandidate",
      label: "Collect From Candidate",
      data: values.collectFromCandidate,
    },
    {
      fieldKey: "forOfficial",
      label: "For Official",
      data: values.forOfficial,
    },
    {
      fieldKey: "credentialsShare",
      label: "Credentials",
      data: values.credentialsShare,
    },
    {
      fieldKey: "otherRequirements",
      label: "Other Requirements",
      data: values.otherRequirements,
    },
  ];

  return (
    <div className="flex flex-col p-2">
      {isLoading ? (
        <div className=" w-[100%] h-screen flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="text-lg font-medium">
            {formtype === "ADD"
              ? "Add On-Board Stage"
              : "Update On-Board Stage"}
          </div>
          <div className="grid grid-cols-2 gap-4 p-4">
            {/* Stage Name */}
            <div>
              <ATMTextField
                required
                name="stageName"
                value={values?.stageName}
                onChange={(e) => {
                  setFieldValue("stageName", e.target?.value);
                }}
                label="Name"
                placeholder="Enter stage name"
              />
            </div>

            {/* Stage Order */}
            <div>
              <ATMTextField
                required
                name="stageOrder"
                value={values?.stageOrder}
                onChange={(e) => {
                  setFieldValue("stageOrder", e.target?.value);
                }}
                label="Order"
                placeholder="Enter order"
              />
            </div>

            {/* Sections */}
            <div className="col-span-2 space-y-2 ">
              {sections?.map((section) => {
                return (
                  <div className="p-4">
                    <div className="p-2 font-medium border-l-4 border-primary-main text-primary-main">
                      {section?.label}
                    </div>

                    <div className="px-4 py-2 space-y-2">
                      <ul className="space-y-2">
                        {section?.data?.map((fieldData, fieldIndex) => {
                          return (
                            <li
                              key={fieldIndex}
                              className="flex items-stretch gap-3 group"
                            >
                              <div
                                onClick={() => {
                                  setDialogData({
                                    fieldKey: section?.fieldKey,
                                    fieldIndex,
                                    values: {
                                      title: fieldData?.title,
                                      completeWithinDays:
                                        fieldData?.completeWithinDays,
                                      description: fieldData?.description,
                                      file: fieldData?.file,
                                      isMandatory: fieldData?.isMandatory,
                                      responsibleUserId:
                                        fieldData?.responsibleUserId,
                                    },
                                  });

                                  setOpenEditDialog(true);
                                }}
                                className="flex justify-between p-2 w-[400px] border rounded cursor-pointer"
                              >
                                <div className="flex flex-col gap-1">
                                  <span className="font-medium text-md text-slate-700">
                                    {fieldData?.title}
                                  </span>

                                  <span className="flex items-center gap-1 text-sm text-gray-500">
                                    {" "}
                                    <MdPersonOutline />{" "}
                                    {fieldData?.responsibleUserId?.name}{" "}
                                  </span>
                                </div>

                                <span className="text-sm font-medium text-slate-600">
                                  In {fieldData?.completeWithinDays} Days
                                </span>
                              </div>

                              <div
                                onClick={() => {
                                  const newList = [...section?.data];

                                  newList.splice(fieldIndex, 1);

                                  setFieldValue(section?.fieldKey, newList);
                                }}
                                className="flex items-center invisible px-4 text-red-500 bg-red-100 cursor-pointer rounded-r-md group-hover:visible"
                              >
                                <FiDelete />
                              </div>
                            </li>
                          );
                        })}
                      </ul>

                      <div>
                        <button
                          type="button"
                          className="px-4 py-2 font-semibold border-2 border-dashed rounded text-primary-light"
                          onClick={() => {
                            setDialogData({
                              fieldKey: section?.fieldKey,
                              values: {
                                title: "",
                                completeWithinDays: "",
                                description: "",
                                file: [],
                                isMandatory: false,
                                responsibleUserId: "",
                              },
                            });

                            setOpenDialog(true);
                          }}
                        >
                          + Add more
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex items-center justify-end gap-4 p-4 col-span-full">
              <ATMLoadingButton onClick={() => onclose()} variant="tonal">
                {" "}
                Cancel{" "}
              </ATMLoadingButton>

              <ATMLoadingButton type="submit" isLoading={isSubmitting}>
                Save
              </ATMLoadingButton>
            </div>
          </div>

          {openDialog && (
            <StageDocumentDataFormDialog
              values={dialogData?.values}
              onClose={() => {
                setOpenDialog(false);
                setDialogData(null);
              }}
              onSave={(newValue) => {
                setFieldValue(dialogData?.fieldKey, [
                  ...(values as any)[dialogData?.fieldKey],
                  newValue,
                ]);
              }}
            />
          )}

          {openEditDialog && (
            <StageDocumentDataFormDialog
              formType="EDIT"
              values={dialogData?.values}
              onClose={() => {
                setOpenEditDialog(false);
                setDialogData(null);
              }}
              onSave={(newValue) => {
                const newList = (values as any)[dialogData?.fieldKey]?.map(
                  (el: any, index: number) => {
                    if (index === dialogData?.fieldIndex) {
                      return newValue;
                    } else {
                      return el;
                    }
                  }
                );
                setFieldValue(dialogData?.fieldKey, newList);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OnBoardStageForm;
