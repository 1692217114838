import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ATMActionMenu, {
  OptionType,
} from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import EditTaskFormWrapper from "src/screens/Ticket/View/TicketTabs/Task/Edit/EditTaskFormWrapper";
import StatusChangeDialogWrapper from "src/screens/Ticket/View/TicketTabs/Task/List/StatusChangeDialog/StatusChangeDialogWrapper";
import { useDeleteTaskByIdMutation } from "src/services/TaskService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import useMoveTaskToArchive from "src/hooks/useMoveTaskToArchive";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import {
  TbArchive,
  TbChevronRight,
  TbEdit,
  TbNewSection,
  TbStack,
  TbTrash,
  TbUserEdit,
} from "react-icons/tb";
import ColorComponent from "src/components/UI/colorComponent";
import { MdBackHand } from "react-icons/md";
import AssignToDialogWrapper from "src/screens/Ticket/View/TicketTabs/Task/List/AssignToDialog/AssignToDialogWrapper";

type Props = {
  rows: any[];
  labels: any;
};

const AllTaskBoardLayout = ({ rows, labels }: Props) => {
  const [isOpenAssignTo, setIsOpenAssignTo] = useState(false);
  const [isOpenStatusChange, setIsOpenStatusChange] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [assigneeId, setAssigneeId] = useState<any>({});
  const [labelId, setLabelId] = useState<any>({});
  const { pathname, search } = useLocation();
  const { handleMoveTaskToArchive } = useMoveTaskToArchive();

  const navigate = useNavigate();
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>("");

  //   Label Wize tablwe Sorting
  const labelOrder: string[] = [
    "OPEN",
    "TODO",
    "IN PROGRESS",
    "HOLD",
    "BLOCKING",
    "READY FOR QA",
    "QA IN PROGRESS",
    "ISSUE",
    "COMPLETE",
    "QA_OPEN",
    "CLOSE",
  ];
  const labelIndexMap: Record<string, number> = {};
  labelOrder.forEach((name, index) => {
    labelIndexMap[name] = index;
  });

  // Define color mapping for labels
  const labelColors: Record<string, string> = {
    OPEN: "text-primary-dark",
    TODO: "text-slate-700",
    "IN PROGRESS": "text-green-700",
    HOLD: "text-yellow-700",
    BLOCKING: "text-orange-700",
    "READY FOR QA": "text-lime-700",
    "QA IN PROGRESS": "text-emerald-700",
    ISSUE: "text-red-700",
    COMPLETE: "text-purple-700",
    QA_OPEN: "text-rose-700",
    CLOSE: "text-teal-700",
  };

  // Delete Method
  const [deleteTask] = useDeleteTaskByIdMutation();
  const handleDeleteTask = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Task?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteTask(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  //get task as per labels
  const getStatusWisetask = (label: string) => {
    return rows.filter((row) => row.label?.[0]?.labelName === label);
  };

  return (
    <>
      <div className="flex flex-col h-full bg-gray-50">
        {/* Board */}
        <div className="flex gap-4 p-4 overflow-x-scroll">
          {labels?.data
            ?.slice()
            ?.sort(
              (a: any, b: any) =>
                labelIndexMap[a.labelName] - labelIndexMap[b.labelName]
            )
            ?.map((label: any) => (
              <div
                key={label.id}
                className="flex flex-col h-full gap-2 overflow-hidden min-w-[320px] max-w-[320px]"
              >
                <div
                  className={`${
                    labelColors[label.labelName]
                  } p-2 rounded-lg bg-white custom-shadow font-semibold`}
                >
                  <p>{label.labelName}</p>
                </div>
                <div className="flex flex-col h-full gap-4 overflow-auto">
                  {getStatusWisetask(label.labelName)?.map((row: any) => (
                    <div
                      className="flex flex-col gap-3 bg-white rounded-xl custom-shadow"
                      key={row.id}
                    >
                      {/* ticket no. and actions  */}
                      <div className="flex items-center justify-between w-full p-2 py-1 border-b border-dashed">
                        <p className="flex items-center text-xs font-medium leading-none text-teal-600 md:text-sm">
                          {row?.taskNumber}
                        </p>
                        <div className="items-end">
                          <ATMActionMenu
                            options={
                              [
                                {
                                  label: (
                                    <div className="flex items-center gap-x-2">
                                      <TbEdit />
                                      Edit
                                    </div>
                                  ),
                                  onClick: () => {
                                    navigate(`/task/edit/${row?._id}`, {
                                      state: {
                                        pathname,
                                        search,
                                      },
                                    });
                                  },
                                  acitonName:
                                    UserModuleNameTypes.ACTION_TASK_EDIT,
                                },
                                {
                                  label: (
                                    <div className="flex items-center gap-x-2">
                                      <TbNewSection />
                                      Duplicate
                                    </div>
                                  ),
                                  onClick: () => {
                                    navigate(`/task/duplicate/${row?._id}`);
                                  },
                                  acitonName:
                                    UserModuleNameTypes.ACTION_TASK_DUPLICATE,
                                },
                                !row?.isArchived && {
                                  label: (
                                    <div className="flex items-center gap-x-2">
                                      <TbArchive /> Move to Archive
                                    </div>
                                  ),
                                  onClick: () => {
                                    handleMoveTaskToArchive(row?._id);
                                  },
                                  acitonName:
                                    UserModuleNameTypes.ACTION_TASK_MOVE_TO_ARCHIVE,
                                },
                                {
                                  label: (
                                    <div className="flex items-center gap-x-2">
                                      <TbTrash /> Delete
                                    </div>
                                  ),
                                  onClick: () => {
                                    handleDeleteTask(row?._id);
                                  },
                                  acitonName:
                                    UserModuleNameTypes.ACTION_TASK_DELETE,
                                },
                              ].filter(Boolean) as OptionType[]
                            }
                          />
                        </div>
                      </div>
                      {/* ticket title and hierarchy*/}
                      <div className="flex flex-col gap-2 px-2">
                        <p className="items-center hidden md:flex">
                          <span className="flex flex-wrap items-center gap-1 text-xs font-medium">
                            {row?.layer[0]?.layerName && (
                              <>
                                <TbStack />
                                {row?.layer[0]?.layerName}
                                {row?.projectName && (
                                  <TbChevronRight className="text-gray-600" />
                                )}
                              </>
                            )}

                            {row?.projectName && (
                              <>
                                <TbStack />
                                {row?.projectName}
                                {row?.moduleName && (
                                  <TbChevronRight className="text-gray-600" />
                                )}
                              </>
                            )}

                            {row?.moduleName && (
                              <>
                                <MdBackHand />
                                {row?.moduleName}
                                {row?.featureName && (
                                  <TbChevronRight className="text-gray-600" />
                                )}
                              </>
                            )}

                            {row?.featureName && (
                              <>
                                <MdBackHand />
                                {row?.featureName}
                              </>
                            )}
                          </span>
                        </p>
                       
                        <p
                          onClick={() => {
                            isAuthorized(
                              UserModuleNameTypes.ACTION_TASK_SINGLE_VIEW
                            ) &&
                              navigate(`/task/${row?._id}`, {
                                state: {
                                  pathname,
                                  search,
                                },
                              });
                          }}
                          className="text-md md:text-lg cursor-pointer line-clamp-2 hover:underline"
                        >
                          {row.title}
                        </p> 
                        {row?.ticketNumber && (
                          // ticketId
                          <p
                            className="cursor-pointer text-xs line-clamp-2 hover:underline"
                            // onClick={() => {
                            //   isAuthorized(
                            //     UserModuleNameTypes.ACTION_TIKCETS_SINGLE_VIEW
                            //   ) && navigate(`/ticket/${row?.ticketId}/details`);
                            // }}
                          >
                            #{row?.ticketNumber}
                          </p>
                        )}
                      </div>
                      {/* ticket assignee and status  */}
                      <div className="flex items-center gap-2 px-2 pb-2">
                        <div
                          onClick={() => {
                            setIsOpenAssignTo(true);
                            setTaskId(row?._id);
                            setProjectId(row?.projectId);
                            setAssigneeId([row?.assignedTo, row?.assigneeName]);
                          }}
                          className="cursor-pointer w-fit h-fit"
                        >
                          {row?.assignedTo === null ? (
                            <div className="p-2 border border-dashed rounded-full w-fit spect-square">
                              <TbUserEdit />
                            </div>
                          ) : (
                            <div className="p-2 px-2 py-1 text-xs font-semibold border rounded-full bg-gray-50 w-fit">
                              <ColorComponent inputString={row?.assigneeName} />
                            </div>
                          )}
                        </div>
                        <div
                          className="px-2 py-1  border rounded-full cursor-pointer hover:bg-hover"
                          onClick={() => {
                            if (row?.assignedTo !== null) {
                              setIsOpenStatusChange(true);
                              setTaskId(row?._id);
                              setProjectId(row?.projectId);
                              setAssigneeId([
                                row?.assignedTo,
                                row?.assigneeName,
                              ]);
                              setLabelId(row?.label);
                            }
                          }}
                        >
                          <p className="text-xs text-primary-dark">
                            {row?.assignedTo === null ? (
                              <span>Please assign the task</span>
                            ) : (
                              row?.label[0]?.labelName
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>

      {isOpenEditFormDialog ? (
        <EditTaskFormWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedTask={taskId}
        />
      ) : null}

      {isOpenAssignTo ? (
        <AssignToDialogWrapper
          projectId={projectId}
          onClose={() => setIsOpenAssignTo(false)}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}

      {isOpenStatusChange ? (
        <StatusChangeDialogWrapper
          onClose={() => setIsOpenStatusChange(false)}
          projectId={projectId}
          labelId={labelId}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}
    </>
  );
};

export default AllTaskBoardLayout;
