import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

type Props = {
  salaryInfo: any;
};

const SalarySlipView = ({ salaryInfo }: Props) => {
  const printRef = useRef<HTMLDivElement>(null);
  const maxRows = Math.max(
    salaryInfo?.data?.earnings?.length || 0,
    salaryInfo?.data?.deductions?.length || 0
  );

  const renderRows = (data: any, fields: any) => {
    return Array.from({ length: maxRows }).map((_, index) => {
      const item = data[index] || { title: "", amount: "" };

      return (
        <div
          key={index}
          className="grid h-10 grid-cols-3 border-t border-r border-black"
        >
          <div className="flex items-center col-span-1 px-2 font-semibold border-r border-black">
            {item[fields?.title] || " "}
          </div>
          <div className="flex items-center justify-end col-span-2 px-2 ">
            {item[fields?.amount]}
          </div>
        </div>
      );
    });
  };
  const numberToWords = (num: number): string => {
    if (num === 0) return "Zero";

    const lessThanTwenty: string[] = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens: string[] = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands: string[] = ["", " Thousand", " Lakh", " Crore"];

    const helper = (n: number): string => {
      if (n === 0) return "";
      else if (n < 20) return lessThanTwenty[n] + " ";
      else if (n < 100) return tens[Math.floor(n / 10)] + " " + helper(n % 10);
      else
        return (
          lessThanTwenty[Math.floor(n / 100)] + " Hundred " + helper(n % 100)
        );
    };

    const integerToWords = (num: number): string => {
      if (num === 0) return "";

      let word: string = "";
      let unitIndex: number = 0;

      while (num > 0) {
        let chunk: number;
        if (unitIndex === 0) {
          // For units (ones, tens, hundreds)
          chunk = num % 1000;
          num = Math.floor(num / 1000);
        } else {
          // For thousands, lakhs, crores
          chunk = num % 100;
          num = Math.floor(num / 100);
        }

        if (chunk !== 0) {
          const chunkWords = helper(chunk).trim();
          if (unitIndex === 0) {
            word = chunkWords + word;
          } else if (chunkWords) {
            word = chunkWords + thousands[unitIndex] + " " + word;
          }
        }

        unitIndex++;
      }

      return word.trim();
    };

    const numParts = num.toString().split(".");
    const integerPart = parseInt(numParts[0]);
    const decimalPart = numParts[1] ? parseInt(numParts[1]) : 0;

    let result = integerToWords(integerPart);

    if (decimalPart > 0) {
      result +=
        " Point " +
        decimalPart
          .toString()
          .split("")
          .map((digit) => lessThanTwenty[parseInt(digit)])
          .join(" ");
    }

    return result.trim().replace(/\s+/g, " "); // Replace multiple spaces with single space
  };
  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    documentTitle: "Salary Slip",
  });

  const totalEarnings =
    salaryInfo?.data?.earnings?.reduce(
      (sum: any, el: any) => sum + el.value,
      0
    ) || 0;
  const totalDeductions =
    salaryInfo?.data?.deductions?.reduce(
      (sum: any, el: any) => sum + el.value,
      0
    ) || 0;

  return (
    <>
      <div className="flex justify-end p-4 print:hidden">
        <ATMLoadingButton children="Download" onClick={handlePrint} />
      </div>
      <div ref={printRef} className=" border w-[900px] mx-auto border-black">
        <div className="flex flex-col gap-4 p-4">
          <div className="text-4xl font-bold text-center">
            {salaryInfo?.company?.companyName}
          </div>
          <div className="text-center">
            {salaryInfo?.company?.companyAddress}
          </div>
          <div className="mb-2 text-xl font-semibold text-center border-b border-black ">
            Monthly Salary Slip{" "}
          </div>

          <div className="grid grid-cols-2 text-sm">
            <div className="grid grid-cols-3 border border-black">
              <div className="col-span-1 p-2 font-semibold border-r border-black">
                Employee id
              </div>
              <div className="col-span-2 p-2 ">
                {salaryInfo?.data?.employeeId}
              </div>
            </div>
            <div className="grid grid-cols-3 border border-l-0 border-black">
              <div className="col-span-1 p-2 font-semibold border-r border-black">
                Employee Name
              </div>
              <div className="col-span-2 p-2 capitalize">
                {salaryInfo?.data?.teamMemberName}
              </div>
            </div>
            <div className="grid grid-cols-3 border border-t-0 border-black">
              <div className="col-span-1 p-2 font-semibold border-r border-black">
                Designation
              </div>
              <div className="col-span-2 p-2">
                {salaryInfo?.data?.positionName}
              </div>
            </div>
            <div className="grid grid-cols-3 border border-t-0 border-l-0 border-black">
              <div className="col-span-1 p-2 font-semibold border-r border-black">
                Month & Year
              </div>
              <div className="col-span-2 p-2 ">
                {/* {salaryInfo?.data?.effectiveDate
                  ? moment(salaryInfo?.data?.effectiveDate).format("MMM-YYYY")
                  : ""}{" "} */}
                {salaryInfo?.data?.month} {salaryInfo?.data?.year}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-2 border border-r-0 border-black">
            <div className="text-sm">
              <div className="p-2 font-semibold text-center bg-gray-300 border-r border-black">
                Earning
              </div>
              {renderRows(salaryInfo?.data?.earnings, {
                title: "labelName",
                amount: "value",
              })}
              <div className="grid h-10 grid-cols-3 border-t border-r border-black">
                <div className="flex items-center col-span-1 px-2 font-semibold border-r border-black">
                  Total Earnings
                </div>
                <div className="flex items-center justify-end col-span-2 px-2 ">
                  &#8377; {totalEarnings}
                </div>
              </div>
              <div className="grid h-10 grid-cols-3 border-t border-r border-black">
                <div className="flex items-center col-span-1 px-2 font-semibold border-r border-black"></div>
                <div className="flex items-center justify-end col-span-2 px-2 "></div>
              </div>
            </div>

            {/* Deduction Section */}
            <div className="text-sm">
              <div className="p-2 font-semibold text-center bg-gray-300 border-r border-black">
                Deduction
              </div>
              {renderRows(salaryInfo?.data?.deductions, {
                title: "labelName",
                amount: "value",
              })}
              <div className="grid h-10 grid-cols-3 border-t border-r border-black">
                <div className="flex items-center col-span-1 px-2 font-semibold border-r border-black">
                  Total Deduction
                </div>
                <div className="flex items-center justify-end col-span-2 px-2 ">
                  &#8377; {totalDeductions}
                </div>
              </div>
              <div className="grid h-10 grid-cols-3 border-t border-r border-black">
                <div className="flex items-center col-span-1 px-2 font-semibold border-r border-black">
                  Net Salary
                </div>
                <div className="flex items-center justify-end col-span-2 px-2 ">
                  &#8377; {salaryInfo?.data?.netAmount}
                </div>
              </div>
            </div>
            <div className="grid h-10 grid-cols-12 col-span-2 border-t border-r border-black ">
              <div className="flex items-center col-span-2 px-2 font-semibold border-r border-black ">
                In Words
              </div>
              <div className="col-span-10 p-2 ">
                Rupees {numberToWords(Number(salaryInfo?.data?.netAmount))} only
              </div>
            </div>
          </div>
          <div className="text-xs text-center">
            This is computer generated Payslip and doesn't require any signature
          </div>
        </div>
      </div>
    </>
  );
};
export default SalarySlipView;
