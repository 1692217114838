import { FormikProps } from "formik";
import moment from "moment";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import MOLLabelDropdown from "src/components/UI/molecules/MOLLabelDropdown/MOLLabelDropdown";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { EditTaskFormValues } from "src/models/Task.model";

type Props = {
  formikProps: FormikProps<EditTaskFormValues>;
  onClose: () => void;
  id: string | undefined;
};

const EditTaskForm = ({ formikProps, onClose, id }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={"Edit Task"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        {/* Task title  */}
        <div className="">
          <ATMTextArea
            required
            name="title"
            value={values.title}
            onChange={(e) => setFieldValue("title", e)}
            label="Task Title"
            placeholder="Enter Task Title"
          />
        </div>

        {/* Description */}
        <div className="z-0">
          <MOLMarkdownEditor
            label=" Description"
            placeholder="Write description or drag your files here...."
            value={values?.description}
            onChange={(e: any) => {
              setFieldValue("description", e);
            }}
          />
        </div>
        <div>
          <MOLLabelDropdown
            disabled={values?.assignedToCheck ? false : true}
            name="label"
            placeholder="Label"
            value={values.label}
            onChange={(newValue) => {
              setFieldValue("label", newValue);
            }}
            onBlur={handleBlur}
            id={id}
          />
        </div>
        <div>
          <ATMTextField
            name="estimation"
            value={values.estimation}
            onChange={(e) => setFieldValue("estimation", e.target.value)}
            label="Estimation (in Mins)"
            placeholder="Enter Estimation (in Mins)"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMDatePicker
            label="Due Date"
            name="dueDate"
            format="DD/MM/YYYY"
            minDate={moment().format("yyyy-MM-DD")}
            value={values.dueDate}
            onChange={(newValue) => setFieldValue("dueDate", newValue)}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default EditTaskForm;
