import {
  IconBan,
  IconCalendarClock,
  IconCircleCheck,
  IconRefresh,
} from "@tabler/icons-react";
import React from "react";
import { BiSearch } from "react-icons/bi";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";

type Question = {
  questionTitle: string;
  value: string;
};

type Stage = {
  stageName: string;
  questions: Question[];
  isCracked: boolean;
  roundStatus: string;
};

type Row = {
  applicantName: string;
  applicantPhoneNumber: string;
  stages: Stage[];
};

type Props = {
  rows: Row[];
  columns: any;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const RoundTrackingReportListing: React.FC<Props> = ({
  rows,
  columns,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}) => {
  const uniqueStages: { stageName: string; questions: string[] }[] = [];
  rows.forEach((row) => {
    row.stages.forEach((stage) => {
      if (!uniqueStages.some((s) => s.stageName === stage.stageName)) {
        uniqueStages.push({
          stageName: stage.stageName,
          questions: stage.questions.map((q) => q.questionTitle),
        });
      }
    });
  });

  const getStatusIcon = (isCracked: boolean, roundStatus: string) => {
    if (!isCracked && roundStatus === "COMPLETED") {
      return (
        <div className="px-2 py-1 text-xs text-white capitalize bg-red-500 rounded-lg ">
          <IconBan />
        </div>
      );
    } else if (!isCracked && roundStatus === "SCHEDULED") {
      return (
        <div className="px-2 py-1 text-xs text-white capitalize bg-orange-500 rounded-lg ">
          <IconCalendarClock />
        </div>
      );
    } else if (isCracked && roundStatus === "COMPLETED") {
      return (
        <div className="px-2 py-1 text-xs text-white capitalize bg-green-500 rounded-lg ">
          <IconCircleCheck />
        </div>
      );
    } else if (!isCracked && roundStatus === "ONGOING") {
      return (
        <div className="px-2 py-1 text-xs text-white capitalize bg-green-800 rounded-lg ">
          <IconRefresh />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="flex flex-col flex-1 h-full">
      {/* Page Header */}
      <div className="flex justify-between">
        <div className="w-full">
          <ATMPageHeader
            pageTitle="Round Tracking Report"
            hideSearchBox
            hideAddButton={true}
          />
        </div>
        <div className="flex items-center w-full gap-2 px-4 md:justify-end">
          <div className="xs:w-full md:max-w-[300px]">
            <ATMInputAdormant
              name=""
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              adormant={<BiSearch />}
              adormantProps={{
                position: "start",
                extraClasses: "bg-white border-0",
              }}
              inputProps={{ className: "bg-white" }}
              placeholder="Search..."
            />
          </div>
        </div>
      </div>

      {/* Table Wrapper for X-Axis Scrolling */}
      <div className="m-4 overflow-auto ">
        <table className="border-separate border-spacing-0">
          <thead className="sticky top-0 z-50 bg-slate-100">
            <tr>
              <th
                rowSpan={2}
                className="sticky left-0 p-2 font-medium border border-1 text-slate-700 bg-slate-100 text-start min-w-[150px] z-50"
              >
                Applicant Name
              </th>
              <th
                className=" p-2 font-medium  text-center border border-1 top-0 text-slate-700 bg-slate-100 min-w-[150px] z-50 "
                rowSpan={2}
              >
                Mobile No.
              </th>
              {uniqueStages.map((stage, stageIndex) => (
                <th
                  key={`stage-${stageIndex}`}
                  className="z-50 p-2 font-medium text-center border border-1 text-slate-700"
                  colSpan={stage.questions.length + 1}
                >
                  {stage.stageName}
                </th>
              ))}
            </tr>
            <tr className=" bg-slate-100">
              {uniqueStages.map((stage, stageIndex) => (
                <React.Fragment key={`fragment-${stageIndex}`}>
                  {stage.questions.map((question, questionIndex) => (
                    <th
                      key={`question-${stageIndex}-${questionIndex}`}
                      className="p-2 text-xs text-center border border-1 text-slate-600  min-w-[250px]"
                    >
                      {question}
                    </th>
                  ))}
                  <th
                    key={`status-${stageIndex}`}
                    className="p-2 text-xs text-center border border-1 text-slate-600 "
                  >
                    Status
                  </th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                <td className="sticky left-0 z-10 p-2 text-xs capitalize bg-white border text-start border-1 text-slate-600">
                  {row.applicantName}
                </td>
                <td className="p-2 text-xs text-center bg-white border border-1 text-slate-600">
                  {row.applicantPhoneNumber}
                </td>
                {uniqueStages.map((stage, stageIndex) => {
                  const currentStage = row.stages.find(
                    (s) => s.stageName === stage.stageName
                  );
                  return (
                    <React.Fragment key={`stage-${rowIndex}-${stageIndex}`}>
                      {stage.questions.map((question, questionIndex) => {
                        const answer = currentStage?.questions.find(
                          (q) => q.questionTitle === question
                        )?.value;
                        return (
                          <td
                            key={`answer-${rowIndex}-${stageIndex}-${questionIndex}`}
                            className="p-2 text-xs text-center border border-1 text-slate-600 "
                          >
                            {answer || "-"}
                          </td>
                        );
                      })}
                      <td
                        key={`icon-${rowIndex}-${stageIndex}`}
                        className="p-2 text-xs text-center border border-1 text-slate-600 "
                        title={currentStage?.roundStatus}
                      >
                        {getStatusIcon(
                          currentStage?.isCracked || false,
                          currentStage?.roundStatus || ""
                        )}
                      </td>
                    </React.Fragment>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {!rows.length && (
          <div className="flex justify-center py-2 border">No data found</div>
        )}
      </div>
    </div>
  );
};

export default RoundTrackingReportListing;
