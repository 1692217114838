import React from "react";
import { PiDotOutlineFill } from "react-icons/pi";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";

interface RequirementGatheringData {
  _id: string;
  requirementGatheringId: string;
  requirementGatheringName: string;
  nav: (Group | Screen | Functionality)[];
}

interface Group {
  _id: string;
  groupName: string;
  nodeType: "group";
  parentId?: string;
  children?: (Group | Screen | Functionality)[];
}

interface Screen {
  _id: string;
  screenName: string;
  nodeType: "screen";
  parentId?: string;
  children?: (Group | Screen | Functionality)[];
}

interface Functionality {
  _id: string;
  functionalityName: string;
  nodeType: "functionality";
  parentId?: string;
  children?: any[];
}

const RenderNode: React.FC<{
  node: any;
  level: number;
  prefix: string;
}> = ({ node, level, prefix }) => {
  const hasChildren = node?.children && node?.children.length > 0;

  return (
    <div style={{ paddingLeft: `${level * 8}px` }}>
      <div className="flex items-center w-full p-2 text-sm rounded-md">
        <div className="flex flex-col flex-1 gap-2">
          <div className="font-bold text-md">
            {prefix}
            {"."}{" "}
            {node.groupName
              ? node.groupName
              : `As a ${node.reqUserName}, I can ${node.action} ${node.reqPlatformName}`}
          </div>
          <ATMMarkdownContainer markdown={node?.description} />
        </div>
      </div>
      {hasChildren && (
        <div>
          {node?.children?.map((childNode: any, index: number) => (
            <RenderNode
              key={childNode?._id}
              node={childNode}
              level={level + 1}
              prefix={`${prefix}.${index + 1}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const PrintScreenDetails: React.FC<{
  featuresData: any;
  users: any;
  screenRef: React.RefObject<HTMLDivElement>;
  requirementGatheringData: RequirementGatheringData[];
}> = ({ screenRef, requirementGatheringData, featuresData, users }) => {
  // Function to build hierarchical tree structure from flat data
  const buildTree = (
    data: (Group | Screen | Functionality)[]
  ): (Group | Screen | Functionality)[] => {
    const tree: (Group | Screen | Functionality)[] = [];

    // Step 1: Create a map of nodes for quick lookup
    const nodeMap: Record<string, Group | Screen | Functionality> = {};

    data?.forEach((item) => {
      const nodeId = item?._id;
      nodeMap[nodeId] = { ...item, children: item?.children || [] }; // Ensure children is always initialized as an array
    });

    // Step 2: Build the tree
    data?.forEach((item) => {
      const node = nodeMap[item?._id];

      if (item?.parentId && nodeMap[item?.parentId]) {
        // This item has a parent
        const parentNode = nodeMap[item?.parentId];
        if (!parentNode?.children) {
          parentNode.children = [];
        }
        parentNode?.children?.push(node);
      } else {
        // No parent or parent not found, treat as root node
        tree?.push(node);
      }
    });

    return tree;
  };

  // Build hierarchical tree structure from requirementGatheringData
  const hierarchicalData = requirementGatheringData?.map((data) => ({
    groups: buildTree(data?.nav),
  }));

  // Render component with hierarchical data
  return (
    <div ref={screenRef} className="flex flex-col gap-4 divide-y">
      {/* Example rendering of tree structure */}
      <p className="p-2 text-lg">
        {requirementGatheringData[0]?.requirementGatheringName}
      </p>
      <div>
        <p className="text-sm font-medium">
          This Project consists of these platforms:
        </p>
        <div className="flex flex-col text-sm font-medium">
          {featuresData?.map((platform: any) => (
            <div className="flex items-center gap-2 ml-2" key={platform?._id}>
              <PiDotOutlineFill />
              <span className="capitalize">{platform?.platformName}</span>
            </div>
          ))}
        </div>
      </div>
      <div>
        <p className="text-sm font-medium">
          This Project consists of these Users:
        </p>
        <div className="flex flex-col text-sm font-medium">
          {users?.map((user: any) => (
            <div className="flex items-center gap-2 ml-2" key={user?._id}>
              <PiDotOutlineFill />
              <span className="capitalize">{user?.user}</span>
            </div>
          ))}
        </div>
      </div>
      {hierarchicalData?.map((platformData, platformIndex) => (
        <div className="p-2" key={platformIndex}>
          <div className="pl-4">
            {platformData?.groups?.map((node, nodeIndex) => (
              <RenderNode
                key={node?._id}
                node={node}
                level={0}
                prefix={`${nodeIndex + 1}`}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PrintScreenDetails;
