/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { FaUserPlus } from "react-icons/fa6";
import { SiBackstage, SiMediafire } from "react-icons/si";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { useGetAllJobCreationListingQuery } from "src/services/JobCreationService";
import AccessDenied from "src/utils/AccessDenied";
import { isAuthorized } from "src/utils/authorization";
import JobCreationSideBarLayout from "./JobCreationSideBarLayout/JobCreationSideBarLayout";
import { TbListDetails } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { setIsCollapsed } from "src/redux/slices/SideNavLayoutSlice";
import { useMediaQuery } from "@mui/material";

export const jobCreationTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
  {
    label: "Job Details",
    icon: TbListDetails,
    path: "job-details",
    moduleName: UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_DETAILS_TAB,
  },
  {
    label: "Job Application",
    icon: FaUserPlus,
    path: "job-application",
    moduleName: UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPICATION_TAB,
  },
  {
    label: "Stages",
    icon: SiBackstage,
    path: "stages",
    moduleName:
      UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB,
  },
  {
    label: "Round Tracking Report",
    icon: SiMediafire,
    path: "round-tracking-report",
    // moduleName: UserModuleNameTypes.ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_ROUND_TRACKING_REPORT_TAB,
  },
];

const ViewJobCreationLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname.split("/")[5];
  const { userData } = useGetLocalStorage();
  const [searchValue, setSearchValue] = useState("");
  const [jobList, setJobList] = useState<any>([]);

  // Job Creation Listing
  const { data, isFetching, isLoading } = useGetAllJobCreationListingQuery({
    // limit: rowsPerPage,
    searchValue: searchValue,
    params: ["jobTitle", "jobLocation"],
    // page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (!isLoading || !isFetching) {
      setJobList(data?.data || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("agenda");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of jobCreationTabs) {
      const isValue = isAuthorized(
        nav?.moduleName as keyof typeof UserModuleNameTypes
      );
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tabsRender = jobCreationTabs?.some((nav) => {
    return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
  });

  useEffect(() => {
    if (!currentPath) {
      navigate("user");
    }
  }, []);
  const sideNavLayoutState: any = useSelector(
    (state: RootState) => state.sideNavLayout
  );

  const { isCollapsed } = sideNavLayoutState;

  // Define breakpoints
  const isMdToXlScreen = useMediaQuery(
    "(min-width:960px) and (max-width:1535px)"
  );
  const is2XlScreen = useMediaQuery("(min-width:1536px)");

  useEffect(() => {
    if (isMdToXlScreen) {
      // Collapse the sidebar for md to xl screens
      if (!isCollapsed) {
        dispatch(setIsCollapsed(true));
      }
    } else if (is2XlScreen) {
      // Expand the sidebar for 2xl screens
      if (isCollapsed) {
        dispatch(setIsCollapsed(false));
      }
    }
  }, [isMdToXlScreen, is2XlScreen]);

  useEffect(() => {
    // Ensure the sidebar state persists when navigating
    const handleLocationChange =
      location.pathname === "/job-onboard/job-creation";

    if (handleLocationChange) {
      dispatch(setIsCollapsed(false));
    }
  }, [location.pathname]);

  return (
    <div>
      <JobCreationSideBarLayout
        listData={jobList}
        onSearch={(value: string) => setSearchValue(value)}
        searchValue={searchValue}
      >
        {tabsRender ? (
          <div className="flex flex-col h-full">
            {/* jobCreationTabs */}
            {jobCreationTabs?.length && (
              <div className="flex items-center gap-3 px-4 pt-2 bg-white border-b">
                {jobCreationTabs
                  ?.filter((nav) => {
                    return isAuthorized(
                      nav?.moduleName as keyof typeof UserModuleNameTypes
                    );
                  })
                  ?.map((tab: any, index: any) => {
                    return (
                      <div key={index}>
                        <button
                          type="button"
                          onClick={() => navigate(tab.path)}
                          className={`h-full px-4 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${
          currentPath === tab.path?.split("/")[0]
            ? "text-primary-main   border-primary-main"
            : "text-gray-700 border-white"
        }
         `}
                        >
                          <div className="text-lg">
                            <tab.icon />
                          </div>
                          {tab.label}
                        </button>
                      </div>
                    );
                  })}
              </div>
            )}

            <div className="h-full overflow-auto grow">
              <Outlet />
            </div>
          </div>
        ) : (
          <AccessDenied />
        )}
      </JobCreationSideBarLayout>
    </div>
  );
};

export default ViewJobCreationLayout;
