import React, { useEffect, useState } from "react";
import { CircularProgress, Divider } from "@mui/material";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { MdDelete, MdEdit } from "react-icons/md";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";

import {
  FaArrowLeft,
  FaExchangeAlt,
  FaFileArchive,
  FaPen,
  FaUser,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import EditTaskFormWrapper from "src/screens/Ticket/View/TicketTabs/Task/Edit/EditTaskFormWrapper";
import AssignToDialogWrapper from "src/screens/Ticket/View/TicketTabs/Task/List/AssignToDialog/AssignToDialogWrapper";
import StatusChangeDialogWrapper from "src/screens/Ticket/View/TicketTabs/Task/List/StatusChangeDialog/StatusChangeDialogWrapper";
import { useParams } from "react-router-dom";
import {
  useCloseTaskMutation,
  useDeleteTaskByIdMutation,
  useGetLogOfTaskQuery,
} from "src/services/TaskService";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import ColorComponent from "src/components/UI/colorComponent";
import useMoveTaskToArchive from "src/hooks/useMoveTaskToArchive";

type Props = {
  singleTaskData: any;
  isSingleTicketLoading?: boolean;
  singleTaskLog: any;
  afterCloseTicket: any;
};

const SingleTaskView = ({
  singleTaskData,
  singleTaskLog,
  afterCloseTicket,
}: Props) => {
  const [isOpenAssignTo, setIsOpenAssignTo] = useState(false);
  const [isOpenStatusChange, setIsOpenStatusChange] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [assigneeId, setAssigneeId] = useState<any>({});
  const [labelId, setLabelId] = useState<any>({});
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);
  const { id: TASK_ID } = useParams();
  const { handleMoveTaskToArchive } = useMoveTaskToArchive();

  const [CloseTask] = useCloseTaskMutation();

  const handleCloseTask = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to Close this Task ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          CloseTask({ taskId: row }).then((res: any) => {
            if (res?.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              if (res?.data?.status) {
                showToast("success", res?.data?.message);
                afterCloseTicket();
              } else {
                showToast("error", res?.data?.message);
              }
            }
          });
        }
      },
    });
  };

  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>("");

  const [timeLineOfTask, setTimeLineOfTask] = useState<any[]>([]);
  const handleRedirect = () => {
    return state
      ? navigate(`${state?.pathname + state?.search}`)
      : navigate("/task");
  };
  const {
    isLoading: isTaskTimeLineLoading,
    isFetching: isTaskTimeLineFetching,
    data: taskTimeLineData,
  } = useGetLogOfTaskQuery({
    limit: 100,
    searchValue: "",
    params: ["taskStatus", "previousStatus"],
    page: 1,
    filterBy: [
      {
        fieldName: "taskId",
        value: [TASK_ID],
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: 1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isTaskTimeLineLoading && !isTaskTimeLineFetching) {
      setTimeLineOfTask(taskTimeLineData?.data);
    }
  }, [isTaskTimeLineLoading, isTaskTimeLineFetching, taskTimeLineData]);

  // delete task
  const [deleteTask] = useDeleteTaskByIdMutation();

  const handleCopyClick = (branchName: any) => {
    navigator.clipboard
      .writeText(branchName)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Unable to copy text: ", err);
      });
  };

  const handleDeleteTask = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Task?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteTask(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
              handleRedirect();
            }
          });
        }
      },
    });
  };

  const getTaskStatus = (taskStatus: string) => {
    switch (taskStatus) {
      case "OPEN":
        return {
          label: "Open",
          classes: "bg-blue-500 text-white rounded-xl px-2 py-[1px] ",
        };
      case "TODO":
        return {
          label: "Todo",
          classes: "bg-[#6695cc] text-white rounded-xl px-2 ",
        };
      case "IN_PROGRESS":
        return {
          label: "In Progress",
          classes: "bg-yellow-500 text-white rounded-xl px-2 py-[1px] ",
        };
      case "BUGS":
        return {
          label: "Bug",
          classes: "bg-rose-500 text-white rounded-xl px-2 py-[1px] ",
        };
      case "BLOCKING":
        return {
          label: "Blocking",
          classes: "bg-red-500 text-white rounded-xl px-2 py-[1px] ",
        };
      case "UAT_REVIEW":
        return {
          label: "UAT Review",
          classes: "bg-lime-500 text-white rounded-xl px-2 py-[1px] ",
        };
      case "DONE":
        return {
          label: "Done",
          classes: "bg-green-500 text-white rounded-xl px-2 py-[1px] ",
        };
      case "HOLD":
        return {
          label: "Hold",
          classes: "bg-slate-500 text-white rounded-xl px-2 py-[1px] ",
        };
      case "ISSUE":
        return {
          label: "Issue",
          classes: "bg-orange-500 text-white rounded-xl px-2 py-[1px] ",
        };
      case "COMPLETE":
        return {
          label: "Complete",
          classes: "bg-teal-500 text-white rounded-xl px-2 py-[1px] ",
        };
      case "CLOSE":
        return {
          label: "Close",
          classes: "bg-yellow-500 text-white rounded-xl px-2 py-[1px] ",
        };
    }
  };

  return (
    <>
      <div className="flex flex-col overflow-hidden h-full">
        <div className="h-full">
          <>
            <div className="flex w-full h-full">
              {/* description div */}
              <div className="flex-1 flex flex-col gap-2 bg-white rounded-md relative h-full w-full">
                {/* back, close task, options */}
                <div className="flex justify-between py-2 md:px-4 px-2">
                  <div className="flex items-center gap-4">
                    <p
                      className="text-end flex items-center cursor-pointer"
                      onClick={() => handleRedirect()}
                    >
                      <FaArrowLeft />
                    </p>

                    <span className="text-sm text-gray-600">
                      {singleTaskData?.createdByLog} (
                      {singleTaskData?.taskNumber})
                    </span>
                  </div>

                  <div className="flex items-center gap-4">
                    <div>
                      <ATMLoadingButton
                        className="border border-slate-400 rounded-md"
                        onClick={() => handleCloseTask(singleTaskData?._id)}
                      >
                        Close Task
                      </ATMLoadingButton>
                    </div>
                    <div className="items-end text-sm">
                      <ATMActionMenu
                        options={[
                          {
                            label: (
                              <div className="flex gap-x-2 items-center">
                                <MdEdit color="#000000" /> Edit
                              </div>
                            ),
                            onClick: () => {
                              setIsOpenEditFormDialog(true);
                              setTaskId(singleTaskData?._id);
                              setProjectId(singleTaskData?.projectId);
                            },
                          },
                          {
                            label: (
                              <div className="flex gap-x-2 items-center">
                                <MdDelete color="#000000" /> Delete
                              </div>
                            ),
                            onClick: () => {
                              handleDeleteTask(singleTaskData?._id);
                            },
                          },
                          {
                            label: (
                              <div className="flex gap-x-2 items-center">
                                <FaFileArchive color="#000000" /> Move to
                                Archive
                              </div>
                            ),
                            onClick: () => {
                              handleMoveTaskToArchive(singleTaskData?._id);
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col overflow-auto md:px-4 px-3 w-full">
                  {/* Title and Description */}
                  <div className="flex flex-col pb-4">
                    <h1 className="text-2xl font-medium">
                      {singleTaskData?.title}
                    </h1>
                    {singleTaskData?.description && (
                      <p className="text-xs pb-2">
                        <ATMMarkdownContainer
                          markdown={singleTaskData?.description || "-"}
                        />
                      </p>
                    )}
                  </div>
                  {/* Status, Branch name, Assignee, Related to */}
                  <div className="flex flex-col gap-4 ">
                    {/* Status */}
                    <div className="flex items-center justify-between md:justify-start">
                      <div className="w-64">
                        <p className="text-sm">Task Status</p>
                      </div>
                      <span
                        className="text-xs bg-green-500 h-fit px-2 py-1 text-white font-medium rounded-md cursor-pointer"
                        onClick={() => {
                          if (singleTaskData?.assignedTo !== null) {
                            setIsOpenStatusChange(true);
                            setTaskId(singleTaskData?._id);
                            setProjectId(singleTaskData?.projectId);
                            setAssigneeId([
                              singleTaskData?.assignedTo,
                              singleTaskData?.assigneeName,
                            ]);
                            setLabelId(singleTaskData?.label);
                          }
                        }}
                      >
                        {singleTaskData?.label?.[0]?.labelName || "-"}
                      </span>
                    </div>
                    {/* Branch name */}
                    <div className="flex items-center justify-between md:justify-start">
                      <div className="w-64">
                        <p className="text-sm">Branch Name</p>
                      </div>
                      {singleTaskData?.branchName && (
                        <span
                          onClick={() => {
                            handleCopyClick(singleTaskData?.branchName);
                          }}
                          className="flex items-center bg-gray-100 h-fit hover:bg-gray-300 text-xs font-medium px-2 py-1 gap-2 rounded-xl hover:rounded-full transition-all ease-linear cursor-pointer border border-white hover:border"
                        >
                          {singleTaskData?.branchName || "-"}
                          <button disabled={isCopied}>
                            {isCopied ? <LuCopyCheck /> : <LuCopy />}
                          </button>
                        </span>
                      )}
                    </div>
                    {/* Related To */}
                    <div className="flex items-center justify-between md:justify-start">
                      <div className="w-64">
                        <p className="text-sm">Related To</p>
                      </div>
                      <div className="text-xs font-semibold text-white py-1 px-2 rounded-md bg-sky-400">
                        {singleTaskData?.relatedTo || "-"}
                      </div>
                    </div>

                    {singleTaskData?.relatedTo === "PROJECT" && (
                      <div className="flex items-center justify-between md:justify-start">
                        <div className="w-64">
                          <p className="text-sm">
                            {singleTaskData?.relatedTo === "PROJECT" &&
                              "Project Name"}
                          </p>
                        </div>
                        <div className="text-xs font-semibold text-white py-1 px-2 rounded-md bg-sky-400">
                          {singleTaskData?.relatedTo === "PROJECT"
                            ? singleTaskData?.projectName
                            : null}
                        </div>
                      </div>
                    )}

                    {/* Assignee */}
                    <div className="flex items-center justify-between md:justify-start">
                      <div className="w-64">
                        <p className="text-sm">Assignee</p>
                      </div>
                      <div className="flex gap-2">
                        {singleTaskData?.assignedTo === null ? (
                          "Unassigned"
                        ) : (
                          <>
                            <p className="font-medium text-primary text-xs flex items-center gap-4 bg-gray-100 px-2 py-1 rounded-lg capitalize h-fit">
                              {singleTaskData?.assigneeName}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    {/* Total Time Spent */}
                    <div className="flex items-center justify-between md:justify-start">
                      <div className="w-64">
                        <p className="text-sm">Total Time</p>
                      </div>
                      <div className="text-sm font-semibold flex items-center gap-2">
                        {singleTaskLog?.[0]?.userData?.length ? (
                          <div
                            onClick={handleOpen}
                            className="cursor-pointer flex items-center gap-2"
                          >
                            <span>{singleTaskLog[0]?.timeTaken} Minutes</span>
                            {open ? (
                              <span className="">
                                <BiChevronUp className="text-xl" />
                              </span>
                            ) : (
                              <span className="">
                                <BiChevronDown className="text-xl" />
                              </span>
                            )}
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </div>
                  {open && (
                    <div className="accordion-content">
                      <div className="container mx-auto">
                        <table className="min-w-full bg-white border border-gray-300">
                          <thead>
                            <tr>
                              <th className="py-2 px-4 border-b">Name</th>
                              <th className="py-2 px-4 border-b">Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {singleTaskLog?.[0]?.userData?.map(
                              (task: any, taskIndex: any) => {
                                return (
                                  <tr
                                    key={taskIndex}
                                    className={
                                      taskIndex % 2 === 0 ? "bg-gray-100" : ""
                                    }
                                  >
                                    <td className="text-center py-2 px-2 border-b">
                                      {task.assignedToUserName || "-"}{" "}
                                    </td>
                                    <td className="text-center py-2 px-2 border-b">
                                      {task.durationInMinutes}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="py-4">
                    <Divider />
                  </div>

                  {/* Logs */}
                  <div className="flex flex-col gap-2">
                    {/* DYNAMIC DATA START */}

                    <div className="text-sm flex relative">
                      {/* Label History */}

                      {(isTaskTimeLineLoading || isTaskTimeLineFetching) && (
                        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                          <CircularProgress />
                        </div>
                      )}

                      <ul>
                        {timeLineOfTask?.map((timeLine: any, index: number) => (
                          <li key={index} className="flex relative w-full">
                            <div className="flex">
                              {timeLine?.actionPerformedByUserName && (
                                <div className="flex pb-8 gap-3 relative">
                                  {/* Dynamic Action Performed Icons */}
                                  <div className="p-2 h-fit aspect-square border rounded-full">
                                    {timeLine?.actionPerformed === "CREATED" ? (
                                      <FaPen className="text-sm text-gray-600" />
                                    ) : timeLine?.actionPerformed ===
                                      "ASSIGNED" ? (
                                      <FaUser className="text-sm text-gray-600" />
                                    ) : timeLine?.actionPerformed ===
                                      "MOVED" ? (
                                      <FaExchangeAlt className="text-sm text-gray-600" />
                                    ) : timeLine?.actionPerformed ===
                                      "UPDATED" ? (
                                      <FaPen className="text-sm text-gray-600" />
                                    ) : null}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <div className="flex items-center gap-4">
                                      <p className="text-xs">
                                        {timeLine?.dateTime}
                                      </p>
                                      <span
                                        // className={`px-2 bg-${timeLine.actionPerformedByUserName.toLowerCase()} bg-sky-300 h-fit text-white font-medium rounded-md`}
                                        className={`${
                                          getTaskStatus(timeLine?.taskStatus)
                                            ?.classes
                                        } text-xs font-semibold`}
                                      >
                                        {/* {timeLine?.taskStatus} */}
                                        {
                                          getTaskStatus(timeLine?.taskStatus)
                                            ?.label
                                        }
                                      </span>
                                    </div>
                                    <span className="flex gap-1 text-sm">
                                      <p className="">
                                        <ColorComponent
                                          inputString={timeLine.actionPerformedByUserName
                                            .toLowerCase()
                                            .replace(/\s+/g, "")}
                                        />
                                      </p>
                                      <span className="flex">
                                        {timeLine?.actionPerformed ===
                                        "CREATED" ? (
                                          <p className="font-semibold">
                                            created task
                                          </p>
                                        ) : timeLine?.actionPerformed ===
                                          "ASSIGNED" ? (
                                          <div className="flex gap-2">
                                            <p className="font-semibold">
                                              assigned task to
                                            </p>
                                            <ColorComponent
                                              inputString={timeLine?.assignedToUserName
                                                .toLowerCase()
                                                .replace(/\s+/g, "")}
                                            />
                                          </div>
                                        ) : timeLine?.actionPerformed ===
                                          "MOVED" ? (
                                          <p className="font-semibold">
                                            changed task status
                                          </p>
                                        ) : timeLine?.actionPerformed ===
                                          "UPDATED" ? (
                                          <p className="font-semibold">
                                            updated task
                                          </p>
                                        ) : null}
                                      </span>

                                      {/* <p className=""> */}
                                      {/* {timeLine?.remark?.split(" ")} */}
                                      {/* created task */}
                                      {/* </p> */}
                                    </span>
                                    {/* {timeLine?.remark} */}

                                    {timeLine?.taskStatusComment ? (
                                      <div className="bg-gray-50 p-2 rounded-md">
                                        <p className="">
                                          <ATMMarkdownContainer
                                            markdown={
                                              timeLine?.taskStatusComment || "-"
                                            }
                                          />
                                          {/* <span className="text-black font-medium capitalize">
                                            {timeLine?.taskStatusComment || ""}
                                          </span> */}
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* DYNAMIC DATA END*/}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      {isOpenEditFormDialog ? (
        <EditTaskFormWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedTask={taskId}
        />
      ) : null}
      {isOpenAssignTo ? (
        <AssignToDialogWrapper
          onClose={() => setIsOpenAssignTo(false)}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}
      {isOpenStatusChange ? (
        <StatusChangeDialogWrapper
          onClose={() => setIsOpenStatusChange(false)}
          projectId={projectId}
          labelId={labelId}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}
    </>
  );
};

export default SingleTaskView;
