import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
// import { object } from "yup";
import { showToast } from "src/utils/showToaster";
import {
  useGetMeetingNotesByNoteIdQuery,
  useUpdateNoteMeetingMutation,
} from "src/services/MeetingServices";
import { useParams } from "react-router-dom";
import AddMeetingNotesForm from "../Layout/AddMeetingNotesForm";
import { useGetAllNeetingNoteType } from "src/hooks/useMeetingNoteType";
import { array, object, string } from "yup";

type Props = {
  noteId: string;
  actualClientUsers: any[];
  onClickRenderAddForm: () => void;
};

export type MeetingNotesTabFormValues = {
  meetingId: any;
  clientUserId: any;
  notes: any;
  title: any;
 meetingNoteTypeId: any;
 userData? : any
};


const EditMeetingNotesFormWrapper = ({
  noteId,
  actualClientUsers,
  onClickRenderAddForm,
}: Props) => {
  const [noteData, setNoteData] = useState<any>();
  const { meetingId: MEETING_ID } = useParams();
  // get all meetng note type hook
  const { allMeetingNoteType, isDataLoading: isAllMeetingNotetypeDataLoading } =
    useGetAllNeetingNoteType();

  const { isLoading, isFetching, data } = useGetMeetingNotesByNoteIdQuery(
    noteId || "",
    {
      skip: !noteId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setNoteData(data?.data?.[0]);
    }
  }, [isLoading, isFetching, data]);

  const [updateMeetingNote] = useUpdateNoteMeetingMutation();
  // Form Initial Values
  const initialValues: MeetingNotesTabFormValues = {
    meetingId: MEETING_ID || "",
    clientUserId:
      noteData?.clientUserId === null
        ? ""
        : {
            label: noteData?.clientUserId || "",
            value: noteData?.clientUserId || "",
          },
    notes: noteData?.notes,
    title: noteData?.title,
    meetingNoteTypeId: noteData?.meetingNoteTypeId?.map((noteType: any) => {
      return {
        label: noteType?.meetingNoteType,
        value: noteType?._id,
      };
    }),
  };

  // Validation Schema
  const validationSchema = object().shape({
    meetingNoteTypeId: array()
      .test("nonEmpty", "Please select note type.", function (value) {
        return value && value.length > 0;
      })
      .of(
        object().shape({
          label: string().required("required"),
          value: string().required("Please enter field type"),
        })
      ),

    notes: string().required("Please enter notes"),
  });

  // Handle Submit
  const handleSubmit = (
    values: MeetingNotesTabFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingNotesTabFormValues>
  ) => {
    const { clientUserId, meetingId, meetingNoteTypeId, ...rest } = values;
    const formatedValues = {
      clientUserId: clientUserId?.value,
      meetingId: MEETING_ID,
      meetingNoteTypeId: meetingNoteTypeId
        ? meetingNoteTypeId?.map((noteType: any) => noteType?.value)
        : [],
      ...rest,
    };

    updateMeetingNote({
      id: noteId,
      body: formatedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClickRenderAddForm();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <AddMeetingNotesForm
            formikProps={formikProps}
            actualClientUsers={actualClientUsers}
            isAllMeetingNotetypeDataLoading={isAllMeetingNotetypeDataLoading}
            allMeetingNoteType={allMeetingNoteType}
            formType = "Edit"

          />
        </Form>
      )}
    </Formik>
  );
};

export default EditMeetingNotesFormWrapper;
