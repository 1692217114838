import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import {
  setIsOpenAddDialog,
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/WorkspaceSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { useGetAllWorkspaceWithoutPaginationQuery } from "src/services/RequirementGatheringService";
import AddWorkspaceFormWrapper from "../Add/AddWorkspaceFormWrapper";
import WorkspaceList from "./WorkspaceList";
import AddPlatformWrapper from "../Add/AddPlatformWrapper";
import EditWorkspaceFormWrapper from "../Edit/EditWorkspaceFormWrapper";

type Props = {};

const WorkspaceListWrapper = (props: Props) => {
  const {
    items,
    totalItems,
    page,
    isOpenAddDialog,
    rowsPerPage,
    searchValue,
    isTableLoading,
  } = useSelector((state: RootState) => state.workspaceSlice);

  const dispatch = useDispatch<AppDispatch>();

  const { data, isLoading, isFetching } =
    useGetAllWorkspaceWithoutPaginationQuery({ workspaceName: searchValue });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
  }, [data, isLoading, isFetching, dispatch]);

  const [isOpenAddPlatformDialog, setIsOpenAddPlatformDialog] = useState(false);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>("");
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [selectedModuleId, setSelectedModuleId] = useState<string>("");

  const openAddPlatformDialog = (workspaceId: string) => {
    setSelectedWorkspaceId(workspaceId);
    setIsOpenAddPlatformDialog(true);
  };

  const closeAddPlatformDialog = () => {
    // setSelectedWorkspaceId("");
    setIsOpenAddPlatformDialog(false);
  };

  const openEditDialog = (moduleId: string) => {
    setSelectedModuleId(moduleId);
    setIsOpenEditDialog(true);
  };

  const closeEditDialog = () => {
    setSelectedModuleId("");
    setIsOpenEditDialog(false);
  };

  return (
    <>
      <WorkspaceList
        rows={items}
        addNew={(value) => dispatch(setIsOpenAddDialog(value))}
        openAddPlatformDialog={openAddPlatformDialog}
        openEditDialog={openEditDialog}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      <Suspense fallback={<CircularProgress />}>
        {isOpenAddDialog && (
          <AddWorkspaceFormWrapper
            onClose={() => dispatch(setIsOpenAddDialog(false))}
          />
        )}
      </Suspense>

      <Suspense fallback={<CircularProgress />}>
        {isOpenAddPlatformDialog && (
          <AddPlatformWrapper
            workspaceId={selectedWorkspaceId}
            onClose={closeAddPlatformDialog}
          />
        )}
      </Suspense>

      <Suspense fallback={<CircularProgress />}>
        {isOpenEditDialog && (
          <EditWorkspaceFormWrapper
            moduleId={selectedModuleId}
            onClose={closeEditDialog}
          />
        )}
      </Suspense>
    </>
  );
};

export default WorkspaceListWrapper;
