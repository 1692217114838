import React, { useEffect, useState } from "react";
import ViewInProgressTickets from "./ViewInProgressTickets";

import { useGetAllInProgressTaskQuery } from "src/services/UserService";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

const ViewInProgressTicketsWrapper = () => {
  const [pendingTicketData, setPendingTicketData] = useState<any>([]);

  const { searchValue } = useSelector((state: RootState) => state.teamView);

  const { data, isLoading, isFetching } = useGetAllInProgressTaskQuery(
    searchValue || ""
  );

  useEffect(() => {
    if (!isFetching && !isLoading) { 
      let sortData = [...data?.data]?.sort((a, b) => a?.name?.localeCompare(b?.name));
      setPendingTicketData(sortData || []);
    }
  }, [isLoading, isFetching, data]);

  return (
    <ViewInProgressTickets
      pendingTicketData={pendingTicketData}
      isLoading={isLoading || isFetching}
    />
  );
};

export default ViewInProgressTicketsWrapper;
