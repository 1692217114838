import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";

import CircularProgress from "@mui/material/CircularProgress";
import { ExpenseCategoryFormValues } from "src/models/ExpenseCategory.model";
import {
  useGetGoalCategoryByIdQuery,
  useUpdateGoalCategoryMutation,
} from "src/services/GoalCategoryService";
import GoalCategoryForm from "../Layout/GoalCategoryForm";

type Props = {
  selectedSubscription: string;
  onClose: () => void;
};

const EditGoalCategoryWrapper = ({ selectedSubscription, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetGoalCategoryByIdQuery(selectedSubscription);

  const [updateAccount] = useUpdateGoalCategoryMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: ExpenseCategoryFormValues = {
    title: items?.categoryName || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter goal category"),
  });
  const handleSubmit = (
    values: ExpenseCategoryFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ExpenseCategoryFormValues>
  ) => {
    const formattedValues = {
      categoryName: values?.title,
    };

    updateAccount({
      id: selectedSubscription,
      body: formattedValues,
    }).then((res: any) => {
      updateAccount({
        id: selectedSubscription,
        body: formattedValues,
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <GoalCategoryForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditGoalCategoryWrapper;
