import { CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetJobCreationStageByIdQuery } from "src/services/JobsCreationStageService";

type Props = {
  label: string;
  value: string;
};
const TextShowField = ({ label, value }: Props) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="font-medium text-xs px-1">{label} </div>
      <div className="border p-2 text-sm rounded-lg">{value} </div>
    </div>
  );
};

const ViewJobCreationStage = () => {
  const { stageId } = useParams();
  const [stageData, setStageData] = useState<any>();
  const { data, isFetching, isLoading } =
    useGetJobCreationStageByIdQuery(stageId);
  useEffect(() => {
    if (!isFetching || !isLoading) {
      setStageData(data?.data?.[0]);
    }
  }, [data, isFetching, isLoading]);
  return (
    <div className="flex flex-col gap-4 p-4">
         {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <div className="bg-white text-lg font-medium">
            Stage Details
          </div>
      <div className="grid lg:grid-cols-5 grid-cols-2 gap-4">
        <TextShowField
          label="Created Date"
          value={moment(stageData?.createdAt).format("DD MMM YYYY")}
        />
        <TextShowField label="Stage Name" value={stageData?.stageName} />
        <TextShowField label="Order" value={stageData?.order} />
        <TextShowField label="Create By" value={stageData?.createdByUserName} />
        <TextShowField label="Job Title" value={stageData?.jobTitle} />
      </div>
      <div>
        <div className="text-[17px] font-medium">Questions</div>
        {stageData?.questions?.map((el:any , ind:number)=>{
            return(
                <div key={ind} className="shadow-lg  p-3 rounded-lg mb-3">
                    <p className="font-medium text-[16px] border-b-2 pb-1 text-blue-9">Question : {ind+1} </p>
                  <div className="flex gap-4 pt-2">
                  <TextShowField label="Question Type" value={el?.questionType} />
                 {el?.questionType === 'RANGE' &&  <TextShowField label="Minimum Range" value={el?.minimumValForRange} />}
                 {el?.questionType === 'RANGE' &&  <TextShowField label="Maximum Range" value={el?.maximumValForRange} />}
                  <TextShowField label="Question Title" value={el?.questionTitle} />
                  </div>
                </div>
            )
        })}
      </div>
    </div>
  );
};

export default ViewJobCreationStage;
