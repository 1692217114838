import React, { useEffect, useState, Suspense } from "react";
import ModuleList from "./ModuleList";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
  setIsOpenAddDialog,
} from "src/redux/slices/ModuleSlice";
import { useGetAllModulesWithPaginationQuery } from "src/services/ModuleServices";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdEdit } from "react-icons/md";
import { ModulesListResponse } from "src/models/Module.model";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { CircularProgress } from "@mui/material";

type Props = {};

const ModuleListWrapper = (props: Props) => {
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [selectedModuleId, setSelectedModuleId] = useState<string>("");

  const {
    items,
    totalItems,
    page,
    isOpenAddDialog,
    rowsPerPage,
    searchValue,
    isTableLoading,
  } = useSelector((state: RootState) => state.module);

  // Initiate Methods
  const dispatch = useDispatch<AppDispatch>();

  // Dynamic import
  const AddModuleFormWrapper = React.lazy(
    () => import("../Add/AddModuleFormWrapper")
  );
  const EditModuleFormWrapper = React.lazy(
    () => import("../Edit/EditModuleFormWrapper")
  );

  const { data, isLoading, isFetching } = useGetAllModulesWithPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["moduleName", "projectLabel"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "moduleName",
      headerName: "Module",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[180px]",
      name: UserModuleNameTypes.MODULES_LIST_MODULE,
      renderCell: (row: any) => (
        <div className="capitalize"> {row?.moduleName} </div>
      ),
    },
    {
      field: "projectLabel",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.MODULES_LIST_PROJECT,
      renderCell: (row: any) => (
        <div className="capitalize"> {row?.projectLabel} </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: ModulesListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectedModuleId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_MODULES_EDIT,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ModuleList
        columns={columns}
        rows={items}
        addNew={(value) => dispatch(setIsOpenAddDialog(value))}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      <Suspense fallback={<CircularProgress />}>
        {isOpenAddDialog ? (
          <AddModuleFormWrapper
            onClose={() => dispatch(setIsOpenAddDialog(false))}
          />
        ) : null}
      </Suspense>

      <Suspense fallback={<CircularProgress />}>
        {isOpenEditDialog ? (
          <EditModuleFormWrapper
            moduleId={selectedModuleId}
            onClose={() => setIsOpenEditDialog(false)}
          />
        ) : null}
      </Suspense>
    </React.Fragment>
  );
};

export default ModuleListWrapper;
