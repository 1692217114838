import { Dialog } from "@mui/material";
import { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { MdCancel, MdOutlineFileDownload } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { twMerge } from "tailwind-merge";
// import { IMAGE_BASE_URL } from "src/utils/constants";

type Props = {
  fileInfo: {
    type: any;
    url: string;
  };
  isShowCancel?: boolean;
  onCancelClick?: () => void;
  extraClasses?: string;
  onPreviewClick?: () => void;
  isShowPreview?: boolean;
};

// const IMAGE_URL = "https://fm-api.codioticdemo.com";

//For Image
const ImageFile = ({ fileInfo }: Props) => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  return (
    <>
      <img
        src={fileInfo.url}
        alt=""
        className="w-full h-full rounded cursor-pointer"
        onClick={() => setIsShowDialog(true)}
      />
      <Dialog open={isShowDialog} fullScreen>
        <div className="flex gap-5 flex-col justify-center items-center p-4 bg-gray-200 border-2   ">
          <MdCancel
            color="black"
            size={25}
            className="sticky top-3 self-end cursor-pointer"
            onClick={() => setIsShowDialog(false)}
          />
          <img src={fileInfo.url} alt="" />
        </div>
      </Dialog>
    </>
  );
};

//For Video
const VideoFile = ({ fileInfo }: Props) => {
  const fileType = fileInfo?.url?.split(".")?.at(-1) || "*";
  return fileInfo?.url ? (
    <video controls width="100%" style={{ height: "100%" }}>
      <source src={fileInfo?.url} type={`video/${fileType}`} />
    </video>
  ) : null;
};
//For audio
const AudioFile = ({ fileInfo }: Props) => {
  const fileType = fileInfo?.url?.split(".")?.at(-1) || "*";
  return fileInfo?.url ? (
    <audio controls>
      <source src={fileInfo?.url} type={`video/${fileType}`} />
    </audio>
  ) : null;
};

//For PDF
const PdfFile = ({ fileInfo, isShowPreview, onPreviewClick }: Props) => {
  const fileName = fileInfo?.url?.split("/")?.at(-1) || "";
  return (
    <>
      {isShowPreview ? (
        <div
          className="flex flex-col gap-2 flex-wrap justify-center items-center cursor-pointer"
          onClick={() => onPreviewClick?.()}
        >
          <FaFilePdf color="red" size={25} />
          <span className="font-medium text-center text-xs  break-all w-full">
            {fileName}
          </span>
          <div className="flex gap-1 hover:underline text-blue-500">
            <AiOutlineEye />
            <span className="text-xs ">Preview</span>
          </div>
        </div>
      ) : (
        <a href={fileInfo?.url} target="_blank" rel="noreferrer">
          <div className="flex flex-col gap-2 flex-wrap justify-center items-center">
            <FaFilePdf color="red" size={25} />
            <span className="font-medium text-center text-xs  break-all w-full">
              {fileName}
            </span>
          </div>
        </a>
      )}
    </>
  );
};

//For Excel
const ExcelFile = ({ fileInfo }: Props) => {
  const fileName = fileInfo?.url?.split("/")?.at(-1) || "";
  return (
    <div className="flex flex-col gap-2 items-center justify-center flex-wrap p-2 rounded ">
      <FaFileExcel size={25} color="green" />
      <span className="font-medium text-center text-xs break-all w-full">
        {fileName}
      </span>
      <a href={fileInfo?.url} download>
        <MdOutlineFileDownload size={25} className="cursor-pointer" />
      </a>
    </div>
  );
};

const ATMRenderFile = ({
  fileInfo,
  isShowCancel = false,
  onCancelClick,
  extraClasses,
  isShowPreview,
  onPreviewClick,
}: Props) => {
  const fileData = {
    ...fileInfo,
    type:
      fileInfo?.type === "DOCUMENT"
        ? fileInfo?.url?.split(".")?.at(-1)?.toUpperCase()
        : fileInfo?.type || "",
    url: fileInfo?.url,
  };
  const getComponent = () => {
    switch (fileData?.type) {
      case "IMAGE":
        return <ImageFile fileInfo={fileData} />;
      case "VIDEO":
        return <VideoFile fileInfo={fileData} />;
      case "PDF":
        return (
          <PdfFile
            fileInfo={fileData}
            isShowPreview={isShowPreview}
            onPreviewClick={onPreviewClick}
          />
        );
      case "pdfs":
        return (
          <PdfFile
            fileInfo={fileData}
            isShowPreview={isShowPreview}
            onPreviewClick={onPreviewClick}
          />
        );
      case "XLSX":
        return <ExcelFile fileInfo={fileData} />;
      case "AUDIO":
        return <AudioFile fileInfo={fileData} />;
      default:
        return <ImageFile fileInfo={fileData} />;
    }
  };

  return (
    <div
      className={twMerge(
        ` border-2 rounded border-slate-400 border-dashed px-3 pb-3 ${
          isShowCancel ? "pt-8" : "pt-3"
        }  w-full h-full mt-3 flex items-center justify-center relative ${extraClasses}`
      )}
    >
      {isShowCancel ? (
        <div
          className="absolute top-[5px] right-[5px] bg-slate-500 rounded-xl cursor-pointer hover:bg-red-500 p-1"
          onClick={onCancelClick}
        >
          <RxCross2 size={15} color="white" />
        </div>
      ) : null}
      {getComponent()}
    </div>
  );
};

export default ATMRenderFile;
