import apiSlice from "./ApiSlice";

export const JobApplicationServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // List Event Type
    allJobApplication: builder.query({
      providesTags: ["job-application"],
      query: (body) => ({
        url: "/job-application",
        method: "POST",
        body,
      }),
    }),

    // ADD Event Type
    addJobApplication: builder.mutation({
      invalidatesTags: ["job-application"],
      query: (body) => ({
        url: "/job-application/add",
        method: "POST",
        body,
      }),
    }),
    // Get Event Type
    getJobApplication: builder.query({
      providesTags: ["job-application"],
      query: (id: string) => {
        return {
          url: `/job-application/${id}`,
          method: "GET",
        };
      },
    }),

    // Update Event Type
    updateJobApplication: builder.mutation({
      invalidatesTags: ["job-application"],
      query: ({ id, body }) => {
        return {
          url: `/job-application/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // Get Event Type
    getAllJobApplication: builder.query({
      providesTags: ["job-application"],
      query: (id: string) => {
        return {
          url: `/job-application`,
          method: "GET",
        };
      },
    }),
    //DELETE job application
    deleteJobApplication: builder.mutation({
      invalidatesTags: ["job-application"],
      query: (id) => {
        return {
          url: `/job-application/${id}`,
          method: "DELETE",
        };
      },
    }),
    //ShortList job application
    updateJobApplicationStatus: builder.mutation({
      invalidatesTags: ["job-application"],
      query: ({ applicationId, body }) => {
        return {
          url: `/job-application/update-status/${applicationId}`,
          method: "PUT",
          body,
        };
      },
    }),

    //add job application stage
    getJobApplicationStages: builder.query({
      providesTags: ["job-stage"],
      query: ({ jobId, applicationId }) => {
        return {
          url: `job-stage/job/${jobId}/application/${applicationId}`,
          method: "GET",
        };
      },
    }),
    //add job application stage
    applicationStageFeedback: builder.mutation({
      invalidatesTags: ["job-stage", "job-application"],
      query: ({ body, interviewId }) => {
        return {
          url: `/interview/feedback/${interviewId}`,
          method: "PUT",
          body,
        };
      },
    }),
    //add interview
    scheduleInterview: builder.mutation({
      invalidatesTags: ["job-stage"],
      query: ({ body }) => {
        return {
          url: `/interview/add`,
          method: "POST",
          body,
        };
      },
    }),
    //add interview
    proceedNextStage: builder.mutation({
      invalidatesTags: ["job-stage"],
      query: ({ interviewId }) => {
        return {
          url: `/interview/proceed-next-round/${interviewId}`,
          method: "PUT",
        };
      },
    }),
  }),
});

export const {
  useAllJobApplicationQuery,
  useAddJobApplicationMutation,
  useGetJobApplicationQuery,
  useUpdateJobApplicationMutation,
  useGetAllJobApplicationQuery,
  useDeleteJobApplicationMutation,
  useApplicationStageFeedbackMutation,
  useGetJobApplicationStagesQuery,
  useScheduleInterviewMutation,
  useProceedNextStageMutation,
  useUpdateJobApplicationStatusMutation,
} = JobApplicationServiceApi;
