import React from "react";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { FormikProps } from "formik";
import { FormInitialValues } from "./LeadsFilterFormCardDialogWrapper";

// HOOKS
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import useLeadsLabels from "src/hooks/useLeadsLabels";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  AllLabels: any;
  onClose: () => void;
}; 
const LeadsOption = [
  {
    label: "YES",
    value: true,
  },
  {
    label: "NO",
    value: false,
  },
];

const LeadsFilterFormCardDialog = ({
  open,
  formikProps,
  onReset,
  AllLabels,
  onClose,
}: Props) => {
  // const dispatch = useDispatch();
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  const { LeadsLabels, isDataLoading: isLeadsLabelsLoading } = useLeadsLabels();

  return (
    <MOLFilterDialog 
   title={"Filters"}
   onClose={onClose}
   isSubmitting={isSubmitting}
   clearFilter={onReset}
   handleSubmit={handleSubmit}
   open={open}
   >
     <div className="flex flex-col gap-4 px-4 py-2">
        <div className="flex flex-col gap-4">

          <div>
          <ATMSelect
              name="starLeads"
              label="Is Star Leads"
              placeholder="Select Is Star Leads"
              value={values?.starLeads}
              options={LeadsOption}
              onChange={(newValue) => {
                setFieldValue("starLeads", newValue);
              }}
            />
          </div>
          {/* Lead Labels */}
          <div>
            <ATMSelect
              name="leadLabel"
              label="Lead Label"
              placeholder="Select Lead Label"
              value={values?.leadLabel}
              isLoading={isLeadsLabelsLoading}
              options={LeadsLabels?.map((ele: any) => {
                return {
                  label: ele?.leadLabelName,
                  value: ele?.leadLabelName,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("leadLabel", newValue);
              }}
            />
          </div>

          {/* Last folloup date */}
          <div className="w-full">
            <ATMDatePicker
              label="Next Follow Up Date"
              name="dueDate"
              format="DD/MM/YYYY"
              value={values?.followUpdate}
              onChange={(newValue) => setFieldValue("followUpdate", newValue)}
            />
          </div>
        </div>
      </div>
   </MOLFilterDialog>
  );
};

export default LeadsFilterFormCardDialog;
