import React from 'react'
import SideNavLayout from 'src/components/layouts/SideNavLayout/SideNavLayout'

const Welcome = () => {

  return (
    <>
      <SideNavLayout>
        < div className='flex justify-center py-5' >
          Welcome to my codiotic
        </div >
      </SideNavLayout >
    </>
  )
}

export default Welcome