import apiSlice from "./ApiSlice";

export const performanceServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Clarification By User
    clarificationRequestByUser: builder.mutation({
      invalidatesTags: ["incident"],
      query: ({ id, body }) => {
        return {
          url: `/pointer/clarification/user/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //In Perfomance list  by Category
    categoryWisePointer: builder.query({
      providesTags: ["incident"],
      query: (body) => ({
        url: "/pointer/category-wise-pointers",
        method: "POST",
        body,
      }),
    }),
    //Not me Request
    notMeRequest: builder.mutation({
      invalidatesTags: ["incident"],
      query: ({ id, body }) => {
        return {
          url: `/pointer/not-me/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // List Pointer
    performanceList: builder.query({
      providesTags: ["incident"],
      query: (body) => ({
        url: "/pointer/user-pointers",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCategoryWisePointerQuery,
  useClarificationRequestByUserMutation,
   usePerformanceListQuery ,
  useNotMeRequestMutation,
} = performanceServiceApi;
