import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { MeetingTypeFormValues } from "src/models/MeetingType.model";
import MeetingTypeForm from "../Layouts/MeetingTypeForm";
import { useAddMeetingTypeMutation } from "src/services/MeetingTypeService";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
};

const AddMeetingTypeWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addMeetingType] = useAddMeetingTypeMutation();

  // Form Initial Values
  const initialValues: MeetingTypeFormValues = {
    meetingType: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    meetingType: string().required("Please enter meeting  type"),
  });

  // Handle Submit
  const handleSubmit = (
    values: MeetingTypeFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MeetingTypeFormValues>
  ) => {
    addMeetingType(values).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <MeetingTypeForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddMeetingTypeWrapper;
