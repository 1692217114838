import apiSlice from "./ApiSlice";

export const clientApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all clients with pagination
    getClientsPagination: builder.query({
      providesTags: ["clients"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/client",
          method: "POST",
          body,
        };
      },
    }),

    // Get all clients
    getClients: builder.query({
      providesTags: ["clients"],
      query: () => {
        return {
          url: "/client",
          method: "GET",
        };
      },
    }),

    // Add client
    addClient: builder.mutation({
      invalidatesTags: ["clients"],
      query: (body) => {
        return {
          url: "/client/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get single client
    getSingleClient: builder.query({
      providesTags: ["clients"],
      query: (id: string) => {
        return {
          url: `/client/${id}`,
          method: "GET",
        };
      },
    }),

    // Update client
    updateClient: builder.mutation({
      invalidatesTags: ["clients"],
      query: ({ id, body }) => {
        return {
          url: `/client/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // ADD PAYMENT
    getClientLeadger: builder.query({
      providesTags: ["clients"],
      query: (body) => {
        return {
          url: `/client-ledger/`,
          method: "POST",
          body,
        };
      },
    }),
    // ADD MILESTORE 
    addMilestore: builder.mutation({
      invalidatesTags: ["clients"],
      query: (body) => {
        return {
          url: `/client-ledger/add`,
          method: "POST",
          body,
        };
      },
    }),
    // ADD MILESTORE 
    getDefaultCurrency: builder.query({
      providesTags: ["clients"],
      query: (clientId) => {
        return {
          url: `/currency/${clientId}/default`,
          method: "GET",
        };
      },
    }),
    
    //
  }),
});

export const {
  useGetClientsPaginationQuery,
  useGetClientsQuery,
  useAddClientMutation,
  useGetSingleClientQuery,
  useUpdateClientMutation,
  useAddMilestoreMutation,
  useGetClientLeadgerQuery, 
  useGetDefaultCurrencyQuery
} = clientApi;
