import React, { useState } from "react";
import { HiOutlineDocument } from "react-icons/hi";
import { TbX } from "react-icons/tb";
import ATMDialog from "src/components/UI/atoms/ATMDialog/ATMDialog";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFileUpload from "src/components/UI/molecules/MOLFileUpload/MOLFileUpload";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import MOLUserDropdown from "src/components/UI/molecules/MOLUserDropdown/MOLUserDropdown";
import { extractFileInfoFromUrl } from "src/utils/common/extractFileInfoFromUrl";
import { showToast } from "src/utils/showToaster";
import { handleValidNumber } from "src/utils/validations/numberInput";

type Values = {
  title: string;
  completeWithinDays: string;
  description: string;
  file: any[];
  isMandatory: boolean;
  responsibleUserId: string;
};

type Props = {
  values: Values;
  onClose: () => void;
  onSave: (values: Values) => void;
  formType?: "ADD" | "EDIT";
};

const StageDocumentDataFormDialog = ({
  values,
  onClose,
  onSave,
  formType = "ADD",
}: Props) => {
  const [formValues, setFormValues] = useState<Values>(values);

  // const [initialzeSubmit, setInitialzeSubmit] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const errorMsg = {
    title: "Please enter title",
    completeWithinDays: "Please enter days",
    responsibleUserId: "Please select user",
  };

  const [errors, setErrors] = useState({
    title: "",
    completeWithinDays: "",
    description: "",
    file: "",
    isMandatory: "",
    responsibleUserId: "",
  });

  const validate = () => {
    for (const key in formValues) {
      if ((formValues as any)[key] === "") {
        (errorMsg as any)[key] &&
          setErrors((prev) => ({
            ...prev,
            [key]: (errorMsg as any)[key],
          }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [key]: "",
        }));
      }
    }

    const errorList = Object.entries(errors);
    setIsValid(errorList?.every((error) => error?.[1] === ""));
  };

  const handleOnChange = (fieldName: string, newValue: any) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: newValue,
    }));
  };

  const ErrorMessage = ({ name }: { name: string }) => {
    return (
      <>
        {(errors as any)[name] ? (
          <p className="text-xs text-red-500"> {(errors as any)[name]} </p>
        ) : null}
      </>
    );
  };

  return (
    <ATMDialog>
      <div className="p-4 w-[90vw] flex flex-col gap-4 h-[90vh] ">
        <div className="grid grid-cols-[1fr_300px] gap-4 flex-1">
          <div className="flex flex-col h-full gap-4 p-4">
            {/* Title */}
            <div className="">
              <ATMTextField
                name=""
                value={formValues?.title}
                onChange={(e) => handleOnChange("title", e.target?.value)}
                label="Title"
                placeholder="Enter title"
              />

              <ErrorMessage name="title" />
            </div>

            {/* Description */}
            <div className="overflow-auto">
              <MOLMarkdownEditor
                name=""
                value={formValues?.description}
                onChange={(html) => handleOnChange("description", html)}
                label="Description"
                placeholder="Start writting here..."
                extraClassName="overflow-auto h-[calc(90vh-300px)]"
              />
            </div>
          </div>

          <div className="p-4 space-y-4 border rounded-md">
            {/* Complete Within Days */}
            <div>
              <ATMTextField
                name=""
                value={formValues?.completeWithinDays}
                onChange={(e) => {
                  handleValidNumber(e) &&
                    handleOnChange("completeWithinDays", e.target?.value);
                }}
                label="Complete Within Days"
                placeholder="Enter no. of days"
              />

              <ErrorMessage name="completeWithinDays" />
            </div>

            {/* Resposible User Id */}
            <div>
              <MOLUserDropdown
                name=""
                value={formValues?.responsibleUserId}
                onChange={(newValue) =>
                  handleOnChange("responsibleUserId", newValue)
                }
                label="Resposible User"
                placeholder="Select user"
              />

              <ErrorMessage name="responsibleUserId" />
            </div>

            <div className="py-2">
              <ATMCheckbox
                label="Is Mandatory"
                checked={formValues?.isMandatory}
                onChange={(newValue) => handleOnChange("isMandatory", newValue)}
              />
            </div>

            <div className="flex col-span-full">
              <MOLFileUpload
                name=""
                label="Documents"
                value={""}
                onChange={(newFile: any) => {
                  handleOnChange("file", [...formValues?.file, newFile?.url]);
                }}
                placeholder="Select file"
              />
            </div>

            <div className="flex flex-col gap-4">
              {formValues?.file?.map((file, fileIndex) => {
                const url = extractFileInfoFromUrl(file);
                return (
                  <div
                    key={fileIndex}
                    className="relative flex items-center gap-2 p-2 bg-white border-2 rounded-md"
                  >
                    {url?.type === "images" ? (
                      <img
                        src={file}
                        alt="document"
                        className="size-[50px] rounded-md border"
                      />
                    ) : (
                      <div className="size-[50px] flex justify-center items-center text-xl border rounded-md bg-gray-50">
                        <HiOutlineDocument />
                      </div>
                    )}

                    <div className="w-[200px] truncate text-xs font-semibold">
                      {url?.name}
                    </div>

                    <div
                      onClick={() => {
                        const newList = [...formValues?.file];

                        newList.splice(fileIndex, 1);

                        handleOnChange("file", newList);
                      }}
                      className="absolute size-[28px] cursor-pointer hover:bg-gray-50 rounded-full border text-[20px] -top-[14px] -right-[14px] flex justify-center items-center border-gray-400 text-gray-500"
                    >
                      <TbX />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <ATMLoadingButton onClick={onClose} variant="tonal">
            {" "}
            Cancel{" "}
          </ATMLoadingButton>

          <ATMLoadingButton
            onClick={() => {
              validate();
              setTimeout(() => {
                if (isValid) {
                  onSave(formValues);
                  onClose();
                  showToast("success", "Added successfully");
                }
              }, 300);
            }}
            variant="outlined"
          >
            Save & Close
          </ATMLoadingButton>

          {formType === "ADD" && (
            <ATMLoadingButton
              onClick={() => {
                validate();
                setTimeout(() => {
                  if (isValid) {
                    onSave(formValues);
                    showToast("success", "Added successfully");
                  }
                }, 300);
              }}
            >
              Save
            </ATMLoadingButton>
          )}
        </div>
      </div>
    </ATMDialog>
  );
};

export default StageDocumentDataFormDialog;
