import { default as dashboardJson } from "../../utils/ModulesJson/dashboard.json";
import { default as campaignJson } from "../../utils/ModulesJson/campaign.json";
import { default as campaignGrouJson } from "../../utils/ModulesJson/campaignGroup.json";
import { default as changeRequestJson } from "../../utils/ModulesJson/changeRequest.json";
import { default as channelJson } from "../../utils/ModulesJson/channel.json";
import { default as channelGroupJson } from "../../utils/ModulesJson/channelGroup.json";
import { default as clientUsersJson } from "../../utils/ModulesJson/clientUsers.json";
import { default as clientsJson } from "../../utils/ModulesJson/clients.json";
import { default as currencyJson } from "../../utils/ModulesJson/currency.json";
import { default as deliveryDateJson } from "../../utils/ModulesJson/deliveryDate.json";
import { default as deploymentRequestJson } from "../../utils/ModulesJson/deploymentRequest.json";
import { default as domainJson } from "../../utils/ModulesJson/domain.json";
import { default as doubtsJson } from "../../utils/ModulesJson/doubts.json";
import { default as dumpsJson } from "../../utils/ModulesJson/dumps.json";
import { default as featuresJson } from "../../utils/ModulesJson/features.json";
import { default as followUpJson } from "../../utils/ModulesJson/followUp.json";
import { default as holidayCalendarJson } from "../../utils/ModulesJson/holidayCalendar.json";
import { default as leadLabelJson } from "../../utils/ModulesJson/leadLabel.json";
import { default as leadsJson } from "../../utils/ModulesJson/leads.json";
import { default as leaveRequestJson } from "../../utils/ModulesJson/leaveRequest.json";
import { default as locationJson } from "../../utils/ModulesJson/location.json";
import { default as meetingJson } from "../../utils/ModulesJson/meeting.json";
import { default as modulesJson } from "../../utils/ModulesJson/modules.json";
import { default as projectsJson } from "../../utils/ModulesJson/projects.json";
import { default as resourceRequestJson } from "../../utils/ModulesJson/resourceRequest.json";
import { default as serverJson } from "../../utils/ModulesJson/server.json";
import { default as socialMediaJson } from "../../utils/ModulesJson/socialMedia.json";
import { default as taskJson } from "../../utils/ModulesJson/task.json";
import { default as teamViewJson } from "../../utils/ModulesJson/teamView.json";
import { default as teamsJson } from "../../utils/ModulesJson/teams.json";
import { default as ticketsJson } from "../../utils/ModulesJson/tickets.json";
import { default as gitRepoJson } from "../../utils/ModulesJson/GitRepo.json";
import { default as productJson } from "../../utils/ModulesJson/product.json";
import { default as subscriptionJson } from "../../utils/ModulesJson/subscription.json";
import { default as departmentJson } from "../../utils/ModulesJson/department.json";
import { default as rolesJson } from "../../utils/ModulesJson/roles.json";
import { default as teamAttendanceJson } from "../../utils/ModulesJson/teamAttendance.json";
import { default as accountJson } from "../../utils/ModulesJson/accounts.json";
import { default as expenseCategoryJson } from "../../utils/ModulesJson/expensCategory.json";
import { default as expensesJson } from "../../utils/ModulesJson/expenses.json";
import { default as recurringPaymentsJson } from "../../utils/ModulesJson/recurringPayments.json";
import { default as upcomingPaymentsJson } from "../../utils/ModulesJson/upcomingPayments.json";
import { default as financeDashboardJson } from "../../utils/ModulesJson/financeDashboard.json";
import { default as meetingNoteTypeJson } from "../../utils/ModulesJson/meetingNoteType.json";
import { default as meetingTypeJson } from "../../utils/ModulesJson/meetingType.json";
import { default as reminderJson } from "../../utils/ModulesJson/reminder.json";
import { default as meetingNotesJson } from "../../utils/ModulesJson/meetingNotes.json";
import { default as goalJson } from "../../utils/ModulesJson/goal.json";
import { default as goalCategoryJson } from "../../utils/ModulesJson/goalCategory.json";
import { default as serviceTicketJson } from "../../utils/ModulesJson/serviceTicket.json";
import { default as incidentJson } from "../../utils/ModulesJson/incident.json";
import { default as timeAnalyticsJson } from "../../utils/ModulesJson/TimeAnalytics.json";
import { default as incidentCategoryJson } from "../../utils/ModulesJson/incidentCategory.json";
import { default as incidentTypeJson } from "../../utils/ModulesJson/IncidentType.json";
import { default as workspaceJson } from "../../utils/ModulesJson/workspace.json";
import { default as ticketGroupJson } from "../../utils/ModulesJson/ticketGroup.json";
import { default as estimationCalculatorJson } from "../../utils/ModulesJson/estimationCalculator.json";
import { default as requirementGatheringJson } from "../../utils/ModulesJson/requirementGathering.json";
import { default as jobCreationJson } from "../../utils/ModulesJson/jobCreation.json";
import { default as payoutJson } from "../../utils/ModulesJson/payout.json";
import { default as industryJson } from "../../utils/ModulesJson/industry.json";
import { default as businessJson } from "../../utils/ModulesJson/business.json";
import { default as featureAsOperationsJson } from "../../utils/ModulesJson/featureAsOperations.json";

export const mergeUserModules = [
  { ...dashboardJson },
  { ...clientsJson },
  { ...clientUsersJson },
  { ...teamsJson },
  { ...projectsJson },
  { ...workspaceJson },
  { ...requirementGatheringJson },
  { ...modulesJson },
  { ...featuresJson },
  { ...changeRequestJson },
  { ...ticketsJson },
  { ...ticketGroupJson },
  { ...serviceTicketJson },
  { ...gitRepoJson },

  { ...serverJson },
  { ...deploymentRequestJson },
  { ...taskJson },
  { ...teamViewJson },
  { ...deliveryDateJson },
  { ...leaveRequestJson },
  { ...doubtsJson },
  { ...meetingJson },
  { ...locationJson },
  { ...currencyJson },
  { ...meetingNoteTypeJson },
  { ...meetingTypeJson },
  { ...meetingNotesJson },
  { ...resourceRequestJson },
  { ...campaignJson },
  { ...campaignGrouJson },
  { ...channelJson },
  { ...channelGroupJson },

  { ...socialMediaJson },
  { ...leadsJson },
  { ...leadLabelJson },
  { ...followUpJson },
  { ...domainJson },

  { ...holidayCalendarJson },
  { ...dumpsJson },
  { ...teamAttendanceJson },
  { ...productJson },
  { ...subscriptionJson },
  { ...financeDashboardJson },
  { ...recurringPaymentsJson },
  { ...upcomingPaymentsJson },
  { ...accountJson },
  { ...expenseCategoryJson },
  { ...expensesJson },
  { ...reminderJson },
  { ...goalJson },
  { ...goalCategoryJson },
  { ...timeAnalyticsJson },
  { ...incidentCategoryJson },
  { ...incidentTypeJson },
  { ...incidentJson },
  { ...estimationCalculatorJson },
  { ...jobCreationJson },
  { ...payoutJson },
  { ...industryJson },
  { ...businessJson },
  { ...featureAsOperationsJson },
  { ...departmentJson },
  { ...rolesJson },
];
