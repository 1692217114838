import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type RoundTrackingReportSliceStateType = {
  items: any[] | [];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  filterBy: {
    fieldName: string;
    value: string[];
  }[];
  dateFilter: {
    startDate: string | null;
    endDate: string | null;
    dateFilterKey: string
  };
  getSelectedUser: string
  year: string
  month: string
};

const initialState: RoundTrackingReportSliceStateType = {
  items: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  rowsPerPage: 20,
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  filterBy: [
   
  ],
  dateFilter: {
    startDate: null,
    endDate: null,
    dateFilterKey: '',
  },
  getSelectedUser: '',
  month: '',
  year: ''
};

const RoundTrackingReportSlice: Slice<RoundTrackingReportSliceStateType> = createSlice({
  name: "roundTrackingReport",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (state, action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (state, action: PayloadAction<{ fieldName: string; value: string[] }[]>) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setDateFilter: (state, action: PayloadAction<{ startDate: string; endDate: string, dateFilterKey: string }>) => {
      state.dateFilter = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<any>) => {
      state.getSelectedUser = action.payload;
    },
    setMonth: (state, action: PayloadAction<any>) => {
      state.month = action.payload;
    },
    setYear: (state, action: PayloadAction<any>) => {
      state.year = action.payload;
    },
  },
});

export const {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setFilterBy,
  setSelectedUser,
  setYear,
  setMonth
} = RoundTrackingReportSlice.actions;
export default RoundTrackingReportSlice.reducer;