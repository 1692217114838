import apiSlice from "./ApiSlice";

export const requirementApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get
    getAllRequirementWithPagination: builder.query({
      providesTags: ["requirement"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/requirement",
          method: "POST",
          body,
        };
      },
    }),

    // Get Feature or Requirement by moduleId
    getAllRequirementByModuleId: builder.query({
      providesTags: ["requirement"],
      query: (moduleId) => {
        return {
          url: `requirement/module/${moduleId}`,
          method: "GET",
        };
      },
    }),

    // Get Feature or Requirement by projectId
    getAllRequirementByProjectId: builder.query({
      providesTags: ["requirement"],
      query: (pId) => {
        return {
          url: `/project/${pId}/requirements`,
          method: "GET",
        };
      },
    }),

    // Get
    getRequirement: builder.query({
      providesTags: ["requirement"],
      query: () => {
        return {
          url: "/requirement/",
          method: "GET",
        };
      },
    }),

    // Add
    addRequirement: builder.mutation({
      invalidatesTags: ["requirement"],
      query: (body) => {
        return {
          url: "/requirement/add",
          method: "POST",
          body,
        };
      },
    }),

    // Single Product Delete
    deleteRequirementById: builder.mutation({
      invalidatesTags: ["requirement"],
      query: (id: any) => ({
        url: `/requirement/${id}`,
        method: "DELETE",
      }),
    }),

    // Get the Git Repo  by id
    getRequirementById: builder.query({
      providesTags: ["requirement"],
      query: (id: string) => {
        return {
          url: `/requirement/${id}`,
          method: "GET",
        };
      },
    }),

    // Update Git Repo
    updateRequirementById: builder.mutation({
      invalidatesTags: ["requirement"],
      query: ({ id, body }) => {
        return {
          url: `/requirement/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Get requirement by module id
    getFeaturesByModuleId: builder.query({
      providesTags: ["features"],
      query: (mid) => {
        return {
          url: `/requirement/module/${mid}`,
          method: "GET",
        };
      },
    }),

    // TIME Analytics Moudle

    getAllTimeAnalyticsWithPagination: builder.query({
      providesTags: ["time-analytics"],
      query: (body: any) => {
        return {
          url: "/analytics/nav-project-time-analytics",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllRequirementWithPaginationQuery,
  useGetAllRequirementByProjectIdQuery,
  useGetAllRequirementByModuleIdQuery,
  useAddRequirementMutation,
  useGetRequirementQuery,
  useGetRequirementByIdQuery,
  useUpdateRequirementByIdMutation,
  useDeleteRequirementByIdMutation,
  useGetFeaturesByModuleIdQuery,

  // Time Analytics
  useGetAllTimeAnalyticsWithPaginationQuery
} = requirementApi;
