import React from "react";

export enum ENMTicketStatus {
  "UNMAPPED" = "Pending",
  "MAPPED" = "Mapped",
  "OPEN" = "Open",
  "ASSIGNED" = "Assigned",
  "CLOSED" = "Closed",
  "APPROVAL_PENDING" = "Approval Pending",
  "DONE" = "Done",
  "APPROVAL_DONE" = "Approval Done",
}

export type TYPTicketStatus = keyof typeof ENMTicketStatus;

type Props = {
  status: TYPTicketStatus;
};

const getClassNameByStatus = (status: TYPTicketStatus) => {
  switch (status) {
    case "UNMAPPED":
      return "text-rose-600 border-rose-600 bg-rose-100";

    case "MAPPED":
      return "text-sky-600 border-sky-600 bg-sky-100";

    case "APPROVAL_PENDING":
      return "text-yellow-600 border-yellow-600 bg-yellow-100";

    case "APPROVAL_DONE":
      return "text-green-600 border-green-600 bg-green-100";

    case "OPEN":
      return "text-yellow-500 bg-yellow-100 border-yellow-500";

    case "ASSIGNED":
      return "text-pink-500 bg-pink-100 border-pink-500";

    case "CLOSED":
      return "text-slate-500 bg-slate-100 border-slate-500";

    case "DONE":
      return "text-[#008080] border-[#008080] bg-[#00808033]";
  }
};

const MOLTicketStatusChip = ({ status }: Props) => {
  const className = ` 
    font-medium
    rounded-full
    px-2
    py-[2px]
    text-[10px]
    border-[1px]
    ${getClassNameByStatus(status)}`;

  return <div className={className}> {ENMTicketStatus[status]} </div>;
};

export default MOLTicketStatusChip;
