import React from "react";
import ShowInprogressTaskForm from "./ShowInprogressTaskForm";

const ShowInprogressTaskFormWrapper = ({
  taskData,
  setIsShowInprogressTask,
}: any) => {
  return (
    <ShowInprogressTaskForm
      taskNotificationList={taskData}
      setIsShowInprogressTask={setIsShowInprogressTask}
    />
  );
};

export default ShowInprogressTaskFormWrapper;
