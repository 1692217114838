import React, { useEffect, useState } from "react";
import TeamSalaryTabList from "./TeamSalaryTabList";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/TeamSalaryTabSlice";
import AddIncreamentFormWrapper from "../Add/AddIncreamentFormWrapper";
import { useGetMemberSalaryHistoryQuery, useGetTeamMemberCurrentsalaryQuery } from "src/services/TeamSalaryTabService";
import moment from "moment";
import { useParams } from "react-router-dom";

type Props = {};

const TeamSalaryTabListWrapper = (props: Props) => {
    const { teamMemberId } = useParams();
  const dispatch = useDispatch();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [currentSalary , setCurrentSalary] = useState('0')

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.teamSalaryTab);

    const {data:memberData , isLoading :memberDataIsLoading , isFetching : memberDataIsFetching} = useGetTeamMemberCurrentsalaryQuery(teamMemberId) 

  const { data, isLoading, isFetching } = useGetMemberSalaryHistoryQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["teamMemberId"],
    page: page,
    filterBy: [{
        fieldName :'teamMemberId' ,
        value :[teamMemberId]
    }],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(()=>{
    setCurrentSalary(memberData?.data?.salary)
 },[memberData , memberDataIsLoading , memberDataIsFetching])

  // History Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Increment Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div className="py-1">
          <div className="text-sm text-slate-700 font-medium">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="flex mt-1">
            <span className="text-xs text-slate-500 font-medium">
              {moment(row?.createdAt).format("hh:mm A")}
            </span>
          </div>
        </div>
      ),
    },
    {
      field: "incrementEffectiveDate",
      headerName: "Effective Date",
      flex: "flex-[1_1_0%]",
      renderCell: (row: any) => (
        <div className="py-1">
          <div className="text-sm text-slate-700 font-medium">
            {moment(row?.incrementEffectiveDate).format("DD MMM YYYY")}
          </div>
        </div>
      ),
    },
    { field: "currentSalary", headerName: "Current Salary", flex: "flex-[1_1_0%]" ,  renderCell: (row: any) => (
         <div> ₹{row?.currentSalary || 0}</div>) },
    { field: "newSalary", headerName: "New Salary", flex: "flex-[1_1_0%]" , renderCell: (row: any) => (
         <div> ₹{row?.newSalary || 0}</div>)  },
    { field: "incrementPercent", headerName: "Increment Percent", flex: "flex-[1_1_0%]", renderCell:(row:any)=>(
        <div>{row?.incrementPercent}%</div>
    ) },
    { field: "addedByName", headerName: "Add By", flex: "flex-[1_1_0%]" },
  ];

  return (
    <div>
      <TeamSalaryTabList
        rows={items}
        columns={columns}
        currentSalary={currentSalary}
        onAddNew={() => {
          setOpenAddDialog(true);
        }}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {openAddDialog && (
        <AddIncreamentFormWrapper 
         isLoading ={memberDataIsFetching} 
        currentSalary={currentSalary}
          onClose={() => {
            setOpenAddDialog(false);
          }}
        />
      )}
    </div>
  );
};

export default TeamSalaryTabListWrapper;
