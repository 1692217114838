import React from "react";

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  label?: string;
  placeholder?: string;
}

const ATMContactSelect = ({
  options,
  onChange,
  value,
  label,
  placeholder,
}: SelectProps) => {
  return (
    <div className="flex flex-col">
      {label && <label className="text-[12px] mx-1">{label}</label>}
      <select
        value={value}
        onChange={onChange}
        className="outline-0 !text-[14px] font-medium text-primary-dark w-fit"
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options?.map((option, index) => (
          <option key={index} value={option?.value} className="!py-4">
            {option?.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ATMContactSelect;
