import apiSlice from "../ApiSlice";

export const recurringIncomeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // recurring income pagination
    recurringIncomeList: builder.query({
      query: (body) => {
        return {
          url: "/recurring-income",
          method: "POST",
          body,
        };
      },
    }),
    //  recurring income add
    addRecurringIncome: builder.mutation({
      query: (body) => {
        return {
          url: "recurring-income/add",
          method: "POST",
          body,
        };
      },
    }),
    // recurring income update
    udateRecurringIncome: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `recurring-income/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    // recurring income get one
    getOneRecurringIncome: builder.query({
      query: (id) => {
        return {
          url: `recurring-income/${id}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useAddRecurringIncomeMutation,
  useGetOneRecurringIncomeQuery,
  useRecurringIncomeListQuery,
  useUdateRecurringIncomeMutation,
} = recurringIncomeApi;
