import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  JobApplicationChangeStatusValues,
  JobApplicationFormValues,
} from "src/models/JobApplication.model";
import {
  employmentTypeOption,
  experienceRangeOption,
  joiningAvailabilityOptions,
} from "src/screens/JobApplication/Layouts/JobApplicationForm";
import UpdateJobApplicationStatusWrapper from "src/screens/JobApplication/UpdateJobApplicationStatus/UpdateJobApplicationStatusWrapper";
import {
  useGetJobApplicationQuery,
  useUpdateJobApplicationMutation,
} from "src/services/JobApplicationService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import UpdateJobApplicationForm from "./UpdateJobApplicationForm";

const UpdateJobApplicationWrapper = () => {
  // Form Initial Values
  const navigate = useNavigate();
  const { id: jobId, applicationId } = useParams();

  const [items, setItems] = useState<any>({});
  const [isShowResume, setIsShowResume] = useState(false);
  const [statusPopupInfo, setStatusPopupInfo] = useState<{
    isOpen: boolean;
    status: JobApplicationChangeStatusValues;
  }>({
    isOpen: false,
    status: "UNDER_REVIEW",
  });

  const [updateApplication] = useUpdateJobApplicationMutation();
  const { data, isLoading, isFetching } = useGetJobApplicationQuery(
    applicationId || ""
  );
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data?.[0] || {});
    }
  }, [isLoading, isFetching, data]);

  const initialValues: JobApplicationFormValues = {
    jobId: items?.jobId || "",
    platform: items?.platform || "",
    applicantName: items?.applicantName || "",
    applicantEmail: items?.applicantEmail || "",
    applicantPhoneNumber: items?.applicantPhoneNumber || "",
    resumeLink: items?.resumeLink
      ? {
          url: items?.resumeLink,
          type: "PDF",
        }
      : "",
    platformApplicationURL: items?.platformApplicationURL || "",
    primerySkills: items?.primarySkills
      ? items?.primarySkills?.map((el: any) => {
          return {
            label: el?.skillName,
            value: el?.skillId,
          };
        })
      : [],
    expectedPackageMin: items?.expectedPackageMin || "",
    expectedPackageMax: items?.expectedPackageMax || "",
    isExperienced: items?.isExperienced || false,
    experienceRange:
      experienceRangeOption?.find(
        (option) => option?.value === items?.experienceRange
      ) || "",
    employmentType:
      employmentTypeOption?.find(
        (option) => option?.value === items?.employmentType
      ) || "",
    joiningAvailability:
      joiningAvailabilityOptions?.find(
        (option) => option?.value === items?.joiningAvailability
      ) || "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    applicantName: string().required("Please enter name"),
    applicantEmail: string()
      .email("Please enter valid email")
      .required("Please enter email"),
    applicantPhoneNumber: string()
      .min(10, "Invalid number")
      .max(10, "Invalid number")
      .required("Please enter mobile number"),
    resumeLink: object().required("Please upload resume"),
    // platformApplicationURL: string().url("Please enter valid link"),
    // joiningAvailability: object().required("Please select joining type"),
    // employmentType: object().required("Please select employment type"),
    // experienceRange: object().test(
    //   "exp-test",
    //   "Please select experience",
    //   (value, context) => {
    //     const { isExperienced } = context.parent;
    //     return isExperienced ? (value ? true : false) : true;
    //   }
    // ),
    // expectedPackageMin: string().required("Please enter min package"),
    // expectedPackageMax: string().required("Please enter max package"),
    // primerySkills: array()
    //   .min(1, "Please enter the skills")
    //   .required("Please enter the skills"),
  });

  // Handle Submit
  const handleSubmit = (
    values: JobApplicationFormValues,
    { setSubmitting, resetForm }: FormikHelpers<JobApplicationFormValues>
  ) => {
    const formattedValues = {
      jobId: values?.jobId,
      platform: values?.platform,
      applicantName: values?.applicantName,
      applicantEmail: values?.applicantEmail,
      applicantPhoneNumber: values?.applicantPhoneNumber,
      resumeLink: values?.resumeLink?.url,
      platformApplicationURL: values?.platformApplicationURL,
      primarySkills: values.primerySkills?.map((skill: any) => skill?.value),
      expectedPackageMin: values?.expectedPackageMin || 0,
      expectedPackageMax: values?.expectedPackageMax || 0,
      isExperienced: values?.isExperienced,
      experienceRange: values?.experienceRange?.value || "",
      employmentType: values?.employmentType?.value || "",
      joiningAvailability: values?.joiningAvailability?.value || "",
    };
    updateApplication({
      id: applicationId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          navigate(
            `/job-onboard/job-creation/view/${jobId}/job-application/view/${applicationId}/details`
          );
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <UpdateJobApplicationForm
              formikProps={formikProps}
              formHeading="EDIT"
              setIsShowResume={(value: boolean) => setIsShowResume(value)}
              isShowResume={isShowResume}
              handleChangeStatus={(status: JobApplicationChangeStatusValues) =>
                setStatusPopupInfo({
                  isOpen: !statusPopupInfo?.isOpen,
                  status: status,
                })
              }
              applicationStatus={items?.applicationStatus}
            />
          </Form>
        )}
      </Formik>
      {statusPopupInfo?.isOpen && (
        <UpdateJobApplicationStatusWrapper
          onClose={() =>
            setStatusPopupInfo({
              ...statusPopupInfo,
              isOpen: false,
            })
          }
          applicationStatus={statusPopupInfo?.status}
          applicationId={applicationId || ""}
        />
      )}
    </>
  );
};

export default UpdateJobApplicationWrapper;
