import { IconType } from "react-icons";
import {
  TbApps,
  TbArchive,
  TbBellRinging,
  TbBox,
  TbBrandGitlab,
  TbCalendar,
  TbCarouselHorizontal,
  TbCloudUp,
  TbHandStop,
  TbHeartHandshake,
  TbLayoutDashboard,
  TbPictureInPicture,
  TbPointerMinus,
  TbQuestionMark,
  TbReplace,
  TbServer,
  TbStack,
  TbSubtask,
  TbTicket,
  TbTopologyRing,
  TbTruckDelivery,
  TbUser,
  TbUserCog,
  TbUsersGroup,
} from "react-icons/tb";
import { UserModuleNameTypes } from "./models/UserAccess/UserAccess.model";
import { MdOutlineAddTask, MdOutlineRememberMe } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { BiCategoryAlt } from "react-icons/bi";
import { RiContactsBookLine, RiCustomerService2Fill } from "react-icons/ri";
import { FaRegNoteSticky } from "react-icons/fa6";
import { FaLayerGroup } from "react-icons/fa";
import { BsPersonWorkspace } from "react-icons/bs";
import { GoProject } from "react-icons/go";
import { VscFileSubmodule } from "react-icons/vsc";
import { SlCalculator } from "react-icons/sl";


type NavItemTypeWithoutChild = {
  label: string;
  path: string;
  icon: IconType;
  children?: never;
  name?: string;
};

type NavItemTypeWithChild = {
  label: string;
  path?: never;
  icon: IconType;
  name?: string;
  children?: {
    label: string;
    path: string;
    icon: IconType;
  }[];
};

export type NavItemType = NavItemTypeWithChild | NavItemTypeWithoutChild;

export const navigation: NavItemType[] = [
  {
    label: "Dashboard",
    icon: TbLayoutDashboard,
    path: "/",
    name: UserModuleNameTypes.NAV_DASHBOARD,
  },
  {
    label: "Clients",
    icon: TbUser,
    path: "/client",
    name: UserModuleNameTypes.NAV_CLIENTS,
  },
  {
    label: "Client Users",
    icon: TbUsersGroup,
    path: "/client-user",
    name: UserModuleNameTypes.NAV_CLIENT_USERS,
  },
  {
    label: "Team",
    icon: TbHeartHandshake,
    path: "/team",
    name: UserModuleNameTypes.NAV_TEAMS,
  },
  {
    label: "Contact",
    icon: RiContactsBookLine,
    path: "/contact",
    name: UserModuleNameTypes.NAV_CONTACT,
  },
  {
    label: "Projects",
    icon: GoProject,
    path: "/project",
    name: UserModuleNameTypes.NAV_PROJECTS,
  },
  {
    label: "Workspace",
    icon: BsPersonWorkspace,
    path: "/workspace",
    name: UserModuleNameTypes.NAV_WORKSPACE,
  },
  {
    label: "Requirement Gathering",
    icon: BsPersonWorkspace,
    path: "/requirement-gathering",
    name: UserModuleNameTypes.NAV_REQUIREMENT_GATHERING,
  },
  {
    label: "Modules",
    icon: VscFileSubmodule,
    path: "/module",
    name: UserModuleNameTypes.NAV_MODULES,
  },
  {
    label: "Features",
    icon: TbStack,
    path: "/requirement",
    name: UserModuleNameTypes.NAV_FEATURES,
  },
  {
    label: "Change Request",
    icon: TbReplace,
    path: "/change-request",
    name: UserModuleNameTypes.NAV_CHANGE_REQUEST,
  },
  {
    label: "Tickets",
    icon: TbTicket,
    path: "/ticket",
    name: UserModuleNameTypes.NAV_TIKCETS,
  },
  {
    label: "Ticket Group",
    icon: FaLayerGroup,
    path: "/ticket-group",
    name: UserModuleNameTypes.NAV_TICKET_GROUP,
  },
  {
    label: "Service Tickets",
    icon: RiCustomerService2Fill,
    path: "/service-ticket",
    name: UserModuleNameTypes.NAV_SERVICE_TICKET,
  },
  {
    label: "Git Repo",
    icon: TbBrandGitlab,
    path: "/git-repo",
    name: UserModuleNameTypes.NAV_GIT_REPO,
  },
  {
    label: "Server",
    icon: TbServer,
    path: "/server",
    name: UserModuleNameTypes.NAV_SERVER,
  },
  {
    label: "Deployment Request",
    icon: TbCloudUp,
    path: "/deployment-request",
    name: UserModuleNameTypes.NAV_DEPLOYMENT_REQUEST,
  },
  {
    label: "Tasks",
    icon: TbSubtask,
    path: "/task",
    name: UserModuleNameTypes.NAV_TASK,
  },
  {
    label: "Team View",
    icon: TbCarouselHorizontal,
    path: "/team-view",
    name: UserModuleNameTypes.NAV_TEAM_VIEW,
  },
  {
    label: "Delivery Date",
    icon: TbTruckDelivery,
    path: "/delivery-date",
    name: UserModuleNameTypes.NAV_DELIVERY_DATE,
  },
  {
    label: "Leave Request",
    icon: TbBellRinging,
    path: "/leave-request",
    name: UserModuleNameTypes.NAV_LEAVE_REQUEST,
  },
  {
    label: "Doubts",
    icon: TbQuestionMark,
    path: "/doubt",
    name: UserModuleNameTypes.NAV_DOUBTS,
  },
  {
    label: "Meeting",
    icon: TbPictureInPicture,
    path: "/meeting",
    name: UserModuleNameTypes.NAV_MEETING,
  },
  {
    label: "Meeting Notes",
    icon: FaRegNoteSticky,
    path: "/meeting-notes",
    name: UserModuleNameTypes.NAV_MEETING_NOTES,
  },
  // {
  //   label: "Configuration",
  //   icon: TbSettings,
  //   path: "/configuration/location",
  //   name: UserModuleNameTypes.NAV_CONFIGURATION,
  // },
  {
    label: "Resource Request",
    icon: TbApps,
    path: "/resource-request",
    name: UserModuleNameTypes.NAV_RESOURCE_REQUEST,
  },
  // {
  //   label: "Sales Marketing",
  //   icon: TbSpeakerphone,
  //   path: "/sales&marketing/channel-group",
  //   name: UserModuleNameTypes.NAV_SALES_MARKETING,
  // },
  {
    label: "Holiday Calendar",
    icon: TbCalendar,
    path: "/holiday-calendar",
    name: UserModuleNameTypes.NAV_HOLIDAY_CALENDAR,
  },
  {
    label: "Dumps",
    icon: TbArchive,
    path: "/dump",
    name: UserModuleNameTypes.NAV_DUMPS,
  },
  {
    label: "Team Attendance",
    icon: TbHandStop,
    path: "/team-attendance",
    name: UserModuleNameTypes.NAV_TEAM_ATTENDANCE,
  },
  {
    label: "Products",
    icon: TbBox,
    path: "/product",
    name: UserModuleNameTypes.NAV_PRODUCTS,
  },
  {
    label: "Reminder",
    icon: MdOutlineRememberMe,
    path: "/reminder",
    name: UserModuleNameTypes.NAV_REMINDER,
  },
  {
    label: "Goal Category",
    icon: BiCategoryAlt,
    path: "/goal-category",
    name: UserModuleNameTypes.NAV_GOAL_CATEGORY,
  },
  {
    label: "Goals",
    icon: GoGoal,
    path: "/goals",
    name: UserModuleNameTypes.NAV_GOAL,
  },
  {
    label: "Incident",
    icon: TbPointerMinus,
    path: "/incident",
    name: UserModuleNameTypes.NAV_INCIDENT,
  }, {
    label: "Time Analytics",
    icon: MdOutlineAddTask,
    path: "/time-analytics",
    name: UserModuleNameTypes.NAV_TIME_ANALYTICS,
  },
  {
    label: "Estimation-Calculator",
    icon: SlCalculator,
    path: "/estimations",
    name: UserModuleNameTypes.NAV_ESTIMATION_CALCULATOR,
  },
  {
    label: "Department",
    icon: TbTopologyRing,
    path: "/department",
    name: UserModuleNameTypes.NAV_DEPARTMENT,
  },
  {
    label: "Roles",
    icon: TbUserCog,
    path: "/user-role",
    name: UserModuleNameTypes.NAV_ROLES,
  },

  // {
  //   label: "Finance",
  //   icon: TbCash,
  //   path: "/finance/subscription",
  //   name: UserModuleNameTypes.NAV_FINANCE,
  // },
];
