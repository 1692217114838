import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const ATMPageHeading = ({ children }: Props) => {
  return <span className="text-lg font-medium"> {children} </span>;
};

export default ATMPageHeading;
