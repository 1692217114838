import { Chip } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import MOLMeetingStatusChip from "src/components/UI/molecules/MOLMeetingStatusChip/MOLMeetingStatusChip";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { MeetingListResponse } from "src/models/Meeting.model";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/MeetingSlice";
import { RootState } from "src/redux/store";
import {
  useDeleteMeetingMutation,
  useGetMeetingsQuery,
  useJoinMeetingbyIdMutation,
} from "src/services/MeetingServices";
import { getTimeDuration } from "src/utils/constants/ticketUtils";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import MeetingMarkAsDoneFormWrapper from "../View/tabs/NotesTab/MeetingMarkAsDoneForm/MeetingMarkAsDoneFormWrapper";
import HoldStartMeetingTaskWrapper from "./HoldStartMeetingTask/HoldStartMeetingTaskWrapper";
import MeetingList from "./MeetingList";
import HoldJoinMeetingTaskWrapper from "./HoldJoinMeetingTask/HoldJoinMeetingTaskWrapper";

type Props = {};

const MeetingListWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  // Check if the current path starts with "/client"
  const isClientRoute = currentPath.startsWith("/client");
  const { clientId } = useParams();

  const [deleteMeeting] = useDeleteMeetingMutation();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    notCompletedMeeting,
    codioticUser,
    projects,
    meetingWith,
    mode,
    meetingTypeFilter,
    dateFilter,
  } = useSelector((state: RootState) => state.meeting);

  const [isOpenMarkAsDoneModel, setIsOpenMarkAsDoneModel] =
    useState<boolean>(false);
  const [actualStartTime, setActualStartTime] = useState<string>("");
  const [actualEndTime, setActualEndTime] = useState<string>("");
  const [meetingId, setMeetingId] = useState<any>("");
  const [meetingType, setMeetingType] = useState<string>("");
  const { data, isLoading, isFetching } = useGetMeetingsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "title",
      "projectId",
      "changeRequestId",
      "mode",
      "scheduledStartTime",
      "scheduledEndTime",
      "actualStartTime",
      "actualEndTime",
      "clientUsers",
      "codioticUsers",
      "address",
      "meetingLink",
      "requiredThings",
      "discussionPoints",
      "createdById",
      "createdByUserType",
      "isDeleted",
      "isActive",
    ],
    page: page,
    filterBy: [
      ...(isClientRoute
        ? [
            {
              fieldName: "clientId",
              value: clientId,
            },
          ]
        : [
            {
              fieldName: "projectId",
              value: projects?.value,
            },
            {
              fieldName: "meetingWith",
              value: meetingWith?.value,
            },
            {
              fieldName: "mode",
              value: mode?.value,
            },
            {
              fieldName: "meetingCreateType",
              value: meetingTypeFilter,
            },
            {
              fieldName: "status",
              value: notCompletedMeeting || "",
            },
            {
              fieldName: "actualCodioticUsers._id",
              value: codioticUser?.value ? [codioticUser?.value] : "",
            },
          ]),
    ],
    dateFilter: {
      dateFilterKey: "actualStartDate",
      startDate: dateFilter?.start_date || "",
      endDate: dateFilter?.end_date || "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDeleteGitRepo = (row: string) => {
    showConfirmationDialog({
      title: "Heads Up",
      text: "Are you sure want to delete this Meeting ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteMeeting(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const [openHoldTask, setIsOpenHoldTask] = useState<boolean>(false);
  const [isOpenLoggedInUserTask, setIsOpenLoggedInUserTask] =
    useState<boolean>(false);
  const [isOpenJoinMeeting, setIsOpenJoinMeeting] = useState<any>("");

  const [meetingStartId, setMeetingStartId] = useState<any>("");
  const { userData } = useGetLocalStorage();
  const [joinYourMeeting] = useJoinMeetingbyIdMutation();

  const handleStartMeeting = (row: string) => {
    setMeetingStartId(row);
    setIsOpenHoldTask(true);
    //
  };

  const handleJoinMeeting = (meetingApi: string) => {
    setIsOpenJoinMeeting(meetingApi);
    setIsOpenLoggedInUserTask(true);
  };

  const handleLeaveMeeting = (row: string) => {
    showConfirmationDialog({
      title: "Heads Up",
      text: "Are you sure want to leave the meeting?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          joinYourMeeting({
            id: row,
            body: { type: "LEAVE", userId: userData?.userId },
          }).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Scheduled Date Time",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.MEETING_LIST_SCHEDULED_DATE_TIME,
      renderCell: (row: MeetingListResponse) => (
        <div className="py-1">
          <div className="text-sm font-medium text-slate-700">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="flex items-center mt-1">
            <span className="text-xs font-medium text-slate-500">
              {moment(row?.scheduledStartTime).format("hh:mm A")}
            </span>
            {row?.scheduledEndTime && (
              <span className="flex">
                <span className="mx-1 text-xs font-bold text-slate-500">:</span>
                <span className="text-xs font-medium text-slate-500">
                  {moment(row?.scheduledEndTime).format("hh:mm A")}
                </span>
              </span>
            )}
          </div>
        </div>
      ),
    },
    {
      field: "actualStartTime",
      headerName: "Actual End Date Time",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[180px]",
      name: UserModuleNameTypes.MEETING_LIST_ACTUAL_END_DATE_TIME,
      renderCell: (row: MeetingListResponse) =>
        row?.actualEndDate ? (
          <div className="py-1">
            <div className="text-sm font-medium text-slate-700">
              {moment(row?.actualEndDate).format("DD MMM YYYY")}
            </div>
            <div className="flex mt-1">
              <span className="text-xs font-medium text-slate-500">
                {moment(row?.actualStartTime).format("hh:mm A")}
              </span>
              <span className="mx-1 text-xs font-bold text-slate-500">:</span>
              <span className="text-xs font-medium text-slate-500">
                {moment(row?.actualEndTime).format("hh:mm A")}
              </span>
            </div>
          </div>
        ) : (
          " - "
        ),
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: MeetingListResponse) => {
        return (
          <div>{getTimeDuration(row?.actualStartTime, row?.actualEndTime)}</div>
        );
      },
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.MEETING_LIST_PROJECT,
      renderCell: (row: MeetingListResponse) => (
        <div className="capitalize"> {row?.projectName} </div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.MEETING_LIST_TITLE,
      renderCell: (row: MeetingListResponse) => (
        <div className="capitalize"> {row?.title} </div>
      ),
    },
    {
      field: "mode",
      headerName: "Mode",
      flex: "flex-[1.5_1.5_0%]",
      align: "center",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.MEETING_LIST_MODE,
      renderCell: (row: MeetingListResponse) =>
        row?.mode === "CALL" ? (
          "N/A"
        ) : row?.mode === "ONLINE" ? (
          <a
            href={`${row?.meetingLink}`}
            target="_blank"
            rel="noreferrer"
            className="px-3 py-1 text-xs text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
          >
            Meeting Link
          </a>
        ) : (
          <Chip label={`${row?.address}`} className="my-2 me-2" />
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.MEETING_LIST_STATUS,
      renderCell: (row: MeetingListResponse) => (
        <div className="capitalize">
          <MOLMeetingStatusChip status={row?.status} />
        </div>
      ),
    },
    {
      field: "meetingWith",
      headerName: "Start/End",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: MeetingListResponse) => {
        if (
          row?.mode !== "CALL" &&
          row?.actualStartTime === "" &&
          (userData?.userType === "SUPER_ADMIN" ||
            userData?.userId === row?.createdById)
        ) {
          // Case 1: meetingWith is INTERNAL_TEAM and actualStartTime is an empty string
          return (
            <div
              className="capitalize"
              onClick={(e: any) => e.stopPropagation()}
            >
              <button
                className="px-3 py-1 text-xs font-semibold text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
                onClick={() => {
                  handleStartMeeting(row?._id);
                }}
              >
                Start Meeting
              </button>
            </div>
          );
        } else if (
          row?.mode !== "CALL" &&
          row?.actualStartTime !== "" &&
          row?.actualEndTime === "" &&
          (userData?.userType === "SUPER_ADMIN" ||
            userData?.userId === row?.createdById)
        ) {
          // Case 2: meetingWith is INTERNAL_TEAM, actualStartTime is not an empty string, and actualEndTime is an empty string
          // You can add your logic for this case here
          return (
            <div
              className="capitalize"
              onClick={(e: any) => e.stopPropagation()}
            >
              <button
                className="px-3 py-1 text-xs font-semibold text-white transition duration-300 bg-red-500 rounded-md hover:bg-red-600"
                onClick={() => {
                  setIsOpenMarkAsDoneModel(true);
                  setActualStartTime(row?.actualStartTime);
                  setActualEndTime(row?.actualEndTime);
                  setMeetingId(row?._id);
                  setMeetingType(row?.meetingWith);
                }}
              >
                End Meeting
              </button>
            </div>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "join&leave",
      headerName: "Join/Leave",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: MeetingListResponse) => {
        if (
          row?.mode !== "CALL" &&
          row?.status === "ONGOING" &&
          row?.actualCodioticUsers
        ) {
          if (row?.isJoined) {
            return (
              <div
                className="capitalize"
                onClick={(e: any) => e.stopPropagation()}
              >
                <button
                  className="px-3 py-1 text-xs font-semibold text-white transition duration-300 bg-red-500 rounded-md hover:bg-red-600"
                  onClick={() => {
                    handleLeaveMeeting(row?._id);
                  }}
                >
                  Leave Meeting
                </button>
              </div>
            );
          } else {
            return (
              <div
                className="capitalize"
                onClick={(e: any) => e.stopPropagation()}
              >
                <button
                  className="px-3 py-1 text-xs font-semibold text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
                  onClick={() => {
                    handleJoinMeeting(row?._id);
                  }}
                >
                  Join Meeting
                </button>
              </div>
            );
          }
        } else {
          return "-";
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: MeetingListResponse) =>
        row?.status !== "FREEZE" && (
          <div>
            <ATMActionMenu
              options={[
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdEdit color="#000000" /> Edit
                    </div>
                  ),
                  onClick: () => {
                    if (row?.mode === "CALL") {
                      navigate(`/meeting/conversation/${row?._id}/edit`);
                    } else {
                      navigate(`/meeting/${row?._id}/edit`);
                    }
                  },
                  acitonName: UserModuleNameTypes.ACTION_MEETING_EDIT,
                },
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdDelete color="#000000" /> Delete
                    </div>
                  ),
                  onClick: () => {
                    handleDeleteGitRepo(row?._id);
                  },
                  acitonName: UserModuleNameTypes.ACTION_MEETING_DELETE,
                },
              ]}
            />
          </div>
        ),
    },
  ];

  return (
    <>
      <div>
        <MeetingList
          columns={columns}
          isMeetingLoading={isLoading || isFetching}
          rows={items}
          isClientRoute={isClientRoute}
          paginationProps={{
            isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
        />
      </div>

      {isOpenMarkAsDoneModel ? (
        <MeetingMarkAsDoneFormWrapper
          meetingId={meetingId}
          meetingType={meetingType}
          actualStartTime={actualStartTime}
          actualEndTime={actualEndTime}
          onClose={() => {
            setIsOpenMarkAsDoneModel(false);
            setMeetingId("");
          }}
        />
      ) : null}

      {openHoldTask && (
        <HoldStartMeetingTaskWrapper
          onClose={() => {
            setIsOpenHoldTask(false);
            setMeetingStartId("");
          }}
          meetingStartId={meetingStartId}
        />
      )}
      {isOpenLoggedInUserTask && (
        <HoldJoinMeetingTaskWrapper
          onClose={() => {
            setIsOpenLoggedInUserTask(false);
            setIsOpenJoinMeeting("");
          }}
          JoinMeeting={isOpenJoinMeeting}
        />
      )}
    </>
  );
};

export default MeetingListWrapper;
