import apiSlice from "./ApiSlice";

const deliveryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get all delivery dates
    getAllDeliveryDate: builder.query({
      providesTags: ["delivery-date"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/delivery-date",
          method: "POST",
          body,
        };
      },
    }),

    // delete delivery date
    deleteDeliveryDate: builder.mutation({
      invalidatesTags: ["delivery-date"],
      query: (id: any) => ({
        url: `/delivery-date/${id}`,
        method: "DELETE",
      }),
    }),
    // add delivery date
    addDeliveryDate: builder.mutation({
      invalidatesTags: ["delivery-date"],
      query: (body) => ({
        url: `/delivery-date/add`,
        method: "POST",
        body,
      }),
    }),
    //get delivery date by Id
    getDeliveryDateById: builder.query({
      providesTags: ["delivery-date"],
      query: (id: string) => {
        return {
          url: `/delivery-date/${id}`,
          method: "GET",
        };
      },
    }),

    // update delivery date
    updateDeliveryDate: builder.mutation({
      invalidatesTags: ["delivery-date"],
      query: ({ body, id }) => ({
        url: `/delivery-date/${id}`,
        method: "PUT",
        body,
      }),
    }),

    // change status of delivery date
    changeStatusDeliveryDate: builder.mutation({
      invalidatesTags: ["delivery-date"],
      query: ({ body, id }) => ({
        url: `/delivery-date/mark-as-done/${id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllDeliveryDateQuery,
  useDeleteDeliveryDateMutation,
  useAddDeliveryDateMutation,
  useGetDeliveryDateByIdQuery,
  useUpdateDeliveryDateMutation,
  useChangeStatusDeliveryDateMutation,
} = deliveryApi;
