import apiSlice from "./ApiSlice";

export const departmentApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Get
        getAllDepartmentWithPagination: builder.query({
            providesTags: ["department"],
            query: (body: PaginationPayloadType) => {
                return {
                    url: "/department",
                    method: "POST",
                    body,
                };
            },
        }),

        // Single Product Delete 
        deleteDepartment: builder.mutation({
            invalidatesTags: ["department"],
            query: (id: any) => ({
                url: `/department/${id}`,
                method: "DELETE",
            }),
        }),
        // Get
        getDepartment: builder.query({
            providesTags: ["department"],
            query: () => {
                return {
                    url: "/department/",
                    method: "GET",
                };
            },
        }),

        // Add
        addDepartment: builder.mutation({
            invalidatesTags: ["department"],
            query: (body) => {
                return {
                    url: "/department/add",
                    method: "POST",
                    body,
                };
            },
        }),


        // Get the Git Repo  by id
        getDepartmentById: builder.query({
            providesTags: ["department"],
            query: (id: string) => {
                return {
                    url: `/department/${id}`,
                    method: "GET",
                };
            },
        }),

        // Update Git Repo
        updateDepartmentById: builder.mutation({
            invalidatesTags: ["department"],
            query: ({ id, body }) => {
                return {
                    url: `/department/${id}`,
                    method: "PUT",
                    body,
                };
            },
        }),
        //
    }),
});

export const {
    useGetAllDepartmentWithPaginationQuery,
    useAddDepartmentMutation,
    useGetDepartmentQuery,
    useGetDepartmentByIdQuery,
    useUpdateDepartmentByIdMutation,
    useDeleteDepartmentMutation,
} = departmentApi;
