import React, { useEffect, useState } from "react";
import TicketIssuesListing from "./TicketIssuesListing";
import AddTicketIssuesFormWrapper from "../Add/AddTicketIssuesFormWrapper";
import { useParams } from "react-router-dom";
import { useGetSingleTicketsViaIdQuery } from "src/services/TicketServices";
import { useGetMeetingsQuery } from "src/services/MeetingServices";
import { useGetAllTicketIssueQuery } from "src/services/TicketIssuesService";
import { CircularProgress } from "@mui/material";

const TicketIssuesListingWrapper = () => {
  //Usestate
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [ticketDetail, setTicketDetail] = useState<any>({});
  const [issueData, setIssueData] = useState<any>([]);
  // Get the Ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  // Get Single Ticket Details
  const {
    isLoading: isTicketDetailsLoading,
    isFetching: isTicketDetailsFetching,
    data: ticketData,
  } = useGetSingleTicketsViaIdQuery(TICKET_ID || "", {
    skip: !TICKET_ID,
  });

  useEffect(() => {
    if (!isTicketDetailsFetching && !isTicketDetailsLoading) {
      setTicketDetail(ticketData?.data?.[0]);
    }
  }, [isTicketDetailsLoading, isTicketDetailsFetching, ticketData]);
  //Get Meeting Details
  const { data: meetingData } = useGetMeetingsQuery(
    {
      limit: 10,
      searchValue: "",
      params: [
        "title",
        "projectId",
        "changeRequestId",
        "mode",
        "scheduledStartTime",
        "scheduledEndTime",
        "actualStartTime",
        "actualEndTime",
        "clientUsers",
        "codioticUsers",
        "address",
        "meetingLink",
        "requiredThings",
        "discussionPoints",
        "createdById",
        "createdByUserType",
        "isDeleted",
        "isActive",
      ],
      page: 1,
      filterBy: [
        {
          fieldName: "projectId",
          value: [ticketDetail?.projectId],
        },
        {
          fieldName: "status",
          value: ["COMPLETED", "FREEZE"],
        },
      ],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: false,
    },
    { skip: !ticketDetail?.projectId }
  );

  //Get All Ticket Issues
  const {
    data: ticketIssuesData,
    isLoading: ticketIssuesDataIsLoading,
    isFetching: ticketIssuesDataIsFetching,
  } = useGetAllTicketIssueQuery({
    limit: 10,
    searchValue: "",
    params: ["originId", "sourceId"],
    page: 1,
    filterBy: [
      {
        fieldName: "originId",
        value: [TICKET_ID],
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (!ticketIssuesDataIsLoading && !ticketIssuesDataIsFetching) {
      setIssueData(ticketIssuesData?.data);
    }
  }, [ticketIssuesDataIsLoading, ticketIssuesDataIsFetching, ticketIssuesData]);

  const meetingOptions = meetingData?.data?.map((el: any) => {
    return {
      label: el?.title,
      value: el?._id,
    };
  });
  return (
    <>
      {(ticketIssuesDataIsLoading || ticketIssuesDataIsFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <TicketIssuesListing
        projectId={ticketDetail?.projectId}
        issueData={issueData}
        onAddNew={() => setIsOpenAddForm(true)}
        meetingOptions={meetingOptions}
      />
      {isOpenAddForm ? (
        <AddTicketIssuesFormWrapper
          meetingOptions={meetingOptions}
          onClose={() => setIsOpenAddForm(false)}
        />
      ) : null}
    </>
  );
};

export default TicketIssuesListingWrapper;
