import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { HiDotsHorizontal, HiDotsVertical } from "react-icons/hi";
import { isAuthorized } from "src/utils/authorization";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

const ITEM_HEIGHT = 48;

export type OptionType = {
  label: React.ReactNode;
  onClick: () => void;
  acitonName?: keyof typeof UserModuleNameTypes | undefined | any;
  isHideOptions?:boolean
};

type Props = {
  options: OptionType[];
  orientation?: "vertical" | "horizontal";
  size?: number;
};

const ATMActionMenu = ({
  options,
  orientation = "vertical",
  size = 18,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const isHidden = Boolean(
    options?.filter((option: OptionType) => {
      return isAuthorized(option?.acitonName as keyof typeof UserModuleNameTypes);
    }).length
  );
  return (
    <div hidden={!isHidden}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {orientation === "vertical" ? (
          <HiDotsVertical size={size} />
        ) : (
          <HiDotsHorizontal size={size} />
        )}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",

          className: "min-w-[200px]",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options
          ?.filter((option: OptionType) => {
            return isAuthorized(option?.acitonName as keyof typeof UserModuleNameTypes);
          })
          .map((option, optionIndex) => (
           <div  key={optionIndex}>
              { option?.isHideOptions ? null :(option.label &&
                <MenuItem
                 
                  onClick={(e) => {
                    option.onClick();
                    handleClose(e);
                  }}
                >
                  {option.label}
                </MenuItem>)}
                </div>
          ))}
      </Menu>
    </div>
  );
};

export default ATMActionMenu;
