/* eslint-disable @typescript-eslint/no-unused-vars */
// |-- Built-in Dependencies --|
import React, { useEffect, useState } from "react";

// |-- External Dependencies --|
import { BiSearchAlt2 } from "react-icons/bi";
import {
  Outlet,
  //, useLocation, useNavigate
} from "react-router-dom";
import { IconType } from "react-icons";
import { useDispatch } from "react-redux";

// |-- Internal Dependencies --|
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
// |-- Redux --|
import { AppDispatch } from "src/redux/store";
import { TaskListResponse } from "src/models/Task.model";
import { useParams } from "react-router-dom";
import { IoFilter } from "react-icons/io5";
// import AllTaskFilterFormCardDialogWrapper from "../../List/AllTaskFilterFormCard/AllTaskFilterFormCardDialogWrapper";
import AllTaskFilterFormCardDialogWrapper from "src/screens/AllTask/List/AllTaskFilterFormCard/AllTaskFilterFormCardDialogWrapper";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import TaskFilterFormCardDialogWrapper from "../../List/AllTaskFilterFormCard/TaskFilterFormCardDialogWrapper";
import { Tooltip } from "@mui/material";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

// import TabScrollable from 'src/components/utilsComponent/TabScrollable'
// import { setIsCollapsed } from 'src/redux/slices/SideNavLayout'

// |-- Types --|
type ViewLayoutPropTypes = {
  leftbar?: boolean;
  infoCard?: React.ReactNode;
  actionIcons?: {
    icon: IconType;
    onClick: () => void;
  }[];
  listData?: any[];
  tabs?: {
    label: string;
    icon: IconType;
    path: string;
  }[];
  searchValue?: string;
  onSearch?: (value: string) => void;
  onClickViewMore?: () => void;
  onClickTicket?: (taskId: string) => void;
  children?: any;
  hideViewMore?: boolean;
};

const TicketTaskSideBarLayout = ({
  leftbar = true,
  infoCard,
  listData,
  tabs,
  searchValue = "",
  onSearch = () => {},
  onClickTicket = () => {},
  onClickViewMore,
  children,
  hideViewMore = false,
}: ViewLayoutPropTypes) => {
  // const navigate = useNavigate()
  const { id: TASK_ID } = useParams();
  const [isOpenTaskAllFilterDialog, setIsOpenTaskAllFilterDialog] =
    useState<boolean>(false);

  // const location = useLocation()

  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = useState(0);

  //   useEffect(() => {
  //     if (leftbar === true) {
  //       dispatch(setIsCollapsed(true));
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  useEffect(() => {
    const activeTabIndex = window.location.pathname.split("/")[3];
    // const tabindex = tabs?.findIndex((tab) => tab.path === activeTabIndex);
    // setActiveTab(tabindex);
  }, [tabs]);
  return (
    <>
      <div className="h-full">
        <div className="w-full flex h-[calc(100%)] bg-white">
          {/* Left Section Side Bar */}
          {leftbar === true && (
            <div className="w-[250px] h-full flex flex-col border-r">
              {/* Info Card */}
              <div className=" w-full">{infoCard}</div>

              {/* Search Bar */}

              <div className="px-2 py-2 flex items-center justify-center ">
                <ATMInputAdormant
                  name=""
                  value={searchValue}
                  placeholder="Search here..."
                  className="h-[40px] shadow-sm border-[1px] focus:border-black"
                  adormant={
                    <BiSearchAlt2 className="text-slate-400 text-xl " />
                  }
                  adormantProps={{
                    position: "start",
                    extraClasses: "bg-white border-0 ",
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                  }}
                />
                {/* Filter Button */}
                <ATMLoadingButton
                    variant="tonal"
                    onClick={() => setIsOpenTaskAllFilterDialog(true)}
                  >
                    <div className="flex items-center gap-2">
                      <IoFilter />
                      Filters
                    </div>
                  </ATMLoadingButton>
                {/* Filter Button Model */}
                <TaskFilterFormCardDialogWrapper
                  open={isOpenTaskAllFilterDialog}
                  onClose={() => setIsOpenTaskAllFilterDialog(false)}
                />
              </div>

              {/* List */}
              <div className="grow overflow-auto">
                <div className="p-2 flex flex-col min-w-[100%] overflow-y-auto gap-2">
                  {listData?.map((task: TaskListResponse) => {
                    return (
                      <div
                        key={task?._id}
                        className={`rounded-md border p-2 cursor-pointer hover:bg-teal-100 flex flex-col gap-2 ${
                          task?._id === TASK_ID
                            ? "bg-teal-100 border border-teal-100 shadow-sm"
                            : "bg-white"
                        }`}
                        onClick={() => onClickTicket(task?._id)}
                      >
                        <div className="" style={{ userSelect: "text" }}>
                          <div className="flex text-xs justify-between font-semibold text-teal-800 ">
                            <span className="task-id">#{task?.taskNumber}</span>
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <Tooltip title={task?.title}>
                            <h3 className="w-full overflow-hidden line-clamp-2 text-sm min-w-[100%] font-medium">
                              {task?.title}
                            </h3>
                          </Tooltip>
                          <p className="text-sm font-medium text-neutral line-clamp-1 flex justify-end">
                            {task?.layer[0]?.layerName || "-"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                  {/* View more button */}
                  {hideViewMore && (
                    <span
                      onClick={onClickViewMore}
                      className="text-black text-center text-sm cursor-pointer hover:underline"
                    >
                      View More
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Right Section */}
          <div className={`w-full grow flex flex-col h-full`}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default TicketTaskSideBarLayout;
