import React from 'react';
import ATMPageHeader from 'src/components/UI/atoms/ATMPageHeader/ATMPageHeader';
import ATMPagination from 'src/components/UI/atoms/ATMPagination/ATMPagination';
import ATMTable, {
  columnTypes,
} from 'src/components/UI/atoms/ATMTable/ATMTable';
import { UserModuleNameTypes } from 'src/models/UserAccess/UserAccess.model';
import { isAuthorized } from 'src/utils/authorization';

type Props = {
  columns: columnTypes[];
  onAddNew:()=>void ;
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const RequirementGatheringUsersListing = ({
  columns,
  rows,
  onAddNew ,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  return (
      <div className='flex flex-col h-full'>
        {/* Page Header */}
        <div>
          <ATMPageHeader
            pageTitle='Users'
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            buttonProps={{
              btnName: 'Add New',
              onClick: onAddNew,
            }}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_ADD)
            }
          />
        </div>

        {/* Table */}
        <div className='flex-1 overflow-auto flex flex-col'>
            <ATMTable
              columns={columns} 
              rows={rows}
              rowExtraClasses={() => 'min-h-[40px]'}
              isLoading={isTableLoading}
            />
        </div>

        <div className='flex justify-end py-2'>
            <ATMPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowCount={totalItems}
              rows={rows}
            />
          </div>
      </div>
  );
};

export default RequirementGatheringUsersListing;