import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useAddServerMutation } from "src/services/ServerService";
import { array, object, string } from "yup";
import ServerForm from "../Layouts/ServerForm";
import { showToast } from "src/utils/showToaster";
import { ServerFormValues } from "src/models/Server.model";

type Props = {
  onClose: () => void;
};

const LabelType = [
  {
    label: "UAT",
    value: "UAT",
  },
  {
    label: "CLIENT",
    value: "CLIENT",
  },
];

const AddServerFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addServer] = useAddServerMutation();

  // Form Initial Values
  const initialValues: ServerFormValues = {
    projectId: [],
    serverName: "",
    serverConfig: "",
    label: "",
    serverCredentials: [
      {
        type: "",
        userName: "",
        password: "",
        remark: "",
      },
    ],
    service: [
      {
        type: "",
        port: "0",
        remark: "",
      },
    ],
  };

  // Validation Schema
  const validationSchema = object().shape({
    serverName: string().required("Please enter server  name."),
    serverConfig: string().required("Please enter server  config."),
    label: object().required("Server Label is required"),
    serverCredentials: array().of(
      object().shape({
        type: object().required("Server Credential Type is required"),
        userName: string().required("Please enter the username"),
        password: string().required("Please enter the password"),
        remark: string().required("Please enter remark"),
      })
    ),
    service: array().of(
      object().shape({
        remark: string().required("Please enter the remark"),
        type: object().required(" Service type is required"),
      })
    ),
    projectId: array()
      .test("nonEmpty", "Please enter at least one project", function (value) {
        return value && value.length > 0;
      })
      .of(
        object().shape({
          _id: string().required("required"),
          projectName: string().required("Please enter field type"),
        })
      ),
  });

  // Handle Submit
  const handleSubmit = (
    values: ServerFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ServerFormValues>
  ) => {
    const { projectId, serverCredentials, label, service, ...rest } = values;
    const formattedValues = {
      ...rest,
      label: label?.value,
      projectId: (projectId || [])?.map((project: any) => project?._id),
      serverCredentials: (serverCredentials || [])?.map(
        (serverCredential: any) => ({
          password: serverCredential?.password,
          userName: serverCredential?.userName,
          remark: serverCredential?.remark,
          type: serverCredential?.type?.value,
        })
      ),
      service: (service || [])?.map((service: any) => ({
        port: service?.port || 0,
        remark: service?.remark,
        type: service?.type?.value,
      })),
    };
    addServer(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ServerForm
            formikProps={formikProps}
            formType="ADD"
            LabelType={LabelType}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddServerFormWrapper;
