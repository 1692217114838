import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/ExpensesSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useDeleteExpensesMutation,
  useGetExpensesQuery,
} from "src/services/ExpensesService";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import EditSubscriptionWrapper from "../Edit/EditExpensesWrapper";
import AccountsListing from "./ExpensesListing";

type Props = {};

const ExpensesListingWrapper = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [selectedtProductId, setSelectedProductId] = useState("");

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    dateFilter,
    GSTApplicable,
    expenseCategoryId,
  } = useSelector((state: RootState) => state.expenses);
  const [deleteExpense] = useDeleteExpensesMutation();
  const { data, isLoading, isFetching } = useGetExpensesQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["remark"],
    page: page,
    filterBy:
      GSTApplicable?.length !== 0
        ? [
            {
              fieldName: "GSTApplicable",
              value: GSTApplicable,
            },
            {
              fieldName: "expenseCategoryId",
              value: expenseCategoryId?.value || [],
            },
          ]
        : [
            {
              fieldName: "expenseCategoryId",
              value: expenseCategoryId?.value || [],
            },
          ],
    dateFilter: dateFilter,
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleDelete = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Expense?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteExpense(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "expenseDate",
      headerName: "expense date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.EXPENSES_LIST_EXPENSE_DATE,
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {row.expenseDate
              ? formatedDateTimeIntoIst(row.expenseDate, "DD MMM yyyy")
              : "-"}
          </div>
          {/* <div className="text-[13px] font-medium text-slate-400">
            {formatedDateTimeIntoIst(row.createdAt, "hh:mm A")}
          </div> */}
        </div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.EXPENSES_LIST_AMOUNT,
    },

    {
      field: "expencecategoryLabel",
      headerName: "Category Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.EXPENSES_LIST_CATEGORY_NAME,
    },
    {
      field: "accountLabel",
      headerName: "Accounts Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.EXPENSES_LIST_ACCOUNTS_NAME,
    },
    {
      field: "GSTApplicable",
      headerName: "GST Applicable",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.EXPENSES_LIST_GST_APPLICABLE,
      renderCell: (row: any) => <div>{row?.GSTApplicable ? "Yes" : "No"}</div>,
    },
    {
      field: "remark",
      headerName: "Remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[250px] max-w-[250px]",
      name: UserModuleNameTypes.EXPENSES_LIST_REMARK,
      renderCell: (row: any) => (
        <Tooltip title={row?.remark}>
          <div className="w-full overflow-hidden truncate text-md min-w-[100%]">
            {row?.remark || "-"}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "accountName",
      headerName: "View Bill",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[250px]",
      name: UserModuleNameTypes.EXPENSES_LIST_VIEW_BILL,
      renderCell: (row: any) => (
        <div className="text-[12px]">
          {row?.billPhoto?.length
            ? row?.billPhoto?.map((el: any, index: any) => {
                return (
                  <Tooltip title={el?.url}>
                    <Link
                      to={el?.url}
                      target="_blank"
                      className="flex flex-col gap-2"
                    >
                      {index + 1}. {el?.url}
                    </Link>
                  </Tooltip>
                );
              })
            : "-"}
        </div>
      ),
    },
    {
      field: "addedByLabel",
      headerName: "Added By",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.EXPENSES_LIST_ADDED_BY,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true);
                  setSelectedProductId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_EXPENSES_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDelete(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_EXPENSES_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <AccountsListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
        // onAddNew={() => setIsOpenAddDialog(true)}
      />

      {isOpenEditDialog && (
        <EditSubscriptionWrapper
          selectedSubscription={selectedtProductId}
          onClose={() => setIsOpenEditDialog(false)}
        />
      )}
    </>
  );
};

export default ExpensesListingWrapper;
