import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IMAGE_BASE_URL } from "src/utils/constants";

export const filePickerSlice = createApi({
  reducerPath: "filePickerSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: `${IMAGE_BASE_URL}`,
  }),
  // tagTypes: tagTypes,
  endpoints: (builder) => ({
    // ADD
    addFileUrl: builder.mutation({
      query: (body) => ({
        url: "/upload",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useAddFileUrlMutation } = filePickerSlice;
