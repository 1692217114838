import apiSlice from "./ApiSlice";

export const jobCreationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    // GET ALL JOB CREATION 
    getAllJobCreationListing: builder.query({
      providesTags: ["job-creation"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/job",
          method: "POST",
          body,
        };
      },
    }),

    //ADD JOB CREATION 
    addJobCreation: builder.mutation({
      invalidatesTags: ["job-creation"],
      query: (body) => {
        return {
          url: "/job/add",
          method: "POST",
          body,
        };
      },
    }),

    //GET JOB CREATION  BY ID
    getJobCreationById: builder.query({
      providesTags: ["job-creation"],
      query: (id) => {
        return {
          url: `/job/${id}`,
          method: "GET",
        };
      },
    }),

    //JOB CREATION UPDATE
    JobCreationUpdate: builder.mutation({
      invalidatesTags: ["job-creation"],
      query: ({ body, id }) => {
        return {
          url: `/job/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    //JOB CREATION DELETE

    deleteJobCreation: builder.mutation({
      invalidatesTags: ["job-creation"],
      query: (id) => {
        return {
          url: `/job/${id}`,
          method: "DELETE",
        };
      },
    }),


  }),
});

export const {
  useGetAllJobCreationListingQuery,
  useAddJobCreationMutation,
  useGetJobCreationByIdQuery,
  useJobCreationUpdateMutation,
  useDeleteJobCreationMutation,
} = jobCreationApi;
