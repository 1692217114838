import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsOpenEditFormDialog,
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSelectedClient,
  setTotalItems,
} from "src/redux/slices/DumpSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { MdEdit } from "react-icons/md";
import DumpList from "./DomainList";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import EditDumpFormWrapper from "../Edit/EditDumpFormWrapper";
import { useGetDumpPaginationQuery } from "src/services/DumpService";
import AddDumpFormWrapper from "../Add/AddDumpFormWrapper";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const DumpListingWrapper = (props: Props) => {
  const [selectedDumpId, setSelectedDumpId] = useState<string>("");
  const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    isOpenEditFormDialog,
    projectFilterValue,
    // selectedClient,
  } = useSelector((state: RootState) => state.dump);

  const { data, isLoading, isFetching } = useGetDumpPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "projectId",
      "title",
      "layerRemark",
      "createdById",
      "createdByUserName",
      "isDeleted",
      "isActive",
    ],
    page: page,
    filterBy: [
      {
        fieldName: "projectId",
        value: projectFilterValue?.value,
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "title",
      headerName: "Title Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "z-0 min-w-[200px]",
      name: UserModuleNameTypes.DUMPS_LIST_TITLE_NAME,
      renderCell: (row) => <ATMMarkdownContainer markdown={row?.title} />,
    },
    {
      field: "projectName",
      headerName: "Project Name",
      flex: "flex-[0.5_0.5_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.DUMPS_LIST_PROJECT_NAME,
    },
    {
      field: "layerRemark",
      headerName: "Layer Remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      name: UserModuleNameTypes.DUMPS_LIST_LAYER_REMARK,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  dispatch(setIsOpenEditFormDialog(true));
                  dispatch(setSelectedClient(row));
                  setSelectedDumpId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_DUMPS_EDIT,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <SideNavLayout>
        <DumpList
          columns={columns}
          rows={items}
          addNew={() => setIsOpenAddFormDialog(true)}
          paginationProps={{
            isTableLoading: isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
        />
      </SideNavLayout>

      {isOpenAddFormDialog ? (
        <AddDumpFormWrapper onClose={() => setIsOpenAddFormDialog(false)} />
      ) : null}
      {isOpenEditFormDialog ? (
        <EditDumpFormWrapper
          onClose={() => dispatch(setIsOpenEditFormDialog(false))}
          selecetedDumpId={selectedDumpId}
        />
      ) : null}
    </div>
  );
};

export default DumpListingWrapper;
