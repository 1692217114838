import { IconType } from "react-icons";
import { BiSelectMultiple } from "react-icons/bi";
import { FaSearchPlus } from "react-icons/fa";
import { GoFileMedia } from "react-icons/go";
import { MdOutlineCampaign } from "react-icons/md";

import { MdOutlineDomainAdd } from "react-icons/md";
import { SiMediafire } from "react-icons/si";
import { SlSocialDribbble } from "react-icons/sl";
import { TiSocialDribbble } from "react-icons/ti";
import { MdOutlineAddAlert } from "react-icons/md";
import { UserModuleNameTypes } from "./models/UserAccess/UserAccess.model";

type NavItemTypeWithoutChild = {
  label: string;
  path: string;
  icon: IconType;
  children?: never;
  name:string
};

type NavItemTypeWithChild = {
  label: string;
  path?: never;
  icon: IconType;
  name:string
  children?: {
    label: string;
    path: string;
    icon: IconType;
    name:string
  }[];
};

export type NavItemType = NavItemTypeWithChild | NavItemTypeWithoutChild;

export const SalesAndMarketingNavigation: NavItemType[] = [
    {
        label: "Channel Group",
        icon: MdOutlineCampaign,
        path: "channel-group",
        name:UserModuleNameTypes.NAV_CHANNEL_GROUP
    },
    {
        label: "Channel",
        icon: SiMediafire,
        path: "channel",
        name:UserModuleNameTypes.NAV_CHANNEL
    },

    {
        label: "Campaign Group",
        icon: TiSocialDribbble,
        path: "campaign-group",
        name:UserModuleNameTypes.NAV_CAMPAIGN_GROUP
    },
    {
        label: "Campaign",
        icon: GoFileMedia,
        path: "campaign",
        name:UserModuleNameTypes.NAV_CAMPAIGN
    } ,
    {
        label: "Leads",
        icon: FaSearchPlus,
        path: "leads",
        name:UserModuleNameTypes.NAV_LEADS
    }, 
    {
        label: "Social Media",
        icon: SlSocialDribbble,
        path: "social-media",
        name:UserModuleNameTypes.NAV_SOCIAL_MEDIA
      },
      {
        label: "Domain",
        icon: MdOutlineDomainAdd,
        path: "domain",
        name:UserModuleNameTypes.NAV_DOMAIN
      },
      {
        label: "Lead Label",
        icon: BiSelectMultiple,
        path: "lead-label",
        name:UserModuleNameTypes.NAV_LEAD_LABEL
      },
      {
        label: "Follow-up",
        icon: MdOutlineAddAlert,
        path: "follow-up",
        name:UserModuleNameTypes.NAV_FOLLOW_UP
      },
];
