import apiSlice from "./ApiSlice";
import { AddTicketFormValues } from "src/models/Ticket.model";

export const ticketApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // ADD Ticket
    addTicket: builder.mutation({
      invalidatesTags: ["tickets", "meeting"],
      query: (body: AddTicketFormValues) => {
        return {
          url: "ticket/add",
          method: "POST",
          body,
        };
      },
    }),

    // Get All Ticket
    getAllTicket: builder.query({
      providesTags: ["tickets"],
      query: (body: PaginationPayloadType) => {
        return {
          url: "/ticket",
          method: "POST",
          body,
        };
      },
    }),

    // Get All Ticket
    getAllTicketWithoutPagination: builder.query({
      providesTags: ["tickets"],
      query: () => {
        return {
          url: "/ticket",
          method: "GET",
        };
      },
    }),

        // Get All Ticket
        GetAllTags: builder.query({
          providesTags: ["tickets"],
          query: () => {
            return {
              url: "/tag",
              method: "GET",
            };
          },
        }),

    // Get All Ticket
    getAllTicketWithDifferentIds: builder.query({
      providesTags: ["tickets"],
      query: (body) => {
        return {
          url: "/ticket/project-module-feature-tickets",
          method: "POST",
          body,
        };
      },
    }),

    // Get single ticket via id
    getSingleTicketsViaId: builder.query({
      providesTags: ["task", "tickets", "resource-request"],
      query: (ticketId: string) => {
        return {
          url: `ticket/${ticketId}`,
          method: "GET",
        };
      },
    }),

    // Edit Ticket
    updateTicket: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: ({ ticketId, body }) => {
        return {
          url: `ticket/${ticketId}`,
          method: "PUT",
          body,
        };
      },
    }),

    // Change  Ticket status
    changeClientSideTicketStatus: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: ({ ticketId, status }) => {
        return {
          url: `ticket/client-side-status/${ticketId}?status=${status}`,
          method: "PUT",
        };
      },
    }),

    // Edit Ticket
    closeTicket: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: ({ ticketId, body }) => {
        return {
          url: `ticket/${ticketId}/mark-as-close`,
          method: "PUT",
          body,
        };
      },
    }),

    // Update ticket Details
    updateTicketDetails: builder.mutation({
      invalidatesTags: ["task", "tickets"],
      query: ({
        ticketId,
        body,
      }: {
        ticketId: string;
        body:
        | {
          projectId: string;
          moduleId: string;
          featureId: string;
          type: string;
          problemStatement: string;
        }
        | any;
      }) => {
        return {
          url: `ticket/${ticketId}/ticket-details`,
          method: "PUT",
          body: body,
        };
      },
    }),

    // Assign to team member
    updateAssignTicketIntoMembers: builder.mutation({
      invalidatesTags: ["tickets"],
      query: ({
        ticketId,
        body,
      }: {
        ticketId: string;
        body: {
          assignedTo: string[];
        };
      }) => {
        return {
          url: `ticket/${ticketId}/assigned-to`,
          method: "PUT",
          body,
        };
      },
    }),

    // Create ETA for ticket
    createETATicket: builder.mutation({
      invalidatesTags: ["tickets", "resource-request"],
      query: ({ ticketId, body }: { ticketId: string; body: any }) => {
        return {
          url: `ticket/${ticketId}/create-eta`,
          method: "PUT",
          body,
        };
      },
    }),
    // Create ETA for ticket
    deleteResourceRequiredById: builder.mutation({
      invalidatesTags: ["tickets", "resource-request"],
      query: ({ resourceRequiredId, body }: { resourceRequiredId: string; body: any }) => {
        return {
          url: `ticket/${resourceRequiredId}/resource`,
          method: "PUT",
          body,
        };
      },
    }),



    //Resource reuired

    addRequiredRessource: builder.mutation({
      invalidatesTags: ["tickets"],
      query: ({ ticketId, body }: { ticketId: string; body: any }) => {
        return {
          url: `ticket/${ticketId}/resource-required`,
          method: "PUT",
          body,
        };
      },
    }),
    // Edit ETA for ticket
    updateETATicket: builder.mutation({
      invalidatesTags: ["tickets"],
      query: ({ ticketId, body }: { ticketId: string; body: any }) => {
        return {
          url: `ticket/${ticketId}/est-time`,
          method: "PUT",
          body,
        };
      },
    }),

    updateTagTicket: builder.mutation({
      invalidatesTags: ["tickets"],
      query: ({ ticketId, body }: { ticketId: string; body: any }) => {
        return {
          url: `ticket/${ticketId}/tags`,
          method: "PUT",
          body,
        };
      },
    }),

    // Mark as Done
    markAsDone: builder.mutation({
      invalidatesTags: ["tickets"],
      query: ({
        ticketId,
        body,
      }: {
        ticketId: string;
        body: {
          remark: string;
          numberOfDeveloper: string;
          timeTaken: string;
          changeLog: string;
          responsibleMembers?: string[];
          issueFrom?: string;
          reasonOfBug?: string;
        };
      }) => {
        return {
          url: `ticket/${ticketId}/mark-as-done`,
          method: "PUT",
          body,
        };
      },
    }),

    // Update Ticket Summery
    updateSummery: builder.mutation({
      invalidatesTags: ["tickets"],
      query: ({
        ticketId,
        body,
      }: {
        ticketId: string;
        body: {
          problemStatement?: string;
          problemSolution?: string;
          flow?: string;
          technicalApproach?: string;
          affectedAreas?: any[];
          changeRequestId?: string;
        };
      }) => {
        return {
          url: `ticket/${ticketId}/summary`,
          method: "PUT",
          body,
        };
      },
    }),

    // Get single ticket time analysis via id
    getSingleTicketTimeAnalysisViaId: builder.query({
      providesTags: ["task", "tickets"],
      query: (ticketId: string) => {
        return {
          url: `/ticket/task-time-logs/${ticketId}`,
          method: "GET",
        };
      },
    }),

    // Move ticket to Archive
    moveTicketToArchive: builder.mutation({
      invalidatesTags: ["tickets"],
      query: (ticketId: string) => {
        return {
          url: `/ticket/move-to-archived/${ticketId}`,
          method: "PUT",
        };
      },
    }),
  }),
});

export const {
  useAddTicketMutation,
  useGetAllTicketQuery,
  useGetSingleTicketsViaIdQuery,
  useUpdateTicketMutation,
  useCloseTicketMutation,
  useUpdateTicketDetailsMutation,
  useUpdateAssignTicketIntoMembersMutation,
  useCreateETATicketMutation,
  useDeleteResourceRequiredByIdMutation,
  useAddRequiredRessourceMutation,
  useGetAllTagsQuery,
  useUpdateETATicketMutation,
  useMarkAsDoneMutation,
  useUpdateTagTicketMutation,
  useChangeClientSideTicketStatusMutation,
  useGetAllTicketWithDifferentIdsQuery,
  useGetAllTicketWithoutPaginationQuery,
  useUpdateSummeryMutation,
  useGetSingleTicketTimeAnalysisViaIdQuery,
  useMoveTicketToArchiveMutation,
} = ticketApi;
