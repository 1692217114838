import { FormikProps } from "formik";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<any>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};

const recurranceTypeOptions = [
  {
    label: "DAILY",
    value: "DAILY"
  },
  {
    label: "MONTHLY",
    value: "MONTHLY"
  },
  {
    label: "WEEKLY",
    value: "WEEKLY"
  },
  {
    label: "QUARTERLY",
    value: "QUARTERLY"
  },
  {
    label: "HALF YEARLY",
    value: "HALF YEARLY"
  },
  {
    label: "YEARLY",
    value: "YEARLY"
  },
]

const RecurringPaymentListFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  return (
   <MOLFilterDialog 
   title={"Filters"}
   onClose={onClose}
   isSubmitting={isSubmitting}
   clearFilter={onReset}
   handleSubmit={handleSubmit}
   open={open}
   >
     <div className="flex flex-col gap-4 px-2 md:px-4 md:py-2 py-4">
        {/* Recurrance Type  */}
        <div>
          <ATMSelect
            required
            name="recurranceType"
            label="Recurrance Type"
            placeholder="Select Recurrance Type"
            value={values.recurranceType}
            options={recurranceTypeOptions}
            onChange={(newValue) => {
              setFieldValue("recurranceType", newValue);
            }}
          />
        </div>
      </div>
   </MOLFilterDialog>
  );
};

export default RecurringPaymentListFilterFormDialog;
