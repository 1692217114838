import React from "react";
import Dialog from "@mui/material/Dialog";
import AssignTicketDetailsModalForm from "./AssignTicketDetailsModalForm";
import { object, string } from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { useUpdateTicketDetailsMutation } from "src/services/TicketServices";
import { showToast } from "src/utils/showToaster";
import { isMobile } from "react-device-detect";

type Props = {
  ticketId: string;
  open: boolean;
  handleClose: () => void;
  initialData: any;
};

export type FormInitialValues = {
  projectId: any;
  moduleId: any;
  featureId: any;
  type: string;
  description: string;
};

const AssignTicketDetailsModalWrapper = ({
  ticketId,
  open = false,
  handleClose,
  initialData,
}: Props) => {
  const [updateTicketDetails] = useUpdateTicketDetailsMutation();
  // Form Initial Values
  const initialValues: FormInitialValues = {
    projectId: {
      projectName: initialData?.projectName,
      _id: initialData?.projectId,
    },
    moduleId: initialData?.moduleLabel ? { label: initialData?.moduleLabel, value: initialData?.moduleId } : '',
    featureId: initialData?.featureLabel ? { label: initialData?.featureLabel, value: initialData?.featureId } : '',
    type: "",
    description: initialData?.description,
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: object().required("Please select ticket related project"),
    moduleId: object().test(
      "moduleTst",
      "Please select ticket related module",
      (value, context) => {
        const { type } = context.parent;
        return type === "CHANGE_REQUEST" ? true : value ? true : false;
      }
    ),
    featureId: object().test(
      "featureTest",
      "Please select ticket related feature",
      (value, context) => {
        const { type } = context.parent;
        return type === "CHANGE_REQUEST" ? true : value ? true : false;
      }
    ),
    type: string().required("Please select ticket type"),
  });

  const handleSubmit = (
    values: FormInitialValues,
    { setSubmitting, resetForm }: FormikHelpers<FormInitialValues>
  ) => {
    const { projectId, moduleId, featureId, ...rest } = values;

    const formatedValue = {
      projectId: projectId?._id,
      moduleId: moduleId?.value,
      featureId: featureId?.value,
      ...rest,
    };
    updateTicketDetails({
      ticketId: ticketId,
      body: formatedValue,
    })
      .then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            handleClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      })
      .catch((err:any) => {
        console.error("error", err);
      });
  };

  return (
    <Dialog open={open} maxWidth="md" fullScreen={isMobile}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <AssignTicketDetailsModalForm
              formikProps={formikProps}
              handleClose={handleClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AssignTicketDetailsModalWrapper;
