import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import {
  CampaignFormValues,
  CampaignInfoPropType,
} from "src/models/Campaign.model";
import {
  useEditCampaignMutation,
  useGetCampaignByIdQuery,
} from "src/services/CampaignService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import CampaignForm from "../Layouts/CampaignForm";

type Props = {
  onClose: () => void;
  campaignId: string;
};

const EditCampaignFormWrapper = ({ onClose, campaignId }: Props) => {
  const [editCampaign] = useEditCampaignMutation();
  const { data, isLoading, isFetching } = useGetCampaignByIdQuery(campaignId);
  const [campaignInfo, setCampaignInfo] = useState<CampaignInfoPropType>();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCampaignInfo(data?.data);
    }
  }, [data, isLoading, isFetching]);
  // Form Initial Values
  const initialValues: CampaignFormValues = {
    campaignName: campaignInfo?.campaignName || "",
    campaignGroupName:
      {
        label: campaignInfo?.campaignGroupName,
        value: campaignInfo?.campaignGroupId,
      } || "",
    channelName:
      campaignInfo?.channels?.map((channel) => {
        return {
          label: channel?.channelName,
          value: channel?._id,
        };
      }) || [],
  };

  // Validation Schema
  const validationSchema = object().shape({
    campaignName: string().required("Please enter campaign name"),
    campaignGroupName: object().required("Please select campaign group"),
    channelName: array()
      .min(1, "Please select channel")
      .required("Please select channel"),
  });

  // Handle Submit
  const handleSubmit = (
    values: CampaignFormValues,
    { setSubmitting, resetForm }: FormikHelpers<CampaignFormValues>
  ) => {
    const formattedValues = {
      campaignName: values?.campaignName,
      campaignGroupId: values?.campaignGroupName?.value,
      channels: values?.channelName?.map((channel) => channel?.value),
    };
    editCampaign({
      id: campaignId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <CampaignForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditCampaignFormWrapper;
