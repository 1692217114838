import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type MeetingNotesSliceStateType = {
  items: any[] | [];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  filterBy: {
    fieldName: string;
    value: string[];
  }[];
  dateFilter: {
    startDate: string | null;
    endDate: string | null;
  };
  meetingId: any,
  projectId: any,
  meetingType: any
  meetingTag: any
};

const initialState: MeetingNotesSliceStateType = {
  items: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  rowsPerPage: 20,
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  filterBy: [
    {
      fieldName: "companyName",
      value: [],
    },
    {
      fieldName: "country",
      value: [],
    },
  ],
  dateFilter: {
    startDate: '',
    endDate: '',
  },
  meetingId: [],
  projectId: [],
  meetingType: [],
  meetingTag: [],

};

const MeetingNotesSlice: Slice<MeetingNotesSliceStateType> = createSlice({
  name: "meetingNotes",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string[] }[]>
    ) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setDateFilter: (
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>
    ) => {
      state.dateFilter = action.payload;
    },
    setMeetingId: (state, action: PayloadAction<boolean>) => {
      state.meetingId = action.payload;
    },
    setProjectId: (state, action: PayloadAction<boolean>) => {
      state.projectId = action.payload;
    },
    setMeetingTypeId: (state, action: PayloadAction<boolean>) => {
      state.meetingType = action.payload;
    },
    setMeetingTagId: (state, action: PayloadAction<boolean>) => {
      state.meetingTag = action.payload;
    },
  },
});

export const {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setFilterBy,
  setMeetingId,
  setProjectId,
  setMeetingTypeId,
  setMeetingTagId,
} = MeetingNotesSlice.actions;
export default MeetingNotesSlice.reducer;
