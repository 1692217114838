import React, { useEffect, useState } from 'react'
import MeetingActivitiesTab from './MeetingActivitiesTab'
import { useParams } from 'react-router-dom';
import { useGetContactLeadAllLogsQuery } from 'src/services/ContactService';

const MeetingActivitiesTabWrapper = () => { 
    const { leadId } = useParams();
    const [meetingsData, setMeetingsData] = useState<any>();
    const { data, isLoading, isFetching } = useGetContactLeadAllLogsQuery({
      limit: 10,
      searchValue: "",
      params: ["description"],
      page: 0,
      filterBy: [
        {
          fieldName: "leadId",
          value: leadId,
        },
        {
          fieldName: "logType",
          value: "MEETING",
        },
      ],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });
  
    useEffect(() => {
      if (!isLoading && !isFetching) {
        setMeetingsData(data?.data);
      }
    }, [data, isLoading, isFetching]);
  return ( 
   <MeetingActivitiesTab isLoading={isLoading || isFetching} meetingsData={meetingsData} />
  )
}

export default MeetingActivitiesTabWrapper