import { useEffect, useState } from "react";
import { PiUserFill } from "react-icons/pi";
import ColorComponent from "src/components/UI/colorComponent";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { CLIENT_GRW } from "src/utils/constants";

const TimeAnalyticsView = ({ timeAnalyticData, taskGCTC, taskCTC, totalTicketTime }: any) => {
  const { userData } = useGetLocalStorage();
  const [isMeetingMode, setIsMeetingMode] = useState<any>(false);

  useEffect(() => {
    // get the meetingmode is enable or not
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem(
      "isMeetingModeEnable"
    ) as string;

    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";

    setIsMeetingMode(isEnabled);
  }, [isMeetingMode]);

  return (
    <div className="flex flex-col bg-gray-50 h-full">

      <div className="p-4 border-b">
        <p className="text-md flex items-center">
          <span className="">Total Duration In Tickets :</span>
          {totalTicketTime ? (
            <p className="font-medium">
              <span className="ml-1">
                {`${Math?.floor(
                  totalTicketTime / 60
                )}:${(
                  totalTicketTime % 60
                )?.toFixed()}  hrs`}
              </span>
              <span className="text-sm text-neutral ml-1">
                ({`${totalTicketTime?.toFixed()}`} min)
              </span>
            </p>
          ) : (
            "0 min"
          )}

        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full  p-4 ">
        {timeAnalyticData?.length ? timeAnalyticData?.map((el: any, ind: any) => {
          return (
            <div
              key={ind}
              className="flex flex-col gap-6 border bg-white h-fit items-center rounded-md p-4"
            >
              <div className="flex flex-col items-center gap-2">
                <div className="border p-2 rounded-full aspect-square">
                  <PiUserFill
                    size={16}
                    className="rounded-full mx-auto"
                  />
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-lg font-medium">
                    <ColorComponent
                      inputString={el?.codioticUserName}
                    />
                  </div>
                </div>
              </div>

              <div className="font-medium flex items-center gap-1">
                <span className="text-lg">
                  {`${Math?.floor(el?.durationInMinutes / 60)}:${(
                    el?.durationInMinutes % 60
                  )?.toFixed()}  hrs`}
                </span>

                <span className="text-sm text-neutral">
                  ({`${el?.durationInMinutes?.toFixed()}`} min)
                </span>
              </div>
            </div>
          );
        }) : null}
      </div>
      <>
        {!timeAnalyticData?.length && (
          <div className="flex justify-center items-center h-80 text-[22px] text-red-700 font-semibold">
            Data not Found{" "}
          </div>
        )}
      </>
      {userData?.userType === "SUPER_ADMIN" && (
        <div>
          {timeAnalyticData?.length ? (
            <div className="flex justify-end gap-6 w-full px-4">
              {!isMeetingMode && (
                <div>
                  <span className="font-semibold text-sm text-cyan-600">
                    CTC :{" "}
                  </span>
                  <span className="px-1">₹{taskCTC || 0} </span>
                </div>
              )}
              {CLIENT_GRW === true ? (
                <div>
                  <span className="font-semibold text-sm text-green-600">
                    {!isMeetingMode ? "GCTC" : "CTC"} :{" "}
                  </span>
                  <span className="px-1">₹{taskGCTC || 0} </span>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default TimeAnalyticsView;
