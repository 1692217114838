import { FormikProps } from "formik";
import moment from "moment";
import React from "react";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import { ResourceRequestFilterCardValues } from "./ResourceRequestFilterCardWrapper";
import MOLFilterDialog from "src/components/UI/molecules/MOLFilterDialog/MOLFilterDialog";

type Props = {
  formikProps: FormikProps<ResourceRequestFilterCardValues>;
  onClose: any;
  onReset:any;
  open:boolean
};

const ResourceRequestFilterCard = ({
  formikProps,
  onReset,
  onClose,
  open , 
}: Props) => {
  const { values, setFieldValue, isSubmitting , handleSubmit  } = formikProps;
  return (
   <MOLFilterDialog 
   title={"Filters"}
   onClose={onClose}
   isSubmitting={isSubmitting}
   clearFilter={onReset}
   handleSubmit={handleSubmit}
   open={open}
   >
     
      <div className="flex flex-col gap-4">
        {/* Inquiry Date */}
        <div className="border-l-[3px] border-primary-dark px-3 font font-medium text-[18px]  bg-white  text-primary-dark">
          Date
        </div>
        <div className="grid grid-cols-2 gap-6">
          {/* From */}
          <div className="w-full">
            <ATMDatePicker
              label="From"
              name="startDate"
              format="DD/MM/YYYY"
              maxDate={moment().format("yyyy-MM-DD")}
              value={values.startDate}
              onChange={(newValue) => setFieldValue("startDate", newValue)}
            />
          </div>

          {/* To */}
          <div className="w-full">
            <ATMDatePicker
              label="To"
              name="endDate"
              format="DD/MM/YYYY"
              value={values.endDate}
              minDate={values?.startDate || undefined}
              onChange={(newValue) => setFieldValue("endDate", newValue)}
            />
          </div>
        </div>
      </div>
   </MOLFilterDialog>
  );
};

export default ResourceRequestFilterCard;
