import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { BiSearch } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { MdCancel, MdOutlineManageAccounts } from "react-icons/md";
import { TbCash, TbHome, TbSettings, TbSpeakerphone } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { setIsCollapsed } from "src/redux/slices/SideNavLayoutSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { isAuthorized } from "src/utils/authorization";
import { NavItemType } from "../../../navigation";
import { IconList } from "@tabler/icons-react";

type Props = {
  toggleCollapse: () => void;
  isCollapsed: boolean;
  navigation: NavItemType[];
  isPathEqualtoNavItem?: (navItem: any) => boolean;
};

const categoryNavigation = [
  {
    label: "Home",
    parentPath: "",
    path: "/",
    icon: TbHome,
  },
  {
    label: "Configuration",
    icon: TbSettings,
    parentPath: "configuration",
    path: "/configuration/location",
    name: UserModuleNameTypes.NAV_CONFIGURATION,
  },
  {
    label: "Finance",
    icon: TbCash,
    parentPath: "finance",
    path: "/finance/subscription",
    name: UserModuleNameTypes.NAV_FINANCE,
  },
  {
    label: "Sales Marketing",
    icon: TbSpeakerphone,
    parentPath: "sales&marketing",
    path: "/sales&marketing/channel-group",
    name: UserModuleNameTypes.NAV_SALES_MARKETING,
  },
  {
    label: "HR Module",
    icon: MdOutlineManageAccounts,
    parentPath: "job-onboard",
    path: "/job-onboard/job-creation",
    name: UserModuleNameTypes.NAV_HR_MODULE,
  },
  {
    label: "EnterpriseSuite",
    icon: IconList,
    parentPath: "enterprisesuite",
    path: "/enterprisesuite/industry",
    name: UserModuleNameTypes.NAV_ENTERPRISESUITE,
  },
];

const VerticalNavBar = ({
  toggleCollapse,
  isCollapsed,
  navigation,
  isPathEqualtoNavItem = () => false,
}: Props) => {
  const location = useLocation();
  let pathLocal = location?.pathname?.split("/")?.[1];
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { doubtCount } = useSelector((state: RootState) => state.doubts);
  const { deploymentCount } = useSelector(
    (state: RootState) => state.deployment
  );

  const { userData } = useGetLocalStorage();

  function showCounter(navTitle: string) {
    // append here navigation title and it's redux counter state when have to show the bedges count in sidenav layout
    let isVisibleCounterBedgeArray = [
      {
        title: "Doubts",
        counter: doubtCount,
      },
      {
        title: "Deployment Request",
        counter: deploymentCount,
      },
    ];

    for (let bedges of isVisibleCounterBedgeArray) {
      if (bedges?.title === navTitle) {
        return bedges.counter ? (
          <div className="bg-[#2f3131] rounded-full font-semibold size-6 text-[10px] flex justify-center items-center -right-[6px] -top-[1px] text-white">
            {bedges.counter}
          </div>
        ) : null;
      }
    }

    return false;
  }

  const selectedCategorynavOptions = (path?: string) => {
    const seletedPath = location.pathname?.split("/")?.[1];
    return seletedPath === path || "";
  };

  const getNavigate = (path: string) => {
    if (pathLocal === "configuration") {
      navigate(`/configuration/${path}`);
      return;
    }
    if (pathLocal === "sales&marketing") {
      navigate(`/sales&marketing/${path}`);
      return;
    }
    if (pathLocal === "welcome") {
      navigate(`/${pathLocal}`);
    }
    navigate(`${path}`);
  };

  React.useEffect(() => {
    // Check if the function has been executed before
    const hasExecuted = localStorage.getItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      return;
    }
    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }
    for (const nav of navigation) {
      const isValue = isAuthorized(
        nav?.name as keyof typeof UserModuleNameTypes
      );
      if (isValue) {
        getNavigate(nav.path as string);
        localStorage.setItem("hasExecuted", "true");
        break;
      }
    }
    return () => {
      console.error("herer");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCheckNavigate = (name: string) => {
    if (
      name === UserModuleNameTypes.NAV_CONFIGURATION ||
      name === UserModuleNameTypes.NAV_SALES_MARKETING
    ) {
      localStorage.removeItem("hasExecuted");
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  const filterNavItems = (navItem: NavItemType) => {
    return navItem.label.toLowerCase().includes(searchQuery.toLowerCase());
  };

  return (
    <div
      className={`h-full flex flex-col overflow-hidden transition-all bg-black-11 duration-500 md:static absolute z-[20]  ${
        isCollapsed
          ? "md:min-w-[0px] md:w-[0px] min-w-0 w-0"
          : "min-w-[250px] w-[250px]"
      }`}
    >
      {/* Logo & Menu Icon */}
      <div
        className={`flex px-4 items-center justify-between sticky top-0 py-3 h-[50px] `}
      >
        {/* Logo */}
        <div
          onClick={() => navigate("/")}
          // logo-glow
          className={`font-semibold text-xl text-white cursor-pointer select-none ${
            isCollapsed && "md:hidden"
          }`}
        >
          Codiotic
        </div>

        {/* Menu Icon */}
        <Tooltip title="Alt + T">
          <button
            type="button"
            onClick={toggleCollapse}
            className="text-xl text-white "
          >
            <FiMenu className="hidden md:block" />
            <MdCancel className="block text-xl md:hidden" />
          </button>
        </Tooltip>
      </div>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* categoryNavigation */}
          <div className="flex flex-col h-full">
            {categoryNavigation
              ?.filter((nav) => {
                return isAuthorized(
                  nav?.name as keyof typeof UserModuleNameTypes
                );
                //  &&
                // filterNavItems(nav)
              })
              .map((navItem, navIndex) => {
                return (
                  <Tooltip
                    key={navIndex}
                    title={navItem.label}
                    placement="right-end"
                  >
                    <div
                      onClick={() => {
                        getCheckNavigate(navItem.name as string);
                        isMobile
                          ? dispatch(setIsCollapsed(true))
                          : dispatch(setIsCollapsed(false));
                        navItem.path && navigate(navItem.path);
                      }}
                      className={`flex items-center justify-center text-white rounded-md cursor-pointer aspect-square size-12 ${
                        selectedCategorynavOptions(navItem.parentPath)
                          ? "text-blue"
                          : ""
                      }`}
                    >
                      <navItem.icon
                        className={`${
                          selectedCategorynavOptions(navItem.parentPath)
                            ? "text-white"
                            : "text-gray"
                        }`}
                      />
                    </div>
                  </Tooltip>
                );
              })}
          </div>
          {/* Navigations */}
          <div className="flex flex-col w-full gap-2 p-2 overflow-hidden bg-black rounded-tl-lg">
            <div className="relative flex items-center text-white border rounded-md bg-black-10 border-black-11">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`border-none w-full text-sm font-regular bg-transparent focus:outline-none p-2`}
              />
              <div className="pr-2">
                <BiSearch className="" />
              </div>
            </div>
            <div className="flex flex-col w-full gap-2 overflow-auto">
              {navigation
                ?.filter((nav) => {
                  return (
                    isAuthorized(
                      nav?.name as keyof typeof UserModuleNameTypes
                    ) && filterNavItems(nav)
                  );
                })
                .map((navItem, navIndex) => {
                  return (
                    <div
                      key={navIndex}
                      onClick={() => {
                        getCheckNavigate(navItem.name as string);
                        isMobile
                          ? dispatch(setIsCollapsed(true))
                          : dispatch(setIsCollapsed(false));
                        navItem.path && navigate(navItem.path);
                      }}
                      className={`
                flex
                gap-4
                items-center 
                py-2 
                px-3
                w-full
                rounded-md
                cursor-pointer
                ${!isPathEqualtoNavItem(navItem) && "hover:bg-black-10 "} 
                transition-all
                duration-300
                text-white
                text-xs
                font-regular
                ${isPathEqualtoNavItem(navItem) ? "bg-blue font-medium" : ""}
                `}
                    >
                      <div>
                        <navItem.icon className="size-4" />
                      </div>
                      <div
                        className={`flex justify-between items-center grow ${
                          isCollapsed && " md:hidden"
                        }`}
                      >
                        {navItem.label}
                        {showCounter(navItem.label)}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalNavBar;
