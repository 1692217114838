import React, { ChangeEvent } from "react";
import ATMInputAdormant from "../formFields/ATMInputAdormant/ATMInputAdormant";
import { BiSearch } from "react-icons/bi";
import ATMLoadingButton from "../ATMLoadingButton/ATMLoadingButton";
import { IoFilter } from "react-icons/io5";

type Props = {
  pageTitle?: string;
  searchValue?: string;
  isFilter?: boolean;
  onFilterClick?: () => void;
  onSearchChange?: (
    newValue: string,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  buttonProps?: {
    btnName?: string;
    onClick: () => void;
    className?: string;
    disabled?: boolean;
    variant?: any;
    color?: any;
  };
  hideAddButton?: boolean;
  hideSearchBox?: boolean;
};

const ATMPageHeader = ({
  pageTitle,
  searchValue = "",
  onSearchChange,
  hideAddButton = false,
  buttonProps = {
    btnName: "Add New",
    onClick: () => {},
    className: "",
    disabled: false,
    variant: "",
    color: "",
  },
  hideSearchBox = false,
  onFilterClick = () => {},
  isFilter,
}: Props) => {
  return (
    <div className="items-center justify-between flex-1 gap-2 p-4 md:flex">
      <div className="font-medium text-md">{pageTitle}</div>
      <div className="flex flex-wrap gap-2">
        {hideSearchBox ? null : (
          <div className="w-[300px] ">
            <ATMInputAdormant
              name=""
              value={searchValue}
              onChange={(e) => onSearchChange?.(e.target.value, e)}
              adormant={<BiSearch />}
              adormantProps={{
                position: "start",
                extraClasses: "bg-white border-0",
              }}
              inputProps={{ className: "bg-white" }}
              placeholder="Search..."
              autoFocusShortcut="Ctrl+k"
            />
          </div>
        )}

        {hideAddButton ? null : (
          <div>
            <ATMLoadingButton
              variant={buttonProps?.variant}
              color={buttonProps?.color}
              onClick={buttonProps?.onClick}
              className={buttonProps?.className}
              disabled={buttonProps?.disabled}
              title={
                buttonProps?.disabled
                  ? "Please map the ticket first"
                  : undefined
              }
            >
              {" "}
              {buttonProps?.btnName}{" "}
            </ATMLoadingButton>
          </div>
        )}
        {isFilter && (
          <ATMLoadingButton variant="tonal" onClick={() => onFilterClick()}>
            <div className="flex items-center gap-2">
              <IoFilter />
              Filters
            </div>
          </ATMLoadingButton>
        )}
      </div>
    </div>
  );
};

export default ATMPageHeader;
