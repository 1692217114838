import { FormikProps } from "formik";
import React from "react";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { DoubtsFormValue } from "src/models/Doubts.model";

// MODELS
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import { FeatureListResponseType } from "src/models/Feature.model";
import { ModulesListResponse } from "src/models/Module.model";
import { ProjectListResponse } from "src/models/Project.model";

// HOOKS
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { useGetAllFeatureByModule } from "src/hooks/useGetAllFeatureByModule";
import { useGetAllModulesByProject } from "src/hooks/useGetAllModulesByProject";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { useGetProjectWiseMembers } from "src/hooks/useGetProjectWiseMembers";
import { showToast } from "src/utils/showToaster";

type Props = {
  formikProps: FormikProps<DoubtsFormValue>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  projectItems?: any;
  saveNextChecked?: any;
};

const ModuleForm = ({
  formikProps,
  projectItems,
  formType,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  const [userData, setuserData] = React.useState<any>();

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  // get module by project id hook
  const { modules, isDataLoading: isModulesDataLoading } =
    useGetAllModulesByProject(values?.project?.value);

  // get feature by module id hook
  const { features, isDataLoading: isFeaturesDataLoading } =
    useGetAllFeatureByModule(values?.module?.value);

  const { allProjectWiseMembers, isDataLoading } = useGetProjectWiseMembers(
    values.project?.value || ""
  );

  React.useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setuserData(userData);
    } else {
      console.error("userData is not available in localStorage");
    }
  }, []);

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add New Doubt" : "Update Doubt"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Project */}
        <div>
          <ATMSelect
            required
            name="project"
            label="Project"
            placeholder="Select Project"
            value={values.project}
            isLoading={isAllProjectsDataLoading}
            options={allProjects?.map((ele: ProjectListResponse) => {
              return {
                label: ele?.projectName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("project", newValue);
            }}
          />
        </div>

        {/* Modules by project id */}
        <div className="mt-1">
          <ATMSelect
            name="module"
            label="Module"
            placeholder="Select Module"
            value={values.module}
            isLoading={isModulesDataLoading}
            options={modules?.map((ele: ModulesListResponse) => {
              return {
                label: ele?.moduleName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("module", newValue);
            }}
          />
        </div>

        {/* Feature by module id */}
        <div className="mt-1">
          <ATMSelect
            name="feature"
            label="Feature"
            placeholder="Select Feature"
            value={values.feature}
            isLoading={isFeaturesDataLoading}
            options={features?.map((ele: FeatureListResponseType) => {
              return {
                label: ele?.featureName,
                value: ele?._id,
              };
            })}
            onChange={(newValue) => {
              setFieldValue("feature", newValue);
            }}
          />
        </div>

        {/* User */}
        <div>
          <ATMSelect
            name="askTo"
            required
            label="ASK TO"
            placeholder="Select User"
            value={values.askTo}
            isLoading={isDataLoading}
            options={allProjectWiseMembers?.members?.map(
              (ele: CodioticUserListResponse) => {
                return {
                  label: ele?.name,
                  value: ele?._id,
                };
              }
            )}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return <div className="capitalize"> {option.label} </div>;
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            onChange={(newValue) => {
              if (userData?.userId === newValue?.value) {
                showToast(
                  "error",
                  "you can not select yourself for question asking"
                );
              } else {
                setFieldValue("askTo", newValue);
              }
            }}
          />
        </div>

        {/* asked question */}
        <div className="z-0">
          <MOLMarkdownEditor
            required
            label="Question"
            value={values.question}
            placeholder="Enter question"
            onChange={(newValue: any) => {
              setFieldValue("question", newValue);
            }}
          />
        </div>
        {/* {formType === "Add" && (
          <div>
            <ATMCheckbox
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              label="Save and Next"
              checked={saveNextChecked}
              onChange={(checked) => {
                setSaveNextChecked(checked);
              }}
            />
          </div>
        )}
        <div>
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Save
          </ATMLoadingButton>
        </div> */}
      </div>
    </MOLFormDialog>
  );
};

export default ModuleForm;
