import React from "react";
import { Dialog } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { object } from "yup";
import { useAddPunchMutation } from "src/services/AttendanceService";
import { showToast } from "src/utils/showToaster";
import AttedancePunchIn from "./AttedancePunchIn";

type Props = {
  onClose: any;
  setIsActive: any;
};

const AttendancePunchInWrapper = ({ onClose, setIsActive }: Props) => {
  const [addAttendancePunchIn] = useAddPunchMutation();

  // Form Initial Values
  const initialValues: any = {
    workingFrom: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    workingFrom: object().required("Please select working from?"),
  });

  // Handle Submit
  const handleSetYourselfActive = (
    values: { workingFrom?: { value: any } },
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    const workingFromValue = values?.workingFrom?.value;
    if (workingFromValue !== undefined) {
      addAttendancePunchIn({ workingFrom: workingFromValue }).then(
        (res: any) => {
          if (res?.error) {
            showToast("error", res?.error?.data?.message);
          } else {
            if (res?.data?.status) {
              showToast("success", 'Logged in successfully');
              setIsActive(true);
              resetForm();
              onClose();
            } else {
              showToast("error", res?.data?.message);
            }
          }
          setSubmitting(false);
        }
      );
    } else {
      // Handle the case when workingFrom is not defined or its value is undefined
      console.error("workingFrom is not defined or its value is undefined");
    }
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSetYourselfActive}
      >
        {(formikProps) => (
          <Form>
            <AttedancePunchIn formikProps={formikProps} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AttendancePunchInWrapper;
