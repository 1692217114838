import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { CurrencyFormValues } from "src/models/Currency.model";
import { handleValidWithDotNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<CurrencyFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const CurrencyForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Currency" : "Update Currency"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Currency Name */}
        <div>
          <ATMTextField
            required
            name="currencyName"
            value={values.currencyName}
            onChange={(e) => setFieldValue("currencyName", e.target.value)}
            label="Currency Name"
            placeholder="Enter Currency Name"
            onBlur={handleBlur}
          />
        </div>
        {/* Currency Value */}
        <div>
          <ATMTextField
            required
            name="currencyValueInINR"
            value={values.currencyValueInINR}
            onChange={(e) => {
              handleValidWithDotNumber(e) &&
                setFieldValue("currencyValueInINR", e.target.value);
            }}
            label="Currency Value In INR"
            placeholder="Enter Currency Value In INR"
            className="mt-0 rounded"
            onBlur={handleBlur}
          />
        </div>
        {/* Currency Symbol */}
        <div>
          <ATMTextField
            name="currencySymbol"
            value={values.currencySymbol}
            onChange={(e) => setFieldValue("currencySymbol", e.target.value)}
            label="Currency Symbol"
            placeholder="Enter Currency Symbol"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default CurrencyForm;
