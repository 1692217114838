import React, { useEffect, useState } from "react";
import AllTaskAddForm from "../Layouts/AllTaskAddForm";
import { Form, Formik, FormikHelpers } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditAllTaskFormValues } from "src/models/AllTask.model";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import {
  useGetTaskByIdQuery,
  useUpdateTaskByIdMutation,
} from "src/services/TaskService";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { CircularProgress } from "@mui/material";

const RelatedToType = [
  {
    label: "Project",
    value: "PROJECT",
  },
  {
    label: "Ticket",
    value: "TICKET",
  },
];

const EditAllTaskWrapper = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetTaskByIdQuery(id || "", {
    skip: !id,
  });
  const [updateTaskById] = useUpdateTaskByIdMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  function capitalizeFirstLetter(str: any) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1).toLowerCase();
  }
  const initialValues: EditAllTaskFormValues = {
    title: items?.title || "",
    layer: items?.layer?.map((layer: any) => ({
      layerName: layer?.layerName,
      _id: layer?._id,
    })),
    label: {
      labelName: items?.label?.[0]?.labelName,
      _id: items?.label?.[0]?._id,
    },
    estimation: items?.estimation || "",
    featureId: { value: items?.featureId, label: items?.featureName },
    projectId: { _id: items?.projectId, projectName: items?.projectName },
    moduleId: { value: items?.moduleId, label: items?.moduleName },
    description: items?.description || "",
    ticketId: { value: items?.ticketId, label: items?.ticketName },
    deploymentId: items?.deploymentId || "",
    assignedTo: { value: items?.assignedTo, label: items?.assigneeName },
    dueDate: items?.dueDate || "",
    relatedTo: {
      label: capitalizeFirstLetter(items?.relatedTo),
      value: items?.relatedTo,
    },
  };

  const handleSubmit = (
    values: EditAllTaskFormValues,
    { setSubmitting, resetForm }: FormikHelpers<EditAllTaskFormValues>
  ) => {
    const {
      projectId,
      relatedTo,
      deploymentId,
      moduleId,
      ticketId,
      featureId,
      assignedTo,
      ...rest
    } = values;
    const formattedValues = {
      ...rest,
      deploymentId: deploymentId?.value,
      relatedTo: relatedTo?.value,
      featureId: featureId?.value,
      moduleId: moduleId?.value,
      projectId: projectId?._id,
      assignedTo: assignedTo?.value,
      ticketId: ticketId?.value,
      label: rest?.label?.labelName
        ? [
            {
              labelName: rest?.label?.labelName,
            },
          ]
        : [],
    };
    updateTaskById({ id: id, body: formattedValues }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          state
            ? navigate(`${state?.pathname + state?.search}`)
            : navigate("/task");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  const validationSchema = object().shape({
    title: string().required("Please enter title"),
    relatedTo: object().required("Related To is required"),
    projectId: object().test(
      "projectId",
      "project is required",
      (value, context) => {
        const { relatedTo } = context.parent;
        if (relatedTo?.value === "PROJECT" && !value) {
          return false;
        } else {
          return true;
        }
      }
    ),
    // moduleId: object().test(
    //   "moduleId",
    //   "module is required",
    //   (value, context) => {
    //     const { relatedTo } = context.parent;
    //     if (relatedTo?.value === "PROJECT" && !value) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    // featureId: object().test(
    //   "featureId",
    //   "feature is required",
    //   (value, context) => {
    //     const { relatedTo } = context.parent;
    //     if (relatedTo?.value === "PROJECT" && !value) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    ticketId: object().test(
      "ticketId",
      "Ticket is required",
      (value, context) => {
        const { relatedTo } = context.parent;
        if (relatedTo?.value === "TICKET" && !value) {
          return false;
        } else {
          return true;
        }
      }
    ),
  });

  return (
    <>
      <SideNavLayout>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
               {(isLoading || isFetching) && (
                <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                  <CircularProgress />
                </div>
              )}
              <AllTaskAddForm
                formikProps={formikProps}
                RelatedToType={RelatedToType}
                formType="EDIT"
                isLoading= {isLoading}
              />
            </Form>
          )}
        </Formik>
      </SideNavLayout>
    </>
  );
};

export default EditAllTaskWrapper;
