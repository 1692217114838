import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import HoliDayCalenderForm from "../Layouts/HoliDayCalendarForm";
import { HoliDayCalenderFormValues } from "src/models/HoliDayCalender.model";
import { useAddHoliDayMutation } from "src/services/HolidayCalendarServices";
import { showToast } from "src/utils/showToaster";
import moment from "moment";

type Props = {
  onClose: () => void;
};

const AddHolidayCalendarFormWrapper = ({ onClose }: Props) => {
  const [addHoliDay] = useAddHoliDayMutation();

  // Form Initial Values
  const initialValues: HoliDayCalenderFormValues = {
    date: "",
    optional: false,
    holidayName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    date: string().required("Please enter date"),
    optional: string().required("Required"),
    holidayName: string().required("Holiday Name is Required"),
  });

  // Handle Submit
  const handleSubmit = (
    values: HoliDayCalenderFormValues,
    { setSubmitting, resetForm }: FormikHelpers<HoliDayCalenderFormValues>
  ) => {
    const formattedValues = {
      holidayName: values?.holidayName,
      date: moment(values?.date).format("YYYY-MM-DD"),
      optional: values?.optional,
    };
    addHoliDay(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <HoliDayCalenderForm
            formType="ADD"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddHolidayCalendarFormWrapper;
