/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategoryFilter,
  setDateFilter,
  setGstApplicableFilter,
  setStatusFilter,
} from "src/redux/slices/Finance-New-Slices/IncomeSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { object } from "yup";
import IncomeFlowListFilterFormDialog from "./IncomeListFilterFormDialog";
type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  status: any;
  gstApplicable: any;
  category: any;
  startDate: null | string;
  endDate: null | string;
};

const IncomeListFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { categoryFilter, statusFilter, gstApplicableFilter } = useSelector(
    (state: RootState) => state.income
  );

  const initialValues: FormInitialValues = {
    status: statusFilter,
    gstApplicable: gstApplicableFilter,
    category: categoryFilter,
    startDate: null,
    endDate: null,
  };

  const validationSchema: any = object({});
  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);
    // created date from to dispatch
    dispatch(
      setDateFilter({
        startDate: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        endDate: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
        dateFilterKey: "incomeDate",
      })
    );
    // ticket type  dispatch
    dispatch(setCategoryFilter(values?.category));
    dispatch(setStatusFilter(values?.status));
    dispatch(setGstApplicableFilter(values?.gstApplicable));

    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        startDate: "",
        endDate: "",
      })
    );
    await dispatch(setCategoryFilter([]));
    await dispatch(setStatusFilter([]));
    await dispatch(setGstApplicableFilter([]));
    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <IncomeFlowListFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default IncomeListFilterFormDialogWrapper;
