import React from "react";
import { FieldArray, FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { ProfileDetailsFormValues } from "./ProfileDetailsFormWrapper";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { AiOutlinePlus } from "react-icons/ai";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import { TbTrash } from "react-icons/tb";
import MOLFileUpload from "src/components/UI/molecules/MOLFileUpload/MOLFileUpload";
import { useGetAllSkillQuery } from "src/services/SkillService";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import { HiOutlineMinusCircle } from "react-icons/hi";

type Props = {
  formikProps: FormikProps<ProfileDetailsFormValues>;
};

const GenderOption = [
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const MaritalStatusOption = [
  {
    label: "Single",
    value: "Single",
  },
  {
    label: "Married",
    value: "Married",
  },
  {
    label: "Separate",
    value: "Separate",
  },
];

const CourseOption = [
  {
    label: "Tech",
    value: "Tech",
  },
  {
    label: "Non-Tech",
    value: "Non-Tech",
  },
];

const CollegeTearOption = [
  {
    label: "Tier One",
    value: "Tier One",
  },
  {
    label: "Tier Two",
    value: "Tier Two",
  },
  {
    label: "Tier Three",
    value: "Tier Three",
  },
];

const ProfileDetailsForm = ({ formikProps }: Props) => {
  const { values, setFieldValue, handleBlur, isSubmitting } = formikProps;

  const { data, isLoading, isFetching } = useGetAllSkillQuery("");

  const SkillOptions = data?.data?.map((el: any) => {
    return {
      label: el?.skillName,
      value: el?._id,
    };
  });
  return (
    <div className="p-4 flex flex-col gap-4 bg-gray-100">
      {/* Personal Information */}
      <div className="bg-white shadow border p-4 rounded-lg">
        <div className="border-l-[3px] border-teal-600 px-2 py-1 mb-2 font font-medium text-md bg-white  text-teal-600">
          Personal Information
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-6 ">
          {/* Name */}
          <div>
            <ATMTextField
              disabled
             required
              name="name"
              value={values?.name}
              onChange={(e) => {
                setFieldValue("name", e.target.value);
              }}
              label="Name"
              placeholder="Enter Name"
              onBlur={handleBlur}
            />
          </div>
          {/* Father Name */}
          <div>
            <ATMTextField
              name="fatherName"
              required
              value={values?.fatherName}
              onChange={(e) => {
                setFieldValue("fatherName", e.target.value);
              }}
              label="Father Name"
              placeholder="Enter Father Name"
              onBlur={handleBlur}
            />
          </div>
          {/* Email Name */}
          <div>
            <ATMTextField
              name="personalEmail"
              
              required
              value={values?.personalEmail}
              onChange={(e) => {
                setFieldValue("personalEmail", e.target.value);
              }}
              label="Personal Email"
              placeholder="Enter Personal Email"
              onBlur={handleBlur}
            />
          </div>
          {/* Mobile */}
          <div>
            <ATMTextField
              disabled
              required
              name="mobile"
              value={values?.mobile}
              onChange={(e) => {
                setFieldValue("mobile", e.target.value);
              }}
              label="Mobile"
              placeholder="Enter Mobile"
              onBlur={handleBlur}
            />
          </div>
          {/* WhatsApp Mobile */}
          <div>
            <ATMTextField
              name="whatsAppMobile"
              required
              value={values?.whatsAppMobile}
              onChange={(e) => {
                setFieldValue("whatsAppMobile", e.target.value);
              }}
              label="WhatsApp Mobile"
              placeholder="Enter WhatsApp Mobile"
              onBlur={handleBlur}
            />
          </div>
          {/* Emergency Contact Number */}
          <div>
            <ATMTextField
              name="emergencyContactNo"
              required
              value={values?.emergencyContactNo}
              onChange={(e) => {
                setFieldValue("emergencyContactNo", e.target.value);
              }}
              label="Emergency Contact Number"
              placeholder="Enter Emergency Contact Number"
              onBlur={handleBlur}
            />
          </div>
          {/* DOB As Per Document */}
          <div>
            <ATMDatePicker
              label="DOB (As Per Documents)"
              name="dobAsPerDocument"
              format="DD/MM/YYYY"
              value={values.dobAsPerDocument || null}
              onChange={(newValue) => {
                setFieldValue("dobAsPerDocument", newValue);
              }}
            />
          </div>
          {/* DOB As Per Actuall */}
          <div>
            <ATMDatePicker
              label="DOB (Actually)"
              name="dobActually"
              format="DD/MM/YYYY"
              value={values.dobActually || null}
              onChange={(newValue) => {
                setFieldValue("dobActually", newValue);
              }}
            />
          </div>
          {/* Joining Date */}
          <div>
            <ATMDatePicker
              label="Joining Date"
              name="joiningDate"
              format="DD/MM/YYYY"
              value={values?.joiningDate || null}
              onChange={(newValue) => {
                setFieldValue("joiningDate", newValue);
              }}
            />
          </div>
          {/* Blood Group */}
          <div>
            <ATMTextField
              name="bloodGroup"
              value={values?.bloodGroup}
              onChange={(e) => {
                setFieldValue("bloodGroup", e.target.value);
              }}
              label="Blood Group"
              placeholder="Enter Blood Group"
              onBlur={handleBlur}
            />
          </div>
          {/* Gender */}
          <div className="">
            <ATMSelect
              name="gender"
              value={values?.gender}
              onChange={(newValue) => setFieldValue("gender", newValue)}
              onBlur={handleBlur}
              label="Gender"
              placeholder="Select Gender"
              options={GenderOption}
            />
          </div>
          {/* Marital Status */}
          <div className="">
            <ATMSelect
              name="maritalStatus"
              value={values?.maritalStatus}
              onChange={(newValue) => setFieldValue("maritalStatus", newValue)}
              onBlur={handleBlur}
              label="Marital Status"
              placeholder="Select Marital Status"
              options={MaritalStatusOption}
            />
          </div>
          {/* Medical History */}
          <div>
            <ATMTextField
              name="medicalHistory"
              value={values?.medicalHistory}
              onChange={(e) => {
                setFieldValue("medicalHistory", e.target.value);
              }}
              label="Medical History"
              placeholder="Enter Medical History"
              onBlur={handleBlur}
            />
          </div>
          {/* Allergic */}
          <div>
            <ATMTextField
              name="allergic"
              value={values?.allergic}
              onChange={(e) => {
                setFieldValue("allergic", e.target.value);
              }}
              label="Allergic"
              placeholder="Enter Allergic"
              onBlur={handleBlur}
            />
          </div>
          <div>
            <ATMMultiSelect
              name="skills"
              label="Skills"
              value={values?.skills}
              onBlur={handleBlur}
              isLoading={isLoading || isFetching}
              placeholder="Select Skills"
              options={SkillOptions}
              onChange={(newValue) => {
                setFieldValue("skills", newValue);
              }}
            />
          </div>
          {/* Permanent Address */}
          <div>
            <ATMTextField
              name="permanentAddress"
              value={values?.permanentAddress}
              onChange={(e) => {
                setFieldValue("permanentAddress", e.target.value);
              }}
              label="Permanent Address"
              placeholder="Enter Permanent Address"
              onBlur={handleBlur}
            />
          </div>
          {/* Local Address */}
          <div>
            <ATMTextField
              name="localAddress"
              value={values?.localAddress}
              onChange={(e) => {
                setFieldValue("localAddress", e.target.value);
              }}
              label="Local Address"
              placeholder="Enter Local Address"
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      {/* Bank Details */}
      <div className="bg-white shadow border p-4 rounded-lg">
        <div className="border-l-[3px] border-cyan-600 px-2 py-1 mb-2 font font-medium text-md bg-white text-cyan-600">
          Bank Details
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1  gap-6">
          {/* Bank Name */}
          <div>
            <ATMTextField
              name="bankName"
              value={values?.bankName}
              onChange={(e) => {
                setFieldValue("bankName", e.target.value);
              }}
              label="Bank Name"
              placeholder="Enter Bank Name"
              onBlur={handleBlur}
            />
          </div>
          {/* Account Number */}
          <div>
            <ATMTextField
              name="accountNumber"
              value={values?.accountNumber}
              onChange={(e) => {
                setFieldValue("accountNumber", e.target.value);
              }}
              label="Account Number"
              placeholder="Enter Account Number"
              onBlur={handleBlur}
            />
          </div>
          {/* Branch Name */}
          <div>
            <ATMTextField
              name="bankBranchName"
              value={values?.bankBranchName}
              onChange={(e) => {
                setFieldValue("bankBranchName", e.target.value);
              }}
              label="Branch Name"
              placeholder="Enter Branch Name"
              onBlur={handleBlur}
            />
          </div>
          {/* IFSC Code */}
          <div>
            <ATMTextField
              name="bankIfscCode"
              value={values?.bankIfscCode}
              onChange={(e) => {
                setFieldValue("bankIfscCode", e.target.value);
              }}
              label="IFSC Code"
              placeholder="Enter IFSC Code"
              onBlur={handleBlur}
            />
          </div>
          {/* Cancel Cheque */}
          <MOLFileUpload
            isShowCancel
            onCancelClick={() => setFieldValue("cancelCheque", "")}
            value={values?.cancelCheque}
            label="Cancel Cheque"
            name="cancelCheque"
            onChange={(file: any) => {
              setFieldValue("cancelCheque", file);
            }}
          />
        </div>
      </div>
      {/* Academic History */}
      <div className="bg-white shadow border p-4 rounded-lg">
        <div className="border-l-[3px] border-fuchsia-600 px-2 py-1  font font-medium text-md bg-white text-fuchsia-600">
          Academic History
        </div>
        <div className="mt-1">
          <FieldArray
            name="academicHistory"
            render={({ push, remove }) => (
              <>
                {values?.academicHistory?.map((doc: any, index: any) => {
                  return (
                    <div key={index}>
                      <div className="flex justify-end ">
                        <ATMLoadingButton
                          className="bg-transparent text-red-500 border-0 font-bold w-[20px] h-[38px]"
                          onClick={() => {
                            showConfirmationDialog({
                              title: "Heads Up",
                              text: "Are you sure want to Delete this Academic History?",
                              icon: "question",
                              showCancelButton: true,
                              next: (result) => {
                                if (result.isConfirmed) {
                                  remove(index);
                                }
                              },
                            });
                          }}
                        >
                          <TbTrash size={20} />
                        </ATMLoadingButton>
                      </div>
                      <div className="grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1  xl:gap-6 gap-4">
                        {/* College School Name */}
                        <div>
                          <ATMTextField
                            name={`academicHistory[${index}].collgeSchoolName`}
                            value={
                              values?.academicHistory?.[index]?.collgeSchoolName
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `academicHistory[${index}].collgeSchoolName`,
                                e.target.value
                              )
                            }
                            label="College School Name"
                            placeholder="Enter College School Name"
                            onBlur={handleBlur}
                          />
                        </div>
                        {/* Course Name */}
                        <div>
                          <ATMTextField
                            name={`academicHistory[${index}].courseName`}
                            value={values?.academicHistory?.[index]?.courseName}
                            onChange={(e) =>
                              setFieldValue(
                                `academicHistory[${index}].courseName`,
                                e.target.value
                              )
                            }
                            label="Course Name"
                            placeholder="Enter Course Name"
                            onBlur={handleBlur}
                          />
                        </div>
                        {/* Course Type */}
                        <div className="">
                          <ATMSelect
                            name={`academicHistory[${index}].courseType`}
                            value={values?.academicHistory?.[index]?.courseType}
                            onChange={(newValue) =>
                              setFieldValue(
                                `academicHistory[${index}].courseType`,
                                newValue
                              )
                            }
                            onBlur={handleBlur}
                            label="Course Type"
                            placeholder="Select Course Type"
                            options={CourseOption}
                          />
                        </div>
                        {/* University Board */}
                        <div>
                          <ATMTextField
                            name={`academicHistory[${index}].universityBoard`}
                            value={
                              values?.academicHistory?.[index]?.universityBoard
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `academicHistory[${index}].universityBoard`,
                                e.target.value
                              )
                            }
                            label="University Board"
                            placeholder="Enter University Board"
                            onBlur={handleBlur}
                          />
                        </div>
                        {/* Course Tier */}
                        <div className="">
                          <ATMSelect
                            name={`academicHistory[${index}].collgeSchoolTier`}
                            value={
                              values?.academicHistory?.[index]?.collgeSchoolTier
                            }
                            onChange={(newValue) =>
                              setFieldValue(
                                `academicHistory[${index}].collgeSchoolTier`,
                                newValue
                              )
                            }
                            onBlur={handleBlur}
                            label="College School Tier"
                            placeholder="Select College School Tier"
                            options={CollegeTearOption}
                          />
                        </div>
                        {/* ATMDATEPICKER */}
                        <div>
                          <ATMDatePicker
                            views={["year"]}
                            format="YYYY"
                            name={`academicHistory[${index}].starYear`}
                            value={
                              values?.academicHistory?.[index]?.starYear || null
                            }
                            onChange={(newValue) =>
                              setFieldValue(
                                `academicHistory[${index}].starYear`,
                                newValue
                              )
                            }
                            label="Start Year"
                          />
                        </div>
                        <div>
                          <ATMDatePicker
                            views={["year"]}
                            format="YYYY"
                            name={`academicHistory[${index}].endYear`}
                            value={
                              values?.academicHistory?.[index]?.endYear || null
                            }
                            onChange={(newValue) =>
                              setFieldValue(
                                `academicHistory[${index}].endYear`,
                                newValue
                              )
                            }
                            label="End Year"
                          />
                        </div>
                        <MOLFileUpload
                          isShowCancel
          
                          onCancelClick={() =>
                            setFieldValue(
                              `academicHistory[${index}].EducationCertificate`,
                              ""
                            )
                          }
                          value={
                            values?.academicHistory?.[index]
                              ?.EducationCertificate || ""
                          }
                          label="Certificate"
                          name={`academicHistory[${index}].EducationCertificate`}
                          onChange={(file: any) => {
                            setFieldValue(
                              `academicHistory[${index}].EducationCertificate`,
                              file
                            );
                          }}
                        />
                        <div className="mt-6">
                          <ATMCheckbox
                            id={index}
                            name={`academicHistory[${index}].currentlyPursuing`}
                            extraClasses="font-semibold"
                            checked={
                              values?.academicHistory?.[index]
                                ?.currentlyPursuing
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `academicHistory[${index}].currentlyPursuing`,
                                e
                              )
                            }
                            label="Currently Pursuing"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="flex justify-end my-4">
                  <p
                    className="text-link font-semibold text-base text-[16px] max-[1024px]:text-sm flex items-center cursor-pointer"
                    onClick={() =>
                      push({
                        collgeSchoolName: "",
                        courseName: "",
                        endYear: "",
                        starYear: "",
                        courseType: "",
                        collgeSchoolTier: "",
                        currentlyPursuing: false,
                      })
                    }
                  >
                    <AiOutlinePlus size={18} color="#0064C4" className="mr-2" />
                    Add Academic History
                  </p>
                </div>
              </>
            )}
          />
        </div>
      </div>
      {/* Work History */}
      <div className="bg-white shadow border p-4 rounded-lg">
        <div className="border-l-[3px] border-sky-600 px-2 py-1  font font-medium text-md bg-white text-sky-600">
          Work History
        </div>
        <div className="mt-1">
          <FieldArray
            name="workHistory"
            render={({ push, remove }) => (
              <>
                {values?.workHistory?.map((doc: any, index: any) => {
                  return (
                    <div key={index}>
                      <div className="flex justify-end">
                        <ATMLoadingButton
                          className="bg-transparent text-red-500 border-0 font-bold w-[20px] h-[38px]"
                          onClick={() => {
                            showConfirmationDialog({
                              title: "Heads Up",
                              text: "Are you sure want to Delete this Work History?",
                              icon: "question",
                              showCancelButton: true,
                              next: (result) => {
                                if (result.isConfirmed) {
                                  remove(index);
                                }
                              },
                            });
                          }}
                        >
                          <TbTrash size={20} />
                        </ATMLoadingButton>
                      </div>
                      <div className="grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1  xl:gap-6 gap-4">
                        <div>
                          <ATMTextField
                            name={`workHistory[${index}].companyName`}
                            value={values?.workHistory?.[index]?.companyName}
                            onChange={(e) =>
                              setFieldValue(
                                `workHistory[${index}].companyName`,
                                e.target.value
                              )
                            }
                            label="Company Name"
                            placeholder="Enter Company Name"
                            onBlur={handleBlur}
                          />
                        </div>
                        <div>
                          <ATMTextField
                            name={`workHistory[${index}].jobTitle`}
                            value={values?.workHistory?.[index]?.jobTitle}
                            onChange={(e) =>
                              setFieldValue(
                                `workHistory[${index}].jobTitle`,
                                e.target.value
                              )
                            }
                            label="Job Title"
                            placeholder="Enter Job Title"
                            onBlur={handleBlur}
                          />
                        </div>

                        <div>
                          <ATMDatePicker
                            views={["year"]}
                            format="YYYY"
                            name={`workHistory[${index}].starYear`}
                            value={
                              values?.workHistory?.[index]?.starYear || null
                            }
                            onChange={(newValue) =>
                              setFieldValue(
                                `workHistory[${index}].starYear`,
                                newValue
                              )
                            }
                            label="Start Year"
                          />
                        </div>
                        <div>
                          <ATMDatePicker
                            views={["year"]}
                            format="YYYY"
                            name={`workHistory[${index}].endYear`}
                            value={
                              values?.workHistory?.[index]?.endYear || null
                            }
                            onChange={(newValue) =>
                              setFieldValue(
                                `workHistory[${index}].endYear`,
                                newValue
                              )
                            }
                            label="End Year"
                          />
                        </div>
                        <FieldArray
                          name={`workHistory[${index}].workExperienceCertificate`}
                          render={({ push, remove }) => (
                            <>
                              {values?.workHistory?.[
                                index
                              ]?.workExperienceCertificate?.map(
                                (expCer: any, certificateIndex: any) => {
                                  return (
                                    <div className="relative ">
                                      <div className="flex justify-end absolute right-2">
                                      <p className="cursor-pointer" onClick={()=>{
                                         remove(certificateIndex)
                                      }}>  <HiOutlineMinusCircle  color="#FF0000" />{" "} </p>
                                      </div>
                                      <MOLFileUpload
                                        isShowCancel
                        
                                        onCancelClick={() =>
                                          setFieldValue(
                                            `workHistory[${index}].workExperienceCertificate[${certificateIndex}].url`,
                                            ""
                                          )
                                        }
                                        value={
                                          values?.workHistory?.[index]
                                            ?.workExperienceCertificate?.[
                                            certificateIndex
                                          ]?.url || ""
                                        }
                                        label={`Certificate ${
                                          certificateIndex + 1
                                        }`}
                                        name={`workHistory[${index}].workExperienceCertificate[${certificateIndex}].url`}
                                        onChange={(file: any) => {
                                          setFieldValue(
                                            `workHistory[${index}].workExperienceCertificate[${certificateIndex}].url`,
                                            file
                                          );
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              )}
                              <div className="flex justify-center  items-center  border-dotted border-2 h-[43px] rounded mt-7">
                                <p
                                  className="text-link font-semibold text-base text-[16px] max-[1024px]:text-sm flex items-center cursor-pointer  "
                                  onClick={() =>
                                    push({
                                      url: "",
                                    })
                                  }
                                >
                                  <AiOutlinePlus
                                    size={18}
                                    color="#0064C4"
                                    className="mr-2"
                                  />
                                  Add More Certificate
                                </p>
                              </div>
                            </>
                          )}
                        />
                      </div>

                      <div className="mt-4">
                        <ATMTextArea
                          name={`workHistory[${index}].jobDescription`}
                          value={values?.workHistory?.[index]?.jobDescription}
                          onChange={(e) =>
                            setFieldValue(
                              `workHistory[${index}].jobDescription`,
                              e
                            )
                          }
                          label="Job Description"
                          placeholder="Enter Job Description"
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="flex justify-end my-4">
                  <p
                    className="text-link font-semibold text-base text-[16px] max-[1024px]:text-sm flex items-center cursor-pointer"
                    onClick={() =>
                      push({
                        companyName: "",
                        jobDescription: "",
                        jobTitle: "",
                        endYear: "",
                        starYear: "",
                        workExperienceCertificate: [
                          {
                            url: "",
                          },
                        ],
                      })
                    }
                  >
                    <AiOutlinePlus size={18} color="#0064C4" className="mr-2" />
                    Add Work History
                  </p>
                </div>
              </>
            )}
          />
        </div>
      </div>
      {/* Documents */}
      <div className="bg-white shadow border p-4 rounded-lg">
        <div className="border-l-[3px] border-amber-700 px-2 py-1  font font-medium text-md bg-white text-amber-700">
          Documents
        </div>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-4 mt-2">
          <MOLFileUpload
            isShowCancel
            onCancelClick={() => setFieldValue("identityProof", "")}
            value={values?.identityProof}
            label="Identity"
            name="identityProof"
            onChange={(file: any) => {
              setFieldValue("identityProof", file);
            }}
          />
          <MOLFileUpload
            isShowCancel
            onCancelClick={() => setFieldValue("PermanentAddressProof", "")}
            value={values?.PermanentAddressProof}
            label="Permanent Address Document"
            name="PermanentAddressProof"
            onChange={(file: any) => {
              setFieldValue("PermanentAddressProof", file);
            }}
          />
          <MOLFileUpload
            isShowCancel
            onCancelClick={() => setFieldValue("localAddressProof", "")}
            value={values?.localAddressProof}
            label="Local Address Document"
            name="localAddressProof"
            onChange={(file: any) => {
              setFieldValue("localAddressProof", file);
            }}
          />
          <MOLFileUpload
            isShowCancel
            onCancelClick={() => setFieldValue("passportSizePhoto", "")}
            value={values?.passportSizePhoto}
            label="Photo"
            name="passportSizePhoto"
            onChange={(file: any) => {
              setFieldValue("passportSizePhoto", file);
            }}
          />
        </div>
      </div>
      {/* Save Button */}
      <div className=" flex justify-end ">
        <ATMLoadingButton
          isLoading={isSubmitting}
          className="w-32"
          type="submit"
        >
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default ProfileDetailsForm;
