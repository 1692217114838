import { FormikProps } from "formik";
import React from "react";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import MOLLabelDropdown from "src/components/UI/molecules/MOLLabelDropdown/MOLLabelDropdown";
import { useGetProjectWiseMembers } from "src/hooks/useGetProjectWiseMembers";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<any>;
  onClose: any;
  projectId: string;
};

const StatusChangeDialog = ({ formikProps, onClose, projectId }: Props) => {
  // Redux state for login user data
  const { userData } = useSelector((state: RootState) => state?.auth);

  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  // get project wise members
  const { allProjectWiseMembers, isDataLoading } =
    useGetProjectWiseMembers(projectId);

  return (
    <MOLFormDialog
      title={"Edit Status"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      hideSavenNext
    >
      <div className="flex flex-col gap-4">
        <div className="">
          <ATMSelect
            name="assignedTo"
            label="Assignee"
            placeholder="Select Assigner"
            value={values.assignedTo}
            isLoading={isDataLoading}
            options={allProjectWiseMembers?.members?.map((ele: any) => {
              return {
                label: ele?.name,
                value: ele?._id,
              };
            })}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return (
                  <div className="capitalize flex gap-x-3">
                    {option.label}
                    {option?.value === userData?.userId && (
                      <span className="bg-green-500 py-[2px] flex items-center px-[8px] text-center rounded-full text-white text-[10px]">
                        It's you
                      </span>
                    )}
                  </div>
                );
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            onChange={(newValue) => {
              setFieldValue("assignedTo", newValue);
            }}
          />
        </div>
        <div className="">
          <MOLLabelDropdown
            name="requestedLabel"
            placeholder="Label"
            value={values.requestedLabel}
            onChange={(newValue) => {
              setFieldValue("requestedLabel", newValue);
            }}
            onBlur={handleBlur}
            id={projectId}
          />
        </div>

        {/* MDX Editor for chat */}
        <div className="z-0 rounded-md fit-height p-1">
          <MOLMarkdownEditor
            label="Comment"
            value={values.taskStatusComment}
            placeholder="Write chat or drag your files here...."
            onChange={(e) => setFieldValue("taskStatusComment", e)}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default StatusChangeDialog;
