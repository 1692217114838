/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import TeamAttendanceFilterCardWrapper from "./TeamAttendanceFilteCard/TeamAttendanceFilterCardWrapper";
import { CircularProgress, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import EditTeamAttendanceWrapper from "./Edit/EditTeamAttendanceWrapper";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

type Props = {
  isTableLoading: any;
  items: any;
  year: any;
  month: any;
};

const TeamAttendance2 = (props: Props) => {
  let { isTableLoading, items, year, month } = props;

  let itemsClone = items?.data ? [...items?.data] : [];

  const [
    isOpenTeamAttendanceFilterDialog,
    setIsOpenTeamAttendanceFilterDialog,
  ] = useState<boolean>(false);
  const [isOpenEditAttendanceDialog, setIsOpenEditAttendanceDialog] =
    useState<boolean>(false);
  const [attendanceData, setAttendanceData] = useState<any>([]);
  const [alphabeticallyMembers, setAlphabeticallyMembers] = useState<any>([])

  const [memberId, setMemberId] = useState<string>("");
  const selectedMonth = useSelector(
    (state: any) => state.teamAttendance.month.value
  );
  const selectedYear = useSelector(
    (state: any) => state.teamAttendance.year.value
  );

  let totalWorkingDays = 0; // Initialize total working days
  let totalPresentDays = 0;
  const generateTableHeaders = () => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const headers = [];

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(selectedYear, selectedMonth - 1, i);
      const dayOfWeek = currentDate.toLocaleDateString("en-US", {
        weekday: "long",
      }); // Get the full name of the day

      const isSundayColumn = isSunday(selectedYear, selectedMonth, i); // Pass year, month, and day to isSunday
      const isHoliday = items?.holidays?.find((holiday: any) => {
        const holidayDate = new Date(holiday.date);
        return (
          holidayDate.getFullYear() === selectedYear &&
          holidayDate.getMonth() === selectedMonth - 1 &&
          holidayDate.getDate() === i
        );
      });

      headers.push(
        <th
          key={i}
          className={`border border-collapse border-gray-300 px-4 py-2 ${
            isSundayColumn || isHoliday ? "bg-yellow-200" : ""
          }`}
        >
          <div> {i} </div>
          <div> ({dayOfWeek}) </div>
          {isHoliday && isHoliday.holidayName !== "Sunday" && (
            <div className="underline">{isHoliday.holidayName}</div>
          )}
        </th>
      );

      // Increment totalWorkingDays if the current date is not Sunday or a holiday
      if (!isSundayColumn && !isHoliday) {
        totalWorkingDays++;
      }
    }

    // Add other headers as needed
    headers.push(
      <th
        key="totalWorkingDays"
        className="sticky top-0 px-4 py-2 overflow-auto border border-gray-300"
      >
        Total Working Days
      </th>
    );
    headers.push(
      <th
        key="totalAbsentDays"
        className="sticky top-0 px-4 py-2 overflow-auto border border-gray-300"
      >
        Total Present Days
      </th>
    );
    headers.push(
      <th
        key="totalAbsentDays"
        className="sticky top-0 px-4 py-2 overflow-auto border border-gray-300"
      >
        Total Absent Days
      </th>
    );
    return headers;
  };

  const isSunday = (year: number, month: number, day: number) => {
    const firstDayOfMonth = new Date(year, month - 1, 1).getDay();
    return (day + firstDayOfMonth - 1) % 7 === 0;
  };

  const handleOpenClickEditAttendance = (attendance: any, memberId: string) => {
    setIsOpenEditAttendanceDialog(true);
    setAttendanceData(attendance);
    setMemberId(memberId);
  };

  useEffect(() => {
    // Sort the array of objects alphabetically based on the "name" key
    let sortedArray = itemsClone?.sort((a: any, b: any) => {
           const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setAlphabeticallyMembers(sortedArray);
    // Update the state with the sorted array
  }, [items]);

  
  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div
          className={`flex gap-2 px-2 md:p-4 py-2 border-b shadow-sm flex-col`}
        >
          <div className="flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
            {/* Filter Button */}
            <ATMLoadingButton
              variant="tonal"
              onClick={() => setIsOpenTeamAttendanceFilterDialog(true)}
            >
              <div className="flex items-center gap-2">
                <IoFilter />
                Filters
              </div>
            </ATMLoadingButton>
        
            {/* TeamAttendance Filter Dialog Wrapper */}
            <TeamAttendanceFilterCardWrapper
              open={isOpenTeamAttendanceFilterDialog}
              onClose={() => setIsOpenTeamAttendanceFilterDialog(false)}
            />
          </div>
          <div>
            <span className="py-2 my-2 font-bold text-md">
              {" "}
              Team Attendance of {month?.label} of {year?.value} year.
            </span>
            <div className="mt-4">
              {isTableLoading && (
                <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                  <CircularProgress />
                </div>
              )}

              {alphabeticallyMembers ? (
                <>
                  <div className="overflow-auto max-h-[calc(100vh-180px)] border-t border-collapse">
                    <table className="relative table-auto">
                      <thead className="sticky top-0 z-50 border border-collapse bg-gray-50">
                        <tr>
                          <th className="sticky top-0 left-0 px-4 py-2 overflow-auto bg-white">
                            Name
                          </th>
                          {generateTableHeaders()}
                        </tr>
                      </thead>
                      <tbody>
                        {alphabeticallyMembers?.map((item: any, index: any) => {
                          totalPresentDays = 0;
                          return (
                            <tr key={index}>
                              <td className="sticky left-0 z-10 px-4 py-2 bg-white border">
                                {item?.name}
                              </td>
                              {Array.from({
                                length: new Date(
                                  selectedYear,
                                  selectedMonth,
                                  0
                                ).getDate(),
                              }).map((_, i) => {
                                const attendanceForDate =
                                  item.dateWiseAttendance.find(
                                    (attendance: any) => {
                                      const startDateFormatted = `${selectedYear}-${selectedMonth
                                        .toString()
                                        .padStart(2, "0")}-${(i + 1)
                                        .toString()
                                        .padStart(2, "0")}`;
                                      return (
                                        startDateFormatted === attendance.date
                                      );
                                    }
                                  );
                                if (attendanceForDate?.attendance?.length) {
                                  ++totalPresentDays;
                                }

                                return (
                                  <td
                                    key={i}
                                    className="px-4 py-2 border border-gray-300 "
                                    onClick={() =>
                                      isAuthorized(
                                        UserModuleNameTypes.ACTION_TEAM_ATTENDANCE_EDIT
                                      ) &&
                                      handleOpenClickEditAttendance(
                                        attendanceForDate,
                                        item?._id
                                      )
                                    }
                                  >
                                    {attendanceForDate?.attendance?.length ? (
                                      <>
                                        <div className="relative flex items-center font-bold parent">
                                          <div className="text-green-400 ">
                                            P
                                          </div>
                                          <div className="px-1">
                                            {attendanceForDate?.attendance[0]
                                              ?.workingFrom === "OFFICE"
                                              ? null
                                              : "(WFH)"}{" "}
                                          </div>
                                          <div className="absolute child">
                                            {attendanceForDate?.attendance?.map(
                                              (atten: any) => {
                                                return atten?.isPunchedIn ? (
                                                  <>
                                                    <div className="flex p-2 text-sm">
                                                      <span>
                                                        {" "}
                                                        {atten?.punchInTime
                                                          ? moment(
                                                              atten?.punchInTime
                                                            ).format("hh:mm A")
                                                          : "-"}
                                                      </span>
                                                      -
                                                      <span>
                                                        {" "}
                                                        {atten?.punchOutTime
                                                          ? moment(
                                                              atten?.punchOutTime
                                                            ).format("hh:mm A")
                                                          : "-"}
                                                      </span>
                                                    </div>
                                                    <Divider />
                                                  </>
                                                ) : (
                                                  <span>Didn't punched IN</span>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <span className="font-bold text-red-400">
                                        A
                                      </span>
                                    )}
                                  </td>
                                );
                              })}
                              <td className="sticky left-0 z-10 px-4 py-2 bg-white border border-gray-300">
                                {totalWorkingDays}
                              </td>
                              <td className="sticky left-0 z-10 px-4 py-2 bg-white border border-gray-300">
                                {totalPresentDays}
                              </td>
                              <td className="sticky left-0 z-10 px-4 py-2 bg-white border border-gray-300">
                                {totalWorkingDays - totalPresentDays}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="p-2 m-5 font-bold text-slate-400 flex items-center justify-center">
                    NO DATA FOUND
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpenEditAttendanceDialog && (
        <EditTeamAttendanceWrapper
          attendanceData={attendanceData}
          memberid={memberId}
          onClose={() => setIsOpenEditAttendanceDialog(false)}
        />
      )}
    </>
  );
};

export default TeamAttendance2;
