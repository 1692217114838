import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/UserSlice";
import { RootState } from "src/redux/store";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import UserRoleListing from "./UserRoleListing";
import { useNavigate } from "react-router-dom";

import { UserRoleListTypes } from "src/models/UserRole.model";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
// import { UserModuleActionTypes, UserModuleNameTypes } from "src/models/userAccess/UserAccess.model";
import { useDeleteUserRoleMutation } from "src/services/UserAccessServices";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import AddPositionFormWrapper from "src/screens/PositionsDeparment/Add/AddPositionFormWrapper";
import { useGetAllPositionWithPaginationQuery } from "src/services/PositionServices";
import EditPositionFormWrapper from "src/screens/PositionsDeparment/Edit/EditPositionFormWrapper";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
type Props = {};

const UserRoleListingWrapper = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [positionId, setPositionId] = useState("");
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.userAccess);

  const columns: columnTypes[] = [
    {
      field: "positionName",
      headerName: "Position",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.ROLES_LIST_POSITION,
      renderCell: (row: any) => (
        <div
          onClick={() => {
            navigate("/user-access", {
              state: {
                positionId: row?._id,
                departmentName: row?.departmentName,
                departmentId: row?.departmentId,
                positionName: row?.positionName,
              },
            });
          }}
          className="text-blue-800 underline cursor-pointer"
        >
          {row?.positionName?.trim()?.replaceAll("_", " ")}
        </div>
      ),
    },
    {
      field: "departmentName",
      headerName: "Department",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.ROLES_LIST_DEPARTMENT,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: UserRoleListTypes) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-2 text-secondary-main">
                    <MdEdit className="text-lg" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditFormDialog(true);
                  setPositionId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_ROLES_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-2 font-semibold text-red-600">
                    <MdDeleteOutline className="text-lg" /> Delete
                  </div>
                ),
                onClick: () => {
                  showConfirmationDialog({
                    title: "Heads Up",
                    text: "Are you sure want to Delete this User role ?",
                    icon: "question",
                    showCancelButton: true,
                    next: (result: any) => {
                      if (result.isConfirmed) {
                        deleteUserRole(row?._id).then((res: any) => {
                          if (res?.error) {
                            showToast("error", res?.error?.data?.message);
                          } else {
                            if (res?.data?.status) {
                              showToast("success", res?.data?.message);
                            } else {
                              showToast("error", res?.data?.message);
                            }
                          }
                        });
                      }
                    },
                  });
                },
                acitonName: UserModuleNameTypes.ACTION_ROLES_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  const { data, isLoading, isFetching } = useGetAllPositionWithPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["positionName", "departmentId", "departmentName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  const [deleteUserRole] = useDeleteUserRoleMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem));
      dispatch(setRowsPerPage(data?.pageSize || 0));
      dispatch(setPage(data?.currentPage || 0));
    } else {
      dispatch(setIsTableLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <div>
      <UserRoleListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
        onAddNew={() => {
          setIsOpenAddFormDialog(true);
        }}
      />
      {isOpenAddFormDialog ? (
        <AddPositionFormWrapper onClose={() => setIsOpenAddFormDialog(false)} />
      ) : null}

      {isOpenEditFormDialog ? (
        <EditPositionFormWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedPositionId={positionId}
        />
      ) : null}
    </div>
  );
};

export default UserRoleListingWrapper;
