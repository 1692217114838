import React, { useState, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/ChangeRequestSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { ChangeRequestListResponse } from "src/models/ChangeRequest.model";
import {
  useGetChangeRequestQuery,
  useDeleteChangeRequestMutation,
  useRaiseChangeRequestETAApprovalMutation,
} from "src/services/ChangeRequestServices";
import ChangeRequestList from "./ChangeRequestList";
import { MdApproval, MdDateRange, MdDelete, MdEdit } from "react-icons/md";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import PrepareChangeRequestFormWrapper from "./PrepareChangeRequestForm/PrepareChangeRequestFormWrapper";
// import TicketData from "./ChangeRequestApproval/TicketData/TicketData";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ETAStatusListWrapper from "./ETAStatusList/ETAStatusListWrapper";

type OptionType = {
  label: React.ReactNode;
  onClick: () => void;
};

type Props = {};

const ChangeRequestListWrapper = (props: Props) => {
  const [isOpenAddDialog, setIsOpenAddDialog] = useState<boolean>(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [isOpenETAStatusDialog, setIsOpenETAStatusDialog] =
    useState<boolean>(false);
  const [isOpenEstDeliveryDateDialog, setIsOpenEstDeliveryDateDialog] =
    useState<boolean>(false);
  const [selectedPrepareChangeRequest, setSelectedPrepareChangeRequest] =
    useState<boolean>(false);
  const [selectedChangeRequestId, setSelectedChangeRequestId] =
    useState<string>("");

  const location = useLocation();
  const currentPath = location.pathname;

  // Check if the current path starts with "/client"
  const isClientRoute = currentPath.startsWith("/client");
  const { clientId } = useParams();
  const [ticketNumber, setShowTicketNumber] = useState<boolean>(false);
  const [ticketNumberData, setShowTicketNumberData] = useState<string>();
  const [ticketMessageData, setShowTicketMeesageData] = useState<string>();

  // Redux State
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.changeRequest);

  // Initiate Methods
  const dispatch = useDispatch<AppDispatch>();

  // Dynamic import
  const AddChangeRequestFormWrapper = React.lazy(
    () => import("../Add/AddChangeRequestFormWrapper")
  );
  const EditChangeRequestFormWrapper = React.lazy(
    () => import("../Edit/EditChangeRequestFormWrapper")
  );
  const EstimatedDeliveryDateWrapper = React.lazy(
    () => import("./EstimatedDeliveryDate/EstimatedDeliveryDateWrapper")
  );
  const TicketData = React.lazy(
    () => import("./ChangeRequestApproval/TicketData/TicketData")
  );

  const [deleteChangeRequest] = useDeleteChangeRequestMutation();
  const [raiseChangeRequestETA] = useRaiseChangeRequestETAApprovalMutation();

  const { data, isLoading, isFetching } = useGetChangeRequestQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["clientId", "requestName"],
    page: page,
    filterBy: [
      ...(isClientRoute
        ? [
            {
              fieldName: "clientId",
              value: clientId,
            },
          ]
        : []),
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleApprovalChangeRequest = (changeRequestId: string) => {
    raiseChangeRequestETA(changeRequestId).then((res: any) => {
      if (res?.error) {
        setShowTicketNumber(true);
        setShowTicketNumberData(res?.data?.data);
        setShowTicketMeesageData(res?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
        } else {
          setShowTicketNumber(true);
          setShowTicketNumberData(res?.data?.data);
          setShowTicketMeesageData(res?.data?.message);
        }
      }
    });
  };

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.CHANGE_REQUEST_LIST_CREATED_DATE,
      renderCell: (row: any) => (
        <div className="text-xs">
          <div className="text-neutral text-[10px]">
            {formatedDateTimeIntoIst(row.createdAt, "hh:mm A")}
          </div>
          <div className="font-medium">
            {formatedDateTimeIntoIst(row.createdAt, "DD MMM yyyy")}
          </div>
        </div>
      ),
    },
    {
      field: "requestName",
      headerName: "Change Request Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[180px]",
      name: UserModuleNameTypes.CHANGE_REQUEST_LIST_CHANGE_REQUEST_NAME,
    },
    {
      field: "projectName",
      headerName: "Project Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[220px]",
      name: UserModuleNameTypes.CHANGE_REQUEST_LIST_PROJECT_NAME,
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-black">{row?.projectName}</div>
          <div className="text-xs text-neutral">
            ({row?.clientOrganisationName})
          </div>
        </div>
      ),
    },
    {
      field: "totalTickets",
      headerName: "Total Tickets",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[120px]",
      name: UserModuleNameTypes.CHANGE_REQUEST_LIST_TOTAL_TICKET,
      renderCell: (row: any) => (
        <div className="items-center font-bold">{row?.tickets?.length}</div>
      ),
    },
    {
      field: "approvalStatus",
      headerName: "Approval Status",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.CHANGE_REQUEST_LIST_APPROVAL_STATUS,
      renderCell: (row: any) => {
        let label = row?.approvalStatus;
        if (label === "APPROVED") {
          label = "Approved";
        } else if (label === "NOT_RAISED") {
          label = "Not Raised";
        }

        return (
          <div
            className={`items-center capitalize font-semibold ${
              row?.approvalStatus === "APPROVED"
                ? "text-green-700"
                : row?.approvalStatus === "NOT_RAISED"
                  ? "text-red-700"
                  : "text-black"
            }`}
          >
            {label}
          </div>
        );
      },
    },
    {
      field: "numberOfDeveloper",
      headerName: "No. of Developers",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.CHANGE_REQUEST_LIST_NO_OF_DEVELOPER,
      renderCell: (row: any) => (
        <div className="items-center font-bold">{row?.numberOfDeveloper}</div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: ChangeRequestListResponse) => (
        <div>
          <ATMActionMenu
            options={
              [
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdEdit color="#000000" />
                      ETA Status
                    </div>
                  ),
                  onClick: () => {
                    setIsOpenETAStatusDialog(true);
                    setSelectedChangeRequestId(row?._id);
                  },
                },
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdEdit color="#000000" /> Prepare Change Request
                    </div>
                  ),
                  onClick: () => {
                    setSelectedPrepareChangeRequest(true);
                    setSelectedChangeRequestId(row?._id);
                  },
                  acitonName:
                    UserModuleNameTypes.ACTION_CHANGE_REQUEST_PREPARE_CHANGE_REQUEST,
                },
                {
                  label:
                    row?.approvalStatus === "NOT_RAISED" ? (
                      <div className="flex items-center gap-x-2">
                        <MdApproval color="#000000" /> Request Approval
                      </div>
                    ) : (
                      <div className="flex items-center gap-x-2">
                        <MdDateRange color="#000000" /> Send Estimated Delivery
                        Date
                      </div>
                    ),
                  onClick: () => {
                    if (row?.approvalStatus === "NOT_RAISED") {
                      setSelectedChangeRequestId(row?._id);
                      showConfirmationDialog({
                        title: "Hands Up",
                        text: "Are you sure want to raise this Change Request?",
                        icon: "question",
                        showCancelButton: true,
                        next: (result) => {
                          if (result?.isConfirmed) {
                            handleApprovalChangeRequest(row?._id);
                          }
                        },
                      });
                    } else {
                      setIsOpenEstDeliveryDateDialog(true);
                      setSelectedChangeRequestId(row?._id);
                    }
                  },
                  acitonName:
                    UserModuleNameTypes.ACTION_CHANGE_REQUEST_REQUEST_APPROVAL,
                },
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdEdit color="#000000" /> Edit
                    </div>
                  ),
                  onClick: () => {
                    setIsOpenEditDialog(true);
                    setSelectedChangeRequestId(row?._id);
                  },
                  acitonName: UserModuleNameTypes.ACTION_CHANGE_REQUEST_EDIT,
                },
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdDelete color="#000000" /> Delete
                    </div>
                  ),
                  onClick: () => {
                    showConfirmationDialog({
                      title: "Hands Up",
                      text: "Are you sure want to delete this Change Request?",
                      icon: "question",
                      showCancelButton: true,
                      next: (result) => {
                        if (result?.isConfirmed) {
                          deleteChangeRequest(row?._id).then((res: any) => {
                            if (res.error) {
                              showToast("error", res?.error?.data?.message);
                            } else {
                              showToast("success", res?.data?.message);
                            }
                          });
                        }
                      },
                    });
                  },
                  acitonName: UserModuleNameTypes.ACTION_CHANGE_REQUEST_DELETE,
                },
              ] as unknown as OptionType[] // Explicitly cast the array to OptionType[]
            }
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ChangeRequestList
        columns={columns}
        rows={items}
        isClientRoute={isClientRoute}
        addNew={() => setIsOpenAddDialog(true)}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      {/* Prepare Change Request Form */}
      {selectedPrepareChangeRequest && (
        <PrepareChangeRequestFormWrapper
          selectedChangeRequestId={selectedChangeRequestId}
          onClose={() => {
            setSelectedPrepareChangeRequest(false);
          }}
        />
      )}

      {/* Add Form */}
      <Suspense fallback={<CircularProgress />}>
        {isOpenAddDialog ? (
          <AddChangeRequestFormWrapper
            onClose={() => setIsOpenAddDialog(false)}
          />
        ) : null}
      </Suspense>

      {/* Edit Form */}
      <Suspense fallback={<CircularProgress />}>
        {isOpenEditDialog ? (
          <EditChangeRequestFormWrapper
            onClose={() => setIsOpenEditDialog(false)}
            selectedChangeRequestId={selectedChangeRequestId}
          />
        ) : null}
      </Suspense>

      {/* Estimation Delivery Date Form */}
      <Suspense fallback={<CircularProgress />}>
        {isOpenEstDeliveryDateDialog ? (
          <EstimatedDeliveryDateWrapper
            onClose={() => setIsOpenEstDeliveryDateDialog(false)}
            selectedChangeRequestId={selectedChangeRequestId}
          />
        ) : null}
      </Suspense>

      <Suspense fallback={<CircularProgress />}>
        {ticketNumber && (
          <TicketData
            ticketNumberData={ticketNumberData}
            ticketMessageData={ticketMessageData}
            onClose={() => setShowTicketNumber(false)}
          />
        )}
      </Suspense>
      {isOpenETAStatusDialog && (
        <ETAStatusListWrapper
          onClose={() => setIsOpenETAStatusDialog(false)}
          changeRequestId={selectedChangeRequestId}
        />
      )}
    </React.Fragment>
  );
};

export default ChangeRequestListWrapper;
