import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';

type Props = {
  data: any;
};

const timelineData = [
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
];

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>To Convert Hour to minutes<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//

const getMinutes = (time: string) => {
  const [hour, minute] = time.split(":");

  const minutes = Number(hour) * 60 + Number(minute);

  return minutes;
};

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>To Show Data of their Data Type <<<<<<<<<<<<<<<<<<<<<<<<<<<<<//

const getTimeLineStatus = (el: any) => {
  switch (el?.action) {
    case "MEETING":
      return {
        ClassName:
          "text-yellow-500 bg-yellow-100 border-yellow-500 w-[96%] ml-2 hover:bg-yellow-100",
        text: el?.meetingTitle ? el?.meetingTitle : "Meeting",
        status: "Meeting",
        duration: el?.duration,
      };
    case "BREAK":
      return {
        ClassName:
          "text-[#0243D2] border-[#00F] bg-[#B5D2FF] w-[98%] h-[20px] ",
        text: "Break",
        status: "Break",
        duration: el?.duration,
      };

    case "BENCH":
      return {
        ClassName:
          "text-red-500 bg-red-100 border-red-500 w-[98%] hover:bg-red-100",
        text: "Bench",
        status: "Bench",
        duration: el?.duration,
      };
    case "IDLE":
      return {
        ClassName:
          "text-slate-500 border-slate-500 bg-slate-100 w-[98%] hover:bg-slate-100",
        text: "Idle",
        status: "Idle",
        duration: el?.duration,
      };
    case "PUNCH":
      return {
        ClassName:
          "text-lime-500 border-lime-500 bg-lime-100 w-[98%]  h-[10px] hover:bg-lime-100",
        text: "Punch",
        status: "Punch",
        duration: 2,
      };
    case "TASK":
      return {
        ClassName:
          "text-green-500 border-green-500 bg-green-100 w-[98%] hover:bg-green-100",
        text: el?.taskTitle,
        status: el?.taskStatus,
        duration: el?.duration,
      };
  }
};

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> To Get Closes Hour of Start Time <<<<<<<<<<<<<<<<<<<<<<<<<<<<<//

const getClosestHour = (startTime: any) => {
  const [time, period] = startTime?.split(" ");
  const [hours] = time?.split(":");
  const closestHour = `${hours.padStart(2, "0")}:00 ${period}`;

  return closestHour;
};

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>ReArrange the Time Line According to their Start Time <<<<<<<<<<<<<<<<<<<<<<<<<<<<<//

const rearrangeTimeline = ({ timelineData, closestHour }: any) => {
  const startIndex = timelineData?.indexOf(closestHour);
  if (startIndex === -1) {
    console.error("Closest hour not found in timeline data.");
    return timelineData;
  }
  return [
    ...timelineData?.slice(startIndex),
    ...timelineData?.slice(0, startIndex),
  ];
};

const UserTimeLineCalendar = ({ data }: Props) => {
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  To Get Difference between start Time and Current Time<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//
 
  // Parse the time string
  const time = moment(
    getClosestHour(
      moment(data?.getTodayActivity?.[0]?.logStartDateTime).format("hh:mm A")
    ),
    "hh:mm A"
  );
  // Extract hours and minutes
  const hours = time.hours();
  const minutes = time.minutes();

  // Calculate total minutes since midnight
  const totalMinutes = hours * 60 + minutes;

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  To Get Total Office Hours in Minutes<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//

  const getOfficeHours = () => {
    let previousTime = 0;
    data?.getTodayActivity
      ?.filter((el: any) => el?.logFor === "PUNCH")
      ?.forEach((ele: any) => {
        if (ele?.logEndDateTime !== "") {
          previousTime += ele?.durationInMinutes;
        } else {
          let startTime = moment().diff(
            moment(ele?.logStartDateTime),
            "minutes"
          );

          previousTime += startTime;
        }
      });

    return previousTime;
  };

  const [calculateTime, setCalculateTime] = useState<any>(0);

  useEffect(() => {
    setCalculateTime(getOfficeHours());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setInterval(() => {
      setCalculateTime((prev: number) => prev + 1);
    }, 60000);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  Formate the Log Data According to Time Line<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//

  const updatedTimeLineData = data?.getTodayActivity?.map((el: any) => {
    return {
      action: el?.logFor,
      project: el?.projectName,
      startTime: el?.logStartDateTime,
      endTime: el?.logEndDateTime,
      taskTitle: el?.taskTitle,
      taskStatus: el?.taskStatus,
      duration: el?.durationInMinutes,
      meetingTitle: el?.meetingTitle,
    };
  });

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses?.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex flex-1 h-full gap-4 p-2 pr-0 overflow-auto">
        {/* Time Params */}

        <div className="flex flex-col h-full">
          {rearrangeTimeline({
            timelineData: timelineData,
            closestHour: getClosestHour(
              moment(data?.getTodayActivity?.[0]?.logStartDateTime).format(
                "hh:mm A"
              )
            ),
          })?.map((time: any, ind: any) => {
            return (
              <div key={ind} className="flex items-start gap-4 min-h-[60px] ">
                <div className="p-0 m-0 text-xs leading-none">{time}</div>
              </div>
            );
          })}
        </div>

        {/* Work  */}

        <div className="relative flex flex-col flex-1 h-full">
          {rearrangeTimeline({
            timelineData: timelineData,
            closestHour: getClosestHour(
              moment(data?.getTodayActivity?.[0]?.logStartDateTime).format(
                "hh:mm A"
              )
            ),
          })?.map((timeline: any, index: any) => {
            return (
              <div
                key={index}
                className="w-full border-t border-l min-h-[60px]  "
              ></div>
            );
          })}

          {updatedTimeLineData?.map((timeline: any, ind: number) => {
            return (
              <LightTooltip 
                key={ind}
                title={`${
                  timeline?.startTime &&
                  moment(timeline?.startTime).format("hh:mm A")
                }  ${timeline?.endTime && "-"} ${
                  timeline?.endTime &&
                  moment(timeline?.endTime).format("hh:mm A")
                }${
                  timeline?.project && '|'
                } ${timeline?.project} | ${getTimeLineStatus(timeline)
                  ?.status} `}
              >
                <div
                  className={`px-2  absolute font-semibold transition-all duration-500  rounded cursor-pointer text-xs ${getTimeLineStatus(
                    timeline
                  )?.ClassName}`}
                  style={{
                    top:
                      getMinutes(moment(timeline?.startTime).format("HH:mm")) -
                      totalMinutes,
                    height: timeline?.endTime
                      ? getTimeLineStatus(timeline)?.duration
                      : getMinutes(moment().format("HH:mm")) -
                        getMinutes(moment(timeline?.startTime).format("HH:mm")),
                  }}
                
                >
                  {getTimeLineStatus(timeline)?.duration > 10 &&
                    getTimeLineStatus(timeline)?.text}
                </div>
              </LightTooltip>
            );
          })}
        </div>
      </div>

      <div className="grid w-full md:grid-cols-4  sm:grid-cols-3 xs:grid-cols-2  gap-2 p-2 bg-white border-t">
        <div className="flex flex-col gap-1 p-2 text-sm border rounded-md custom-shadow">
          <p className="text-xs text-neutral">Office Hours</p>
          <div className="font-medium text-sky-600">
            <>
              {Math.floor(calculateTime / 60)} hrs : {calculateTime % 60} min{" "}
            </>
          </div>
        </div>

        <div className="flex flex-col gap-1 p-2 text-sm border rounded-md custom-shadow">
          <p className="text-xs text-neutral">Working Hours</p>
          <div className="font-medium text-green-700">
            {Math.floor(
              (data?.totalInProgressTaskTime +
                data?.totalBenchTime +
                data?.totalMeetingTime) /
                60
            )}{" "}
            hrs :{" "}
            {(data?.totalInProgressTaskTime +
              data?.totalBenchTime +
              data?.totalMeetingTime) %
              60}{" "}
            min{" "}
          </div>
        </div>

        <div className="flex flex-col gap-1 p-2 text-sm border rounded-md custom-shadow">
          <p className="text-xs text-neutral">Task Time</p>
          <div className="font-medium text-emerald-500">
            {Math.floor(data?.totalInProgressTaskTime / 60)} hrs :{" "}
            {data?.totalInProgressTaskTime % 60} min{" "}
          </div>
        </div>

        <div className="flex flex-col gap-1 p-2 text-sm border rounded-md custom-shadow">
          <p className="text-xs text-neutral">In Meeting</p>
          <div className="font-medium text-yellow-500">
            {Math.floor(data?.totalMeetingTime / 60)} hrs :{" "}
            {data?.totalMeetingTime % 60} min{" "}
          </div>
        </div>
        <div className="flex flex-col gap-1 p-2 text-sm border rounded-md custom-shadow">
          <p className="text-xs text-neutral">On Bench</p>
          <div className="font-medium text-red-500">
            {Math.floor(data?.totalBenchTime / 60)} hrs :{" "}
            {data?.totalBenchTime % 60} min{" "}
          </div>
        </div>
        <div className="flex flex-col gap-1 p-2 text-sm border rounded-md custom-shadow">
          <p className="text-xs text-neutral">On Break</p>
          <div className="font-medium text-[#0000FF]">
            {Math.floor(data?.totalBreakTime / 60)} hrs :{" "}
            {data?.totalBreakTime % 60} min{" "}
          </div>
        </div>

        <div className="flex flex-col gap-1 p-2 text-sm border rounded-md custom-shadow">
          <p className="text-xs text-neutral">Idle</p>
          <div className="font-medium text-slate-500">
            {Math.floor(data?.totalIdleTime / 60)} hrs :{" "}
            {data?.totalIdleTime % 60} min{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTimeLineCalendar;
