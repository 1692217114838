import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsOpenEditFormDialog,
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSelectedClient,
  setTotalItems,
} from "src/redux/slices/DomainSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useGetDomainPaginationQuery,
  useChangeStatusDomainMutation,
} from "src/services/DomainServices";
import EditDomainFormWrapper from "../Edit/EditDomainFormWrapper";
import DomainList from "./DomainList";
import { MdBlock } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { DomainListResponse } from "src/models/Domain.model";
import { showToast } from "src/utils/showToaster";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

const DomainListingWrapper = (props: Props) => {
  const [selectedDomainId, setSelectedDomainId] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    isOpenEditFormDialog,
    // selectedClient,
  } = useSelector((state: RootState) => state.domain);

  const [activeUpdateDomain] = useChangeStatusDomainMutation();

  const { data, isLoading, isFetching } = useGetDomainPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["domainName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Clients Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleStatusChangeDomain = (id: string) => {
    activeUpdateDomain(id)
      .then((res: any) => {
        showToast("success", "status change successfully");
      })
      .catch((err: any) => {
        console.error("error", err);
      });
  };

  const columns: columnTypes[] = [
    {
      field: "domainName",
      headerName: "Domain Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize",
      name: UserModuleNameTypes.DOMAIN_LIST_DOMAIN_NAME,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: DomainListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  dispatch(setIsOpenEditFormDialog(true));
                  dispatch(setSelectedClient(row));
                  setSelectedDomainId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_DOMAIN_EDIT,
              },
              {
                label: row.isActive ? (
                  <div className="flex items-center gap-x-2">
                    <MdBlock color="red" /> Deactivate
                  </div>
                ) : (
                  <div className="flex items-center gap-x-2">
                    <VscActivateBreakpoints color="#00a67e" /> Activate
                  </div>
                ),
                onClick: () => {
                  handleStatusChangeDomain(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_DOMAIN_DEACTIVE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <DomainList
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      {isOpenEditFormDialog ? (
        <EditDomainFormWrapper
          onClose={() => dispatch(setIsOpenEditFormDialog(false))}
          selecetedDomainId={selectedDomainId}
        />
      ) : null}
    </div>
  );
};

export default DomainListingWrapper;
