import React, { useEffect, useState } from "react";
import ResourceRequestList from "./ResourceRequestList";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/ResourceRequestSlice";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdDelete, MdEdit } from "react-icons/md";
import { CiViewList } from "react-icons/ci";
import {
  useGetAllResourceRequestQuery,
  useCloseResourceRequestMutation,
} from "src/services/ResourceRequestService";
import { ResourceRequestListResponse } from "src/models/ResourceRequest.model";
import moment from "moment";
import { Tooltip } from "@mui/material";
import EditResourceRequestFormWrapper from "../Edit/EditResourceRequestFormWrapper";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { GrResources } from "react-icons/gr";
import AddResourceWrapper from "../AddResource/AddResourceWrapper";
import { useNavigate } from "react-router-dom";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import ResourceRequestFilterCardWrapper from "./ResourceRequestFilterCard/ResourceRequestFilterCardWrapper";

type Props = {};

const ResourceRequestListWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [closeResourceDelete] = useCloseResourceRequestMutation();

  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    dateFilter,
  } = useSelector((state: RootState) => state.resourceRequest);
  const [selectedResourceId, setSelectedResourceId] = useState<string>("");
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [isOpenFilterCard, setIsOpenFilterCard] = useState<boolean>(false);
  const [isOpenCloseFormDialog, setIsOpenCloseFormDialog] =
    useState<boolean>(false);
  const { data, isLoading, isFetching } = useGetAllResourceRequestQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["projectId", "resourceName"],
    page: page,
    filterBy: [
      // {
      //   fieldName: "projectId",
      //   value: "6582722ce117633db9442cba",
      // },
    ],
    dateFilter: {
      dateFilterKey: "uploadedOnDate",
      startDate: dateFilter?.start_date
        ? moment(dateFilter?.start_date)?.format("yyyy-MM-DD")
        : "",
      endDate: dateFilter?.end_date
        ? moment(dateFilter?.end_date)?.format("yyyy-MM-DD")
        : "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const handleCloseResource = (row: string) => {
    showConfirmationDialog({
      title: "Heads Up",
      text: "Are you sure want to Close this Resource Request ?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          closeResourceDelete(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      name: UserModuleNameTypes.RESOURCE_REQUEST_LIST_DATE,
      renderCell: (row: any) => (
        <div className="py-1">
          <div className="text-sm font-medium text-slate-700">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="flex mt-1">
            <span className="text-xs font-medium text-slate-500">
              {moment(row?.createdAt).format("hh:mm A")}
            </span>
          </div>
        </div>
      ),
    },
    {
      field: "resourceName",
      headerName: "Resource Name",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[150x]",
      name: UserModuleNameTypes.RESOURCE_REQUEST_LIST_RESOURCE_NAME,
      renderCell: (row: ResourceRequestListResponse) => (
        <div className="capitalize"> {row?.resourceName} </div>
      ),
    },
    {
      field: "createdByName",
      headerName: "Created By",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      name: UserModuleNameTypes.RESOURCE_REQUEST_LIST_CREATED_BY,
      renderCell: (row: ResourceRequestListResponse) => (
        <div className="capitalize"> {row?.createdByName} </div>
      ),
    },
    {
      field: "projectName",
      headerName: "Project Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      name: UserModuleNameTypes.RESOURCE_REQUEST_LIST_PROJECT_NAME,
      renderCell: (row: ResourceRequestListResponse) => (
        <div className="capitalize"> {row?.projectName} </div>
      ),
    },

    {
      field: "description",
      headerName: "description",
      flex: "flex-[1.5_1.5_0%]",
      name: UserModuleNameTypes.RESOURCE_REQUEST_LIST_DESCRIPTION,
      renderCell: (row: any) => (
        <Tooltip title={row?.description}>
          <div className="w-full overflow-hidden truncate">
            {row?.description || "-"}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "requestedFromName",
      headerName: "Requested From",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      name: UserModuleNameTypes.RESOURCE_REQUEST_LIST_REQUESTED_FROM,
      renderCell: (row: ResourceRequestListResponse) => (
        <div className="capitalize"> {row?.requestedFromName || "-"} </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      name: UserModuleNameTypes.RESOURCE_REQUEST_LIST_STATUS,
      renderCell: (row: ResourceRequestListResponse) => (
        <div className="capitalize"> {row?.status} </div>
      ),
    },
    {
      field: "remark",
      headerName: "Remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      name: UserModuleNameTypes.RESOURCE_REQUEST_LIST_REMARK,
      renderCell: (row: ResourceRequestListResponse) => (
        <Tooltip title={row?.remark}>
          <div className="w-full overflow-hidden truncate">
            {row?.remark || "-"}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: ResourceRequestListResponse) => {
        const menuOptions = [
          {
            label: (
              <div className="flex items-center gap-x-2">
                <CiViewList color="#000000" /> View
              </div>
            ),
            onClick: () => {
              navigate(`/resource-request/${row?._id}/view`);
            },
            acitonName: UserModuleNameTypes.ACTION_RESOURCE_REQUEST_VIEW,
          },
          {
            label: (
              <div className="flex items-center gap-x-2">
                <MdEdit color="#000000" /> Edit
              </div>
            ),
            onClick: () => {
              setIsOpenEditFormDialog(true);
              setSelectedResourceId(row?._id);
            },
            acitonName: UserModuleNameTypes.ACTION_RESOURCE_REQUEST_EDIT,
          },

          {
            label: (
              <div className="flex items-center gap-x-2">
                <GrResources color="#000000" /> Add Resource
              </div>
            ),
            onClick: () => {
              setIsOpenCloseFormDialog(true);
              setSelectedResourceId(row?._id);
              // navigate(`/resource-request/${row?._id}/add`),
            },
            acitonName:
              UserModuleNameTypes.ACTION_RESOURCE_REQUEST_ADD_RESOURCE,
          },
          {
            label: (
              <div className="flex items-center gap-x-2">
                <MdDelete color="#000000" /> Close
              </div>
            ),
            onClick: () => {
              handleCloseResource(row?._id);
            },
            acitonName: UserModuleNameTypes.ACTION_RESOURCE_REQUEST_CLOSE,
          },
        ];
        const CloseOptions = [
          {
            label: (
              <div className="flex items-center gap-x-2">
                <CiViewList color="#000000" /> View
              </div>
            ),
            onClick: () => {
              navigate(`/resource-request/${row?._id}/view`);
            },
            acitonName: UserModuleNameTypes.ACTION_RESOURCE_REQUEST_CLOSE_VIEW,
          },
          {
            label: (
              <div className="flex items-center gap-x-2">
                <MdEdit color="#000000" /> Edit
              </div>
            ),
            onClick: () => {
              setIsOpenEditFormDialog(true);
              setSelectedResourceId(row?._id);
            },
            acitonName: UserModuleNameTypes.ACTION_RESOURCE_REQUEST_CLOSE_EDIT,
          },
        ];
        return row?.status !== "CLOSED" ? (
          <ATMActionMenu options={menuOptions} />
        ) : (
          <ATMActionMenu options={CloseOptions} />
        );
      },
    },
  ];

  return (
    <div>
      <ResourceRequestList
        columns={columns}
        openFilterCard={() => {
          setIsOpenFilterCard(true);
        }}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenEditFormDialog ? (
        <EditResourceRequestFormWrapper
          resourceId={selectedResourceId}
          onClose={() => setIsOpenEditFormDialog(false)}
        />
      ) : null}

      {isOpenCloseFormDialog ? (
        <AddResourceWrapper
          resourceId={selectedResourceId}
          onClose={() => setIsOpenCloseFormDialog(false)}
        />
      ) : null}
      {isOpenFilterCard ? (
        <ResourceRequestFilterCardWrapper
        open={isOpenFilterCard}
          onClose={() => setIsOpenFilterCard(false)}
        />
      ) : null}
    </div>
  );
};

export default ResourceRequestListWrapper;
