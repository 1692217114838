import moment from "moment";
import { FaArrowLeft } from "react-icons/fa";
import { LiaComments } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import AddCommentFormWrapper from "./AddCommentForm/AddCommentFormWrapper";

function formatCreatedAt(date: any) {
  const now = moment();
  const createdDate = moment(date);
  const diffInMinutes = now.diff(createdDate, "minutes");

  if (diffInMinutes < 1) {
    return "Just now";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? "minute" : "minutes"} ago`;
  } else if (diffInMinutes < 1440) {
    // 60 minutes * 24 hours = 1440 minutes
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
  } else if (diffInMinutes < 43200) {
    // 60 minutes * 24 hours * 30 days = 43200 minutes
    const diffInDays = Math.floor(diffInMinutes / 1440);
    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  } else {
    // If more than 30 days, you might want to show the actual date or a different format
    return createdDate.format("MMMM Do YYYY, h:mm:ss a");
  }
}

type Props = {
  doubtsLogListing: any[];
  doubtData: any
};

const SingleServiceTicketView = ({
  doubtsLogListing,
  doubtData
}: Props) => {

  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full">
      <div className="md:flex block md:px-4 px-2 py-2 items-center justify-between border-b">
        {/* back arrow, open, added by, assigned to */}
        <div className="md:flex block items-center md:gap-4 gap-2">
          <div className="flex justify-between items-center md:gap-4 gap-2">
            <p
              className="text-end flex items-center cursor-pointer"
              onClick={() => navigate('/service-ticket')}
            >
              <FaArrowLeft />
            </p>
            <p className="capitalize"> {doubtData?.problemName}</p>
          </div>

        </div>
      </div>

      <div className="flex flex-col border-b shadow-md overflow-hidden h-full z-0">
        <div className="flex flex-col h-full overflow-hidden">
          {/* Comment */}
          <div className="flex items-center gap-2 px-4 py-2 border-y">
            <LiaComments className="text-base" />
            <div className="text-primary-main flex text-sm items-center gap-2 font-semibold">
              <span>Comments</span>
              <span>
                ({doubtsLogListing?.length ? doubtsLogListing?.length : "0"})
              </span>
            </div>
          </div>
          <div className="bg-gray-50 grow overflow-scroll">
            <div className="flex w-full h-full">
              {/* description div */}
              <div className="w-full gap-2 rounded-md p-2 px-4 relative">
                {doubtsLogListing?.length ? (
                  <div className="flex flex-col gap-y-2 max-h-[550px] rounded-md">

                    {/* <!-- Comments Section --> */}
                    {doubtsLogListing?.map((ele) => {
                      return (
                        <div
                          className=" last:border-none border-b py-4"
                          key={ele?._id}
                        >
                          <div className="flex space-x-4">
                            <div>
                              <h2 className="text-gray-400 text-sm ">
                                {ele?.commentedByName} add comment on{" "}
                                {formatCreatedAt(ele?.createdAt)}
                              </h2>
                              <p className="text-gray-600 font-semibold text-md">
                                <ATMMarkdownContainer
                                  markdown={ele?.message || ""}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex justify-center items-center text-slate-400 mt-4">
                    No comment found in this doubt
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="fit-height p-2">
          <AddCommentFormWrapper />
        </div>
      </div>
    </div>
  );
};

export default SingleServiceTicketView;
