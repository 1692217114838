import React, { useEffect, useState } from "react";
import ContactLeadListing from "./ContactLeadListing";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setTotalItems,
  setItems
} from "src/redux/slices/ContactLeadSlice";
import AddContactLeadFormWrapper from "../Add/AddContactLeadFormWrapper";
import { useGetAllContactsLeadsQuery } from "src/services/ContactService";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import moment from "moment";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdEdit } from "react-icons/md";
import EditContactLeadFormWrapper from "../Edit/EditContactLeadFormWrapper";

type Props = {};

const ContactLeadListingWrapper = (props: Props) => {
  const dispatch = useDispatch();

  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  const [isOpenEditDialog , setIsOpenEditDialog] = useState(false)
  const [selectedId , setSelectedId] = useState('')

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.contactLead);

    const { data, isLoading, isFetching } = useGetAllContactsLeadsQuery({
        limit: rowsPerPage,
        searchValue: searchValue,
        params: ["organizationName"],
        page: page,
        filterBy: [],
        dateFilter: {},
        orderBy: "createdAt",
        orderByValue: -1,
        isPaginationRequired: true,
      }); 
    
      // Set All contact Data
      useEffect(() => {
        if (isLoading || isFetching) {
          dispatch(setIsTableLoading(true));
        } else {
          dispatch(setItems(data?.data || []));
          dispatch(setIsTableLoading(false));
          dispatch(setTotalItems(data?.totalItem || 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Date-Time",
      flex: "flex-[1_1_0%]" ,
      name: UserModuleNameTypes.NAV_CONTACT,
      renderCell: (row: any) => (
        <div>
          <div className="text-[13px] text-slate-700 font-medium">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="text-[12px] text-slate-500  text-xs font-medium">
            {moment(row?.createdAt).format("hh:mm A")}
          </div>
        </div>
      ),
    },
    { field: "organizationName", headerName: "Organization Name", flex: "flex-[1_1_0%]" },
    { field: "createdByName", headerName: "Create By", flex: "flex-[1_1_0%]" },
    { field: "countryName", headerName: "Country", flex: "flex-[1_1_0%]" },
    { field: "domainName", headerName: "Domain", flex: "flex-[1_1_0%]" },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditDialog(true)
                  setSelectedId(row?._id)
                },
              },
              // {
              //   label: (
              //     <div className="flex items-center gap-x-2 text-[#991b1b] font-medium">
              //       <MdDelete color="#991b1b" /> Delete
              //     </div>
              //   ),
              //   onClick: () => {
              //     handleDelete(row?._id);
              //   },
              //   // acitonName: UserModuleNameTypes.ACTION_GOAL_DELETE,
              // },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <ContactLeadListing
        columns={columns}
        rows={items}
        onAddNew={() => {
          setIsOpenAddDialog(true);
        }}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenAddDialog && (
        <AddContactLeadFormWrapper onClose={() => setIsOpenAddDialog(false)} />
      )}
      {isOpenEditDialog && (
        <EditContactLeadFormWrapper onClose={()=>setIsOpenEditDialog(false) } leadId={selectedId} />
      )}
    </div>
  );
};

export default ContactLeadListingWrapper;
