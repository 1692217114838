import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { LeadLabelFormValues } from "src/models/LeadLabel.model";
import SocialMediaForm from "../Layouts/SocialMediaForm";
import { useAddLeadLabelMutation } from "src/services/LeadLabelService";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: () => void;
};

const AddLeadLabelFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addSocialMedia] = useAddLeadLabelMutation();

  // Form Initial Values
  const initialValues: LeadLabelFormValues = {
    leadLabelName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    leadLabelName: string().required("Please enter lead label name"),
  });

  // Handle Submit
  const handleSubmit = (
    values: LeadLabelFormValues,
    { setSubmitting, resetForm }: FormikHelpers<LeadLabelFormValues>
  ) => {
    addSocialMedia(values).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <SocialMediaForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddLeadLabelFormWrapper;
