export type CurrencyFormValues = {
  currencyName: string;
  currencySymbol: string;
};

export type ResourceRequestListResponse = {
  projectName: string;
  resourceName: string;
  description: string;
  remark: string;
  createdById: string;
  createdByName: string;
  requestedFromId: string;
  requestedFromName: string;
  requestedFromType: string;
  status: string;
  isDeleted: boolean;
  isActive: boolean;
  _id: string;
  attachments: [];
  createdAt: string;
  updatedAt: string;
  __v: 0;
};

export type ResourceRequestFormValues = {
  projectId: any;
  resourceName: string;
  description: string;
  requestedFromId: any;
  requestedFromType: any;
  sourceId?: any
  sourceName?: any
};

export type CloseResourceRequestFormValues = {
  remark: any;
  attachments: any;
};

export enum FILE_FORMAT {
  pdfs = "DOCUMENT",
  videos = "VIDEO",
  images = "IMAGE",
  xlsx = "DOCUMENT",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  PDF = "PDF",
  XLSX = "XLSX",
}