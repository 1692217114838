import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { PayoutFormProps } from "src/models/Payout.model";
import { useGetPayoutByIdQuery, usePayoutUpdateMutation } from "src/services/PayoutService";
import { showToast } from "src/utils/showToaster";
import { array, object, string } from "yup";
import JobCreationFormLayout from "../Layout/PayoutLayout";

type Props = {
  payoutId?: any,
  onClose: any
};

const EditPayoutFormWrapper = ({ onClose, payoutId }: Props) => {

  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetPayoutByIdQuery(payoutId, { skip: !payoutId });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data || {});
    }
  }, [isLoading, isFetching, data]);

  const [updatePayout] = usePayoutUpdateMutation();

  const initialValues: PayoutFormProps = {
    teamMemberId: items?.teamMemberId ? {
      label: items?.teamMemberName,
      value: items?.teamMemberId
    } : '',
    year: items?.year,
    month: items?.month ? moment(items?.month, 'MMMM') : moment(),
    totalWorkingDays: items?.totalWorkingDays,
    daysOfAbsent: items?.daysOfAbsent,
    netAmount: items?.netAmount,
    earning: items?.earnings?.length
      ? items?.earnings?.map((el: any) => {
        return {
          labelName: el?.labelName,
          value: el?.value,
        };
      })
      : [
        {
          labelName: '',
          value: '',
        }
      ],
    deducation:
      items?.deductions?.length
        ? items?.deductions?.map((el: any) => {
          return {
            labelName: el?.labelName,
            value: el?.value,
          };
        })
        : [
          {
            labelName: '',
            value: '',
          }
        ],


  };


  // Validation Schema
  const validationSchema = object({
    teamMemberId: object().required('Please select team member'),
    year: string().required(' Required'),
    month: string().required('Required'),
    totalWorkingDays: string().required('Required'),
    daysOfAbsent: string().required('Please total absent day Required'),
    netAmount: string().required('Required'),
    earning: array().of(
      object({
        labelName: string().required('Required'),
        value: string().required('Required'),
      })
    ),
    deducation: array().of(
      object({
        labelName: string().required('Required'),
        value: string().required('Required'),
      })
    ),
  });

  // Handle Submit
  const handleSubmit = (
    values: PayoutFormProps,
    { setSubmitting, resetForm }: FormikHelpers<PayoutFormProps>
  ) => {
    const formattedValues = {
      teamMemberId: values?.teamMemberId?.value,
      year: values?.year ? moment(values?.year).format('YYYY') : '',
      month: values?.month ? moment(values?.month).format('MMMM') : '',
      totalWorkingDays: values?.totalWorkingDays,
      daysOfAbsent: values?.daysOfAbsent,
      netAmount: values?.netAmount,
      earnings: values?.earning?.map((el: any) => el),
      deductions: values?.deducation?.map((el: any) => el),
    };
    updatePayout({ body: formattedValues, id: payoutId }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          onClose()
          // navigate("/job-onboard/job-creation/");
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <JobCreationFormLayout
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditPayoutFormWrapper;
