import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/TeamTransationHistorySlice";
import TeamDetails from "./TeamDetails";

import { IoMdDownload } from "react-icons/io";
import { MdDone } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { RootState } from "src/redux/store";
import {
  useGetSingleTeamMemberTransactionsQuery,
  useUpdateMemeberMarkAsPaidStatusMutation,
} from "src/services/TeamServices";
import { formatedDateTimeIntoIst } from "src/utils/formUtils/dateTimeFormate";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";

const TeamDetailsWrapper = () => {
  const { teamMemberId } = useParams();
  const dispatch = useDispatch();

  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.teamTransationHistory);
  const { data, isLoading, isFetching } =
    useGetSingleTeamMemberTransactionsQuery({
      limit: rowsPerPage,
      searchValue: searchValue,
      params: ["status"],
      page: page,
      filterBy: [
        {
          fieldName: "teamMemberId",
          value: [teamMemberId],
        },
      ],
      dateFilter: {
        startDate: "",
        endDate: "",
      },
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });

  const [updateMarkAsPaidStatus] = useUpdateMemeberMarkAsPaidStatusMutation();

  // Setting Project Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const getActionOptions = (row: any) => {
    const OtherMenuOptions = [
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdDone color="#000000" /> Mark As Paid
          </div>
        ),
        onClick: () => {
          showConfirmationDialog({
            title: "Heads Up",
            text: "Are you sure, you want to mark as paid?",
            icon: "question",
            showCancelButton: true,
            next: (result) => {
              if (result?.isConfirmed) {
                updateMarkAsPaidStatus(row?._id).then((res: any) => {
                  if (res.error) {
                    showToast("error", res?.error?.data?.message);
                  } else {
                    if (res?.data?.status) {
                      showToast("success", res?.data?.message);
                    } else {
                      showToast("error", res?.data?.message);
                    }
                  }
                });
              }
            },
          });
        },
        // acitonName: UserModuleNameTypes.ACTION_LEAVE_REQUEST_DELETE,
      },
      {
        label: (
          <div className="flex items-center gap-x-2">
            <IoMdDownload color="#000000" /> Download Salary Slip
          </div>
        ),
        onClick: () => {
          //   setIsShowEditDialog(true);
          //   setSelectedLeaveReqId(leaveData?._id);
          //   setSelectedLeaveType(leaveData?.daysOfLeave);
        },
        // acitonName: UserModuleNameTypes.ACTION_LEAVE_REQUEST_EDIT,
      },
    ];
    const menuOptions = [
      {
        label: (
          <div className="flex items-center gap-x-2">
            <IoMdDownload color="#000000" /> Download Salary Slip
          </div>
        ),
        onClick: () => {
          //   setIsShowEditDialog(true);
          //   setSelectedLeaveReqId(leaveData?._id);
          //   setSelectedLeaveType(leaveData?.daysOfLeave);
        },
        // acitonName: UserModuleNameTypes.ACTION_LEAVE_REQUEST_EDIT,
      },
    ];
    return row?.status === "PENDING" ? OtherMenuOptions : menuOptions;
  };
  const columns: columnTypes[] = [
    {
      field: "name",
      headerName: "Payroll Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {row.payrollDate
              ? formatedDateTimeIntoIst(row.payrollDate, "DD MMM yyyy")
              : "-"}
          </div>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Transfer Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "capitalize min-w-[150px]",
      renderCell: (row: any) => (
        <div>
          <div className="font-medium text-slate-700">
            {row.transferDate
              ? formatedDateTimeIntoIst(row.transferDate, "DD MMM yyyy")
              : "-"}
          </div>
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Total salary",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => (
        <div>{row?.totalSalary ? row?.totalSalary : "-"}</div>
      ),
    },
    {
      field: "mobile",
      headerName: "Loan Amount ",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => (
        <div>{row?.loanAmount ? row?.loanAmount : "-"}</div>
      ),
    },
    {
      field: "mobile",
      headerName: "TDS",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => <div>{row?.tds ? row?.tds : "-"}</div>,
    },
    {
      field: "mobile",
      headerName: "LOP",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => <div>{row?.lop ? row?.lop : "-"}</div>,
    },
    {
      field: "mobile",
      headerName: "Paid Amount",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => (
        <div>{row?.paidAmount ? row?.paidAmount : "-"}</div>
      ),
    },
    {
      field: "mobile",
      headerName: "Remark",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      renderCell: (row: any) => <div>{row?.remark ? row?.remark : "-"}</div>,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => {
        const options = getActionOptions(row);
        return <ATMActionMenu options={options} orientation="vertical" />;
      },
    },
  ];
  return (
    <div>
      <TeamDetails
        columns={columns}
        rows={items}
        // isClientRoute={isClientRoute}
        // onAddNew={() => navigate("/project/add")} rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default TeamDetailsWrapper;
