import React from "react";
import Dialog from "@mui/material/Dialog";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { FormikProps } from "formik";
import { FormInitialValues } from "./TaskFilterFormCardDialogWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";

// MODELS
import { CodioticUserListResponse } from "src/models/CodioticUser.model";
import { ProjectListResponse } from "src/models/Project.model";
import { ModulesListResponse } from "src/models/Module.model";
import { FeatureListResponseType } from "src/models/Feature.model";

// HOOKS
import { useGetAllCodioticUsers } from "src/hooks/useGetAllCodioticUser";
import { useGetAllLabelProject } from "src/hooks/useGetAllLabelProject";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { useGetAllModulesByProject } from "src/hooks/useGetAllModulesByProject";
import { useGetAllFeatureByModule } from "src/hooks/useGetAllFeatureByModule";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";

// TASK STATUS ENUM
const taskStatusOption = [
  {
    label: "OPEN",
    value: "OPEN",
  },
  {
    label: "TODO",
    value: "TODO",
  },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "BUGS",
    value: "BUGS",
  },
  {
    label: "BLOCKING",
    value: "BLOCKING",
  },
  {
    label: "UAT_REVIEW",
    value: "UAT_REVIEW",
  },
  {
    label: "DONE",
    value: "DONE",
  },
  {
    label: "HOLD",
    value: "HOLD",
  },
  {
    label: "ISSUE",
    value: "ISSUE",
  },
  {
    label: "COMPLETE",
    value: "COMPLETE",
  },
  {
    label: "CLOSE",
    value: "CLOSE",
  },
];

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};

const TaskFilterFormCardDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;

  // get all codiotic user hook
  const { codioticUsers, isDataLoading: isCodioticUserDataLoading } =
    useGetAllCodioticUsers();

  // get all codiotic user hook
  const { allProjectLabel, isDataLoading: isAllProjectLabelDataLoading } =
    useGetAllLabelProject();

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  // get module by project id hook
  const { modules, isDataLoading: isModulesDataLoading } =
    useGetAllModulesByProject(values?.project?.value);

  // get feature by module id hook
  const { features, isDataLoading: isFeaturesDataLoading } =
    useGetAllFeatureByModule(values?.module?.value);

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <div className="flex flex-col gap-4 px-4 py-2">
        {/* Heading & Clear Button */}
        <div className="sticky top-0 z-50 flex items-center justify-between">
          <div className="text-xl font-medium"> Filter </div>
          <button
            type="button"
            className="font-medium text-red-600 hover:text-red-400"
            onClick={onReset}
          >
            Clear Filters
          </button>
        </div>

        <div className="flex flex-col gap-4">
          {/* User */}
          <div>
            <ATMSelect
              name="codioticUser"
              label="User"
              placeholder="Select User"
              value={values.codioticUser}
              isLoading={isCodioticUserDataLoading}
              options={codioticUsers?.map((ele: CodioticUserListResponse) => {
                return {
                  label: ele?.name,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("codioticUser", newValue);
              }}
            />
          </div>

          {/* Project Label */}
          <div>
            <ATMSelect
              name="label"
              label="Label"
              placeholder="Select Label"
              value={values?.label}
              isLoading={isAllProjectLabelDataLoading}
              options={allProjectLabel?.map((ele: any) => {
                return {
                  label: ele?.labelName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("label", newValue);
              }}
            />
          </div>

          {/* Status */}
          <ATMSelect
            name="status"
            label="Status"
            placeholder="Select Status"
            value={values.status}
            options={taskStatusOption}
            onChange={(newValue) => {
              setFieldValue("status", newValue);
            }}
          />

          {/* Due Date filter */}
          <div className="w-full">
            <ATMDatePicker
              label="Due date"
              name="dueDate"
              format="DD/MM/YYYY"
              value={values.dueDate}
              onChange={(newValue) => setFieldValue("dueDate", newValue)}
            />
          </div>

          {/* Project */}
          <div>
            <ATMSelect
              name="project"
              label="Project"
              placeholder="Select Project"
              value={values.project}
              isLoading={isAllProjectsDataLoading}
              options={allProjects?.map((ele: ProjectListResponse) => {
                return {
                  label: ele?.projectName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("project", newValue);
              }}
            />
          </div>

          {/* Modules by project id */}
          <div>
            <ATMSelect
              name="module"
              label="Module"
              placeholder="Select Module"
              value={values.module}
              isLoading={isModulesDataLoading}
              options={modules?.map((ele: ModulesListResponse) => {
                return {
                  label: ele?.moduleName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("module", newValue);
              }}
            />
          </div>

          {/* Feature by module id */}
          <div>
            <ATMSelect
              name="feature"
              label="Feature"
              placeholder="Select Feature"
              value={values.feature}
              isLoading={isFeaturesDataLoading}
              options={features?.map((ele: FeatureListResponseType) => {
                return {
                  label: ele?.featureName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("feature", newValue);
              }}
            />
          </div>

          {/* Archived  */}
          <div className="">
            <ATMCheckbox
              label="Archived Task"
              name="isTaskArchived"
              checked={values.isTaskArchived}
              onChange={(checked) => {
                setFieldValue("isTaskArchived", checked);
              }}
            />
          </div>

          {/* Apply & Cancel Buttons */}
          <div className="sticky bottom-0 flex flex-col gap-2 bg-white">
            <div>
              <ATMLoadingButton
                type="submit"
                className="h-[40px]"
                isLoading={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Apply
              </ATMLoadingButton>
            </div>

            <div>
              <ATMLoadingButton
                className="bg-slate-300 hover:bg-gray-200 transition-all h-[40px] border-none text-slate-700 font-medium"
                onClick={onClose}
              >
                Cancel
              </ATMLoadingButton>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TaskFilterFormCardDialog;
