import React, { useEffect, useState } from "react";
import DoubtsList from "./DoubtsList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/DoubtSlice";
import { useGetDoubtsQuery } from "src/services/DoubtsServices";
import AddDoubtsFormWrapper from "../Add/AddDoubtsFormWrapper";
import EditDoubtsFormWrapper from "../Edit/EditDoubtsFormWrapper";

type Props = {};

const DoubtsListWrapper = (props: Props) => {
  // Add Form
  const [isOpenAddFormDialog, setIsOpenAddFormDialog] = useState(false);
  // Edit Form
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);

  const [selectedDoubtId, setSelectedDoubtId] = useState<string>("");

  const dispatch = useDispatch();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    codioticUser,
    projectFilterValue,
    moduleFilterValue,
    featureFilterValue,
  } = useSelector((state: RootState) => state.doubts);

  const { data, isLoading, isFetching } = useGetDoubtsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "projectId",
      "moduleId",
      "featureId",
      "question ",
      "askToId",
      "createdById",
      "createdByName",
      "side",
      "status",
    ],
    page: page,
    filterBy: [
      {
        fieldName: "status",
        value: 'OPEN' ,
      },
      {
        fieldName: "askToId",
        value: codioticUser?.value || "",
      },
      {
        fieldName: "projectId",
        value: projectFilterValue?.value || "",
      },
      {
        fieldName: "moduleId",
        value: moduleFilterValue?.value || "",
      },
      {
        fieldName: "featureId",
        value: featureFilterValue?.value || "",
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <div>
      <DoubtsList
        // columns={columns}
        rows={items}
        addNew={() => setIsOpenAddFormDialog(true)}
        onEdit={(id: string) => {
          setIsOpenEditFormDialog(true);
          setSelectedDoubtId(id);
        }}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      {isOpenAddFormDialog ? (
        <AddDoubtsFormWrapper onClose={() => setIsOpenAddFormDialog(false)} />
      ) : null}

      {isOpenEditFormDialog ? (
        <EditDoubtsFormWrapper
          doubtId={selectedDoubtId}
          onClose={() => setIsOpenEditFormDialog(false)}
        />
      ) : null}
    </div>
  );
};

export default DoubtsListWrapper;
