import ProjectFeature from "./ProjectFeature";

type Props = {
  featureId: any;
  featureItems: any;
  featureIsLoading: any;
  singleModuleData: any;
};

const ProjectFeatureWrapper = ({
  featureId,
  featureItems,
  featureIsLoading,
  singleModuleData,
}: Props) => {
  return (
    <>
      <ProjectFeature
        singleModuleData={singleModuleData}
        features={featureItems}
        featureId={featureId}
        featureIsLoading={featureIsLoading}
      />
    </>
  );
};

export default ProjectFeatureWrapper;
