import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useAddPositionMutation } from "src/services/PositionServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import PositonForm from "../Layouts/PositionForm";

type Props = {
  onClose: () => void;
};

const AddPositionFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addGitRepo] = useAddPositionMutation();

  // Form Initial Values
  const initialValues: any = {
    repoProjects: "",
    repoName: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    repoProjects: object().required("Please select department"),
    repoName: string().required("Please enter position"),
  });

  // Handle Submit
  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    const formattedValues = {
      positionName: values?.repoName,
      departmentId: values?.repoProjects?.value,
    };

    addGitRepo(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <PositonForm
            formikProps={formikProps}
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddPositionFormWrapper;
