import apiSlice from "./ApiSlice";

type HandShakeFirebaseTokenPayload = {
  firebaseToken: string;
};

export const notificationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Notification
    handShakeFirebaseToken: builder.mutation({
      invalidatesTags: ["notification"],
      query: (body: HandShakeFirebaseTokenPayload) => {
        return {
          url: "/firebase-token/add",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { useHandShakeFirebaseTokenMutation } = notificationApi;
