import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "src/redux/store";
import { useEditTicketGroupMutation, useGetTicketGroupByIdQuery } from "src/services/TicketGroupServices";
import { useGetAllTicketQuery } from "src/services/TicketServices";
import { showToast } from "src/utils/showToaster";
import AddTicketForm from "./AddTicketForm";
import { setIsTicketTableLoading } from "src/redux/slices/TicketGroupSlice";
type Props = {
  onClose: () => void;
};

export type AddTicketFormValues = {
  groupName: string;
  tickets?: any
};

const AddTicketFormWrapper = ({ onClose }: Props) => {
  const dispatch = useDispatch()
  // Redux State
  const { ticketOptionSearchValue } =
    useSelector((state: RootState) => state.ticketGroup);

  const { id } = useParams()
  const [ticketGroupData, setTicketGroupData] = useState<any>();
  const [ticketOptions, setTicketOptions] = useState<any>()
  const [updateTicketGroup] = useEditTicketGroupMutation();

  // Ticket Query
  const { isLoading: isTicketLoading, isFetching: isTicketFetching, data: ticketData } = useGetAllTicketQuery({
    limit: 0,
    searchValue: ticketOptionSearchValue,
    params: ["title", "ticketNumber",],
    page: 1,
    filterBy: [

    ],
    dateFilter: {
      startDate: "",
      endDate: "",
    },
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (isTicketLoading || isTicketFetching) {
      dispatch(setIsTicketTableLoading(true));
    } else {
      (setTicketOptions(ticketData?.data || []));
      dispatch(setIsTicketTableLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTicketLoading, isTicketFetching, ticketData]);


  const { isLoading, isFetching, data } = useGetTicketGroupByIdQuery(id,
    {
      skip: !id,
    }
  );
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTicketGroupData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  let updatedTickets = ticketGroupData?.tickets?.length
    ? ticketGroupData.tickets.map((el: any) => el)
    : [];

  // Form Initial Values
  const initialValues: AddTicketFormValues = {
    groupName: "",
    tickets: updatedTickets
  };


  // Handle Submit
  const handleSubmit = (
    values: AddTicketFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AddTicketFormValues>
  ) => {

    const formatedValues = {
      groupName: ticketGroupData?.groupName,
      tickets: values?.tickets,
      dueDate: ''
    };

    updateTicketGroup({ id: id, body: formatedValues }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="md" fullWidth fullScreen={isMobile}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <AddTicketForm
              ticketOptions={ticketOptions}
              formType="ADD"
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddTicketFormWrapper;
