import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import IncidentTypeForm from "../Layouts/IncidentTypeForm";
import { showToast } from "src/utils/showToaster";
import { CircularProgress } from "@mui/material";
import { useGetEventTypeQuery, useUpdateEventTypeMutation } from "src/services/IncidentTypeService";

type Props = {
  onClose: () => void;
  typeId:string ;
};

export type IncidentTypeFormValues = {
  categoryName: any;
  eventType: string;    
  weightage: string;
};

const EditIncidentTypeFormWrapper = ({ onClose , typeId }: Props) => { 
  
    const [updateEventType] = useUpdateEventTypeMutation()

  const [typeData, setTypeData] = useState<any>();

  const {data , isLoading , isFetching} = useGetEventTypeQuery(typeId,{skip:!typeId})
  useEffect(() => {
    setTypeData(data?.data);
  }, [data, isLoading, isFetching]);

  // Form Initial Values
  const initialValues: IncidentTypeFormValues = {
    categoryName: {
      label :typeData?.categoryName ,
      value : typeData?.categoryId
    },
    eventType:typeData?.typeName ||'',
    weightage:typeData?.weightage  ||'',
  };

  // Validation Schema
  const validationSchema = object().shape({
    eventType: string().required("Please enter event"),
    weightage: string().required("Please enter weightage"),
  });

  // Handle Submit
  const handleSubmit = (
    values: IncidentTypeFormValues,
    { setSubmitting, resetForm }: FormikHelpers<IncidentTypeFormValues>
  ) => {
    const formattedValues ={
      typeName : values?.eventType ,
      categoryId : values?.categoryName?.value ,
      weightage : values?.weightage
    }
    updateEventType({
      id: typeId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
      enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form> 
             {(isLoading || isFetching) && (
              <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                <CircularProgress />
              </div>
            )}
            <IncidentTypeForm formikProps={formikProps} onClose={onClose} formType={'Update'} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditIncidentTypeFormWrapper;
