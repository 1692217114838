import React, { useEffect, useState } from "react";
import ServerList from "./ServerList";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/ServerSlice";
import {
  useDeleteServerByIdMutation,
  useGetAllServerWithPaginationQuery,
} from "src/services/ServerService";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { MdDelete, MdEdit } from "react-icons/md";
import EditServerFormWrapper from "../Edit/EditServerFormWrapper";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

type Props = {};

type ServerListResponse = {
  _id: string;
  serverName: string;
  projectId: string[];
  serverConfig: string;
  isDeleted: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

const ServerListWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.server);
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [ServerId, setServerId] = useState<string>("");

  const [deleteServer] = useDeleteServerByIdMutation();

  const handleDeleteServer = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Server?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteServer(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  const { data, isLoading, isFetching } = useGetAllServerWithPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "serverName",
      "serverConfig",
      "serverCredentials",
      "projectId ",
      "service",
    ],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "serverName",
      headerName: "Server Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.SERVER_LIST_SERVER_NAME,
      renderCell: (row: ServerListResponse) => (
        <div className="capitalize"> {row?.serverName} </div>
      ),
    },
    {
      field: "projectId",
      headerName: "Project",
      flex: "flex-[2_2_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.SERVER_LIST_PROJECT,
      renderCell: (row: ServerListResponse) =>
        row?.projectId?.map((project: any, index: any) => (
          <span
            key={index}
            className="p-1 m-1 text-sm rounded-md  bg-slate-300"
          >
            {project?.projectName}
          </span>
        )),
    },
    {
      field: "serverConfig",
      headerName: "Server Config",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[150px]",
      name: UserModuleNameTypes.SERVER_LIST_SERVER_CONFIG,
      renderCell: (row: ServerListResponse) => (
        <div className="capitalize"> {row?.serverConfig} </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: ServerListResponse) => (
        <div>
          <ATMActionMenu
            options={[
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdEdit color="#000000" /> Edit
                  </div>
                ),
                onClick: () => {
                  setIsOpenEditFormDialog(true);
                  setServerId(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_SERVER_EDIT,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <MdDelete color="#000000" /> Delete
                  </div>
                ),
                onClick: () => {
                  handleDeleteServer(row?._id);
                },
                acitonName: UserModuleNameTypes.ACTION_SERVER_DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <ServerList
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
      {isOpenEditFormDialog ? (
        <EditServerFormWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedServer={ServerId}
        />
      ) : null}
    </div>
  );
};

export default ServerListWrapper;
