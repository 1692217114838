import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useAddQuickMeetingMutation } from "src/services/MeetingServices";
import { showToast } from "src/utils/showToaster";
import { array, object } from "yup";
import InternalMeetingAdd from "./AddQuicklMeetingForm";

type Props = {
    onClose: () => void;
};

const AddQuicklMeetingWrapper = ({ onClose }: Props) => {

    const navigate = useNavigate()
    const [saveNextChecked, setSaveNextChecked] = useState(true);

    const [addMeeting] = useAddQuickMeetingMutation();
    // Form Initial Values
    const initialValues: any = {
        codioticUsers: [],
        meetingTypeId: "",
    };

    // Validation Schema
    const validationSchema = object().shape({
        meetingTypeId: object().required("Meeting type is required"),
        codioticUsers: array()
            .min(1, "Please select user")
            .required("Please select user"),
    });

    // Handle Submit
    const handleSubmit = (
        values: any,
        { setSubmitting }: FormikHelpers<any>
    ) => {
        const {
            codioticUsers,
            meetingTypeId,
            scheduledStartTime,
            scheduledEndTime,
            ...rest
        } = values;

        const formatedValues = {
            codioticUsers: codioticUsers?.map((ele: any) => ele?.value),
            meetingTypeId: meetingTypeId?.value,
            scheduledStartTime: moment(scheduledEndTime).format(
                "HH:mm:ss"
            ),
            scheduledEndTime: moment(scheduledEndTime).format("HH:mm:ss"),
            ...rest,
        };
        addMeeting(formatedValues).then((res: any) => {
            if (res?.error) {
                showToast("error", res?.error?.data?.message);
            } else {
                if (res?.data?.status) {
                    showToast("success", res?.data?.message);
                    if (saveNextChecked) {
                        navigate(`/meeting/${res?.data?.data?._id}/agenda`)
                    }
                    onClose()
                } else {
                    showToast("error", res?.data?.message);
                }
            }
            setSubmitting(false);
        });
    };

    return (
        <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formikProps) => (
                    <Form>
                        <InternalMeetingAdd
                            formType="ADD"
                            formikProps={formikProps}
                            onClose={onClose}
                            setSaveNextChecked={setSaveNextChecked}
                            saveNextChecked={saveNextChecked}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default AddQuicklMeetingWrapper;
