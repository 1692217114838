import { ReactNode } from "react";
import ATMLoadingButton from "../../atoms/ATMLoadingButton/ATMLoadingButton";
import ATMDialog from "../../atoms/ATMDialog/ATMDialog";

type Props = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  clearFilter: () => void;
  isSubmitting: boolean;
  handleSubmit?: () => void;
};

const MOLFilterDialog = ({
  open,
  onClose,
  children,
  title,
  clearFilter,
  isSubmitting,
  handleSubmit,
}: Props) => {
  return (
    <>
      {open ? (
        <ATMDialog>
          <div
            className={`flex flex-col relative min-w-[30vw] max-h-[90vh] overflow-auto hide-scrollbar`}
          >
            {/* Header */}
            <div className="sticky top-0 flex items-center justify-between p-4 pb-2 z-[10000] bg-white">
              <span className="font-semibold text-md">{title}</span>
            </div>

            {/* Main Content */}
            <div className="flex-1 p-4">{children}</div>

            {/* Actions */}
            <div className="sticky bottom-0 flex items-center justify-between gap-2 px-4 py-3 bg-blue-2">
              <ATMLoadingButton
                variant="text"
                type="button"
                color="error"
                onClick={clearFilter}
              >
                Clear Filters
              </ATMLoadingButton>
              <div className="flex items-center justify-end flex-1 gap-2">
                <ATMLoadingButton
                  variant="text"
                  type="button"
                  color="primary"
                  onClick={onClose}
                >
                  Cancel
                </ATMLoadingButton>
                <ATMLoadingButton
                  type="submit"
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                >
                  Apply
                </ATMLoadingButton>
              </div>
            </div>
          </div>
        </ATMDialog>
      ) : null}
    </>
  );
};

export default MOLFilterDialog;
