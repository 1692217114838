import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ATMActionMenu, {
  OptionType,
} from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import EditTaskFormWrapper from "src/screens/Ticket/View/TicketTabs/Task/Edit/EditTaskFormWrapper";
import AssignToDialogWrapper from "src/screens/Ticket/View/TicketTabs/Task/List/AssignToDialog/AssignToDialogWrapper";
import StatusChangeDialogWrapper from "src/screens/Ticket/View/TicketTabs/Task/List/StatusChangeDialog/StatusChangeDialogWrapper";
import { useDeleteTaskByIdMutation } from "src/services/TaskService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import useMoveTaskToArchive from "src/hooks/useMoveTaskToArchive";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";
// |-- Icons --|
import { IoChevronDown } from "react-icons/io5";
import { RxDividerVertical } from "react-icons/rx";
import { MdBackHand, MdChevronRight } from "react-icons/md";
import {
  TbArchive,
  TbEdit,
  TbNewSection,
  TbStack,
  TbTrash,
} from "react-icons/tb";
import { Divider } from "@mui/material";

type Props = {
  rows: any[];
  isTableLoading: boolean;
};

const AllTaskListLayout = ({ rows, isTableLoading }: Props) => {
  const [isOpenAssignTo, setIsOpenAssignTo] = useState(false);
  const [isOpenStatusChange, setIsOpenStatusChange] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [assigneeId, setAssigneeId] = useState<any>({});
  const [labelId, setLabelId] = useState<any>({});
  const { pathname, search } = useLocation();
  const { handleMoveTaskToArchive } = useMoveTaskToArchive();

  const navigate = useNavigate();
  const [isOpenEditFormDialog, setIsOpenEditFormDialog] =
    useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>("");

  const [deleteTask] = useDeleteTaskByIdMutation();
  const handleDeleteTask = (row: string) => {
    showConfirmationDialog({
      title: "Hands Up",
      text: "Are you sure want to delete this Task?",
      icon: "question",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          deleteTask(row).then((res: any) => {
            if (res.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              showToast("success", res?.data?.message);
            }
          });
        }
      },
    });
  };

  return (
    <>
      <div className="flex flex-col h-full w-full">
        {/* View */}
        <div className="h-[100vh] overflow-y-scroll overflow-x-hidden xs:px-0 md:px-0 bg-gray-50">
          {rows?.length ? (
            <div className="flex flex-col gap-2 p-2 md:p-4 md:gap-4">
              {rows?.map((row: any, index: any) =>
                  <div key={index} className="">
                    <div
                      className={`rounded-md bg-white border border-slate-200 hover:border-primary-dark max-w-[100vw] transition-all duration-500 hover:shadow-md`}
                    >
                      <div className="flex h-full md:px-2">
                        <div className="flex items-center gap-2 px-2">
                          <p className="flex items-center text-xs font-medium leading-none text-teal-600 md:text-sm">
                            {row?.taskNumber}
                          </p>
                          <RxDividerVertical className="hidden md:flex" />
                          <p className="items-center hidden md:flex">
                            <span className="flex flex-wrap items-center gap-1 text-xs font-medium">
                              {row?.layer[0]?.layerName && (
                                <>
                                  <TbStack />
                                  {row?.layer[0]?.layerName}
                                  {row?.projectName && (
                                    <MdChevronRight className="text-gray-600" />
                                  )}
                                </>
                              )}

                              {row?.projectName && (
                                <>
                                  <TbStack />
                                  {row?.projectName}
                                  {row?.moduleName && (
                                    <MdChevronRight className="text-gray-600" />
                                  )}
                                </>
                              )}

                              {row?.moduleName && (
                                <>
                                  <MdBackHand />
                                  {row?.moduleName}
                                  {row?.featureName && (
                                    <MdChevronRight className="text-gray-600" />
                                  )}
                                </>
                              )}

                              {row?.featureName && (
                                <>
                                  <MdBackHand />
                                  {row?.featureName}
                                </>
                              )}
                            </span>
                          </p>
                        </div>
                        <div className="flex-grow" />
                        <div className="items-end">
                          <ATMActionMenu
                            options={
                              [
                                {
                                  label: (
                                    <div className="flex items-center gap-x-2">
                                      <TbEdit /> Edit
                                    </div>
                                  ),
                                  onClick: () => {
                                    navigate(`/task/edit/${row?._id}`, {
                                      state: {
                                        pathname,
                                        search,
                                      },
                                    });
                                  },
                                  acitonName:
                                    UserModuleNameTypes.ACTION_TASK_EDIT,
                                },
                                {
                                  label: (
                                    <div className="flex items-center gap-x-2">
                                      <TbNewSection color="#000000" />
                                      Duplicate
                                    </div>
                                  ),
                                  onClick: () => {
                                    navigate(`/task/duplicate/${row?._id}`);
                                  },
                                  acitonName:
                                    UserModuleNameTypes.ACTION_TASK_DUPLICATE,
                                },
                                !row?.isArchived && {
                                  label: (
                                    <div className="flex items-center gap-x-2">
                                      <TbArchive /> Move to Archive
                                    </div>
                                  ),
                                  onClick: () => {
                                    handleMoveTaskToArchive(row?._id);
                                  },
                                  acitonName:
                                    UserModuleNameTypes.ACTION_TASK_MOVE_TO_ARCHIVE,
                                },
                                {
                                  label: (
                                    <div className="flex items-center gap-x-2">
                                      <TbTrash /> Delete
                                    </div>
                                  ),
                                  onClick: () => {
                                    handleDeleteTask(row?._id);
                                  },
                                  acitonName:
                                    UserModuleNameTypes.ACTION_TASK_DELETE,
                                },
                              ].filter(Boolean) as OptionType[]
                            }
                          />
                        </div>
                      </div>

                      <Divider />

                      <div className="flex flex-col justify-between w-full gap-4 p-2 md:p-4 md:flex-row">
                        <div className="flex flex-col gap-1">
                          <p className="flex items-center text-neutral md:hidden">
                            <span className="flex flex-wrap items-center gap-1 text-xs font-medium">
                              {row?.layer[0]?.layerName && (
                                <>
                                  <TbStack />
                                  {row?.layer[0]?.layerName}
                                  {row?.projectName && (
                                    <MdChevronRight className="text-gray-600" />
                                  )}
                                </>
                              )}

                              {row?.projectName && (
                                <>
                                  <TbStack />
                                  {row?.projectName}
                                  {row?.moduleName && (
                                    <MdChevronRight className="text-gray-600" />
                                  )}
                                </>
                              )}

                              {row?.moduleName && (
                                <>
                                  <MdBackHand />
                                  {row?.moduleName}
                                  {row?.featureName && (
                                    <MdChevronRight className="text-gray-600" />
                                  )}
                                </>
                              )}

                              {row?.featureName && (
                                <>
                                  <MdBackHand />
                                  {row?.featureName}
                                </>
                              )}
                            </span>
                          </p>
                          <div
                            className="flex flex-col gap-1"
                            onClick={() => {
                              isAuthorized(
                                UserModuleNameTypes.ACTION_TASK_SINGLE_VIEW
                              ) &&
                                navigate(`/task/${row?._id}`, {
                                  state: {
                                    pathname,
                                    search,
                                  },
                                });
                            }}
                          >
                            <p className="cursor-pointer text-md md:text-lg line-clamp-2  hover:underline">
                              {row?.title}
                            </p>

                            {row?.ticketNumber && (
                              <p className="cursor-pointer text-xs line-clamp-2 hover:underline" onClick={(()=>navigate(`/ticket/${row?.ticketId}/details`))}>
                                #{row?.ticketNumber}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-end gap-2 md:gap-4 md:py-0">
                          <div
                            className="flex items-center justify-between w-full gap-4 px-4 py-2 text-xs font-medium capitalize bg-gray-100 rounded cursor-pointer text-primary md:text-sm md:w-fit h-fit"
                            onClick={() => {
                              setIsOpenAssignTo(true);
                              setTaskId(row?._id);
                              setProjectId(row?.projectId);
                              setAssigneeId([
                                row?.assignedTo,
                                row?.assigneeName,
                              ]);
                            }}
                          >
                            {row?.assignedTo === null
                              ? "Unassigned"
                              : row?.assigneeName}
                            <p>
                              <TbEdit />
                            </p>
                          </div>
                          <div
                            className={`font-medium text-primary text-xs md:text-sm w-full md:w-fit justify-between flex items-center gap-4 bg-primary-main text-white px-4 py-2 rounded capitalize cursor-pointer h-fit ${
                              row?.assignedTo === null
                                ? "disabled text-slate-300"
                                : ""
                            }`}
                            onClick={() => {
                              if (row?.assignedTo !== null) {
                                setIsOpenStatusChange(true);
                                setTaskId(row?._id);
                                setProjectId(row?.projectId);
                                setAssigneeId([
                                  row?.assignedTo,
                                  row?.assigneeName,
                                ]);
                                setLabelId(row?.label);
                              }
                            }}
                            title={
                              row?.assignedTo === null
                                ? "Please assign the task"
                                : ""
                            }
                          >
                            {row?.label[0]?.labelName}
                            <IoChevronDown />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
              )}
            </div>
          ) : (
            <div className="flex justify-center items-center h-[400px] font-semibold text-red-700 text-[22px]">
              Data not Found
            </div>
          )}
        </div>
      </div>

      {isOpenEditFormDialog ? (
        <EditTaskFormWrapper
          onClose={() => setIsOpenEditFormDialog(false)}
          selectedTask={taskId}
        />
      ) : null}

      {isOpenAssignTo ? (
        <AssignToDialogWrapper
          projectId={projectId}
          onClose={() => setIsOpenAssignTo(false)}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}

      {isOpenStatusChange ? (
        <StatusChangeDialogWrapper
          onClose={() => setIsOpenStatusChange(false)}
          projectId={projectId}
          labelId={labelId}
          selectedTask={taskId}
          assignToId={assigneeId[0]}
          assignToName={assigneeId[1]}
        />
      ) : null}
    </>
  );
};

export default AllTaskListLayout;
