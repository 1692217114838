import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";

import CircularProgress from "@mui/material/CircularProgress";
import { ExpenseFormValues } from "src/models/Expenses.model";
import SubscriptionForm from "../Layout/ExpensesForm";
import {
  useGetExpensesByIdQuery,
  useUpdateExpensesMutation,
} from "src/services/ExpensesService";
import { FILE_FORMAT } from "src/models/ResourceRequest.model";
import moment from "moment";

type Props = {
  selectedSubscription: string;
  onClose: () => void;
};

const EditExpensesWrapper = ({ selectedSubscription, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetExpensesByIdQuery(selectedSubscription);

  const [updateExpense] = useUpdateExpensesMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data?.[0] || {});
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: ExpenseFormValues = {
    expenseDate: items?.expenseDate,
    amount: items?.amount,
    accountId: {
      label: items?.accountLabel,
      value: items?.accountId,
    },
    expenseCategoryId: {
      label: items?.expencecategoryLabel,
      value: items?.expenseCategoryId,
    },
    GSTApplicable: items?.GSTApplicable,
    billPhoto: items?.billPhoto,
    expiryDate: items?.expiryDate ? items?.expiryDate : null,
    remark: items?.remark,
  };

  // Validation Schema
  const validationSchema = object().shape({
    expenseDate: string().required("Please enter expense date"),
    amount: string().required("Please enter amount"),
    accountId: object().required("Please select account"),
    expenseCategoryId: object().required("Please select expense category"),
  });
  const handleSubmit = (
    values: ExpenseFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ExpenseFormValues>
  ) => {
    const formattedValues = {
      expenseDate: values?.expenseDate
        ? moment(values?.expenseDate).format("YYYY-MM-DD")
        : "",
      amount: values?.amount,
      accountId: values?.accountId?.value,
      expenseCategoryId: values?.expenseCategoryId?.value,
      GSTApplicable: values?.GSTApplicable,
      billPhoto: values?.billPhoto?.map(
        (attachment: {
          url: string;
          type: "images" | "pdfs" | "videos" | "xlsx";
        }) => {
          return {
            url: attachment?.url,
            type: FILE_FORMAT[attachment.type],
          };
        }
      ),
      expiryDate: values?.expiryDate
        ? moment(values?.expiryDate).format("YYYY-MM-DD")
        : "",
      remark: values?.remark,
    };

    updateExpense({
      id: selectedSubscription,
      body: formattedValues,
    }).then((res: any) => {
      updateExpense({
        id: selectedSubscription,
        body: formattedValues,
      }).then((res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      });
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <SubscriptionForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditExpensesWrapper;
