import React from 'react'
import { PiUserFill } from 'react-icons/pi';
import ColorComponent from 'src/components/UI/colorComponent';

type Props ={
    row :any ;
    columns:any
}

const ProjectMembersTabForm = ({row , columns} : Props) => {
  return (
    <div className='grid grid-cols-4 p-4 gap-4'>
        {row?.map((el:any , index:any)=>{
            return(
                <div
                key={index}
                className="flex flex-col gap-6 border bg-white items-center rounded-md p-4"
              >
                <div className="flex flex-col items-center gap-2">
                  <div className="border p-2 rounded-full aspect-square">
                    <PiUserFill
                      size={16}
                      className="rounded-full mx-auto"
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-lg font-medium">
                      <ColorComponent inputString={el?.name} />
                    </div>
                  </div>
                </div>
              </div>
            )
        })}
    </div>
  )
}

export default ProjectMembersTabForm