import React, { Suspense } from "react";
const ATMMarkDownEditor = React.lazy(
  () => import("../../atoms/ATMMarkDownEditor/ATMMarkDownEditor")
);

type Props = {
  onChange: (html: any) => void;
  value: any;
  label?: string;
  required?: boolean;
  placeholder?: string;
  readonly?: boolean;
  height?: number;
  extraClassName?: string;
  name?: string
};

const MOLMarkdownEditor = ({
  value = "",
  onChange,
  label = "",
  required = false,
  placeholder,
  readonly,
  height = 250,
  extraClassName,
  name
}: Props) => {
  return (
    <Suspense fallback={<>loading.....</>}>
      <ATMMarkDownEditor
        label={label}
        readonly={readonly}
        placeholder={placeholder}
        required={required}
        height={height}
        extraClassName={extraClassName}
        value={value}
        onChange={onChange}
        name={name}
      />
    </Suspense>
  );
};

export default MOLMarkdownEditor;
