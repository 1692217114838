import React, { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import ClarificationDateFilterDialogWrapper from "../ClarificationDateFilterDialog/ClarificationDateFilterDialogWrapper";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const ClarificationIncidentListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenFilterDialog, setIsOpenFilterDialog] = useState<boolean>(false);
  return (
    <div className="flex flex-col">
      {/* Page Header */}
      <div className="p-4">
        <ATMPageHeader
          pageTitle="Clarification Incidents"
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          isFilter
          onFilterClick={() => {
            setIsOpenFilterDialog(true);
          }}
          hideAddButton
        />
      </div>

      {/* Table */}
      <div className="flex flex-col flex-1 overflow-auto">
        <ATMTable
          columns={columns}
          rows={rows}
          rowExtraClasses={() => "h-[40px]"}
          isLoading={isTableLoading}
        />
      </div>

      <div>
        <ATMPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          rowCount={totalItems}
          rows={rows}
        />
      </div>
      {isOpenFilterDialog && (
        <ClarificationDateFilterDialogWrapper
          onClose={() => setIsOpenFilterDialog(false)}
        />
      )}
    </div>
  );
};

export default ClarificationIncidentListing;
