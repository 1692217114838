import React, { useState, useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { ClientUserFormValues } from "src/models/ClientUser.model";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import {
  useGetUsersByIdQuery,
  useUpdateClientUserMutation,
} from "src/services/ClientUserServices";
import CircularProgress from "@mui/material/CircularProgress";
import ClientUserForm from "../Layouts/ClientUserForm";

type Props = {
  selectedClient: string;
  onClose: () => void;
};

const EditClientUserFormWrapper = ({ selectedClient, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } = useGetUsersByIdQuery(selectedClient);
  const [updateClientUser] = useUpdateClientUserMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: ClientUserFormValues = {
    clientId: { _id: items?.clientId, organisationName: items?.clientOrgLabel },
    name: items?.name,
    email: items?.email,
    mobile: items?.mobile,
    canApproveETA: items?.canApproveETA,
    canCloseTicket: items?.canCloseTicket,
    projectId: items?.projectId?.map((project: any) => ({
      _id: project?._id,
      projectName: project?.projectName,
    })),
  };

  // Validation Schema
  const validationSchema = object().shape({
    name: string().required("Please enter name"),
    email: string().email("Invalid email").required("Please enter email"),
    clientId: object().required("Please select client"),
    mobile: string()
      .required("Please enter mobile number")
      .matches(/^[0-9]+$/, "Mobile number should only contain numbers"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ClientUserFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ClientUserFormValues>
  ) => {
    const { clientId, projectId, ...rest } = values;
    const formattedValues = {
      ...rest,
      clientId: clientId?._id,
      projectId: projectId?.map((project: any) => project?._id),
    };
    updateClientUser({ id: selectedClient, body: formattedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          {/* <EditClientUserForm formikProps={formikProps} onClose={onClose} /> */}
          <ClientUserForm
            formType="EDIT"
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditClientUserFormWrapper;
