import { Tooltip } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { IoAlert } from "react-icons/io5";
import { LuUsers } from "react-icons/lu";
import { MdDelete, MdEdit } from "react-icons/md";
import { RiUserSmileLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { LeaveRequestListValue } from "src/models/LeaveReques.model";
import {
  setIsTableLoading,
  setItems,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
  setPage,
} from "src/redux/slices/LeaveRequestSlice";
import { RootState } from "src/redux/store";
import {
  useDeleteLeaveRequestMutation,
  useGetAllLeaveRequestWithPaginationQuery,
} from "src/services/LeaveRequestService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import EditHalfDayLeaveRequestFormWrapper from "../Edit/EditHalfDayLeaveRequestFormWrapper";
import EditMoreThanOneDayLeaveRequestFormWrapper from "../Edit/EditMoreThanOneDayLeaveRequestFormWrapper";
import EditOneDayLeaveRequestFormWrapper from "../Edit/EditOneDayLeaveRequestFormWrapper";
import LeaveRequestList from "./LeaveRequestList";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import UpdateLeaveRequestFormWrapper from "../UpdateAdmin/UpdateLeaveRequestFormWrapper";
import { TbUserEdit } from "react-icons/tb";

const LeaveRequestListWrapper = () => {
  const dispatch = useDispatch();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.leaveRequest);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const [isShowEditDialog, setIsShowEditDialog] = useState(false);
  const [isShowUpdateDialog, setIsShowUpdateDialog] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [selectedLeaveReqId, setSelectedLeaveReqId] = useState("");
  const [deleteLeaveRequest] = useDeleteLeaveRequestMutation();
  const { data, isLoading, isFetching } =
    useGetAllLeaveRequestWithPaginationQuery({
      limit: rowsPerPage,
      searchValue: searchValue,
      params: [
        "leaveType",
        "daysOfLeave",
        "requestedBy",
        "requestedByName",
        "status",
        "leaveStartDate",
        "reportingDate",
        "buddy",
      ],
      page: page,
      filterBy: [],
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });

  // Setting leave  Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const getActionOptions = (leaveData: any) => {
    const isRequestedByMe = leaveData?.requestedBy === userData?.userId;
    const OtherMenuOptions = [
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdEdit color="#000000" /> Edit
          </div>
        ),
        onClick: () => {
          setIsShowEditDialog(true);
          setSelectedLeaveReqId(leaveData?._id);
          setSelectedLeaveType(leaveData?.daysOfLeave);
        },
        acitonName: UserModuleNameTypes.ACTION_LEAVE_REQUEST_EDIT,
      },
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdEdit color="#000000" /> Update
          </div>
        ),
        onClick: () => {
          setIsShowUpdateDialog(true);
          setSelectedLeaveReqId(leaveData?._id);
          setSelectedLeaveType(leaveData?.daysOfLeave);
        },
        acitonName: UserModuleNameTypes.ACTION_LEAVE_REQUEST_UPDATE,
      },
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdDelete color="#000000" /> Delete
          </div>
        ),
        onClick: () => {
          showConfirmationDialog({
            title: "Heads Up",
            text: "Are you sure, you want to delete this Leave Request?",
            icon: "question",
            showCancelButton: true,
            next: (result) => {
              if (result?.isConfirmed) {
                deleteLeaveRequest(leaveData?._id).then((res: any) => {
                  if (res.error) {
                    showToast("error", res?.error?.data?.message);
                  } else {
                    if (res?.data?.status) {
                      showToast("success", res?.data?.message);
                    } else {
                      showToast("error", res?.data?.message);
                    }
                  }
                });
              }
            },
          });
        },
        acitonName: UserModuleNameTypes.ACTION_LEAVE_REQUEST_DELETE,
      },
    ];

    return isRequestedByMe
      ? OtherMenuOptions
      : [
          {
            label: (
              <div className="flex items-center gap-x-2">
                <TbUserEdit color="#000000" /> Update
              </div>
            ),
            onClick: () => {
              setIsShowUpdateDialog(true);
              setSelectedLeaveReqId(leaveData?._id);
              setSelectedLeaveType(leaveData?.daysOfLeave);
            },
            acitonName: UserModuleNameTypes.ACTION_LEAVE_REQUEST_UPDATE,
          },
          {
            label: (
              <div className="flex items-center gap-x-2">
                <MdDelete color="#000000" /> Delete
              </div>
            ),
            onClick: () => {
              showConfirmationDialog({
                title: "Heads Up",
                text: "Are you sure, you want to delete this Leave Request?",
                icon: "question",
                showCancelButton: true,
                next: (result) => {
                  if (result?.isConfirmed) {
                    deleteLeaveRequest(leaveData?._id).then((res: any) => {
                      if (res.error) {
                        showToast("error", res?.error?.data?.message);
                      } else {
                        if (res?.data?.status) {
                          showToast("success", res?.data?.message);
                        } else {
                          showToast("error", res?.data?.message);
                        }
                      }
                    });
                  }
                },
              });
            },
            acitonName: UserModuleNameTypes.ACTION_LEAVE_REQUEST_DELETE,
          },
        ];
  };

  const getStatusWiseIcon = (status: string) => {
    switch (status) {
      case "PENDING":
        return (
          <div className="text-white bg-amber-500 rounded-full p-[1px]">
            <IoAlert size={12} />
          </div>
        );

      case "APPROVED":
        return (
          <div className="text-white bg-green-500 rounded-full p-[1px]">
            <FaCheck size={12} />
          </div>
        );
      case "DECLINED":
        return (
          <div className="text-white bg-red-500 rounded-full p-[1px]">
            <RxCross2 size={12} />
          </div>
        );
    }
  };
  const getLeaveDays = (noOfDays: any) => {
    switch (noOfDays) {
      case 1:
        return "Full Day";
      case 0.5:
        return "Half Day";
      default:
        return noOfDays + " days";
    }
  };
  const columns: columnTypes[] = [
    {
      field: "requestedOnDate",
      headerName: "Requested Date",
      extraClasses: "min-w-[120px] max-w-[120px]",
      name: UserModuleNameTypes.LEAVE_REQUEST_LIST_REQUESTED_DATE,
      renderCell: (row: LeaveRequestListValue) => (
        <div className="py-1">
          <div className="text-[15px] text-slate-500 font-medium">
            {moment(row?.requestedOnDate).format("hh:mm A")}
          </div>
          <div className="text-[15px] text-slate-700 font-medium">
            {moment(row?.requestedOnDate).format("DD MMM YYYY")}
          </div>
        </div>
      ),
    },
    {
      field: "requestedByName",
      headerName: "Requested By",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[140px]",
      name: UserModuleNameTypes.LEAVE_REQUEST_LIST_REQUESTED_BY,
      renderCell: (row: LeaveRequestListValue) => (
        <div className="capitalize"> {row?.requestedByName} </div>
      ),
    },
    {
      field: "leaveType",
      headerName: "Leave Type",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[140px]",
      name: UserModuleNameTypes.LEAVE_REQUEST_LIST_LEAVE_TYPE,
      renderCell: (row: LeaveRequestListValue) => (
        <div className="capitalize"> {row?.leaveType} </div>
      ),
    },
    {
      field: "noOfDays",
      headerName: "Leave days",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[140px]",
      name: UserModuleNameTypes.LEAVE_REQUEST_LIST_LEAVE_DAYS,
      renderCell: (row: LeaveRequestListValue) => (
        <div> {getLeaveDays(row?.noOfDays)} </div>
      ),
    },
    {
      field: "leaveStartDate",
      headerName: "Leaving Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[140px]",
      name: UserModuleNameTypes.LEAVE_REQUEST_LIST_LEAVING_DATE,
      renderCell: (row: LeaveRequestListValue) => (
        <div> {moment(row.leaveStartDate)?.format("DD-MM-YYYY")} </div>
      ),
    },
    {
      field: "reportingDate",
      headerName: "Reporting Date",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[140px]",
      name: UserModuleNameTypes.LEAVE_REQUEST_LIST_REPORTING_DATE,
      renderCell: (row: LeaveRequestListValue) => (
        <div> {moment(row.reportingDate)?.format("DD-MM-YYYY")} </div>
      ),
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[220px]",
      name: UserModuleNameTypes.LEAVE_REQUEST_LIST_REASON,
      renderCell: (row: LeaveRequestListValue) => (
        <Tooltip title={row?.reason}>
          <div className="w-full overflow-hidden truncate"> {row?.reason} </div>
        </Tooltip>
      ),
    },
    {
      field: "approvals",
      headerName: "Approval",
      flex: "flex-[1.25_1.25_0%]",
      extraClasses: "min-w-[180px]",
      name: UserModuleNameTypes.LEAVE_REQUEST_LIST_APPROVALS,

      renderCell: (row: LeaveRequestListValue) => {
        return (
          <div className="flex flex-col gap-1">
            {row?.approvals?.map((approval: any) => {
              return (
                <div className="flex items-center gap-2">
                  {approval?.approvedBy === row?.buddy ? (
                    <RiUserSmileLine />
                  ) : (
                    <LuUsers />
                  )}
                  <span className="text-xs">{approval?.name}</span>
                  {getStatusWiseIcon(approval?.approvalStatus)}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: LeaveRequestListValue) => {
        const options = getActionOptions(row);
        return options?.length ? (
          userData?.userType === "SUPER_ADMIN" ? (
            <ATMActionMenu options={options} />
          ) : row?.status === "PENDING" ? (
            <ATMActionMenu options={options} />
          ) : (
            <span>-</span>
          )
        ) : (
          []
        );
      },
    },
  ];

  return (
    <>
      <LeaveRequestList
        columns={columns}
        onAddNew={() => navigate("/leave-request/add")}
        rows={items}
        paginationProps={{
          isTableLoading: isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />

      {isShowEditDialog ? (
        <>
          {selectedLeaveType === "ONE_DAY" && (
            <EditOneDayLeaveRequestFormWrapper
              leaveRequestId={selectedLeaveReqId}
              handleClose={() => setIsShowEditDialog(false)}
            />
          )}
          {selectedLeaveType === "HALF_DAY" && (
            <EditHalfDayLeaveRequestFormWrapper
              leaveRequestId={selectedLeaveReqId}
              handleClose={() => setIsShowEditDialog(false)}
            />
          )}
          {selectedLeaveType === "MULTIPLE_DAYS" && (
            <EditMoreThanOneDayLeaveRequestFormWrapper
              leaveRequestId={selectedLeaveReqId}
              handleClose={() => setIsShowEditDialog(false)}
            />
          )}
        </>
      ) : null}
      {isShowUpdateDialog && (
        <UpdateLeaveRequestFormWrapper
          leaveRequestId={selectedLeaveReqId}
          handleClose={() => setIsShowUpdateDialog(false)}
        />
      )}
    </>
  );
};

export default LeaveRequestListWrapper;
