import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { ClientUserFormValues } from "src/models/ClientUser.model";
import { useAddTeamMemberMutation } from "src/services/ClientUserServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import ClientUserForm from "../Layouts/ClientUserForm";

type Props = {
  onClose: () => void;
};

const AddClientUserFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addClientUser] = useAddTeamMemberMutation();

  // Form Initial Values
  const initialValues: ClientUserFormValues = {
    clientId: null,
    name: "",
    email: "",
    mobile: "",
    canApproveETA: false,
    canCloseTicket: false,
    password: "",
    projectId: [],
  };

  // Validation Schema
  const validationSchema = object().shape({
    name: string().required("Please enter name"),
    email: string().email("Invalid email").required("Please enter email"),
    clientId: object().required("Please select client"),
    password: string().required("Password is required"),
    mobile: string()
      .required("Please enter mobile number")
      .matches(/^[0-9]+$/, "Mobile number should only contain numbers"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ClientUserFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ClientUserFormValues>
  ) => {
    const { clientId, projectId, ...rest } = values;
    const formattedValues = {
      ...rest,
      clientId: clientId?._id,
      projectId: projectId?.map((project: any) => project?._id),
    };

    addClientUser(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ClientUserForm
            formType="ADD"
            formikProps={formikProps}
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddClientUserFormWrapper;
