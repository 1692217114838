import { FormikProps } from "formik";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import useDepartment from "src/hooks/useDepartment";
import { GitRepoFormValues } from "src/models/GitRepo.model";

type Props = {
  formikProps: FormikProps<GitRepoFormValues>;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
  onClose: () => void;
};

const PositonForm = ({
  formikProps,
  formType = "ADD",
  setSaveNextChecked,
  saveNextChecked,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  const { department, isDataLoading } = useDepartment();

  const departmentOptions = department?.map((depart) => {
    return {
      label: depart?.departmentName,
      value: depart?._id,
    };
  });
  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Position" : "Update Position"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        {/* Project */}

        <div>
          <ATMSelect
            required
            name="repoProjects"
            value={values?.repoProjects}
            onChange={(value) => {
              setFieldValue("repoProjects", value);
            }}
            placeholder="Select department"
            options={departmentOptions}
            isLoading={isDataLoading}
            label="Department"
          />
        </div>

        {/* Module Name */}
        <div className="">
          <ATMTextField
            required
            name="repoName"
            value={values.repoName}
            onChange={(e) => setFieldValue("repoName", e.target.value)}
            label="Position"
            placeholder="Enter Position"
            onBlur={handleBlur}
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default PositonForm;
