import { FormikProps } from "formik";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { ClipLoader } from "react-spinners";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMSwitchButton from "src/components/UI/atoms/formFields/ATMSwitchButton/ATMSwitchButton";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";
import { useAccounts } from "src/hooks/useAccounts";
import { useExpenesCategory } from "src/hooks/useExpenesCategory";
import { ExpenseFormValues } from "src/models/Expenses.model";
import useFileUploader from "src/utils/useFileUploader";
import { handleValidWithDotNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<ExpenseFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const ExpensesForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  // hook
  const { accounts, isDataLoading } = useAccounts();
  const { expenesCategory, isDataLoading: isCategoryDataLoading } =
    useExpenesCategory();

  const { initiateUpload, uploadedFile } = useFileUploader({
    next: (file: any) =>
      setFieldValue("billPhoto", [
        ...values?.billPhoto,
        {
          url: file?.fileUrl,
          type: file?.fileType,
          name: file?.fileTitle,
        },
      ]),
    accept: ".jpg, .jpeg, .png,  .mp4, .xls, .xlsx, .pdf, .webp, .webm",
  });

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Expense" : "Update Expense"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        <div>
          <ATMDatePicker
            required
            label="Expense Date"
            name="expenseDate"
            format="DD/MM/YYYY"
            value={values.expenseDate}
            onChange={(newValue) => setFieldValue("expenseDate", newValue)}
          />
        </div>

        {/* Amount */}
        <div>
          <ATMTextField
            required
            name="amount"
            value={values.amount}
            onChange={(e) => {
              handleValidWithDotNumber(e) &&
                setFieldValue("amount", e.target.value);
            }}
            label="Amount"
            placeholder="Enter Amount"
            onBlur={handleBlur}
          />
        </div>

        {/* accountId  */}
        <div className="">
          <ATMSelect
            required
            name="accountId"
            value={values.accountId}
            onChange={(newValue: any) => {
              setFieldValue("accountId", newValue);
            }}
            onBlur={handleBlur}
            label="Accounts"
            placeholder="Select Accounts"
            options={accounts?.map((ele: any) => {
              return {
                label: ele?.accountName,
                value: ele?._id,
              };
            })}
            isLoading={isDataLoading}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return <div className="capitalize"> {option.label} </div>;
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            isOptionEqualToValue={(option, selected) => {
              return (
                selected?.findIndex(
                  (selectedValue: any) => selectedValue?.value === option.value
                ) > -1
              );
            }}
            isSearchedOption={(option, searchValue) => {
              return searchValue
                ? option?.data?.label?.toLowerCase()?.includes(searchValue)
                : true;
            }}
          />
        </div>

        {/* expenseCategoryId  */}
        <div className="">
          <ATMSelect
            required
            name="expenseCategoryId"
            value={values.expenseCategoryId}
            onChange={(newValue: any) => {
              setFieldValue("expenseCategoryId", newValue);
            }}
            onBlur={handleBlur}
            label="Expense Category "
            placeholder="Select Expense Category"
            options={expenesCategory?.map((ele: any) => {
              return {
                label: ele?.categoryName,
                value: ele?._id,
              };
            })}
            isLoading={isCategoryDataLoading}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return <div className="capitalize"> {option.label} </div>;
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            isOptionEqualToValue={(option, selected) => {
              return (
                selected?.findIndex(
                  (selectedValue: any) => selectedValue?.value === option.value
                ) > -1
              );
            }}
            isSearchedOption={(option, searchValue) => {
              return searchValue
                ? option?.data?.label?.toLowerCase()?.includes(searchValue)
                : true;
            }}
          />
        </div>

        {/* GSTApplicable  */}
        <div>
          <ATMSwitchButton
            required
            name="GSTApplicable"
            value={values.GSTApplicable}
            onChange={(newValue) => setFieldValue("GSTApplicable", newValue)}
            label="GST Applicable"
          />
        </div>

        {/* Expiry Date  */}
        <div>
          <ATMDatePicker
            label="Expiry Date"
            name="expiryDate"
            format="DD/MM/YYYY"
            value={values.expiryDate}
            onChange={(newValue) => setFieldValue("expiryDate", newValue)}
          />
        </div>

        {/* bill Photo  */}

        {values?.billPhoto?.length ? (
          <div className="grid md:grid-cols-12 grid-cols-6 gap-4 pb-10">
            <div className="md:col-span-12 col-span-6 text-slate-700 font-medium text-sm">
              Bill Photo
            </div>
            {values?.billPhoto?.map((item: any, index: any) => {
              return (
                <div className="col-span-3 h-[200px]" key={index}>
                  <ATMRenderFile
                    fileInfo={item}
                    isShowCancel={true}
                    onCancelClick={() => {
                      const attachmentInfo = [...values?.billPhoto];
                      attachmentInfo?.splice(index, 1);
                      setFieldValue("billPhoto", attachmentInfo);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="flex justify-end mt-3">
          <button
            className="text-primary-main flex gap-1 font-bold items-center border rounded p-2"
            type="button"
            onClick={initiateUpload}
            disabled={uploadedFile ? true : false}
          >
            {uploadedFile ? (
              <ClipLoader
                color={"teal"}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <HiOutlinePlusSmall size={20} />
            )}
            Add Bill Photo
          </button>
        </div>

        {/* Remark */}
        <div className="">
          <ATMTextArea
            name="remark"
            value={values.remark}
            onChange={(e) => setFieldValue("remark", e)}
            label="Remark"
            placeholder="Enter Remark"
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default ExpensesForm;
