import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/showToaster";
import TicketIssuesForm from "../Layouts/TicketIssuesForm";
import { useParams } from "react-router-dom";
import { useAddTicketIssueMutation } from "src/services/TicketIssuesService";

type Props = {
  onClose: () => void;
  meetingOptions: any;
};

export type addTicketIssuesFormWrapperPropType = {
  Issue: any;
  meetings: any;
};

const AddTicketIssuesFormWrapper = ({ onClose, meetingOptions }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState(true);

  const [addTicketIssue] = useAddTicketIssueMutation();

  // Get the Ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  // Form Initial Values
  const initialValues: addTicketIssuesFormWrapperPropType = {
    Issue: "",
    meetings: "",
  };
  // Validation Schema
  const validationSchema = object().shape({
    Issue: string().required("Please enter Issue"),
  });

  // Handle Submit
  const handleSubmit = (
    values: addTicketIssuesFormWrapperPropType,
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<addTicketIssuesFormWrapperPropType>
  ) => {
    const formattedValues = {
      title: values?.Issue,
      sourceId: values?.meetings?.value,
      originId: TICKET_ID,
    };

    addTicketIssue(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <TicketIssuesForm
            formikProps={formikProps}
            onClose={onClose}
            formType="ADD"
            saveNextChecked={saveNextChecked}
            setSaveNextChecked={setSaveNextChecked}
            meetingOptions={meetingOptions}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddTicketIssuesFormWrapper;
