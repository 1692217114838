import React, { useEffect, useState } from "react";
import HoldStartMeetingTask from "./HoldStartMeetingTask";
import { CircularProgress, Dialog } from "@mui/material";
import { Form, Formik } from "formik";
import { isMobile } from "react-device-detect";
import {
  useGetTaskByMeetingIdQuery,
  useStartInternalMeetingMutation,
} from "src/services/MeetingServices";
import { showToast } from "src/utils/showToaster";

type Props = {
  onClose: any;
  meetingStartId: any;
};

const HoldStartMeetingTaskWrapper = ({ onClose, meetingStartId }: Props) => {
  const [taskData, setTaskData] = useState<any>();
  const [taskIds, setTaskIds] = useState<string[]>([]);

  const [startTimer ,startTimerInfo] = useStartInternalMeetingMutation(meetingStartId);
  const { isLoading, isFetching, data } = useGetTaskByMeetingIdQuery(
    meetingStartId || "",
    {
      skip: !meetingStartId,
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setTaskData(data?.data || []);
    }
  }, [isLoading, isFetching, data]);

  const initialValues: any = {
    isTaskHold: [],
  };

  const handleSubmit = () => {
    startTimer({ id: meetingStartId, body: { taskIds: taskIds } }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
      }
    );
  };

  return (
    <>
      <Dialog open maxWidth="xs" fullWidth fullScreen={isMobile}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              {(isLoading || isFetching) && (
                <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
                  <CircularProgress />
                </div>
              )}
              <HoldStartMeetingTask
                onClose={onClose}
                formikProps={formikProps}
                taskData={taskData}
                meetingStartId={meetingStartId}
                submitting={startTimerInfo}
                setTaskIds={setTaskIds}
                taskIds={taskIds}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default HoldStartMeetingTaskWrapper;
