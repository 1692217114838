import { ErrorMessage } from "formik";
import React, { useState } from "react";

interface ATMNumberPickerProps {
  name: string;
  value: any;
  placeholder: string;
  onSelect: (number: number) => void;
  label: string;
  required: boolean;
}

const ATMNumberPicker: React.FC<ATMNumberPickerProps> = ({
  name,
  value = null,
  placeholder = "Select a number",
  onSelect,
  label,
  required,
}) => {
  const [selectedNumber, setSelectedNumber] = useState(value);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const numbers = Array.from({ length: 28 }, (_, index) => index + 1);

  const handleNumberSelect = (number: number) => {
    setSelectedNumber(number);
    setIsOpen(false); // Close the popup after selecting a number
    onSelect(number); // Callback to parent component with selected number
  };

  return (
    <div className="relative ">
      {label && (
        <label className="font-medium text-slate-700">
          {" "}
          {label} {required && <span className="text-red-500"> * </span>}{" "}
        </label>
      )}{" "}
      <br />
      <input
        type="text"
        value={selectedNumber ?? ""}
        placeholder={placeholder}
        readOnly
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-2 border border-gray-300 rounded-md cursor-pointer  focus:outline-none h-[40px]"
      />
      {isOpen && (
        <div className="absolute z-50 ">
          <div className="grid grid-cols-7 gap-2 p-4 overflow-y-auto bg-white rounded-md shadow-lg max-h-40">
            {numbers.map((number) => (
              <div
                key={number}
                className={`flex items-center justify-center w-10 h-10 px-2 py-1 border rounded-full cursor-pointer hover:bg-gray-200 ${selectedNumber === number && "bg-blue-400 text-white"} `}
                onClick={() => handleNumberSelect(number)}
              >
                {number}
              </div>
            ))}
          </div>
        </div>
      )}
      {name && (
        <ErrorMessage name={name}>
          {(errMsg) => (
            <p className="font-poppins absolute text-[14px] text-start mt-0 text-red-500">
              {errMsg}
            </p>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ATMNumberPicker;
