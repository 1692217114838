// NAVIGATION MODULES

// export enum ModuleClientActionTypes {

// }
export enum UserModuleNameTypes {
  WELCOME = "WELCOME",
  NAV_DASHBOARD = "NAV_DASHBOARD",
  NAV_CAMPAIGN = "NAV_CAMPAIGN",
  NAV_CAMPAIGN_GROUP = "NAV_CAMPAIGN_GROUP",
  NAV_CHANGE_REQUEST = "NAV_CHANGE_REQUEST",
  NAV_CHANNEL = "NAV_CHANNEL",
  NAV_CHANNEL_GROUP = "NAV_CHANNEL_GROUP",
  NAV_CLIENTS = "NAV_CLIENTS",
  NAV_CLIENT_USERS = "NAV_CLIENT_USERS",
  NAV_CONFIGURATION = "NAV_CONFIGURATION",
  NAV_CURRENCY = "NAV_CURRENCY",
  NAV_MEETING_NOTES_TYPE = "NAV_MEETING_NOTES_TYPE",
  NAV_MEETING_TYPE = "NAV_MEETING_TYPE",
  NAV_DELIVERY_DATE = "NAV_DELIVERY_DATE",
  NAV_DEPLOYMENT_REQUEST = "NAV_DEPLOYMENT_REQUEST",
  NAV_DOMAIN = "NAV_DOMAIN",
  NAV_DOUBTS = "NAV_DOUBTS",
  NAV_DUMPS = "NAV_DUMPS",
  NAV_FEATURES = "NAV_FEATURES",
  NAV_FOLLOW_UP = "NAV_FOLLOW_UP",
  NAV_GIT_REPO = "NAV_GIT_REPO",
  NAV_HOLIDAY_CALENDAR = "NAV_HOLIDAY_CALENDAR",
  NAV_LEAD_LABEL = "NAV_LEAD_LABEL",
  NAV_LEADS = "NAV_LEADS",
  NAV_LEAVE_REQUEST = "NAV_LEAVE_REQUEST",
  NAV_LOCATION = "NAV_LOCATION",
  NAV_MEETING = "NAV_MEETING",
  NAV_MODULES = "NAV_MODULES",
  NAV_PROJECTS = "NAV_PROJECTS",
  NAV_RESOURCE_REQUEST = "NAV_RESOURCE_REQUEST",
  NAV_SALES_MARKETING = "NAV_SALES_MARKETING",
  NAV_SERVER = "NAV_SERVER",
  NAV_SOCIAL_MEDIA = "NAV_SOCIAL_MEDIA",
  NAV_TASK = "NAV_TASK",
  NAV_TEAMS = "NAV_TEAMS",
  NAV_TEAM_VIEW = "NAV_TEAM_VIEW",
  NAV_TIKCETS = "NAV_TIKCETS",
  NAV_ATTENDANCE = "NAV_ATTENDANCE",
  NAV_TEAM_ATTENDANCE = "NAV_TEAM_ATTENDANCE",
  NAV_ROLES = "NAV_ROLES",
  NAV_DEPARTMENT = "NAV_DEPARTMENT",
  NAV_PRODUCTS = "NAV_PRODUCTS",
  NAV_FINANCE = "NAV_FINANCE",
  NAV_SUBSCRIPTION = "NAV_SUBSCRIPTION",
  NAV_FINANCE_DASHBOARD = "NAV_FINANCE_DASHBOARD",
  NAV_RECURRING_PAYMENTS = "NAV_RECURRING_PAYMENTS",
  NAV_UPCOMING_PAYMENTS = "NAV_UPCOMING_PAYMENTS",
  NAV_ACCOUNTS = "NAV_ACCOUNTS",
  NAV_EXPENSE_CATEGORY = "NAV_EXPENSE_CATEGORY",
  NAV_EXPENSES = "NAV_EXPENSES",
  NAV_REMINDER = "NAV_REMINDER",
  NAV_GOAL = "NAV_GOAL",
  NAV_GOAL_CATEGORY = "NAV_GOAL_CATEGORY",
  NAV_MEETING_NOTES = "NAV_MEETING_NOTES",
  NAV_SERVICE_TICKET = "NAV_SERVICE_TICKET",
  NAV_WORKSPACE = "NAV_WORKSPACE",
  NAV_INCIDENT = "NAV_INCIDENT",
  NAV_TIME_ANALYTICS = "NAV_TIME_ANALYTICS",
  NAV_TICKET_GROUP = "NAV_TICKET_GROUP",
  NAV_INCIDENT_CATEGORY = "NAV_INCIDENT_CATEGORY",
  NAV_INCIDENT_TYPE = "NAV_INCIDENT_TYPE",
  NAV_ESTIMATION_CALCULATOR = "NAV_ESTIMATION_CALCULATOR",
  NAV_HR_MODULE = "NAV_HR_MODULE",
  NAV_JOB_CREATION = "NAV_JOB_CREATION",
  NAV_PAYOUT = "NAV_PAYOUT",
  NAV_CONTACT = "NAV_CONTACT",
  NAV_ENTERPRISESUITE = "NAV_ENTERPRISESUITE",
  NAV_INDUSTRY = "NAV_INDUSTRY",
  NAV_BUSINESS = "NAV_BUSINESS",
  NAV_OPERATIONS = "NAV_OPERATIONS",

  //Client Action
  ACTION_CLIENTS_LIST = "ACTION_CLIENTS_LIST",
  ACTION_CLIENTS_ADD = "ACTION_CLIENTS_ADD",
  ACTION_CLIENTS_EDIT = "ACTION_CLIENTS_EDIT",
  ACTION_CLIENTS_VIEW = "ACTION_CLIENTS_VIEW",
  ACTION_CLIENTS_DELETE = "ACTION_CLIENTS_DELETE",

  // ACTION FEILDS
  CLIENTS_LIST_ORGANISATION_NAME = "CLIENTS_LIST_ORGANISATION_NAME",
  CLIENTS_LIST_MOBILE = "CLIENTS_LIST_MOBILE",
  CLIENTS_LIST_EMAIL = "CLIENTS_LIST_EMAIL",
  CLIENTS_LIST_CURRENCY = "CLIENTS_LIST_CURRENCY",

  //CLIENT_USERS Action
  ACTION_CLIENT_USERS_LIST = "ACTION_CLIENT_USERS_LIST",
  ACTION_CLIENT_USERS_ADD = "ACTION_CLIENT_USERS_ADD",
  ACTION_CLIENT_USERS_EDIT = "ACTION_CLIENT_USERS_EDIT",
  ACTION_CLIENT_USERS_DEACTIVE = "ACTION_CLIENT_USERS_DEACTIVE",

  ACTION_CLIENTS_VIEW_DETAILS_TAB = "ACTION_CLIENTS_VIEW_DETAILS_TAB",
  ACTION_CLIENTS_VIEW_PROJECTS_TAB = "ACTION_CLIENTS_VIEW_PROJECTS_TAB",
  ACTION_CLIENTS_VIEW_MEETINGS_TAB = "ACTION_CLIENTS_VIEW_MEETINGS_TAB",
  ACTION_CLIENTS_VIEW_CHANGE_REQUEST_TAB = "ACTION_CLIENTS_VIEW_CHANGE_REQUEST_TAB",
  ACTION_CLIENTS_VIEW_RESOURCE_TAB = "ACTION_CLIENTS_VIEW_RESOURCE_TAB",
  ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB = "ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB",

  // CLIENT LEDGER LISTING
  CLIENTS_VIEW_CLIENT_LEDGER_LIST_TYPE = "CLIENTS_VIEW_CLIENT_LEDGER_LIST_TYPE",
  CLIENTS_VIEW_CLIENT_LEDGER_LIST_DATE = "CLIENTS_VIEW_CLIENT_LEDGER_LIST_DATE",
  CLIENTS_VIEW_CLIENT_LEDGER_LIST_AMOUNT = "CLIENTS_VIEW_CLIENT_LEDGER_LIST_AMOUNT",
  CLIENTS_VIEW_CLIENT_LEDGER_LIST_TAX_AMOUNT = "CLIENTS_VIEW_CLIENT_LEDGER_LIST_TAX_AMOUNT",
  CLIENTS_VIEW_CLIENT_LEDGER_LIST_TDS_AMOUNT = "CLIENTS_VIEW_CLIENT_LEDGER_LIST_TDS_AMOUNT",
  CLIENTS_VIEW_CLIENT_LEDGER_LIST_CONVERSION_LOSS = "CLIENTS_VIEW_CLIENT_LEDGER_LIST_CONVERSION_LOSS",
  CLIENTS_VIEW_CLIENT_LEDGER_LIST_TOTAL_AMOUNT = "CLIENTS_VIEW_CLIENT_LEDGER_LIST_TOTAL_AMOUNT",
  CLIENTS_VIEW_CLIENT_LEDGER_LIST_LOCAL_AMOUNT = "CLIENTS_VIEW_CLIENT_LEDGER_LIST_LOCAL_AMOUNT",
  CLIENTS_VIEW_CLIENT_LEDGER_LIST_REMARK = "CLIENTS_VIEW_CLIENT_LEDGER_LIST_REMARK",

  ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB_ADD_MILESTONE = "ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB_ADD_MILESTONE",
  ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB_ADD_EXPENSE = "ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB_ADD_EXPENSE",
  ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB_ADD_PAYMENT = "ACTION_CLIENTS_VIEW_CLIENT_LEDGER_TAB_ADD_PAYMENT",

  // ACTION FEILDS
  CLIENT_USERS_LIST_NAME = "CLIENT_USERS_LIST_NAME",
  CLIENT_USERS_LIST_MOBILE = "CLIENT_USERS_LIST_MOBILE",
  CLIENT_USERS_LIST_EMAIL = "CLIENT_USERS_LIST_EMAIL",
  CLIENT_USERS_LIST_CIENT = "CLIENT_USERS_LIST_CIENT",
  CLIENT_USERS_LIST_ETA_ACCESS = "CLIENT_USERS_LIST_ETA_ACCESS",
  CLIENT_USERS_LIST_CLOSE_TICKET_ACCESS = "CLIENT_USERS_LIST_CLOSE_TICKET_ACCESS",

  //CURRENCY Action
  ACTION_CURRENCY_LIST = "ACTION_CURRENCY_LIST",
  ACTION_CURRENCY_ADD = "ACTION_CURRENCY_ADD",
  ACTION_CURRENCY_EDIT = "ACTION_CURRENCY_EDIT",
  ACTION_CURRENCY_DEACTIVE = "ACTION_CURRENCY_DEACTIVE",
  ACTION_CURRENCY_DELETE = "ACTION_CURRENCY_DELETE",

  // ACTION FEILDS
  CURRENCY_LIST_CURRENCY_NAME = "CURRENCY_LIST_CURRENCY_NAME",
  CURRENCY_LIST_SYMBOL = "CURRENCY_LIST_SYMBOL",

  //CAMPAIGN Action
  ACTION_CAMPAIGN_LIST = "ACTION_CAMPAIGN_LIST",
  ACTION_CAMPAIGN_ADD = "ACTION_CAMPAIGN_ADD",
  ACTION_CAMPAIGN_EDIT = "ACTION_CAMPAIGN_EDIT",
  ACTION_CAMPAIGN_DELETE = "ACTION_CAMPAIGN_DELETE",

  // ACTION FEILDS
  CAMPAIGN_LIST_CAMPAIGN_NAME = "CAMPAIGN_LIST_CAMPAIGN_NAME",
  CAMPAIGN_LIST_CAMPAIGN_GROUP_NAME = "CAMPAIGN_LIST_CAMPAIGN_GROUP_NAME",
  CAMPAIGN_LIST_CHANNELS_NAME = "CAMPAIGN_LIST_CHANNELS_NAME",

  //CAMPAIGN_GROUP Action
  ACTION_CAMPAIGN_GROUP_LIST = "ACTION_CAMPAIGN_GROUP_LIST",
  ACTION_CAMPAIGN_GROUP_ADD = "ACTION_CAMPAIGN_GROUP_ADD",
  ACTION_CAMPAIGN_GROUP_EDIT = "ACTION_CAMPAIGN_GROUP_EDIT",
  ACTION_CAMPAIGN_GROUP_DELETE = "ACTION_CAMPAIGN_GROUP_DELETE",

  // ACTION FEILDS
  CAMPAIGN_GROUP_LIST_CAMPAIGN_GROUP_NAME = "CAMPAIGN_GROUP_LIST_CAMPAIGN_GROUP_NAME",

  //CHANNEL Action
  ACTION_CHANNEL_LIST = "ACTION_CHANNEL_LIST",
  ACTION_CHANNEL_ADD = "ACTION_CHANNEL_ADD",
  ACTION_CHANNEL_EDIT = "ACTION_CHANNEL_EDIT",
  ACTION_CHANNEL_DELETE = "ACTION_CHANNEL_DELETE",

  // ACTION FEILDS
  CHANNEL_LIST_CHANNEL_NAME = "CHANNEL_LIST_CHANNEL_NAME",
  CHANNEL_LIST_CHANNEL_GROUP_NAME = "CHANNEL_LIST_CHANNEL_GROUP_NAME",

  //CHANNEL_GROUP Action
  ACTION_CHANNEL_GROUP_LIST = "ACTION_CHANNEL_GROUP_LIST",
  ACTION_CHANNEL_GROUP_ADD = "ACTION_CHANNEL_GROUP_ADD",
  ACTION_CHANNEL_GROUP_EDIT = "ACTION_CHANNEL_GROUP_EDIT",
  ACTION_CHANNEL_GROUP_DELETE = "ACTION_CHANNEL_GROUP_DELETE",

  // ACTION FEILDS
  CHANNEL_GROUP_LIST_CHANNEL_GROUP_NAME = "CHANNEL_GROUP_LIST_CHANNEL_GROUP_NAME",

  //DELIVERY_DATE Action
  ACTION_DELIVERY_DATE_LIST = "ACTION_DELIVERY_DATE_LIST",
  ACTION_DELIVERY_DATE_ADD = "ACTION_DELIVERY_DATE_ADD",
  ACTION_DELIVERY_DATE_EDIT = "ACTION_DELIVERY_DATE_EDIT",
  ACTION_DELIVERY_DATE_MARK_AS_DONE = "ACTION_DELIVERY_DATE_MARK_AS_DONE",
  ACTION_DELIVERY_DATE_DELETE = "ACTION_DELIVERY_DATE_DELETE",

  // ACTION FEILDS
  DELIVERY_DATE_LIST_DATE_TIME = "DELIVERY_DATE_LIST_DATE_TIME",
  DELIVERY_DATE_LIST_CLINET_NAME = "DELIVERY_DATE_LIST_CLINET_NAME",
  DELIVERY_DATE_LIST_PROJECT_NAME = "DELIVERY_DATE_LIST_PROJECT_NAME",
  DELIVERY_DATE_LIST_DELIVERY_DATE = "DELIVERY_DATE_LIST_DELIVERY_DATE",
  DELIVERY_DATE_LIST_STATUS = "DELIVERY_DATE_LIST_STATUS",
  DELIVERY_DATE_LIST_REMARK = "DELIVERY_DATE_LIST_REMARK",

  //DEPLOYMENT_REQUEST Action
  ACTION_DEPLOYMENT_REQUEST_LIST = "ACTION_DEPLOYMENT_REQUEST_LIST",
  ACTION_DEPLOYMENT_REQUEST_ADD = "ACTION_DEPLOYMENT_REQUEST_ADD",
  ACTION_DEPLOYMENT_REQUEST_EDIT = "ACTION_DEPLOYMENT_REQUEST_EDIT",
  ACTION_DEPLOYMENT_REQUEST_CLOSE = "ACTION_DEPLOYMENT_REQUEST_CLOSE",
  ACTION_DEPLOYMENT_REQUEST_DELETE = "ACTION_DEPLOYMENT_REQUEST_DELETE",

  // ACTION FEILDS
  DEPLOYMENT_REQUEST_LIST_PROJECT = "DEPLOYMENT_REQUEST_LIST_PROJECT",
  DEPLOYMENT_REQUEST_LIST_GIT_REPO = "DEPLOYMENT_REQUEST_LIST_GIT_REPO",
  DEPLOYMENT_REQUEST_LIST_SERVER = "DEPLOYMENT_REQUEST_LIST_SERVER",
  DEPLOYMENT_REQUEST_LIST_TEAM_MEMBER = "DEPLOYMENT_REQUEST_LIST_TEAM_MEMBER",

  //CHANGE_REQUEST Action
  ACTION_CHANGE_REQUEST_LIST = "ACTION_CHANGE_REQUEST_LIST",
  ACTION_CHANGE_REQUEST_ADD = "ACTION_CHANGE_REQUEST_ADD",
  ACTION_CHANGE_REQUEST_EDIT = "ACTION_CHANGE_REQUEST_EDIT",
  ACTION_CHANGE_REQUEST_DELETE = "ACTION_CHANGE_REQUEST_DELETE",
  ACTION_CHANGE_REQUEST_REQUEST_APPROVAL = "ACTION_CHANGE_REQUEST_REQUEST_APPROVAL",
  ACTION_CHANGE_REQUEST_PREPARE_CHANGE_REQUEST = "ACTION_CHANGE_REQUEST_PREPARE_CHANGE_REQUEST",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW = "ACTION_CHANGE_REQUEST_SINGLE_VIEW",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS",

  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_SINGLE_VIEW = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_SINGLE_VIEW",

  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TIME_LOGS = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TIME_LOGS",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_SUMMARY = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_SUMMARY",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_CHANGE_REQUEST_LOGS = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_CHANGE_REQUEST_LOGS",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TIME_QUOTATION = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TIME_QUOTATION",

  // ACTION FEILDS
  CHANGE_REQUEST_LIST_CREATED_DATE = "CHANGE_REQUEST_LIST_CREATED_DATE",
  CHANGE_REQUEST_LIST_CHANGE_REQUEST_NAME = "CHANGE_REQUEST_LIST_CHANGE_REQUEST_NAME",
  CHANGE_REQUEST_LIST_PROJECT_NAME = "CHANGE_REQUEST_LIST_PROJECT_NAME",
  CHANGE_REQUEST_LIST_TOTAL_TICKET = "CHANGE_REQUEST_LIST_TOTAL_TICKET",
  CHANGE_REQUEST_LIST_APPROVAL_STATUS = "CHANGE_REQUEST_LIST_APPROVAL_STATUS",
  CHANGE_REQUEST_LIST_NO_OF_DEVELOPER = "CHANGE_REQUEST_LIST_NO_OF_DEVELOPER",

  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_ID = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_ID",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_DATE_TIME = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_DATE_TIME",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_TITLE = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_TITLE",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_PROJECT = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_PROJECT",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_PRIORITY = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_PRIORITY",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_CREATED_BY = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_CREATED_BY",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_TYPE = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_TYPE",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_STAGE = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_STAGE",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_STATUS = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_STATUS",
  ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_UNSEEN_MESSAGE = "ACTION_CHANGE_REQUEST_SINGLE_VIEW_TICKET_DETAILS_LIST_UNSEEN_MESSAGE",

  //DOMAIN Action
  ACTION_DOMAIN_LIST = "ACTION_DOMAIN_LIST",
  ACTION_DOMAIN_ADD = "ACTION_DOMAIN_ADD",
  ACTION_DOMAIN_EDIT = "ACTION_DOMAIN_EDIT",
  ACTION_DOMAIN_DEACTIVE = "ACTION_DOMAIN_DEACTIVE",

  // ACTION FEILDS
  DOMAIN_LIST_DOMAIN_NAME = "DOMAIN_LIST_DOMAIN_NAME",

  //DOUBTS Action

  ACTION_DOUBTS_OPEN_LIST = "ACTION_DOUBTS_OPEN_LIST",
  ACTION_DOUBTS_OPEN_ADD = "ACTION_DOUBTS_OPEN_ADD",
  ACTION_DOUBTS_OPEN_EDIT = "ACTION_DOUBTS_OPEN_EDIT",
  ACTION_DOUBTS_OPEN_SINGLE_VIEW = "ACTION_DOUBTS_OPEN_SINGLE_VIEW",
  ACTION_DOUBTS_CLOSE_LIST = "ACTION_DOUBTS_CLOSE_LIST",
  ACTION_DOUBTS_CLOSE_SINGLE_VIEW = "ACTION_DOUBTS_CLOSE_SINGLE_VIEW",

  //DUMPS Action
  ACTION_DUMPS_LIST = "ACTION_DUMPS_LIST",
  ACTION_DUMPS_ADD = "ACTION_DUMPS_ADD",
  ACTION_DUMPS_EDIT = "ACTION_DUMPS_EDIT",

  // ACTION FEILDS
  DUMPS_LIST_TITLE_NAME = "DUMPS_LIST_TITLE_NAME",
  DUMPS_LIST_PROJECT_NAME = "DUMPS_LIST_PROJECT_NAME",
  DUMPS_LIST_LAYER_REMARK = "DUMPS_LIST_LAYER_REMARK",

  //FEATURES Action
  ACTION_FEATURES_LIST = "ACTION_FEATURES_LIST",
  ACTION_FEATURES_ADD = "ACTION_FEATURES_ADD",
  ACTION_FEATURES_EDIT = "ACTION_FEATURES_EDIT",
  ACTION_FEATURES_DELETE = "ACTION_FEATURES_DELETE",
  ACTION_FEATURES_DUPLICATE = "ACTION_FEATURES_DUPLICATE",

  // ACTION FEILDS

  //FOLLOW_UP Action
  ACTION_FOLLOW_UP_LIST = "ACTION_FOLLOW_UP_LIST",
  // ACTION_FOLLOW_UP_ADD = "ACTION_FOLLOW_UP_ADD",
  // ACTION_FOLLOW_UP_EDIT = "ACTION_FOLLOW_UP_EDIT",
  // ACTION_FOLLOW_UP_DELETE = "ACTION_FOLLOW_UP_DELETE",

  // ACTION FEILDS
  FOLLOW_UP_LIST_TITLE_NAME = "FOLLOW_UP_LIST_TITLE_NAME",
  FOLLOW_UP_LIST_NAME = "FOLLOW_UP_LIST_NAME",
  FOLLOW_UP_LIST_MOBILE_NUMBER = "FOLLOW_UP_LIST_MOBILE_NUMBER",
  FOLLOW_UP_LIST_CREATED_AT = "FOLLOW_UP_LIST_CREATED_AT",
  FOLLOW_UP_LIST_DATE_TIME = "FOLLOW_UP_LIST_DATE_TIME",
  FOLLOW_UP_LIST_SUMMARY = "FOLLOW_UP_LIST_SUMMARY",

  //GIT_REPO Action
  ACTION_GIT_REPO_LIST = "ACTION_GIT_REPO_LIST",
  ACTION_GIT_REPO_ADD = "ACTION_GIT_REPO_ADD",
  ACTION_GIT_REPO_EDIT = "ACTION_GIT_REPO_EDIT",
  ACTION_GIT_REPO_DELETE = "ACTION_GIT_REPO_DELETE",

  // ACTION FEILDS
  GIT_REPO_LIST_GIT_REPO = "GIT_REPO_LIST_GIT_REPO",
  GIT_REPO_LIST_PROJECT = "GIT_REPO_LIST_PROJECT",
  GIT_REPO_LIST_URL = "GIT_REPO_LIST_URL",

  //HOLIDAY_CALENDAR Action
  ACTION_HOLIDAY_CALENDAR_LIST = "ACTION_HOLIDAY_CALENDAR_LIST",
  ACTION_HOLIDAY_CALENDAR_ADD = "ACTION_HOLIDAY_CALENDAR_ADD",
  ACTION_HOLIDAY_CALENDAR_EDIT = "ACTION_HOLIDAY_CALENDAR_EDIT",
  ACTION_HOLIDAY_CALENDAR_DELETE = "ACTION_HOLIDAY_CALENDAR_DELETE",

  // ACTION FEILDS
  HOLIDAY_CALENDAR_LIST_DATE = "HOLIDAY_CALENDAR_LIST_DATE",
  HOLIDAY_CALENDAR_LIST_HOLIDAY_NAME = "HOLIDAY_CALENDAR_LIST_HOLIDAY_NAME",
  HOLIDAY_CALENDAR_LIST_OPTIONAL = "HOLIDAY_CALENDAR_LIST_OPTIONAL",

  //LEAD_LABEL Action
  ACTION_LEAD_LABEL_LIST = "ACTION_LEAD_LABEL_LIST",
  ACTION_LEAD_LABEL_ADD = "ACTION_LEAD_LABEL_ADD",
  ACTION_LEAD_LABEL_EDIT = "ACTION_LEAD_LABEL_EDIT",
  ACTION_LEAD_LABEL_DEACTIVE = "ACTION_LEAD_LABEL_DEACTIVE",

  // ACTION FEILDS
  LEAD_LABEL_LIST_LEAD_LABEL_NAME = "LEAD_LABEL_LIST_LEAD_LABEL_NAME",

  //LEADS Action
  ACTION_LEADS_LIST = "ACTION_LEADS_LIST",
  ACTION_LEADS_ADD = "ACTION_LEADS_ADD",
  ACTION_LEADS_EDIT = "ACTION_LEADS_EDIT",
  ACTION_LEADS_DELETE = "ACTION_LEADS_DELETE",
  ACTION_LEADS_DOWNLOAD_TEMPLATE = "ACTION_LEADS_DOWNLOAD_TEMPLATE",
  ACTION_LEADS_ADD_LEADS_CSV_FILES = "ACTION_LEADS_ADD_LEADS_CSV_FILES",
  ACTION_LEADS_SINGLE_VIEW = "ACTION_LEADS_SINGLE_VIEW",
  ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP = "ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP",
  ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP_ADD = "ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP_ADD",
  ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP_EDIT = "ACTION_LEADS_SINGLE_VIEW_FOLLOW_UP_EDIT",

  ACTION_LEADS_SINGLE_VIEW_DETAILS = "ACTION_LEADS_SINGLE_VIEW_DETAILS",

  // ACTION FEILDS
  LEADS_LIST_STARED = "LEADS_LIST_STARED",
  LEADS_LIST_DATE_TIME = "LEADS_LIST_DATE_TIME",
  LEADS_LIST_NAME = "LEADS_LIST_NAME",
  LEADS_LIST_MOBILE = "LEADS_LIST_MOBILE",
  LEADS_LIST_ORGANIZATION = "LEADS_LIST_ORGANIZATION",
  LEADS_LIST_DOMAINS = "LEADS_LIST_DOMAINS",
  LEADS_LIST_COUNTRY = "LEADS_LIST_COUNTRY",
  LEADS_LIST_LEADS_LABEL = "LEADS_LIST_LEADS_LABEL",
  LEADS_LIST_LEADS_FOLLOW_UP_DATE_TIME = "LEADS_LIST_LEADS_FOLLOW_UP_DATE_TIME",

  //LEAVE_REQUEST Action
  ACTION_LEAVE_REQUEST_LIST = "ACTION_LEAVE_REQUEST_LIST",
  ACTION_LEAVE_REQUEST_ADD = "ACTION_LEAVE_REQUEST_ADD",
  ACTION_LEAVE_REQUEST_EDIT = "ACTION_LEAVE_REQUEST_EDIT",
  ACTION_LEAVE_REQUEST_UPDATE = "ACTION_LEAVE_REQUEST_UPDATE",
  ACTION_LEAVE_REQUEST_DELETE = "ACTION_LEAVE_REQUEST_DELETE",
  ACTION_LEAVE_REQUEST_SINGLE_VIEW = "ACTION_LEAVE_REQUEST_SINGLE_VIEW",

  // ACTION FEILDS
  LEAVE_REQUEST_LIST_REQUESTED_DATE = "LEAVE_REQUEST_LIST_REQUESTED_DATE",
  LEAVE_REQUEST_LIST_REQUESTED_BY = "LEAVE_REQUEST_LIST_REQUESTED_BY",
  LEAVE_REQUEST_LIST_LEAVE_TYPE = "LEAVE_REQUEST_LIST_LEAVE_TYPE",
  LEAVE_REQUEST_LIST_LEAVE_DAYS = "LEAVE_REQUEST_LIST_LEAVE_DAYS",
  LEAVE_REQUEST_LIST_LEAVING_DATE = "LEAVE_REQUEST_LIST_LEAVING_DATE",
  LEAVE_REQUEST_LIST_REPORTING_DATE = "LEAVE_REQUEST_LIST_REPORTING_DATE",
  LEAVE_REQUEST_LIST_REASON = "LEAVE_REQUEST_LIST_REASON",
  LEAVE_REQUEST_LIST_APPROVALS = "LEAVE_REQUEST_LIST_APPROVALS",

  //LOCATION Action
  ACTION_LOCATION_LIST = "ACTION_LOCATION_LIST",
  ACTION_LOCATION_ADD = "ACTION_LOCATION_ADD",
  ACTION_LOCATION_EDIT = "ACTION_LOCATION_EDIT",
  ACTION_LOCATION_DELETE = "ACTION_LOCATION_DELETE",

  ACTION_COUNTRY_ADD = "ACTION_COUNTRY_ADD",
  ACTION_STATE_ADD = "ACTION_STATE_ADD",
  ACTION_CITY_ADD = "ACTION_CITY_ADD",

  // ACTION FEILDS

  //MEETING Action
  ACTION_MEETING_LIST = "ACTION_MEETING_LIST",
  ACTION_MEETING_ADD = "ACTION_MEETING_ADD",
  ACTION_MEETING_EDIT = "ACTION_MEETING_EDIT",
  ACTION_MEETING_DELETE = "ACTION_MEETING_DELETE",
  ACTION_MEETING_SINGLE_VIEW = "ACTION_MEETING_SINGLE_VIEW",

  ACTION_MEETING_SINGLE_VIEW_AGENDA = "ACTION_MEETING_SINGLE_VIEW_AGENDA",
  ACTION_MEETING_SINGLE_VIEW_ATTENDEES = "ACTION_MEETING_SINGLE_VIEW_ATTENDEES",
  ACTION_MEETING_SINGLE_VIEW_NOTES = "ACTION_MEETING_SINGLE_VIEW_NOTES",
  ACTION_MEETING_SINGLE_VIEW_MOM = "ACTION_MEETING_SINGLE_VIEW_MOM",

  // ACTION FEILDS
  MEETING_LIST_SCHEDULED_DATE_TIME = "MEETING_LIST_SCHEDULED_DATE_TIME",
  MEETING_LIST_ACTUAL_END_DATE_TIME = "MEETING_LIST_ACTUAL_END_DATE_TIME",
  MEETING_LIST_PROJECT = "MEETING_LIST_PROJECT",
  MEETING_LIST_TITLE = "MEETING_LIST_TITLE",
  MEETING_LIST_MODE = "MEETING_LIST_MODE",
  MEETING_LIST_STATUS = "MEETING_LIST_STATUS",

  //MODULES Action
  ACTION_MODULES_LIST = "ACTION_MODULES_LIST",
  ACTION_MODULES_ADD = "ACTION_MODULES_ADD",
  ACTION_MODULES_EDIT = "ACTION_MODULES_EDIT",

  // ACTION FEILDS
  MODULES_LIST_MODULE = "MODULES_LIST_MODULE",
  MODULES_LIST_PROJECT = "MODULES_LIST_PROJECT",

  //PROJECTS Action
  ACTION_PROJECT_LIST = "ACTION_PROJECT_LIST",
  ACTION_PROJECT_ADD = "ACTION_PROJECT_ADD",
  ACTION_PROJECT_EDIT = "ACTION_PROJECT_EDIT",
  ACTION_PROJECT_DELETE = "ACTION_PROJECT_DELETE",
  ACTION_PROJECTS_VIEW = "ACTION_PROJECTS_VIEW",

  ACTION_PROJECTS_VIEW_DETAILS = "ACTION_PROJECTS_VIEW_DETAILS",
  ACTION_PROJECTS_VIEW_TIME_ANALYTICS = "ACTION_PROJECTS_VIEW_TIME_ANALYTICS",
  ACTION_PROJECTS_VIEW_STATUS = "ACTION_PROJECTS_VIEW_STATUS",
  ACTION_PROJECTS_VIEW_RESOURCE_REQUEST = "ACTION_PROJECTS_VIEW_RESOURCE_REQUEST",
  ACTION_PROJECTS_VIEW_FLOW = "ACTION_PROJECTS_VIEW_FLOW",
  ACTION_PROJECTS_VIEW_MEMBERS = "ACTION_PROJECTS_VIEW_MEMBERS",
  ACTION_PROJECTS_VIEW_FLOW_ADD = "ACTION_PROJECTS_VIEW_FLOW_ADD",

  ACTION_PROJECTS_VIEW_DETAILS_FEATURES_DETAILS_TAB = "ACTION_PROJECTS_VIEW_DETAILS_FEATURES_DETAILS_TAB",
  ACTION_PROJECTS_VIEW_DETAILS_TASK_TAB = "ACTION_PROJECTS_VIEW_DETAILS_TASK_TAB",
  ACTION_PROJECTS_VIEW_DETAILS_CHANFGE_REQUEST_TAB = "ACTION_PROJECTS_VIEW_DETAILS_CHANFGE_REQUEST_TAB",

  ACTION_PROJECTS_VIEW_TIME_ANALYTICS_TASKS_TAB = "ACTION_PROJECTS_VIEW_TIME_ANALYTICS_TASKS_TAB",
  ACTION_PROJECTS_VIEW_TIME_ANALYTICS_MEETINGS_TAB = "ACTION_PROJECTS_VIEW_TIME_ANALYTICS_MEETINGS_TAB",

  // ACTION FEILDS
  PROJECTS_LIST_PROJECT_NAME = "PROJECTS_LIST_PROJECT_NAME",
  PROJECTS_LIST_CLIENT = "PROJECTS_LIST_CLIENT",

  //RESOURCE_REQUEST_OPEN Action
  ACTION_RESOURCE_REQUEST_LIST = "ACTION_RESOURCE_REQUEST_LIST",
  ACTION_RESOURCE_REQUEST_ADD = "ACTION_RESOURCE_REQUEST_ADD",
  ACTION_RESOURCE_REQUEST_EDIT = "ACTION_RESOURCE_REQUEST_EDIT",
  ACTION_RESOURCE_REQUEST_VIEW = "ACTION_RESOURCE_REQUEST_VIEW",
  ACTION_RESOURCE_REQUEST_SINGLE_VIEW = "ACTION_RESOURCE_REQUEST_SINGLE_VIEW",
  ACTION_RESOURCE_REQUEST_CLOSE = "ACTION_RESOURCE_REQUEST_CLOSE_CLOSE",
  ACTION_RESOURCE_REQUEST_ADD_RESOURCE = "ACTION_RESOURCE_REQUEST_CLOSE_ADD_RESOURCE",

  //RESOURCE_REQUEST_CLOSE Action

  ACTION_RESOURCE_REQUEST_CLOSE_VIEW = "ACTION_RESOURCE_REQUEST_CLOSE_VIEW",
  ACTION_RESOURCE_REQUEST_CLOSE_EDIT = "ACTION_RESOURCE_REQUEST_CLOSE_EDIT",

  // ACTION FEILDS
  RESOURCE_REQUEST_LIST_DATE = "RESOURCE_REQUEST_LIST_DATE",
  RESOURCE_REQUEST_LIST_RESOURCE_NAME = "RESOURCE_REQUEST_LIST_RESOURCE_NAME",
  RESOURCE_REQUEST_LIST_CREATED_BY = "RESOURCE_REQUEST_LIST_CREATED_BY",
  RESOURCE_REQUEST_LIST_PROJECT_NAME = "RESOURCE_REQUEST_LIST_PROJECT_NAME",
  RESOURCE_REQUEST_LIST_DESCRIPTION = "RESOURCE_REQUEST_LIST_DESCRIPTION",
  RESOURCE_REQUEST_LIST_REQUESTED_FROM = "RESOURCE_REQUEST_LIST_REQUESTED_FROM",
  RESOURCE_REQUEST_LIST_STATUS = "RESOURCE_REQUEST_LIST_STATUS",
  RESOURCE_REQUEST_LIST_REMARK = "RESOURCE_REQUEST_LIST_REMARK",

  //SERVER Action
  ACTION_SERVER_LIST = "ACTION_SERVER_LIST",
  ACTION_SERVER_ADD = "ACTION_SERVER_ADD",
  ACTION_SERVER_EDIT = "ACTION_SERVER_EDIT",
  ACTION_SERVER_DELETE = "ACTION_SERVER_DELETE",

  // ACTION FEILDS
  SERVER_LIST_SERVER_NAME = "SERVER_LIST_SERVER_NAME",
  SERVER_LIST_PROJECT = "SERVER_LIST_PROJECT",
  SERVER_LIST_SERVER_CONFIG = "SERVER_LIST_SERVER_CONFIG",

  //SOCIAL_MEDIA Action
  ACTION_SOCIAL_MEDIA_LIST = "ACTION_SOCIAL_MEDIA_LIST",
  ACTION_SOCIAL_MEDIA_ADD = "ACTION_SOCIAL_MEDIA_ADD",
  ACTION_SOCIAL_MEDIA_EDIT = "ACTION_SOCIAL_MEDIA_EDIT",
  ACTION_SOCIAL_MEDIA_DEACTIVE = "ACTION_SOCIAL_MEDIA_DEACTIVE",

  // ACTION FEILDS
  SOCIAL_MEDIA_LIST_SOCIAL_MEDIA_NAME = "SOCIAL_MEDIA_LIST_SOCIAL_MEDIA_NAME",

  //TASK Action
  ACTION_TASK_LIST = "ACTION_TASK_LIST",
  ACTION_TASK_ADD = "ACTION_TASK_ADD",
  ACTION_TASK_EDIT = "ACTION_TASK_EDIT",
  ACTION_TASK_DELETE = "ACTION_TASK_DELETE",
  ACTION_TASK_DUPLICATE = "ACTION_TASK_DUPLICATE",
  ACTION_TASK_MOVE_TO_ARCHIVE = "ACTION_TASK_MOVE_TO_ARCHIVE",
  ACTION_TASK_SINGLE_VIEW = "ACTION_TASK_SINGLE_VIEW",

  //TEAMS Action
  ACTION_TEAMS_LIST = "ACTION_TEAMS_LIST",
  ACTION_TEAMS_ADD = "ACTION_TEAMS_ADD",
  ACTION_TEAMS_EDIT = "ACTION_TEAMS_EDIT",
  ACTION_TEAMS_CHANGE_STATUS = "ACTION_TEAMS_CHANGE_STATUS",
  ACTION_TEAMS_SALARY_CONFIGURE = "ACTION_TEAMS_SALARY_CONFIGURE",

  ACTION_TEAMS_VIEW = "ACTION_TEAMS_VIEW",
  ACTION_TEAMS_VIEW_TRANSACTION_HISTORY_TAB = "ACTION_TEAMS_VIEW_TRANSACTION_HISTORY_TAB",
  ACTION_TEAMS_VIEW_SALARY_SUMMARY_TAB = "ACTION_TEAMS_VIEW_SALARY_SUMMARY_TAB",
  ACTION_TEAMS_VIEW_SALARY_SUMMARY_TAB_ADD_INCREMENT = "ACTION_TEAMS_VIEW_SALARY_SUMMARY_TAB_ADD_INCREMENT",
  ACTION_TEAMS_VIEW_DOCUMENTS_TAB = "ACTION_TEAMS_VIEW_DOCUMENTS_TAB",
  ACTION_TEAMS_VIEW_DOCUMENTS_TAB_ADD_DOCUMENTS = "ACTION_TEAMS_VIEW_DOCUMENTS_TAB_ADD_DOCUMENTS",
  ACTION_TEAMS_VIEW_PROFILE_TAB = "ACTION_TEAMS_VIEW_PROFILE_TAB",

  // ACTION FEILDS
  TEAM_LIST_NAME = "TEAM_LIST_NAME",
  TEAM_LIST_PROJECT_NUMBER = "TEAM_LIST_PROJECT_NUMBER",
  TEAM_LIST_LAYER_EMAIL = "TEAM_LIST_LAYER_EMAIL",
  TEAM_LIST_LAYER_SALARY = "TEAM_LIST_LAYER_SALARY",
  TEAM_LIST_LAYER_DOB_AS_PER_DOCUMENTS = "TEAM_LIST_LAYER_DOB_AS_PER_DOCUMENTS",
  TEAM_LIST_LAYER_DOB_AS_PER_ACTULAY = "TEAM_LIST_LAYER_DOB_AS_PER_ACTULAY",
  TEAM_LIST_LAYER_JOINING_DATE = "TEAM_LIST_LAYER_JOINING_DATE",

  TEAM_LIST_LAYER_POSITION = "TEAM_LIST_LAYER_POSITION",
  TEAM_LIST_LAYER_DEPARTMENT = "TEAM_LIST_LAYER_DEPARTMENT",
  TEAM_LIST_LAYER_ISACTIVE = "TEAM_LIST_LAYER_ISACTIVE",

  //TEAM_VIEW Action
  ACTION_TEAM_VIEW_ALL_TASK = "ACTION_TEAM_VIEW_ALL_TASK",
  ACTION_TEAM_VIEW_INPROGRESS = "ACTION_TEAM_VIEW_INPROGRESS",
  ACTION_TEAM_VIEW_COMPLETE = "ACTION_TEAM_VIEW_COMPLETE",

  //TIKCETS Action
  ACTION_TIKCETS_LIST = "ACTION_TIKCETS_LIST",
  ACTION_TIKCETS_ADD = "ACTION_TIKCETS_ADD",
  ACTION_TIKCETS_EDIT = "ACTION_TIKCETS_EDIT",
  // ACTION_TIKCETS_DELETE = "ACTION_TIKCETS_DELETE",
  ACTION_TIKCETS_DUPLICATE = "ACTION_TIKCETS_DUPLICATE",
  ACTION_TIKCETS_MOVE_TO_ARCHIVE = "ACTION_TIKCETS_MOVE_TO_ARCHIVE",
  ACTION_TIKCETS_SINGLE_VIEW = "ACTION_TIKCETS_SINGLE_VIEW",

  ACTION_TIKCETS_SINGLE_VIEW_TICKET_DETAILS = "ACTION_TIKCETS_SINGLE_VIEW_TICKET_DETAILS",
  ACTION_TIKCETS_SINGLE_VIEW_SUMMARY = "ACTION_TIKCETS_SINGLE_VIEW_SUMMARY",
  ACTION_TIKCETS_SINGLE_VIEW_CHAT = "ACTION_TIKCETS_SINGLE_VIEW_CHAT",
  ACTION_TIKCETS_SINGLE_VIEW_TASK = "ACTION_TIKCETS_SINGLE_VIEW_TASK",
  ACTION_TIKCETS_SINGLE_VIEW_TIME_ANALYIS = "ACTION_TIKCETS_SINGLE_VIEW_TIME_ANALYIS",
  ACTION_TIKCETS_SINGLE_VIEW_CLOSE_SUMMARY = "ACTION_TIKCETS_SINGLE_VIEW_CLOSE_SUMMARY",
  ACTION_TIKCETS_SINGLE_VIEW_ISSUES = "ACTION_TIKCETS_SINGLE_VIEW_ISSUES",

  // ACTION FEILDS
  TIKCETS_LIST_TICKET_NUMBER = "TIKCETS_LIST_TICKET_NUMBER",
  TIKCETS_LIST_DATE_TIME = "TIKCETS_LIST_DATE_TIME",
  TIKCETS_LIST_TITLE = "TIKCETS_LIST_TITLE",
  TIKCETS_LIST_PROJECT = "TIKCETS_LIST_PROJECT",
  TIKCETS_LIST_CREATED_BY = "TIKCETS_LIST_CREATED_BY",
  TIKCETS_LIST_TYPE = "TIKCETS_LIST_TYPE",
  TIKCETS_LIST_PRIORITY = "TIKCETS_LIST_PRIORITY",
  TIKCETS_LIST_STAGE = "TIKCETS_LIST_STAGE",
  TIKCETS_LIST_STATUS = "TIKCETS_LIST_STATUS",

  // Total Attendance Action
  ACTION_TEAM_ATTENDANCE_LIST = "ACTION_TEAM_ATTENDANCE_LIST",
  ACTION_TEAM_ATTENDANCE_EDIT = "ACTION_TEAM_ATTENDANCE_EDIT",

  //DEPARTMENT Action
  ACTION_DEPARTMENT_LIST = "ACTION_DEPARTMENT_LIST",
  ACTION_DEPARTMENT_ADD = "ACTION_DEPARTMENT_ADD",
  ACTION_DEPARTMENT_EDIT = "ACTION_DEPARTMENT_EDIT",
  ACTION_DEPARTMENT_DEACTIVE = "ACTION_DEPARTMENT_DEACTIVE",
  ACTION_DEPARTMENT_DELETE = "ACTION_DEPARTMENT_DELETE",

  // ACTION FEILDS
  DEPARTMENT_LIST_DEPARTMENT_NAME = "DEPARTMENT_LIST_DEPARTMENT_NAME",

  //ROLES Action
  ACTION_ROLES_LIST = "ACTION_ROLES_LIST",
  ACTION_ROLES_ADD = "ACTION_ROLES_ADD",
  ACTION_ROLES_EDIT = "ACTION_ROLES_EDIT",
  ACTION_ROLES_DELETE = "ACTION_ROLES_DELETE",

  // ACTION FEILDS
  ROLES_LIST_POSITION = "ROLES_LIST_POSITION",
  ROLES_LIST_DEPARTMENT = "ROLES_LIST_DEPARTMENT",

  // ATTENDANCE ACTION

  // ACTION FEILDS
  ATTENDANCE_LIST_IS_PRESENT = "ATTENDANCE_LIST_IS_PRESENT",
  ATTENDANCE_LIST_TEAM_MEMBER_NAME = "ATTENDANCE_LIST_TEAM_MEMBER_NAME",
  ATTENDANCE_LIST_WORKING_FROM = "ATTENDANCE_LIST_WORKING_FROM",
  ATTENDANCE_LIST_PINCHED_IN_TIME = "ATTENDANCE_LIST_PINCHED_IN_TIME",
  ATTENDANCE_LIST_PINCHED_OUT_TIME = "ATTENDANCE_LIST_PINCHED_OUT_TIME",

  //PRODUCT Action
  ACTION_PRODUCT_LIST = "ACTION_PRODUCT_LIST",
  ACTION_PRODUCT_ADD = "ACTION_PRODUCT_ADD",
  ACTION_PRODUCT_EDIT = "ACTION_PRODUCT_EDIT",
  ACTION_PRODUCT_VIEW = "ACTION_PRODUCT_VIEW",
  ACTION_PRODUCT_PLANS_TAB = "ACTION_PRODUCT_PLANS_TAB",
  ACTION_PRODUCT_VIEW_CUSTOMERS = "ACTION_PRODUCT_VIEW_CUSTOMERS",

  //PRDOCUT ACTION FIELD
  PRODUCT_LIST_PRODUCT_NAME = "PRODUCT_LIST_PRODUCT_NAME",
  PRODUCT_LIST_AMOUNT = "PRODUCT_LIST_AMOUNT",

  // SUBSCRIPTION ACTION FIELD
  ACTION_SUBSCRIPTION_LIST = "ACTION_SUBSCRIPTION_LIST",
  ACTION_SUBSCRIPTION_ADD = "ACTION_SUBSCRIPTION_ADD",
  ACTION_SUBSCRIPTION_EDIT = "ACTION_SUBSCRIPTION_EDIT",
  ACTION_SUBSCRIPTION_DELETE = "ACTION_SUBSCRIPTION_DELETE",

  // SUBSCRIPTION ACTION FIELD
  SUBSCRIPTION_LIST_TITLE = "SUBSCRIPTION_LIST_TITLE",
  SUBSCRIPTION_LIST_AMOUNT = "SUBSCRIPTION_LIST_AMOUNT",
  SUBSCRIPTION_LIST_RECURRANCE_TYPE = "SUBSCRIPTION_LIST_RECURRANCE_TYPE",

  // RECURRING ACTION FIELD
  ACTION_RECURRING_PAYMENTS_LIST = "ACTION_RECURRING_PAYMENTS_LIST",
  ACTION_RECURRING_PAYMENTS_ADD = "ACTION_RECURRING_PAYMENTS_ADD",

  RECURRING_PAYMENTS_LIST_CLIENT_NAME = "RECURRING_PAYMENTS_LIST_CLIENT_NAME",
  RECURRING_PAYMENTS_LIST_START_DATE = "RECURRING_PAYMENTS_LIST_START_DATE",
  RECURRING_PAYMENTS_LIST_EXPIRY_DATE = "RECURRING_PAYMENTS_LIST_EXPIRY_DATE",
  RECURRING_PAYMENTS_LIST_AMOUNT = "RECURRING_PAYMENTS_LIST_AMOUNT",
  RECURRING_PAYMENTS_LIST_TAX_AMOUNT = "RECURRING_PAYMENTS_LIST_TAX_AMOUNT",
  RECURRING_PAYMENTS_LIST_TDS_AMOUNT = "RECURRING_PAYMENTS_LIST_TDS_AMOUNT",
  RECURRING_PAYMENTS_LIST_CONVERSION_LOSS = "RECURRING_PAYMENTS_LIST_CONVERSION_LOSS",
  RECURRING_PAYMENTS_LIST_TOTAL_AMOUNT = "RECURRING_PAYMENTS_LIST_TOTAL_AMOUNT",
  RECURRING_PAYMENTS_LIST_RECURRANCE_TYPE = "RECURRING_PAYMENTS_LIST_RECURRANCE_TYPE",
  RECURRING_PAYMENTS_LIST_RECURRANCE_ON = "RECURRING_PAYMENTS_LIST_RECURRANCE_ON",
  RECURRING_PAYMENTS_LIST_NEXT_PAYMENT_DATE = "RECURRING_PAYMENTS_LIST_NEXT_PAYMENT_DATE",
  RECURRING_PAYMENTS_LIST_REMARK = "RECURRING_PAYMENTS_LIST_REMARK",

  // UPCOMING ACTION FIELD

  UPCOMING_PAYMENTS_LIST_DATE = "UPCOMING_PAYMENTS_LIST_DATE",
  UPCOMING_PAYMENTS_LIST_CLIENT_NAME = "UPCOMING_PAYMENTS_LIST_CLIENT_NAME",
  UPCOMING_PAYMENTS_LIST_TYPE = "UPCOMING_PAYMENTS_LIST_TYPE",
  UPCOMING_PAYMENTS_LIST_AMOUNT = "UPCOMING_PAYMENTS_LIST_AMOUNT",
  UPCOMING_PAYMENTS_LIST_TAX_AMOUNT = "UPCOMING_PAYMENTS_LIST_TAX_AMOUNT",
  UPCOMING_PAYMENTS_LIST_TOTAL_AMOUNT = "UPCOMING_PAYMENTS_LIST_TOTAL_AMOUNT",
  UPCOMING_PAYMENTS_LIST_REMARK = "UPCOMING_PAYMENTS_LIST_REMARK",

  // ACCOUNTS ACTION FIELD
  ACTION_ACCOUNTS_LIST = "ACTION_ACCOUNTS_LIST",
  ACTION_ACCOUNTS_ADD = "ACTION_ACCOUNTS_ADD",
  ACTION_ACCOUNTS_EDIT = "ACTION_ACCOUNTS_EDIT",
  ACTION_ACCOUNTS_DELETE = "ACTION_ACCOUNTS_DELETE",
  ACCOUNTS_LIST_ACCOUNT_NAME = "ACCOUNTS_LIST_ACCOUNT_NAME",

  // EXPENSE_CATEGORY ACTION FIELD
  ACTION_EXPENSE_CATEGORY_LIST = "ACTION_EXPENSE_CATEGORY_LIST",
  EXPENSE_CATEGORY_LIST_CATEGORY_NAME = "EXPENSE_CATEGORY_LIST_CATEGORY_NAME",
  ACTION_EXPENSE_CATEGORY_ADD = "ACTION_EXPENSE_CATEGORY_ADD",
  ACTION_EXPENSE_CATEGORY_EDIT = "ACTION_EXPENSE_CATEGORY_EDIT",
  ACTION_EXPENSE_CATEGORY_DELETE = "ACTION_EXPENSE_CATEGORY_DELETE",

  // EXPENSES ACTION FIELD

  ACTION_EXPENSES_LIST = "ACTION_EXPENSES_LIST",
  ACTION_EXPENSES_ADD = "ACTION_EXPENSES_ADD",
  ACTION_EXPENSES_EDIT = "ACTION_EXPENSES_EDIT",
  ACTION_EXPENSES_DELETE = "ACTION_EXPENSES_DELETE",

  // EXPENSES ACTION FIELD

  EXPENSES_LIST_EXPENSE_DATE = "EXPENSES_LIST_EXPENSE_DATE",
  EXPENSES_LIST_AMOUNT = "EXPENSES_LIST_AMOUNT",
  EXPENSES_LIST_CATEGORY_NAME = "EXPENSES_LIST_CATEGORY_NAME",
  EXPENSES_LIST_ACCOUNTS_NAME = "EXPENSES_LIST_ACCOUNTS_NAME",
  EXPENSES_LIST_GST_APPLICABLE = "EXPENSES_LIST_GST_APPLICABLE",
  EXPENSES_LIST_REMARK = "EXPENSES_LIST_REMARK",
  EXPENSES_LIST_VIEW_BILL = "EXPENSES_LIST_VIEW_BILL",
  EXPENSES_LIST_ADDED_BY = "EXPENSES_LIST_ADDED_BY",

  // MEETING NOTES TYPE ACTION FIELD
  ACTION_MEETING_NOTES_TYPE_LIST = "ACTION_MEETING_NOTES_TYPE_LIST",
  ACTION_MEETING_NOTES_TYPE_ADD = "ACTION_MEETING_NOTES_TYPE_ADD",
  ACTION_MEETING_NOTES_TYPE_EDIT = "ACTION_MEETING_NOTES_TYPE_EDIT",
  ACTION_MEETING_NOTES_TYPE_DEACTIVE = "ACTION_MEETING_NOTES_TYPE_DEACTIVE",
  ACTION_MEETING_NOTES_TYPE_DELETE = "ACTION_MEETING_NOTES_TYPE_DELETE",
  ACTION_MEETING_NOTES_TYPE_SET_AS_DEFAULT = "ACTION_MEETING_NOTES_TYPE_SET_AS_DEFAULT",

  MEETING_NOTES_TYPE_LIST_MEETING_NOTES_TYPE = "MEETING_NOTES_TYPE_LIST_MEETING_NOTES_TYPE",

  // MEETING NOTES TYPE ACTION FIELD
  ACTION_MEETING_TYPE_LIST = "ACTION_MEETING_TYPE_LIST",
  ACTION_MEETING_TYPE_ADD = "ACTION_MEETING_TYPE_ADD",
  ACTION_MEETING_TYPE_EDIT = "ACTION_MEETING_TYPE_EDIT",
  ACTION_MEETING_TYPE_DEACTIVE = "ACTION_MEETING_TYPE_DEACTIVE",
  ACTION_MEETING_TYPE_DELETE = "ACTION_MEETING_TYPE_DELETE",

  MEETING_TYPE_LIST_MEETING_TYPE = "MEETING_TYPE_LIST_MEETING_TYPE",

  // REMINDER ACTION FIELD

  ACTION_REMINDER_LIST = "ACTION_REMINDER_LIST",
  ACTION_REMINDER_ADD = "ACTION_REMINDER_ADD",
  ACTION_REMINDER_EDIT = "ACTION_REMINDER_EDIT",
  ACTION_REMINDER_DEACTIVE = "ACTION_REMINDER_DEACTIVE",
  ACTION_REMINDER_DELETE = "ACTION_REMINDER_DELETE",

  REMINDER_LIST_DATE_TIME = "REMINDER_LIST_DATE_TIME",
  REMINDER_LIST_REMINDER = "REMINDER_LIST_REMINDER",
  REMINDER_LIST_SOURCE = "REMINDER_LIST_SOURCE",

  // GOAL  ACTION FIELD

  ACTION_NAV_GOAL_LIST = "ACTION_NAV_GOAL_LIST",
  ACTION_GOAL_VIEW = "ACTION_GOAL_VIEW",
  ACTION_GOAL_ADD = "ACTION_GOAL_ADD",
  ACTION_GOAL_EDIT = "ACTION_GOAL_EDIT",
  ACTION_GOAL_DELETE = "ACTION_GOAL_DELETE",

  NAV_GOAL_LIST_CATEGORY = "NAV_GOAL_LIST_CATEGORY",
  NAV_GOAL_LIST_NAME = "NAV_GOAL_LIST_NAME",
  NAV_GOAL_LIST_TYPE = "NAV_GOAL_LIST_TYPE",
  NAV_GOAL_LIST_TARGET = "NAV_GOAL_LIST_TARGET",
  NAV_GOAL_LIST_PENDING = "NAV_GOAL_LIST_PENDING",
  NAV_GOAL_LIST_DEADLINE = "NAV_GOAL_LIST_DEADLINE",
  NAV_GOAL_LIST_MEMBERS = "NAV_GOAL_LIST_MEMBERS",
  NAV_GOAL_LIST_MEASURED_BY = "NAV_GOAL_LIST_MEASURED_BY",
  NAV_GOAL_LIST_ACHIEVEMENT = "NAV_GOAL_LIST_ACHIEVEMENT",

  // GOAL_CATEGORY  ACTION FIELD

  ACTION_NAV_GOAL_CATEGORY_LIST = "ACTION_NAV_GOAL_CATEGORY_LIST",
  ACTION_GOAL_CATEGORY_VIEW = "ACTION_GOAL_CATEGORY_VIEW",
  ACTION_GOAL_CATEGORY_ADD = "ACTION_GOAL_CATEGORY_ADD",
  ACTION_GOAL_CATEGORY_EDIT = "ACTION_GOAL_CATEGORY_EDIT",
  ACTION_GOAL_CATEGORY_DELETE = "ACTION_GOAL_CATEGORY_DELETE",

  NAV_GOAL_CATEGORY_LIST_CATEGORY_NAME = "NAV_GOAL_CATEGORY_LIST_CATEGORY_NAME",

  // SERVICE TICKET ACTION FIELD

  ACTION_NAV_SERVICE_TICKET_LIST = "ACTION_NAV_SERVICE_TICKET_LIST",
  ACTION_SERVICE_TICKET_VIEW = "ACTION_SERVICE_TICKET_VIEW",
  ACTION_SERVICE_TICKET_ADD = "ACTION_SERVICE_TICKET_ADD",
  ACTION_SERVICE_TICKET_EDIT = "ACTION_SERVICE_TICKET_EDIT",
  ACTION_SERVICE_TICKET_DELETE = "ACTION_SERVICE_TICKET_DELETE",

  NAV_SERVICE_TICKET_LIST_PROBLEM_NAME = "NAV_SERVICE_TICKET_LIST_PROBLEM_NAME",
  NAV_SERVICE_TICKET_LIST_DEPARMENT = "NAV_SERVICE_TICKET_LIST_DEPARMENT",
  NAV_SERVICE_TICKET_LIST_CREATED_BY = "NAV_SERVICE_TICKET_LIST_CREATED_BY",

  // WORKSPACE ACTION FIELD

  ACTION_WORKSPACE_LIST = "ACTION_WORKSPACE_LIST",
  ACTION_WORKSPACE_ADD = "ACTION_WORKSPACE_ADD",
  ACTION_WORKSPACE_EDIT = "ACTION_WORKSPACE_EDIT",
  ACTION_WORKSPACE_DELETE = "ACTION_WORKSPACE_DELETE",
  ACTION_WORKSPACE_SINGLE_VIEW_PLATFORM = "ACTION_WORKSPACE_SINGLE_VIEW_PLATFORM",
  ACTION_PLATFORM_ADD = "ACTION_PLATFORM_ADD",
  ACTION_PLATFORM_EDIT = "ACTION_PLATFORM_EDIT",
  ACTION_PLATFORM_DELETE = "ACTION_PLATFORM_DELETE",
  ACTION_PLATFORM_VIEW = "ACTION_PLATFORM_VIEW",

  WORKSPACE_LIST_WORKSPACE_NAME = "WORKSPACE_LIST_WORKSPACE_NAME",
  PLATFORM_LIST_PLATFORM_NAME = "PLATFORM_LIST_PLATFORM_NAME",

  // REQUREMENT GATHERING
  NAV_REQUIREMENT_GATHERING = "NAV_REQUIREMENT_GATHERING",
  ACTION_REQUIREMENT_GATHERING_LIST = "ACTION_REQUIREMENT_GATHERING_LIST",
  ACTION_REQUIREMENT_GATHERING_ADD = "ACTION_REQUIREMENT_GATHERING_ADD",
  ACTION_REQUIREMENT_GATHERING_VIEW = "ACTION_REQUIREMENT_GATHERING_VIEW",
  ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB = "ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB",
  ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORMS_TAB = "ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORMS_TAB",
  ACTION_REQUIREMENT_GATHERING_VIEW_FEATURES_TAB = "ACTION_REQUIREMENT_GATHERING_VIEW_FEATURES_TAB",

  ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_LIST_NAME = "ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_LIST_NAME",
  ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_ADD = "ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_ADD",
  ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_EDIT = "ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_EDIT",
  ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_DELETE = "ACTION_REQUIREMENT_GATHERING_VIEW_USER_TAB_DELETE",

  ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORM_TAB_LIST_NAME = "ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORM_TAB_LIST_NAME",
  ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORM_TAB_ADD = "ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORM_TAB_ADD",
  ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORM_TAB_EDIT = "ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORM_TAB_EDIT",
  ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORM_TAB_DELETE = "ACTION_REQUIREMENT_GATHERING_VIEW_PLATFORM_TAB_DELETE",
  // TICKET GROUP ACTION FIELD

  ACTION_TICKET_GROUP_LIST = "ACTION_TICKET_GROUP_LIST",
  ACTION_TICKET_GROUP_ADD = "ACTION_TICKET_GROUP_ADD",
  ACTION_TICKET_GROUP_EDIT = "ACTION_TICKET_GROUP_EDIT",
  ACTION_TICKET_GROUP_DELETE = "ACTION_TICKET_GROUP_DELETE",
  ACTION_TICKET_GROUP_SINGLE_VIEW = "ACTION_TICKET_GROUP_SINGLE_VIEW",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS",

  TICKET_GROUP_LIST_CREATED_DATE = "TICKET_GROUP_LIST_CREATED_DATE",
  TICKET_GROUP_LIST_CHANGE_GROUP_NAME = "TICKET_GROUP_LIST_CHANGE_GROUP_NAME",
  TICKET_GROUP_LIST_TOTAL_TICKETS = "TICKET_GROUP_LIST_TOTAL_TICKETS",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_ID = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_ID",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_DATE_TIME = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_DATE_TIME",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_TITLE = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_TITLE",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_PROJECT = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_PROJECT",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_PRIORITY = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_PRIORITY",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_CREATED_BY = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_CREATED_BY",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_TYPE = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_TYPE",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_STAGE = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_STAGE",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_STATUS = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_STATUS",
  ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_UNSEEN_MESSAGE = "ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_UNSEEN_MESSAGE",

  //NAV_INCIDENT  Action
  ACTION_INCIDENT_DESPUTED_INCIDENT = "ACTION_INCIDENT_DESPUTED_INCIDENT",
  ACTION_INCIDENT_UNHANDLED_INCIDENT = "ACTION_INCIDENT_UNHANDLED_INCIDENT",
  ACTION_INCIDENT_CLARIFICATION_INCIDENT = "ACTION_INCIDENT_CLARIFICATION_INCIDENT",
  ACTION_INCIDENT_ALL_INCIDENT = "ACTION_INCIDENT_ALL_INCIDENT",

  //All Incident List Field
  ALL_INCIDENT_LIST_CREATE_ON_DATE = "ALL_INCIDENT_LIST_CREATE_ON_DATE",
  ALL_INCIDENT_LIST_ADDED_ON_DATE = "ALL_INCIDENT_LIST_ADDED_ON_DATE",
  ALL_INCIDENT_LIST_CATEGORY_NAME = "ALL_INCIDENT_LIST_CATEGORY_NAME",
  ALL_INCIDENT_LIST_TYPE_NAME = "ALL_INCIDENT_LIST_TYPE_NAME",
  ALL_INCIDENT_LIST_ASSIGN_TO = "ALL_INCIDENT_LIST_ASSIGN_TO",
  ALL_INCIDENT_LIST_REMARK = "ALL_INCIDENT_LIST_REMARK",
  ALL_INCIDENT_LIST_FLOW = "ALL_INCIDENT_LIST_FLOW",
  ACTION_INCIDENT_ALL_INCIDENT_ADD = "ACTION_INCIDENT_ALL_INCIDENT_ADD",
  ACTION_INCIDENT_ALL_INCIDENT_EDIT = "ACTION_INCIDENT_ALL_INCIDENT_EDIT",

  //Desputed Incident List Field
  DESPUTED_INCIDENT_LIST_ADDED_ON_DATE = "DESPUTED_INCIDENT_LIST_ADDED_ON_DATE",
  DESPUTED_INCIDENT_LIST_CATEGORY_NAME = "DESPUTED_INCIDENT_LIST_CATEGORY_NAME",
  DESPUTED_INCIDENT_LIST_TYPE_NAME = "DESPUTED_INCIDENT_LIST_TYPE_NAME",
  DESPUTED_INCIDENT_LIST_ASSIGN_TO = "DESPUTED_INCIDENT_LIST_ASSIGN_TO",
  DESPUTED_INCIDENT_LIST_REMARK = "DESPUTED_INCIDENT_LIST_REMARK",

  //Clarification Incident List Field
  CLARIFICATION_INCIDENT_LIST_ADDED_ON_DATE = "CLARIFICATION_INCIDENT_LIST_ADDED_ON_DATE",
  CLARIFICATION_INCIDENT_LIST_CATEGORY_NAME = "CLARIFICATION_INCIDENT_LIST_CATEGORY_NAME",
  CLARIFICATION_INCIDENT_LIST_TYPE_NAME = "CLARIFICATION_INCIDENT_LIST_TYPE_NAME",
  CLARIFICATION_INCIDENT_LIST_ASSIGN_TO = "CLARIFICATION_INCIDENT_LIST_ASSIGN_TO",
  CLARIFICATION_INCIDENT_LIST_REMARK = "CLARIFICATION_INCIDENT_LIST_REMARK",
  //UnHandled Incident List Field
  UNHANDLED_INCIDENT_LIST_MOVE_ON_DATE = "UNHANDLED_INCIDENT_LIST_MOVE_ON_DATE",
  UNHANDLED_INCIDENT_LIST_ADDED_ON_DATE = "UNHANDLED_INCIDENT_LIST_ADDED_ON_DATE",
  UNHANDLED_INCIDENT_LIST_CATEGORY_NAME = "UNHANDLED_INCIDENT_LIST_CATEGORY_NAME",
  UNHANDLED_INCIDENT_LIST_TYPE_NAME = "UNHANDLED_INCIDENT_LIST_TYPE_NAME",
  UNHANDLED_INCIDENT_LIST_REMARK = "UNHANDLED_INCIDENT_LIST_REMARK",

  //ACTION BUTTON
  ACTION_INCIDENT_REJCT_NOT_ME_REQUEST = "ACTION_INCIDENT_REJCT_NOT_ME_REQUEST",
  ACTION_INCIDENT_CLARIFICATION_REQUEST = "ACTION_INCIDENT_CLARIFICATION_REQUEST",
  ACTION_INCIDENT_MARK_AS_UNHANDLED = "ACTION_INCIDENT_MARK_AS_UNHANDLED",
  ACTION_INCIDENT_FORWARD = "ACTION_INCIDENT_FORWARD",

  //INCIDENT CATEGORY
  ACTION_INCIDENT_CATEGORY_LIST = "ACTION_INCIDENT_CATEGORY_LIST",
  INCIDENT_CATEGORY_LIST_INCIDENT_CATEGORY_NAME = "INCIDENT_CATEGORY_LIST_INCIDENT_CATEGORY_NAME",
  ACTION_INCIDENT_CATEGORY_ADD = "ACTION_INCIDENT_CATEGORY_ADD",
  ACTION_INCIDENT_CATEGORY_EDIT = "ACTION_INCIDENT_CATEGORY_EDIT",
  ACTION_INCIDENT_CATEGORY_DELETE = "ACTION_INCIDENT_CATEGORY_DELETE",

  //INCIDENT TYPE
  ACTION_INCIDENT_TYPE_LIST = "ACTION_INCIDENT_TYPE_LIST",
  INCIDENT_TYPE_LIST_INCIDENT_TYPE_CATEGORY_NAME = "INCIDENT_TYPE_LIST_INCIDENT_TYPE_CATEGORY_NAME",
  INCIDENT_TYPE_LIST_INCIDENT_TYPE_NAME = "INCIDENT_TYPE_LIST_INCIDENT_TYPE_NAME",
  INCIDENT_TYPE_LIST_INCIDENT_TYPE_WEIGHT = "INCIDENT_TYPE_LIST_INCIDENT_TYPE_WEIGHT",
  ACTION_INCIDENT_TYPE_ADD = "ACTION_INCIDENT_TYPE_ADD",
  ACTION_INCIDENT_TYPE_EDIT = "ACTION_INCIDENT_TYPE_EDIT",
  ACTION_INCIDENT_TYPE_DELETE = "ACTION_INCIDENT_TYPE_DELETE",

  // ESTIMATION CALCULATOR

  ACTION_ESTIMATION_CALCULATOR_LIST = "ACTION_ESTIMATION_CALCULATOR_LIST",
  ACTION_ESTIMATION_CALCULATOR_ADD = "ACTION_ESTIMATION_CALCULATOR_ADD",
  ACTION_ESTIMATION_CALCULATOR_VIEW = "ACTION_ESTIMATION_CALCULATOR_VIEW",

  // ESTIMATION CALCULATOR ACTION FIELD
  ESTIMATION_CALCULATOR_LIST_CREATED_AT = "ESTIMATION_CALCULATOR_LIST_CREATED_AT",
  ESTIMATION_CALCULATOR_LIST_TITLE = "ESTIMATION_CALCULATOR_LIST_TITLE",
  ESTIMATION_CALCULATOR_LIST_DEVELOPER = "ESTIMATION_CALCULATOR_LIST_DEVELOPER",
  ESTIMATION_CALCULATOR_LIST_CTC = "ESTIMATION_CALCULATOR_LIST_CTC",
  ESTIMATION_CALCULATOR_LIST_GCTC = "ESTIMATION_CALCULATOR_LIST_GCTC",

  // JOB ONBOARD MODULE
  // JOB CREATION
  ACTION_JOB_CREATION_LIST = "ACTION_JOB_CREATION_LIST",
  ACTION_JOB_CREATION_ADD = "ACTION_JOB_CREATION_ADD",
  ACTION_JOB_CREATION_EDIT = "ACTION_JOB_CREATION_EDIT",
  ACTION_JOB_CREATION_VIEW = "ACTION_JOB_CREATION_VIEW",
  ACTION_JOB_CREATION_DELETE = "ACTION_JOB_CREATION_DELETE",

  ACTION_JOB_CREATION_VIEW_DETAILS_TAB = "ACTION_JOB_CREATION_VIEW_DETAILS_TAB",
  ACTION_JOB_CREATION_VIEW_JOB_APPICATION_TAB = "ACTION_JOB_CREATION_VIEW_JOB_APPICATION_TAB",
  ACTION_JOB_CREATION_VIEW_STAGE_TAB = "ACTION_JOB_CREATION_VIEW_STAGE_TAB",
  ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_ADD = "ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_ADD",
  ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_EDIT = "ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_EDIT",
  ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_DELETE = "ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_DELETE",
  ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_VIEW = "ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_VIEW",
  ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_DETAILS_TAB = "ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_DETAILS_TAB",
  ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB = "ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_STAGE_TAB",
  ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_ROUND_TAB = "ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_ROUND_TAB",
  ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_ROUND_TRACKING_REPORT_TAB = "ACTION_JOB_CREATION_VIEW_JOB_APPLICATION_TAB_ROUND_TRACKING_REPORT_TAB",
  // PAYOUT
  ACTION_PAYOUT_LIST = "ACTION_PAYOUT_LIST",
  ACTION_PAYOUT_ADD = "ACTION_PAYOUT_ADD",
  ACTION_PAYOUT_EDIT = "ACTION_PAYOUT_EDIT",
  ACTION_PAYOUT_DELETE = "ACTION_PAYOUT_DELETE",

  // INDUSTRY
  ACTION_INDUSTRY_LIST = "ACTION_INDUSTRY_LIST",
  ACTION_INDUSTRY_ADD = "ACTION_INDUSTRY_ADD",
  ACTION_INDUSTRY_EDIT = "ACTION_INDUSTRY_EDIT",
  ACTION_INDUSTRY_DELETE = "ACTION_INDUSTRY_DELETE",

  INDUSTRY_LIST_INDUSTRY_NAME = "INDUSTRY_LIST_INDUSTRY_NAME",

  // BUSINESS
  ACTION_BUSINESS_LIST = "ACTION_BUSINESS_LIST",
  ACTION_BUSINESS_ADD = "ACTION_BUSINESS_ADD",
  ACTION_BUSINESS_EDIT = "ACTION_BUSINESS_EDIT",
  ACTION_BUSINESS_DELETE = "ACTION_BUSINESS_DELETE",

  BUSINESS_LIST_BUSINESS_NAME = "BUSINESS_LIST_BUSINESS_NAME",
  BUSINESS_LIST_INDUSTRY_NAME = "BUSINESS_LIST_INDUSTRY_NAME",

  //FEATURE AS OPERATION
  ACTION_FEATURE_AS_OPERATIONS_LIST = "ACTION_FEATURE_AS_OPERATIONS_LIST",
  ACTION_FEATURE_AS_OPERATIONS_ADD = "ACTION_FEATURE_AS_OPERATIONS_ADD",
  ACTION_FEATURE_AS_OPERATIONS_EDIT = "ACTION_FEATURE_AS_OPERATIONS_EDIT",
  ACTION_FEATURE_AS_OPERATIONS_DELETE = "ACTION_FEATURE_AS_OPERATIONS_DELETE",
  ACTION_FEATURE_AS_OPERATIONS_VIEW = "ACTION_FEATURE_AS_OPERATIONS_VIEW",

  FEATURE_AS_OPERATIONS_TITLE = "FEATURE_AS_OPERATIONS_TITLE",
  FEATURE_AS_OPERATIONS_BUSINESS_MODEL = "FEATURE_AS_OPERATIONS_BUSINESS_MODEL",
}
// MODULE ACTIONS
export enum UserModuleActionTypes {
  EDIT = "EDIT",
  DELETE = "DELETE",
  VIEW = "VIEW",
  LIST = "LIST",
  ADD = "ADD",
}
// export type UserModuleNameTypesProps = UserModuleNameTypes | ModuleClientActionTypes;

// MODULE OTHER ACTIONS
export enum UserModuleOtherActionTypes {}
