import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import ATMContactSelect from "src/components/UI/atoms/ATMContactSelect/ATMContactSelect";
import MOLMarkdownEditor from "src/components/UI/molecules/MOLMarkdownEditor/MOLMarkdownEditor";
import { addContactTaskValuesType } from "./AddTaskDialogFormWrapper";

type Props = {
  formikProps: FormikProps<addContactTaskValuesType>;
  onClose: () => void;
  editorValue?: string;
  userOptions: any;
};

const actionTypeOptions = [
  { label: "Call", value: "CALL" },
  { label: "Email", value: "EMAIL" },
  { label: "To-do", value: "TO-DO" },
];

const priorityOptions = [
  { label: "None", value: "NONE" },
  { label: "Low", value: "LOW" },
  { label: "Medium", value: "MEDIUM" },
  { label: "High", value: "HIGH" },
];
const ReminderOptions = [
  { label: "No reminder", value: "No reminder" },
  { label: "At task due time", value: "At task due time" },
  { label: "30 minutes before", value: "30 minutes before" },
  { label: "1 hour before", value: "1 hour before" },
  { label: "1 day before", value: "1 day before" },
  { label: "Custom Date", value: "Custom Date" },
];

const AddTaskDialogForm = ({
  formikProps,
  onClose,
  userOptions,
  editorValue = "",
}: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

//   const { data: QueueData } = useGetQueueListQuery("");

//   const queueOptions = QueueData?.data?.map((el: any) => {
//     return {
//       title: el?.queueName,
//     };
//   });

  return (
    <div className=" flex flex-col">
      <div className="bg-slate-500 p-2 flex justify-between items-center">
        <div className="flex gap-3 items-center">
          <IoIosArrowDown size="1.3em" color="#fbfbfb" />
          <p className="text-white capitalize ">Task</p>
        </div>
        <div className="flex gap-2 items-center">
          <span
            className="hover:bg-slate-600 p-1 cursor-pointer rounded-full"
            onClick={onClose}
          >
            <RxCross2 size="1.4em" color="#fbfbfb" />
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-4">
        <input
          type="text"
          placeholder="Enter your Task"
          className="!outline-0    text-primary-dark font-medium text-[14px] border-b rounded px-1"
          value={values?.taskName || ""}
          onChange={(e) => {
            setFieldValue("taskName", e.target.value);
          }}
        />
        <div className="grid grid-cols-3 gap-4 border-b pb-2">
          <div className="flex flex-col">
            <label className="text-[12px] mx-1 mb-1">Activity Date</label>
            <input
              type="date"
              className="!outline-0  w-32 bg-blue-50 text-primary-dark font-medium text-[14px] border rounded px-1"
              value={values?.activityDate || null}
              onChange={(e) => {
                setFieldValue("activityDate", e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-[12px] mx-1 mb-1">Activity Time</label>
            <input
              type="time"
              className="!outline-0  w-32  text-primary-dark font-medium text-[14px] border rounded px-1"
              value={values?.activityTime || null}
              onChange={(e) => {
                setFieldValue("activityTime", e.target.value);
              }}
            />
          </div>

          {values?.wantToAddReminder === "Custom Date" ? (
            <div className="flex flex-col">
              <label className="text-[12px] mx-1 mb-1"> Date</label>
              <input
                type="date"
                className="!outline-0  w-32 bg-blue-50 text-primary-dark font-medium text-[14px] border rounded px-1"
                value={values?.reminderForDate || null}
                onChange={(e) => {
                  setFieldValue("reminderForDate", e.target.value);
                }}
              />
            </div>
          ) : (
            <ATMContactSelect
              label="Send reminder"
              value={values?.wantToAddReminder}
              onChange={(e: any) =>
                setFieldValue("wantToAddReminder", e.target.value)
              }
              options={ReminderOptions}
            />
          )}
        </div>
        <div className="grid grid-cols-3 gap-4 border-b pb-2">
          <ATMContactSelect
            label="Task Type"
            value={values?.taskType}
            onChange={(e: any) => setFieldValue("taskType", e.target.value)}
            options={actionTypeOptions}
          />
          <ATMContactSelect
            label="Priority"
            value={values?.priority}
            onChange={(e: any) => setFieldValue("priority", e.target.value)}
            options={priorityOptions}
          />
          <ATMContactSelect
            label="Activity assigned to"
            value={values?.assignedTo}
            onChange={(e: any) => setFieldValue("assignedTo", e.target.value)}
            options={userOptions}
          />
          {/* <div>
            <ATMTagAutoComplete
              variant="standard"
              labelClasses="text-[12px] font-normal"
              multiple={false}
              value={values?.queue}
              onChange={(value: any) => {
                setFieldValue("queue", value);
              }}
              label="Queue"
              option={queueOptions || []}
            />
          </div> */}
        </div>

        <div>
          <MOLMarkdownEditor
            extraClassName="min-h-[200px] max-h-96 overflow-y-auto"
            key={editorValue}
            required
            value={values?.description}
            placeholder="Notes...."
            onChange={(newValue: any) => {
              setFieldValue("description", newValue);
            }}
          />
        </div>
      </div>
      <div className="pb-2 px-4">
        <ATMLoadingButton
          isLoading={isSubmitting}
          type="submit"
          className="bg-gray-200  !h-[32px] text-slate-800 w-24 font-semibold text-[14px] hover:text-white hover:bg-slate-500"
        >
          Create
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default AddTaskDialogForm;
