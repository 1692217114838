import { FormikProps } from "formik";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { useAccounts } from "src/hooks/useAccounts";
import useCurrency from "src/hooks/useCurrency";
import { useExpenesCategory } from "src/hooks/useExpenesCategory";
import { handleValidWithDotNumber } from "src/utils/validations/numberInput";
import { MilestonFormValues } from "./AddExpenseWrapper";
import { useEffect } from "react";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<MilestonFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const AddExpenseForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const { currency, isDataLoading } = useCurrency();
  const { accounts, isDataLoading: isDataLoadingAccount } = useAccounts();
  const { expenesCategory, isDataLoading: isCategoryDataLoading } =
    useExpenesCategory();

  useEffect(() => {
    const taxAmount =
      Number(values?.amount) * (Number(values?.taxPercent) / 100);
    setFieldValue("taxAmount", taxAmount.toFixed(2));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.taxPercent, values?.amount]);

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Expense" : "Update Expense"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="grid grid-cols-2 gap-4">
        <div>
          <ATMDatePicker
            required
            label="Expense Date"
            name="milestoneDate"
            format="DD/MM/YYYY"
            value={values.milestoneDate}
            onChange={(newValue) => setFieldValue("milestoneDate", newValue)}
          />
        </div>
        {/* accountId  */}
        <div className="">
          <ATMSelect
            required
            name="accountId"
            value={values.accountId}
            onChange={(newValue: any) => {
              setFieldValue("accountId", newValue);
            }}
            onBlur={handleBlur}
            label="Accounts"
            placeholder="Select Accounts"
            options={accounts?.map((ele: any) => {
              return {
                label: ele?.accountName,
                value: ele?._id,
              };
            })}
            isLoading={isDataLoadingAccount}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return <div className="capitalize"> {option.label} </div>;
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            isOptionEqualToValue={(option, selected) => {
              return (
                selected?.findIndex(
                  (selectedValue: any) => selectedValue?.value === option.value
                ) > -1
              );
            }}
            isSearchedOption={(option, searchValue) => {
              return searchValue
                ? option?.data?.label?.toLowerCase()?.includes(searchValue)
                : true;
            }}
          />
        </div>
        {/* expenseCategoryId  */}
        <div className="">
          <ATMSelect
            // required
            name="expenseCategoryId"
            value={values.expenseCategoryId}
            onChange={(newValue: any) => {
              setFieldValue("expenseCategoryId", newValue);
            }}
            onBlur={handleBlur}
            label="Expense Category "
            placeholder="Select Expense Category"
            options={expenesCategory?.map((ele: any) => {
              return {
                label: ele?.categoryName,
                value: ele?._id,
              };
            })}
            isLoading={isCategoryDataLoading}
            renderOption={(option, { context }) => {
              if (context === "menu") {
                return <div className="capitalize"> {option.label} </div>;
              } else {
                return <div className="capitalize"> {option.label} </div>;
              }
            }}
            isOptionEqualToValue={(option, selected) => {
              return (
                selected?.findIndex(
                  (selectedValue: any) => selectedValue?.value === option.value
                ) > -1
              );
            }}
            isSearchedOption={(option, searchValue) => {
              return searchValue
                ? option?.data?.label?.toLowerCase()?.includes(searchValue)
                : true;
            }}
          />
        </div>
        {/* Amount */}
        <div className="grid grid-cols-12 items-center gap-2 w-full">
          {/* Select Currency */}
          <div className="col-span-6 mt-1">
            <ATMSelect
              required
              extraMinWidthClass={150}
              size="small"
              extraClasses="w-1/4"
              name="currencyId"
              label="Currency"
              placeholder="Select Currency"
              value={values.currencyId}
              isLoading={isDataLoading}
              options={currency?.map((ele: any) => {
                return {
                  label: ele?.currencyName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("currencyId", newValue);
              }}
            />
          </div>
          <div className="col-span-6	">
            <ATMTextField
              required
              name="amount"
              value={values.amount}
              onChange={(e) => {
                handleValidWithDotNumber(e) &&
                  setFieldValue("amount", e.target.value);
              }}
              label="Amount"
              placeholder="Enter Amount"
              className="mt-0 rounded"
              onBlur={handleBlur}
            />
          </div>
        </div>

        {/*Tax Percent */}
        <div>
          <ATMTextField
            name="taxPercent"
            value={values?.taxPercent}
            onChange={(e) => {
              handleValidWithDotNumber(e) &&
                setFieldValue("taxPercent", e.target.value);
            }}
            label="Tax Percent"
            placeholder="Enter Tax Percent"
            className="mt-0 rounded"
            onBlur={handleBlur}
          />
        </div>
        {/* taxAmount */}
        <div>
          <ATMTextField
            disabled
            name="taxAmount"
            value={values.taxAmount}
            onChange={(e) => {
              handleValidWithDotNumber(e) &&
                setFieldValue("taxAmount", e.target.value);
            }}
            label="Tax Amount"
            placeholder="Enter Tax Amount"
            className="mt-0 rounded"
            onBlur={handleBlur}
          />
        </div>

        {/* Remark */}
        <div className="">
          <ATMTextArea
            name="remark"
            value={values.remark}
            onChange={(e) => setFieldValue("remark", e)}
            label="Remark"
            placeholder="Enter Remark"
          />
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default AddExpenseForm;
