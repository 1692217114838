import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { GitRepoFormValues } from "src/models/GitRepo.model";
import {
  useGetPositionByIdQuery,
  useUpdatePositionByIdMutation,
} from "src/services/PositionServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import PositonForm from "../Layouts/PositionForm";

type Props = {
  selectedPositionId: string;
  onClose: () => void;
};

const EditPositionFormWrapper = ({ selectedPositionId, onClose }: Props) => {
  const [items, setItems] = useState<any>({});

  const { isLoading, isFetching, data } =
    useGetPositionByIdQuery(selectedPositionId);
  const [updateGitRepo] = useUpdatePositionByIdMutation();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setItems(data?.data);
    }
  }, [isLoading, isFetching, data]);

  // Form Initial Values
  const initialValues: any = {
    repoProjects: {
      label: items?.departmentName,
      value: items?.departmentId,
    },
    repoName: items?.positionName,
  };

  // Validation Schema
  const validationSchema = object().shape({
    repoProjects: object().required("Please select department"),
    repoName: string().required("Please enter position"),
  });

  // Handle Submit
  const handleSubmit = (
    values: GitRepoFormValues,
    { setSubmitting, resetForm }: FormikHelpers<GitRepoFormValues>
  ) => {
    const formattedValues = {
      positionName: values?.repoName,
      departmentId: values?.repoProjects?.value,
    };

    updateGitRepo({ id: selectedPositionId, body: formattedValues }).then(
      (res: any) => {
        if (res?.error) {
          showToast("error", res?.error?.data?.message);
        } else {
          if (res?.data?.status) {
            showToast("success", res?.data?.message);
            resetForm();
            onClose();
          } else {
            showToast("error", res?.data?.message);
          }
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
          <PositonForm
            formikProps={formikProps}
            formType="EDIT"
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditPositionFormWrapper;
