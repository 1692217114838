import React from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { setDateFilter } from "src/redux/slices/ResourceRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import ResourceRequestFilterCard from "./ResourceRequestFilterCard";

type Props = {
  onClose:()=>void;
  open:boolean
};

export type ResourceRequestFilterCardValues ={
    startDate: any;
    endDate: any;
}

const ResourceRequestFilterCardWrapper = ({ onClose , open }: Props) => {
  const dispatch = useDispatch();
  const { dateFilter } =
  useSelector((state: RootState) => state.resourceRequest);

  // Form Initial Values
  const initialValues: ResourceRequestFilterCardValues = {
    startDate: dateFilter?.start_date || null,
    endDate:dateFilter?.end_date || null,
  };
 

  // Handle Submit
  const handleSubmit = (
    values: ResourceRequestFilterCardValues,
    { setSubmitting, resetForm }: FormikHelpers<ResourceRequestFilterCardValues>
  ) => {
    dispatch(
      setDateFilter({
        start_date: values?.startDate,
        end_date: values?.endDate,
      })
    );
   setSubmitting(false) 
   onClose()
  };
  const handleReset = (formikProps: FormikProps<ResourceRequestFilterCardValues>) => {
    dispatch(setDateFilter({
      start_date:null,
      end_date: null,
    })) };

  return (
    <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
       
      >
        {(formikProps) => (
          <Form>
            <ResourceRequestFilterCard open={open} formikProps={formikProps} onClose={onClose}  onReset={handleReset}  />
          </Form>
        )}
      </Formik>
  );
};

export default ResourceRequestFilterCardWrapper;
