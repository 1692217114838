import { useEffect, useState } from "react";
import SubscriptionListing from "./FinanceDashboardListing";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { useGetDashboardFinanceReportQuery, useGetFinanceDashboardQuarterlyReportQuery } from "src/services/DashbordService";
import { CircularProgress } from "@mui/material";

type Props = {};

const FinanceDashboardListingWrapper = (props: Props) => {

  const {
    dateFilter,
    // dateFilterQuarterly
  } = useSelector((state: RootState) => state.financeDashboard);

  // STATE
  const [financeReportData, setFinanceReportData] = useState<any>()
  const [financeQuarterlyReportData, setFinanceQuarterlyReportData] = useState<any>()

  // QUARY

  const { data: financeData, isLoading: isFinanceIsLoading, isFetching: iseFinanceIsFetching } = useGetDashboardFinanceReportQuery({
    dateFilter: {
      startDate: dateFilter.startDate
        ? moment(dateFilter?.startDate).format("YYYY-MM-DD")
        : "",
      endDate: dateFilter.endDate
        ? moment(dateFilter?.endDate).format("YYYY-MM-DD")
        : dateFilter.startDate ? moment().format("YYYY-MM-DD") : '',
    },
  });

  const { data: financeQuarterlyData, isLoading: isFinanceQuarterlyIsLoading, isFetching: iseFinanceQuarterlyIsFetching } = useGetFinanceDashboardQuarterlyReportQuery({

    dateFilter: {
      startDate: moment().startOf('year').format("YYYY-MM-DD") || "",
      endDate: moment().endOf('year').format("YYYY-MM-DD") || '',
    },
  });

  // USE EFFECT
  useEffect(() => {
    if (!isFinanceIsLoading && !iseFinanceIsFetching) {
      setFinanceReportData(financeData?.data);
    }
  }, [financeData, isFinanceIsLoading, iseFinanceIsFetching]);


  useEffect(() => {
    if (!isFinanceQuarterlyIsLoading && !iseFinanceQuarterlyIsFetching) {
      setFinanceQuarterlyReportData(financeQuarterlyData?.data);
    }
  }, [financeQuarterlyData, isFinanceQuarterlyIsLoading, iseFinanceQuarterlyIsFetching]);

  return (
    <div>
      {(isFinanceIsLoading || iseFinanceIsFetching || isFinanceQuarterlyIsLoading || iseFinanceQuarterlyIsFetching) && (
        <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
          <CircularProgress />
        </div>
      )}
      <SubscriptionListing
        financeReportData={financeReportData}
        financeQuarterlyReportData={financeQuarterlyReportData}
      />
    </div>

  );
};

export default FinanceDashboardListingWrapper;
