import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react'
import ProjectFlowLayout from '../Layouts/ProjectFlowLayout';
import { useAddProjectFlowMutation } from 'src/services/ProjectServices';
import { object, string } from 'yup';
import { showToast } from 'src/utils/showToaster';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { v4 } from "uuid";
const getId = () => {
  return v4();
};
const ProjectFlowAddWrapper = () => { 
  const {id} = useParams() 

  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addProjectFlow] = useAddProjectFlowMutation(); // add ticket
 
  const navigate = useNavigate()
  // Form Initial Values
  const initialValues: any = {
    projectId: id || '',
    flowName: "",
    flow: "",
    flowChartData  :{
      nodes:[{
        id: getId(),
        position: { x: 0, y: 50 },
        data: { label: "Add Label", bgColor: "bg-white", shape: "Rectangle" },
        type: "rectNode",
      },] ,
      edges:[]
    }
  };

  // Validation Schema
  const validationSchema = object().shape({
    flowName: string().required("Flow Name is required."),
    flow: string().required("Flow is required."),
  });


  // Handle Submit
  const handleSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => { 
    
    // Add Ticket 

    addProjectFlow(values).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm(); 
            navigate(`/project/${id}/flow`)
          }
        } else {
          showToast("error", res?.data?.message);

        }
      }
      setSubmitting(false);
    });
  };


  return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <ProjectFlowLayout
              formikProps={formikProps}
              formType="ADD"
              setSaveNextChecked={setSaveNextChecked}
              saveNextChecked={saveNextChecked}
            />
          </Form>
        )}
      </Formik>
  )
}

export default ProjectFlowAddWrapper
