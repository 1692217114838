import React from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useNavigate } from "react-router-dom";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  addNew: () => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValueListing: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};

const TicketGroupList = ({
  columns,
  rows,
  addNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValueListing,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();

  return (
    <SideNavLayout>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div>
          <ATMPageHeader
            pageTitle="Ticket Group"
            searchValue={searchValueListing}
            onSearchChange={setSearchValue}
            hideAddButton={
              !isAuthorized(UserModuleNameTypes.ACTION_TICKET_GROUP_ADD)
            }
            buttonProps={{
              btnName: "Add New",
              onClick: () => navigate('/ticket-group/add'),
            }}
          />
        </div>

        {/* Table */}
        <div className="flex flex-col flex-1 overflow-auto">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "h-[50px]"}
            disableRowClick={
              !isAuthorized(UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW)
            }
            onRowClick={(row) => navigate(`${row?._id}/ticket-details`)}
            isLoading={isTableLoading}
          />
        </div>

        <div>
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </SideNavLayout>
  );
};

export default TicketGroupList;
