import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import {
  useEditRequirementGatheringPlatformsMutation,
  useGetRequirementGatheringPlatformsByIdQuery,
} from "src/services/RequirementGatheringService";
import { useParams } from "react-router-dom";
import { showToast } from "src/utils/showToaster";
import RequirementGatheringPlatforms from "../Layouts/RequirementGatheringPlatforms";
import { CircularProgress } from "@mui/material";

export type RequirementGatheringUsersValues = {
  platformName: string;
};

type Props = {
  onClose: () => void;
  selectedId: string;
};

const EditRequirementGatheringPlatforms = ({ onClose, selectedId }: Props) => {
  const [userData, setUserData] = useState<any>();

  const [editRequiremtGathering] = useEditRequirementGatheringPlatformsMutation();

  const { isLoading, isFetching, data } =
    useGetRequirementGatheringPlatformsByIdQuery(selectedId, {
      skip: !selectedId,
    });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setUserData(data?.data);
    }
  }, [isLoading, isFetching, data]);

  const { requirementId } = useParams();
  // Form Initial Values
  const initialValues: RequirementGatheringUsersValues = {
    platformName: userData?.platformName,
  };

  // Validation Schema
  const validationSchema = object().shape({
    platformName: string().required("Please enter Platform"),
  });

  // Handle Submit
  const handleSubmit = (
    values: RequirementGatheringUsersValues,
    { setSubmitting, resetForm }: FormikHelpers<RequirementGatheringUsersValues>
  ) => {
    const formattedValues = {
      requirementGatheringId: requirementId,
      platformName: values?.platformName,
    };
    editRequiremtGathering({
      Id: selectedId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
              {(isLoading || isFetching) && (
            <div className="absolute w-[100%] h-[100%] flex justify-center items-center z-10 bg-slate-100 opacity-50">
              <CircularProgress />
            </div>
          )}
            <RequirementGatheringPlatforms
              formikProps={formikProps}
              onClose={onClose} 
              formType='UPDATE'
            />
          </Form>
        )}
      </Formik>
  );
};

export default EditRequirementGatheringPlatforms;
