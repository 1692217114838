import React, { useEffect, useState } from "react";
import TaskTimeLogAnalyticsWrapper from "./TasksTime/List/TaskTimeLogAnalyticsWrapper";
import MeetingTimeLogAnalyticsWrapper from "./MeetingsTime/List/MeetingTimeLogAnalyticsWrapper";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import moment from "moment";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { CLIENT_GRW } from "src/utils/constants";

type Props = {};

const PlatformProjectTimeAnalyticsLayout = (props: Props) => {
  const { userData } = useGetLocalStorage();

  const {
    dateFilter,
    taskCTC,
    taskGCTC,
    meetingCTC,
    meetingGCTC,
    totalPayment,
  } = useSelector((state: RootState) => state.projectTimeAnalytics);
  const [isMeetingMode, setIsMeetingMode] = useState<any>(false);
  useEffect(() => {
    // get the meetingmode is enable or not
    // Retrieve the stored string value from localStorage
    const storedValue: string = localStorage.getItem(
      "isMeetingModeEnable"
    ) as string;
    // Convert the stored string value into a boolean
    const isEnabled: boolean = storedValue === "true";
    setIsMeetingMode(isEnabled);
  }, [isMeetingMode]);

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex flex-col overflow-scroll h-full">
          <div>
            <TaskTimeLogAnalyticsWrapper />
          </div>
          <div>
            <MeetingTimeLogAnalyticsWrapper />
          </div>
        </div>
        {userData?.userType === "SUPER_ADMIN" && (
          <div className="flex-1 bg-gray-100 p-2 w-full px-5">
            <div className="flex gap-5 items-center py-1">
              {dateFilter?.startDate !== null && (
                <div className="flex gap-3">
                  <span className="font-medium">
                    {dateFilter?.startDate !== null &&
                      moment(dateFilter?.startDate).format("DD MMM YYYY")}{" "}
                  </span>
                  <span className=" font-semibold">To</span>
                  <span className="font-medium">
                    {dateFilter?.endDate !== null &&
                      moment(dateFilter?.endDate).format("DD MMM YYYY")}{" "}
                  </span>
                </div>
              )}
              {!isMeetingMode && (
                <div>
                  <span className="font-semibold text-[18px] text-cyan-600">
                    CTC
                  </span>{" "}
                  : ₹{taskCTC + meetingCTC || 0}{" "}
                </div>
              )}
              {CLIENT_GRW === true ? (
                <div>
                  <span className="font-semibold text-[18px] text-green-600">
                    {!isMeetingMode ? "GCTC" : "CTC"}
                  </span>{" "}
                  : ₹{taskGCTC + meetingGCTC || 0}{" "}
                </div>
              ) : null}
              <div>
                <span className="font-semibold text-[18px] text-green-600">
                  Payment
                </span>{" "}
                : ₹{totalPayment?.paymentTotal || 0}{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PlatformProjectTimeAnalyticsLayout;
