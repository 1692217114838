import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { SubscriptionFormValues } from "src/models/Subscription.model";
import { useAddSubscriptionMutation } from "src/services/SubscriptionService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import SubscriptionForm from "../Layout/SubscriptionForm";

type Props = {
  onClose: () => void;
};

const AddSubscriptionFormWrapper = ({ onClose }: Props) => {
  const [saveNextChecked, setSaveNextChecked] = useState<boolean>(true);
  const [addSubscription] = useAddSubscriptionMutation();

  // Form Initial Values
  const initialValues: SubscriptionFormValues = {
    title: "",
    isRecurssion: false,
    recurranceType: "",
    amount: "",
    recurranceDay: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    title: string().required("Please enter title"),
    amount: string().test("cost-test", "Please enter amount", (value) => {
      return Number(value || 0) > 0;
    }),
  });

  // Handle Submit
  const handleSubmit = (
    values: SubscriptionFormValues,
    { setSubmitting, resetForm }: FormikHelpers<SubscriptionFormValues>
  ) => {
    const formattedValues = {
      recurranceType: values?.isRecurssion ? values?.recurranceType?.value : "",
      title: values?.title,
      isRecurssion: values?.isRecurssion,
      amount: values?.amount,
      recurranceDay: values?.recurranceDay,
    };

    addSubscription(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          if (!saveNextChecked) {
            resetForm();
            onClose();
          }
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <SubscriptionForm
            formType="ADD"
            setSaveNextChecked={setSaveNextChecked}
            saveNextChecked={saveNextChecked}
            formikProps={formikProps}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddSubscriptionFormWrapper;
