import { FieldArray, FormikProps } from "formik";
import { FiPlus } from "react-icons/fi";
import { TbTrash } from "react-icons/tb";
import ATMFormSectionHeading from "src/components/UI/atoms/ATMFormSectionHeading/ATMFormSectionHeading";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import MOLFileUpload from "src/components/UI/molecules/MOLFileUpload/MOLFileUpload";
import { JobApplicationFormValues } from "src/models/JobApplication.model";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { handleValidNumber } from "src/utils/validations/numberInput";

type Props = {
  formikProps: FormikProps<JobApplicationFormValues>;
  formHeading: "ADD" | "EDIT";
  onClose: () => void;
};

export const experienceRangeOption = [
  { label: "0-1 years", value: "0-1 years: Entry Level" },
  { label: "1-3 years", value: "1-3 years: Junior Level" },
  { label: "3-5 years", value: "3-5 years: Mid Level" },
  { label: "5-7 years", value: "5-7 years: Senior Level" },
  { label: "7-10 years", value: "7-10 years: Lead Level" },
  { label: "10-15 years", value: "10-15 years: Expert Level" },
  { label: "15+ years", value: "15+ years: Veteran Level" },
];
export const joiningAvailabilityOptions = [
  { label: "Immediate Joiner", value: "Immediate Joiner" },
  { label: "Serving Notice Period", value: "Serving Notice Period" },
];
export const employmentTypeOption = [
  { label: "Full Time", value: "Full-Time" },
  { label: "Part Time", value: "Part-Time" },
  { label: "Internship", value: "Internship" },
  { label: "Contract", value: "Contract" },
  { label: "Freelance", value: "Freelance" },
];

const JobApplicationForm = ({ formikProps, formHeading, onClose }: Props) => {
  const {
    values,
    isSubmitting,
    setFieldValue,
    handleBlur,
    errors,
    handleSubmit,
  } = formikProps;

  return (
    <div className="flex flex-col gap-6 p-3">
      <div className="py-2 border-b">
        <div className="flex items-center justify-between w-[70%] m-auto">
          <div className="text-xl font-medium">
            {" "}
            {formHeading === "ADD" ? "Add" : "Edit"} Job Application{" "}
          </div>
          <div className="flex gap-2">
            <div>
              <ATMLoadingButton
                onClick={() => onClose()}
                type="button"
                className="text-black bg-white"
              >
                Cancel
              </ATMLoadingButton>
            </div>
            <div>
              <ATMLoadingButton
                isLoading={isSubmitting}
                onClick={(e) => handleSubmit()}
              >
                Save
              </ATMLoadingButton>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-1 gap-4 w-[70%] m-auto py-4">
        <div className="col-span-2 xl:col-span-3 ">
          <ATMFormSectionHeading heading="Personal Information" />
        </div>
        {/* Applicant Name */}
        <div>
          <ATMTextField
            required
            name="applicantName"
            value={values.applicantName}
            onChange={(e) => setFieldValue("applicantName", e.target.value)}
            label="Name"
            placeholder="Enter Name"
            onBlur={handleBlur}
          />
        </div>
        {/* Applicant Email */}
        <div className="">
          <ATMTextField
            required
            name="applicantEmail"
            value={values.applicantEmail}
            onChange={(e) => setFieldValue("applicantEmail", e.target.value)}
            label="Email"
            placeholder="Enter Email"
            onBlur={handleBlur}
          />
        </div>
        {/* Application Mobile */}
        <div className="">
          <ATMTextField
            required
            name="applicantPhoneNumber"
            value={values.applicantPhoneNumber}
            onChange={(e) => {
              handleValidNumber(e) &&
                setFieldValue("applicantPhoneNumber", e.target.value);
            }}
            label="Mobile"
            placeholder="Enter Mobile"
            onBlur={handleBlur}
          />
        </div>
        {/* Resume Link */}
        <div className="">
          <MOLFileUpload
            required
            isShowCancel
            onCancelClick={() => setFieldValue("resumeLink", "")}
            value={values.resumeLink}
            label="Upload Resume"
            name={`resumeLink`}
            onChange={(file: any) => {
              setFieldValue("resumeLink", file);
            }}
            accept=".pdf"
            placeholder="Upload resume"
            labelClasses="text-sm text-black"
          />
        </div>
        {/* platformApplicationURL Link */}
        <div className="">
          <ATMTextField
            name="platformApplicationURL"
            value={values.platformApplicationURL}
            onChange={(e) =>
              setFieldValue("platformApplicationURL", e.target.value)
            }
            label="Platform Application URL"
            placeholder="Enter Platform Application URL"
            onBlur={handleBlur}
          />
        </div>
        <div className="col-span-2 xl:col-span-3 pt-5 ">
          <ATMFormSectionHeading heading="Experience" />
        </div>

        <div className="col-span-3">
          <FieldArray name="experience">
            {({ push, remove }) => (
              <>
                {values?.experience?.map((label: any, index: number) => (
                  <div key={index}>
                    <div className="flex justify-end ">
                      <ATMLoadingButton
                        className="bg-transparent text-red-500 border-0 font-bold w-[20px] h-[38px]"
                        onClick={() => {
                          showConfirmationDialog({
                            title: "Heads Up",
                            text: "Are you sure want to Delete this Academic History?",
                            icon: "question",
                            showCancelButton: true,
                            next: (result) => {
                              if (result.isConfirmed) {
                                remove(index);
                              }
                            },
                          });
                        }}
                      >
                        <TbTrash size={20} />
                      </ATMLoadingButton>
                    </div>
                    <div className="grid grid-cols-12 gap-4">
                      {/* Label Name*/}

                      <div className="col-span-4">
                        <ATMTextField
                          name={`experience[${index}].profile`}
                          value={values?.experience[index]?.profile}
                          onChange={(e) =>
                            setFieldValue(
                              `experience[${index}].profile`,
                              e.target.value
                            )
                          }
                          label="Profile"

                          placeholder="Enter Profile"
                          onBlur={handleBlur}
                        />
                      </div>

                      {/* Status */}
                      <div className="col-span-4 my-3 md:my-0">
                        <ATMTextField
                          name={`experience[${index}].company`}
                          value={values?.experience[index]?.company}
                          onChange={(e) =>
                            setFieldValue(
                              `experience[${index}].company`,
                              e.target.value
                            )
                          }
                          label="Company"

                          placeholder="Enter Company"
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="col-span-4 my-3 md:my-0">
                        <ATMTextField
                          name={`experience[${index}].city`}
                          value={values?.experience[index]?.city}
                          onChange={(e) =>
                            setFieldValue(
                              `experience[${index}].city`,
                              e.target.value
                            )
                          }
                          label="City"

                          placeholder="Enter City"
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="col-span-4 my-3 md:my-0">
                        <ATMTextField
                          name={`experience[${index}].country`}
                          value={values?.experience[index]?.country}
                          onChange={(e) =>
                            setFieldValue(
                              `experience[${index}].country`,
                              e.target.value
                            )
                          }
                          label="Country"

                          placeholder="Enter Country"
                          onBlur={handleBlur}
                        />
                      </div>


                      <div className="col-span-6 flex justify-between gap-2">
                        <div className="my-3 md:my-0">
                          <ATMDatePicker
                            label="From"
                            name={`experience[${index}].from`}
                            format="MM/YYYY"
                            views={['month', 'year']}
                            // minDate={moment().format("yyyy-MM-DD")}
                            value={values?.experience[index]?.from}
                            onChange={(e) =>
                              setFieldValue(
                                `experience[${index}].from`,
                                e
                              )
                            }
                          />
                        </div>
                        {!values?.experience[index]?.isPresent &&
                          <div className="my-3 md:my-0">
                            <ATMDatePicker
                              label="To"
                              name={`experience[${index}].to`}
                              format="MM/YYYY"
                              views={['month', 'year']}
                              // minDate={moment().format("yyyy-MM-DD")}
                              value={values?.experience[index]?.to}
                              onChange={(e) =>
                                setFieldValue(
                                  `experience[${index}].to`,
                                  e
                                )
                              }
                            />
                          </div>
                        }
                      </div>
                      <div className="col-span-1 mt-8">
                        <ATMCheckbox
                          id="isPresent"
                          checked={values?.experience[index]?.isPresent}
                          onChange={(checked) => {
                            setFieldValue(`experience[${index}].isPresent`, checked)
                            setFieldValue(
                              `experience[${index}].to`,
                              ""
                            )
                          }}
                          label="Present"
                          labelClasses="select-none"
                        />
                      </div>

                    </div>
                  </div>
                ))}

                {/* Add More Button */}
                <div className="flex justify-end mt-3">
                  <button
                    type="button"
                    onClick={() =>
                      push({
                        profile: '',
                        company: '',
                        city: '',
                        country: '',
                        from: null,
                        to: null,
                        isPresent: false
                      })
                    }
                    className="rounded border-dashed border p-1 border-slate-500 font-medium text-md text-gray-400 flex justify-center  gap-1 items-center"
                  >
                    <FiPlus size="1.3em" /> Add More
                  </button>
                </div>
              </>
            )}
          </FieldArray>
        </div>

        {/* Experience */}

        <div className="col-span-2 xl:col-span-3 pt-5 ">
          <ATMFormSectionHeading heading="Others" />
        </div>
        {/* Employment Type */}
        <div className="">
          <ATMSelect
            name="employmentType"
            value={values.employmentType}
            onChange={(newValue) => setFieldValue("employmentType", newValue)}
            onBlur={handleBlur}
            label="Employment Type"
            placeholder="Select Employment Type"
            options={employmentTypeOption}
          />
        </div>
        {/* Joining Availability */}
        <div className="">
          <ATMSelect
            name="joiningAvailability"
            value={values.joiningAvailability}
            onChange={(newValue) =>
              setFieldValue("joiningAvailability", newValue)
            }
            onBlur={handleBlur}
            label="Availability Type"
            placeholder="Select Availability Type"
            options={joiningAvailabilityOptions}
          />
        </div>

        {/* Min Expected Packae */}
        <div className="relative">
          <div className="text-sm font-medium">
            {" "}
            Expected Salary <span className="text-red-500"> * </span>{" "}
          </div>
          <div className="flex gap-5 items-center  mt-2">
            <div className="w-[100px]">
              <ATMTextField
                name="expectedPackageMin"
                value={values.expectedPackageMin}
                onChange={(e) =>
                  handleValidNumber(e) &&
                  setFieldValue("expectedPackageMin", e.target.value)
                }
                label=""
                placeholder=""
                onBlur={handleBlur}
                className="text-center rounded-lg"
                isShowErrorMessage={false}
              />
            </div>
            <div>-</div>
            <div className="w-[100px]">
              <ATMTextField
                name="expectedPackageMax"
                value={values.expectedPackageMax}
                onChange={(e) =>
                  handleValidNumber(e) &&
                  setFieldValue("expectedPackageMax", e.target.value)
                }
                label=""
                placeholder=""
                onBlur={handleBlur}
                className="text-center rounded-lg"
                isShowErrorMessage={false}
              />
            </div>
          </div>
          {errors?.expectedPackageMin || errors?.expectedPackageMax ? (
            <p className="absolute mt-0 text-xs text-red-500 text-start">
              Please enter the expected salary range
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default JobApplicationForm;
