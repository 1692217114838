import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ATMActionMenu from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setItems,
  setIsTableLoading,
  setTotalItems,
} from "src/redux/slices/ChannelSlice";
import { RootState } from "src/redux/store";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import AddChannelFormWrapper from "../Add/AddChannelFormWrapper";
import EditChannelFormWrapper from "../Edit/EditChannelFormWrapper";
import ChannelListing from "./ChannelListing";
import {
  useDeleteChannelMutation,
  useGetAllChannelsQuery,
} from "src/services/ChannelService";
import { showToast } from "src/utils/showToaster";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";

const ChannelListingWrapper = () => {
  const dispatch = useDispatch();
  const { isTableLoading, totalItems, page, rowsPerPage, searchValue, items } =
    useSelector((state: RootState) => state.channel);
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState("");
  const [deleteChannel] = useDeleteChannelMutation();
  const { data, isLoading, isFetching } = useGetAllChannelsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: ["channelName", "channelGroupId"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    } else {
      dispatch(setIsTableLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const getActionOptions = (channelId: string) => {
    return [
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdEdit color="#000000" /> Edit
          </div>
        ),
        onClick: () => {
          setIsOpenEditForm(true);
          setSelectedChannelId(channelId);
        },
        acitonName: UserModuleNameTypes.ACTION_CHANNEL_EDIT,
      },
      {
        label: (
          <div className="flex items-center gap-x-2">
            <MdDelete color="#000000" /> Delete
          </div>
        ),
        onClick: () => {
          showConfirmationDialog({
            title: "Hands Up",
            text: "Are you sure want to delete this Channel?",
            icon: "question",
            showCancelButton: true,
            next: (result) => {
              if (result?.isConfirmed) {
                deleteChannel(channelId).then((res: any) => {
                  if (res.error) {
                    showToast("error", res?.error?.data?.message);
                  } else {
                    if (res?.data?.status) {
                      showToast("success", res?.data?.message);
                    } else {
                      showToast("success", res?.data?.message);
                    }
                  }
                });
              }
            },
          });
        },
        acitonName: UserModuleNameTypes.ACTION_CHANNEL_DELETE,
      },
    ];
  };
  const columns: columnTypes[] = [
    {
      field: "channelName",
      headerName: "Channel Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      name: UserModuleNameTypes.CHANNEL_LIST_CHANNEL_NAME,
    },
    {
      field: "channelGroupName",
      headerName: "Channel Group Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      name: UserModuleNameTypes.CHANNEL_LIST_CHANNEL_GROUP_NAME,
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => {
        const options = getActionOptions(row?._id);
        return <ATMActionMenu options={options} orientation="vertical" />;
      },
    },
  ];
  return (
    <div>
      <ChannelListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
        onAddNew={() => setIsOpenAddForm(true)}
      />
      {isOpenAddForm ? (
        <AddChannelFormWrapper onClose={() => setIsOpenAddForm(false)} />
      ) : null}
      {isOpenEditForm ? (
        <EditChannelFormWrapper
          onClose={() => setIsOpenEditForm(false)}
          channelId={selectedChannelId}
        />
      ) : null}
    </div>
  );
};

export default ChannelListingWrapper;
