/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useParams } from "react-router-dom";
import ATMActionMenu, {
  OptionType,
} from "src/components/UI/atoms/ATMActionMenu/ATMActionMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import MOLPriorityChip from "src/components/UI/molecules/MOLPriorityChip/MOLPriorityChip";
import MOLTicketStatusChip from "src/components/UI/molecules/MOLTicketStatusChip/MOLTicketStatusChip";
import MOLTicketTypeChip from "src/components/UI/molecules/MOLTicketTypeChip/MOLTicketTypeChip";
import { TicketListResponse } from "src/models/Ticket.model";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import {
  useDeleteTicketGroupTicketMutation,
  useGetAllTicketGroupSingleQuery,
} from "src/services/TicketGroupServices";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import TicketGroupDetailsView from "./TicketGroupDetailsView";

const TicketGroupDetailsViewWrapper = () => {
  const { id: TICKET_GROUP_ID } = useParams();

  const [ticketsData, setTicketsData] = useState<any>();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const { isLoading, isFetching, data } = useGetAllTicketGroupSingleQuery(
    TICKET_GROUP_ID,
    { skip: !TICKET_GROUP_ID }
  );

  const [deleteTicket] = useDeleteTicketGroupTicketMutation();

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsTableLoading(true);
    } else {
      setTicketsData(data?.data);
      setIsTableLoading(false);
    }
  }, [isLoading, isFetching, data]);

  enum TicketPriorityEnum {
    low = "LOW",
    medium = "MEDIUM",
    high = "HIGH",
    urgent = "URGENT",
  }

  const columns: columnTypes[] = [
    {
      field: "ticketNumber",
      headerName: "Ticket Id",
      flex: "flex-[2_2_0%]",
      extraClasses: "min-w-[120px] max-w-[160px]",
      name: UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_ID,
      renderCell: (row: TicketListResponse) => (
        <div className="text-blue-500"> {row?.ticketNumber} </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date-Time",
      extraClasses: "min-w-[120px] max-w-[120px]",
      name: UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_DATE_TIME,
      renderCell: (row: TicketListResponse) => (
        <div className="py-1">
          <div className="text-[15px] text-slate-500 font-medium">
            {moment(row?.createdAt).format("hh:mm A")}
          </div>
          <div className="text-[15px] text-slate-700 font-medium">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Ticket Title",
      flex: "flex-[2_2_0%]",
      name: UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_TICKET_TITLE,
      extraClasses: "min-w-[120px] max-w-[200px]",
      renderCell: (row: TicketListResponse) => (
        <div className="w-full overflow-hidden truncate">{row?.title}</div>
      ),
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_PROJECT,
      extraClasses: "min-w-[100px]",
      renderCell: (row: TicketListResponse) => (
        <div className="capitalize">{row?.projectName} </div>
      ),
    },
    {
      field: "priority",
      headerName: "Priority",
      extraClasses: "min-w-[100px] max-w-[110px]",
      name: UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_PRIORITY,
      renderCell: (row: TicketListResponse) => (
        <div>
          <MOLPriorityChip priority={row?.priority as TicketPriorityEnum} />
        </div>
      ),
    },
    {
      field: "createdByLabel",
      headerName: "Created By",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_CREATED_BY,
      extraClasses: "min-w-[100px]",
      align: "center",
      renderCell: (row: TicketListResponse) => (
        <div className="capitalize"> {row?.createdByLabel} </div>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      extraClasses: "min-w-[180px] max-w-[180px]",
      name: UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_TYPE,
      renderCell: (row: TicketListResponse) => (
        <div className="flex justify-center">
          <MOLTicketTypeChip ticketType={row?.type as any} />
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Stage",
      flex: "flex-[1_1_0%]",
      name: UserModuleNameTypes.ACTION_TICKET_GROUP_SINGLE_VIEW_TICKET_DETAILS_LIST_STAGE,
      extraClasses: "min-w-[180px] max-w-[180px]",
      renderCell: (row: TicketListResponse) => (
        <div>
          <MOLTicketStatusChip status={row?.status as any} />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      extraClasses: "min-w-[100px]",
      renderCell: (row: any) => (
        <div>
          <ATMActionMenu
            options={
              [
                {
                  label: (
                    <div className="flex items-center gap-x-2">
                      <MdDelete color="#000000" /> Delete
                    </div>
                  ),
                  onClick: () => {
                    showConfirmationDialog({
                      title: "Hands Up",
                      text: "Are you sure want to delete this ticket from this group?",
                      icon: "question",
                      showCancelButton: true,
                      next: (result) => {
                        if (result?.isConfirmed) {
                          deleteTicket({
                            id: TICKET_GROUP_ID,
                            body: { ticketId: row?._id },
                          }).then((res: any) => {
                            if (res.error) {
                              showToast("error", res?.error?.data?.message);
                            } else {
                              showToast("success", res?.data?.message);
                            }
                          });
                        }
                      },
                    });
                  },
                  // acitonName: UserModuleNameTypes.ACTION_TICKET_GROUP_DELETE,
                },
              ] as unknown as OptionType[] // Explicitly cast the array to OptionType[]
            }
          />
        </div>
      ),
    },
  ];
  return (
    <TicketGroupDetailsView
      items={ticketsData}
      columns={columns}
      isLoading={isTableLoading}
    />
  );
};

export default TicketGroupDetailsViewWrapper;
