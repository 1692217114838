import moment from "moment";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMTable from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { ProjectListResponse } from "src/models/Project.model";
import {
  setDateFilter,
  setProjectValueFilter,
} from "src/redux/slices/TimeAnalyticsSlice";
import { AppDispatch, RootState } from "src/redux/store";

type Props = {
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
  meetingColumns: any;
  taskColumns: any;
};

const TimeAnalyticsList = ({
  rows,
  paginationProps: { isTableLoading },
  taskColumns,
  meetingColumns,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const projectTimeState = useSelector(
    (state: RootState) => state.timeAnalytics
  );
  const { projectFilterValue, dateFilter } = projectTimeState;

  const navigate = useNavigate();

  const meetingMOMRef = useRef(null);

  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();

  const handlePrint = useReactToPrint({
    content: () => meetingMOMRef?.current,
    documentTitle: `${"task-meeting-data"}(codiotic-connect)`,
  });

  const totalTaskTimeCalculate = rows[0]?.tasks?.reduce(
    (acc: number, el: any) => {
      return acc + (el?.totalDuration || 0);
    },
    0
  );

  const totalMeetingTimeCalculate = rows[0]?.meeting?.reduce(
    (acc: number, el: any) => {
      return acc + (el?.durationInMinutes || 0);
    },
    0
  );

  return (
    <SideNavLayout>
      <div className="h-[95vh] overflow-hidden flex flex-col">
        <div className="flex items-center justify-between ">
          <ATMPageHeader
            pageTitle="Time Analytics"
            hideSearchBox
            hideAddButton={true}
          />
        </div>
        <div className="flex justify-between px-4 mb-2">
          <div className="flex items-center gap-6">
            {/* Project */}
            <div className="z-50">
              <ATMSelect
                name=""
                label="Project"
                placeholder="Select Project"
                value={projectFilterValue}
                isLoading={isAllProjectsDataLoading}
                options={allProjects?.map((ele: ProjectListResponse) => {
                  return {
                    label: ele?.projectName,
                    value: ele?._id,
                  };
                })}
                onChange={(newValue) => {
                  dispatch(setProjectValueFilter(newValue));
                }}
              />
            </div>

            {/* From */}
            <div className="flex justify-between gap-2 ">
              <div className="w-full">
                <ATMDatePicker
                  label="From"
                  name=""
                  format="DD/MM/YYYY"
                  maxDate={moment().format("yyyy-MM-DD")}
                  value={dateFilter?.startDate}
                  onChange={(newValue) =>
                    dispatch(
                      setDateFilter({
                        startDate: newValue,
                        endDate: dateFilter?.endDate,
                      })
                    )
                  }
                />
              </div>

              {/* To */}
              <div className="w-full">
                <ATMDatePicker
                  label="To"
                  name=""
                  format="DD/MM/YYYY"
                  value={dateFilter?.endDate}
                  minDate={dateFilter?.startDate || ""}
                  onChange={(newValue) =>
                    dispatch(
                      setDateFilter({
                        startDate: dateFilter?.startDate,
                        endDate: newValue,
                      })
                    )
                  }
                />
              </div>
            </div>
          </div>
          {projectFilterValue && (
            <div className="flex justify-end mt-5">
              <ATMLoadingButton className="" onClick={handlePrint}>
                Download
              </ATMLoadingButton>
            </div>
          )}
        </div>

        {!projectFilterValue ? (
          <div className="py-5 text-xl text-center text-slate-500">
            Please Select Project !
          </div>
        ) : (
          <div>
            <div className="flex gap-4 px-2 md:px-4">
              <div className="w-full ">
                <div className="text-lg font-medium">Task</div>
                <div className="flex items-center gap-1">
                  <span className="font-semibold">
                    {`${Math?.floor(totalTaskTimeCalculate / 60)}:${(
                      totalTaskTimeCalculate % 60
                    )?.toFixed()}  hrs`}
                  </span>

                  <span className="text-sm text-neutral">
                    ({`${totalTaskTimeCalculate?.toFixed()}`} min)
                  </span>
                </div>
              </div>
              <div className="w-full ml-4 text-lg font-medium">
                <div>Meeting</div>
                <div className="flex items-center gap-1">
                  <span className="font-semibold">
                    {`${Math?.floor(totalMeetingTimeCalculate / 60)}:${(
                      totalMeetingTimeCalculate % 60
                    )?.toFixed()}  hrs`}
                  </span>

                  <span className="text-sm text-neutral">
                    ({`${totalMeetingTimeCalculate?.toFixed()}`} min)
                  </span>
                </div>
              </div>
            </div>
            {/* Page Header */}
            <div className=" h-[75vh] overflow-auto mb-4 flex  gap-2">
              <div className="w-full overflow-y-auto ">
                {/* <div className="fixed top-0"> </div> */}
                <div className="flex flex-col flex-1 px-2 overflow-auto md:px-4">
                  <ATMTable
                    columns={taskColumns}
                    rows={rows[0]?.tasks || []}
                    rowExtraClasses={() => "min-h-[30px]"}
                    onRowClick={(row) => navigate(`/task/${row?._id}`)}
                    isLoading={isTableLoading}
                  />
                </div>
              </div>
              <div className="w-full overflow-y-auto ">
                <div className="flex flex-col flex-1 px-2 overflow-auto md:px-4">
                  <ATMTable
                    columns={meetingColumns}
                    rows={rows[0]?.meeting || []}
                    rowExtraClasses={() => "min-h-[30px]"}
                    onRowClick={(row) =>
                      navigate(`/meeting/${row?.meetingId}/agenda`)
                    }
                    isLoading={isTableLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="opacity-0 -z-10">
          {!projectFilterValue ? (
            <div className="py-5 text-xl text-center text-slate-500">
              Please Select Project !
            </div>
          ) : (
            <div ref={meetingMOMRef}>
              <div className="flex justify-center my-1 font-semibold capitalize text-md">
                {rows[0]?.projectName}
              </div>
              <div className="flex gap-4 px-2 md:px-4">
                <div className="w-full ">
                  <div className="text-lg font-medium">Task</div>
                  <div className="flex items-center gap-1">
                    <span className="font-semibold">
                      {`${Math?.floor(totalTaskTimeCalculate / 60)}:${(
                        totalTaskTimeCalculate % 60
                      )?.toFixed()}  hrs`}
                    </span>

                    <span className="text-sm text-neutral">
                      ({`${totalTaskTimeCalculate?.toFixed()}`} min)
                    </span>
                  </div>
                </div>
              </div>
              {/* Page Header */}
              <div className="flex flex-col w-full gap-2 mb-4">
                <div className="w-full overflow-y-auto ">
                  {/* <div className="fixed top-0"> </div> */}
                  <div className="flex flex-col px-2 overflow-auto md:px-4">
                    <ATMTable
                      columns={taskColumns}
                      rows={rows[0]?.tasks || []}
                      rowExtraClasses={() => "min-h-[30px]"}
                      onRowClick={(row) => navigate(`/task/${row?.taskId}`)}
                      isLoading={isTableLoading}
                    />
                  </div>
                </div>
                <div className="w-full mt-2 overflow-y-auto">
                  <div className="w-full ml-4 text-lg font-medium">
                    <div>Meeting</div>
                    <div className="flex items-center gap-1">
                      <span className="font-semibold">
                        {`${Math?.floor(totalMeetingTimeCalculate / 60)}:${(
                          totalMeetingTimeCalculate % 60
                        )?.toFixed()}  hrs`}
                      </span>

                      <span className="text-sm text-neutral">
                        ({`${totalMeetingTimeCalculate?.toFixed()}`} min)
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 px-2 overflow-auto md:px-4">
                    <ATMTable
                      columns={meetingColumns}
                      rows={rows[0]?.meeting || []}
                      rowExtraClasses={() => "min-h-[30px]"}
                      onRowClick={(row) =>
                        navigate(`/meeting/${row?.meetingId}/agenda`)
                      }
                      isLoading={isTableLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </SideNavLayout>
  );
};

export default TimeAnalyticsList;
