import { FormikProps } from "formik";
import moment from "moment";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";

import {
  handleValidNumber,
  handleValidWithDotNumber,
} from "src/utils/validations/numberInput";
import { RecurringPaymentsFormValues } from "../Add/AddRecurringPaymentsFormWrapper";
import { useGetAllClient } from "src/hooks/useGetAllClient";
import { useEffect } from "react";
import useCurrency from "src/hooks/useCurrency";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<RecurringPaymentsFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const weeklyOptions = [
  {
    label: "MONDAY",
    value: "MONDAY",
  },
  {
    label: "TUESDAY",
    value: "TUESDAY",
  },
  {
    label: "WEDNESDAY",
    value: "WEDNESDAY",
  },
  {
    label: "THRUSDAY",
    value: "THRUSDAY",
  },
  {
    label: "FRIDAY",
    value: "FRIDAY",
  },
  {
    label: "SATURDAY",
    value: "SATURDAY",
  },
  {
    label: "SUNDAY",
    value: "SUNDAY",
  },
];
const recurranceTypeOptions = [
  {
    label: "DAILY",
    value: "DAILY",
  },
  {
    label: "WEEKLY",
    value: "WEEKLY",
  },
  {
    label: "MONTHLY",
    value: "MONTHLY",
  },
  {
    label: "QUARTERLY",
    value: "QUARTERLY",
  },
  {
    label: "HALF YEARLY",
    value: "HALF YEARLY",
  },
  {
    label: "YEARLY",
    value: "YEARLY",
  },
];

const RecurringPaymentsForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const { currency, isDataLoading } = useCurrency();

  const { allClient, isDataLoading: isAllClientLoading } = useGetAllClient();

  useEffect(() => {
    const taxAmount =
      Number(values?.amount) * (Number(values?.taxPercent) / 100);
    setFieldValue("taxAmount", taxAmount.toFixed(2));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.taxPercent, values?.amount]);

  return (
    <MOLFormDialog
      title={
        formType === "ADD"
          ? "Add Recurring Payments"
          : "Update Recurring Payments"
      }
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4 items-end">
          <div className="mt-1">
            <ATMSelect
              required
              isLoading={isAllClientLoading}
              name="client"
              label="Client"
              placeholder="Select Client"
              value={values?.client}
              options={allClient?.map((ele: any) => {
                return {
                  label: ele?.organisationName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("client", newValue);
              }}
            />
          </div>
          {/* Organisation Name */}
          <div className=" mt-1">
            <ATMSelect
              required
              extraMinWidthClass={150}
              name="currencyId"
              label="Currency"
              placeholder="Select Currency"
              value={values.currencyId}
              isLoading={isDataLoading}
              options={currency?.map((ele: any) => {
                return {
                  label: ele?.currencyName,
                  value: ele?._id,
                };
              })}
              onChange={(newValue) => {
                setFieldValue("currencyId", newValue);
              }}
            />
          </div>
          <div>
            <ATMTextField
              required
              name="amount"
              value={values.amount}
              onChange={(e) =>
                handleValidNumber(e) && setFieldValue("amount", e.target.value)
              }
              label="Amount"
              placeholder="Enter Amount"
              onBlur={handleBlur}
            />
          </div>
          {/*Tax Percent */}
          <div className="mt-1">
            <ATMTextField
              name="taxPercent"
              value={values?.taxPercent}
              onChange={(e) => {
                handleValidWithDotNumber(e) &&
                  setFieldValue("taxPercent", e.target.value);
              }}
              label="Tax Percent"
              placeholder="Enter Tax Percent"
              className="mt-0 rounded"
              onBlur={handleBlur}
            />
          </div>
          {/*Tax Amount */}
          <div>
            <ATMTextField
              disabled
              name="taxAmount"
              value={values.taxAmount}
              onChange={(e) => {
                handleValidWithDotNumber(e) &&
                  setFieldValue("taxAmount", e.target.value);
              }}
              label="Tax Amount"
              placeholder="Enter Tax Amount"
              className="mt-0 rounded"
              onBlur={handleBlur}
            />
          </div>

          {/*TDS Amount */}
          <div>
            <ATMTextField
              name="tdsAmount"
              value={values.tdsAmount}
              onChange={(e) => {
                handleValidWithDotNumber(e) &&
                  setFieldValue("tdsAmount", e.target.value);
              }}
              label="TDS Amount"
              placeholder="Enter TDS Amount"
              className="mt-0 rounded"
              onBlur={handleBlur}
            />
          </div>

          {/* conversionLoss */}
          <div>
            <ATMTextField
              name="conversionLoss"
              value={values.conversionLoss}
              onChange={(e) => {
                handleValidWithDotNumber(e) &&
                  setFieldValue("conversionLoss", e.target.value);
              }}
              label="Conversion Loss"
              placeholder="Enter Conversion Loss"
              className="mt-0 rounded"
              onBlur={handleBlur}
            />
          </div>
          <div className="flex items-center">
            <ATMCheckbox
              id="isRecurssion"
              label="Recurring"
              checked={values?.isRecurssion}
              inputClasses="h-[15px]"
              labelClasses="text-sm select-none"
              onChange={(checked) => {
                setFieldValue("isRecurssion", checked);
                setFieldValue("recurranceType", "");
                setFieldValue("recurranceDay", "");
                setFieldValue("endOfTheMonth", false);
              }}
            />
          </div>

          {values?.isRecurssion === true && (
            <div className="mt-1">
              <ATMSelect
                name="recurranceType"
                label="Reccurance Type"
                placeholder="Select reccurance type"
                value={values?.recurranceType}
                options={recurranceTypeOptions}
                onChange={(newValue) => {
                  setFieldValue("recurranceType", newValue);
                  setFieldValue("recurranceDay", "");
                }}
              />
            </div>
          )}
          {values?.recurranceType?.value === "WEEKLY" && (
            <div>
              <ATMSelect
                name="recurranceDay"
                label="Day"
                placeholder="Select Day"
                value={values?.recurranceDay}
                options={weeklyOptions}
                onChange={(newValue) => {
                  setFieldValue("recurranceDay", newValue);
                }}
              />
            </div>
          )}
          {!values?.endOfTheMonth ? (
            <>
              {(values?.recurranceType?.value === "YEARLY" ||
                values?.recurranceType?.value === "MONTHLY" ||
                values?.endOfTheMonth) && (
                <div>
                  <ATMDatePicker
                    label="Day"
                    name="recurranceDay"
                    format={
                      values?.recurranceType?.value === "YEARLY"
                        ? "DD/MM"
                        : "DD"
                    }
                    minDate={moment().format("yyyy-MM-DD")}
                    value={values.recurranceDay}
                    onChange={(newValue) =>
                      setFieldValue("recurranceDay", newValue)
                    }
                  />
                </div>
              )}
            </>
          ) : null}
          {values?.recurranceType?.value === "MONTHLY" && (
            <div className="mt-2">
              <ATMCheckbox
                id="endOfTheMonth"
                label="End of the month"
                checked={values?.endOfTheMonth}
                inputClasses="h-[15px]"
                labelClasses="text-sm select-none"
                onChange={(checked) => {
                  setFieldValue("endOfTheMonth", checked);
                  setFieldValue("recurranceDay", "");
                }}
              />
            </div>
          )}

          <div>
            <ATMTextField
              name="paymentRemark"
              value={values.paymentRemark}
              onChange={(e) => setFieldValue("paymentRemark", e.target.value)}
              label="Payment Remark"
              placeholder="Enter Payment Remark"
              onBlur={handleBlur}
            />
          </div>

          <div className="mt-1">
            <ATMDatePicker
              required
              label="Start Date"
              name="startDate"
              format="DD/MM/YYYY"
              value={values.startDate}
              onChange={(newValue) => setFieldValue("startDate", newValue)}
            />
          </div>

          <div>
            <ATMDatePicker
              label="Expiry Date"
              name="expiryDate"
              format="DD/MM/YYYY"
              value={values.expiryDate}
              onChange={(newValue) => setFieldValue("expiryDate", newValue)}
            />
          </div>
        </div>
      </div>
    </MOLFormDialog>
  );
};

export default RecurringPaymentsForm;
