import React from "react";
// import moment from "moment";
// import { object, string } from "yup";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import TaskFilterFormCardDialog from "./TaskFilterFormCardDialog";
import {
  setUserValueFilter,
  setTaskStatusValueFilter,
  setDueDateFilter,
  setProjectValueFilter,
  setModuleValueFilter,
  setFeatureValueFilter,
  setLabelValueFilter,
  setIsTaskArchivedFilter,
} from "src/redux/slices/TaskSlice";
import moment from "moment";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  codioticUser: any;
  status: any;
  dueDate: any;
  project: any;
  module: any;
  feature: any;
  label: any;
  isTaskArchived:any;
};

const TaskFilterFormCardDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    userFilterValue,
    taskStatus,
    dueDateFilterValue,
    projectFilterValue,
    moduleFilterValue,
    featureFilterValue,
    labelFilterValue,
    isTaskArchived
  } = useSelector((state: RootState) => state.task);

  // Initial Values
  const initialValues: FormInitialValues = {
    codioticUser: userFilterValue,
    status: taskStatus,
    dueDate: dueDateFilterValue,
    project: projectFilterValue,
    module: moduleFilterValue,
    feature: featureFilterValue,
    label: labelFilterValue,
    isTaskArchived: isTaskArchived,
  };

  // const validationSchema: any = object({
  //   startDate: string().required("please select start date"),
  //   endDate: string().required("please select end date"),
  // });

  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);

    // set codiotic user filter value
    dispatch(setUserValueFilter(values.codioticUser));

    // set project label filter value
    dispatch(setLabelValueFilter(values?.label));

    // set task status filter value
    dispatch(setTaskStatusValueFilter(values.status));

    // set task due date filter value
    dispatch(
      setDueDateFilter(
        values.dueDate ? moment(values.dueDate)?.format("yyyy-MM-DD") : ""
      )
    );

    //  set project value
    dispatch(setProjectValueFilter(values.project));

    // set module value
    dispatch(setModuleValueFilter(values.module));

    // set feature value
    dispatch(setFeatureValueFilter(values.feature));
    dispatch(setIsTaskArchivedFilter(values?.isTaskArchived));


    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // set User filter filter dispatch
    await dispatch(setUserValueFilter(""));

    // set project label filter value
    await dispatch(setLabelValueFilter(""));

    // set task status filter dispatch
    dispatch(setTaskStatusValueFilter(""));

    // set task due date filter value
    await dispatch(setDueDateFilter(null));

    //  set project value
    await dispatch(setProjectValueFilter(""));

    // set module value
    await dispatch(setModuleValueFilter(""));

    // set feature value
    await dispatch(setFeatureValueFilter(""));
    await dispatch(setIsTaskArchivedFilter(false));

    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <TaskFilterFormCardDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TaskFilterFormCardDialogWrapper;
