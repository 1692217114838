import React, { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { FaUser } from "react-icons/fa";
import { RxLapTimer } from "react-icons/rx";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetLocalStorage } from "src/hooks/useGetLocalStorage";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { useGetProductsQuery } from "src/services/ProductService";
import AccessDenied from "src/utils/AccessDenied";
import { isAuthorized } from "src/utils/authorization";
import ProductSideBarLayout from "./ProductSideBarLayout/ProductSideBarLayout";

type Props = {};

export const productViewTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
    {
      label: "PLANS",
      icon: FaUser,
      path: "plans",
      moduleName: UserModuleNameTypes.ACTION_PRODUCT_PLANS_TAB
    },
    {
      label: "CUSTOMERS",
      icon: RxLapTimer,
      path: "customer",
      moduleName: UserModuleNameTypes.ACTION_PRODUCT_VIEW_CUSTOMERS
    },
  ];

const ProductViewTabsLayout = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[3];
  const { userData } = useGetLocalStorage();
  const [productList, setProductList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);

  const { data, isLoading, isFetching } = useGetProductsQuery({
    limit: 20,
    searchValue: searchValue,
    params: ["productName"],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isLoading || !isFetching) {
      if(page ===1){
        setProductList(data?.data || []);
      }else{
        setProductList((prev: any) => {
          return [...prev, ...data?.data];
        });
      }
     
      setTotalPage(data?.totalPage || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  React.useEffect(() => {
    localStorage.removeItem("hasExecuted");
    if (userData?.userType === "SUPER_ADMIN") {
      // navigate("open");
      return;
    }
    const hasExecuted = localStorage.getItem("hasExecuted");

    if (hasExecuted) {
      return; // Exit early if the function has been executed
    }

    for (const nav of productViewTabs) {
      const isValue = isAuthorized(
        nav?.moduleName as keyof typeof UserModuleNameTypes
      );
      localStorage.setItem("hasExecuted", "true");
      if (isValue) {
        navigate(nav?.path);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tabsRender = productViewTabs?.some((nav) => {
    return isAuthorized(nav?.moduleName as keyof typeof UserModuleNameTypes);
  });
  return (
    <>
      <SideNavLayout>
        <ProductSideBarLayout
          listData={productList}
          searchValue={searchValue}
          onSearch={(value: string) => setSearchValue(value)}
          hideViewMore={page < totalPage ? true : false}
          onClickViewMore={() => setPage((prev) => prev + 1)}
          onClickProduct={(productId) =>
            navigate(`/product/${productId}/plans`)
          }
          isLoading={isLoading || isFetching}
        >
          {" "}
          {tabsRender ? (
            <div className="flex flex-col gap-2 h-full px-2 py-3">
              {/* productViewTabs */}
              {productViewTabs?.length && (
                <div className="flex gap-3 items-center bg-white">
                  {productViewTabs
                    ?.filter((nav) => {
                      return isAuthorized(
                        nav?.moduleName as keyof typeof UserModuleNameTypes
                      );
                    })
                    ?.map((tab: any, index: any) => {
                      return (
                        <div key={index}>
                          <button
                            type="button"
                            onClick={() => navigate(tab.path)}
                            className={`h-full px-4 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${
          currentPath === tab.path?.split("/")[0]
            ? "text-primary-main   border-primary-main"
            : "text-gray-700 border-white"
        }
         `}
                          >
                            <div className="text-lg">
                              <tab.icon />
                            </div>
                            {tab.label}
                          </button>
                        </div>
                      );
                    })}
                </div>
              )}
              <div className="grow overflow-auto ">
                <Outlet />
              </div>
            </div>
          ) : (
            <AccessDenied />
          )}
        </ProductSideBarLayout>
      </SideNavLayout>
    </>
  );
};

export default ProductViewTabsLayout;
