import React from "react";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import { UserModuleNameTypes } from "src/models/UserAccess/UserAccess.model";
import { isAuthorized } from "src/utils/authorization";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    sortValue: {
      field: string;
      value: "DESC" | "ASC";
    } | null;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
    setSortValue: (
      newValue: {
        field: string;
        value: "DESC" | "ASC";
      } | null
    ) => void;
  };
};

const ProjectFlowTab = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col h-full">
        {/* Page Header */}
        <div className="flex">
          <ATMPageHeader pageTitle="Project Flow" hideAddButton hideSearchBox />
          <div className="flex items-center justify-between gap-3">
            <div className="flex items-center gap-2">
              {/* Search Field */}
              <div className="w-[300px]">
                <ATMInputAdormant
                  name=""
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  adormant={<BiSearch />}
                  adormantProps={{
                    position: "start",
                    extraClasses: "bg-white border-0",
                  }}
                  inputProps={{ className: "bg-white" }}
                  placeholder="Search..."
                />
              </div>

              {/* Filter Button */}
              {/* <button
                type="button"
                onClick={() => setIsOpenTicketFilterDialog(true)}
                className={`rounded-lg flex justify-center items-center border border-gray-200 text-gray-600 shadow bg-gray-200 ${getInputHeight(
                  "small"
                )} aspect-square`}
              >
                <IoFilter />
              </button>
              {/* Ticket Filter Dialog Wrapper */}
              {/* <TicketListFilterFormDialogWrapper
                open={isOpenTicketFilterDialog}
                onClose={() => setIsOpenTicketFilterDialog(false)}
              />  */}
            </div>

            {/* Sort By */}
            {isAuthorized(
              UserModuleNameTypes.ACTION_PROJECTS_VIEW_FLOW_ADD
            ) && (
              <div className="flex gap-x-2">
                <div>
                  <ATMLoadingButton
                    onClick={() => {
                      navigate("add");
                    }}
                  >
                    Add New
                  </ATMLoadingButton>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Table */}
        <div className="flex flex-col flex-1 p-4 overflow-auto bg-gray-50">
          <ATMTable
            columns={columns}
            rows={rows}
            rowExtraClasses={() => "min-h-[40px]"}
            isLoading={isTableLoading}
          />
        </div>

        <div className="py-2 border-t">
          <ATMPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            rowCount={totalItems}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectFlowTab;
