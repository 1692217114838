import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setPage,
  setRowsPerPage,
  setSearchValue,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/CloseDoubtSlice";
import { useGetDoubtsQuery } from "src/services/DoubtsServices";
import CloseDoubtsListing from "./CloseDoubtsListing";

type Props = {};

const CloseDoubtsListingWrapper = (props: Props) => {
  

  const dispatch = useDispatch();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    codioticUser,
    projectFilterValue,
    moduleFilterValue,
    featureFilterValue,
  } = useSelector((state: RootState) => state.closeDoubt);

  const { data, isLoading, isFetching } = useGetDoubtsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "projectId",
      "moduleId",
      "featureId",
      "question ",
      "askToId",
      "createdById",
      "createdByName",
      "side",
      "status",
    ],
    page: page,
    filterBy: [
      {
        fieldName: "status",
        value: "SOLVED",
      },
      {
        fieldName: "askToId",
        value: codioticUser?.value || "",
      },
      {
        fieldName: "projectId",
        value: projectFilterValue?.value || "",
      },
      {
        fieldName: "moduleId",
        value: moduleFilterValue?.value || "",
      },
      {
        fieldName: "featureId",
        value: featureFilterValue?.value || "",
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting Module Data
  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <div>
      <CloseDoubtsListing
        // columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default CloseDoubtsListingWrapper;
