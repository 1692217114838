import React from "react";
import { FormikProps } from "formik";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { DomainFormValues } from "src/models/Domain.model";
import MOLFormDialog from "src/components/UI/molecules/MOLFormDialog/MOLFormDialog";

type Props = {
  formikProps: FormikProps<DomainFormValues>;
  onClose: () => void;
  formType: "ADD" | "EDIT";
  setSaveNextChecked?: any;
  saveNextChecked?: any;
};

const DomainForm = ({
  formType = "ADD",
  formikProps,
  onClose,
  setSaveNextChecked,
  saveNextChecked,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <MOLFormDialog
      title={formType === "ADD" ? "Add Domain" : "Update Domain"}
      onClose={onClose}
      isSubmitting={isSubmitting}
      formType={formType}
      setSaveNextChecked={setSaveNextChecked}
      saveNextChecked={saveNextChecked}
    >
      <ATMTextField
        required
        name="domainName"
        value={values.domainName}
        onChange={(e) => setFieldValue("domainName", e.target.value)}
        label="Name"
        placeholder="Enter Doamin name"
        onBlur={handleBlur}
      />
    </MOLFormDialog>
  );
};

export default DomainForm;
