import { Chip } from "@mui/material";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import { SingleTicketResponseType } from "src/models/Ticket.model";
import "video-react/dist/video-react.css";

type Props = {
  ticketDetails: SingleTicketResponseType;
};

const TicketDetails = ({ ticketDetails }: Props) => {

  // Function to handle click event
  const handleClick = (_id: any) => {
    const newRoute = `/ticket/${_id}/details`;
    window.open(newRoute, '_blank');
  };

  return (
    <div className=" h-full overflow-auto p-4 flex flex-col gap-4">
      <div className="flex justify-between">
        {ticketDetails?.projectLabel ? (
          <div>
            <Chip label={ticketDetails?.projectLabel || "-"} />
          </div>
        ) : null}

        <div onClick={() => handleClick(ticketDetails?._id)}  style={{ cursor: 'pointer' }} >
          {ticketDetails?.ticketNumber ? (
            <div className="">
              <Chip
                label={"Ticket Number - " + ticketDetails?.ticketNumber || "-"}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <ATMMarkdownContainer markdown={ticketDetails?.description || "-"} />
      </div>

      <div>
        {ticketDetails?.attachments?.length ? (
          <div className="text-gray-500 pb-2 text-lg"> Assets </div>
        ) : null}

        <div className="grid grid-cols-12 gap-4">
          {ticketDetails?.attachments?.map((attachment, index) => {
            return (
              <div
                className="col-span-4  sm:col-span-6 xs:col-span-12 h-[200px]"
                key={index}
              >
                <ATMRenderFile fileInfo={attachment} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
